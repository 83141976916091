import { fetchSaga, takeEarliest } from 'store/sagas';
import { put, call, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { endpointSelector } from './selectors';
import { actionTypes, actions } from './modules';
import { isEmpty, join } from 'lodash';

const {
  SEARCH,
  SEARCH_MORE,
} = actionTypes;

const {
  loadSuccess,
  loadFail,
} = actions;

// throttle(ms, pattern, saga, ...args)
function* watchSearch(request) {
  yield takeEarliest([SEARCH, SEARCH_MORE], search, request);
}

function* search(request, action) {
  yield delay(300);
  const { name, filters } = action;
  let endpoint = yield select(endpointSelector(name));
  if (!isEmpty(filters)) {
    endpoint += `&${join(filters, '&')}`;
  }
  try {
    const listJSON = yield call(request, endpoint);
    const { results, count, next, previous } = listJSON;
    yield put(loadSuccess(results, count, !!next, !!previous));
  } catch (e) {
    yield put(loadFail(e.toString()));
  }
}

export default [
  fetchSaga(watchSearch),
];
