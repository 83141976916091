import * as stateGenerators from './stateGenerators';
import * as constants from './constants';
import { reducers } from './reducers';
import { sagas } from './sagas';

export const linkedinAdsPagesDucks = {
  stateGenerators,
  reducers,
  constants,
  sagas,
};
