import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: '6sense_team_access',
    name: '6sense_team_access',
    getComponent(_nextState, cb) {
      require.ensure([], (require) => {
        const container = require('./containers/TeamAccessContainer').default;
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('accesscontrol', reducer.default);
        injectSagas('accesscontrol', sagas.default);
        cb(null, container);
      },
        '6sense_team_access'
      );
    },
  };
}
