export default function createRoutes() {
  return {
    path: 'table_list',
    name: 'table_list',
    getComponent(nextState, cb) {
      require.ensure(
            [],
            (require) => {
              const containers = require('../../containers/TableList/containers');
              cb(null, containers.TableList);
            },
            'settings_standardization_prepete_table_list'
          );
    },
  };
}

