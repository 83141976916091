import CampaignsRoute from "routes/AdvertisingDS/routes/Campaigns";
import AdLibraryRoute from "./routes/AdLibrary";
import SegmentSyncsRoute from "./routes/SegmentSyncs";
import DashboardsRoute from "./routes/Dashboard";
import { ADVERTISING_PATHS, ADVERTISING_REDUX_STATE_KEY } from "./constants";
import { getAsyncInjectors } from "store/utils";
import ReportsRoute from "./routes/Reports";
import { CAMPAIGNS_ROUTE } from "./routes/Campaigns/constants";
import { CAMPAIGN_LIST_ROUTE } from "./routes/Campaigns/routes/ListCampaigns/constants";

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: ADVERTISING_PATHS.advertising,
    name: "Advertising",
    indexRoute: {
      onEnter: (nextState, replace) =>
        replace(
          `/${ADVERTISING_PATHS.advertising}/${CAMPAIGNS_ROUTE}/${CAMPAIGN_LIST_ROUTE}`
        ),
    },
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require("./containers/AdvertisingContainer");

          const sagas = require("./sagas").default;
          injectSagas(ADVERTISING_REDUX_STATE_KEY, sagas);

          const reducer = require("./modules").default;
          injectReducer(ADVERTISING_REDUX_STATE_KEY, reducer);

          cb(null, containers.default);
        },
        "6si_advertising"
      );
    },
    childRoutes: [
      CampaignsRoute(store),
      AdLibraryRoute(store),
      ReportsRoute(store),
      SegmentSyncsRoute(store),
      DashboardsRoute(store),
    ],
  };
}
