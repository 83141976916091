import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router';

const ATag = (props) => <a {...props}>{props.children}</a>;

ATag.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

function Link(props) {
  const {
    link,
    children,
    className,
    onClick,
    isInternal,
    newWindow,
    title,
    id,
    dataPendo,
    disabled,
  } = props;

  const DisplayComponent = isInternal ? RouterLink : ATag;
  return (
    <DisplayComponent
      id={id}
      title={title}
      className={className}
      href={link}
      to={link}
      onClick={onClick}
      target={newWindow ? '_blank' : ''}
      data-pendo={dataPendo}
      disabled={disabled}
    >
      {children}
    </DisplayComponent>
  );
}

Link.defaultProps = {
  disabled: false,
};

Link.propTypes = {
  link: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isInternal: PropTypes.bool,
  newWindow: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
  dataPendo: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Link;
