import { getAsyncInjectors } from 'store/utils';
import { CAMPAIGN_LIST_ROUTE, ROUTE_STATE_KEY } from './constants';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: CAMPAIGN_LIST_ROUTE,
    name: 'listCampaigns',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const container = require('./containers/ListCampaignsContainer').default;

        const reducer = require('./modules');
        injectReducer(ROUTE_STATE_KEY, reducer.default);

        const sagas = require('./sagas');
        injectSagas(ROUTE_STATE_KEY, sagas.default);

        cb(null, container);
      }, 'campaign_list');
    },
  };
}
