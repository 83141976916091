import { getAsyncInjectors } from 'store/utils';
import ManageRoute from './routes/Manage';
import KeywordsConfigRoute from './routes/KeywordsConfig';
import BlacklistRoute from './routes/Blacklist';
import IntegrationRoute from './routes/Integration';
import CFNRoute from './routes/CFN';
import UserManagementRoute from './routes/UserManagementV2';
import UserManagementTeamAcessRoute from './routes/UserManagementV2/6senseTeamAccess';
import SalesConfigRoute from './routes/SalesConfig';
import RealTimeScoringRoute from './routes/RealTimeScoring';
import SalesStageConfigRoute from './routes/SalesStageConfig';
import NotificationsRoute from './routes/Notifications';
import MappingRoute from './routes/Mapping';
import SalesIntelMapping from './routes/SalesIntelMapping';
import ContactOrchestrationRoute from './routes/ContactOrchestration';
import TaxonomyRoute from './routes/Taxonomy';
import PublishedSegmentsRoute from './routes/PublishedSegments';
import ActionInPlatformAndApps from './routes/ActionInPlatformAndApps';
import Custom6QA from './routes/Custom6QA';
import KeywordsRoute from './routes/Keywords';
import SecurityConfig from './routes/SecurityConfig';
import Standardization from './routes/Standardization';
import { SETTINGS_ROUTE } from './constants';
import Talkingpoints from './routes/SI-Talkingpoints';
import Persona from './routes/SI-Persona';
import ActionsSettings from './routes/SI-ActionsSettings';
import Technologies from './routes/SI-Technologies';
import Psychographic from './routes/SI-Psychographics';
import CustomValueMappings from './routes/SI-CustomValueMappings';
import JobTitles from './routes/SI-JobTitles';
import Activities from './routes/SI-Activities';
import ComponentsAndMetrics from './routes/SI-ComponentsAndMetrics';
import CreditManagement from './routes/SI-CreditManagement';
import SalesIntegrationPage from './routes/SI-SalesIntegration';
import Alerts from './routes/Alerts';
import PrivacyDataControl from './routes/PrivacyDataControl';
import FiscalYearRoute from './routes/FiscalYear';
import FirstPartyUserConsent from './routes/FirstPartyUserConsent';
import GenerativeAI from './routes/SI-GenerativeAI';
import TaxonomyV2 from './routes/TaxonomyV2';

export default function createRoutes(store) {
  const {
    injectReducer,
    injectSagas,
  } = getAsyncInjectors(store);

  return {
    path: SETTINGS_ROUTE,
    name: 'Settings',
    indexRoute: {
      onEnter: (_nextState, replace) => replace(`/${SETTINGS_ROUTE}/manage`),
    },
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');

        // need these to load at a higher level for toplevelcomponent, or the saga
        // does not get called
        const userMgmtSagas = require('./routes/UserManagementV2/sagas');
        const usrMgmtReducer = require('./routes/UserManagementV2/modules');
        const mappingSagas = require('./routes/Mapping/sagas');
        const SalesIntelMappingSagas = require('./routes/SalesIntelMapping/sagas');
        const customMappingSagas = require('./routes/Mapping/sagas_custom');


        const realTimeScoringSagas = require('./routes/RealTimeScoring/sagas');
        const realTimeScoringReducer = require('./routes/RealTimeScoring/modules');

        injectReducer('userManagement', usrMgmtReducer.default);
        injectReducer('settings', reducer.settingsReducer);
        injectReducer('RealTimeScoring', realTimeScoringReducer.default);

        injectSagas(
          'settings',
          [
            ...sagas.default,
            ...userMgmtSagas.default,
            ...realTimeScoringSagas.default,
            ...mappingSagas.default,
            ...SalesIntelMappingSagas.default,
            ...customMappingSagas.default,
          ]
        );
        cb(null, containers.SettingsContainer);
      }, 'settings');
    },
    childRoutes: [
      ManageRoute(store),
      KeywordsConfigRoute(store),
      BlacklistRoute(store),
      IntegrationRoute(store),
      CFNRoute(store),
      UserManagementRoute(store),
      UserManagementTeamAcessRoute(store),
      SalesConfigRoute(store),
      RealTimeScoringRoute(store),
      NotificationsRoute(store),
      MappingRoute(store),
      SalesIntelMapping(store),
      ContactOrchestrationRoute(store),
      PublishedSegmentsRoute(store),
      TaxonomyRoute(store),
      ActionInPlatformAndApps(store),
      SalesStageConfigRoute(store),
      Custom6QA(store),
      KeywordsRoute(store),
      SecurityConfig(store),
      Standardization(store),
      Talkingpoints(store),
      Persona(store),
      ActionsSettings(store),
      Technologies(store),
      Psychographic(store),
      CustomValueMappings(store),
      JobTitles(store),
      Activities(store),
      ComponentsAndMetrics(store),
      CreditManagement(store),
      SalesIntegrationPage(store),
      Alerts(store),
      PrivacyDataControl(store),
      FiscalYearRoute(store),
      FirstPartyUserConsent(store),
      GenerativeAI(store),
      TaxonomyV2(store),
    ],
  };
}
