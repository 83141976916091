import {
  orderFolders,
  formatFilterVariable,
  getDefaultFilterVal,
  resolveRelationship,
  cloneRelationshipNode,
  getDateMetadata,
  isNullFilterset,
  genEmptyFilterset,
  resolveNextField,
} from './utils';
import { NULLEXPR } from 'ast-redux/constants';
import { UNSORTED_FOLDER } from './constants';
import { cloneDeep } from 'lodash';
import { getConstType, AndNoOrder } from 'ast-redux/constructors';
import { computeRangeSelection } from 'utils/utils';

const initialState = {
  loading: true,
  loaded: false,
  error: false,
  configLoading: false,
  filtersConfig: {},
  templateData: {},
  creatingSegment: false,
  createSegmentError: false,
  updatingSegment: false,
  updateSegmentError: false,
  stagedSegmentData: {},
  logicError: false,
  foldersLoading: false,
  segFolders: [],
  tagsLoading: false,
  segTags: [],
  selectedFolders: [],
  folderToCreate: '',
  workingSegmentName: null,
  tagsToCreate: [],
  initialFilterset: {
    filters: [],
    relationship: { type: NULLEXPR },
  },
  editFilterset: {
    filters: [],
    relationship: { type: NULLEXPR },
  },
  createCampaignModal: {
    isVisible: false,
    segmentId: null,
  },
};

const LOAD_FILTERS_CONFIG = 'SEGMENTS/LOAD_FILTERS_CONFIG';
const loadFiltersConfig = () => ({
  type: LOAD_FILTERS_CONFIG,
});

const LOAD_FILTERS_CONFIG_FAIL = 'SEGMENTS/LOAD_FILTERS_CONFIG_FAIL';
const loadFiltersConfigFail = () => ({
  type: LOAD_FILTERS_CONFIG_FAIL,
});

export const LOAD_FILTERS_CONFIG_SUCCESS = 'SEGMENTS/LOAD_FILTERS_CONFIG_SUCCESS';
const loadFiltersConfigSuccess = (filtersConfig, templateData) => ({
  type: LOAD_FILTERS_CONFIG_SUCCESS,
  filtersConfig,
  templateData,
});

const UPDATE_FILTERS_CONFIG = 'SEGMENTS/UPDATE_FILTERS_CONFIG';
const updateFiltersConfig = (partialFiltersConfig) => ({
  type: UPDATE_FILTERS_CONFIG,
  partialFiltersConfig,
});

const LOAD_SEGMENT_DATA = 'SEGMENTS/LOAD_SEGMENT_DATA';
const loadSegmentData = () => ({
  type: LOAD_SEGMENT_DATA,
});

const LOAD_SEGMENT_DATA_SUCCESS = 'SEGMENTS/LOAD_SEGMENT_DATA_SUCCESS';
const loadSegmentDataSuccess = () => ({
  type: LOAD_SEGMENT_DATA_SUCCESS,
});

const LOAD_SEGMENT_DATA_FAIL = 'SEGMENTS/LOAD_SEGMENT_DATA_FAIL';
const loadSegmentDataFail = () => ({
  type: LOAD_SEGMENT_DATA_FAIL,
});

const CREATE_SEGMENT = 'SEGMENTS/CREATE_SEGMENT';
const createSegment = (segmentData, segmentId) => ({
  type: CREATE_SEGMENT,
  segmentData,
  segmentId,
});

const CREATE_SEGMENT_SUCCESS = 'SEGMENTS/CREATE_SEGMENT_SUCCESS';
const createSegmentSuccess = (segment) => ({
  type: CREATE_SEGMENT_SUCCESS,
  segment,
});

const CREATE_SEGMENT_FAIL = 'SEGMENTS/CREATE_SEGMENT_FAIL';
const createSegmentFail = () => ({
  type: CREATE_SEGMENT_FAIL,
});

const UPDATE_SEGMENT = 'SEGMENTS/UPDATE_SEGMENT';
const updateSegment = (id, segmentData) => ({
  type: UPDATE_SEGMENT,
  id,
  segmentData,
});

const UPDATE_SEGMENT_SUCCESS = 'SEGMENTS/UPDATE_SEGMENT_SUCCESS';
const updateSegmentSuccess = (segment) => ({
  type: UPDATE_SEGMENT_SUCCESS,
  segment,
});

const UPDATE_SEGMENT_FAIL = 'SEGMENTS/UPDATE_SEGMENT_FAIL';
const updateSegmentFail = () => ({
  type: UPDATE_SEGMENT_FAIL,
});

const STAGE_NEW_SEGMENT_DATA = 'SEGMENTS/STAGE_NEW_SEGMENT_DATA';
const stageNewSegmentData = (data) => ({
  type: STAGE_NEW_SEGMENT_DATA,
  data,
});

const UNSTAGE_NEW_SEGMENT_DATA = 'SEGMENTS/UNSTAGE_NEW_SEGMENT_DATA';
const unstageNewSegmentData = () => ({
  type: UNSTAGE_NEW_SEGMENT_DATA,
});

const SET_LOGIC_ERROR = 'SEGMENTS/SET_LOGIC_ERROR';
const setFilterLogicStatus = (logicError) => ({ type: SET_LOGIC_ERROR, logicError });

const GET_SEG_FOLDERS = 'SEGMENTS/GET_SEG_FOLDERS';
const getSegFolders = () => ({
  type: GET_SEG_FOLDERS,
});

const GET_SEG_FOLDERS_SUCCESS = 'SEGMENTS/GET_SEG_FOLDERS_SUCCESS';
const getSegFoldersSuccess = (segFolders = []) => ({
  type: GET_SEG_FOLDERS_SUCCESS,
  segFolders,
});

const GET_SEG_FOLDERS_FAIL = 'SEGMENTS/GET_SEG_FOLDERS_FAIL';
const getSegFoldersFail = (errorMessage) => ({
  type: GET_SEG_FOLDERS_FAIL,
  errorMessage,
});

const GET_SEG_TAGS = 'SEGMENTS/GET_SEG_TAGS';
const getSegTags = () => ({
  type: GET_SEG_TAGS,
});

const GET_SEG_TAGS_SUCCESS = 'SEGMENTS/GET_SEG_TAGS_SUCCESS';
const getSegTagsSuccess = (segTags = []) => ({
  type: GET_SEG_TAGS_SUCCESS,
  segTags,
});

const GET_SEG_TAGS_FAIL = 'SEGMENTS/GET_SEG_TAGS_FAIL';
const getSegTagsFail = (errorMessage) => ({
  type: GET_SEG_TAGS_FAIL,
  errorMessage,
});

const SET_VIEW_FOLDER = 'SEGMENTS/SET_VIEW_FOLDER';
const setViewFolder = (selectedFolder) => ({
  type: SET_VIEW_FOLDER,
  selectedFolder,
});

const RESET_SELECTED_FOLDERS = 'SEGMENTS/RESET_SELECTED_FOLDERS';
const resetSelectedFolders = () => ({
  type: RESET_SELECTED_FOLDERS,
});

const CANCEL_FOLDER_BULK_SELECT = 'SEGMENTS/CANCEL_FOLDER_BULK_SELECT';
const cancelFolderBulkSelect = () => ({
  type: CANCEL_FOLDER_BULK_SELECT,
});

const SELECT_OR_UNSELECT_FOLDER = 'SEGMENTS/SELECT_OR_UNSELECT_FOLDER';
const selectOrUnselectFolder = (folder) => ({
  type: SELECT_OR_UNSELECT_FOLDER,
  folder,
});

const BULK_SELECT_OR_UNSELECT_FOLDERS = 'SEGMENTS/BULK_SELECT_OR_UNSELECT_FOLDERS';
const bulkSelectOrUnselectFolders = (folder, lastSelected) => ({
  type: BULK_SELECT_OR_UNSELECT_FOLDERS,
  folder,
  lastSelected,
});

const SET_FOLDER_TO_CREATE = 'SEGMENTS/SET_FOLDER_TO_CREATE';
const setFolderToCreate = (folder) => ({
  type: SET_FOLDER_TO_CREATE,
  folder,
});

const SET_WORKING_SEGMENT_NAME = 'SEGMENTS/SET_WORKING_SEGMENT_NAME';
const setWorkingSegmentName = (workingSegmentName) => ({
  type: SET_WORKING_SEGMENT_NAME,
  workingSegmentName,
});

const SET_TAGS_TO_CREATE = 'SEGMENTS/SET_TAGS_TO_CREATE';
const setTagsToCreate = (tags) => ({
  type: SET_TAGS_TO_CREATE,
  tags,
});

const ADD_TAG_TO_CREATE = 'SEGMENTS/ADD_TAG_TO_CREATE';
const addTagToCreate = (tag) => ({
  type: ADD_TAG_TO_CREATE,
  tag,
});

const REMOVE_TAG_TO_CREATE = 'SEGMENTS/REMOVE_TAG_TO_CREATE';
const removeTagToCreate = (tag) => ({
  type: REMOVE_TAG_TO_CREATE,
  tag,
});

const ADD_CREATED_FOLDER = 'SEGMENTS/ADD_CREATED_FOLDER';
const addCreatedFolder = (folder) => ({
  type: ADD_CREATED_FOLDER,
  folder,
});

const RENAME_FOLDER = 'SEGMENTS/RENAME_FOLDER';
const renameFolder = (folder) => ({
  type: RENAME_FOLDER,
  folder,
});

const DELETE_FOLDERS = 'SEGMENTS/DELETE_FOLDERS';
const deleteFolders = (folderIds) => ({
  type: DELETE_FOLDERS,
  folderIds,
});

const MODIFY_TAG_STATE = 'SEGMENTS/MODIFY_TAG_STATE';
const modifyTagState = (createdTags, moddedTags) => ({
  type: MODIFY_TAG_STATE,
  createdTags,
  moddedTags,
});

const SET_INITIAL_FILTERSET = 'SEGMENTS/SET_INITIAL_FILTERSET';
const setInitialFilterset = (filterset) => ({
  type: SET_INITIAL_FILTERSET,
  filterset,
});

const SET_EMPTY_FILTERSET = 'SEGMENTS/SET_EMPTY_FILTERSET';
const setEmptyFilterset = () => ({
  type: SET_EMPTY_FILTERSET,
});

const SET_EDIT_FILTERSET = 'SEGMENTS/SET_EDIT_FILTERSET';
const setEditFilterset = (filterset) => ({
  type: SET_EDIT_FILTERSET,
  filterset,
});

const RESET_FILTERSET = 'SEGMENTS/RESET_FILTERSET';
const resetFilterset = () => ({
  type: RESET_FILTERSET,
});

const REPLACE_RELATIONSHIP = 'SEGMENTS/REPLACE_RELATIONSHIP';
const replaceRelationship = (relationship) => ({
  type: REPLACE_RELATIONSHIP,
  relationship,
});

const ADD_FILTER = 'SEGMENTS/ADD_FILTER';
const addFilter = (astKey, filterConfig) => ({
  type: ADD_FILTER,
  astKey,
  filterConfig,
});

const ADD_AST_FILTER = 'SEGMENTS/ADD_AST_FILTER';
const addAstFilter = (astKey, filterConfig) => ({
  type: ADD_AST_FILTER,
  astKey,
  filterConfig,
});

const REMOVE_FILTER = 'SEGMENTS/REMOVE_FILTER';
const removeFilter = (position) => ({
  type: REMOVE_FILTER,
  position,
});

const REMOVE_ALL_FILTERS = 'SEGMENTS/REMOVE_ALL_FILTERS';
const removeAllFilters = () => ({
  type: REMOVE_ALL_FILTERS,
});

const ADD_REMOVE_FILTER_VALUE = 'SEGMENTS/ADD_REMOVE_FILTER_VALUE';
const addRemoveFilterValue = (position, values, add, commaSep, metadata) => ({
  type: ADD_REMOVE_FILTER_VALUE,
  position,
  values,
  add,
  commaSep,
  metadata: metadata || {},
});

const REPLACE_FILTER_VALUE = 'SEGMENTS/REPLACE_FILTER_VALUE';
const replaceFilterValue = (position, value, metadata) => ({
  type: REPLACE_FILTER_VALUE,
  position,
  value,
  metadata: metadata || {},
});

const MODIFY_FILTER = 'SEGMENTS/MODIFY_FILTER';
const modifyFilter = (position, attribute, value) => ({
  type: MODIFY_FILTER,
  position,
  attribute,
  value,
});

const CLONE_FILTER = 'SEGMENTS/CLONE_FILTER';
const cloneFilter = (position) => ({
  type: CLONE_FILTER,
  position,
});

const TOGGLE_CREATE_CAMPAIGN_MODAL_VISIBLE = 'SEGMENTS/CREATE_CAMPAIGN_MODAL/TOGGLE_VISIBLE';
const toggleCreateCampaignModalVisible = (isVisible, segmentId = null) => ({
  type: TOGGLE_CREATE_CAMPAIGN_MODAL_VISIBLE,
  isVisible,
  segmentId,
});

const segmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SEGMENT_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_SEGMENT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case LOAD_SEGMENT_DATA_FAIL: {
      return {
        ...state,
        error: true,
      };
    }
    case LOAD_FILTERS_CONFIG: {
      return {
        ...state,
        configLoading: true,
        configError: false,
      };
    }
    case LOAD_FILTERS_CONFIG_SUCCESS: {
      const { filtersConfig, templateData } = action;
      return {
        ...state,
        configLoading: false,
        configError: false,
        filtersConfig,
        templateData,
      };
    }
    case LOAD_FILTERS_CONFIG_FAIL: {
      return {
        ...state,
        configLoading: false,
        configError: true,
        listLoading: false,
      };
    }
    case UPDATE_FILTERS_CONFIG: {
      const { partialFiltersConfig } = action;
      return {
        ...state,
        filtersConfig: {
          ...state.filtersConfig,
          ...partialFiltersConfig,
        },
      };
    }
    case CREATE_SEGMENT: {
      return {
        ...state,
        creatingSegment: true,
        createSegmentError: false,
      };
    }
    case CREATE_SEGMENT_SUCCESS: {
      return {
        ...state,
        creatingSegment: false,
      };
    }
    case CREATE_SEGMENT_FAIL: {
      return {
        ...state,
        creatingSegment: false,
        createSegmentError: true,
      };
    }
    case UPDATE_SEGMENT: {
      return {
        ...state,
        updatingSegment: true,
        updateSegmentError: false,
      };
    }
    case UPDATE_SEGMENT_SUCCESS: {
      return {
        ...state,
        updatingSegment: false,
        workingSegmentName: null,
      };
    }
    case UPDATE_SEGMENT_FAIL: {
      return {
        ...state,
        updatingSegment: false,
        updateSegmentError: true,
      };
    }
    case STAGE_NEW_SEGMENT_DATA: {
      const { data } = action;
      return {
        ...state,
        stagedSegmentData: data,
      };
    }
    case UNSTAGE_NEW_SEGMENT_DATA:
      return {
        ...state,
        stagedSegmentData: {},
        workingSegmentName: null,
      };
    case SET_LOGIC_ERROR: {
      const { logicError } = action;
      return {
        ...state,
        logicError,
      };
    }
    case GET_SEG_FOLDERS: {
      return {
        ...state,
        foldersLoading: true,
        folderError: false,
      };
    }
    case GET_SEG_FOLDERS_SUCCESS: {
      const { segFolders } = action;
      const { selectedFolders } = state;
      const sortedFolders= orderFolders(segFolders);
      const unSortedFolder = segFolders.filter(
        (folder) => folder.classification_type === UNSORTED_FOLDER
      );
      const newSelected = selectedFolders.length !== 0 ? selectedFolders : [unSortedFolder[0]];
      return {
        ...state,
        foldersLoading: false,
        folderError: false,
        segFolders: sortedFolders,
        selectedFolders: newSelected,
      };
    }
    case GET_SEG_FOLDERS_FAIL: {
      return {
        ...state,
        foldersLoading: false,
        folderError: true,
        listLoading: false,
      };
    }
    case GET_SEG_TAGS: {
      return {
        ...state,
        tagsLoading: true,
        tagError: false,
      };
    }
    case GET_SEG_TAGS_SUCCESS: {
      const { segTags } = action;
      return {
        ...state,
        tagsLoading: false,
        tagsError: false,
        segTags,
      };
    }
    case GET_SEG_TAGS_FAIL: {
      return {
        ...state,
        tagsLoading: false,
        tagsError: true,
        listLoading: false,
      };
    }
    case SET_VIEW_FOLDER: {
      const selectedFolder = action.selectedFolder;
      return {
        ...state,
        selectedFolders: [selectedFolder],
        folderToCreate: selectedFolder.name,
      };
    }
    case RESET_SELECTED_FOLDERS: {
      return {
        ...state,
        selectedFolders: [],
      };
    }
    case CANCEL_FOLDER_BULK_SELECT: {
      const { folderToCreate, segFolders } = state;
      const selectedFolders = segFolders.filter((folder) => folder.name === folderToCreate);
      return {
        ...state,
        selectedFolders,
      };
    }
    case SELECT_OR_UNSELECT_FOLDER: {
      const folder = action.folder;
      const { selectedFolders } = state;
      const curSelected = selectedFolders.filter(
        (selectedFolder) => selectedFolder.id !== folder.id
      );
      const newSelected = curSelected.length === selectedFolders.length ?
        curSelected.concat([folder]) : curSelected;
      return {
        ...state,
        selectedFolders: newSelected,
      };
    }
    case BULK_SELECT_OR_UNSELECT_FOLDERS: {
      const { folder, lastSelected } = action;
      const { selectedFolders, segFolders } = state;
      const newSelectedFolders = computeRangeSelection(
        segFolders, folder, lastSelected, selectedFolders, (a, b) => a.id === b.id
      );
      return {
        ...state,
        selectedFolders: newSelectedFolders,
      };
    }
    case ADD_CREATED_FOLDER: {
      const { folder } = action;
      const { segFolders } = state;
      const modifiedSegFolders = segFolders.concat([folder]);
      const sortedFolders = orderFolders(modifiedSegFolders);
      return {
        ...state,
        segFolders: sortedFolders,
      };
    }
    case RENAME_FOLDER: {
      const { folder } = action;
      const { segFolders } = state;
      const foldToRename = segFolders.filter((fold) => fold.id === folder.id)[0];
      const modifiedSegFolders = orderFolders(
        segFolders.filter((fold) => fold.id !== folder.id).concat(
          [{ ...foldToRename, name: folder.name }]
        )
      );
      return {
        ...state,
        segFolders: modifiedSegFolders,
      };
    }
    case DELETE_FOLDERS: {
      const { folderIds } = action;
      const { segFolders } = state;
      const modifiedSegFolders = segFolders.filter((fold) => !folderIds.includes(fold.id));
      return {
        ...state,
        segFolders: modifiedSegFolders,
      };
    }
    case SET_FOLDER_TO_CREATE: {
      const folderToCreate = action.folder;
      return {
        ...state,
        folderToCreate,
      };
    }
    case SET_WORKING_SEGMENT_NAME: {
      const { workingSegmentName } = action;
      return {
        ...state,
        workingSegmentName,
      };
    }
    case SET_TAGS_TO_CREATE: {
      const { segTags } = state;
      const tags = action.tags;
      const tagsToCreate = tags.map((tagId) => {
        const foundTag = segTags.find((tag) => tag.id === tagId);
        return foundTag.name;
      });
      return {
        ...state,
        tagsToCreate,
      };
    }
    case ADD_TAG_TO_CREATE: {
      const tag = action.tag;
      const { tagsToCreate } = state;
      const newTagsToCreate = tagsToCreate.concat([tag]);
      return {
        ...state,
        tagsToCreate: newTagsToCreate,
      };
    }
    case REMOVE_TAG_TO_CREATE: {
      const tag = action.tag;
      const { tagsToCreate } = state;
      const newTagsToCreate = tagsToCreate.filter((curTag) => curTag !== tag);
      return {
        ...state,
        tagsToCreate: newTagsToCreate,
      };
    }
    case MODIFY_TAG_STATE: {
      const { createdTags, moddedTags } = action;
      const remDeletedTags = moddedTags.filter((tag) => !tag.is_deleted);
      const renamedTags = remDeletedTags.map((tag) => {
        if (tag.name !== tag.newName) {
          return { ...tag, name: tag.newName };
        }
        return tag;
      });
      const finalTags = renamedTags.concat(createdTags);
      return {
        ...state,
        segTags: finalTags,
      };
    }
    case SET_INITIAL_FILTERSET: {
      const { filterset } = action;
      return {
        ...state,
        initialFilterset: cloneDeep(filterset),
        editFilterset: cloneDeep(filterset),
      };
    }
    case SET_EMPTY_FILTERSET: {
      return {
        ...state,
        initialFilterset: genEmptyFilterset(),
        editFilterset: genEmptyFilterset(),
      };
    }
    case SET_EDIT_FILTERSET: {
      const { filterset } = action;
      return {
        ...state,
        editFilterset: cloneDeep(filterset),
      };
    }
    case RESET_FILTERSET: {
      const { initialFilterset } = state;
      return {
        ...state,
        editFilterset: cloneDeep(initialFilterset),
      };
    }
    case REPLACE_RELATIONSHIP: {
      const { relationship } = action;
      const { editFilterset } = state;
      const tempFilterset = cloneDeep(editFilterset);
      tempFilterset.relationship = relationship;
      return {
        ...state,
        editFilterset: tempFilterset,
      };
    }
    case ADD_FILTER: {
      let { filterConfig } = action;
      const { editFilterset } = state;
      const nextField = resolveNextField(filterConfig);
      if (nextField) {
        filterConfig = nextField;
      }
      const tempFilterset = cloneDeep(editFilterset);
      const variable = filterConfig.template ?
        formatFilterVariable(filterConfig) : filterConfig.column;
      const defaultVal = getDefaultFilterVal(filterConfig.form_input_type);
      let metadata = {};
      if (filterConfig.is_date_dependent) {
        metadata = { ...metadata, ...getDateMetadata(tempFilterset.filters) };
      }
      tempFilterset.filters.push({
        filter: {
          variable,
          operator: filterConfig.default_operator,
          value_relationship: 'OR',
          metadata,
        },
        filter_values: defaultVal !== '' ? [{
          value: defaultVal,
          display_name: defaultVal,
          metadata: { type: getConstType(defaultVal) },
        }] : [],
      });
      const newNode = { type: 'IntConst', value: tempFilterset.filters.length };
      tempFilterset.relationship =
        isNullFilterset(tempFilterset) ? newNode :
        AndNoOrder(tempFilterset.relationship, newNode);

      return {
        ...state,
        editFilterset: tempFilterset,
      };
    }
    case REMOVE_FILTER: {
      const { position } = action;
      const { editFilterset } = state;
      const tempFilterset = cloneDeep(editFilterset);
      tempFilterset.filters.splice(position, 1);
      tempFilterset.relationship = tempFilterset.filters.length < 1 ? { type: NULLEXPR } :
        resolveRelationship(position + 1, tempFilterset.relationship);
      return {
        ...state,
        editFilterset: tempFilterset,
      };
    }
    case REMOVE_ALL_FILTERS: {
      const { editFilterset } = state;
      const tempFilterset = cloneDeep(editFilterset);
      tempFilterset.filters = [];
      tempFilterset.relationship = { type: NULLEXPR };
      return {
        ...state,
        editFilterset: tempFilterset,
      };
    }
    case CLONE_FILTER: {
      const { position } = action;
      const { editFilterset } = state;
      const tempFilterset = cloneDeep(editFilterset);
      const copiedFilter = cloneDeep(tempFilterset.filters[position]);
      tempFilterset.filters.splice(position, 0, copiedFilter);
      tempFilterset.relationship = cloneRelationshipNode(position + 1, tempFilterset.relationship);
      return {
        ...state,
        editFilterset: tempFilterset,
      };
    }
    case ADD_REMOVE_FILTER_VALUE: {
      const { position, values, add, commaSep, metadata } = action;
      const { editFilterset } = state;
      const tempFilterset = cloneDeep(editFilterset);
      for (const value of values) {
        if (!add) {
          const tempFilterVals = tempFilterset.filters[position].filter_values.filter(
            (filtval) => filtval.value !== value
          );
          tempFilterset.filters[position].filter_values = tempFilterVals;
        } else {
          const isConstValueFilter = (
            tempFilterset.filters[position]?.filter?.variable === 'keyword'
            || commaSep
          );
          tempFilterset.filters[position].filter_values.push(
            value.value
              ? {
                value: value.value,
                display_name: value.label,
                metadata: {
                  ...metadata,
                  type: isConstValueFilter ? 'Const' : getConstType(value.value),
                },
              }
              : {
                value,
                display_name: value,
                metadata: {
                  ...metadata,
                  type: isConstValueFilter ? 'Const' : getConstType(value),
                },
              }
          );
        }
      }
      return {
        ...state,
        editFilterset: tempFilterset,
      };
    }
    case REPLACE_FILTER_VALUE: {
      const { position, value, metadata } = action;
      const { editFilterset } = state;
      const tempFilterset = cloneDeep(editFilterset);
      let oldMetadata;
      if (tempFilterset && tempFilterset.filters[position].filter_values[0]) {
        oldMetadata = tempFilterset.filters[position].filter_values[0].metadata;
      } else {
        oldMetadata = {};
      }
      tempFilterset.filters[position].filter_values = value !== null ?
      [{
        value,
        display_name: value,
        metadata: { ...oldMetadata, ...metadata, type: getConstType(value) },
      }] : [];
      return {
        ...state,
        editFilterset: tempFilterset,
      };
    }
    case MODIFY_FILTER: {
      const { position, attribute, value } = action;
      const { editFilterset } = state;
      const tempFilterset = cloneDeep(editFilterset);
      tempFilterset.filters[position].filter[attribute] = value;
      return {
        ...state,
        editFilterset: tempFilterset,
      };
    }
    case TOGGLE_CREATE_CAMPAIGN_MODAL_VISIBLE: {
      const { isVisible, segmentId } = action;
      return {
        ...state,
        createCampaignModal: {
          ...state.createCampaignModal,
          isVisible,
          segmentId: isVisible ? segmentId : null,
        },
      };
    }
    default:
      return state;
  }
};

export const actions = {
  loadFiltersConfig,
  loadFiltersConfigFail,
  loadFiltersConfigSuccess,
  updateFiltersConfig,
  loadSegmentData,
  loadSegmentDataFail,
  loadSegmentDataSuccess,
  createSegment,
  createSegmentSuccess,
  createSegmentFail,
  updateSegment,
  updateSegmentSuccess,
  updateSegmentFail,
  stageNewSegmentData,
  unstageNewSegmentData,
  setFilterLogicStatus,
  addFilter,
  addAstFilter,
  getSegFolders,
  getSegFoldersSuccess,
  getSegFoldersFail,
  getSegTags,
  getSegTagsSuccess,
  getSegTagsFail,
  setViewFolder,
  selectOrUnselectFolder,
  resetSelectedFolders,
  bulkSelectOrUnselectFolders,
  setFolderToCreate,
  setTagsToCreate,
  addTagToCreate,
  removeTagToCreate,
  addCreatedFolder,
  renameFolder,
  deleteFolders,
  modifyTagState,
  cancelFolderBulkSelect,
  setInitialFilterset,
  setEmptyFilterset,
  setEditFilterset,
  resetFilterset,
  replaceRelationship,
  removeFilter,
  removeAllFilters,
  cloneFilter,
  addRemoveFilterValue,
  replaceFilterValue,
  modifyFilter,
  toggleCreateCampaignModalVisible,
  setWorkingSegmentName,
};

export const actionTypes = {
  LOAD_SEGMENT_DATA,
  CREATE_SEGMENT,
  CREATE_SEGMENT_SUCCESS,
  UPDATE_SEGMENT,
  UPDATE_SEGMENT_SUCCESS,
  ADD_FILTER,
  ADD_AST_FILTER,
  GET_SEG_FOLDERS,
  GET_SEG_TAGS,
  SET_VIEW_FOLDER,
  LOAD_FILTERS_CONFIG,
};

export default segmentReducer;
