import { stateGenerator } from "../../stateGenerator";
import { actions as globalActions } from "modules/global";
import { createSelector } from "reselect";
import { orgSelector } from "modules/user/selectors";
import { flattenCampaignSelections, statsDateRangeParams } from "./utils";
import {
  baseSelector,
  dateRangeFilterSelector,
  filterBySelector,
  globalStateSelector,
} from "./commonSelectors";
import { campaignClassificationDucks } from "routes/AdvertisingDS/ducks";
import { CAMPAIGN_STATES } from "routes/AdvertisingDS/routes/Campaigns/constants";
import { get } from "lodash";
import { generateQueryParams } from "routes/AdvertisingDS/routes/Campaigns/utils";
import { selectedTreeNodeSelector } from "../../../../selectors";
import { treeNodeKeysUtils } from "../../../../utils";
import { treeNodeSelectionsSelector } from "routes/AdvertisingDS/selectors";

const {
  stateGenerators: { campaignClassifications },
  constants: { CLASSIFICATION_TYPES },
} = campaignClassificationDucks;

const { showNotification } = globalActions;

export const campaignListEndpointSelector = createSelector(
  [
    orgSelector,
    globalStateSelector,
    dateRangeFilterSelector,
    filterBySelector,
    selectedTreeNodeSelector,
    campaignClassifications.dataSelector,
  ],
  (
    orgId,
    pageGlobalState,
    dateRangeFilter,
    filterBy,
    selectedTreeNodeKey,
    campaignClassificationsList
  ) => {
    const {
      pageNumber,
      campaignListPageSize,
      sortBy,
      sortOrder,
      // selectedFolders,
      search,
    } = pageGlobalState;
    const dateFilterParams = statsDateRangeParams(
      dateRangeFilter.value,
      dateRangeFilter.from,
      dateRangeFilter.to
    );

    const selectedFolderId =
      treeNodeKeysUtils.extractFolderId(selectedTreeNodeKey);
    const selectedFolderClassificationType = get(
      treeNodeKeysUtils.getFolderObject(
        selectedTreeNodeKey,
        campaignClassificationsList
      ),
      "classification_type"
    );
    const isDraftFolderSelected =
      selectedFolderClassificationType === CLASSIFICATION_TYPES.CAMPAIGNS_DRAFT;
    const shouldFilterOnClassificationId =
      !isDraftFolderSelected && Boolean(selectedFolderClassificationType);

    const classificationId = shouldFilterOnClassificationId
      ? selectedFolderId
      : undefined;
    const campaignState = isDraftFolderSelected
      ? CAMPAIGN_STATES.draft
      : CAMPAIGN_STATES.submitted;

    const params = {
      limit: campaignListPageSize,
      offset: campaignListPageSize * (pageNumber - 1),
      sort_by: sortBy,
      sort_by_type: sortOrder,
      classification_id: classificationId,
      campaign_state: campaignState,
      search,
      ...filterBy,
      ...dateFilterParams,
    };
    const queryParams = generateQueryParams(params);

    return `org/${orgId}/campaign_list/${queryParams}`;
  }
);
export const campaignListStateGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: "CAMPAIGNS/LIST/CAMPAIGN_LIST",
  endpointSelector: campaignListEndpointSelector,
  stateKey: "campaignList",
  onFailureActions: [() => showNotification("error", "Failed to fetch list")],
});

const folderStatsEndpointSelector = createSelector(
  [
    orgSelector,
    globalStateSelector,
    dateRangeFilterSelector,
    filterBySelector,
    treeNodeSelectionsSelector,
    campaignClassifications.dataSelector,
  ],
  (
    orgId,
    { campaignListSelections, search },
    dateRangeFilter,
    filterBy,
    selectedFolders,
    allFolders
  ) => {
    const selectedFolder =
      treeNodeKeysUtils.getFolderObject(
        get(selectedFolders, "[0]"),
        allFolders
      ) || {};
    const dateFilterParams = statsDateRangeParams(
      dateRangeFilter.value,
      dateRangeFilter.from,
      dateRangeFilter.to
    );
    const selectedCampaignIds = flattenCampaignSelections(
      campaignListSelections
    );

    const isDraftFolderSelected =
      get(selectedFolder, "classification_type") ===
      CLASSIFICATION_TYPES.CAMPAIGNS_DRAFT;
    const classificationId = isDraftFolderSelected
      ? undefined
      : get(selectedFolder, "id");
    const campaignState = isDraftFolderSelected
      ? CAMPAIGN_STATES.draft
      : CAMPAIGN_STATES.submitted;

    const params = {
      campaign_state: campaignState,
      classification_id: classificationId,
      campaign_ids: selectedCampaignIds.length ? selectedCampaignIds : null,
      search,
      ...filterBy,
      ...dateFilterParams,
    };
    const queryParams = generateQueryParams(params);

    return `query/${orgId}/aggregate_campaigns_stats/${queryParams}`;
  }
);
export const folderStatsStateGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: "CAMPAIGNS/LIST/FOLDER_STATS",
  endpointSelector: folderStatsEndpointSelector,
  stateKey: "folderStats",
  receivedDataTransform: (data) =>
    data[0] || {
      account_ctr: 0,
      account_vtr: 0,
      accounts_reached: 0,
      budget_spent: 0,
      media_impressions: 0,
    },
  onFailureActions: [
    () => showNotification("error", "Failed to fetch folder analytics"),
  ],
});
