import { Flex, Loading, ResultBoundary } from '@sixsense/core/components';
import React, { useMemo, useState } from 'react';
import { css, cx } from 'styles/emotion';
import { capitalize } from 'lodash';
import { Button, Modal, Text, Pill, Icon, Select } from 'v2-components';
import { getQueryParams, getPaginationParams, resetConfig, getUpdatedConfig } from '../../utils';
import { useRequest, useRequestValue, useSharedState, useSharedValue } from '@sixsense/core';
import { actions as globalActions } from 'modules/global';
import { useOrg } from 'hooks';
import { maybePlural } from 'utils/utils';
import SearchBar from '../SearchBar';
import { ResultStatus } from '@sixsense/core/shared/async-result/types';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { KeywordTable } from '../KeywordTable';
import { KeywordGroup, Keyword } from '../../types';
import { useKeywordAccountsRequest } from '../../hooks';
import { NoDataComponent } from '../../components';
import CategoryDescription from '../CategoryDescriptions';
import { Option } from 'rc-select';
import { AAColor } from '@sixsense/core/style';
import { FilterBy } from '../Keywords';
import {
  keywordsToGroupRequestState,
  addKwToGroupConfigState,
  orgProductState,
  PageConfig,
  groupsKeywordsConfigState,
} from '../../state';
import {
  KEYWORDS,
  INTENT_TABLE_HEADERS,
  KEYWORD_CATEGORY_OPTIONS,
  KW_EXP,
  OTHER,
  BRANDED,
  GENERIC,
} from '../../constants';
import { useDebounce } from 'react-use';

const styles = {
  button: css({
    marginRight: '10px',
    height: '30px',
  }),
  select: css({
    placeContent: 'flex-start !important',
    marginTop: '10px',
    marginBottom: '200px',
  }),
  error: css({
    margin: '10px 10px 0 0',
  }),
  loadingIcon: css({
    marginLeft: '10px',
  }),
  tableContainer: css({
    flexGrow: 0,
    flexBasis: '110%',
    overflow: 'scroll',
    width: '130%',
  }),
  selectedContainer: css({
    flexGrow: 0,
    flexBasis: '30%',
    padding: '24px 12px',
    borderLeft: '1px solid #C6CACE',
    overflow: 'scroll',
    width: '130%',
  }),
  pill: css({
    padding: '0.125rem 0.625rem !important',
    marginRight: '10px !important',
  }),
  branded: css({
    fontWeight: 'bold',
    background: '#FFEFC8 !important',
    border: '1px solid #EBA300',
    color: '#EBA300',
    '&:hover': {
      backgroundColor: '#FFEFC8 !important',
    },
  }),
  generic: css({
    fontWeight: 'bold',
    background: '#E2E7EC !important',
    border: '1px solid #9299A3',
    color: '#9299A3',
    '&:hover': {
      backgroundColor: '#E2E7EC !important',
    },
  }),
  selectedKeyword: css({
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    border: '1px solid #C6CACE',
    marginTop: 10,
    borderRadius: 2,
  }),
  productSelect: css({
    marginLeft: '10px',
  }),
  dropdown: css({
    zIndex: '1200 !important',
  }),
  searchBar: css({
    marginLeft: 15,
    marginRight: 10,
  }),
  option: css({
    whiteSpace: 'normal !important',
    '&:hover': {
      backgroundColor: `${AAColor.BLUE} !important`,
      color: `${AAColor.WHITE} !important`,
    },
  }),
  keywordText: css({
    maxWidth: '80%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  }),
  filterBy: css({
    minWidth: '8rem',
    marginLeft: 0,
  }),
  filterBySelected: css({
    minWidth: '9rem',
    marginLeft: 0,
  }),
};

type TableProps = {
  keywordList: Keyword[];
  config: PageConfig;
  setConfig: (any) => void;
}

const TableDataContainer = ({ keywordList, config, setConfig }: TableProps) => {
  const keywordAccountsResult = useKeywordAccountsRequest(keywordList, 'last_30_days', true);
  const newAccHeader = (<Flex direction="column">
    <Text bold>Research Accounts</Text>
    <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.GREY1}>(Last 30 days)</Text>
  </Flex>);
  const entry = {
    columnName: newAccHeader,
    className: css({ width: '13%' }),
  };
  const newHeaders = INTENT_TABLE_HEADERS.slice(0, 3).concat(
    [entry]).concat(INTENT_TABLE_HEADERS.slice(4, 7));
  return (
    <ResultBoundary
      result={keywordAccountsResult}
      renderLoading={() => <Loading.Spinner level="page" />}
    >
      {({ data }) =>
        keywordList.results ?
          <KeywordTable
            requestData={(pageNumber, pageSize) =>
              setConfig({ ...config, pagination: { pageSize, pageNumber } })}
            totalDataCount={keywordList.count || 0}
            pageSize={config.pagination.pageSize}
            pageOptions={[25, 50, 75, 100]}
            currentPage={config.pagination.pageNumber}
            dataset={keywordList.results || []}
            pageKey={KEYWORDS}
            headers={newHeaders}
            hideActions
            keywordAccounts={data}
            fromAddToGroup
            dropdownClassName={styles.dropdown}
          /> :
          <NoDataComponent
            filters={config.filters}
            noDataStr={'There are no available keywords to add to this group.'}
            noMatchStr={'No Keywords match your filters.'}
          />}
    </ResultBoundary>);
};

type Props = {
  onCancel: () => void;
  selectedGroup: KeywordGroup;
};

const AddToGroupModal = ({ onCancel, selectedGroup }: Props) => {

  const [config, setConfig] = useSharedState(addKwToGroupConfigState);
  const [groupKwConfig, setGroupKwConfig] = useSharedState(groupsKeywordsConfigState);
  const queryParams = getQueryParams({ ...config.filters, exclude_group: selectedGroup?.id }, true);
  const paginationParams = getPaginationParams(config.pagination);
  const [loading, setLoading] = useState(false);
  const [, setInitLoad] = useState(true);
  const [query, setSearchQuery] = useState(undefined);
  const org = useOrg();
  const request = useRequest();
  const dispatch = useDispatch();
  const keywordsResult = useRequestValue(
    keywordsToGroupRequestState,
    {
      orgId: org.id,
      queryParams,
      paginationParams,
    }
  );
  const disabled = keywordsResult.status === ResultStatus.LOADING;
  const products = useSharedValue(orgProductState);

  let productOptions = useMemo(
    () => products.map((p) => ({
      value: p.id,
      label: p.displayName === KW_EXP ? OTHER : p.displayName,
    })),
    [products]
  );
  productOptions = [{ label: 'All Product Categories', value: 'all' }].concat(productOptions);

  useDebounce(() => {
    setConfig({
      ...config,
      filters: { ...config.filters, query },
      pagination: { ...config.pagination, pageNumber: 1 },
    });
  }, 1500, [query]);

  const addToGroup = async () => {
    const body = {
      config: {
        id: selectedGroup.id || selectedGroup.value,
      },
      keywords: config.selectedValues.map((v) => v.id),
    };
    setLoading(true);
    try {
      await request(`organization/${org.id}/keyword_set/`, {
        method: 'PATCH',
        body: JSON.stringify(body),
      });
      dispatch(globalActions.showNotification('success', `Successfully added
        ${maybePlural(config.selectedValues.length, 'keyword')} to
        "${selectedGroup.name}" group.`));
      const updatedGroupKwConfig = getUpdatedConfig(groupKwConfig);
      const updatedAddKwToGroupConfig = getUpdatedConfig(config);
      resetConfig(updatedAddKwToGroupConfig, setConfig);
      resetConfig(updatedGroupKwConfig, setGroupKwConfig);
      onCancel();
    } catch (e) {
      let errorMessage = 'There was an issue adding to this group. Please try again later';
      if (e.errorStatus === 400) {
        const respBody = e.body;
        errorMessage = respBody.detail;
      }
      dispatch(globalActions.showNotification('error', errorMessage));
    }
    setLoading(false);
  };

  const categoryOptions = KEYWORD_CATEGORY_OPTIONS.map((option) => (
    <Option
      key={option.value}
      value={option.value}
      className={styles.option}
    >
      {[BRANDED, GENERIC].includes(option.value) ?
        <CategoryDescription category={option.value} /> : option.label}
    </Option>
  ));

  const footer = (
    <Flex direction="column" style={{ padding: '10px 0' }}>
      <Flex justifyContent="flex-end">
        <Button
          pointer
          className={styles.button}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          pointer
          className={styles.button}
          type={'primary'}
          disabled={loading || !config.selectedValues.length}
          onClick={() => addToGroup()}
        >
        Add Selected Keywords
        </Button>
        {loading && (
          <Loading
            size="small"
            spinnerWidth={15}
            spinnerHeight={14}
            pageLevel={false}
            className={styles.loadingIcon}
          />
        )}
      </Flex>
    </Flex>
  );

  const selectedKws = config.selectedValues.map((k) =>
    <Flex className={styles.selectedKeyword}>
      <div title={k.keyword} className={styles.keywordText}>
        <Text>{k.keyword}</Text>
      </div>
      <Flex alignItems="center">
        <Pill
          className={cx(
            styles.pill,
            k.category === 'branded' ? styles.branded : styles.generic
          )}
          notClickable
        >
          {capitalize(k.category)}
        </Pill>
        <Icon
          size={Icon.SIZE.LARGE}
          type="close"
          pointer
          onClick={() => setConfig({
            ...config,
            selectedValues: config.selectedValues.filter((i) => i.id !== k.id),
          })}
        />
      </Flex>
    </Flex>
  );

  return (
    <Modal
      visible
      bodyStyle={{ padding: 0 }}
      footer={footer}
      width="90%"
      maskClosable={false}
      onCancel={() => onCancel()}
    >
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9' }}>
        <Text type={Text.TYPE.TITLE} bold>Add keywords to "{selectedGroup.name}" group</Text>
      </Flex>
      <Flex style={{ maxHeight: 600 }}>
        <div className={styles.tableContainer}>
          <Flex style={{ marginBottom: '25px', marginTop: '10px' }}>
            <Select
              options={productOptions}
              value={config.filters.product_id}
              onChange={(v) => {
                setInitLoad(false);
                setConfig({
                  ...config,
                  filters: { ...config.filters, product_id: v },
                  pagination: { ...config.pagination, pageNumber: 1 },
                });
              }}
              width="200px"
              placeholder="Select a Product"
              icon={Select.FILTER_ICON}
              containerClassName={styles.productSelect}
              disabled={disabled || loading}
              tether
              dropdownClassName={styles.dropdown}
            />
            <Select
              showSearch={false}
              options={categoryOptions}
              useCustomOptions
              value={[BRANDED, GENERIC].includes(config.filters.category) ?
                capitalize(config.filters.category) : config.filters.category}
              width="200px"
              containerClassName={styles.productSelect}
              icon={Select.FILTER_ICON}
              placeholder="Select a Keyword Category"
              onChange={(v) => {
                setInitLoad(false);
                setConfig({
                  ...config,
                  filters: { ...config.filters, category: v },
                  pagination: { ...config.pagination, pageNumber: 1 },
                });
              }}
              disabled={disabled || loading}
              tether
              dropdownClassName={styles.dropdown}
            />
            <SearchBar
              containerClassName={styles.searchBar}
              value={query}
              setValue={setSearchQuery}
              disabled={disabled || loading}
              placeholder={'Search keywords'}
            />
            <FilterBy
              extraClass={`${config.filters.filterBy.length > 0
                ? styles.filterBySelected : styles.filterBy}`}
              config={config}
              setConfig={setConfig}
              disabled={disabled || loading}
            />
          </Flex>
          <div
            style={{
              maxWidth: 'unset !important',
              width: keywordsResult?.status === ResultStatus.LOADING ? '100%' : '130%',
              minHeight: 600,
            }}
          >
            <ResultBoundary
              result={keywordsResult}
              renderLoading={() => <Loading.Spinner level="page" />}
            >
              {({ data }) =>
                <TableDataContainer keywordList={data} config={config} setConfig={setConfig} />}
            </ResultBoundary>
          </div>
        </div>
        <div className={styles.selectedContainer}>
          <Text bold>
            {config.selectedValues.length}{' '}
            {maybePlural(config.selectedValues.length, 'keyword')} selected
          </Text>
          {selectedKws}
        </div>
      </Flex>
    </Modal>
  );
};

export default AddToGroupModal;
