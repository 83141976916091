export default function createRoutes() {
  return {
    path: 'value',
    name: 'Value',
    getComponent(_nextState, cb) {
      require.ensure(
        [],
        (require) => {
          // reducer and sagas are injected at the settings level
          const containers = require('./containers');
          cb(null, containers.ValueMetrics);
        },
        'value_reports'
      );
    },
  };
}
