import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'v2-components';
import styles from './Box.module.scss';
import { COLORS } from 'v2-styles/constants';
import { coerceLocaleString } from 'utils/utils';

const determineClassName = (boxHeight) => {
  let className = styles.content;
  if (boxHeight <= 3.571) {
    className = `${styles.onTop} ${className}`;
  }
  return className;
};

export const Box = (props) => {
  const { height, label, value, onClick, isSelected, selected } = props;
  const color = height >= 3.571 ? COLORS.WHITE : COLORS.GREY;
  const minHeight = Math.max(height, 0.142);

  return (
    <div
      className={`
        ${styles[label]}
        ${!isSelected && selected.size ? styles.unselected : null}
      `}
      style={{
        height: `${minHeight}rem`,
      }}
      onClick={() => onClick(isSelected)}
    >
      <div className={determineClassName(height)}>
        <Text color={label === 'Target' ? undefined : color} type="title">
          {label}
        </Text>
        <Text color={label === 'Target' ? undefined : color} className={styles.count}>
          {coerceLocaleString(value)} Accounts
        </Text>
      </div>
    </div>
  );
};

Box.propTypes = {
  height: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.number,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  selected: PropTypes.object,
};
