// import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(
  // store
) {
//   const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    // short name rather than full TaxonomyV2
    // TODO: redirect to manage page
    path: 'overview',
    name: 'overview-taxonomy',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const container = require('./containers/TaxonomyOverviewContainer');
        //   const sagas = require('./sagas');
        //   const reducer = require('./modules');
        //   injectReducer('taxv2', reducer.default);
        //   injectSagas('taxv2', sagas.default);
          cb(null, container.TaxonomyOverviewContainer);
        },
        'taxv2-manage',
      );
    },
  };
}
