// keeping this here for reference
export const resolvers = {
  // Mutation: {
  //   setViewAllTimeline: (_, variables, context) => {
  //     const { cache } = context;
  //     const { hashKey } = variables;

  //     const timeline = cache.readQuery({
  //       query: gql`
  //         {
  //           viewAllFlags @client
  //           testTimeline @client
  //         }
  //       `,
  //     });

  //     const { viewAllFlags } = timeline;

  //     viewAllFlags[hashKey] = viewAllFlags[hashKey]
  //       ? viewAllFlags[hashKey] + 1
  //       : 2;

  //     const timelineNew = { ...timeline };
  //     const viewAllFlagsNew = { ...viewAllFlags };

  //     cache.writeData({
  //       data: {
  //         testTimeline: timelineNew,
  //         viewAllFlags: viewAllFlagsNew,
  //         __typename: 'defaultData',
  //       },
  //     });

  //     return null;
  //   },
  // },
};
