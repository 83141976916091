import React from 'react';
import { PropTypes } from 'prop-types';
import { RELEASE_TYPES } from 'utils/constants';
import { values } from 'lodash';
import { getReleaseTypeForLogo } from 'utils/utils';
import alphaLogo from 'images/alpha_release_logo.svg';
import betaLogo from 'images/beta_release_logo.svg';
import earlyAccessTag from 'images/early_access_tag.svg';
import styles from './ReleaseLogos.module.scss';

const RELEASE_TYPE_INFO = {
  [RELEASE_TYPES.ALPHA]: {
    src: alphaLogo,
    cssClassName: 'releaseTypeLogo',
  },
  [RELEASE_TYPES.BETA]: {
    src: betaLogo,
    cssClassName: 'releaseTypeLogo',
  },
  [RELEASE_TYPES.EARLY_ACCESS]: {
    src: earlyAccessTag,
    cssClassName: 'earlyAccessTag',
  },
};

export const ReleaseTypeLogo = ({ className, releaseType }) => (
  <img
    src={RELEASE_TYPE_INFO[releaseType].src}
    alt={`${getReleaseTypeForLogo(releaseType)} Release`}
    className={className || styles[RELEASE_TYPE_INFO[releaseType].cssClassName]}
  />
);

ReleaseTypeLogo.propTypes = {
  className: PropTypes.string,
  releaseType: PropTypes.oneOf(values(RELEASE_TYPES)),
};

export default ReleaseTypeLogo;
