import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';
import { LocalState } from 'HOCS';
import styles from './NewPaginationComponent.module.scss';
import { Row, Icon } from 'v2-components';

const NewPaginationComponent = ({ pageSize }) => (ContentComponent) => {
  class NewPaginateComponent extends Component {
    componentWillReceiveProps(nextProps) {
      const { resetWhen, handleChange, dataset, totalDataCount } = nextProps;
      if (resetWhen) {
        const shouldReset =
          this.props.page > Math.max(Math.ceil((totalDataCount || dataset.length) / pageSize), 1);
        if (resetWhen(this.props, nextProps) || shouldReset) {
          handleChange(1);
        }
      }
    }

    onPageChange = (pageNumber) => {
      if (this.props.requestData) {
        this.props.requestData(pageSize, pageNumber);
      }
      this.props.handleChange(pageNumber);
    };

    itemRender = (_, type, originalElement) => {
      if (type === 'prev') {
        return (
          <a className={styles.paginationButton}>
            <Icon type="keyboard_arrow_left" size={Icon.SIZE.LARGE} /> Back
          </a>
        );
      }
      if (type === 'next') {
        return (
          <a className={styles.paginationButton}>
            Next <Icon type="keyboard_arrow_right" size={Icon.SIZE.LARGE} />
          </a>
        );
      }
      return <div className={styles.paginationElement}>{originalElement}</div>;
    };

    handlePaginationChange = (pageNumber) => this.onPageChange(pageNumber);

    render() {
      const { dataset, page, totalDataCount } = this.props;

      return (
        <Fragment>
          <ContentComponent {...this.props} />
          <Row className={styles.paginateOuter} justifyContent={'center'} alignItems={'center'}>
            <Pagination
              total={totalDataCount || dataset.length}
              pageSize={pageSize}
              current={page}
              onChange={this.handlePaginationChange}
              itemRender={this.itemRender}
              size={'small'}
            />
          </Row>
        </Fragment>
      );
    }
  }

  NewPaginateComponent.propTypes = {
    dataset: PropTypes.array,
    page: PropTypes.number,
    handleChange: PropTypes.func,
    requestData: PropTypes.func,
    resetWhen: PropTypes.func,
    totalDataCount: PropTypes.number,
  };

  return LocalState({
    localState: { page: 1 },
    changeToState: (page) => ({ page }),
  })(NewPaginateComponent);
};

NewPaginationComponent.makeDataset = (page, pageSize, dataset) => {
  const start = pageSize * (page - 1);
  const end = pageSize * page;
  return dataset.slice(start, end);
};
export default NewPaginationComponent;
