import ListTPRRoutes from './routes/ListTPR';
import CreateTPRRoutes from './routes/CreateTPR';
import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: 'talkingpointrules',
    name: 'Talking Point Rules',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/settings/salesconfig/talkingpointrules/manage'),
    },
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const sagas = require('./routes/ListTPR/sagas');
          const reducer = require('./routes/ListTPR/modules');
          injectReducer('TPRList', reducer.default);
          injectSagas('TPRList', sagas.default);
          cb(null, containers.TalkingPointRules);
        },
        'TalkingPointRules'
      );
    },
    childRoutes: [
      ListTPRRoutes(store),
      CreateTPRRoutes(store),
    ],
  };
}
