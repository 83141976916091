import { get, includes, create, map, each, keys } from 'lodash';
import { isA, isLogical, not, isBin, isNegBin } from 'ast-redux/utils';
import {
  isDateFilter,
  isDateFilterParent,
  isDateFilterSibling,
} from 'aa-ast/base';
import { humanReadableAst } from 'aa-ast/utils';
import { collect, returnFirst } from 'zipper/utils';
import {
  VAREXPR,
  AND,
  OR,
  CONST,
  INTCONST,
  OP_LABELS,
  DT_OP_LABELS,
  MATCHING_OPS,
  LTOP,
  LEOP,
  EQOP,
  GTOP,
  GEOP,
  STARTSWITHOP,
  CONTAINSOP,
  ENDSWITHOP,
  NOTLTOP,
  NOTLEOP,
  NOTEQOP,
  NOTGTOP,
  NOTGEOP,
  NOTSTARTSWITHOP,
  NOTCONTAINSOP,
  NOTENDSWITHOP,
  BOOLCONST,
  NEGATIVE_BINARY_EXPRESSIONS,
} from 'ast-redux/constants';
import { zipper } from 'ast-redux/zipper';
import { DATE_LABELS, INDUSTRY_V2_FILTER_LABELS } from 'utils/constants';
import { segmentFiltersAsArray } from './utils';
import moment from 'moment';

const logicMap = {
  and: ' all of the following: ',
  or: ' any of the following: ',
};

const humanReadableAstFormatterFactory = (
  filterConfig,
  segmentFilters,
  conditionMapper
) => {
  const serializedFilters = segmentFiltersAsArray(segmentFilters);
  const humanReadable = {};
  const _total = serializedFilters.length;
  each(serializedFilters, (sFilter) => {
    const { expr, order } = sFilter;
    /*
    1. Collect all expressions, defined as a date filter parent or non date
       filter child that is a Binary Expression
    2. Get the dimension key. Since there is only one dimKey each and/or
       cascade operates on, we can return the first VarExpr that is not a date filter
    3. Get the delimiter for the cascade if exists. Every logical operator in a cascade is
       consistent (aside from date filter which we ignore), we can just grab the
       first non date filter logical operator
    4. Format the return value, converting each expression to "sql"
    */
    const expressions = collect(
      expr,
      (loc) =>
        isDateFilterParent(loc) ||
        ((isBin(loc) || isNegBin(loc)) &&
          !isDateFilter(loc) &&
          !isDateFilterSibling(loc))
    );

    const isNeg = isNegBin(zipper(expressions[0])) || isNegBin(zipper(expressions[0].lhs));

    const dimensionKey = returnFirst(expr, isA(VAREXPR), not(isDateFilter))
      .name;

    const exprConfig = filterConfig[dimensionKey];
    const { column } = exprConfig;

    const delimiterLookup = {
      [AND]: 'and',
      [OR]: 'or',
    };

    const delimeterOpposites = {
      or: 'and',
      and: 'or',
    };

    let delim = get(
      delimiterLookup,
      get(returnFirst(expr, isLogical, not(isDateFilterParent)), 'type'),
      'or'
    );

    if (isNeg) {
      delim = delimeterOpposites[delim];
    }

    humanReadable[order] = {
      column,
      conditions: conditionMapper(expressions, delim),
      timestamp: order,
      delim,
      expr,
      dimensionKey,
    };
  });

  return create({ _total, _ast: segmentFilters }, humanReadable);
};

const humanReadableAstFormatter = (filterConfig, segmentFilters) => {
  const dateCols = ['span', 'dt'];
  const printConfig = {
    [VAREXPR]: (varExpr) => {
      const dName = varExpr.name;
      if (includes(dateCols, dName)) {
        return 'Date Range';
      }
      const colConfig = filterConfig[varExpr.name];
      const { label, activeParam } = colConfig;
      return activeParam ? `${label} for ${activeParam.label}` : label;
    },
  };
  const conditionMapper = (expressions) =>
    map(expressions, (expression) => humanReadableAst(expression, printConfig));
  return humanReadableAstFormatterFactory(filterConfig, segmentFilters, conditionMapper);
};

const humanReadableAstFormatterForDisplay = (filterConfig, segmentFilters) => {

  const dateCols = ['span', 'dt'];
  const dtFilters = ['qual_dt', 'latest_won'];

  const printConfig = {
    valueOp(op, first, duplicates, delim) {
      const usingDateOps = dtFilters.some((str) => (op.lhs.name || '').includes(str));
      const labels = usingDateOps ? DT_OP_LABELS : OP_LABELS;
      return duplicates && !first
        ? humanReadableAst(op.rhs, printConfig, first, duplicates, delim)
        : `${humanReadableAst(op.lhs, printConfig, first, duplicates, delim)}
        ${
          isDateFilter(zipper(op))
            ? ''
            : `
            ${MATCHING_OPS.has(op.type) || usingDateOps ? '' : 'is'}
            ${labels[op.type]}
            ${MATCHING_OPS.has(op.type) || usingDateOps ? '' : 'to'}
            ${duplicates && first ? '' : ':'}
          `
        }
        ${humanReadableAst(op.rhs, printConfig, first, duplicates, delim)}`;
    },

    logicalOp(op, ...rest) {
      const [first, duplicates] = rest;
      // need to break up date dependent filters
      // first filters, so we want to include the date in the display
      // this displays [filter column] [date range] [operation] [value]
      if (first && isDateFilterParent(zipper(op))) {
        return `
          ${humanReadableAst(op.lhs.lhs, printConfig, ...rest, true)}
          ${humanReadableAst(op.rhs, printConfig, ...rest)}

          ${MATCHING_OPS.has(op.lhs.type) ? '' : 'is'}
          ${OP_LABELS[op.lhs.type]}
          ${MATCHING_OPS.has(op.lhs.type) ? '' : 'to'}
          ${duplicates && first ? '' : ':'}

          ${humanReadableAst(op.lhs.rhs, printConfig, ...rest, true)}
        `;

        // this is a duplicate df, so we only want to show the value
      } else if (duplicates && !first) {
        return humanReadableAst(op.lhs.rhs, printConfig, ...rest, true);
      }

      return `
        ${humanReadableAst(op.lhs, printConfig, ...rest)}
        ${humanReadableAst(op.rhs, printConfig, ...rest)}
      `;
    },

    constExpr(constExpr, first, duplicates, delim) {
      // This is a temporary hack for a weird product requirement to show different labels for
      // specific values. This is handled elsewhere by showing the filter option label. Once
      // filtersets are used it should be easier to grab the filter config options and fill the
      // label here.
      if (constExpr.value in INDUSTRY_V2_FILTER_LABELS) {
        return INDUSTRY_V2_FILTER_LABELS[constExpr.value];
      }
      return constExpr.value in DATE_LABELS
        ? DATE_LABELS[constExpr.value]
        : `${duplicates && first ? logicMap[delim] : ''} ${constExpr.value}`;
    },

    [VAREXPR]: (varExpr) => {
      const dName = varExpr.name;
      if (includes(dateCols, dName)) {
        return dName === 'span' ? 'during the' : '';
      }
      const colConfig = filterConfig[varExpr.name];
      const { label, activeParam } = colConfig;
      return activeParam ? `${label} for ${activeParam.label}` : label;
    },

    // logical operations
    [AND]: (...all) => printConfig.logicalOp(...all),
    [OR]: (...all) => printConfig.logicalOp(...all),

    // non-logical operations
    [LTOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [LEOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [EQOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [GTOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [GEOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [STARTSWITHOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [CONTAINSOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [ENDSWITHOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [NOTLTOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [NOTLEOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [NOTEQOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [NOTGTOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [NOTGEOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [NOTSTARTSWITHOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [NOTCONTAINSOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),
    [NOTENDSWITHOP]: (op, ...rest) => printConfig.valueOp(op, ...rest),

    // constants
    [CONST]: (...rest) => printConfig.constExpr(...rest),
    [INTCONST]: (...rest) => printConfig.constExpr(...rest),
    [BOOLCONST]: (...rest) => printConfig.constExpr(...rest),
  };

  const conditionMapper = (expressions, delim) => map(expressions, (expression, i) =>
    humanReadableAst(
      expression,
      printConfig,
      i === 0, // is it the first expression in a cascade?
      expressions.length > 1, // are there duplicates?
      delim // what language should be used for duplicates?
    )
  );

  return humanReadableAstFormatterFactory(filterConfig, segmentFilters, conditionMapper);
};

const readableFiltersetFormatterForDisplay = (filterConfig, filter, dups, products) => {
  const usingDateOps = filterConfig.activeParam?.field?.form_input_type === 'date' ||
    filterConfig.form_input_type === 'date';
  const labels = usingDateOps ? DT_OP_LABELS : OP_LABELS;
  let dtVal = filter.metadata.span || filter.metadata.date_between || '';
  if (filter.metadata.date_between) {
    const dateRange = filter.metadata.date_between.split(':');
    const startDate = dateRange[1];
    const endDate = dateRange[0];
    dtVal =
      `${moment(startDate).format('MMM DD YYYY')} and ${moment(endDate).format('MMM DD YYYY')}`;
  }

  let paramData;

  if (filterConfig.param_field_version === 2 || filterConfig.param_field_version === 3) {
    // new type of parameterized filter, need to display the params
    // this will need to be changed to support multiple params
    const paramKey = keys(filterConfig.param_labels)[0];
    if (filter.metadata.params) {
      const paramVal = filter.metadata.params[paramKey][0];
      products.forEach((obj) => {
        if (paramVal === obj.name) {
          paramData = obj.display_name;
        }
      });
    } else {
      paramData = '';
    }
  } else if (filterConfig.template && filterConfig.activeParam) {
    const paramVal = filterConfig.activeParam.value;
    products.forEach((obj) => {
      if (paramVal === obj.name) {
        paramData = obj.display_name;
      }
    });
  }

  // Don't return label if this is a varexpr (handled later)
  return `${filterConfig.label}
          ${paramData ? `for
          ${paramData}` : ''}
          ${!(filter.metadata.is_counting) && filter.metadata.span ? 'during the' : ''}
          ${!(filter.metadata.is_counting) && filter.metadata.date_between ? 'between' : ''}
          ${!(filter.metadata.is_counting) &&
            dtVal ? `${filter.metadata.date_between ? dtVal : DATE_LABELS[dtVal]}` : ''}
          ${MATCHING_OPS.has(filter.operator) || usingDateOps ? '' : 'is'}
          ${labels[filter.operator]} ${MATCHING_OPS.has(filter.operator) ||
           usingDateOps ? '' : 'to'}
          ${dups ? readableValueRelationship(filter.value_relationship, filter.operator,
                  filter.metadata) : ''}
          `;
};

const readableValueRelationship = (value, operator, metadata) => {
  if (metadata.is_counting && metadata.value_operator === 'At least') {
    return `${metadata.value_operator} ${metadata.value_count} of `;
  }
  if (NEGATIVE_BINARY_EXPRESSIONS.has(operator)) {
    return logicMap[value === 'AND' ? 'or' : 'and'];
  }
  if (metadata.is_counting) {
    if (metadata.value_operator.toLowerCase() === 'all') {
      return ' all of the following: ';
    } else if (metadata.value_operator.toLowerCase() === 'any') {
      return ' any of the following: ';
    }
  }
  return logicMap[value.toLowerCase()];
};

const readableAtleastValueOpFormatterForDisplay = (filter) => {
  let dtVal = filter.metadata.span || filter.metadata.date_between || '';
  if (filter.metadata.date_between) {
    const dateRange = filter.metadata.date_between.split(':');
    const startDate = dateRange[1];
    const endDate = dateRange[0];
    dtVal =
      `${moment(startDate).format('MMM DD YYYY')} and ${moment(endDate).format('MMM DD YYYY')}`;
  }

  return `researched more than a total of
  ${filter.metadata.total_count} times 
  ${filter.metadata.span ? 'during the' : ''}
  ${filter.metadata.date_between ? 'between' : ''}
  ${dtVal ? `${filter.metadata.date_between ? dtVal : DATE_LABELS[dtVal]}` : ''}`;
};

export {
  humanReadableAstFormatter,
  humanReadableAstFormatterForDisplay, readableFiltersetFormatterForDisplay,
  readableAtleastValueOpFormatterForDisplay };
