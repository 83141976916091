import React from 'react';
import PropTypes from 'prop-types';

/* eslint max-len: [2, 100000] */
export function UrsaMajor(props) {
  if (props.color === 'grey') {
    return (
      <svg {...props} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <title>ursamajor-grey</title>
        <g fill="none" fillRule="evenodd">
          <path
            d="M332.936 958.073l9.96 17.44 290.474-165.99c7.53-4.297 11.424-12.87 9.728-21.373l-62.253-311.206c-1.275-6.385-5.552-11.746-11.495-14.396-5.953-2.66-12.8-2.27-18.402 1.044L94.495 733.316c-5.492 3.242-9.106 8.894-9.758 15.24-.653 6.334 1.747 12.608 6.465 16.895l228.232 207.482c6.405 5.832 15.932 6.876 23.46 2.58l-9.958-17.44 13.503-14.857-208.094-189.17 408.677-241.492 53.85 269.282-277.896 158.81 9.96 17.428 13.502-14.858-13.504 14.858"
            fill="#D0D4DB"
          />
          <path
            d="M561.138 500.96c-3.373 0-6.807-.852-9.94-2.65-9.627-5.5-12.97-17.76-7.468-27.386L709.708 180.45c1.837-3.234 4.548-5.884 7.81-7.66L945.76 48.292c9.758-5.3 21.936-1.717 27.237 8.01 5.32 9.74 1.726 21.937-8.002 27.247L741.743 205.316 578.586 490.84c-3.705 6.497-10.48 10.12-17.448 10.12"
            fill="#D0D4DB"
          />
          <path
            d="M146.207 750.6c0 22.92-18.583 41.49-41.492 41.49-22.92 0-41.502-18.57-41.502-41.49s18.582-41.493 41.502-41.493c22.91 0 41.492 18.572 41.492 41.492"
            fill="#EAE8E8"
          />
          <path
            d="M104.706 726.667c-13.182 0-23.924 10.73-23.924 23.933 0 13.192 10.742 23.934 23.924 23.934 13.19 0 23.933-10.742 23.933-23.934 0-13.202-10.743-23.933-23.934-23.933m0 83.004c-32.567 0-59.06-26.503-59.06-59.07s26.493-59.07 59.06-59.07c32.567 0 59.07 26.503 59.07 59.07s-26.503 59.07-59.07 59.07"
            fill="#D0D4DB"
          />
          <path
            d="M374.43 958.075c0 22.92-18.58 41.493-41.49 41.493-22.92 0-41.503-18.573-41.503-41.493s18.583-41.492 41.502-41.492c22.91 0 41.49 18.573 41.49 41.492"
            fill="#EAE8E8"
          />
          <path
            d="M332.94 934.147c-13.19 0-23.932 10.732-23.932 23.934 0 13.182 10.742 23.924 23.933 23.924 13.183 0 23.925-10.742 23.925-23.923 0-13.2-10.742-23.933-23.924-23.933m0 82.994c-32.566 0-59.07-26.492-59.07-59.06 0-32.567 26.504-59.07 59.07-59.07 32.57 0 59.062 26.503 59.062 59.07 0 32.568-26.493 59.06-59.06 59.06"
            fill="#D0D4DB"
          />
          <path
            d="M664.9 792.094c0 22.92-18.584 41.492-41.493 41.492-22.92 0-41.502-18.572-41.502-41.492 0-22.92 18.582-41.492 41.502-41.492 22.91 0 41.492 18.573 41.492 41.492"
            fill="#EAE8E8"
          />
          <path
            d="M623.402 768.167c-13.182 0-23.924 10.732-23.924 23.933 0 13.182 10.742 23.924 23.924 23.924 13.19 0 23.933-10.742 23.933-23.924 0-13.2-10.742-23.933-23.933-23.933m0 82.994c-32.567 0-59.06-26.493-59.06-59.06 0-32.567 26.493-59.07 59.06-59.07 32.567 0 59.07 26.503 59.07 59.07s-26.503 59.06-59.07 59.06"
            fill="#D0D4DB"
          />
          <path
            d="M623.404 480.878c0 34.375-27.87 62.244-62.243 62.244-34.373 0-62.242-27.87-62.242-62.244 0-34.374 27.87-62.243 62.243-62.243 34.375 0 62.244 27.87 62.244 62.243"
            fill="#EAE8E8"
          />
          <path
            d="M561.157 436.206c-24.626 0-44.665 20.038-44.665 44.674 0 24.627 20.04 44.665 44.665 44.665 24.636 0 44.674-20.038 44.674-44.665 0-24.636-20.037-44.674-44.673-44.674m0 124.476c-44.002 0-79.802-35.8-79.802-79.802 0-44.01 35.8-79.81 79.802-79.81 44.012 0 79.812 35.8 79.812 79.81 0 44.002-35.8 79.802-79.813 79.802"
            fill="#D0D4DB"
          />
          <path
            d="M768.638 190.41c0 22.92-18.582 41.493-41.492 41.493-22.92 0-41.502-18.573-41.502-41.492 0-22.92 18.583-41.49 41.502-41.49 22.91 0 41.492 18.57 41.492 41.49"
            fill="#EAE8E8"
          />
          <path
            d="M727.137 166.48c-13.18 0-23.923 10.732-23.923 23.934 0 13.18 10.742 23.923 23.923 23.923 13.192 0 23.934-10.742 23.934-23.923 0-13.202-10.74-23.934-23.933-23.934m0 82.995c-32.567 0-59.06-26.494-59.06-59.06 0-32.568 26.493-59.072 59.06-59.072 32.567 0 59.07 26.504 59.07 59.07 0 32.568-26.503 59.062-59.07 59.062"
            fill="#D0D4DB"
          />
          <path
            d="M996.863 65.925c0 22.92-18.583 41.492-41.492 41.492-22.92 0-41.5-18.573-41.5-41.492 0-22.92 18.58-41.493 41.5-41.493 22.91 0 41.493 18.573 41.493 41.493"
            fill="#EAE8E8"
          />
          <path
            d="M955.373 42c-13.192 0-23.934 10.732-23.934 23.924 0 13.19 10.74 23.933 23.933 23.933 13.18 0 23.923-10.742 23.923-23.933 0-13.192-10.742-23.924-23.923-23.924m0 82.994c-32.568 0-59.07-26.503-59.07-59.07 0-32.568 26.502-59.06 59.07-59.06 32.567 0 59.06 26.492 59.06 59.06 0 32.567-26.493 59.07-59.06 59.07"
            fill="#D0D4DB"
          />
          <path
            d="M329.255 169.772c-.28.31-.572.613-.894.894.323.28.614.582.895.893.28-.312.572-.613.893-.894-.32-.28-.612-.583-.893-.894"
            fill="#9AE0E6"
          />
          <path
            d="M329.256 130.51c-4.337 0-8.162 2.75-9.527 6.866l-5.945 17.83-17.83 5.942c-4.105 1.366-6.856 5.18-6.856 9.518 0 4.337 2.75 8.152 6.856 9.517l17.83 5.953 5.943 17.82c1.364 4.116 5.19 6.867 9.526 6.867 4.337 0 8.152-2.75 9.517-6.867l5.943-17.82 17.83-5.953c4.116-1.365 6.867-5.18 6.867-9.517s-2.75-8.152-6.867-9.518l-17.83-5.953-5.943-17.82c-1.365-4.115-5.18-6.866-9.517-6.866m-.894 40.156c.312-.28.613-.573.894-.894.28.32.572.613.893.894-.322.28-.613.582-.894.893-.28-.312-.582-.613-.894-.894M58.196 473.474l-.512 1.546c-1.004 2.99-3.363 5.35-6.355 6.344l-1.547.522 1.546.512c2.99.994 5.35 3.353 6.354 6.345l.512 1.546.512-1.547c.994-2.992 3.363-5.35 6.345-6.345l1.546-.512-1.547-.522c-2.982-.994-5.35-3.353-6.345-6.344l-.512-1.546"
            fill="#9AE0E6"
          />
          <path
            d="M70.196 431.684c-4.337 0-8.152 2.75-9.527 6.867l-8.454 25.36-25.35 8.443C22.752 473.73 20 477.543 20 481.88c0 4.337 2.75 8.152 6.867 9.528l25.35 8.453 8.452 25.35c1.374 4.116 5.19 6.866 9.526 6.866 4.337 0 8.152-2.75 9.517-6.866l8.453-25.35 25.36-8.452c4.115-1.376 6.866-5.19 6.866-9.528 0-4.337-2.75-8.15-6.867-9.527l-25.36-8.453-8.452-25.35c-1.365-4.115-5.18-6.866-9.517-6.866m-.512 43.33l.512-1.536.512 1.536c.994 2.99 3.363 5.36 6.345 6.354l1.546.512-1.547.522c-2.982.994-5.35 3.353-6.345 6.345l-.512 1.546-.512-1.546c-1.004-2.992-3.363-5.35-6.355-6.345l-1.547-.522 1.546-.512c2.99-.994 5.35-3.363 6.354-6.354M339.293 401.57c-11.083 0-20.078 8.994-20.078 20.077 0 11.083 8.995 20.078 20.078 20.078 11.093 0 20.08-8.995 20.08-20.078s-8.987-20.078-20.08-20.078M560.158 120.473c-11.093 0-20.08 8.985-20.08 20.078 0 11.084 8.987 20.08 20.08 20.08 11.083 0 20.078-8.996 20.078-20.08 0-11.092-8.995-20.077-20.078-20.077M58.196 875.452c-11.093 0-20.078 8.985-20.078 20.078 0 11.084 8.985 20.08 20.078 20.08 11.083 0 20.08-8.996 20.08-20.08 0-11.093-8.997-20.078-20.08-20.078M439.686 722.823c-11.093 0-20.078 8.995-20.078 20.078 0 11.094 8.985 20.08 20.078 20.08 11.094 0 20.08-8.986 20.08-20.08 0-11.082-8.986-20.077-20.08-20.077M781.02 501.962c-11.085 0-20.08 8.985-20.08 20.078 0 11.084 8.995 20.08 20.08 20.08 11.092 0 20.077-8.996 20.077-20.08 0-11.093-8.985-20.078-20.078-20.078M851.292 731.968c-.28.31-.572.613-.893.894.32.28.61.572.892.893.28-.32.572-.612.894-.893-.322-.28-.613-.583-.894-.894"
            fill="#9AE0E6"
          />
          <path
            d="M851.296 692.707c-4.337 0-8.162 2.75-9.527 6.857l-5.944 17.83-17.83 5.943c-4.106 1.365-6.857 5.19-6.857 9.527 0 4.337 2.75 8.152 6.856 9.517l17.83 5.944 5.943 17.83c1.364 4.116 5.19 6.867 9.526 6.867 4.337 0 8.152-2.75 9.517-6.866l5.944-17.83 17.83-5.943c4.115-1.364 6.866-5.18 6.866-9.516 0-4.337-2.75-8.162-6.867-9.527l-17.83-5.944-5.943-17.83c-1.365-4.105-5.18-6.856-9.517-6.856m-.893 40.157c.31-.28.612-.583.893-.894.27.31.572.613.894.894-.322.27-.623.572-.894.893-.28-.32-.582-.622-.893-.893M961.214 334.47c-1.003 2.99-3.363 5.35-6.354 6.343l-1.546.522 1.546.512c2.99.994 5.35 3.363 6.354 6.355l.512 1.536.512-1.536c.994-2.992 3.354-5.36 6.345-6.355l1.546-.512-1.547-.522c-2.99-.994-5.35-3.353-6.345-6.344l-.512-1.548-.512 1.547z"
            fill="#9AE0E6"
          />
          <path
            d="M945.726 291.138c-4.336 0-8.16 2.75-9.527 6.857l-8.454 25.36-25.35 8.452c-4.115 1.375-6.866 5.19-6.866 9.527 0 4.327 2.75 8.152 6.867 9.528l25.35 8.453 8.452 25.35c1.365 4.115 5.19 6.865 9.526 6.865 4.337 0 8.152-2.75 9.518-6.866l8.453-25.35 25.36-8.452c4.105-1.376 6.866-5.2 6.866-9.528 0-4.337-2.76-8.152-6.867-9.527l-25.36-8.453-8.452-25.36c-1.366-4.105-5.18-6.856-9.518-6.856m-.512 43.33l.512-1.547.512 1.548c.994 2.99 3.354 5.35 6.345 6.354l1.546.512-1.547.512c-2.99 1.004-5.35 3.363-6.345 6.355l-.512 1.547-.512-1.546c-1.003-2.99-3.363-5.35-6.354-6.354l-1.546-.512 1.546-.512c2.99-1.004 5.35-3.363 6.354-6.354"
            fill="#9AE0E6"
          />
        </g>
      </svg>
    );
  }
  return (
    <svg {...props} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <title>ursamajor</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M330.936 957.073l9.96 17.44 290.474-165.99c7.53-4.297 11.424-12.87 9.728-21.373l-62.253-311.206c-1.275-6.385-5.552-11.746-11.495-14.396-5.953-2.66-12.8-2.27-18.402 1.044L92.495 732.316c-5.492 3.242-9.106 8.894-9.758 15.24-.653 6.334 1.747 12.608 6.465 16.895l228.232 207.482c6.405 5.832 15.932 6.876 23.46 2.58l-9.958-17.44 13.503-14.857-208.094-189.17 408.677-241.492 53.85 269.282-277.896 158.81 9.96 17.428 13.502-14.858-13.504 14.858"
          fill="#2D3666"
        />
        <path
          d="M559.138 499.96c-3.373 0-6.807-.852-9.94-2.65-9.627-5.5-12.97-17.76-7.468-27.386L707.708 179.45c1.837-3.234 4.548-5.884 7.81-7.66L943.76 47.292c9.758-5.3 21.936-1.717 27.237 8.01 5.32 9.74 1.726 21.937-8.002 27.247L739.743 204.316 576.586 489.84c-3.705 6.497-10.48 10.12-17.448 10.12"
          fill="#2D3666"
        />
        <path
          d="M144.207 749.6c0 22.92-18.583 41.49-41.492 41.49-22.92 0-41.502-18.57-41.502-41.49s18.582-41.493 41.502-41.493c22.91 0 41.492 18.572 41.492 41.492"
          fill="#FFDCA1"
        />
        <path
          d="M102.706 725.667c-13.182 0-23.924 10.73-23.924 23.933 0 13.192 10.742 23.934 23.924 23.934 13.19 0 23.933-10.742 23.933-23.934 0-13.202-10.743-23.933-23.934-23.933m0 83.004c-32.567 0-59.06-26.503-59.06-59.07s26.493-59.07 59.06-59.07c32.567 0 59.07 26.503 59.07 59.07s-26.503 59.07-59.07 59.07"
          fill="#2D3666"
        />
        <path
          d="M372.43 957.075c0 22.92-18.58 41.493-41.49 41.493-22.92 0-41.503-18.573-41.503-41.493s18.583-41.492 41.502-41.492c22.91 0 41.49 18.573 41.49 41.492"
          fill="#78D5DD"
        />
        <path
          d="M330.94 933.147c-13.19 0-23.932 10.732-23.932 23.934 0 13.182 10.742 23.924 23.933 23.924 13.183 0 23.925-10.742 23.925-23.923 0-13.2-10.742-23.933-23.924-23.933m0 82.994c-32.566 0-59.07-26.492-59.07-59.06 0-32.567 26.504-59.07 59.07-59.07 32.57 0 59.062 26.503 59.062 59.07 0 32.568-26.493 59.06-59.06 59.06"
          fill="#2D3666"
        />
        <path
          d="M662.9 791.094c0 22.92-18.584 41.492-41.493 41.492-22.92 0-41.502-18.572-41.502-41.492 0-22.92 18.582-41.492 41.502-41.492 22.91 0 41.492 18.573 41.492 41.492"
          fill="#FFDCA1"
        />
        <path
          d="M621.402 767.167c-13.182 0-23.924 10.732-23.924 23.933 0 13.182 10.742 23.924 23.924 23.924 13.19 0 23.933-10.742 23.933-23.924 0-13.2-10.742-23.933-23.933-23.933m0 82.994c-32.567 0-59.06-26.493-59.06-59.06 0-32.567 26.493-59.07 59.06-59.07 32.567 0 59.07 26.503 59.07 59.07s-26.503 59.06-59.07 59.06"
          fill="#2D3666"
        />
        <path
          d="M621.404 479.878c0 34.375-27.87 62.244-62.243 62.244-34.373 0-62.242-27.87-62.242-62.244 0-34.374 27.87-62.243 62.243-62.243 34.375 0 62.244 27.87 62.244 62.243"
          fill="#78D5DD"
        />
        <path
          d="M559.157 435.206c-24.626 0-44.665 20.038-44.665 44.674 0 24.627 20.04 44.665 44.665 44.665 24.636 0 44.674-20.038 44.674-44.665 0-24.636-20.037-44.674-44.673-44.674m0 124.476c-44.002 0-79.802-35.8-79.802-79.802 0-44.01 35.8-79.81 79.802-79.81 44.012 0 79.812 35.8 79.812 79.81 0 44.002-35.8 79.802-79.813 79.802"
          fill="#2D3666"
        />
        <path
          d="M766.638 189.41c0 22.92-18.582 41.493-41.492 41.493-22.92 0-41.502-18.573-41.502-41.492 0-22.92 18.583-41.49 41.502-41.49 22.91 0 41.492 18.57 41.492 41.49"
          fill="#FFDCA1"
        />
        <path
          d="M725.137 165.48c-13.18 0-23.923 10.732-23.923 23.934 0 13.18 10.742 23.923 23.923 23.923 13.192 0 23.934-10.742 23.934-23.923 0-13.202-10.74-23.934-23.933-23.934m0 82.995c-32.567 0-59.06-26.494-59.06-59.06 0-32.568 26.493-59.072 59.06-59.072 32.567 0 59.07 26.504 59.07 59.07 0 32.568-26.503 59.062-59.07 59.062"
          fill="#2D3666"
        />
        <path
          d="M994.863 64.925c0 22.92-18.583 41.492-41.492 41.492-22.92 0-41.5-18.573-41.5-41.492 0-22.92 18.58-41.493 41.5-41.493 22.91 0 41.493 18.573 41.493 41.493"
          fill="#78D5DD"
        />
        <path
          d="M953.373 41c-13.192 0-23.934 10.732-23.934 23.924 0 13.19 10.74 23.933 23.933 23.933 13.18 0 23.923-10.742 23.923-23.933 0-13.192-10.742-23.924-23.923-23.924m0 82.994c-32.568 0-59.07-26.503-59.07-59.07 0-32.568 26.502-59.06 59.07-59.06 32.567 0 59.06 26.492 59.06 59.06 0 32.567-26.493 59.07-59.06 59.07"
          fill="#2D3666"
        />
        <path
          d="M327.255 168.772c-.28.31-.572.613-.894.894.323.28.614.582.895.893.28-.312.572-.613.893-.894-.32-.28-.612-.583-.893-.894"
          fill="#9AE0E6"
        />
        <path
          d="M327.256 129.51c-4.337 0-8.162 2.75-9.527 6.866l-5.945 17.83-17.83 5.942c-4.105 1.366-6.856 5.18-6.856 9.518 0 4.337 2.75 8.152 6.856 9.517l17.83 5.953 5.943 17.82c1.364 4.116 5.19 6.867 9.526 6.867 4.337 0 8.152-2.75 9.517-6.867l5.943-17.82 17.83-5.953c4.116-1.365 6.867-5.18 6.867-9.517s-2.75-8.152-6.867-9.518l-17.83-5.953-5.943-17.82c-1.365-4.115-5.18-6.866-9.517-6.866m-.894 40.156c.312-.28.613-.573.894-.894.28.32.572.613.893.894-.322.28-.613.582-.894.893-.28-.312-.582-.613-.894-.894M56.196 472.474l-.512 1.546c-1.004 2.99-3.363 5.35-6.355 6.344l-1.547.522 1.546.512c2.99.994 5.35 3.353 6.354 6.345l.512 1.546.512-1.547c.994-2.992 3.363-5.35 6.345-6.345l1.546-.512-1.547-.522c-2.982-.994-5.35-3.353-6.345-6.344l-.512-1.546"
          fill="#9AE0E6"
        />
        <path
          d="M56.196 430.684c-4.337 0-8.152 2.75-9.527 6.867l-8.454 25.36-25.35 8.443C8.752 472.73 6 476.543 6 480.88c0 4.337 2.75 8.152 6.867 9.528l25.35 8.453 8.452 25.35c1.374 4.116 5.19 6.866 9.526 6.866 4.337 0 8.152-2.75 9.517-6.866l8.453-25.35 25.36-8.452c4.115-1.376 6.866-5.19 6.866-9.528 0-4.337-2.75-8.15-6.867-9.527l-25.36-8.453-8.452-25.35c-1.365-4.115-5.18-6.866-9.517-6.866m-.512 43.33l.512-1.536.512 1.536c.994 2.99 3.363 5.36 6.345 6.354l1.546.512-1.547.522c-2.982.994-5.35 3.353-6.345 6.345l-.512 1.546-.512-1.546c-1.004-2.992-3.363-5.35-6.355-6.345l-1.547-.522 1.546-.512c2.99-.994 5.35-3.363 6.354-6.354M337.293 400.57c-11.083 0-20.078 8.994-20.078 20.077 0 11.083 8.995 20.078 20.078 20.078 11.093 0 20.08-8.995 20.08-20.078s-8.987-20.078-20.08-20.078M558.158 119.473c-11.093 0-20.08 8.985-20.08 20.078 0 11.084 8.987 20.08 20.08 20.08 11.083 0 20.078-8.996 20.078-20.08 0-11.092-8.995-20.077-20.078-20.077M56.196 882.452c-11.093 0-20.078 8.985-20.078 20.078 0 11.084 8.985 20.08 20.078 20.08 11.083 0 20.08-8.996 20.08-20.08 0-11.093-8.997-20.078-20.08-20.078M437.686 721.823c-11.093 0-20.078 8.995-20.078 20.078 0 11.094 8.985 20.08 20.078 20.08 11.094 0 20.08-8.986 20.08-20.08 0-11.082-8.986-20.077-20.08-20.077M779.02 500.962c-11.085 0-20.08 8.985-20.08 20.078 0 11.084 8.995 20.08 20.08 20.08 11.092 0 20.077-8.996 20.077-20.08 0-11.093-8.985-20.078-20.078-20.078M849.292 730.968c-.28.31-.572.613-.893.894.32.28.61.572.892.893.28-.32.572-.612.894-.893-.322-.28-.613-.583-.894-.894"
          fill="#9AE0E6"
        />
        <path
          d="M849.296 691.707c-4.337 0-8.162 2.75-9.527 6.857l-5.944 17.83-17.83 5.943c-4.106 1.365-6.857 5.19-6.857 9.527 0 4.337 2.75 8.152 6.856 9.517l17.83 5.944 5.943 17.83c1.364 4.116 5.19 6.867 9.526 6.867 4.337 0 8.152-2.75 9.517-6.866l5.944-17.83 17.83-5.943c4.115-1.364 6.866-5.18 6.866-9.516 0-4.337-2.75-8.162-6.867-9.527l-17.83-5.944-5.943-17.83c-1.365-4.105-5.18-6.856-9.517-6.856m-.893 40.157c.31-.28.612-.583.893-.894.27.31.572.613.894.894-.322.27-.623.572-.894.893-.28-.32-.582-.622-.893-.893M959.726 331.922l-.512 1.547c-1.003 2.99-3.363 5.35-6.354 6.343l-1.546.522 1.546.512c2.99.994 5.35 3.363 6.354 6.355l.512 1.536.512-1.536c.994-2.992 3.354-5.36 6.345-6.355l1.546-.512-1.547-.522c-2.99-.994-5.35-3.353-6.345-6.344l-.512-1.548"
          fill="#9AE0E6"
        />
        <path
          d="M959.726 290.138c-4.336 0-8.16 2.75-9.527 6.857l-8.454 25.36-25.35 8.452c-4.115 1.375-6.866 5.19-6.866 9.527 0 4.327 2.75 8.152 6.867 9.528l25.35 8.453 8.452 25.35c1.365 4.115 5.19 6.865 9.526 6.865 4.337 0 8.152-2.75 9.518-6.866l8.453-25.35 25.36-8.452c4.105-1.376 6.866-5.2 6.866-9.528 0-4.337-2.76-8.152-6.867-9.527l-25.36-8.453-8.452-25.36c-1.366-4.105-5.18-6.856-9.518-6.856m-.512 43.33l.512-1.547.512 1.548c.994 2.99 3.354 5.35 6.345 6.354l1.546.512-1.547.512c-2.99 1.004-5.35 3.363-6.345 6.355l-.512 1.547-.512-1.546c-1.003-2.99-3.363-5.35-6.354-6.354l-1.546-.512 1.546-.512c2.99-1.004 5.35-3.363 6.354-6.354"
          fill="#9AE0E6"
        />
      </g>
    </svg>
  );
}

UrsaMajor.propTypes = {
  color: PropTypes.string,
};
