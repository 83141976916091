import React, { useState } from 'react';
import { Flex } from '@sixsense/core/components';
import { css } from 'styles/emotion';
import { Button, Modal, Text, Loading, Input } from 'v2-components';
import { useOrg } from 'hooks';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { actions as globalActions } from 'modules/global';
import { useRequest, useSharedState, useSetSharedValue } from '@sixsense/core';
import {
  addKeywordsConfigState,
  activePageState,
  selectedGroupState,
  groupsKeywordsConfigState,
  selectGroupCounter,
  bulkAddToGroupConfigState,
} from '../../state';
import { NEW_GROUP, GROUP_KW_LIST } from '../../constants';
import { getConfigState, parseKeywords, resetConfig, getUpdatedConfig } from '../../utils';

const styles = {
  input: css({
    width: '100%',
  }),
};

type Props = {
  onCancel: () => void;
  action: string;
  keywords?: Number[];
  fromAddKeywords?: Boolean;
  fromBulkAddGroups?: Boolean;
};

const CreateGroupModal = ({
  onCancel,
  action,
  keywords,
  fromAddKeywords,
  fromBulkAddGroups,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [groupName, setGroupName] = useState('New keyword group');
  const [config, setConfig] = useSharedState(addKeywordsConfigState);
  const [bulkGroupconfig, setBulkGroupConfig] = useSharedState(bulkAddToGroupConfigState);
  const [activePage, setActivePage] = useSharedState(activePageState);
  const state = getConfigState(activePage, false);
  const setSelectedGroup = useSetSharedValue(selectedGroupState);
  const [selectGroupcounter, setSelectGroupCounter] = useSharedState(selectGroupCounter);
  const [kwGroupConfig, setKwGroupConfig] = useSharedState(groupsKeywordsConfigState);
  const [activeConfig, setactiveConfig] = useSharedState(state);

  const org = useOrg();

  const request = useRequest();
  const dispatch = useDispatch();

  // Product_id is 0 since it is unused in the bulk_update calls
  const requestUrl = keywords ? `organization/${org.id}/product_keyword/${0}/keyword/`:
    `organization/${org.id}/keyword_set/`;
  const body = keywords ? {
    action: action === 'Move' ? 'move_to' : 'add_to',
    keyword_ids: keywords,
    config: {
      location: NEW_GROUP,
      group_name: groupName,
      group_ids: null,
      target_product_ids: null,
    },
  } : {
    config: {
      name: groupName,
    },
  };

  const createGroup = async () => {
    setLoading(true);
    try {
      const resp = await request(requestUrl, {
        method: keywords ? 'PATCH' : 'POST',
        body: JSON.stringify(body),
      });
      dispatch(globalActions.showNotification('success',
      `Successfully created ${groupName} ${keywords ? 'with your keywords' : ''}`));
      setGroupName('New keyword group');
      // This modal can be triggered from the Add Keywords modal. If so, we need to make sure to
      // add the created group to the dropdown options in that modal on successful creation.
      if (fromAddKeywords) {
        const selectedGroups = [...config.keyword_groups].concat(resp.id);
        const newGroupOptions = [...config.group_options]
          .concat({ value: resp.id, label: resp.name });
        setConfig({ ...config, keyword_groups: selectedGroups, group_options: newGroupOptions });
      // Triggered from bulk add to groups
      } else if (fromBulkAddGroups) {
        const selectedGroups = [...bulkGroupconfig.keyword_groups].concat(resp.id);
        const newGroupOptions = [...bulkGroupconfig.group_options]
          .concat({ value: resp.id, label: resp.name });
        setBulkGroupConfig({
          ...bulkGroupconfig, keyword_groups: selectedGroups, group_options: newGroupOptions });
      } else {
        setSelectedGroup({ value: resp.id, name: resp.name });
        const newGroupOptions = [...config.group_options]
        .concat({ value: resp.id, label: resp.name });
        // Make sure new group options are added to the modals when created from the main page.
        setConfig({ ...config, group_options: newGroupOptions });
        setBulkGroupConfig({ ...bulkGroupconfig, group_options: newGroupOptions });

        // Make sure to reset group keyword list config, and navigate to newly created group.
        const updatedGroupKwConfig = getUpdatedConfig(kwGroupConfig);
        updatedGroupKwConfig.filters.keyword_item__keyword_set_id = resp.id;
        resetConfig(updatedGroupKwConfig, setKwGroupConfig);

        // If we're not on the group keyword list page need to reset filters on that page too.
        if (activePage !== GROUP_KW_LIST) {
          const updatedConfig = getUpdatedConfig(activeConfig);
          resetConfig(updatedConfig, setactiveConfig);
        }
        setActivePage(GROUP_KW_LIST);
      }
      setSelectGroupCounter(selectGroupcounter + 1);
      onCancel();
    } catch (e) {
      let errorMessage = 'There was an issue creating your group. Please try again later.';
      if (e.errorStatus === 400) {
        const respBody = e.body;
        errorMessage = respBody.detail;
      }
      dispatch(globalActions.showNotification('error', errorMessage));
    }
    setLoading(false);
  };


  const footer = (
    <Flex justifyContent="flex-end" style={{ padding: '10px 0' }}>
      <Button
        pointer
        onClick={() => {
          onCancel();
        }}
      >
        Cancel
      </Button>
      <Button
        pointer
        className="aam-l--10"
        type={'primary'}
        onClick={() => {
          createGroup();
        }}
        disabled={loading || !groupName}
      >
        Create
      </Button>
      {loading && (
        <Loading
          size="small"
          spinnerWidth={15}
          spinnerHeight={15}
          pageLevel={false}
          className="aam-l--10"
        />
      )}
    </Flex>
  );

  return (
    <Modal
      visible
      bodyStyle={{ padding: 0 }}
      footer={footer}
      maskClosable={false}
      closable={false}
      width={400}
    >
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9' }}>
        <Text type={Text.TYPE.TITLE} bold>New keyword group</Text>
      </Flex>
      <Flex style={{ padding: '20px' }}>
        <Input
          id="create_group_text_input"
          value={groupName}
          onChange={(e) => setGroupName(parseKeywords(e.target.value)[0])}
          className={styles.input}
          placeholder={'Enter keyword group name'}
          size={450}
          onFocus={(e) => e.target.select()}
          autoFocus
        />
      </Flex>
    </Modal>
  );
};

export default CreateGroupModal;
