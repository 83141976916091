import { map, capitalize, get, includes } from 'lodash';
import moment from 'moment';
import FileSaver from 'file-saver';
import { unparse } from 'papaparse';
import { filterFieldTypes } from 'utils/constants';
import { AND, INTCONST, BOOLCONST, NULLEXPR } from 'ast-redux/constants';
import {
  BEHAVIOR_SEGMENT,
  NAMED_ACCOUNTS_SEGMENT,
  LOOKALIKE_ACCOUNTS_SEGMENT,
  FIRMOGRAPH_SEGMENT,
  NAMED_ACCOUNTS_ROUTE,
  LOOKALIKE_ACCOUNTS_ROUTE,
  FIRMOGRAPHIC_ROUTE,
  BEHAVIORAL_ROUTE,
  SELECT_ROUTE,
  UNSORTED_FOLDER,
} from './constants';
import {
  FILE_UPLOAD,
  LOOKALIKE_ACCOUNTS,
  EXTERNAL_LIST,
  LOOKALIKE_EXTERNAL,
} from 'v2-components/SegmentCard/constants';
import { collect } from 'zipper/utils';

const {
  NUMERIC,
  DATE,
  BOOLEAN,
} = filterFieldTypes;

const gtmFilters = ['crm_accounts', '_profile_fit'];

export function exportAccounts(accounts, title) {
  const cleanedAccounts = map(accounts, (account) => {
    const renamedAccount = { domain: account.website, ...account };
    return [
      renamedAccount.name,
      renamedAccount.country,
      renamedAccount.domain,
    ];
  });
  const accountsCSV = unparse({
    fields: ['name', 'country', 'domain'],
    data: cleanedAccounts,
  });
  const dateString = moment().format('YYYYMMDD');
  const blob = new Blob([accountsCSV], { type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(blob, `${dateString}_${title}.csv`);
}

export function inferDefaultTab(segment) {
  switch (segment.segment_type) {
    case BEHAVIOR_SEGMENT:
      return BEHAVIORAL_ROUTE;
    case FIRMOGRAPH_SEGMENT:
      return FIRMOGRAPHIC_ROUTE;
    case LOOKALIKE_ACCOUNTS_SEGMENT:
      return LOOKALIKE_ACCOUNTS_ROUTE;
    case NAMED_ACCOUNTS_SEGMENT:
      return NAMED_ACCOUNTS_ROUTE;
    default:
      return SELECT_ROUTE;
  }
}

// Most parameterized fields use simple strings as the param values, but newer params can define a
// value label and field to show when that value is active. To support the legacy params convert
// them to use the new label/value object.
const processParam = (value) => typeof value === 'string' ? ({
  label: capitalize(value),
  value,
}) : value;

const processParams = (params) => {
  const outParams = {};
  for (const key of Object.keys(params)) {
    outParams[key] = params[key].map(processParam);
  }
  return outParams;
};

export const processFiltersConfig = (config) => {
  const outConfig = {};
  for (const key of Object.keys(config)) {
    let field = config[key];
    if (field.params) {
      field = {
        ...field,
        params: processParams(field.params),
      };
    }
    outConfig[key] = field;
  }
  return outConfig;
};

export const segmentFiltersAsArray = (segmentFilter) => {
  if (!segmentFilter) {
    // Stupid react router
    return [];
  }

  return map(
    collect(segmentFilter, (loc) => loc.node().order),
    (expr) => ({ expr, order: expr.order })
  );
};

// Have to have folders sorted in state to work with shift/ctrl click
export const orderFolders = (segFolders) => {
  const unSortedFolder = segFolders.filter(
    (folder) => folder.classification_type === UNSORTED_FOLDER
  );
  const otherFolders = segFolders.filter(
    (folder) => folder.classification_type !== UNSORTED_FOLDER
  );
  otherFolders.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
  return unSortedFolder.concat(otherFolders);
};

export const getCreatedTagsFromResp= (tagsToCreate, resp) => {
  const createdNames = tagsToCreate.map((tag) => tag.newName);
  const filteredResponse = resp.filter((tag) => createdNames.includes(tag.name));
  return filteredResponse.map((tag) => ({
    ...tag,
    newName: tag.name,
  }));
};

export const generateFolderBody =
(segment, segmentId, userId, segFolder, allFolders, update) => {
  if (update) {
    if (segment.folder && segment.folder.name === segFolder) {
      return [];
    }
  }
  const folder = allFolders.filter((fold) => fold.name === segFolder);
  const obj_ids = {};
  obj_ids[segmentId] = true;
  const folderResponse = {
    edited_by: userId,
    name: segFolder,
    class_type: 'segment_folder',
    object_type: 22,
    obj_ids,
  };
  const updatedFolderResponse = folder.length > 0 ?
    { ...folderResponse, id: folder[0].id } : folderResponse;
  return [updatedFolderResponse];
};

export const generateTagBodyCreate =
(segmentId, userId, segTags, allTags) => {
  const obj_ids = {};
  obj_ids[segmentId] = true;
  const fullTagResponse = [];
  for (const newTag of segTags) {
    const tagResponse = {
      edited_by: userId,
      name: newTag,
      class_type: 'tag',
      object_type: 22,
      obj_ids,
    };
    const existingTag = allTags.filter((tag) => tag.name === newTag);
    const updatedTagResponse = existingTag.length > 0 ?
      { ...tagResponse, id: existingTag[0].id } : tagResponse;
    fullTagResponse.push(updatedTagResponse);
  }
  return fullTagResponse;
};

export const generateTagBodyUpdate =
(segment, userId, segTags, allTags) => {
  const fullTagResponse = [];
  const origTagNames = segment.seg_tags.map((tagId) => {
    const tag = allTags.filter((allTag) => allTag.id === tagId)[0];
    return { id: tag.id, name: tag.name };
  });
  // Add response for tags that were removed from the segment
  for (const tag of origTagNames) {
    if (segTags.indexOf(tag.name) < 0) {
      const obj_ids = {};
      obj_ids[segment.id] = false;
      const response = {
        id: tag.id,
        edited_by: userId,
        name: tag.name,
        class_type: 'tag',
        object_type: 22,
        obj_ids,
      };
      fullTagResponse.push(response);
    }
  }
  // Add response for tags that were added to the segment
  for (const tag of segTags) {
    if (origTagNames.filter((origTag) => origTag.name === tag).length === 0) {
      const obj_ids = {};
      obj_ids[segment.id] = true;
      const response = {
        edited_by: userId,
        name: tag,
        class_type: 'tag',
        object_type: 22,
        obj_ids,
      };
      const existingTag = allTags.filter((allTag) => allTag.name === tag);
      const updatedTagResponse = existingTag.length > 0 ?
        { ...response, id: existingTag[0].id } : response;
      fullTagResponse.push(updatedTagResponse);
    }
  }
  return fullTagResponse;
};

export const genEmptyFilterset = () => ({
  filters: [],
  relationship: { type: NULLEXPR },
});

export const getDateMetadata = (filters) => {
  for (const { filter = { metadata: {} } } of filters) {
    if (filter.metadata.span) {
      return { span: filter.metadata.span };
    } else if (filter.metadata.date_between) {
      return { date_between: filter.metadata.date_between };
    }
  }
  return { span: 'last_30_days' };
};

export const formatFilterVariable = (config) => {
  if (config.activeParam) {
    return config.template.replace(/\{(.+?)\}/g, config.activeParam.value);
  }
  // If an active param is not defined, choose to format based on the first available param option
  if (config.params && Object.keys(config.params).length) {
    return config.template.replace(/\{(.+?)\}/g,
      config.params[Object.keys(config.params)[0]][0].value);
  }
  return config.column;
};

export const isNullFilterset = (filterset) =>
  filterset.relationship?.type === NULLEXPR;

export const getDefaultFilterVal = (formType) => {
  switch (formType) {
    case NUMERIC:
      return 0;
    case DATE:
      return 'date_placeholder';
    case BOOLEAN:
      return true;
    default:
      return '';
  }
};

export const resolveRelationship = (pos, node) => {
  if (node.type === 'IntConst') {
    return parseInt(node.value) > pos ?
      { type: 'IntConst', value: parseInt(node.value) - 1 } : node;
  }
  if (node.lhs && parseInt(node.rhs.value) === pos) {
    return resolveRelationship(pos, node.lhs);
  }
  if (node.rhs && parseInt(node.lhs.value) === pos) {
    return resolveRelationship(pos, node.rhs);
  }
  const newNode = node;
  newNode.lhs = resolveRelationship(pos, node.lhs);
  newNode.rhs = resolveRelationship(pos, node.rhs);
  return newNode;
};

export const resolveNextField = (config) => {
  // If we already have an activeParam in there
  if (get(config, 'activeParam.field')) {
    return get(config, 'activeParam.field');
  }
  // Otherwise need to grab a default
  if (config.params && Object.keys(config.params).length) {
    return config.params[Object.keys(config.params)[0]][0].field;
  }
  // Otherwise we have no next field, this is not a param filter
  return null;
};

export const cloneRelationshipNode = (pos, node) => {
  if (node.type === INTCONST) {
    if (parseInt(node.value) === pos) {
      return {
        lhs: { type: INTCONST, value: pos },
        rhs: { type: INTCONST, value: pos + 1 },
        type: AND,
      };
    }
    return parseInt(node.value) > pos ?
      { type: INTCONST, value: parseInt(node.value) + 1 } : node;
  }
  const newNode = node;
  newNode.lhs = cloneRelationshipNode(pos, node.lhs);
  newNode.rhs = cloneRelationshipNode(pos, node.rhs);
  return newNode;
};

export const isValidGTMSegment = (filterset, type, config) => {
  if (filterset.relationship.type === 'NullExpr' &&
    !includes([FILE_UPLOAD, LOOKALIKE_ACCOUNTS, EXTERNAL_LIST, LOOKALIKE_EXTERNAL], type)) {
    return false;
  }
  for (const filter of filterset.filters) {
    if (!config[filter.filter.variable] ||
      (!includes(['MAP', 'CRM', 'Company Profile'],
      config[filter.filter.variable].filter_display_category) &&
      !gtmFilters.some((filt) => filter.filter.variable.includes(filt)))) {
      return false;
    }
  }
  return true;
};

export const genFilterDisplayObj = (filter) => ({
  filterLabel: filter.label,
  value: filter.column,
  icon: filter.icon,
  filterDisplayCategory: filter.filter_display_category,
  toolTipDescription: filter.disabled
    ? filter.disabled_message || filter.description
    : filter.description,
  filterCategory: filter.filter_category,
  filterDisabled: filter.disabled,
  isHidden: filter.is_hidden,
});

export const formatFilterset = (filterset) => {
  const updatedFilters = map(filterset.filters, (filter) => {
    const updatedFilterValues = map(filter.filter_values, (val) => ({
      value: val.value,
      display_name: val.display_name,
      metadata: JSON.parse(val.metadata),
    }));
    return ({
      filter: {
        relationship_position: filter.relationship_position,
        variable: filter.variable,
        operator: filter.operator,
        value_relationship: filter.value_relationship,
        metadata: JSON.parse(filter.metadata),
      },
      filter_values: updatedFilterValues,
    });
  }).sort((a, b) => a.relationship_position < b.relationship_position ? -1 : 1);
  return ({
    relationship: JSON.parse(filterset.relationship.replace(/'/g, '"')),
    filters: updatedFilters,
  });
};

export const formatFiltersetResponse = (filterset, org, user, category) => {
  const filtersResponse = map(filterset.filters, (filter, index) => {
    const filterValuesResponse = map(filter.filter_values, (val) => {
      const usingBool = val.metadata.type === BOOLCONST;
      let value = val.value;
      // Have to send boolean as a string for serializer
      if (usingBool) {
        value = value && value !== 'False' ? 'True' : 'False';
      }
      return {
        value,
        display_name: value,
        metadata: JSON.stringify(val.metadata),
        org,
        filter: 1, // This is required on serializer but we'll switch it on the backend
      };
    });
    const metadata = filter.filter.metadata;
    if (['keyword', 'keyword_group', 'url'].indexOf(filter.filter.variable) > -1) {
      if (!('is_counting' in metadata)) {
        metadata.is_counting = true;
      }
      if (!('span' in metadata) && !('date_between' in metadata)) {
        metadata.span = 'last_30_days';
      }
      if (!('total_count' in metadata)) {
        metadata.total_count = 0;
      }
      if (!('value_count' in metadata)) {
        metadata.value_count = 1;
      }
      if (!('value_operator' in metadata)) {
        let value_operator = 'All';
        if (filter.filter.value_relationship === 'OR') {
          value_operator = 'Any';
        }
        metadata.value_operator = value_operator;
      }
      if (!('value_variable' in metadata)) {
        metadata.value_variable = filter.filter.variable;
      }
    }

    return ({
      ...filter.filter,
      org,
      filter_values: filterValuesResponse,
      metadata: JSON.stringify(metadata),
      relationship_position: index + 1,
      filterset: 1, // This is required on serializer but we'll switch it on the backend
    });
  });
  return {
    ...filterset,
    filters: filtersResponse,
    org,
    filterset_type: 5,
    category: category || 'segment',
  };
};

