import { concat } from 'lodash';
import { combineReducers } from 'redux';
import { getAsyncInjectors } from 'store/utils';
import PartnerTaxonomyRoute from './containers/PartnerTaxonomy';
import EventRoute from './routes/EventTaxonomy';
import OverviewRoute from './routes/Overview';
import ProductRoute from './routes/ProductTaxonomy';
import RuleBasedMappingRoute from './routes/RuleBasedMapping';
import TaxonomyJobsRoute from './routes/TaxonomyJobs';

export default function createRoutes(store) {

  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'taxonomy',
    name: 'taxonomy',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/settings/taxonomy/overview'),
    },
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const taxonomySagas = require('./sagas');
        const productSagas = require('./containers/ProductTaxonomy/sagas');
        const eventSagas = require('./containers/EventTaxonomy/sagas');
        const overviewSagas = require('./containers/Overview/sagas');
        const ruleBasedMappingSagas = require('./containers/RuleBasedMapping/sagas');
        const jobsSagas = require('./containers/TaxonomyJobs/sagas');

        const taxonomyReducer = require('./modules');
        const productReducer = require('./containers/ProductTaxonomy/modules');
        const eventReducer = require('./containers/EventTaxonomy/modules');
        const overviewReducer = require('./containers/Overview/modules');
        const ruleBasedMappingReducer = require('./containers/RuleBasedMapping/modules');
        const jobsReducer = require('./containers/TaxonomyJobs/modules');

        // Partner Taxonomy
        const partnerTaxonomySagas = require('./containers/PartnerTaxonomy/sagas');
        const partnerOverviewSagas =
          require('./containers/PartnerTaxonomy/containers/Overview/sagas');
        const partnerEventSagas =
          require('./containers/PartnerTaxonomy/containers/EventTaxonomy/sagas');
        const partnerTaxonomyJobsSagas =
        require('./containers/PartnerTaxonomy/containers/TaxonomyJobs/sagas');

        const partnerTaxonomyReducer = require('./containers/PartnerTaxonomy/modules');
        const partnerOverviewReducer =
          require('./containers/PartnerTaxonomy/containers/Overview/modules');
        const partnerEventReducer =
          require('./containers/PartnerTaxonomy/containers/EventTaxonomy/modules');
        const partnerTaxonomyJobsReducer =
          require('./containers/PartnerTaxonomy/containers/TaxonomyJobs/modules');

        const combinedReducers = combineReducers({
          product: productReducer.default,
          event: eventReducer.default,
          overview: overviewReducer.default,
          jobs: jobsReducer.default,
          config: taxonomyReducer.default,
          mappingRules: ruleBasedMappingReducer.default,
          partnerOverview: partnerOverviewReducer.default,
          partnerConfig: partnerTaxonomyReducer.default,
          partnerEvent: partnerEventReducer.default,
          partnerTaxonomyJobs: partnerTaxonomyJobsReducer.default,
        });

        const combinedSagas = concat(
          taxonomySagas.default,
          productSagas.default,
          eventSagas.default,
          overviewSagas.default,
          ruleBasedMappingSagas.default,
          jobsSagas.default,
          partnerTaxonomySagas.default,
          partnerOverviewSagas.default,
          partnerEventSagas.default,
          partnerTaxonomyJobsSagas.default
        );

        injectReducer('settingsTaxonomy', combinedReducers);
        injectSagas('settingsTaxonomy', combinedSagas);
        cb(null, containers.TaxonomyContainer);
      }, 'settings_taxonomy');
    },
    childRoutes: [OverviewRoute(), ProductRoute(), EventRoute(),
      RuleBasedMappingRoute(), TaxonomyJobsRoute(), PartnerTaxonomyRoute()],
  };
}
