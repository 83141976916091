import {
  CAMPAIGN_CONFIG_FORM,
} from 'routes/Advertising/routes/Campaigns/routes/Analytics/constants';

export const config = {
  segment: {
    placeholder: 'Search Segment',
    name: 'segment',
    form: CAMPAIGN_CONFIG_FORM,
  },
  segment_lite_orc: {
    placeholder: 'Search Segment',
    name: 'segment_lite_orc',
    form: CAMPAIGN_CONFIG_FORM,
  },
  orc_segment: {
    placeholder: 'Search Segment',
    name: 'orc_segment',
    form: CAMPAIGN_CONFIG_FORM,
  },
};
