import { useMemo } from 'react';
import { useSelector } from '@sixsense/core/versioned/react-redux';
import {
  formattedUsersSelector,
} from '../selectors';
import { User } from '../types';
import { DUMMY_SELECT_VALUE } from '../constants';

const useUserActionOptions = (
  selectedUserIds: Set<number>,
  isSAML: boolean,
  isAnyAppLicenseUserLimitExceed: boolean,
) => {
  const users: User[] = useSelector(formattedUsersSelector);

  return useMemo(() => {
    const selectedUsers = users.filter(({ id }) => selectedUserIds.has(id));

    const options: {
      label: string;
      value: string | number;
      disabled: boolean;
    }[] = [];
    options.push({
      label: 'Select Action',
      value: DUMMY_SELECT_VALUE,
      disabled: false,
    });
    if (!isSAML) {
      options.push({
        label: 'Enable',
        value: 'enable',
        disabled:
          !selectedUsers.every(({ status }) => status === 'disabled') ||
          isAnyAppLicenseUserLimitExceed,
      });
    }
    options.push({
      label: 'Disable',
      value: 'disable',
      disabled: !selectedUsers.every(({ status }) => status === 'enabled'),
    });
    if (!isSAML) {
      const allInvited = selectedUsers.every(
        ({ status }) => status === 'invited' || status === 'inviteExpired'
      );
      options.push({ label: 'Delete', value: 'delete', disabled: !allInvited });
      options.push({
        label: 'Resend Invite',
        value: 'reinvite',
        disabled: !allInvited || isAnyAppLicenseUserLimitExceed,
      });
    }

    return { options };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserIds, users]);
};

export default useUserActionOptions;
