import AccountDetailRoute from './routes/AccountDetail';
import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer } = getAsyncInjectors(store);
  return {
    path: 'account',
    name: 'Account',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/discover/dashboard'),
    },
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const reducer = require('./modules');
          injectReducer('discoverAccount', reducer.default);
          cb(null, containers.AccountTop);
        },
        'discover_account'
      );
    },
    childRoutes: [AccountDetailRoute(store)],
  };
}
