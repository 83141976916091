import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { every, filter, some } from 'lodash';
import styles from './NewCampaignModal.module.scss';
import { CREATE_CAMPAIGN_FORM } from './constants';
import { Modal, Row, Text, Col, Button, Link } from 'v2-components';
import {
  LINKEDIN_PAGE_STATUS,
  LINKEDIN_PAGE_PROP_TYPE,
  LINKEDIN_ADS_INTEGRATION_SETUP_ROUTE,
} from 'utils/constants';
import { computePageStatus, isPagePosterAccessRevoked } from 'utils/utils';
import { linkedinIntegratedPagesSelector } from 'routes/Advertising/routes/Campaigns/selectors';
import { If } from 'babel-plugin-jsx-control-statements';

const SELECT_CAMPAIGN_NAME_STEP = 'selectCampaignName';

const modalPrompt = ({ title, content, okText }) => {
  const redirectToIntegration = () =>
    browserHistory.push(`/${LINKEDIN_ADS_INTEGRATION_SETUP_ROUTE}`);

  Modal.prompt({
    type: Modal.PROMPT_TYPES.CONFIRM,
    title,
    width: 726,
    content,
    okText,
    onOk: redirectToIntegration,
  });
};

const LinkedInIntegrationCampaignComponent = ({
    closeModal,
    setActiveStep,
    setLinkedInCampaign,
    linkedinPages,
}) => {

  const hasPagesWithAdAccountReady = some(
    linkedinPages,
    ({ is_ad_account_ready }) => is_ad_account_ready === true
  );

  const hasPagesWithContentPosterApproved = some(
    linkedinPages,
    ({ sponsored_content_poster_status }) => sponsored_content_poster_status === 'APPROVED'
  );

  const areAllPagesDisabled = every(
    linkedinPages, ['is_disabled', true]
  );

  if (!linkedinPages.length) {
    closeModal();
    const title = 'LinkedIn page not added';
    const content = 'Please add at least 1 LinkedIn page under Integrations to create campaigns.';
    const okText = 'Integrate Now';

    modalPrompt({ title, content, okText });

  } else if (areAllPagesDisabled) {
    closeModal();

    const title = 'LinkedIn page not enabled';
    const content = `Please enable at least 1 LinkedIn page under Integrations to 
    create campaigns.`;
    const okText = 'Integrate Now';

    modalPrompt({ title, content, okText });
  } else if (!hasPagesWithContentPosterApproved) {
    closeModal();

    const title = 'Content poster access not approved!';
    const content = `Please make sure you have approved the content poster access for the
    pages that you want to use for creating campaigns.`;
    const okText = 'Go To Integration';

    modalPrompt({ title, content, okText });
  } else if (!hasPagesWithAdAccountReady) {
    closeModal();

    const title = 'We’re setting up your LinkedIn pages';
    const content = `You’ll be able to create campaigns as soon as your pages are ready.
    Please make sure you have approved the content poster access for the
    pages that you want to use for creating campaigns.`;
    const okText = 'Go To Integration';

    modalPrompt({ title, content, okText });
  }

  const pages = filter(
    linkedinPages,
    (page) => computePageStatus(page) === LINKEDIN_PAGE_STATUS.APPROVED
  );

  if (pages.length === 1) {
    setLinkedInCampaign({
      hasMultiplePages: false,
      selected_page_id: pages[0].id,
      selected_page_name: pages[0].page_name,
    });
    setActiveStep(SELECT_CAMPAIGN_NAME_STEP);
  }

  const choosePage = (id, page_name) => {
    setLinkedInCampaign({
      hasMultiplePages: true,
      selected_page_id: id,
      selected_page_name: page_name,
    });
    setActiveStep(SELECT_CAMPAIGN_NAME_STEP);
  };

  return (
    <IntegratedLinkedInPages
      closeModal={closeModal}
      choosePage={choosePage}
      listOfPages={pages}
    />
  );

};

LinkedInIntegrationCampaignComponent.propTypes = {
  closeModal: PropTypes.func,
  setActiveStep: PropTypes.func,
  setLinkedInCampaign: PropTypes.func,
  linkedinPages: PropTypes.arrayOf(LINKEDIN_PAGE_PROP_TYPE),
};

const LinkedInIntegrationCampaign = compose(
  reduxForm({
    form: CREATE_CAMPAIGN_FORM,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  connect((state) => ({
    linkedinPages: linkedinIntegratedPagesSelector(state),
  })),
)(LinkedInIntegrationCampaignComponent);

export default LinkedInIntegrationCampaign;

const IntegratedLinkedInPagesComponent = ({ closeModal, choosePage, listOfPages }) => (
  <React.Fragment>
    <div className={styles.linkedInPagesList}>
      {listOfPages.map(({ page_name, id, sponsored_content_poster_status }) =>
        <Col key={id}>
          <Row className={styles.pageRow}>
            <Col>
              <Text bold>
                {page_name}
              </Text>
              <Row className={styles.msgRow}>
                <If
                  condition={isPagePosterAccessRevoked({ sponsored_content_poster_status })}
                >
                  <Text type={Text.TYPE.MINI} color={Text.COLOR.ERROR}>
                    <i className={'fa fa-exclamation-circle'} /> Content poster access revoked
                  </Text>
                </If>
              </Row>
            </Col>
            <Col>
              <Row justifyContent={Row.JUSTIFY_CONTENT.FLEX_END}>
                <Button
                  type={Button.PRIMARY}
                  onClick={() => choosePage(id, page_name)}
                  disabled={isPagePosterAccessRevoked({ sponsored_content_poster_status })}
                >
                    Select
                  </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
    </div>
    <Row>
      <Link
        link={`/${LINKEDIN_ADS_INTEGRATION_SETUP_ROUTE}`}
        className={styles.integrationLink}
        isInternal
      >
        Go to Integration
      </Link>
      <Col>
        <Row className={styles.footer} justifyContent={Row.JUSTIFY_CONTENT.FLEX_END}>
          <Button className={styles.buttonSpacing} onClick={closeModal}>Cancel</Button>
        </Row>
      </Col>
    </Row>
  </React.Fragment>
);

IntegratedLinkedInPagesComponent.propTypes = {
  closeModal: PropTypes.func,
  choosePage: PropTypes.func,
  listOfPages: PropTypes.array,
};

export const IntegratedLinkedInPages = compose(
  reduxForm({
    form: CREATE_CAMPAIGN_FORM,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)(IntegratedLinkedInPagesComponent);
