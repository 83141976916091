import React from 'react';
import PropTypes from 'prop-types';
import { Icon as AntIcon } from 'antd';
import styles from './CustomIcon.module.scss';
import {
  UrsaMajor,
  Rocket,
  Segment,
  SixsenseCampaign,
  Html5,
  BrokenImageIcon,
  AdBlockerIcon,
  CheckCircle,
  Syncing,
  ExpiredExclamation,
  SyncFail,
  UnsyncedFrom,
  CheckCircleGreen,
  BetaIcon,
  SimpleRocket,
  Schedule,
  NewFeature,
  LinkedIn,
  FileCabinet,
} from './icons';
import { COLORS } from 'v2-styles/constants';
import { classNames } from 'utils/utils';

/*
  PLEASE READ!
  This component is the original v2 icon component. However, moving forward
  we will be using the new icon component which has been renamed to icon,
  with this old icon component being renamed to custom icon. This component
  is meant to be used for custom icons that are not in the icon library
  being used for our new icons. Please only use this component now for
  icons that exist below. If you are adding a new icon, please check the
  link listed in the new v2 icon component to see if it exists.

*/

const ICON_CLOSE = 'close';
const ICON_EXCLAIM = 'fa fa-exclamation';
const ICON_EXCLAIM_TRIANGLE = 'fa fa-exclamation-triangle';
const ICON_EXCLAIM_CIRCLE = 'fa fa-exclamation-circle';
const ICON_CLONE = 'fa fa-clone';
const ICON_TPM = 'fa fa-file-image-o';
const ICON_CALENDAR_O = 'fa fa-calendar-check-o';
const ICON_VISITORS = 'fa fa-users';
const ICON_ERROR = 'fa fa-times';
const ICON_ANGLE_DOWN = 'fa fa-angle-down';
const ICON_ANGLE_UP = 'fa fa-angle-up';
const ICON_ANGLE_RIGHT = 'fa fa-angle-right';
const ICON_CHECK_CIRCLE = 'fa fa-check-circle';
const ICON_PLAY_CIRCLE_O = 'fa fa-play-circle-o';
const ICON_HELP = 'fa fa-question-circle';
const ICON_FILTER = 'fa fa-filter';
const ICON_INFO = 'fa fa-info-circle';
const ICON_FILE = 'fa fa-file';
const ICON_ADDRESS_BOOK = 'fa fa-address-book';
const ICON_ANGLE_DOUBLE_RIGHT = 'fa fa-angle-double-right';
const ICON_STOP = 'fa fa-stop-circle';
const ICON_FILES = 'fa fa-files-o';
const ICON_EXCLAMATION = 'fa fa-exclamation-triangle';
const ICON_EXTERNAL_LINK = 'fa fa-external-link';
const ICON_LOADING_CIRCLE_NOTCH = 'fa fa-circle-o-notch fa-spin';
const ICON_TIME_CIRCLE = 'fa fa-times-circle';

// iconType constants
const EXCLAIM_ICON = 'EXCLAIM';
const EXCLAIM_TRIANGE_ICON = 'EXCLAIM_TRIANGLE';
const EXCLAIM_CIRCLE_ICON = 'EXCLAIM_CIRCLE';
const CLONE_ICON = 'CLONE_ICON';
const TPM_ICON = 'TPM';
const CALENDAR_ICON_O = 'CALENDAR_ICON_O';
const VISITORS_ICON = 'VISITORS';
const ERROR_ICON = 'ERROR';
const ANGLE_DOWN_ICON = 'ANGLE_DOWN';
const ANGLE_UP_ICON = 'ANGLE_UP';
const ANGLE_RIGHT_ICON = 'ANGLE_RIGHT';
const CLOSE_ICON = 'CLOSE';
const CHECK_CIRCLE_ICON = 'CHECK_CIRCLE';
const PLAY_CIRCLE_ICON = 'PLAY_CIRCLE';
const HELP_ICON = 'HELP_ICON';
const URSA_MAJOR_ICON = 'URSA_MAJOR_ICON';
const ROCKET_ICON = 'ROCKET_ICON';
const LINKEDIN_ICON = 'LINKEDIN_ICON';
const SIMPLE_ROCKET_ICON = 'SIMPLE_ROCKET_ICON';
const SCHEDULE = 'SCHEDULE';
const NEW_FEATURE = 'NEW_FEATURE';
const SEGMENT_ICON = 'SEGMENT_ICON';
const HTML5_ICON = 'HTML5_ICON';
const ADBLOCKER_ICON = 'ADBLOCKER_ICON';
const CHECKCIRCLE_ICON = 'CHECKCIRCLE_ICON';
const CHECKCIRCLE_GREEN_ICON = 'CHECKCIRCLE_GREEN_ICON';
const SYNCING_ICON = 'SYNCING_ICON';
const EXPIRED_EXCLAMATION_ICON = 'EXPIRED_EXCLAMATION_ICON';
const SYNC_FAIL_ICON = 'SYNC_FAIL_ICON';
const UNSYNCED_FROM_ICON = 'UNSYNCED_FROM_ICON';
const BROKEN_IMAGE_ICON = 'BROKEN_IMAGE_ICON';
const FILTER_ICON = 'FILTER';
const SIXSENSE_CAMPAIGN_ICON = 'SIXSENSE_CAMPAIGN';
const INFO_ICON = 'ICON_INFO';
const FILE_ICON = 'FILE_ICON';
const ADDRESS_BOOK_ICON = 'ICON_ADDRESS_BOOK';
const ANGLE_DOUBLE_RIGHT_ICON = 'ICON_DOUBLE_ANGLE_RIGHT';
const STOP_ICON = 'ICON_STOP';
const EXCLAMATION_ICON = 'ICON_EXCLAMATION';
const FILES_ICON = 'FILES_ICON';
const EXTERNAL_ICON_LINK = 'ICON_EXTERNAL_LINK';
const LOADING_CIRCLE_NOTCH = 'LOADING_CIRCLE_NOTCH';
const BETA_ICON = 'BETA_ICON';
const FILE_CABINET = 'FILE_CABINET';

const ICON_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  MEDIUM_1: 'medium1',
  SMALL_2: 'small2',
  SMALL_3: 'small3',
  SMALL_4: 'small4',
  MED_2: 'med2',
  LARGE_1: 'large1',
  LARGE_2: 'large2',
  XL: 'extraLarge',
};
const ICON_SIZE_CLASS = {
  [ICON_SIZE.SMALL]: styles.sizeSmall,
  [ICON_SIZE.MEDIUM]: styles.sizeMedium,
  [ICON_SIZE.MEDIUM_1]: styles.sizeMedium1,
  [ICON_SIZE.SMALL_2]: styles.sizeSmall2,
  [ICON_SIZE.SMALL_3]: styles.small3,
  [ICON_SIZE.SMALL_4]: styles.sizeSmall4,
  [ICON_SIZE.MED_2]: styles.sizeMed2,
  [ICON_SIZE.LARGE_1]: styles.sizeLarge1,
  [ICON_SIZE.LARGE_2]: styles.sizeLarge2,
  [ICON_SIZE.XL]: styles.sizeExtraLarge,
};

function CustomIcon(props) {
  const {
    size,
    classIcon,
    color,
    iconType,
    inverse,
    onClick,
    title,
    circular,
    pointer,
  } = props;

  let iconTypeClass;
  const colorClass = color;
  let isExternalIcon = false;
  let svgIcon = false;

  switch (iconType) {
    case EXCLAIM_ICON:
      iconTypeClass = ICON_EXCLAIM;
      break;
    case EXCLAIM_TRIANGE_ICON:
      iconTypeClass = ICON_EXCLAIM_TRIANGLE;
      break;
    case EXCLAIM_CIRCLE_ICON:
      iconTypeClass = ICON_EXCLAIM_CIRCLE;
      break;
    case ICON_TIME_CIRCLE:
      iconTypeClass = ICON_TIME_CIRCLE;
      break;
    case BETA_ICON:
      svgIcon = true;
      iconTypeClass = BetaIcon;
      break;
    case FILE_CABINET:
      svgIcon = true;
      iconTypeClass = FileCabinet;
      break;
    case SIXSENSE_CAMPAIGN_ICON:
      svgIcon = true;
      iconTypeClass = SixsenseCampaign;
      break;
    case URSA_MAJOR_ICON:
      svgIcon = true;
      iconTypeClass = UrsaMajor;
      break;
    case ROCKET_ICON:
      svgIcon = true;
      iconTypeClass = Rocket;
      break;
    case LINKEDIN_ICON:
      svgIcon = true;
      iconTypeClass = LinkedIn;
      break;
    case SIMPLE_ROCKET_ICON:
      svgIcon = true;
      iconTypeClass = SimpleRocket;
      break;
    case SCHEDULE:
      svgIcon = true;
      iconTypeClass = Schedule;
      break;
    case NEW_FEATURE:
      svgIcon = true;
      iconTypeClass = NewFeature;
      break;
    case SEGMENT_ICON:
      svgIcon = true;
      iconTypeClass = Segment;
      break;
    case HTML5_ICON:
      svgIcon = true;
      iconTypeClass = Html5;
      break;
    case ADBLOCKER_ICON:
      svgIcon = true;
      iconTypeClass = AdBlockerIcon;
      break;
    case CHECKCIRCLE_ICON:
      svgIcon = true;
      iconTypeClass = CheckCircle;
      break;
    case CHECKCIRCLE_GREEN_ICON:
      svgIcon = true;
      iconTypeClass = CheckCircleGreen;
      break;
    case SYNCING_ICON:
      svgIcon = true;
      iconTypeClass = Syncing;
      break;
    case EXPIRED_EXCLAMATION_ICON:
      svgIcon = true;
      iconTypeClass = ExpiredExclamation;
      break;
    case SYNC_FAIL_ICON:
      svgIcon = true;
      iconTypeClass = SyncFail;
      break;
    case UNSYNCED_FROM_ICON:
      svgIcon = true;
      iconTypeClass = UnsyncedFrom;
      break;
    case BROKEN_IMAGE_ICON:
      svgIcon = true;
      iconTypeClass = BrokenImageIcon;
      break;
    case CALENDAR_ICON_O:
      iconTypeClass = ICON_CALENDAR_O;
      break;
    case TPM_ICON:
      iconTypeClass = ICON_TPM;
      break;
    case VISITORS_ICON:
      iconTypeClass = ICON_VISITORS;
      break;
    case CLONE_ICON:
      iconTypeClass = ICON_CLONE;
      break;
    case ERROR_ICON:
      iconTypeClass = ICON_ERROR;
      break;
    case ANGLE_DOWN_ICON:
      iconTypeClass = ICON_ANGLE_DOWN;
      break;
    case ANGLE_UP_ICON:
      iconTypeClass = ICON_ANGLE_UP;
      break;
    case ANGLE_RIGHT_ICON:
      iconTypeClass = ICON_ANGLE_RIGHT;
      break;
    case CLOSE_ICON:
      iconTypeClass = ICON_CLOSE;
      isExternalIcon = true;
      break;
    case CHECK_CIRCLE_ICON:
      iconTypeClass = ICON_CHECK_CIRCLE;
      break;
    case PLAY_CIRCLE_ICON:
      iconTypeClass = ICON_PLAY_CIRCLE_O;
      break;
    case HELP_ICON:
      iconTypeClass = ICON_HELP;
      break;
    case FILTER_ICON:
      iconTypeClass = ICON_FILTER;
      break;
    case INFO_ICON:
      iconTypeClass = ICON_INFO;
      break;
    case FILE_ICON:
      iconTypeClass = ICON_FILE;
      break;
    case ADDRESS_BOOK_ICON:
      iconTypeClass = ICON_ADDRESS_BOOK;
      break;
    case ANGLE_DOUBLE_RIGHT_ICON:
      iconTypeClass = ICON_ANGLE_DOUBLE_RIGHT;
      break;
    case STOP_ICON:
      iconTypeClass = ICON_STOP;
      break;
    case EXCLAMATION_ICON:
      iconTypeClass = ICON_EXCLAMATION;
      break;
    case FILES_ICON:
      iconTypeClass = ICON_FILES;
      break;
    case EXTERNAL_ICON_LINK:
      iconTypeClass = ICON_EXTERNAL_LINK;
      break;
    case LOADING_CIRCLE_NOTCH:
      iconTypeClass = ICON_LOADING_CIRCLE_NOTCH;
      break;
    default:
      iconTypeClass = '';
  }

  const sizeClass = size && ICON_SIZE_CLASS[size];

  let finaliconTypeClass = classNames(
    sizeClass,
    styles[colorClass],
    pointer ? styles.pointer : undefined
  );

  if (svgIcon) {
    const IconComp = iconTypeClass;
    return (
      <IconComp onClick={onClick} className={`${sizeClass} ${classIcon}`} color={colorClass} />
    );
  }

  if (!isExternalIcon) {
    finaliconTypeClass = `${iconTypeClass}  ${finaliconTypeClass}`;
  }

  if (classIcon) {
    finaliconTypeClass = `${classIcon} ${finaliconTypeClass}`;
  }

  if (isExternalIcon) {
    return (
      <AntIcon
        type={iconTypeClass}
        className={finaliconTypeClass}
        onClick={onClick}
        title={title}
      />
    );
  }

  let iconDisplay = (
    <i className={finaliconTypeClass} onClick={onClick} title={title} alt={title} />
  );

  if (inverse) {
    finaliconTypeClass = `${classIcon} ${finaliconTypeClass} fa-stack-1x fa-inverse`;
    iconDisplay = (
      <span className="fa-stack" onClick={onClick}>
        <i className="fa fa-circle fa-stack-2x" />
        <i className={finaliconTypeClass} />
      </span>
    );
  }

  if (circular) {
    finaliconTypeClass = `${finaliconTypeClass} fa-stack-1x`;
    iconDisplay = (
      <span className="fa-stack" onClick={onClick}>
        <i className={`fa fa-circle fa-stack-2x ${styles.white}`} />
        <i className={`fa fa-circle-thin fa-stack-2x ${styles.grey}`} />
        <i className={finaliconTypeClass} />
      </span>
    );
  }

  return iconDisplay;
}

CustomIcon.EXCLAIM_ICON = EXCLAIM_ICON;
CustomIcon.EXCLAIM_TRIANGE_ICON = EXCLAIM_TRIANGE_ICON;
CustomIcon.EXCLAIM_CIRCLE_ICON = EXCLAIM_CIRCLE_ICON;
CustomIcon.TPM_ICON = TPM_ICON;
CustomIcon.CALENDAR_ICON_O = CALENDAR_ICON_O;
CustomIcon.VISITORS_ICON = VISITORS_ICON;
CustomIcon.ERROR_ICON = ERROR_ICON;
CustomIcon.ANGLE_DOWN_ICON = ANGLE_DOWN_ICON;
CustomIcon.ANGLE_UP_ICON = ANGLE_UP_ICON;
CustomIcon.ANGLE_RIGHT_ICON = ANGLE_RIGHT_ICON;
CustomIcon.CLOSE_ICON = CLOSE_ICON;
CustomIcon.CLONE_ICON = CLONE_ICON;
CustomIcon.CHECK_CIRCLE_ICON = CHECK_CIRCLE_ICON;
CustomIcon.PLAY_CIRCLE_ICON = PLAY_CIRCLE_ICON;
CustomIcon.HELP_ICON = HELP_ICON;
CustomIcon.URSA_MAJOR_ICON = URSA_MAJOR_ICON;
CustomIcon.ROCKET_ICON = ROCKET_ICON;
CustomIcon.LINKEDIN_ICON = LINKEDIN_ICON;
CustomIcon.SIMPLE_ROCKET_ICON = SIMPLE_ROCKET_ICON;
CustomIcon.SCHEDULE = SCHEDULE;
CustomIcon.NEW_FEATURE = NEW_FEATURE;
CustomIcon.SEGMENT_ICON = SEGMENT_ICON;
CustomIcon.HTML5_ICON = HTML5_ICON;
CustomIcon.ADBLOCKER_ICON = ADBLOCKER_ICON;
CustomIcon.CHECKCIRCLE_ICON = CHECKCIRCLE_ICON;
CustomIcon.CHECKCIRCLE_GREEN_ICON = CHECKCIRCLE_GREEN_ICON;
CustomIcon.SYNCING_ICON = SYNCING_ICON;
CustomIcon.EXPIRED_EXCLAMATION_ICON = EXPIRED_EXCLAMATION_ICON;
CustomIcon.SYNC_FAIL_ICON = SYNC_FAIL_ICON;
CustomIcon.UNSYNCED_FROM_ICON = UNSYNCED_FROM_ICON;
CustomIcon.BROKEN_IMAGE_ICON = BROKEN_IMAGE_ICON;
CustomIcon.FILTER_ICON = FILTER_ICON;
CustomIcon.SIXSENSE_CAMPAIGN_ICON = SIXSENSE_CAMPAIGN_ICON;
CustomIcon.INFO_ICON = INFO_ICON;
CustomIcon.FILE_ICON = FILE_ICON;
CustomIcon.ADDRESS_BOOK_ICON = ADDRESS_BOOK_ICON;
CustomIcon.ANGLE_DOUBLE_RIGHT_ICON = ANGLE_DOUBLE_RIGHT_ICON;
CustomIcon.STOP_ICON = STOP_ICON;
CustomIcon.EXCLAMATION_ICON = EXCLAMATION_ICON;
CustomIcon.FILES_ICON = FILES_ICON;
CustomIcon.EXTERNAL_ICON_LINK = EXTERNAL_ICON_LINK;
CustomIcon.LOADING_CIRCLE_NOTCH = LOADING_CIRCLE_NOTCH;
CustomIcon.BETA_ICON = BETA_ICON;
CustomIcon.FILE_CABINET = FILE_CABINET;
CustomIcon.ICON_TIME_CIRCLE = ICON_TIME_CIRCLE;

CustomIcon.propTypes = {
  size: PropTypes.oneOf(Object.values(ICON_SIZE)),
  classIcon: PropTypes.string,
  iconType: PropTypes.string,
  color: PropTypes.string,
  inverse: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
  circular: PropTypes.bool,
  pointer: PropTypes.bool,
};

CustomIcon.SIZE = ICON_SIZE;
CustomIcon.COLOR = COLORS;

export default CustomIcon;
