import React from 'react';
import styles from './CountDisplay.module.scss';
import PropTypes from 'prop-types';
import { Text } from 'v2-components';
import { COLORS } from '../../v2-styles/constants';

const CountDisplay = ({ children, className }) => (
  <Text color={COLORS.WHITE} className={`${styles.outer} ${className}`}>
    {children}
  </Text>
);

CountDisplay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
};

export default CountDisplay;
