import { getAsyncInjectors } from "store/utils";
import { PAGE_STATE_KEY, SEGMENT_SYNCS_ROUTE } from "./constants";

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: SEGMENT_SYNCS_ROUTE,
    name: "segment_syncs",
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require("./containers");
          const sagas = require("./ducks/sagas");
          const reducer = require("./ducks/reducers");
          injectReducer(PAGE_STATE_KEY, reducer.default);
          injectSagas(PAGE_STATE_KEY, sagas.default);
          cb(null, containers.SegmentSyncs);
        },
        "segmentSyncs"
      );
    },
  };
}
