import React, { useEffect } from 'react';
import { Field, useForm } from 'react-final-form';
import { DistributeField } from './DistributeField';
import { Text } from '@sixsense/core/components';
import { css } from 'styles/emotion';
import { pxToRem, ThemeColor } from '@sixsense/core/style';
import { reduce } from 'lodash';
import { commaSeparatedNumber } from '../../utils';
import { TFieldConfiguration } from './DistributeCreditDrawer';

const distributeTableStyle = {
  table: css({
    fontSize: pxToRem(13),
    width: '100%',
    minHeight: '15vh',
  }),
  headerRow: css({
    height: pxToRem(40),
    userSelect: 'none',
    border: `1px solid var(${ThemeColor.Gray.LIGHT_6})`,
    padding: `0 ${pxToRem(5)}`,
  }),
  footerRow: css({
    height: pxToRem(40),
    border: `1px solid var(${ThemeColor.Gray.LIGHT_4})`,
    background: `var(${ThemeColor.Gray.LIGHT_4})`,
  }),
  row: css({
    height: pxToRem(60),
    border: `1px solid var(${ThemeColor.Gray.LIGHT_4})`,
    ':hover': {
      background: `var(${ThemeColor.Gray.LIGHT_5})`,
      button: {
        display: 'block',
      },
    },
    button: {
      display: 'none',
    },
  }),
  cell: css({
    paddingLeft: `${pxToRem(1)}`,
    position: 'relative',
    '&:first-child': {
      paddingLeft: pxToRem(40),
    },
    '&:last-child': {
      paddingRight: pxToRem(40),
    },
    color: 'black',
  }),
  alignRight: css({
    textAlign: 'right',
  }),
};

type TDistributeTableProps = {
  fieldConfiguration: TFieldConfiguration[];
  updateLiveCreditBalance: (totalAppSumUpValue: number) => void;
}
const DistributeTable = (props: TDistributeTableProps) => {
  const { fieldConfiguration, updateLiveCreditBalance } = props;
  const form = useForm();
  const sumOfAppCreds = reduce(fieldConfiguration, (accSum, currField) => (
    accSum + (form.getState()?.values[currField.name] || 0)
  ), 0);
  useEffect(() => {
    updateLiveCreditBalance(sumOfAppCreds);
  }, [sumOfAppCreds]);
  return (
    <table className={distributeTableStyle.table}>
      <thead>
        <tr className={distributeTableStyle.headerRow}>
          <td className={[distributeTableStyle.cell, 'textGray'].join(' ')}>Application</td>
          <td
            className={[
              distributeTableStyle.cell,
              distributeTableStyle.alignRight,
              'textGray'].join(' ')}
          >Balance Credits</td>
        </tr>
      </thead>
      <tbody>
        {
          fieldConfiguration.map((config) => (
            <tr className={distributeTableStyle.row}>
              <td className={[distributeTableStyle.cell, 'textBlack w50'].join(' ')}>
                <Text className="my2" weight={'bold'}>
                  {config?.label}
                </Text>
              </td>
              <td className={[distributeTableStyle.cell, 'textBlack w50'].join(' ')}>
                <Field name={config.name} disabled={config.disabled}>
                  {(fieldProps) => (
                    <DistributeField {...fieldProps} />
                  )}
                </Field>

              </td>
            </tr>
          ))
        }
      </tbody>
      <tfoot>
        <tr className={distributeTableStyle.footerRow}>
          <td className={[distributeTableStyle.cell, 'textBlack'].join(' ')}>
            Total Balance Credits</td>
          <td
            className={[
              distributeTableStyle.cell,
              distributeTableStyle.alignRight,
              'textBlack'].join(' ')}
          >
            {commaSeparatedNumber(sumOfAppCreds)}</td>
        </tr>
      </tfoot>
    </table>
  );
};

export default DistributeTable;
