import React from 'react';
import { Flex, Text, Button } from '@sixsense/core/components';
import { css } from 'styles/emotion';
import vectorImg from '../images/vector_illustration.svg';

const styles = {
  generatingFailureBody: css({
    padding: '54px',
    textAlign: 'center',
  }),
  tryBtn: css({
    padding: '0px 20px !important',
    borderRadius: '4px !important',
    height: '28px !important',
  }),
};

type FileCorruptedErrorErrorProps = {
  excludeFilesGenerateSummary: any;
  corruptedFiles: any;
  updateSharedState: any;
  uploadedDocuments: any;
};

export const FileCorruptedError = ({
    excludeFilesGenerateSummary,
    corruptedFiles,
    updateSharedState,
    uploadedDocuments,
  }: FileCorruptedErrorErrorProps) => {
  const excludeFilesFromPayload = () => {
    const idsToRemove = new Set(corruptedFiles?.map((item) => item.id));
    const updateUploadedDocuments = uploadedDocuments?.map((item) => {
      if (idsToRemove.has(item.id)) {
        return { ...item, action: 'remove' };
      }
      return item;
    });
    updateSharedState('documents', updateUploadedDocuments);
  };

  const handleTryAgain = () => {
    excludeFilesFromPayload();
    excludeFilesGenerateSummary(true);
  };

  return (<Flex
    gap={20}
    direction={'column'}
    alignItems="center"
    className={styles.generatingFailureBody}
  >
    <img src={vectorImg} alt={'File Corrupted'} />
    <Text size={16} weight={600}>
        Corrupted Files
      </Text>
    <Text weight={400} style={{ marginTop: '-12px' }}>
      Would you like to proceed by excluding these corrupted files?
      {corruptedFiles?.map((file) => (
        <div key={file.file_name}>
          <Text>{file.file_name}</Text>
        </div>
      ))}
    </Text>
    <Flex gap={12}>
      <Button onClick={handleTryAgain} className={styles.tryBtn}>
          Exclude & Try Again
        </Button>
    </Flex>
  </Flex>);
};
