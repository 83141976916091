import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { TopLevelComponent } from 'HOCS';
import { Row, Text, Button, UserCondition } from 'v2-components';
import { browserHistory } from 'react-router';
import { loadOwner } from './modules';
import styles from './AccessDenied.module.scss';
import {
  isSettingsIframeSelect,
} from 'modules/user/selectors';

/**
 * Include this at any base container/route level to where a specific role/set
 * roles is not permitted
 * @param {string[]} forbidden the role(s) to whom access is denied
 *
 */
const AccessDenied = (props) => {
  const { owner, forbidden, accessDenied, isSettingsIframe, message } = props;

  return (
    <UserCondition accessDenied={accessDenied} permissions={forbidden}>
      <Row
        flexDirection={Row.FLEX_DIRECTION.COLUMN}
        className={styles.container}
        alignItems={Row.ALIGN_ITEMS.CENTER}
      >
        <i className={`fa fa-lock ${styles.icon}`} />
        {message ? (
          <Text className={styles.text}>
            {message}
          </Text>
        ) : (
          <React.Fragment>
            <Text className={styles.text}>
              You don't have permission to view this page
            </Text>
            <Text className={styles.text}>
              {`Please contact ${owner} for permission adjustments`}
            </Text>
          </React.Fragment>
        )}
        {!isSettingsIframe ? <Button onClick={() => browserHistory.push('/')} type={Button.PRIMARY}>
          Home
        </Button> : null}
      </Row>
    </UserCondition>
  );
};

AccessDenied.defaultProps = {
  accessDenied: true,
};

AccessDenied.propTypes = {
  forbidden: PropTypes.array,
  owner: PropTypes.any,
  accessDenied: PropTypes.bool,
  isSettingsIframe: PropTypes.bool,
  message: PropTypes.string,
};

const mapStateToProps = (state = {}) => ({
  owner: (state.accessDenied || {}).owner,
  loaded: (state.accessDenied || {}).loaded,
  isSettingsIframe: isSettingsIframeSelect(state),
});

export default compose(
  connect(mapStateToProps, { load: loadOwner }),
  TopLevelComponent,
)(AccessDenied);
