import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: '/activate/:token',
    name: 'Activate User',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const Containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('activateUser', reducer.activateUserReducer);
        injectSagas('activateUser', sagas.default);
        cb(null, Containers.Register);
      }, 'auth_activate');
    },
  };
}
