import { Flex } from '@sixsense/core/components';
import React, { useState } from 'react';
import { Button, Modal, Text, Loading } from 'v2-components';
import { normalizeKeyword, getUpdatedConfig, resetConfig } from '../../utils';
import { Keyword } from '../../types';
import { useOrg } from 'hooks';
import { maybePlural } from 'utils/utils';
import { deactivatedConfigState, keywordGroupSelectQueryState } from '../../state';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { actions as globalActions } from 'modules/global';
import { useSharedState, useRequest, useSetSharedValue } from '@sixsense/core';
import { NEW_GROUP } from '../../constants';
import { GroupSelect } from '../';

type Props = {
  onCancel: () => void;
  keywords: Keyword[];
  setCreateGroupVisible: (Boolean) => void;
};

const RestoreKeywordsModal = ({
  onCancel,
  keywords,
  setCreateGroupVisible,
}: Props) => {
  const [config, setConfig] = useSharedState(deactivatedConfigState);
  const keywordIds = config.selectedValues;
  const [loading, setLoading] = useState(false);
  const setGroupSelectQuery = useSetSharedValue(keywordGroupSelectQueryState);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const org = useOrg();
  const keywordTitle = `Restore ${keywordIds.length > 1 ? `${keywordIds.length} keywords` :
    keywords.find((kw) => kw.id === keywordIds[0])?.normalized_keyword}`;

  const onClose = () => {
    onCancel();
    setSelectedGroups([]);
    setGroupSelectQuery(undefined);
  };

  const request = useRequest();
  const dispatch = useDispatch();
  const restoreKeywords = async () => {
    const kwsToRestore = keywordIds.map((k) => keywords.find((kw) => k === kw.id));
    const keywordsBody = kwsToRestore.map((keyword) => ({
      id: keyword.id,
      keyword: keyword.keyword,
      normalized_keyword: normalizeKeyword(keyword.keyword),
      client_added: keyword.client_added,
      category: keyword.category,
      is_active: true,
      is_deleted: false,
    }));
    const restoreBody = {
      action: 'restore',
      keyword_ids: keywordsBody,
      config: {
        group_ids: selectedGroups,
      },
    };
    setLoading(true);
    try {
      await request(`organization/${org.id}/product_keyword/${0}/keyword/`, {
        method: 'PATCH',
        body: JSON.stringify(restoreBody),
      });
      dispatch(globalActions.showNotification(
        'success',
        `${maybePlural(keywordIds.length, 'Keyword')}
        restored successfully. Please note it may take up to 24 hours to see
        added keywords in analytics.`));
      const changePage = keywords.length === keywordIds.length;
      const updatedConfig = getUpdatedConfig(config);
      // Need to refresh to previous page if we delete the last group on that page.
      if (changePage && config.pagination.pageNumber !== 1) {
        updatedConfig.pagination.pageNumber = config.pagination.pageNumber - 1;
      }
      resetConfig(updatedConfig, setConfig);
    } catch (e) {
      let errorMessage = `There was an issue restoring your
        ${maybePlural(keywordIds.length, 'Keyword')}. Please try again later`;
      if (e.errorStatus === 400) {
        const { body } = e;
        errorMessage = body.detail;
      }
      dispatch(globalActions.showNotification('error', errorMessage));
    }
    setLoading(false);
    onClose();
  };

  const footer = (
    <Flex justifyContent="flex-end" style={{ padding: '10px 0' }}>
      <Button
        pointer
        onClick={() => {
          onClose();
        }}
      >
        Cancel
      </Button>
      <Button
        pointer
        className="aam-l--10"
        type={'primary'}
        disabled={loading}
        onClick={() => {
          restoreKeywords();
        }}
      >
        Restore
      </Button>
      {loading && (
        <Loading
          size="small"
          spinnerWidth={15}
          spinnerHeight={15}
          pageLevel={false}
          className="aam-l--10"
        />
      )}
    </Flex>
  );

  return (
    <Modal
      visible
      bodyStyle={{ padding: 0 }}
      footer={footer}
      maskClosable={false}
      closable={false}
    >
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9' }}>
        <Text type={Text.TYPE.TITLE} bold>{keywordTitle}</Text>
      </Flex>
      <Flex style={{ padding: '20px 30px' }} direction="column">
        <Text className="aam-b--20">
          Keywords will be restored in their respective product categories.
          You can also add them to a keyword group.
        </Text>
        <Text bold>Add to a keyword group (Optional)</Text>
        <GroupSelect
          groups={selectedGroups}
          setGroups={(val) => setSelectedGroups(val.filter((v) => v !== NEW_GROUP))}
          setCreateGroupModalVisible={setCreateGroupVisible}
          noCreate
        />
      </Flex>
    </Modal>
  );
};

export default RestoreKeywordsModal;
