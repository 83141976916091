import React, { useState } from 'react';
import { Icon } from 'v2-components';
import { css } from 'styles/emotion';
import { Card, Flex } from '@sixsense/core/components';
import Section from './Section';
import RBACPermissions from 'dev-tools/sections/RBACPermissions';
import { COLORS } from 'v2-styles/constants';
import RBACRoles from 'dev-tools/sections/RBACRoles';

const styles = {
  container: css({
    minWidth: 400,
    backgroundColor: '#fff',
    position: 'fixed',
    top: 40,
    left: 20,
    margin: 0,
    borderRadius: 4,
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.2)',
    overflow: 'hidden',
    zIndex: 9999,
  }),
  header: css({
    backgroundColor: COLORS.AA_6SENSE_GREEN,
  }),
  content: css({
    height: 500,
    overflow: 'auto',
  }),
  button: css({
    userSelect: 'none',
  }),
};

const DevTools = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => setVisible((lastVisible) => !lastVisible);

  return (
    <React.Fragment>
      <Icon
        type="bug_report"
        size="36px"
        color={visible ? Icon.COLORS.GREEN : Icon.COLORS.ERROR}
        pointer
        className={styles.button}
        onClick={toggleVisible}
      />
      {visible ? (
        <Card className={styles.container}>
          <Card.Header className={styles.header}>
            <Flex justifyContent="space-between" >
              <Card.Header.Title>
                Dev Tools
              </Card.Header.Title>
              <Icon type="close" size="24px" onClick={() => setVisible(false)} pointer />
            </Flex>
          </Card.Header>
          <div className={styles.content}>
            <Section title="RBAC Permissions">
              <RBACPermissions />
            </Section>
            <Section title="RBAC Roles">
              <RBACRoles />
            </Section>
          </div>
        </Card>
      ) : null}
    </React.Fragment>
  );
};

export default DevTools;
