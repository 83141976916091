import React from 'react';
import { Row, Text } from 'v2-components';
import { Flex, Button } from '@sixsense/core/components';
import { css } from 'styles/emotion';
import { Download } from '@sixsense/core/icons';
import PropTypes from 'prop-types';

const styles = {
  privacySection: css({
    padding: '18px 20px',
    width: '100%',
    background: '#FFFFFF',
    height: '100%',
    border: '1px solid #C6CACE',
    borderRadius: '2px',
  }),
  privacyText: css({
    marginTop: '8px',
  }),
  privacyBox: css({
    display: 'grid',
  }),
  optoutRow: css({
    marginTop: '18px',
  }),
  rowText: css({
    marginRight: '8px',
  }),
};

type HeaderComponentProps = {
  downloadCsv : () => {},
  enableDownload: boolean,
}

const TimeStamp = () => (
  <Text>
    *NOTE :
    <ul>
      <li>
        The table gets updated every Tuesday.
      </li>
      <li>
        This is applicable only if your existing 6sense plan has or ever had access to People data.
      </li>
    </ul>
  </Text>
);

const HeaderComponent = ({ downloadCsv, enableDownload } : HeaderComponentProps) => (
  <div className={styles.privacySection}>
    <Row>
      <Flex direction="column">
        <Text type="title" weight="bold">
          Opt-Out List
        </Text>
        <span className={styles.privacyText}>
          <Text>
            This is a list of persons who have requested that their personal
            data be removed from the 6sense contact database.
          </Text>
        </span>
        <span className={styles.privacyText}>
          <Text>
            You are required to review this list on a monthly basis. If you
            downloaded or exported from 6sense one or more contacts appearing on
            this list, you must remove any such contacts from your internal
            systems unless you have an independent lawful basis to possess and
            use the contacts’ personal information.
          </Text>
        </span>
      </Flex>
    </Row>
    <Flex
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className={styles.optoutRow}
    >
      <TimeStamp />
      <Button
        disabled={enableDownload}
        hierarchy="secondary"
        size="md"
        leadingBadge=""
        leadingIcon={Download}
        onClick={downloadCsv}
      >
        Download CSV
      </Button>
    </Flex>
  </div>
  );

HeaderComponent.props = {
  downloadCsv: PropTypes.func,
  enableDownload: PropTypes.bool,
};

export default HeaderComponent;
