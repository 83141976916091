import React from 'react';
import { Text } from 'v2-components';
import { css, cx } from 'styles/emotion';
import { pxToRem } from '@sixsense/core/style';
import { capitalize } from 'lodash';
import { CampaignFlightStatus, CampaignStatus as CampaignStatusProps } from '../types';
import { DRAFT } from '../constants';

const styles = {
  commonStatusStyles: css`
    border-radius: ${pxToRem(4)};
    font-weight: semibold;
    font-size: ${pxToRem(12)};
  `,
  Draft_campaign: css`
    background: #F7ECD4;
    border: solid 1px #F4DBA2;
    color: #D5980E;
  `,
  Deleted_campaign: css`
    background: #F6DBDB;
    border: solid 1px #F1B1B1;
    color: #E12C2C;
  `,
  Submitted_campaign: css`
    background: #DBEFDB;
    border: solid 1px #B2E2B2;
    color: #2EB62D;
  `,
  Error_campaign: css`
    background: #F6DBDB;
    border: solid 1px #F1B1B1;
    color: #E12C2C;
  `,
  Active_campaign: css`
    background-color: #E5F2FA;
    border: solid 1px #B2D9F2;
    color: #0082D4;
  `,
  Inactive_campaign: css`
    background: #F6DBDB;
    border: solid 1px #F1B1B1;
    color: #E12C2C;
  `,
  Ended_campaign: css`
    background: #EFF2F5;
    border: solid 1px #D6D9DB;
    color: #6E7E87;
  `,
};

type CampaignStatusType = typeof DRAFT | CampaignFlightStatus;

const CampaignStatus = (props: CampaignStatusProps) => {
  const { campaign_state, flight_state } = props;
  const campaignStatus: CampaignStatusType = campaign_state === 'draft' ? DRAFT
  : capitalize(flight_state);

  return (
    <Text
      className={
        cx(styles[`${campaignStatus}_campaign`],
          styles.commonStatusStyles,
          'aam-h--10 aap-a--5')}
    >
      {campaignStatus}
    </Text>
  );

};

export default CampaignStatus;
