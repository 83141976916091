import {
  AddKeywordsConfig,
  PageFilters,
  PaginationState,
  BulkAddToGroupConfig,
} from './types';
import { sanitizeCsvInput, maybePlural } from 'utils/utils';
import {
  keywordsConfigState,
  groupsConfigState,
  groupsKeywordsConfigState,
  deactivatedConfigState,
  addKwToGroupConfigState,
} from './state';
import { KEYWORDS, GROUPS, GROUP_KW_LIST, DEACTIVATED, KW_EXP, OTHER } from './constants';

/*
  Parses keywords from the input field in the Add Keywords modal.
  Splits keywords by new line characters, as well as sanitizes the input for
  special characters.
*/
export const parseKeywords = (value) => value.split(/\r?\n/)
  .map((keyword) => sanitizeCsvInput(keyword));

/*
  Normalizes keyword (taken from current keyword config utils)
*/
export const normalizeKeyword = (keyword) =>
  keyword.replace(/[`~!@#$%^&*()_|\s+\-=?;:'",.<>{}[\]\\/]/gi, '').toLowerCase();


type ConfigValidation = {
  status: 'VALID' | 'INVALID';
  error: string;
};

export const validateAddKeywordsConfig = (config: AddKeywordsConfig): ConfigValidation => {
  const uniqueKeywords = new Set(config.keywords.map((v) => normalizeKeyword(v)));
  const symbols = ['=', '+', '-', '@'];

  if (!config.product_id) {
    return { status: 'INVALID', error: 'Please select a product category.' };
  } else if (!config.category) {
    return { status: 'INVALID', error: 'Please select a keyword type.' };
  } else if (config.keywords.length === 0) {
    return { status: 'INVALID', error: 'Please add one or more keywords.' };
  } else if (config.keywords.filter((v) => v.length < 2).length > 0) {
    return { status: 'INVALID', error: 'All keywords must be at least 2 characters in length.' };
  } else if (config.keywords.filter((v) => symbols.some((r) => v.indexOf(r) >= 0)).length > 0) {
    return { status: 'INVALID', error: 'Keywords cannot contain special characters.' };
  } else if (config.keywords.filter((v) => v.length > 80).length > 0) {
    return { status: 'INVALID', error: 'All keywords must be at under 80 characters in length.' };
  } else if (uniqueKeywords.size !== config.keywords.length) {
    return {
      status: 'INVALID',
      error: 'You may not enter duplicate keywords, or variations of the same keyword.',
    };
  }
  return { status: 'VALID', error: '' };
};

export const validateBulkAddToGroupsConfig = (config: BulkAddToGroupConfig): ConfigValidation => {
  const uniqueKeywords = new Set(config.keywords.map((v) => normalizeKeyword(v)));
  if (config.keywords.length === 0) {
    return { status: 'INVALID', error: 'Please add one or more keywords.' };
  } else if (config.keywords.filter((v) => v.length < 2).length > 0) {
    return { status: 'INVALID', error: 'All keywords must be at least 2 characters in length.' };
  } else if (config.keywords.filter((v) => v.length > 80).length > 0) {
    return { status: 'INVALID', error: 'All keywords must be at under 80 characters in length.' };
  } else if (uniqueKeywords.size !== config.keywords.length) {
    return {
      status: 'INVALID',
      error: 'You may not enter duplicate keywords, or variations of the same keyword.',
    };
  } else if (config.keyword_groups.length === 0) {
    return { status: 'INVALID', error: 'Please select a group.' };
  }
  return { status: 'VALID', error: '' };
};

/*
  Generate query params for list request for keywords.
*/

export const getQueryParams = (filters: PageFilters, usingPagination: Boolean): string => {
  const sortOrderToString = { asc: '', desc: '-' };
  let params = '';
  for (const [key, value] of Object.entries(filters)) {
    if (key === 'sortBy') {
      params = params.concat(`&order_by=${sortOrderToString[value.order]}${value.column}`);
    } else if (key === 'filterBy' && filters.filterBy.length > 0) {
      params = params.concat(`&filter_by=${filters.filterBy.join(',')}`);
    } else if (value !== undefined && key !== 'dummyVar' && key !=='filterBy' && value !== 'all') {
      params = params.concat(`&${key}=${value}`);
    }
  }
  // Added because download errors out with any filters since url starts with '&' not '?'
  if (params.length && !usingPagination) {
    params = '?'.concat(params.substring(1));
  }
  return params;
};

/*
  Generate pagination params for list request for keywords.
*/

export const getPaginationParams = (config: PaginationState): string => {
  const offset = config.pageSize ? (config.pageSize * config.pageNumber) - config.pageSize : 0;
  return `?limit=${config.pageSize}&offset=${offset}`;
};


export const getConfigState = (pageKey, fromAddToGroup) => {
  if (pageKey === KEYWORDS && fromAddToGroup) {
    return addKwToGroupConfigState;
  }
  switch (pageKey) {
    case KEYWORDS:
      return keywordsConfigState;
    case GROUPS:
      return groupsConfigState;
    case GROUP_KW_LIST:
      return groupsKeywordsConfigState;
    case DEACTIVATED:
      return deactivatedConfigState;
    default:
      return null;
  }
};

export const genProductDetailText = (products, selectedProducts, keywords): string => {
  let detailStr = '';
  const numDeactive = keywords.reduce((count, kw) =>
    kw.keyword_products.length === selectedProducts.length ? count + 1 : count, 0);
  const productCounts = selectedProducts.reduce((acc, product) => {
    acc[product] = 0;
    return acc;
  }, {});
  for (const keyword of keywords) {
    for (const kw_prod of keyword.keyword_products) {
      if (kw_prod.product in productCounts) {
        productCounts[kw_prod.product]++;
      }
    }
  }
  for (const [key, value] of Object.entries(productCounts)) {
    const productName = products.find((p) => p.id.toString() === key)?.displayName;
    detailStr += `${value} ${maybePlural(value, 'keyword')} will be removed from 
      ${productName === KW_EXP ? OTHER : productName} product category. `;
  }
  if (numDeactive) {
    detailStr += `${numDeactive} ${maybePlural(numDeactive, 'keyword')} will be deactivated. `;
  }
  detailStr += 'Do you wish to continue?';
  return detailStr;
};

export const getUpdatedConfig = (config) => ({
  ...config,
  selectedValues: [],
  counter: config.counter + 1,
  isRefresh: true,
});

export const resetConfig = (config, setConfig) => {
  setConfig({ ...config });
};

export const dedupGroupOptions = (options) => {
  const uniqueIds = [];
  const returnedOptions = [];
  for (const option of options) {
    if (!uniqueIds.includes(option.value)) {
      uniqueIds.push(option.value);
      returnedOptions.push(option);
    }
  }
  return returnedOptions;
};
