/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Flex } from '@sixsense/core/components';
import { Button, Link } from 'v2-components';
import { User } from '../types';
import { css } from 'styles/emotion';
import { pxToRem } from '@sixsense/core/style';
import useUserActions from '../hooks/useUserActions';
import ConfirmModal from './ConfirmModal';
import { isAppEditUserPermissionMapSelector } from '../selectors';
import { BulkAction } from '../components/BulkAction';
import { BULK_ACTION_DEFAULT_FILTER, DUMMY_SELECT_VALUE } from '../constants';
import { cloneDeep, forOwn, forEach } from 'lodash';
import { useRequest } from '@sixsense/core/request';

import { useDispatch, useSelector } from '@sixsense/core/versioned/react-redux';
import { actions as globalActions } from 'modules/global';
import { actions } from '../modules';
import { orgSelector } from 'modules/user/selectors';


import { App } from '@sixsense/rbac';

const styles = {
  selectionText: css({
    color: '#001F32',
    fontSize: pxToRem(12),
    fontStyle: 'italic',
  }),
  link: css({
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: pxToRem(12),
  }),
  select: css({
    minWidth: 250,
    height: 32,
  }),
  dropdownCustom: css({
    maxHeight: '500px',
    overflow: 'auto',
  }),
};

type UserActionProps = {
  users: User[];
  selectedUsers: Set<number>;
  setSelectedUsers: (val: Set<number>) => void;
  isSAML: boolean;
};

const MultiUserActions = ({
  selectedUsers,
  users,
  setSelectedUsers,
  isSAML,
}: UserActionProps) => {
  const request = useRequest();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const canEditApps = useSelector(isAppEditUserPermissionMapSelector);
  const orgId = useSelector(orgSelector);

  const [visibleBulkActionDropdown, setVisibleBulkActionDropdown] =
    useState(false);

  // eslint-disable-next-line no-unused-vars
  const [filterApplied, setFilterApplied] = useState(
    cloneDeep(BULK_ACTION_DEFAULT_FILTER.values)
  );

  const { deleteUsers, reinviteUsers } = useUserActions();

  const { confirm, modalProps } = ConfirmModal.useConfirm();

  React.useEffect(() => {
    handleSaveAction();
  }, [filterApplied]);

  const postAPIAction = (type, msg) => {
    dispatch(globalActions.showNotification(type, msg));

    dispatch(actions.loadAllUsers());
  };

  const bulkApiCall = async (options, successMsg, shouldUnallocateCredits = false) => {
    try {
      await request(`popcorn/v1/organizations/${orgId}/users/`, options);
      postAPIAction('success', successMsg);
      if (shouldUnallocateCredits) {
        dispatch(
          actions.unallocateCreditUsers(
            users
              .filter(({ id }) => selectedUsers.has(id))
              .map(({ username }) => username)
          )
        );
      }
    } catch (e) {
      dispatch(actions.setBulkUpdateApiFailureLog(e));
      postAPIAction('error', 'Something went wrong. Please try again later.');
    }
  };

  const handleEnableAction = () => {
    const { roleFilter } = filterApplied;
    const organizationuser_ids = [];
    forEach(Array.from(selectedUsers), (id) => {
      organizationuser_ids.push(id);
    });

    const app_roles = [];
    let isAnyRoleSelected = false;
    forOwn(roleFilter, (value, key) => {
      if (isNaN(roleFilter[key])) {
        return;
      }
      if (roleFilter[key] === DUMMY_SELECT_VALUE) {
        return;
      }
      isAnyRoleSelected = true;
      if (roleFilter[key] === -1) {
        app_roles.push({
          app_id: +key,
          action: 'REMOVE',
        });
        return;
      }
      app_roles.push({
        app_id: +key,
        role_id: roleFilter[key],
        action: 'ADD',
      });
    });
    const body: any = {
      organizationuser_ids,
      is_active: true,
    };
    if (isAnyRoleSelected) {
      body.app_roles = app_roles;
    }
    const options: any = {
      method: 'PATCH',
      body: JSON.stringify(body),
    };
    const count = organizationuser_ids.length;
    bulkApiCall(
      options,
      isAnyRoleSelected
        ? `Successfully updated ${count} users.`
        : `Successfully enabled ${count} users.`,
    );
  };

  const handleDisableAction = () => {
    const organizationuser_ids = [];
    forEach(Array.from(selectedUsers), (id) => {
      organizationuser_ids.push(id);
    });

    const body = {
      organizationuser_ids,
      is_active: false,
    };
    const options: any = {
      method: 'PATCH',
      body: JSON.stringify(body),
    };
    const count = organizationuser_ids.length;
    bulkApiCall(options, `Successfully disabled ${count} users.`, true);
  };
  const handleNoActionSelection = () => {
    const { roleFilter } = filterApplied;
    const organizationuser_ids = [];
    forEach(Array.from(selectedUsers), (id) => {
      organizationuser_ids.push(id);
    });
    const app_roles = [];
    let isAnyRoleSelected = false;
    let shouldUpdateCredits = false;

    forOwn(roleFilter, (value, key) => {
      if (isNaN(roleFilter[key])) {
        return;
      }
      if (roleFilter[key] === DUMMY_SELECT_VALUE) {
        return;
      }
      isAnyRoleSelected = true;
      if (roleFilter[key] === -1) {
        app_roles.push({
          app_id: +key,
          action: 'REMOVE',
        });
        if ((+key) === (+App.SALES)) {
          shouldUpdateCredits = true;
        }
        return;
      }
      app_roles.push({
        app_id: +key,
        role_id: roleFilter[key],
        action: 'ADD',
      });
    });

    if (!isAnyRoleSelected) {
      return;
    }

    const body = {
      organizationuser_ids,
      app_roles,
    };
    const options: any = {
      method: 'PATCH',
      body: JSON.stringify(body),
    };
    const count = organizationuser_ids.length;
    bulkApiCall(
      options,
      `Successfully updated ${count} users.`,
      shouldUpdateCredits
    );
  };

  // eslint-disable-next-line no-unused-vars
  const handleSaveAction = () => {
    const { actionFilter } = filterApplied;
    switch (actionFilter) {
      case 'enable':
        handleEnableAction();
        break;
      case 'disable':
        handleDisableAction();
        break;
      case 'delete':
        confirm().then(() => {
          deleteUsers(Array.from(selectedUsers));
          dispatch(
            actions.unallocateCreditUsers(
              users
                .filter(({ id }) => selectedUsers.has(id))
                .map(({ username }) => username)
            )
          );
        });
        break;
      case 'reinvite':
        reinviteUsers(Array.from(selectedUsers));
        break;
      default:
        break;
    }
    if (actionFilter === DUMMY_SELECT_VALUE || !actionFilter) {
      handleNoActionSelection();
    }
  };

  return (
    <Flex alignItems="center">
      <span className={styles.selectionText}>
        {selectedUsers.size} of {users.length} selected
      </span>
      <div style={{ width: 8 }} />
      <Link
        className={styles.link}
        onClick={() => setSelectedUsers(new Set(users.map(({ id }) => id)))}
      >
        Select All
      </Link>
      <div style={{ width: 8 }} />
      <Link className={styles.link} onClick={() => setSelectedUsers(new Set())}>
        Clear Selection
      </Link>
      <div style={{ width: 8 }} />
      <div style={{ position: 'relative' }}>
        <Button
          type="primary"
          isHeaderButton
          onClick={() => {
            setVisibleBulkActionDropdown(true);
            setFilterApplied(BULK_ACTION_DEFAULT_FILTER.values);
          }}
        >
          Bulk Actions
        </Button>
        {visibleBulkActionDropdown ? (
          <BulkAction
            visibility={visibleBulkActionDropdown}
            changeVisible={setVisibleBulkActionDropdown}
            setFilterApplied={setFilterApplied}
            selectedUsers={selectedUsers}
            isSAML={isSAML}
            users={users}
          />
        ) : null}
      </div>
      <ConfirmModal {...modalProps} />
    </Flex>
  );
};

export default MultiUserActions;
