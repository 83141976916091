import React from 'react';
import Text from 'v2-components/Text';
import { formatDate } from 'utils/utils';
import { DATE_FORMAT } from 'utils/constants';
import { ROW_DATA_TYPES } from 'aa-components/CampaignCSVExportModal/constants';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';

export const LAST_UPDATED = {
  sortByKey: 'client_updated',
  key: 'last-updated',
  csvColumnKey: 'last_updated',
  title: <Label name={CAMPAIGN_LABELS.lastUpdated} />,
  dataIndex: 'client_updated',
  width: 150,
  render: (lastUpdated) => (<Text
    type={Text.TYPE.SUBBODY}
  >
    {formatDate(DATE_FORMAT)(lastUpdated)}
  </Text>),
  csvOverrides: {
    dataIndex: '',
    render: (dataObject, _, dataType) => {
      if (dataType === ROW_DATA_TYPES.aggregated) return '';

      if (dataType === ROW_DATA_TYPES.campaign) {
        return `"${formatDate(DATE_FORMAT)(dataObject.client_updated)}"`;
      }

      return `"${formatDate(DATE_FORMAT)(dataObject.updated)}"`;
    },
  },
};
