import { mapValues, omitBy, keys } from 'lodash';

const initialState = {
  formStatus: 'okay',
};

// File dropped => drop, validate, preupload
const DROP_FILE = 'UPLOAD2/UPLOAD_FILES';
const VALIDATE = 'UPLOAD2/VALID_FILE';
const STAGE = 'UPLOAD2/STAGE';
const REMOVE_FILE = 'UPLOAD2/REMOVE_FILE';
const dropFile = (uploadKey) => (newFile) => ({ type: DROP_FILE, newFile, uploadKey });
const validateFile = (uploadKey) => (file) => ({ type: VALIDATE, file, uploadKey });
const preUpload = (uploadKey) => () => ({ type: STAGE, uploadKey });
const removeFile = (uploadKey) => (fileKey) => ({ type: REMOVE_FILE, uploadKey, fileKey });

// Errors:
const DUPLICATE_FILE_NAME = 'UPLOAD2/DUPLICATE_FILE_NAME';
const INVALID_FILE_TYPE = 'UPLOAD2/INVALID_FILE_TYPE';
const EXCEED_FILE_LIMIT = 'UPLOAD2/EXCEED_FILE_LIMIT';
const VALIDATE_FILE_LIST = 'UPLOAD2/VALIDATE_FILE_LIST';
const invalidateFile = (uploadKey) => (file, type) => ({ type, uploadKey, file });
const invalidateFileList = (uploadKey) => (type) => ({ type, uploadKey });
const validateFileList = (uploadKey) => () => ({ type: VALIDATE_FILE_LIST, uploadKey });

// Config store
const SET_ATTACHMENT_TYPE = 'UPLOAD2/SET_ATTACHMENT_TYPE';
const SET_FILE_LIMIT = 'UPLOAD2/SET_FILE_LIMIT';
const SET_PRE_UPLOAD = 'UPLOAD2/SET_PRE_UPLOAD';
const SET_DEFAULT_FILES = 'UPLOAD2/SET_DEFAULT_FILES';
const setAttachmentType = (uploadKey) => (attachmentType) => ({
  type: SET_ATTACHMENT_TYPE,
  attachmentType,
  uploadKey,
});
const setFileLimit = (uploadKey) => (fileLimit) => ({
  type: SET_FILE_LIMIT,
  fileLimit,
  uploadKey,
});
const setPreUpload = (uploadKey) => (func) => ({ type: SET_PRE_UPLOAD, uploadKey, func });
/* Weird, but here uploadKey is not available if curried, so
   have to pass it directly to have access to it in the reducer */
const setDefaultFiles = () => (files, hackUploadKey) => (
  { type: SET_DEFAULT_FILES, hackUploadKey, files }
);

// Saga dispatched actions not curried uploadFile+uploadProgress not called from saga
const UPLOAD_FILE_REQUEST = 'UPLOAD2/UPLOAD_FILE_REQUEST';
const UPLOAD_FILE_SUCCESS = 'UPLOAD2/UPLOAD_FILE_SUCCESS';
const UPLOAD_FILE_FAILURE = 'UPLOAD2/UPLOAD_FILE_FAILURE';
const UPLOAD_PROGRESS = 'UPLOAD2/UPLOAD_PROGRESS';
const ABORT_FILE = 'UPLOAD2/ABORT_FILE';
const uploadFile = (uploadKey) => (file, fileKey, retryCount, preSignedUrlEndpoint,
  shouldPostOnPreSignedUrl) => ({
    type: UPLOAD_FILE_REQUEST,
    status: 'uploading',
    fileKey,
    file,
    uploadKey,
    loading: true,
    retryCount,
    preSignedUrlEndpoint,
    shouldPostOnPreSignedUrl,
  });
const uploadFileSuccess = (uploadKey, fileKey) => ({
  type: UPLOAD_FILE_SUCCESS,
  status: 'uploaded',
  fileKey,
  uploadKey,
  loading: false,
});
const uploadFileFailure = (errorMessage, errorStatus, uploadKey, fileKey) => ({
  type: UPLOAD_FILE_FAILURE,
  status: 'failed',
  errorMessage,
  fileKey,
  uploadKey,
  loading: false,
});
const uploadProgress = (uploadKey) => (fileKey, progress) => ({
  type: UPLOAD_PROGRESS,
  fileKey,
  progress,
  uploadKey,
});

const abortFile = (uploadKey) => (fileKey) => ({
  type: ABORT_FILE,
  status: 'aborted',
  fileKey,
  uploadKey,
});
const LOAD_S3URL_REQUEST = 'UPLOAD2/LOAD_S3URL_REQUEST';
const LOAD_S3URL_SUCCESS = 'UPLOAD2/LOAD_S3URL_SUCCESS';
const LOAD_S3URL_FAILURE = 'UPLOAD2/LOAD_S3URL_FAILURE';
const loadS3Url = (fileKey, uploadKey, retryCount = 0, preSignedUrlEndpoint) => ({
  type: LOAD_S3URL_REQUEST,
  loading: true,
  status: 'uploadingS3',
  fileKey,
  uploadKey,
  retryCount,
  preSignedUrlEndpoint,
});
const loadS3UrlSuccess = (s3Url, uploadKey, fileKey) => ({
  type: LOAD_S3URL_SUCCESS,
  s3Url,
  fileKey,
  uploadKey,
});
const loadS3UrlFailure = (errorMessage, errorObj, uploadKey, fileKey) => ({
  type: LOAD_S3URL_FAILURE,
  errorMessage,
  fileKey,
  uploadKey,
  errorObj,
});

// add request instance
const ADD_REQUEST_INSTANCE = 'UPLOAD2/ADD_REQ_INSTANCE';
const addRequestInstance = (uploadKey, fileKey, requestInstance) => ({
  type: ADD_REQUEST_INSTANCE,
  requestInstance,
  fileKey,
  uploadKey,
});

// abort upload file
const ABORT_UPLOAD_FILES = 'UPLOAD2/ABORT_UPLOAD_FILES';
const abortUploadingFiles = (uploadKey) => (uploadingS3Files) => ({
  type: ABORT_UPLOAD_FILES,
  uploadingS3Files,
  uploadKey,
});

// beforeupload saga
const PRE_UPLOAD = 'UPLOAD2/PRE_UPLOAD';
const PRE_UPLOAD_IN_PROGRESS = 'UPLOAD2/PRE_UPLOAD_IN_PROGRESS';
const PRE_UPLOAD_SUCCESS = 'UPLOAD2/PRE_UPLOAD_SUCCESS';
const PRE_UPLOAD_FAILURE = 'UPLOAD2/PRE_UPLOAD_FAILURE';
const preUploadRequest = (uploadKey) => (file, beforeUpload) => ({
  type: PRE_UPLOAD,
  status: 'preupload-pending',
  file,
  beforeUpload,
  fileKey: file.name,
  uploadKey,
});
const preUploadProgress = (uploadKey, fileKey) => ({
  type: PRE_UPLOAD_IN_PROGRESS,
  status: 'processing',
  fileKey,
  uploadKey,
});
const preUploadSuccess = (uploadKey, fileKey) => ({
  type: PRE_UPLOAD_SUCCESS,
  status: 'ready',
  fileKey,
  uploadKey,
});
const preUploadFailure = (errorMessage, uploadKey, fileKey) => ({
  type: PRE_UPLOAD_FAILURE,
  status: 'error',
  errorMessage,
  fileKey,
  uploadKey,
});

const RESET_UPLOAD = 'UPLOAD2/RESET_UPLOAD';
const resetUpload = (uploadKey) => () => ({
  type: RESET_UPLOAD,
  uploadKey,
});
/* todo:
  - figure out how to set error message on loadS3url failure
  - nested selectors
*/
const errorMessages = {
  [DUPLICATE_FILE_NAME]: 'You have attempted to upload a file with a name that already exists',
  [INVALID_FILE_TYPE]: 'You have attempted to upload an unsupported file type.',
  [EXCEED_FILE_LIMIT]: 'You have attempted to upload too many files.',
};
function uploadReducer2(state = initialState, action) {
  switch (action.type) {
    case DROP_FILE: {
      const { newFile, uploadKey } = action;
      const newFileKey = newFile.name;
      const currentFiles = keys(state).includes(uploadKey)
        ? state[uploadKey].fileList
        : {};

      return {
        ...state,
        [uploadKey]: {
          ...state[uploadKey],
          fileList: {
            ...currentFiles,
            [newFileKey]: newFile,
          },
        },
      };
    }
    case VALIDATE: {
      const { uploadKey, file } = action;
      const { fileList } = state[uploadKey];
      const validatedFile = fileList[file.name];

      return {
        ...state,
        [uploadKey]: {
          ...state[uploadKey],
          fileList: {
            ...fileList,
            [file.name]: {
              ...validatedFile,
              status: 'okay',
            },
          },
        },
      };
    }
    case PRE_UPLOAD:
    case PRE_UPLOAD_SUCCESS:
    case PRE_UPLOAD_IN_PROGRESS: {
      const { fileKey, status, uploadKey } = action;
      const { fileList } = state[uploadKey];
      const file = fileList[fileKey];
      return {
        ...state,
        [uploadKey]: {
          ...state[uploadKey],
          fileList: {
            ...fileList,
            [fileKey]: {
              ...file,
              status,
            },
          },
        },
      };
    }
    case PRE_UPLOAD_FAILURE: {
      const { uploadKey, fileKey, status, errorMessage } = action;
      const { fileList } = state[uploadKey];
      const erroredFile = fileList[fileKey];
      return {
        ...state,
        [uploadKey]: {
          ...state[uploadKey], // royal
          fileList: {
            ...fileList,
            [fileKey]: {
              ...erroredFile,
              status,
              errorMessage,
            },
          },
        },
      };
    }
    case DUPLICATE_FILE_NAME:
    case INVALID_FILE_TYPE: {
      const { uploadKey, file } = action;
      const { fileList } = state[uploadKey];
      const erroredFile = fileList[file.name];
      return {
        ...state,
        [uploadKey]: {
          ...state[uploadKey],
          fileList: {
            ...fileList,
            [file.name]: {
              ...erroredFile,
              status: 'error',
              errorMessage: errorMessages[action.type],
            },
          },
        },
      };
    }
    case EXCEED_FILE_LIMIT: {
      const { uploadKey } = action;
      const { fileList } = state[uploadKey];
      return {
        ...state,
        formStatus: errorMessages[action.type],
        [uploadKey]: {
          ...state[uploadKey],
          fileList: {
            ...fileList,
          },
        },
      };
    }
    case VALIDATE_FILE_LIST: {
      const { uploadKey } = action;
      const { fileList, fileLimit } = state[uploadKey];
      const fileCount = keys(fileList).length;
      const formStatus = fileCount <= fileLimit ? 'okay' : errorMessages[EXCEED_FILE_LIMIT];
      return {
        ...state,
        formStatus,
      };
    }
    case ADD_REQUEST_INSTANCE: {
      const { uploadKey, requestInstance, fileKey } = action;
      const { fileList } = state[uploadKey];
      const file = fileList[fileKey];
      return {
        ...state,
        [uploadKey]: {
          ...state[uploadKey],
          fileList: {
            ...fileList,
            [fileKey]: {
              ...file,
              requestInstance,
            },
          },
        },
      };
    }
    case LOAD_S3URL_REQUEST:
    case LOAD_S3URL_FAILURE:
    case UPLOAD_FILE_REQUEST: {
      const { uploadKey, status, fileKey, loading } = action;
      const { fileList } = state[uploadKey];
      const file = fileList[fileKey];
      return {
        ...state,
        [uploadKey]: {
          ...state[uploadKey],
          fileList: {
            ...fileList,
            [fileKey]: {
              ...file,
              status,
              loading,
            },
          },
        },
      };
    }
    case UPLOAD_FILE_SUCCESS: {
      const { uploadKey, status, fileKey, loading } = action;
      const { fileList } = state[uploadKey];
      const file = fileList[fileKey];
      return {
        ...state,
        [uploadKey]: {
          ...state[uploadKey],
          fileList: {
            ...fileList,
            [fileKey]: {
              ...file,
              status,
              loading,
            },
          },
        },
      };
    }
    case UPLOAD_FILE_FAILURE: {
      const { uploadKey, status, fileKey, errorMessage = null, loading } = action;
      const { fileList } = state[uploadKey];
      const file = fileList[fileKey];
      if (file) {
        return {
          ...state,
          [uploadKey]: {
            ...state[uploadKey],
            fileList: {
              ...fileList,
              [file.name]: {
                ...file,
                status,
                errorMessage,
                loading,
              },
            },
          },
        };
      }
      return {
        ...state,
      };
    }
    case LOAD_S3URL_SUCCESS: {
      // find better way to do this
      const { uploadKey, fileKey, s3Url } = action;
      const { fileList } = state[uploadKey];
      const file = fileList[fileKey];
      return {
        ...state,
        [uploadKey]: {
          ...state[uploadKey],
          fileList: {
            ...fileList,
            [file.name]: {
              ...file,
              s3Url,
            },
          },
        },
      };
    }
    case UPLOAD_PROGRESS: {
      const { uploadKey, fileKey, progress } = action;
      const { fileList } = state[uploadKey];
      const file = fileList[fileKey];
      return {
        ...state,
        [uploadKey]: {
          ...state[uploadKey],
          fileList: {
            ...fileList,
            [file.name]: {
              ...file,
              progress,
            },
          },
        },
      };
    }
    case ABORT_FILE: {
      const { uploadKey, status, fileKey } = action;
      const { fileList } = state[uploadKey];
      const file = fileList[fileKey];
      if (file) {
        return {
          ...state,
          [uploadKey]: {
            ...state[uploadKey],
            fileList: {
              ...fileList,
              [file.name]: {
                ...file,
                status,
              },
            },
          },
        };
      }
      return {
        ...state,
      };
    }
    case REMOVE_FILE: {
      const { uploadKey, fileKey } = action;
      const { fileList } = state[uploadKey];
      const remainingFiles = omitBy(fileList, (val, key) => key === fileKey) || {};
      return {
        ...state,
        [uploadKey]: {
          ...state[uploadKey],
          fileList: {
            ...remainingFiles,
          },
        },
      };
    }
    case SET_ATTACHMENT_TYPE: {
      const { attachmentType, uploadKey } = action;
      return {
        ...state,
        [uploadKey]: {
          ...state[uploadKey],
          attachmentType,
        },
      };
    }
    case SET_FILE_LIMIT: {
      const { uploadKey, fileLimit } = action;
      return {
        ...state,
        [uploadKey]: {
          ...state[uploadKey],
          fileLimit,
        },
      };
    }
    case SET_DEFAULT_FILES: {
      const { hackUploadKey, files } = action;
      const uploadedFiles = files.reduce((list, file) => ({
        ...list,
        [file.name]: { ...file, status: 'uploaded' },
      }), {});
      if (state[hackUploadKey]) {
        const { fileList = {} } = state[hackUploadKey];
        return {
          ...state,
          [hackUploadKey]: {
            ...state[hackUploadKey],
            fileList: {
              ...fileList,
              ...uploadedFiles,
            },
          },
        };
      }
      return {
        ...state,
        [hackUploadKey]: {
          ...state[hackUploadKey],
          fileList: {
            ...uploadedFiles,
          },
        },
      };
    }
    case SET_PRE_UPLOAD: {
      const { uploadKey } = action;
      const uploadKeyState = state[uploadKey];
      return {
        ...state,
        [uploadKey]: {
          beforeUpload: action.func,
          ...uploadKeyState,
        },
      };
    }
    case RESET_UPLOAD: {
      const { uploadKey } = action;
      const { formStatus } = initialState;
      return {
        ...state,
        formStatus,
        [uploadKey]: {
          ...state[uploadKey],
          fileList: {},
        },
      };
    }
    default:
      return state;
  }
}


export const actionTypes = {
  INVALID_FILE_TYPE,
  DUPLICATE_FILE_NAME,
  EXCEED_FILE_LIMIT,

  PRE_UPLOAD,
  PRE_UPLOAD_IN_PROGRESS,
  PRE_UPLOAD_SUCCESS,
  PRE_UPLOAD_FAILURE,

  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_REQUEST,
  LOAD_S3URL_REQUEST,
  LOAD_S3URL_SUCCESS,
  LOAD_S3URL_FAILURE,

  ABORT_UPLOAD_FILES,
};

export const actions = {
  dropFile,
  preUpload,
  invalidateFile,
  invalidateFileList,
  validateFile,
  validateFileList,
  removeFile,

  preUploadRequest,
  preUploadProgress,
  preUploadSuccess,
  preUploadFailure,

  uploadFile,
  uploadProgress,
  abortFile,
  uploadFileSuccess,
  uploadFileFailure,
  loadS3Url,
  loadS3UrlSuccess,
  loadS3UrlFailure,

  addRequestInstance,
  abortUploadingFiles,

  setPreUpload,
  setAttachmentType,
  setFileLimit,
  setDefaultFiles,
  resetUpload,
};


export const applyKey = (storeKey) => mapValues(actions, (action) => action(storeKey));

export default uploadReducer2;
