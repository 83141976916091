import React from "react";
import { Text } from "@sixsense/core/components";
import { COLUMN_CATEGORIES } from "../constants";
import { CAMPAIGN_SOURCE } from "../../../constants";
import { NA } from "./common/NA";
import { ROW_DATA_TYPES } from "aa-components/CampaignCSVExportModal/constants";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const PROVIDER = {
  category: COLUMN_CATEGORIES.externalMedia,
  key: "provider",
  csvColumnKey: "ad_provider_name",
  title: <Label name={CAMPAIGN_LABELS.adProviderName} />,
  dataIndex: "ad_provider_name",
  width: pxToRem(160),
  render: (adprovider, campaign) => {
    if (campaign.campaign_source !== CAMPAIGN_SOURCE.EXTERNAL) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {adprovider}
      </Text.Body>
    );
  },
  csvOverrides: {
    render: (adprovider, campaign, dataType) => {
      if (dataType === ROW_DATA_TYPES.aggregated) return "";

      if (campaign.campaign_source !== CAMPAIGN_SOURCE.EXTERNAL) {
        return "-";
      }

      return adprovider;
    },
  },
};
