import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { GenericCard, KeyPoint, Text, Link, Row, Col } from 'v2-components';
import { truncateString } from 'utils/utils';
import styles from './AccountCard.module.scss';
import { COLORS } from 'v2-styles/constants';

const DetailLabel = ({ label, value, keep }) => (
  <div className={`${styles[label.replace(' ', '')]} ${keep ? undefined : styles.centerValue}`}>
    {keep &&
      <Row>
        <Text type={Text.TYPE.SUBBODY} color={COLORS.GREY1}>
          {label}
        </Text>
      </Row>}
    <Row><Text>{value}</Text></Row>
  </div>
);

DetailLabel.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  keep: PropTypes.bool,
};

function AccountCard(props) {
  const {
    accountStats,
    detailStats,
    accountName,
    accountCountry,
    accountDomain,
    detailCard,
    mid,
    span,
    linked,
    reached,
    firstAccount,
    openInNewTab,
    className,
    loadInfluencedConversionsFormsList,
    influencedFormFillsCount,
    showInfluencedFormFills,
    hasUnifiedContacts,
    ...rest
  } = props;

  const accountNameColor = linked ? COLORS.BLUE : COLORS.GREY;
  const queryString = span ? `?span=${JSON.stringify(span)}` : '';

  let classText = `${styles.accountCard} ${detailCard && styles.detailCard}`;
  classText = className ? `${classText} ${className}` : classText;

  const statsDisplay = map(accountStats, (accountStat) => {
    const { label, flexBasis, value } = accountStat;
    return (
      <Col flexBasis={flexBasis} key={label}>
        <KeyPoint type={KeyPoint.ACCOUNT_STAT} label={label}>
          {value}
        </KeyPoint>
      </Col>
    );
  });

  const accountNameNode = (<Text type="title" color={accountNameColor}>
    {truncateString(accountName || accountDomain || 'N/A', 70)}
  </Text>);
  const accountNameLink = (
    <Row
      flexDirection={Row.FLEX_DIRECTION.COLUMN}
      justifyContent={Row.JUSTIFY_CONTENT.SPACE_BETWEEN}
    >
      {linked ? (
        <Link
          isInternal
          newWindow={openInNewTab}
          id={`link_todetail_${mid}`}
          link={`/discover/account/detail/${mid}/${queryString}`}

        >
          {accountNameNode}
        </Link>
      ) : accountNameNode}
      {detailCard
        ? null
        : (<Text color="grey1">
          {accountCountry} - {accountDomain}
        </Text>)}
    </Row>
  );

  return (
    <GenericCard bodyClass={styles.cardBody} className={classText} {...rest}>

      <Row alignItems={Row.ALIGN_ITEMS.CENTER} className={styles.row}>
        <Col flexBasis={'50%'} className={`${detailCard && styles.row}`}>
          {detailCard ? (
            <Row
              flexDirection={Row.FLEX_DIRECTION.COLUMN}
              justifyContent={Row.JUSTIFY_CONTENT.SPACE_BETWEEN}
              className={styles.row}
            >
              <Row className={styles.detailCardTitle}>
                {accountNameLink}
              </Row>
              <Row className={styles.detailStats}>
                {statsDisplay}
                {showInfluencedFormFills ? <Col key={'formFills'}>
                  <KeyPoint type={KeyPoint.ACCOUNT_STAT} label={'Influenced Form Fills'}>
                    {(influencedFormFillsCount && hasUnifiedContacts) ? <Link
                      onClick={() =>
                      loadInfluencedConversionsFormsList({
                        mid,
                        campaignOrAccountName: accountName,
                      })}
                    >{influencedFormFillsCount}</Link>: influencedFormFillsCount}
                  </KeyPoint>
                </Col>: null}
              </Row>
            </Row>
          ) : (
            <Row>
              <Col flexBasis={'25%'} className={styles.accountName}>
                {accountNameLink}
              </Col>
              {statsDisplay}
              {showInfluencedFormFills ? <Col key={'formFills'}>
                <KeyPoint type={KeyPoint.ACCOUNT_STAT} label={'Influenced Form Fills'}>
                  {(influencedFormFillsCount && hasUnifiedContacts) ? <Link
                    onClick={() =>
                      loadInfluencedConversionsFormsList({
                        mid,
                        campaignOrAccountName: accountName,
                      })}
                  >{influencedFormFillsCount}</Link>: influencedFormFillsCount}
                </KeyPoint>
              </Col>: null}
            </Row>
          )}
        </Col>

        {detailCard ?
          <Col flexBasis={'50%'} className={styles.detailSegment}>
            <div className={styles.detailGrid}>
              {detailStats.map(({ label, value }) => (
                <DetailLabel
                  key={label}
                  keep={firstAccount}
                  value={value}
                  label={label}
                />
              ))}
            </div>
          </Col>
          : null}
      </Row>
    </GenericCard>
  );
}

const NUMBER_STRING = [PropTypes.number, PropTypes.string];

AccountCard.propTypes = {
  className: PropTypes.string,
  accountName: PropTypes.string,
  accountCountry: PropTypes.string,
  accountDomain: PropTypes.string,
  accountStats: PropTypes.arrayOf(PropTypes.object),
  linked: PropTypes.bool,
  reached: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  mid: PropTypes.oneOfType(NUMBER_STRING),
  span: PropTypes.object,
  detailCard: PropTypes.bool,
  firstAccount: PropTypes.bool,
  detailStats: PropTypes.array,
  openInNewTab: PropTypes.bool,
  loadInfluencedConversionsFormsList: PropTypes.func,
  influencedFormFillsCount: PropTypes.number,
  showInfluencedFormFills: PropTypes.bool,
  hasUnifiedContacts: PropTypes.bool,
};

AccountCard.defaultProps = {
  linked: true,
  reached: false,
  detail: false,
  openInNewTab: false,
};

export default AccountCard;
