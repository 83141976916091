export default function createRoutes() {
  return {
    path: 'table_edit',
    name: 'table_edit',
    getComponent(nextState, cb) {
      require.ensure(
              [],
              (require) => {
                const containers = require('../../containers/TableEdit/containers');
                cb(null, containers.TableEdit);
              },
              'settings_standardization_prepete_table_edit'
            );
    },
  };
}

