import React from 'react';
import { Loading, Flex, Card, ResultBoundary } from '@sixsense/core/components';
import { KeywordFilters, KeywordTable, NoDataComponent } from '../components';
import { groupsKeywordsConfigState, groupKeywordsRequestState } from '../state';
import { useOrg } from 'hooks';
import { maybePlural } from 'utils/utils';
import { getQueryParams, getPaginationParams } from '../utils';
import { ResultStatus } from '@sixsense/core/shared/async-result/types';
import { useSharedState, useRequestValue, useSharedValue } from '@sixsense/core';
import { GROUP_KW_LIST, INTENT_TABLE_HEADERS, NO_MATCH } from '../constants';
import { useKeywordAccountsRequest } from '../hooks';

type Props = {
  keywordsList: any;
};

const TableDataContainer = ({ keywordsList }: Props) => {
  const [config, setConfig] = useSharedState(groupsKeywordsConfigState);
  const keywordAccountsResult = useKeywordAccountsRequest(keywordsList, config.spanFilter);

  return (
    <ResultBoundary
      result={keywordAccountsResult}
      renderLoading={() => <Loading.Spinner level="page" />}
    >
      {({ data }) => (
        <Card.Content
          style={{
            background: 'white',
            border: '1px solid #C6CACE',
            padding: '0px',
          }}
        >
          {keywordsList.results ? (
            <KeywordTable
              fromAddToGroup={false}
              requestData={(pageNumber, pageSize) =>
                setConfig({ ...config, pagination: { pageSize, pageNumber }, selectedValues: [] })}
              totalDataCount={keywordsList.count}
              pageSize={config.pagination.pageSize}
              pageOptions={[25, 50, 75, 100]}
              currentPage={config.pagination.pageNumber}
              dataset={keywordsList.results || []}
              pageKey={GROUP_KW_LIST}
              headers={INTENT_TABLE_HEADERS}
              keywordAccounts={data}
            />
          ) :
            <NoDataComponent
              filters={config.filters}
              noDataStr={'You have not added any keywords to this group yet. Click ' +
                '"Add Keywords to this Group" to add your Keywords.'}
              noMatchStr={NO_MATCH}
            />
          }
        </Card.Content>
      )}
    </ResultBoundary>
  );
};


const KeywordGroupKwList = () => {
  const config = useSharedValue(groupsKeywordsConfigState);
  const org = useOrg();
  const queryParams = getQueryParams(config.filters, true);
  const paginationParams = getPaginationParams(config.pagination);
  const keywordsResult = useRequestValue(
    groupKeywordsRequestState,
    {
      orgId: org.id,
      queryParams,
      paginationParams,
      counter: config.counter,
    }
  );

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <KeywordFilters
        label={`${keywordsResult.data?.count || 0}
          ${maybePlural(keywordsResult.data?.count || 0, 'Keyword')}`}
        multiLabel={`${config.selectedValues.length} Keywords selected`}
        disabled={keywordsResult.data?.status === ResultStatus.LOADING}
        allKeywords={keywordsResult.data?.results || []}
        keywordsCount={keywordsResult.data?.count || 0}
        pageKey={GROUP_KW_LIST}
      />
      <ResultBoundary
        result={keywordsResult}
        renderLoading={() => <Loading.Spinner level="page" />}
      >
        {({ data }) => <TableDataContainer keywordsList={data} />}
      </ResultBoundary>
    </Flex>
  );
};

export default KeywordGroupKwList;
