import React, { Fragment, useEffect } from 'react';
import { usePageTitle } from 'hooks';
import { Flex, Loading } from '@sixsense/core/components';
import PropTypes from 'prop-types';
import AppHeaderTitle from './AppHeaderTitle';
import { useAudienceWorkflowsListeners } from 'hooks/useABMMultiAppEventListeners';

const AudienceMultiAppEventHandler = ({ children }) => {
  const {
    appHeaderProps,
    mutliAppEventListener,
    audienceIframeLoading,
  } = useAudienceWorkflowsListeners();
  usePageTitle(<AppHeaderTitle {...appHeaderProps} />);
  useEffect(() => {
    window.addEventListener('message', mutliAppEventListener, false);
    return () => window.removeEventListener('message', mutliAppEventListener);
  }, []);

  return (
    <Fragment>
      {audienceIframeLoading &&
      <Flex className="w100 h100" alignItems="center" justifyContent="center">
        <Loading.Spinner size={50} />
      </Flex>}
      {children(audienceIframeLoading)}
    </Fragment>);
};
AudienceMultiAppEventHandler.propTypes = {
  children: () => PropTypes.node,
};

export default AudienceMultiAppEventHandler;
