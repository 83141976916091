import { App, Auth } from 'containers';
import { NotFound } from 'v2-components';
import Discover from './Discover';
import Segments from './Segments';
import SegmentsAlpha from './SegmentsAlpha';
import Advertising from './Advertising';
import AdvertisingDS from './AdvertisingDS';
import AuthRoutes from './Auth';
import Settings from './Settings';
import Alerts from './Alerts';
import Orchestrations from './Orchestrations';
import AudienceWorkflows from './AudienceWorkflows';
import Reports from './Reports';
import Storybook from './Storybook';
import SmartFormFill from './SmartFormFill';
import { CAMPAIGN_REPORTS_ROUTE } from './Advertising/routes/Campaigns/routes/Reports/constants';
// eslint-disable-next-line max-len
import LinkedinAuthorizationDetailsConsumer from './Settings/routes/Integration/routes/SocialAdsIntegration/routes/LinkedinAdsIntegration/LinkedinAuthorizationDetailsConsumer/LinkedinAuthorizationDetailsConsumer';
import { CAMPAIGNS_ROUTE } from './Advertising/routes/Campaigns/constants';
import { ADVERTISING_BASE_ROUTE } from './AdvertisingDS/constants';
import HomePageDashboard from './HomePageDashboard';
// eslint-disable-next-line max-len
import GoogleAuthorizationDetailsConsumer from './Settings/routes/Integration/routes/SocialAdsIntegration/routes/GoogleAdsIntegration/GoogleAuthorizationDetailsConsumer/GoogleAuthorizationDetailsConsumer';
// eslint-disable-next-line max-len
import FacebookAuthorizationDetailsConsumer from './Settings/routes/Integration/routes/SocialAdsIntegration/routes/FacebookAdsIntegration/FacebookAuthorizationDetailsConsumer/FacebookAuthorizationDetailsConsumer';
import Saas from './Saas';

export function createAuthRoutes(store) {
  return [
    {
      path: '/',
      name: 'Auth',
      component: Auth,
      childRoutes: [
        AuthRoutes(store, true),
      ],
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound,
    },
  ];
}

export function createRoutes(store) {
  return [
    // mount linkedinpopup as top level route bcoz we don't want things like sidenav,header to show
    // also make any API calls that could slow down the opening of the route
    { component: LinkedinAuthorizationDetailsConsumer, path: '/linkedinpopup' },
    { component: GoogleAuthorizationDetailsConsumer, path: '/googleAuthorizationDetailsConsumer' },
    { component: FacebookAuthorizationDetailsConsumer,
      path: '/settings/integration/facebook-business-manager/redirect' },
    {
      path: '/campaigns/*',
      onEnter: ({ location }, replace) =>
        replace(`/${ADVERTISING_BASE_ROUTE}${location.pathname}${location.search}`),
    },
    {
      path: '/ad_library/*',
      onEnter: ({ location }, replace) =>
        replace(`/${ADVERTISING_BASE_ROUTE}${location.pathname}${location.search}`),
    },
    {
      path: '/segments-alpha/*',
      onEnter: ({ location }, replace) =>
        replace(location.pathname.replace('-alpha', '-beta')),
    },
    {
      // this route will be opened from email link and only downloads a file from s3
      // we don't want any API calls to slow down the opening of the route
      path: `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGN_REPORTS_ROUTE}/:id/download`,
      name: 'campaignReports',
      getComponent(nextState, cb) {
        require.ensure([], (require) => {
          const container = require(
            './Advertising/routes/Campaigns/routes/Reports/ReportDownload'
          ).default;

          cb(null, container);
        }, 'campaign_reports');
      },
    },
    {
      path: '/',
      name: 'Home',
      component: App,
      indexRoute: { onEnter: (nextState, replace) => replace('/discover') },
      childRoutes: [
        Discover(store),
        HomePageDashboard(store),
        Segments(store),
        SegmentsAlpha(store),
        Advertising(store),
        AdvertisingDS(store),
        Settings(store),
        Alerts(store),
        Orchestrations(store),
        AudienceWorkflows(store),
        Reports(store),
        SmartFormFill(store),
        {
          path: '/storybook',
          name: 'Storybook',
          component: Storybook,
        },
        {
          path: '/saas-frontend*',
          name: 'Saas',
          component: Saas,
        },
      ],
    },
    {
      path: '/auth',
      name: 'Auth',
      component: Auth,
      indexRoute: { onEnter: (nextState, replace) => replace('/login') },
      childRoutes: [
        AuthRoutes(store),
      ],
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound,
    },
  ];
}
