import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';

import styles from './DnDList.module.scss';


const Item = SortableElement(({ key, component }) => (
  <div className={styles.itemStyle} key={key} > {component}</div>
));

const DnDListComponent = SortableContainer(({ mappedOptions }) => {
  const listItems = [];
  mappedOptions.forEach((option, i) => {
    /* eslint-disable */
    listItems.push(
      <Item
        key={`item-${i}`}
        index={i}
        component={option}
        disabled={option.props.disabled}
      />
    );
  });
  /* eslint-enable */

  return (
    <div>
      {listItems}
    </div>
  );
}, { withRef: true });

class DnDList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mappedOptions: props.options,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.options) {
      this.setState({
        mappedOptions: nextProps.options,
      });
    }
  }

  render() {
    const { onSortEnd, scrollableId } = this.props;
    const currentContainer = scrollableId ? document.getElementById(scrollableId) : undefined;

    return (
      <DnDListComponent
        mappedOptions={this.state.mappedOptions}
        onSortEnd={onSortEnd}
        distance={1}
        helperClass={styles.sortableHelper}
        lockToContainerEdges
        getContainer={currentContainer ? () => currentContainer : undefined}
      />
    );
  }
}

// DnDList.defaultProps = {
//   scrollableId: 'appBody',
// };

DnDList.propTypes = {
  options: PropTypes.any,
  onSortEnd: PropTypes.func,
  scrollableId: PropTypes.bool,
};

export default DnDList;
