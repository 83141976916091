import React from 'react';
import { css } from 'styles/emotion';
import { Text } from 'v2-components';
import { PageFilters } from '../types';
import { useSharedValue } from '@sixsense/core';
import { Flex } from '@sixsense/core/components';
import { uniquePageFilters } from '../constants';
import EmptyState from 'images/Emptystate.svg';
import { orgProductState } from '../state';

const styles = {
  noDataContainer: css({
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '250px',
    paddingBottom: 20,
  }),
  noDataMsg: css({
    height: '50%',
    marginTop: 20,
    color: '#505C62',
  }),
};

type NoDataProps = {
  filters: PageFilters;
  noDataStr: string;
  noMatchStr: string;
};


const NoDataComponent = ({ filters, noDataStr, noMatchStr } : NoDataProps) => {

  const products = useSharedValue(orgProductState);

  const hasFilters = () => {
    for (const [key, value] of Object.entries(filters)) {
      if (key === 'product_id' && products.length < 2) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (value && value !== 'all' && value !== undefined && !uniquePageFilters.includes(key)) {
        return true;
      }
    }
    return false;
  };

  return (
    <Flex className={styles.noDataContainer}>
      <Flex direction="column" alignItems="center" style={{ width: '30%' }}>
        <img alt="No Data" src={EmptyState} width={100} />
        <Text className={styles.noDataMsg} type={Text.TYPE.SUBBODY}>
          {hasFilters() ? noMatchStr : noDataStr}
        </Text>
      </Flex>
    </Flex>
  );
};

export default NoDataComponent;
