import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import { Tooltip2 as Tooltip } from 'v2-components';
import styles from './OrchestrationIconStyles.module.scss';
import PF6senseLogo from './images/6sense-logo.svg';
import PFSalesforceLogo from './images/salesforce-logo.png';
import PFMarketoLogo from './images/marketo-logo.png';
import PFSalesloftLogo from './images/salesloft-logo.png';
import PFOutreachLogo from './images/outreach-logo.png';
import PFHubspotLogo from './images/logo-hubspot-o.svg';
import PFPardotLogo from './images/logo-pardot-o.png';
import PFEloquaLogo from './images/logo-eloqua-o.png';
import PFDynamicsLogo from './images/dynamics.png';
import { SYSTEMS } from 'routes/Orchestrations/constants';

const platformMap = {
  '6sense': PF6senseLogo,
  [SYSTEMS.SALESFORCE]: PFSalesforceLogo,
  [SYSTEMS.MARKETO]: PFMarketoLogo,
  [SYSTEMS.SALESLOFT]: PFSalesloftLogo,
  [SYSTEMS.OUTREACH]: PFOutreachLogo,
  [SYSTEMS.HUBSPOT]: PFHubspotLogo,
  [SYSTEMS.HUBSPOT_CRM]: PFHubspotLogo,
  [SYSTEMS.PARDOT]: PFPardotLogo,
  [SYSTEMS.ELOQUA]: PFEloquaLogo,
  [SYSTEMS.DYNAMICS]: PFDynamicsLogo,
};

const tooltipContainerStyle = { position: 'unset' };

const Platform = ({ className, size, system }) => (
  <Tooltip placement={'top'} overlay={startCase(system)} style={tooltipContainerStyle}>
    <img
      src={platformMap[system]} alt={`Platform ${system}`}
      className={`${styles.default} ${styles[size]} ${className}`}
    />
  </Tooltip>
);

Platform.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  system: PropTypes.string,
};

export default Platform;
