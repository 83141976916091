import React from 'react';
import { ResultBoundary } from '@sixsense/core/components';
import { appPoolRefetcherState, creditPoolConfigByPoolIdRequestState } from '../state';
import { EnabledAppsForCredits, TCreditConfig } from '../types';
import { useRequestValue, useSharedState } from '@sixsense/core';
import { CreditFetcherSkeleton } from './CreditFetcherSkeleton';
import { CreditErrorDisplay } from './CreditErrorDisplay';

const STRINGS = {
  FETCH_ERROR: 'Something went wrong while fetching credit pools. Please try again.',
};

type TCreditPoolsFetcherProps = {
  orgId: number,
  orgPoolId: number,
  enabledAppsForCreditDistribution: EnabledAppsForCredits,
  canDistributeCredits: boolean,
  canAllocateCreditsToSiUsers: boolean,

  children: (data: TCreditConfig[]) => React.ReactNode;
};
const CreditPoolsFetcher = ({
  orgId,
  orgPoolId,
  canDistributeCredits,
  canAllocateCreditsToSiUsers,
  enabledAppsForCreditDistribution,
  children }: TCreditPoolsFetcherProps) => {

  const [refreshOnChange] = useSharedState(appPoolRefetcherState);

  const creditPoolConfigResult = useRequestValue(
    creditPoolConfigByPoolIdRequestState(
      enabledAppsForCreditDistribution,
      canDistributeCredits,
      canAllocateCreditsToSiUsers,
    ),
    {
      orgId,
      orgPoolId,
      refreshOnChange,
    },
  );

  return (
    <ResultBoundary
      result={creditPoolConfigResult}
      renderLoading={() => <CreditFetcherSkeleton />}
      renderError={() => <CreditErrorDisplay message={STRINGS.FETCH_ERROR} />}
    >
      {({ data }) => children(data)}
    </ResultBoundary>
  );
};

export default CreditPoolsFetcher;
