import React from 'react';
import PropTypes from 'prop-types';
import { CustomIcon, TetheredSelect, Loading } from 'v2-components';
import { includes, isObject } from 'lodash';
import { COLORS } from 'v2-styles/constants';
import styles from './DropdownSearch.module.scss';

const { Option } = TetheredSelect;
const { SEARCH, LOADING_CIRCLE_NOTCH } = CustomIcon;

export default class DropdownSearch extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    handleSearchResultSelect: PropTypes.func,
    handleSearchResultDeselect: PropTypes.func,
    filterOption: PropTypes.func,
    placeholder: PropTypes.string,
    searchData: PropTypes.arrayOf(PropTypes.object),
    mapOption: PropTypes.string,
    itemClassName: PropTypes.string,
    dropdownClassName: PropTypes.string,
    handleInput: PropTypes.func,
    searchBar: PropTypes.bool,
    value: PropTypes.string,
    valueAccessor: PropTypes.string,
    onBlur: PropTypes.func,
    loading: PropTypes.bool,
    disableValuePassthrough: PropTypes.bool,
    mode: PropTypes.string,
    onFocus: PropTypes.func,
    iconColor: PropTypes.string,
    iconPlacement: PropTypes.string,
    inputClassName: PropTypes.string,
    autoFocus: PropTypes.bool,
    loaderColor: PropTypes.string,
  };

  static defaultProps = {
    iconPlacement: 'right',
    placeholder: '',
    mapOption: 'name',
    searchBar: false,
    mode: 'combobox',
    filterOption: (inputValue, dataValue) => includes(
      dataValue.key.toLowerCase(),
      inputValue.toLowerCase()
    ),
    handleInput: () => {},
  };

  constructor() {
    super();
    this.state = {
      isSelecting: false,
    };
  }

  componentDidMount = () => {
    /* antd doesn't add data-lpignore="true" to their non-username/password
    inputs, so since this component is the cause of a floating lastpass icon,
    we have to manually remove the icon from the dom.
    */
    const img = document.getElementById('__lpform_input_idx_0_icon');
    const imgNum = document.getElementById('__lpform_input_idx_0_numspan');
    if (img) {
      img.remove();
    }
    if (imgNum) {
      imgNum.remove();
    }
  }

  handleChange = (isSelecting) => {
    this.setState({ isSelecting });
  };

  voidFunc = () => null;

  render() {
    const {
      id,
      searchData,
      handleSearchResultSelect,
      handleSearchResultDeselect,
      filterOption,
      placeholder,
      mapOption,
      itemClassName,
      dropdownClassName,
      handleInput,
      searchBar,
      value,
      valueAccessor,
      onBlur,
      loading,
      disableValuePassthrough,
      mode,
      onFocus,
      iconColor,
      iconPlacement,
      inputClassName,
      loaderColor,
      autoFocus,
    } = this.props;

    function renderOptions(list) {
      return list.map(({ name, val }) => {
        let stringifiedVal = val;
        if (isObject(stringifiedVal)) {
          // I don't know why in the world rc-select
          // doesn't accept any value other than string,
          // hence this workaround so I can use objects
          stringifiedVal = JSON.stringify(stringifiedVal);
        }

        return (
          <Option
            className={`${styles.item} ${itemClassName}`}
            id={name}
            key={`${name}key`}
            value={stringifiedVal}
          >
            {name}
          </Option>
        );
      });
    }

    // Doing some horse shit here to get the box to clear when selecting,
    // problem being that onChange gets called after onSelect and both are asynchronous
    const selectHandleChange = this.state.isSelecting && !searchBar
      ? this.handleChange
      : this.voidFunc;

    let inputValue;
    if (searchBar) {
      inputValue = value;
    } else {
      inputValue = this.state.isSelecting ? '' : undefined;
    }

    const valueProp = {};
    if (!disableValuePassthrough) {
      /*
      Strange issue where passing undefined prop value is working as
      intended (as if the prop doesn't exist) in production but not in dev
      so let's make it actually not exist.
      */
      valueProp.value = inputValue;
    }

    const iconType = loading ? LOADING_CIRCLE_NOTCH : SEARCH;

    const customOnSelect = (selection) => {
      this.handleChange(true);
      let finalSelectionValue;
      // Now, let's go back to json object
      try {
        finalSelectionValue = JSON.parse(selection);
      } catch (e) {
        finalSelectionValue = selection;
      }
      handleSearchResultSelect(finalSelectionValue);
    };

    const customOnDeselect = (deselection) => {
      this.handleChange(false);
      let finalDeselectionValue;
      // Now, let's go back to json object
      try {
        finalDeselectionValue = JSON.parse(deselection);
      } catch (e) {
        finalDeselectionValue = deselection;
      }
      handleSearchResultDeselect(finalDeselectionValue);
    };

    const DisplayIcon = (
      <div className={styles.iconContainer}>
        {
          loading ?
            <Loading
              size="small"
              color={loaderColor}
              spinnerWidth={15}
              spinnerHeight={15}
              pageLevel={false}
            />
            :
            <CustomIcon
              classIcon={`${loading ? styles.loadingIcon : styles.icon}`}
              iconType={iconType}
              color={loading ? COLORS.BLUE : iconColor}
            />
        }
      </div>
    );

    return (
      <div className={styles.inputRow}>
        <div className={styles.inputContainer}>
          {iconPlacement === 'left' && DisplayIcon}
          <TetheredSelect
            id={`${id}_searchable_dropdown`}
            mode={mode}
            dropdownClassName={dropdownClassName}
            className={`${styles.dropdown} ${inputClassName}`}
            filterOption={filterOption}
            placeholder={placeholder}
            {...valueProp}
            onChange={(term) => {
              selectHandleChange(false);
              handleInput(term);
            }}
            onSelect={customOnSelect}
            onDeselect={customOnDeselect}
            onBlur={onBlur}
            onFocus={onFocus}
            autoFocus={autoFocus}
          >
            {renderOptions(searchData.map((record) => ({
              name: record[mapOption],
              val: valueAccessor ? record[valueAccessor] : record,
            })))}
          </TetheredSelect>
          {iconPlacement === 'right' && DisplayIcon}
        </div>
      </div>
    );
  }
}
