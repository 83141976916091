export default function createRoutes() {
  return {
    path: 'generativeai',
    name: 'generativeai',
    getComponent(nextState, cb) {
      import('./containers/GenerativeAI').then((page) => {
        cb(null, page.default);
      });
    },
  };
}
