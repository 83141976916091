import { zipper } from 'ast-redux/zipper';
import { meetsAllConditions } from 'aa-ast/base';
import { each, map, max } from 'lodash';

/* generic tree traversal utils */
const walk = (visitor, node, termCond = () => false) => {
  /*
  Start on leftmost node, postorder visit all nodes until root.
  or termCond is true. The node which the term condition
  returns true for WILL be visited
  */

  // Sensible message if misused
  if (!node) {
    throw new Error('Walk did not receive a tree');
  }

  let loc = zipper(node).leftmostDescendent();

  while (!loc.atEnd() && !termCond(loc)) {
    loc = visitor(loc);
    loc = loc.postorderNext();
  }

  // visit root node if not terminate early
  loc = visitor(loc);
  return loc.root();
};

const visitWith = (...visitors) => (loc) => {
  let newLoc = loc;
  each(visitors, (visitor) => {
    const [condition, func] = visitor;
    if (condition(newLoc)) {
      newLoc = func(newLoc, newLoc.node());
    }
  });
  return newLoc;
};

const collect = (node, ...conditions) => {
  // Collects expressions which satisfy conditions
  const collection = [];
  const visitors = [[
    meetsAllConditions(conditions),
    (loc, locNode) => {
      collection.push(locNode);
      return loc;
    },
  ]];

  walk(visitWith(...visitors), node);
  return collection;
};

const maxDepth = (ast) => { // ast = node
  const loc = zipper(ast);
  if (!ast || !loc.children()) {
    return 0;
  }

  const childDepths = map(loc.children(), ({ value: child }) => maxDepth(child)); // child = node
  return max(childDepths) + 1;
};

const returnFirst = (node, ...conditions) => {
  let firstNode;
  const visitors = [[
    meetsAllConditions(conditions),
    (loc, locNode) => {
      firstNode = locNode;
      return loc;
    },
  ]];

  walk(visitWith(...visitors), node, meetsAllConditions(conditions));
  return firstNode;
};

const safeGetInPath = (path, loc) => {
  let currentLoc = loc;
  for (const item of path) {
    currentLoc = currentLoc[item]();
    if (!currentLoc) {
      return null;
    }
  }
  return { loc: currentLoc, node: currentLoc.node() };
};

export {
  walk,
  visitWith,
  collect,
  maxDepth,
  returnFirst,
  safeGetInPath,
};
