export default function createRoutes() {
  return {
    path: 'custom-value-mappings',
    name: 'custom-value-mappings',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./../Manage/containers');
          cb(null, containers.ManageContainer);
        },
        'settings_custom_value_mapping'
      );
    },
  };
}
