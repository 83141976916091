/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { LOCATION_CHANGE } from 'react-router-redux';
import { createReducer as createCrossfilterReducer } from 'crossfilter-redux/modules';
import { createReducer as createAstReducer } from 'ast-redux';
import { createReducer as createSagaPollingReducer } from 'saga-polling';
import { FILTER_TYPES } from 'crossfilter-redux/constants';
import { applyFilter } from 'crossfilter-redux/utils';

import globalReducer from 'modules/global';
import userReducer from 'modules/user';
import segmentsReducer from 'modules/segments';
import labelsReducer from 'modules/labels';
import uploadReducer from 'OLD_components/Upload/modules';
import autoCompleteReducer from 'modules/autoComplete';
import fetchableReducer from 'modules/fetchable';
import authReducer from 'modules/auth';
import slackReducer from 'modules/slack';
import outreachReducer from 'modules/outreach';
import hubspotReducer from 'modules/hubspot';
import integrationsReducer from 'modules/integrations';
import uploadReducer2 from 'v2-components/Upload2/modules';
import searchReducer from 'containers/Navbar/modules';
import imageReducer from 'v2-components/Image/modules';
import accessDeniedReducer from 'v2-components/AccessDenied/modules';
import DynamicListReducer from 'v2-components/DynamicList/modules';
import GenericModalReducer from 'v2-components/GenericModal/modules';
import { reducer as sharedStateReducer } from '@sixsense/core/shared-state';
import { rbacReducers } from '@sixsense/rbac';
import campaignCSVExportReducer from 'aa-components/CampaignCSVExportModal/modules';
import {
  CAMPAIGN_CSV_EXPORT_MODAL_STATE_KEY,
} from 'aa-components/CampaignCSVExportModal/constants';
import {
  persistedCampaignListTimeframe,
  STATE_KEY as CAMPAIGN_LIST_TIMEFRAME,
} from './modules/persisted/campaignListTimeframe';
import { utmMacrosListStateGenerator } from 'aa-components/UTMBuilder/stateGenerators';
import { featureFlagsReducer } from '@sixsense/core/featureFlags';
// eslint-disable-next-line max-len
import campaignCSVDrawerExportReducer from './routes/AdvertisingDS/routes/Campaigns/containers/CampaignCSVExportDrawer/modules';
// eslint-disable-next-line max-len
import { CAMPAIGN_CSV_EXPORT_DRAWER_STATE_KEY } from './routes/AdvertisingDS/routes/Campaigns/containers/CampaignCSVExportDrawer/constants';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = {
  locationBeforeTransitions: null,
};

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */

    case LOCATION_CHANGE:
      return {
        ...state,
        locationBeforeTransitions: action.payload,
      };
    default:
      return state;
  }
}

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
  return combineReducers({
    crossfilterRedux: createCrossfilterReducer(FILTER_TYPES.EXACT, applyFilter),
    astRedux: createAstReducer(),
    sagaPolling: createSagaPollingReducer(),
    form: formReducer,
    route: routeReducer,
    global: globalReducer,
    user: userReducer,
    segments: segmentsReducer,
    labels: labelsReducer,
    upload: uploadReducer,
    autoComplete: autoCompleteReducer,
    fetchable: fetchableReducer,
    auth: authReducer,
    slack: slackReducer,
    outreach: outreachReducer,
    hubspot: hubspotReducer,
    integrations: integrationsReducer,
    upload2: uploadReducer2,
    globalSearch: searchReducer,
    images: imageReducer,
    accessDenied: accessDeniedReducer,
    DynamicList: DynamicListReducer,
    GenericModal: GenericModalReducer,
    sharedState: sharedStateReducer,
    [CAMPAIGN_CSV_EXPORT_MODAL_STATE_KEY]: campaignCSVExportReducer,
    [CAMPAIGN_CSV_EXPORT_DRAWER_STATE_KEY]: campaignCSVDrawerExportReducer,
    [CAMPAIGN_LIST_TIMEFRAME]: persistedCampaignListTimeframe,
    [utmMacrosListStateGenerator.stateKey]: utmMacrosListStateGenerator.reducer,
    ...rbacReducers,
    ...asyncReducers,
    ...featureFlagsReducer,
  });
}
