export const PATH_PREFIX = 'settings.si-activities';

export const ACTIVITY_TYPES = {
  attended_event: 'Attended Event',
  attended_meeting: 'Attended Meeting',
  attended_webinar: 'Attended Webinar',
  email_click: 'Clicked Email',
  anonymous_click: 'Clicked on Webpage(Anonymous User)',
  webpage_click: 'Clicked on Webpage(Known User)',
  form_fill: 'Filled out Form',
  email_open: 'Opened Email',
  anonymous_play: 'Played a video(Anonymous User)',
  registered_event: 'Registered for Event',
  email_reply: 'Replied to Email',
  kw_research: 'Researched Keyword',
  responded: 'Responded',
  anonymous_submit: 'Submitted (Anonymous User)',
  anonymous_web_unclassified: 'Unclassified Web Activities (Anonymous User)',
  unsubscribe: 'Unsubscribed',
  anonymous_web_visit: 'Visited Website (Anonymous User)',
  known_web_visit: 'Visited Website (Known User)',
  campaign: 'Added to Campaign',
  call: 'Called',
  left_voicemail: 'Left Voicemail',
  email_send: 'Sent Email',
  bombora_topic_surge: 'Bombora Company Surged topic',
  trust_radius: 'TrustRadius',
  positive_response: 'Positive Response',
  attended_online_event: 'Attended Online Event',
  trustradius_intent: 'TrustRadius',
  g2_intent: 'G2',
  peerspot_intent: 'PeerSpot',
  invited_to_meeting: 'Invited to meeting',
};
export const TOOLTIP_MSG = 'Reach out to admin to upgrade plan';

