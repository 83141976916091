import React from 'react';
// @ts-ignore
import snowflakeLogo from 'images/IntegrationLogos/snowflake-logo.svg';
import { FeaturedIcon } from '@sixsense/core/components';
import { LayersTwo, Database, Dataflow } from '@sixsense/core/icons';

export const SNOWFLAKE_REDUX_STATE_KEY:string = 'snowflake';
export const SNOWFLAKE_ROUTE:string = 'snowflake';
export const SNOWFLAKE_ACTION_BASESTRING:string = 'settings/integration/snowflake';
export const SNOWFLAKE_REFRESH_ACTIONNAME:string = 'settings/integration/snowflake/REFRESH';

export const SNOWFLAKE_ADD_DOMAINS_FORM_NAME = 'snowflake-add-domains';
export const SNOWFLAKE_BASIC_DETAILS_FORM_NAME = 'snowflake-basic-details';
export const SNOWFLAKE_CONFIGURATION_FORM_NAME = 'snowflake-configuration';

export const SnowflakeSummaryConfig:Object = {
  header: {
    logo: snowflakeLogo,
    mainTitle: 'Snowflake',
    category: 'Data Warehouse',
    subTitle: 'by Snowflake.inc',
  },
  about: {
    mainInfo: {
      title: 'About this Integration',
      description:
      'The 6sense Snowflake Integration allows your data team to easily '+
      'leverage industry-leading 6sense data directly in your Snowflake instance '+
      'to support internal reporting and modeling needs.',
    },
    metaInfo: [
      {
        name: 'Category',
        value: 'Data Warehouse',
      },
      {
        name: 'Feature Support',
        value: 'Internal Models, Dashboards & Reporting',
      },
    ],
    keyfeatures: {
      title: 'Key Features',
      features: [
        {
          icon: <FeaturedIcon theme="light-circle" icon={Database} color="Gray" />,
          name: 'Storage and Data Sets Unification',
          value: 'Using direct data pushed from 6sense to Snowflake',
        },
        {
          icon: <FeaturedIcon theme="light-circle" icon={LayersTwo} color="Gray" />,
          name: 'Build internal models, dashboard, and reports',
          value: 'Leveraging unified data sets',
        },
        {
          icon: <FeaturedIcon theme="light-circle" icon={Dataflow} color="Gray" />,
          name: 'Automate and Streamline Workflows',
          value: 'With direct integrations into Snowflake for internal reporting '+
          'and modeling, reducing operational overhead',
        },
      ],
    },
  },
  help: {
    links: [
      {
        title: 'Installation Guide',
        list: [
          {
            href: 'https://support.6sense.com/hc/en-us/articles/13761622095891',
            text: '6sense Snowflake Integrations',
          },
        ],
      },
    ],
  },
};

export const SNOWFLAKE_STATUSES = Object.freeze({
  ACTIVE: 'ACTIVE',
  ERROR: 'ERROR',
});

export const SNOWFLAKE_FORM_STEP_IDS = Object.freeze({
  CONNECT: 'connect',
  CONFIGURE: 'configure',
  DATA_SYNCS: 'data-syncs',
});

export const SNOWFLAKE_FORM_NAMES = Object.freeze({
  CONNECT: 'snowflake_connect',
  CONFIGURE: 'snowflake_configure',
  DATA_SYNCS: 'snowflake_data_syncs',
});
