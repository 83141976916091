import { getAsyncInjectors } from 'store/utils';
import { TARGET_AUDIENCE_STORE_KEY } from './containers/TargetAudienceForm/constants';
import {
  GLOBAL_INVENTORY_LIST_STATE_KEY,
} from './containers/InventoryList/components/GlobalListModal/constants';
import { VIEW_CAMPAIGN_DETAILS_ROUTE } from './constants';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: `${VIEW_CAMPAIGN_DETAILS_ROUTE}/:id`,
    name: 'createCampaign',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('createCampaign', reducer.default);
        injectSagas('createCampaign', sagas.default);

        const globalInventoryListSagas = require(
          './containers/InventoryList/components/GlobalListModal/sagas'
        );
        injectSagas(GLOBAL_INVENTORY_LIST_STATE_KEY, globalInventoryListSagas.default);

        const audienceSagas = require('./containers/TargetAudienceForm/sagas');
        const audienceReducer = require('./containers/TargetAudienceForm/modules');
        injectReducer(TARGET_AUDIENCE_STORE_KEY, audienceReducer.default);
        injectSagas(TARGET_AUDIENCE_STORE_KEY, audienceSagas.default);

        // All the crap for analytics. will be moved into its own subroute one day
        const analyticsSagas = require('../Analytics/sagas');
        const analyticsReducer = require('../Analytics/modules');
        injectReducer('campaignAnalytics', analyticsReducer.default);
        injectSagas('campaignAnalytics', analyticsSagas.default);
        // END
        // All the crap for Ad groups
        const adGroupsSagas = require('../AdGroups/sagas');
        const adGroupsReducer = require('../AdGroups/modules');
        injectReducer('adGroups', adGroupsReducer.default);
        injectSagas('adGroups', adGroupsSagas.default);
        // END
        cb(null, containers.CreateCampaign);
      }, 'campaign_create');
    },
  };
}
