import { SalesStageGroup, StageOption } from './types';

export const buildConfigState = (
  config: SalesStageGroup[],
  options: StageOption[]
): SalesStageGroup[] => {
  const usedKeys: Set<string> = new Set();
  for (const group of config) {
    for (const stage of group.stages) {
      usedKeys.add(`${stage.source}:${stage.stage}`);
    }
  }
  const unused: SalesStageGroup[] = options.filter(
    (opt) => !usedKeys.has(`${opt.source_value}:${opt.value}`)
  ).map((opt) => ({
    group_name: opt.label,
    stages: [
      { source: opt.source_value, stage: opt.value, name: opt.label, hidden: true },
    ],
  }));

  return [...config, ...unused];
};
