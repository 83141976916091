/* eslint-disable react-hooks/rules-of-hooks */
import { usePermissionCheck } from '@sixsense/rbac';
import {
  VIEW_ACTIONS_PERMISSIONS,
  EDIT_ACTIONS_PERMISSIONS,
} from '../../../../constants/featurePermissions';

export const useActionsReadonlyPermissions = () => {
  const isReadOnly = !usePermissionCheck(EDIT_ACTIONS_PERMISSIONS) &&
    usePermissionCheck(VIEW_ACTIONS_PERMISSIONS);
  return isReadOnly;
};

export const useActionsViewEditPermissions = () => {
  const hasSISettingsAccess = usePermissionCheck(EDIT_ACTIONS_PERMISSIONS) ||
  usePermissionCheck(VIEW_ACTIONS_PERMISSIONS);
  return hasSISettingsAccess;
};

