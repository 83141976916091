import LoginRoute from './routes/Login';
import PasswordResetRoute from './routes/PasswordReset';
import SetPasswordRoute from './routes/SetPassword';
import ActivateUserRoute from './routes/ActivateUser';
import PasswordUpdateRoute from './routes/PasswordUpdate';
import LoginGrowth from './routes/LoginGrowth';

export default function createRoutes(store, isLoginFlow=false) {
  return {
    path: '',
    name: 'Auth',
    indexRoute: {
      getComponent: isLoginFlow ? LoginRoute(store, isLoginFlow).getComponent: null,
    },
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const Containers = require('./containers');
        cb(null, Containers.Auth);
      }, 'auth');
    },
    childRoutes: [
      LoginRoute(store, isLoginFlow),
      LoginGrowth(store),
      PasswordResetRoute(store),
      SetPasswordRoute(store),
      ActivateUserRoute(store),
      PasswordUpdateRoute(store),
    ],
  };
}
