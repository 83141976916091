import React from 'react';
import styles from './CopyableSegmentId.module.scss';
import PropTypes from 'prop-types';
import Copyable from '../Copyable';
import { Text } from 'v2-components';

const CopyableSegmentId=(props) => (
  <Copyable
    copyText={props.segmentId}
    description={'Copy Segment ID'}
  >
    <Text className={styles.id}>
      {'ID: '}
    </Text>
    <Text className={styles.segmentId}>
      {props.segmentId}
    </Text>
  </Copyable>
);

CopyableSegmentId.propTypes = {
  segmentId: PropTypes.string,
};

export default CopyableSegmentId;
