export default function createRoutes() {
  return {
    path: 'manage',
    name: 'Manage',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          cb(null, containers.ManageContainer);
        },
        'manage'
      );
    },
  };
}
