import React from 'react';
import { css } from 'styles/emotion';
import { Button } from 'v2-components';
import { Flex } from '@sixsense/core/components';

const styles = {
  container: css({
    minHeight: 200,
  }),
  text: css({
    fontSize: 16,
    marginBottom: 8,
  }),
};

const ErrorDisplay = () => (
  <Flex className={styles.container} direction="column" alignItems="center" justifyContent="center">
    <div className={styles.text}>Something went wrong, refresh the page and try again.</div>
    <Button onClick={() => window.location.reload()}>Refresh</Button>
  </Flex>
);

export default ErrorDisplay;
