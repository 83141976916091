import { takeEvery, select, put, call } from 'redux-saga/effects';
import { fetchSaga } from 'store/sagas';
import { strings } from 'utils/constants';
import { orgSelector } from 'modules/user/selectors';
import { actionTypes, actions } from 'modules/fetchable';

const { FETCHABLE_LOAD_DATA } = actionTypes;
const { loadSuccess, loadFail } = actions;

function* loadOptions(request, action) {
  const { column } = action;
  try {
    const org = yield select(orgSelector);
    const endpoint = `org/${org}/fetchable/${column}/`;
    const { results, count } = yield call(request, endpoint);
    yield put(loadSuccess(results, column, count));
  } catch (e) {
    yield put(loadFail(column, strings.ERROR_500));
  }
}

function* watchLoadFetchable(request) {
  yield takeEvery(FETCHABLE_LOAD_DATA, loadOptions, request);
}

export default [fetchSaga(watchLoadFetchable)];
