import React from 'react';
import { SelectGroup } from 'v2-components';
import useAssignableRoleOptions from '../../../hooks/useAssignableRoleOptions';
import styles from './SingleRoleDropdown.module.scss';
import { classNames } from 'utils/utils';
import PropTypes from 'prop-types';
import { DUMMY_SELECT_VALUE } from '../../../constants';
import { refiningRoleOptions } from '../../../utils';


export const SingleRoleDropdownComponent = ({
  isSelectedUserPrimaryAdmin,
  disabled,
  value,
  onChange,
  appId,
  wrapperRef,
  moreOptions = [],
  isNoRoleAssign,
  isSelectRole,
  noRoleAssignValue = -1,
  selectRoleValue = DUMMY_SELECT_VALUE,
}) => {
  const roleAppOptionsFromHookMap =
    useAssignableRoleOptions.useAssignableRoleAppMapOptions({
      enableAdmin: false });
  const getRoleByAppOptions = () => {
    const roleAppOptions = [];
    if (isSelectRole) {
      roleAppOptions.push({
        label: 'Select Role',
        value: selectRoleValue,
      });
    }
    if (isNoRoleAssign) {
      roleAppOptions.push({
        label: 'No Role Assigned',
        value: noRoleAssignValue,
      });
    }
    const roleAppOptionsFromHook = refiningRoleOptions(
      roleAppOptionsFromHookMap[appId] || [], value
    );


    return [
      ...roleAppOptions,
      ...moreOptions,
      ...roleAppOptionsFromHook,
    ];
  };
  return (
    <SelectGroup
      width="100%"
      disabled={disabled || isSelectedUserPrimaryAdmin}
      options={getRoleByAppOptions(appId)}
      value={value}
      onChange={(roleId) => onChange(roleId, appId)}
      placeholder="Select a role"
      getPopupContainer={
        !!wrapperRef && (() => wrapperRef && wrapperRef.current)
      }
      containerClassName={classNames(styles.customStyleSelector)}
      useCustomOptions
      dropdownClassName={styles.dropdown}

    />
  );
};


SingleRoleDropdownComponent.propTypes = {
  moreOptions: PropTypes.any,
  isSelectedUserPrimaryAdmin: PropTypes.bool,
  disabled: PropTypes.bool,
  appId: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  wrapperRef: PropTypes.any,
  isNoRoleAssign: PropTypes.bool,
  isSelectRole: PropTypes.bool,
  noRoleAssignValue: PropTypes.any,
  selectRoleValue: PropTypes.any,
};
