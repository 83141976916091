import { combineReducers } from "redux";
import {
  adOptionsStateGenerator,
  createAdStateGenerator,
  creativesOptionsStateGenerator,
} from "./stateGenerators";

export const reducers = combineReducers({
  [createAdStateGenerator.stateKey]: createAdStateGenerator.reducer,
  [adOptionsStateGenerator.stateKey]: adOptionsStateGenerator.reducer,
  [creativesOptionsStateGenerator.stateKey]:
    creativesOptionsStateGenerator.reducer,
});
