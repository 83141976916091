import React from 'react';
import PropTypes from 'prop-types';

/* eslint max-len: [2, 1000] */
// Using 1000 for now.... might need to bump it up
export function Account(props) {
  if (props.color === 'white') {
    return (
      <svg {...props} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <title>Account</title>
        <g fill="#FFF" fillRule="evenodd">
          <path d="M760.038 1016H27.062C14.425 1016 6 1007.575 6 994.937v-505.5c0-8.425 4.213-14.743 12.637-18.956l480.226-216.943c4.212-2.106 10.53-2.106 14.743 0L766.356 342c8.425 2.106 14.744 10.53 14.744 18.956v633.982c0 12.637-8.425 21.062-21.063 21.062zM48.125 973.875h690.85V375.7l-231.688-80.037L48.126 502.075v471.8z" />
          <path d="M505.18 1016c-12.636 0-21.06-8.425-21.06-21.063V289.345c0-12.638 8.424-21.063 21.06-21.063 12.64 0 21.064 8.426 21.064 21.064v705.593c0 12.638-8.425 21.063-21.063 21.063zM100.78 594.75c-8.424 0-16.85-6.32-21.06-14.744-4.214-10.53 2.105-23.168 14.742-27.38L393.55 459.95c10.53-4.213 23.17 2.106 27.38 14.744 4.214 10.53-2.105 23.168-14.742 27.38L107.1 594.75h-6.32zM100.78 706.38c-10.53 0-18.955-6.317-21.06-16.85-2.107-10.53 4.21-23.167 16.85-25.274l299.086-63.187c10.53-2.108 23.17 4.21 25.275 16.85 2.108 10.53-4.21 23.168-16.85 25.274L104.995 706.38h-4.213zM100.78 815.906c-10.53 0-18.955-8.425-21.06-18.956-2.107-10.53 6.318-21.063 18.955-23.17l299.087-31.592c10.532-2.107 21.063 6.318 23.17 18.956 2.106 10.53-6.32 21.062-18.957 23.168l-299.088 31.594h-2.106zM399.87 927.538H100.78c-12.636 0-21.06-8.425-21.06-21.063 0-12.638 8.424-21.063 21.06-21.063h299.09c12.636 0 21.06 8.425 21.06 21.063 0 12.637-8.424 21.063-21.06 21.063z" />
          <path d="M995.938 1016H673.68c-12.636 0-21.06-8.425-21.06-21.063 0-12.637 8.424-21.062 21.06-21.062h301.195v-831.97l-286.45-92.674-273.812 122.164v141.118c0 12.638-8.425 21.063-21.063 21.063-12.637 0-21.063-8.425-21.063-21.063V156.65c0-8.425 4.213-16.85 12.638-18.956L677.895 7.106C682.104 5 688.424 5 692.637 5l309.618 101.1c8.425 4.213 14.744 12.638 14.744 21.063v867.774c0 12.638-8.425 21.063-21.063 21.063z" />
          <path d="M869.563 293.556c-12.638 0-21.063-8.425-21.063-21.062V190.35c0-12.637 8.425-21.063 21.063-21.063 12.637 0 21.062 8.425 21.062 21.063v82.144c0 12.637-8.425 21.062-21.063 21.062zM869.563 440.994c-12.638 0-21.063-8.425-21.063-21.063V337.79c0-12.638 8.425-21.063 21.063-21.063 12.637 0 21.062 8.425 21.062 21.063v82.143c0 12.64-8.425 21.064-21.063 21.064zM869.563 590.538c-12.638 0-21.063-8.426-21.063-21.063v-84.25c0-12.638 8.425-21.063 21.063-21.063 12.637 0 21.062 8.425 21.062 21.063v84.25c0 12.637-8.425 21.063-21.063 21.063zM869.563 737.975c-12.638 0-21.063-8.425-21.063-21.063V634.77c0-12.64 8.425-21.064 21.063-21.064 12.637 0 21.062 8.425 21.062 21.063v82.143c0 12.637-8.425 21.062-21.063 21.062zM869.563 885.413c-12.638 0-21.063-8.426-21.063-21.063v-82.144c0-12.637 8.425-21.062 21.063-21.062 12.637 0 21.062 8.425 21.062 21.062v82.144c0 12.637-8.425 21.063-21.063 21.063z" />
        </g>
      </svg>
    );
  }
  return (
    <svg {...props} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <title>Account Icon</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M760.038 1016H27.062C14.425 1016 6 1007.575 6 994.937v-505.5c0-8.425 4.213-14.743 12.637-18.956l480.226-216.943c4.212-2.106 10.53-2.106 14.743 0L766.356 342c8.425 2.106 14.744 10.53 14.744 18.956v633.982c0 12.637-8.425 21.062-21.063 21.062zM48.125 973.875h690.85V375.7l-231.688-80.037L48.126 502.075v471.8z"
          fill="#B0BEC5"
        />
        <path
          d="M505.18 1016c-12.636 0-21.06-8.425-21.06-21.063V289.345c0-12.638 8.424-21.063 21.06-21.063 12.64 0 21.064 8.426 21.064 21.064v705.593c0 12.638-8.425 21.063-21.063 21.063z"
          fill="#B0BEC5"
        />
        <g fill="#109E96">
          <path d="M100.78 594.75c-8.424 0-16.85-6.32-21.06-14.744-4.214-10.53 2.105-23.168 14.742-27.38L393.55 459.95c10.53-4.213 23.17 2.106 27.38 14.744 4.214 10.53-2.105 23.168-14.742 27.38L107.1 594.75h-6.32zM100.78 706.38c-10.53 0-18.955-6.317-21.06-16.85-2.107-10.53 4.21-23.167 16.85-25.274l299.086-63.187c10.53-2.108 23.17 4.21 25.275 16.85 2.108 10.53-4.21 23.168-16.85 25.274L104.995 706.38h-4.213zM100.78 815.906c-10.53 0-18.955-8.425-21.06-18.956-2.107-10.53 6.318-21.063 18.955-23.17l299.087-31.592c10.532-2.107 21.063 6.318 23.17 18.956 2.106 10.53-6.32 21.062-18.957 23.168l-299.088 31.594h-2.106zM399.87 927.538H100.78c-12.636 0-21.06-8.425-21.06-21.063 0-12.638 8.424-21.063 21.06-21.063h299.09c12.636 0 21.06 8.425 21.06 21.063 0 12.637-8.424 21.063-21.06 21.063z" />
        </g>
        <path
          d="M995.938 1016H673.68c-12.636 0-21.06-8.425-21.06-21.063 0-12.637 8.424-21.062 21.06-21.062h301.195v-831.97l-286.45-92.674-273.812 122.164v141.118c0 12.638-8.425 21.063-21.063 21.063-12.637 0-21.063-8.425-21.063-21.063V156.65c0-8.425 4.213-16.85 12.638-18.956L677.895 7.106C682.104 5 688.424 5 692.637 5l309.618 101.1c8.425 4.213 14.744 12.638 14.744 21.063v867.774c0 12.638-8.425 21.063-21.063 21.063z"
          fill="#B0BEC5"
        />
        <path
          d="M869.563 293.556c-12.638 0-21.063-8.425-21.063-21.062V190.35c0-12.637 8.425-21.063 21.063-21.063 12.637 0 21.062 8.425 21.062 21.063v82.144c0 12.637-8.425 21.062-21.063 21.062zM869.563 440.994c-12.638 0-21.063-8.425-21.063-21.063V337.79c0-12.638 8.425-21.063 21.063-21.063 12.637 0 21.062 8.425 21.062 21.063v82.143c0 12.64-8.425 21.064-21.063 21.064zM869.563 590.538c-12.638 0-21.063-8.426-21.063-21.063v-84.25c0-12.638 8.425-21.063 21.063-21.063 12.637 0 21.062 8.425 21.062 21.063v84.25c0 12.637-8.425 21.063-21.063 21.063zM869.563 737.975c-12.638 0-21.063-8.425-21.063-21.063V634.77c0-12.64 8.425-21.064 21.063-21.064 12.637 0 21.062 8.425 21.062 21.063v82.143c0 12.637-8.425 21.062-21.063 21.062zM869.563 885.413c-12.638 0-21.063-8.426-21.063-21.063v-82.144c0-12.637 8.425-21.062 21.063-21.062 12.637 0 21.062 8.425 21.062 21.062v82.144c0 12.637-8.425 21.063-21.063 21.063z"
          fill="#B0BEC5"
        />
      </g>
    </svg>
  );
}

Account.propTypes = {
  color: PropTypes.string,
};
