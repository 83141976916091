import { findKey, memoize } from "lodash";
import { FEATURE_FLAGS_AND_IDENTIFIERS_MAPPING } from "./constants";

export const getFeatureFlagForIdentifier = memoize((identifier) =>
  findKey(FEATURE_FLAGS_AND_IDENTIFIERS_MAPPING, (identifiers) =>
    identifiers.includes(identifier)
  )
);

export const isFeatureFlagEnabledForOrg = ({
  orgFeatureFlags,
  featureIdentifier,
} = {}) => {
  const featureFlagName = getFeatureFlagForIdentifier(featureIdentifier);
  return (
    orgFeatureFlags[featureFlagName] === true ||
    orgFeatureFlags[featureFlagName] === undefined
  );
};
