export default function createRoutes() {
  return {
    path: 'componentsmetrics',
    name: 'componentsmetrics',
    getComponent(nextState, cb) {
      import('./containers/ComponentsAndMetrics').then((page) => {
        cb(null, page.default);
      });
    },
  };
}
