export default function createRoutes() {
  return {
    path: 'mappingrules',
    name: 'mappingrules',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('../../containers/RuleBasedMapping/containers');
        cb(null, containers.RuleBasedMappingContainer);
      }, 'settings_taxonomy_mappingrules');
    },
  };
}
