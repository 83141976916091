import React from 'react';
import styles from './Loading.module.scss';
import PropTypes from 'prop-types';
import { Row } from 'v2-components';

function Loading(props) {
  const {
    className,
    spinnerWidth = 50,
    spinnerHeight = 50,
    size,
    thin,
    color = 'blue',
    pageLevel = true,
  } = props;
  let strokeWidth = '4';
  let height;
  let width;
  let displayClass;
  switch (size) {
    case 'large': {
      height = '65px';
      width = '65px';
      break;
    }
    case 'small': {
      height = '20px';
      width = '20px';
      break;
    }
    default: {
      if (
        typeof spinnerHeight === 'string' &&
        typeof spinnerWidth === 'string'
      ) {
        height = spinnerHeight;
        width = spinnerWidth;
      } else {
        height = `${spinnerHeight}px`;
        width = `${spinnerWidth}px`;
      }
      break;
    }
  }
  if (thin) {
    strokeWidth = '2';
  }
  if (pageLevel) {
    displayClass = styles.pageLevel;
  }
  return (
    <Row
      justifyContent="center"
      alignItems="center"
      className={`${displayClass} ${className}`}
    >
      <svg
        className={styles.spinner}
        width={width}
        height={height}
        viewBox="0 0 66 66"
      >
        <circle
          className={`${styles.circle} ${styles[color]}`}
          fill="none"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    </Row>
  );
}

Loading.propTypes = {
  className: PropTypes.string,
  spinnerWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  spinnerHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['large', 'small']),
  thin: PropTypes.bool,
  color: PropTypes.oneOf(['blue', 'white', 'grey']),
  pageLevel: PropTypes.bool,
};

export default Loading;
