import { Flex, Loading } from '@sixsense/core/components';
import React, { useMemo } from 'react';
import { css } from 'styles/emotion';
import { Checkbox2, Icon, ShowHideUnder } from 'v2-components';
import { GENERIC, BRANDED } from '../constants';
import { CategoryDescription } from '../components';
import { AAColor } from '@sixsense/core/style';

const styles = {
  selected: css({
    color: '#0083D4',
  }),
  unselected: css({
    backgroundColor: '#DBF4F3',
    display: 'inline-block',
    padding: '0 10px',
    lineHeight: '40px',
  }),
  disabled: css({
    color: '#001F34',
  }),
  wrapper: css({
    display: 'inline-block',
  }),
  icon: css({
    marginLeft: 5,
  }),
  menuContainer: css({
    background: '#fff',
    fontSize: 14,
    padding: '8px 0',
    lineHeight: '20px',
    color: '#001F32',
    fontWeight: 'normal',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.2)',
    maxHeight: 400,
    minWidth: '100%',
    width: '250px',
    zIndex: 20,
  }),
  menuItem: css({
    padding: '7px 20px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${AAColor.BLUE}`,
      color: `${AAColor.WHITE}`,
    },
  }),
};


export type MenuOptions<TValue> = {
  // eslint-disable-next-line react/no-unused-prop-types
  key?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  label: string;
  value: TValue;
}

type MenuProps<TValue> = {
  options: MenuOptions<TValue>[];
  compareFn?: (v1: TValue, v2: TValue) => boolean;
  loading?: boolean;
} & ({
  multi: true;
  value?: TValue[];
  onChange: (val?: TValue[]) => void;
} | {
  multi: false;
  value?: TValue;
  onChange: (val?: TValue)=> void;
});

type Props<TValue> = {
  placeholder: string;
  disabled?: boolean;
  // eslint-disable-next-line no-undef
  renderValue?: (val: TValue) => React.ReactNode;
} & MenuProps<TValue>;

function Menu<TValue>({ options, multi, onChange, value, compareFn, loading }: MenuProps<TValue>) {

  const selectedIndicies = useMemo(() => {
    const selectedSet: Set<number> = new Set();
    options.forEach((opt, i) => {
      if (multi) {
        (value || []).forEach((val) => {
          if (compareFn(opt.value, val)) {
            selectedSet.add(i);
          }
        });
      } else if (compareFn(opt.value, value)) {
        selectedSet.add(i);
      }
    });
    return selectedSet;
  }, [options, value, compareFn]);

  const handleChange = (nextValue: TValue, e: any) => {
    // If the user is selecting a category (branded/generic), but clicks
    // the 'learn more' option, we need to stop the value from being selected
    // and render the help modal instead. This here prevents that
    if (e.target.id === 'learn-more') {
      return;
    }

    if (multi) {
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      if ((value || []).find((val) => compareFn(val, nextValue))) {
        onChange([...(value || [])].filter((oldVal) => !compareFn(oldVal, nextValue)));
      } else {
        onChange([...(value || []), nextValue]);
      }
    } else {
      onChange(nextValue);
    }
  };

  const renderLabel = (option) => [BRANDED, GENERIC].includes(option.value) ?
    <CategoryDescription category={option.value} /> : option.label;

  return (
    <div>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" style={{ minHeight: 120 }}>
          <Loading.Spinner />
        </Flex>
      ) : options.map((option, i) => (
        <div
          key={option.key || option.label}
          className={styles.menuItem}
          onClick={(e) => handleChange(option.value, e)}
        >
          {multi ? (
            <Checkbox2
              label={option.label}
              size={Icon.SIZE.LARGE}
              checked={selectedIndicies.has(i)}
              onChange={() => {}}
            />
          ) : renderLabel(option)}
        </div>
      ))}
    </div>
  );
}

function Select<TValue>({
  value,
  placeholder,
  options = [],
  renderValue = (val) => options.find((opt) => opt.value === val)?.label || val,
  onChange,
  multi,
  disabled,
  compareFn = (v1, v2) => v1 === v2,
  loading,
}: Props<TValue>) {
  const hasValue = multi ? value && value.length : value !== undefined;
  let textStyle = styles.unselected;
  if (hasValue) {
    textStyle = styles.selected;
  }
  if (disabled) {
    textStyle = styles.disabled;
  }
  return (
    <span className={styles.wrapper}>
      <ShowHideUnder
        useDocument
        containerClassName={styles.menuContainer}
        Toggle={({ showFn }) => (
          <span
            className={textStyle}
            onClick={disabled ? undefined : showFn}
          >
            {hasValue ? renderValue(value) : placeholder }
            {hasValue ? null : (
              <Icon type="expand_more" size={Icon.SIZE.LARGE} className={styles.icon} />
            )}
          </span>
        )}
        ToShow={({ showFn }) => (
          <Menu
            options={options}
            value={value}
            onChange={multi ? onChange : (val) => {
              showFn();
              onChange(val);
            }}
            multi={multi}
            compareFn={compareFn}
            loading={loading}
          />
        )}
      />
    </span>
  );
}

export default Select;
