import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { userObjectSelector } from 'modules/user/selectors';
import { connect } from 'react-redux';

const MetaContext = createContext();
const { Provider, Consumer } = MetaContext;

/**
 * This is a context to pass around the user object
 *
 * The UserConsumer can be used when composing a component that will pass the user
 * object into the component's props
 *
 * You most likely don't need to do this and can use 'UserCondition' from v2-components
 * which will allows you to show or hide based on a particular flag
 */
const UserProvider = (props) => {
  const { user, children } = props;
  return (
    <Provider value={user}>
      {children}
    </Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.any,
  user: PropTypes.any,
};

const mapStateToProps = (state) => ({
  user: userObjectSelector(state),
});

const UserContext = connect(mapStateToProps)(UserProvider);

const UserConsumer = (WrappedComponent) => (props) => (
  <Consumer>
    {(user) => <WrappedComponent {...props} user={user} />}
  </Consumer>
);

export function useUser() {
  const user = useContext(MetaContext);
  return user;
}

UserContext.UserConsumer = UserConsumer;
UserContext.Consumer = Consumer;

export default UserContext;
