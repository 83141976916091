const DEFAULT_SPAN = {
  fixedRange: true,
  timeWindow: 'last_30_days',
  start_date: null,
  end_date: null,
};

const LIMIT = 5;

const KEYWORDS_DIMENSION = 'keyword';
const KEYWORDS_GROUP_DIMENSION = 'keyword_group';
const IN_CRM = 'Within CRM';
const NOT_IN_CRM = 'Not In CRM';

export {
  DEFAULT_SPAN,
  LIMIT,
  KEYWORDS_DIMENSION,
  KEYWORDS_GROUP_DIMENSION,
  IN_CRM,
  NOT_IN_CRM,
};
