import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { PageHeader } from 'HOCS';
import { AccessDenied } from 'v2-components';
import {
  Permission,
  withPermissionBoundary,
} from '@sixsense/rbac';
import CreditManagementHeader from '../components/CreditManagementHeader';
import { useRequestValue, useSharedState } from '@sixsense/core';
import { compose } from 'redux';
import CreditDetailsComponent from '../components/CreditDetails';
import { CREDIT_MSGS } from '../constants';
import {
  userRolesState,
  orgPoolSelectedState,
  orgCreditPoolState,
  dataCreditsUsageState,
} from '../state';
import { Alert, Flex, ResultBoundary, Text } from '@sixsense/core/components';
import {
  orgFlagsSelector,
  orgObjSelector,
} from 'modules/user/selectors';
import { CreditType, EnabledAppsForCredits } from '../types';
import {
  siPackagePlanNameSelector,
  canAllocateCreditsToSiUsersSelector,
  canDistributeCreditsSelector,
  enabledAppsForCreditDistributionSelector,
} from 'routes/Settings/selectors';
import { actions } from 'v2-components/Download/modules';
import DistributeCreditDrawer from '../components/Distribute/DistributeCreditDrawer';
import { MAINTENANCE_INFO } from 'routes/Settings/constants';
import AllocateCreditsByUserModal from '../components/Users/AllocateCreditsByUserModal';
import CreditPoolsFetcher from '../components/CreditPoolsFetcher';
import { CreditErrorDisplay } from '../components/CreditErrorDisplay';
import { ResultStatus } from '@sixsense/core/shared/async-result/types';
import { CreditFetcherSkeleton } from '../components/CreditFetcherSkeleton';

type Props = {
  org: any;
  orgFlags: any;
  siPackagePlanName: string;
  dl: any;
  canDistributeCredits: boolean;
  canAllocateCreditsToSiUsers: boolean;
  enabledAppsForCreditDistribution: EnabledAppsForCredits;
};

const CreditManagementContainer = ({
  org,
  orgFlags,
  siPackagePlanName,
  dl,
  canDistributeCredits,
  canAllocateCreditsToSiUsers,
  enabledAppsForCreditDistribution,
}: Props) => {
  const { id: orgId } = org;
  const userRoles = useRequestValue(userRolesState, { orgId });
  const [creditError, setCreditError] = useState('');

  const [dataCreditsUsageFlag, setdataCreditsUsageFlag] = useSharedState(
    dataCreditsUsageState
  );
  const [orgPoolSelected] = useSharedState<CreditType>(orgPoolSelectedState);
  const creditPoolConfigResult = useRequestValue(orgCreditPoolState, { orgId });
  const { data: creditPoolData = [], status: orgCreditPoolLoadingStatus } = creditPoolConfigResult;

  useEffect(() => {
    setdataCreditsUsageFlag(orgFlags.data_credits_usage_allowed);
  }, [orgFlags?.data_credits_usage_allowed]);

  useEffect(() => {
    if (
      orgCreditPoolLoadingStatus === ResultStatus.LOADED &&
      creditPoolData &&
      Array.isArray(creditPoolData) &&
      !creditPoolData.length
    ) {
      setCreditError(CREDIT_MSGS.CREDIT_NOT_ALLOTED);
    }
  }, [creditPoolData, orgCreditPoolLoadingStatus]);

  if (MAINTENANCE_INFO.with_read_only) {
    return <AccessDenied message={MAINTENANCE_INFO.message} />;
  }

  if (creditError) {
    return <CreditErrorDisplay message={creditError} />;
  }
  const hasInValidPoolSelection = orgPoolSelected?.id === 0;
  if (hasInValidPoolSelection) {
    return <CreditFetcherSkeleton />;
  }

  return (
    <ResultBoundary
      result={creditPoolConfigResult}
      renderLoading={() => <CreditFetcherSkeleton />}
      renderError={() => (
        <CreditErrorDisplay message={CREDIT_MSGS.CREDITS_ERR_FETCHING_DATA} />
      )}
    >
      {() => (
        <CreditPoolsFetcher
          orgId={orgId}
          orgPoolId={orgPoolSelected?.id}
          canDistributeCredits={canDistributeCredits}
          canAllocateCreditsToSiUsers={canAllocateCreditsToSiUsers}
          enabledAppsForCreditDistribution={enabledAppsForCreditDistribution}
        >
          {(creditConfig) => (
            <React.Fragment>
              <div>
                <Flex gap={8} direction="column">
                  {!dataCreditsUsageFlag && (
                    <Alert
                      align="center"
                      titleText={CREDIT_MSGS.CREDIT_USAGE_DISABLED}
                    />
                  )}
                  {creditConfig.map((config) => (
                    <Fragment>
                      <CreditDetailsComponent {...config} />
                      {creditConfig.length > 1 &&
                        config.appCreditType === 'global' && (
                          <Text size={'1.2rem'} weight={'bold'} className="my4">
                            {CREDIT_MSGS.CREDITS_BY_APPLICATION}
                          </Text>
                        )}
                    </Fragment>
                  ))}
                </Flex>
              </div>
              <AllocateCreditsByUserModal
                creditConfig={creditConfig}
                orgId={orgId}
                userRoles={userRoles?.data || []}
                dl={dl}
                planName={siPackagePlanName}
              />
              <DistributeCreditDrawer
                creditConfig={creditConfig}
                orgId={orgId}
                orgPoolId={orgPoolSelected?.id}
              />
            </React.Fragment>
          )}
        </CreditPoolsFetcher>
      )}
    </ResultBoundary>
  );
};

const mapStateToProps = (state) => ({
  org: orgObjSelector(state),
  orgFlags: orgFlagsSelector(state),
  siPackagePlanName: siPackagePlanNameSelector(state),
  canDistributeCredits: canDistributeCreditsSelector(state),
  canAllocateCreditsToSiUsers: canAllocateCreditsToSiUsersSelector(state),
  enabledAppsForCreditDistribution:
    enabledAppsForCreditDistributionSelector(state),
});

const CreditManagement = compose(
  withPermissionBoundary({
    allow: (permissions) =>
      permissions.has(Permission.SETTINGS_CREDITS_VIEW),
    renderDenied: () => <AccessDenied />,
  }),
  connect(mapStateToProps, { dl: actions.download }),
  PageHeader([CreditManagementHeader])
)(CreditManagementContainer);

export default CreditManagement;
