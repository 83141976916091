import { unset } from 'lodash';

const initialState = {};

const initialEntityState = {
  loading: false,
  loaded: false,
  error: false,
  options: [],
  count: 0,
  errorMessage: null,
};

const FETCHABLE_INITIALIZE = 'FETCHABLE/INITIALIZE';
const FETCHABLE_LOAD_DATA = 'FETCHABLE/LOAD_DATA';
const FETCHABLE_LOAD_DATA_SUCCESS = 'FETCHABLE/SUCCESS';
const FETCHABLE_LOAD_DATA_FAIL = 'FETCHABLE/ERROR';
const FETCHABLE_REFRESH = 'FETCHABLE/REFRESH';
const FETCHABLE_CLEAR = 'FETCHABLE/CLEAR';

function initialize(column) {
  return { type: FETCHABLE_INITIALIZE, column };
}

function loadOptions(column) {
  return { type: FETCHABLE_LOAD_DATA, column };
}

function loadSuccess(options, column, count) {
  return { type: FETCHABLE_LOAD_DATA_SUCCESS, options, column, count };
}

function loadFail(column, errorMessage) {
  return { type: FETCHABLE_LOAD_DATA_FAIL, column, errorMessage };
}

function refreshColumn(column) {
  return { type: FETCHABLE_REFRESH, column };
}

function clearColumn(column) {
  return { type: FETCHABLE_CLEAR, column };
}

function fetchableReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHABLE_INITIALIZE: {
      const { column } = action;
      return { ...state, [column]: { ...initialEntityState } };
    }
    case FETCHABLE_REFRESH: {
      // const { column } = action;
      // unset(state, column);
      return state;
    }
    case FETCHABLE_CLEAR: {
      const { column } = action;
      unset(state, column);
      return state;
    }
    case FETCHABLE_LOAD_DATA: {
      const { column } = action;
      const columnState = state[column];
      const newColumnState = { ...columnState, loading: true, options: [], count: 0 };

      return { ...state, [column]: newColumnState };
    }
    case FETCHABLE_LOAD_DATA_FAIL: {
      const { column, errorMessage } = action;
      const columnState = state[column];
      const newColumnState = {
        ...columnState,
        loading: false,
        error: true,
        loaded: true,
        errorMessage,
      };

      return { ...state, [column]: newColumnState };
    }
    case FETCHABLE_LOAD_DATA_SUCCESS: {
      const { column, options, count } = action;
      const columnState = state[column];
      const newColumnState = { ...columnState, loading: false, options, loaded: true, count };
      return { ...state, [column]: newColumnState };
    }
    default:
      return state;
  }
}

export const actions = {
  initialize,
  loadSuccess,
  loadOptions,
  loadFail,
  refreshColumn,
  clearColumn,
};

export const actionTypes = {
  FETCHABLE_LOAD_DATA,
};

export default fetchableReducer;
