/*
 * WAIT....Do you really need this? Probably not!
 * useState will likely handle your use case and is more readable
 * https://reactjs.org/docs/hooks-state.html
 * */
import React, { Component } from 'react';

const LocalState = (config) => (WrappedComponent) => {
  const {
    localState = {},
    changeToState = (c) => c,
  } = config;
  return class LocalStateClass extends Component {
    constructor(props, context) {
      super(props, context);
      this.state = localState;
    }

    handleChange(change) {
      this.setState(changeToState(change));
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          handleChange={this.handleChange.bind(this)}
        />
      );
    }
  };
};

export default LocalState;
