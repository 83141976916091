import { Record } from 'immutable';
import * as astClasses from './astClasses';
import { filtersetToAst } from './astFiltersetUtil';
import toAST from './evalApi';
import * as astIndex from 'index';
import { isNil } from 'lodash';

export const walkWith = (fn, expr) => {
  let type;
  if (expr instanceof Record) {
    type = expr.constructor.name;
  } else {
    type = expr.type;
  }

  switch (type) {
    case 'NullExpr':
    case 'ScanOp':
    case 'VarExpr':
    case 'Const':
      break;
    case 'SelectionOp':
      walkWith(fn, expr.relation);
      walkWith(fn, expr.bool_op);
      break;
    case 'ProjectOp':
      walkWith(fn, expr.relation);
      expr.exprs.forEach((e) => walkWith(fn, e));
      break;
    case 'AliasExpr':
    case 'Cast':
      walkWith(fn, expr.expr);
      break;
    case 'CaseWhen':
      expr.conditions.forEach((c) => {
        walkWith(fn, c.when);
        walkWith(fn, c.then);
      });
      break;
    case 'Func':
      expr.exprs.forEach((e) => walkWith(fn, e));
      break;
    case 'EqOp':
    case 'And':
    case 'Or':
    case 'ModelAnd':
    case 'ModelOr':
    case 'LtOp':
    case 'LeOp':
    case 'GeOp':
    case 'GtOp':
    case 'NeOp':
    case 'NotEqOp':
    case 'RegExpOp':
    case 'StartsWithOp':
    case 'ContainsOp':
    case 'NotContainsOp':
    case 'EndsWithOp':
    case 'RLikeOp':
    case 'IsNotOp':
    case 'IsOp':
    case 'LikeOp':
    case 'NotLikeOp':
    case 'RenameOp':
      walkWith(fn, expr.lhs);
      walkWith(fn, expr.rhs);
      break;
    case 'JoinOp':
      walkWith(fn, expr.left);
      walkWith(fn, expr.right);
      walkWith(fn, expr.bool_op);
      break;
    case 'BetweenOp':
      walkWith(fn, expr.ge_op);
      walkWith(fn, expr.le_op);
      walkWith(fn, expr.expr);
      break;
    case 'NotOp':
      walkWith(fn, expr.expr);
      break;
    case 'InOp':
      walkWith(fn, expr.expr);
      expr.exprs.forEach((e) => walkWith(fn, e));
      break;
    case 'UnionAllOp':
      walkWith(fn, expr.left);
      walkWith(fn, expr.right);
      break;
    case 'LeftJoinOp':
      walkWith(fn, expr.left);
      walkWith(fn, expr.right);
      walkWith(fn, expr.bool_op);
      break;
    default:
    // console.log(`Unknown ${expr.constructor.name}`);
  }
  return fn(expr);
};

export const getASTObject = (primaryTable, additionalObjects, fields, filterset) => {
  let relation = new astClasses.ScanOp(primaryTable);

  additionalObjects.forEach(({ table, joinType, joinConditions }) => {
    let boolOp = null;

    joinConditions.forEach(({ type, lhs, rhs }) => {
      let lhsOp = new astClasses.VarExpr(lhs);
      let rhsOp = new astClasses.VarExpr(rhs);

      if (!lhs) {
        lhsOp = new astClasses.Const('');
      }

      if (!rhs) {
        rhsOp = new astClasses.Const('');
      }

      const operation = new astClasses[type || 'EqOp'](lhsOp, rhsOp);
      if (!boolOp) {
        boolOp = operation;
      } else {
        boolOp = new astClasses.ModelAnd(boolOp, operation);
      }
    });
    relation = new astClasses[joinType || 'JoinOp'](relation, new astClasses.ScanOp(table), boolOp);
  });

  // Convert the fields to AST, validate this step once integrated.
  const metadata = {}; // dict for field mapping metadata { alias1: {}, alias2: {}, ...}
  const exprs = fields
    .map(({ mapping }) => {
      try {
        // For empty mappings
        if (isNil(mapping)) return null;

        // first validate
        const validatedAst = toAST(mapping.formula_ast);
        // then push metadata
        metadata[mapping.formula_ast.alias] = {
          formula_id: mapping.formula_id,
          is_complex_ast: mapping.is_complex_ast,
          mapping_status: mapping.mapping_status,
          mapping_source: mapping.mapping_source,
        };
        return validatedAst;
      } catch (e) {
        return null;
      }
    })
    .filter((mapping) => mapping); // filter out null mappings

  const filterOp = filtersetToAst(filterset);
  let targetAst = new astClasses.ProjectOp(relation, exprs);

  if (!(filterOp instanceof astClasses.NullExpr)) {
    targetAst = new astClasses.SelectionOp(targetAst, filterOp);
  }

  return [targetAst, metadata];
};

export const collect = (astExpr, ...types) => {
  const collection = [];
  walkWith(
    (expr) => (types.some((type) => expr instanceof type) ? collection.push(expr) : null),
    astExpr,
  );
  return collection;
};

export const getTablePrefix = (str) => (str.includes('.') ? str.split('.')[0] : null);

export const quotesAroundColumn = (col) => {
  const split = col.split('.');
  return `${split[0]}."${split[1]}"`;
};

export const buildSampleAST = (table, filters, sourceTables, colPrefixes, relation, boolOp) => {
  sourceTables.forEach((t) => {
    if (!colPrefixes.has(t)) {
      throw new Error(`Aborting sample: Source table ${t} has no selected columns`);
    }
  });

  colPrefixes.forEach((t) => {
    if (!sourceTables.includes(t)) {
      throw new Error(`Aborting sample: Invalid column source ${t}`);
    }
  });

  if (!table.column_names.length) {
    throw new Error('Aborting sample: Table has 0 columns');
  }

  if (
    !(relation instanceof astIndex.ScanOp) &&
    !(relation instanceof astIndex.JoinOp) &&
    !(relation instanceof astIndex.LeftJoinOp)
  ) {
    throw new Error('Aborting sample: Invalid source table configuration');
  }

  const projection = new astIndex.ProjectOp(
    relation,
    table.column_names.map(
      (el) => new astIndex.AliasExpr(new astIndex.VarExpr(quotesAroundColumn(el)), `"${el}"`),
    ),
  );

  let sourceAST;

  if (boolOp) {
    sourceAST = new astIndex.SelectionOp(projection, boolOp);
  } else {
    sourceAST = projection;
  }

  if (!(filters instanceof astIndex.NullExpr)) {
    return new astIndex.SelectionOp(sourceAST, filters);
  }

  return sourceAST;
};
