import {
  reduce,
  set,
  includes,
  find,
  map,
  has,
  omitBy,
  isNull,
  filter,
  isEmpty,
} from 'lodash';
import {
  CRM_LISTS_ERROR_TYPE,
  FORMATTED_DEPENDENCY_TEMPATES,
  PUBLISHED_OPTIONS,
  SEGMENT_FIELDS_TO_FETCH,
} from './constants';
import {
  SORT_UPDATED_DATE,
  SORT_CREATED_DATE,
  CREATED_BY,
  EDITED_BY,
  IN_CAMPAIGNS,
  TOTAL_SYNCED_TO_LINKEDIN,
  SORT_ACCOUNT_COUNT,
  SYNCED_TO_FACEBOOK,
  ACTIVELY_SYNCED_TO_LINKEDIN,
  ACTIVELY_SYNCED_TO_FACEBOOK,
  TOTAL_SYNCED_TO_GOOGLE_ADS,
  ACTIVELY_SYNCED_TO_GOOGLE_ADS,
} from 'modules/segments/constants';
import { createSelector } from 'reselect';
import {
  queryStringParamsSelector,
  routePathnameSelector,
} from 'modules/global/selectors';
import { orgSelector, userSelector } from 'modules/user/selectors';
import {
  selectedFoldersSelector,
  segFoldersSelector,
  segTagsSelector as globalTagSelector,
} from 'modules/segments/selectors';
import { permissionsSelector } from '@sixsense/rbac';
import { CAMPAIGN_PERMISSIONS } from '../../../../constants/featurePermissions';
import {
  CRM_ACCOUNT,
  CRM_REPORT,
  // FILE_UPLOAD,
  LOOKALIKE_EXTERNAL,
} from 'routes/Segments/constants';
import { AD_NETWORKS, SEGMENT_SYNC_AD_PROVIDER_KEY } from 'constants/campaigns';

const segmentsManageSelector = (state) => state.segmentsManage;

export const getSelectedSegmentSelector = createSelector(
  segmentsManageSelector,
  (segmentsManage) => segmentsManage.selectedSegments[0]
);

export const routeStateSelector = (state) => state.segmentsManage;

export const loadingModeSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.loadingMore,
);

const offsetSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.offset,
);

const pageSizeSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.pageSize,
);

export const paginationOffsetSelector = createSelector(
  [pageSizeSelector, offsetSelector],
  (pageSize, offset) => offset - pageSize
);

export const viewFilterSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.viewFilter,
);

export const segmentsSelector = createSelector(
  routeStateSelector,
  viewFilterSelector,
  userSelector,
  ({ segmentsList }, filterField, userId) => {
    switch (filterField) {
      case CREATED_BY:
      case EDITED_BY: {
        return filter(segmentsList, (s) => s[filterField] === userId);
      }
      case IN_CAMPAIGNS: {
        return filter(segmentsList, (s) => s[filterField] > 0);
      }
      default:
        return segmentsList;
    }
  },
);

export const totalSegmentCountSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.totalSegmentCount,
);

export const sortBySelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.sortBy,
);

export const searchSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.search,
);

export const selectedSegmentsSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.selectedSegments,
);

export const manageTagsToCreateSelector = createSelector(
  routeStateSelector,
  (routeState) => {
    const tags = routeState.manageTagsToCreate;
    tags.sort((a, b) => a.newName.toLowerCase() < b.newName.toLowerCase() ? -1 : 1);
    return tags;
  }
);

export const segTagsToCreateSelector = createSelector(
  routeStateSelector,
  (routeState) => {
    const tags = routeState.segTagsToCreate;
    tags.sort((a, b) => a.newName.toLowerCase() < b.newName.toLowerCase() ? -1 : 1);
    return tags;
  }
);

export const segTagsSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.segTagsManage,
);

export const manageTagsSelector = createSelector(
  [segTagsSelector, manageTagsToCreateSelector],
  (segTags, createTags) => {
    const manageTags = segTags.concat(createTags);
    const filteredTags = manageTags.filter((tag) => !tag.is_deleted);
    filteredTags.sort((a, b) => a.newName.toLowerCase() < b.newName.toLowerCase() ? -1 : 1);
    return filteredTags;
  }
);

export const dropDownUsersSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.dropDownUsers,
);

export const dropDownLoadingSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.dropDownLoading,
);

export const advancedSearchSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.advancedSearch,
);

export const tempAdvancedSearchSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.tempAdvancedSearch,
);

export const advancedSearchIdsSelector = createSelector(
  [advancedSearchSelector, globalTagSelector, segFoldersSelector],
  (advancedSearch, segTags, segFolders) => {
    const tempAdvancedSearch = { ...advancedSearch };
    tempAdvancedSearch.folders = tempAdvancedSearch.folders.map((folder) =>
      segFolders.filter((segFold) => segFold.name === folder)[0].id
    );
    tempAdvancedSearch.tags = tempAdvancedSearch.tags.map((tag) =>
      segTags.filter((segTag) => segTag.name === tag)[0].id
    );
    tempAdvancedSearch.created_by = tempAdvancedSearch.created_by.map((created) =>
      created.split(' ')[0]
    );
    tempAdvancedSearch.updated_by = tempAdvancedSearch.updated_by.map((updated) =>
      updated.split(' ')[0]
    );
    tempAdvancedSearch.published = tempAdvancedSearch.published.map((publish) =>
      Object.keys(PUBLISHED_OPTIONS).find((key) => PUBLISHED_OPTIONS[key] === publish)
    );
    return tempAdvancedSearch;
  }
);

export const endpointSelector = createSelector(
  [
    orgSelector,
    offsetSelector,
    pageSizeSelector,
    sortBySelector,
    searchSelector,
    selectedFoldersSelector,
    userSelector,
    advancedSearchIdsSelector,
  ],
  (
    orgId,
    offset,
    pageSize,
    sortBy,
    searchStr,
    selectedFolders,
    userId,
    advancedSearch
  ) => {
    let sortValue = sortBy;
    if (includes([SORT_UPDATED_DATE, SORT_CREATED_DATE, SORT_ACCOUNT_COUNT], sortValue)) {
      sortValue = `-${sortValue}`;
    }

    let endpoint =
      `org/${orgId}/segmentV2/?offset=${offset}&limit=${pageSize}` +
      `&ordering=${sortValue}&user_id=${userId}`;

    if (selectedFolders.length > 0) {
      endpoint = `${endpoint}&folder_id=${selectedFolders[0].id}`;
    }

    const {
      folders,
      tags,
      created_by,
      updated_by,
      activity,
      creation_type,
      published,
      untagged,
      broken,
    } = advancedSearch;

    if (broken.length !== 0) {
      endpoint = `${endpoint}&is_valid=${false}`;
    }
    if (folders.length !== 0) {
      endpoint = `${endpoint}&folder_id=${folders.join()}`;
    }
    if (tags.length !== 0 || untagged.length !== 0) {
      const tempTags = [...tags];
      if (untagged.length !== 0) {
        tempTags.push('Untagged');
      }
      endpoint = `${endpoint}&tag_id=${tempTags.join()}`;
    }

    if (created_by.length > 0) {
      endpoint = `${endpoint}&created_by_id=${created_by.join()}`;
    }

    // if (untagged.length !== 0) {
    //   debugger;
    //   endpoint = `${endpoint}&untagged=true`;
    // }

    if (updated_by.length > 0) {
      endpoint = `${endpoint}&edited_by_id=${updated_by.join()}`;
    }
    if (activity.includes(IN_CAMPAIGNS)) {
      endpoint = `${endpoint}&campaign_count_gt=0&user_id=${userId}`;
    }
    if (activity.includes(TOTAL_SYNCED_TO_LINKEDIN)) {
      endpoint = `${endpoint}&total_synced_to_ad_network=${AD_NETWORKS.linkedin}`;
    }
    if (activity.includes(SYNCED_TO_FACEBOOK)) {
      endpoint = `${endpoint}&total_synced_to_ad_network=${AD_NETWORKS.facebook}`;
    }
    if (activity.includes(ACTIVELY_SYNCED_TO_LINKEDIN)) {
      endpoint = `${endpoint}&actively_synced_to_ad_network=${AD_NETWORKS.linkedin}`;
    }
    if (activity.includes(ACTIVELY_SYNCED_TO_FACEBOOK)) {
      endpoint = `${endpoint}&actively_synced_to_ad_network=${AD_NETWORKS.facebook}`;
    }
    if (activity.includes(TOTAL_SYNCED_TO_GOOGLE_ADS)) {
      endpoint = `${endpoint}&total_synced_to_ad_network=${
        SEGMENT_SYNC_AD_PROVIDER_KEY[AD_NETWORKS.google]
      }`;
    }
    if (activity.includes(ACTIVELY_SYNCED_TO_GOOGLE_ADS)) {
      endpoint = `${endpoint}&actively_synced_to_ad_network=${
        SEGMENT_SYNC_AD_PROVIDER_KEY[AD_NETWORKS.google]
      }`;
    }
    if (creation_type.length > 0) {
      endpoint = `${endpoint}&creation_type=${creation_type.join()}`;
    }
    if (published.length > 0) {
      endpoint = `${endpoint}&published_to=${published.join()}`;
    }
    if (searchStr && searchStr !== '') {
      endpoint = `${endpoint}&name_contains=${encodeURIComponent(searchStr)}`;
      endpoint = `${endpoint}&fields=${SEGMENT_FIELDS_TO_FETCH.join()}`;
      return endpoint;
    }

    endpoint = `${endpoint}&fields=${SEGMENT_FIELDS_TO_FETCH.join()}`;
    return endpoint;
  },
);

export const advancedSearchLoadingSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.advancedSearchLoading,
);

export const allFoldersToggleSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.allFolders,
);

export const showCloneModalSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.showingSaveCloneModal,
);

export const segmentToCloneIdSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.segmentToCloneId,
);

export const segmentToCloneSelector = createSelector(
  [segmentToCloneIdSelector, segmentsSelector],
  (id, segments) => find(segments, (seg) => seg.id === id),
);

export const cloningSegmentSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.cloningSegment,
);

export const isManagePathSelector = createSelector(
  routePathnameSelector,
  (path) => includes(path, 'manage'),
);

export const segmentsObjByIdSelector = createSelector(
  // [{id: 4, name: foo}] => {4: {id: 4, name: foo}}
  segmentsSelector,
  (segments) =>
    reduce(segments, (acc, segment) => set(acc, segment.id, segment), {}),
);

export const selectSegmentById = (id) =>
  createSelector(segmentsObjByIdSelector, (segments) => segments[id]);

export const selectStrippedSegmentById = (id) =>
  createSelector(selectSegmentById(id), (segment) => omitBy(segment, isNull));

export const segmentsManageModeSelector = createSelector(
  queryStringParamsSelector,
  (params) => params && params.mode,
);

export const createSegmentTypeSelector = createSelector(
  queryStringParamsSelector,
  (params) => params && params.segment_type,
);

export const createSegmentModeSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.createSegmentMode
);

export const editSegmentIdSelector = createSelector(
  queryStringParamsSelector,
  (params) => params.id,
);

export const lookalikeCountSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.lookalikeCount,
);

export const uploadSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.upload,
);

export const uploadLoadingSelector = createSelector(
  uploadSelector,
  (uploads) => uploads.loading,
);

export const matchStatsStateSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.matchStats,
);

export const namedAccountsSelector = createSelector(
  matchStatsStateSelector,
  (matchStatsState) => matchStatsState.namedAccounts,
);

export const matchedAccountsSelector = createSelector(
  matchStatsStateSelector,
  (matchStatsState) => matchStatsState.matchedAccounts,
);

export const unmatchedAccountsSelector = createSelector(
  matchStatsStateSelector,
  (matchStatsState) => matchStatsState.unmatchedAccounts,
);

export const duplicateAccountsSelector = createSelector(
  matchStatsStateSelector,
  (matchStatsState) => matchStatsState.duplicates,
);

export const crmDataTypeSelector = createSelector(
  matchStatsStateSelector,
  (matchStatsState) => matchStatsState.crmDataType,
);

export const crmStateSelector = createSelector(
  routeStateSelector,
  queryStringParamsSelector,
  (routeState, qsParams) => {
    const { segment_type, crm_type } = qsParams;
    return {
      ...routeState.crm,
      syncFromAccountList:
        segment_type === CRM_ACCOUNT ||
        (segment_type === LOOKALIKE_EXTERNAL && crm_type === CRM_ACCOUNT),
      syncFromReport:
        segment_type === CRM_REPORT ||
        (segment_type === LOOKALIKE_EXTERNAL && crm_type === CRM_REPORT),
    };
  },
);

export const crmSourceColumnNameSelector = createSelector(
  crmDataTypeSelector,
  (crmDataType) => {
    switch (crmDataType) {
      case 'CMC':
        return 'Contact ID';
      case 'CML':
        return 'Lead ID';
      case 'CMA':
        return 'Account ID';
      default:
        return '';
    }
  },
);

export const strippedNamedAccountsSelector = createSelector(
  [namedAccountsSelector, crmSourceColumnNameSelector, crmStateSelector],
  (namedAccounts, crmDataType, crmState) => {
    if (crmDataType !== '') {
      return map(namedAccounts, (account) => ({
        Name: account.name,
        Country: account.country,
        Domain: crmState.syncFromReport ? account.domain : account.website,
        [crmDataType]: account.external_id,
      }));
    }

    return map(namedAccounts, (account) => ({
      Name: account.name,
      Country: account.country,
      Domain: account.website,
    }));
  },
);

export const strippedMatchedAccountsSelector = createSelector(
  [matchedAccountsSelector, crmSourceColumnNameSelector],
  (matchedAccounts, crmDataType) => {
    if (crmDataType !== '') {
      return map(matchedAccounts, (account) => ({
        Name: account.name,
        Country: account.country,
        Domain: account.domain,
        [crmDataType]: account.external_id,
      }));
    }

    return map(matchedAccounts, (account) => ({
      Name: account.name,
      Country: account.country,
      Domain: account.domain,
    }));
  },
);

export const strippedUnmatchedAccountsSelector = createSelector(
  [unmatchedAccountsSelector, crmSourceColumnNameSelector, crmStateSelector],
  (unmatchedAccounts, crmDataType, crmState) => {
    if (crmDataType !== '' && crmState.syncFromReport) {
      return map(unmatchedAccounts, (account) => ({
        [crmDataType]: account.external_id,
        Name: account.name,
        Country: account.country,
        Domain: account.website,
        'Reason for unmatched account': account.reason_for_no_match || '',
      }));
    } else if (crmDataType !== '') {
      return map(unmatchedAccounts, (account) => ({
        Name: account.name,
        Country: account.country,
        Domain: account.website,
        [crmDataType]: account.external_id,
        'Reason for unmatched account': account.reason_for_no_match || '',
      }));
    }

    return map(unmatchedAccounts, (account) => ({
      Name: account.name,
      Country: account.country,
      Domain: account.website,
      'Reason for unmatched account': account.reason_for_no_match || '',
    }));
  },
);

export const strippedDuplicateAccountsSelector = createSelector(
  [duplicateAccountsSelector, crmSourceColumnNameSelector, crmStateSelector],
  (duplicateAccounts, crmDataType, crmState) => {
    if (crmDataType !== '') {
      // We can't get the duplicate name for CRM Reports since we only sync their ids.
      if (crmState.syncFromReport) {
        return map(duplicateAccounts, (account) => ({
          Name: account.name,
          Country: account.country,
          Domain: crmState.syncFromReport ? account.domain : account.website,
          [crmDataType]: account.external_id,
        }));
      }
      return map(duplicateAccounts, (account) => ({
        Name: account.name,
        Country: account.country,
        Domain: account.website,
        [crmDataType]: account.external_id,
        'Matched Account Name': account.matched_account_name,
      }));
    }

    return map(duplicateAccounts, (account) => ({
      Name: account.name,
      Country: account.country,
      Domain: account.website,
      'Matched Account Name': account.matched_account_name,
    }));
  },
);

export const matchStatsSelector = createSelector(
  // This is liable to change after we implement
  // new named accounts architecture
  [
    namedAccountsSelector,
    matchedAccountsSelector,
    unmatchedAccountsSelector,
    duplicateAccountsSelector,
  ],
  (namedAccounts, matchedAccounts, unmatchedAccounts, duplicateAccounts) => ({
    totalAccounts: namedAccounts.length || 0,
    matchedAccounts: matchedAccounts.length || 0,
    unmatchedAccounts: unmatchedAccounts.length || 0,
    duplicateAccounts: duplicateAccounts.length || 0,
  }),
);

export const matchesLoadingSelector = createSelector(
  matchStatsStateSelector,
  (matchStatsState) => matchStatsState.loading,
);

export const matchesLoadedSelector = createSelector(
  matchStatsStateSelector,
  (matchStatsState) => matchStatsState.loaded,
);

export const matchesErrorSelector = createSelector(
  matchStatsStateSelector,
  (matchStatsState) => matchStatsState.error,
);

export const saveModalVisibleSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.saveModalVisible,
);

export const loadCrmListsSelector = createSelector(
  crmStateSelector,
  (crmState) => crmState.loadCrmLists,
);

export const loadCrmReportsSelector = createSelector(
  crmStateSelector,
  (crmState) => crmState.loadCrmReports,
);

export const isSearchingReportsSelector = createSelector(
  loadCrmReportsSelector,
  (crmReportsState) => crmReportsState.searchingReports,
);

export const externalListsAndReportsLoaded = createSelector(
  loadCrmListsSelector,
  (crmLists) => crmLists.loaded,
);

export const crmFileDownloadPrefixSelector = createSelector(
  crmStateSelector,
  (crmState) => {
    if (crmState.syncFromReport && crmState.selectedCrmReport.name) {
      return `crmreport_${crmState.selectedCrmReport.name}`;
    } else if (crmState.syncFromAccountList && crmState.selectedCrmList.name) {
      return `crmlist_${crmState.selectedCrmList.name}`;
    }
    return '';
  }
);

const loadCrmCredentialsSelector = createSelector(
  crmStateSelector,
  (crmState) => crmState.loadCrmCredentials,
);

const verifyCrmCredentialsSelector = createSelector(
  crmStateSelector,
  (crmState) => crmState.verifyCrmCredentials,
);

export const crmListsLoadingSelector = createSelector(
  loadCrmCredentialsSelector,
  verifyCrmCredentialsSelector,
  loadCrmListsSelector,
  (loadCrmCredentialsState, verifyCrmCredentialsState, loadCrmListsState) =>
    loadCrmCredentialsState.loading ||
    verifyCrmCredentialsState.loading ||
    loadCrmListsState.loading,
);

export const crmListsErrorSelector = createSelector(
  loadCrmCredentialsSelector,
  verifyCrmCredentialsSelector,
  loadCrmListsSelector,
  loadCrmReportsSelector,
  (loadCrmCredentialsState, verifyCrmCredentialsState, loadCrmListsState, loadCrmReportsState) =>
    loadCrmCredentialsState.error ||
    (loadCrmCredentialsState.loaded &&
      !loadCrmCredentialsState.areCredentialsValid) ||
    verifyCrmCredentialsState.error ||
    (verifyCrmCredentialsState.loaded && !verifyCrmCredentialsState.isValid) ||
    loadCrmListsState.error || loadCrmReportsState.error,
);

export const crmListsErrorMessageSelector = createSelector(
  loadCrmCredentialsSelector,
  verifyCrmCredentialsSelector,
  loadCrmListsSelector,
  loadCrmReportsSelector,
  (loadCrmCredentialsState, verifyCrmCredentialsState, loadCrmListsState, loadCrmReportsState) => {
    if ((loadCrmCredentialsState.loaded && !isEmpty(loadCrmCredentialsState.errorMessage))
    || (!verifyCrmCredentialsState.isValid && !isEmpty(verifyCrmCredentialsState.errorMessage))) {
      return loadCrmCredentialsState.errorMessage || verifyCrmCredentialsState.errorMessage;
    } else if (
      loadCrmCredentialsState.error ||
      verifyCrmCredentialsState.error ||
      loadCrmListsState.error ||
      loadCrmReportsState.error
    ) {
      const { errorMessage: crmListErrorMessage } = loadCrmListsState;
      const { errorMessage: crmReportsErrorMessage } = loadCrmReportsState;
      const { errorMessage: credVerificationErrorMessage } = verifyCrmCredentialsState;
      return crmListErrorMessage || crmReportsErrorMessage
      || credVerificationErrorMessage || CRM_LISTS_ERROR_TYPE.UNKNOWN_ERROR;
    }

    return null;
  },
);

export const selectedCrmListSelector = createSelector(
  crmStateSelector,
  (crmState) => crmState.selectedCrmList,
);

export const selectedCrmReportSelector = createSelector(
  crmStateSelector,
  (crmState) => crmState.selectedCrmReport,
);

export const selectedListOrReportSubmitted = createSelector(
  [selectedCrmListSelector, selectedCrmReportSelector],
  (crmList, crmReport) => crmList.submitted || crmReport.submitted,
);

export const validCrmListSelectedSelector = createSelector(
  selectedCrmListSelector,
  loadCrmListsSelector,
  (selectedCrmList, loadCrmListsState) =>
    has(selectedCrmList, 'id') &&
    includes(
      map(loadCrmListsState.crmLists, (list) => list.id),
      selectedCrmList.id,
    ),
);

export const validCrmReportSelectedSelector = createSelector(
  selectedCrmReportSelector,
  loadCrmReportsSelector,
  (selectedCrmReport, loadCrmReportsState) =>
    has(selectedCrmReport, 'id') &&
    includes(
      map(loadCrmReportsState.crmReports, (report) => report.id),
      selectedCrmReport.id,
    ),
);

export const loadCrmListDataSelector = createSelector(
  crmStateSelector,
  (crmState) => crmState.loadCrmListData,
);

export const crmListDataLoadingSelector = createSelector(
  loadCrmListDataSelector,
  (loadCrmListDataState) => loadCrmListDataState.loading,
);

export const crmListDataErrorSelector = createSelector(
  loadCrmListDataSelector,
  (loadCrmListDataState) => loadCrmListDataState.error,
);

export const crmListDataErrorMessageSelector = createSelector(
  loadCrmListDataSelector,
  (loadCrmListDataState) => loadCrmListDataState.errorMessage,
);

export const loadCrmReportDataSelector = createSelector(
  crmStateSelector,
  (crmState) => crmState.loadCrmReportData,
);

export const crmReportDataLoadingSelector = createSelector(
  loadCrmReportDataSelector,
  (loadCrmReportDataState) => loadCrmReportDataState.loading,
);

export const crmReportDataErrorSelector = createSelector(
  loadCrmReportDataSelector,
  (loadCrmReportDataState) => loadCrmReportDataState.error,
);

export const crmReportDataErrorMessageSelector = createSelector(
  loadCrmReportDataSelector,
  (loadCrmReportDataState) => loadCrmReportDataState.reportErrorMessage,
);

export const crmReportValidationErrorSelector = createSelector(
  loadCrmReportDataSelector,
  (reportState) => reportState.validationError,
);

export const logicErrorSelector = createSelector(
  routeStateSelector,
  ({ logicError }) => logicError,
);

export const segmentCountriesSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.segmentCountries
);

export const tagSearchValueSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.tagSearchValue,
);

export const moveSegmentModalVisibilitySelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.moveSegmentVisibility,
);

export const tagsToAddSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.tagsToAdd,
);

export const tagsToRemoveSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.tagsToRemove,
);

export const madeTagChangesSelector = createSelector(
  [segTagsSelector, manageTagsToCreateSelector],
  (segTags, createTags) =>
      createTags.length > 0 ||
      segTags.filter((tag) => tag.is_deleted || tag.newName !== tag.name).length > 0
);

export const segmentMovingSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.segmentMoving,
);

export const modifyingTagsSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.modifyingTags
);

export const segmentDependenciesStateSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.segmentDependencies
);


export const segmentDependenciesLoadingSelector = createSelector(
  segmentDependenciesStateSelector,
  ({ loading }) => loading,
);

export const segmentDependenciesLoadedSelector = createSelector(
  segmentDependenciesStateSelector,
  ({ loaded }) => loaded,
);

export const segmentDependenciesErrorSelector = createSelector(
  segmentDependenciesStateSelector,
  ({ error }) => error,
);

export const segmentDependenciesDataSelector = createSelector(
  segmentDependenciesStateSelector,
  ({ dependencyData }) => dependencyData,
);

export const segmentDependenciesSelector = createSelector(
  segmentDependenciesDataSelector,
  ({ dependencies }) => dependencies,
);

export const segmentSyncSummaryStateSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.segmentSyncSummary
);

export const segmentSyncSummaryLoadingSelector = createSelector(
  segmentSyncSummaryStateSelector,
  ({ loading }) => loading,
);

export const segmentSyncSummaryErrorSelector = createSelector(
  segmentSyncSummaryStateSelector,
  ({ error }) => error,
);

export const segmentSyncSummaryDataSelector = createSelector(
  segmentSyncSummaryStateSelector,
  ({ segmentSyncSummaryData }) => segmentSyncSummaryData,
);


export const segmentDeletableSelector = createSelector(
  segmentDependenciesDataSelector,
  ({ can_delete: canDelete }) => canDelete,
);

export const formattedSegmentDependenciesSelector = createSelector(
  segmentDependenciesSelector,
  (deps) => {
    const publishMap = {
      sales_intelligence: 'Sales Intelligence Experience',
      account_details: 'Account Details Experience',
      orchestration: 'Segment Name Enrichment',
      gtm: 'GTM Segment Nomination',
      '6sense_apis': '6sense APIs',
      workflows: 'Workflows',
    };
    const depMap = {};
    for (const seg of Object.keys(deps || {})) {
      const formattedDeps = reduce(
        deps[seg],
        (acc, val, key) => {
          if (val && !(Array.isArray(val) && !val.length)) {
            let formattedVal = val;
            if (key === 'published_to') {
              formattedVal = map(val, (item) => publishMap[item]);
            }
            acc[key] = {
              ...FORMATTED_DEPENDENCY_TEMPATES[key],
              content: formattedVal,
              internalValue: key,
            };
          }
          return acc;
        },
        {}
      );
      if (!isEmpty(formattedDeps)) {
        depMap[seg] = formattedDeps;
      }
    }
    return depMap;
  }
);

export const hasCampaignPermissionsSelector = createSelector(
  permissionsSelector,
  (allPermissions) => CAMPAIGN_PERMISSIONS
      .some((permission) => allPermissions.has(permission))
);

export const isPageLoading = createSelector(
  routeStateSelector,
  hasCampaignPermissionsSelector,
  (
    { loading: segmentsManageLoading },
    hasCampaignPermissions
  ) => {
    if (hasCampaignPermissions) {
      return segmentsManageLoading;
    }
    return segmentsManageLoading;
  }
);

export const isPageLoaded = createSelector(
  routeStateSelector,
  hasCampaignPermissionsSelector,
  (
    { loaded: segmentsManageLoaded },
    hasCampaignPermissions
  ) => {
    if (hasCampaignPermissions) {
      return segmentsManageLoaded;
    }

    return segmentsManageLoaded;
  }
);
