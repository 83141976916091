import React from 'react';
import { Card, Flex } from '@sixsense/core/components';
import { Button, Icon, Text, Select, Row } from 'v2-components';
import RoleList from './RoleList';
import RoleComparisonTable from './RoleComparisonTable';
import { useSharedState } from '@sixsense/core/shared-state';
import { showRoleComparisonState } from '../state';
import { useSelector, useDispatch } from '@sixsense/core/versioned/react-redux';
import {
  appsListSelector,
  licenseResponseSelector,
  getRolesTabSelectionSelector,
} from '../selectors';
import { css } from 'styles/emotion';
import { actions } from '../modules';

const getRolesOptions = (appsList) =>
  appsList.map(({ id: appId, longName }) => ({
    label: longName,
    value: appId,
  }));

const styles = {
  fieldText: css({
    marginRight: '23px',
  }),
  inputContainer: css({
    minWidth: 200,
  }),
  filterButton: css({
    height: '36px',
    width: '150px',
    marginRight: '10px',
    marginLeft: '10px',
  }),
  filterByText: css({
    marginRight: '5px',
    marginLeft: '5px',
  }),
  active: css({
    color: 'white',
  }),
  countPip: css({
    backgroundColor: '#E12C2C',
    color: 'white',
    borderRadius: '50%',
    width: '18px',
  }),
};

const LicenseSelection = () => {
  const licenseResponse = useSelector(licenseResponseSelector);
  const dispatch = useDispatch();

  const {
    selectedAppId = null,
    selectedLicenseLabel = null,
  } = useSelector(getRolesTabSelectionSelector);

  const selectedAppLicense = licenseResponse.filter(
    ({ app_id: appId }) => selectedAppId === appId
  )[0];
  if (!selectedAppLicense) {
    return null;
  }
  const licenses = selectedAppLicense.licenses;
  if (licenses.length <= 1) {
    return null;
  }
  return (
    <React.Fragment>
      <div style={{ width: '20px' }} />
      <Text bold className={styles.fieldText}>
        Choose License
      </Text>
      <Select
        options={licenses.map(({ name, licenseLabel }) => ({
          label: licenseLabel || name,
          value: licenseLabel,
        }))}
        onChange={(val) => {
          dispatch(
            actions.setRolesTabSelection({
              selectedAppId,
              selectedLicenseLabel: val,
              selectedRoleIds: licenses.find(
                ({ licenseLabel }) => licenseLabel === val
              ).role_ids,
            })
          );
        }}
        value={selectedLicenseLabel}
        dropdownMatchSelectWidth={false}
      />
    </React.Fragment>
  );
};

const RolesCard = () => {
  const licenseResponse = useSelector(licenseResponseSelector);

  const appsList = useSelector(appsListSelector);

  const dispatch = useDispatch();

  const {
    selectedAppId = null,
  } = useSelector(getRolesTabSelectionSelector);

  const setRolesTabSelectionByAppId = React.useCallback(
    (appId) => {
      // eslint-disable-next-line no-shadow
      const selectedAppId = appId || (appsList[0] || {}).id;

      const appLicense = licenseResponse.filter(
        // eslint-disable-next-line no-shadow
        ({ app_id: appId }) => selectedAppId === appId
      )[0];

      if (appLicense) {
        const firstLicense = appLicense.licenses[0];

        if (firstLicense) {
          dispatch(
            actions.setRolesTabSelection({
              selectedAppId,
              selectedLicenseLabel: firstLicense.licenseLabel,
              selectedRoleIds: firstLicense.role_ids,
            })
          );
        }
      }
    },
    [appsList, licenseResponse, dispatch]
  );

  React.useEffect(() => {
    if (selectedAppId === null) {
      setRolesTabSelectionByAppId(null);
    }
  }, [selectedAppId, setRolesTabSelectionByAppId]);

  const [showComparison, setShowComparison] = useSharedState(
    showRoleComparisonState
  );
  if (!selectedAppId) {
    return null;
  }

  return (
    <Card>
      <Card.Header>
        <Flex justifyContent="space-between">
          <Row
            alignItems={Row.ALIGN_ITEMS.CENTER}
            justifyContent={Row.JUSTIFY_CONTENT.CENTER}
          >
            {appsList.length > 1 ? (
              <React.Fragment>
                <Text bold className={styles.fieldText}>
                  Choose App
                </Text>
                <Select
                  options={getRolesOptions(appsList)}
                  onChange={(val) => setRolesTabSelectionByAppId(val)}
                  value={selectedAppId}
                  dropdownMatchSelectWidth={false}
                />
              </React.Fragment>
            ) : null}
            {<LicenseSelection />}
          </Row>

          {showComparison ? (
            <Button
              onClick={() => setShowComparison(false)}
              isHeaderButton
              iconLeft={
                <Icon type="list" size="24px" color={Icon.COLORS.BLACK} />
              }
            >
              View Role List
            </Button>
          ) : (
            <Button
              onClick={() => setShowComparison(true)}
              isHeaderButton
              iconLeft={
                <Icon
                  type="view_module"
                  size="24px"
                  color={Icon.COLORS.BLACK}
                />
              }
            >
              View Role Comparison Table
            </Button>
          )}
        </Flex>
      </Card.Header>
      <Card.Content>
        {showComparison ? <RoleComparisonTable /> : <RoleList />}
      </Card.Content>
    </Card>
  );
};

export default RolesCard;
