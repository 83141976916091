import React from 'react';
import { Text } from 'v2-components';
import { classNames } from 'utils/utils';
import { css } from 'styles/emotion';
import { EmailsListRender } from '../EmailsListRender';

const styles = {
  errorText: css`
    color: #D11C1C;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `,
};

export const BulkUpdateErrorMsgObject = {
  MySelfEmailSelected: () => (
    <Text className={classNames(styles.errorText)}>
      Deselect your own email address. You can’t change your own status or role.
    </Text>
  ),
  PossibleGhostUserList: (ghostUserEmailsList) => (
    <Text className={classNames(styles.errorText)}>
      Deselect{' '}
      <EmailsListRender
        emails={ghostUserEmailsList}
        suffixText=". Users have to have at least one assigned role."
        removeSuffixSpace
      />
    </Text>
  ),
  LicenseingLimitExceed: (shortName, licenseLabel) => (
    <Text className={classNames(styles.errorText)}>
      You don’t have enough {shortName} {licenseLabel} licenses to make this change.{' '}
      Contact your CSM for more licenses.
    </Text>
  ),
  AdminAssignNoAccess: (adminListSelectedAppMap, appId, shortName) => (
    <Text className={classNames(styles.errorText)}>
      Deselect <EmailsListRender
        emails={adminListSelectedAppMap[appId] || []}
        suffixText={`. You don’t have access to change ${shortName} administrator roles/status.`}

        removeSuffixSpace
      />
    </Text>
  ),
  MaxSelectionLimitExceed: () => (
    <Text className={classNames(styles.errorText)}>
      You cannot select more than 100 users in the list. Deselect some users and
      retry.
    </Text>
  ),
  disabledUserSelected: (disabledUserList) => (
    <Text className={classNames(styles.errorText)}>
       Deselect <EmailsListRender
         emails={disabledUserList || []}
         suffixText={'.Role changes are not allowed for Disabled users.'}
         removeSuffixSpace
       />
    </Text>
  ),
  expiredUserRoleDisabledSelected: (expiredUserRoleUserList) => (
    <Text className={classNames(styles.errorText)}>
       Deselect <EmailsListRender
         emails={expiredUserRoleUserList || []}
         suffixText={'.Role changes are not allowed for Expired Invites.'}
         removeSuffixSpace
       />
    </Text>
  ),
};

export const FilterErrorMsgObject = {
  EmailFilterOut: (filteredOut) => (
    <React.Fragment>
      Check the filter settings. These don't match those settings:{' '}
      <EmailsListRender
        emails={filteredOut}
        suffixText="."
        removeSuffixSpace
      />
    </React.Fragment>
  ),
  PrimaryAdminInFilter: (paEmail) => (
    <React.Fragment>
      You can’t use the email address <strong>({paEmail})</strong>{' '}
      of the primary administrator as a filter.
    </React.Fragment>
  ),
  EmailNotInSystem: (notInSystem) => (
    <React.Fragment>
      Check the email addresses. We can’t find matches for{' '}
      <EmailsListRender emails={notInSystem} suffixText="." removeSuffixSpace />
    </React.Fragment>
  ),
};
