import { SET_CAMPAIGN_CLASSIFICATION_DRAWER_VISIBILITY } from "./types";
import { createReducerUtil } from "utils/core";
import { stateGenerators } from "./stateGenerators";
import { combineReducers } from "redux";

const {
  renameClassification,
  deleteClassificationStateGenerator,
  createClassification,
  changeClassification,
} = stateGenerators;

const campaignClassificationDrawerInitialState = {
  visible: false,
  campaignIds: [],
  uiAction: undefined,
};

const campaignClassificationDrawer = createReducerUtil(
  campaignClassificationDrawerInitialState,
  {
    [SET_CAMPAIGN_CLASSIFICATION_DRAWER_VISIBILITY]: (
      state,
      { visible, campaignIds, uiAction }
    ) => {
      if (visible === false) {
        return campaignClassificationDrawerInitialState;
      }

      return {
        ...state,
        visible,
        campaignIds,
        uiAction,
      };
    },
  }
);

export const reducers = combineReducers({
  folders: stateGenerators.campaignClassifications.reducer,
  classificationsDrawer: campaignClassificationDrawer,
  changeClassification: changeClassification.reducer,
  newClassification: createClassification.reducer,
  [deleteClassificationStateGenerator.stateKey]:
    deleteClassificationStateGenerator.reducer,
  [renameClassification.stateKey]: renameClassification.reducer,
});
