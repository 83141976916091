import { ADVERTISING_ACTION_PREFIX } from './constants';

export const INIT_FOLDER_SELECTION = `${ADVERTISING_ACTION_PREFIX}/INIT_FOLDER_SELECTION`;
export const initFolderSelection = (payload) => ({
  type: INIT_FOLDER_SELECTION,
  payload,
});

export const UPDATE_FOLDER_SELECTION = `${ADVERTISING_ACTION_PREFIX}/UPDATE_FOLDER_SELECTION`;
export const updateFolderSelection = (payload) => ({
  type: UPDATE_FOLDER_SELECTION,
  payload,
});

export const UPDATE_EXPANDED_FOLDERS = `${ADVERTISING_ACTION_PREFIX}/UPDATE_EXPANDED_FOLDERS`;
export const updateExpandedFolders = (payload) => ({
  type: UPDATE_EXPANDED_FOLDERS,
  payload,
});

export const SHOW_CREATE_NEW_CAMPAIGNS_FOLDER_MODAL =
  `${ADVERTISING_ACTION_PREFIX}/SHOW_CREATE_NEW_CAMPAIGNS_FOLDER_MODAL`;
export const showCreateNewCampaignsFolderModal = () => ({
  type: SHOW_CREATE_NEW_CAMPAIGNS_FOLDER_MODAL,
});
export const HIDE_CREATE_NEW_CAMPAIGNS_FOLDER_MODAL =
  `${ADVERTISING_ACTION_PREFIX}/HIDE_CREATE_NEW_CAMPAIGNS_FOLDER_MODAL`;
export const hideCreateNewCampaignsFolderModal = () => ({
  type: HIDE_CREATE_NEW_CAMPAIGNS_FOLDER_MODAL,
});

export const SHOW_CREATE_NEW_ADS_FOLDER_MODAL =
  `${ADVERTISING_ACTION_PREFIX}/SHOW_CREATE_NEW_ADS_FOLDER_MODAL`;
export const showCreateNewAdsFolderModal = () => ({
  type: SHOW_CREATE_NEW_ADS_FOLDER_MODAL,
});
export const HIDE_CREATE_NEW_ADS_FOLDER_MODAL =
  `${ADVERTISING_ACTION_PREFIX}/HIDE_CREATE_NEW_ADS_FOLDER_MODAL`;
export const hideCreateNewAdsFolderModal = () => ({
  type: HIDE_CREATE_NEW_ADS_FOLDER_MODAL,
});

export const TOGGLE_DELETE_CAMPAIGNS_FOLDER_MODAL =
  `${ADVERTISING_ACTION_PREFIX}/TOGGLE_DELETE_CAMPAIGNS_FOLDER_MODAL`;
export const toggleDeleteCampaignsFolderModal = (payload) => ({
  type: TOGGLE_DELETE_CAMPAIGNS_FOLDER_MODAL,
  payload,
});

export const TOGGLE_DELETE_ADS_FOLDER_MODAL =
  `${ADVERTISING_ACTION_PREFIX}/TOGGLE_DELETE_ADS_FOLDER_MODAL`;
export const toggleDeleteAdsFolderModal = (payload) => ({
  type: TOGGLE_DELETE_ADS_FOLDER_MODAL,
  payload,
});

export const TOGGLE_RENAME_CAMPAIGNS_FOLDER_MODAL =
  `${ADVERTISING_ACTION_PREFIX}/TOGGLE_RENAME_CAMPAIGNS_FOLDER_MODAL`;
export const toggleRenameCampaignsFolderModal = (payload) => ({
  type: TOGGLE_RENAME_CAMPAIGNS_FOLDER_MODAL,
  payload,
});

export const TOGGLE_RENAME_ADS_FOLDER_MODAL =
  `${ADVERTISING_ACTION_PREFIX}/TOGGLE_RENAME_ADS_FOLDER_MODAL`;
export const toggleRenameAdsFolderModal = (payload) => ({
  type: TOGGLE_RENAME_ADS_FOLDER_MODAL,
  payload,
});

export const SET_UPDATING_TREE_DATA =
  `${ADVERTISING_ACTION_PREFIX}/SET_UPDATING_TREE_DATA`;
export const setUpdatingTreeData = (isUpdating) => ({
  type: SET_UPDATING_TREE_DATA,
  payload: isUpdating,
});

export const CAMPAIGNS_EXTERNAL_DROP = `${ADVERTISING_ACTION_PREFIX}/CAMPAIGNS_EXTERNAL_DROP`;
export const campaignsExternalDrop = (nodeKey, droppedItem) => ({
  type: CAMPAIGNS_EXTERNAL_DROP,
  payload: { nodeKey, droppedItem },
});

export const LOG_ROLLBAR_ERROR = `${ADVERTISING_ACTION_PREFIX}/LOG_ROLLBAR_ERROR`;
export const logRollbarError = (message) => ({
  type: LOG_ROLLBAR_ERROR,
  payload: message,
});

export const DIRECTORY_TREE_LOAD = `${ADVERTISING_ACTION_PREFIX}/DIRECTORY_TREE_LOAD`;
export const directoryTreeLoad = () => ({
  type: DIRECTORY_TREE_LOAD,
});

// linkedin video creative upload modal actions
export const ADD_LINKEDIN_VIDEO_CREATIVE =
  `${ADVERTISING_ACTION_PREFIX}/CREATE_AD_PLACEMENT/ADD_LI_VIDEO_CREATIVE`;
export function addLinkedinVideoCreative(creative, placementType) {
  return { type: ADD_LINKEDIN_VIDEO_CREATIVE, creative, placementType };
}

export const REMOVE_LINKEDIN_VIDEO_CREATIVE =
  `${ADVERTISING_ACTION_PREFIX}/CREATE_AD_PLACEMENT/REMOVE_LI_VIDEO_CREATIVE`;
export function removeLinkedinVideoCreative(creativeId) {
  return { type: REMOVE_LINKEDIN_VIDEO_CREATIVE, creativeId };
}

export const UPDATE_LINKEDIN_VIDEO_CREATIVE_ASSET =
  `${ADVERTISING_ACTION_PREFIX}/CREATE_AD_PLACEMENT/UPDATE_LINKEDIN_VIDEO_CREATIVE_ASSET`;
export function updateLinkedinVideoCreativeAsset(payload, selectedCreativeId, attachmentType) {
  return {
    type: UPDATE_LINKEDIN_VIDEO_CREATIVE_ASSET,
    payload,
    selectedCreativeId,
    attachmentType,
  };
}

export const SELECT_LINKEDIN_VIDEO_CREATIVE =
  `${ADVERTISING_ACTION_PREFIX}/CREATE_AD_PLACEMENT/SELECT_LI_VIDEO_CREATIVE`;
export function selectLinkedinVideoCreative(creativeId) {
  return { type: SELECT_LINKEDIN_VIDEO_CREATIVE, creativeId };
}

export const RESET_LINKEDIN_VIDEO_CREATIVE =
  `${ADVERTISING_ACTION_PREFIX}/CREATE_AD_PLACEMENT/RESET_LI_VIDEO_CREATIVE`;
export function resetLinkedinVideoCreative() {
  return { type: RESET_LINKEDIN_VIDEO_CREATIVE };
}

export const UPLOAD_LINKEDIN_VIDEO_CREATIVE =
  `${ADVERTISING_ACTION_PREFIX}/CREATE_AD_PLACEMENT/UPLOAD_LI_VIDEO_CREATIVE`;
export function uploadLinkedinVideoCreative(payload) {
  return { type: UPLOAD_LINKEDIN_VIDEO_CREATIVE, payload };
}

export const RESET_LINKEDIN_VIDEO_CREATIVE_ASSET_UPLOAD =
  `${ADVERTISING_ACTION_PREFIX}/CREATE_AD_PLACEMENT/RESET_LINKEDIN_VIDEO_CREATIVE_ASSET_UPLOAD`;
export function resetVideoCreativeAssetUpload({ removeAllFiles }) {
  return { type: RESET_LINKEDIN_VIDEO_CREATIVE_ASSET_UPLOAD, removeAllFiles };
}

export const REMOVE_LINKEDIN_VIDEO_CREATIVE_ASSET_UPLOAD =
  `${ADVERTISING_ACTION_PREFIX}/CREATE_AD_PLACEMENT/REMOVE_LINKEDIN_VIDEO_CREATIVE_ASSET_UPLOAD`;
export function removeVideoCreativeAssetUploadFile(creative) {
  return { type: REMOVE_LINKEDIN_VIDEO_CREATIVE_ASSET_UPLOAD, creative };
}
