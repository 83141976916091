import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';
import { createSelector } from 'reselect';
import { orgFlagsSelector, orgSelector, userObjectSelector } from '../../modules/user/selectors';
import { formStateSelector, modalStateSelector, stateSelector } from './selectors';
import { actions as globalActions } from '../../modules/global';
import { getReportColumnKeys, removeFalsyValues } from './utils';
import { campaignOrgSelector } from 'routes/Advertising/routes/Campaigns/selectors';
import { get } from 'lodash';
import {
  statsDateRangeParams,
} from 'routes/Advertising/routes/Campaigns/routes/ListCampaigns/utils';
import { toggleVisibility } from './actions';
import {
  campaignAllColumnsSelector,
} from 'routes/Advertising/routes/Campaigns/routes/ListCampaigns/selectors';

const { showNotification } = globalActions;

const emailReportBodySelector = createSelector(
  userObjectSelector,
  formStateSelector,
  campaignOrgSelector,
  modalStateSelector,
  orgFlagsSelector,
  campaignAllColumnsSelector,
  (user, formState, campaignOrg, modalState, orgFeatureFlags, allColumns) => {
    const columns = getReportColumnKeys(false, orgFeatureFlags, allColumns);
    const { dateRange, groupBy, timeBreakdown } = formState;
    const lastPipelineRun = get(campaignOrg, 'last_updated');

    const dateFilterParams = statsDateRangeParams(
      dateRange.value, dateRange.from, dateRange.to, lastPipelineRun
    );
    const { campaignIds, additionalQueryParams } = modalState;
    const stringify = (obj) => Object.entries(obj)
      .reduce(
        (acc, [key, value]) => {
          if (!value) return acc;

          return { ...acc, [key]: value.toString() };
        },
        {},
      );

    return {
      body: JSON.stringify(removeFalsyValues({
        column_names: columns,
        email_ids: [user.username],
        group_by: groupBy,
        interval_type: timeBreakdown,
        ...dateFilterParams,
        filters: stringify({
          id__in: campaignIds.length ? campaignIds : null,
          ...additionalQueryParams,
        }),
      })),
    };
  }
);

export const emailReportStateGenerator = stateGenerator({
  actionPrefix: 'CAMPAIGNS/NEW_CAMPAIGN_DOWNLOAD_MODAL/EMAIL_REPORT',
  endpointSelector: createSelector(
    orgSelector,
    (orgId) => `org/${orgId}/campaign_report/`,
  ),
  stateKey: 'emailReport',
  baseStateSelector: stateSelector,
  onFailureActions: [
    () => showNotification('error', 'Unable to email report. Please try again later'),
  ],
  onSuccessActions: [
    (response) => {
      const email = get(response, ['created_by', 'username'], 'your email');

      return showNotification(
        'success',
        'Your email is on it’s way!',
        `Email will be sent to ${email} typically within 30` +
        '- 90 minutes. In rare cases, the report can take up to 12 hours.'
      );
    },
    () => toggleVisibility(false),
  ],
  method: 'POST',
  requestOptions: emailReportBodySelector,
});
