import React from "react";
import { Text } from "@sixsense/core/components";
import { localeString } from "utils/utils";
import { NA } from "./common/NA";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { isDraftOrScheduledCampaign } from "../../../utils";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const IMPRESSIONS = {
  sortByKey: "media_impressions",
  customizable: true,
  key: "impressions",
  csvColumnKey: "impressions",
  defaultSelected: true,
  title: <Label name={CAMPAIGN_LABELS.impressions} />,
  dataIndex: "campaign_data.media_impressions",
  width: pxToRem(150),
  render: (impressions, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (isDraftOrScheduledCampaign(campaignStatus)) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {localeString(impressions)}
      </Text.Body>
    );
  },
  csvOverrides: {
    dataIndex: "download_stats.media_impressions",
    render: (impressions, campaign) => {
      const campaignStatus = campaign.campaign_status;
      if (isDraftOrScheduledCampaign(campaignStatus)) {
        return "-";
      }

      return `"${localeString(parseInt(impressions) || 0)}"`;
    },
  },
  align: "right",
};
