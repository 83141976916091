import { FEATURE_FLAGS } from 'constants/featureFlags';
import { AD_TYPE_IDS } from 'routes/Advertising/constants';
import { RELEASE_TYPES } from 'utils/constants';

export const AD_NETWORKS = {
  sixSense: '6sense',
  linkedIn: 'LinkedIn',
};

export const AD_TYPES = {
  [AD_NETWORKS.sixSense]: {
    description: `Create digital display, video, native, HTML5 & HTML5 Dynamics
    ads for 6sense campaigns that can run directly through the 6sense DSP.`,
    types: [
      { id: AD_TYPE_IDS.bannerAd, label: 'Banner Ad' },
      { id: AD_TYPE_IDS.videoAd, label: 'Video Ad' },
      { id: AD_TYPE_IDS.nativeAd, label: 'Native Ad' },
      { id: AD_TYPE_IDS.html5Ad, label: 'HTML5 Ad' },
      { id: AD_TYPE_IDS.html5DynamicAd, label: 'HTML5 Dynamic Ad' },
    ],
  },
  [AD_NETWORKS.linkedIn]: {
    description: `Create LinkedIn ads for LinkedIn campaigns
    that can run directly through 6sense.`,
    types: [
      {
        id: AD_TYPE_IDS.linkedInSingleImageAd,
        label: 'Single Image Ad',
        featureFlag: FEATURE_FLAGS.hasLinkedinAdvertising,
      },
      {
        id: AD_TYPE_IDS.linkedInVideoAd,
        label: 'Video Ad',
        featureFlag: FEATURE_FLAGS.hasLinkedinAdvertising,
      },
    ],
  },
};

export const AD_TYPE_PROPERTIES = {
  [AD_TYPE_IDS.bannerAd]: {
    acceptedFileTypes: ['JPEG', 'JPG', 'PNG'],
    acceptedCreativeDimensions: [
      '160 x 600',
      '300 x 250',
      '300 x 600',
      '320 x 480',
      '320 x 50',
      '728 x 90',
      '768 x 1024',
      '1024 x 768',
    ],
    acceptedFileSize: {
      min: '1 KB',
      max: '150 KB',
    },
    iconSVG: '6s_display_ad_icon.svg',
  },
  [AD_TYPE_IDS.videoAd]: {
    acceptedFileTypes: ['MP4', 'WEBM', 'MOV', 'QUICKTIME'],
    acceptedDimensions: ['1280 x 720', '> 1280 x 720'],
    acceptedFileSize: {
      min: '1 KB',
      max: '122 MB',
    },
    recommendedVideoDuration: {
      duration: ['15 sec', '30 sec'],
      note: `We recommend that your creative contains the skippable element
       if you want to serve video ads over 15 seconds in duration.`,
    },
    otherValidations: 'We support in-stream (VAST) video on secure (HTTPS) inventory',
    iconSVG: '6s_video_ad_icon.svg',
  },
  [AD_TYPE_IDS.nativeAd]: {
    acceptedImageTypes: ['GIF', 'JPEG', 'JPG', 'PNG'],
    acceptedImageDimensions: ['1200 x 627'],
    acceptedImageSize: {
      min: '1 KB',
      max: '200 KB',
    },
    acceptedIconTypes: ['GIF', 'JPEG', 'JPG', 'PNG'],
    acceptedIconDimensions: ['300 x 300', '> 300 x 300'],
    acceptedIconSize: {
      min: '1 KB',
      max: '150 KB',
    },
    otherImageValidations: 'Should have 1.91:1 aspect ratio',
    otherIconValidations: 'Should have 1:1 aspect ratio',
    iconSVG: 'native_ad_icon.svg',
  },
  [AD_TYPE_IDS.html5Ad]: {
    acceptedFileTypes: ['ZIP', 'X-ZIP-COMPRESSED'],
    acceptedCreativeDimensions: [
      '120 x 20',
      '120 x 600',
      '160 x 600',
      '168 x 28',
      '180 x 150',
      '216 x 36',
      '234 x 60',
      '250 x 250',
      '300 x 50',
      '300 x 250',
      '300 x 600',
      '320 x 50',
      '320 x 100',
      '320 x 480',
      '336 x 280',
      '468 x 60',
      '480 x 320',
      '728 x 90',
      '970 x 90',
      '970 x 250',
    ],
    acceptedFileSize: {
      min: '1 KB',
      max: '122 MB',
    },
    otherValidations: 'Must contain index.html file',
    iconSVG: 'html5_ad_icon.svg',
  },
  [AD_TYPE_IDS.linkedInSingleImageAd]: {
    acceptedFileTypes: ['JPG', 'PNG'],
    acceptedDimensions: {
      horizontal: {
        min: '640 x 360',
        max: '7680 x 4320',
        recommended: ['1200 x 628 pixels'],
      },
      square: {
        min: '360 x 360',
        max: '4320 x 4320',
        recommended: ['1200 x 1200 pixels'],
      },
      vertical: {
        min: '360 x 640',
        max: '1250 x 2400',
        recommended: [
          '1:1.91 - 628 x 1200 pixels',
          '2:3 - 600 x 900 pixels',
          '4:5 - 720 x 900 pixels',
        ],
      },
    },
    acceptedFileSize: {
      min: '1 KB',
      max: '5 MB',
    },
    iconSVG: '6s_display_ad_icon.svg',
    releaseType: RELEASE_TYPES.EARLY_ACCESS,
    releaseTypeCssClass: 'earlyAccessReleaseTag',
  },
  [AD_TYPE_IDS.linkedInVideoAd]: {
    acceptedFileTypes: ['MP4'],
    acceptedDimensions: {
      horizontal: {
        min: '640 x 360',
        max: '1920 x 1080',
      },
      square: {
        min: '360 x 360',
        max: '1920 x 1920',
      },
      vertical_1: {
        min: '360 x 640',
        max: '1080 x 1920',
      },
      vertical_2: {
        min: '360 x 450',
        max: '1080 x 1350',
      },
    },
    acceptedFileSize: {
      min: '75 KB',
      max: '200 MB',
    },
    videoDuration: {
      min: '3 Sec',
      max: '30 Min',
    },
    videoThumbnail: {
      acceptedFileTypes: ['JPG', 'PNG'],
      acceptedFileSize: ['2 MB'],
    },
    videoSubtitle: 'Only SRT Format',
    iconSVG: '6s_video_ad_icon.svg',
    releaseType: RELEASE_TYPES.EARLY_ACCESS,
    releaseTypeCssClass: 'earlyAccessReleaseTag',
  },
};

AD_TYPE_PROPERTIES[AD_TYPE_IDS.html5DynamicAd] =
  AD_TYPE_PROPERTIES[AD_TYPE_IDS.html5Ad];

export const FORM_NAME = 'AD_TYPE_SELECTION_MODAL';
export const DEFAULT_SELECTED_AD_FORMAT = AD_TYPE_IDS.bannerAd;

const COMMON_TITLES = {
  acceptedFileTypes: 'Accepted File Type',
  acceptedCreativeDimension: 'Accepted Creative Dimensions',
  acceptedFileSize: 'Accepted File Size',
};

export const TITLES = {
  [AD_TYPE_IDS.bannerAd]: {
    ...COMMON_TITLES,
    main: '6sense Banner Ad',
  },
  [AD_TYPE_IDS.videoAd]: {
    ...COMMON_TITLES,
    main: '6sense Video Ad',
    acceptedDimensions: 'Accepted Dimensions',
    recommendedVideoDuration: 'Recommended Video Durations',
    otherValidations: 'Other Validations',
  },
  [AD_TYPE_IDS.nativeAd]: {
    main: '6sense Native Ad',
    acceptedImageTypes: 'Accepted File (Image) Types',
    acceptedImageDimensions: 'Accepted Creative (Image) Dimensions',
    acceptedImageSize: 'Accepted File (Image) Size',
    otherImageValidations: 'Other (Image) Validations',
    acceptedIconTypes: 'Accepted File (Icon) Types',
    acceptedIconDimensions: 'Accepted Creative (Icon) Dimensions',
    acceptedIconSize: 'Accepted File (Icon) Size',
    otherIconValidations: 'Other (Icon) Validations',
  },
  [AD_TYPE_IDS.html5Ad]: {
    ...COMMON_TITLES,
    main: '6sense HTML5 Ad',
    otherValidations: 'Other Validations',
  },
  [AD_TYPE_IDS.html5DynamicAd]: {
    ...COMMON_TITLES,
    main: '6sense HTML5 Dynamic Ad',
    otherValidations: 'Other Validations',
  },
  [AD_TYPE_IDS.linkedInSingleImageAd]: {
    ...COMMON_TITLES,
    main: 'LinkedIn Single Image Ad',
    acceptedDimensions: {
      main: 'Accepted Dimensions',
      square: 'Square:  1:1',
      horizontal: 'Horizontal/landscape:  1.91:1',
      vertical: 'Vertical:  1:1.91 (mobile only)',
    },
  },
  [AD_TYPE_IDS.linkedInVideoAd]: {
    ...COMMON_TITLES,
    main: 'LinkedIn Video Ad',
    acceptedDimensions: {
      main: 'Accepted Dimensions',
      horizontal: 'Horizontal/Landscape:  16:9',
      square: 'Square:  1:1',
      vertical_1: 'Vertical:   9:16',
      vertical_2: 'Vertical:   4:5',
    },
    videoDuration: 'Video Duration',
    videoThumbnail: {
      main: 'Video Thumbnail (optional)',
      acceptedFileTypes: 'Accepted File Type:',
      acceptedFileSize: 'Accepted File Size:',
    },
    videoSubtitle: 'Video Caption',
  },
};

export const LINKEDIN_VIDEO_RATIO_DIMENSION_TITLE_MAPPING = {
  1: 'square',
  1.77: 'horizontal',
  0.56: 'vertical_1',
  0.8: 'vertical_2',
};
