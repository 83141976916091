import gql from 'graphql-tag';

export const USER_MANAGEMENT_QUERY = gql`
query {
    stats {
        userManagement {
            active
            invited
            disabled
        }
    }
}
`;

export const USAGE_METRICS_QUERY = gql`
query {
    stats {
        usageMetrics {
            platformLoginsLast3Months
            siLoginsLast3Months
        }
    }
}
`;

export const MAPPING_PROFILES_QUERY = gql`
query {
    stats {
        mappingProfiles {
            fieldsEnabled
        }
    }
}
`;

export const SEGMENT_QUERY = gql`
query {
    stats {
        segment {
            published
        }
    }
}
`;

export const SYSTEM_NOTIFICATIONS_QUERY = gql`
query {
    stats {
        systemNotifications {
            notifiedUsers
        }
    }
}
`;

export const CONTACT_LEAD_MANAGEMENT_QUERY = gql`
query {
    stats {
        contactLeadManagement {
            purchasedThisMonth
        }
    }
}
`;
