import { createReducerUtil } from 'utils/core';
import { PRESET_STRING } from '../../../constants';

const AUDITS_PRESET_STRING = `${PRESET_STRING}/JOBS_LIST`;

export const initialState = {
  filters: null,
};

const UPDATE_AUDIT_FILTERS = `${AUDITS_PRESET_STRING}/UPDATE_AUDIT_FILTERS`;
function updateAuditFilters(filters) {
  return {
    type: UPDATE_AUDIT_FILTERS,
    filters,
  };
}

export const auditsReducer = createReducerUtil(
  { ...initialState },
  {
    [UPDATE_AUDIT_FILTERS]: (state, action) => ({
      ...state,
      filters: { ...state.filters, ...action.filters },
    }),
  }
);

export const actionTypes = {
  UPDATE_AUDIT_FILTERS,
};

export const actions = {
  updateAuditFilters,
};

