import { createSelector } from 'reselect';
import { ROUTE_STATE_KEY } from './constants';
import { filtersStateToQueryParamsObject } from './utils';
import {
  defaultValue as timeframeDefaultValue,
  persistedTimeframeFilterStateSelector,
} from 'modules/persisted/campaignListTimeframe';
import { get, some, isEmpty } from 'lodash';
import { campaignClassificationDucks } from 'routes/Advertising/ducks';
import { userSelector } from 'modules/user/selectors';
import { getFilterOptionsForClassification } from './components/FilterByPanel/utils';
import {
  selectedTreeNodeSelector,
} from 'routes/Advertising/selectors';
import { treeNodeKeysUtils } from 'routes/Advertising/utils';
import { linkedinIntegratedPagesSelector } from '../../selectors';

export const baseSelector = (state) => state[ROUTE_STATE_KEY];

export const globalStateSelector = createSelector(
  baseSelector,
  (state) => get(state, 'globalState'),
);

export const filtersSelector = createSelector(
  globalStateSelector,
  selectedTreeNodeSelector,
  ({ filters }, selectedTreeNodeKey) => get(filters, selectedTreeNodeKey) || {},
);

export const hasFiltersAppliedSelector = createSelector(
  filtersSelector,
  (filterCategories) => (
    !isEmpty(filterCategories) &&
    some(filterCategories, (category) =>
      some(category, (filter) => filter)
    )
  )
);

export const filterBySelector = createSelector(
  filtersSelector,
  userSelector,
  campaignClassificationDucks.selectors.archivedFolderSelector,
  campaignClassificationDucks.stateGenerators.campaignClassifications.dataSelector,
  selectedTreeNodeSelector,
  linkedinIntegratedPagesSelector,
  (filters, userId, archiveFolder, allFolders, selectedTreeNode, linkedinPages) => {
    const selectedFolderObject = treeNodeKeysUtils.getFolderObject(selectedTreeNode, allFolders);
    const selectedClassificationType = get(selectedFolderObject, 'classification_type');
    const filterOptions = getFilterOptionsForClassification(
      selectedClassificationType,
      userId,
      get(archiveFolder, 'id'),
      linkedinPages,
    );
    // TODO: need to handle browser refresh. Currently, some options may get lost
    // because we have different filters for different folders
    return filtersStateToQueryParamsObject(filters, filterOptions);
  }
);

export const dateRangeFilterSelector = createSelector(
  selectedTreeNodeSelector,
  persistedTimeframeFilterStateSelector,
  (selectedTreeNodeKey, dateRangeFilter) => {
    const folderId = treeNodeKeysUtils.extractFolderId(selectedTreeNodeKey);

    return dateRangeFilter[folderId] || timeframeDefaultValue;
  },
);
