import React from "react";
import { Text } from "@sixsense/core/components";
import { NA } from "./common/NA";
import { CAMPAIGN_SOURCE } from "../../../constants";
import { COLUMN_CATEGORIES } from "../constants";
import { localeString } from "utils/utils";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { isDraftOrScheduledCampaign } from "../../../utils";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const AUDIENCE_REACHED = {
  sortByKey: "audience_reached",
  category: COLUMN_CATEGORIES.retargetingCampaign,
  key: "audience-reached",
  csvColumnKey: "audience_reached",
  title: <Label name={CAMPAIGN_LABELS.audienceReached} />,
  dataIndex: "campaign_data.audience_reached",
  width: pxToRem(190),
  render: (audienceReached, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (
      campaign.campaign_source !== CAMPAIGN_SOURCE.RETARGETING ||
      isDraftOrScheduledCampaign(campaignStatus)
    ) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {localeString(audienceReached)}
      </Text.Body>
    );
  },
  align: "right",
};
