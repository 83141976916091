import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import styles from './TetheredSelect.module.scss';
import { FormComponent } from '../../v2-HOCS';

const TetheredSelect = (props) => {
  const { children, id } = props;
  return (
    <div className={styles.outer} id={id}>
      <Select
        {...props}
        getPopupContainer={() => document.getElementById(id)}
      >
        { children }
      </Select>
    </div>
  );
};

TetheredSelect.propTypes = {
  children: PropTypes.array,
  id: PropTypes.string,
};

TetheredSelect.Option = Select.Option;
const selectMapInputToProps = (input) => ({
  ...input,
});
TetheredSelect.form = FormComponent(TetheredSelect, selectMapInputToProps);

export default TetheredSelect;
