import { get } from "lodash";
import { actions as globalActions } from "modules/global";
import {
  CHANGE_CLASSIFICATION,
  CLASSIFICATIONS,
  CREATE_NEW_CLASSIFICATION,
  DELETE_CLASSIFICATION,
  RENAME_CLASSIFICATION,
} from "./types";
import { actionCreators } from "./actions";
import { getSuccessMessageForAction, isNotCampaignListRoute } from "./utils";
import { stateGenerator } from "routes/AdvertisingDS/routes/Campaigns/stateGenerator";
import { selectors } from "./selectors";

// TODO: Assess if these can be pulled out and are needed?
import {
  deleteFolderTimeframeState,
  initTimeframeFolderStates,
} from "modules/persisted/campaignListTimeframe";
import { STATIC_TREE_NODE_KEYS } from "routes/AdvertisingDS/constants";
import { getObjectIds, maybePlural } from "utils/utils";
import { hotSwapReducerFactory } from "../advertisingClassificationCommon/reducers";
import {
  getMissingFolderTypes,
  reArrangeFolders,
} from "../advertisingClassificationCommon/utils";
import { CLASSIFICATION_TYPES, REQUIRED_FOLDER_TYPES } from "./constants";
import {
  bulkDeleteClassificationBodySelector,
  renameClassificationBodySelector,
} from "../advertisingClassificationCommon/selectors";
import * as commonActions from "routes/AdvertisingDS/ducks/advertisingClassificationCommon/actions";

import { logRollbarError } from "routes/AdvertisingDS/actions";

const initialState = [];

const { showNotification } = globalActions;

const campaignClassifications = stateGenerator({
  baseStateSelector: selectors.baseSelector,
  actionPrefix: CLASSIFICATIONS,
  endpointSelector: selectors.fetchClassificationEndpointSelector,
  stateKey: "folders",
  initialData: initialState,
  receivedDataTransform: ({ results }) => {
    const correctArchiveFolderId = 22417;
    const ignoringDuplicateArchivedFolders = results.filter(
      ({ id, classification_type }) =>
        !(
          classification_type === CLASSIFICATION_TYPES.CAMPAIGNS_ARCHIVED &&
          id !== correctArchiveFolderId
        )
    );

    return reArrangeFolders(
      [
        CLASSIFICATION_TYPES.CAMPAIGNS_UNSORTED,
        CLASSIFICATION_TYPES.CAMPAIGNS_ARCHIVED,
        CLASSIFICATION_TYPES.CAMPAIGN_FOLDER,
      ],
      ignoringDuplicateArchivedFolders
    );
  },
  onFailureActions: [
    () => showNotification("error", "Failed to fetch folder list"),
  ],
  onSuccessActions: [
    // Note: campaignClassifications is a shared entity and is being used in campaigns list
    // slightly differently, it is used to decipher the currently selected folder when
    // we do a goBack() on routes. We do not want that to be reset by other route while still
    // the listCampaign is getting unmounted or is in a pre-unmount
    (folders) =>
      isNotCampaignListRoute()
        ? commonActions.fakeAction(CLASSIFICATIONS)
        : initTimeframeFolderStates([
            STATIC_TREE_NODE_KEYS.CAMPAIGN_ALL,
            ...getObjectIds(folders),
          ]),
    (folders) => {
      const missingFolderTypes = getMissingFolderTypes(
        folders,
        REQUIRED_FOLDER_TYPES
      );
      if (missingFolderTypes.length > 0) {
        return logRollbarError(
          `${missingFolderTypes.toString()} not found in folder list for Campaigns`
        );
      }
      return commonActions.fakeAction(CLASSIFICATIONS);
    },
  ],
  attachReducer: hotSwapReducerFactory(CLASSIFICATIONS),
});

export const changeClassification = stateGenerator({
  baseStateSelector: selectors.baseSelector,
  actionPrefix: CHANGE_CLASSIFICATION,
  endpointSelector: selectors.changeClassificationEndpointSelector,
  stateKey: "changeClassification",
  onFailureActions: [
    (_, rootAction) => {
      const uiAction = get(rootAction, "payload.uiAction");
      return showNotification("error", `Failed to ${uiAction}.`);
    },
  ],
  onSuccessActions: [
    (payload) => actionCreators.replaceClassificationHotSwap(payload),
    (_, rootAction) =>
      showNotification("success", getSuccessMessageForAction(rootAction)),
    () => actionCreators.setClassificationDrawerVisibility(false),
    () => changeClassification.refreshAction(),
  ],
  method: "PATCH",
  requestOptions: selectors.changeClassificationPayloadSelector,
});

export const createClassification = stateGenerator({
  baseStateSelector: selectors.baseSelector,
  actionPrefix: CREATE_NEW_CLASSIFICATION,
  endpointSelector: selectors.classificationEndpointSelector,
  stateKey: "newClassification",
  onFailureActions: [
    (e) => showNotification("error", "Failed to create folder", e.errorMessage),
  ],
  onSuccessActions: [
    () => showNotification("success", "Successfully created folder"),
    (payload) => actionCreators.addClassificationHotSwap(payload),
  ],
  method: "POST",
  requestOptions: selectors.createClassificationPayloadSelector,
});

const deleteClassificationStateGenerator = stateGenerator({
  baseStateSelector: selectors.baseSelector,
  actionPrefix: DELETE_CLASSIFICATION,
  endpointSelector: selectors.bulkDeleteClassificationEndpointSelector,
  stateKey: "bulkDeleteFolders",
  onFailureActions: [
    (e) =>
      showNotification("error", "Failed to delete folders", e.errorMessage),
  ],
  onSuccessActions: [
    (_, rootAction) =>
      showNotification(
        "success",
        `Successfully deleted ${maybePlural(
          rootAction.payload.length,
          "folder"
        )}`
      ),
    (_, rootAction) =>
      actionCreators.deleteClassificationHotSwap(rootAction.payload),
    (_, rootAction) => deleteFolderTimeframeState(rootAction.payload),
  ],
  method: "POST",
  requestOptions: bulkDeleteClassificationBodySelector,
});

const renameClassification = stateGenerator({
  baseStateSelector: selectors.baseSelector,
  actionPrefix: RENAME_CLASSIFICATION,
  endpointSelector: selectors.changeClassificationEndpointSelector,
  stateKey: "renameFolder",
  onFailureActions: [
    () => showNotification("error", "Failed to rename folder"),
  ],
  onSuccessActions: [
    (payload) => actionCreators.replaceClassificationHotSwap(payload),
    () => showNotification("success", "Successfully renamed folder"),
    () => renameClassification.refreshAction(),
  ],
  method: "PATCH",
  requestOptions: renameClassificationBodySelector,
});

export const stateGenerators = {
  campaignClassifications,
  changeClassification,
  createClassification,
  deleteClassificationStateGenerator,
  renameClassification,
};
