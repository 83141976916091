import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, flow } from 'lodash';
import { Button, Checkbox, Modal, Row, Text } from 'v2-components';
import { TablePreviewLayout } from 'aa-components';
import { convertPxToRem } from 'utils/utils';
import { PreviewPane } from './PreviewPane';
import { CreativesList } from './CreativesList';
import styles from './CreativeModal.module.scss';

function CreativeModalComponent(props) {
  const {
    closeCreativeModal,
    creativeModalVisible,
    creativeValue,
    searchCreative,
    creatives,
    totalCreatives,
    loadMoreCreatives,
    isLoading,
    showCreativePreview,
    selectedCreativePreview,
    // updateFormValue,
    orderCreativeList,
    orderBy,
    ordering,

    // multiple ad placements
    addCreative,
    isMultipleAdPlacement,
    selectedCreativeList = [],
    selectedIconList = [],
    selectedLocalCreatives,
    handleChange: addLocalCreativeToState,
    showSelectedAdsOnly: showSelectedAdsOnlyFn,
    showAllSelectedCreative,
    attachmentType,
    extraAdPreviewData,
  } = props;
  const getCreativesList = (pageSize, pageNumber) => {
    const requestedDataLength = pageSize * pageNumber;
    loadMoreCreatives(requestedDataLength - pageSize);
  };

  const resetWhenFn = (oldProps, newProps) => oldProps.creativeValue !== newProps.creativeValue;
  const selectedItems = attachmentType === 'icon' ? selectedIconList : selectedCreativeList;
  const allSelectedCreatives = [...selectedItems, ...selectedLocalCreatives].filter(
    (selectedCreative) => {
      if (creativeValue) {
        const searchString = [
          selectedCreative.name,
          selectedCreative.file_type,
          ...selectedCreative.labels,
        ].join(', ');
        return searchString.toLowerCase().includes(creativeValue.trim().toLowerCase());
      }
      return selectedCreative;
    }
  );
  const creativeListProps = {
    dataset: showAllSelectedCreative ? allSelectedCreatives : creatives,
    totalDataCount: showAllSelectedCreative ? 5 : totalCreatives,
    requestData: getCreativesList,
    resetWhen: resetWhenFn,
    creativeValue,
    showCreativePreview,
    selectedCreativePreview,
    isLoading,
    orderCreativeList,
    orderBy,
    ordering,
    isMultipleAdPlacement,
    selectedLocalCreatives,
    addLocalCreativeToState,
    selectedCreativeList: selectedItems,
  };

  const title = (
    <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
      <Text className={`${styles.modalTitle} ${styles.modalTitleMain}`}>Creatives Library</Text>
      <Text className={styles.modalTitle}>Select or upload at least one creative</Text>
    </Row>
  );

  const addCreativesToPlacementsFp = flow([
    () => addCreative(selectedLocalCreatives, attachmentType),
    () => addLocalCreativeToState([]),
    closeCreativeModal,
  ]);

  const footer = (
    <Fragment>
      <Text pointer onClick={closeCreativeModal}>
        Cancel
      </Text>
      <Button
        type="primary"
        disabled={isEmpty(selectedLocalCreatives)}
        className={styles.addCreativeButton}
        onClick={addCreativesToPlacementsFp}
      >
        Add Creative
      </Button>
    </Fragment>
  );

  const bodyStyle = {
    height: `${convertPxToRem(625)}rem`,
    padding: 0,
  };

  const previewPaneContent = (<PreviewPane
    selectedCreativePreview={selectedCreativePreview}
    extraAdPreviewData={extraAdPreviewData}
  />);

  let creativeListContent;

  if (creatives.length === 0 && !isLoading) {
    creativeListContent = (
      <Row
        justifyContent={Row.JUSTIFY_CONTENT.CENTER}
        alignItems={Row.ALIGN_ITEMS.CENTER}
        style={{ height: '100%' }}
        className={styles.addPadding}
      >
        <Text className={`${styles.modalTitle} ${styles.modalTitleMain}`}>
          {creativeValue !== ''
            ? 'No matches found in your search. Try a different search.'
            : 'No creative available. Upload a new creative'}
        </Text>
      </Row>
    );
  } else {
    creativeListContent = <CreativesList {...creativeListProps} />;
  }

  const searchBarExtraContent =
    isMultipleAdPlacement && totalCreatives ? (
      <Row justifyContent={Row.JUSTIFY_CONTENT.FLEX_END} className={styles.searchBarExtra}>
        <Checkbox
          label={'Show Selected Only'}
          checked={showAllSelectedCreative}
          onChange={(e) => showSelectedAdsOnlyFn(e.target.checked)}
        />
      </Row>
    ) : (
      undefined
    );

  return (
    <Modal
      visible={creativeModalVisible}
      onCancel={() => closeCreativeModal()}
      style={{ top: `${convertPxToRem(50)}rem` }}
      width={`${convertPxToRem(1180)}rem`}
      title={title}
      footer={footer}
      bodyStyle={bodyStyle}
    >
      <TablePreviewLayout
        previewPaneChildren={previewPaneContent}
        tableBodyChildren={creativeListContent}
        searchBarOnChange={searchCreative}
        searchBarPlaceholder={'Search Creatives'}
        searchBarId={'search_creatives'}
        searchBarValue={creativeValue}
        searchBarKey={'creative_searchbar'}
        searchBarExtra={searchBarExtraContent}
      />
    </Modal>
  );
}

CreativeModalComponent.propTypes = {
  creatives: PropTypes.array,
  totalCreatives: PropTypes.number,
  creativeModalVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  creativeValue: PropTypes.string,
  selectedCreativePreview: PropTypes.object,
  closeCreativeModal: PropTypes.func,
  searchCreative: PropTypes.func,
  showCreativePreview: PropTypes.func,
  loadMoreCreatives: PropTypes.func,
  // updateFormValue: PropTypes.func,
  orderCreativeList: PropTypes.func,
  orderBy: PropTypes.string,
  ordering: PropTypes.string,

  // multiple ad placements
  addCreative: PropTypes.func,
  isMultipleAdPlacement: PropTypes.bool,
  selectedLocalCreatives: PropTypes.array,
  handleChange: PropTypes.func,
  showSelectedAdsOnly: PropTypes.func,
  selectedCreativeList: PropTypes.array,
  selectedIconList: PropTypes.array,
  showAllSelectedCreative: PropTypes.bool,
  attachmentType: PropTypes.string,
  extraAdPreviewData: PropTypes.object,
};

export default CreativeModalComponent;
