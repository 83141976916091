import crossfilter2 from 'crossfilter2';
import { omit, keys, reduce, get } from 'lodash';
import { filtersApplier } from './utils';

const CREATE_CROSSFILTER = 'CROSSFILTER_REDUX/CREATE_CROSSFILTER';
const createCrossfilter = (crossfilterKey) => (dataset) => (
  { type: CREATE_CROSSFILTER, crossfilterKey, dataset }
);

const RESET_CROSSFILTER = 'CROSSFILTER_REDUX/RESET_CROSSFILTER';
const resetCrossfilter = (crossfilterKey) => (dataset) => ({
  type: RESET_CROSSFILTER,
  crossfilterKey,
  dataset,
});

const CLEAR_ALL_FILTERS = 'CROSSFILTER_REDUX/CLEAR_ALL_FILTERS';
const clearAllFilters = (crossfilterKey) => () => (
  { type: CLEAR_ALL_FILTERS, crossfilterKey }
);

const DESTROY_CROSSFILTER = 'CROSSFILTER_REDUX/DESTROY_CROSSFILTER';
const destroyCrossfilter = (crossfilterKey) => () => (
  { type: DESTROY_CROSSFILTER, crossfilterKey }
);

const CREATE_DIMENSION = 'CROSSFILTER_REDUX/CREATE_DIMENSION';
const createDimension =
  (crossfilterKey, dimensionKey, dimensionAccessor, isArray, serializer, extra, props) => (
    {
      type: CREATE_DIMENSION,
      crossfilterKey,
      dimensionKey,
      dimensionAccessor,
      isArray,
      serializer,
      extra,
      props,
    });

const DISPOSE_DIMENSION = 'CROSSFILTER_REDUX/DISPOSE_DIMENSION';
const disposeDimension = (crossfilterKey, dimensionKey) => ({
  type: DISPOSE_DIMENSION, crossfilterKey, dimensionKey,
});

const ADD_FILTER = 'CROSSFILTER_REDUX/ADD_FILTER';
const addDimensionFilter = (crossfilterKey, dimensionKey, filterValue, filterType) => (
  { type: ADD_FILTER, crossfilterKey, dimensionKey, filterValue, filterType }
);

const REMOVE_FILTER = 'CROSSFILTER_REDUX/REMOVE_FILTER';
const removeDimensionFilter = (crossfilterKey, dimensionKey, filterValue, filterType) => (
  { type: REMOVE_FILTER, crossfilterKey, dimensionKey, filterValue, filterType }
);

const ADD_OR_REMOVE_FILTER = 'CROSSFILTER_REDUX/ADD_OR_REMOVE_FILTER';
const addOrRemoveDimensionFilter = (crossfilterKey, dimensionKey, filterValue, filterType) => (
  { type: ADD_OR_REMOVE_FILTER, crossfilterKey, dimensionKey, filterValue, filterType }
);

const CLEAR_DIMENSION_FILTERS = 'CROSSFILTER_REDUX/CLEAR_DIMENSION_FILTERS';
const clearDimensionFilters = (crossfilterKey, dimensionKey) => (
  { type: CLEAR_DIMENSION_FILTERS, crossfilterKey, dimensionKey }
);

/* REDUCERS */
export function createReducer(default_filter_type, applyFilter) {
  const applyFilters = filtersApplier(applyFilter);
  const createFilterKey = (filterType, filterValue) =>
    `${filterType}_${JSON.stringify(filterValue)}`;
  const initialState = {};
  function filtersReducer(state = initialState, action, dimension) {
    switch (action.type) {
      case ADD_FILTER: {
        const { filterType = default_filter_type, filterValue } = action;
        const filterKey = createFilterKey(filterType, filterValue);
        const allFilters = {
          ...state,
          [filterKey]: { filterType, filterValue, timestamp: (new Date()).getTime() },
        };
        applyFilters(dimension, allFilters);
        return allFilters;
      }
      case REMOVE_FILTER: {
        const { filterType = default_filter_type, filterValue } = action;
        const filterKey = createFilterKey(filterType, filterValue);
        const allFilters = omit(state, filterKey);
        applyFilters(dimension, allFilters);
        return allFilters;
      }
      case ADD_OR_REMOVE_FILTER: {
        const { filterType = default_filter_type, filterValue } = action;
        const filterKey = createFilterKey(filterType, filterValue);
        const allFilters = get(state, filterKey, false) ?
          omit(state, filterKey) : {
            ...state,
            [filterKey]: { filterType, filterValue, timestamp: (new Date()).getTime() },
          };
        applyFilters(dimension, allFilters);
        return allFilters;
      }
      case CLEAR_DIMENSION_FILTERS: {
        const allFilters = initialState;
        applyFilters(dimension, allFilters);
        return allFilters;
      }
      default:
        return state;
    }
  }

  function dimensionReducer(state = initialState, action, crossfilter) {
    switch (action.type) {
      case CREATE_DIMENSION: {
        const { dimensionKey, dimensionAccessor, isArray, serializer, extra, props } = action;
        if (state[dimensionKey]) {
          return state;
        }
        return {
          ...state,
          [dimensionKey]: {
            dimension: crossfilter.dimension(
              (d) => dimensionAccessor(d, dimensionKey, props), isArray),
            serializer,
            extra,
          },
        };
      }
      case DISPOSE_DIMENSION: {
        const { dimensionKey } = action;
        const dimension = state[dimensionKey];
        if (dimension) {
          dimension.dimension.dispose();
        }
        return omit(state, dimensionKey);
      }
      case ADD_FILTER:
      case REMOVE_FILTER:
      case ADD_OR_REMOVE_FILTER:
      case CLEAR_DIMENSION_FILTERS: {
        const { dimensionKey } = action;
        /* ------------------ remove this shit when keywords are separated -------------------- */
        if (dimensionKey === 'keyword') {
          const { dimension: gDimension, filters: gFilters } = state.generic_keyword_name;
          const { dimension: bDimension, filters: bFilters } = state.branded_keyword_name;
          return {
            ...state,
            generic_keyword_name: {
              ...state.generic_keyword_name,
              filters: filtersReducer(gFilters, action, gDimension),
            },
            branded_keyword_name: {
              ...state.branded_keyword_name,
              filters: filtersReducer(bFilters, action, bDimension),
            },
          };
        }
        /* ------------------ ---------------------------- -------------------- */

        const { dimension, filters } = state[dimensionKey];
        return {
          ...state,
          [dimensionKey]: {
            ...state[dimensionKey],
            filters: filtersReducer(filters, action, dimension),
          },
        };
      }
      default:
        return state;
    }
  }

  function crossfilterReducer(state = initialState, action) {
    switch (action.type) {
      case CREATE_CROSSFILTER: {
        const { crossfilterKey, dataset } = action;
        if (state[crossfilterKey]) {
          return state;
        }
        return {
          ...state,
          [crossfilterKey]: { crossfilter: crossfilter2(dataset) },
        };
      }
      case RESET_CROSSFILTER: {
        const { crossfilterKey, dataset } = action;
        const {
          crossfilter = crossfilter2(),
          ...rest
        } = state[crossfilterKey] || {};
        crossfilter.remove(() => true);
        crossfilter.add(dataset);
        return {
          ...state,
          [crossfilterKey]: {
            crossfilter,
            ...rest,
          },
        };
      }
      case DESTROY_CROSSFILTER : {
        const { crossfilterKey } = action;
        return omit(state, crossfilterKey);
      }
      case CLEAR_ALL_FILTERS: {
        const { crossfilterKey } = action;
        const { crossfilter, dimensions } = state[crossfilterKey];
        return {
          ...state,
          [crossfilterKey]: {
            ...state[crossfilterKey],
            dimensions: reduce(
              keys(dimensions),
              (acc, dimensionKey) => dimensionReducer(
                acc,
                clearDimensionFilters(crossfilterKey, dimensionKey),
                crossfilter
              ),
              dimensions),
          },
        };
      }
      case ADD_FILTER:
      case REMOVE_FILTER:
      case ADD_OR_REMOVE_FILTER:
      case CREATE_DIMENSION:
      case DISPOSE_DIMENSION:
      case CLEAR_DIMENSION_FILTERS: {
        const { crossfilterKey } = action;
        if (!state[crossfilterKey]) {
          // This means that the crossfilter has already been destroyed... idk if
          // we want a more elegant solution
          return state;
        }
        const { crossfilter, dimensions } = state[crossfilterKey];
        return {
          ...state,
          [crossfilterKey]: {
            ...state[crossfilterKey],
            dimensions: dimensionReducer(dimensions, action, crossfilter),
          },
        };
      }
      default:
        return state;
    }
  }
  return crossfilterReducer;
}

export const actions = {
  createCrossfilter,
  resetCrossfilter,
  clearAllFilters,
  addDimensionFilter,
  removeDimensionFilter,
  addOrRemoveDimensionFilter,
  clearDimensionFilters,
  createDimension,
  disposeDimension,
  destroyCrossfilter,
};

export const actionTypes = {
  CREATE_CROSSFILTER,
};
