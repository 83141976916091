import {
  LOGICAL_EXPRESSIONS,
  BETWEENOP,
  VAREXPR,
  AND,
} from 'ast-redux/constants';
import { TLoc, zipper } from 'ast-redux/zipper';
import { includes, some, isEqual, every, get } from 'lodash';
import {
  compareBinExprNodesForCascade,
  compareDfParentNodesForCascade,
  compareDfParentNodesForCascadeNoOrder,
} from './utils.js';
import { not, isA, isBin, isNegBin } from 'ast-redux/utils';
// import { safeGetInPath } from 'zipper/utils.js';

/* Composed type verification */
const isSegmentFilter = (loc) => loc.node().lhs
  && ['in_segment', 'not_in_segment'].includes(loc.node().lhs.name);

const isPredictiveFilter = (loc) => get(loc.node(), 'lhs.name', '').includes('buying_stage');

const isDateFilter = (loc) =>
  (isBin(loc) || isA(BETWEENOP)(TLoc(loc))) &&
  some(
    loc.children(),
    ({ value: child }) => child && child.type === VAREXPR && includes(['span', 'dt'], child.name),
  );

const isDateFilterParent = (loc) =>
  isA(AND)(TLoc(loc)) &&
  some(loc.children(), ({ value: child }) => isDateFilter(zipper(child))); // child = expr

const isDateFilterSibling = (loc) =>
  some(
    TLoc(loc).siblings(),
    ({ value: sibling }) => isDateFilter(zipper(sibling))
  ); // sibling = expr

// receives a date parent: isDateFilterParent(loc) === true
const isDateCascade = (loc) => {
  const rightSibling = TLoc(loc).right();
  // we can't have a cascade if there is no right sibling
  if (!rightSibling) {
    return false;
  }

  // the right sibling could also be a date filter, but if they have unique orders,
  // this isn't a cascade
  const uniqueFilters =
    loc.node().order !== null &&
    rightSibling.node().order !== null &&
    loc.node().order !== rightSibling.node().order;

  if (uniqueFilters) {
    return false;
  }

  const oneDuplicateDate =
    isDateFilterParent(loc) && isDateFilterParent(rightSibling);
  const dateCascade =
    LOGICAL_EXPRESSIONS.has(rightSibling.node().type) &&
    (isDateFilterParent(rightSibling.down().right()) ||
      isDateFilterParent(rightSibling.down()));

  // either the current location AND the right sibling are date filters(one level of duplication)
  // OR one of the right sibling's children are date filters(2+ levels of duplication)
  return oneDuplicateDate || dateCascade;
};

// identify if a node is the last filter in a cascade
// (The right side of the final and)
const isCascadeBottomNode = (loc) => {
  if (TLoc(loc).node().order || !loc.left()) {
    return false;
  }
  const nodesForComparison = [loc.node(), loc.left().node()];

  return isDateFilterParent(loc)
    ? compareDfParentNodesForCascade(...nodesForComparison)
    : compareBinExprNodesForCascade(...nodesForComparison);
};


const isCascadeTopNode = (loc) => {
  if (!TLoc(loc) || !loc.down()) {
    return false;
  }
  return isFirstBinOrDfInCascade(loc.down());
};

// I think this can be replaced with iscascade bottom node OR be renamed
const isLastCascadeNode = (loc) => isEqual(
  loc.down().node().lhs,
  loc
    .down()
    .right()
    .node().lhs,
);

const isFirstBinOrDfInCascade = (loc) => {
  const node = TLoc(loc).node();
  const rhs = loc.right() && loc.right().node();
  if (!rhs) {
    return false;
  }
  const parentLoc = loc.up();
  const parType = parentLoc && parentLoc.node().type;

  const parLeftLoc = parentLoc && parentLoc.left();
  const parLeftNode = parLeftLoc && parLeftLoc.node();

  const rightDownLoc = loc.right().down() && loc.right().down();
  const rightDown = rightDownLoc && rightDownLoc.node();
  const rightDownRight = rightDownLoc && rightDownLoc.right() && rightDownLoc.right().node();

  return meetsAnyCondition([
    meetsAllConditions([
      isDateFilterParent,
      meetsAnyCondition([
        () => compareDfParentNodesForCascadeNoOrder(node, rhs),
        meetsAllConditions([
          () => parType === rhs.type,
          () => compareDfParentNodesForCascadeNoOrder(node, rightDown),
          meetsAnyCondition([
            () => parType === rightDownRight.type,
            () => compareDfParentNodesForCascadeNoOrder(node, rightDownRight),
          ]),
        ]),
      ]),
      () => !compareDfParentNodesForCascadeNoOrder(node, parLeftNode),
    ]),
    meetsAllConditions([
      meetsAnyCondition([
        isBin,
        isNegBin,
      ]),
      meetsAnyCondition([
        () => compareBinExprNodesForCascade(node, rhs),
        meetsAllConditions([
          () => parType === rhs.type,
          () => compareBinExprNodesForCascade(node, rightDown),
          meetsAnyCondition([
            () => parType === rightDownRight.type,
            () => compareBinExprNodesForCascade(node, rightDownRight),
          ]),
        ]),
      ]),
      () => !compareBinExprNodesForCascade(node, parLeftNode),
    ]),
  ])(loc);
};

const isDownstreamCascade = (loc) => {
  const expr = TLoc(loc).node();
  return meetsAnyCondition([
    isFirstBinOrDfInCascade,
    meetsAllConditions([
      // downstream if is rhs and equal to lhs
      (location) => location.left(),
      meetsAnyCondition([
        meetsAllConditions([
          isDateFilterParent,
          (location) => {
            const lExpr = location.left().node();
            return compareDfParentNodesForCascadeNoOrder(expr, lExpr);
          },
        ]),
        (location) => {
          const lExpr = location.left().node();
          return compareBinExprNodesForCascade(expr, lExpr);
        },
      ]),
    ]),
    meetsAllConditions([
      // downstream if is lhs and equal to parent's parent's lhs
      (location) => location.up(),
      (location) => location.up().left(),
      (location) => location.up().node().type === location.up().up().node().type,
      meetsAnyCondition([
        meetsAllConditions([
          isDateFilterParent,
          (location) => {
            const lExpr = location.up().left().node();
            return compareDfParentNodesForCascadeNoOrder(expr, lExpr);
          },
        ]),
        (location) => {
          const lExpr = location.up().left().node();
          return compareBinExprNodesForCascade(expr, lExpr);
        },
      ]),
    ]),
  ])(loc);
};

/* etc */
const meetsAllConditions = (conditions) => (loc) =>
  every(conditions, (cond) => cond(loc));

const meetsAnyCondition = (conditions) => (loc) =>
  some(conditions, (cond) => cond(loc));

const shouldHaveOrder = (loc) => meetsAllConditions([
  not(isDateFilter),
  not(isDateFilterSibling),
  meetsAllConditions([
    meetsAnyCondition([isDateFilterParent, isCascadeTopNode, isBin, isNegBin]),
    not(isDownstreamCascade),
  ]),
])(TLoc(loc));

export {
  isSegmentFilter,
  isDateFilter,
  isDateFilterParent,
  isDateFilterSibling,
  isDateCascade,
  isCascadeBottomNode,
  isCascadeTopNode,
  isLastCascadeNode,
  isFirstBinOrDfInCascade,
  isDownstreamCascade,
  isPredictiveFilter,

  meetsAllConditions,
  meetsAnyCondition,
  shouldHaveOrder,
};
