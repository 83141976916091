import { getAsyncInjectors } from 'store/utils';
import { combineReducers } from 'redux';
import { concat } from 'lodash';

export default function createRoutes(store) {
  const {
    injectReducer,
    injectSagas,
  } = getAsyncInjectors(store);

  return {
    path: 'exclusions',
    name: 'exclusions',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const urlSagas = require('./sagas');
        const domainSagas =
          require('./containers/CompanyBlacklist/components/DomainBlacklist/sagas');
        const ruleSagas = require('./containers/CompanyBlacklist/components/Rules/sagas');
        const adInventoryBlacklistSaga = require('./containers/PublisherListing/sagas');

        const urlReducer = require('./modules');
        const domainReducer =
          require('./containers/CompanyBlacklist/components/DomainBlacklist/modules');
        const rulesReducer = require('./containers/CompanyBlacklist/components/Rules/modules');
        const companyBlConfigReducer = require('./containers/CompanyBlacklist/modules');
        const adInventoryBlacklistReducer = require('./containers/PublisherListing/modules');
        const combinedReducers = combineReducers({
          config: companyBlConfigReducer.default,
          domains: domainReducer.default,
          rules: rulesReducer.default,
          url: urlReducer.default,
          adInventoryBlacklist: adInventoryBlacklistReducer.default,
        });
        const combinedSagas = concat(
          domainSagas.default,
          ruleSagas.default,
          urlSagas.default,
          adInventoryBlacklistSaga.default
        );
        injectReducer('settingsBlacklist', combinedReducers);
        injectSagas('settingsBlacklist', combinedSagas);
        cb(null, containers.BlacklistContainer);
      }, 'settings_blacklist');
    },
  };
}
