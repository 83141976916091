import OverviewRoute from './routes/Overview';
// import ProductRoute from './routes/ProductTaxonomy';
import EventRoute from './routes/EventTaxonomy';
// import RuleBasedMappingRoute from './routes/RuleBasedMapping';
import TaxonomyRoute from './routes/TaxonomyJobs';

export default function createRoutes() {
  return {
    path: 'partner',
    name: 'partner',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/settings/taxonomy/partner/overview'),
    },
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        cb(null, containers.PartnerTaxonomyContainer);
      }, 'partner_settings_taxonomy');
    },
    childRoutes: [
      OverviewRoute(),
      EventRoute(),
      TaxonomyRoute(),
    ],
  };
}
