import { call, all, select } from 'redux-saga/effects';
import { isIframeUserSelector } from '../selectors';
import { App } from '@sixsense/rbac';

export function* getIframeUserCount(request, orgId) {
  try {
    const isIFrameUser = yield select(isIframeUserSelector);
    if (!isIFrameUser) {
      return null;
    }
    const siReportIFrameUsersEndpoint =
    `query/${orgId}/report/aggregated/iframe_si_logins/?span=last_3_months`;
    const [
      {
        aggregated: [
          {
            sales_intelligence_iframe_logins_user_count,
          },
        ],
      },
    ] = yield all([
      call(request, siReportIFrameUsersEndpoint, 'GET'),
    ]);
    return sales_intelligence_iframe_logins_user_count;
  } catch (e) {
    return null;
  }
}

export const getIsApplyAppLicenseing = (appId) => {
  if (appId === App.SALES) {
    return true;
  }
  return false;
};
