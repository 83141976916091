// selectors
// These selectors just get accountDetail and down, not the whole state
import { createSelector } from 'reselect';
import { get, isEmpty } from 'lodash';
import { App, SalesDefaultRole } from '@sixsense/rbac';
import { SI_PlANS } from './constants';
import { getAdminIdListByAppId, getPrimaryAdminListByAppId } from
  'routes/Settings/routes/UserManagementV2/AppManageConfig/apps.roles.config';
import {
  ABM_ROLES_ID,
  CE_ROLES_ID,
  SI_ROLES_ID,
  SIAPP_PLAN_TYPE
  , WF_ROLES_ID,
} from './routes/Integration/routes/Manage/constants';

import {
  isAudienceBetaOrGaCustomerSelector,
  isDataBetaCustomerSelector,
  isDataEnrichmentCustomerSelector,
  isViewOnlyEnabledFor6senseAccessSelector,
  orgObjSelector,
} from 'modules/user/selectors';

const stateSelector = (state) => state;

const settingsSelector = createSelector(
  stateSelector,
  (state) => state.settings,
);

const tokenSelector = createSelector(
  settingsSelector,
  (settings) => settings.token,
);

const tokenThirdPartySelector = createSelector(
  settingsSelector,
  (settings) => settings.tokenThirdParty,
);

const loadSelector = createSelector(
  tokenSelector,
  tokenThirdPartySelector,
  (token, tokenThirdParty) =>
    [token, tokenThirdParty].every((item) => item.loaded),
);

const authTypeSelector = createSelector(
  settingsSelector,
  (settings) => settings?.authType
);

const siPackagePlanNameSelector = createSelector(
  stateSelector,
  (state) => get(state, 'user.user.organization.packagePlan.SI.plan.name', '')
);

const siPackagePlanDeletedSelector = createSelector(
  stateSelector,
  (state) => get(state, 'user.user.organization.packagePlan.SI.plan.is_deleted', '')
);

const isOrgSiOnlySelector = createSelector(
  stateSelector,
  (state) => {
    const userRoles = get(state, 'user.user.app_roles', []);
    const isABM = userRoles.find((el) => el.app_id === App.ABM);
    const isSales = userRoles.find((el) => el.app_id === App.SALES);
    return (!isABM && isSales);
  }
);

const isPlanTeamOrGrowthSelector = createSelector(
  stateSelector,
  (state) => {
    const plan = get(state, 'user.user.organization.packagePlan.SI.plan.name', '');
    return [SI_PlANS.PLAN_C, SI_PlANS.PLAN_D].includes(plan);
  }
);


const isInternalOrStaffUserSelector = createSelector(stateSelector, (state) => (
  get(state, 'user.user.is_staff', false) ||
  get(state, 'user.user.is_internaluser', false)
));

// This flag is required perm in backend APIS to allocate/distribute credits. Adding same in FE.
const hasDataCreditsUsageAllowedFlagSelector = createSelector(
  orgObjSelector,
  ({ flags } = {}) => !!flags.data_credits_usage_allowed
);
// Distribute & Allocate credits Permissions Matrix
// eslint-disable-next-line max-len
// https://6sense.atlassian.net/wiki/spaces/PROD/pages/3885105153/Credit+Allocation+Specs+SI+DWF+AWF#Which-Roles-WILL-have-access-to-Data-Credit-Settings-GOING-FORWARD%3F
const canDistributeCreditsSelector = createSelector(
  hasDataCreditsUsageAllowedFlagSelector,
  isViewOnlyEnabledFor6senseAccessSelector,
  (hasDataCreditsUsageAllowedFlag, isViewOnlyUser) =>
    (hasDataCreditsUsageAllowedFlag || isViewOnlyUser),
);

const canAllocateCreditsToSiUsersSelector = createSelector(
  hasDataCreditsUsageAllowedFlagSelector,
  isViewOnlyEnabledFor6senseAccessSelector,
  (hasDataCreditsUsageAllowedFlag, isViewOnlyUser) =>
    hasDataCreditsUsageAllowedFlag || isViewOnlyUser,
);


const appRolesSelector = createSelector(
  stateSelector,
  (state) => get(state, 'user.user.app_roles', [])
);

const primaryAdminListByAppId = getPrimaryAdminListByAppId();
const adminIdListByAppId = getAdminIdListByAppId();
const doesAppRolesIncludes = (app_roles, roles) => isEmpty(app_roles)
  ? false
  : app_roles.some((role) => roles.includes(role.role_id)
  );


const isPrimaryAdminLoggedInSelector = createSelector(
  appRolesSelector,
  (app_roles) => {
    const isValidABMPA = doesAppRolesIncludes(app_roles, primaryAdminListByAppId[App.ABM]);
    const isValidSIPA = doesAppRolesIncludes(app_roles, primaryAdminListByAppId[App.SALES]);
    const isValidCEPA = doesAppRolesIncludes(app_roles, primaryAdminListByAppId[App.CE]);
    const isValidWorklfowPA = doesAppRolesIncludes(
      app_roles, primaryAdminListByAppId[App.WORKFLOWS]);
    return isValidABMPA || isValidSIPA || isValidWorklfowPA || isValidCEPA;
  }
);


const isAdminLoggedInSelector = createSelector(
  appRolesSelector,
  (app_roles) => {
    const isValidABMAdmin = doesAppRolesIncludes(app_roles, adminIdListByAppId[App.ABM]);
    const isValidSIAdmin = doesAppRolesIncludes(app_roles, adminIdListByAppId[App.SALES]);
    const isValidWorklfowAdmin = doesAppRolesIncludes(
      app_roles, adminIdListByAppId[App.WORKFLOWS]);
    const isValidCEAdmin = doesAppRolesIncludes(app_roles, adminIdListByAppId[App.CE]);
    return isValidABMAdmin || isValidSIAdmin || isValidWorklfowAdmin || isValidCEAdmin;
  }
);


const isWorkflowEnabledSelector = createSelector(
  stateSelector,
  (state) => {
    const app_roles = get(state, 'user.user.app_roles', []);

    const isWorkflowPAAdmin = isEmpty(app_roles)
      ? false
      : app_roles.some(
        (role) =>
          primaryAdminListByAppId[App.WORKFLOWS].includes(role.role_id) ||
          adminIdListByAppId[App.WORKFLOWS].includes(role.role_id)
      );

    return isWorkflowPAAdmin;
  }
);

const siInternalLicenseTypeSelector = createSelector(
  stateSelector,
  isInternalOrStaffUserSelector,
  (state, internalOrStaffUser) => {
    const app_roles = get(state, 'user.user.app_roles', []);
    return internalOrStaffUser && app_roles?.some((role) =>
      [SalesDefaultRole.E_LITE_VIEW_ONLY_6SI_STAFF_ROLE,
        SalesDefaultRole.E_LITE_ADMINISTRATOR,
      ].includes(role.role_id));
  }
);

const enabledAppsForCreditDistributionSelector = createSelector(
  isDataBetaCustomerSelector,
  isDataEnrichmentCustomerSelector,
  isAudienceBetaOrGaCustomerSelector,
  siPackagePlanNameSelector,
  siInternalLicenseTypeSelector,
  siPackagePlanDeletedSelector,
  (
    isDataBetaCustomer,
    isDataEnrichmentCustomer,
    isAudienceBetaOrGaCustomer,
    siPackagePlanName,
    isInternalEntLite,
    siPackagePlanDeleted,
  ) => ({
    sales_intelligence: !siPackagePlanDeleted && siPackagePlanName && !isInternalEntLite,
    audience_workflows: isAudienceBetaOrGaCustomer,
    data_workflows: isDataBetaCustomer || isDataEnrichmentCustomer,
    global: true, // always show
  })
);

// SI Plan specific checks
export const checkIsPrimaryAdminForSIPlan = (SIPlanName, app_roles = []) => {
  const primaryAdminMap = {
    [SIAPP_PLAN_TYPE.C]: [SI_ROLES_ID.C_PRIMARY_ADMINISTRATOR],
    [SIAPP_PLAN_TYPE.D]: [SI_ROLES_ID.D_PRIMARY_ADMINISTRATOR],
    [SIAPP_PLAN_TYPE.E]: [SI_ROLES_ID.E_FULL_PRIMARY_ADMINISTRATOR],
    [SIAPP_PLAN_TYPE.F]: [SI_ROLES_ID.E_LITE_PRIMARY_ADMINISTRATOR],
  };

  return doesAppRolesIncludes(app_roles, primaryAdminMap[SIPlanName] || []);
};

export const checkIsAdminForSIPlan = (SIPlanName, app_roles = []) => {
  const adminMap = {
    [SIAPP_PLAN_TYPE.C]: [
      SI_ROLES_ID.C_ADMINISTRATOR,
    ],
    [SIAPP_PLAN_TYPE.D]: [
      SI_ROLES_ID.D_ADMINISTRATOR,
    ],
    [SIAPP_PLAN_TYPE.E]: [
      SI_ROLES_ID.E_FULL_PRIMARY_ADMINISTRATOR,
    ],
    [SIAPP_PLAN_TYPE.F]: [
      SI_ROLES_ID.E_LITE_ADMINISTRATOR,
    ],
  };

  return doesAppRolesIncludes(app_roles, adminMap[SIPlanName] || []);
};

// ABM specific checks
export const checkIsPrimaryAdminForABM = (app_roles = []) =>
  doesAppRolesIncludes(app_roles, [ABM_ROLES_ID.PRIMARY_ADMINISTRATOR]);

export const checkIsAdminForABM = (app_roles = []) => doesAppRolesIncludes(app_roles, [
  ABM_ROLES_ID.ADMINISTRATOR,
]);

// Workflow specific checks
export const checkIsPrimaryAdminForWorkflows = (app_roles = []) =>
  doesAppRolesIncludes(app_roles, [WF_ROLES_ID.PRIMARY_ADMINISTRATOR]);

export const checkIsWorkflowAdmin = (app_roles = []) =>
  doesAppRolesIncludes(app_roles, [WF_ROLES_ID.WORKFLOW_ADMINISTRATOR]);

// CE specific checks
export const checkIsPrimaryAdminForCE = (app_roles = []) =>
  doesAppRolesIncludes(app_roles, [CE_ROLES_ID.PRIMARY_ADMINISTRATOR]);

export const checkIsAdminForCE = (app_roles = []) => doesAppRolesIncludes(app_roles, [
  CE_ROLES_ID.CE_ADMINISTRATOR,
]);

export const checkIsAdminForAnyApps = (app_roles = []) => (
  checkIsAdminForABM(app_roles) ||
  checkIsWorkflowAdmin(app_roles) ||
  checkIsAdminForSIPlan(SIAPP_PLAN_TYPE.C, app_roles) ||
  checkIsAdminForSIPlan(SIAPP_PLAN_TYPE.D, app_roles) ||
  checkIsAdminForSIPlan(SIAPP_PLAN_TYPE.E, app_roles) ||
  checkIsAdminForSIPlan(SIAPP_PLAN_TYPE.F, app_roles) ||
  checkIsAdminForCE(app_roles)
);

export const checkIsPrimaryAdminForAnyApps = (app_roles = []) => (
  checkIsPrimaryAdminForABM(app_roles) ||
  checkIsPrimaryAdminForWorkflows(app_roles) ||
  checkIsPrimaryAdminForSIPlan(SIAPP_PLAN_TYPE.C, app_roles) ||
  checkIsPrimaryAdminForSIPlan(SIAPP_PLAN_TYPE.D, app_roles) ||
  checkIsPrimaryAdminForSIPlan(SIAPP_PLAN_TYPE.E, app_roles) ||
  checkIsPrimaryAdminForSIPlan(SIAPP_PLAN_TYPE.F, app_roles) ||
  checkIsPrimaryAdminForCE(app_roles)
);

export const checkIsAdminOrPrimaryAdminForAnyApps = (app_roles = []) => (
  checkIsAdminForAnyApps(app_roles) ||
  checkIsPrimaryAdminForAnyApps(app_roles)
);

export {
  settingsSelector,
  tokenSelector,
  tokenThirdPartySelector,
  loadSelector,
  authTypeSelector,
  siPackagePlanNameSelector,
  isOrgSiOnlySelector,
  isPlanTeamOrGrowthSelector,
  isWorkflowEnabledSelector,
  canDistributeCreditsSelector,
  canAllocateCreditsToSiUsersSelector,
  siInternalLicenseTypeSelector,
  isPrimaryAdminLoggedInSelector,
  isAdminLoggedInSelector,
  isInternalOrStaffUserSelector,
  enabledAppsForCreditDistributionSelector,
};
