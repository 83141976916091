import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { forEach, get, has, includes, isEmpty, reduce } from 'lodash';
import { LoadComponent } from 'HOCS';
import { actions } from '../modules.js';
import {
  orgObjSelector,
  orgPropertiesSelector,
  packagesSelector,
  hasDynamicsIntegrationSelector,
  userAppRolesSelector,
  isSettingsManageRouteSelector,
  isSettingsIframeSelect,
  settingsConfigSelector,
  resetAppSettingRandomSelector,
  isSettingsRouteSelector,
  multiAppsListSelector,
} from 'modules/user/selectors';
import {
  getSettingsMapping,
} from '../routes/Manage/constants.js';
import FlagContext, { useFlags } from 'contexts/FlagContext';
import UserContext from 'contexts/UserContext';
import styles from './Drawer.module.scss';
import { RBACContext } from '@sixsense/rbac';
import { classNames } from 'utils/utils';
import {
  FoldLeft,
  FoldRight,
} from '@sixsense/core/icons';
import { useSelector, useDispatch } from '@sixsense/core/versioned/react-redux';
import { actions as userActions } from 'modules/user/index';
import { advertisingSubOptionSelector } from '../routes/Manage/selector';
import { destroyAllParamsNavigate, destroyParamNavigate, getCurrentParams } from 'utils/navigate';
import { Body, CollapseBody } from './Drawer.component';
import { withRouter, browserHistory } from 'react-router';
import {
  MULTI_APP_EVENT_NAME,
  emitMultiAppEventToChildIframe,
  emitMultiAppEventToParentWindow,
} from 'utils/multiAppEventUtils.js';
import { useSettingsDrawerListeners } from 'hooks/useABMMultiAppEventListeners.js';

export const DrawerComponent = (props) => {
  const {
    user,
    packages: { zen, predictive },
    properties: { bombora_topic_cap, si_trial },
    customerPackages,
    hasDynamics,
    org,
    userAppRoles,
    drawerContainerCustomStyle,
    drawerType,
    isNavigation,
    router,
    multiAppsList,
  } = props;

  const isSettingsManageRoute = useSelector(isSettingsManageRouteSelector);
  const isSettingsIframe = useSelector(isSettingsIframeSelect);
  const advertisingSubOption = useSelector(advertisingSubOptionSelector);
  const resetAppSettingRandom = useSelector(resetAppSettingRandomSelector);
  const { iFrameContainerURL } = useSelector(settingsConfigSelector);
  const shouldSubscribeToMultiAppEvents = drawerType === 'embedded'
    && (iFrameContainerURL || isSettingsIframe);

  const dispatch = useDispatch();

  const { permissions, isExternalSixsenseUser } = useContext(RBACContext);
  const flags = useFlags();

  const showInfo = {
    permissions,
    flags,
    user,
    zen,
    predictive,
    bombora_topic_cap,
    customerPackages,
    hasDynamics,
    isExternalSixsenseUser,
    org,
    userAppRoles,
    si_trial,
  };

  const settingsMapping = React.useMemo(
    () => {
      const apps = multiAppsList.reduce((total, app) => ({
        ...total,
        [app.name]: app.enabled,
      }), {});
      return getSettingsMapping(flags, advertisingSubOption, org, apps);
    },
    [flags, advertisingSubOption, org, multiAppsList]
  );

  const appSettingsItem = get(settingsMapping, '0', { id: 'App Settings' });

  const updateDrawerStateFromEvent = (eventData) => {
    const hasDrawerState = has(eventData, 'expandDrawer');
    if (hasDrawerState) {
      const drawerState = get(eventData, 'expandDrawer');
      setIsExpandedDrawer(drawerState);
    }
  };

  const resetAppSettingSelection = useCallback((multiAppEventData) => {
    dispatch(userActions.setIFrameContainerURL(null)); // to rerender iframe if url changes
    let appName;
    if (has(multiAppEventData, 'appName')) {
      appName = multiAppEventData.appName;
    }
    const firstAppInAppSettings = get(appSettingsItem.children, 0)?.id;
    const activeSubItemLocal = appName || currentApp || firstAppInAppSettings;
    onActiveSubItemClick(appSettingsItem.id, activeSubItemLocal, multiAppEventData);
    setExpandedItem(appSettingsItem.id);
    setIsExpandedDrawer(true);
    if (appName || currentApp) {
      const {
        location,
        usePermissions,
      } = findActiveSubItemObject(appSettingsItem.id, activeSubItemLocal);
      const appLocation = location(usePermissions);
      destroyAllParamsNavigate(appLocation);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSettingsItem, currentApp]);

  const mutliAppEventListener = useSettingsDrawerListeners(
    resetAppSettingSelection,
    updateDrawerStateFromEvent,
  );

  const isVisible = ({ requiredPermissions, visibilityCheck }) => {
    if (visibilityCheck && !visibilityCheck(showInfo, isExternalSixsenseUser, permissions)) {
      return false;
    }
    if (
      !isExternalSixsenseUser &&
      requiredPermissions &&
      !requiredPermissions.some((p) => permissions.has(p))
    ) {
      return false;
    }
    return true;
  };

  const {
    isExpandedDrawer,
    expandedItem,
    activeItem,
    activeSubItem,
    currentApp,
  } = useSelector(settingsConfigSelector);

  const setExpandedItem = (val) => {
    dispatch(userActions.setExpandedItem(val));
  };
  const setActiveItem = (val) => {
    dispatch(userActions.setActiveItem(val));
  };
  const setActiveSubItem = (val) => {
    dispatch(userActions.setActiveSubItem(val));
  };
  const setCurrentApp = (val) => {
    dispatch(userActions.setCurrentApp(val));
  };

  const setIsExpandedDrawer = (val) => {
    dispatch(userActions.setIsExpandedDrawer(val));
  };

  const handleDrawerSelectionOnLoad = () => {
    const currentAppFromParam = getCurrentParams(window.location.href).current_app;
    if (currentAppFromParam) {
      setCurrentApp(currentAppFromParam);
      destroyParamNavigate('current_app'); // set current_app to state & remove from URL
      resetAppSettingSelection(); // To select the current app setting in drawer on load
      return;
    }
    const matchingValues = [];
    const checkAndPushMatch = (locationFunc, parentId, subId, usePermissions) => {
      const link = locationFunc(usePermissions);
      if (window.location.href.includes(link)) {
        matchingValues.push({ parentId, subId, link });
      }
    };

    forEach(settingsMapping, ({ id: parentId, children }) => {
      forEach(children, ({ id: subId, subOption, location, usePermissions }) => {
        if (location) {
          checkAndPushMatch(location, parentId, subId, usePermissions);
        }
        forEach(subOption, ({ location: sLocation, usePermissions: sUsePermissions }) => {
          if (sLocation) {
            checkAndPushMatch(sLocation, parentId, subId, sUsePermissions);
          }
        });
      });
    });

    if (matchingValues.length > 0) {
      const { parentId, subId } = reduce(matchingValues, (prev, current) =>
        prev.link.length > current.link.length ? prev : current
      );
      setExpandedItem(parentId);
      onActiveSubItemClick(parentId, subId);
    } else {
      resetAppSettingSelection();
    }
  };

  const handleRouteChangeEmitters = () => {
    const pathname = router.getCurrentLocation().pathname;
    const search = router.getCurrentLocation().search;
    const activePath = encodeURIComponent(pathname + search);
    emitMultiAppEventToParentWindow({
      multiAppEvent: MULTI_APP_EVENT_NAME.EMITTERS.ABM_TO_PARENT_ACTIVE_PATH_SYNC,
      // Child (ABM) --> Parent (WF)
      activePath,
    }); // emit current active path to parent so that parent can show the abm path in URL area
  };


  const onExpandedItemClick = (id) => {
    if (expandedItem === id) {
      setExpandedItem(null);
    } else {
      setExpandedItem(id);
    }
  };

  const findActiveSubItemObject = (itemId, subItemId) => {
    let selectedSubItem;
    forEach(settingsMapping, ({ id: parentId, children }) => {
      if (itemId === parentId) {
        forEach(children, (subItem) => {
          if (subItemId === subItem.id) {
            selectedSubItem = subItem;
          }
        });
      }
    });
    return selectedSubItem;
  };

  const onActiveSubItemClick = (itemId, subItemId, multiAppEventData) => {
    try {
      setActiveItem(itemId);
      setActiveSubItem(subItemId);
      const {
        subOption,
        title,
        id: subId,
        isIframe,
        getIFrameURL,
      } = findActiveSubItemObject(itemId, subItemId);
      let iFrameURL = getIFrameURL ? getIFrameURL() : '';
      const iframeRedirectUrl = get(multiAppEventData, 'iframeRedirectUrl');
      if (iframeRedirectUrl) {
        iFrameURL = iframeRedirectUrl;
      }
      const activeConfigUrl = iFrameURL ? new URL(iFrameURL) : '';
      if (isIframe) {

        if (isEmpty(iFrameContainerURL)) {
          dispatch(userActions.setIFrameContainerURL(iFrameURL));
          // set iframe src only if we are switching from a nonIframe setting page to iframe page
          // or switching from one app that has iframe setting to another app with iframe setting
          // all child navigations has to be done via events. This is done to avoid frequent
          // reloads when events are emitted / settings are switched.
        } else {
          const activeHost = (new URL(iFrameContainerURL)).host;
          const activeConfigUrlHost = activeConfigUrl ? activeConfigUrl.host : '';
          if (activeHost !== activeConfigUrlHost) {
            dispatch(userActions.setIFrameContainerURL(iFrameURL));
          }
        }
        const isPassThrougEvent = includes(
          get(multiAppEventData, 'multiAppEvent'), 'dataPassThrough');
        if (!isPassThrougEvent && iFrameContainerURL === iFrameURL) {
          // current app is iframeApp, load list page of child
          emitMultiAppEventToChildIframe({
            multiAppEvent: MULTI_APP_EVENT_NAME.EMITTERS.ABM_TO_CHILD_LOAD_APP_SETTINGS_LISTPAGE,
          }, iFrameContainerURL);
        }
        const isInMpIframe = getCurrentParams(
          window.location.href)?.activeContext === 'mapping-profiles';
        if (isInMpIframe) {
          emitMultiAppEventToChildIframe({
            multiAppEvent: MULTI_APP_EVENT_NAME.EMITTERS.ABM_TO_CHILD_LOAD_MAPPING_PROFILES_PAGE,
          });
        }
      } else {
        dispatch(userActions.setIFrameContainerURL(null));
      }
      if (subOption) {
        dispatch(userActions.setActiveSettings({ list: subOption, title, id: subId }));
      }
    } catch (e) {
      console.error('Failed to select sub item in drawer - onActiveSubItemClick');
    }
  };

  const onActiveItemClick = (itemId, subItemId, isIframe, iFrameURL) => {
    setActiveItem(itemId);
    setActiveSubItem(subItemId);
    if (!isIframe) {
      setIsExpandedDrawer(false);
    }
    // This is done to prevent redirection for integrations on calling loadOrg()
    if (itemId === 'Integration Settings') {
      dispatch(userActions.setResetAppSettingRandom(null));
    }
    if (isIframe) {
      dispatch(userActions.setIFrameContainerURL(iFrameURL));
      const isInMpIframe = getCurrentParams(
          window.location.href)?.activeContext === 'mapping-profiles';
      const loadListPageEvt = isInMpIframe ?
      MULTI_APP_EVENT_NAME.EMITTERS.ABM_TO_CHILD_LOAD_MAPPING_PROFILES_PAGE
      : MULTI_APP_EVENT_NAME.EMITTERS.ABM_TO_CHILD_LOAD_APP_SETTINGS_LISTPAGE;
      emitMultiAppEventToChildIframe({
        multiAppEvent: loadListPageEvt,
        expandDrawer: true,
      }, iFrameContainerURL);
    } else {
      dispatch(userActions.setIFrameContainerURL(null));
    }
  };

  const titleConversion = (title) => title;

  React.useEffect(() => {
    if (resetAppSettingRandom) {
      resetAppSettingSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAppSettingRandom]);

  React.useEffect(() => {
    let unSubscribeRouteListener = () => {};
    unSubscribeRouteListener = browserHistory.listen(handleRouteChangeEmitters);
    handleRouteChangeEmitters(); // emit active URL to parent window on first load

    handleDrawerSelectionOnLoad();
    if (!isSettingsManageRoute) {
      setIsExpandedDrawer(false);
    }
    return () => { // destructor
      emitMultiAppEventToParentWindow({
        multiAppEvent: MULTI_APP_EVENT_NAME.EMITTERS.ABM_TO_PARENT_SETTINGS_DRAWER_UNLOADED,
        // Child (ABM) --> Parent (WF)
      }); // when session timeout occurs, page gets redirected and ABM should notify the parent
      unSubscribeRouteListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (shouldSubscribeToMultiAppEvents) {
      // Listener - listens to child events
      window.addEventListener('message', mutliAppEventListener, false);

      emitMultiAppEventToParentWindow({
        multiAppEvent: MULTI_APP_EVENT_NAME.EMITTERS.ABM_TO_PARENT_SETTINGS_DRAWER_LOADED,
      }); // emit onLoad event to parent to stop loader

    } else {
      window.removeEventListener('message', mutliAppEventListener);
    }
    return () => window.removeEventListener('message', mutliAppEventListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iFrameContainerURL, isSettingsIframe, mutliAppEventListener]);


  return (
    <React.Fragment>
      <div
        className={classNames(
          styles.drawerContainer,
          !isExpandedDrawer ? styles.collapsedContainer : null,
          drawerType === 'overlay' ? styles.overlayDrawerContainer : null,
          drawerType === 'embedded' ? styles.embeddedDrawerContainer : null,
          isSettingsManageRoute && drawerType === 'overlay' ? styles.dnone : null,
          !isSettingsManageRoute && drawerType === 'embedded' ?
            styles.dnone : null,
          drawerContainerCustomStyle,
          isSettingsIframe && drawerType === 'overlay' ? styles.isIframeOverlay : null,
          isSettingsIframe && drawerType === 'embedded' ? styles.isIframeEmbedded : null,
          !isNavigation && drawerType === 'overlay' ? styles.leftZero : null,
        )}
        onClick={() => !isExpandedDrawer && setIsExpandedDrawer(true)}
      >
        <span
          className={classNames(styles.foldIconContainer)}
          onClick={() => setIsExpandedDrawer(!isExpandedDrawer)}
          data-6si-id={isExpandedDrawer ? 'fold-left' : 'fold-right'}
          data-6si-name={isExpandedDrawer ? 'fold-left' : 'fold-right'}
        >
          {isExpandedDrawer ? (
            <FoldLeft
              color="#111927"
              className={classNames(styles.icon)}
            />
          ) : (
            <FoldRight
              color="#111927"
              className={classNames(styles.icon)}
            />
          )}
        </span>
        <Body
          isExpandedDrawer={isExpandedDrawer}
          isVisible={isVisible}
          settingsMapping={settingsMapping}
          activeItem={activeItem}
          activeSubItem={activeSubItem}
          expandedItem={expandedItem}
          onActiveItemClick={onActiveItemClick}
          onActiveSubItemClick={onActiveSubItemClick}
          onExpandedItemClick={onExpandedItemClick}
          titleConversion={titleConversion}
        />
        <CollapseBody
          isExpandedDrawer={isExpandedDrawer}
        />
      </div>
    </React.Fragment>);

};

DrawerComponent.propTypes = {
  properties: PropTypes.object,
  packages: PropTypes.any,
  user: PropTypes.any,
  customerPackages: PropTypes.any,
  hasDynamics: PropTypes.bool,
  org: PropTypes.bool,
  userAppRoles: PropTypes.array,
  drawerContainerCustomStyle: PropTypes.any,
  drawerType: PropTypes.oneOf(['overlay', 'embedded']),
  isNavigation: PropTypes.bool,
  router: PropTypes.object,
  multiAppsList: PropTypes.array,
};

const mapStateToProps = (state) => ({
  loading: false, // ?
  loaded: true,
  org: orgObjSelector(state),
  properties: orgPropertiesSelector(state),
  customerPackages: packagesSelector(state),
  hasDynamics: hasDynamicsIntegrationSelector(state),
  userAppRoles: userAppRolesSelector(state),
  multiAppsList: multiAppsListSelector(state),
});

const manageActions = { ...actions };

const DrawerWrapper = (props) => {
  const isSettingsRoute = useSelector(isSettingsRouteSelector);
  if (!isSettingsRoute) {
    return null;
  }
  return <DrawerComponent {...props} />;
};

export default compose(
  FlagContext.FlagConsumer,
  UserContext.UserConsumer,
  connect(mapStateToProps, manageActions),
  LoadComponent
)(withRouter(DrawerWrapper));
