import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RcSelect, { Option } from 'rc-select';
import { FormComponent } from 'v2-HOCS';
import { Row, Col, Icon, Tooltip2, Text, Loading2 } from 'v2-components';
import 'rc-select/assets/index.css';
import styles from './Select.module.scss';
import { isNumber, get } from 'lodash';

/**
* Select Component
*
* This component replaces the old ant-d select component in place of the ‘rc-select’
* component. See: https://www.npmjs.com/package/rc-select
*
* The select component preserves all functionality from the old antd select component.
* As well, the following changes have been made in conjuction with the platform
* header redesign project:
*
*   - new default styles as per the header redesign mocks
*   - label can be optionally replaced by a tooltip when hovering select component.
*     (see tooltipText prop)
*   - custom icon instead of default chevron icon (see icon prop)
*   - custom dropdown menu widths and styles
*
* @param {bool} allowClear - whether selected value can be cleared
* @param {string} rowClassName - classname for styling row that wraps select
* @param {string} defaultValue - default value to display in select
* @param {bool} disabled - whether select is disabled
* @param {bool} dropdownMatchSelectWidth - whether dropdown matches width of select.
* @param {number} dropdownWidthOffset - dropdown menu width offset from width of select.
* @param {number} dropdownWidth - width of dropdown
* @param {object} dropdownMenuStyle - additional custom styles for dropdown menu if necessary
* @param {bool, func} filterOption  - filtering of dropdown menu options
* @param {string} id - id for select component
* @param {string} labelKey - key for option labels
* @param {func} onChange - function for when selected value changes
* @param {array of objects} options - options for select
* @param {string} placeholder - label displayed if no option currently selected
* @param {bool} tether - tether select to parent node. Useful for components with varying z indexes.
*   requires id prop for identifying parent node.
* @param {string} titleKey  - key for option title
* @param {bool} showSearch - whether searching is allowed
* @param {string} mode - one of 'default', 'multiple'. allows multiple values to be selected.
* @param {string} optionClassName - classname for dropdown menu options
* @param {string} dropdownClassName - classname for dropdown menu
* @param {string} justifySelect - flex positioning for select
* @param {string} value - string value displayed for selected value.
* @param {string} valueKey - key for option values
* @param {string, number} width - width of select
* @param {string, number} minWidth - min width if no set width provided.
* @param {string, number} maxWidth - max width if no set width provided.
* @param {bool} open - whether select dropdown menu is open or not
* @param {string} selectKey - key for select component
* @param {bool} allowEmptyValue - whether empty values allowed
* @param {string} tooltipText - text for tooltip displayed on hovering select
* @param {string} icon - string for icon to be displayed if using custom icon
* @param {string} label  - label for dropdown (positionedleft of select)
* @param {string} labelType - label text type (title, subbody etc)
* @param {string} labelClassName - classname for label component
* @param {string} containerClassName  - classname for outermost parent of this component
* @param {string} optionsTooltipLengthCheck  - Length check for option values in number.
*/

const Select = (props) => {
  const {
    allowClear,
    rowClassName,
    defaultValue,
    disabled,
    dropdownMatchSelectWidth,
    dropdownWidthOffset,
    dropdownWidth,
    dropdownMenuStyle,
    filterOption,
    id,
    labelKey,
    onChange,
    options,
    placeholder,
    tether,
    titleKey,
    showSearch,
    mode,
    optionClassName,
    dropdownClassName,
    justifySelect,
    value,
    valueKey,
    width,
    minWidth,
    maxWidth,
    open,
    selectKey,
    allowEmptyValue,
    tooltipText,
    icon,
    label,
    labelType,
    labelClassName,
    containerClassName,
    optionsTooltipLengthCheck,
    tooltipKey,
    loading,
    useCustomOptions,
    ...restProp
  } = props;

  const style = {
    width,
    minWidth,
    maxWidth,
    fontFamily: 'Open Sans, sans-serif',
  };

  const dropdownStyle = {
    width: dropdownWidth,
    ...dropdownMenuStyle,
  };

  const mapOption = (option) => {
    const optionValue = option[valueKey];
    const title = option[titleKey];
    const extraClass = get(option, 'className');
    const dataPendo = get(option, 'dataPendo');
    // If you want custom tooltip to get displayed then don't pass title prop or vice versa.
    // Just pass optionsTooltipLengthCheck prop in number
    // If you need other tooltip value to be shown than actual display value pass tooltipKey prop

    // NOTE: if you use custom tooltip please check your
    // filterOption prop conditions while passing from component.
    //
    // optionMeta is the entire option object used to generate the option, use this field
    // in your filterOption as this is much cleaner than accessing or relying on specific
    // children of the option
    return (
      <Option
        key={optionValue}
        value={optionValue}
        title={title}
        disabled={option.disabled}
        className={`${styles.option} ${optionClassName} ${extraClass && extraClass}`}
        optionMeta={option}
        data-pendo={dataPendo}
      >
        {isNumber(optionsTooltipLengthCheck) ?
          <Tooltip2
            placement={'top'}
            overlayStyle={{ maxWidth: '300px', wordBreak: 'break-all' }}
            overlay={(option[labelKey].length >= optionsTooltipLengthCheck) ?
              (<Text color={Text.COLOR.WHITE}>{option[tooltipKey || labelKey]}</Text>) : ''
            }
          >
            {option[labelKey]}
          </Tooltip2>
          :
          option[labelKey]
        }
      </Option>
    );
  };

  const blurSelect = () => {
    // The select element does not lose focus after selecting an element by default.
    // Blurring it programatically allows us to hide the tooltip correctly.
    const selectRow = document.getElementById(id);
    const selectElements = selectRow.getElementsByClassName('rc-select-selection');
    selectElements[0].blur();
  };

  const optionItems = useCustomOptions ? options : options.map((option) => mapOption(option));

  let valueProp = {};

  if (allowEmptyValue || ((value || value === 0) && value !== '')) {
    valueProp = { value };
  }

  if (defaultValue !== undefined) {
    valueProp = { ...valueProp, defaultValue };
  }

  const openState = open ? { open } : {};

  // Local state boolean for toggling hiding/displaying tooltip.
  const [tooltipVisible, toggleHideTooltip] = useState(true);

  let labelComponent;
  if (label) {
    labelComponent = (
      <Col alignSelf="center" className={`${labelClassName} ${styles.label}`}>
        <Text
          color="grey1"
          type={labelType || Text.TYPE.SUBBODY}
        >
          {label}
        </Text>
      </Col>
    );
  }

  const inputIcon = props.loading ? <Loading2 size="small" /> : (
    <Icon
      className={styles.inputIcon}
      type={icon || 'keyboard_arrow_down'}
      color={Icon.COLORS.GREY}
    />
  );

  const selectComponent = (
    <RcSelect
      onFocus={() => toggleHideTooltip(!tooltipVisible)}
      onBlur={() => toggleHideTooltip(!tooltipVisible)}
      onSelect={blurSelect}
      multiple={mode === 'multiple'}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      dropdownClassName={`${dropdownClassName} ${showSearch && styles.showSearchStyles}`}
      style={style}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      filterOption={filterOption}
      allowClear={allowClear}
      getPopupContainer={tether ? () => document.getElementById(id) : null}
      inputIcon={inputIcon}
      dropdownAlign={{ offset: [-dropdownWidthOffset, 5] }}
      dropdownMenuStyle={dropdownStyle}
      optionLabelProp={'children'}
      showSearch={showSearch}
      loading={loading}
      {...openState}
      {...valueProp}
      {...restProp}
    >
      {optionItems}
    </RcSelect>
  );

  return (
    <div key={selectKey} className={`${styles.outerClass} ${containerClassName}`} id={id}>
      <Col>
        <Row
          justifyContent={justifySelect}
          className={`
            ${styles.selectStyles}
            ${icon && styles.selectCustomIcon}
            ${showSearch && styles.showSearchStyles}
            ${rowClassName}
          `}
        >
          {labelComponent}
          <Tooltip2
            placement="bottomLeft"
            overlay={tooltipText || ''}
            overlayClassName={`${styles.overlay} ${!tooltipVisible ? styles.hideTooltip : null}`}
            style={style}
            mouseLeaveDelay={0}
          >
            {selectComponent}
          </Tooltip2>
        </Row>
      </Col>
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  rowClassName: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.object, PropTypes.element)),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  showSearch: PropTypes.bool,
  dropdownMatchSelectWidth: PropTypes.bool,
  dropdownWidthOffset: PropTypes.number,
  dropdownWidth: PropTypes.number,
  filterOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  allowClear: PropTypes.bool,
  tether: PropTypes.bool,
  titleKey: PropTypes.string,
  mode: PropTypes.string,
  optionClassName: PropTypes.string,
  dropdownClassName: PropTypes.string,
  justifySelect: PropTypes.string,
  open: PropTypes.bool,
  selectKey: PropTypes.string,
  allowEmptyValue: PropTypes.bool,
  tooltipText: PropTypes.string,
  icon: PropTypes.string,
  dropdownMenuStyle: PropTypes.object,
  label: PropTypes.string,
  labelType: PropTypes.string,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  optionsTooltipLengthCheck: PropTypes.number,
  tooltipKey: PropTypes.string,
  loading: PropTypes.bool,
  useCustomOptions: PropTypes.bool,
};

Select.defaultProps = {
  id: 'selectRow',
  width: 200,
  maxWidth: 300,
  filterOption: true,
  dropdownMatchSelectWidth: true,
  labelKey: 'label',
  valueKey: 'value',
  titleKey: 'title',
  mode: 'default',
  justifySelect: 'center',
  alignSelect: 'stretch',
  suffixIcon: null,
  allowEmptyValue: false,
  showSearch: false,
  loading: false,
  useCustomOptions: false,
};

const mapInputToProps = (input, formProps) => ({
  value: formProps.mode === 'multiple' ? ((!input.value && []) || input.value) : input.value,
  onChange: (value, label) => (value === undefined ?
    input.onChange(null, label) : input.onChange(value, label)),
});

Select.form = FormComponent(Select, mapInputToProps);

// Default icons based on Steven's definitions of our standard dropdowns.
Select.SORT_ICON = 'format_line_spacing';
Select.DATE_ICON = 'calendar_today';
Select.FILTER_ICON = 'visibility';

export default Select;
