import { Permission, SalesPermission, WorkflowsPermission } from '@sixsense/rbac';

export const CAMPAIGN_PERMISSIONS = [
  Permission.CAMPAIGN_VIEW,
  Permission.CAMPAIGN_EDIT,
];
export const ADS_LIBRARY_PERMISSIONS = [
  Permission.ADS_VIEW,
  Permission.ADS_EDIT,
];
export const GOOGLE_ADS_PERMISSIONS = [
  Permission.SEGMENT_EDIT,
];
export const VIEW_DASHBOARD_PERMISSIONS = [Permission.DASHBOARD_VIEW];
export const VIEW_SEGMENT_PERMISSIONS = [Permission.SEGMENT_VIEW, Permission.SEGMENT_EDIT];
export const VIEW_ALERT_PERMISSIONS = [Permission.ALERT_EDIT];
export const VIEW_REPORTS_PERMISSIONS = [Permission.REPORTING_VIEW];
export const VIEW_REPORTS_6QA_PERMISSIONS = [Permission.REPORTING_6QA_VIEW];
export const VIEW_SI_SETTINGS_PERMISSIONS = [Permission.SETTINGS_SALES_INTELLIGENCE_VIEW];
export const EDIT_SI_SETTINGS_PERMISSIONS = [Permission.SETTINGS_SALES_INTELLIGENCE_EDIT];
export const VIEW_ACTIONS_PERMISSIONS = [Permission.SETTINGS_ACTIONS_VIEW];
export const EDIT_ACTIONS_PERMISSIONS = [Permission.SETTINGS_ACTIONS_EDIT];
export const EDIT_CAMPAIGN_PERMISSIONS = [Permission.CAMPAIGN_EDIT];
export const ORCHESTRATIONS_PERMISSIONS = [
  Permission.ORCHESTRATION_VIEW,
  Permission.ORCHESTRATION_EDIT,
];
export const EDIT_ORCHESTRATION_PERMISSION = [Permission.ORCHESTRATION_EDIT];
export const AUDIENCE_WORKFLOWS_PERMISSION = [
  WorkflowsPermission.AUDIENCE_WORKFLOWS_VIEW,
  WorkflowsPermission.AUDIENCE_WORKFLOWS_EDIT,
];
export const TPR_SETTINGS_PERMISSIONS = [Permission.SETTINGS_SALES_INTELLIGENCE_TPR_EDIT];
export const SETTINGS_PERMISSIONS = [
  Permission.CAMPAIGN_EDIT,
  Permission.SETTINGS_EXCLUSIONS_INVENTORY_VIEW,
  Permission.SETTINGS_EXCLUSIONS_WEBSITE_VIEW,
  Permission.SETTINGS_EXCLUSIONS_COMPANY_VIEW,
  Permission.SETTINGS_EXCLUSIONS_INVENTORY_EDIT,
  Permission.SETTINGS_EXCLUSIONS_WEBSITE_EDIT,
  Permission.SETTINGS_EXCLUSIONS_COMPANY_EDIT,
  Permission.SETTINGS_KEYWORDS_VIEW,
  Permission.SETTINGS_KEYWORDS_EDIT,
  Permission.SETTINGS_BOMBORA_VIEW,
  Permission.SETTINGS_BOMBORA_EDIT,
  Permission.SETTINGS_WEB_TAG_VIEW,
  Permission.SETTINGS_WEB_TAG_EDIT,
  Permission.SETTINGS_API_KEY_VIEW,
  Permission.SETTINGS_API_KEY_EDIT,
  Permission.SETTINGS_INTEGRATION_CRM_VIEW,
  Permission.SETTINGS_INTEGRATION_CRM_EDIT,
  Permission.SETTINGS_INTEGRATION_MAP_VIEW,
  Permission.SETTINGS_INTEGRATION_MAP_EDIT,
  Permission.SETTINGS_INTEGRATION_PARTNER_INTEGRATION_VIEW,
  Permission.SETTINGS_INTEGRATION_PARTNER_INTEGRATION_EDIT,
  Permission.SETTINGS_INTEGRATION_SEP_VIEW,
  Permission.SETTINGS_INTEGRATION_SEP_EDIT,
  Permission.SETTINGS_INTEGRATION_SLACK_VIEW,
  Permission.SETTINGS_INTEGRATION_SLACK_EDIT,
  Permission.SETTINGS_MAPPINGS_PROFILE_EDIT,
  Permission.SETTINGS_CONTACT_PURCHASE_LIMIT_EDIT,
  Permission.SETTINGS_ACCOUNT_MATCHING_EDIT,
  Permission.SETTINGS_NOTIFICATIONS_VIEW,
  Permission.SETTINGS_NOTIFICATIONS_EDIT,
  Permission.SETTINGS_PUBLISH_SEGMENT_VIEW,
  Permission.SETTINGS_PUBLISH_SEGMENT_EDIT,
  Permission.SETTINGS_SALES_INTELLIGENCE_VIEW,
  Permission.SETTINGS_SALES_INTELLIGENCE_EDIT,
  Permission.SETTINGS_SALES_INTELLIGENCE_TPR_EDIT,
  Permission.SETTINGS_ACTIONS_VIEW,
  Permission.SETTINGS_ACTIONS_EDIT,
  Permission.SETTINGS_USER_MANAGEMENT_VIEW,
  Permission.SETTINGS_USER_MANAGEMENT_FETCH,
  Permission.SETTINGS_USER_MANAGEMENT_EDIT_USER,
  Permission.SETTINGS_USER_MANAGEMENT_ADMIN_ASSIGNMENT,
  Permission.SETTINGS_USER_MANAGEMENT_OWNER_ASSIGNMENT,
  Permission.SETTINGS_ADMIN_REPORTS_VIEW,
  Permission.SETTINGS_FISCAL_YEAR_VIEW,
  Permission.SETTINGS_FISCAL_YEAR_EDIT,
  WorkflowsPermission.TIE_BREAK_SETTINGS_VIEW,
  WorkflowsPermission.TERRITORY_MGMT_VIEW,
  WorkflowsPermission.ROUND_ROBIN_VIEW,
  WorkflowsPermission.MERGE_SETTINGS_VIEW,
  WorkflowsPermission.MAPPING_PROFILE_VIEW,
  WorkflowsPermission.CREDIT_SETTINGS_VIEW,
  SalesPermission.SETTINGS_SI_MAPPING_PROFILES_CRM_EDIT,
  SalesPermission.SETTINGS_SI_MAPPING_PROFILES_EDIT,
];

export const PIPELINE_INTELLIGENCE_PERMISSIONS = [
  Permission.PIPELINE_INTELLIGENCE_EDIT, Permission.PIPELINE_INTELLIGENCE_VIEW];

export const SMART_FORM_FILL_PERMISSIONS = [
  Permission.SETTINGS_WEB_TAG_VIEW,
  Permission.SETTINGS_WEB_TAG_EDIT,
];

const FUNNEL_INSIGHTS_SUPER_USERS = [
  'greg.chamberlain@6sense.com',
  'michael@6sense.com',
  'albert.yam@6sense.com',
  'testuser@6sense.com',
];

const FUNNEL_INSIGHTS_INTERNAL_USERS = [
  ...FUNNEL_INSIGHTS_SUPER_USERS,
  'kory.geyer@6sense.com',
  'chris.dutton@6sense.com',
  'latane.conant@6sense.com',
  'viral@6sense.com',
  'rahul.sachdev@6sense.com',
  'amar@6sense.com',
  'premal@6sense.com',
  'vibhor.gaur@6sense.com',
  'ken@6sense.com',
  'john.he@6sense.com',
  'shantanu.sinha@6sense.com',
  'steven.sassaman@6sense.com',
  'kerry.cunningham@6sense.com',
  'eric.wittlake@6sense.com',
  // Turn on CSM emails on 2021-10-18
  // 'alexandra.lewis@6sense.com',
  // 'caroline.oneill@6sense.com',
  // 'daniel.lapushin@6sense.com',
];

export const FUNNEL_INSIGHTS_ORG_WHITELIST = {
  '6sense': new Set(FUNNEL_INSIGHTS_INTERNAL_USERS),
  centro: new Set([
    ...FUNNEL_INSIGHTS_SUPER_USERS,
    'bryce.atkinson@6sense.com',
    'kelli.hutt@centro.net',
    'tyler.mcnamara@centro.net',
  ]),
  highradius: new Set(FUNNEL_INSIGHTS_SUPER_USERS),
  xactly: new Set([
    ...FUNNEL_INSIGHTS_SUPER_USERS,
    'ashaw@xactlycorp.com',
    'acaldwell@xactlycorp.com',
    'edishman@xactlycorp.com',
    'gknudsen@xactlycorp.com',
    'pcash@xactlycorp.com',
    'bryce.atkinson@6sense.com',
  ]),
  deluxe: new Set([
    ...FUNNEL_INSIGHTS_SUPER_USERS,
    'megan.coats@6sense.com',
    'sarah.wiese@deluxe.com',
    'jeremy.mcmillan@deluxe.com',
    'dan.a.lewis@deluxe.com',
    'debra.tassone@deluxe.com',
    'steve.brown@deluxe.com',
    'wendy.flaherty@deluxe.com',
    'awoodle@deluxe.com',
  ]),
  windstream: new Set(FUNNEL_INSIGHTS_SUPER_USERS),
  smartsheet: new Set([
    ...FUNNEL_INSIGHTS_SUPER_USERS,
    'charina.figueroa@6sense.com',
    'renee.coulombe@smartsheet.com',
    'samantha.bourne@smartsheet.com',
    'bridget.durdy@smartsheet.com',
  ]),
  copado: new Set([
    ...FUNNEL_INSIGHTS_SUPER_USERS,
    'cameron@copado.com',
    'mmessina@copado.com',
    'jared.chism@6sense.com',
  ]),
  automationanywhere: new Set(FUNNEL_INSIGHTS_SUPER_USERS),
};
