import { getAsyncInjectors } from "store/utils";
import { CREATE_EDIT_AD_ROUTE, PAGE_STATE_KEY } from "./constants";

export default function createEditAdRoute(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: CREATE_EDIT_AD_ROUTE,
    name: "create-edit-ad",

    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require("./containers");
          const sagas = require("./sagas");
          const reducer = require("./modules");
          injectReducer(PAGE_STATE_KEY, reducer.default);
          injectSagas(PAGE_STATE_KEY, sagas.default);
          cb(null, containers.default);
        },
        "create-edit-ad"
      );
    },
  };
}
