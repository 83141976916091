import { omit } from 'lodash';
import { createReducerUtil } from 'utils/core';
import { PRESET_STRING } from '../constants';

export const initialState = {
  rules: [], // rules array used to render the rules list
  // rules array used to save a copy of original rules order when user click change rule order btn
  rulesBeforeReorder: [],
  isDeleteRuleCfmModalOpen: false, //  bool used to show/hide the delete rule confirmation modal
  isDisableRuleCfmModalOpen: false, //  bool used to show/hide the disable rule confirmation modal
  isEnableRuleCfmModalOpen: false, //  bool used to show/hide the enable rule confirmation modal
  // bool used to indicate that user has clicked Change Rule order btn, and is making changes
  isChangeRuleOrderInProgress: false,
  // bool used to show/hide the update rule order confirmation modal
  isUpdateRuleOrderCfmModalOpen: false,
  ruleToDelete: null, // stores the rule which will get deleted once confirmed from delete modal
  // array to store the impacted records count for all rules for
  //  given classifcationType & dataSource
  recordCounts: [],
};

export const setPayload = (state, action) => {
  const payload = omit(action, 'type');
  return { ...state, ...payload };
};

const SET_DELETE_RULE_CFM_MODAL_OPEN = `${PRESET_STRING}/SET_DELETE_RULE_CFM_MODAL_OPEN`;
function setDeleteRuleCfmModalOpen(isDeleteRuleCfmModalOpen) {
  return {
    type: SET_DELETE_RULE_CFM_MODAL_OPEN,
    isDeleteRuleCfmModalOpen,
  };
}

const SET_DISABLE_RULE_CFM_MODAL_OPEN = `${PRESET_STRING}/SET_DISABLE_RULE_CFM_MODAL_OPEN`;
function setDisableRuleCfmModalOpen(isDisableRuleCfmModalOpen) {
  return {
    type: SET_DISABLE_RULE_CFM_MODAL_OPEN,
    isDisableRuleCfmModalOpen,
  };
}

const SET_ENABLE_RULE_CFM_MODAL_OPEN = `${PRESET_STRING}/SET_ENABLE_RULE_CFM_MODAL_OPEN`;
function setEnableRuleCfmModalOpen(isEnableRuleCfmModalOpen) {
  return {
    type: SET_ENABLE_RULE_CFM_MODAL_OPEN,
    isEnableRuleCfmModalOpen,
  };
}

const SET_UPDATE_RULE_ORDER_CFM_MODAL_OPEN =
  `${PRESET_STRING}/SET_UPDATE_RULE_ORDER_CFM_MODAL_OPEN`;
function setUpdateRuleOrderCfmModalOpen(isUpdateRuleOrderCfmModalOpen) {
  return {
    type: SET_UPDATE_RULE_ORDER_CFM_MODAL_OPEN,
    isUpdateRuleOrderCfmModalOpen,
  };
}

const SET_RULES = `${PRESET_STRING}/SET_RULES`;
function setRules(rules) {
  return {
    type: SET_RULES,
    rules,
  };
}

const ADD_TO_RULES = `${PRESET_STRING}/ADD_TO_RULES`;
function addToRules(rule) {
  return {
    type: ADD_TO_RULES,
    rule,
  };
}


const DELETE_RULE = `${PRESET_STRING}/DELETE_RULE`;
function deleteRule(id) {
  return {
    type: DELETE_RULE,
    id,
  };
}

const REPLACE_RULE = `${PRESET_STRING}/REPLACE_RULE`;
function replaceRule(rule) {
  return {
    type: REPLACE_RULE,
    rule,
  };
}

const SET_CHANGE_RULE_ORDER_IN_PROGRESS = `${PRESET_STRING}/SET_CHANGE_RULE_ORDER_IN_PROGRESS`;
function setChangeRuleOrderInProgress(isChangeRuleOrderInProgress) {
  return {
    type: SET_CHANGE_RULE_ORDER_IN_PROGRESS,
    isChangeRuleOrderInProgress,
  };
}

const SET_RULES_BEFORE_REORDER = `${PRESET_STRING}/SET_RULES_BEFORE_REORDER`;
function setRulesBeforeReorder(rulesBeforeReorder) {
  return {
    type: SET_RULES_BEFORE_REORDER,
    rulesBeforeReorder,
  };
}

const REVERT_RULE_ORDER = `${PRESET_STRING}/REVERT_RULE_ORDER`;
function revertRuleOrder() {
  return {
    type: REVERT_RULE_ORDER,
  };
}

const SET_RULE_TO_DELETE = `${PRESET_STRING}/SET_RULE_TO_DELETE`;
function setRuleToDelete(ruleToDelete) {
  return {
    type: SET_RULE_TO_DELETE,
    ruleToDelete,
  };
}
const SET_RECORD_COUNTS = `${PRESET_STRING}/SET_RECORD_COUNTS`;
function setRecordCounts(recordCounts) {
  return {
    type: SET_RECORD_COUNTS,
    recordCounts,
  };
}

const UPDATE_RECORD_COUNTS = `${PRESET_STRING}/UPDATE_RECORD_COUNTS`;
function updateRecordCounts(recordCounts) {
  return {
    type: UPDATE_RECORD_COUNTS,
    recordCounts,
  };
}


export const ruleListReducer = createReducerUtil(
  { ...initialState },
  {
    [SET_DELETE_RULE_CFM_MODAL_OPEN]: setPayload,
    [SET_RULES]: setPayload,
    [ADD_TO_RULES]: (state, action) => {
      const rules = [action.rule].concat([...state.rules]);
      return { ...state, rules };
    },
    [DELETE_RULE]: (state, action) => {
      const rules = [...state.rules].filter((r) => r.id !== action.id);
      return { ...state, rules };
    },
    [REPLACE_RULE]: (state, action) => {
      const rule = action.rule;
      const id = rule.id;
      const index = state.rules.findIndex((r) => r.id === id);
      const rules = [...state.rules];
      if (index >= 0) {
        rules[index] = rule;
      }
      return { ...state, rules };
    },
    [SET_DISABLE_RULE_CFM_MODAL_OPEN]: setPayload,
    [SET_ENABLE_RULE_CFM_MODAL_OPEN]: setPayload,
    [SET_CHANGE_RULE_ORDER_IN_PROGRESS]: setPayload,
    [SET_RULES_BEFORE_REORDER]: setPayload,
    [SET_UPDATE_RULE_ORDER_CFM_MODAL_OPEN]: setPayload,
    [REVERT_RULE_ORDER]: (state) => ({ ...state, rules: state.rulesBeforeReorder }),
    [SET_RULE_TO_DELETE]: setPayload,
    [SET_RECORD_COUNTS]: setPayload,
    [UPDATE_RECORD_COUNTS]: (state, action) => {
      // api doesn't send rules with 0 count, so we need to set all to zero first
      const setToZero = state.rules.map((r) => ({ rule_id: String(r.id), cnt: 0 }));
      const current = setToZero.concat(action.recordCounts);
      // Create a Map to store unique rule_id
      const uniqueMap = new Map(current.map((item) => [item.rule_id, item]));
      // Convert the Map values back into a Set (or array if needed)
      const uniqueArray = Array.from(uniqueMap.values());
      return {
        ...state,
        recordCounts: uniqueArray,
      };
    },
  }
);

export const actionTypes = {
  SET_DELETE_RULE_CFM_MODAL_OPEN,
  SET_RULES,
  ADD_TO_RULES,
  DELETE_RULE,
  REPLACE_RULE,
  SET_DISABLE_RULE_CFM_MODAL_OPEN,
  SET_ENABLE_RULE_CFM_MODAL_OPEN,
  SET_CHANGE_RULE_ORDER_IN_PROGRESS,
  SET_RULES_BEFORE_REORDER,
  REVERT_RULE_ORDER,
  SET_UPDATE_RULE_ORDER_CFM_MODAL_OPEN,
  SET_RULE_TO_DELETE,
  SET_RECORD_COUNTS,
  UPDATE_RECORD_COUNTS,
};

export const actions = {
  setDeleteRuleCfmModalOpen,
  setRules,
  addToRules,
  deleteRule,
  replaceRule,
  setDisableRuleCfmModalOpen,
  setEnableRuleCfmModalOpen,
  setChangeRuleOrderInProgress,
  setRulesBeforeReorder,
  revertRuleOrder,
  setUpdateRuleOrderCfmModalOpen,
  setRuleToDelete,
  setRecordCounts,
  updateRecordCounts,
};

