import { useSharedState } from '@sixsense/core';
import {
  Divider,
  Flex,
  Button
  as
  ButtonCore,
  Input,
  Icon as IconCore,
} from '@sixsense/core/components';
import { DefaultRole } from '@sixsense/rbac';
import React, { Fragment, useState } from 'react';
import { css } from 'styles/emotion';
import {
  Col,
  Select,
  Text,
  Button,
  Link,
  Icon,
  RadioGroup,
} from 'v2-components';
import popoverStyles from 'v2-components/Popover/Popover.module.scss';
import {
  BALANCE_FILTER_TYPES,
  STATUS_FILTER_OPTIONS,
  DISABLED_STATUS,
  LICENSE_TYPES,
} from '../../constants';
import {
  dataCreditsUsageState,
  searchUsersState,
  selectedBalanceActionState,
  selectedBalanceState,
  selectedRoleState,
  selectedStatusState,
  showFilterPopoverState,
} from '../../state';
import { pluralize } from 'utils/utils';
import { CreditUsers } from '../../types';
import { AAColor, pxToRem } from '@sixsense/core/style';
import InputNumber from 'v2-components/InputNumber';
import { isNil, isNumber } from 'lodash';
import { COLORS } from 'v2-styles/constants';
import { Popover } from 'antd';
import { Download } from '@sixsense/core/icons';

const styles = {
  buttonStyle: css({
    height: pxToRem(36),
  }),
  dropdownSection: css({
    justifyContent: 'end',
  }),
  headerCard: css({
    marginBottom: pxToRem(18),
  }),
  input: css({
    border: '1px solid #C1C7CC',
    borderRadius: pxToRem(2),
    width: `${pxToRem(140)} !important`,
  }),
  selectContainer: css({
    width: `${pxToRem(120)} !important`,
  }),
  selectionText: css({
    color: '#001F32',
    fontSize: pxToRem(12),
    fontStyle: 'italic',
  }),
  link: css({
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: pxToRem(12),
  }),
  balanceButton: css({
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    width: pxToRem(140),
    backgroundColor: AAColor.WHITE,
    border: `1px solid ${AAColor.GREY2}`,
    paddingLeft: '10px',
    borderRadius: pxToRem(2),
    '&:hover, &:active, &:focus': {
      backgroundColor: `${AAColor.BLUE} !important`,
      '& > span:last-child': {
        color: `${AAColor.WHITE} !important`,
      },
    },
  }),
  balanceButtonText: css({
    marginLeft: '4px',
  }),
  balancePopover: css({
    padding: pxToRem(20),
  }),
  balancePopoverRadio: css({
    padding: `${pxToRem(4)} !important`,
  }),
  balancePopoverInput: css({
    width: '100% !important',
  }),
  balancePopoverFooter: css({
    padding: pxToRem(8),
  }),
  radio: css({
    margin: '0px -8px -0px -8px !important',
    padding: '0px !important',
  }),
  filterNotify: css({
    background: '#E12C2CD9',
    width: pxToRem(16),
    height: pxToRem(16),
    borderRadius: '50%',
    marginLeft: pxToRem(8),
  }),
};

type UserCardHeaderProps = {
  users: Array<CreditUsers>;
  roles: Array<[]>;
  selectedUsers: Set<String>;
  handleMultiAddCredit: () => void;
  setSelectedUsers: (users: Set<String>) => void;
  downloadCsv: (content: Array<[]>) => void;
};

const PopoverContent = () => {
  const [, setPopverVisible] = useSharedState(showFilterPopoverState);
  const [balanceFilter, setBalanceFilter] =
    useSharedState(selectedBalanceState);
  const [balanceActionFilter, setBalanceActionFilter] = useSharedState(
    selectedBalanceActionState
  );
  const [action, setAction] = useState(balanceActionFilter);
  const [balance, setBalance] = useState(balanceFilter);

  const reset = () => {
    setAction(balanceActionFilter);
    setBalance(balanceFilter);
    setPopverVisible(false);
  };
  return (
    <Fragment>
      <Flex direction="column" gap={16} className={styles.balancePopover}>
        <Text type={Text.TYPE.TITLE} weight={Text.WEIGHT.BOLD}>
          Credit Balance
        </Text>
        <RadioGroup
          className={styles.radio}
          containerClassName={styles.balancePopoverRadio}
          options={[
            {
              label: (
                <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.BLACK}>
                  All
                </Text>
              ),
              value: BALANCE_FILTER_TYPES.ALL,
            },
            {
              label: (
                <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.BLACK}>
                  Below
                </Text>
              ),
              value: BALANCE_FILTER_TYPES.BELOW,
            },
            {
              label: (
                <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.BLACK}>
                  Above
                </Text>
              ),
              value: BALANCE_FILTER_TYPES.ABOVE,
            },
          ]}
          onChange={(v) => {
            if (action !== v) {
              setBalance(null);
              setAction(BALANCE_FILTER_TYPES[v.toUpperCase()]);
            }
          }}
          selectedValue={action}
        />
        {action !== BALANCE_FILTER_TYPES.ALL ? (
          <Flex direction="column" gap={8}>
            <Text type={Text.TYPE.TITLE2}>Number of Credits</Text>
            <InputNumber
              min={0}
              className={styles.balancePopoverInput}
              type="number"
              value={balance}
              onChange={(e) => setBalance(isNumber(e) ? e : null)}
            />
          </Flex>
        ) : null}
      </Flex>
      <Divider color={COLORS.AA_GREY3} />
      <Flex
        direction={'row-reverse'}
        className={styles.balancePopoverFooter}
        gap={8}
      >
        <Button
          type="primary"
          disabled={balance && balance < 0}
          onClick={() => {
            setBalanceFilter(balance);
            setBalanceActionFilter(action);
            setPopverVisible(false);
          }}
        >
          Apply
        </Button>
        <Button type={'secondary'} onClick={reset}>
          Cancel
        </Button>
      </Flex>
    </Fragment>
  );
};

const UserCardHeader = ({
  users = [],
  selectedUsers,
  handleMultiAddCredit,
  roles,
  setSelectedUsers,
  downloadCsv,
}: UserCardHeaderProps) => {
  const [popoverVisible, setPopverVisible] = useSharedState(
    showFilterPopoverState
  );
  const [dataCreditsUsageFlag] = useSharedState(dataCreditsUsageState);
  const [balanceAction] = useSharedState(selectedBalanceActionState);
  const [balance] = useSharedState(selectedBalanceState);
  const [search, setSearch] = useSharedState(searchUsersState);
  const [statusFilter, setStatusFilter] = useSharedState(selectedStatusState);
  const [roleFilter, setRoleFilter] = useSharedState(selectedRoleState);
  const selectedUsersSize = selectedUsers.size;
  const checkSelected = () => selectedUsersSize > 0;
  const rolesMap = roles?.reduce((map, role: any) => ({ ...map, [role.value]: role.label }), {});

  const mapCsvFields = (data) =>
    data.map((user) => ({
      Username: user.user_email,
      Status: user.status,
      Role: rolesMap[user.role_rbac] || '-',
      'Last Updated By': user.updated_by,
      'Used Credit': user.total_used_credits,
      Balance: user.total_balance_credits,
      'Allocated Credit': user.total_credits,
    }));

  const handleDownloadCsv = () => {
    const content = mapCsvFields(users);
    downloadCsv(content);
  };

  return (
    <Flex alignItems="center" className={styles.headerCard}>
      <Col flexBasis={'40%'}>
        {dataCreditsUsageFlag && selectedUsersSize > 0 ? (
          <Flex alignItems="center" gap={10}>
            <span className={styles.selectionText}>
              {selectedUsersSize} of {users.length} selected
            </span>
            <div style={{ width: 8 }} />
            <Link
              className={styles.link}
              onClick={() =>
                setSelectedUsers(
                  new Set(
                    users
                      .filter(
                        ({ status, license_type, role_rbac }) =>
                        status !== DISABLED_STATUS &&
                        license_type !== LICENSE_TYPES.LITE && role_rbac
                      )
                      .map(({ user_email }) => user_email)
                  )
                )
              }
            >
              Select All
            </Link>
            <div style={{ width: 8 }} />
            <Link
              className={styles.link}
              onClick={() => setSelectedUsers(new Set())}
            >
              Clear Selection
            </Link>
            <div style={{ width: 8 }} />
            <Button
              className={styles.buttonStyle}
              onClick={handleMultiAddCredit}
              type="primary"
              disabled={!checkSelected()}
            >
              Add Credit
            </Button>
          </Flex>
        ) : (
          <Text bold>
            {' '}
            {`${users.length} ${pluralize('User', users.length > 1)}`}{' '}
          </Text>
        )}
      </Col>
      <Col flexBasis={'60%'}>
        <Flex alignItems="flex-end" className={styles.dropdownSection}>
          <Select
            options={STATUS_FILTER_OPTIONS}
            onChange={(val) => setStatusFilter(val)}
            value={statusFilter}
            icon="visibility"
            width={160}
            dropdownMatchSelectWidth={false}
            disabled={checkSelected()}
            containerClassName={styles.selectContainer}
          />
          <div style={{ width: 16 }} />
          <Select
            disabled={checkSelected()}
            options={[
              {
                label: 'All Roles',
                value: -1,
              },
              ...roles.filter(
                (role) => role.value !== DefaultRole.PRIMARY_ADMINISTRATOR
              ),
            ]}
            onChange={(val) => setRoleFilter(val)}
            value={roleFilter}
            icon="visibility"
            width={160}
            dropdownMatchSelectWidth={false}
            containerClassName={styles.selectContainer}
          />
          <div style={{ width: 16 }} />
          <Popover
            overlayClassName={popoverStyles.popoverWrapper}
            content={<PopoverContent />}
            placement={'bottomRight'}
            trigger={'click'}
            visible={popoverVisible}
            onVisibleChange={setPopverVisible}
          >
            <button className={styles.balanceButton}>
              <Icon type="filter_list" color={Icon.COLORS.GREY} />
              <Text
                color={Text.COLOR.BLUE}
                className={styles.balanceButtonText}
              >
                Filter By
              </Text>
              {balanceAction && !isNil(balance) && (
                <Flex
                  className={styles.filterNotify}
                  alignItems="center"
                  justifyContent="center"
                >
                  1
                </Flex>
              )}
            </button>
          </Popover>
          <Flex className="ml4">
            <Input
              className={styles.input}
              name={'search-users'}
              value={search}
              before={
                <Flex alignItems="center" className="ml2">
                  <IconCore type={'MdSearch'} />
                </Flex>
              }
              placeholder={'Search Users'}
              onChange={setSearch}
              disabled={checkSelected()}
            />
          </Flex>
          <ButtonCore
            disabled={!users.length}
            hierarchy="tertiary"
            size="md"
            leadingBadge=""
            leadingIcon={Download}
            onClick={handleDownloadCsv}
          />
        </Flex>
      </Col>
    </Flex>
  );
};

export default UserCardHeader;
