import { COLORS } from 'v2-styles/constants';

export const colorMap = {
  activity_account_count: COLORS.AA_BLUE,
  keyword_account_count: COLORS.AA_GREEN,
  fpm_account_count: COLORS.AA_YELLOW,
  tpm_account_count: COLORS.AA_MAROON,
  map_account_count: COLORS.AA_BLUEGREY,
  crm_account_count: COLORS.AA_PURPLE,
};

export const allViewOptions = {
  rollUp: 'rollUp',
  individual: 'individual',
};

export const allViewTabs = [
  {
    label: 'Roll-Up',
    value: allViewOptions.rollUp,
  },
  {
    label: 'Individual',
    value: allViewOptions.individual,
  },
];
