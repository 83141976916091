import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';
import { actions as globalActions } from 'modules/global';
import { baseSelector, linkedinAdsPageEndpointSelector } from './selectors';
import { ACTION_PREFIX } from './constants';

const { showNotification } = globalActions;


export const linkedinIntegratedPagesStateGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: `${ACTION_PREFIX}/FETCH_PAGES`,
  endpointSelector: linkedinAdsPageEndpointSelector,
  stateKey: 'linkedinIntegratedPages',
  initialData: [],
  // TODO: The response object looks huge. Need to trim down to what is required
  receivedDataTransform: (data) => data.results,
  onFailureActions: [
    () => showNotification(
      'error',
      'Failed to load linkedin pages. Please try again',
    ),
  ],
});
