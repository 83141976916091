import { filter, isEmpty, toLower } from 'lodash';


const validTLD = (str) => {
  try {
    const url = new URL(str);
    return url.protocol === 'http:' || url.protocol === 'https:' ? undefined: 'invalid';
  } catch (e) {
    return 'invalid';
  }
};

const checkDuplicate = (url, allURLs) => {
  const records = filter(allURLs, (_url) => toLower(_url) === toLower(url));
  return records.length > 1 ? 'duplicate' : undefined;
};

const noWebProtocol = (str) =>
  !str.toLowerCase().includes('https://') &&
  !str.toLowerCase().includes('http://');

export const setURL = (
  productTags,
  urlIndex,
  value,
  productName,
  setProductTags
) => {
  const newProductTags = productTags.map((productObj) => {
    if (productObj.product === productName) {
      const urlObj = productObj.urls[urlIndex];
      // eslint-disable-next-line no-param-reassign
      productObj.urls[urlIndex] = { ...urlObj, url: value, touched: true };
    }
    return productObj;
  });
  setProductTags(newProductTags);
};

export const addNewURL = (productTags, productName, setProductTags) => {
  const newProductTags = productTags.map((productObj) => {
    if (productObj.product === productName) {
      // eslint-disable-next-line no-param-reassign
      productObj.urls.push({
        id: null,
        mappingId: null,
        url: '',
        updated: false,
        isDeleted: false,
        touched: false,
      });
    }
    return productObj;
  });
  setProductTags(newProductTags);
};

export const deleteURL = (productTags, index, productName, setProductTags) => {
  const newProductTags = productTags.map((productObj) => {
    if (productObj.product === productName) {
      // eslint-disable-next-line no-param-reassign
      productObj.urls = [
        ...productObj.urls.slice(0, index),
        ...productObj.urls.slice(index + 1),
      ];
    }
    return productObj;
  });
  setProductTags(newProductTags);
};

export const validateURL = (url, allURLs) => {
  if (isEmpty(url)) {
    return 'URL cannot be empty';
  } else if (noWebProtocol(url)) {
    return 'URL must start with http:// or https://';
  } else if (validTLD(url)) {
    return 'Invalid URL pattern, validate the URL';
  } else if (checkDuplicate(url, allURLs)) {
    return 'URL must be unique';
  }

  return undefined;
};
