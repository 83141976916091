import { ROUTE_STATE_KEY } from './constants';
import { getAsyncInjectors } from 'store/utils';
import { LINKEDIN_ADVERTISING_ROUTE } from 'utils/constants';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: LINKEDIN_ADVERTISING_ROUTE,
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const container = require('./containers/LinkedInAdvertisingContainer');

        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer(ROUTE_STATE_KEY, reducer.default);
        injectSagas(ROUTE_STATE_KEY, sagas.default);

        cb(null, container.default);
      }, 'linkedin_ads'); // beware of ad blocker
    },
  };
}
