import { Loading } from '@sixsense/core/components';
import { useSharedState, useSharedValue } from '@sixsense/core';
import React, { useMemo } from 'react';
import { css } from 'styles/emotion';
import { TetheredSelect } from 'v2-components';
import { NEW_GROUP } from '../constants';
import { dedupGroupOptions } from '../utils';
import { useKwGroupSearchRequest } from '../hooks';
import {
  keywordGroupSelectQueryState,
  addKeywordsConfigState,
  bulkAddToGroupConfigState,
} from '../state';
import { ResultStatus } from '@sixsense/core/shared/async-result/types';
import { AAColor } from '@sixsense/core/style';

const { Option } = TetheredSelect;

const styles = {
  dropdownStyle: css`
  margin-bottom: 200px;
  margin-top: 10px;
  :global(.ant-select-selection) {
      border:none !important;
      box-shadow: none !important;
      :global(.ant-select-selection__choice){
        background-color: #E5F2FA;
      }
    }
  `,
  createText: css({
    color: `${AAColor.BLUE} !important`,
    '&:hover': {
      color: `${AAColor.WHITE} !important`,
    },
  }),
};

type Props = {
  groups: Number[];
  setGroups: (any) => void;
  setCreateGroupModalVisible: (Boolean) => void;
  noCreate: Boolean;
  width: Number;
};

const GroupSelect = ({
  groups,
  setGroups,
  setCreateGroupModalVisible,
  noCreate,
  width=100,
} : Props) => {

  const [searchQuery, setSearchQuery] = useSharedState(keywordGroupSelectQueryState);
  const addKwsConfig = useSharedValue(addKeywordsConfigState);
  const bulkConfig = useSharedValue(bulkAddToGroupConfigState);
  const keywordGroupsResult = useKwGroupSearchRequest();

  const loading = keywordGroupsResult.status === ResultStatus.LOADING;

  const extraGroups = addKwsConfig.group_options;
  const bulkGroups = bulkConfig.group_options;

  const groupOptions = useMemo(
    () => {
      const allOptions = keywordGroupsResult.data?.results?.map(
        (p) => ({ value: p.id, label: p.name })).concat(extraGroups, bulkGroups)
        || [].concat(extraGroups, bulkGroups);
      const dedupedOptions = dedupGroupOptions(allOptions);
      const options = !noCreate ? dedupedOptions.concat(
        [{ value: NEW_GROUP, label: '+ New keyword group' }]) : dedupedOptions;
      return options;
    }, [extraGroups, bulkGroups, keywordGroupsResult]
  );

  return (
    <TetheredSelect
      id={'kwGroupSelect'}
      className={styles.dropdownStyle}
      style={{ width: `${width}%` }}
      onChange={(val) => setGroups(val)}
      value={groups.filter((v) => v !== NEW_GROUP)}
      mode={'multiple'}
      tokenSeparators={[',']}
      loading={loading}
      size={'large'}
      searchValue={searchQuery}
      filterOption={(inputValue, option) => {
        if (option.props?.optionMeta?.value === NEW_GROUP) {
          return true;
        }
        const name = option.props?.optionMeta?.label || '';
        return name.toLowerCase().includes(inputValue.toLowerCase());
      }}
      onSearch={(v) => {
        setSearchQuery(v);
      }}
      placeholder={noCreate ? 'Select existing group' : 'Select existing group or create new'}
    >
      {loading ?
        <Option
          key="LOADING_OPTION"
          disabled
        >
          <Loading.Spinner />
        </Option> :
        groupOptions.map((v) =>
          <Option
            optionMeta={v}
            key={v.value}
            value={v.value}
            style={{ borderTop: v.value === NEW_GROUP && groupOptions.length ?
              '1px solid #C6CACE' : 'none' }}
            className={v.value === NEW_GROUP ? styles.createText : ''}
            onClick={(k) => {
              if (k.key === NEW_GROUP) {
                setCreateGroupModalVisible(true);
              }
            }}
          >
            {v.label}
          </Option>)}
    </TetheredSelect>
  );
};

export default GroupSelect;
