// I put these in here because the alert listener and dispatcher
// components should have no clue of the available channels. that is
// more of a application knowledge than component knowledge.
const NAVBAR = 'navbar';
export const ALERT_CHANNELS = {
  NAVBAR,
};

export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const PROMISE_STATES = {
  INIT: 'init',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
};
