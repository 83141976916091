import { spanToQueryParams, spanToMomentRange } from 'utils/utils';
import moment from 'moment';

export const genQueryParams = (
  lastUpdated: string,
  span?,
  productCategory?: string,
  limit?: number,
): string => {
  let dateRange;
  if (span.fixedRange) {
    const timeRange = span.timeWindow || 'last_30_days';
    dateRange = spanToMomentRange(
      { fixedRange: true, timeWindow: timeRange },
      moment.utc(lastUpdated)
    );
  } else {
    dateRange = spanToMomentRange(span);
  }

  let queryString = spanToQueryParams(dateRange);
  if (limit) {
    queryString += `&limit=${limit}`;
  }
  if (productCategory) {
    queryString += `&product_name=${productCategory}`;
  }
  return queryString;
};
