import {
  ADVERTISING_BASE_ROUTE,
  FILTER_FIELDS,
  POSSIBLE_VALUE_DISPLAY_TYPES,
} from "routes/AdvertisingDS/constants";
import { CAMPAIGN_SOURCE, CAMPAIGN_SOURCE_LABELS } from "utils/constants";
import {
  CAMPAIGN_STATUS_LABELS,
  CAMPAIGN_STATUS_X,
} from "../Campaigns/constants";
import { filter, invert, isNil, values } from "lodash";
import { numberToDollar, toFixedfp } from "utils/utils";
import { transformNumber } from "routes/AdvertisingDS/utils";
import {
  ACCOUNTS_ENGAGED_KEY,
  ACCOUNTS_REACHED_KEY,
  ACCOUNT_CTR_KEY,
  ACCOUNT_VTR_KEY,
  BUDGET_SPENT_KEY,
  CLICK_COUNT_KEY,
  COST_PER_ACCOUNT_ENGAGED_KEY,
  COST_PER_ACCOUNT_REACHED_KEY,
  CPC_KEY,
  CPM_KEY,
  CTR_KEY,
  MEDIA_IMPRESSIONS_KEY,
  FILTER_KEYS as PTR_FILTER_KEYS,
  USERS_VIEW_THROUGH_KEY,
  VIEWABILITY_PERCENT_KEY,
  VTR_KEY,
} from "./../Reports/routes/PerformanceTrend/constants";
import { TextColor } from "@sixsense/core/style";
import { css } from "styles/emotion";
import moment from "moment";

export const DASHBOARD_ROUTE = "dashboard";
export const ADVERTISING_DASHBOARD_ROUTE = `${ADVERTISING_BASE_ROUTE}/${DASHBOARD_ROUTE}`;

export const PAGE_STATE_KEY = "advertisingDashboard";

export const ID_PREFIX = "dashboard";

export const DISTRIBUTED_STATS_QUERY_LIMIT = 5;

export const LAST_UPDATED_TIMESTAMP_DATE_FORMAT = "MMM D, YYYY h:mma";

export const CAMPAIGN_SOURCE_FILTER_KEY = "campaignSource";
export const CAMPAIGN_STATUS_FILTER_KEY = "campaignStatus";
export const CAMPAIGN_CREATED_BY_FILTER_KEY = "createdBy";
export const CAMPAIGN_FOLDERS_FILTER_KEY = "folders";
export const CAMPAIGNS_FILTER_KEY = "campaigns";

export const FILTER_KEYS = {
  [CAMPAIGN_SOURCE_FILTER_KEY]: "Campaign.campaignSource",
  [CAMPAIGN_STATUS_FILTER_KEY]: "Campaign.uiStatus",
  [CAMPAIGN_CREATED_BY_FILTER_KEY]: "Campaign.createdById",
  [CAMPAIGN_FOLDERS_FILTER_KEY]: "Classification.id",
  [CAMPAIGNS_FILTER_KEY]: "Campaign.id",
};

export const DASHBOARD_TO_ADS_LIBRARY_FILTER_KEYS_MAP = {
  [FILTER_KEYS.createdBy]: FILTER_FIELDS.createdByMe,
  [FILTER_KEYS.campaigns]: FILTER_FIELDS.campaigns,
};

// inverting FILTER_KEYS to be used to convert cube specific filter keys to generic
// filter keys to be used in the URL params
export const CUBE_TO_GENERIC_FILTER_KEYS = invert(FILTER_KEYS);

export const DASHBOARD_TO_PTR_FILTER_KEYS_MAP = {
  [FILTER_KEYS.campaignSource]: [PTR_FILTER_KEYS.campaignSource],
  [FILTER_KEYS.campaignStatus]: [PTR_FILTER_KEYS.campaignStatus],
  [FILTER_KEYS.createdBy]: [PTR_FILTER_KEYS.createdBy],
  [FILTER_KEYS.folders]: [PTR_FILTER_KEYS.folders],
  [FILTER_KEYS.campaigns]: [PTR_FILTER_KEYS.campaigns],
};

export const FILTER_LABELS = {
  createdBy: "Created By",
  campaignType: "Campaign Type",
  status: "Status",
  folders: "Campaign Folder",
  campaigns: "Campaigns",
  createdByMe: "Created By Me",
};

export const CREATED_BY_ALL = "all";

export const CREATED_BY_ME_FILTER_OPTION = {
  label: null,
  filterKey: FILTER_KEYS.createdBy,
  // undefined will be replaced with actualt logged in user's id in a selector before consumption of this constant
  choices: [{ label: FILTER_LABELS.createdByMe, value: undefined }],
};

export const CAMPAIGN_TYPE_FILTER_OPTION = {
  label: FILTER_LABELS.campaignType,
  filterKey: FILTER_KEYS.campaignSource,
  choices: [
    CAMPAIGN_SOURCE.INTERNAL,
    CAMPAIGN_SOURCE.RETARGETING,
    CAMPAIGN_SOURCE.CONTEXTUAL,
    CAMPAIGN_SOURCE.EXTERNAL,
    CAMPAIGN_SOURCE.LINKEDIN,
    CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING,
  ].map((source) => ({
    label: CAMPAIGN_SOURCE_LABELS[source],
    value: source,
  })),
};

export const CAMPAIGN_STATUS_FILTER_OPTION = {
  label: FILTER_LABELS.status,
  filterKey: FILTER_KEYS.campaignStatus,
  choices: filter(
    values(CAMPAIGN_STATUS_X).map(
      (status) =>
        ![CAMPAIGN_STATUS_X.draft, CAMPAIGN_STATUS_X.scheduled].includes(
          status
        ) && {
          label: CAMPAIGN_STATUS_LABELS[status],
          value: status,
        }
    )
  ),
};

export const FOLDER_FILTER_OPTION = {
  label: FILTER_LABELS.folders,
  filterKey: FILTER_KEYS.folders,
  choices: [],
};

export const CAMPAIGNS_FILTER_OPTION = {
  label: FILTER_LABELS.campaigns,
  filterKey: FILTER_KEYS.campaigns,
  choices: [],
};

export const FILTER_OPTIONS = [
  CREATED_BY_ME_FILTER_OPTION,
  CAMPAIGN_TYPE_FILTER_OPTION,
  CAMPAIGN_STATUS_FILTER_OPTION,
  FOLDER_FILTER_OPTION,
  CAMPAIGNS_FILTER_OPTION,
];

export const BUDGET_SPENT = "budgetSpent";
export const MID_LEVEL_BUDGET_SPENT = "totalMidLevelSpend";
export const ACCOUNTS_REACHED = "accountsReached";
export const MEDIA_IMPRESSIONS = "totalMediaImpressions";
export const CPM = "cpm";
export const VIEWABILITY = "viewabilityPercent";
export const CLICKS = "totalMediaClicks";
export const ACCOUNTS_CLICKS = "accountsClicked";
export const CTR = "ctr";
export const CPC = "cpc";
export const ACCOUNT_CTR = "accountCtr";
export const VIEW_THROUGHS = "viewthroughsCount";
export const VTR = "vtr";
export const ACCOUNT_VTR = "avtr";
export const INFLUENCED_FORM_FILLS = "influencedFormFills";
export const ACCOUNTS_ENGAGED = "accountsEngaged";
export const COST_PER_ACCOUNT_REACHED = "costPerAccountReached";
export const COST_PER_ACCOUNT_ENGAGED = "costPerAccountEngaged";
export const BUDGET = "campaignBudget";
export const COUNTRY = "country";
export const CAMPAIGN_SOURCE_METRIC = "campaignSource";
export const ACCOUNTS_TARGETED = "accountsTargeted";
export const ACCOUNT_ENGAGEMENT_RATE = "accountEngagementRate";
export const MATCHED_IMPRESSIONS = "totalMatchedImpression";
export const MATCHED_CLICKS = "totalMatchedClick";

export const ADS_DAILY_STATS_CUBE = "AdsDailyStats";
export const ADS_MID_DAILY_STATS_CUBE = "AdsMidDailyStats";
export const VIEW_THROUGH_ACTIVITIES_CUBE = "ViewthroughActivities";
export const ACCOUNT_VIEW_THROUGHS_CUBE = "AccountViewthroughs";
export const FORM_FILL_ACTIVITES_CUBE = "FormFillActivitiesDeduped";
export const CAMPAIGN_ACCOUNTS_TARGETED_CUBE = "CampaignAccountsTargeted";
export const CAMPAIGN_CUBE = "Campaign";
export const CAMPAIGNS_FIRMOGRAPH = "CampaignsFirmograph";

export const DASHBOARD_TO_PTR_METRIC_MAP = {
  [BUDGET_SPENT]: BUDGET_SPENT_KEY,
  [MID_LEVEL_BUDGET_SPENT]: BUDGET_SPENT_KEY,
  [ACCOUNTS_REACHED]: ACCOUNTS_REACHED_KEY,
  [MEDIA_IMPRESSIONS]: MEDIA_IMPRESSIONS_KEY,
  [CPM]: CPM_KEY,
  [CLICKS]: CLICK_COUNT_KEY,
  [ACCOUNTS_CLICKS]: CLICK_COUNT_KEY,
  [CTR]: CTR_KEY,
  [CPC]: CPC_KEY,
  [ACCOUNT_CTR]: ACCOUNT_CTR_KEY,
  [VIEW_THROUGHS]: USERS_VIEW_THROUGH_KEY,
  [VTR]: VTR_KEY,
  [ACCOUNT_VTR]: ACCOUNT_VTR_KEY,
  [ACCOUNTS_ENGAGED]: ACCOUNTS_ENGAGED_KEY,
  [COST_PER_ACCOUNT_REACHED]: COST_PER_ACCOUNT_REACHED_KEY,
  [COST_PER_ACCOUNT_ENGAGED]: COST_PER_ACCOUNT_ENGAGED_KEY,
  [MATCHED_IMPRESSIONS]: MEDIA_IMPRESSIONS_KEY,
  [MATCHED_CLICKS]: CLICK_COUNT_KEY,
  [VIEWABILITY]: VIEWABILITY_PERCENT_KEY,
};

export const DASHBOARD_METRICS_LABELS = {
  [BUDGET_SPENT]: "Spend",
  [MID_LEVEL_BUDGET_SPENT]: "Spend",
  [ACCOUNTS_REACHED]: "Accounts Reached",
  [MEDIA_IMPRESSIONS]: "Impressions",
  [MATCHED_IMPRESSIONS]: "Impressions",
  [CPM]: "eCPM",
  [VIEWABILITY]: "Viewability",
  [CLICKS]: "Clicks",
  [ACCOUNTS_CLICKS]: "Clicks",
  [MATCHED_CLICKS]: "Clicks",
  [CTR]: "CTR",
  [CPC]: "eCPC",
  [ACCOUNT_CTR]: "aCTR",
  [VIEW_THROUGHS]: "View-throughs",
  [VTR]: "VTR",
  [ACCOUNT_VTR]: "aVTR",
  [INFLUENCED_FORM_FILLS]: "Influenced Form Fills",
  [ACCOUNTS_ENGAGED]: "Accounts Engaged",
  [COST_PER_ACCOUNT_REACHED]: "Cost per account reached",
  [COST_PER_ACCOUNT_ENGAGED]: "Cost per account engaged",
  [BUDGET]: "Budget",
};

const TREND_REPORT_METRICS = [
  BUDGET_SPENT,
  ACCOUNTS_REACHED,
  MEDIA_IMPRESSIONS,
  CPM,
  VIEWABILITY,
  CLICKS,
  CTR,
  CPC,
  ACCOUNT_CTR,
  ACCOUNT_VTR,
  INFLUENCED_FORM_FILLS,
  ACCOUNTS_ENGAGED,
  COST_PER_ACCOUNT_REACHED,
  COST_PER_ACCOUNT_ENGAGED,
];

export const TREND_REPORT_METRICS_LABELS = TREND_REPORT_METRICS.reduce(
  (acc, metric) => ({
    ...acc,
    [metric]: DASHBOARD_METRICS_LABELS[metric],
  }),
  {}
);

export const VALUE_DISPLAY_TYPE_MAP = {
  [DASHBOARD_METRICS_LABELS[BUDGET_SPENT]]:
    POSSIBLE_VALUE_DISPLAY_TYPES.CURRENCY,
  [DASHBOARD_METRICS_LABELS[BUDGET]]: POSSIBLE_VALUE_DISPLAY_TYPES.CURRENCY,
  [DASHBOARD_METRICS_LABELS[CPM]]: POSSIBLE_VALUE_DISPLAY_TYPES.CURRENCY,
  [DASHBOARD_METRICS_LABELS[VIEWABILITY]]:
    POSSIBLE_VALUE_DISPLAY_TYPES.PERCENTAGE,

  [DASHBOARD_METRICS_LABELS[CTR]]: POSSIBLE_VALUE_DISPLAY_TYPES.PERCENTAGE,
  [DASHBOARD_METRICS_LABELS[CPC]]: POSSIBLE_VALUE_DISPLAY_TYPES.CURRENCY,

  [DASHBOARD_METRICS_LABELS[VTR]]: POSSIBLE_VALUE_DISPLAY_TYPES.PERCENTAGE,
  [DASHBOARD_METRICS_LABELS[ACCOUNT_CTR]]:
    POSSIBLE_VALUE_DISPLAY_TYPES.PERCENTAGE,
  [DASHBOARD_METRICS_LABELS[ACCOUNT_VTR]]:
    POSSIBLE_VALUE_DISPLAY_TYPES.PERCENTAGE,

  [DASHBOARD_METRICS_LABELS[COST_PER_ACCOUNT_REACHED]]:
    POSSIBLE_VALUE_DISPLAY_TYPES.CURRENCY,
  [DASHBOARD_METRICS_LABELS[COST_PER_ACCOUNT_ENGAGED]]:
    POSSIBLE_VALUE_DISPLAY_TYPES.CURRENCY,
};

export const ABBREVIATED_CURRENCY_DISPLAY_CONFIG = {
  abbreviate: true,
  showCents: true,
  compact: true,
};

export const CURRENCY_DISPLAY_CONFIG = {
  showCents: true,
  insertCommas: true,
};

export const ABBREVIATED_COMPACT_NUMBER_CONFIG = {
  abbreviate: true,
  compact: true,
};

export const LI_CAMPAIGN_TYPES = [
  CAMPAIGN_SOURCE.LINKEDIN,
  CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING,
];

// Metrics that are not applicable to LI Segments Sync and LI Ads campaign types
export const NA_METRICS = [
  VIEWABILITY,
  VIEW_THROUGHS,
  VTR,
  INFLUENCED_FORM_FILLS,
];

export const NA = "NA";

export const ALL_HIGHLIGHTS_METRICS = {
  [BUDGET_SPENT]: {
    value: BUDGET_SPENT,
    label: DASHBOARD_METRICS_LABELS[BUDGET_SPENT],
    default: true,
    description: "Amount of US dollars spent from the configured budget.",
    render: (value) =>
      (!isNil(value) &&
        numberToDollar(value, ABBREVIATED_CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [ACCOUNTS_REACHED]: {
    value: ACCOUNTS_REACHED,
    label: DASHBOARD_METRICS_LABELS[ACCOUNTS_REACHED],
    description: "Number of unique accounts served at least 1 impression.",
    render: (value) =>
      transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG) || value,
  },
  [MEDIA_IMPRESSIONS]: {
    value: MEDIA_IMPRESSIONS,
    label: DASHBOARD_METRICS_LABELS[MEDIA_IMPRESSIONS],
    default: true,
    description: "Number of impressions from your ads.",
    render: (value) =>
      (!isNil(value) &&
        transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG)) ||
      value,
  },
  [CPM]: {
    value: CPM,
    label: DASHBOARD_METRICS_LABELS[CPM],
    default: true,
    description: "Cost incurred for procuring 1000 ad impressions.",
    invertDeltaColors: true,
    render: (value) =>
      (!isNil(value) && numberToDollar(value, CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [VIEWABILITY]: {
    value: VIEWABILITY,
    label: DASHBOARD_METRICS_LABELS[VIEWABILITY],
    description:
      "Percentage of ad impressions that were measured as viewable by the user.",
    render: (value) => (!isNil(value) && `${toFixedfp(2)(value)}%`) || value,
  },
  [CLICKS]: {
    value: CLICKS,
    label: DASHBOARD_METRICS_LABELS[CLICKS],
    description: "Number of clicks on your ads.",
    render: (value) =>
      (!isNil(value) &&
        transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG)) ||
      value,
  },
  [CTR]: {
    value: CTR,
    label: DASHBOARD_METRICS_LABELS[CTR],
    default: true,
    description:
      "Percentage of Ad click-through generated out of matched ad impressions.",
    render: (value) => (!isNil(value) && `${toFixedfp(2)(value)}%`) || value,
  },
  [CPC]: {
    value: CPC,
    label: DASHBOARD_METRICS_LABELS[CPC],
    description:
      "Average cost-per-click (Avg. CPC) is the amount you've paid for your ad divided by its total clicks.",
    invertDeltaColors: true,
    render: (value) =>
      (!isNil(value) && numberToDollar(value, CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [ACCOUNT_CTR]: {
    value: ACCOUNT_CTR,
    label: DASHBOARD_METRICS_LABELS[ACCOUNT_CTR],
    description:
      "Percentage of Account click-throughs generated out of matched ad impressions.",
    render: (value) => (!isNil(value) && `${toFixedfp(2)(value)}%`) || value,
  },
  [ACCOUNT_VTR]: {
    value: ACCOUNT_VTR,
    label: DASHBOARD_METRICS_LABELS[ACCOUNT_VTR],
    default: true,
    description:
      "Percentage of Account view-throughs generated out of matched ad impressions.",
    render: (value) => (!isNil(value) && `${toFixedfp(2)(value)}%`) || value,
  },
  [INFLUENCED_FORM_FILLS]: {
    value: INFLUENCED_FORM_FILLS,
    label: DASHBOARD_METRICS_LABELS[INFLUENCED_FORM_FILLS],
    description:
      "Count of form-fills served an impression prior to filling out the form.",
    render: (value) =>
      (!isNil(value) &&
        transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG)) ||
      value,
  },
  [ACCOUNTS_ENGAGED]: {
    value: ACCOUNTS_ENGAGED,
    label: DASHBOARD_METRICS_LABELS[ACCOUNTS_ENGAGED],
    default: true,
    description: "Number of unique accounts that have clicks or view-throughs.",
    render: (value) =>
      (!isNil(value) &&
        transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG)) ||
      value,
  },
  [COST_PER_ACCOUNT_REACHED]: {
    value: COST_PER_ACCOUNT_REACHED,
    label: DASHBOARD_METRICS_LABELS[COST_PER_ACCOUNT_REACHED],
    description: "Total spend divided by accounts reached.",
    invertDeltaColors: true,
    render: (value) =>
      (!isNil(value) && numberToDollar(value, CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [COST_PER_ACCOUNT_ENGAGED]: {
    value: COST_PER_ACCOUNT_ENGAGED,
    label: DASHBOARD_METRICS_LABELS[COST_PER_ACCOUNT_ENGAGED],
    description: "Total spend divided by accounts engaged.",
    invertDeltaColors: true,
    render: (value) =>
      (!isNil(value) && numberToDollar(value, CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
};

export const CAMPAIGN_PERFORMANCE_METRICS = {
  [MATCHED_IMPRESSIONS]: {
    value: MATCHED_IMPRESSIONS,
    label: DASHBOARD_METRICS_LABELS[MATCHED_IMPRESSIONS],
    default: true,
    render: (value) =>
      (!isNil(value) &&
        transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG)) ||
      value,
  },
  [MID_LEVEL_BUDGET_SPENT]: {
    value: MID_LEVEL_BUDGET_SPENT,
    label: DASHBOARD_METRICS_LABELS[MID_LEVEL_BUDGET_SPENT],
    render: (value) =>
      (!isNil(value) &&
        numberToDollar(value, ABBREVIATED_CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [CLICKS]: {
    value: CLICKS,
    label: DASHBOARD_METRICS_LABELS[CLICKS],
    render: (value) =>
      (!isNil(value) &&
        transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG)) ||
      value,
  },
  [BUDGET]: {
    value: BUDGET,
    label: DASHBOARD_METRICS_LABELS[BUDGET],
    render: (value) =>
      (!isNil(value) &&
        numberToDollar(value, ABBREVIATED_CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [CPM]: {
    value: CPM,
    label: DASHBOARD_METRICS_LABELS[CPM],
    render: (value) =>
      (!isNil(value) && numberToDollar(value, CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [VIEWABILITY]: {
    value: VIEWABILITY,
    label: DASHBOARD_METRICS_LABELS[VIEWABILITY],
    render: (value) => (!isNil(value) && `${toFixedfp(2)(value)}%`) || value,
  },

  [CTR]: {
    value: CTR,
    label: DASHBOARD_METRICS_LABELS[CTR],
    render: (value) => (!isNil(value) && `${toFixedfp(2)(value)}%`) || value,
  },
  [CPC]: {
    value: CPC,
    label: DASHBOARD_METRICS_LABELS[CPC],
    render: (value) =>
      (!isNil(value) && numberToDollar(value, CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [ACCOUNT_CTR]: {
    value: ACCOUNT_CTR,
    label: DASHBOARD_METRICS_LABELS[ACCOUNT_CTR],
    render: (value) => (!isNil(value) && `${toFixedfp(2)(value)}%`) || value,
  },
  [ACCOUNTS_ENGAGED]: {
    value: ACCOUNTS_ENGAGED,
    label: DASHBOARD_METRICS_LABELS[ACCOUNTS_ENGAGED],
    render: (value) =>
      (!isNil(value) &&
        transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG)) ||
      value,
  },
};

export const SEGMENT_PERFORMANCE_METRICS = {
  [ACCOUNTS_REACHED]: {
    value: ACCOUNTS_REACHED,
    label: DASHBOARD_METRICS_LABELS[ACCOUNTS_REACHED],
    default: true,
    render: (value) =>
      (!isNil(value) &&
        transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG)) ||
      value,
  },
  [MATCHED_IMPRESSIONS]: {
    value: MATCHED_IMPRESSIONS,
    label: DASHBOARD_METRICS_LABELS[MATCHED_IMPRESSIONS],
    render: (value) =>
      (!isNil(value) &&
        transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG)) ||
      value,
  },
  [BUDGET_SPENT]: {
    value: BUDGET_SPENT,
    label: DASHBOARD_METRICS_LABELS[BUDGET_SPENT],
    render: (value) =>
      (!isNil(value) &&
        numberToDollar(value, ABBREVIATED_CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [CLICKS]: {
    value: CLICKS,
    label: DASHBOARD_METRICS_LABELS[CLICKS],
    render: (value) =>
      (!isNil(value) &&
        transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG)) ||
      value,
  },
  [CPM]: {
    value: CPM,
    label: DASHBOARD_METRICS_LABELS[CPM],
    render: (value) =>
      (!isNil(value) && numberToDollar(value, CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [CTR]: {
    value: CTR,
    label: DASHBOARD_METRICS_LABELS[CTR],
    render: (value) => (!isNil(value) && `${toFixedfp(2)(value)}%`) || value,
  },
  [CPC]: {
    value: CPC,
    label: DASHBOARD_METRICS_LABELS[CPC],
    render: (value) =>
      (!isNil(value) && numberToDollar(value, CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [ACCOUNT_CTR]: {
    value: ACCOUNT_CTR,
    label: DASHBOARD_METRICS_LABELS[ACCOUNT_CTR],
    render: (value) => (!isNil(value) && `${toFixedfp(2)(value)}%`) || value,
  },
};

export const ADS_PERFORMANCE_METRICS = {
  [MATCHED_IMPRESSIONS]: {
    value: MATCHED_IMPRESSIONS,
    label: DASHBOARD_METRICS_LABELS[MATCHED_IMPRESSIONS],
    default: true,
    render: (value) =>
      (!isNil(value) &&
        transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG)) ||
      value,
  },
  [BUDGET_SPENT]: {
    value: BUDGET_SPENT,
    label: DASHBOARD_METRICS_LABELS[BUDGET_SPENT],
    render: (value) =>
      (!isNil(value) &&
        numberToDollar(value, ABBREVIATED_CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [CLICKS]: {
    value: CLICKS,
    label: DASHBOARD_METRICS_LABELS[CLICKS],
    render: (value) =>
      (!isNil(value) &&
        transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG)) ||
      value,
  },
  [CPM]: {
    value: CPM,
    label: DASHBOARD_METRICS_LABELS[CPM],
    render: (value) =>
      (!isNil(value) && numberToDollar(value, CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [CTR]: {
    value: CTR,
    label: DASHBOARD_METRICS_LABELS[CTR],
    render: (value) => (!isNil(value) && `${toFixedfp(2)(value)}%`) || value,
  },
  [CPC]: {
    value: CPC,
    label: DASHBOARD_METRICS_LABELS[CPC],
    render: (value) =>
      (!isNil(value) && numberToDollar(value, CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [ACCOUNT_CTR]: {
    value: ACCOUNT_CTR,
    label: DASHBOARD_METRICS_LABELS[ACCOUNT_CTR],
    render: (value) => (!isNil(value) && `${toFixedfp(2)(value)}%`) || value,
  },
  [VIEWABILITY]: {
    value: VIEWABILITY,
    label: DASHBOARD_METRICS_LABELS[VIEWABILITY],
    render: (value) => (!isNil(value) && `${toFixedfp(2)(value)}%`) || value,
  },
};

export const ACCOUNTS_PERFORMANCE_METRICS = {
  [MATCHED_IMPRESSIONS]: {
    value: MATCHED_IMPRESSIONS,
    label: DASHBOARD_METRICS_LABELS[MATCHED_IMPRESSIONS],
    default: true,
    render: (value) =>
      (!isNil(value) &&
        transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG)) ||
      value,
  },
  [MID_LEVEL_BUDGET_SPENT]: {
    value: MID_LEVEL_BUDGET_SPENT,
    label: DASHBOARD_METRICS_LABELS[MID_LEVEL_BUDGET_SPENT],
    render: (value) =>
      (!isNil(value) &&
        numberToDollar(value, ABBREVIATED_CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [MATCHED_CLICKS]: {
    value: MATCHED_CLICKS,
    label: DASHBOARD_METRICS_LABELS[MATCHED_CLICKS],
    render: (value) =>
      (!isNil(value) &&
        transformNumber(value, ABBREVIATED_COMPACT_NUMBER_CONFIG)) ||
      value,
  },
  [CPM]: {
    value: CPM,
    label: DASHBOARD_METRICS_LABELS[CPM],
    render: (value) =>
      (!isNil(value) && numberToDollar(value, CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [CTR]: {
    value: CTR,
    label: DASHBOARD_METRICS_LABELS[CTR],
    render: (value) => (!isNil(value) && `${toFixedfp(2)(value)}%`) || value,
  },
  [CPC]: {
    value: CPC,
    label: DASHBOARD_METRICS_LABELS[CPC],
    render: (value) =>
      (!isNil(value) && numberToDollar(value, CURRENCY_DISPLAY_CONFIG)) ||
      value,
  },
  [VIEWABILITY]: {
    value: VIEWABILITY,
    label: DASHBOARD_METRICS_LABELS[VIEWABILITY],
    render: (value) => (!isNil(value) && `${toFixedfp(2)(value)}%`) || value,
  },
};

export const MEASURE_TO_CUBE_MAP = {
  [BUDGET_SPENT]: ADS_DAILY_STATS_CUBE,
  [ACCOUNTS_REACHED]: ADS_MID_DAILY_STATS_CUBE,
  [MEDIA_IMPRESSIONS]: ADS_DAILY_STATS_CUBE,
  [CPM]: ADS_DAILY_STATS_CUBE,
  [VIEWABILITY]: ADS_DAILY_STATS_CUBE,
  [CLICKS]: ADS_DAILY_STATS_CUBE,
  [CTR]: ADS_DAILY_STATS_CUBE,
  [CPC]: ADS_DAILY_STATS_CUBE,
  [ACCOUNT_CTR]: ADS_MID_DAILY_STATS_CUBE,
  [VIEW_THROUGHS]: VIEW_THROUGH_ACTIVITIES_CUBE,
  [VTR]: VIEW_THROUGH_ACTIVITIES_CUBE,
  [ACCOUNT_VTR]: ACCOUNT_VIEW_THROUGHS_CUBE,
  [INFLUENCED_FORM_FILLS]: FORM_FILL_ACTIVITES_CUBE,
  [ACCOUNTS_ENGAGED]: ACCOUNT_VIEW_THROUGHS_CUBE,
  [COST_PER_ACCOUNT_REACHED]: ADS_MID_DAILY_STATS_CUBE,
  [COST_PER_ACCOUNT_ENGAGED]: ACCOUNT_VIEW_THROUGHS_CUBE,
  [BUDGET]: CAMPAIGN_CUBE,
};

export const GEOGRAPHICAL_MEASURE_TO_CUBE_MAP = {
  [MATCHED_IMPRESSIONS]: ADS_MID_DAILY_STATS_CUBE,
  [MID_LEVEL_BUDGET_SPENT]: ADS_MID_DAILY_STATS_CUBE,
  [ACCOUNTS_CLICKS]: ADS_MID_DAILY_STATS_CUBE,
  [ACCOUNTS_ENGAGED]: ACCOUNT_VIEW_THROUGHS_CUBE,
};

export const CAMPAIGN_TYPE_MEASURE_TO_CUBE_MAP = {
  [MATCHED_IMPRESSIONS]: ADS_MID_DAILY_STATS_CUBE,
  [BUDGET]: CAMPAIGN_CUBE,
  [CLICKS]: ADS_DAILY_STATS_CUBE,
  [ACCOUNTS_ENGAGED]: ACCOUNT_VIEW_THROUGHS_CUBE,
};

export const CAMPAIGN_PERFORMANCE_MEASURE_TO_CUBE_MAP = {
  [MATCHED_IMPRESSIONS]: ADS_MID_DAILY_STATS_CUBE,
  [BUDGET]: CAMPAIGN_CUBE,
  [MID_LEVEL_BUDGET_SPENT]: ADS_MID_DAILY_STATS_CUBE,
  [CLICKS]: ADS_DAILY_STATS_CUBE,
  [CPM]: ADS_MID_DAILY_STATS_CUBE,
  [CTR]: ADS_MID_DAILY_STATS_CUBE,
  [CPC]: ADS_MID_DAILY_STATS_CUBE,
  [ACCOUNT_CTR]: ADS_MID_DAILY_STATS_CUBE,
  [VIEW_THROUGHS]: VIEW_THROUGH_ACTIVITIES_CUBE,
  [VIEWABILITY]: ADS_MID_DAILY_STATS_CUBE,
  [ACCOUNTS_ENGAGED]: ACCOUNT_VIEW_THROUGHS_CUBE,
};

export const ADS_PERFORMANCE_MEASURE_TO_CUBE_MAP = {
  [MATCHED_IMPRESSIONS]: ADS_DAILY_STATS_CUBE,
  [BUDGET_SPENT]: ADS_DAILY_STATS_CUBE,
  [CLICKS]: ADS_DAILY_STATS_CUBE,
  [CPM]: ADS_DAILY_STATS_CUBE,
  [CTR]: ADS_DAILY_STATS_CUBE,
  [CPC]: ADS_DAILY_STATS_CUBE,
  [ACCOUNT_CTR]: ADS_MID_DAILY_STATS_CUBE,
  [VIEWABILITY]: ADS_DAILY_STATS_CUBE,
};

export const ACCOUNTS_PERFORMANCE_MEASURE_TO_CUBE_MAP = {
  [MATCHED_IMPRESSIONS]: ADS_MID_DAILY_STATS_CUBE,
  [MID_LEVEL_BUDGET_SPENT]: ADS_MID_DAILY_STATS_CUBE,
  [MATCHED_CLICKS]: ADS_MID_DAILY_STATS_CUBE,
  [CPM]: ADS_MID_DAILY_STATS_CUBE,
  [CTR]: ADS_MID_DAILY_STATS_CUBE,
  [CPC]: ADS_MID_DAILY_STATS_CUBE,
  [ACCOUNT_CTR]: ADS_MID_DAILY_STATS_CUBE,
  [VIEW_THROUGHS]: VIEW_THROUGH_ACTIVITIES_CUBE,
  [VIEWABILITY]: ADS_MID_DAILY_STATS_CUBE,
  [ACCOUNTS_ENGAGED]: ACCOUNT_VIEW_THROUGHS_CUBE,
};

export const SEGMENT_PERFORMANCE_MEASURE_TO_CUBE_MAP = {
  [ACCOUNTS_REACHED]: ADS_MID_DAILY_STATS_CUBE,
  [MATCHED_IMPRESSIONS]: ADS_DAILY_STATS_CUBE,
  [BUDGET_SPENT]: ADS_DAILY_STATS_CUBE,
  [CLICKS]: ADS_DAILY_STATS_CUBE,
  [CPM]: ADS_DAILY_STATS_CUBE,
  [CTR]: ADS_DAILY_STATS_CUBE,
  [CPC]: ADS_DAILY_STATS_CUBE,
  [ACCOUNT_CTR]: ADS_MID_DAILY_STATS_CUBE,
};

export const SORT_ORDER = {
  asc: "asc",
  desc: "desc",
};

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_KEY = "dt";

export const PERFORMANCE_TREND_CUMULATIVE_STAT_TYPES = {
  left: "leftStat",
  right: "rightStat",
};

export const CAMPAIGN_SOURCE_LABELS_MAP = {
  [CAMPAIGN_SOURCE.EXTERNAL]: "External Media",
  [CAMPAIGN_SOURCE.INTERNAL]: "Advertising",
  [CAMPAIGN_SOURCE.RETARGETING]: "Visitor Retargeting",
  [CAMPAIGN_SOURCE.LINKEDIN]: "Segments",
  [CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING]: "Advertising",
  [CAMPAIGN_SOURCE.CONTEXTUAL]: "Contextual",
};

export const SET_OPERATOR = "set";
export const EQUALS_OPERATOR = "equals";

export const TRUNCATE_WITH_ELLIPSES_STYLE = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const HOVER_LINK_STYLE = css`
  cursor: pointer;
  &:hover {
    color: ${`var(${TextColor.LINK_PRIMARY_LIGHT})`};
  }
`;

export const NO_DATA_EMPTY_STATE_MESSAGE = {
  title: "Data not found",
  description:
    "We couldn't find any data matching the filter(s) and timeframe selection. Please try again.",
};

export const ASCENDING_SORT = "asc";
export const DESCENDING_SORT = "desc";
export const TOGGLE_SORT_MAP = {
  [ASCENDING_SORT]: DESCENDING_SORT,
  [DESCENDING_SORT]: ASCENDING_SORT,
};
export const SORT_TOOLTIP_MAP = {
  [ASCENDING_SORT]: "Sorting from low to high",
  [DESCENDING_SORT]: "Sorting from high to low",
};

export const FIRST_JAN_2024 = moment("2024-01-01 00:00Z");

export const DEPRECATED_HIGHLIGHT_METRICS = [VTR, VIEW_THROUGHS];
