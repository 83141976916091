import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';

import styles from './ActionDropdown.module.scss';

export function ActionMenu({ defaultSelectedKeys, children, onClick }) {
  return (
    <Menu
      onClick={onClick}
      selectedKeys={defaultSelectedKeys}
      className={styles.menuContainer}
    >
      {children}
    </Menu>
  );
}

ActionMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  defaultSelectedKeys: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
};

