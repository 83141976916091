const initialState = {
  loaded: false,
  owner: '',
};

export const LOAD_OWNER = 'ACCESS/LOAD';
export const LOAD_OWNER_SUCCESS = 'ACCESS/LOAD_OWNER_SUCCESS';
export const LOAD_OWNER_FAILURE = 'ACCESS/LOAD_OWNER_FAILURE';

export const loadOwner = () => ({ type: LOAD_OWNER });
export const loadOwnerSuccess = (owner) => ({ type: LOAD_OWNER_SUCCESS, owner });
export const loadOwnerFailure = (owner) => ({ type: LOAD_OWNER_FAILURE, owner });

function accessDeniedReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_OWNER_FAILURE:
    case LOAD_OWNER_SUCCESS: {
      const { owner } = action;
      return { owner, loaded: true };
    }
    default: {
      return state;
    }
  }
}

export default accessDeniedReducer;
