import { getAsyncInjectors } from 'store/utils';
import ManageRoute from './routes/Manage';
import SegmentRoute from './routes/Segment';
import AnalyticsRoute from './routes/Analytics';
import { SEGMENT_SYNC_BASE_REDUX_STATE_KEY } from './containers/SocialSegmentSyncModal/constants';
import {
  SEGMENT_SYNC_BASE_REDUX_STATE_KEY as SAAS_SEGMENT_SYNC_BASE_REDUX_STATE_KEY,
} from './containers/SaaSSocialSegmentSyncModal/constants';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'segments',
    name: 'Segments',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/segments/manage'),
    },
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        // segments manage
        const manageReducer = require('./routes/Manage/modules');
        const manageSagas = require('./routes/Manage/sagas');
        injectReducer('segmentsManage', manageReducer.default);
        injectSagas('segmentsManage', manageSagas.default);

        // published segments
        const sagas = require('../../routes/Settings/routes/PublishedSegments/sagas');
        const reducer = require('../../routes/Settings/routes/PublishedSegments/modules');
        injectReducer('publishedSegments', reducer.default);
        injectSagas('publishedSegments', sagas.default);


        // segments segment
        const segmentReducer = require('./routes/Segment/modules');
        const segmentSagas = require('./routes/Segment/sagas');
        injectReducer('segmentsSegment', segmentReducer.default);
        injectSagas('segmentsSegment', segmentSagas.default);

        // segments analytics
        const analyticsReducer = require('./routes/Analytics/modules');
        const analyticsSagas = require('./routes/Analytics/sagas');
        injectReducer('segmentAnalytics', analyticsReducer.default);
        injectSagas('segmentAnalytics', analyticsSagas.default);

        const socialSegmentSyncReducer = require('./containers/SocialSegmentSyncModal/modules');
        const socialSegmentSyncSagas = require('./containers/SocialSegmentSyncModal/sagas');
        injectReducer(SEGMENT_SYNC_BASE_REDUX_STATE_KEY, socialSegmentSyncReducer.default);
        injectSagas(SEGMENT_SYNC_BASE_REDUX_STATE_KEY, socialSegmentSyncSagas.default);

        const saasSocialSegmentSyncReducer =
          require('./containers/SaaSSocialSegmentSyncModal/modules');
        const saasSocialSegmentSyncSagas =
          require('./containers/SaaSSocialSegmentSyncModal/sagas');
        injectReducer(SAAS_SEGMENT_SYNC_BASE_REDUX_STATE_KEY, saasSocialSegmentSyncReducer.default);
        injectSagas(SAAS_SEGMENT_SYNC_BASE_REDUX_STATE_KEY, saasSocialSegmentSyncSagas.default);

        const containers = require('./containers');
        cb(null, containers.SegmentsSwitcher);
      }, 'segments');
    },
    childRoutes: [
      ManageRoute(store),
      SegmentRoute(store),
      AnalyticsRoute(store),
    ],
  };
}
