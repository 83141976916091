import { errors, decorators } from 'utils/validators';

export const UTM_QUERY_PARAMS = {
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_TERM: 'utm_term',
  UTM_CONTENT: 'utm_content',
  CLICK_URL: 'click_url',
};

export const UTM_FORM_FIELDS = {
  UTM_SOURCE: 'utmSource',
  UTM_MEDIUM: 'utmMedium',
  UTM_CAMPAIGN: 'utmCampaign',
  UTM_TERM: 'utmTerm',
  UTM_CONTENT: 'utmContent',
  CLICK_URL: 'clickUrl',
};

export const UTM_MAX_LENGTH=100;

export const UTM_BUILDER_FIELDS = [
  {
    id: UTM_FORM_FIELDS.UTM_MEDIUM,
    key: UTM_FORM_FIELDS.UTM_MEDIUM,
    name: UTM_FORM_FIELDS.UTM_MEDIUM,
    label: 'UTM Medium',
    required: true,
    manualTypeUtmValidations: [
      errors.isRequired,
      decorators.forceError(errors.maxLength(UTM_MAX_LENGTH)),
    ],
    dynamicTypeUtmValidations: [
      errors.isRequired,
    ],
    tooltipText: `Medium identifies the vehicles you are using to market your company by 
      answering the question “how are they getting to you?” 
      Typically Medium is the highest level channel.`,
  },
  {
    id: UTM_FORM_FIELDS.UTM_CAMPAIGN,
    key: UTM_FORM_FIELDS.UTM_CAMPAIGN,
    name: UTM_FORM_FIELDS.UTM_CAMPAIGN,
    label: 'UTM Campaign',
    required: true,
    manualTypeUtmValidations: [
      errors.isRequired,
      decorators.forceError(errors.maxLength(UTM_MAX_LENGTH)),
    ],
    dynamicTypeUtmValidations: [
      errors.isRequired,
    ],
    tooltipText: `Campaign is used to identify a specific marketing campaign 
      by answering the question: “why are they coming to you?”`,
  },
  {
    id: UTM_FORM_FIELDS.UTM_TERM,
    key: UTM_FORM_FIELDS.UTM_TERM,
    name: UTM_FORM_FIELDS.UTM_TERM,
    label: 'UTM Term',
    required: false,
    manualTypeUtmValidations: [
      decorators.forceError(errors.maxLength(UTM_MAX_LENGTH)),
    ],
    dynamicTypeUtmValidations: [],
    tooltipText: 'Term is often used to identify keywords in ads for paid programs.',
  },
  {
    id: UTM_FORM_FIELDS.UTM_CONTENT,
    key: UTM_FORM_FIELDS.UTM_CONTENT,
    name: UTM_FORM_FIELDS.UTM_CONTENT,
    label: 'UTM Content',
    required: false,
    manualTypeUtmValidations: [
      decorators.forceError(errors.maxLength(UTM_MAX_LENGTH)),
    ],
    dynamicTypeUtmValidations: [],
    tooltipText: `Content is used to track more than one marketing piece 
      existing on a single web page.`,
  },
];

export const options = [
    { label: 'Campaign Name', value: '{{campaign_name}}' },
    { label: 'Ad Type', value: '{{campaign_type}}' },
];

export const UTM_BUILDER_FORM = 'utmBuilderForm';

export const DEFAULT_UTM_SOURCE = '6sense';

export const MANUAL = 'manual';
export const DYNAMIC = 'dynamic';

export const UTM_TYPES=[
  { label: 'Manual', value: MANUAL },
  { label: 'Dynamic', value: DYNAMIC },
];

export const UTM_SOURCE_TOOLTIP = `Source identifies the sub-channel that is the source of 
  your traffic by answering the question “where is this person coming from?” 
  Typically Source refers to the platform being used within a specific channel.`;

// eslint-disable-next-line max-len
export const UTM_BUILDER_HELP_LINK = 'https://support.6sense.com/hc/en-us/articles/4405026813971-Creating-a-6sense-Ads-Campaign#ads-setup-0-2';
