import React, { Component } from 'react';
import { connect } from 'react-redux';

// I like the config way better but doing it this way this time to mimick
// how react-redux connect just accepts arguments
const RuntimeConnect =
(mapPropsToMapStateToProps = () => { },
  mapPropsToActions = () => { },
  mapPropsToMergeProps = () => (stateProps, actionProps, ownProps) => ({
    ...stateProps,
    ...actionProps,
    ...ownProps,
  }),
  reconnectWhen = () => false,
  contextType = {}) =>
  (WrappedComponent) =>
    class CrossfilterClass extends Component {
      constructor(props, context) {
        super(props, context);
        // Here or component willmount? I just put it here because i read online
        // that if the initialization does not
        // depend on the dom then you can just put in constructor
        this.state = {
          ConnectedComponent: connect(
            mapPropsToMapStateToProps(props, context),
            mapPropsToActions(props, context),
            mapPropsToMergeProps(props, context),
          )(WrappedComponent),
        };
      }

      static contextTypes = contextType;

      componentWillReceiveProps(nextProps) {
        if (reconnectWhen(this.props, nextProps)) {
          this.setState({
            ConnectedComponent: connect(
              mapPropsToMapStateToProps(nextProps, this.context),
              mapPropsToActions(nextProps, this.context),
              mapPropsToMergeProps(nextProps, this.context),
            )(WrappedComponent),
          });
        }
      }

      render() {
        const { ConnectedComponent } = this.state;
        return (
          <ConnectedComponent
            {...this.props}
          />);
      }
    };

export default RuntimeConnect;
