/* AA Color Constants */
export const COLORS = Object.freeze({
  AA_GREEN: '#2EB62D',
  AA_ERROR: '#E12C2C',
  AA_YELLOW: '#EBA300',
  AA_GREY: '#001F32',
  AA_GREY1: '#505C62',
  AA_GREY2: '#C1C7CC',
  AA_GREY3: '#C6CACE',
  AA_GREY4: '#9299A3',
  AA_BLUE: '#0082D4',
  AA_MAROON: '#E12C6A',
  AA_PURPLE: '#6A45EE',
  AA_WHITE: '#FFFFFF',
  AA_BLUEGREY: '#394348',
  AA_BEIGE: '#F3EFEC',
  AA_6SENSE_GREEN: '#13bbb2',
  AA_6SENSE_BLUE: '#0068A9',
  AA_BLUE_0: '#B2D9F2',
  AA_BLUE_1: '#3AA1DE',
  AA_BLUE_2: '#002E49',
  AA_DARK_GREEN: '#0C7972',


  /* Use these types when using the colors mixin: */
  ACTIVE: 'active',
  BACKGROUND: 'background',
  BLACK: 'black',
  BLUE: 'blue',
  BLUEGREY: 'bluegrey',
  DARK_GREY: 'dark-grey',
  ERROR: 'error',
  GREEN: 'green',
  GREY: 'grey',
  GREY1: 'grey1',
  GREY2: 'grey2',
  GREY3: 'grey3',
  GREY4: 'grey4',
  MAROON: 'maroon',
  PURPLE: 'purple',
  SECONDARY_BLUE: 'secondary-blue',
  SEMI_TRANSPARENT_BLUE: 'semi-transparent-blue',
  SIX_SENSE_GREEN: 'six-sense-green',
  TRANSLUCENT: 'translucent',
  TRANSLUCENT_BLUE: 'translucent-blue',
  TRANSLUCENT_BLUEGREY: 'translucent-bluegrey',
  TRANSLUCENT_GREY: 'translucent-grey',
  TRANSPARENT_BLUE: 'transparent-blue',
  WHITE: 'white',
  YELLOW: 'yellow',
  DEFAULT_TEXT: 'default-text',
});
