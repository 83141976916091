import { curry, is } from 'ramda';
import rollbar from 'lib/rollbar';

const compile = (expr, ...args) => {
  const name = expr.constructor.name;
  try {
    return DISPATCH[name](expr, ...args);
  } catch (e) {
    rollbar.warn(`Unable to compile ${name}`);
  }

  return null;
};

const binExpr = curry((symbol, expr) => `${compile(expr.lhs)} ${symbol} ${compile(expr.rhs)}`);

const needsParens = (expr) => {
  const ops = ['And', 'Or', 'ModelAnd', 'ModelOr'];
  const lType = expr.lhs.constructor.name;
  const rType = expr.lhs.constructor.name;

  if (ops.includes(lType) && ops.includes(rType)) return true;

  return false;
};

const logicalOp = curry((sqlOperator, expr) =>
  needsParens(expr)
    ? `(${compile(expr.lhs)}) ${sqlOperator} (${compile(expr.rhs)})`
    : `${compile(expr.lhs)} ${sqlOperator} ${compile(expr.rhs)}`
);

const caseWhen = ({ conditions, default_ }) =>
  'CASE' +
  `${conditions
    .map((cond) => ` WHEN ${compile(cond.when)} THEN ${compile(cond.then)}`)
    .join(' ')}` +
  ' ELSE ' +
  `${default_ ? compile(default_) : "''"}` +
  ' END';

const cast = ({ expr, cast_type }) => `CAST(${compile(expr)} AS ${cast_type})`;

const inOp = ({ expr, exprs }) =>
  `${compile(expr)} IN (${exprs.map((e) => compile(e)).join(', ')})`;

const notInOp = ({ expr, exprs }) =>
  `${compile(expr)} NOT IN (${exprs.map((e) => compile(e)).join(', ')})`;

const scanOp = ({ name }) => `  ${name}`;

const joinOp = ({ left, right, bool_op }) =>
  `${compile(left)} JOIN ${compile(right)} ON ${compile(bool_op)}`;

const leftjoinOp = ({ left, right, bool_op }) =>
  `${compile(left)} LEFT OUTER JOIN ${compile(right)} ON ${compile(bool_op)}`;

const projectOp = ({ relation, exprs }) =>
  'SELECT\n' +
  `${exprs.map((expr) => compile(expr)).join(',\n')}` +
  '\nFROM\n' +
  `${compile(relation)}`;

const selectionOp = ({ relation, bool_op }) => `${compile(relation)} \nWHERE\n ${compile(bool_op)}`;

const constOp = ({ value }, noQuotes) =>
  is(Number, value) || is(Boolean, value) || noQuotes ? `${value}` : `'${value}'`;

const unionAllOp = ({ left, right }) => `${compile(left)} \nUNION ALL\n ${compile(right)}`;

const betweenOp = ({ expr, le_op, ge_op }) =>
  `${compile(expr)} BETWEEN ${compile(le_op)} AND ${compile(ge_op)}`;

const DISPATCH = {
  VarExpr: ({ name }) => name,

  Const: (expr, ...args) => constOp(expr, ...args),

  NullExpr: () => 'null',
  AllColsExpr: () => '*',
  AliasExpr: ({ expr, alias }) => `    ${compile(expr)} AS ${alias}`,

  Func: ({ name, exprs }) => `${name}(${exprs.map((e) => compile(e)).join(', ')})`,

  EqOp: binExpr('='),
  NeOp: binExpr('!='),
  NotEqOp: binExpr('!='),
  LtOp: binExpr('<'),
  LeOp: binExpr('<='),
  GtOp: binExpr('>'),
  GeOp: binExpr('>='),

  StartsWithOp: ({ lhs, rhs }) => `${compile(lhs)} LIKE '%${compile(rhs, true)}'`,
  EndsWithOp: ({ lhs, rhs }) => `${compile(lhs)} LIKE '${compile(rhs, true)}%'`,
  ContainsOp: ({ lhs, rhs }) => `${compile(lhs)} LIKE '%${compile(rhs, true)}%'`,
  NotContainsOp: ({ lhs, rhs }) => `${compile(lhs)} NOT LIKE '%${compile(rhs, true)}%'`,
  LikeOp: ({ lhs, rhs }) => `${compile(lhs)} LIKE ${compile(rhs)}`,
  NotLikeOp: ({ lhs, rhs }) => `${compile(lhs)} NOT LIKE ${compile(rhs)}`,
  RLikeOp: ({ lhs, rhs }) => `${compile(lhs)} RLIKE ${compile(rhs)}`,
  NotRLikeOp: ({ lhs, rhs }) => `${compile(lhs)} NOT RLIKE ${compile(rhs)}`,
  RenameOp: ({ lhs, rhs }) => `   ${compile(lhs)} AS ${compile(rhs, true)}`,

  Or: logicalOp('OR'),
  And: logicalOp('AND'),
  ModelOr: logicalOp('OR'),
  ModelAnd: logicalOp('AND'),

  CaseWhen: caseWhen,
  Cast: cast,

  InOp: inOp,
  NotInOp: notInOp,
  NotOp: ({ expr }) => `NOT ${compile(expr)}`,

  ScanOp: scanOp,
  ProjectOp: projectOp,
  SelectionOp: selectionOp,
  JoinOp: joinOp,
  LeftJoinOp: leftjoinOp,
  UnionAllOp: unionAllOp,
  BetweenOp: betweenOp,
};

const ast2sql = (expr) => compile(expr);

export default ast2sql;
