import React from 'react';
import { Text } from 'v2-components';
import { css } from 'styles/emotion';
import { classNames } from 'utils/utils';
import { forEach } from 'lodash';

const styles = {
  link: css({
    color: '#0082D4',
    textAlign: 'right',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    cursor: 'pointer',
  }),
};

export const EmailsListRender = (
  { emails, maxAllowedLength = 200, suffixText, removeSuffixSpace }:
  { emails: string[], maxAllowedLength?: number, suffixText: string, removeSuffixSpace?: boolean }
) => {
  let indexTill = 0;
  let lengthCurrent = 0;
  forEach(emails, (email) => {
    if (lengthCurrent + email.length < maxAllowedLength) {
      lengthCurrent += email.length;
      indexTill++;
    }
  });
  const [showMore, setShowMore] = React.useState(false);
  const getLink = () => {
    if (indexTill < emails.length) {
      if (showMore) {
        return (
          <Text
            onClick={() => setShowMore(false)}
            className={classNames(styles.link)}
          >
            show less
          </Text>
        );
      }
      return (
        <Text
          onClick={() => setShowMore(true)}
          className={classNames(styles.link)}
        >
          show more
        </Text>
      );
    }
    return '';
  };
  const getShowMoreValid = () => {
    if (indexTill < emails.length) {
      return !showMore;
    }
    return false;
  };
  return (
    <React.Fragment>
      <strong>
        {' '}{emails.slice(0, showMore ? emails.length : indexTill).join(', ')}
      </strong>
      {removeSuffixSpace ? '' : ' '}
      {getShowMoreValid() ? '' : `${suffixText}`}
      {getLink()}{' '}
    </React.Fragment>
  );
};
