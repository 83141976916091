import { MODEL_UPDATE_ROUTE } from './constants';

export default function createRoutes() {
  return {
    path: MODEL_UPDATE_ROUTE,
    name: 'ModelUpdate',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          cb(null, containers.ModelUpdate);
        },
        'ModelUpdate'
      );
    },
  };
}
