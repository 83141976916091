import React from 'react';
import PropTypes from 'prop-types';
import { map, range } from 'lodash';
import { ImageContext } from 'contexts';
import { Col, Row, Icon, Text } from 'v2-components';
import styles from './TableLayout.module.scss';
import { classNames } from 'utils/utils';
import { Skeleton } from 'antd';

const ImageProvider = ImageContext({ routeKey: 'table_layout' });

function TableLayout(props) {
  const {
    tableHeader,
    tableBody,
    isLoading,
    orderList,
    ordering,
    orderBy,
    loadingClassName,
    headerClassName,
    tableDataClassName,
    sortedColumnAlignment=Row.JUSTIFY_CONTENT.SPACE_BETWEEN,
    rowCount,
  } = props;
  const orderListFn = (key) => () => {
    const newOrdering = key === orderBy && ordering === 'desc' ? 'asc' : 'desc';
    orderList(key, newOrdering);
  };

  const tableHeaderContent = map(tableHeader, ({ columnName, className, key }) => {
    const sortedColumn = orderBy === key;
    return (
      <Col
        flexGrow={0}
        className={`${styles.columnHeader} ${
          !key ? styles.disabledSortColumn : styles.enabledSortColumn
        } ${className}`}
        key={columnName}
        onClick={key && orderListFn(key)}
      >
        <Row
          alignItems={Row.ALIGN_ITEMS.CENTER}
          justifyContent={sortedColumnAlignment}
        >
          <Text bold>{columnName}</Text>
          {key ?
            (<Row
              flexDirection={'column'}
              className={styles.cursorPointer}
            >
              <Icon
                type="expand_less"
                size={Icon.SIZE.MEDIUM}
                className={classNames(
                styles.negativeMargin,
                sortedColumn && ordering === 'desc' && styles.inActiveSortIcon,
              )}
              />
              <Icon
                type="expand_more"
                size={Icon.SIZE.MEDIUM}
                className={classNames(
                sortedColumn && ordering === 'asc' && styles.inActiveSortIcon
              )}
              />
            </Row>):null
          }
        </Row>
      </Col>
    );
  });

  const SkeletonRowLoader = map(tableHeader, ({ className, key }) => (
    <Skeleton className={className} paragraph={false} key={key} active />
    ));

  const SkeletonLoaderList = range(rowCount).map((currentRow) => (
    <Row
      alignItems={Row.ALIGN_ITEMS.CENTER}
      className={`${styles.loadingBox} ${loadingClassName}`}
      key={currentRow}
    >
      {SkeletonRowLoader}
    </Row>
  ));

  return (
    <ImageProvider>
      <Row
        alignItems={Row.ALIGN_ITEMS.CENTER}
        className={classNames(styles.tableHeaderRow, headerClassName)}
      >
        {tableHeaderContent}
      </Row>
      {isLoading ? (
        <Col
          className={tableDataClassName}
        >
          {SkeletonLoaderList}
        </Col>
      ) : (
        <Row
          className={tableDataClassName}
          flexDirection={Row.FLEX_DIRECTION.COLUMN}
        >{
          tableBody}
        </Row>
      )}
    </ImageProvider>
  );
}

TableLayout.propTypes = {
  tableHeader: PropTypes.array,
  tableBody: PropTypes.arrayOf(PropTypes.element),
  isLoading: PropTypes.bool,
  orderList: PropTypes.func,
  ordering: PropTypes.string,
  orderBy: PropTypes.string,
  loadingClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  tableDataClassName: PropTypes.string,
  sortedColumnAlignment: PropTypes.any,
  rowCount: PropTypes.number,
};

TableLayout.defaultProps = {
  ordering: 'desc',
  orderBy: 'id',
  rowCount: 5,
};

export default TableLayout;
