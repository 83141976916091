import React from "react";
import { Text } from "@sixsense/core/components";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const CREATED_BY = {
  key: "created-by",
  csvColumnKey: "created_by",
  title: <Label name={CAMPAIGN_LABELS.createdBy} />,
  dataIndex: "created_by",
  width: pxToRem(200),
  render: (createdBy) => (
    <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
      {createdBy && createdBy.username ? createdBy.username : "-"}
    </Text.Body>
  ),
};
