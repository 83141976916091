import AdPlacementListRoute from "./routes/AdPlacementList";
import AdPlacementRoute from "./routes/AdPlacement";
import CreateEditAdRoute from "./routes/CreateEditAd";
import { getAsyncInjectors } from "store/utils";
import { AD_LIBRARY_BASE_ROUTE } from "./constants";
import { ADVERTISING_PATHS } from "../../constants";

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: AD_LIBRARY_BASE_ROUTE,
    name: "ad_library",
    indexRoute: {
      onEnter: (nextState, replace) => replace(`/${ADVERTISING_PATHS.adsList}`),
    },
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require("./containers");
          const sagas = require("./sagas");
          const reducer = require("./modules");
          injectReducer("adLibrary", reducer.default);
          injectSagas("adLibrary", sagas.default);
          cb(null, containers.AdLibrary);
        },
        "adlibrary"
      );
    },
    childRoutes: [
      AdPlacementListRoute(store),
      AdPlacementRoute(store),
      CreateEditAdRoute(store),
    ],
  };
}
