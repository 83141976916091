export default function createRoutes() {
  return {
    path: 'table_create',
    name: 'table_create',
    getComponent(nextState, cb) {
      require.ensure(
              [],
              (require) => {
                const containers = require('../../containers/TableCreate/containers');
                cb(null, containers.TableCreate);
              },
              'settings_standardization_prepete_table_create'
            );
    },
  };
}

