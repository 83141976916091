import React, { useEffect, useState } from 'react';
import { Modal, Text, Button, Row, Input, Col,
  TetheredSelect, Loading2, Tooltip2, Link } from 'v2-components';
import Icon from 'v2-components/Icon';
import PropTypes from 'prop-types';
import styles from './UTMBuilder.module.scss';
import { reduxForm, Field, getFormValues, getFormSyncErrors, change } from 'redux-form';
import { errors, decorators } from 'utils/validators';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  formatDynamicMacrosValue,
  normalizeDynamicMacroValue,
  appendStringType,
  getInitialFormValues,
  getSubmittedUtmQueryParamsValues,
  invalidRepetationOfUtmParams,
} from './utils';
import {
    UTM_BUILDER_FIELDS,
    UTM_BUILDER_FORM,
    UTM_FORM_FIELDS,
    UTM_TYPES,
    MANUAL,
    DYNAMIC,
    UTM_MAX_LENGTH,
    DEFAULT_UTM_SOURCE,
    UTM_SOURCE_TOOLTIP,
    UTM_BUILDER_HELP_LINK,
} from './constants';
import { wrapUrlWithQueryParams, classNames, scrollTo } from 'utils/utils';
import { get } from 'lodash';
import { utmMacrosListStateGenerator } from './stateGenerators';
import { PROMISE_STATES } from 'modules/global/constants';
import GeneratedUrlSection from './GeneratedUrlSection';

const FormInput = Input.form;
const FormTetheredSelect = TetheredSelect.form;
const { Option } = TetheredSelect;

const formLayout = {
  marginTop: 0,
  marginRight: 5,
  marginBottom: 0,
  stacked: true,
  justifyLabel: 'flex-start',
  inputSize: '100%',
  stackedMargin: 10,
  width: '100%',
  justifyRightExtra: 'flex-end',
};

const TeatheredFormSelect = (props) => <FormTetheredSelect
  className={styles.inputClassName}
  id={props.id}
  allowClear
  optionLabelProp={'label'}
  mode="multiple"
  showArrow
  {...props}
>
  {props.options.map(({ label, value, info }) =>
    <Option id={label} key={label} value={value} label={label} className={styles.option}>
      <Row justifyContent={Row.JUSTIFY_CONTENT.SPACE_BETWEEN}>
        <Tooltip2 overlay={info} placement={'left'}>
          {label}
        </Tooltip2>
      </Row>
    </Option>
  )}
</FormTetheredSelect>;

TeatheredFormSelect.propTypes = {
  id: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};

const UtmTypeSelect = (props) => <FormTetheredSelect
  className={styles.inputClassName}
  id={props.id}
  optionLabelProp={'title'}
  {...props}
  dropdownMatchSelectWidth={false}
>
  {props.options.map(({ label, value }) =>
    <Option id={value} key={value} value={value} title={label}>
      {label} {value === DYNAMIC ?
        <Text className={styles.textRecommended}>(Recommended)</Text>: null}
    </Option>
  )}
</FormTetheredSelect>;

UtmTypeSelect.propTypes = {
  id: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};

const getFieldLabel = (fieldLabel, tooltipText, required) =>
  (<Row>
    <Text bold>{fieldLabel}{required ? '*' : ''}</Text>
    {tooltipText ? <Tooltip2
      overlay={tooltipText}
      overlayStyle={{ maxWidth: '25vw' }}
      placement={'right'}
      className={styles.labelTooltip}
    >
      <Icon
        type={'info_outlined'}
        size={Icon.SIZE.LARGE}
        className={classNames('aam-l--5', styles.infoIcon)}
        pointer
      />
    </Tooltip2>
    :null}
  </Row>);

const UTMBuilderModalComponent = ({
  updateUrl,
  formValues,
  formSyncErrors,
  closeModal,
  modalVisible,
  loadUtmMacrosOptions,
  loading,
  loaded,
  options,
  clickUrlValidations,
  updateFormValue,
}) => {
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [showUtmValidationMessage, setShowUtmValidationMessage] = useState(false);

  useEffect(() => {
    if (!loaded) loadUtmMacrosOptions();
  }, []);

  useEffect(() => {
    setShowUtmValidationMessage(invalidRepetationOfUtmParams(generatedUrl));
  }, [generatedUrl]);

  const applyUtmParamsToUrl = () => {
    const submittedUtmQueryParamsValues = getSubmittedUtmQueryParamsValues(formValues);
    const urlWithUpdatedQueryParams = wrapUrlWithQueryParams(
      get(formValues, UTM_FORM_FIELDS.CLICK_URL),
      submittedUtmQueryParamsValues
    );

    return decodeURIComponent(urlWithUpdatedQueryParams);
  };

  const updateUrlAndCloseModal = () => {
    updateUrl(generatedUrl);
    closeModal();
  };

  const applyUtmParamsAndSetGeneratedUrl = () => {
    setGeneratedUrl(applyUtmParamsToUrl());
    scrollTo('generatedUrlSection');
  };

  const nonLoadedState = () => loading ? <Loading2.LoadingComponent /> :
  <Text className={styles.errorText}>Something went wrong</Text>;

  const resetRespectiveUtmField = (fieldName) => {
    updateFormValue(UTM_BUILDER_FORM, fieldName, '');
  };

  const generateUrlDisabled = !!formSyncErrors || !loaded;

  return (
    <Modal
      visible={modalVisible}
      title={<Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
        <Text bold>UTM Parameters</Text>
        <Text className={styles.subtext}>
          Specify base URL and UTM parameters to generate and apply your Click URL.
          &nbsp;<Link newWindow link={UTM_BUILDER_HELP_LINK}>Learn more</Link>
        </Text>
      </Row>}
      width={'40vw'}
      onCancel={closeModal}
      className={styles.modalClass}
      footer={<Row className={styles.footer} justifyContent={Row.JUSTIFY_CONTENT.FLEX_END}>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          disabled={!generatedUrl}
          onClick={updateUrlAndCloseModal}
          type={Button.PRIMARY}
        >
            Save Generated URL
          </Button>
      </Row>}
    >
      {loaded ? (<Col className={styles.formContainer}>
        <Col className={styles.mb15}>
          <Field
            id={UTM_FORM_FIELDS.CLICK_URL}
            name={UTM_FORM_FIELDS.CLICK_URL}
            key={UTM_FORM_FIELDS.CLICK_URL}
            label={getFieldLabel('Base URL', null, true)}
            formLayout={formLayout}
            component={FormInput}
            className={styles.inputField}
            validate={clickUrlValidations}
          />
        </Col>
        <Col className={styles.mb15}>
          <Field
            id={UTM_FORM_FIELDS.UTM_SOURCE}
            name={UTM_FORM_FIELDS.UTM_SOURCE}
            key={UTM_FORM_FIELDS.UTM_SOURCE}
            label={getFieldLabel('UTM Source', UTM_SOURCE_TOOLTIP, true)}
            formLayout={formLayout}
            component={FormInput}
            className={styles.inputField}
            validate={[
              errors.isRequired,
              decorators.forceError(errors.maxLength(UTM_MAX_LENGTH)),
            ]}
          />
          {get(formValues, 'utmSource') === DEFAULT_UTM_SOURCE ? null : <Row
            alignItems={Row.ALIGN_ITEMS.FLEX_START}
            className={styles.warningTextContainer}
          >
            <Icon
              type="warning"
              color="yellow"
              className={styles.warningIcon}
            />
            <Text className={styles.warningText}>
              &nbsp;&nbsp;It is recommend to use <b>{DEFAULT_UTM_SOURCE}</b> as UTM Source.
            </Text>
          </Row>
          }
        </Col>
        <Col className={styles.utmFieldsContainer}>
          {UTM_BUILDER_FIELDS.map(({ key, id, name, label, required,
            manualTypeUtmValidations, dynamicTypeUtmValidations, tooltipText }) => (
              <Col className={styles.mb15}>
                <Row>
                  <Row justifyContent={Row.JUSTIFY_CONTENT.FLEX_START} className={styles.label}>
                    {getFieldLabel(label, tooltipText, required)}
                  </Row>
                </Row>
                <Row>
                  <Row>
                    <Field
                      id={appendStringType(id)}
                      key={appendStringType(key)}
                      name={appendStringType(name)}
                      formLayout={formLayout}
                      component={UtmTypeSelect}
                      options={UTM_TYPES}
                      tether
                      className={styles.inputField}
                      onChange={() => resetRespectiveUtmField(name)}
                    />
                  </Row>
                  <Row className={styles.flexGrow}>
                    {get(formValues, appendStringType(name)) === MANUAL ?
                      <Field
                        id={id}
                        key={key}
                        name={name}
                        formLayout={formLayout}
                        required={required}
                        component={FormInput}
                        validate={manualTypeUtmValidations}
                        placeholder="Enter"
                        className={styles.inputField}
                        containerClassName={styles.width100}
                      />:<Field
                        id={id}
                        key={key}
                        name={name}
                        formLayout={formLayout}
                        required={required}
                        component={TeatheredFormSelect}
                        validate={dynamicTypeUtmValidations}
                        placeholder="Select Macros"
                        format={formatDynamicMacrosValue}
                        normalize={normalizeDynamicMacroValue}
                        options={options}
                        className={classNames(styles.inputField, styles.dynamicMacro)}
                        containerClassName={styles.width100}
                      />}
                  </Row>
                </Row>
              </Col>)
          )}
        </Col>
        <Col>
          <Row justifyContent={Row.JUSTIFY_CONTENT.FLEX_END} className={styles.generateUrl}>
            <Tooltip2
              overlay={generateUrlDisabled ?
                'All required field should have valid values':''
                }
            >
              <Button onClick={applyUtmParamsAndSetGeneratedUrl} disabled={generateUrlDisabled}>
              Generate URL
            </Button>
            </Tooltip2>
          </Row>
        </Col>
        <Col>
          <Row justifyContent={Row.JUSTIFY_CONTENT.FLEX_START} className={styles.label}>
            <Text weight={Text.WEIGHT.BOLD}>Generated URL</Text>
          </Row>
        </Col>
        <Col id={'generatedUrlSection'}>
          <GeneratedUrlSection url={generatedUrl} />
        </Col>
        {showUtmValidationMessage?<Col>
          <Row alignItems={Row.ALIGN_ITEMS.FLEX_START}>
            <Icon
              type="warning"
              color="yellow"
              className={styles.warningIcon}
            />&nbsp;&nbsp;
            <Text className={styles.warningText}>
            Duplicate utm params present in the url,
            It is recommended to remove duplicate from above click url input box and generate again.
          </Text>
          </Row>
        </Col>:null}
      </Col>) : nonLoadedState()}
    </Modal>
  );
};

UTMBuilderModalComponent.propTypes = {
  updateUrl: PropTypes.func,
  formValues: PropTypes.object,
  formSyncErrors: PropTypes.object,
  closeModal: PropTypes.func,
  modalVisible: PropTypes.bool,
  loadUtmMacrosOptions: PropTypes.func,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  options: PropTypes.array,
  clickUrlValidations: PropTypes.array,
  updateFormValue: PropTypes.func,
};

const UTMBuilderModal = compose(
  connect((state, ownProps) => ({
    initialValues: getInitialFormValues(get(ownProps, 'url', '')),
    formValues: getFormValues(UTM_BUILDER_FORM)(state),
    formSyncErrors: getFormSyncErrors(UTM_BUILDER_FORM)(state),
    loading: utmMacrosListStateGenerator.promiseStateSelector(state) === PROMISE_STATES.PENDING,
    loaded: utmMacrosListStateGenerator.promiseStateSelector(state) === PROMISE_STATES.SUCCESS,
    options: utmMacrosListStateGenerator.dataSelector(state),
  }),
    {
      loadUtmMacrosOptions: utmMacrosListStateGenerator.loadAction,
      updateFormValue: change,
    }),
reduxForm({
  form: UTM_BUILDER_FORM,
}))(UTMBuilderModalComponent);

const UTMBuilder = (props) => {
  const [modalVisible, setModalVisibility] = useState(false);
  const openModal = () => setModalVisibility(true);
  const closeModal = () => setModalVisibility(false);

  return (<React.Fragment>
    {props.children({ openModal, closeModal, modalVisible })}
    {modalVisible ? <UTMBuilderModal
      {...props}
      closeModal={closeModal}
      modalVisible={modalVisible}
    />: null}
  </React.Fragment>);
};

UTMBuilder.propTypes = {
  children: PropTypes.node,
};

export default UTMBuilder;
