import { createSelector } from 'reselect';
import { get } from 'lodash';
import { permissionsSelector, Permission } from '@sixsense/rbac';
import {
  orgFlagsSelector,
  isExternalSixsenseUserSelector,
} from 'modules/user/selectors';

import { GOOGLE_INTEGRATION_DISABLED } from '../Integration/routes/Manage/constants';

// eslint-disable-next-line max-len
import { LINKEDIN_SEGMENT_SYNC_INTEGRATION_ROUTE } from '../Integration/routes/SocialAdsIntegration/routes/LinkedinAdsIntegration/constants';
// eslint-disable-next-line max-len
import { FACEBOOK_ADS_INTEGRATION_ROUTE } from '../Integration/routes/SocialAdsIntegration/routes/FacebookAdsIntegration/constants';
// eslint-disable-next-line max-len
import { GOOGLE_ADS_INTEGRATION_ROUTE } from '../Integration/routes/SocialAdsIntegration/routes/GoogleAdsIntegration/constants';
import { LINKEDIN_ADS_INTEGRATION_SETUP_ROUTE } from 'utils/constants';
import { FEATURE_FLAGS } from 'constants/featureFlags';
import {
  useSISettingsViewEditPermissions,
} from 'routes/Settings/routes/SalesConfig/SISettingsPermissions';
import { CONSENT_MANAGEMENT_TITLE } from './constants';

const hasPermissions = (availablePermissions, permissionsArray) =>
  permissionsArray.some((_permission) => {
    const hasPermission = availablePermissions.has(_permission);
    return hasPermission;
  });

export const advertisingSubOptionSelector = createSelector(
  permissionsSelector,
  orgFlagsSelector,
  isExternalSixsenseUserSelector,
  (permissions, flags, isExternalSixsenseUser) => {
    const hasGoogleAds = get(flags, FEATURE_FLAGS.hasGoogleAds, false); //

    return [
      {
        title: '6sense Segments for LinkedIn',
        id: '6sense Segments for LinkedIn',
        displaySequence: 1,
        visibilityCheck: () =>
          !(isExternalSixsenseUser
            ? false
            : !hasPermissions(permissions, [Permission.SEGMENT_EDIT])),
        location: () =>
          `/settings/integration/${LINKEDIN_SEGMENT_SYNC_INTEGRATION_ROUTE}/`,
        description: 'Integrate your LinkedIn Ad Accounts',
      },
      {
        title: 'LinkedIn Advertising',
        id: 'LinkedIn Advertising',
        visibilityCheck: () =>
          !(isExternalSixsenseUser
            ? false
            : !hasPermissions(permissions, [Permission.CAMPAIGN_EDIT])),
        location: () => `/${LINKEDIN_ADS_INTEGRATION_SETUP_ROUTE}`,
        description: 'Integrate your sponsored Content Poster access',
      },
      {
        title: '6sense Segments for Meta',
        id: '6sense Segments for Facebook',
        visibilityCheck: () =>
          !(isExternalSixsenseUser
            ? false
            : !hasPermissions(permissions, [Permission.CAMPAIGN_EDIT])),
        location: () =>
          `/settings/integration/${FACEBOOK_ADS_INTEGRATION_ROUTE}/`,
        description: 'Integrate your Meta Business Accounts',
      },
      {
        title: '6sense Segments for Google Ads',
        id: '6sense Segments for Google Ads',
        visibilityCheck: () =>
          !(isExternalSixsenseUser
            ? false
            : !hasPermissions(permissions, [Permission.SEGMENT_EDIT])),
        location: () =>
          `/settings/integration/${GOOGLE_ADS_INTEGRATION_ROUTE}/`,
        description: `Use 6sense account segments and first party contacts to create customer match
        segments directly in your Google Ads account`,
        disabled: !hasGoogleAds,
        disabledMessage: GOOGLE_INTEGRATION_DISABLED,
      },
      {
        title: 'Exclusions',
        id: 'Ad Exclusions',
        description: 'Use exclusions to focus analytics and campaigns on relevant data',
        location: () => '/settings/exclusions?listType=inventory',
        requiredPermissions: [
          Permission.SETTINGS_EXCLUSIONS_INVENTORY_VIEW,
          Permission.SETTINGS_EXCLUSIONS_WEBSITE_VIEW,
          Permission.SETTINGS_EXCLUSIONS_COMPANY_VIEW,
          Permission.SETTINGS_EXCLUSIONS_INVENTORY_EDIT,
          Permission.SETTINGS_EXCLUSIONS_WEBSITE_EDIT,
          Permission.SETTINGS_EXCLUSIONS_COMPANY_EDIT,
        ],
        usePermissions: useSISettingsViewEditPermissions,
      },
      {
        title: CONSENT_MANAGEMENT_TITLE,
        id: 'Consent Management',
        description: `Define user consent for 1st party contacts used to create 
          custom audiences on external channels along with
          managing contacts exclusions.`,
        location: () => '/settings/firstpartyUserConsent',
        visibilityCheck: () =>
          get(flags, FEATURE_FLAGS.hasGoogleConsentForEu, false),
      },
    ];
  }
);
