import React, { useState } from 'react';
import { css } from 'styles/emotion';
import { Flex } from '@sixsense/core/components';
import { Button, Modal, Text, Loading, Icon, Checkbox2 } from 'v2-components';
import { useOrg } from 'hooks';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { actions as globalActions } from 'modules/global';
import { useRequest, useSharedValue, useSharedState } from '@sixsense/core';
import { maybePlural } from 'utils/utils';
import { Keyword } from '../../types';
import { genProductDetailText, getConfigState, getUpdatedConfig, resetConfig } from '../../utils';
import { orgProductState, activePageState } from '../../state';
import { KW_EXP, OTHER } from '../../constants';


const styles = {
  icon: css({
    color: '#EBA300',
  }),
  message: css({
    marginBottom: '20px',
  }),
};

type Props = {
  onCancel: () => void;
  keywords: Keyword[];
};

const RemoveFromProductModal = ({ onCancel, keywords }: Props) => {
  const MAIN = 'MAIN';
  const WARNING = 'WARNING';
  const [loading, setLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [contentState, setContentState] = useState(MAIN);
  const org = useOrg();
  const products = useSharedValue(orgProductState);
  const keywordIds = keywords.map((k) => k?.id);
  const activePage = useSharedValue(activePageState);
  const state = getConfigState(activePage, false);
  const [config, setConfig] = useSharedState(state);

  const request = useRequest();
  const dispatch = useDispatch();

  const closeModal = () => {
    setSelectedProducts([]);
    setContentState(MAIN);
    onCancel();
  };

  const onCheckboxClick = (kId) => {
    if (selectedProducts.includes(kId)) {
      setSelectedProducts(selectedProducts.filter((i) => i !== kId));
    } else {
      setSelectedProducts([...selectedProducts, kId]);
    }
  };

  const selectedProductText = selectedProducts.map((i) => {
    const productName = products.find((p) => p.id === i).displayName;
    return productName === KW_EXP ? `"${OTHER}"` : `"${productName}"`;
  }).join(',');

  const usedProductsSet = keywords.reduce((cur, kw) => cur.concat(kw.keyword_products), []).reduce(
    (cur, entry) => cur.add(entry.product), new Set());
  const usedProducts = [...usedProductsSet].map((i) => products.find((p) => p.id === i));

  const removeProductDetailText = genProductDetailText(products, selectedProducts, keywords);

  const removeFromProduct = async () => {
    const body = {
      action: 'remove_from_product',
      keyword_ids: keywordIds,
      config: {
        product_ids: selectedProducts,
      },
    };
    setLoading(true);
    try {
      // Product_id is 0 since it is unused in the bulk_update calls
      await request(`organization/${org.id}/product_keyword/${0}/keyword/`, {
        method: 'PATCH',
        body: JSON.stringify(body),
      });
      dispatch(globalActions.showNotification(
        'success',
        selectedProducts.length === usedProducts.length ?
        `Successfully deactivated ${maybePlural(keywords.length, 'keyword')}` :
        `Successfully removed ${maybePlural(keywords.length, 'keyword')} from product`));
      closeModal();
      const updatedConfig = getUpdatedConfig(config);
      resetConfig(updatedConfig, setConfig);
    } catch (e) {
      const errorMessage = `There was an issue removing ${maybePlural(keywords.length, 'keyword')}
                            from the selected ${maybePlural(selectedProducts.length, 'product')}.
                            Please try again later.`;
      dispatch(globalActions.showNotification('error', errorMessage));
    }
    setLoading(false);
  };

  const footer = (
    <Flex justifyContent="flex-end" style={{ padding: '10px 0' }}>
      <Button
        pointer
        onClick={() => {
          closeModal();
        }}
      >
        Cancel
      </Button>
      <Button
        pointer
        className="aam-l--10"
        type={'primary'}
        onClick={() => {
          if (contentState === MAIN &&
            (keywords.length > 1 || selectedProducts.length === usedProducts.length)) {
            setContentState(WARNING);
          } else {
            removeFromProduct();
          }
        }}
        disabled={loading || !selectedProducts.length}
      >
        Remove
      </Button>
      {loading && (
        <Loading
          size="small"
          spinnerWidth={15}
          spinnerHeight={15}
          pageLevel={false}
          className="aam-l--10"
        />
      )}
    </Flex>
  );

  const mainContent = (
    <React.Fragment>
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9' }}>
        <Text type={Text.TYPE.TITLE} bold>
          Which product category did you want to remove {' '}
          {keywords.length > 1 ? `${keywords.length} keywords` : `"${keywords[0].keyword}"`} from?
        </Text>
      </Flex>
      <Flex style={{ padding: '20px', paddingBottom: '100px' }} direction="column">
        <Text className="aam-b--15" bold>Select product category</Text>
        {usedProducts.map((p) =>
          <Flex className="aam-b--15">
            <Checkbox2
              onClick={() => onCheckboxClick(p.id)}
              checked={selectedProducts.includes(p?.id)}
              size={Checkbox2.SIZE.LARGE}
            />
            <Text>{p?.displayName === KW_EXP ? OTHER : p?.displayName}</Text>
          </Flex>
        )}
      </Flex>
    </React.Fragment>
  );

  const warningContent = keywords.length > 1 ? (
    <React.Fragment>
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9' }}>
        <Text type={Text.TYPE.TITLE} bold>
          Remove {keywords.length} keywords from {selectedProductText} product{' '}
          {maybePlural(selectedProducts.length, 'category', 'categories')}?
        </Text>
      </Flex>
      <Flex style={{ padding: '20px', paddingBottom: '100px' }}>
        <Text>
          {removeProductDetailText}
        </Text>
      </Flex>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9' }}>
        <Icon type="report_problem" size={Icon.SIZE.XLARGE} className={styles.icon} />
        <Text className="aam-l--10" type={Text.TYPE.TITLE} bold>
          Warning
        </Text>
      </Flex>
      <Flex style={{ padding: '20px' }}>
        <Text className={styles.message}>
          Removing a keyword from all product categories will automatically deactivate
          the keyword. Do you wish to continue?
        </Text>
      </Flex>
    </React.Fragment>
  );

  return (
    <Modal
      visible
      bodyStyle={{ padding: 0 }}
      footer={footer}
      maskClosable={false}
      closable={false}
      width={500}
    >
      {contentState === MAIN ? mainContent : warningContent}
    </Modal>
  );
};

export default RemoveFromProductModal;
