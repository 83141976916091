import CreateAdPlacementRoute from './routes/CreateAdPlacement';
import EditAdPlacementRoute from './routes/EditAdPlacement';
import { getAsyncInjectors } from 'store/utils';
import { AD_BASE_ROUTE } from './constants';
import { ADVERTISING_PATHS } from 'routes/Advertising/constants';

export default function adPlacementRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: AD_BASE_ROUTE,
    name: 'ad',
    indexRoute: {
      onEnter: (nextState, replace) =>
        replace(`/${ADVERTISING_PATHS.adCreate}`),
    },

    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('adPlacement', reducer.default);
        injectSagas('adPlacement', sagas.default);
        cb(null, containers.AdPlacement);
      }, 'ad_placement');
    },
    childRoutes: [
      CreateAdPlacementRoute(store),
      EditAdPlacementRoute(store),
      {
        path: 'prerequisite',
        name: 'prerequisiteHtml',
        getComponent(nextState, cb) {
          require.ensure([], (require) => {
            const { PrerequisiteHtml } = require('./components/StaticPages/PrerequisiteHtml');
            cb(null, PrerequisiteHtml);
          }, 'Prerequisite_Html');
        },
      },
      {
        path: 'dynamic',
        name: 'prerequisiteDynamicHtml',
        getComponent(nextState, cb) {
          require.ensure([], (require) => {
            const { PrerequisiteDynamicHtml } =
            require('./components/StaticPages/PrerequisiteDynamicHtml');
            cb(null, PrerequisiteDynamicHtml);
          }, 'Prerequisite_Dynamic_Html');
        },
      },
      {
        path: 'prerequisiteAE',
        name: 'prerequisiteAE',
        getComponent(nextState, cb) {
          require.ensure([], (require) => {
            const { PrerequisiteAE } = require('./components/StaticPages/PrerequisiteAE');
            cb(null, PrerequisiteAE);
          }, 'PrerequisiteAE');
        },
      },
      {
        path: 'prerequisiteGWD',
        name: 'prerequisiteGWD',
        getComponent(nextState, cb) {
          require.ensure([], (require) => {
            const { PrerequisiteGWD } = require('./components/StaticPages/PrerequisiteGWD');
            cb(null, PrerequisiteGWD);
          }, 'PrerequisiteGWD');
        },
      },
      {
        path: 'iab-HTML5-clickTag-Standard',
        name: 'prerequisiteHTMLClickTagStandard',
        getComponent(nextState, cb) {
          require.ensure([], (require) => {
            const {
              PrerequisiteHTMLClickTagStandard,
            } = require('./components/StaticPages/PrerequisiteHTMLClickTagStandard');
            cb(null, PrerequisiteHTMLClickTagStandard);
          }, 'prerequisiteHTMLClickTagStandard');
        },
      },
      {
        path: 'library-with-adobe-edge',
        name: 'libraryWithAdobeEdge',
        getComponent(nextState, cb) {
          require.ensure([], (require) => {
            const {
              PrerequisiteLibraryWithAdobeEdge,
            } = require('./components/StaticPages/PrerequisiteLibraryWithAdobeEdge');
            cb(null, PrerequisiteLibraryWithAdobeEdge);
          }, 'libraryWithAdobeEdge');
        },
      },
      {
        path: 'library-with-google-web-designer',
        name: 'libraryWithGoogleWebDesigner',
        getComponent(nextState, cb) {
          require.ensure([], (require) => {
            const {
              PrerequisiteLibraryWithGWD,
            } = require('./components/StaticPages/PrerequisiteLibraryWithGWD');
            cb(null, PrerequisiteLibraryWithGWD);
          }, 'libraryWithGoogleWebDesigner');
        },
      },
    ],
  };
}
