import React from 'react';
import useAssignableRoleOptions from '../../../hooks/useAssignableRoleOptions';
import { SelectGroup, Select } from 'v2-components';
import PropTypes from 'prop-types';
import { DUMMY_SELECT_VALUE } from '../../../constants';
import styles from './MultiRoleDropdown.module.scss';
import { refiningRoleOptions } from '../../../utils';

const getRolesToOptions = (roles, tag = '') => {
  if (roles === undefined || roles === null) return [];
  return [
    ...roles.map((role) => ({
      ...role,
      tagLabel: tag ? `${tag} ${role.label}` : '',
    })),
  ];
};

export const MultiRoleDropdownComponent = ({
  moreOptions = [],
  isSelectedUserPrimaryAdmin,
  disabled,
  value,
  onChange,
  appId,
  wrapperRef,
  isNoRoleAssign,
  isSelectRole,
  noRoleAssignValue = -1,
  selectRoleValue = DUMMY_SELECT_VALUE,
}) => {
  if (isSelectedUserPrimaryAdmin) {
    return (
      <Select
        width="100%"
        disabled
        options={[]}
        value={'Primary Administrator'}
        placeholder="Select a role"
      />
    );
  }
  const roleAppOptionsFromHookMap =
    useAssignableRoleOptions.useAssignableRoleAppMapOptions({ enableAdmin: true });
  const getRoleByAppOptions = () => {
    const roleAppOptions = [];
    if (isSelectRole) {
      roleAppOptions.push({
        label: 'Select Role',
        value: selectRoleValue,
      });
    }
    if (isNoRoleAssign) {
      roleAppOptions.push({
        label: 'No Role Assigned',
        value: noRoleAssignValue,
      });
    }
    const roleAppOptionsFromHook = refiningRoleOptions(
      roleAppOptionsFromHookMap[appId] || [], value
    );
    return [
      ...roleAppOptions,
      ...moreOptions,
      ...(roleAppOptionsFromHook),
    ];
  };
  return (
    <SelectGroup
      disabled={disabled}
      options={
        getRolesToOptions(getRoleByAppOptions(appId), '')
      }
      onChange={(val) => onChange(val, appId)}
      value={value || []}
      width={'100%'}
      dropdownMatchSelectWidth={false}
      getPopupContainer={
        !!wrapperRef && (() => wrapperRef && wrapperRef.current)
      }
      mode="multiple"
      placeholder={'Select Roles'}
      useCustomOptions
      dropdownClassName={styles.dropdown}
    />
  );
};

MultiRoleDropdownComponent.propTypes = {
  moreOptions: PropTypes.any,
  isSelectedUserPrimaryAdmin: PropTypes.bool,
  disabled: PropTypes.bool,
  appId: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  wrapperRef: PropTypes.any,
  isNoRoleAssign: PropTypes.bool,
  isSelectRole: PropTypes.bool,
  noRoleAssignValue: PropTypes.any,
  selectRoleValue: PropTypes.any,
};
