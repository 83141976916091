import React from 'react';
import { TablePreviewLayout } from 'aa-components';
import { Permission, usePermissionCheck } from '@sixsense/rbac';
import { Keyword, KeywordGroup } from '../types';
import { KEYWORDS, GROUPS, DEACTIVATED, GROUP_KW_LIST } from '../constants';
import { useSharedState, useSharedValue } from '@sixsense/core';
import KeywordTableRow from './Keywords/KeywordTableRow';
import GroupTableRow from './Groups/GroupTableRow';
import DeactivatedTableRow from './Deactivated/DeactivatedTableRow';
import { getConfigState } from '../utils';
import { orgProductState } from '../state';
import { PaginateCard2 } from 'HOCS';
import { compose } from 'redux';
import { Row, Checkbox2, Icon } from 'v2-components';
import { css } from 'styles/emotion';

const { Table } = TablePreviewLayout;

type Props = {
  dataset: Keyword[] | KeywordGroup[];
  footer: any;
  pageKey: string;
  headers: any;
  keywordAccounts: any;
  hideActions?: boolean;
  fromAddToGroup: boolean;
};

const KeywordTableComponent = ({
  dataset,
  footer,
  pageKey,
  headers,
  keywordAccounts=[],
  hideActions,
  fromAddToGroup,
}: Props) => {

  const state = getConfigState(pageKey, fromAddToGroup);
  const [config, setConfig] = useSharedState(state);
  const sortState = config.filters.sortBy;
  const canEdit = usePermissionCheck([Permission.SETTINGS_KEYWORDS_EDIT]);

  const reverseOrder = {
    asc: 'desc',
    desc: 'asc',
  };

  const handleSortClick = (column: string) => {
    if (sortState?.column === column) {
      setConfig({
        ...config,
        filters: { ...config.filters, sortBy: { column, order: reverseOrder[sortState.order] } },
      });
    } else {
      setConfig({ ...config, filters: { ...config.filters, sortBy: { column, order: 'asc' } } });
    }
  };

  const keywordData = dataset.map((v) => {
    const match = pageKey === GROUPS ?
      keywordAccounts.find((k) => v.name.toString() === k.name.toString())
      : keywordAccounts.find((k) => v.id.toString() === k.id.toString());
    return { ...v, keyword_accounts: match ? match.account_count : 0 };
  });

  const setAllSelected = (checked) => {
    /*
      For adding keywords to groups, there's no limit on how many you can add
      (ie. you can add keywords across all pages.) So the behaviour is different here
      if that is the case.
    */
    setConfig({ ...config });
    const allKeywords = dataset.map((v) => v.id);
    if (checked) {
      if (fromAddToGroup) {
        let newKeywords = [...config.selectedValues];
        for (const keyword of dataset) {
          // If the keyword already exists, don't add it. (ie. they selected single checkbox
          // then clicked select all)
          if (!newKeywords.find((v) => v.id === keyword.id)) {
            newKeywords = newKeywords.concat(keyword);
          }
        }
        setConfig({ ...config, selectedValues: newKeywords });
      } else {
        setConfig({ ...config, selectedValues: allKeywords });
      }
    // Unchecked
    } else if (fromAddToGroup) {
      // Only remove selected values from current page, not all pages.
      setConfig({
        ...config,
        selectedValues: [...config.selectedValues].filter((v) => !allKeywords.includes(v.id)),
      });
    } else {
      setConfig({ ...config, selectedValues: [] });
    }
  };

  const determineAllSelected = () => {
    const keywordsOnPage = dataset.map((v) => v.id);
    if (fromAddToGroup) {
      // All the keywords on the current page need to be included in the selected values.
      const allSelectedIds = config.selectedValues.map((v) => v.id);
      return keywordsOnPage.every((v) => allSelectedIds.includes(v))
        && config.selectedValues.length > 0;
    }
    return dataset.length && (config.selectedValues.length === dataset.length);
  };

  // Hack for select all column in the header.
  const tableHeaders = canEdit ? [
    {
      columnName: <Checkbox2
        checked={determineAllSelected()}
        size={Icon.SIZE.LARGE}
        onChange={(e) => setAllSelected(e.target.checked)}
      />,
      className: css({ width: '3%' }),
    },
    ...headers,
  ] : headers;

  const products = useSharedValue(orgProductState);
  const TableBody = () => {
    switch (pageKey) {
      case KEYWORDS: case GROUP_KW_LIST:
        return keywordData.map((keyword) =>
          <KeywordTableRow
            keywordData={keyword}
            products={products}
            config={config}
            setConfig={setConfig}
            hideActions={hideActions}
            fromAddToGroup={fromAddToGroup}
          />);
      case GROUPS:
        return keywordData.map((group) =>
          <GroupTableRow
            groupData={group}
          />);
      case DEACTIVATED:
        return keywordData.map((keyword) =>
          <DeactivatedTableRow
            keywordData={keyword}
            products={products}
          />);
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Table
        tableHeader={tableHeaders}
        tableBody={<TableBody />}
        orderBy={sortState.column}
        ordering={sortState.order}
        orderList={handleSortClick}
        sortedColumnAlignment={Row.JUSTIFY_CONTENT.FLEX_START}
      />
      {footer}
    </React.Fragment>
  );
};

export const KeywordTable = compose(
  PaginateCard2(),
)(KeywordTableComponent);
