import { fetchRequest } from 'utils/request';

const matchingCampaignsEndpoint = ({
  orgId,
  name,
 }) =>
 `org/${orgId}/campaign_list/?campaign_name=${name}`;

const getMatchingCampaigns = (params) =>
    fetchRequest(matchingCampaignsEndpoint(params), { method: 'GET' });

export const apiRequests = {
  getMatchingCampaigns,
};
