import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'v2-components';
import { debounce } from 'lodash';
import styles from './SearchBar.module.scss';

/*
  Two requirements here:
  1. Keep local state of input starting with initial value
  2. Debounce onChange
  @param {bool} allowClear - whether or not this search bar can be cleared
  @param {func} clear - func to call when clearing, otherwise default
    handle change will be called with an empty string
*/
class SearchBar extends Component {
  static defaultProps = {
    wait: 200,
  }
  static propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    BeforeIcon: PropTypes.element,
    onClickInput: PropTypes.func,
    disabled: PropTypes.bool,
    wait: PropTypes.number,
    allowClear: PropTypes.bool,
    clear: PropTypes.func,
    autoFocus: PropTypes.bool,
    onBlur: PropTypes.func,
    noIcon: PropTypes.bool,
    iconClassName: PropTypes.string,
  }
  static defaultProps = {
    wait: 200,
    onChange: () => { },
    onSubmit: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      text: props.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { value: newValue } = nextProps;
    if (newValue !== this.props.value) {
      this.setState({ text: newValue });
      this._debouncedOnChange(newValue);
    }
  }

  _debouncedOnChange = debounce((text) => this.props.onChange(text), this.props.wait);

  defaultHandleClear = () => {
    this.setState({ text: '' });
  }

  handleOnChange = (e) => {
    const newText = e.target.value;
    this.setState({ text: newText });
    if (this.props.onChange) {
      this._debouncedOnChange(newText);
    }
  };

  handleOnSubmit = (e) => {
    if (e.key === 'Enter') {
      this.props.onSubmit(e.target.value);
    }
  }

  render() {
    const {
      placeholder,
      className,
      containerClassName,
      BeforeIcon,
      onClickInput,
      disabled,
      allowClear,
      clear,
      onSubmit,
      autoFocus,
      onBlur,
      noIcon,
      iconClassName,
    } = this.props;

    let classText = `${styles.container} ${containerClassName}`;
    if (disabled) {
      classText += ` ${styles.disabledStyle}`;
    }

    const { text } = this.state;
    const canClear = allowClear && text && text.length;

    return (
      <div className={classText}>
        <input
          type="text"
          placeholder={placeholder}
          value={text}
          onChange={this.handleOnChange}
          onKeyDown={this.handleOnSubmit}
          className={`${styles.inputBox} ${className}`}
          onClick={onClickInput}
          disabled={disabled}
          autoFocus={autoFocus}
          onBlur={onBlur}
        />
        {BeforeIcon && BeforeIcon}
        {!noIcon &&
        <Icon
          className={`
            ${styles.icon}
            ${iconClassName}
            ${canClear ? styles.cursor : undefined}
          `}
          type={canClear ? 'clear' : 'search'}
          onClick={() => {
            if (canClear || onSubmit) {
              if (onSubmit) {
                this.defaultHandleClear();
              }
              if (clear) {
                clear();
              } else {
                this.handleOnChange('');
              }
            }
          }}
        />}
      </div>
    );
  }
}

export default SearchBar;
