import React from 'react';
import { NA } from './common/NA';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';
import { Tooltip2 as Tooltip, Row, Icon, Text, Link } from 'v2-components';
import { css } from 'styles/emotion';
import { pxToRem } from '@sixsense/core/style';
import { classNames } from 'utils/utils';
import {
  VIEWABILITY as viewabilityFlagIdentifier,
} from 'routes/Advertising/constants';
import { get } from 'lodash';
import { formatViewabilityPercent, isViewabilityAvailable } from '../../../utils';
import { VIEWABILITY_METRICS_DOJO_LINK } from '../../CampaignConfiguration/constants';

export const VIEWABILITY = {
  sortByKey: 'viewability_percent',
  key: 'viewability',
  csvColumnKey: 'viewability_percent',
  featureFlagKey: viewabilityFlagIdentifier,
  defaultSelected: true,
  title: (<Row alignItems={Row.ALIGN_ITEMS.CENTER}>
    <Label name={CAMPAIGN_LABELS.viewabilityPercent} />
    <Tooltip
      style={{ height: '100%' }}
      overlayStyle={{ maxWidth: pxToRem(250) }}
      overlay={<Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
        <Row alignItems={Row.ALIGN_ITEMS.CENTER} className="aam-b--5">
          <Text
            color={Text.COLOR.WHITE}
            bold
            className={classNames(css`font-size:${pxToRem(12)}, aam-t--5`)}
          >
            Viewability
          </Text>
        </Row>
        <Text color={Text.COLOR.WHITE} className={css`font-size:${pxToRem(12)}`}>
          The viewability rate is the percentage of ad impressions that
          were measured as viewable by a user. This metric is reportable for
          6sense advertising campaign and ad performance data as of January 1, 2023.
          &nbsp;<Link link={VIEWABILITY_METRICS_DOJO_LINK} newWindow>Learn more</Link>
        </Text>
      </Row>}
      placement={'top'}
    >
      <Icon
        type={'info_outlined'}
        color={Icon.COLORS.BLUE}
        size={Icon.SIZE.SMALL}
        className={'aam-l--5 aam-t--5'}
      />
    </Tooltip>
  </Row>),
  dataIndex: 'campaign_data.viewability_percent',
  width: 110,
  render: (viewabilityPercent = 0, campaign) => {
    const { campaign_source } = campaign;
    const showViewability = isViewabilityAvailable(
      campaign_source, get(campaign, 'end_date'), get(campaign, 'campaign_status')
    );
    if (showViewability) {
      return (<Text type={Text.TYPE.SUBBODY}>
        {formatViewabilityPercent(viewabilityPercent)}%
        </Text>);
    }
    return (<NA />);
  },
};
