import React from 'react';
import Text from 'v2-components/Text';
import { localeString } from 'utils/utils';
import { NA } from './common/NA';
import { is6SenseCampaign, isDraftOrScheduledCampaign } from '../../../utils';
import { ROW_DATA_TYPES } from 'aa-components/CampaignCSVExportModal/constants';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';

export const VIEWS = {
  sortByKey: 'users_view_through',
  key: 'views',
  csvColumnKey: 'user_view_through',
  title: <Label name={CAMPAIGN_LABELS.userViewThrough} />,
  dataIndex: 'campaign_data.users_view_through',
  width: 140,
  render: (userViewThrough, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (is6SenseCampaign(campaign) === false ||
      isDraftOrScheduledCampaign(campaignStatus)) {
      return (<NA />);
    }

    return (<Text
      type={Text.TYPE.SUBBODY}
    >
      {localeString(userViewThrough)}
    </Text>);
  },
  csvOverrides: {
    dataIndex: 'download_stats.users_view_through',
    render: (userViewThrough, campaign, dataType) => {
      const campaignStatus = campaign.campaign_status;
      const campaignHasStats = !isDraftOrScheduledCampaign(campaignStatus);
      if ((is6SenseCampaign(campaign) && campaignHasStats) ||
        dataType === ROW_DATA_TYPES.aggregated) {
        return `"${localeString(parseInt(userViewThrough) || 0)}"`;
      }

      return '-';
    },
  },
};
