import React from 'react';
import PropTypes from 'prop-types';
import { map, snakeCase } from 'lodash';
import { Link } from 'v2-components';
import styles from './Drawer.module.scss';
import { classNames } from 'utils/utils';
import {
  ChevronRightDouble,
  ChevronDown,
  ChevronUp,
} from '@sixsense/core/icons';

export const Item = ({
    title,
    moreStyles,
    parentId,
    subId,
    isIframe,
    iFrameURL,
    onActiveItemClick,
    activeSubItem,
    titleConversion,
}) => (
  <div
    onClick={() => onActiveItemClick(parentId, subId, isIframe, iFrameURL)}
    className={classNames(
      styles.itemTitle,
      activeSubItem === subId ? styles.activeItem : null,
      ...moreStyles,
    )
  }
    data-6si-id={snakeCase(subId)}
    data-6si-name={'item'}
  >
    {titleConversion(title)}
  </div>
);
Item.propTypes = {
  title: PropTypes.any,
  moreStyles: PropTypes.array,
  parentId: PropTypes.any,
  subId: PropTypes.any,
  isIframe: PropTypes.bool,
  iFrameURL: PropTypes.string,
  onActiveItemClick: PropTypes.func,
  activeSubItem: PropTypes.any,
  titleConversion: PropTypes.any,
};

export const SubItem = ({
    subItemTitle,
    moreStyles,
    parentId,
    subId,
    onActiveSubItemClick,
    activeSubItem,
    titleConversion,
}) => (
  <div
    onClick={() =>
      onActiveSubItemClick(parentId, subId)
    }
    className={classNames(
      styles.itemTitle,
      activeSubItem === subId ? styles.activeItem : null,
      ...moreStyles,
    )}
    data-6si-id={snakeCase(subId)}
    data-6si-name={'subItem--with--options'}
  >
    {titleConversion(subItemTitle)}
  </div>
);
SubItem.propTypes = {
  subItemTitle: PropTypes.any,
  moreStyles: PropTypes.array,
  parentId: PropTypes.any,
  subId: PropTypes.any,
  onActiveSubItemClick: PropTypes.any,
  activeSubItem: PropTypes.any,
  titleConversion: PropTypes.any,
};


export const ItemContainer = ({
    title,
    children,
    id,
    activeItem,
    onExpandedItemClick,
    titleConversion,
    expandedItem,
}) => (
  <div className={styles.itemContainer}>
    <span
      className={classNames(
        styles.itemTitle,
        activeItem === id ? styles.activeItem : null
      )}
      onClick={() => onExpandedItemClick(id)}
      data-6si-id={snakeCase(id)}
      data-6si-name={'ItemContainer'}
    >
      {titleConversion(title)}
    </span>
    <span onClick={() => onExpandedItemClick(id)}>
      {expandedItem === id ? (
        <ChevronUp
          color={activeItem === id ? '#0D96D5' : '#9DA4AE'}
          className={classNames(styles.itemIcon)}
          data-6si-id={`${snakeCase(id)}_chevronUp`}
          data-6si-name={'ChevronUp'}
        />
      ) : (
        <ChevronDown
          color={activeItem === id ? '#0D96D5' : '#9DA4AE'}
          className={classNames(styles.itemIcon)}
          data-6si-id={`${snakeCase(id)}_chevronDown`}
          data-6si-name={'ChevronDown'}
        />
      )}
    </span>
    {expandedItem === id ? children : null}
  </div>
);
ItemContainer.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
  id: PropTypes.any,
  activeItem: PropTypes.any,
  onExpandedItemClick: PropTypes.any,
  titleConversion: PropTypes.any,
  expandedItem: PropTypes.any,
};

export const CollapseBody = ({ isExpandedDrawer }) => (
  <div
    className={classNames(
      styles.collapsedBodyContainer,
      isExpandedDrawer ? styles.dnone : styles.display
    )}
  >
    <ChevronRightDouble className={classNames(styles.collapsedIcon)} />
    <span className={classNames(styles.titleText)}>Settings</span>
  </div>
);

CollapseBody.propTypes = {
  isExpandedDrawer: PropTypes.any,
};

export const Body = ({
    isExpandedDrawer,
    settingsMapping,
    isVisible,
    onActiveSubItemClick,
    onActiveItemClick,
    activeSubItem,
    titleConversion,
    activeItem,
    onExpandedItemClick,
    expandedItem,
}) => (
  <div
    className={classNames(
      styles.bodyContainer,
      !isExpandedDrawer ? styles.dnone : styles.display
    )}
  >
    {map(settingsMapping, (settings) => {
      const { title, children, id: parentId } = settings;
      if (isVisible(settings)) {
        const moreStyles = [];
        let Container = React.Fragment;
        if (title) {
          moreStyles.push(styles.subMenuItem);
          Container = ItemContainer;
        }
        return (
          <Container
            title={title}
            key={parentId}
            id={parentId}
            activeItem={activeItem}
            onExpandedItemClick={onExpandedItemClick}
            titleConversion={titleConversion}
            expandedItem={expandedItem}
          >
            {map(children, (opts) => {
              const ChildContainer = Link;
              if (isVisible(opts)) {
                if (opts.subOption) {
                  return (
                    <ChildContainer
                      link={opts.location(opts.usePermissions)}
                      isInternal={!opts.isExternal}
                      key={opts.id}
                    >
                      <SubItem
                        subItemTitle={opts.title}
                        moreStyles={moreStyles}
                        parentId={parentId}
                        subId={opts.id}
                        onActiveSubItemClick={onActiveSubItemClick}
                        activeSubItem={activeSubItem}
                        titleConversion={titleConversion}

                      />
                    </ChildContainer>
                  );
                }
                return (
                  <ChildContainer
                    link={opts.location(opts.usePermissions)}
                    isInternal={!opts.isExternal}
                    key={opts.id}
                  >
                    <Item
                      title={opts.title}
                      description={opts.description}
                      className={styles.navcard}
                      parentTitle={title}
                      moreStyles={moreStyles}
                      subId={opts.id}
                      parentId={parentId}
                      isIframe={opts.isIframe}
                      iFrameURL={opts.getIFrameURL ? opts.getIFrameURL() : null}
                      onActiveItemClick={onActiveItemClick}
                      activeSubItem={activeSubItem}
                      titleConversion={titleConversion}

                    />
                  </ChildContainer>
                );
              }
              return null;
            }).filter((v) => v)}
          </Container>
        );
      }
      return null;
    })}
  </div>
);

Body.propTypes = {
  isExpandedDrawer: PropTypes.any,
  settingsMapping: PropTypes.any,
  isVisible: PropTypes.any,
  onActiveSubItemClick: PropTypes.any,
  onActiveItemClick: PropTypes.any,
  activeSubItem: PropTypes.any,
  titleConversion: PropTypes.any,
  activeItem: PropTypes.any,
  onExpandedItemClick: PropTypes.func,
  expandedItem: PropTypes.any,
};
