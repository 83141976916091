// TODO(DOGA): should probably remove this from here.
// This is more for each use of crossfilter-redux library
// SO each project should have this in the top level contants file
export const FILTER_TYPES = Object.freeze({
  EXACT: 'EXACT',
  RANGE: 'RANGE',
  HIERARCHICAL: 'HIERARCHICAL',
  GREATER_THAN: 'GREATER_THAN',
  LESS_THAN: 'LESS_THAN',
});
