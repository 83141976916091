import { Flex } from '@sixsense/core/components';
import React, { useState, useMemo } from 'react';
import { Button, Modal, Text, Input, Loading, Icon } from 'v2-components';
import { GroupSelect } from '../';
import { css } from 'styles/emotion';
import { useSharedState, useRequest, useSetSharedValue, useSharedValue } from '@sixsense/core';
import {
  addKeywordsConfigState,
  keywordsConfigState,
  DEFAULT_ADD_KEYWORDS_CONFIG_STATE,
  keywordGroupSelectQueryState,
  siKeywordsValidationState,
} from '../../state';
import ConfigSentence from '../ConfigSentence';
import {
  validateAddKeywordsConfig,
  parseKeywords,
  normalizeKeyword,
  resetConfig,
  getUpdatedConfig,
} from '../../utils';
import { actions as globalActions } from 'modules/global';
import { useOrg } from 'hooks';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import moment from 'moment';
import { USER_ADDED_ORIGIN_VALUE, REC_ORIGIN_VALUE, NEW_GROUP, KEYWORDS } from '../../constants';
import { InvalidKeywordsModal, CreateGroupModal } from '../Modals';
import { maybePlural } from 'utils/utils';
import { AAColor } from '@sixsense/core/style';

type Props = {
  onCancel: () => void;
};

const styles = {
  button: css({
    marginRight: '10px',
    height: '30px',
  }),
  select: css({
    placeContent: 'flex-start !important',
    marginTop: '10px',
    marginBottom: '200px',
  }),
  error: css({
    marginBottom: '20px',
  }),
  loadingIcon: css({
    marginLeft: '10px',
  }),
  label: css({
    lineHeight: 3,
  }),
  dropdownStyle: css`
    width: 40%;
    margin-bottom: 200px;
    margin-top: 10px;
    :global(.ant-select-selection) {
        border:none !important;
        box-shadow: none !important;
        :global(.ant-select-selection__choice){
          background-color: #E5F2FA;
        }
      }
  `,
  option: css({
    whiteSpace: 'normal !important',
    borderBottom: `1px solid ${AAColor.GREY4}`,
  }),
  newGroupLabel: css({
    marginLeft: '5px',
  }),
  backIcon: css({
    marginRight: '10px',
  }),
  input: css({
    width: '30em',
    minHeight: '8em',
    resize: 'vertical',
    margin: '10px 0 10px 0',
  }),
};

const AddKeywordsModal = ({ onCancel }: Props) => {
  const [config, setAddKwsConfig] = useSharedState(addKeywordsConfigState);
  const [kwConfig, setKwConfig] = useSharedState(keywordsConfigState);
  const setGroupSelectQuery = useSetSharedValue(keywordGroupSelectQueryState);
  const [duplicateKeywords, setDuplicateKeywords] = useState([]);
  const [invalidModalVisible, setinvalidModalVisible] = useState(false);
  const [createGroupModalVisible, setCreateGroupModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const validation = useMemo(() => validateAddKeywordsConfig(config), [config]);
  const siValidations = useSharedValue(siKeywordsValidationState);

  // Make sure to reset modal values on close.
  const onClose = (isBackButton) => {
    onCancel();
    setGroupSelectQuery(undefined);
    setAddKwsConfig({ ...DEFAULT_ADD_KEYWORDS_CONFIG_STATE, recKeywordsVisible: isBackButton });
    setDirty(false);
  };

  // Load Groups for Add Keywords Modal
  const org = useOrg();
  const request = useRequest();
  const dispatch = useDispatch();
  const saveKeywords = async () => {
    let filteredKws = [...config.keywords];
    // Need to check whether form is dirty, ie. did the user close the invalid keywords
    // modal and try to save again or did they save from the invalid modal.
    if (duplicateKeywords.length > 0 && dirty) {
      const invalidKws = duplicateKeywords.map((v) => normalizeKeyword(v.keyword));
      filteredKws = config.keywords.filter((v) => !invalidKws.includes(normalizeKeyword(v)));
    }
    setDuplicateKeywords([]);

    // Need to make sure that after filtering out invalid keywords (if the validation failed)
    // there is still keywords to save
    if (filteredKws.length > 0) {
      const keywordsBody = filteredKws.map((keyword) => ({
        keyword: keyword.trim(),
        normalized_keyword: normalizeKeyword(keyword.trim()),
        category: config.category,
        is_active: true,
        is_deleted: false,
        isNew: true,
        client_added: moment.utc().format(),
        org: org.id,
        origin: config.rec_keywords.includes(keyword) ? REC_ORIGIN_VALUE : USER_ADDED_ORIGIN_VALUE,
      }));
      setLoading(true);
      try {
        const resp =
        await request(`organization/${org.id}/product_keyword/${config.product_id}/keyword/`, {
          method: 'POST',
          body: JSON.stringify(keywordsBody),
        });
        dispatch(globalActions.showNotification('success', `Keywords saved successfully. Please
        note it may take up to 24 hours to see added keywords in analytics.`));
        if (config.keyword_groups.length > 0) {
          // Existing keywords will not be added to groups.
          // (use the new add to groups modal instead)
          addToGroups(resp, filteredKws);
        } else {
          onClose(false);
          // Refresh main keywords page
          const keywordPageConfig = getUpdatedConfig(kwConfig);
          keywordPageConfig.recentlyAddedKws = [...kwConfig.recentlyAddedKws].concat(filteredKws);
          resetConfig(keywordPageConfig, setKwConfig);
        }
      } catch (e) {
        // 400 Request means server validation failed (ie. duplicate keywords.)
        if (e.errorStatus === 400) {
          const { body } = e;
          setDuplicateKeywords(body.keywords);
          setinvalidModalVisible(true);
          setDirty(true);
        }
      }
      setLoading(false);
    }
  };

  // Save New Keywords to group
  // Product_id is 0 since it is unused in the bulk_update calls
  // TODO: duplicated code from ExistingGroupModal, cleanup.
  const addToGroups = async (keywords, filteredKws) => {
    const requestUrl = `organization/${org.id}/product_keyword/${0}/keyword/`;
    const body = {
      action: 'add_to',
      keyword_ids: keywords.map((v) => v.id),
      config: {
        location: 'existing_group',
        group_name: null,
        group_ids: config.keyword_groups.filter((v) => v !== NEW_GROUP),
        target_product_ids: null,
      },
    };

    setLoading(true);
    try {
      await request(requestUrl, {
        method: 'PATCH',
        body: JSON.stringify(body),
      });
      dispatch(globalActions.showNotification('success',
      `Successfully added to ${maybePlural(config.keyword_groups.length, 'group')}`));
      onClose(false);
      // Refresh main keywords page
      const keywordPageConfig = getUpdatedConfig(kwConfig);
      keywordPageConfig.recentlyAddedKws = [...kwConfig.recentlyAddedKws].concat(filteredKws);
      resetConfig(keywordPageConfig, setKwConfig);
    } catch (e) {
      let errorMessage = `There was an issue adding to
                            ${maybePlural(config.keyword_groups.length, 'group')}.
                            Please try again later.`;
      if (e.errorStatus === 400) {
        // cuz lint is complaining
        const respBody = e.body;
        errorMessage = respBody.detail;
      }
      dispatch(globalActions.showNotification('error', errorMessage));
    }
    setLoading(false);
  };

  const setKeywordGroups = (val) => {
    setAddKwsConfig({ ...config, keyword_groups: val });
  };

  const footer = (
    <Flex direction="column" style={{ padding: '10px 0' }}>
      <Flex justifyContent="flex-end">
        <Button
          pointer
          className={styles.button}
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
        <Button
          pointer
          className={styles.button}
          type={'primary'}
          disabled={loading}
          onClick={() => {
            setDirty(true);
            if (validation.status === 'VALID') {
              saveKeywords();
            }
          }}
        >
        Add Keywords
        </Button>
        {loading && (
          <Loading
            size="small"
            spinnerWidth={15}
            spinnerHeight={15}
            pageLevel={false}
            className={styles.loadingIcon}
          />
        )}
      </Flex>
    </Flex>
  );
  return (
    <Modal
      visible
      bodyStyle={{ padding: 0, minHeight: 400 }}
      footer={footer}
      width="1250px"
      maskClosable={false}
      onCancel={() => onClose(false)}
    >
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9', alignContent: 'center' }}>
        {config.rec_keywords.length > 0 && (
          <Icon
            className={styles.backIcon}
            type="west"
            size={Icon.SIZE.XLARGE}
            pointer
            onClick={() => onClose(true)}
          />
        )}
        <Text type={Text.TYPE.TITLE} bold>
          {`Add ${config.rec_keywords.length > 0 ? 'Recommended Keywords' : 'Keywords'}`}
        </Text>
      </Flex>
      <div style={{ padding: '20px 30px' }}>
        <ConfigSentence config={config} setConfig={setAddKwsConfig} />
        <Flex
          direction="column"
          style={{ marginTop: `${validation.status === 'INVALID' ? '10px' : '30px'}` }}
        >
          {(dirty && validation.status === 'INVALID' && validation.error.includes('select')) && (
            <Text className={styles.error} color={Text.COLOR.ERROR}>{validation.error}</Text>
          )}
          <Text bold>Keywords</Text>
        </Flex>
        <Input
          value={config.keywords.join('\n')}
          className={styles.input}
          type={'textarea'}
          placeholder={'List new keywords, each on a separate line.'}
          onChange={({ target: { value } }) =>
            setAddKwsConfig({ ...config, keywords: parseKeywords(value) })}
        />
        {(dirty && validation.status === 'INVALID' && !validation.error.includes('select')) && (
          <Text className={styles.error} color={Text.COLOR.ERROR}>{validation.error}</Text>
        )}
        {
          siValidations.addKeyword &&
          <div style={{ marginTop: '20px' }}>
            <Text className={styles.label} bold>Add to a keyword group (Optional)</Text>
            <GroupSelect
              groups={config.keyword_groups}
              setGroups={setKeywordGroups}
              setCreateGroupModalVisible={setCreateGroupModalVisible}
              width={40}
            />
          </div>
        }

        {invalidModalVisible && (
          <InvalidKeywordsModal
            onCancel={() => {
              setinvalidModalVisible(false);
              setDirty(false);
            }}
            config={config}
            invalidKeywords={duplicateKeywords}
            onSave={saveKeywords}
            type={KEYWORDS}
          />
        )}
        {createGroupModalVisible && (
          <CreateGroupModal
            onCancel={() => setCreateGroupModalVisible(false)}
            fromAddKeywords
          />
        )}
      </div>
    </Modal>
  );
};

export default AddKeywordsModal;
