import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { compose } from 'redux';
import { css } from 'styles/emotion';
import {
  MULTI_PRODUCT_EVENT_NOT_USED_MODELS_NODE_ID,
  MULTI_PRODUCT_EVENT_USED_IN_MODELS_NODE_ID,
  NODE_POSITION_BASED_ON_ID,
  PRODUCT_CRM,
  PRODUCT_MAP,
  PRODUCT_NODE_ID,
  PRODUCT_WEB,
  SINGLE_PRODUCT_EVENT_NOT_USED_IN_MODELS_NODE_ID,
  SINGLE_PRODUCT_EVENT_USED_IN_MODELS_NODE_ID,
} from '../../constants';
import { actions } from '../../modules/commonModules';
import {
  countByMappingSelector,
  dataSourcePresentInStatusSelector,
  getChildNodesSelector,
  loadRulesCountDataSelector,
} from '../../selectors';
import { loadRecordsReviewStatusCountGenerator } from '../../stateGenerators';
import Header from './Header';
import { MAPPING_SUB_TYPES, NODE_TYPES, nodeTypes } from './nodes';

import { isEmpty } from 'lodash';
import 'reactflow/dist/style.css';
import { DesignSystemThemeHoc } from '../DesignSystemThemeHOC';

const styles = {
  container: css({
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    minHeight: '95vh',
  }),
};

// const testChildNodesNotUsedInModels = [
//   {
//     title: 'Marketer',
//     activityCount: 2663,
//     percentage: '2.1%',
//     subType: 'no_map',
//   },
//   {
//     title: 'SFDC',
//     activityCount: 1293,
//     percentage: '1.1%',
//     subType: 'no_map',
//   },
//   {
//     title: 'Lead',
//     activityCount: 7472,
//     percentage: '7%',
//     subType: 'no_map',
//   },
//   {
//     title: 'Email',
//     activityCount: 314,
//     percentage: '0.01%',
//     subType: 'no_map',
//   },
// ];

const getInitialNodes = ({
  integrationType,
  containerName,
  isMultiProduct,
  dataSource,
}) => {
  let nodes = [];
  if (
    [PRODUCT_CRM, PRODUCT_MAP, PRODUCT_WEB].includes(dataSource) &&
    isMultiProduct
  ) {
    nodes = [
      {
        id: '1',
        position: { x: 520, y: 0 },
        type: NODE_TYPES.IMAGE_NODE,
        data: { integrationType, containerName },
      },
      {
        id: '2a',
        position: { x: 142, y: 150 },
        type: NODE_TYPES.TOP_LEVEL_NODE,
        data: { taxonomyType: 'activity', dataSource },
      },
      {
        id: '2b',
        position: { x: 805, y: 150 },
        type: NODE_TYPES.TOP_LEVEL_NODE,
        data: { taxonomyType: 'product', dataSource },
      },
      {
        id: '3a',
        position: { x: 196, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: {
          type: MAPPING_SUB_TYPES.MAPPED,
          taxonomyType: 'activity',
          dataSource,
        },
      },
      {
        id: '4a',
        position: { x: 0, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: {
          type: MAPPING_SUB_TYPES.UNMAPPED,
          taxonomyType: 'activity',
          dataSource,
        },
      },
      {
        id: '5a',
        position: { x: 395, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: {
          type: MAPPING_SUB_TYPES.EXCLUDED,
          taxonomyType: 'activity',
          dataSource,
        },
      },
      {
        id: MULTI_PRODUCT_EVENT_USED_IN_MODELS_NODE_ID,
        position:
          NODE_POSITION_BASED_ON_ID[MULTI_PRODUCT_EVENT_USED_IN_MODELS_NODE_ID],
        type: NODE_TYPES.MAPPED_PARENT_NODE,
        data: { usedInModels: true, taxonomyType: 'event', dataSource },
      },
      {
        id: MULTI_PRODUCT_EVENT_NOT_USED_MODELS_NODE_ID,
        position:
          NODE_POSITION_BASED_ON_ID[
            MULTI_PRODUCT_EVENT_NOT_USED_MODELS_NODE_ID
          ],
        type: NODE_TYPES.MAPPED_PARENT_NODE,
        data: { usedInModels: false, taxonomyType: 'event', dataSource },
      },
      {
        id: 'annotationA',
        position: { x: 100, y: 670 },
        type: NODE_TYPES.ANNOTATION_NODE,
        data: { dataSource },
      },
      {
        id: '3b',
        position: { x: 860, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: {
          type: MAPPING_SUB_TYPES.MAPPED,
          taxonomyType: 'product',
          dataSource,
        },
      },
      {
        id: '4b',
        position: { x: 645, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: {
          type: MAPPING_SUB_TYPES.UNMAPPED,
          taxonomyType: 'product',
          dataSource,
        },
      },
      {
        id: '5b',
        position: { x: 1065, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: {
          type: MAPPING_SUB_TYPES.EXCLUDED,
          taxonomyType: 'product',
          dataSource,
        },
      },
      {
        id: PRODUCT_NODE_ID,
        position: NODE_POSITION_BASED_ON_ID[PRODUCT_NODE_ID],
        type: NODE_TYPES.MAPPED_PARENT_NODE,
        data: { usedInModels: false, taxonomyType: 'product', dataSource },
      },
    ];
  } else {
    nodes = [
      {
        id: '1',
        position: { x: 400, y: 0 },
        type: NODE_TYPES.IMAGE_NODE,
        data: { integrationType, containerName },
      },
      {
        id: '2',
        position: { x: 343, y: 150 },
        type: NODE_TYPES.TOP_LEVEL_NODE,
        data: { dataSource, taxonomyType: 'activity' },
      },
      {
        id: '3',
        position: { x: 396, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: {
          type: MAPPING_SUB_TYPES.MAPPED,
          taxonomyType: 'activity',
          dataSource,
        },
      },
      {
        id: '4',
        position: { x: 172, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: {
          type: MAPPING_SUB_TYPES.UNMAPPED,
          taxonomyType: 'activity',
          dataSource,
        },
      },
      {
        id: '5',
        position: { x: 620, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: {
          type: MAPPING_SUB_TYPES.EXCLUDED,
          taxonomyType: 'activity',
          dataSource,
        },
      },
      {
        id: SINGLE_PRODUCT_EVENT_USED_IN_MODELS_NODE_ID,
        position:
          NODE_POSITION_BASED_ON_ID[
            SINGLE_PRODUCT_EVENT_USED_IN_MODELS_NODE_ID
          ],
        type: NODE_TYPES.MAPPED_PARENT_NODE,
        data: { usedInModels: true, taxonomyType: 'event', dataSource },
      },
      {
        id: SINGLE_PRODUCT_EVENT_NOT_USED_IN_MODELS_NODE_ID,
        position:
          NODE_POSITION_BASED_ON_ID[
            SINGLE_PRODUCT_EVENT_NOT_USED_IN_MODELS_NODE_ID
          ],
        type: NODE_TYPES.MAPPED_PARENT_NODE,
        data: { usedInModels: false, taxonomyType: 'event', dataSource },
      },
      {
        id: 'annotation',
        position: { x: 325, y: 670 },
        type: NODE_TYPES.ANNOTATION_NODE,
        data: { dataSource },
      },
    ];
  }
  return nodes;
};

const getInitialEdges = ({ isMultiProduct, dataSource }) => {
  let edges = [];
  if (
    [PRODUCT_CRM, PRODUCT_MAP, PRODUCT_WEB].includes(dataSource) &&
    isMultiProduct
  ) {
    edges = [
      { id: 'e1-2a', source: '1', target: '2a' },
      { id: 'e1-2b', source: '1', target: '2b' },
      { id: 'e2a-3a', source: '2a', target: '3a' },
      { id: 'e2a-4a', source: '2a', target: '4a' },
      { id: 'e2a-5a', source: '2a', target: '5a' },
      { id: 'e3a-6a', source: '3a', target: '6a' },
      { id: 'e3a-7a', source: '3a', target: '7a' },
      { id: 'e2b-3b', source: '2b', target: '3b' },
      { id: 'e2b-4b', source: '2b', target: '4b' },
      { id: 'e2b-5b', source: '2b', target: '5b' },
      { id: 'e3a-6b', source: '3b', target: '6b' },
    ];
  } else {
    edges = [
      { id: 'e1-2', source: '1', target: '2' },
      { id: 'e2-3', source: '2', target: '3' },
      { id: 'e2-4', source: '2', target: '4' },
      { id: 'e2-5', source: '2', target: '5' },
      { id: 'e3-6', source: '3', target: '6' },
      { id: 'e3-7', source: '3', target: '7' },
    ];
  }
  return edges;
};

const defaultEdgeOptions = {
  type: 'smoothstep',
  markerEnd: { type: MarkerType.ArrowClosed },
  pathOptions: { offset: 5 },
};

const proOptions = {
  account: 'paid-pro',
  hideAttribution: true,
};

function LayoutFlowComponent({
  integrationType = 'web',
  containerName = 'Website URL',
  isMultiProduct = true,
  isDataSourcePresentInStatus,
  loadStatuses,
  dataSource,
  loadRecordsReviewStatus,
  loadCountByMapping,
  countByMapping,
  loadRulesCount,
  loadRulesCountData,
  childNodes,
}) {
  useEffect(() => {
    if (!isDataSourcePresentInStatus) {
      loadStatuses(dataSource);
      loadRecordsReviewStatus();
    }
    loadCountByMapping(dataSource);
    if (isEmpty(loadRulesCountData)) {
      loadRulesCount();
    }
  }, [dataSource]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  console.log({ countByMapping });
  // useEffect(() => {
  //   const initialNodes = getInitialNodes({
  //     integrationType,
  //     containerName,
  //     isMultiProduct,
  //     dataSource });
  //   const initialEdges = getInitialEdges({ isMultiProduct, dataSource });
  //   setNodes(initialNodes);
  //   setEdges(initialEdges);
  // }, [dataSource, integrationType, setNodes, setEdges]);

  useEffect(() => {
    const initialNodes = getInitialNodes({
      integrationType,
      containerName,
      isMultiProduct,
      dataSource,
    });
    const initialEdges = getInitialEdges({ isMultiProduct, dataSource });
    const types = Object.keys(childNodes);
    const allChildNodes = types.reduce((acc, type) => {
      if (isEmpty(childNodes[type])) return acc;
      // eslint-disable-next-line no-param-reassign
      acc = [...acc, ...childNodes[type].modifiedNodes];
      return acc;
    }, []);
    if (!isEmpty(allChildNodes)) {
      setNodes([...initialNodes, ...allChildNodes]);
    } else {
      setNodes(initialNodes);
    }
    setEdges(initialEdges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childNodes, setNodes, dataSource, integrationType]);

  return (
    <DesignSystemThemeHoc>
      <div className={styles.container}>
        <Header
          onDateRangeChange={() => {
            loadStatuses(dataSource);
            loadRecordsReviewStatus();
            loadCountByMapping(dataSource);
          }}
          dataSource={dataSource}
        />
        <ReactFlow
          nodes={nodes}
          edges={edges}
          fitView
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          proOptions={proOptions}
          defaultEdgeOptions={defaultEdgeOptions}
          nodesDraggable={false}
        >
          <Controls position="top-right" showInteractive={false} />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
      </div>
    </DesignSystemThemeHoc>
  );
}

LayoutFlowComponent.propTypes = {
  integrationType: PropTypes.string,
  containerName: PropTypes.string,
  isMultiProduct: PropTypes.bool,
  isDataSourcePresentInStatus: PropTypes.bool,
  loadStatuses: PropTypes.func,
  dataSource: PropTypes.string,
  loadRecordsReviewStatus: PropTypes.func,
  loadCountByMapping: PropTypes.func,
  countByMapping: PropTypes.object,
  loadRulesCount: PropTypes.func,
  loadRulesCountData: PropTypes.array,
  childNodes: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  isDataSourcePresentInStatus: dataSourcePresentInStatusSelector(
    ownProps.dataSource
  )(state),
  childNodes: getChildNodesSelector(ownProps.dataSource)(state),
  countByMapping: countByMappingSelector(state),
  loadRulesCountData: loadRulesCountDataSelector(state),
});

export const LayoutFlow = compose(
  connect(mapStateToProps, {
    loadStatuses: actions.loadStatuses,
    loadRecordsReviewStatus: loadRecordsReviewStatusCountGenerator.loadAction,
    loadCountByMapping: actions.loadCountByMapping,
    loadRulesCount: actions.loadRulesCount,
  })
)(LayoutFlowComponent);

export default (props) => (
  <ReactFlowProvider>
    <LayoutFlow {...props} />
  </ReactFlowProvider>
);
