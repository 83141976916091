import { defineSharedState, defineRequestState } from '@sixsense/core';
import { KeywordRequestParams } from './keywords';
import { Keyword } from '../types';

const PATH_PREFIX = 'settings.keywordsPage.deactivated';

export const restoreKeywordsVisibleState = defineSharedState<Boolean>({
  path: `${PATH_PREFIX}.restoreKeywordsModalVisible`,
  defaultValue: false,
});

export const deactivatedKeywordsRequestState = defineRequestState({
  path: `${PATH_PREFIX}.deactivatedKeywords`,
  getConfig: ({ orgId, queryParams, paginationParams }: KeywordRequestParams) => ({
    url: `${queryParams.length > 0
      ? `organization/${orgId}/keyword/${paginationParams}${queryParams}`
      : `organization/${orgId}/keyword/${paginationParams}`}`,
  }),
  transformData: (data: any): Keyword[] | [] => {
    if (data.results.length) {
      return data;
    }
    return [];
  },
});
