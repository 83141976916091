import React from 'react';
import Text from 'v2-components/Text';
import { COLUMN_CATEGORIES } from '../constants';
import { CAMPAIGN_SOURCE } from '../../../constants';
import { NA } from './common/NA';
import { ROW_DATA_TYPES } from 'aa-components/CampaignCSVExportModal/constants';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';

export const PROVIDER = {
  category: COLUMN_CATEGORIES.externalMedia,
  key: 'provider',
  csvColumnKey: 'ad_provider_name',
  title: <Label name={CAMPAIGN_LABELS.adProviderName} />,
  dataIndex: 'ad_provider_name',
  width: 160,
  render: (adprovider, campaign) => {
    if (campaign.campaign_source !== CAMPAIGN_SOURCE.EXTERNAL) {
      return (<NA />);
    }

    return (<Text type={Text.TYPE.SUBBODY}>{adprovider}</Text>);
  },
  csvOverrides: {
    render: (adprovider, campaign, dataType) => {
      if (dataType === ROW_DATA_TYPES.aggregated) return '';

      if (campaign.campaign_source !== CAMPAIGN_SOURCE.EXTERNAL) {
        return '-';
      }

      return adprovider;
    },
  },
};
