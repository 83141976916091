/*
  Contains rbm rule edit/creation specific constants & methods like
  Add/Remove/Duplicate Rule,
  Add/Remove/Duplicate Group,
  Wrap Rule into Group,
  Unwrap a group into Rule,
  Change relation (And/Or) in a Group,
  Comapring 2 entities,
  Validating a entity

  Note: Do not add any other miscellneous constants/utils in this file.
  Use constants.js & utils.js instead
*/
import { concat, get, isEmpty, isEqual, slice, trim } from 'lodash';
import { And, ModelAnd, ModelOr, Or } from 'routes/Settings/routes/Standardization/lib/ast';
import { coaleseNull } from '../../utils';
import { DEFAULT_GROUP, DEFAULT_RULE, ENTITY_TYPE } from './constants';
import { RbmAst } from './rbmAst';

export const MODEL_AND = 'ModelAnd';
export const AND = 'And';
export const MODEL_OR = 'ModelOr';
export const OR = 'Or';


export function isModelAndType(type) {
  return type === MODEL_AND || type === AND;
}
export function isModelOrType(type) {
  return type === MODEL_OR || type === OR;
}

export function isModelAndInstance(expr) {
  return expr instanceof ModelAnd || expr instanceof And;
}

export function isModelOrInstance(expr) {
  return expr instanceof ModelOr || expr instanceof Or;
}

export function isGroup(entity) {
  return entity.type === ENTITY_TYPE.GROUP;
}

export function isRule(entity) {
  return entity.type === ENTITY_TYPE.RULE;
}

export function createRule(column, operand, values, isCaseSensitive) {
  return {
    type: ENTITY_TYPE.RULE,
    column,
    operand,
    values: values ? values.filter((v) => v) : null,
    isCaseSensitive,
  };
}

export function createGroup(relation, rules) {
  return {
    type: ENTITY_TYPE.GROUP,
    relation,
    rules,
  };
}

export function insertElementAt(arr, index, element) {
  let _index = index;
  // If the index is greater than the array length, append the element at the end
  if (index > arr.length) {
    _index = arr.length;
  }
  // Split the array into two parts and insert the element in between
  return concat(slice(arr, 0, _index), element, slice(arr, _index));
}

export function removeElementAt(arr, index) {
  // Check if index is within array bounds
  if (index >= 0 && index < arr.length) {
    arr.splice(index, 1);
  }
  return arr;
}

export function addEntity(ruleSet, path, entity) {
  if (isEmpty(ruleSet)) {
    return entity;
  }

  const ruleSetCopy = { ...JSON.parse(JSON.stringify(ruleSet)) };
  // ie: just one rule at root
  if (path.length === 0 && !isGroup(ruleSet)) {
    return createGroup(MODEL_AND, [ruleSetCopy, entity]);
  }

  let track = ruleSetCopy;

 // traverse the path to find the target array
  for (let i = 0; i < path.length; i++) {
    const p = path[i];
    if (!track.rules) {
      track.rules = [];
    }

    if (i === path.length - 1) {
      track.rules = insertElementAt(track.rules, p, entity);
    } else {
      if (!track.rules[p]) {
        track.rules[p] = { rules: [] };
      }
      track = track.rules[p];
    }
  }

  return ruleSetCopy;
}

export function duplicateEntity(ruleSet, path) {
  const ruleSetCopy = { ...JSON.parse(JSON.stringify(ruleSet)) };
  const pathCopy = [...path];

  if (path.length === 0) {
    return createGroup(MODEL_AND, [ruleSetCopy, ruleSetCopy]);
  }

  let entityToCopy = ruleSetCopy;

  // traverse the path to find the target to copy
  for (let i = 0; i < path.length; i++) {
    const p = path[i];
    entityToCopy = entityToCopy.rules[p];
  }
  // update last path index, ex: path[0,3] -> path[0,4]
  pathCopy[pathCopy.length - 1] = pathCopy[pathCopy.length - 1] + 1;

  return addEntity(ruleSet, pathCopy, entityToCopy);
}

export function removeEntity(ruleSet, path) {
  const ruleSetCopy = { ...JSON.parse(JSON.stringify(ruleSet)) };

  if (path.length === 0) {
    return null;
  }

  let track = ruleSetCopy;
  // traverse the path to find the target to copy
  for (let i = 0; i < path.length; i++) {
    const p = path[i];
    if (i === path.length - 1) {
      track.rules = removeElementAt(track.rules, p);
    } else {
      track = track.rules[p];
    }
  }

  return ruleSetCopy;
}

export function addRule(ruleSet, path, rule = DEFAULT_RULE) {
  return addEntity(ruleSet, path, rule);
}

export function addGroup(ruleSet, path, group = DEFAULT_GROUP) {
  return addEntity(ruleSet, path, group);
}

export function duplicateGroup(ruleSet, path) {
  return duplicateEntity(ruleSet, path);
}

export function duplicateRule(ruleSet, path) {
  return duplicateEntity(ruleSet, path);
}

export function removeRule(ruleSet, path) {
  return removeEntity(ruleSet, path);
}

export function removeGroup(ruleSet, path) {
  return removeEntity(ruleSet, path);
}

export function takeAction(ruleSet, path, action) {
  const ruleSetCopy = { ...JSON.parse(JSON.stringify(ruleSet)) };

  if (path.length === 0) {
    return action(ruleSetCopy);
  }

  let track = ruleSetCopy;
  // traverse the path to find the target to copy
  for (let i = 0; i < path.length; i++) {
    const p = path[i];
    if (i === path.length - 1) {
      track.rules[p] = action(track.rules[p]);
    } else {
      track = track.rules[p];
    }
  }
  return ruleSetCopy;
}

export function wrapRuleIntoGroup(ruleSet, path) {
  const result = takeAction(
    ruleSet,
    path,
    (e) => {
      if (path.length === 0) { return createGroup(MODEL_AND, [e]); }

      // eslint-disable-next-line no-param-reassign
      e = createGroup(MODEL_AND, [e]);
      return e;

    }
  );
  return result;
}

export function turnGroupIntoRule(ruleSet, path) {
  const result = takeAction(
    ruleSet,
    path,
    (e) => {
      if (isGroup(e) && e.rules.length === 1) {
        // eslint-disable-next-line no-param-reassign
        e = e.rules[0];
        return e;
      }
      return e;
    }
  );
  return result;
}

export function changeRelation(ruleSet, path, relation) {
  const result = takeAction(
    ruleSet,
    path,
    (e) => {
      if (isGroup(e)) {
        e.relation = relation;
        return e;
      }
      return e;
    }
  );
  return result;
}

// updates can be an incomplete rule object
export function updateRule(ruleSet, path, updates) {
  const result = takeAction(
    ruleSet,
    path,
    (e) => {
      if (isRule(e)) {
        // eslint-disable-next-line no-param-reassign
        e = {
          ...e,
          ...updates,
        };
      }
      return e;
    }
  );
  return result;
}

export function convertAstToEntity(astJson) {
  const rbAst = new RbmAst();
  return rbAst.astToEntity(astJson);
}

export function convertEntityToAst(entity) {
  const rbAst = new RbmAst();
  const astObject = rbAst.entityToAst(entity);
  return JSON.parse(JSON.stringify(astObject));
}

export function getFilterLogic(entity) {
  const rbAst = new RbmAst();
  const filterLogic = rbAst.getFilterLogic(entity);
  return filterLogic;
}

export function getVerboseEntityRules(entity) {
  const rbAst = new RbmAst();
  const entityVerbose = rbAst.getVerboseEntityRules(entity);
  return entityVerbose;
}

export function isOnlyOneEmpty(o1, o2) {
  if ((isEmpty(o1) && !isEmpty(o2)) || (!isEmpty(o1) && isEmpty(o2))) return true;
  return false;
}

export function isBothEmpty(o1, o2) {
  return isEmpty(o1) && isEmpty(o2);
}

export function ruleEntitiesEqual(ruleEntity1, ruleEntity2) {
  // both are null/empty  =>e qual
  if (isBothEmpty(ruleEntity1, ruleEntity2)) return true;
  // one is empty/null, another is not => not equal
  if (isOnlyOneEmpty(ruleEntity1, ruleEntity2)) return false;
  // if types not same => not equal
  if (ruleEntity1.type !== ruleEntity2.type) return false;

  // check if both are groups
  if (isGroup(ruleEntity1) && isGroup(ruleEntity2)) {
    // if relation not same => not equal
    if (ruleEntity1.relation !== ruleEntity2.relation) return false;
    // if both rules are empty => equal
    if (isBothEmpty(ruleEntity1.rules, ruleEntity2.rules)) return true;
    // if either ones rules are empty/null, another ones rules are not => not equal
    if (isOnlyOneEmpty(ruleEntity1.rules, ruleEntity2.rules)) return false;
    // if rules lenght are not equal => not equal
    if (ruleEntity1.rules.length !== ruleEntity2.rules.length) return false;

    // if relation & both rules are of same length
    for (let i = 0; i < ruleEntity1.rules.length; i++) {
      if (!ruleEntitiesEqual(ruleEntity1.rules[i], ruleEntity2.rules[i])) {
       // if any sub entity is not equal => not equal
        return false;
      }
    }

    // all sub entities are equal => equal
    return true;
  }

  if (isRule(ruleEntity1) && isRule(ruleEntity2)) {
    if (
      (ruleEntity1.column !== ruleEntity2.column) ||
      (ruleEntity1.operand !== ruleEntity2.operand) ||
      (ruleEntity1.isCaseSensitive !== ruleEntity2.isCaseSensitive)
    ) return false;

    return isEqual(ruleEntity1.values, ruleEntity2.values);
  }

  // shouldn't reach here
  return false;
}

export function isRuleObjEdited(ruleObj, ruleObjEditCopy, ruleEntity) {

  const obj1 = {
    name: ruleObj ? trim(ruleObj.name): '',
    remove_manual_override:
      ruleObj && ruleObj.remove_manual_override !== null &&
      ruleObj.remove_manual_override !== undefined
      ? ruleObj.remove_manual_override: false,
    mapping: coaleseNull(get(ruleObj, 'mapping')),
    ruleEntity: ruleObj && ruleObj.ast ? convertAstToEntity(JSON.parse(ruleObj.ast)): null,
  };

  const obj2 = {
    name: ruleObjEditCopy ? trim(ruleObjEditCopy.name): '',
    remove_manual_override:
      ruleObjEditCopy && ruleObjEditCopy.remove_manual_override !== null &&
      ruleObjEditCopy.remove_manual_override !== undefined?
        ruleObjEditCopy.remove_manual_override : false,
    mapping: coaleseNull(get(ruleObjEditCopy, 'mapping')),
    ruleEntity,
  };

  if (
    obj1.name === obj2.name &&
    obj1.remove_manual_override === obj2.remove_manual_override &&
    obj1.mapping === obj2.mapping
 ) {
    if (isEmpty(obj1.ruleEntity)) {
      if (isEmpty(obj2.ruleEntity)) {
        return false;
      }
      return !ruleEntitiesEqual(obj2.ruleEntity, DEFAULT_RULE);
    }
    return !ruleEntitiesEqual(obj1.ruleEntity, obj2.ruleEntity);
  }
  return true;
}

export function isRuleEntityValid(ruleEntity) {
  if (!ruleEntity) return false;

  if (isRule(ruleEntity)) {
    if (
      isEmpty(trim(ruleEntity.column)) ||
      isEmpty(trim(ruleEntity.operand)) ||
      isEmpty(trim(ruleEntity.values))
    ) { return false; }
    return true;
  }

  if (isGroup(ruleEntity)) {
    if (isEmpty(ruleEntity.rules) || isEmpty(trim(ruleEntity.relation))) return false;

    let rulesValid = true;
    for (let i=0; i<ruleEntity.rules.length; i++) {
      if (!isRuleEntityValid(ruleEntity.rules[i])) {
        rulesValid = false;
        break;
      }
    }
    return rulesValid;
  }

  return false;
}

export function canCreateRule(ruleObjEditCopy, ruleEntity) {
  if (!ruleObjEditCopy) return false;
  if (
    isEmpty(trim(ruleObjEditCopy.name)) ||
    isEmpty(trim(ruleObjEditCopy.data_source)) ||
    isEmpty(trim(ruleObjEditCopy.classification_type)) ||
    isEmpty(trim(ruleObjEditCopy.mapping))
  ) { return false; }

  return isRuleEntityValid(ruleEntity);
}
