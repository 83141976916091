import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'Peerspot',
    name: 'Peerspot',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const PeerspotContainer = require('./containers/PeerspotContainer');
          const sagas = require('../../PeerspotGartnerCommonConfig/sagas');
          const reducer = require('../../PeerspotGartnerCommonConfig/modules');
          injectReducer('PeerSpot', reducer.default);
          injectSagas('PeerSpot', sagas.default);
          cb(null, PeerspotContainer.default);
        },
        'peerspot',
      );
    },
  };
}
