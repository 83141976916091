import React from 'react';
import { css, cx } from 'styles/emotion';
import { pxToRem } from '@sixsense/core/style';
import { Link } from 'v2-components';
import { useSelector } from '@sixsense/core/versioned/react-redux';
import {
  getRolesTabSelectionSelector,
  getRoleByIdSelector,
} from '../selectors';

const styles = {
  table: css({
    fontSize: pxToRem(13),
    color: '#001F32',
    width: '100%',
  }),
  headerRow: css({
    height: pxToRem(40),
    border: 'none',
    fontWeight: 'bold',
  }),
  row: css({
    height: pxToRem(60),
    border: '1px solid #C6CACE',
  }),
  cell: css({
    padding: `0 ${pxToRem(40)}`,
  }),
  nameCell: css({
    width: '20%',
  }),
  countCell: css({
    width: '15%',
    textAlign: 'center',
  }),
};

const RoleList = () => {
  const { selectedRoleIds = [] } = useSelector(getRolesTabSelectionSelector);
  const getRoleById = useSelector(getRoleByIdSelector);
  const roles = selectedRoleIds.map((id) => getRoleById(id));

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.headerRow}>
          <th className={cx(styles.cell, styles.nameCell)}>Role</th>
          <th className={styles.cell}>Summary</th>
          <th className={cx(styles.cell, styles.countCell)}>
            Number of Enabled Users
          </th>
        </tr>
      </thead>
      <tbody>
        {roles.map((role) => (
          <tr key={role.id} className={styles.row}>
            <td className={cx(styles.cell, styles.nameCell)}>
              <Link
                isInternal
                link={`/settings/usermanagement/roles/${role.id}`}
              >
                {role.name}
              </Link>
            </td>
            <td className={styles.cell}>{role.description}</td>
            <td className={cx(styles.cell, styles.countCell)}>
              {role.users || 0}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RoleList;
