import React, { Component } from 'react';
import styles from './ShowHideUnder.module.scss';
import PropTypes from 'prop-types';

class ShowHideUnder extends Component {
  static propTypes = {
    Toggle: PropTypes.func,
    toggleProps: PropTypes.object,
    ToShow: PropTypes.func,
    toShowProps: PropTypes.object,
    containerClassName: PropTypes.string,
    toggleClassName: PropTypes.string,
    registerOpen: PropTypes.func,
    registerClose: PropTypes.func,
    useDocument: PropTypes.bool,
  };

  static defaultProps = {
    registerOpen: () => null,
    registerClose: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentWillUnmount() {
    if (this.props.useDocument) {
      document.removeEventListener('click', this.close);
    } else {
      window.removeEventListener('click', this.close);
    }
  }

  close = () => {
    this.setState({ show: false });
    if (this.props.useDocument) {
      document.removeEventListener('click', this.close);
    } else {
      window.removeEventListener('click', this.close);
    }
    this.props.registerClose();
  };

  show = (val = !this.state.show) => {
    this.setState({ show: val });
    if (this.props.useDocument) {
      document.addEventListener('click', this.close);
    } else {
      window.addEventListener('click', this.close);
    }
    this.props.registerOpen();
  };

  render() {
    const {
      Toggle,
      ToShow,
      containerClassName,
      toggleClassName,
      toShowProps,
      toggleProps,
    } = this.props;
    const { show } = this.state;

    return (
      <div
        className={styles.container}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={toggleClassName}>
          <Toggle
            showFn={this.show}
            closeFn={this.close}
            contentIsShowing={show}
            {...toggleProps}
          />
        </div>
        {show && (
          <div
            className={`${styles.showUnderContainer} ${containerClassName}`}
            onClick={(event) => {
              event.nativeEvent.stopImmediatePropagation();
              event.stopPropagation();
            }}
          >
            <ToShow
              showFn={this.show}
              contentIsShowing={show}
              {...toShowProps}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ShowHideUnder;
