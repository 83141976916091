import React from 'react';
import { useOrg } from 'hooks';
import { useRequestValue, useSharedState, useSharedValue } from '@sixsense/core';
import { KeywordFilters, KeywordTable, NoDataComponent } from '../components';
import {
  keywordGroupRequestState,
  groupsConfigState,
  renameGroupVisibleState,
  deleteGroupVisibleState,
} from '../state';
import { useGroupAccountsRequest } from '../hooks';
import { KeywordGroup } from '../types';
import { ResultStatus } from '@sixsense/core/shared/async-result/types';
import { GROUPS, GROUP_TABLE_HEADERS } from '../constants';
import { Loading, Flex, Card, ResultBoundary } from '@sixsense/core/components';
import { RenameGroupModal, DeleteGroupModal } from '../components/Modals';
import { getPaginationParams, getQueryParams } from '../utils';

type Props = {
  groupList: KeywordGroup[];
};

const TableDataContainer = ({ groupList }: Props) => {
  const [config, setConfig] = useSharedState(groupsConfigState);
  const [renameModalVisible, setRenameModalVisible] = useSharedState(renameGroupVisibleState);
  const [deleteModalVisible, setDeleteModalVisible] = useSharedState(deleteGroupVisibleState);
  // const groupIds = groupList?.results?.map((v) => v.id) || [];
  const groupAccountsResult = useGroupAccountsRequest(config.spanFilter);

  return (
    <ResultBoundary
      result={groupAccountsResult}
      renderLoading={() => <Loading.Spinner level="page" />}
    >
      {({ data }) => (
        <Card.Content
          style={{
            background: 'white',
            border: '1px solid #C6CACE',
            padding: '0px',
          }}
        >
          {groupList.results ?
            <React.Fragment>
              <KeywordTable
                fromAddToGroup={false}
                requestData={(pageNumber, pageSize) =>
                  setConfig({
                    ...config,
                    pagination: { pageSize, pageNumber },
                    selectedValues: [],
                  })}
                totalDataCount={groupList.count}
                pageSize={config.pagination.pageSize}
                currentPage={config.pagination.pageNumber}
                pageOptions={[25, 50, 75, 100]}
                dataset={groupList.results || []}
                pageKey={GROUPS}
                headers={GROUP_TABLE_HEADERS}
                keywordAccounts={data}
                accountsKey="name"
              />
              {renameModalVisible && (
                <RenameGroupModal
                  onCancel={() => setRenameModalVisible(false)}
                  groups={groupList.results || []}
                />
              )}
              {deleteModalVisible && (
                <DeleteGroupModal
                  onCancel={() => setDeleteModalVisible(false)}
                  groups={groupList.results || []}
                />
              )}
            </React.Fragment> :
            <NoDataComponent
              filters={config.filters}
              noDataStr={'Keep your keywords organised by creating keyword groups. Click ' +
                'on "Add Keyword Group" to create your first keyword group.'}
              noMatchStr={'No Groups match your filters.'}
            />
          }
        </Card.Content>
    )}
    </ResultBoundary>
  );
};

const KeywordGroupsContainer = () => {
  const org = useOrg();
  const config = useSharedValue(groupsConfigState);
  const queryParams = getQueryParams(config.filters, true);
  const paginationParams = getPaginationParams(config.pagination);
  const groups = useRequestValue(keywordGroupRequestState,
    { orgId: org.id, queryParams, paginationParams, counter: config.counter });

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <KeywordFilters
        label={`Keyword Groups (${groups.data?.count || 0})`}
        multiLabel={`${config.selectedValues.length} Groups selected`}
        disabled={groups.status === ResultStatus.LOADING}
        pageKey={GROUPS}
      />
      <ResultBoundary result={groups} renderLoading={() => <Loading.Spinner level="page" />}>
        {({ data }) => (
          <TableDataContainer groupList={data} />
        )}
      </ResultBoundary>
    </Flex>
  );
};

export default KeywordGroupsContainer;
