import {
  updateAndGetLatestColumnConfig,
} from 'routes/Advertising/routes/Campaigns/routes/ListCampaigns/utils';
import {
  ARCHIVED_CAMPAIGNS_COLUMNS, getDefaultColumns,
} from 'routes/Advertising/routes/Campaigns/routes/ListCampaigns/ColumnDefinitions';
import {
  CSV_DATA_TYPE,
} from 'routes/Advertising/routes/Campaigns/routes/ListCampaigns/ColumnDefinitions/CSV_DATA_TYPE';
import {
  CAMPAIGN_TYPE,
} from 'routes/Advertising/routes/Campaigns/routes/ListCampaigns/ColumnDefinitions/CAMPAIGN_TYPE';
import {
  CAMPAIGN_ID,
} from 'routes/Advertising/routes/Campaigns/routes/ListCampaigns/ColumnDefinitions/CAMPAIGN_ID';

export const getReportColumnKeys = (isArchivedFolderSelected, orgFeatureFlags, allColumns) => {
  const savedColumns = isArchivedFolderSelected
    ? ARCHIVED_CAMPAIGNS_COLUMNS
    : updateAndGetLatestColumnConfig(allColumns, getDefaultColumns(allColumns), orgFeatureFlags);

  const columns = [
    ...savedColumns.slice(0, 2),
    CSV_DATA_TYPE,
    CAMPAIGN_TYPE,
    CAMPAIGN_ID,
    ...savedColumns.slice(2),
  ]
    .map((column) => ({ ...column, ...column.csvOverrides }))
    .filter((column) => Boolean(column.hide) === false)
    .map((column) => column.csvColumnKey);

  return columns;
};

export const removeFalsyValues = (object) => Object.entries(object).reduce(
  (acc, [key, value]) => {
    if (value) {
      return { ...acc, [key]: value };
    }

    return acc;
  },
  {}
);
