export default function createRoutes() {
  return {
    path: 'segment/:id',
    name: 'segment-edit',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const CreateRoute = require('./SegmentCreation');
        cb(null, CreateRoute.default);
      }, 'segment-edit');
    },
  };
}
