import RoleDetailPage from './Roles/Detail/RoleDetailPage';
import RolesCard from './Roles/RolesCard';
import UsersPage from './Users/UsersPage';

export default function createRoutes() {
  return {
    path: 'usermanagement',
    name: 'usermanagement',
    getComponent(_nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const container = require('./containers/UserManagementSwitcher').default;
          cb(null, container);
        },
        'settings_usermanagement'
      );
    },
    childRoutes: [
      {
        path: 'users',
        name: 'user_management_users',
        component: UsersPage,
      },
      {
        path: 'roles',
        name: 'user_management_roles',
        indexRoute: {
          component: RolesCard,
        },
        childRoutes: [
          {
            path: ':id',
            name: 'user_management_role_detail',
            component: RoleDetailPage,
          },
        ],
      },
    ],
  };
}
