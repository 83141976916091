import { createReducerUtil } from 'utils/core';
import { PROMISE_STATES } from 'modules/global/constants';
import { fetchSaga } from 'store/sagas';
import {
  all, call, cancel, cancelled, fork, put, select, take, takeLatest,
} from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { throwRequiredError } from 'utils/decorators';
import { isEmpty, get } from 'lodash';
import { NO_ABORT } from 'utils/constants';

export const stateGenerator = ({
  actionPrefix = throwRequiredError('actionPrefix'),
  endpointSelector = throwRequiredError('endpointSelector'),
  stateKey = throwRequiredError('stateKey'),
  initialData = null,
  receivedDataTransform = (data) => data,
  baseStateSelector = (state) => state,
  onFailureActions = [],
  onSuccessActions = [],
  method = 'GET',
  requestOptions = () => {},
  attachReducer = {},
  additionalRefreshActions = [],
  customRequestErrorHandler = undefined,
  debug = false,
}) => {
  const logger = debug ?
    // eslint-disable-next-line no-console
    (...args) => console.log(`${stateKey} stateGenerator > `, ...args) :
    () => null;
  const LOAD = `${actionPrefix}_LOAD`;
  const loadAction = (payload, meta) => ({ type: LOAD, payload, meta });

  const ABORT_LOAD = `${actionPrefix}_ABORT_LOAD`;
  const abortLoadAction = () => ({ type: ABORT_LOAD });

  const LOAD_SUCCESS = `${actionPrefix}_LOAD_SUCCESS`;
  const loadSuccessAction = (payload) => ({ type: LOAD_SUCCESS, payload });

  const LOAD_FAIL = `${actionPrefix}_LOAD_FAILED`;
  const loadFailedAction = (payload) => ({ type: LOAD_FAIL, payload });

  const REFRESH = `${actionPrefix}_REFRESH`;
  const refreshAction = () => ({ type: REFRESH });

  const initialState = { promiseState: PROMISE_STATES.INIT, data: initialData, error: null };
  const refreshActionTypes = [REFRESH, ...additionalRefreshActions];
  const reducer = createReducerUtil(initialState, {
    [LOAD]: (state) => ({
      ...state,
      promiseState: PROMISE_STATES.PENDING,
    }),
    [LOAD_SUCCESS]: (state, { payload }) => ({
      ...state,
      promiseState: PROMISE_STATES.SUCCESS,
      data: payload,
    }),
    [LOAD_FAIL]: (state, { payload }) => ({
      ...state,
      promiseState: PROMISE_STATES.FAILED,
      error: payload,
    }),
    ...attachReducer,
  }, refreshActionTypes);

  function* saga(request, action) {
    const controller = new AbortController();
    try {
      const endpoint = yield select(endpointSelector, action);
      const requestsOptions = yield select(requestOptions, action);
      const options = { signal: controller.signal, ...requestsOptions };
      const response = yield call(request, endpoint, method, options, customRequestErrorHandler);
      const transformedResponse = receivedDataTransform(response, action);

      yield put(loadSuccessAction(transformedResponse));
      if (onSuccessActions.length) {
        yield all(
          onSuccessActions
            .map((actionCreator) => actionCreator(transformedResponse, action))
            .filter((actionObject) => !isEmpty(actionObject))
            .map((actionObject) => put(actionObject))
        );
      }
    } catch (e) {
      logger('failed at saga with', e);
      const payload = { nativeError: e, toString: e.toString() };
      yield put(loadFailedAction(payload));
      if (onFailureActions.length) {
        yield all(onFailureActions.map((actionCreator) => put(actionCreator(payload, action))));
      }
    } finally {
      if (yield cancelled() && !get(action, `meta.${NO_ABORT}`)) {
        controller.abort();
      }
    }
  }

  function* loadSaga(request, action) {
    const loadTask = yield fork(saga, request, action);
    yield take(ABORT_LOAD);
    yield cancel(loadTask);
  }

  function* loadActionWatcher(request) {
    yield takeLatest(LOAD, loadSaga, request);
  }

  const dataSelector = createSelector(
    baseStateSelector,
    (state) => state[stateKey].data,
  );
  const errorSelector = createSelector(
    baseStateSelector,
    (state) => state[stateKey].error,
  );
  const promiseStateSelector = createSelector(
    baseStateSelector,
    (state) => state[stateKey].promiseState,
  );

  return {
    reducer,
    loadAction,
    abortLoadAction,
    refreshAction,
    saga: fetchSaga(loadActionWatcher),
    stateKey,
    dataSelector,
    errorSelector,
    promiseStateSelector,
    actionTypes: {
      LOAD,
      LOAD_SUCCESS,
      LOAD_FAIL,
      REFRESH,
    },
  };
};

