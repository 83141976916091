import { SNOWFLAKE_ROUTE, SNOWFLAKE_REDUX_STATE_KEY } from './constants';
import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: SNOWFLAKE_ROUTE,
    getComponent(nextState, cb) {
      // @ts-ignore
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const reducer = require('./modules');
          const sagas = require('./sagas');

          injectReducer(SNOWFLAKE_REDUX_STATE_KEY, reducer.default);
          injectSagas(SNOWFLAKE_REDUX_STATE_KEY, sagas.default);

          const formWizardReducer =
          require('routes/Settings/routes/Integration/components/FormWizard2/modules');
          injectReducer('SettingsFormWizard', formWizardReducer.default);

          cb(null, containers.SnowflakeSummaryContainer);
        },
        'snowflake',
      );
    },
  };
}
