/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { Checkbox2, Icon, Button, Text } from 'v2-components';
import { css, cx } from 'styles/emotion';
import { pxToRem, AAColor } from '@sixsense/core/style';
import {
  STATUS_LABEL_MAP,
  DISABLED_STATUS,
  TOOLTIP_MSGS,
  LICENSE_TYPES,
} from '../../constants';
import { Flex, Tooltip } from '@sixsense/core/components';
import { Pagination } from 'antd';
import usePagination from '@sixsense/core/hooks/usePagination';
import { CreditUsers, SortOrder, SortState } from '../../types';
import { useSharedState } from '@sixsense/core';
import {
  dataCreditsUsageState,
  orgPoolSelectedState,
  searchUsersState,
  selectedRoleState,
  selectedStatusState,
} from '../../state';
import { isViewOnlyEnabledFor6senseAccessSelector } from 'modules/user/selectors';
import { useSelector } from '@sixsense/core/versioned/react-redux';

const styles = {
  table: css({
    fontSize: pxToRem(13),
    color: '#001F32',
    width: '100%',
    minHeight: '15vh',
  }),
  headerRow: css({
    height: pxToRem(40),
    border: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  }),
  row: css({
    height: pxToRem(60),
    border: '1px solid #C6CACE',
    ':hover': {
      background: '#F9FAFB',
      button: {
        display: 'block',
      },
    },
    button: {
      display: 'none',
    },
  }),
  cell: css({
    padding: `0 ${pxToRem(10)}`,
    position: 'relative',
    '&:first-child': {
      paddingLeft: pxToRem(40),
    },
    '&:last-child': {
      paddingRight: pxToRem(40),
    },
  }),
  checkboxCell: css({
    paddingLeft: `${pxToRem(16)}`,
  }),
  nameCell: css({
    width: '20%',
    textAlign: 'left',
  }),
  sortIcon: css({
    position: 'absolute',
    left: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
  }),
  checkbox: css({
    width: 20,
  }),
  editButton: css({
    border: 'none',
    fontSize: `${pxToRem(12)}`,
    backgroundColor: '#ffffff00',
    padding: '0px',
  }),
  editButtonText: css({
    margin: '4px',
  }),
  footer: css({
    marginTop: `${pxToRem(20)}`,
    '.ant-pagination': {
      width: '100%',
    },
    '.ant-pagination-total-text': {
      width: '40%',
    },
  }),
  nousers: css({
    position: 'absolute',
    marginLeft: '35%',
    padding: '30px',
  }),
};

const HEADER_CONFIG = [
  { label: 'Username', sortKey: 'user_email', className: styles.nameCell },
  { label: 'Status', sortKey: 'status' },
  { label: 'Role', sortKey: 'role' },
  { label: 'Last Updated By', sortKey: 'updated_by' },
  { label: 'Used Credit', sortKey: 'total_used_credits' },
  { label: 'Balance', sortKey: 'total_balance_credits' },
  { label: 'Allocated Credits', sortKey: 'total_credits' },
];

const pagination = {
  pageSize: 10,
  initialPage: 1,
};

const showTotal = (total, range) => (
  <Text>
    {' '}
    Showing {range[0]} - {range[1]} of {total} records
  </Text>
);

const NoUsers = () => (
  <Flex
    className={styles.nousers}
    direction="row"
    alignItems="center"
    justifyContent="center"
  >
    <Text>No users found</Text>
  </Flex>
);

type UsersTableProps = {
  users: Array<CreditUsers>;
  roles: Array<[]>;
  selectedUsers: Set<String>;
  setSelectedUsers: (users: Set<String>) => void;
  handleUpdateCredit: (user: Array<CreditUsers>) => void;
  sortState: SortState;
  setSortState: (nextState: SortState) => void;
};

const renderSortIcon = (sortOrder?: SortOrder) => {
  switch (sortOrder) {
    case 'asc':
      return <Icon type="arrow_drop_up" />;
    case 'desc':
      return <Icon type="arrow_drop_down" />;
    default:
      return <span style={{ display: 'inline-block', width: 16 }} />;
  }
};

const getTooltipMsg = ({ status, role_rbac }, pool) => {
  if (pool.is_expired) {
    return TOOLTIP_MSGS.CREDIT_POOL_EXPIRED_STATUS;
  }
  return status === DISABLED_STATUS || !role_rbac
    ? TOOLTIP_MSGS.DISABLED
    : TOOLTIP_MSGS.LITE;
};

type HeaderProps = {
  children: string;
  sortState?: SortState;
  sortKey: string;
  className?: string;
  onClick: (col: string) => void;
};

const HeaderColumn = ({
  children,
  sortState,
  sortKey,
  className,
  onClick,
}: HeaderProps) => (
  <th className={cx(styles.cell, className)} onClick={() => onClick(sortKey)}>
    <Flex alignItems="center" justifyContent="flex-start">
      {children}{' '}
      {renderSortIcon(
        sortState?.column === sortKey ? sortState?.order : undefined
      )}
    </Flex>
  </th>
);

const checkUserDisabled = ({ status, role_rbac, license_type }) =>
  status === DISABLED_STATUS ||
  !role_rbac ||
  license_type === LICENSE_TYPES.LITE;

const UsersTable = ({
  users,
  selectedUsers,
  roles,
  setSelectedUsers,
  handleUpdateCredit,
  sortState,
  setSortState,
}: UsersTableProps) => {
  const [search] = useSharedState(searchUsersState);
  const [userStatus] = useSharedState(selectedStatusState);
  const [userRole] = useSharedState(selectedRoleState);
  const roleMapper = roles.reduce(
    (el, array: any) => ({ ...el, [array.value]: array.label }),
    {}
  );
  const {
    data: pageData,
    page,
    setPage,
  } = usePagination(users, { pageSize: pagination.pageSize });
  const [orgPoolSelected] = useSharedState(orgPoolSelectedState);
  const [dataCreditsUsageFlag] = useSharedState(dataCreditsUsageState);
  const isViewOnlyUser = useSelector(isViewOnlyEnabledFor6senseAccessSelector);

  useEffect(() => {
    setPage(pagination.initialPage);
  }, []);

  useEffect(() => {
    if (page !== pagination.initialPage) {
      setPage(pagination.initialPage);
    }
  }, [search, userStatus, userRole, orgPoolSelected]);

  const handleSortClick = (column: string) => {
    if (sortState?.column === column) {
      if (sortState?.order === 'asc') {
        setSortState({ column, order: 'desc' });
      } else {
        setSortState(undefined);
      }
    } else {
      setSortState({ column, order: 'asc' });
    }
  };

  const selectAllUsers = (selected: boolean) => {
    const nextSelected = new Set(selectedUsers);
    pageData.forEach(({ user_email, status, role_rbac, license_type }) => {
      if (
        selected &&
        !checkUserDisabled({ status, role_rbac, license_type }) &&
        !orgPoolSelected.is_expired
      ) {
        nextSelected.add(user_email);
      } else {
        nextSelected.delete(user_email);
      }
    });
    setSelectedUsers(nextSelected);
  };

  const allSelected = useMemo(() => {
    const selectablePageData = pageData.filter(
      (user) => !checkUserDisabled(user)
    );
    return (
      selectedUsers.size &&
      selectablePageData.every(({ user_email }) =>
        selectedUsers.has(user_email)
      )
    );
  }, [users, selectedUsers]);
  const anySelected = useMemo(
    () => users.some(({ user_email }) => selectedUsers.has(user_email)),
    [users, selectedUsers]
  );

  return (
    <div>
      <table className={styles.table}>
        <thead>
          <tr className={styles.headerRow}>
            {dataCreditsUsageFlag ? (
              <th className={cx(styles.checkboxCell, styles.checkbox)}>
                <Checkbox2
                  size="20px"
                  checked={allSelected}
                  indeterminate={anySelected}
                  onChange={(e) => selectAllUsers(e.target.checked)}
                  disabled={
                    orgPoolSelected.is_expired ||
                    isViewOnlyUser
                  }
                />
              </th>
            ) : (
              <th />
            )}
            {HEADER_CONFIG.map((config) => (
              <HeaderColumn
                key={config.label}
                sortState={sortState}
                onClick={handleSortClick}
                className={cx(styles.cell, config.className)}
                sortKey={config.sortKey}
              >
                {config.label}
              </HeaderColumn>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {pageData.map((user) => (
            <Tooltip
              placement="top"
              overlay={
                checkUserDisabled(user) || orgPoolSelected.is_expired
                  ? getTooltipMsg(user, orgPoolSelected)
                  : ''
              }
              key={user.id}
            >
              <tr key={user.id} className={styles.row}>
                {dataCreditsUsageFlag ? (
                  <td className={cx(styles.checkboxCell, styles.checkbox)}>
                    <Checkbox2
                      size="20px"
                      disabled={
                        checkUserDisabled(user) ||
                        orgPoolSelected.is_expired ||
                        isViewOnlyUser
                      }
                      checked={selectedUsers.has(user.user_email)}
                      onChange={(e) => {
                        const nextSelected = new Set(selectedUsers);
                        if (e.target.checked) {
                          nextSelected.add(user.user_email);
                        } else {
                          nextSelected.delete(user.user_email);
                        }
                        setSelectedUsers(nextSelected);
                      }}
                    />
                  </td>
                ) : (
                  <td />
                )}
                <td className={cx(styles.cell, styles.nameCell)}>
                  {user.user_email}
                </td>
                <td className={styles.cell}>{STATUS_LABEL_MAP[user.status]}</td>
                <td className={styles.cell}>
                  {roleMapper[user.role_rbac] || '-'}
                </td>
                <td className={styles.cell}>{user.updated_by || '-'}</td>
                <td className={styles.cell}>{user.total_used_credits}</td>
                <td className={styles.cell}>{user.total_balance_credits}</td>
                <td className={styles.cell}>
                  <Flex>
                    <span className={styles.editButtonText}>
                      {user.total_credits}
                    </span>
                    <Button
                      className={styles.editButton}
                      onClick={() => handleUpdateCredit(user)}
                      disabled={
                        checkUserDisabled(user) ||
                        orgPoolSelected.is_expired ||
                        !dataCreditsUsageFlag ||
                        isViewOnlyUser
                      }
                      iconRight={
                        <Icon
                          type="drive_file_rename_outline"
                          theme="outlined"
                          color={AAColor.GREY3}
                          size="small2"
                        />
                      }
                    />
                  </Flex>
                </td>
              </tr>
            </Tooltip>
          ))}
          {pageData.length === 0 && <NoUsers />}
        </tbody>
      </table>

      <Flex justifyContent="flex-start" className={styles.footer}>
        <Pagination
          current={page}
          pageSize={pagination.pageSize}
          onChange={(nextPage) => setPage(nextPage)}
          total={users.length}
          showTotal={showTotal}
        />
      </Flex>
    </div>
  );
};

export default UsersTable;
