import { createSelector } from "reselect";

import { find, isEmpty, map, omit, reduce, some } from "lodash";
import { orgSelector, userSelector } from "modules/user/selectors";

export const routeStateSelector = (state) => state.adGroups;

export const getActiveAdGroupsSelector = createSelector(
  routeStateSelector,
  ({ placementGroups }) => placementGroups.filter((group) => !group.is_deleted)
);

export const initialPlacementGroupsSelector = createSelector(
  routeStateSelector,
  ({ initialPlacementGroups }) =>
    initialPlacementGroups.filter((group) => !group.is_deleted)
);

export const placementTypeSelector = createSelector(
  routeStateSelector,
  ({ placementType }) => placementType
);

export const adPlacementSelector = createSelector(
  routeStateSelector,
  ({ adPlacement }) => adPlacement
);

export const adPlacementListSelector = createSelector(
  adPlacementSelector,
  ({ adPlacementList }) => adPlacementList
);

export const totalPlacementsSelector = createSelector(
  adPlacementSelector,
  ({ totalPlacements }) => totalPlacements
);

export const placementSearchInputSelector = createSelector(
  adPlacementSelector,
  ({ searchInput }) => searchInput
);

export const placementLimitSelector = createSelector(
  adPlacementSelector,
  ({ limit }) => limit
);

export const placementOffsetSelector = createSelector(
  adPlacementSelector,
  ({ offset }) => offset
);

export const isLoadingSelector = createSelector(
  adPlacementSelector,
  ({ loading }) => loading
);

export const placementPreviewSelector = createSelector(
  adPlacementSelector,
  ({ selectedPlacement }) => selectedPlacement
);

export const orderBySelector = createSelector(
  adPlacementSelector,
  ({ orderBy }) => orderBy
);

export const orderingSelector = createSelector(
  adPlacementSelector,
  ({ ordering }) => ordering
);

export const placementEndpointSelector = createSelector(
  orgSelector,
  placementSearchInputSelector,
  placementLimitSelector,
  placementOffsetSelector,
  placementTypeSelector,
  orderBySelector,
  orderingSelector,
  (orgId, search, limit, offset, creativeType, orderBy, ordering) =>
    `org/${orgId}/${creativeType}_placement/?search=${search}&limit=${limit}&offset=${offset}` +
    `&ordering=${
      ordering === "desc" ? "-" : ""
    }${orderBy}&is_deleted=False&show_in_library=True`
);

export const placementGroupsSelectors = createSelector(
  routeStateSelector,
  ({ placementGroups }) => placementGroups
);

export const placementGroupsRequestBodySelector = (
  isCreate,
  isExternalCampaign
) =>
  createSelector(
    placementGroupsSelectors,
    getActiveAdGroupsSelector,
    (placementGroups, activePlacementGroups) => {
      let reqBody = placementGroups;
      if (isCreate) {
        reqBody = activePlacementGroups;
      }
      return reqBody.map((group) => {
        const placementIds = group.placements.map(
          (placement) => (isExternalCampaign && placement.pid) || placement.id
        );
        return {
          ...group,
          placements: placementIds,
        };
      });
    }
  );
export const placementGroupErrorSelector = (isExternalCampaignSelector) =>
  createSelector(
    getActiveAdGroupsSelector,
    isExternalCampaignSelector,
    (placementGroups, isExternalCampaign) => {
      const adsDeletedFromAdGroup =
        !isExternalCampaign &&
        some(placementGroups, (group) =>
          isEmpty(group.placements.filter((pl) => pl.is_deleted === false))
        );
      return (
        isEmpty(placementGroups) ||
        some(placementGroups, (group) => isEmpty(group.placements)) ||
        adsDeletedFromAdGroup
      );
    }
  );

// dummy ads
export const dummyAdsStateSelector = createSelector(
  routeStateSelector,
  ({ dummyAdsState }) => dummyAdsState
);

export const modalVisibleSelector = createSelector(
  dummyAdsStateSelector,
  (routeState) => routeState.modalVisible
);

export const unsavedDummyAdsSelector = createSelector(
  getActiveAdGroupsSelector,
  (adGroups) =>
    reduce(
      adGroups,
      (unsavedAds, adGroup) => {
        const unsavedPlacements = adGroup.placements.filter(
          (ad) => ad.saved === false
        );
        return unsavedAds.concat(unsavedPlacements);
      },
      []
    )
);

export const dummyAdReqBodySelector = (dummyAd) =>
  createSelector(userSelector, (userId) => ({
    ...omit(dummyAd, "isNew", "saved", "id", "groupName"),
    user_id: userId,
  }));

export const downloadTrackerListSelector = (groupName) =>
  createSelector(getActiveAdGroupsSelector, (adGroups) => {
    const selectedAdGroup = find(adGroups, { placement_group_name: groupName });
    const placementData = map(selectedAdGroup.placements, (pl) => ({
      id: `"${pl.id}"`,
      ad: `"${pl.name}"`,
      adGroup: `"${groupName}"`,
      url: `"${pl.url}"`,
      external_id: `"${pl.external_id == null ? "-" : pl.external_id}"`,
      created_date: `"${pl.created}"`,
      updated_date: `"${pl.updated}"`,
      impression_tracker: `"${pl.trackers.impression_url}"`,
      click_tracker: `"${pl.trackers.click_url}"`,
    }));
    return placementData;
  });
