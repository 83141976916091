import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { compact, map, capitalize } from 'lodash';
import { Sidebar } from '@sixsense/core/components';
import FlagContext, { useFlags } from 'contexts/FlagContext';
import UserContext from 'contexts/UserContext';
import {
  actions as orchestrationListActions,
} from 'routes/Orchestrations/routes/ListOrchestration/modules';
import styles from './SideNav.module.scss';
import { Permission, usePermissionCheck } from '@sixsense/rbac';
import {
  ADS_LIBRARY_PERMISSIONS,
  CAMPAIGN_PERMISSIONS,
  ORCHESTRATIONS_PERMISSIONS,
  VIEW_ALERT_PERMISSIONS,
  VIEW_DASHBOARD_PERMISSIONS,
  VIEW_REPORTS_PERMISSIONS,
  VIEW_REPORTS_6QA_PERMISSIONS,
  VIEW_SEGMENT_PERMISSIONS,
  SMART_FORM_FILL_PERMISSIONS,
  AUDIENCE_WORKFLOWS_PERMISSION,
} from '../../constants/featurePermissions';
import { ADVERTISING_BASE_ROUTE } from '../../routes/AdvertisingDS/constants';
import { browserHistory } from 'react-router';
import {
  CAMPAIGN_LIST_ROUTE,
} from 'routes/Advertising/routes/Campaigns/routes/ListCampaigns/constants';
import {
  AD_LIST_BASE_ROUTE,
} from 'routes/Advertising/routes/AdLibrary/routes/AdPlacementList/constants';
import {
  isSmartFormFillEnabledSelector,
  isAudienceBetaOrGaCustomerSelector,
  packagesSelector,
  orgPropertiesSelector,
} from 'modules/user/selectors';
import { useSelector } from '@sixsense/core/versioned/react-redux';
import { abmAlertsEnabledSelector } from 'routes/Alerts/selectors';
import {
  TrendUp,
  LayersThree,
  Announcement,
  Lightbulb,
  NotificationText,
  Speedometer,
  Dataflow,
  FileCheck,
  NavAudienceWorkflow,
} from '@sixsense/core/icons';
import { Link } from 'v2-components';
import {
  CAMPAIGN_REPORTS_ROUTE,
} from 'routes/AdvertisingDS/routes/Campaigns/routes/Reports/constants';
import { AUDIENCE_ROUTES } from 'routes/AudienceWorkflows/constants';
import { PERMITTED_USERS as SEGMENTS_V2_USERS } from 'routes/SegmentsAlpha/constants';
import { SegmentRoute } from '@sixsense/core/segments';
import { emitMultiAppEventToChildIframe, MULTI_APP_EVENT_NAME } from 'utils/multiAppEventUtils';
import { getAppUrlWithOrgName } from 'routes/Settings/routes/Manage/utils';
import { showNewVersionDashboardStorage } from 'commonLocalStorage';
import { HOMEPAGE_DASHBOARD_ROUTE } from 'routes/HomePageDashboard/constants';

const {
  AUDIENCE_WF,
  DASHBOARD,
  SEGMENTS,
  CAMPAIGNS,
  ALERTS,
  ORCHESTRATIONS,
  REPORTS,
  SMART_FORM_FILL,
} = {
  AUDIENCE_WF: NavAudienceWorkflow,
  DASHBOARD: TrendUp,
  SEGMENTS: LayersThree,
  CAMPAIGNS: Announcement,
  ALERTS: NotificationText,
  ORCHESTRATIONS: Lightbulb,
  REPORTS: Speedometer,
  SMART_FORM_FILL: FileCheck,
  WORKFLOWS: Dataflow,
};

export const SideNavComponent = (props) => {
  const {
    reportsLandingPageEnabled,
    has_new_segments_experience,
    has_raim_dashboard,
  } = useFlags();

  const {
    currentPath,
    refreshOrchestrationList,
    isSmartFormFillEnabled,
    hasPlatformPackage,
    user,
    hasAudienceWorkflowsEnabled,
    abmAlertsEnabled,
  } = props;

  const reportPermissions = reportsLandingPageEnabled ? [
    ...VIEW_REPORTS_PERMISSIONS,
    ...VIEW_REPORTS_6QA_PERMISSIONS,
    Permission.SETTINGS_ADMIN_REPORTS_VIEW,
  ] : [...VIEW_REPORTS_PERMISSIONS, ...VIEW_REPORTS_6QA_PERMISSIONS];

  const showCampaigns = usePermissionCheck(CAMPAIGN_PERMISSIONS);
  const showAdLibrary = usePermissionCheck(ADS_LIBRARY_PERMISSIONS);
  const showAdvertising = showCampaigns || showAdLibrary;
  const showDashboard = usePermissionCheck(VIEW_DASHBOARD_PERMISSIONS);
  const showSegments = usePermissionCheck(VIEW_SEGMENT_PERMISSIONS) && hasPlatformPackage;
  const showAlerts = usePermissionCheck(VIEW_ALERT_PERMISSIONS) && abmAlertsEnabled;
  const showReports = usePermissionCheck(reportPermissions);
  const showOrchestration = usePermissionCheck(ORCHESTRATIONS_PERMISSIONS);
  const hasWorkflowsPermissions = usePermissionCheck(AUDIENCE_WORKFLOWS_PERMISSION);
  const showSmartFormFill =
  usePermissionCheck(SMART_FORM_FILL_PERMISSIONS) && isSmartFormFillEnabled;
  const showAudienceWF = hasAudienceWorkflowsEnabled && hasWorkflowsPermissions;

  const properties = useSelector(orgPropertiesSelector);
  const showSegmentsV2 = showSegments &&
    properties.segments_v2_migration_status !== 'complete' && (
    has_new_segments_experience
    || SEGMENTS_V2_USERS.includes(user?.username?.toLowerCase())
  );

  const showNewDashboard =
    has_raim_dashboard && showNewVersionDashboardStorage.get();

  const topMenuItems = [
    showDashboard
      ? {
        name: 'dashboard',
        icon: DASHBOARD,
        location: showNewDashboard
            ? `/${HOMEPAGE_DASHBOARD_ROUTE}`
            : '/discover',
      }
      : 0,
    showSegments ? { name: 'segments', icon: SEGMENTS, location: '/segments' } : 0,
    showSegmentsV2 ? {
      name: 'Segments',
      tooltip: 'Segments',
      icon: SEGMENTS,
      location: SegmentRoute.ROOT,
      releaseStatus: 'new',
    } : 0,
    showAdvertising ? {
      name: 'Advertising',
      icon: CAMPAIGNS,
      location: `/${ADVERTISING_BASE_ROUTE}`,
      onClick: (e) => {
        const pathname = browserHistory.getCurrentLocation().pathname;
        const isListPage = pathname.includes(CAMPAIGN_LIST_ROUTE) ||
          pathname.includes(AD_LIST_BASE_ROUTE);
        if (isListPage) {
          e.preventDefault();
        }
      },
    } : 0,
    showOrchestration
      ? {
        name: 'orchestration',
        icon: ORCHESTRATIONS,
        location: '/orchestrations',
        onClick: () => {
          const location = window.location.pathname;
          if (location.match('/orchestrations/manage')) {
            refreshOrchestrationList();
          }
        },
      }
      : 0,
    showAudienceWF
      ? {
        name: AUDIENCE_ROUTES.DEFAULT.TITLE,
        icon: AUDIENCE_WF,
        location: `/${AUDIENCE_ROUTES.DEFAULT.PATH}`,
        releaseStatus: 'beta',
        onClick: () => {
          // e.preventDefault();
          const endpoint = getAppUrlWithOrgName(window.process.env.WORKFLOWS_UI_ENDPOINT);
          emitMultiAppEventToChildIframe({
            multiAppEvent: MULTI_APP_EVENT_NAME.EMITTERS.ABM_TO_CHILD_LOAD_DEFAULT_PAGE,
          }, endpoint);
        },
      }
      : 0,
    showAlerts ?
      { name: 'alerts', icon: ALERTS, location: '/alerts' }
      :
      0,
    showReports ? { name: 'reports', icon: REPORTS, location: '/reports' } : 0,
    showSmartFormFill ?
    { name: 'smart form fill',
      icon: SMART_FORM_FILL,
      location: '/smartformfill' }
    :
    0,
  ];


  const isSelected = ({ location }) => {
    if (currentPath.includes('account')) return false;
    if (currentPath.includes('settings/report') && location !== '/settings') return false;
    if (location === '/campaigns') {
      return currentPath.includes(location);
    }
    if (location === '/segments' && currentPath.includes(SegmentRoute.ROOT)) {
      return false;
    }
    if (
      currentPath.includes(`/${ADVERTISING_BASE_ROUTE}/${CAMPAIGN_REPORTS_ROUTE}`) &&
      location !== `/${ADVERTISING_BASE_ROUTE}`) return false;
    return currentPath.includes(location);
  };

  const setName = (path) =>
    path
      .split(' ')
      .map((word) => capitalize(word))
      .join(' ');

  return (

    <Sidebar zIndex={100}>
      <Sidebar.Header />
      <Sidebar.Body className={styles.sidebarBody}>
        {map(compact(topMenuItems), ({
            isHighlightExempted,
            name,
            icon,
            releaseStatus,
            onClick,
            location,
            tooltip,
            isInternal = true,
            newWindow,
          }) => (
            <Link
              link={location}
              isInternal={isInternal}
              onClick={onClick}
              newWindow={newWindow}
            >
              <Sidebar.Item
                active={isSelected({ location }) && !isHighlightExempted}
                icon={icon}
                title={tooltip || setName(name)}
                releaseStatus={releaseStatus}
                usePx
              />
            </Link>
          ))}
      </Sidebar.Body>
    </Sidebar>

  );
};

SideNavComponent.propTypes = {
  currentPath: PropTypes.string,
  refreshOrchestrationList: PropTypes.func,
  isSmartFormFillEnabled: PropTypes.bool,
  hasPlatformPackage: PropTypes.bool,
  user: PropTypes.any,
  hasAudienceWorkflowsEnabled: PropTypes.bool,
  abmAlertsEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isSmartFormFillEnabled: isSmartFormFillEnabledSelector(state),
  hasPlatformPackage: packagesSelector(state).includes('platform'),
  hasAudienceWorkflowsEnabled: isAudienceBetaOrGaCustomerSelector(state),
  abmAlertsEnabled: abmAlertsEnabledSelector(state),
});

const { refresh } = orchestrationListActions;

export const SideNav = compose(
  FlagContext.FlagConsumer,
  UserContext.UserConsumer,
  connect(mapStateToProps, { refreshOrchestrationList: refresh })
)(SideNavComponent);
