import React from 'react';
import { Loading, Flex, Card, ResultBoundary } from '@sixsense/core/components';
import { useKeywordRequest, useKeywordAccountsRequest } from '../hooks';
import { KeywordFilters, KeywordTable, NoDataComponent } from '../components';
import { keywordsConfigState } from '../state';
import { maybePlural } from 'utils/utils';
import { ResultStatus } from '@sixsense/core/shared/async-result/types';
import { useSharedState, useSharedValue } from '@sixsense/core';
import { KEYWORDS, INTENT_TABLE_HEADERS, NO_MATCH, NO_KEYWORDS } from '../constants';
import { Text } from 'v2-components';
import { css } from 'styles/emotion';

type Props = {
  keywordList: any;
};

const styles = {
  error: css({
    margin: '10px 10px 0 0',
  }),
};

const ErrorDisplay = () => (
  <Flex style={{ justifyContent: 'center', paddingTop: '200px' }}>
    <Text type={Text.TYPE.TITLE} className={styles.error}>
      Unable to load keywords. Please refresh this page or try again later.
    </Text>
  </Flex>
);

const TableDataContainer = ({ keywordList }: Props) => {
  const [config, setConfig] = useSharedState(keywordsConfigState);
  const keywordAccountsResult = useKeywordAccountsRequest(keywordList, config.spanFilter);

  return (
    <ResultBoundary
      result={keywordAccountsResult}
      renderLoading={() => <Loading.Spinner level="page" />}
      renderError={() => <ErrorDisplay />}
    >
      {({ data }) => (
        <Card.Content
          style={{
            background: 'white',
            border: '1px solid #C6CACE',
            padding: '0px',
          }}
        >
          {keywordList.results ? (
            <KeywordTable
              fromAddToGroup={false}
              requestData={(pageNumber, pageSize) =>
                setConfig({ ...config, pagination: { pageSize, pageNumber }, selectedValues: [] })}
              totalDataCount={keywordList.count}
              pageSize={config.pagination.pageSize}
              pageOptions={[25, 50, 75, 100]}
              currentPage={config.pagination.pageNumber}
              dataset={keywordList.results || []}
              keywordAccounts={data}
              pageKey={KEYWORDS}
              headers={INTENT_TABLE_HEADERS}
            />
          ) :
            <NoDataComponent
              filters={config.filters}
              noDataStr={NO_KEYWORDS}
              noMatchStr={NO_MATCH}
            />
          }
        </Card.Content>
      )}
    </ResultBoundary>
  );
};

const IntentKeywordsContainer = () => {
  const config = useSharedValue(keywordsConfigState);
  const keywordsResult = useKeywordRequest(config.filters, config.pagination, config.counter);

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <KeywordFilters
        label={`${keywordsResult.data?.count || 0}
          ${maybePlural(keywordsResult.data?.count || 0, 'Keyword')}`}
        multiLabel={`${config.selectedValues.length} Keywords selected`}
        disabled={keywordsResult.status === ResultStatus.LOADING}
        allKeywords={keywordsResult.data?.results || []}
        keywordsCount={keywordsResult.data?.count || 0}
        pageKey={KEYWORDS}
      />
      <ResultBoundary
        result={keywordsResult}
        renderLoading={() => <Loading.Spinner level="page" />}
        renderError={() => <ErrorDisplay />}
      >
        {({ data }) => <TableDataContainer keywordList={data} />}
      </ResultBoundary>
    </Flex>
  );
};

export default IntentKeywordsContainer;
