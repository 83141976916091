import React from 'react';
import PropTypes from 'prop-types';
import { reduce, keys, first, get, orderBy, sortBy } from 'lodash';
import { KeyPoint, Row } from 'v2-components';
import styles from './ActivityFooter.module.scss';
import { safePercentfp, coerceLocaleString } from 'utils/utils';
import { nameMap, tabOptions, statFields, CRM_CATEGORIES, MAP_CATEGORIES } from './constants';
import { setInitialFilter, navigateToKeywords } from './utils';
import { useFlags } from 'contexts/FlagContext';
import {
  SegmentRoute,
  createFilterset,
  createWebActivityCountFilter,
  createDisplayCampaignImpressionsFilter,
  createExternalCampaignImpressionsFilter,
  createTotalKeywordsResearchedFilter,
} from '@sixsense/core/segments';
import { withRouter } from 'react-router';
import { spanSelector } from 'routes/Discover/routes/Dashboard/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';

const StatsFooter = (props) => {
  const {
    category,
    stats,
    clickable,
    labelType,
    customAllField,
    onClick,
    router,
    span,
  } = props;

  const { has_new_segments_experience } = useFlags();

  const field = statFields[category];
  const { event, campaign, keyword, tpm } = stats;
  const allFields = customAllField || [
    { ...nameMap.activity_account_count,
      name: `Website${labelType}`,
      value: event && event.activity_account_count,
    },
    { ...nameMap.keyword_account_count,
      name: `B2B Network${labelType}`,
      value: keyword && keyword.keyword_account_count,
    },
    { ...nameMap.reach_account_count,
      name: `6sense Media${labelType || ' Campaigns'}`,
      value: campaign && campaign.reach_account_count,
    },
    { ...nameMap.reach_account_count,
      name: `External Media${labelType || ' Campaigns'}`,
      value: tpm && tpm.reach_account_count,
    },
  ];

  const isCRM = CRM_CATEGORIES.has(category);
  const isMAP = MAP_CATEGORIES.has(category);

  const getName = (name) => {
    if (isCRM) {
      return `${name} by Sales`;
    }
    if (isMAP) {
      return `${name} by Marketing`;
    }
    return name;
  };


  let keyPoints = field === tabOptions.all
    ? allFields
    : sortBy(reduce(stats[field], (reduced, value, key) => {
      const valid = nameMap[key];
      if (valid) {
        reduced.push({
          ...valid,
          value,
          isKeyword: valid.keyword,
          link: isCRM || isMAP ? false : valid.link,
          name: getName(valid.name),
        });
      }

      return reduced;
    }, []), 'order');

  if (category !== 'all_activity_count') {
    keyPoints = orderBy(keyPoints, ['order']);
  }

  const handleClick = (filter, isKeyword) => {
    if (isKeyword) {
      navigateToKeywords(filter);
    } else {
      let colName = first(keys(filter));
      const { operator, value } = first(get(filter, [colName, 'conditions']));
      if (colName === 'activity_count') {
        colName = 'web_activity_count';
      }
      onClick();
      if (has_new_segments_experience) {
        let filters = [];

        switch (colName) {
          case 'web_activity_count':
            filters = [createWebActivityCountFilter({ span: span.timeWindow })];
            break;
          case 'fpm_impression_count':
            filters = [createDisplayCampaignImpressionsFilter({ span: span.timeWindow })];
            break;
          case 'tpm_impression_count':
            filters = [createExternalCampaignImpressionsFilter({ span: span.timeWindow })];
            break;
          case 'keyword_count':
            filters = [createTotalKeywordsResearchedFilter({ span: span.timeWindow })];
            break;
          default:
            break;
        }

        router.push(SegmentRoute.CREATE({
          initialFilterset: createFilterset({ filters }),
          tab: 'accounts',
        }));
      } else {
        setInitialFilter(colName, operator, value, { isInt: true });
      }

    }
  };

  return (
    <Row className={styles.border} justifyContent={Row.JUSTIFY_CONTENT.SPACE_AROUND}>
      {
        keyPoints.map(({ name, value = 0, percent, link, filter, keyword: isKeyword }) => (
          <KeyPoint
            onClick={link && clickable ? () => {
              let filterValue = filter;
              if (Array.isArray(filter)) {
                filterValue = field === 'tpm' || name === 'External Media Accounts'
                  ? filter[1]
                  : filter[0];
              }
              return handleClick(filterValue, isKeyword);
            } : null}
            id={name} key={name}
            type={KeyPoint.STATS}
            label={name}
            link={link && clickable}
          >
            {percent ? `${safePercentfp(2)([value, 1])}%` : coerceLocaleString(value)}
          </KeyPoint>
        ))
      }
    </Row>
  );
};

StatsFooter.propTypes = {
  stats: PropTypes.object,
  category: PropTypes.string,
  clickable: PropTypes.bool,
  labelType: PropTypes.string,
  customAllField: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  router: PropTypes.object,
  span: PropTypes.object,
};

const mapStateToProps = (state) => ({
  span: spanSelector(state),
});

StatsFooter.defaultProps = {
  labelType: '',
  stats: {
    event: 0,
    campaign: 0,
    keyword: 0,
    tp: 0,
  },
};

export default withRouter(compose(connect(
  mapStateToProps,
))(StatsFooter));
