import { toaster } from '@sixsense/core';
import { get, includes } from 'lodash';
import { routePathnameSelector } from 'modules/global/selectors';
import { orgSelector } from 'modules/user/selectors';
import { createSelector } from 'reselect';
import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';
import { PRESET_STRING } from './constants';
import { getDateRanges } from './utils';

const taxonomySelector = (state) => state.taxonomyV2;

const mappedActivitiesEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `turbotax/taxonomy/org/${orgId}/mapping_fields/`
);

export const loadMappedActivitesStateGenerator = stateGenerator({
  // taxonomySelector, doesn't seem to work with selector
  baseStateSelector: (state) => state.taxonomyV2,
  actionPrefix: `${PRESET_STRING}/MAPPED_ACTIVITIES`,
  endpointSelector: mappedActivitiesEndpointSelector,
  stateKey: 'loadMappedActivites',
  onSuccessActions: [],
  onFailureActions: [
    () =>
      toaster.showError(
        'error occcured while loading mapped activity data, please try again'
      ),
  ],
  method: 'GET',
});

// REFACTOR THIS TO MAKE IT COMMON
export const dateRangeSelector = (state) =>
  state.taxonomyV2.common.dateRangeOption.dateRange;
// doesn't seem to work with selector
// createSelector(taxonomySelector, (state) => state.common.dateRangeOption.dateRange);

export const getStartAndEndDateSelector = createSelector(
  dateRangeSelector,
  (dateRange) => getDateRanges(dateRange)
);

export const loadRecordsReviewStatusEndpointSelector = createSelector(
  orgSelector,
  getStartAndEndDateSelector,
  routePathnameSelector,
  (orgId, dateRanges, path) =>
    dateRanges && !includes(path, 'preferences')
      ? `turbotax/taxonomy/org/${orgId}/` +
        'turbotax_records_review_status_count/?' +
        `start_date=${dateRanges.from}&end_date=${dateRanges.to}`
      : `turbotax/taxonomy/org/${orgId}/turbotax_records_review_status_count/`
);

export const loadRecordsReviewStatusCountGenerator = stateGenerator({
  baseStateSelector: taxonomySelector,
  actionPrefix: `${PRESET_STRING}/RECORDS_REVIEW_STATUS`,
  endpointSelector: loadRecordsReviewStatusEndpointSelector,
  stateKey: 'recordsReviewStatus',
  initialData: [],
  onSuccessActions: [],
  onFailureActions: [
    () =>
      toaster.showError(
        'error occcured while loading review status data, please try again'
      ),
  ],
  method: 'GET',
});

const loadProductTagMappingEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `turbotax/taxonomy/org/${orgId}/product_tag_mapping/`
);

export const INITIALIZE_URLS = `${PRESET_STRING}/INITIALIZE_URLS`;

function initializeURLs() {
  return {
    type: INITIALIZE_URLS,
  };
}

export const loadProductTagMappingGenerator = stateGenerator({
  baseStateSelector: taxonomySelector,
  actionPrefix: `${PRESET_STRING}/PRODUCT_TAG_MAPPING`,
  endpointSelector: loadProductTagMappingEndpointSelector,
  stateKey: 'productTagMapping',
  // receivedDataTransform: () => cannedData,
  initialData: [],
  onSuccessActions: [() => initializeURLs()],
  onFailureActions: [],
  method: 'GET',
  // attachReducer: saveThresholdCoverageReducer,
});

const loadUserEndpointSelector = createSelector(
  orgSelector,
  (orgId) =>
    `auth/internal/v3/organizations/ext/${orgId}/organizationusers/?limit=1000`
);

export const loadUsersGenerator = stateGenerator({
  baseStateSelector: taxonomySelector,
  actionPrefix: `${PRESET_STRING}/TAXONOMY_USERS`,
  endpointSelector: loadUserEndpointSelector,
  stateKey: 'taxonomyUsers',
  receivedDataTransform: ({ results }) =>
    results.map((item) => {
      const user = get(item, 'user.email') || get(item, 'user.username');
      return {
        value: user,
        label: user,
      };
    }),
  initialData: [],
  onSuccessActions: [],
  onFailureActions: [],
  method: 'GET',
});
