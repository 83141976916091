export const AUDIENCE_ROUTES = {
  DEFAULT: {
    PATH: 'workflows',
    TITLE: 'Workflows',
  },
};

export const WORKFLOWSUI_ENDPOINT = window.process.env.WORKFLOWS_UI_ENDPOINT;
// export const WORKFLOWSUI_ENDPOINT = 'http://marketotest.localhost.com:8002/';
export const AUDIENCE_WF_DEFAULT_PATH = 'listPlay/AUDIENCE';
export const AUDIENCE_WF_REPORT_PATH = `/workflows?activePath=${
  encodeURIComponent('/workflows-reports/credits/AUDIENCE?dateRange=LAST_THREE_MONTHS')}`;
