import {
  Button,
  ButtonGroup,
  Flex,
  Skeleton,
  Text,
  Tooltip,
} from '@sixsense/core/components';
import { ChevronDown, ChevronUp, CornerDownRight } from '@sixsense/core/icons';
import { DesignSystemColor } from '@sixsense/core/style';
import { differenceBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useReactFlow } from 'reactflow';
import { CRM, CRM_EVENT, CRM_TASK, MAP, NODE_POSITION_BASED_ON_ID } from '../../../constants';
import {
  countByMappingBaseSelector,
  mappedActivitiesSelector,
} from '../../../selectors';
import NodeContainer from './NodeContainer';

function getYPos(y, i) {
  if (i > 1) {
    return y + (110 * (i + 2));
  } else if (i === 1) {
    return y + 325;
  }
  return y + 200;
}

function getTitle(data) {
  const { taxonomyType, dataSource } = data;
  if (taxonomyType === 'product') {
    return (
      <Text.Body
        weight="semibold"
        color={DesignSystemColor.Gray.DARK_4}
      >All Products
    </Text.Body>
    );
  }
  if (dataSource === MAP || dataSource === CRM_EVENT || dataSource === CRM_TASK) {
    return (
      <Flex gap="4px">
        <Text.Body
          weight="semibold"
          color={DesignSystemColor.Gray.DARK_4}
        >All Action
    </Text.Body>
        <CornerDownRight color={DesignSystemColor.Gray.DEFAULT} />
        <Text.Body
          weight="semibold"
          color={DesignSystemColor.Gray.DARK_4}
        >All Channel
    </Text.Body>
      </Flex>
    );
  }
  if (dataSource === CRM) {
    return (
      <Flex gap="4px">
        <Text.Body
          weight="semibold"
          color={DesignSystemColor.Gray.DARK_4}
        >All Action
    </Text.Body>&nbsp;
        <CornerDownRight color={DesignSystemColor.Gray.DEFAULT} />&nbsp;
        <Text.Body
          weight="semibold"
          color={DesignSystemColor.Gray.DARK_4}
        >All Channel
    </Text.Body>&nbsp;
        <CornerDownRight color={DesignSystemColor.Gray.DEFAULT} />&nbsp;
        <Text.Body
          weight="semibold"
          color={DesignSystemColor.Gray.DARK_4}
        >All Type
    </Text.Body>
      </Flex>
    );
  }
  return (
    <Text.Body
      weight="semibold"
      color={DesignSystemColor.Gray.DARK_4}
    >All Activities
  </Text.Body>
  );
}


// we have to get the child nodes data via redux because I'm not sure
// about the feasibility of prop passing
const MappedParentNodeComponent = ({
  data,
  isConnectable,
  mappedActivities,
  loading,
  ...props }) => {

  const [isExpanded, setExpanded] = useState(true);
  const [prevNodes, setPrevNodes] = useState([]);
  const [childNodes, setChildNodes] = useState([]);
  const [filter, setFilter] = useState('all');
  const reactFlow = useReactFlow();
  const { setNodes } = reactFlow;

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const currentDataSource = usePrevious(data.dataSource);

  useEffect(() => {
    if (currentDataSource !== data.dataSource) {
      setFilter('all');
      setExpanded(true);
    }
  }, [data.dataSource, currentDataSource]);

  useEffect(() => {
    setChildNodes(mappedActivities.modifiedNodes);
    setPrevNodes(mappedActivities.modifiedNodes);
  }, [mappedActivities]);

  const { usedInModels, taxonomyType } = data;
  const activityCount = mappedActivities.total;
  const intentTotal = mappedActivities.intentTotal;
  const reachTotal = mappedActivities.reachTotal;
  // so that we avoid divide by 0
  const globalTotal = mappedActivities.globalTotal || 1;

  let currentTotal = activityCount;
  if (filter === 'Intent') {
    currentTotal = intentTotal;
  } else if (filter === 'Reach') {
    currentTotal = reachTotal;
  }

  const updatePos = useCallback(() => {
    // const { xPos, yPos, id } = props;
    // const position = { x: xPos, y: yPos };
    // const { nodes: newNodes } =
    //   createNodesAndEdges(childNodes, id, position, usedInModels, filter);
    const { x, y } = NODE_POSITION_BASED_ON_ID[props.id];
    let sourceNodes = childNodes;
    if (filter !== 'all') {
      sourceNodes = sourceNodes.filter((node) => node.data.subType === filter)
      .map((node, idx) => ({
        ...node,
        position: { x, y: getYPos(y, idx) },
      }));
    }
    if (!isExpanded) {
      setNodes((oldNodes) => [...differenceBy(oldNodes, prevNodes, 'id'), ...sourceNodes]);
      setPrevNodes(sourceNodes);
      setExpanded(true);
    } else {
      setNodes((oldNodes) => differenceBy(oldNodes, prevNodes, 'id'));
      setExpanded(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNodes, isExpanded, usedInModels, filter, childNodes]);

  const changeFilter = useCallback((filterVal) => {
    // const { xPos, yPos, id } = props;
    // const position = { x: xPos, y: yPos };
    // const { nodes: newNodes } =
    //   createNodesAndEdges(childNodes, id, position, usedInModels, filterVal);
    // eslint-disable-next-line react/prop-types
    const { x, y } = NODE_POSITION_BASED_ON_ID[props.id];
    let sourceNodes = childNodes;
    if (filterVal !== 'all') {
      sourceNodes = sourceNodes.filter((node) => node.data.subType === filterVal)
      .map((node, idx) => ({
        ...node,
        position: { x, y: getYPos(y, idx) },
      }));
    }
    if (!isExpanded) {
      setExpanded(true);
    }
    setNodes((oldNodes) => {
      const diff = differenceBy(oldNodes, prevNodes, 'id');
      return [...diff, ...sourceNodes];
    });
    setPrevNodes(sourceNodes);
    setFilter(filterVal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNodes, usedInModels, setPrevNodes, prevNodes, childNodes]);


  return (
    <NodeContainer data={data} isConnectable={isConnectable}>
      <Flex
        direction="column"
        style={{
          margin: '0',
          width: '350px',
          borderRadius: '8px',
          background: '#FFFFFF',
          padding: '16px 12px 16px 12px',
          boxShadow: '0px 2px 4px -2px #10182514, 0px 4px 8px -2px #10182533',
          borderTop: `4px solid ${(usedInModels || taxonomyType === 'product') ?
                    DesignSystemColor.Green.DEFAULT : DesignSystemColor.Gray.DEFAULT}`,
        }}
      >
        {getTitle(data)}
        <Flex className="aam-t--5">
          {usedInModels ? (<Text.Body
            size="sm"
            color={DesignSystemColor.Gray.DARK_4}
          >
            Used In Predictive Models
            </Text.Body>) :
                (<Text.Body
                  size="sm"
                  color={DesignSystemColor.Gray.DARK_4}
                >
                Not Used In Predictive Models
                </Text.Body>)
              }
        </Flex>
        <Flex className="aam-t--2">
          {loading ? (
            <Skeleton style={{ height: 15, width: 100 }} variant="rectangular" />
              ) : (
                <React.Fragment>
                  { !usedInModels ? (<Text.RegularText>{
                    Intl.NumberFormat('en-US').format(activityCount)}</Text.RegularText>)
                    : (
                      <Text.RegularText>
                        {Intl.NumberFormat('en-US').format(currentTotal)}
                      </Text.RegularText>
                    )
                    }
                  {taxonomyType === 'event' && (<Text.RegularText>&nbsp;
                    ({parseFloat((currentTotal/ globalTotal) * 100).toFixed(2)})%
                    </Text.RegularText>)}
                </React.Fragment>
              )}
        </Flex>
        <Flex className="aam-v--5">
          <Flex style={{ visibility: usedInModels ? 'visible' : 'hidden' }}>
            <ButtonGroup
              value={filter}
              onChange={changeFilter}
            >
              <Tooltip
                overlay={
                  <Flex direction="column" className="p2">
                    <Text.RegularText>All</Text.RegularText>
                    <Text.RegularText>
                      {Intl.NumberFormat('en-US').format(activityCount)}&nbsp;
                          ({parseFloat((activityCount/ globalTotal) * 100).toFixed(2)}%)
                    </Text.RegularText>
                  </Flex>
                    }
                placement="top"
              >
                <ButtonGroup.Button value="all">All</ButtonGroup.Button>
              </Tooltip>
              <Tooltip
                overlay={
                  <Flex direction="column" className="p2">
                    <Text.RegularText>Intent Model</Text.RegularText>
                    <Text.RegularText>
                      {Intl.NumberFormat('en-US').format(intentTotal)}&nbsp;
                          ({parseFloat((intentTotal/ globalTotal) * 100).toFixed(2)}%)
                    </Text.RegularText>
                  </Flex>
                    }
                placement="top"
              >
                <ButtonGroup.Button value="Intent">Intent</ButtonGroup.Button>
              </Tooltip>
              <Tooltip
                overlay={
                  <Flex direction="column" className="p2">
                    <Text.RegularText>Reach Model</Text.RegularText>
                    <Text.RegularText>
                      {Intl.NumberFormat('en-US').format(reachTotal)}&nbsp;
                          ({parseFloat((reachTotal/ globalTotal) * 100).toFixed(2)}%)
                      </Text.RegularText>
                  </Flex>
                  }
                placement="top"
              >
                <ButtonGroup.Button value="Reach">Reach</ButtonGroup.Button>
              </Tooltip>
            </ButtonGroup>
          </Flex>
        </Flex>
        <Flex className="mt2">
          { currentTotal !== 0 && (<Button
            size="sm"
            hierarchy="link-secondary"
            trailingIcon={isExpanded ? ChevronUp : ChevronDown}
            onClick={updatePos}
          >
            {isExpanded ? 'Show Less' : 'Show More'}
          </Button>)}
        </Flex>
      </Flex>
    </NodeContainer>
  );
};

MappedParentNodeComponent.propTypes = {
  data: PropTypes.any,
  isConnectable: PropTypes.bool,
  mappedActivities: PropTypes.array,
  loading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
  mappedActivities:
  mappedActivitiesSelector(
    ownProps.data.dataSource,
    ownProps.data.taxonomyType,
    ownProps.data.usedInModels,
    ownProps.id,
  )(state),
  loading: countByMappingBaseSelector(state).loading,
});

const MappedParentNode = connect(mapStateToProps, null)(MappedParentNodeComponent);


export default MappedParentNode;
