import { VALID_DATA_SOURCES } from '../../constants';
import { initialize as initializeForm } from 'redux-form';
import { isEmpty } from 'lodash';

export function initializeAssigneeForm(assignees) {
  const formData = VALID_DATA_SOURCES.reduce((acc, data_source) => {
    acc[data_source] = assignees
        .filter((assignee) => assignee.data_source === data_source)
        .map((assignee) => assignee.name);
    return acc;
  }, {});
  return initializeForm('form_assignee', formData);
}

export const validateThreshold = (threshold) => {
  if (typeof threshold !== 'number' && isEmpty(threshold)) {
    return 'threshold cannot be empty';
  } else if (threshold < 0.01) {
    return 'threshold cannot be less than 0.01';
  } else if (threshold > 0.1) {
    return 'threshold cannot be greater than 0.1';
  }
  return undefined;
};
