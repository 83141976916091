import React from 'react';
import styles from './CenterInParent.module.scss';

// The extra nested div servers a purpose! Its protection so we do not apply our flexbox
// direction to an underlying Col by mistake.
const CenterInParent = ({ fillParent = false }) => (WrappedComponent) =>
  (props) => (
    <div className={styles.center}>
      {fillParent ? <WrappedComponent {...props} /> : <div><WrappedComponent {...props} /></div>
      }
    </div>
  );

export default CenterInParent;
