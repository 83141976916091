export default function createRoutes() {
  return {
    path: 'usage',
    name: 'Usage',
    getComponent(_nextState, cb) {
      require.ensure(
        [],
        (require) => {
          // reducer and sagas are injected at the settings level
          const containers = require('./containers');
          cb(null, containers.UsageReports);
        },
        'usage_reports'
      );
    },
  };
}
