import React from 'react';
import PropTypes from 'prop-types';
import { Text, Row } from 'v2-components';
import { coerceLocaleString } from 'utils/utils';
import styles from './TotalCountLabel.module.scss';

const TotalCountLabel = ({ count, label }) => (
  <Row className={styles.countRow} justifyContent={Row.JUSTIFY_CONTENT.FLEX_END}>
    <Text className={styles.accountCount} bold weight={'bold'}>
      {coerceLocaleString(count)}
    </Text>
    <Text>{label}</Text>
  </Row>
);

TotalCountLabel.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TotalCountLabel;
