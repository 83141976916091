import { ModelMetricsRedirect } from './ModelMetricsRedirect';

export default function createRoutes() {
  return {
    path: 'model',
    name: 'Model',
    getComponent(_nextState, cb) {
      require.ensure(
        [],
        () => {
          // reducer and sagas are injected at the settings level
          cb(null, ModelMetricsRedirect);
        },
        'model_reports'
      );
    },
  };
}
