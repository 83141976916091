import { combineReducers } from 'redux';
import { PRESET_STRING } from '../../../constants';
import { loaderPresets } from '../../../utils';
import {
    loadCoverageThresholdGenerator,
    loadAssigneeGenerator,
} from '../stateGenerators';
import { createReducerUtil } from 'utils/core';


const SAVE_THRESHOLD_COVERAGE_REQUEST = `${PRESET_STRING}/SAVE_THRESHOLD_COVERAGE_REQUEST`;
const SAVE_THRESHOLD_COVERAGE_SUCCESS = `${PRESET_STRING}/SAVE_THRESHOLD_COVERAGE_SUCCESS`;
const SAVE_THRESHOLD_COVERAGE_FAILURE = `${PRESET_STRING}/SAVE_THRESHOLD_COVERAGE_FAILURE`;

function saveThresholdCoverage() {
  return {
    ...loaderPresets,
    type: SAVE_THRESHOLD_COVERAGE_REQUEST,
    loading: true,
  };
}
function saveThresholdCoverageSuccess(coverage) {
  return {
    type: SAVE_THRESHOLD_COVERAGE_SUCCESS,
    loading: false,
    loaded: true,
    coverage,
  };
}
function saveThresholdCoverageFailure(errMessage, errStatus) {
  return {
    type: SAVE_THRESHOLD_COVERAGE_FAILURE,
    loading: false,
    error: true,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}

export const CHANGE_ADMIN = `${PRESET_STRING}/CHANGE_ADMIN`;

export function changeAdminAssignee(admin) {
  return {
    type: CHANGE_ADMIN,
    admin,
  };
}

export const SAVE_ADMIN = `${PRESET_STRING}/SAVE_ADMIN`;
export const SAVE_ASSIGNEES = `${PRESET_STRING}/SAVE_ASSIGNEES`;
export const SAVE_ASSIGNEE_SUCCESS = `${PRESET_STRING}/SAVE_ASSIGNEE_SUCCESS`;
export const SAVE_ASSIGNEE_FAILURE = `${PRESET_STRING}/SAVE_ASSIGNEE_FAILURE`;

export function saveAdminAssignee(admin) {
  return {
    type: SAVE_ADMIN,
    admin,
  };
}

export function saveAssignees(assignees) {
  return {
    type: SAVE_ASSIGNEES,
    assignees,
  };
}

export function saveAssigneeSuccess(assigees) {
  return {
    type: SAVE_ASSIGNEE_SUCCESS,
    assigees,
  };
}

export function saveAssigneeFailure(error) {
  return {
    type: SAVE_ASSIGNEE_FAILURE,
    error,
  };
}

const adminReducer = createReducerUtil('', {
  [CHANGE_ADMIN]: (_, action) => action.admin,
});

export const SET_COVERAGE_THRESHOLD_FORM_VALUES =
`${PRESET_STRING}/SET_COVERAGE_THRESHOLD_FORM_VALUES`;

export function setCoverageThresholdFormValues(data) {
  return {
    type: SET_COVERAGE_THRESHOLD_FORM_VALUES,
    data,
  };
}

const coverageThresholdReducer = createReducerUtil([], {
  [SET_COVERAGE_THRESHOLD_FORM_VALUES]: (_, action) => action.data,
});

const saveCoverageThresholdLoadingReducer = createReducerUtil(false, {
  [SAVE_THRESHOLD_COVERAGE_REQUEST]: () => true,
  [SAVE_THRESHOLD_COVERAGE_FAILURE]: () => false,
  [SAVE_THRESHOLD_COVERAGE_SUCCESS]: () => false,
});

const saveAssigneeLoadingReducer = createReducerUtil(false, {
  [SAVE_ADMIN]: () => true,
  [SAVE_ASSIGNEES]: () => true,
  [SAVE_ASSIGNEE_FAILURE]: () => false,
  [SAVE_ASSIGNEE_SUCCESS]: () => false,
});


export const preferencesReducer = combineReducers({
  [loadCoverageThresholdGenerator.stateKey]: loadCoverageThresholdGenerator.reducer,
  [loadAssigneeGenerator.stateKey]: loadAssigneeGenerator.reducer,
  admin: adminReducer,
  coverageThresholdForm: coverageThresholdReducer,
  saveCoverageThresholdLoading: saveCoverageThresholdLoadingReducer,
  saveAssigneeLoading: saveAssigneeLoadingReducer,
});

export const ON_CANCEL = `${PRESET_STRING}/ON_CANCEL`;

export function onCancel() {
  return {
    type: ON_CANCEL,
  };
}

export const actions = {
  saveThresholdCoverage,
  saveThresholdCoverageFailure,
  saveThresholdCoverageSuccess,
  changeAdminAssignee,
  saveAdminAssignee,
  saveAssignees,
  saveAssigneeFailure,
  saveAssigneeSuccess,
  onCancel,
  setCoverageThresholdFormValues,
};

export const actionTypes = {
  SAVE_THRESHOLD_COVERAGE_REQUEST,
  SAVE_THRESHOLD_COVERAGE_FAILURE,
  SAVE_THRESHOLD_COVERAGE_SUCCESS,
  SAVE_ADMIN,
  SAVE_ASSIGNEES,
  SAVE_ASSIGNEE_FAILURE,
  SAVE_ASSIGNEE_SUCCESS,
  ON_CANCEL,
  SET_COVERAGE_THRESHOLD_FORM_VALUES,
};

