import React, { useState } from 'react';
import { css } from 'styles/emotion';
import { Link, Modal, Row, Text } from 'v2-components';

const styles = {
  showDashboardLink: css({
    display: 'flex',
    margin: '20px 0 10px 0',
  }),
  sampleLink: css({
  }),
  linkToDashboardsImg: css({
    width: '100%',
  }),
  title: css({
    padding: '10px',
    marginBottom: '27px',
  }),
};

type Props = {
  content: any
}

const ExampleModal = ({
  content,
}: Props) => {
  const { value, examples = [], exampleModalHeading } = content;
  const [showSampleLinkModal, changeShowSampleLinkModal] = useState({});
  return (
    <span>
      <Link
        className={styles.sampleLink}
        onClick={
          () => changeShowSampleLinkModal({
            [value]: true,
          })
        }
      >Example</Link>
      <Modal
        key={value}
        visible={showSampleLinkModal[value]}
        title={<Text weight="bold">{exampleModalHeading}</Text>}
        width={750}
        onCancel={() => changeShowSampleLinkModal({
          [value]: false,
        })}
      >
        {examples.map((el) => <span key={el.key}>
          <img
            src={el.image}
            key={el.key}
            alt={el.title}
            className={styles.linkToDashboardsImg}
          />
          {el.title ? <Row
            justifyContent="center"
            alignItems="center"
            className={styles.title}
          >
            <Text>{el.title} </Text> </Row> : null}
        </span>
        )}
      </Modal>
    </span>
  );
};

export default ExampleModal;
