import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RuntimeConnect } from 'HOCS';
import { astSelector } from './selectors';
import { actions } from 'ast-redux';

export const AstConnect = (WrappedComponent) => {
  class AstConnectClass extends Component {
    constructor(props, context) {
      super(props, context);
      if (!context._astRedux) {
        throw new Error(
          'AstConnect must be used under an Ast',
        );
      }
    }

    render() {
      const { astKey } = this.props;
      return (
        <WrappedComponent
          {...this.props}
          breadcrumbs={[astKey, 'ast']}
        />
      );
    }
  }

  AstConnectClass.contextTypes = {
    _astRedux: PropTypes.object,
  };

  AstConnectClass.propTypes = {
    astKey: PropTypes.string,
  };

  const mapStateToMapStateToProps = (props, context) => (
    state,
  ) => {
    const { key } = context._astRedux;
    return {
      ast: astSelector(key)(state),
      astKey: key,
    };
  };

  return RuntimeConnect(
    mapStateToMapStateToProps,
    (props, context) => ({ replaceExpr: actions.replaceExpr(context._astRedux) }),
    undefined,
    undefined,
    AstConnectClass.contextTypes,
  )(AstConnectClass);
};
