import React from 'react';

/* eslint max-len: [2, 100000] */
export function WebsiteActivites(props) {
  return (
    <svg {...props} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <title>website-activities</title>
      <g transform="translate(16 126)" fill="none" fillRule="evenodd">
        <path
          d="M356.49 16.87L249.13 439.08H23.06c-7.88-.018-15.168 4.175-19.113 10.996-3.945 6.82-3.945 15.23 0 22.05 3.945 6.822 11.234 11.015 19.113 10.998h243.278c10.046.005 18.822-6.79 21.334-16.517l85.336-335.495 99.79 623.16c1.877 10.5 11.01 18.145 21.677 18.145 10.666 0 19.8-7.644 21.678-18.144l68.82-417.735 26.84 128.35c2.27 10.126 11.3 17.293 21.68 17.204l212.995 1.035c9.26 23.976 32.47 41.292 59.53 41.292 35.054 0 64-28.948 64-64.003s-28.946-63.66-64-63.66c-27.56 0-50.977 17.656-59.875 42.326l-194.758-.683-47.83-225.73c-2.114-10.467-11.434-17.91-22.11-17.656-10.678.254-19.633 8.132-21.247 18.69l-65.378 399.84-95.315-594.6C397.814 8.27 388.63.398 377.824.362c-11.334.872-19.05 7.484-21.335 16.51z"
          stroke="#B0BEC5"
          strokeWidth=".5"
          fill="#B0BEC5"
        />
        <circle fill="#109E96" cx="913.49" cy="466.074" r="78.179" />
      </g>
    </svg>
  );
}
