import {
  loadEventJobsCountStateGenerator,
  loadInitJobsStateGenerator,
  loadMoreJobsStateGenerator,
  loadProductJobsCountStateGenerator,
} from '../stateGenerators';

export const jobsSagas = [
  loadInitJobsStateGenerator.saga,
  loadMoreJobsStateGenerator.saga,
  loadEventJobsCountStateGenerator.saga,
  loadProductJobsCountStateGenerator.saga,
];
