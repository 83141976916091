import React, { Fragment } from 'react';
import {
  ENRICH_SEGMENTS,
  ENRICH,
  L2A,
  L2C,
  NET_NEW_ACCOUNTS,
  PURCHASE,
  PUSH_CONTACTS,
  WEB_ENGAGEMENT,
  CREATE_NEW_OPPORTUNITIES,
  EXPORT_SCORES,
  PUSH_ESI_CONTACTS,
  OF,
  SYSTEMS,
  SUPPORTED_DESTINATIONS,
  OBJECT_TYPES,
} from 'routes/Orchestrations/constants';

export const ENRICH_RECORDS = 'ENRICH_RECORDS';

export const ORCHESTRATION_FORM = 'orchestration_form';

export const DECIMAL_NUMBER_ERROR_MSG = 'Must be whole number';

export const PREVIEW_PAGE_SIZE = 10;

export const APPROVER_LIMIT = 10;

// eslint-disable-next-line max-len
export const APPROVERS_LIMIT_MESSAGE = `You can add up to ${APPROVER_LIMIT} people who can receive and approve requests`;

export const ORC_PUBLISH_SUCCESS = (<Fragment>
  <div>Orchestration published successfully.</div>
</Fragment>);

export const ORC_SAVE_SUCCESS = (<Fragment>
  <div>Orchestration saved successfully.</div>
  <div>
    The Preview is now being generated – once the page refreshes
    click on Preview to review the selected records.
  </div>
</Fragment>);

export const ORC_SAVE_SUCCESS_WO_PREVIEW = (<Fragment>
  <div>Orchestration saved successfully.</div>
</Fragment>);

export const SAVE_NOTIFICATION = {
  [EXPORT_SCORES]: ORC_SAVE_SUCCESS_WO_PREVIEW,
  [WEB_ENGAGEMENT]: ORC_PUBLISH_SUCCESS,
  [CREATE_NEW_OPPORTUNITIES]: ORC_PUBLISH_SUCCESS,
};

export const ORC_FREQUENCY_META = [
  {
    label: 'Only when initiated ####',
    value: 'manual',
    progressDisplay: 'On Demand (default)',
    labelDynamicKey: 'manually',
    tooltipText: `When this option is selected, the Orchestration will not run on a
    scheduled basis. Instead, it will only run when a user manually initiates a run by
    navigating to the Preview and selecting Run Now. That option to Run Now remains available
    even when the Orchestration is set to run automatically.`,
    tooltipPushText: `This Orchestration will not run on a recurring basis.
    It will only run and process the eligible records when you select Run Now.`,
    previewDisplay: 'On Demand',
    previewTooltip: `This Orchestration will not run on a recurring basis.
    It will only run and process the eligible records when you select Run Now.`,
    previewTooltipDK: 'Run Now.',
  },
  {
    label: 'Automatically ####',
    value: 'daily',
    progressDisplay: 'Runs every day',
    labelDynamicKey: 'every day',
    previewDisplay: 'Runs every day',
  },
  {
    label: 'Automatically ####',
    value: 'weekly',
    progressDisplay: 'Runs every {day}',
    labelDynamicKey: 'every week',
    previewDisplay: 'Runs every {day}',
  },
];

export const ORC_FREQUENCY_DAY_META = [
  { label: 'Day', value: '', disabled: true },
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
  { label: 'Sunday', value: 'sunday' },
];

export const ORC_TYPE_OPTIONS = [
  {
    label: '{action} #### {source} {extension}',
    value: 'ALL',
    labelDynamicKey: 'all',
    progressDisplay: 'All records (default)',
  },
  {
    label: '{action} {source} created in the #### {extension}',
    value: 'CREATED_LAST_WEEK',
    labelDynamicKey: 'last week',
    progressDisplay: 'Records created in last week',
  },
  {
    label: '{action} {source} created in the #### {extension}',
    value: 'CREATED_LAST_DAY',
    labelDynamicKey: 'last day',
    progressDisplay: 'Records created in last day',
  },
  {
    label: '{action} {source} {extension} within the ####',
    value: 'IN_SEGMENT',
    labelDynamicKey: '6sense segment',
    progressDisplay: 'Records from the Segment',
  },
];

export const FILTERS_META = [
  {
    key: 'limit_other',
    title: '',
    notSupportedType: [PURCHASE],
    filtersList: [
      {
        key: 'data_sources',
        pushKey: 'record_types',
        label: 'Limit to these ####',
        labelDynamicKey: 'SOURCES OF DATA:',
        labelPushDynamicKey: 'RECORD TYPES:',
        mode: 'multiple',
        dataKey: 'data_sources',
        dataPushKey: 'record_types',
        placeholderText: 'Select Source...',
        placeholderPushText: 'Select Record Type...',
      },
    ],
  },
];

export const PURCHASE_CONTACT_CHECKBOX_META = [
  {
    key: 'directdial',
    formKey: OF.PURCH_DIR_FLAG,
    label: 'Only purchase contacts with a ####',
    labelDynamicKey: 'DIRECT DIAL NUMBER',
  },
  {
    key: 'companyphone',
    formKey: OF.PURCH_COMPANY_PHONE_FLAG,
    label: 'Only purchase contacts with a ####',
    labelDynamicKey: 'COMPANY PHONE NUMBER',
  },
];

export const FMOverwriteMeta = [
  { error: null, id: 1, name: 'NEVER', overwrite: false },
  { error: null, id: 2, name: 'ALWAYS', overwrite: true },
  { error: null, id: 3, name: 'IF_VALUE_CHANGED', overwrite: true },
  { error: null, id: 4, name: 'IF_BLANK', overwrite: true },
];

export const ORC_TARGET_META = [
  {
    label: 'Salesforce Campaign',
    value: SUPPORTED_DESTINATIONS.SALESFORCE_CAMPAIGN,
    system: 'salesforce',
    actionTypeSupported: [PURCHASE, PUSH_CONTACTS],
  },
  {
    label: 'Salesforce Lead',
    value: SUPPORTED_DESTINATIONS.SALESFORCE_LEAD_NO_CAMPAIGN,
    actionTypeSupported: [PURCHASE],
  },
  {
    label: 'Salesforce Contact',
    value: SUPPORTED_DESTINATIONS.SALESFORCE_CONTACT_NO_CAMPAIGN,
    actionTypeSupported: [PURCHASE],
  },
  {
    label: 'Marketo Static List',
    value: SUPPORTED_DESTINATIONS.MARKETO_STATIC_LIST,
    system: 'marketo',
    actionTypeSupported: [PURCHASE, PUSH_CONTACTS],
  },
  {
    label: 'Hubspot Static List',
    value: SUPPORTED_DESTINATIONS.HUBSPOT_STATIC_LIST,
    system: 'hubspot',
    actionTypeSupported: [PURCHASE, PUSH_CONTACTS],
  },
  {
    label: 'Hubspot Static List',
    value: SUPPORTED_DESTINATIONS.HUBSPOTCRM_STATIC_LIST,
    system: 'hubspotcrm',
    actionTypeSupported: [PURCHASE, PUSH_CONTACTS],
  },
  {
    label: 'Eloqua List',
    value: SUPPORTED_DESTINATIONS.ELOQUA_LIST,
    system: 'eloqua',
    actionTypeSupported: [PURCHASE, PUSH_CONTACTS],
  },
  {
    label: 'Pardot List',
    value: SUPPORTED_DESTINATIONS.PARDOT_LIST,
    system: 'pardot',
    actionTypeSupported: [PURCHASE, PUSH_CONTACTS],
  },
  {
    label: '{mapname} Lead',
    value: '{mapsystem}_no_campaign',
    type: 'ma_nocampaign',
    actionTypeSupported: [PURCHASE],
  },
  {
    label: 'CSV File',
    value: SUPPORTED_DESTINATIONS.CSV,
    actionTypeSupported: [PURCHASE],
  },
  {
    label: 'Outreach Sequence',
    value: SUPPORTED_DESTINATIONS.OUTREACH_SEQUENCE,
    system: 'outreach',
    actionTypeSupported: [PURCHASE, PUSH_CONTACTS],
  },
  {
    label: 'Outreach Prospect',
    value: SUPPORTED_DESTINATIONS.OUTREACH_NO_SEQUENCE,
    system: 'outreach',
    actionTypeSupported: [PURCHASE, PUSH_CONTACTS],
  },
  {
    label: 'Conversational Email Campaign',
    value: SUPPORTED_DESTINATIONS.CONVERSATIONAL_EMAIL_CAMPAIGN,
    system: 'conversational_email',
    actionTypeSupported: [PURCHASE, PUSH_CONTACTS],
  },
  {
    label: 'Conversational Email Leads',
    value: SUPPORTED_DESTINATIONS.CONVERSATIONAL_EMAIL_NO_CAMPAIGN,
    system: 'conversational_email',
    actionTypeSupported: [PURCHASE, PUSH_CONTACTS],
  },
  {
    label: 'SalesLoft Cadence',
    value: SUPPORTED_DESTINATIONS.SALESLOFT_CADENCE,
    system: 'salesloft',
    actionTypeSupported: [PURCHASE, PUSH_CONTACTS],
  },
  {
    label: 'SalesLoft People',
    value: SUPPORTED_DESTINATIONS.SALESLOFT_NO_CADENCE,
    system: 'salesloft',
    actionTypeSupported: [PURCHASE, PUSH_CONTACTS],
  },
  {
    label: 'an MS Dynamics 365 Campaign',
    value: 'dynamics_campaign',
    system: 'dynamics',
    actionTypeSupported: [PURCHASE, PUSH_CONTACTS],
  },
];

export const ORC_ENV_CONFIG = [
  { id: 1, name: 'PRODUCTION' },
  { id: 2, name: 'SANDBOX' },
];

export {
  ENRICH_SEGMENTS,
  ENRICH,
  L2A,
  L2C,
  NET_NEW_ACCOUNTS,
  PURCHASE,
  PUSH_CONTACTS,
  WEB_ENGAGEMENT,
  CREATE_NEW_OPPORTUNITIES,
  EXPORT_SCORES,
  PUSH_ESI_CONTACTS,
};

export const APPROVAL_TYPES = {
  auto: { id: 1, name: 'auto' },
  manual: { id: 2, name: 'manual' },
};

export const exportObjectsMap = {
  [SYSTEMS.SALESFORCE]: [
    { label: 'Standard', value: false },
    { label: 'Custom', value: true },
  ],
  [SYSTEMS.DYNAMICS]: [
    { label: 'Standard', value: false },
    { label: 'Custom', value: true },
  ],
  [SYSTEMS.HUBSPOT_CRM]: [
    { label: 'Standard', value: false },
    { label: 'Custom', value: true },
  ],
};

// took this reference from fetchMappingsEndpointSelector
export const DESTINATION_OBJECT_MAP = {
  [SUPPORTED_DESTINATIONS.SALESFORCE_LEAD_NO_CAMPAIGN]:
    [`${SYSTEMS.SALESFORCE}_${OBJECT_TYPES.LEAD}`],
  [SUPPORTED_DESTINATIONS.SALESFORCE_CONTACT_NO_CAMPAIGN]:
    [`${SYSTEMS.SALESFORCE}_${OBJECT_TYPES.CONTACT}`],

  [SUPPORTED_DESTINATIONS.MARKETO_NO_CAMPAIGN]: [`${SYSTEMS.MARKETO}_${OBJECT_TYPES.LEAD}`],
  [SUPPORTED_DESTINATIONS.ELOQUA_NO_CAMPAIGN]: [`${SYSTEMS.ELOQUA}_${OBJECT_TYPES.LEAD}`],
  [SUPPORTED_DESTINATIONS.HUBSPOT_NO_CAMPAIGN]: [`${SYSTEMS.HUBSPOT}_${OBJECT_TYPES.LEAD}`],
  [SUPPORTED_DESTINATIONS.HUBSPOTCRM_NO_CAMPAIGN]:
    [`${SYSTEMS.HUBSPOT_CRM}_${OBJECT_TYPES.CONTACT}`],
  [SUPPORTED_DESTINATIONS.PARDOT_NO_CAMPAIGN]: [`${SYSTEMS.PARDOT}_${OBJECT_TYPES.LEAD}`],

  [SUPPORTED_DESTINATIONS.MARKETO_STATIC_LIST]: [`${SYSTEMS.MARKETO}_${OBJECT_TYPES.LEAD}`],
  [SUPPORTED_DESTINATIONS.HUBSPOT_STATIC_LIST]: [`${SYSTEMS.HUBSPOT}_${OBJECT_TYPES.LEAD}`],
  [SUPPORTED_DESTINATIONS.HUBSPOTCRM_STATIC_LIST]:
    [`${SYSTEMS.HUBSPOT_CRM}_${OBJECT_TYPES.CONTACT}`],
  [SUPPORTED_DESTINATIONS.ELOQUA_LIST]: [`${SYSTEMS.ELOQUA}_${OBJECT_TYPES.LEAD}`],
  [SUPPORTED_DESTINATIONS.PARDOT_LIST]: [`${SYSTEMS.PARDOT}_${OBJECT_TYPES.LEAD}`],
  [SUPPORTED_DESTINATIONS.CSV]: [],
  [SUPPORTED_DESTINATIONS.MSD_LEAD_NO_CAMPAIGN]: [`${SYSTEMS.DYNAMICS}_${OBJECT_TYPES.LEAD}`],
  [SUPPORTED_DESTINATIONS.MSD_CONTACT_NO_CAMPAIGN]: [`${SYSTEMS.DYNAMICS}_${OBJECT_TYPES.CONTACT}`],
};

// Get Preview Constants
export const PREVIEW_JOB_STATUS = {
  PREVIEW_GENERATED: 'PREVIEW_GENERATED',
  PREVIEW_GENERATION_FAILED: 'PREVIEW_GENERATION_FAILED',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
  RUN_SCHEDULED: 'RUN_SCHEDULED',
  RUNNING: 'RUNNING',
  PENDING_PREVIEW: 'PENDING_PREVIEW',
};

export const PREVIEW_FLOWS = {
  ORC_UPDATED: 'orcUpdated',
  ORC_UPDATED_WITH_EXIT: 'orcUpdatedWithExit',
};
// Get Preview Constants

export const FIELD_VALIDITY = {
  valid: 'valid',
  invalid: 'invalid',
  requiredValid: 'requiredValid',
  optionalValid: 'optionalValid',
};

export const PROFILE_FIT_OPTIONS = ['Strong', 'Moderate', 'Weak'];
export const FILTER_PANEL_CONTACT_PROFILE = '6sense Contact Profile';
export const FILTER_PANEL_TITLES = 'Titles';
export const FILTER_PANEL_GEOGRAPHY = 'Geography';
export const FILTER_PANEL_OTHER_OPTIONS = 'Other Options';
export const FILTER_PANEL_ACCOUNT = 'Account Filters';
// Component Id constants
export const ORC_HEADER_ID = 'orcHeader';
export const ORC_HEADER_NAME_CONTAINER_ID = 'orcNameContainer';
export const ORC_WIZARD_FOOTER_ID = 'orcWizardFooter';
export const APP_BODY_ID = 'appBody';

// Make sure these values matches with response of
// getCampaignTypeListMetaSelector
export const TACTIC_TYPE = {
  DISPLAY_CAMPAIGN: 'DISPLAY_CAMPAIGN',
  NURTURE: 'NURTURE',
  WEBINAR: 'WEBINAR',
  FIELD_EVENT: 'FIELD',
  DIRECT_MAIL: 'DIRECTMAIL',
};

export const TACTIC_META = {
  [TACTIC_TYPE.DISPLAY_CAMPAIGN]: {
    error: null,
    id: 1,
    type: 'DISPLAY_CAMPAIGN',
    display_name: 'Display Campaign',
    enabled: true,
  },
  [TACTIC_TYPE.NURTURE]: {
    error: null,
    id: 2,
    type: 'NURTURE',
    display_name: 'Nurture',
    enabled: true,
  },
  [TACTIC_TYPE.DIRECT_MAIL]: {
    error: null,
    id: 3,
    type: 'DIRECTMAIL',
    display_name: 'Direct Mail',
    enabled: true,
  },
  [TACTIC_TYPE.WEBINAR]: {
    error: null,
    id: 4,
    type: 'WEBINAR',
    display_name: 'Webinar',
    enabled: true,
  },
  [TACTIC_TYPE.FIELD_EVENT]: {
    error: null,
    id: 5,
    type: 'FIELD',
    display_name: 'Field Event',
    enabled: true,
  },
};

export const TACTIC_TYPE_TITLE = {
  [TACTIC_TYPE.DISPLAY_CAMPAIGN]: 'Display Campaign Tactic',
  [TACTIC_TYPE.NURTURE]: 'Nurture Tactic',
  [TACTIC_TYPE.WEBINAR]: 'Webinar Tactic',
  [TACTIC_TYPE.FIELD_EVENT]: 'Field Event Tactic',
  [TACTIC_TYPE.DIRECT_MAIL]: 'Direct Mail Tactic',
  DEFAULT: 'Set up tactics*',
};

export const MULTI_TACTIC_PAGE_MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  PREVIEW: 'PREVIEW',
};


export const PANEL_CAMPAIGN_INFO = 'campaign-info';
export const PANEL_TITLE_FILTER = 'title-filter';
export const PANEL_GEOGRAPHY_FILTER = 'geography-filter';
export const PANEL_ACCOUNT_FILTER = 'account-filter';
export const PANEL_OTHER_LIMITS = 'other-limits';
export const PANEL_RECOMMENDATION_APPROVAL = 'recommendation-approval';
export const PANEL_DISPLAY_CAMPAIGN = 'connect-display-campaign';

export const FIRMOGRAPHIC_TYPE = {
  REVENUE: 'revenue_range',
  INDUSTRY: 'industry',
  EMPLOYEE: 'employee_range',
};

export const ACCOUNT_FILTER_TYPE_OPTIONS = [
  {
    label: 'Industry',
    placeholder: 'Select Industry',
    value: FIRMOGRAPHIC_TYPE.INDUSTRY,
  },
  {
    label: 'Revenue',
    placeholder: 'Choose Range',
    value: FIRMOGRAPHIC_TYPE.REVENUE,
  },
  {
    label: 'Employee',
    placeholder: 'Choose Range',
    value: FIRMOGRAPHIC_TYPE.EMPLOYEE,
  },
];

export const TITLE_FILTER = 'title_filter';
export const TITLE_LEVEL_AND_FUNCTION = 'title_level_and_function';
export const JOB_TITLE = 'job_title';
export const MAX_TITLE_FILTERS_KEY = 'max_title_filters';
export const INCOMPLETE_TITLE_FILTERS_KEY = 'incomplete_title_filters';
export const ALL_TITLE_FILTERS_KEY = 'all_title_filters';
export const ALL_LEVELS = 'All Levels';
export const ALL_FUNCTIONS = 'All Functions';

export const TITLE_FILTER_MESSAGES = {
  [MAX_TITLE_FILTERS_KEY]: 'Maximum filter limit of 25 reached',
  [INCOMPLETE_TITLE_FILTERS_KEY]: 'Enter Title Level and Title Function to add a new filter',
  [ALL_TITLE_FILTERS_KEY]: 'All Levels of All Functions already chosen',
};

export const NOT_FOUND = 'NOT_FOUND';
export const SYSTEM_MAP = {
  [SYSTEMS.SALESFORCE]: OBJECT_TYPES.CONTACT,
  [SYSTEMS.MARKETO]: OBJECT_TYPES.MALEAD,
  [SYSTEMS.SALESLOFT]: OBJECT_TYPES.CONTACT,
  [SYSTEMS.OUTREACH]: OBJECT_TYPES.CONTACT,
  [SYSTEMS.CONVERSATIONAL_EMAIL]: OBJECT_TYPES.CONTACT,
  [SYSTEMS.HUBSPOT]: OBJECT_TYPES.MALEAD,
  [SYSTEMS.HUBSPOT_CRM]: OBJECT_TYPES.CONTACT,
  [SYSTEMS.PARDOT]: OBJECT_TYPES.MALEAD,
  [SYSTEMS.ELOQUA]: OBJECT_TYPES.MALEAD,
  [SYSTEMS.DYNAMICS]: OBJECT_TYPES.CONTACT,
};
export const AI_ORC_DISABLE_REASON_NON_GTM = 'This orchestration requires GTM Segments';

export const FILTER_LIMIT = 25;


// eslint-disable-next-line max-len
export const APPROVALS_DESCRIPTION = 'Configure who receives approval requests for recommendations. '+
'Find users set up within 6sense with appropriate permissions. '+
'Such users will see approval requests via email and the 6sense Inbox.';

export const CDO_SOURCE_TYPE_MAP = {
  // lead
  new_sixsense_lead_scores: 'lead',
  sixsense__X6sense_Lead_Scores__c: 'lead',

  // account
  new_sixsense_account_scores: 'account',
  sixsense__X6sense_Account_Score__c: 'account',
  sixsense_account_scores: 'account',

  // contact
  new_sixsense_contact_scores: 'contact',
  sixsense__X6sense_Contact_Score__c: 'contact',
  sixsense_contact_scores: 'contact',
};

export const FILTER_OPERATION_VALUES = {
  Exclude: 'exclude',
  Include: 'include',
};
export const FILTER_OPERATION_OPTIONS = [
  { label: 'Includes', value: FILTER_OPERATION_VALUES.Include, disabled: false },
  { label: 'Excludes', value: FILTER_OPERATION_VALUES.Exclude, disabled: false },
];

export const PER_AC_OR_RUN_LIMIT_WARNING = 'Either the "6sense Contact Profile" section ' +
  'or "Titles" section needs to be configured when limiting number of contacts';

export const NO_EMAIL_GRADE_WARNING = 'You must choose at least one grade to proceed.';
