import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: '/set_password/:token',
    name: 'Set Password',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const sagas = require('./sagas');
          const reducer = require('./modules');
          injectReducer('setPassword', reducer.default);
          injectSagas('setPassword', sagas.default);
          cb(null, containers.SetPassword);
        },
        'auth_set_password'
      );
    },
  };
}
