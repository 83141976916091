/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import moment from 'moment';
import { Checkbox2, Icon, Link } from 'v2-components';
import { css, cx } from 'styles/emotion';
import { pxToRem } from '@sixsense/core/style';
import { User } from '../types';
import { useSetSharedValue } from '@sixsense/core/shared-state';
import { selectedUserState } from '../state';
import { STATUS_LABEL_MAP } from '../constants';
import UserActionDropdown from './UserActionDropdown';
import { SortOrder, SortState } from './types';
import EnabledCheckSvg from 'images/enabled_check.svg';
import InvitedSvg from 'images/invited.svg';
import DisabledSvg from 'images/disabled.svg';
import { useSelector } from '@sixsense/core/versioned/react-redux';
import {
  appsListSelector,
  isAppEditUserPermissionMapSelector,
} from '../selectors';
import Table from 'rc-table7';
import 'rc-table/assets/index.css';
import { forEach } from 'lodash';

const RenderCompoent = ({ Component }: { Component: any }) => <Component />;

const styles = {
  table: css({
    fontSize: pxToRem(13),
    color: '#001F32',
    width: '100%',
  }),
  headerRow: css({
    height: pxToRem(40),
    border: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  }),
  row: css({
    height: pxToRem(60),
    border: '1px solid #C6CACE',
  }),
  cell: css({
    width: 'max-content',
    fontSize: pxToRem(13),
    color: '#001F32',
  }),
  headerStyle: css({
    width: 'max-content',
    fontSize: pxToRem(13),
    color: '#001F32',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  nameCell: css({

  }),
  sortIcon: css({
    position: 'absolute',
    left: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
  }),
  checkbox: css({

  }),
  statusIcon: css({
    marginRight: '5px',
  }),
  tableUserManagement: css({
    '.rc-table-body': {
      overflow: 'auto !important',
    },
    '.rc-table-cell': {
      background: 'white !important',
    },
    td: {
      borderTop: '1px solid rgb(198, 202, 206)',
      borderBottom: 'none !important',
    },
  }),
};

const renderSortIcon = (sortOrder?: SortOrder) => {
  switch (sortOrder) {
    case 'asc':
      return <Icon type="arrow_drop_up" />;
    case 'desc':
      return <Icon type="arrow_drop_down" />;
    default:
      return <span style={{ display: 'inline-block', width: 16 }} />;
  }
};

type HeaderProps = {
  children: string;
  sortState?: SortState;
  sortKey: string;
  className?: string;
  onClick: (col: string) => void;
};

const HeaderColumn = ({
  children,
  sortState,
  sortKey,
  className,
  onClick,
}: HeaderProps) => (
  <div className={cx(styles.headerStyle, className)} onClick={() => onClick(sortKey)}>
    {children}{' '}
    {renderSortIcon(
      sortState?.column === sortKey ? sortState?.order : undefined
    )}
  </div>
);

type UserListProps = {
  users: User[];
  selectedUsers: Set<number>;
  sortState: SortState;
  setSortState: (nextState: SortState) => void;
  setSelectedUsers: (users: Set<number>) => void;
  isSAML: boolean;
};

const UserList = ({
  users,
  selectedUsers,
  setSelectedUsers,
  isSAML,
  sortState,
  setSortState,
}: UserListProps) => {
  const canEditApps = useSelector(isAppEditUserPermissionMapSelector);
  const appsList = useSelector(appsListSelector);
  const isAnyEditApps = appsList.some(({ id }) => canEditApps[id]);
  const setSelectedUser = useSetSharedValue(selectedUserState);

  const handleSortClick = (column: string) => {
    if (sortState?.column === column) {
      if (sortState?.order === 'asc') {
        setSortState({ column, order: 'desc' });
      } else {
        setSortState(undefined);
      }
    } else {
      setSortState({ column, order: 'asc' });
    }
  };

  const selectAllUsers = (selected: boolean) => {
    const nextSelected = new Set(selectedUsers);
    forEach(users, ({ id }) => {
      if (selected) {
        nextSelected.add(id);
      } else {
        nextSelected.delete(id);
      }
    });
    setSelectedUsers(nextSelected);
  };

  const allSelected = useMemo(
    () => selectedUsers.size && users.every(({ id }) => selectedUsers.has(id)),
    [users, selectedUsers]
  );
  const anySelected = useMemo(
    () => users.some(({ id }) => selectedUsers.has(id)),
    [users, selectedUsers]
  );

  const statusIcon = (status) => {
    if (status === STATUS_LABEL_MAP.enabled) {
      return <img src={EnabledCheckSvg} alt={status} />;
    }
    if (status === STATUS_LABEL_MAP.disabled) {
      return <img src={DisabledSvg} alt={status} />;
    }
    // if (status === STATUS_LABEL_MAP.inviteExpired) {

    // }
    if (status === STATUS_LABEL_MAP.invited) {
      return <img src={InvitedSvg} alt={status} />;
    }
    return null;
  };

  const getTableColumns = () => {
    const columns = [];

    if (isAnyEditApps) {
      const checkbox = {
        title: <RenderCompoent
          Component={() => <div >
            <Checkbox2
              checked={allSelected}
              indeterminate={anySelected}
              onChange={(e) => selectAllUsers(e.target.checked)}
            />
          </div>}
        />,
        render: (user) => (
          <div>
            <Checkbox2
              checked={selectedUsers.has(user.id)}
              onChange={(e) => {
                const nextSelected = new Set(selectedUsers);
                if (e.target.checked) {
                  nextSelected.add(user.id);
                } else {
                  nextSelected.delete(user.id);
                }
                setSelectedUsers(nextSelected);
              }}
            />
          </div>
          ),
        fixed: 'left',
        className: cx(styles.cell, styles.checkbox),
      };
      columns.push(checkbox);
    }

    const username = {
      title: <RenderCompoent
        Component={() => {
          const config = {
            label: 'Username',
            sortKey: 'username',
            className: styles.nameCell,
          };
          return (<HeaderColumn
            key={config.label}
            sortState={sortState}
            onClick={handleSortClick}
            className={config.className}
            sortKey={config.sortKey}
          >
            {config.label}
          </HeaderColumn>);
        }}
      />,
      render: (user) => (
        <div>
          {isAnyEditApps ? (
            <Link onClick={() => setSelectedUser(user.id)}>
              {user.username}
            </Link>
            ) : (
              user.username
            )}
        </div>
        ),
      fixed: 'left',
      width: '25%',
      className: cx(styles.cell, styles.nameCell),
    };
    columns.push(username);

    const status = {
      title: <RenderCompoent
        Component={() => {
          const config = { label: 'Status', sortKey: 'status' };
          return (
            <HeaderColumn
              key={config.label}
              sortState={sortState}
              onClick={handleSortClick}
              sortKey={config.sortKey}
            >
              {config.label}
            </HeaderColumn>
          );
        }}
      />,
      render: (user) => (
        <div>
          <span className={styles.statusIcon}>
            {statusIcon(STATUS_LABEL_MAP[user.status])}
          </span>

          {STATUS_LABEL_MAP[user.status]}
        </div>
        ),
      fixed: 'left',
      width: '20%',
      className: styles.cell,

    };
    columns.push(status);
    const firstName = {
      title: <RenderCompoent
        Component={() => {
          const config = { label: 'First Name', sortKey: 'firstName' };
          return (
            <HeaderColumn
              key={config.label}
              sortState={sortState}
              onClick={handleSortClick}
              sortKey={config.sortKey}
            >
              {config.label}
            </HeaderColumn>
          );
        }}
      />,
      render: (user) => <div>{user.firstName || '-'}</div>,
      className: styles.cell,
      width: '20%',
    };
    columns.push(firstName);
    const lastName = {
      title: <RenderCompoent
        Component={() => {
          const config = { label: 'Last Name', sortKey: 'lastName' };
          return (
            <HeaderColumn
              key={config.label}
              sortState={sortState}
              onClick={handleSortClick}
              sortKey={config.sortKey}
            >
              {config.label}
            </HeaderColumn>
          );
        }}
      />,
      render: (user) => <div>{user.lastName || '-'}</div>,
      className: styles.cell,
      width: '20%',
    };
    columns.push(lastName);
    forEach(appsList, ({ id, shortName }) => {
      const appCol = {
        title: <RenderCompoent
          Component={() => {
            const config = { label: `${shortName} Role`, sortKey: `roleNameMap.${id}` };
            return (
              <HeaderColumn
                key={config.label}
                sortState={sortState}
                onClick={handleSortClick}
                sortKey={config.sortKey}
              >
                {config.label}
              </HeaderColumn>
            );
          }}
        />,
        render: (user) => <div>{user.roleNameMap[id]}
          {user.roleLicenseLabelMap[id] ? ` ${user.roleLicenseLabelMap[id]}` : ''}
        </div>,
        className: styles.cell,
        width: '20%',
      };
      columns.push(appCol);
    });

    if (isSAML) {
      const invitedBy = {
        title: <RenderCompoent
          Component={() => {
            const config = {
              label: 'Invited By',
              sortKey: 'invitedBy',
              hideForSAML: true,
            };
            return (
              <HeaderColumn
                key={config.label}
                sortState={sortState}
                onClick={handleSortClick}
                sortKey={config.sortKey}
              >
                {config.label}
              </HeaderColumn>
            );
          }}
        />,
        render: (user) => <div>{user.invitedBy}</div>,
        className: styles.cell,
      };
      columns.push(invitedBy);
    }
    const lastLogin = {
      title: <RenderCompoent
        Component={() => {
          const config = { label: 'Last Login', sortKey: 'lastLogin' };
          return (
            <HeaderColumn
              key={config.label}
              sortState={sortState}
              onClick={handleSortClick}
              sortKey={config.sortKey}
            >
              {config.label}
            </HeaderColumn>
          );
        }}
      />,
      render: (user) => (
        <div>
          {user.lastLogin
              ? moment(user.lastLogin).format('MMM DD, YYYY hh:mm a')
              : '-'}
        </div>
        ),
      className: styles.cell,
    };
    columns.push(lastLogin);

    const userAction = {
      title: ' ',
      render: (user) => <div>
        <UserActionDropdown user={user} isSAML={false} />
      </div>,
      fixed: 'right',
      className: styles.cell,
    };
    columns.push(userAction);
    return columns;
  };

  return (
    <Table
      columns={getTableColumns()}
      data={users}
      // Todo: check pixle value for x.
      scroll={{ x: true }}
      className={styles.tableUserManagement}
      emptyText={() =>
          'Adjust the applied filters. We can’t find any users that match the filters you used.'}
    />
  );
};

export default UserList;
