import React from 'react';
import {
  EnvProduction,
  EnvSandbox,
} from '../assets';

const ENV_PRODUCTION = 'ENV_PRODUCTION';
const ENV_SANDBOX = 'ENV_SANDBOX';

type TEnvironmentIconProps = {
  type: string;
  className?: string;
  size: string;
}

const EnvironmentIcon = (props: TEnvironmentIconProps) => {
  const { type, className, size } = props;

  switch (type) {
    case ENV_PRODUCTION:
      return <EnvProduction className={className} size={size} />;
    case ENV_SANDBOX:
      return <EnvSandbox className={className} size={size} />;
    default:
      return null;
  }
};

EnvironmentIcon.ENV_PRODUCTION = ENV_PRODUCTION;
EnvironmentIcon.ENV_SANDBOX = ENV_SANDBOX;


export default EnvironmentIcon;
