import 'whatwg-fetch';
import { codes } from './constants';
import { isPrelogin, getPathFromUrl } from 'utils/utils';
import { merge } from 'lodash';
import rollbar from 'lib/rollbar';
import LogRocket from 'logrocket';
import { getDatadogSessionIdHeader } from 'lib/datadog';

const {
  HTTP_401_UNAUTHORIZED,
  HTTP_200_OK,
  HTTP_403_FORBIDDEN,
  HTTP_404_NOT_FOUND,
  HTTP_500_INTERNAL_SERVER_ERROR,
  HTTP_300_MULTIPLE_CHOICES,
  HTTP_422_UNKNOWN,
  HTTP_204_NO_CONTENT,
  HTTP_408_REQUEST_TIMEOUT,
} = codes;

const POST_OPTIONS = {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRFToken': window.__CSRF_TOKEN__,
  },
  credentials: 'same-origin',
};

const PUT_OPTIONS = {
  method: 'PUT',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRFToken': window.__CSRF_TOKEN__,
  },
  credentials: 'same-origin',
};

const PATCH_OPTIONS = {
  method: 'PATCH',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRFToken': window.__CSRF_TOKEN__,
  },
  credentials: 'same-origin',
};

const OPTIONS_OPTIONS = {
  method: 'OPTIONS',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  credentials: 'same-origin',
};

const DELETE_OPTIONS = {
  method: 'DELETE',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRFToken': window.__CSRF_TOKEN__,
  },
  credentials: 'same-origin',
};

const GET_OPTIONS = {
  method: 'GET',
  credentials: 'same-origin',
};

function checkRequestStatus(response, url, method) {
  const { pathname } = window.location;

  if (response.status === HTTP_401_UNAUTHORIZED) {
    rollbar.warning('User session expired: relogin');
    if (!isPrelogin(pathname)) {
      // force logout to ensure clean cookies
      window.location = '/logout';
      return null;
    }
  }
  if (
    response.status >= HTTP_500_INTERNAL_SERVER_ERROR
    || response.status === HTTP_408_REQUEST_TIMEOUT
  ) {
    const error = new Error(url);
    error.errorStatus = response.status;
    error.errorMessage = response.statusText;
    error.response = response;
    rollbar.configure({ payload: { fingerprint: `${error.errorStatus}${method}` } })
      .error(`API Error: ${response.status}`, error);
    throw error;
  }

  // no content, json method will break if called so return empty string here
  if (response.status === HTTP_204_NO_CONTENT) {
    return '';
  }

  const json = response.json();

  if (response.status >= HTTP_200_OK && response.status < HTTP_300_MULTIPLE_CHOICES) {
    return json;
  }

  return json.then((err) => {
    const error = new Error(`${response.statusText}:\n$`, err);
    error.body = err;
    error.errorStatus = response.status;
    error.errorMessage = response.statusText;

    if (
      ([HTTP_401_UNAUTHORIZED, HTTP_403_FORBIDDEN, HTTP_422_UNKNOWN].includes(response.status)
      && isPrelogin(pathname))
      || response.status === HTTP_404_NOT_FOUND
    ) {
      rollbar.warning(`API: ${response.status}`, error);
    } else {
      rollbar.configure({ payload: {
        error,
        fingerprint: `${error.errorStatus}${method}`,
        errorResponse: err,
      } })
        .error(`API Error: ${response.status}`, error);
    }

    throw error;
  });
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {Promise<object>}           The response data
 */
function request(url, method, options, errorHandler) {
  let newErrorHandler = errorHandler;
  if (newErrorHandler === undefined) {
    newErrorHandler = (result) => {
      throw result;
    };
  }
  let bakedOptions;
  const meth = typeof method === 'string' ? method : '';
  switch ((meth || '').toUpperCase()) {
    case 'PUT':
      bakedOptions = PUT_OPTIONS;
      break;
    case 'POST':
      bakedOptions = POST_OPTIONS;
      break;
    case 'PATCH':
      bakedOptions = PATCH_OPTIONS;
      break;
    case 'OPTIONS':
      bakedOptions = OPTIONS_OPTIONS;
      break;
    case 'DELETE':
      bakedOptions = DELETE_OPTIONS;
      break;
    default:
      bakedOptions = GET_OPTIONS;
  }

  let origin = window.location.origin;
  if (!origin) {
    const port = window.location.port ? `:${window.location.port}` : '';
    origin = `${window.location.protocol}//${window.location.hostname}${port}`;
  }

  const logrocketOptions = {
    headers: {
      'X-LogRocket-URL': LogRocket.sessionURL,
    },
  };

  const ddSessionHeader = getDatadogSessionIdHeader();

  return fetch(`${origin}/${url}`, merge(ddSessionHeader, logrocketOptions, bakedOptions, options))
    .then((response) => checkRequestStatus(response, getPathFromUrl(url), meth))
    .catch(newErrorHandler);
}

/**
 * Throws error only if not a 404, could be modified to handle curried list of error codes to ignore
*/
export const throwIfNot404 = (e) => {
  if (e.errorStatus === HTTP_404_NOT_FOUND) {
    return {};
  }
  throw e;
};

const REQUEST_CACHE = {};

export const fetchRequest = (
  url,
  { method, errorHandler, cacheOptions, ...options } = {}
) => {
  if (cacheOptions && cacheOptions.enabled) {
    const { cacheKey = url } = cacheOptions;
    const cachedResult = REQUEST_CACHE[cacheKey];
    if (cachedResult) {
      return Promise.resolve(cachedResult);
    }
    return request(url, method, options, errorHandler).then((result) => {
      REQUEST_CACHE[cacheKey] = result;
      return result;
    });
  }
  return request(url, method, options, errorHandler);
};

export default request;
