const predictive = 'predictive_analytics';
const behavioral = 'behavioral_analytics';
const profile = 'profile_analytics';
const totalAccounts = 'total_accounts';
const createEditSegment = 'discover_filters';

const NA = 'N/A';
const MAX_ACCOUNT_COUNT = 10000;

const graphCategories = [
  predictive,
  behavioral,
  profile,
];
const GRAPH_CATEGORIES = Object.freeze({
  predictive,
  behavioral,
  profile,
});

const EXPORT_TYPES = Object.freeze({
  ALL: 'all',
  FILTERED_OUT: 'filteredOut',
  FILTERED_IN: 'filteredIn',
});

const activityKeys = [
  'all',
  'website_events',
  'keyword_events',
  'fpm_events',
  'tpm_events',
  'crm_events',
  'map_events',
];

export const PartialData = {
  BEHAVIORAL: 'behavioral',
  BEHAVIORAL_NO_KW: 'behavioral_no_kw',
  KEYWORDS: 'keywords',
  PREDICTIVE: 'predictive',
  TREND: 'trend',
  CRM_MAP_CAMPAIGNS: 'crm_map_campaigns',
  SURGE_TOPICS: 'surge_topics',
  NONE: 'none',
  INDUSTRY_V2: 'industry_v2',
};

export {
  NA,
  MAX_ACCOUNT_COUNT,
  behavioral,
  profile,
  predictive,
  graphCategories,
  activityKeys,
  GRAPH_CATEGORIES,
  EXPORT_TYPES,
  totalAccounts,
  createEditSegment,
};

export const SURGE_TOPICS_TOOLTIP = 'This data always reflects Bombora’s Company Surge scores '
+ 'from the past week. As a result, the number of accounts shown below is unaffected by the '
+ 'selected Date Range. ';
