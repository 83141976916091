import { map } from 'lodash';
import { isValidGTMSegment } from 'modules/segments/utils';

export const getMetaForSelectedSegment = (segmentList, savedSegmentName) =>
  [...segmentList].filter((listItem) => listItem.name === savedSegmentName)[0];

export const updateTagResponse = (segmentTags) =>
  map(segmentTags, (tag) => (
    { ...tag,
      isSelected: false,
    }
  ));


/* eslint-disable no-param-reassign */
export const updateSegmentsResponse = (segmentsList, segTags, config, filtersetFunc) =>
  map(segmentsList, (listItem) => {
    const updatedFs = filtersetFunc(listItem.filterset);
    return { ...listItem,
      filterset: updatedFs,
      isGTM: isValidGTMSegment(updatedFs, listItem.segment_type, config),
      selected_tags: segTags.reduce((obj, tag) => {
        const tagIndex = listItem.tags.findIndex((segTag) => segTag.segment_tag_id === tag.id);
        obj[tag.id] = tagIndex >= 0 && listItem.tags[tagIndex].is_deleted === false;
        return obj;
      }, {}),
    };
  });
/* eslint-enable no-param-reassign */
