import React, { useState } from 'react';
import collapse from 'images/collapse.svg';
import PropTypes from 'prop-types';
import styles from './SlidingSideBarContainer.module.scss';
import { classNames } from 'utils/utils';


// Working of this component is mostly based on two entities/variables
// leftPanelExpanded - Keeps track of expanded/collapsed state of left side panel.
// leftPanelTemporarilyExpanded - It's kind of metadata to track if sidebar is expanded
//         because of temporary hover on the collapsed left side panel.

const SlidingSideBarContainer = ({
    leftPanelContent,
    rightPanelContent,
    leftCollapsedPanelContent }) => {
  const [leftPanelExpanded, setLeftPanelExpandedState] = useState(true);

  // This is to maintain left panel temporary expanded state,
  // Because of hover on collapsed left panel.
  const [leftPanelTemporarilyExpanded, setLeftPanelTemporarilyExpanded] = useState(false);
  const toggleLeftPanelExpadedState = () => setLeftPanelExpandedState(!leftPanelExpanded);

  const expandCollapseIconClick = () => leftPanelTemporarilyExpanded ?
    setLeftPanelTemporarilyExpanded(false) : toggleLeftPanelExpadedState();

  const getIconPositionClass = () => {
    if (leftPanelExpanded) {
      return styles.expandedLeftPanel;
    }
    return styles.collapsedLeftPanel;
  };

  return (
    <React.Fragment>
      {leftPanelContent ?
        <div
          onMouseLeave={() => {
            if (leftPanelTemporarilyExpanded && leftPanelExpanded) {
              setLeftPanelTemporarilyExpanded(false);
              toggleLeftPanelExpadedState();
            }
          }}
        >
          <img
            title={leftPanelExpanded ? 'Collapse' : 'Expand'}
            src={collapse}
            alt={'Expand Collapse Icon'}
            onClick={expandCollapseIconClick}
            className={classNames(styles.expandCollapseIcon,
              (leftPanelExpanded && !leftPanelTemporarilyExpanded ?
                styles.expandIcon : styles.collapseIcon),
                getIconPositionClass())}
          />
          <div
            className={`${styles.parentContainer} ${leftPanelExpanded ?
                styles.expandedParentContainer : styles.collapsedParentContainer}`}
          >
            <div
              onMouseEnter={() => {
                if (leftPanelExpanded) return;
                setLeftPanelTemporarilyExpanded(true);
                toggleLeftPanelExpadedState();
              }}
              className={leftPanelExpanded ? styles.leftPanelExpanded : styles.leftPanelCollapsed}
            >
              {leftPanelExpanded ? leftPanelContent : leftCollapsedPanelContent}
            </div>
          </div>
        </div>
      : null}
      <div
        className={leftPanelContent && (!leftPanelTemporarilyExpanded && leftPanelExpanded ?
                styles.rightSection : styles.fullWidthRightSection)}
      >
        {rightPanelContent}
      </div>
    </React.Fragment>
  );
};

SlidingSideBarContainer.propTypes = {
  leftPanelContent: PropTypes.node,
  rightPanelContent: PropTypes.node,
  leftCollapsedPanelContent: PropTypes.node,
};

export default SlidingSideBarContainer;
