import React from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'contexts';
import { some, flatten, get, isEmpty, compact } from 'lodash';
import {
  ORGANIZATION_ROLES,
  ACCESSIBLE_INTERNAL_ROLES,
} from 'routes/Settings/routes/UserManagementV2/constants';

const Consumer = UserContext.Consumer;

/**
 * Please be aware that this component will likely change as RBA is fully implemented
 *
 * @param {(string|string[])} showFlags will show the children IF this flag is true
 * @param {(string|string[])} hideFlags will hide the children IF this flag is true
 * @param {string[]} permissions will show children IF the user's role is one of the valid roles
 *
 * note: roles are NOT flags
 * note: flags take first priority since these are internal(6sense user) flags.
 * Roles are only used to show/hide if there are no flags provided
 *
 * showFlags/hideFlags can be paths to the flag
 * If you need to hide/show based on multiple conditions being true, you will need
 * to nest this component
 *
 * User role's are a bit funky right now. Users will only have a "ORGANIZATION_ROLE" IF they
 * are a member of the organization. This creates a problem for 6sense users switching between
 * orgs. As a temprary(/s) solution, we first check that the user's role is and ORGANIZATION_ROLE
 * aka roles determined via user management settings. 6sense users will have this
 * for 6sense, but will NOT for other orgs. As of right now, we allow
 * 6sense staff + internal users to access other orgs
 *
 * Because we allow 6sense users to access all features on other orgs,
 * there is a check here to make sure that this isn't the wrapper for the access
 * denied page, since we do not deny access to any internal 6sense users while on
 * cusomter platforms.
 */
const UserCondition = (props) => {
  const {
    children,
    hideFlags,
    showFlags,
    permissions,
    accessDenied,
  } = props;

  const hideConditions = compact(flatten([hideFlags]));
  const showConditions = compact(flatten([showFlags]));

  if (isEmpty(hideConditions) && isEmpty(showConditions) && isEmpty(permissions)) {
    return children;
  }

  return (
    <Consumer>
      {(user) => {
        const userRole = user.role;

        // If any hide based flags are true - don't show
        if (
          hideConditions.length
          && some(hideConditions, (condition) => get(user, condition))
        ) {
          return null;
        }

        // If any show based flags are true - show
        if (
          showConditions.length
          && some(showConditions, (condition) => get(user, condition))
        ) {
          return children;
        }

        // No flag conditions passed, but a set of permissions was
        if (ORGANIZATION_ROLES.includes(userRole)) {
          return permissions.includes(userRole) ? children : null;

        // Show the children to internal users If this isn't the access denied page
        } else if (ACCESSIBLE_INTERNAL_ROLES.includes(userRole) && !accessDenied) {
          return children;
        }

        // Should never get here
        return null;
      }}
    </Consumer>
  );
};

UserCondition.defaultProps = {
  permissions: [],
  accessDenied: false,
};

UserCondition.propTypes = {
  children: PropTypes.any,
  hideFlags: PropTypes.string,
  showFlags: PropTypes.any,
  permissions: PropTypes.array,
  accessDenied: PropTypes.bool,
};

export default UserCondition;
