import React from 'react';
import { CAMPAIGN_STATUS_X } from '../../../constants';
import { NA } from './common/NA';
import CampaignPerformanceIndicator
  from '../../../components/CampaignPerformanceIndicator/CampaignPerformanceIndicator';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';

export const CAMPAIGN_PERFORMANCE = {
  key: 'campaign-performance',
  csvColumnKey: 'campaign_performance',
  title: <Label name={CAMPAIGN_LABELS.campaignPerformance} />,
  defaultSelected: true,
  dataIndex: 'campaign_performance',
  width: 200,
  render: (campaignPerformance, campaign) => {
    const performanceDataExists = Boolean(campaignPerformance);
    const status = campaign.campaign_status;

    if ((status && status === CAMPAIGN_STATUS_X.draft) || performanceDataExists === false) {
      return (<NA />);
    }

    return (<CampaignPerformanceIndicator campaign={campaign} />);
  },
};
