import { combineReducers } from 'redux';
import { cloneDeep } from 'lodash';
import update from 'immutability-helper';
import { createReducerUtil } from 'utils/core';
import { SUMMARY_VIEW, NOT_CONFIGURED } from './constants';
import { loaderPresets, setPayload } from '../../utils';
import { PRESET_STRING } from '../../constants';
import { INITIALIZE_URLS } from '../../stateGenerators';

export const SET_PRODUCT_URLS = `${PRESET_STRING}/SET_PRODUCT_URLS`;
export const SET_PRODUCT_KEYWORDS = `${PRESET_STRING}/SET_PRODUCT_KEYWORDS`;

export const SAVE_URLS_REQUEST = `${PRESET_STRING}/SAVE_URLS_REQUEST`;
export const SAVE_URLS_SUCCESS = `${PRESET_STRING}/SAVE_URLS_SUCCESS`;
export const SAVE_URLS_FAILURE = `${PRESET_STRING}/SAVE_URLS_FAILURE`;

export function saveURLs() {
  return {
    ...loaderPresets,
    type: SAVE_URLS_REQUEST,
    loading: true,
  };
}

function saveURLSuccess() {
  return {
    type: SAVE_URLS_SUCCESS,
    loading: false,
  };
}

function saveURLFailure() {
  return {
    type: SAVE_URLS_FAILURE,
    loading: false,
  };
}

export const SAVE_KEYWORDS_REQUEST = `${PRESET_STRING}/SAVE_KEYWORDS_REQUEST`;
export const SAVE_KEYWORDS_SUCCESS = `${PRESET_STRING}/SAVE_KEYWORDS_SUCCESS`;
export const SAVE_KEYWORDS_FAILURE = `${PRESET_STRING}/SAVE_KEYWORDS_FAILURE`;

export function saveKeywords() {
  return {
    ...loaderPresets,
    type: SAVE_KEYWORDS_REQUEST,
    loading: true,
  };
}

function saveKeywordsSuccess() {
  return {
    type: SAVE_KEYWORDS_SUCCESS,
    loading: false,
  };
}

function saveKeywordsFailure() {
  return {
    type: SAVE_KEYWORDS_FAILURE,
    loading: false,
  };
}

export function setProductURLs(urls) {
  return {
    type: SET_PRODUCT_URLS,
    urls,
  };
}

export function setProductKeywords(keywords) {
  return {
    type: SET_PRODUCT_KEYWORDS,
    keywords,
  };
}

const productURLReducer = createReducerUtil([], {
  [SET_PRODUCT_URLS]: (_, action) => action.urls,
});

/**
 * add,remove and move keyword actions
 */

const MOVE_KEYWORD = `${PRESET_STRING}/PRODUCT_CONFIG/MOVE_KEYWORD`;
const moveKeyword = (
  mappingIndex,
  newBucket,
  oldBucket,
  oldBucketIndex,
  keyword
) => ({
  type: MOVE_KEYWORD,
  mappingIndex,
  newBucket,
  oldBucket,
  oldBucketIndex,
  keyword,
});

const REMOVE_KEYWORD = `${PRESET_STRING}/PRODUCT_CONFIG/REMOVE_KEYWORD`;
const removeKeyword = (mappingIndex, bucket, bucketIndex) => ({
  type: REMOVE_KEYWORD,
  mappingIndex,
  bucket,
  bucketIndex,
});

const SET_KEYWORD_INPUT = `${PRESET_STRING}/PRODUCT_CONFIG/SET_KEYWORD_INPUT`;
const setKeywordInput = (value, mappingIndex, bucket) => ({
  type: SET_KEYWORD_INPUT,
  value,
  mappingIndex,
  bucket,
});

const ADD_KEYWORD = `${PRESET_STRING}/PRODUCT_CONFIG/ADD_KEYWORD`;
const addKeyword = (value, mappingIndex, bucket) => ({
  type: ADD_KEYWORD,
  value,
  mappingIndex,
  bucket,
});

function initializeURLs() {
  return {
    type: INITIALIZE_URLS,
  };
}

export const INITIALIZE_VALIDATION_URLS_REQUEST =
  `${PRESET_STRING}/INITIALIZE_VALIDATION_URLS_REQUEST`;

function initializeValidationURLs() {
  return {
    type: INITIALIZE_VALIDATION_URLS_REQUEST,
  };
}

export const INITIALIZE_VALIDATION_URLS_SUCCESS =
  `${PRESET_STRING}/INITIALIZE_VALIDATION_URLS_SUCCESS`;

function initializeValidationURLSuccess(data) {
  return {
    type: INITIALIZE_VALIDATION_URLS_SUCCESS,
    data,
  };
}

export const VALIDATE_URLS_REQUEST = `${PRESET_STRING}/VALIDATE_URLS_REQUEST`;
export const VALIDATE_URLS_SUCCESS = `${PRESET_STRING}/VALIDATE_URLS_SUCCESS`;
export const ADD_URL_TO_VALIDATE = `${PRESET_STRING}/ADD_URL_TO_VALIDATE`;
export const REMOVE_URL_TO_VALIDATE = `${PRESET_STRING}/REMOVE_URL_TO_VALIDATE`;

function validateURLs(data) {
  return {
    type: VALIDATE_URLS_REQUEST,
    data,
  };
}

function validateURLsSuccess(data) {
  return {
    type: VALIDATE_URLS_SUCCESS,
    data,
  };
}

function addURLToValidate(data) {
  return {
    type: ADD_URL_TO_VALIDATE,
    data,
  };
}

function removeURLToValidate(data) {
  return {
    type: REMOVE_URL_TO_VALIDATE,
    data,
  };
}

const productKeywordReducer = createReducerUtil([], {
  [SET_PRODUCT_KEYWORDS]: (_, action) => action.keywords,
  [MOVE_KEYWORD]: (state, action) => {
    const { mappingIndex, newBucket, oldBucket, oldBucketIndex, keyword } =
      action;
    const newKeyword = cloneDeep(keyword);
    newKeyword.updated = true;
    return update(state, {
      [mappingIndex]: {
        keywords: {
          [newBucket]: { $push: [newKeyword] },
          [oldBucket]: { $splice: [[oldBucketIndex, 1]] },
        },
      },
    });
  },
  [REMOVE_KEYWORD]: (state, action) => {
    const { mappingIndex, bucket, bucketIndex } = action;
    return update(state, {
      [mappingIndex]: {
        keywords: {
          [bucket]: {
            [bucketIndex]: {
              isDeleted: { $set: true },
            },
          },
        },
      },
    });
  },
  [SET_KEYWORD_INPUT]: (state, action) => {
    const { value, mappingIndex, bucket } = action;
    return update(state, {
      [mappingIndex]: {
        keywords: {
          keywordInput: {
            [bucket]: { $set: value },
          },
        },
      },
    });
  },
  [ADD_KEYWORD]: (state, action) => {
    const { value, mappingIndex, bucket } = action;
    const keyword = { id: null, value, isDeleted: false, updated: false };
    return update(state, {
      [mappingIndex]: {
        keywords: {
          [bucket]: { $push: [keyword] },
          keywordInput: {
            [bucket]: { $set: '' },
          },
        },
      },
    });
  },
});

const saveURLReducer = createReducerUtil([], {
  [SAVE_URLS_REQUEST]: setPayload,
  [SAVE_URLS_SUCCESS]: setPayload,
  [SAVE_URLS_FAILURE]: setPayload,
});

const saveKeywordsReducer = createReducerUtil([], {
  [SAVE_KEYWORDS_REQUEST]: setPayload,
  [SAVE_KEYWORDS_FAILURE]: setPayload,
  [SAVE_KEYWORDS_SUCCESS]: setPayload,
});

const urlValidationReducer = createReducerUtil(
  {},
  {
    [INITIALIZE_VALIDATION_URLS_REQUEST]: setPayload,
    [INITIALIZE_VALIDATION_URLS_SUCCESS]: (_, action) => action.data,
    [VALIDATE_URLS_REQUEST]: (state, action) => {
      const { data } = action;
      const { index, product, url } = data;

      return {
        ...state,
        [product]: state[product].map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              isValid: null,
              validationLoading: true,
              url,
              serverError: false,
            };
          }
          return item;
        }),
      };
    },
    [VALIDATE_URLS_SUCCESS]: (state, action) => {
      const { data } = action;
      const { index, product, url, isValid, serverError } = data;
      return {
        ...state,
        [product]: state[product].map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              isValid,
              validationLoading: false,
              url,
              serverError,
            };
          }
          return item;
        }),
      };
    },
    [ADD_URL_TO_VALIDATE]: (state, action) => {
      const { product, url } = action.data;
      return {
        ...state,
        [product]: state[product].concat({
          product,
          isValid: false,
          validationLoading: false,
          url,
        }),
      };
    },
    [REMOVE_URL_TO_VALIDATE]: (state, action) => {
      const { product, index } = action.data;
      return {
        ...state,
        [product]: state[product].filter((_, currIndex) => currIndex !== index),
      };
    },
  }
);

const SET_CURRENT_STEP = `${PRESET_STRING}/SET_CURRENT_STEP`;
export const setCurrentStep = (step) => ({
  type: SET_CURRENT_STEP,
  step,
});

const currentStepReducer = createReducerUtil(SUMMARY_VIEW, {
  [SET_CURRENT_STEP]: (_, action) => action.step,
});

const SET_CURRENT_TAXONOMY_STATE = `${PRESET_STRING}/SET_CURRENT_TAXONOMY_STATE`;

export const setCurrentTaxonomyState = (taxonomyState) => ({
  type: SET_CURRENT_TAXONOMY_STATE,
  taxonomyState,
});

const currentTaxonomyStateReducer = createReducerUtil(NOT_CONFIGURED, {
  [SET_CURRENT_TAXONOMY_STATE]: (_, action) => action.taxonomyState,
});

export const actions = {
  saveURLs,
  saveURLFailure,
  saveURLSuccess,
  setProductURLs,
  setProductKeywords,
  moveKeyword,
  removeKeyword,
  setKeywordInput,
  addKeyword,
  setCurrentStep,
  saveKeywords,
  saveKeywordsFailure,
  saveKeywordsSuccess,
  initializeURLs,
  initializeValidationURLs,
  initializeValidationURLSuccess,
  validateURLs,
  validateURLsSuccess,
  addURLToValidate,
  removeURLToValidate,
  setCurrentTaxonomyState,
};

export const actionTypes = {
  SAVE_URLS_REQUEST,
  SET_PRODUCT_URLS,
  SAVE_URLS_SUCCESS,
  SAVE_URLS_FAILURE,
  MOVE_KEYWORD,
  SAVE_KEYWORDS_REQUEST,
  SAVE_KEYWORDS_SUCCESS,
  SAVE_KEYWORDS_FAILURE,
  INITIALIZE_URLS,
  INITIALIZE_VALIDATION_URLS_REQUEST,
  INITIALIZE_VALIDATION_URLS_SUCCESS,
  VALIDATE_URLS_REQUEST,
  VALIDATE_URLS_SUCCESS,
  ADD_URL_TO_VALIDATE,
  REMOVE_URL_TO_VALIDATE,
  SET_CURRENT_TAXONOMY_STATE,
};

export const productUrlConfigReducer = combineReducers({
  productURLs: productURLReducer,
  productKeywords: productKeywordReducer,
  saveURL: saveURLReducer,
  currentStep: currentStepReducer,
  saveKeywords: saveKeywordsReducer,
  urlValidation: urlValidationReducer,
  currentTaxonomyState: currentTaxonomyStateReducer,
});
