import { getAsyncInjectors } from 'store/utils';
import { FIRST_PARTY_CONSENT_PAGE_ROUTE, PAGE_STATE_KEY } from './constants';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: FIRST_PARTY_CONSENT_PAGE_ROUTE,
    name: 'firstpartyUserConsent',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const sagas = require('./ducks/sagas');
          const reducer = require('./ducks/reducers');
          injectReducer(PAGE_STATE_KEY, reducer.default);
          injectSagas(PAGE_STATE_KEY, sagas.default);
          cb(null, containers.UserConsentContainer);
        },
        'settings_user_consent'
      );
    },
  };
}
