import React from "react";
import { get } from "lodash";
import { Text } from "@sixsense/core/components";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const ARCHIVED_BY = {
  key: "archived-by",
  csvColumnKey: "archived_by",
  title: <Label name={CAMPAIGN_LABELS.archivedBy} />,
  dataIndex: "campaign_meta.archived_by",
  width: pxToRem(200),
  render: (archivedBy) => (
    <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
      {get(archivedBy, "username", "-")}
    </Text.Body>
  ),
};
