// Flags must be registered below before you use them to hide/show features.
// Also make sure that you are receiving these flags from organization service
// in response of api with endpoint - `organization/enrich/{org_id}/details/?flags=true....`.
// Above api call is initiated by `loadOrgSaga` in `modules/user/saga`.

export const FEATURE_FLAGS = {
  hasEmc: 'has_emc',
  hasGoogleAds: 'has_google_ads',
  hasCampaignForecasting: 'has_campaign_forecasting',
  hasCrmContact: 'has_crm_contact',
  hasCrmLead: 'has_crm_lead',
  hasMapContact: 'has_map_contact',
  hasLinkedinAdvertising: 'has_linkedin_advertising',
  hasOptimizeViewability: 'has_optimize_viewability',
  hasCpmRecommendation: 'has_cpm_recommendation',
  hasBrandSafety: 'has_brand_safety',
  peerspotEnabled: 'peerspot_enabled',
  hasAdvertisingDesignSystem: 'has_advertising_design_system',
  hasPredictive: 'has_predictive',
  hasZeniq: 'has_zeniq',
  hasAdvertisingReports: 'has_advertising_reports',
  hasMi6qaAnalyticsEnabled: 'mi_6qa_analytics_enabled',
  hasSixQABenchmarkEnabled: 'has_6qa_benchmark_enabled',
  showOpportunitiesInSprDownloads: 'show_opps_in_spraccnt_download',
  hasCampaignBusinessDescriptor: 'has_campaign_business_descriptor',
  hasAccountPacing: 'has_account_pacing',
  hasGoogleConsentForEu: 'has_google_consent_for_eu',
  shouldCommandbarDisplay: 'should_commandbar_display',
  hasAdvertisingDashboard: 'has_advertising_dashboard',
  gongEnabled: 'gong_enabled',
  enableNewModelReport: 'enable_new_model_report',
  hasRegionTargetingBeta: 'has_region_targeting_beta',
  hasAudienceWorkflowsGA: 'has_awf_ga',
  is_2fa_enabled: 'is_2fa_enabled',
  hasRAIMDashboard: 'has_raim_dashboard',
};
