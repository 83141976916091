import { filter, get, remove, toSafeInteger } from "lodash";
import { DATE_FILTER_OPTIONS_VALUES } from "../../components/DateFilter/constants";
import { getAllChoicesForFilterOption } from "./components/FilterByPanel/utils";
import { campaignClassificationDucks } from "routes/AdvertisingDS/ducks";
import { getLastUtcDay, treeNodeKeysUtils } from "routes/AdvertisingDS/utils";
import {
  AD_TYPES,
  ADVERTISING_CATEGORIES,
} from "routes/AdvertisingDS/constants";

const {
  constants: { CLASSIFICATION_TYPES, CAMPAIGN_CLASSIFICATION_ACTIONS },
} = campaignClassificationDucks;

export const filtersStateToQueryParamsObject = (
  filtersState,
  filterOptions
) => {
  if (Boolean(filtersState) === false) return {};
  const allAvailableChoices = getAllChoicesForFilterOption(filterOptions);

  const defaultFilterBuilder = (filterKey, filterValue) => {
    const value = Object.keys(filterValue).filter(
      (choice) =>
        filterValue[choice] &&
        get(allAvailableChoices, `${filterKey}`, []).includes(choice)
    );

    if (value.length !== 0) {
      return { [filterKey]: value };
    }
    return {};
  };

  const filterParams = Object.keys(filtersState).reduce(
    (acc, filterkey) => ({
      ...acc,
      ...defaultFilterBuilder(filterkey, filtersState[filterkey]),
    }),
    {}
  );

  return filterParams;
};

export function flattenCampaignSelections(campaignListSelections) {
  return Object.keys(campaignListSelections).reduce((acc, pageNumber) => {
    const pageSelections = get(campaignListSelections, pageNumber, {});
    const selectedIds = Object.keys(pageSelections)
      .filter((id) => pageSelections[id])
      .map((id) => parseInt(id));

    return [...acc, ...selectedIds];
  }, []);
}

export const getAllSelectedCampaigns = (selectedCampaigns) =>
  Object.keys(selectedCampaigns).reduce((accumulator, pageNumber) => {
    const selectedCampaignsOnPage = get(selectedCampaigns, pageNumber, {});
    const campaignIds = Object.keys(selectedCampaignsOnPage);
    const selectedCampaignData = filter(
      campaignIds.map((id) => ({
        id: toSafeInteger(id),
        ...selectedCampaignsOnPage[id],
      }))
    );

    return [...accumulator, ...selectedCampaignData];
  }, []);

export const mapColumnKeys = (columns) =>
  columns && columns.map(({ key }) => key);

export const statsDateRangeParams = (value, from, to) => {
  const stats_start_date = from;
  const stats_end_date = to;
  if (value === DATE_FILTER_OPTIONS_VALUES.dateRange) {
    return { stats_start_date, stats_end_date };
  }
  if (value === DATE_FILTER_OPTIONS_VALUES.lifetime) {
    return {};
  }

  return {
    stats_start_date: getLastUtcDay()
      .subtract(value, "day")
      .format("YYYY-MM-DD"),
    stats_end_date: getLastUtcDay().format("YYYY-MM-DD"),
  };
};

export const getActionForSelectedCampaigns = (
  selectedCampaignClassifications
) => {
  const { move, restore } = CAMPAIGN_CLASSIFICATION_ACTIONS;
  const campaignClassifications = [...selectedCampaignClassifications];
  // Note: remove alters campaignClassifications by removing the match
  const archivedClassification = remove(
    campaignClassifications,
    (classificationType) =>
      classificationType === CLASSIFICATION_TYPES.CAMPAIGNS_ARCHIVED
  );
  const allAreArchived =
    archivedClassification.length > 0 && campaignClassifications.length === 0;
  const someAreArchived =
    archivedClassification.length > 0 && campaignClassifications.length > 0;
  if (allAreArchived) return restore;
  if (someAreArchived) return `${restore}/ ${move}`;
  return CAMPAIGN_CLASSIFICATION_ACTIONS.move;
};

export const isCampaignCategoryFolder = (treeNodeKey) => {
  const folderCategory =
    treeNodeKeysUtils.extractCategoryFromPrefix(treeNodeKey);
  return folderCategory === ADVERTISING_CATEGORIES.CAMPAIGNS;
};

const AD_TYPE_AND_LOGO_MAP = Object.values(AD_TYPES).reduce(
  (acc, { types, logo }) => {
    const accCopy = { ...acc };
    types.forEach(({ id }) => {
      accCopy[id] = logo;
    });
    return accCopy;
  },
  {}
);

export const adTypeLabel = (campaignSubType) =>
  AD_TYPE_AND_LOGO_MAP[campaignSubType];
