import React from 'react';

/* eslint max-len: [2, 4000] */
// Using 1000 for now.... might need to bump it up
export function BrokenImageIcon(props) {
  return (
    <svg
      {...props}
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <title>BrokenImage Icon</title>
      <desc>BrokenImage Logo</desc>
      <defs>
        <path
          d="M21,5 L21,11.59 L18,8.58 L14,12.59 L10,8.59 L6,12.59 L3,9.58 L3,5 C3,3.9 3.9,3 5,3 L19,3 C20.1,3 21,3.9 21,5 L21,5 Z M18,11.42 L21,14.43 L21,19 C21,20.1 20.1,21 19,21 L5,21 C3.9,21 3,20.1 3,19 L3,12.42 L6,15.41 L10,11.41 L14,15.41 L18,11.42 L18,11.42 Z"
          id="path-1"
        />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Material_icons_library" transform="translate(-435.000000, -3908.000000)">
          <g id="ic_broken_image" transform="translate(432.000000, 3905.000000)">
            <mask id="mask-2" fill="white">
              <use href="#path-1" />
            </mask>
            <g id="Mask" />
            <g id="Colors/Gray.2" mask="url(#mask-2)" fill="#C1C7CC">
              <g transform="translate(-13.000000, -13.000000)">
                <path d="M0,0 L50,0 L50,50 L0,50 L0,0 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
