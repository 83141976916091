import { PERFORMANCE_TREND_ROUTE } from "routes/AdvertisingDS/constants";

export default function createRoutes() {
  return {
    path: PERFORMANCE_TREND_ROUTE,
    name: "Performance Trend",
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require("./containers");
          cb(null, containers.PerformanceTrend);
        },
        "performanceTrend"
      );
    },
  };
}
