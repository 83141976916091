import { setIn } from 'utils/utils';
import { omit, cloneDeep } from 'lodash';

const CREATE_AST = 'AST-REDUX/CREATE_AST';
const createAst = (astKey, InitialAst) => ({
  type: CREATE_AST,
  astKey,
  InitialAst,
});

const REPLACE_EXPR = 'AST-REDUX/REPLACE_EXPR';
const replaceExpr = (astKey) => (expr, breadcrumbs) => ({
  type: REPLACE_EXPR,
  astKey,
  expr,
  breadcrumbs,
});

const RESET_AST = 'AST-REDUX/RESET_AST';
const resetAst = (astKey) => ({
  type: RESET_AST,
  astKey,
});

const DESTROY_AST = 'AST-REDUX/DESTROY_AST';
const destroyAst = (astKey) => ({
  type: DESTROY_AST,
  astKey,
});

const UPDATE_SAVED_AST = 'AST-REDUX/UPDATE_SAVED_AST';
const updateSavedAst = (astKey) => ({
  type: UPDATE_SAVED_AST,
  astKey,
});

export const createReducer = () => {
  const InitialState = {};
  const astReducer = (state = InitialState, action) => {
    switch (action.type) {
      case CREATE_AST: {
        const { astKey, InitialAst } = action;
        return {
          ...state,
          [astKey]: {
            ast: cloneDeep(InitialAst),
            InitialAst,
          },
        };
      }
      case REPLACE_EXPR: {
        const { breadcrumbs, expr } = action;
        return setIn(breadcrumbs, expr, state);
      }
      case RESET_AST: {
        const { astKey } = action;
        return {
          ...state,
          [astKey]: {
            ...state[astKey],
            ast: state[astKey].InitialAst,
          },
        };
      }
      case UPDATE_SAVED_AST: {
        const { astKey } = action;
        if (!state[astKey]) {
          return state;
        }
        return {
          ...state,
          [astKey]: {
            ...state[astKey],
            InitialAst: state[astKey].ast,
          },
        };
      }
      case DESTROY_AST: {
        const { astKey } = action;
        return omit(state, astKey);
      }
      default:
        return state;
    }
  };
  return astReducer;
};

export const actions = {
  createAst,
  replaceExpr,
  resetAst,
  destroyAst,
  updateSavedAst,
};
