import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: '/login',
    name: 'Login',
    indexRoute: {
      onEnter: (nextState, replace) => {
        if (window.process.env.LOGIN_FLOW) {
          return replace('/');
        }
        return replace(window.process.env.LOGIN_SERVICE_ENDPOINT);
      },
    },
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('login', reducer.loginReducer);
        injectSagas('login', sagas.default);
        cb(null, containers.Login);
      }, 'auth_login');
    },
  };
}
