import React from 'react';
// @ts-ignore
import microsoftLogo from 'images/ms-office-logo.svg';
// @ts-ignore
import googleLogo from 'images/google-logo.svg';
import { Database, LayersThree, BuildingDouble, Lightbulb } from '@sixsense/core/icons';
import { FeaturedIcon } from '@sixsense/core/components';


export const ESI_BASE_ACTION_NAME = 'settings/integration/email-server';
export const ESI_REFRESH_ACTION_NAME = 'settings/integration/email-server/REFRESH';

export const ESI_ROUTE = 'email-server';
export const ESI_STATE_KEY = 'emailserver';

export const ESI_FORM_NAMES = Object.freeze({
  CONNECT: 'esi_connect',
  CONFIGURE: 'esi_configure',
});

export const ESI_FORM_STEP_IDS = Object.freeze({
  CONNECT: 'esi_connect',
  CONFIGURE: 'esi_configure',
});

export const ESI_SUPPORTED_SERVER_NAMES = Object.freeze({
  MICROSOFT: 'microsoft',
  GOOGLE: 'google',
});

export const EmailServerSummaryConfig = {
  [ESI_SUPPORTED_SERVER_NAMES.MICROSOFT]: {
    header: {
      logo: microsoftLogo,
      mainTitle: 'Microsoft',
      category: 'Email server',
      subTitle: 'by Microsoft.Inc',
    },
    about: {
      mainInfo: {
        title: 'About this Integration',
        description:
          'Integrating with Office 365 gives users the ability to easily sync contact '+
          'information from their emails,'+
          ' address book, and calendar, and add them to your company’s CRM. Gain greater '+
          'visibility into buying teams by capturing all contacts from the email server,'+
          ' allowing for richer contact-level insights. Capture revenue moments to better '+
          'understand individuals within the buying committee and engage'+
          ' the right accounts at the right time.',
      },
      metaInfo: [
        {
          name: 'Category',
          value: 'Email Server',
        },
        {
          name: 'Feature Support',
          value: 'Orchestration',
        },
      ],
      keyfeatures: {
        title: 'Key Features',
        features: [
          {
            icon: <FeaturedIcon icon={Database} color="Gray" />,
            name: 'Enrich CRM data',
            value: 'Leveraging email server data',
          },
          {
            icon: <FeaturedIcon icon={LayersThree} color="Gray" />,
            name: 'Create segments',
            value:
              'With rich, contact-level insights',
          },
          {
            icon: <FeaturedIcon icon={BuildingDouble} color="Gray" />,
            name: 'Engage the Right Accounts at the Right Time',
            value: 'With greater visibility into buying teams',
          },
          {
            icon: <FeaturedIcon icon={Lightbulb} color="Gray" />,
            name: 'Enable Orchestrations',
            value: 'Using accurate, robust data',
          },
        ],
      },
    },
    help: {
      links: [
        {
          title: 'Additional Resources',
          list: [
            {
              // eslint-disable-next-line max-len
              href: 'https://support.6sense.com/knowledge-base/4414672263827-email-server-integration-microsoft-office-365-setup/',
              text: 'Microsoft - Email Server setup',
            },
          ],
        },
      ],
    },
  },
  [ESI_SUPPORTED_SERVER_NAMES.GOOGLE]: {
    header: {
      logo: googleLogo,
      mainTitle: 'Google',
      category: 'Email server',
      subTitle: 'by Google Inc',
    },
    about: {
      mainInfo: {
        title: 'About this Integration',
        description:
         'By integrating Gsuite , users can easily sync contact information from their emails, '+
         'address book, and calendar, and add them to your company’s CRM. '+
         'Gain greater visibility into buying teams by capturing all contacts'+
         ' from the email server, allowing for richer contact-level insights. '+
         'Capture revenue moments to better understand individuals within the buying '+
         'group and engage the right accounts at the right time.',
      },
      metaInfo: [
        {
          name: 'Category',
          value: 'Email server',
        },
        {
          name: 'Feature Support',
          value: 'Orchestration',
        },
      ],
      keyfeatures: {
        title: 'Key Features',
        features: [
          {
            icon: <FeaturedIcon icon={Database} color="Gray" />,
            name: 'Enrich CRM data',
            value: 'Leveraging email server data',
          },
          {
            icon: <FeaturedIcon icon={LayersThree} color="Gray" />,
            name: 'Create segments',
            value:
              'With rich, contact-level insights',
          },
          {
            icon: <FeaturedIcon icon={BuildingDouble} color="Gray" />,
            name: 'Engage the Right Accounts at the Right Time',
            value: 'With greater visibility into buying teams',
          },
          {
            icon: <FeaturedIcon icon={Lightbulb} color="Gray" />,
            name: 'Enable Orchestrations',
            value: 'Using accurate, robust data',
          },
        ],
      },
    },
    help: {
      links: [
        {
          title: 'Additional Resources',
          list: [
            {
               // eslint-disable-next-line max-len
              href: 'https://support.6sense.com/knowledge-base/4414689746579-email-server-integration-google-suite-setup/',
              text: 'Google Suite Server setup',
            },
          ],
        },
      ],
    },
  },
};
