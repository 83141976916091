import { browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { makeSelectLocationState } from 'modules/global/selectors';
import { isSiteLoadedInIframe } from 'utils/utils';

export const getHistory = (store) => {
  const history = syncHistoryWithStore(browserHistory, store, {
    selectLocationState: makeSelectLocationState(),
  });

  // Check if the site is loaded within an iframe
  if (isSiteLoadedInIframe()) {
    // Listen for changes in the browser history before navigation
    history.listenBefore((location) => {
      const isSettingFramework = window.location.pathname.startsWith('/settings');
      const isNotNavigatingToSettingFramework = !location.pathname.startsWith('/settings');
      // Check if the navigation action is 'PUSH' and the path does not start with '/settings'
      if (isSettingFramework && location.action === 'PUSH' && isNotNavigatingToSettingFramework) {
        // Open the new location in a new browser tab
        window.open(`${location.pathname}${location.search}`, '_blank');
        // Prevent the default navigation behavior
        return false;
      }
      // Continue with the default navigation behavior for other scenarios
      return true;
    });
  }

  return history;
};
