import { defineSharedState } from '@sixsense/core';
import { Product } from '../types';

export * from './config';
export * from './deactivated';
export * from './groups';
export * from './keywords';

const PATH_PREFIX = 'settings.keywordsPage';

export const orgProductState = defineSharedState<Array<Product>>({
  path: `${PATH_PREFIX}.products`,
  defaultValue: [],
});
