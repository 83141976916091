import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'v2-styles/constants';
import { Icon, Text, Tooltip2 as Tooltip } from 'v2-components';
import { classNames } from 'utils/utils';
import styles from './CampaignPerformanceIndicator.module.scss';
import {
  CAMPAIGN_PERFORMANCE_STATE_TOOLTIPS,
  CAMPAIGN_PERFORMANCE_STATES,
  CAMPAIGN_STATUS_X,
} from '../../constants';
import { get } from 'lodash';
import {
  getCampaignPerformanceIndicatorColor,
} from '../../utils';

const simpleIndicator = (color = COLORS.AA_GREY3) => (<div
  style={{
    background: color,
    width: 5,
    borderRadius: 2.5,
    height: 18,
  }}
/>);

const toString = (campaign) => {
  const performanceState = get(campaign, 'campaign_performance.performance_state');
  return CAMPAIGN_PERFORMANCE_STATES[performanceState].label;
};

const CampaignPerformanceIndicator = ({
  campaign,
  className,
  hideTooltip,
  tooltipPlacement,
}) => {
  const isDeactivatedCampaign = get(campaign, 'campaign_status') === CAMPAIGN_STATUS_X.deactivated;
  const isCompletedCampaign = get(campaign, 'campaign_status') === CAMPAIGN_STATUS_X.completed;

  const {
    no_delivery,
  } = CAMPAIGN_PERFORMANCE_STATES;

  const performanceState = get(campaign, 'campaign_performance.performance_state');
  const label = (() => {
    const plainStringLabel = toString(campaign);

    const performanceStateLabel = () => {
      const ll = plainStringLabel;

      if ([no_delivery.key].includes(performanceState)) {
        return (<Text
          type={Text.TYPE.SUBBODY}
          color={Text.COLOR.ERROR}
        >
          {ll}
        </Text>);
      }

      return ll;
    };

    return performanceStateLabel();
  })();

  const indicatorColor = getCampaignPerformanceIndicatorColor(performanceState);

  const getCampaignIndicatorStatus = () => {
    if (isCompletedCampaign) {
      return (<Icon className={styles.icon} type={'check_circle_outline'} color={COLORS.GREEN} />);
    }
    if (isDeactivatedCampaign) {
      return (<Icon className={styles.icon} color={COLORS.GREY3} type={'block'} />);
    }

    return simpleIndicator(indicatorColor);
  };

  const indicator = getCampaignIndicatorStatus();

  const campaignHasNoEndDate = get(campaign, 'end_date', false);
  const tooltip = campaignHasNoEndDate ?
      CAMPAIGN_PERFORMANCE_STATE_TOOLTIPS[performanceState].tooltip_with_end_date :
      CAMPAIGN_PERFORMANCE_STATE_TOOLTIPS[performanceState].tooltip_with_no_end_date;

  const tooltipMessage = (<React.Fragment>
    <Text color="white">
      {tooltip}.&nbsp;
    </Text>
    <Text><a
      href="https://support.6sense.com/hc/en-us/articles/360055344253"
      target="_blank"
      rel="noopener noreferrer"
      style={{ marginLeft: '3px', color: 'white', textDecoration: 'underline' }}
    >
        Learn More
      </a>
    </Text>
  </React.Fragment>
  );

  return (<div className={classNames(styles.campaignPerformanceIndicator, className)}>
    <span className={styles.indicator}>{indicator}</span>
    <Text
      type={Text.TYPE.SUBBODY}
    >
      <Tooltip
        overlay={hideTooltip ? null : tooltipMessage}
        placement={tooltipPlacement}
        destroyTooltipOnHide
      >
        {label}
      </Tooltip>
    </Text>
  </div>);
};
CampaignPerformanceIndicator.propTypes = {
  campaign: PropTypes.object,
  className: PropTypes.string,
  hideTooltip: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
};
CampaignPerformanceIndicator.defaultProps = {
  hideTooltip: false,
  tooltipPlacement: 'right',
};
CampaignPerformanceIndicator.toString = toString;

export default CampaignPerformanceIndicator;
