import Select from './Select';
import React, { useMemo, useEffect } from 'react';
import { css } from 'styles/emotion';
import { useSharedValue } from '@sixsense/core/shared-state';
import { orgProductState } from '../state';
import { AddKeywordsConfig } from '../types';
import { KW_EXP, OTHER } from '../constants';

const styles = {
  sentence: css({
    color: '#001F32',
    fontSize: '24px',
    lineHeight: '36px',
    fontFamily: 'Gelasio !important',
    fontWeight: 'bold',
    height: '40px',
  }),
  static: css({
    color: '#505C62',
  }),
  cancelButton: css({
    marginRight: 10,
  }),
  mask: css({
    top: '212px',
    right: '0',
    left: '0',
    bottom: '0',
    width: '100%',
    height: '100%',
    background: '#373737',
    opacity: '0.6',
    position: 'fixed',
    zIndex: 10,
  }),
  hideMask: css({
    display: 'none',
  }),
  error: css({
    marginTop: '10px',
  }),
};

type Props = {
  setConfig: any;
  config: AddKeywordsConfig;
};


const ConfigSentence = ({ config, setConfig }: Props) => {
  const products = useSharedValue(orgProductState);
  const productOptions = useMemo(
    () => products.map((p) => ({
      value: p.id,
      label: p.displayName === KW_EXP ? OTHER : p.displayName,
    })),
    [products]
  );

  useEffect(() => {
    if (!config.product_id && productOptions.length === 1) {
      setConfig({ ...config, product_id: productOptions[0].value });
    }
  }, [config, productOptions]);

  const keywordCategories = [
    { label: 'Branded', value: 'branded' },
    { label: 'Generic', value: 'generic' },
  ];

  return (
    <React.Fragment>
      <div className={styles.sentence}>
        <span>"</span>
        <span>I want to add</span>
        <span>
          {' '}
          <Select
            placeholder="choose a product category"
            value={config.product_id}
            onChange={(product_id) => setConfig({ ...config, product_id })}
            options={productOptions}
          />
        </span>
        <span>
          {' '}
          <Select
            placeholder="choose keyword type"
            value={config.category}
            onChange={(category) => setConfig({ ...config, category })}
            options={keywordCategories}
          />
        </span>
        <span> keywords."</span>
        <span style={{ display: 'inline-block', width: 20 }} />
      </div>
    </React.Fragment>
  );
};

export default ConfigSentence;
