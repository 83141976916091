import React from 'react';
import { Tooltip2 as Tooltip, Icon, Text } from 'v2-components';
import UTMBuilder from './index';
import { classNames } from 'utils/utils';
import styles from './UTMBuilder.module.scss';
import { PropTypes } from 'prop-types';

const UTMBuilderLink = ({ url, updateUrl, urlHasError, disabled, ...props }) => (
  <UTMBuilder
    url={url}
    updateUrl={updateUrl}
    {...props}
  >
    {({ openModal }) => <Tooltip
      className={styles.height100}
      style={{ height: '100%' }}
      overlay={
        urlHasError ? 'Enter a valid URL to be be able to add UTM Parameters.' : 'UTM builder'
      }
    >
      <Text
        className={classNames(
            styles.utmBuilder,
            urlHasError ? styles.utmBuilderDisabled : styles.utmBuilderActive
        )}
        onClick={(urlHasError || disabled) ? null : openModal}
        disabled={disabled}
      >
        <Icon type={'add_link'} className={styles.utmBuilderIcon} disabled={disabled} />
      </Text>
    </Tooltip>}
  </UTMBuilder>
  );

UTMBuilderLink.propTypes = {
  url: PropTypes.string,
  updateUrl: PropTypes.func,
  urlHasError: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default UTMBuilderLink;
