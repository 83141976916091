import React from 'react';
import { Icon } from 'v2-components';
import { css, cx } from 'styles/emotion';
import { Card, Grid } from '@sixsense/core/components';
import { Role } from '../../types';
import { getRolePermissionConfigByAppId } from '../constants';
import { AAColor, pxToRem } from '@sixsense/core/style';
import { useOrg } from 'hooks';
import { featureFlagsSelector } from '@sixsense/core/featureFlags';
import { useSelector } from '@sixsense/core/versioned/react-redux';

const styles = {
  groupTitle: css({
    textTransform: 'uppercase',
    color: '#001F32',
    fontSize: pxToRem(16),
    fontWeight: 'bold',
  }),
  permissionLevel: css({
    textTransform: 'uppercase',
    color: '#505C62',
    fontSize: pxToRem(14),
    fontWeight: 'bold',
  }),
  permissionTitle: css({
    color: '#001F32',
    fontSize: pxToRem(14),
    fontWeight: 'bold',
    marginTop: pxToRem(16),
    marginBottom: pxToRem(4),
  }),
  permissionDescription: css({
    color: '#505C62',
    fontSize: pxToRem(14),
    marginBottom: pxToRem(16),
  }),
  center: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  icon: css({
    marginTop: pxToRem(16),
    marginBottom: pxToRem(4),
  }),
  packageHint: css({
    color: AAColor.GREY,
    marginTop: 8,
    fontStyle: 'italic',
  }),
};

type Props = {
  role: Role;
  appId: number;
}

const RolePermissionsCard = ({ role, appId }: Props) => {
  const { addons, packages } = useOrg();
  const { flags } = useSelector(featureFlagsSelector);

  return (
    <Card>
      {getRolePermissionConfigByAppId(appId).map((group, gIdx) => (
        <React.Fragment key={group.title}>
          <Card.Content>
            <Grid templateColumns="25% 15% 15%">
              <Grid.Cell row={1} column={1} className={styles.groupTitle}>{group.title}</Grid.Cell>
              <Grid.Cell row={1} column={2} className={cx(styles.permissionLevel, styles.center)}>
                EDIT
              </Grid.Cell>
              <Grid.Cell row={1} column={3} className={cx(styles.permissionLevel, styles.center)}>
                VIEW
              </Grid.Cell>
              {group.permissions
              .filter(({ featureCheck = () => true }) => featureCheck({ flags }))
              .map(({ accessCheck = () => true, ...config }, i) => (
                <React.Fragment key={config.title}>
                  <Grid.Cell row={i * 2 + 2} column={1} className={styles.permissionTitle}>
                    {config.title}{' '}
                    {accessCheck({ addons, packages }) ? undefined : (
                      <Icon
                        type="lock"
                        size={Icon.SIZE.SMALL}
                        color={Icon.COLORS.GREY}
                      />
                    )}
                  </Grid.Cell>
                  <Grid.Cell row={i * 2 + 3} column={1} className={styles.permissionDescription}>
                    {config.description}

                    {accessCheck({ addons, packages }) ? undefined : (
                      <div className={styles.packageHint}>
                        (Your subscription does not include this feature)
                      </div>
                    )}
                  </Grid.Cell>
                  <Grid.Cell row={i * 2 + 2} column={2} className={styles.center}>
                    {role.permissions.includes(config.edit) ? (
                      <Icon size="24px" type="check" className={styles.icon} />
                    ) : null}
                  </Grid.Cell>
                  <Grid.Cell row={i * 2 + 2} column={3} className={styles.center}>
                    {(
                      role.permissions.includes(config.edit) ||
                      role.permissions.includes(config.view)
                    ) ? (
                      <Icon size="24px" type="check" className={styles.icon} />
                    ) : null}
                  </Grid.Cell>
                </React.Fragment>
              ))}
            </Grid>
          </Card.Content>
          {gIdx < (getRolePermissionConfigByAppId(appId).length - 1) ? <Card.Divider /> : null}
        </React.Fragment>
      ))}
    </Card>
  );
};

export default RolePermissionsCard;
