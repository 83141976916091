import { css } from 'styles/emotion';
import React from 'react';
import { Text } from 'v2-components';
import { pxToRem } from '@sixsense/core/style';

const styles = {
  headerWrap: css({
    paddingTop: pxToRem(20),
    paddingRight: pxToRem(30),
    paddingBottom: pxToRem(15),
    paddingLeft: pxToRem(30),
  }),
  headerTitle: css({

  }),
};
export const ActivitiesCardHeader = () => (
  <div className={styles.headerWrap}>
    <Text bold className={styles.headerTitle}>
      Customize Visible Activities
    </Text>
    <br />
    <Text type={Text.TYPE.SUBBODY}>
      Only the activity types selected below will be
      visible within the Activities graph and timeline
      sections of Sales Intelligence. These selections
      do not affect your predictive scores.
    </Text>
  </div>
);
