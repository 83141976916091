import { omit } from "lodash";
import { combineReducers } from "redux";
import { campaignsStateGenerators } from "./ducks";
import { campaignLabelsStateGenerator } from "./stateGenerators";
import { updateFolderSelection } from "../../actions";
import { genCampaignTreeNodeKey } from "../../utils";

const { bulkUpdateStateGenerator: campaignsBulkUpdateStateGenerator } =
  campaignsStateGenerators;

const LOAD_CAMPAIGN_PAGE = "CAMPAIGN/LOAD_CAMPAIGN_PAGE";
function load() {
  return { type: LOAD_CAMPAIGN_PAGE };
}

const LOAD_CAMPAIGN_REQUEST = "CAMPAIGN/LOAD_CAMPAIGN_REQUEST";
const LOAD_CAMPAIGN_FAILURE = "CAMPAIGN/LOAD_CAMPAIGN_FAILURE";
const LOAD_CAMPAIGN_SUCCESS = "CAMPAIGN/LOAD_CAMPAIGN_SUCCESS";
function loadCampaign(loading = true, campaignList) {
  return { type: LOAD_CAMPAIGN_REQUEST, loading, campaignList };
}
function loadCampaignSuccess(campaignsOrg) {
  return { type: LOAD_CAMPAIGN_SUCCESS, campaignsOrg };
}
function loadCampaignFailure(errorMessage, errorStatus) {
  return {
    type: LOAD_CAMPAIGN_FAILURE,
    loading: false,
    error: true,
    loaded: true,
    errorMessage,
    errorStatus,
  };
}

// start Transaction
const START_TRANSACTION = "CAMPAIGN/START_TRANSACTION";
const START_TRANSACTION_SUCCESS = "CAMPAIGN/START_TRANSACTION_SUCCESS";
const START_TRANSACTION_FAILURE = "CAMPAIGN/START_TRANSACTION_FAILURE";
function startTransaction() {
  return { type: START_TRANSACTION };
}
function startTransactionSuccess(transactionId) {
  return { type: START_TRANSACTION_SUCCESS, transactionId };
}
function startTransactionFailure(errorMessage) {
  return { type: START_TRANSACTION_FAILURE, errorMessage };
}

// end transaction
const END_TRANSACTION = "CAMPAIGN/END_TRANSACTION";
const END_TRANSACTION_SUCCESS = "CAMPAIGN/END_TRANSACTION_SUCCESS";
const END_TRANSACTION_FAILURE = "CAMPAIGN/END_TRANSACTION_FAILURE";
function endTransaction() {
  return { type: END_TRANSACTION };
}
function endTransactionSuccess() {
  return { type: END_TRANSACTION_SUCCESS };
}
function endTransactionFailure(errorMessage) {
  return { type: END_TRANSACTION_FAILURE, errorMessage };
}
// clone campaign
const SAVE_CLONE_CAMPAIGN = "CAMPAIGN/SAVE_CLONE_CAMPAIGN";
const CLONE_CAMPAIGN_LOADING = "CAMPAIGN/CLONE_CAMPAIGN_LOADING";
const SAVE_CLONE_CAMPAIGN_FAIL = "CAMPAIGN/SAVE_CLONE_CAMPAIGN_FAIL";
const SHOW_CAMPAIGN_CLONE_MODAL = "CAMPAIGN/SHOW_CAMPAIGN_CLONE_MODAL";
const CLOSE_CAMPAIGN_CLONE_MODAL = "CAMPAIGN/CLOSE_CAMPAIGN_CLONE_MODAL";
function showCampaignCloneModal(campaign, folderList) {
  return { type: SHOW_CAMPAIGN_CLONE_MODAL, campaign, folderList };
}
function saveCloneCampaign(formValues) {
  return { type: SAVE_CLONE_CAMPAIGN, formValues };
}
function setCloneCampaignLoading(isLoading) {
  return { type: CLONE_CAMPAIGN_LOADING, isLoading };
}
function saveCloneCampaignFail(errorMessage, errorStatus) {
  return { type: SAVE_CLONE_CAMPAIGN_FAIL, errorMessage, errorStatus };
}
function closeCampaignCloneModal() {
  return { type: CLOSE_CAMPAIGN_CLONE_MODAL };
}

// deactivate campaign
const DEACTIVATE_CAMPAIGN = "CAMPAIGN/DEACTIVATE_CAMPAIGN";
const DEACTIVATE_CAMPAIGN_FAIL = "CAMPAIGN/DEACTIVATE_CAMPAIGN_FAIL";
const SHOW_DEACTIVATION_MODAL = "CAMPAIGN/SHOW_DEACTIVATION_MODAL";
const CLOSE_DEACTIVATION_MODAL = "CAMPAIGN/CLOSE_DEACTIVATION_MODAL";

function showDeactivationModal(campaign) {
  return { type: SHOW_DEACTIVATION_MODAL, campaign };
}
function deactivateCampaign(formValues) {
  return { type: DEACTIVATE_CAMPAIGN, formValues };
}
function deactivateCampaignFail(errorMessage, errorStatus) {
  return { type: DEACTIVATE_CAMPAIGN_FAIL, errorMessage, errorStatus };
}
function closeDeactivationModal() {
  return { type: CLOSE_DEACTIVATION_MODAL };
}

// activate and delete
const CAMPAIGN_STATE_ACTION_REQUEST = "CAMPAIGN/CAMPAIGN_STATE_ACTION_REQUEST";
const CAMPAIGN_STATE_ACTION_SUCCESS = "CAMPAIGN/CAMPAIGN_STATE_ACTION_SUCCESS";
const CAMPAIGN_STATE_ACTION_FALIURE = "CAMPAIGN/CAMPAIGN_STATE_ACTION_FALIURE";
const CAMPAIGN_STATE_ACTION_PROMPT = "CAMPAIGN/CAMPAIGN_STATE_ACTION_PROMPT";
function campaignStateActionPrompt(
  modalVisible,
  campaign,
  stateAction,
  stateMessage
) {
  return {
    type: CAMPAIGN_STATE_ACTION_PROMPT,
    modalVisible,
    campaign,
    stateAction,
    stateMessage,
  };
}
function campaignStateAction(campaign, stateAction) {
  return {
    type: CAMPAIGN_STATE_ACTION_REQUEST,
    campaign,
    stateAction,
    loading: true,
    modalVisible: true,
    error: false,
  };
}
function campaignStateActionSuccess(campaignId, stateAction) {
  return {
    type: CAMPAIGN_STATE_ACTION_SUCCESS,
    campaignId,
    stateAction,
    loading: false,
    error: false,
    modalVisible: false,
  };
}
function campaignStateActionFailure(
  errorMessage,
  errorStatus,
  campaignId,
  stateAction
) {
  return {
    type: CAMPAIGN_STATE_ACTION_FALIURE,
    campaignId,
    loading: false,
    error: true,
    modalVisible: false,
    stateAction,
    errorMessage,
    errorStatus,
  };
}

// download campaign
const SHOW_DOWNLOAD_CAMPAIGN_MODAL = "CAMPAIGN/SHOW_DOWNLOAD_CAMPAIGN_MODAL";
const DOWNLOAD_CAMPAIGN = "CAMPAIGN/DOWNLOAD_CAMPAIGN";
const DOWNLOAD_CAMPAIGN_SUCCESS = "CAMPAIGN/DOWNLOAD_CAMPAIGN_SUCCESS";
const DOWNLOAD_CAMPAIGN_FAILURE = "CAMPAIGN/DOWNLOAD_CAMPAIGN_FAILURE";
const DOWNLOAD_ACCOUNT = "CAMPAIGN/DOWNLOAD_ACCOUNT";
const DOWNLOAD_ACCOUNT_SUCCESS = "CAMPAIGN/DOWNLOAD_ACCOUNT_SUCCESS";
const DOWNLOAD_ACCOUNT_FAILURE = "CAMPAIGN/DOWNLOAD_ACCOUNT_FAILURE";

function showDownloadModal(visible, campaign, campaignCategory) {
  return {
    type: SHOW_DOWNLOAD_CAMPAIGN_MODAL,
    visible,
    campaign,
    campaignCategory,
  };
}
function downloadCampaignDetails(
  downloadOption,
  showWith0Impressions,
  campaign,
  campaignCategory,
  campaignSource
) {
  return {
    type: DOWNLOAD_CAMPAIGN,
    campaign,
    downloadOption,
    showWith0Impressions,
    campaignCategory,
    campaignSource,
  };
}
function downloadCampaignSuccess() {
  return {
    type: DOWNLOAD_CAMPAIGN_SUCCESS,
    loading: false,
    visible: false,
  };
}
function downloadCampaignFailure(errorMessage, errorStatus) {
  return {
    type: DOWNLOAD_CAMPAIGN_FAILURE,
    errorMessage,
    errorStatus,
    loading: false,
    error: true,
  };
}
function downloadAccountDetails(selValue, campaign) {
  return {
    type: DOWNLOAD_ACCOUNT,
    byCRMId: selValue === "crm",
    campaign,
  };
}
function downloadAccountSuccess() {
  return {
    type: DOWNLOAD_ACCOUNT_SUCCESS,
    loading: false,
    visible: false,
  };
}
function downloadAccountFailure(errorMessage, errorStatus) {
  return {
    type: DOWNLOAD_ACCOUNT_FAILURE,
    errorMessage,
    errorStatus,
    loading: false,
    error: true,
  };
}
const defaultStatus = {
  loading: true,
  error: false,
  errorStatus: null,
  errorMessage: null,
};
const initialState = {
  loadCampaigns: {
    ...defaultStatus,
    loaded: false,
  },

  // clone
  cloneCampaign: {
    ...defaultStatus,
    loading: false,
    visible: false,
    campaign: null,
    folderList: [],
  },

  // deactivate
  deactivateCampaign: {
    ...defaultStatus,
    loading: false,
    visible: false,
    campaignName: "",
    campaignId: null,
  },

  // activate and delete
  campaignStateAction: {
    stateAction: null,
    loading: false,
    error: false,
    onOk: null,
    errorMessage: null,
    errorStatus: null,
    modalVisible: false,
    stateMessage: null,
  },
  transaction: {
    ...defaultStatus,
    loading: false,
    transactionId: null,
  },

  // download campaign
  download: {
    ...defaultStatus,
    campaign: null,
    loading: false,
    visible: false,
    campaignCategory: "all",
  },
  campaignsOrg: {},
  formFills: {
    ...defaultStatus,
    currentPage: 1,
    recordsPerPage: 10,
    totalCount: 0,
    formsList: [],
    visible: false,
    campaignId: null,
    mid: null,
    campaignOrAccountName: "",
    downloading: false,
    dateFilter: null,
    searchBy: "",
    sortBy: "",
    sortingOrder: "",
  },
  archiveCampaignModal: {
    isVisible: false,
    campaignIds: [],
  },
};

const LOAD_INFLUENCED_CONVERSIONS_FORMS_LIST =
  "CAMPAIGN/LOAD_INFLUENCED_CONVERSIONS_FORMS_LIST";
const loadInfluencedConversionsFormsList = ({
  pageNumber = 1,
  campaignId = null,
  mid = null,
  campaignOrAccountName = "",
  dateFilter = null,
}) => ({
  type: LOAD_INFLUENCED_CONVERSIONS_FORMS_LIST,
  pageNumber,
  campaignId,
  mid,
  campaignOrAccountName,
  dateFilter,
});

const LOAD_MORE_INFLUENCED_CONVERSIONS_FORMS_LIST =
  "CAMPAIGN/LOAD_MORE_INFLUENCED_CONVERSIONS_FORMS_LIST";

const CHANGE_RECORDS_PER_PAGE = "CAMPAIGN/LOAD_RECORDS_PER_PAGE";

const loadMoreInfluencedConversionsFormsList = (pageNumber = 1) => ({
  type: LOAD_MORE_INFLUENCED_CONVERSIONS_FORMS_LIST,
  pageNumber,
  loading: true,
});

const changeRecordsPerPage = (recordsPerPage = 10) => ({
  type: CHANGE_RECORDS_PER_PAGE,
  recordsPerPage,
  loading: true,
});

const SET_INFLUENCED_CONVERSIONS_FORMS_MODAL_VISIBILITY =
  "CAMPAIGN/SET_INFLUENCED_CONVERSIONS_FORMS_MODAL_VISIBILITY";
const setInfluencedConversionsFormsModalVisibility = (visible = false) => ({
  type: SET_INFLUENCED_CONVERSIONS_FORMS_MODAL_VISIBILITY,
  visible,
});

const LOAD_INFLUENCED_CONVERSIONS_FORMS_LIST_SUCCESS =
  "CAMPAIGN/LOAD_INFLUENCED_CONVERSIONS_FORMS_LIST_SUCCESS";
const loadInfluencedConversionsFormsListSuccess = (payload) => ({
  type: LOAD_INFLUENCED_CONVERSIONS_FORMS_LIST_SUCCESS,
  ...payload,
});

const LOAD_INFLUENCED_CONVERSIONS_FORMS_LIST_FAIL =
  "CAMPAIGN/LOAD_INFLUENCED_CONVERSIONS_FORMS_LIST_FAIL";
const loadInfluencedConversionsFormsListFail = (e) => ({
  type: LOAD_INFLUENCED_CONVERSIONS_FORMS_LIST_FAIL,
  errorMessage: JSON.stringify(e),
});

const DOWNLOAD_INFLUENCED_CONVERSIONS_FORMS =
  "CAMPAIGN/DOWNLOAD_INFLUENCED_CONVERSIONS_FORMS";
const downloadInfluencedConversionsForms = () => ({
  type: DOWNLOAD_INFLUENCED_CONVERSIONS_FORMS,
});

const DOWNLOAD_INFLUENCED_CONVERSIONS_FORMS_SUCCESS =
  "CAMPAIGN/DOWNLOAD_INFLUENCED_CONVERSIONS_FORMS_SUCCESS";
const downloadInfluencedConversionsFormsSuccess = () => ({
  type: DOWNLOAD_INFLUENCED_CONVERSIONS_FORMS_SUCCESS,
});

const DOWNLOAD_INFLUENCED_CONVERSIONS_FORMS_FAILS =
  "CAMPAIGN/DOWNLOAD_INFLUENCED_CONVERSIONS_FORMS_FAILS";
const downloadInfluencedConversionsFormsFails = () => ({
  type: DOWNLOAD_INFLUENCED_CONVERSIONS_FORMS_FAILS,
});

const SET_INFLUENCED_CONVERSIONS_FORMS_SEARCH =
  "CAMPAIGN/SET_INFLUENCED_CONVERSIONS_FORMS_SEARCH";
const setInfluencedConversionsFormsSearch = (searchBy = "") => ({
  type: SET_INFLUENCED_CONVERSIONS_FORMS_SEARCH,
  searchBy,
});

const SET_INFLUENCED_CONVERSIONS_FORMS_SORT =
  "CAMPAIGN/SET_INFLUENCED_CONVERSIONS_FORMS_SORT";
const setInfluencedConversionsFormsSort = (sortBy = "", sortingOrder = "") => ({
  type: SET_INFLUENCED_CONVERSIONS_FORMS_SORT,
  sortBy,
  sortingOrder,
});

const updateCampaignFolderSelection = (payload) =>
  updateFolderSelection(payload.map((id) => genCampaignTreeNodeKey(id)));

function cloneCampaignReducer(state = initialState.cloneCampaign, action) {
  switch (action.type) {
    case SHOW_CAMPAIGN_CLONE_MODAL: {
      return {
        ...state,
        campaign: action.campaign,
        folderList: action.folderList,
        visible: true,
      };
    }
    case CLONE_CAMPAIGN_LOADING: {
      return {
        ...state,
        loading: action.isLoading,
      };
    }
    case SAVE_CLONE_CAMPAIGN: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case SAVE_CLONE_CAMPAIGN_FAIL: {
      const { errorMessage, errorStatus } = action;
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage,
        errorStatus,
      };
    }
    case CLOSE_CAMPAIGN_CLONE_MODAL: {
      return {
        ...state,
        ...initialState.cloneCampaign,
      };
    }
    default:
      return state;
  }
}

function deactivateCampaignReducer(
  state = initialState.deactivateCampaign,
  action
) {
  switch (action.type) {
    case SHOW_DEACTIVATION_MODAL: {
      return {
        ...state,
        visible: true,
        campaign: action.campaign,
      };
    }
    case DEACTIVATE_CAMPAIGN: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case DEACTIVATE_CAMPAIGN_FAIL: {
      const { errorMessage, errorStatus } = action;
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage,
        errorStatus,
      };
    }
    case CLOSE_DEACTIVATION_MODAL: {
      return {
        ...state,
        ...initialState.deactivateCampaign,
      };
    }
    default:
      return state;
  }
}

const spreadActionReducer =
  (initialStateArg, actionTypesToHandle) =>
  (state = initialStateArg, action) => {
    if (actionTypesToHandle.includes(action.type)) {
      const payload = omit(action, "type");
      return {
        ...state,
        ...payload,
      };
    }

    return state;
  };

const downloadReducer = (state = initialState.download, action) => {
  const actionTypesToSpreadPayload = [
    SHOW_DOWNLOAD_CAMPAIGN_MODAL,
    DOWNLOAD_ACCOUNT_SUCCESS,
    DOWNLOAD_CAMPAIGN_SUCCESS,
    DOWNLOAD_ACCOUNT_FAILURE,
    DOWNLOAD_CAMPAIGN_FAILURE,
  ];
  if (actionTypesToSpreadPayload.includes(action.type)) {
    const payload = omit(action, "type");
    return {
      ...state,
      ...payload,
    };
  }

  switch (action.type) {
    case DOWNLOAD_ACCOUNT:
    case DOWNLOAD_CAMPAIGN: {
      return {
        ...state,
        loading: true,
      };
    }
    default:
      return state;
  }
};

const transactionReducer = (state = initialState.transaction, action) => {
  switch (action.type) {
    case START_TRANSACTION_SUCCESS: {
      const { transactionId } = action;
      return {
        ...state,
        transactionId,
      };
    }
    default:
      return state;
  }
};

const loadCampaignsReducer = (state = initialState.loadCampaigns, action) => {
  switch (action.type) {
    case LOAD_CAMPAIGN_REQUEST:
    case LOAD_CAMPAIGN_FAILURE: {
      const { loadCampaigns } = state;
      const payload = omit(action, "type");
      return { ...state, loadCampaigns: { ...loadCampaigns, ...payload } };
    }
    case LOAD_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }

    default:
      return state;
  }
};

const campaignsOrgReducer = (state = initialState.campaignsOrg, action) => {
  switch (action.type) {
    case LOAD_CAMPAIGN_SUCCESS: {
      const campaignsOrg = action.campaignsOrg;
      return {
        ...state,
        ...campaignsOrg,
      };
    }
    default:
      return state;
  }
};

const SET_ARCHIVE_CAMPAIGN_MODAL_VISIBILITY =
  "CAMPAIGN/SET_ARCHIVE_CAMPAIGN_MODAL_VISIBILITY";
const setArchiveCampaignModalVisibility = (isVisible, campaignIds) => ({
  type: SET_ARCHIVE_CAMPAIGN_MODAL_VISIBILITY,
  isVisible,
  campaignIds,
});
const archiveCampaignModalReducer = (
  state = initialState.archiveCampaignModal,
  action
) => {
  switch (action.type) {
    case SET_ARCHIVE_CAMPAIGN_MODAL_VISIBILITY: {
      const { isVisible, campaignIds } = action;

      if (isVisible === false) {
        return initialState.archiveCampaignModal;
      }

      return {
        ...state,
        isVisible,
        campaignIds,
      };
    }
    default:
      return state;
  }
};

const campaignsFormFillsReducer = (state = initialState.formFills, action) => {
  switch (action.type) {
    case LOAD_INFLUENCED_CONVERSIONS_FORMS_LIST: {
      const { pageNumber, campaignId, mid, campaignOrAccountName, dateFilter } =
        action;
      return {
        ...state,
        currentPage: pageNumber,
        campaignId,
        mid,
        campaignOrAccountName,
        dateFilter,
        searchBy: "",
        sortBy: "",
        sortingOrder: "",
        loading: true,
      };
    }
    case LOAD_MORE_INFLUENCED_CONVERSIONS_FORMS_LIST: {
      const { pageNumber } = action;
      return {
        ...state,
        currentPage: pageNumber,
        loading: true,
      };
    }
    case CHANGE_RECORDS_PER_PAGE: {
      const { recordsPerPage } = action;
      return {
        ...state,
        recordsPerPage,
        loading: true,
      };
    }
    case SET_INFLUENCED_CONVERSIONS_FORMS_MODAL_VISIBILITY: {
      const { visible } = action;
      return {
        ...state,
        visible,
      };
    }
    case LOAD_INFLUENCED_CONVERSIONS_FORMS_LIST_SUCCESS: {
      const payload = omit(action, "type");
      return {
        ...state,
        loading: false,
        ...payload,
      };
    }
    case LOAD_INFLUENCED_CONVERSIONS_FORMS_LIST_FAIL: {
      const { errorMessage } = action;
      return {
        ...state,
        loading: false,
        errorMessage,
        error: true,
      };
    }
    case DOWNLOAD_INFLUENCED_CONVERSIONS_FORMS: {
      return {
        ...state,
        downloading: true,
      };
    }
    case DOWNLOAD_INFLUENCED_CONVERSIONS_FORMS_SUCCESS: {
      return {
        ...state,
        downloading: false,
      };
    }
    case DOWNLOAD_INFLUENCED_CONVERSIONS_FORMS_FAILS: {
      return {
        ...state,
        downloading: false,
      };
    }
    case SET_INFLUENCED_CONVERSIONS_FORMS_SEARCH: {
      const { searchBy } = action;
      return {
        ...state,
        searchBy,
        currentPage: 1,
        loading: true,
      };
    }
    case SET_INFLUENCED_CONVERSIONS_FORMS_SORT: {
      const { sortBy, sortingOrder } = action;
      return {
        ...state,
        sortBy,
        sortingOrder,
        currentPage: 1,
        loading: true,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  loadCampaigns: loadCampaignsReducer,
  campaignStateAction: spreadActionReducer(initialState.campaignStateAction, [
    CAMPAIGN_STATE_ACTION_PROMPT,
    CAMPAIGN_STATE_ACTION_REQUEST,
    CAMPAIGN_STATE_ACTION_SUCCESS,
    CAMPAIGN_STATE_ACTION_FALIURE,
  ]),
  download: downloadReducer,
  transaction: transactionReducer,
  deactivateCampaign: deactivateCampaignReducer,
  cloneCampaign: cloneCampaignReducer,
  campaignsOrg: campaignsOrgReducer,
  formFills: campaignsFormFillsReducer,
  archiveCampaignModal: archiveCampaignModalReducer,
  [campaignLabelsStateGenerator.stateKey]: campaignLabelsStateGenerator.reducer,
  [campaignsBulkUpdateStateGenerator.stateKey]:
    campaignsBulkUpdateStateGenerator.reducer,
});

const CREATE_CAMPAIGN_ASYNC_VALIDATE =
  "CAMPAIGN/CREATE_CAMPAIGN_ASYNC_VALIDATE";
const createCampaignAsyncValidate = (formName, onSuccess) => ({
  type: CREATE_CAMPAIGN_ASYNC_VALIDATE,
  payload: { formName, onSuccess },
});

export const actions = {
  load,
  loadCampaign,
  loadCampaignSuccess,
  loadCampaignFailure,

  // start transcation
  startTransaction,
  startTransactionSuccess,
  startTransactionFailure,

  // end transcation
  endTransaction,
  endTransactionSuccess,
  endTransactionFailure,

  // clone campaign
  saveCloneCampaign,
  setCloneCampaignLoading,
  saveCloneCampaignFail,
  showCampaignCloneModal,
  closeCampaignCloneModal,

  // deactivate campaign
  showDeactivationModal,
  deactivateCampaign,
  deactivateCampaignFail,
  closeDeactivationModal,

  // delete and activate
  campaignStateAction,
  campaignStateActionFailure,
  campaignStateActionSuccess,
  campaignStateActionPrompt,

  // downlaod campaign
  showDownloadModal,
  downloadCampaignDetails,
  downloadCampaignSuccess,
  downloadCampaignFailure,

  // Download Account
  downloadAccountDetails,
  downloadAccountSuccess,
  downloadAccountFailure,
  loadInfluencedConversionsFormsList,
  loadMoreInfluencedConversionsFormsList,
  setInfluencedConversionsFormsModalVisibility,
  loadInfluencedConversionsFormsListSuccess,
  loadInfluencedConversionsFormsListFail,
  downloadInfluencedConversionsForms,
  downloadInfluencedConversionsFormsSuccess,
  downloadInfluencedConversionsFormsFails,
  setInfluencedConversionsFormsSearch,
  setInfluencedConversionsFormsSort,
  createCampaignAsyncValidate,

  setArchiveCampaignModalVisibility,
  updateCampaignFolderSelection,
  changeRecordsPerPage,
};

export const actionTypes = {
  LOAD_CAMPAIGN_PAGE,
  LOAD_CAMPAIGN_REQUEST,
  LOAD_CAMPAIGN_SUCCESS,
  LOAD_CAMPAIGN_FAILURE,

  // clone and deactivate
  SHOW_CAMPAIGN_CLONE_MODAL,
  SAVE_CLONE_CAMPAIGN,
  DEACTIVATE_CAMPAIGN,
  DEACTIVATE_CAMPAIGN_FAIL,
  SHOW_DEACTIVATION_MODAL,

  CAMPAIGN_STATE_ACTION_REQUEST,

  START_TRANSACTION,
  END_TRANSACTION,
  START_TRANSACTION_SUCCESS,
  END_TRANSACTION_SUCCESS,
  START_TRANSACTION_FAILURE,
  END_TRANSACTION_FAILURE,

  DOWNLOAD_CAMPAIGN,
  DOWNLOAD_ACCOUNT,
  LOAD_INFLUENCED_CONVERSIONS_FORMS_LIST,
  LOAD_MORE_INFLUENCED_CONVERSIONS_FORMS_LIST,
  DOWNLOAD_INFLUENCED_CONVERSIONS_FORMS,
  SET_INFLUENCED_CONVERSIONS_FORMS_SEARCH,
  SET_INFLUENCED_CONVERSIONS_FORMS_SORT,
  CREATE_CAMPAIGN_ASYNC_VALIDATE,
  CHANGE_RECORDS_PER_PAGE,
};
