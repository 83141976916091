import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
} from "redux-saga/effects";
import {
  CANCEL_EXPORT,
  EXPORT_FAILED,
  EXPORT_SUCCESS,
  exportFailed,
  exportSuccess,
  toggleAskForEmail,
  SUBMIT_DOWNLOAD_FORM,
  TOGGLE_ASK_FOR_EMAIL,
} from "./actions";
import { formStateSelector, modalStateSelector } from "./selectors";
import { orgFlagsSelector, orgSelector } from "modules/user/selectors";
import {
  generateQueryParams,
  downloadAccountListCSV,
} from "routes/AdvertisingDS/routes/Campaigns/utils";
import { fetchSaga } from "store/sagas";
import moment from "moment";
import { actions as globalActions } from "modules/global";
import {
  ACCOUNTS_TYPE,
  ANALYTICS_TYPE,
  ANALYTICS_TYPE_OPTIONS,
} from "./constants";
import { flatten, get, isEmpty } from "lodash";
import {
  CAMPAIGN_LIST_PAGE_6SENSE_ACCOUNTS_DOWNLOAD_ID,
  CAMPAIGN_LIST_PAGE_CRM_ACCOUNTS_DOWNLOAD_ID,
  CAMPAIGN_SOURCE,
  DATE_FORMAT,
  LOG_DOWNLOAD_EVENT_OBJECT_ID_MAP,
} from "../../constants";
import { downloadAccountsSelector } from "routes/AdvertisingDS/routes/Campaigns/routes/Analytics/selectors";
import { saveAs } from "file-saver";
import { getReportColumnKeys } from "./utils";
import { statsDateRangeParams } from "routes/AdvertisingDS/routes/Campaigns/routes/ListCampaigns/utils";
import { emailReportStateGenerator } from "./stateGenerators";
import { campaignClassificationDucks } from "routes/AdvertisingDS/ducks";
import { isFeatureFlagEnabledForOrg } from "routes/AdvertisingDS/featureGating/utils";
import { campaignAllColumnsSelector } from "routes/AdvertisingDS/routes/Campaigns/routes/ListCampaigns/selectors";
import { logReportDownloadEventStateGenerator } from "routes/AdvertisingDS/stateGenerators";

const { showNotification } = globalActions;

function* campaignsDownloadSaga() {
  try {
    const { campaignIds, additionalQueryParams } = yield select(
      modalStateSelector
    );
    const archivedFolder = yield select(
      campaignClassificationDucks.selectors.archivedFolderSelector
    );
    const orgId = yield select(orgSelector);
    const { dateRange, groupBy, timeBreakdown } = yield select(
      formStateSelector
    );

    const dateFilterParams = statsDateRangeParams(
      dateRange.value,
      dateRange.from,
      dateRange.to
    );
    const isArchivedFolderSelected =
      archivedFolder.id === get(additionalQueryParams, "classification_id");
    const orgFeatureFlags = yield select(orgFlagsSelector);
    const allColumns = yield select(campaignAllColumnsSelector);
    const columns = getReportColumnKeys(
      isArchivedFolderSelected,
      orgFeatureFlags,
      allColumns
    );

    const queryParams = generateQueryParams({
      group_by: groupBy,
      interval_type: timeBreakdown,
      id__in: campaignIds.length ? campaignIds : null,
      column_names: columns,
      ...dateFilterParams,
      ...additionalQueryParams,
    });

    const endpoint = `query/${orgId}/campaign_analytics/download/${queryParams}`;
    const fetchCSV = () =>
      fetch(`${window.location.origin}/${endpoint}`).then((resp) => {
        if (resp.status === 200) {
          return resp.text();
        }

        const error = new Error();
        error.errorStatus = resp.status;
        error.errorMessage = resp.statusText;
        throw error;
      });

    const response = yield call(fetchCSV);

    const blob = new Blob([response], { type: "text/csv;charset=utf-8" });
    const fileName = `Campaign Performance - ${moment().format(
      DATE_FORMAT
    )}.csv`;
    saveAs(blob, fileName);

    yield put(exportSuccess());
  } catch (e) {
    if (e.errorStatus === 504) {
      yield put(toggleAskForEmail(true));
      return;
    }
    yield put(
      showNotification("error", "Unable to Download. Please try again later")
    );
    yield put(exportFailed(e));
  } finally {
    if (yield cancelled()) {
      yield put(showNotification("warning", "Download Cancelled"));
    }
  }
}

function* startAccountDownload(
  data,
  { csvName, byCRMId, isVideoCampaign, isListDownload }
) {
  const config = {
    csvName: `${csvName}`,
    byCRMId,
    isVideoCampaign,
    isListDownload,
  };
  return downloadAccountListCSV(data, config);
}

function* getAccountDataFromApi(
  request,
  byCRMId,
  campaignSource,
  isListDownload,
  additionalQueryParams
) {
  const isVideoCampaign = true;
  const orgId = yield select(orgSelector);
  const queryParams = generateQueryParams({
    ...additionalQueryParams,
    extra_fields: byCRMId ? "external_id" : null,
    offset: 0,
    limit: 100000,
  });

  const endpoint = `query/${orgId}/${campaignSource}_campaign/analytics/${queryParams}`;

  let accountListData = [];
  const { results } = yield call(request, endpoint);
  if (!isEmpty(results)) {
    accountListData = yield select(
      downloadAccountsSelector(
        results,
        byCRMId,
        isVideoCampaign,
        isListDownload
      )
    );
  }
  return accountListData;
}

function* accountDownloadSaga(request, action) {
  try {
    const startTime = new Date().getTime();
    const { accountType } = action.payload;
    const { campaignIds } = yield select(modalStateSelector);
    const {
      additionalQueryParams: { campaign_source__in, ...restParams },
    } = yield select(modalStateSelector);
    const byCRMId = accountType === ACCOUNTS_TYPE.crm;
    let isVideoCampaign;
    const isListDownload = true;
    const orgFeatureFlags = yield select(orgFlagsSelector);
    const allCampaignSources = Object.values(CAMPAIGN_SOURCE).filter(
      (campaignSource) =>
        isFeatureFlagEnabledForOrg({
          orgFeatureFlags,
          featureIdentifier: campaignSource,
        }) === true
    );

    const getAccountDataForSources = campaign_source__in || allCampaignSources;
    const dateString = moment().format(DATE_FORMAT);
    const accountsTypeLabel = ANALYTICS_TYPE_OPTIONS.find(
      (option) => option.value === accountType
    ).label;
    const fileName = `Campaign Accounts - ${dateString}-${accountsTypeLabel}`;
    const queryParams = {
      ...restParams,
      campaign_ids: campaignIds.length ? campaignIds : null,
    };
    const response = yield all(
      getAccountDataForSources.map((campaignSource) =>
        call(
          getAccountDataFromApi,
          request,
          byCRMId,
          campaignSource,
          isListDownload,
          queryParams
        )
      )
    );
    const accountList = flatten(response);

    const info = {
      data: accountList,
      meta: {
        period: "Lifetime",
        type_of_report: "Account Performance",
        grouping: accountsTypeLabel,
        time_of_report: moment().toString(),
      },
    };
    const fileDetail = yield call(startAccountDownload, info, {
      csvName: fileName,
      byCRMId,
      isVideoCampaign,
      isListDownload,
    });
    yield put(exportSuccess());
    const endTime = new Date().getTime();
    const duration = endTime - startTime;
    yield put(
      logReportDownloadEventStateGenerator.loadAction({
        duration,
        object_id:
          LOG_DOWNLOAD_EVENT_OBJECT_ID_MAP[
            byCRMId
              ? CAMPAIGN_LIST_PAGE_CRM_ACCOUNTS_DOWNLOAD_ID
              : CAMPAIGN_LIST_PAGE_6SENSE_ACCOUNTS_DOWNLOAD_ID
          ],
        ...fileDetail,
      })
    );
  } catch (error) {
    yield put(
      showNotification("error", "Unable to Download. Please try again later")
    );
    yield put(exportFailed(error));
  } finally {
    if (yield cancelled()) {
      yield put(showNotification("warning", "Download Cancelled"));
    }
  }
}

function* initDownloadSaga(request, action) {
  const { analyticsType } = action.payload;

  if (analyticsType === ANALYTICS_TYPE.campaign) {
    yield call(campaignsDownloadSaga, request, action);
    return;
  }

  if (analyticsType === ANALYTICS_TYPE.accounts) {
    yield call(accountDownloadSaga, request, action);
  }
}

function* watchSubmit(request) {
  let lastTask = null;
  while (true) {
    const action = yield take(SUBMIT_DOWNLOAD_FORM);
    if (lastTask) {
      yield cancel(lastTask);
    }
    lastTask = yield fork(initDownloadSaga, request, action);

    const terminatingAction = yield take([
      EXPORT_SUCCESS,
      EXPORT_FAILED,
      CANCEL_EXPORT,
      TOGGLE_ASK_FOR_EMAIL,
    ]);
    if (terminatingAction.type === CANCEL_EXPORT) {
      yield cancel(lastTask);
    }
  }
}

export default [fetchSaga(watchSubmit), emailReportStateGenerator.saga];
