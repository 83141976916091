import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'publishedsegments',
    name: 'PublishedSegments',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('publishedSegments', reducer.default);
        injectSagas('publishedSegments', sagas.default);
        cb(null, containers.wrappedPublishedSegments);
      }, 'publishedSegments');
    },
  };
}
