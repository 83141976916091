import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer } = getAsyncInjectors(store);
  return {
    path: 'mapping(/:id)',
    name: 'Mapping',
    getComponent(_nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const reducer = require('./modules');
          cb(null, containers.Mapping);

          // sagas are loaded at the settings level
          injectReducer('mapping', reducer.default);
        },
        'settings_mapping'
      );
    },
  };
}
