import {
  shouldHaveOrder,
  isDateFilter,
  isDateFilterParent,
  isPredictiveFilter,
  isSegmentFilter,
} from './base';
import { TLoc, zipper } from 'ast-redux/zipper';
import {
  AND,
  OR,
  EQOP,
  BETWEENOP,
  VAREXPR,
  LOGICAL_EXPRESSIONS,
  NULLEXPR,
  BINARY_EXPRESSIONS,
  NEGATIVE_BINARY_EXPRESSIONS,
  OP_LABELS,
  CONST,
  INTCONST,
  BOOLCONST,
} from 'ast-redux/constants';
import { TYPE_CONSTRUCTOR_MAP, EqOp, VarExpr, Const } from 'ast-redux/constructors';
import { isEqual, omit, isNumber, times, map } from 'lodash';
import { walk, visitWith, collect } from 'zipper/utils';
import { isA } from 'ast-redux/utils';
import moment from 'moment';
import { DATE_FORMAT } from 'utils/constants';
import { DEFAULT_SPAN } from 'routes/Discover/constants';

const LOGICAL_LABELS = {
  [AND]: 'AND',
  [OR]: 'OR',
};

/* aa specific tree traversal utils */
const orderNext = (node) => {
  let currMax = 0;
  const visitors = [[
    () => true,
    (loc, locNode) => {
      const order = locNode.order;
      if (order && order > currMax) {
        currMax = order;
      }
      return loc;
    },
  ]];

  walk(visitWith(...visitors), node);
  return currMax + 1;
};

const hydrateOrder = (node) => {
  let currOrder = 0;
  const visitors = [[
    shouldHaveOrder,
    (loc) => {
      currOrder += 1;
      const locNode = loc.node();
      return loc.replace(
        TYPE_CONSTRUCTOR_MAP[locNode.type](locNode.lhs, locNode.rhs, currOrder),
      );
    },
  ]];

  return walk(visitWith(...visitors), node);
};

const firstDate = (node) => {
  // returns the first date node in the tree or
  // last 30 days
  let dateNode;
  const visitors = [[
    isDateFilter,
    (location) => {
      dateNode = location.node();
      return location;
    },
  ]];

  walk(visitWith(...visitors), node, isDateFilter);
  return dateNode || EqOp(VarExpr('span'), Const('last_30_days'));
};

// only the head node of a cascade has an order. Because the and/or toggle begins
// at the end of the cascade, we walk our way up until we find an ordered node
// aka the head of the cascade. The path given is also a path to the end of the cascade,
// so we need to generate the correct path to the top of the cascade
const findCascadeHead = (loc, path) => {
  let cascadeHead = TLoc(loc);
  let order = null;
  const pathToHead = [...path];

  while (order === null) {
    pathToHead.pop();
    if (cascadeHead.node().order) {
      order = cascadeHead.node().order;
    } else {
      cascadeHead = cascadeHead.up();
    }
  }

  return [cascadeHead, pathToHead];
};

const compareBinExprNodesForCascade = (node1, node2) => {
  if ((!node1 || !node2) || node1.order || node2.order) {
    return false;
  }

  const sameType = node1.type === node2.type;
  const sameSides = isEqual(node1.lhs, node2.lhs);

  return sameType && sameSides;
};

const compareDfParentNodesForCascade = (node1, node2) => {
  if (!node1 || !node2) {
    return false;
  }
  return (
    node1.type === node2.type &&
    isEqual(node2.rhs, node1.rhs) &&
    isEqual(node2.lhs.type, node1.lhs.type) &&
    isEqual(node2.lhs.lhs, node1.lhs.lhs)
  );
};

const compareDfParentNodesForCascadeNoOrder = (node1, node2) => {
  if (
    (!node1 || !node2)
    || (node1.order && node2.order)
  ) {
    return false;
  }

  return (
    node1.type === node2.type &&
    isEqual(
      omit(node2.rhs, ['order']),
      omit(node1.rhs, ['order']),
    ) &&
    isEqual(node2.lhs.type, node1.lhs.type) &&
    isEqual(
      omit(node2.lhs.lhs, ['order']),
      omit(node1.lhs.lhs, ['order']),
    )
    && (isNumber(node1.order) === isNumber(node2.order))
  );
};


// etc utils
const logicalChildren = (node) => LOGICAL_EXPRESSIONS.has(node.type)
  && !(isDateFilterParent(zipper(node)))
  && !(node.order);

const hasChildren = (node) => LOGICAL_EXPRESSIONS.has(node.type);

const humanReadableAstByOrder = (node) => {
  const nodeType = node.type;
  switch (nodeType) {
    case AND:
    case OR: {
      const { lhs, rhs, order } = node;
      if (order) {
        return `${node.order}`;
      }

      return [
        `${
          logicalChildren(lhs)
            ? `(${humanReadableAstByOrder(lhs)})`
            : humanReadableAstByOrder(lhs)
        }`,
        `${LOGICAL_LABELS[nodeType]}`,
        `${
          logicalChildren(rhs)
            ? `(${humanReadableAstByOrder(rhs)})`
            : humanReadableAstByOrder(rhs)
        }`,
      ].join(' ');
    }
    case NULLEXPR:
      return '';
    default: {
      return `${node.order}`;
    }
  }
};

const readableFiltersetRelationship = (node) => {
  const nodeType = node.type;
  switch (nodeType) {
    case AND:
    case OR: {
      const { lhs, rhs, value } = node;
      if (value) {
        return value;
      }
      return [
        `${
          hasChildren(lhs)
          ? `(${readableFiltersetRelationship(lhs)})`
          : readableFiltersetRelationship(lhs)
        }`,
        `${LOGICAL_LABELS[nodeType]}`,
        `${
          hasChildren(rhs)
            ? `(${readableFiltersetRelationship(rhs)})`
            : readableFiltersetRelationship(rhs)
        }`,
      ].join(' ');
    }
    case NULLEXPR:
      return '';
    default: {
      return `${node.value}`;
    }
  }
};

const humanReadableAst = (node, config, ...rest) => {
  const nodeType = node.type;

  if (config && config[nodeType]) {
    return config[nodeType](node, ...rest);
  }

  if (
    BINARY_EXPRESSIONS.has(nodeType) ||
    NEGATIVE_BINARY_EXPRESSIONS.has(nodeType)
  ) {
    return `
      ${humanReadableAst(node.lhs, config)}
      ${OP_LABELS[nodeType]}
      ${humanReadableAst(node.rhs, config)}
    `;
  }

  if (LOGICAL_EXPRESSIONS.has(nodeType)) {
    return `
      ${humanReadableAst(node.lhs, config)}
      ${LOGICAL_LABELS[nodeType]}
      ${humanReadableAst(node.rhs, config)}
    `;
  }

  switch (nodeType) {
    case VAREXPR:
      return node.name;
    case CONST:
    case INTCONST:
    case BOOLCONST:
      return node.value;
    case BETWEENOP:
      return `
        ${humanReadableAst(node.expr, config)}
        between
        ${moment(humanReadableAst(node.ge_op, config)).format(DATE_FORMAT)}
        and
        ${moment(humanReadableAst(node.le_op, config)).format(DATE_FORMAT)}`;
    default:
      throw new Error(`Cannot sqlize ${nodeType}`);
  }
};

const prettyPrintAst = (node, lvl = 0) => {
  const indent = () => {
    let indentStr = '';
    times(lvl, () => {
      indentStr += '--------|';
    });
    return indentStr;
  };
  const loc = zipper(node);
  const kids = map(loc.children(), ({ value }) => value);
  const type = loc.current.type || '';
  const order = loc.current.order || '';
  const name = loc.current.name || '';
  const value = loc.current.value === false ? false : loc.current.value || null;

  if (new Set([CONST, INTCONST, BOOLCONST]).has(type)) {
    return `${indent()}${order}${type} ${value}
    `;
  } else if (loc.current.type === VAREXPR) {
    return `${indent()}${order}${type} ${name}
    `;
  }
  return `${indent()}${order && order}${type}
  ${map(kids, (kid) => `${prettyPrintAst(kid, lvl + 1)}`).join('')}`;
};

const countFilterValues = (ast) => {
  const filterValues = [];

  const isFilterValue = (node) => {
    if (isA(VAREXPR)(node)) {
      const name = node.current.name;
      if (!(['dt', 'span'].includes(name))) {
        return true;
      }
    }
    return false;
  };

  const visitors = [[isFilterValue,
    (loc, expr) => {
      filterValues.push(expr);
      return loc;
    },
  ]];

  walk(visitWith(...visitors), ast);
  return filterValues.length;
};

// transform an ast date filter into a spanpicker compliant date object
const astToSpanObject = (dateAst) => {
  if (!dateAst) {
    return DEFAULT_SPAN;
  }

  const { type, rhs, ge_op, le_op } = dateAst;
  const fixedRange = type === EQOP;
  const timeWindow = fixedRange ? rhs.value : null;
  const range = fixedRange ? {} : {
    startDate: ge_op.value,
    endDate: le_op.value,
  };

  return {
    ...range,
    fixedRange,
    timeWindow,
  };
};

const containsPredictiveFilters = (filters) => {
  const predictiveFilters = collect(filters, isPredictiveFilter);
  return predictiveFilters.length > 0;
};

const containsSegmentContainsFilter = (ast) => ast
  ? collect(ast, isSegmentFilter).length > 0
  : false;

export {
  orderNext,
  hydrateOrder,
  firstDate,
  findCascadeHead,
  compareBinExprNodesForCascade,
  compareDfParentNodesForCascade,
  compareDfParentNodesForCascadeNoOrder,
  countFilterValues,
  logicalChildren,
  humanReadableAst,
  humanReadableAstByOrder,
  readableFiltersetRelationship,
  prettyPrintAst,
  astToSpanObject,
  containsPredictiveFilters,
  containsSegmentContainsFilter,
};
