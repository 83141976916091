/**
 * @deprecated Should use ActionDropDown2 instead
 *
 *
 *  DO NOT USE THIS COMPONENT
 */

import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import { ActionMenu } from './ActionMenu';
import { Dropdown } from 'antd';
import { Icon } from 'v2-components';
import { COLORS } from '../../v2-styles/constants';
import styles from './ActionDropdown.module.scss';

function ActionDropdown(props) {
  const {
    leftIcon,
    rightIcon,
    buttonLabel,
    color,
    children,
    placement,
    trigger,
    buttonClassname,
    rightIconClass,
    leftIconClass,
    leftIconProps,
    id,
  } = props;

  let leftIconComponent;
  let rightIconComponent;

  if (leftIcon) {
    leftIconComponent = (
      <Icon
        type={leftIcon}
        color={color}
        className={`${styles.leftIcon} ${leftIconClass}`}
        {...leftIconProps}
      />
    );
  }

  if (rightIcon) {
    rightIconComponent = (
      <Icon type={rightIcon} color={color} className={`${styles.rightIcon} ${rightIconClass}`} />
    );
  }

  const menuOptions = <ActionMenu>{children}</ActionMenu>;

  const option = (
    <a className={buttonClassname}>
      {leftIconComponent}
      {buttonLabel}
      {rightIconComponent}
    </a>
  );

  return (
    <div id={id}>
      <Dropdown
        {...props}
        overlay={menuOptions}
        placement={placement}
        trigger={trigger}
        getPopupContainer={() => document.getElementById(id)}
      >
        {option}
      </Dropdown>
    </div>
  );
}

ActionDropdown.propTypes = {
  id: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  buttonLabel: PropTypes.node,
  placement: PropTypes.string,
  trigger: PropTypes.arrayOf(PropTypes.string),
  buttonClassname: PropTypes.string,
  rightIconClass: PropTypes.string,
  leftIconClass: PropTypes.string,
  color: PropTypes.oneOf(values(COLORS)),
  leftIconProps: PropTypes.object,
};

ActionDropdown.defaultProps = {
  placement: 'bottomLeft',
  trigger: ['hover'],
};

ActionDropdown.ActionMenu = ActionMenu;

export default ActionDropdown;
