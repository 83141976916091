import { defineRequestState, defineSharedState } from '@sixsense/core';
import {
  CreditType,
  ValidationType,
} from './types';
import { App } from '@sixsense/rbac';
import { getCreditDateFormat, replaceAll } from './utils';
import { CREDIT_MSGS, CREDIT_PAGE_CONFIG, creditTypeProperties } from './constants';
import request from 'utils/request';
import { get, has, keyBy } from 'lodash';

const PATH_PREFIX = 'creditmanagement';

const creditEndpoints = {
  getUsers: 'credit/{orgId}/users/?credit_type=sales_intelligence&org_credits_id={orgPoolId}',
  getTotalAndAppPoolCredits: 'credit/{orgId}/details/?org_credits_id={orgPoolId}',
};

export const userRolesState = defineRequestState({
  path: `${PATH_PREFIX}.userRoles`,
  getConfig: ({ orgId }) => ({
    url: `popcorn/v1/organizations/${orgId}/roles/?
include_users_count=false&app_id_in=${App.SALES}`,
  }),
  transformData: (data: any) => {
    if (data?.length) {
      return data;
    }
    return [];
  },
});

export const selectedRoleState = defineSharedState({
  path: `${PATH_PREFIX}.selectedRoleState`,
  defaultValue: -1,
});

export const selectedStatusState = defineSharedState<String>({
  path: `${PATH_PREFIX}.selectedStatusState`,
  defaultValue: 'all',
});
export const selectedBalanceState = defineSharedState({
  path: `${PATH_PREFIX}.selectedBalanceState`,
  defaultValue: null,
});
export const selectedBalanceActionState = defineSharedState<String>({
  path: `${PATH_PREFIX}.selectedBalanceActionState`,
  defaultValue: 'all',
});
export const showFilterPopoverState = defineSharedState<Boolean>({
  path: `${PATH_PREFIX}.showFilterPopoverState`,
  defaultValue: false,
});
export const dataCreditsUsageState = defineSharedState<Boolean>({
  path: `${PATH_PREFIX}.dataCreditsUsageState`,
  defaultValue: true,
});

export const searchUsersState = defineSharedState<String>({
  path: `${PATH_PREFIX}.searchUsersState`,
  defaultValue: '',
});

export const creditValidationState = defineSharedState<ValidationType>({
  path: `${PATH_PREFIX}.credtValidation`,
  defaultValue: {
    disabled: true,
    error: '',
  },
});

export const distributeDrawerOpenState = defineSharedState({
  path: `${PATH_PREFIX}.distributeDrawerOpenState`,
  defaultValue: false,
});

export const allocateSiCreditsModalOpenState = defineSharedState({
  path: `${PATH_PREFIX}.allocateSiCreditsModalOpenState`,
  defaultValue: false,
});

export const orgCreditPoolState = defineRequestState({
  path: `${PATH_PREFIX}.orgCreditPool`,
  getConfig: ({ orgId }) => ({
    url: `credit/${orgId}/pools/`,
  }),
  transformData: (data: any) => {
    if (data?.length) {
      return (
        data.map((pool: any) => {
          const expired = pool.is_expired ? 'Expired' : '';
          const label = `${getCreditDateFormat(
            pool.start_date
          )} - ${getCreditDateFormat(pool.expiry_date)}`;
          const title = expired ? `${label} ${expired}` : label;
          return {
            ...pool,
            ...{
              key: pool.id,
              value: pool.id,
              title: title?.toString(),
              label,
              subLabel: expired,
            },
          };
        }) || []
      );
    }
    return [];
  },
});

export const creditPoolState = defineSharedState({
  path: `${PATH_PREFIX}.orgCreditPool`,
  defaultValue: {},
});

export const orgPoolSelectedState = defineSharedState<CreditType>({
  path: `${PATH_PREFIX}.orgPoolSelected`,
  defaultValue: creditTypeProperties,
});

export const appPoolRefetcherState = defineSharedState({
  path: `${PATH_PREFIX}.refreshAppPoolFetchTimestamp`,
  defaultValue: Date.now().toString(),
});

export const usersListRefetcherState = defineSharedState({
  path: `${PATH_PREFIX}.refreshUsersListRefreshTimestamp`,
  defaultValue: Date.now().toString(),
});


export const distributeCreditsByApps = (
 orgId: number,
 orgPoolId: number,
 credits, // TODO type this
) =>
  new Promise(async (resolve, reject) => {
    try {
      const distributeCreditUrl = `credit/${orgId}/distribute/?org_credits_id=${orgPoolId}`;
      const body = JSON.stringify({
        ...credits,
      });

      const res = await request(
        distributeCreditUrl,
        'POST',
        {
          body,
        },
        () => {
          reject(CREDIT_MSGS.CREDITS_DISTRIBUTION_ERR);
        }
      );
      resolve(res); // TODO transform if reqd
    } catch (ex) {
      if (ex && ex.body && typeof ex.body === 'string') {
        reject(ex.body);
        return;
      }
      reject(CREDIT_MSGS.CREDITS_DISTRIBUTION_ERR);
    }
  });

export const usersListRequestState = defineRequestState({
  path: 'CREDIT.ORG.USERS',
  getConfig: ({
    orgId,
    orgPoolId,
    refreshTimestamp,
  }) => ({
    url: `${replaceAll(creditEndpoints.getUsers, {
      '{orgId}': orgId,
      '{orgPoolId}': orgPoolId,
    })}`,
    method: 'GET',
    timeStamp: refreshTimestamp,
  }),
});

export const creditPoolConfigByPoolIdRequestState = (
  enabledAppsForCreditDistribution,
  canDistributeCredits,
  canAllocateCreditsToSiUsers,
) => defineRequestState({
  path: 'CREDIT.POOLS.CONFIG',
  getConfig: ({
    orgId,
    orgPoolId,
    refreshTimestamp,
  }) => ({
    url: replaceAll(
      creditEndpoints.getTotalAndAppPoolCredits,
      {
        '{orgId}': String(orgId),
        '{orgPoolId}': String(orgPoolId),
      },
    ),
    method: 'GET',
    timeStamp: refreshTimestamp,
  }),
  transformData: (result) => {
    try {
      if (Array.isArray(result) && result.length > 0) {
        const creditTypeMap = keyBy(result, 'credit_type');
        const enabledAppConfigs = CREDIT_PAGE_CONFIG
        .filter((config) => has(creditTypeMap, config.appCreditType)
          || enabledAppsForCreditDistribution[config.appCreditType])
          // if app is not present in credits table or if package is not enabled, then the
          // app should not be shown
        .map((config) => ({
          ...config,
          isDisabled: !enabledAppsForCreditDistribution[config.appCreditType], // if app was enabled
          // earlier and gets disabled, then the app card should be shown as disabled with the
          // credits assigned earlier.
        }));
        const hasAtleastOneEnabledAppConfig = enabledAppConfigs.filter((config) =>
        !config.isDisabled)?.length > 1;
        // global pool will be always enabled. We need atleast one app pool to distribute
        return enabledAppConfigs
          .map((config) => ({
            ...config,
            creditData: get(creditTypeMap, config.appCreditType, {}),
            canDistributeCredits: config.appCreditType === 'global'
              && canDistributeCredits && hasAtleastOneEnabledAppConfig,
            canAllocateCreditsToSiUsers: config.appCreditType === 'sales_intelligence'
              && canAllocateCreditsToSiUsers,
          }));
      }
    } catch (e) {
      console.error(CREDIT_MSGS.CREDITS_ERR_FETCHING_DATA);
    }
    return [];
  },
});
