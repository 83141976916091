/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrchestrationIconStyles.module.scss';

const CompanyContact = ({ className, size }) =>
  <svg
    data-testid='companyContact'
    className={`${styles.default} ${className} ${styles[size]}`}
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
  >
    <defs>
      <style>{".cls-1{fill:none}"}</style>
      <clipPath id="clip-path">
        <path
          className="cls-1"
          d="M-26.15-30h1.64l1.51 2-2 2a7.51 7.51 0 01-2.73-4 7.37 7.37 0 01-.27-2 7.37 7.37 0 01.28-2A7.47 7.47 0 01-25-38l2 2-1.51 2h-1.64a6 6 0 00-.35 2 6 6 0 00.35 2zM-30-26h-12v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1zm-6-12a3 3 0 013 3 3 3 0 01-3 3 3 3 0 01-3-3 3 3 0 013-3zm14-3h-20a2 2 0 00-2 2v14a2 2 0 002 2h20a2 2 0 002-2v-14a2 2 0 00-2-2z"
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <path className="cls-1" d="M-42.5 -39.5H-29V-25.25H-42.5z" />
      </clipPath>
      <clipPath id="clip-path-3">
        <path className="cls-1" d="M-41 -38.75H-30.5V-25.25H-41z" />
      </clipPath>
      <clipPath id="clip-path-4">
        <path className="cls-1" d="M-39.5 -37.25H-38V-35.75H-39.5z" />
      </clipPath>
      <clipPath id="clip-path-5">
        <path className="cls-1" d="M-39.5 -34.25H-38V-32.75H-39.5z" />
      </clipPath>
      <clipPath id="clip-path-6">
        <path className="cls-1" d="M-39.5 -31.25H-38V-29.75H-39.5z" />
      </clipPath>
      <clipPath id="clip-path-7">
        <path className="cls-1" d="M-39.5 -28.25H-38V-26.75H-39.5z" />
      </clipPath>
      <clipPath id="clip-path-8">
        <path className="cls-1" d="M-36.5 -37.25H-35V-35.75H-36.5z" />
      </clipPath>
      <clipPath id="clip-path-9">
        <path className="cls-1" d="M-36.5 -34.25H-35V-32.75H-36.5z" />
      </clipPath>
      <clipPath id="clip-path-10">
        <path className="cls-1" d="M-36.5 -31.25H-35V-29.75H-36.5z" />
      </clipPath>
      <clipPath id="clip-path-11">
        <path className="cls-1" d="M-36.5 -28.25H-35V-25.25H-36.5z" />
      </clipPath>
      <clipPath id="clip-path-12">
        <path className="cls-1" d="M-33.5 -37.25H-32V-35.75H-33.5z" />
      </clipPath>
      <clipPath id="clip-path-13">
        <path className="cls-1" d="M-33.5 -34.25H-32V-32.75H-33.5z" />
      </clipPath>
      <clipPath id="clip-path-14">
        <path className="cls-1" d="M-33.5 -31.25H-32V-29.75H-33.5z" />
      </clipPath>
      <clipPath id="clip-path-15">
        <path className="cls-1" d="M-33.5 -28.25H-32V-26.75H-33.5z" />
      </clipPath>
    </defs>
    <path d="M7 7H9V9H7z" />
    <path d="M10 13H12V15H10z" />
    <path d="M7 10H9V12H7z" />
    <path d="M10 10H12V12H10z" />
    <path d="M10 7H12V9H10z" />
    <path d="M4 10H6V12H4z" />
    <path d="M4 13H6V15H4z" />
    <path d="M4 7H6V9H4z" />
    <path d="M22 3H2a2 2 0 00-2 2v14a2 2 0 002 2h20a2 2 0 002-2V5a2 2 0 00-2-2zm-9 15H9v-4H7v4H3V6h10zm4.85-4h1.64L21 16l-2 2a7.5 7.5 0 01-2.73-4 7.28 7.28 0 010-4A7.46 7.46 0 0119 6l2 2-1.51 2h-1.64a5.89 5.89 0 000 4z" />
  </svg>

export default CompanyContact;