import { SIXSENSE_NETWORK } from '../../constants';
import { mapValues, merge, get } from 'lodash';
import { genEmptyFilterset } from 'modules/segments/utils';
import {
  actionTypes as publishedSegmentActionTypes,
} from '../../../Settings/routes/PublishedSegments/modules';
import {
  SOCIAL_SEGMENT_SYNC_STATUS_KEY,
} from 'routes/Segments/containers/SocialSegmentSyncModal/constants';

const initialMenuState = {
  savedFilters: false,
  unsavedFilters: false,
  config: false,
};

const InitialState = {
  segment: null,
  loading: false,
  loaded: false,
  error: false,
  saveModalVisible: false,
  filterMenus: initialMenuState,
  segmentNameMap: {},
  editSegTagVisibility: false,
  tagsToAdd: [],
  tagsToCreate: [],
  tagsToRemove: [],
  modifyingTags: false,
};

const LOAD = 'SEGMENTS_SEGMENT/LOAD';
const load = () => ({
  type: LOAD,
});

const LOAD_SUCCESS = 'SEGMENTS_SEGMENT/LOAD_SUCCESS';
const loadSuccess = (activeSegment) => ({
  type: LOAD_SUCCESS,
  activeSegment,
});

const LOAD_FAIL = 'SEGMENTS_SEGMENT/LOAD_FAIL';
const loadFail = (errorMessage, errorStatus) => ({
  type: LOAD_FAIL,
  errorMessage,
  errorStatus,
});

const REFRESH = 'SEGMENTS_SEGMENT/REFRESH';
const refresh = () => ({
  type: REFRESH,
});

const UPDATE_SEGMENT_TAGS = 'SEGMENTS_SEGMENT/SET_SEGMENT';
const updateSegmentTags = (tag_ids) => ({
  type: UPDATE_SEGMENT_TAGS, tag_ids,
});

const UPDATE_SEGMENT_FOLDER = 'SEGMENTS_SEGMENT/UPDATE_SEGMENT_FOLDER';
const updateSegmentFolder = (folder) => ({
  type: UPDATE_SEGMENT_FOLDER, folder,
});

const SET_ACTIVE_SEGMENT_SUCCESS =
  'SEGMENTS_SEGMENT/SET_ACTIVE_SEGMENT_SUCCESS';
const setActiveSegmentSuccess = (segment) => ({
  type: SET_ACTIVE_SEGMENT_SUCCESS,
  segment,
});

const SET_ACTIVE_SEGMENT_FAIL = 'SEGMENTS_SEGMENT/SET_ACTIVE_SEGMENT_FAIL';
const setActiveSegmentFail = (errorMessage, errorStatus) => ({
  type: SET_ACTIVE_SEGMENT_FAIL,
  errorMessage,
  errorStatus,
});

const SET_NEW_SEGMENT_DATA = 'SEGMENTS_SEGMENT/SET_NEW_SEGMENT_DATA';
const setNewSegmentData = () => ({
  type: SET_NEW_SEGMENT_DATA,
});

const CHANGE_SAVE_MODAL_VISIBILITY =
  'SEGMENTS_SEGMENT/CHANGE_SAVE_MODAL_VISIBILITY';
const changeSaveModalVisibility = (saveModalVisible) => ({
  type: CHANGE_SAVE_MODAL_VISIBILITY,
  saveModalVisible,
});

const TOGGLE_FILTER_MENU = 'SEGMENTS/TOGGLE_FILTER_MENU';
function toggleFilterMenus(menu) {
  return {
    type: TOGGLE_FILTER_MENU, menu,
  };
}

const SET_SEGMENT_NAME_MAP = 'SET_SEGMENT_NAME_MAP';
function setSegmentNameMap(names) {
  return { type: SET_SEGMENT_NAME_MAP, names };
}

const SET_EDIT_SEG_TAGS_VISIBILITY = 'SEGMENTS_SEGMENT/SET_EDIT_SEG_TAGS_VISIBILITY';
function setEditSegTagsVisibility(visible) {
  return { type: SET_EDIT_SEG_TAGS_VISIBILITY, visible };
}

const ADD_TAG_TO_ADD = 'SEGMENTS_SEGMENT/ADD_TAG_TO_ADD';
function addTagToAdd(name, segTags) {
  return { type: ADD_TAG_TO_ADD, name, segTags };
}

const ADD_TAG_TO_REMOVE = 'SEGMENTS_SEGMENT/ADD_TAG_TO_REMOVE';
function addTagToRemove(tag) {
  return { type: ADD_TAG_TO_REMOVE, tag };
}

const RESET_TAG_EDITS = 'SEGMENTS_SEGMENT/RESET_TAG_EDITS';
function resetEdits() {
  return { type: RESET_TAG_EDITS };
}

const MODIFY_TAGS = 'SEGMENTS_SEGMENT/MODIFY_TAGS';
function modifyTags(segments) {
  return { type: MODIFY_TAGS, segments };
}

const MODIFY_TAGS_SUCCESS = 'SEGMENTS_SEGMENT/MODIFY_TAGS_SUCCESS';
function modifyTagsSuccess() {
  return { type: MODIFY_TAGS_SUCCESS };
}

const MODIFY_TAGS_FAIL = 'SEGMENTS_SEGMENT/MODIFY_TAGS_FAIL';
function modifyTagsFail(errorMessage) {
  return { type: MODIFY_TAGS_FAIL, errorMessage };
}

const CREATE_TAG = 'SEGMENTS_SEGMENT/CREATE_TAG';
function createTag(name) {
  return { type: CREATE_TAG, name };
}

const SOCIAL_SYNC_SEGMENT_UPDATE = 'SEGMENTS_SEGMENT/SOCIAL_SYNC_SEGMENT_UPDATE';
const socialSyncSegmentUpdate = (payload) => ({
  type: SOCIAL_SYNC_SEGMENT_UPDATE,
  payload,
});

function segmentsSegmentReducer(state = InitialState, action) {
  switch (action.type) {
    case LOAD:
      return { ...state, loading: true };
    case LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case LOAD_FAIL: {
      const { errorMessage, errorStatus } = action;
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage,
        errorStatus,
      };
    }
    case UPDATE_SEGMENT_TAGS: {
      const seg_tags = action.tag_ids;
      const { segment } = state;
      const newSegment = {
        ...segment,
        seg_tags,
      };
      return {
        ...state,
        segment: newSegment,
      };
    }
    case UPDATE_SEGMENT_FOLDER: {
      const folder = action.folder;
      const { segment } = state;
      if (!segment) {
        return {
          ...state,
        };
      }
      const newSegment = {
        ...segment,
        folder,
      };
      return {
        ...state,
        segment: newSegment,
      };
    }
    case SET_NEW_SEGMENT_DATA:
      return {
        ...state,
        segment: {
          name: 'Untitled Segment',
          filterset: genEmptyFilterset(),
          segment_type: SIXSENSE_NETWORK,
          segment_data: {
            account_count: 0,
          },
        },
      };
    case SET_ACTIVE_SEGMENT_SUCCESS: {
      const { segment } = action;
      return {
        ...state,
        segment,
      };
    }
    case SET_ACTIVE_SEGMENT_FAIL: {
      const { errorMessage, errorStatus } = action;
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage,
        errorStatus,
      };
    }
    case CHANGE_SAVE_MODAL_VISIBILITY: {
      const { saveModalVisible } = action;
      return {
        ...state,
        saveModalVisible,
      };
    }

    case TOGGLE_FILTER_MENU: {
      const { menu } = action;
      const { filterMenus } = state;
      const newFilterMenus = mapValues(
        filterMenus,
        (openStatus, menuItem) => menuItem === menu ? !openStatus : false,
      );

      return {
        ...state,
        filterMenus: menu === 'clear' ? initialMenuState : newFilterMenus,
      };
    }

    case SET_SEGMENT_NAME_MAP: {
      const { names } = action;
      const { segmentNameMap } = state;
      return {
        ...state,
        segmentNameMap: merge(segmentNameMap, names),
      };
    }

    case SET_EDIT_SEG_TAGS_VISIBILITY: {
      const editSegTagVisibility = action.visible;
      return {
        ...state,
        editSegTagVisibility,
      };
    }
    case ADD_TAG_TO_ADD: {
      const { name, segTags } = action;
      const { tagsToRemove, tagsToAdd } = state;
      const tag = segTags.filter((segTag) => segTag.name === name)[0];
      if (tagsToRemove.includes(tag.id)) {
        const filteredTagsToRemove = tagsToRemove.filter((id) => id !== tag.id);
        return {
          ...state,
          tagsToRemove: filteredTagsToRemove,
          tagSearchValue: '',
        };
      }
      const newTagsToAdd = tagsToAdd.concat(tag.id);
      return {
        ...state,
        tagsToAdd: newTagsToAdd,
      };
    }
    case ADD_TAG_TO_REMOVE: {
      const { tag } = action;
      if (tag.id) {
        const { tagsToRemove, tagsToAdd } = state;
        const tempTagsToAdd = tagsToAdd.filter((addTag) => addTag !== tag.id);
        if (tagsToAdd.length !== tempTagsToAdd.length) {
          return {
            ...state,
            tagsToAdd: tempTagsToAdd,
          };
        }
        const newTagsToRemove = tagsToRemove.concat(tag.id);
        return {
          ...state,
          tagsToRemove: newTagsToRemove,
        };
      }
      const { tagsToCreate } = state;
      const tempTagList = tagsToCreate.filter((cTag) => cTag.newName !== tag.newName);
      return {
        ...state,
        tagsToCreate: tempTagList,
      };
    }
    case RESET_TAG_EDITS: {
      return {
        ...state,
        tagsToCreate: [],
        tagsToAdd: [],
        tagsToRemove: [],
      };
    }
    case MODIFY_TAGS: {
      return {
        ...state,
        modifyingTags: true,
      };
    }
    case MODIFY_TAGS_SUCCESS: {
      return {
        ...state,
        error: false,
        editSegTagVisibility: false,
        modifyingTags: false,
      };
    }
    case MODIFY_TAGS_FAIL: {
      const errorMessage = action.errorMessage;
      return {
        ...state,
        error: true,
        errorMessage,
        modifyingTags: false,
      };
    }
    case CREATE_TAG: {
      const { name } = action;
      const { tagsToCreate } = state;
      const newTag = {
        newName: name,
        is_deleted: false,
      };
      const newTagsToCreate = [...tagsToCreate];
      newTagsToCreate.push(newTag);
      return {
        ...state,
        tagsToCreate: newTagsToCreate,
        tagSearchValue: '',
      };
    }
    case REFRESH:
      return InitialState;
    case publishedSegmentActionTypes.UPDATE_PUBLISHED_TAGS_SUCCESS: {
      if (state.segment) {
        const { segmentId, tags } = action;
        const newTags = tags.map((tag) => ({
          is_deleted: false,
          segment_tag_id: tag,
          segment_id: segmentId,
        }));
        return {
          ...state,
          segment: {
            ...state.segment,
            tags: [...state.segment.tags, ...newTags],
          },
        };
      }
      return state;
    }
    case SOCIAL_SYNC_SEGMENT_UPDATE: {
      const adNetwork = get(action.payload, 'adNetwork');
      const updatedSyncDetails = get(action.payload.segment, [
        SOCIAL_SEGMENT_SYNC_STATUS_KEY,
        adNetwork,
      ]);

      return {
        ...state,
        segment: {
          ...state.segment,
          [SOCIAL_SEGMENT_SYNC_STATUS_KEY]: {
            ...state.segment[SOCIAL_SEGMENT_SYNC_STATUS_KEY],
            [adNetwork]: updatedSyncDetails,
          },
        },
      };
    }
    default:
      return state;
  }
}

export const actions = {
  load,
  loadSuccess,
  loadFail,
  setActiveSegmentSuccess,
  setActiveSegmentFail,
  setNewSegmentData,
  changeSaveModalVisibility,
  refresh,
  toggleFilterMenus,
  setSegmentNameMap,
  setEditSegTagsVisibility,
  addTagToAdd,
  addTagToRemove,
  resetEdits,
  modifyTags,
  modifyTagsSuccess,
  modifyTagsFail,
  createTag,
  updateSegmentTags,
  updateSegmentFolder,
  socialSyncSegmentUpdate,
};

export const actionTypes = {
  LOAD,
  SET_ACTIVE_SEGMENT_SUCCESS,
  MODIFY_TAGS,
};

export default segmentsSegmentReducer;
