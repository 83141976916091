import { ADVERTISING_ACTION_PREFIX } from "./constants";

export const INIT_FOLDER_SELECTION = `${ADVERTISING_ACTION_PREFIX}/INIT_FOLDER_SELECTION`;
export const initFolderSelection = (payload) => ({
  type: INIT_FOLDER_SELECTION,
  payload,
});

export const UPDATE_FOLDER_SELECTION = `${ADVERTISING_ACTION_PREFIX}/UPDATE_FOLDER_SELECTION`;
export const updateFolderSelection = (payload) => ({
  type: UPDATE_FOLDER_SELECTION,
  payload,
});

export const UPDATE_EXPANDED_FOLDERS = `${ADVERTISING_ACTION_PREFIX}/UPDATE_EXPANDED_FOLDERS`;
export const updateExpandedFolders = (payload) => ({
  type: UPDATE_EXPANDED_FOLDERS,
  payload,
});

export const SHOW_CREATE_NEW_CAMPAIGNS_FOLDER_MODAL = `${ADVERTISING_ACTION_PREFIX}/SHOW_CREATE_NEW_CAMPAIGNS_FOLDER_MODAL`;
export const showCreateNewCampaignsFolderModal = () => ({
  type: SHOW_CREATE_NEW_CAMPAIGNS_FOLDER_MODAL,
});
export const HIDE_CREATE_NEW_CAMPAIGNS_FOLDER_MODAL = `${ADVERTISING_ACTION_PREFIX}/HIDE_CREATE_NEW_CAMPAIGNS_FOLDER_MODAL`;
export const hideCreateNewCampaignsFolderModal = () => ({
  type: HIDE_CREATE_NEW_CAMPAIGNS_FOLDER_MODAL,
});

export const SHOW_CREATE_NEW_ADS_FOLDER_MODAL = `${ADVERTISING_ACTION_PREFIX}/SHOW_CREATE_NEW_ADS_FOLDER_MODAL`;
export const showCreateNewAdsFolderModal = () => ({
  type: SHOW_CREATE_NEW_ADS_FOLDER_MODAL,
});
export const HIDE_CREATE_NEW_ADS_FOLDER_MODAL = `${ADVERTISING_ACTION_PREFIX}/HIDE_CREATE_NEW_ADS_FOLDER_MODAL`;
export const hideCreateNewAdsFolderModal = () => ({
  type: HIDE_CREATE_NEW_ADS_FOLDER_MODAL,
});

export const TOGGLE_DELETE_CAMPAIGNS_FOLDER_MODAL = `${ADVERTISING_ACTION_PREFIX}/TOGGLE_DELETE_CAMPAIGNS_FOLDER_MODAL`;
export const toggleDeleteCampaignsFolderModal = (payload) => ({
  type: TOGGLE_DELETE_CAMPAIGNS_FOLDER_MODAL,
  payload,
});

export const TOGGLE_DELETE_ADS_FOLDER_MODAL = `${ADVERTISING_ACTION_PREFIX}/TOGGLE_DELETE_ADS_FOLDER_MODAL`;
export const toggleDeleteAdsFolderModal = (payload) => ({
  type: TOGGLE_DELETE_ADS_FOLDER_MODAL,
  payload,
});

export const TOGGLE_RENAME_CAMPAIGNS_FOLDER_MODAL = `${ADVERTISING_ACTION_PREFIX}/TOGGLE_RENAME_CAMPAIGNS_FOLDER_MODAL`;
export const toggleRenameCampaignsFolderModal = (payload) => ({
  type: TOGGLE_RENAME_CAMPAIGNS_FOLDER_MODAL,
  payload,
});

export const TOGGLE_RENAME_ADS_FOLDER_MODAL = `${ADVERTISING_ACTION_PREFIX}/TOGGLE_RENAME_ADS_FOLDER_MODAL`;
export const toggleRenameAdsFolderModal = (payload) => ({
  type: TOGGLE_RENAME_ADS_FOLDER_MODAL,
  payload,
});

export const SET_UPDATING_TREE_DATA = `${ADVERTISING_ACTION_PREFIX}/SET_UPDATING_TREE_DATA`;
export const setUpdatingTreeData = (isUpdating) => ({
  type: SET_UPDATING_TREE_DATA,
  payload: isUpdating,
});

export const CAMPAIGNS_EXTERNAL_DROP = `${ADVERTISING_ACTION_PREFIX}/CAMPAIGNS_EXTERNAL_DROP`;
export const campaignsExternalDrop = (nodeKey, droppedItem) => ({
  type: CAMPAIGNS_EXTERNAL_DROP,
  payload: { nodeKey, droppedItem },
});

export const LOG_ROLLBAR_ERROR = `${ADVERTISING_ACTION_PREFIX}/LOG_ROLLBAR_ERROR`;
export const logRollbarError = (message) => ({
  type: LOG_ROLLBAR_ERROR,
  payload: message,
});

export const DIRECTORY_TREE_LOAD = `${ADVERTISING_ACTION_PREFIX}/DIRECTORY_TREE_LOAD`;
export const directoryTreeLoad = () => ({
  type: DIRECTORY_TREE_LOAD,
});
