import { useSelector } from '@sixsense/core/versioned/react-redux';
import { userObjectSelector } from 'modules/user/selectors';

type User = {
  id: number;
  username: string;
}

const useCurrentUser = (): User => useSelector(userObjectSelector);

export default useCurrentUser;
