import React from 'react';
import { LayoutCard } from 'HOCS';
import { Text } from 'v2-components';
import { compose } from 'redux';
import { css } from 'styles/emotion';
import { Card, Flex, Loading } from '@sixsense/core/components';
import { pxToRem } from '@sixsense/core/style';
import { useRequestValue } from '@sixsense/core/request';
import { useSelector } from '@sixsense/core/versioned/react-redux';
import { genQueryParams } from './utils';
import { organizationSelector, lastUpdatedSelector } from 'modules/user/selectors';
import { Organization } from '@sixsense/core/types';
import { topKeywordGroupsRequestState } from './state';

const styles = {
  bar: css({
    width: '50%',
    height: 20,
    borderRadius: 2,
    backgroundColor: '#0884d4',
    marginLeft: 10,
  }),
  groupRow: (fullGraph) => css({
    alignContent: 'center',
    marginBottom: !fullGraph ? 20 : 0,
  }),
  container: (fullGraph) => css({
    marginTop: `${pxToRem(-12)}`,
    width: '100%',
    paddingTop: '0 !important',
    paddingLeft: `${pxToRem(5)} !important`,
    paddingBottom: `${pxToRem(10)} !important`,
    display: 'flex',
    justifyContent: fullGraph ? 'space-between' : undefined,
  }),
  nameText: (selectable) => css({
    color: '#1890FF',
    '&:hover': {
      color: selectable ? '#40A9FF': '#1890FF',
    },
  }),
  noData: css({
    backgroundColor: '#9299A3',
    borderRadius: '2px',
    padding: `${pxToRem(20)} ${pxToRem(30)}`,
    margin: pxToRem(10),
    maxHeight: 70,
  }),
  stats: css({
    display: 'flex',
    width: '45%',
  }),
  countHeader: css({
    fontStyle: 'italic',
    textAlign: 'center',
    flex: 1,
  }),
  subText: (fullGraph) => css({
    display: 'flex',
    marginTop: -20,
    marginBottom: `${fullGraph ? 0 : 20}px !important`,
  }),
};

const renderLoading = () => (
  <React.Fragment>
    <Loading.Grid rowCount={1} colCount={1} height={46} width="100%" gap={10} />
  </React.Fragment>
);

type Props = {
  span?: string;
  mid?: string;
  productCategory?: string;
  subtitle: string;
  action?: (params: { name: string; id: number }) => void;
};

const KeywordGroupCardComponent = ({ mid, span, productCategory, subtitle, action }: Props) => {

  const organization: Organization = useSelector(organizationSelector);
  const lastUpdated = useSelector(lastUpdatedSelector);

  const asyncResult = useRequestValue(
    topKeywordGroupsRequestState,
    {
      orgId: organization.id,
      mid,
      queryParams: genQueryParams(lastUpdated, span, productCategory, 5),
    },
  );

  return (
    <Card.Content.Async
      result={asyncResult}
      className={styles.container(true)}
      renderLoading={renderLoading}
    >
      {({ data }) => {
        const maxValue = data.length ? data[0].account_count : 1;
        const fullGraph = data.length === 5;
        return (
          data.length ?
            <Flex direction="column" className={styles.container(fullGraph)}>
              <div className={styles.subText(fullGraph)}>
                <div className={styles.stats} />
                <div className={styles.countHeader}>
                  <Text>{subtitle}</Text>
                </div>
              </div>
              {data.map((group) =>
                <Flex className={styles.groupRow(fullGraph)}>
                  <Flex justifyContent="space-between" style={{ width: '50%' }}>
                    <Text
                      className={styles.nameText(action !== undefined)}
                      id={group}
                      key={group}
                      onClick={(value) => action && action(value)}
                      pointer={action !== undefined}
                    >
                      {group.name}
                    </Text>
                    <Text>{group.account_count ? group.account_count.toLocaleString() : ''}</Text>
                  </Flex>
                  <div
                    className={styles.bar}
                    style={{ width: `${40 * group.account_count / maxValue}%` }}
                  />
                </Flex>
              )}
            </Flex> :
            <Flex style={{ width: '100%' }} justifyContent="center" alignItems="center">
              <Text color={Text.COLOR.WHITE} className={styles.noData}>
                No data is available
              </Text>
            </Flex>
        );
      }}
    </Card.Content.Async>
  );
};

const KeywordGroupCard = compose(
  LayoutCard({
    size: LayoutCard.SHORT,
    containerPadding: 10,
    showZeroState: true,
  }),
)(KeywordGroupCardComponent);


export default KeywordGroupCard;
