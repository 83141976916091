import { Flex } from '@sixsense/core/components';
import React, { useMemo } from 'react';
import { Button, Modal, Text, Icon } from 'v2-components';
import { css } from 'styles/emotion';
import { AAColor } from '@sixsense/core/style';
import { useSharedValue } from '@sixsense/core';
import { maybePlural } from 'utils/utils';
import { orgProductState } from '../../state';
import { GROUPS, KEYWORDS, KW_EXP, OTHER } from '../../constants';
import { actions as downloadActions } from 'v2-components/Download/modules';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { useOrg } from 'hooks';

const styles = {
  button: css({
    marginRight: '10px',
    height: '30px',
  }),
  select: css({
    placeContent: 'flex-start !important',
    marginTop: '10px',
    marginBottom: '200px',
  }),
  error: css({
    margin: '10px 10px 0 0',
  }),
  loadingIcon: css({
    marginLeft: '10px',
  }),
  dropdownStyle: css`
    width: 40%;
    margin-bottom: 200px;
    margin-top: 10px;
    :global(.ant-select-selection) {
        border:none !important;
        box-shadow: none !important;
        :global(.ant-select-selection__choice){
          background-color: #E5F2FA;
        }
      }
  `,
  warning: css({
    marginRight: '5px',
    color: '#EBA300',
    width: '25px',
  }),
  column: css({
    wordBreak: 'break-word',
    paddingRight: '50px',
    fontSize: '1rem',
    color: `${AAColor.GREY}`,
  }),
  container: css({
    padding: '20px',
    paddingLeft: '24px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #C6CACE',
    borderTop: 'none',
  }),
  downloadIcon: css({
    marginRight: '10px',
    '&:hover': {
      color: `${AAColor.BLUE}`,
      cursor: 'pointer',
    },
  }),
  text: css({ marginTop: '5px' }),
};

type Props = {
  onCancel: () => void;
  invalidKeywords: any;
  config: any;
  onSave: () => void;
  type: string;
};

const InvalidKeywordsModal = (
  { onCancel, invalidKeywords, config, onSave, type }: Props) => {
  const dispatch = useDispatch();
  const products = useSharedValue(orgProductState);
  const productOptions = useMemo(
    () => products.map((p) => ({
      value: p.id,
      label: p.displayName === KW_EXP ? OTHER : p.displayName,
    })),
    [products]
  );
  const org = useOrg();
  const selectedProductName = products.find((p) => p.id === config.product_id)?.displayName;

  const title = `${invalidKeywords.length} ${maybePlural(invalidKeywords.length, 'Keyword')} 
    ${type === KEYWORDS ? `Already ${invalidKeywords.length > 1 ? 'Exist' : 'Exists'}`
      : `${invalidKeywords.length > 1 ? 'Are' : 'Is'} New`}`;

  const modalLabel = {
    [KEYWORDS]: (
      <div style={{ padding: '5px 30px', paddingTop: '20px' }}>
        <Text>{`You're trying to add ${config.keywords.length} 
          new `}</Text>
        <Text bold>{`${config.category} 
          ${selectedProductName === KW_EXP ? OTHER : selectedProductName} `}</Text>
        <Text>{maybePlural(config.keywords.length, 'keyword')}.</Text>
      </div>
    ),
    [GROUPS]: (
      <div style={{ padding: '5px 30px', paddingTop: '20px' }}>
        <Text>{`You're trying to add ${config.keywords.length} `}<Text bold>existing </Text>
          {`${maybePlural(config.keywords.length, 'keyword')} to the selected keyword groups.`
          }</Text>
      </div>
    ),
  };

  const modalDescription = {
    [KEYWORDS]: (
      <div style={{ padding: '5px 30px', paddingBottom: '20px' }}>
        <Text>{`${invalidKeywords.length} of these ${invalidKeywords.length > 1 ? 'are' : 'is an'} 
          existing ${maybePlural(config.keywords.length, 'keyword')}. They will not be affected.
          You cannot change an existing keyword's type or which keywords are included in any
          group here. `}
        </Text>
        <div style={{ paddingTop: '10px' }}>
          <Text>You can add existing keywords to groups in the <Text bold>Keyword Group </Text>
          tab.
          </Text>
        </div>
      </div>
    ),
    [GROUPS]: (
      <div style={{ padding: '5px 30px', paddingBottom: '20px' }}>
        <Text>
          <Text bold>{invalidKeywords.length}</Text>
          {`${invalidKeywords.length > 1 ? ' of these keywords are new.' : ' keyword is new.'} 
           They will not be added to any keyword groups here.`}
        </Text>
        <div style={{ paddingTop: '10px' }}>
          <Text>
            You can add new keywords in the <Text bold>Intent Keywords </Text>tab.
          </Text>
        </div>
      </div>
    ),
  };

  const downloadKeywords = (keywords) => {
    if (type === KEYWORDS) {
      dispatch(downloadActions.download(
        keywords,
        `${selectedProductName}_existing_keywords`, 'keywords', productOptions, KEYWORDS));
    } else {
      const kwData = keywords.map((v) => ({ keyword: v }));
      dispatch(downloadActions.download(
        kwData,
        `${org.name}_new_keywords`, 'keywords', productOptions, GROUPS));
    }
  };

  const dupesMessage = (dupes) => dupes.length > 0 ? (
    <Flex
      style={{ padding: '20px 30px', marginBottom: '20px' }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Text bold>
        Download the {dupes.length} {type === KEYWORDS ? 'existing' : 'new'}
        {maybePlural(dupes.length, ' Keyword')}
      </Text>
      <Icon
        className={styles.downloadIcon}
        size={Icon.SIZE.MEDIUM} type="get_app"
        onClick={() => downloadKeywords(dupes)}
      />
    </Flex>
  ) : null;

  const footer = (
    <Flex direction="column" style={{ padding: '10px 0' }}>
      <Flex justifyContent="flex-end">
        <Button
          pointer
          className={styles.button}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button
          pointer
          className={styles.button}
          type={'primary'}
          onClick={() => {
            onCancel();
            onSave();
          }}
        >
        Add Keywords
        </Button>
      </Flex>
    </Flex>
  );

  return (
    <Modal
      visible
      bodyStyle={{ padding: 0, marginTop: '100px' }}
      footer={footer}
      width="600px"
      maskClosable={false}
      onCancel={() => onCancel()}
    >
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9', alignItems: 'center' }}>
        <Icon size={Icon.SIZE.LARGE} className={styles.warning} type="warning_triangle" />
        <Text type={Text.TYPE.TITLE} bold>{title}</Text>
      </Flex>
      {modalLabel[type]}
      {modalDescription[type]}
      {dupesMessage(invalidKeywords)}
    </Modal>
  );
};

export default InvalidKeywordsModal;
