/*
  RbmAst class that contains imp methods used in UI like
  astToEntity
  entityToAst
  getFilterLogic
*/
import { Exception } from 'handlebars';
import { forEach } from 'lodash';
import {
  MODEL_OR, createGroup, createRule, isGroup, isModelAndInstance,
  isModelAndType, isModelOrInstance, isRule,
} from './rbmAstUtils';

const {
    toAST, walkWith, EqOp, NotEqOp, StartsWithOp,
    NotContainsOp, ContainsOp, Func,
    VarExpr,
    Const,
    ModelAnd,
    ModelOr,
} =
require('routes/Settings/routes/Standardization/lib/ast');

export class RbmAst {
  constructor() {
    this.rules = [];
    this.verboseEntityRules = [];
    this.isAnAllowedOperator = this.isAnAllowedOperator.bind(this);
    this.createRule = this.createRule.bind(this);
    this.canMergeRules = this.canMergeRules.bind(this);
    this.mergeRules = this.mergeRules.bind(this);
    this.mergeEntities = this.mergeEntities.bind(this);
    this.traverse = this.traverse.bind(this);
    this.astToEntity = this.astToEntity.bind(this);
    this.createRuleNode = this.createRuleNode.bind(this);
    this.entityToAst = this.entityToAst.bind(this);
    this.getFilterLogic = this.getFilterLogic.bind(this);
    this.getVerboseEntityRules = this.getVerboseEntityRules.bind(this);
  }

  isAnAllowedOperator(expr) {
    if (
        expr instanceof EqOp ||
        expr instanceof StartsWithOp ||
        expr instanceof NotContainsOp ||
        expr instanceof ContainsOp
      ) return true;
    return false;
  }

    // creates a UI format rule from an operator expr like EqOp, NotEqOp, ContainsOp etc
  createRule(expr) {
    if (!this.isAnAllowedOperator(expr)) return null;

    let column = '';
    let isCaseSensitive;
    if (expr.lhs instanceof Func) {
      column = Array.from(expr.lhs.exprs)[0].name;
      isCaseSensitive = false;
    } else {
      column = expr.lhs.name;
      isCaseSensitive = true;
    }

    return createRule(
        column,
        expr.constructor.name,
        [expr.rhs.value],
        isCaseSensitive
    );
  }

    /**
      check if all the rules have same column, operand, isCaseSensitive,
      returns true if yes or false.
      Ex:
      combine below 2 rules:
      {column: column1, operand: EqOp, isCaseSensitive: true, values: ["test1"]}
      {column: column1, operand: EqOp, isCaseSensitive: true, values: ["test2"]}
      into:
      {column: column1, operand: EqOp, isCaseSensitive: true, values: ["test1", "test2"]}
    * */
  canMergeRules(arr) {
    if (!arr) return false;
    if (arr.length === 1) return true;

      // this check make sure we are only checking on rules, not groups
    for (let i=0; i<arr.length; i++) {
      if (!isRule(arr[i])) {
        return false;
      }
    }

    let canMerge = true;
      // loop till 0 to second last element
    for (let i=0; i<arr.length-1; i++) {
      if (
          arr[i].column !== arr[i+1].column ||
          arr[i].operand !== arr[i+1].operand ||
          arr[i].isCaseSensitive !== arr[i+1].isCaseSensitive
        ) {
        canMerge = false;
        break;
      }
    }
    return canMerge;
  }

    // combines the values property of rules,
    // keeping the column, operand & isCaseSensitive of first element
  mergeRules(arr) {
    if (!arr) return null;
    if (arr.length === 1) return arr;

    let values = [];
    forEach(arr, (r) => {
      values = values.concat(r.values);
    });

    return createRule(
        arr[0].column,
        arr[0].operand,
        values,
        arr[0].isCaseSensitive
    );
  }

  mergeEntities(entity1, entity2, relation) {
    let result = null;
    // if relation is OR & both are rules & can be merged
    if (
          relation === MODEL_OR &&
          isRule(entity1) && isRule(entity2) &&
          this.canMergeRules([entity1, entity2])
        ) {
      result = this.mergeRules([entity1, entity2]);
    } else {
        // spread rules of the entity whose is a group & its relation is same as the current Op node
      const part1 = isGroup(entity1) && entity1.relation === relation ? entity1.rules : [entity1];
      const part2 = isGroup(entity2) && entity2.relation === relation ? entity2.rules : [entity2];
      result = createGroup(relation, [...part1, ...part2]);
    }

    return result;
  }

  traverse(expr) {
    if (this.isAnAllowedOperator(expr)) {
      this.rules = this.rules.concat(this.createRule(expr));
      return;
    }

    if (isModelOrInstance(expr) || isModelAndInstance(expr)) {
      const lastTwoEntities = this.rules.slice(-2); // get last 2 entities from rules
      this.rules = this.rules.slice(0, -2); // remove last 2 entities

      const result = this.mergeEntities(
        lastTwoEntities[0],
        lastTwoEntities[1],
        expr.constructor.name
        );
      this.rules = this.rules.concat(result);
    }
  }

  astToEntity(astJson) {
    if (!astJson) {
      return null;
    }
    const astObject = toAST(astJson);
    walkWith(this.traverse, astObject);
    return this.rules[0];
  }

  // converts entity rule format to ast class obj
  createRuleNode(entity) {
    if (!entity) return null;
    if (!isRule(entity)) return null;

    const values = entity.values || [];
    const nodes = [];

    values.forEach((v) => {
      let node = new VarExpr(entity.column); // create a VarExpr class
      if (!entity.isCaseSensitive) {
        node = new Func('LOWER', [node]); // wrap in Func class if its not case sensitive
      }
      const constValue = new Const(v);
      // wrap in the Op class
      switch (entity.operand) {
        case 'ContainsOp':
          node = new ContainsOp(node, constValue); break;
        case 'StartsWithOp':
          node = new StartsWithOp(node, constValue); break;
        case 'NotContainsOp':
          node = new NotContainsOp(node, constValue); break;
        case 'EqOp':
          node = new EqOp(node, constValue); break;
        case 'NotEqOp':
          node = new NotEqOp(node, constValue); break;
        default:
          console.log(entity.operand);
          throw new Exception('Not supported op');
      }
      nodes.push(node);
    });

    let ruleNode = null;
    for (let i=0; i<nodes.length; i++) {
      if (!ruleNode) {
        ruleNode = nodes[i];
      } else {
        ruleNode = new ModelOr(ruleNode, nodes[i]);
      }
    }

    return ruleNode;
  }

  // return ast json
  entityToAst(entity) {
    if (!entity) return null;

    if (isRule(entity)) {
      return this.createRuleNode(entity);
    }

    let groupNode = null;
    const nodes = [];
    if (entity.rules) {
      const rules = entity.rules;
      for (let i=0; i<rules.length; i++) {
        nodes.push(this.entityToAst(rules[i]));
      }
    }

    for (let i=0; i<nodes.length; i++) {
      if (!groupNode) groupNode=nodes[i];
      else {
        groupNode = entity.relation === MODEL_OR ?
                    new ModelOr(groupNode, nodes[i]) :
                    new ModelAnd(groupNode, nodes[i]);
      }
    }
    return groupNode;
  }

  getFilterLogic(entity) {
    let index = 0;
    const createFilterLogic = (_entity) => {
      if (!_entity) return '';

      if (isRule(_entity)) {
        index += 1;
        return index;
      }

      let result = '';
      const relation = isModelAndType(_entity.relation) ? 'AND' : 'OR';
      for (let i=0; i<_entity.rules.length; i++) {
        if (result === '') {
          result = createFilterLogic(_entity.rules[i]);
        } else {
          result = `${result} ${relation} ${createFilterLogic(_entity.rules[i])}`;
        }
      }
      result = `( ${result} )`;

      return result;
    };

    return createFilterLogic(entity);
  }

  getVerboseEntityRules(entity) {
    if (!entity) return null;

    if (isRule(entity)) {
      let operand = '';
      switch (entity.operand) {
        case 'ContainsOp': operand = 'contains'; break;
        case 'NotContainsOp': operand = 'does not contain'; break;
        case 'StartsWithOp': operand = 'starts with'; break;
        case 'EqOp': operand = 'equal to'; break;
        default: break;
      }
      const verboseRule = {
        column: entity.column,
        operand,
        values: entity.values,
      };
      this.verboseEntityRules.push(verboseRule);
    }

    if (isGroup(entity)) {
      for (let i=0; i<entity.rules.length; i++) {
        this.getVerboseEntityRules(entity.rules[i]);
      }
    }

    return this.verboseEntityRules.filter((vr) => vr);
  }
}
