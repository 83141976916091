
export default function createRoutes() {
  return {
    path: 'create/:id',
    name: 'createOrchestration',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        cb(null, containers.CreateOrchestration);
      }, 'orchestration_create');
    },
  };
}
