import React from 'react';
import PropTypes from 'prop-types';
import { decorators, errors } from 'utils/validators';
import { Field } from 'redux-form';
import { formLayout } from './constants';
import { Input, Tooltip2 } from 'v2-components';
import UTMBuilderLink from 'aa-components/UTMBuilder/UTMBuilderLink';
import FieldLabel from '../FieldLabels';

const FormInput = Input.form;

const CLICK_URL_FIELD = 'url';

const clickUrlValidations = [
  errors.isRequired,
  decorators.forceError(errors.validWebsite),
];

const CLICK_URL_LABEL = 'Click URL';
const ClickUrl = ({
  value,
  fieldSyncError,
  updateUrl,
  onCopyToAll,
  showCopyToAll,
  disableCopyToAll,
  className,
  disabled,
  tooltip,
}) => (<React.Fragment>
  <FieldLabel
    showCopyToAll={showCopyToAll}
    label={CLICK_URL_LABEL}
    onCopyToAll={() => onCopyToAll({ [CLICK_URL_FIELD]: value })}
    disableCopyToAll={disableCopyToAll || !!fieldSyncError}
    required
    className={'aam-b--10'}
  />
  <Tooltip2
    overlay={tooltip}
    placement={'top'}
  >
    <Field
      className={className}
      id="input_ad_URL"
      name={CLICK_URL_FIELD}
      type="text"
      formLayout={formLayout}
      component={FormInput}
      validate={clickUrlValidations}
      rightSideComponent={<UTMBuilderLink
        url={value}
        urlHasError={!!fieldSyncError}
        updateUrl={(updatedUrl) => updateUrl(CLICK_URL_FIELD, updatedUrl)}
        clickUrlValidations={clickUrlValidations}
        disabled={disabled}
      />}
      placeholder="Enter click URL"
      disabled={disabled}
    />
  </Tooltip2>
</React.Fragment>);
ClickUrl.propTypes = {
  value: PropTypes.string,
  fieldSyncError: PropTypes.string,
  tooltip: PropTypes.node,
  className: PropTypes.string,
  updateUrl: PropTypes.func,
  onCopyToAll: PropTypes.func,
  showCopyToAll: PropTypes.bool,
  disableCopyToAll: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ClickUrl;
export {
  CLICK_URL_FIELD,
  CLICK_URL_LABEL,
};
