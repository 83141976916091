import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'map',
    name: 'MAP Settings',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const sagas = require('./sagas');
          const reducer = require('./modules');
          injectReducer('mapv2', reducer.default);
          injectSagas('mapv2', sagas.default);
          const formWizardReducer = require('../../components/FormWizard2/modules');
          injectReducer('SettingsFormWizard', formWizardReducer.default);
          const dataSyncsReducer = require('../../components/DataSyncs/modules');
          injectReducer('DataSyncs', dataSyncsReducer.default);
          cb(null, containers.MAPContainer);
        },
        'mapv2',
      );
    },
  };
}
