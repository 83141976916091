import { createReducerUtil } from '../../utils/core';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  DATE_FILTER_OPTIONS_VALUES,
} from 'routes/Advertising/routes/Campaigns/components/DateFilter/constants';
import { has, pick, omit } from 'lodash';

const STATE_KEY = 'persistedCampaignListTimeframe';

const MODIFY_TIMEFRAME = 'PERSISTED/CAMPAIGNS_LIST_MODIFY_TIMEFRAME';
const modifyTimeFrame = (folderStateLookupKey, value, from, to) => ({
  type: MODIFY_TIMEFRAME,
  payload: { value, from, to },
  folderStateLookupKey,
});

const TOGGLE_APPLY_GLOBALLY = 'PERSISTED/CAMPAIGNS_LIST_TIMEFRAME_TOGGLE_APPLY_GLOBALLY';
const toggleApplyGlobally = (isChecked, nextState) => ({
  type: TOGGLE_APPLY_GLOBALLY,
  isChecked,
  nextState,
});

const INIT_FOLDER_STATES = 'PERSISTED/CAMPAIGNS_LIST_TIMEFRAME_INIT_FOLDER_STATES';
const initTimeframeFolderStates = (folderIds) => ({
  type: INIT_FOLDER_STATES,
  folderIds,
});

const INIT_NEW_FOLDER_STATE = 'PERSISTED/CAMPAIGNS_LIST_TIMEFRAME_INIT_NEW_FOLDER_STATE';
const initNewFolderTimeframeState = (folderId) => ({
  type: INIT_NEW_FOLDER_STATE,
  folderId,
});

const FOLDER_STATE_DELETE = 'PERSISTED/CAMPAIGNS_LIST_TIMEFRAME_FOLDER_STATE_DELETE';
const deleteFolderTimeframeState = (folderIdsToBeDeleted) => ({
  type: FOLDER_STATE_DELETE,
  folderIdsToBeDeleted,
});

const defaultValue = {
  value: DATE_FILTER_OPTIONS_VALUES.last_7_days,
  from: null,
  to: null,
};

const initialState = {
  filterState: {},
  applyGlobally: false,
};

function applyToAllFolders(prevState, nextState) {
  return Object.keys(prevState)
    .reduce(
      (nextStateAccumulator, folderLookupKey) => ({
        ...nextStateAccumulator,
        [folderLookupKey]: nextState,
      }),
      {},
    );
}

function insertNewFolderStates(allFolderIds, notDeletedFoldersState, applyGlobally) {
  const anyFolderState = Object.keys(notDeletedFoldersState)[0] ?
    notDeletedFoldersState[Object.keys(notDeletedFoldersState)[0]] :
    defaultValue;

  return allFolderIds.reduce(
    (nextState, folderId) => {
      if (has(notDeletedFoldersState, folderId) === false) {
        return {
          ...nextState,
          [folderId]: applyGlobally ? anyFolderState : defaultValue,
        };
      }

      return nextState;
    },
    notDeletedFoldersState,
  );
}

const reducer = createReducerUtil(initialState, {
  [MODIFY_TIMEFRAME]: (state, action) => {
    const { folderStateLookupKey } = action;
    if (state.applyGlobally) {
      return {
        ...state,
        filterState: applyToAllFolders(state.filterState, action.payload),
      };
    }

    return ({
      ...state,
      filterState: {
        ...state.filterState,
        [folderStateLookupKey]: action.payload,
      },
    });
  },
  [TOGGLE_APPLY_GLOBALLY]: (state, action) => ({
    ...state,
    applyGlobally: action.isChecked,
    filterState: action.isChecked ?
      applyToAllFolders(state.filterState, action.nextState) :
      state.filterState,
  }),
  [INIT_FOLDER_STATES]: (rehydratedState, action) => {
    const prevState = rehydratedState.filterState;
    const allFolderIds = action.folderIds;
    const notDeletedFoldersState = pick(prevState, allFolderIds);

    const nextState = insertNewFolderStates(
      allFolderIds,
      notDeletedFoldersState,
      rehydratedState.applyGlobally
    );

    return ({
      ...rehydratedState,
      filterState: nextState,
    });
  },
  [INIT_NEW_FOLDER_STATE]: (prevState, action) => {
    const { folderId } = action;

    const nextState = insertNewFolderStates(
      [folderId],
      prevState.filterState,
      prevState.applyGlobally
    );

    return ({
      ...prevState,
      filterState: nextState,
    });
  },
  [FOLDER_STATE_DELETE]: (prevState, action) => {
    const { folderIdsToBeDeleted } = action;
    const nextState = omit(prevState.filterState, folderIdsToBeDeleted);

    return ({
      ...prevState,
      filterState: nextState,
    });
  },
});

const persistConfig = {
  key: 'campaignListTimeframe',
  storage,
};
const persistedCampaignListTimeframe = persistReducer(persistConfig, reducer);
const persistedTimeframeFilterStateSelector = (state) => state[STATE_KEY].filterState;
const persistedTimeframeApplyGloballySelector = (state) => state[STATE_KEY].applyGlobally;

export {
  STATE_KEY,
  defaultValue,
  MODIFY_TIMEFRAME,
  modifyTimeFrame,
  toggleApplyGlobally,
  initTimeframeFolderStates,
  initNewFolderTimeframeState,
  deleteFolderTimeframeState,
  persistedCampaignListTimeframe,
  persistedTimeframeFilterStateSelector,
  persistedTimeframeApplyGloballySelector,
};
