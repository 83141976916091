import { getAsyncInjectors } from "store/utils";
import { AD_BASE_ROUTE } from "./constants";
import { ADVERTISING_PATHS } from "routes/AdvertisingDS/constants";

export default function adPlacementRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: AD_BASE_ROUTE,
    name: "ad",

    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require("./containers");
          const sagas = require("./sagas");
          const reducer = require("./modules");
          injectReducer("adPlacement", reducer.default);
          injectSagas("adPlacement", sagas.default);
          cb(null, containers.AdPlacement);
        },
        "ad_placement"
      );
    },
    childRoutes: [
      {
        path: "create",
        name: "ad create",
        indexRoute: {
          onEnter: (state, replace) =>
            replace(
              `/${ADVERTISING_PATHS.adCreateEdit}${state.location?.search}`
            ),
        },
      },
      {
        path: "edit/:id",
        name: "ad edit",
        indexRoute: {
          onEnter: (state, replace) => {
            replace(
              `/${ADVERTISING_PATHS.adCreateEdit}?adType=${state?.location?.query?.placement_type}&id=${state?.params?.id}`
            );
          },
        },
      },
      {
        path: "prerequisite",
        name: "prerequisiteHtml",
        getComponent(nextState, cb) {
          require.ensure(
            [],
            (require) => {
              const {
                PrerequisiteHtml,
              } = require("./components/StaticPages/PrerequisiteHtml");
              cb(null, PrerequisiteHtml);
            },
            "Prerequisite_Html"
          );
        },
      },
      {
        path: "dynamic",
        name: "prerequisiteDynamicHtml",
        getComponent(nextState, cb) {
          require.ensure(
            [],
            (require) => {
              const {
                PrerequisiteDynamicHtml,
              } = require("./components/StaticPages/PrerequisiteDynamicHtml");
              cb(null, PrerequisiteDynamicHtml);
            },
            "Prerequisite_Dynamic_Html"
          );
        },
      },
      {
        path: "prerequisiteAE",
        name: "prerequisiteAE",
        getComponent(nextState, cb) {
          require.ensure(
            [],
            (require) => {
              const {
                PrerequisiteAE,
              } = require("./components/StaticPages/PrerequisiteAE");
              cb(null, PrerequisiteAE);
            },
            "PrerequisiteAE"
          );
        },
      },
      {
        path: "prerequisiteGWD",
        name: "prerequisiteGWD",
        getComponent(nextState, cb) {
          require.ensure(
            [],
            (require) => {
              const {
                PrerequisiteGWD,
              } = require("./components/StaticPages/PrerequisiteGWD");
              cb(null, PrerequisiteGWD);
            },
            "PrerequisiteGWD"
          );
        },
      },
      {
        path: "iab-HTML5-clickTag-Standard",
        name: "prerequisiteHTMLClickTagStandard",
        getComponent(nextState, cb) {
          require.ensure(
            [],
            (require) => {
              const {
                PrerequisiteHTMLClickTagStandard,
              } = require("./components/StaticPages/PrerequisiteHTMLClickTagStandard");
              cb(null, PrerequisiteHTMLClickTagStandard);
            },
            "prerequisiteHTMLClickTagStandard"
          );
        },
      },
      {
        path: "library-with-adobe-edge",
        name: "libraryWithAdobeEdge",
        getComponent(nextState, cb) {
          require.ensure(
            [],
            (require) => {
              const {
                PrerequisiteLibraryWithAdobeEdge,
              } = require("./components/StaticPages/PrerequisiteLibraryWithAdobeEdge");
              cb(null, PrerequisiteLibraryWithAdobeEdge);
            },
            "libraryWithAdobeEdge"
          );
        },
      },
      {
        path: "library-with-google-web-designer",
        name: "libraryWithGoogleWebDesigner",
        getComponent(nextState, cb) {
          require.ensure(
            [],
            (require) => {
              const {
                PrerequisiteLibraryWithGWD,
              } = require("./components/StaticPages/PrerequisiteLibraryWithGWD");
              cb(null, PrerequisiteLibraryWithGWD);
            },
            "libraryWithGoogleWebDesigner"
          );
        },
      },
    ],
  };
}
