import Proptypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actions } from './modules';
import { allAppliedFiltersSelector } from './selectors';

export const Crossfilter = (config) => (WrappedComponent) => {
  const {
    key,
    extraContext,
    destroyOnUnmount = true,
    datasetKey = 'dataset',
    defaultSerializer = () => {},
  } = config;

  class CrossfilterClass extends Component {
    getChildContext() {
      return {
        _crossfilterRedux: {
          key,
          defaultSerializer,
          extraContext,
        },
      };
    }

    componentWillMount() {
      const dataset = this.props[datasetKey];
      this.props.createCrossfilter(dataset);
    }

    componentWillUnmount() {
      if (destroyOnUnmount) {
        this.props.destroyCrossfilter();
      }
    }

    render() {
      const { clearAllFilters, allAppliedFilters } = this.props;
      return (
        <WrappedComponent
          {...this.props}
          clearAllFilters={clearAllFilters}
          allAppliedFilters={allAppliedFilters}
        />);
    }
  }

  CrossfilterClass.propTypes = {
    createCrossfilter: Proptypes.func,
    clearAllFilters: Proptypes.func,
    allAppliedFilters: Proptypes.object,
    destroyCrossfilter: Proptypes.func,
  };

  CrossfilterClass.childContextTypes = {
    _crossfilterRedux: Proptypes.object,
  };

  const crossfilterActions = ({
    createCrossfilter: actions.createCrossfilter(key),
    clearAllFilters: actions.clearAllFilters(key),
    clearDimensionFilters: (dimKey) => actions.clearDimensionFilters(key, dimKey),
    destroyCrossfilter: actions.destroyCrossfilter(key),
  });

  const mapStatetoProps = (state) => ({
    allAppliedFilters: allAppliedFiltersSelector(key)(state),
  });

  return connect(mapStatetoProps, crossfilterActions)(CrossfilterClass);
};
