import React from 'react';
import Text from 'v2-components/Text';
import { localeString } from 'utils/utils';
import { NA } from './common/NA';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';
import { isDraftOrScheduledCampaign } from '../../../utils';

export const ACCOUNTS_NEWLY_ENGAGED = {
  sortByKey: 'newly_engaged',
  key: 'newly-engaged',
  csvColumnKey: 'accounts_newly_engaged',
  title: <Label name={CAMPAIGN_LABELS.accountsNewlyEngaged} />,
  dataIndex: 'campaign_data.newly_engaged',
  width: 200,
  render: (accountsNewlyEngaged, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (isDraftOrScheduledCampaign(campaignStatus)) {
      return (<NA />);
    }

    return (<Text
      type={Text.TYPE.SUBBODY}
    >
      {localeString(accountsNewlyEngaged)}
    </Text>);
  },
};
