import { get } from 'lodash';
import { fetchSaga, takeEarliest } from 'store/sagas';
import { select, call, put } from 'redux-saga/effects';
import { orgObjSelector, userObjectSelector } from 'modules/user/selectors';
import { LOAD_OWNER, loadOwnerSuccess, loadOwnerFailure } from './modules';
import { FORBIDDEN_INTERNAL_ROLES } from 'routes/Settings/routes/UserManagementV2/constants';

const throwIfNot404or403 = (e) => {
  if (e.errorStatus === 404 || e.errorStatus === 403) {
    return {};
  }
  throw e;
};

function* loadOwnerSaga(request) { // eslint-disable-line
  const user = yield select(userObjectSelector);
  if (FORBIDDEN_INTERNAL_ROLES.includes(user.role)) {
    return yield put(loadOwnerSuccess('your account owner'));
  }

  const { org_auth_id } = yield select(orgObjSelector);
  try {
    const response = yield call(
      request,
      `auth/v1/organizations/${org_auth_id}/owner/`,
      'GET',
      {},
      throwIfNot404or403,
    );

    const owner = get(response, 'organization_user.user.username', 'your account owner');
    yield put(loadOwnerSuccess(owner));
  } catch (e) {
    yield put(loadOwnerFailure('your account owner'));
  }
}

function* watchLoadOwner(request) {
  yield takeEarliest(LOAD_OWNER, loadOwnerSaga, request);
}

export default [fetchSaga(watchLoadOwner)];
