import { createSelector } from "reselect";

export const bulkDeleteClassificationBodySelector = createSelector(
  (_, action) => action,
  (action) => ({
    body: JSON.stringify({
      folder_ids: action.payload,
    }),
  })
);

export const renameClassificationBodySelector = createSelector(
  (_, action) => action,
  (action) => ({
    body: JSON.stringify({
      name: action.payload.folder.name,
    }),
  })
);
