import { PAGE_STATE_KEY, HOMEPAGE_DASHBOARD_ROUTE } from "./constants";
import { getAsyncInjectors } from "store/utils";

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: HOMEPAGE_DASHBOARD_ROUTE,
    name: "homepagedashboard",
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require("./containers");
          const reducer = require("./duck/reducers");
          const sagas = require("./duck/sagas");
          injectReducer(PAGE_STATE_KEY, reducer.default);
          injectSagas(PAGE_STATE_KEY, sagas.default);
          cb(null, containers.HomePageDashboard);
        },
        "homepagedashboard"
      );
    },
  };
}
