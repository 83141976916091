import LogRocket from 'logrocket';
import { isEmpty, get, startsWith } from 'lodash';
import { LOGROCKET_APP_ID } from './constants';
import { isProduction as _isProduction } from 'utils/utils';
import { LOAD_FILTERS_CONFIG_SUCCESS } from 'modules/segments';
import { LOAD_INBOX_SUC } from 'routes/Discover/routes/Inbox/modules';

const isProduction = _isProduction();

/* Network Sanitization */
const IGNORED_REQUEST_URLS: string = [
  'auth/v1/login',
  'auth/v2/login',
  'auth/v3/login',
  'rollbar',
  's3.amazonaws.com/6si-static',
  'pendo',
  'mixpanel',
].join('|');
const FORBIDDEN_FIELDS: string[] = ['client_secret', 'clientSecret', 'password'];

const APP_CONFIG = {
  network: {
    requestSanitizer: (request: any) => {
      // remove map/crm secret credentials
      let bod = {};
      if (!!request.url.match('nsync') && request.body) {
        bod = { ...JSON.parse(request.body) };
        for (const secretField of FORBIDDEN_FIELDS) {
          delete bod[secretField];
        }
        request.body = JSON.stringify(bod);
      }

      // ignore noisy requests
      const matchExpr = new RegExp(IGNORED_REQUEST_URLS, 'g');
      const ignoredRequest = request.url.match(matchExpr);
      if (!isEmpty(ignoredRequest)) {
        return null;
      }

      return request;
    },
  },
  dom: {
    inputSanitizer: true, // #PRIVACY-will not record user input from form elements
  },
};

/* Redux Sanitization */
const LARGE_PAYLOAD_ACTIONS = [
  LOAD_FILTERS_CONFIG_SUCCESS,
  LOAD_INBOX_SUC,
];
const SANITIZATION_CONFIG = {
  actionSanitizer(action: any) {

    // cannot send actions w/ payload > 4MB
    const payloadLimitExceeded = LARGE_PAYLOAD_ACTIONS.includes(action.type);
    if (payloadLimitExceeded) {
      return {
        type: action.type,
        payload: 'payload exceeds size constraints',
      };
    }

    if (startsWith(action.type, 'AUTH/')) {
      return {
        type: action.type,
        payload: 'omitted for security',
      };
    }

    return action;
  },
  stateSanitizer: (state) => ({
    ...state,
    form: undefined,
    login: undefined,
    segments: {
      ...state.segments,
      filtersConfig: 'filter config omitted due to size constraints',
    },
    discoverInbox: {
      ...state.inbox,
      notifications: 'notification list omitted due to size constrations',
    },
  }),
};

const getLogRocketMiddleware = () => isProduction
  ? LogRocket.reduxMiddleware(SANITIZATION_CONFIG)
  : 0;

const initLogRocket = (): void => {
  try {
    if (isProduction) {
      LogRocket.init(LOGROCKET_APP_ID, APP_CONFIG);
      console.info('LogRocket initialized');
    } else {
      console.info('LogRocket not initialized for non-production environment');
    }
  } catch (e) {
    console.error('Unable to initialize logrocket', e);
  }
};

const identifyUser = (user: any): void => {
  if (isProduction && !isEmpty(user)) {
    LogRocket.identify(user.username, {
      role: user.role,
      host: window?.host,
      email: get(user, 'username', ''),
      orgId: get(user, 'organization.id', ''),
      org: get(user, 'organization.org_name', ''),
    });
  }
};

export {
  getLogRocketMiddleware,
  initLogRocket,
  identifyUser,
};
