import React from 'react';
import PropTypes from 'prop-types';
import styles from './Storybook.module.scss';
import UserContext from 'contexts/UserContext';

const Storybook = ({ user }) => user.is_internaluser || user.is_dev || user.is_staff ? (
  <iframe className={styles.container} src={process.env.STORYBOOK_PATH} />
) : null;

Storybook.propTypes = {
  user: PropTypes.object,
};

export default UserContext.UserConsumer(Storybook);
