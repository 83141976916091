import {
  CAMPAIGNS_CONFIGURATION_ROUTE,
  CONFIGURATION_MODES,
  PAGE_STATE_KEY,
} from "./constants";
import { CAMPAIGN_SOURCE } from "../../constants";
import { getAsyncInjectors } from "store/utils";
import { INVENTORY_LIST_STATE_KEY } from "./components/InventoryListField/components/ListModal/constants";

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: `${CAMPAIGNS_CONFIGURATION_ROUTE}/:mode/:modeInfo`,
    name: "campaignConfiguration",
    indexRoute: {
      onEnter: (nextState, replace) => {
        const {
          params: { mode, modeInfo },
        } = nextState;
        const invalidMode = !Object.values(CONFIGURATION_MODES).includes(mode);
        if (invalidMode) {
          replace("/404");
        }
        const invalidCampaignSource =
          [
            CAMPAIGN_SOURCE.INTERNAL,
            CAMPAIGN_SOURCE.EXTERNAL,
            CAMPAIGN_SOURCE.RETARGETING,
            CAMPAIGN_SOURCE.CONTEXTUAL,
            CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING,
          ].includes(modeInfo) === false;
        if (mode === CONFIGURATION_MODES.create && invalidCampaignSource) {
          replace("/404");
        }
      },
    },
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const campaignConfiguration = require("./containers/CampaignConfiguration");

          const sagas = require("./sagas");
          const reducer = require("./modules");
          injectReducer(PAGE_STATE_KEY, reducer.default);
          injectSagas(PAGE_STATE_KEY, sagas.default);

          const inventoryListSagas = require("./components/InventoryListField/components/ListModal/sagas");
          injectSagas(INVENTORY_LIST_STATE_KEY, inventoryListSagas.default);

          cb(null, campaignConfiguration.default);
        },
        "campaignConfiguration"
      );
    },
  };
}
