import React from 'react';

/* eslint max-len: [2, 100000] */
export function SolarSystem() {
  return (
    <svg width="113" height="110" viewBox="0 0 113 110" xmlns="http://www.w3.org/2000/svg">
      <title>Solar system</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M58 10v2c11.052 0 21.04 4.474 28.284 11.716C93.526 30.96 98 40.948 98 52c0 11.052-4.474 21.04-11.716 28.284C79.04 87.526 69.052 92 58 92c-11.052 0-21.04-4.474-28.284-11.716C22.474 73.04 18 63.052 18 52c0-11.052 4.474-21.04 11.716-28.284C36.96 16.474 46.948 12 58 12V8c-24.3.002-43.998 19.7-44 44 .002 24.3 19.7 44 44 44s44-19.7 44-44S82.3 8.002 58 8v2"
          fill="#2D3666"
        />
        <path
          d="M65.07 44.93c3.906 3.904 3.906 10.236 0 14.14-3.904 3.906-10.236 3.906-14.14 0-3.906-3.904-3.906-10.236 0-14.14 3.904-3.906 10.236-3.906 14.14 0"
          fill="#FFDCA1"
        />
        <path
          d="M58 26v2c6.634 0 12.62 2.683 16.97 7.03C79.318 39.38 82 45.365 82 52c0 6.634-2.683 12.62-7.03 16.97C70.62 73.317 64.635 76 58 76c-6.634 0-12.62-2.683-16.97-7.03C36.682 64.62 34 58.634 34 52c0-6.634 2.683-12.62 7.03-16.97C45.38 30.682 51.365 28 58 28v-4c-15.464 0-28 12.535-28 28s12.536 28 28 28c15.465 0 28-12.535 28-28S73.465 24 58 24v2"
          fill="#2D3666"
        />
        <path
          d="M106 52c0 3.314-2.686 6-6 6s-6-2.686-6-6c0-3.313 2.686-6 6-6s6 2.687 6 6"
          fill="#78D5DD"
        />
        <path
          d="M106 52h-2c0 1.11-.445 2.1-1.172 2.83-.73.726-1.717 1.17-2.828 1.17-1.11 0-2.098-.444-2.83-1.17C96.445 54.1 96 53.11 96 52s.444-2.098 1.17-2.828c.732-.728 1.72-1.17 2.83-1.172 1.11 0 2.098.444 2.828 1.172.727.73 1.17 1.717 1.172 2.828h4c-.002-4.42-3.58-8-8-8s-8 3.58-8 8 3.58 8 8 8 7.998-3.58 8-8h-2"
          fill="#2D3666"
        />
        <path
          d="M100 48c-1.11 0-2.098.444-2.828 1.172-.728.73-1.17 1.717-1.172 2.828 0 1.11.444 2.1 1.172 2.83l.11.105c-.22-1.5.245-3.088 1.402-4.25.974-.97 2.248-1.454 3.52-1.454.245 0 .49.02.734.055-.036-.038-.072-.076-.11-.113-.73-.728-1.717-1.17-2.828-1.172"
          fill="#58C3CE"
        />
        <path
          d="M99.948 46.97c-1.274 0-2.548.487-3.52 1.46-.78.777-1.25 1.753-1.403 2.764l-.016.16c-.18 1.468.293 2.997 1.418 4.117.13.13.264.255.41.364.283.23.587.423.905.578l-.075-.164c-.14-.3-.244-.613-.314-.93-.028-.128-.052-.255-.072-.383-.037-.035-.075-.07-.11-.106C96.444 54.097 96 53.11 96 52c0-1.11.445-2.098 1.17-2.83C97.9 48.445 98.89 48 100 48c1.11 0 2.1.444 2.83 1.17.035.04.072.077.108.115.126.02.25.043.376.07.38.086.747.215 1.096.39-.154-.32-.35-.622-.578-.906-.11-.146-.233-.28-.363-.41-.972-.973-2.246-1.46-3.522-1.46"
          fill="#21315F"
        />
        <path
          d="M42.434 30.778c1.562 1.562 1.562 4.094 0 5.656-1.562 1.563-4.095 1.563-5.657 0-1.562-1.562-1.562-4.094 0-5.656 1.562-1.562 4.095-1.562 5.657 0"
          fill="#78D5DD"
        />
        <path
          d="M42.434 30.778l-1.414 1.414c.394.396.583.896.586 1.414-.003.518-.192 1.02-.586 1.415-.396.395-.896.584-1.415.586-.518-.002-1.018-.192-1.414-.585-.393-.395-.582-.896-.585-1.414.003-.52.192-1.02.586-1.414.397-.395.897-.584 1.415-.586.52.002 1.02.19 1.415.586l1.414-1.414 1.415-1.415c-1.168-1.168-2.716-1.76-4.245-1.757-1.528-.002-3.076.59-4.242 1.757-1.168 1.166-1.76 2.714-1.758 4.243-.002 1.53.59 3.076 1.758 4.243 1.166 1.166 2.714 1.758 4.242 1.756 1.53.002 3.077-.59 4.243-1.757 1.168-1.168 1.76-2.715 1.758-4.244.002-1.53-.59-3.076-1.758-4.243l-1.414 1.415"
          fill="#2D3666"
        />
        <path
          d="M58.07 43.661c-2.22.002-4.442.85-6.133 2.546-1.363 1.353-2.178 3.054-2.447 4.816-.01.095-.026.19-.026.278-.312 2.56.512 5.223 2.473 7.175.225.225.46.442.71.633.495.4 1.025.738 1.58 1.007-.043-.096-.095-.192-.13-.287-.243-.52-.425-1.068-.546-1.623-.64-2.8.13-5.873 2.317-8.069 1.697-1.69 3.917-2.535 6.132-2.535.65.002 1.3.075 1.936.22.66.148 1.302.373 1.91.676-.27-.556-.608-1.085-1.007-1.58-.192-.25-.41-.484-.635-.71-1.69-1.697-3.913-2.545-6.134-2.545"
          fill="#FFCD83"
        />
        <path
          d="M58 44.004c-2.05 0-4.098.78-5.657 2.34-3.12 3.118-3.12 8.194 0 11.313 3.12 3.12 8.195 3.12 11.314 0 3.12-3.12 3.12-8.195 0-11.314-1.56-1.56-3.608-2.34-5.657-2.34M58 64c-3.205 0-6.22-1.248-8.485-3.515C47.248 58.22 46 55.205 46 52c0-3.205 1.248-6.22 3.515-8.485C51.78 41.248 54.795 40 58 40c3.205 0 6.22 1.248 8.485 3.515C68.752 45.78 70 48.795 70 52c0 3.205-1.248 6.22-3.515 8.485C64.22 62.752 61.205 64 58 64M100 48c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4m0 12c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8"
          fill="#2D3666"
        />
        <path
          d="M39.606 31.606c-.518.002-1.018.19-1.414.586-.394.395-.583.895-.586 1.414.003.518.192 1.02.586 1.415.06.06.12.113.183.163-.09-.744.153-1.52.723-2.094.497-.496 1.146-.743 1.793-.743.095 0 .19.006.283.016-.047-.06-.098-.116-.152-.17l.38-.38-.38.38c-.396-.396-.896-.585-1.414-.587"
          fill="#58C3CE"
        />
        <path
          d="M39.743 31.196c-.65 0-1.3.248-1.794.744-.4.396-.637.893-.716 1.408l-.008.08c-.09.75.15 1.528.723 2.1l.207.184c.145.117.3.216.462.294l-.04-.083c-.07-.153-.123-.312-.158-.475-.02-.087-.036-.176-.047-.265-.063-.05-.124-.104-.183-.162-.394-.395-.583-.895-.586-1.414.003-.52.192-1.02.586-1.413.396-.396.896-.585 1.414-.587.52.002 1.02.19 1.414.586l.38-.38c-.474-.41-1.066-.616-1.657-.616m1.657.617l-.38.38c.056.054.107.112.154.17.095.01.19.027.284.05.192.042.38.108.558.197-.078-.163-.177-.318-.294-.462l-.185-.208c-.045-.044-.09-.087-.137-.127"
          fill="#21315F"
        />
        <path
          d="M39.606 31.607c-.513 0-1.025.194-1.415.585-.778.78-.778 2.048.002 2.828.778.78 2.05.78 2.828 0 .78-.78.78-2.05 0-2.83-.39-.39-.902-.583-1.414-.583m0 7.995c-1.537 0-3.073-.584-4.243-1.753-2.34-2.342-2.34-6.147 0-8.487 2.34-2.338 6.144-2.34 8.485 0 2.34 2.34 2.34 6.146 0 8.485-1.17 1.17-2.706 1.754-4.243 1.754"
          fill="#2D3666"
        />
        <path
          d="M108 77.162l-.05.154c-.1.298-.336.533-.634.632l-.154.052.154.052c.298.098.533.334.633.632l.05.153.05-.153c.1-.298.335-.534.634-.632l.154-.052-.154-.052c-.3-.1-.533-.334-.633-.632l-.05-.154"
          fill="#9AE0E6"
        />
        <path
          d="M108 73c-.432 0-.812.274-.948.684l-.843 2.526-2.526.84c-.41.138-.684.518-.684.95 0 .432.274.812.684.95l2.525.84.842 2.526c.136.41.516.684.948.684.432 0 .812-.274.948-.684l.842-2.525 2.526-.84c.41-.138.684-.518.684-.95 0-.432-.274-.812-.684-.95l-2.526-.84-.842-2.526c-.136-.41-.516-.684-.948-.684m-.05 4.316l.05-.154.05.154c.1.298.335.534.634.633l.154.05-.154.052c-.3.1-.533.334-.633.632l-.05.154-.05-.154c-.1-.298-.336-.533-.634-.632l-.154-.052.154-.05c.298-.1.533-.336.633-.634M15 90.91c-.028.032-.058.062-.09.09.032.028.062.058.09.09.028-.032.057-.062.09-.09-.032-.028-.062-.058-.09-.09"
          fill="#9AE0E6"
        />
        <path
          d="M15 87c-.432 0-.812.274-.95.683l-.59 1.776-1.777.59c-.41.137-.683.518-.683.95 0 .432.274.812.683.948l1.776.592.59 1.776c.138.41.518.684.95.684.432 0 .812-.274.948-.684l.593-1.775 1.776-.592c.41-.136.684-.516.684-.948 0-.432-.274-.813-.684-.95l-1.776-.59-.592-1.777c-.136-.41-.516-.683-.948-.683m-.09 4c.032-.028.062-.058.09-.09.028.032.057.062.09.09-.033.028-.062.057-.09.09-.028-.033-.058-.062-.09-.09M91 8.91c-.028.032-.057.062-.09.09.033.028.062.058.09.09.028-.032.058-.062.09-.09-.032-.028-.062-.058-.09-.09"
          fill="#9AE0E6"
        />
        <path
          d="M91 5c-.432 0-.812.274-.95.683l-.59 1.776-1.776.59c-.41.137-.684.518-.684.95 0 .432.274.812.684.948l1.775.592.59 1.776c.138.41.518.684.95.684.432 0 .812-.274.948-.684l.592-1.776 1.776-.592c.41-.136.684-.516.684-.948 0-.432-.274-.813-.684-.95l-1.776-.59-.592-1.777C91.812 5.273 91.432 5 91 5m-.09 4c.032-.028.062-.058.09-.09.028.032.057.062.09.09-.033.027-.062.057-.09.09-.028-.033-.058-.063-.09-.09M12 19.162l-.05.154c-.1.298-.335.533-.633.632l-.155.052.155.05c.298.1.532.335.632.634l.05.154.05-.154c.1-.3.336-.533.634-.633l.154-.05-.154-.052c-.298-.1-.533-.334-.633-.632l-.05-.154"
          fill="#9AE0E6"
        />
        <path
          d="M12 15c-.432 0-.812.274-.948.683l-.843 2.526-2.526.84C7.274 19.19 7 19.57 7 20c0 .432.274.812.684.948l2.526.842.842 2.526c.136.41.516.684.948.684.432 0 .812-.274.95-.684l.84-2.526 2.526-.842c.41-.136.684-.516.684-.948 0-.432-.274-.812-.684-.95l-2.525-.84-.84-2.527C12.81 15.273 12.43 15 12 15m-.05 4.316l.05-.154.05.154c.1.298.336.533.634.632l.154.052-.154.05c-.298.1-.533.335-.633.634l-.05.154-.05-.154c-.1-.3-.335-.533-.633-.633l-.155-.05.155-.052c.298-.1.533-.334.632-.632M2 52c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M36 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M108 30c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M90 98c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M52 106c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2"
          fill="#9AE0E6"
        />
      </g>
    </svg>
  );
}
