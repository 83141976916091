import { ThemeProvider } from '@sixsense/core/style';
import designSystemTheme from '@sixsense/core/style/theme/design-system-theme';
import { PropTypes } from 'prop-types';
import React, { memo } from 'react';

const DesignSystemThemeHocComponent = (props) => {
  const { children } = props;
  return (
    <ThemeProvider theme={designSystemTheme} nonce={window.__CSP_NONCE__} >
      {children}
    </ThemeProvider>
  );
};

DesignSystemThemeHocComponent.propTypes = {
  children: PropTypes.any,
};

export const DesignSystemThemeHoc = memo(DesignSystemThemeHocComponent);
