import { defineRequestState } from '@sixsense/core';

const PATH_PREFIX = 'settings.componentsmetrics';

export const componentsAndMetricsRequestState = defineRequestState({
  path: `${PATH_PREFIX}.config`,
  getConfig: (orgId : number) => ({
    url: `settings/${orgId}/components-and-metrics/`,
  }),
});
