import { defineRequestState } from '@sixsense/core/request';
import { SalesStageGroup, StageOption } from './types';

export const stageOptionsRequestState = defineRequestState({
  path: 'settings.salesStage.stageOptions',
  getConfig: (orgId: number | string) => ({
    url: `query/${orgId}/cmo_dashboard/stage_opps/`,
  }),
  transformData: (data: StageOption[]) => data,
});

export const stageConfigRequestState = defineRequestState({
  path: 'settings.salesStage.stageConfig',
  getConfig: (orgId: number | string) => ({
    url: `org/${orgId}/sales_stage_config/`,
  }),
  transformData: (data: any): SalesStageGroup[] => data.stage_config,
});

export const TMP_STAGE_STATE = [
  {
    group_name: 'No Activity',
    stages: [
      { source: 'SIXSENSE', stage: 'NO_ACTIVITY', name: 'No Activity' },
    ],
  },
  {
    group_name: 'Reached',
    stages: [
      { source: 'SIXSENSE', stage: 'REACHED', name: 'Reached' },
    ],
  },
  {
    group_name: 'Engaged',
    stages: [
      { source: 'SIXSENSE', stage: 'ENGAGED', name: 'Engaged' },
    ],
  },
  {
    group_name: '6QA',
    stages: [
      { source: 'SIXSENSE', stage: '6QA', name: '6QA' },
    ],
  },
  {
    group_name: 'Opp Won',
    stages: [
      {
        source: 'CRM_OPPORTUNITY_STAGE',
        stage: 'stage 6 - closed won',
        name: 'stage 6 - closed won',
      },
      {
        source: 'CRM_OPPORTUNITY_STAGE',
        stage: 'stage 5 - closed win',
        name: 'stage 5 - closed win',
      },
    ],
  },
];
