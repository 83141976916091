import React from "react";
import { NA } from "./common/NA";
import { CAMPAIGN_STATUS_LABELS, CAMPAIGN_STATUS_X } from "../../../constants";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { Badge } from "@sixsense/core/components";
import { pxToRem } from "@sixsense/core/style";

export const CAMPAIGN_STATUS_STYLES = {
  [CAMPAIGN_STATUS_X.active]: "success",
  [CAMPAIGN_STATUS_X.scheduled]: "gray",
  [CAMPAIGN_STATUS_X.completed]: "primary",
  [CAMPAIGN_STATUS_X.draft]: "gray",
  [CAMPAIGN_STATUS_X.deactivated]: "error",
};

export const STATUS = {
  sortByKey: "campaign_status",
  customizable: true,
  defaultSelected: true,
  key: "status",
  csvColumnKey: "status",
  title: <Label name={CAMPAIGN_LABELS.campaignStatus} />,
  width: pxToRem(130),
  render: (_, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (!campaignStatus) {
      return <NA />;
    }

    return (
      <Badge mode="dark" color={CAMPAIGN_STATUS_STYLES[campaignStatus]}>
        {CAMPAIGN_STATUS_LABELS[campaignStatus]}
      </Badge>
    );
  },
};
