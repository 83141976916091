import React from 'react';
import PropTypes from 'prop-types';
import { Alert as AntAlert } from 'antd';
import { Icon } from 'v2-components';
import { classNames } from 'utils/utils';

import styles from './Alert.module.scss';

function Alert({
  className,
  banner,
  type,
  icon,
  ...rest
}) {
  return (
    <AntAlert
      className={
        classNames(
          type === 'info' && styles.customColor,
          className,
        )}
      type={type}
      banner={banner}
      icon={type === 'error' && !icon ? <Icon
        type="error"
        size={Icon.SIZE.SMALL}
        color={Icon.COLORS.ERROR}
      /> : icon}
      {...rest}
    />
  );
}

Alert.propTypes = {
  className: PropTypes.string,
  banner: PropTypes.bool,
  scrollToAccountList: PropTypes.func,
  type: PropTypes.string,
  icon: PropTypes.element,
};
export default Alert;
