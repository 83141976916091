import SixQAAnalyticsRoutes from './routes/SixQAAnalytics';

export default function createRoutes() {
  return {
    path: '6qa-performance',
    name: 'SixQAPerformance',
    indexRoute: {
      onEnter: (nextState, replace) =>
        replace('/reports/6qa-performance/6qa-analytics'),
    },
    childRoutes: [SixQAAnalyticsRoutes()],
  };
}
