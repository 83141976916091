export default function createRoutes() {
  return {
    path: 'influenced-conversions',
    name: 'InfluencedConversions',
    getComponent(_nextState, cb) {
      require.ensure(
        [],
        (require) => {
          // reducer and sagas are injected at the settings level
          const containers = require('./containers');
          cb(null, containers.InfluencedConversions);
        },
        'influenced_conversions'
      );
    },
  };
}
