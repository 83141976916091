import { omit } from 'lodash';

const SHOW_GENERIC_MODAL = 'MODAL/SHOW_GENERIC_MODAL';
const CLOSE_GENERIC_MODAL = 'MODAL/CLOSE_GENERIC_MODAL';

function showGenericModal(additionalData) {
  return { type: SHOW_GENERIC_MODAL, additionalData };
}

function closeGenericModal() {
  return { type: CLOSE_GENERIC_MODAL };
}

const initialState = {
  visible: false,
};

function genericModalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_GENERIC_MODAL: {
      return {
        ...state,
        ...omit(action, ['type']),
        visible: true,
      };
    }
    case CLOSE_GENERIC_MODAL: {
      return {
        ...state,
        visible: false,
      };
    }
    default:
      return state;
  }
}

export default genericModalReducer;

export const actions = {
  showGenericModal,
  closeGenericModal,
};
