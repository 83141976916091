import { omit } from 'lodash';
import { createReducerUtil } from 'utils/core';
import { DEFAULT_RULE, PRESET_STRING } from '../constants';
import { convertAstToEntity } from '../rbmAstUtils';

export const initialState = {
  ruleEditMode: 'Create', // CreateRuleModal.MODE.CREATE,
  ruleObj: null, // rule object from api
  ruleObjEditCopy: null, // rule copy object used for edit and comparsion
  ruleEntity: null, // the array struct created from ruleObjEditCopy.ast
  isCreateRuleModalOpen: false, //  bool used to show/hide the create/update rule modal
  isRuleCreatedSuccessModalOpen: false, // bool used to show/hide the success modal on rule creation
   // bool used to show/hide the comfirmation modal for rule update
  isUpdateRuleCfmModalOpen: false,
  impactedRecordsLastCallPayload: null, // payoad with which last get impacted records was called
};

export const setPayload = (state, action) => {
  const payload = omit(action, 'type');
  return { ...state, ...payload };
};


const PREP_RULE_OBJ_FOR_CREATE = `${PRESET_STRING}/PREP_RULE_OBJ_FOR_CREATE`;
function prepRuleObjForCreate(classificationType, dataSource) {
  return {
    type: PREP_RULE_OBJ_FOR_CREATE,
    ruleEditMode: 'Create', // CreateRuleModal.MODE.CREATE,
    classificationType,
    dataSource,
  };
}

const PREP_RULE_OBJ_FOR_EDIT = `${PRESET_STRING}/PREP_RULE_OBJ_FOR_EDIT`;
function prepRuleObjForEdit(ruleObj) {
  return {
    type: PREP_RULE_OBJ_FOR_EDIT,
    ruleEditMode: 'Update', // CreateRuleModal.MODE.UPDATE,
    ruleObj,
  };
}

const PREP_RULE_OBJ_FOR_VALIDATE = `${PRESET_STRING}/PREP_RULE_OBJ_FOR_VALIDATE`;
function prepRuleObjForValidate(ruleObj) {
  return {
    type: PREP_RULE_OBJ_FOR_VALIDATE,
    ruleEditMode: 'Validate and Save', // CreateRuleModal.MODE.VALIDATE,
    ruleObj,
  };
}

const SET_RULE_OBJ_EDIT_COPY = `${PRESET_STRING}/SET_RULE_OBJ_EDIT_COPY`;
function setRuleObjEditCopy(ruleObjEditCopy) {
  return {
    type: SET_RULE_OBJ_EDIT_COPY,
    ruleObjEditCopy,
  };
}

const UPDATE_RULE_OBJ_EDIT_COPY = `${PRESET_STRING}/UPDATE_RULE_OBJ_EDIT_COPY`;
function updateRuleObjEditCopy(updates) {
  return {
    type: UPDATE_RULE_OBJ_EDIT_COPY,
    updates,
  };
}

const SET_RULE_ENTITY = `${PRESET_STRING}/SET_RULE_ENTITY`;
function setRuleEntity(ruleEntity) {
  return {
    type: SET_RULE_ENTITY,
    ruleEntity,
  };
}

const SET_RULE_CREATED_SUCCESS_MODAL_OPEN = `${PRESET_STRING}/SET_RULE_CREATED_SUCCESS_MODAL_OPEN`;
function setRuleCreatedSuccessModalOpen(isRuleCreatedSuccessModalOpen) {
  return {
    type: SET_RULE_CREATED_SUCCESS_MODAL_OPEN,
    isRuleCreatedSuccessModalOpen,
  };
}

const SET_CREATE_RULE_MODAL_OPEN = `${PRESET_STRING}/SET_CREATE_RULE_MODAL_OPEN`;
function setCreateRuleModalOpen(isCreateRuleModalOpen) {
  return {
    type: SET_CREATE_RULE_MODAL_OPEN,
    isCreateRuleModalOpen,
  };
}

const SET_UPDATE_RULE_CFM_MODAL_OPEN = `${PRESET_STRING}/SET_UPDATE_RULE_CFM_MODAL_OPEN`;
function setUpdateRuleCfmModalOpen(isUpdateRuleCfmModalOpen) {
  return {
    type: SET_UPDATE_RULE_CFM_MODAL_OPEN,
    isUpdateRuleCfmModalOpen,
  };
}

const SET_IMPACTED_RECORDS_LAST_CALL_PAYLOAD =
  `${PRESET_STRING}/SET_IMPACTED_RECORDS_LAST_CALL_PAYLOAD`;
function setImpactedRecordsLastCallPayload(impactedRecordsLastCallPayload) {
  return {
    type: SET_IMPACTED_RECORDS_LAST_CALL_PAYLOAD,
    impactedRecordsLastCallPayload,
  };
}

function _prepRuleForEdit(state, action) {
  if (action.ruleObj) {
    const ruleEditMode = action.ruleEditMode;

    let _ruleEntity;
    if (action.ruleObj.ast) {
      // action.ruleObj.ast is a JSON string, need to convert to JSON
      _ruleEntity = convertAstToEntity(JSON.parse(action.ruleObj.ast));
    } else {
      _ruleEntity = DEFAULT_RULE;
    }

    return {
      ...state,
      ruleObj: action.ruleObj,
      ruleObjEditCopy: { ...action.ruleObj },
      ruleEntity: _ruleEntity,
      ruleEditMode,
    };
  }
  return state;
}

export const ruleEditReducer = createReducerUtil(
  { ...initialState },
  {
    [PREP_RULE_OBJ_FOR_CREATE]: (state, action) => {
      const data_source = action.dataSource;
      const classification_type = action.classificationType;
      const ruleEditMode = action.ruleEditMode;
      return {
        ...state,
        ruleObj: null,
        ruleObjEditCopy: {
          // populate the ds & cs to which this new rule belongs to
          data_source, classification_type,
        },
        ruleEntity: DEFAULT_RULE, // add default rule
        ruleEditMode,
      };
    },
    [PREP_RULE_OBJ_FOR_EDIT]: _prepRuleForEdit,
    [PREP_RULE_OBJ_FOR_VALIDATE]: _prepRuleForEdit,
    [SET_RULE_OBJ_EDIT_COPY]: setPayload,
    [SET_IMPACTED_RECORDS_LAST_CALL_PAYLOAD]: setPayload,
    [UPDATE_RULE_OBJ_EDIT_COPY]: (state, action) => ({
      ...state,
      ruleObjEditCopy: { ...state.ruleObjEditCopy, ...action.updates },
    }),
    [SET_RULE_ENTITY]: setPayload,
    [SET_RULE_CREATED_SUCCESS_MODAL_OPEN]: setPayload,
    [SET_CREATE_RULE_MODAL_OPEN]: setPayload,
    [SET_UPDATE_RULE_CFM_MODAL_OPEN]: setPayload,
  }
);

export const actionTypes = {
  PREP_RULE_OBJ_FOR_CREATE,
  PREP_RULE_OBJ_FOR_EDIT,
  PREP_RULE_OBJ_FOR_VALIDATE,
  SET_RULE_OBJ_EDIT_COPY,
  UPDATE_RULE_OBJ_EDIT_COPY,
  SET_RULE_ENTITY,
  SET_RULE_CREATED_SUCCESS_MODAL_OPEN,
  SET_CREATE_RULE_MODAL_OPEN,
  SET_UPDATE_RULE_CFM_MODAL_OPEN,
  SET_IMPACTED_RECORDS_LAST_CALL_PAYLOAD,
};

export const actions = {
  prepRuleObjForCreate,
  prepRuleObjForEdit,
  prepRuleObjForValidate,
  setRuleObjEditCopy,
  updateRuleObjEditCopy,
  setRuleEntity,
  setRuleCreatedSuccessModalOpen,
  setCreateRuleModalOpen,
  setUpdateRuleCfmModalOpen,
  setImpactedRecordsLastCallPayload,
};

