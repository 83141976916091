/* eslint-disable max-len */
import { createSelector } from 'reselect';
import { forEach, get, pickBy, filter, map, orderBy } from 'lodash';
import {
  PRODUCT_DATA_TYPE_MAPPING,
  STAGES_FOR_MODELS,
  STAGES_INDEX_MAP_FOR_MODELS,
  MODEL_TO_EXPRIMENT_TYPE_MAPPING,
  TAB_OPTIONS,
  SIMPLE_CHART_MODEL_UPDATE,
  SIMPLE_CHART_MODEL_METRICS,
  CONTACT_SOURCE_BEHAVIOURS,
  CONTACT_SOURCE_PROFILE_FIT,
  CONTACT_TYPE,
} from './constants';
import moment from 'moment';
import { categoryFormatters, formatCaps } from '../Model/utils';
import { DesignSystemColor } from '@sixsense/core/style';

const getNewArray = (length) =>
  Array(length)
    .fill(null)
    .map(() => ({}));

const _modelUpdateSelector = (state) => state.modelUpdate || {};
export const getModelUpdateDataSelector = createSelector(
  _modelUpdateSelector,
  ({ modelUpdateData }) => modelUpdateData
);
export const getModelMetricsDataSelector = createSelector(
  _modelUpdateSelector,
  ({
    modelMetricsData,
    selectedSourceContactBehaviour,
    selectedSourceContactProfileFit,
  }) => {
    let { contactBehaviourResponse, contactProfileFitResponse } =
      modelMetricsData;
    contactBehaviourResponse = filter(
      contactBehaviourResponse,
      ({ source }) => source === selectedSourceContactBehaviour
    );
    contactProfileFitResponse = filter(
      contactProfileFitResponse,
      ({ source }) => source === selectedSourceContactProfileFit
    );
    return {
      ...modelMetricsData,
      contactBehaviourResponse,
      contactProfileFitResponse,
    };
  }
);

export const getSignalsDataSelector = createSelector(
  _modelUpdateSelector,
  ({ signalsData }) => signalsData
);
export const getLoadingSelector = createSelector(
  _modelUpdateSelector,
  ({ loading }) => loading
);
export const selectedTabSelector = createSelector(
  _modelUpdateSelector,
  ({ selectedTab }) => selectedTab || TAB_OPTIONS.ACCOUNT
);


export const loadingSetSelector = createSelector(
  _modelUpdateSelector,
  ({ loadingSet }) => loadingSet
);

export const loadingSetContainsFnSelector = createSelector(
  _modelUpdateSelector,
  ({ loadingSet }) =>
    (loadingConst) =>
      loadingSet.has(loadingConst)
);

export const elementIdToScrollSelector = createSelector(
  _modelUpdateSelector,
  ({ elementIdToScroll }) => elementIdToScroll
);

export const productMapSelector = (state) => {
  const allEnabledProducts = pickBy(
    get(state, 'user.user.organization.allProducts', {}),
    ({ is_deleted }) => !is_deleted
  );
  return allEnabledProducts;
};

export const getProductSelectorOptions = createSelector(
  productMapSelector,
  (productMap) =>
    orderBy(
      filter(
        map(productMap, (product) =>
          product.is_deleted || product.statename !== 'models_done'
            ? null
            : {
              value: product.name,
              label: product.display_name,
            }
        ),
        (v) => !!v
      ),
      'label'
    )
);

export const getProductSelected = createSelector(
  _modelUpdateSelector,
  getProductSelectorOptions,

  ({ productSelected }, productOptions) => {
    if (productSelected) {
      return productSelected;
    }
    if (productOptions.length > 0) {
      return productOptions[0].value;
    }
    return '';
  }
);

export const getBehaviourDateLatestFnSelector = createSelector(
  getModelMetricsDataSelector,
  ({ accountBehaviourDateRange, contactBehaviourDateRange }) =>
    (selectedTab) => {
      const behaviourDateRangeFiltered = filter(
        selectedTab === TAB_OPTIONS.ACCOUNT
          ? accountBehaviourDateRange
          : contactBehaviourDateRange,
        ({ metric_name }) => metric_name === 'behavior'
      );
      if (
        behaviourDateRangeFiltered &&
        behaviourDateRangeFiltered[0] &&
        behaviourDateRangeFiltered[0].dates
      ) {
        return behaviourDateRangeFiltered[0].dates.sort().at(-1);
      }
      return null;
    }
);

export const getSelectionDataSelector = createSelector(
  _modelUpdateSelector,
  getBehaviourDateLatestFnSelector,
  (
    {
      selectedAccountBehaviourDate,
      selectedContactBehaviourDate,
      selectedSourceContactBehaviour,
      selectedSourceContactProfileFit,
      selectedTab,
    },
    getBehaviourDateLatestFn
  ) => ({
    selectedAccountBehaviourDate:
      selectedAccountBehaviourDate ||
      getBehaviourDateLatestFn(TAB_OPTIONS.ACCOUNT),
    selectedContactBehaviourDate:
      selectedContactBehaviourDate ||
      getBehaviourDateLatestFn(TAB_OPTIONS.CONTACTS),
    selectedSourceContactBehaviour,
    selectedSourceContactProfileFit,
    selectedTab,
  })
);

export const isModelInstancesValidationSelector = createSelector(
  getModelUpdateDataSelector,
  ({ modelInstances }) => modelInstances.length > 0
);

export const isModelInstancesValidationForProductFnSelector = createSelector(
  getProductSelected,
  getModelUpdateDataSelector,

  (productSelected, { modelInstances }) =>
    (exprimentTypeForModel) =>
      filter(
        modelInstances,
        ({ product, experiment_type_name }) =>
          product === productSelected &&
          experiment_type_name === exprimentTypeForModel
      ).length > 0
);
export const contactSourceOptionFnSelector = createSelector(
  () => (contactType) =>
    [
      {
        label: 'CRM Lead',
        value:
          contactType === CONTACT_TYPE.contactBehaviours
            ? CONTACT_SOURCE_BEHAVIOURS.CRM_LEAD
            : CONTACT_SOURCE_PROFILE_FIT.CRM_LEAD,
      },
      {
        label: 'MAP Contact',
        value:
          contactType === CONTACT_TYPE.contactBehaviours
            ? CONTACT_SOURCE_BEHAVIOURS.MAP_CONTACT
            : CONTACT_SOURCE_PROFILE_FIT.MAP_CONTACT,
      },
      {
        label: 'CRM Contact',
        value:
          contactType === CONTACT_TYPE.contactBehaviours
            ? CONTACT_SOURCE_BEHAVIOURS.CRM_CONTACT
            : CONTACT_SOURCE_PROFILE_FIT.CRM_CONTACT,
      },
    ]
);

export const isAccountBehaviourModelUpdateSelector = createSelector(
  isModelInstancesValidationForProductFnSelector,
  getSelectionDataSelector,
  getBehaviourDateLatestFnSelector,
  (
    isModelInstancesValidationForProductFn,
    { selectedAccountBehaviourDate },
    getBehaviourDateLatestFn
  ) =>
    isModelInstancesValidationForProductFn(
      MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour
    ) &&
    selectedAccountBehaviourDate ===
      getBehaviourDateLatestFn(TAB_OPTIONS.ACCOUNT)
);

export const getBehaviourDateOptionsAllSelector = createSelector(
  getModelMetricsDataSelector,
  selectedTabSelector,
  ({ accountBehaviourDateRange, contactBehaviourDateRange }, selectedTab) => {
    const behaviourDateRangeFiltered = filter(
      selectedTab === TAB_OPTIONS.ACCOUNT
        ? accountBehaviourDateRange
        : contactBehaviourDateRange,
      ({ metric_name }) => metric_name === 'behavior'
    );
    if (behaviourDateRangeFiltered.length > 0) {
      return [...(behaviourDateRangeFiltered[0].dates.sort() || [])]
        .reverse()
        .map((date) => ({
          value: date,
          label: moment(date).format('MMMM YYYY'),
        }));
    }
    return [];
  }
);

// Todo: Recheck
export const getSimpleChartRadioOptionsFnSelector = createSelector(
  isAccountBehaviourModelUpdateSelector,
  isModelInstancesValidationForProductFnSelector,
  (isBehaviourModelUpdate, isModelInstancesValidationForProductFn) =>
    (exprimentTypeForModel, selectedTab) => {
      if (
        exprimentTypeForModel ===
        MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour
      ) {
        if (selectedTab === TAB_OPTIONS.CONTACTS) {
          return SIMPLE_CHART_MODEL_METRICS.contactsBehaviour;
        }
        return isBehaviourModelUpdate
          ? SIMPLE_CHART_MODEL_UPDATE.accountBehaviour
          : SIMPLE_CHART_MODEL_METRICS.accountBehaviour;
      }
      if (
        exprimentTypeForModel ===
        MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit
      ) {
        if (selectedTab === TAB_OPTIONS.CONTACTS) {
          return SIMPLE_CHART_MODEL_METRICS.contactsProfileFit;
        }
        return isModelInstancesValidationForProductFn(
          MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit
        )
          ? SIMPLE_CHART_MODEL_UPDATE.accountProfileFit
          : SIMPLE_CHART_MODEL_METRICS.accountProfileFit;
      }
      return [];
    }
);

export const getAccountsStagesOptionSelectedFnSelector = createSelector(
  _modelUpdateSelector,
  ({
      accountsBuyingStagesOptionSelected,
      accountsProfileFitStagesOptionSelected,
    }) =>
    (exprimentTypeForModel) => {
      if (
        exprimentTypeForModel ===
        MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour
      ) {
        return accountsBuyingStagesOptionSelected;
      } else if (
        exprimentTypeForModel ===
        MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit
      ) {
        return accountsProfileFitStagesOptionSelected;
      }
      return '';
    }
);

export const getAccountSimpleChartModelUpdateStagesDataFnSelector =
  createSelector(
    getModelUpdateDataSelector,
    getAccountsStagesOptionSelectedFnSelector,
    getSimpleChartRadioOptionsFnSelector,
    getProductSelected,
    (
        { accountBehaviourConverstionRates, profileFitAccountConversionRates },
        accountsStagesOptionSelectedFn,
        getSimpleChartRadioOptionsFn,
        productSelected
      ) =>
      (
        exprimentTypeForModel,
        stageDataType,
        stagesOptionSelectedCustom = null
      ) => {
        let Stages = [];
        let StagesIndexMapping = {};
        let converstionRates = [];
        let productDataType = '';
        const stagesOptionSelected =
          stagesOptionSelectedCustom ||
          accountsStagesOptionSelectedFn(exprimentTypeForModel);
        const { valueUnit, extraValueType, extraValueUnit } =
          filter(
            getSimpleChartRadioOptionsFn(
              exprimentTypeForModel,
              TAB_OPTIONS.ACCOUNT
            ),
            ({ value }) => value === stagesOptionSelected
          )[0] || {};
        if (
          exprimentTypeForModel ===
          MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour
        ) {
          Stages = STAGES_FOR_MODELS.accountBehaviour;
          StagesIndexMapping = STAGES_INDEX_MAP_FOR_MODELS.accountBehaviour;
          converstionRates = accountBehaviourConverstionRates;
          productDataType = PRODUCT_DATA_TYPE_MAPPING.accountBehaviour;
        } else if (
          exprimentTypeForModel ===
          MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit
        ) {
          Stages = STAGES_FOR_MODELS.accountProfileFit;
          StagesIndexMapping = STAGES_INDEX_MAP_FOR_MODELS.accountProfileFit;
          converstionRates = profileFitAccountConversionRates;
          productDataType = PRODUCT_DATA_TYPE_MAPPING.accountProfileFit;
        }
        const categories = [...Stages];

        const categoriesIndexMap = {
          ...StagesIndexMapping,
        };

        const currentSeries = {
          data: getNewArray(categories.length),
          name: 'Current',
          type: 'column',
          color: '#696F7B',
        };
        const upcomingSeries = {
          data: getNewArray(categories.length),
          name: 'Upcoming',
          type: 'column',
          color: '#0D96D5',
        };

        forEach(converstionRates, (converstion) => {
          const { status = 'Promoted' } = converstion;
          const product = converstion[productDataType];

          if (product !== productSelected) {
            return;
          }
          const dataValue = converstion[stagesOptionSelected];
          const stageData = converstion[stageDataType];
          if (status === 'Validated') {
            upcomingSeries.data[categoriesIndexMap[stageData]] = {
              y: dataValue,
              valueUnit,
              extraValue: converstion[extraValueType],
              extraValueUnit,
            };
          }
          if (status === 'Promoted') {
            currentSeries.data[categoriesIndexMap[stageData]] = {
              y: dataValue,
              valueUnit,
              extraValue: converstion[extraValueType],
              extraValueUnit,
            };
          }
        });
        const series = [currentSeries, upcomingSeries];
        return {
          categories,
          series,
        };
      }
  );

export const getAccountSimpleChartModelMetricsStagesDataFnSelector =
  createSelector(
    getModelMetricsDataSelector,
    getAccountsStagesOptionSelectedFnSelector,
    getSimpleChartRadioOptionsFnSelector,
    selectedTabSelector,
    (
        {
          accountBehaviourResponse = [],
          accountProfileFitResponse = [],

          contactBehaviourResponse = [],
          contactProfileFitResponse = [],
        },
        accountsStagesOptionSelectedFn,
        getSimpleChartRadioOptionsFn,
        selectedTab
      ) =>
      (
        exprimentTypeForModel,
        stageDataType,
        stagesOptionSelectedCustom = null
      ) => {
        let Stages = [];
        let StagesIndexMapping = {};
        let converstionRates = [];
        const stagesOptionSelected =
          stagesOptionSelectedCustom ||
          accountsStagesOptionSelectedFn(exprimentTypeForModel);
        const { valueUnit, extraValueType, extraValueUnit } =
          filter(
            getSimpleChartRadioOptionsFn(exprimentTypeForModel, selectedTab),
            ({ value }) => value === stagesOptionSelected
          )[0] || {};
        if (
          exprimentTypeForModel ===
          MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour
        ) {
          Stages = STAGES_FOR_MODELS.accountBehaviour;
          StagesIndexMapping = STAGES_INDEX_MAP_FOR_MODELS.accountBehaviour;
          converstionRates = accountBehaviourResponse;
          if (selectedTab === TAB_OPTIONS.CONTACTS) {
            converstionRates = contactBehaviourResponse;
            Stages = STAGES_FOR_MODELS.contactBehaviour;
            StagesIndexMapping = STAGES_INDEX_MAP_FOR_MODELS.contactBehaviour;
          }
        } else if (
          exprimentTypeForModel ===
          MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit
        ) {
          Stages = STAGES_FOR_MODELS.accountProfileFit;
          StagesIndexMapping = STAGES_INDEX_MAP_FOR_MODELS.accountProfileFit;
          converstionRates = accountProfileFitResponse;
          if (selectedTab === TAB_OPTIONS.CONTACTS) {
            converstionRates = contactProfileFitResponse;
          }
        }
        const categories = [...Stages];

        const categoriesIndexMap = {
          ...StagesIndexMapping,
        };

        const currentSeries = {
          data: getNewArray(categories.length),
          name: ' ',
          type: 'column',
          color: '#0D96D5',
        };

        forEach(converstionRates, (converstion) => {
          const dataValue = converstion[stagesOptionSelected];
          const stageData = converstion[stageDataType];

          currentSeries.data[categoriesIndexMap[stageData]] = {
            y: dataValue,
            valueUnit,
            extraValue: converstion[extraValueType],
            extraValueUnit,
          };
        });
        const series = [currentSeries];
        return {
          categories,
          series,
        };
      }
  );

export const getAccountParetoChartModelUpdateConversionRateDataFnSelector =
  createSelector(
    getModelUpdateDataSelector,
    getProductSelected,
    (
        { accountBehaviourConverstionRates, profileFitAccountConversionRates },
        productSelected
      ) =>
      (
        exprimentTypeForModel,
        dataType,
        dataTypeUnit,
        liftDataType,
        liftDataTypeUnit,
        plotDataType,
        stageDataType
      ) => {
        let Stages = [];
        let StagesIndexMapping = {};
        let converstionRates = [];
        let productDataType = '';
        if (
          exprimentTypeForModel ===
          MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour
        ) {
          Stages = STAGES_FOR_MODELS.accountBehaviour;
          StagesIndexMapping = STAGES_INDEX_MAP_FOR_MODELS.accountBehaviour;
          converstionRates = accountBehaviourConverstionRates;
          productDataType = PRODUCT_DATA_TYPE_MAPPING.accountBehaviour;
        } else if (
          exprimentTypeForModel ===
          MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit
        ) {
          Stages = STAGES_FOR_MODELS.accountProfileFit;
          StagesIndexMapping = STAGES_INDEX_MAP_FOR_MODELS.accountProfileFit;
          converstionRates = profileFitAccountConversionRates;
          productDataType = PRODUCT_DATA_TYPE_MAPPING.accountProfileFit;
        }
        const categories = [...Stages];

        const currentBaseSeries = getNewArray(categories.length);
        const upcomingBaseSeries = getNewArray(categories.length);

        const categoriesIndexMap = {
          ...StagesIndexMapping,
        };

        const currentSeries = {
          data: getNewArray(categories.length),
          name: 'Current Conversion Rate',
          type: 'column',
          color: '#696F7B',
        };
        const upcomingSeries = {
          data: getNewArray(categories.length),
          name: 'Upcoming Conversion Rate',
          type: 'column',
          color: '#0D96D5',
        };
        let plotValue = 0;
        forEach(converstionRates, (converstion) => {
          const { status = 'Promoted' } = converstion;
          const product = converstion[productDataType];
          if (product !== productSelected) {
            return;
          }
          plotValue = converstion[plotDataType];
          const dataValue = converstion[dataType];
          const stageData = converstion[stageDataType];

          if (status === 'Validated') {
            upcomingSeries.data[categoriesIndexMap[stageData]] = {
              y: dataValue,
              valueUnit: dataTypeUnit,
            };
            upcomingBaseSeries[categoriesIndexMap[stageData]] = {
              y: converstion[liftDataType],
              valueUnit: liftDataTypeUnit,
            };
          }
          if (status === 'Promoted') {
            currentSeries.data[categoriesIndexMap[stageData]] = {
              y: dataValue,
              valueUnit: dataTypeUnit,
            };
            currentBaseSeries[categoriesIndexMap[stageData]] = {
              y: converstion[liftDataType],
              valueUnit: liftDataTypeUnit,
            };
          }
        });
        const series = [
          currentSeries,
          {
            type: 'pareto',
            name: 'Current Conversion Lift',
            color: DesignSystemColor.Gray.DARK_4,
            zIndex: 10,
            data: currentBaseSeries,
            yAxis: 1,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: '%',
            },
          },
          upcomingSeries,
          {
            type: 'pareto',
            name: 'Upcoming Conversion Lift',
            zIndex: 10,
            data: upcomingBaseSeries,
            color: DesignSystemColor.Blue.DARK_4,
            yAxis: 1,
            dashStyle: 'LongDash',

            tooltip: {
              valueDecimals: 2,
              valueSuffix: '%',
            },
            marker: {
              enabled: false,
            },
          },
          {
            // Series that mimics the plot line
            color: '#CA8504',
            type: 'pareto',
            name: 'Baseline',
            visible: true,
            marker: {
              symbol: 'circle',
            },
            events: {
              // Event for showing/hiding plot line
              legendItemClick() {
                return false;
              },
            },
          },
        ];
        return {
          categories,
          series,
          plotValue,
        };
      }
  );

export const getAccountParetoChartModelMetricsConversionRateDataFnSelector =
  createSelector(
    getModelMetricsDataSelector,
    selectedTabSelector,
    (
        {
          accountBehaviourResponse = [],
          accountProfileFitResponse = [],

          contactBehaviourResponse = [],
          contactProfileFitResponse = [],
        },
        selectedTab
      ) =>
      (
        exprimentTypeForModel,
        dataType,
        dataTypeUnit,
        liftDataType,
        liftDataTypeUnit,
        plotDataType,
        stageDataType
      ) => {
        let Stages = [];
        let StagesIndexMapping = {};
        let converstionRates = [];
        if (
          exprimentTypeForModel ===
          MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour
        ) {
          Stages = STAGES_FOR_MODELS.accountBehaviour;
          StagesIndexMapping = STAGES_INDEX_MAP_FOR_MODELS.accountBehaviour;
          converstionRates = accountBehaviourResponse;
          if (selectedTab === TAB_OPTIONS.CONTACTS) {
            converstionRates = contactBehaviourResponse;
            Stages = STAGES_FOR_MODELS.contactBehaviour;
            StagesIndexMapping = STAGES_INDEX_MAP_FOR_MODELS.contactBehaviour;
          }
        } else if (
          exprimentTypeForModel ===
          MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit
        ) {
          Stages = STAGES_FOR_MODELS.accountProfileFit;
          StagesIndexMapping = STAGES_INDEX_MAP_FOR_MODELS.accountProfileFit;
          converstionRates = accountProfileFitResponse;
          if (selectedTab === TAB_OPTIONS.CONTACTS) {
            converstionRates = contactProfileFitResponse;
          }
        }
        const categories = [...Stages];

        const currentBaseSeries = getNewArray(categories.length);

        const categoriesIndexMap = {
          ...StagesIndexMapping,
        };

        const currentSeries = {
          data: getNewArray(categories.length),
          name: 'Conversion Rate',
          type: 'column',
          color: '#0D96D5',
        };
        let plotValue = 0;

        forEach(converstionRates, (converstion) => {
          plotValue = converstion[plotDataType];
          const dataValue = converstion[dataType];
          const stageData = converstion[stageDataType];
          currentSeries.data[categoriesIndexMap[stageData]] = {
            y: dataValue,
            valueUnit: dataTypeUnit,
          };
          currentBaseSeries[categoriesIndexMap[stageData]] = {
            y: liftDataType(converstion),
            valueUnit: liftDataTypeUnit,
          };
        });
        const series = [
          currentSeries,
          {
            type: 'pareto',
            name: 'Conversion Lift',
            zIndex: 10,
            data: currentBaseSeries,
            yAxis: 1,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: '%',
            },
          },
          {
            // Series that mimics the plot line
            color: '#CA8504',
            type: 'pareto',
            name: 'Baseline',
            visible: true,
            marker: {
              symbol: 'circle',
            },
            events: {
              // Event for showing/hiding plot line
              legendItemClick() {
                return false;
              },
            },
          },
        ];
        return {
          categories,
          series,
          plotValue,
        };
      }
  );

export const getAccountModelUpdateOpportunityXValueFnSelector = createSelector(
  getModelUpdateDataSelector,
  getProductSelected,
  (
      { accountBehaviourConverstionRates, profileFitAccountConversionRates },
      productSelected
    ) =>
    (exprimentTypeForModel, multiplierType) => {
      let converstionRates = [];
      let productDataType = '';
      if (
        exprimentTypeForModel ===
        MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour
      ) {
        converstionRates = accountBehaviourConverstionRates;
        productDataType = PRODUCT_DATA_TYPE_MAPPING.accountBehaviour;
      } else if (
        exprimentTypeForModel ===
        MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit
      ) {
        converstionRates = profileFitAccountConversionRates;
        productDataType = PRODUCT_DATA_TYPE_MAPPING.accountProfileFit;
      }
      const opportunityList = filter(converstionRates, (converstion) => {
        const { status } = converstion;
        const product = converstion[productDataType];
        let isValid = false;
        if (
          exprimentTypeForModel ===
          MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour
        ) {
          isValid =
            converstion.buying_stage === 'Purchase' && status === 'Validated';
        } else if (
          exprimentTypeForModel ===
          MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit
        ) {
          isValid =
            converstion.profile_fit === 'Strong' && status === 'Validated';
        }
        if (product === productSelected && isValid) {
          return true;
        }
        return false;
      });
      if (opportunityList.length > 0) {
        return opportunityList[0][multiplierType].toFixed(2);
      }
      return 'N/A';
    }
);

export const getAccountModelMetrixOpportunityXValueFnSelector = createSelector(
  getModelMetricsDataSelector,
  selectedTabSelector,
  (
      {
        accountBehaviourResponse = [],
        accountProfileFitResponse = [],

        contactBehaviourResponse = [],
        contactProfileFitResponse = [],
      },
      selectedTab
    ) =>
    (exprimentTypeForModel, multiplierType) => {
      let converstionRates = [];
      if (
        exprimentTypeForModel ===
        MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour
      ) {
        converstionRates = accountBehaviourResponse;
        if (selectedTab === TAB_OPTIONS.CONTACTS) {
          converstionRates = contactBehaviourResponse;
        }
      } else if (
        exprimentTypeForModel ===
        MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit
      ) {
        converstionRates = accountProfileFitResponse;
        if (selectedTab === TAB_OPTIONS.CONTACTS) {
          converstionRates = contactProfileFitResponse;
        }
      }
      const opportunityList = filter(converstionRates, (converstion) => {
        let isValid = false;
        if (
          exprimentTypeForModel ===
          MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour
        ) {
          isValid = converstion.buying_stage === 'Purchase';
          if (selectedTab === TAB_OPTIONS.CONTACTS) {
            isValid = converstion.buying_stage === 'A';
          }
        } else if (
          exprimentTypeForModel ===
          MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit
        ) {
          isValid = converstion.product_scores_profile_fit === 'Strong';
        }
        if (isValid) {
          return true;
        }
        return false;
      });
      if (opportunityList.length > 0) {
        return multiplierType(opportunityList[0]).toFixed(2);
      }
      return 'N/A';
    }
);

export const getTransitionImpactDataFnSelector = createSelector(
  getModelUpdateDataSelector,
  getProductSelected,
  (
      { accountBehaviourTransitionImpact, profileFitTransitionImpact },
      productSelected
    ) =>
    (exprimentTypeForModel) => {
      let Stages = [];
      let StagesIndexMapping = {};
      let transitionImpact = [];
      if (
        exprimentTypeForModel ===
        MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour
      ) {
        Stages = STAGES_FOR_MODELS.accountBehaviour;
        StagesIndexMapping = STAGES_INDEX_MAP_FOR_MODELS.accountBehaviour;
        transitionImpact = accountBehaviourTransitionImpact;
      } else if (
        exprimentTypeForModel ===
        MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit
      ) {
        Stages = STAGES_FOR_MODELS.accountProfileFit;
        StagesIndexMapping = STAGES_INDEX_MAP_FOR_MODELS.accountProfileFit;
        transitionImpact = profileFitTransitionImpact;
      }
      transitionImpact = filter(
        transitionImpact,
        ({ product }) => product === productSelected
      );
      const data = [];
      const currentTotal = Array(Stages.length).fill(0);
      const upcomingTotal = Array(Stages.length).fill(0);
      forEach(
        transitionImpact,
        ({
          stage_prod = 'Purchase',
          stage_new = 'Purchase',
          total_mids = 517,
        }) => {
          const ic = StagesIndexMapping[stage_prod];
          currentTotal[ic] += total_mids;
          const iu = StagesIndexMapping[stage_new];
          upcomingTotal[iu] += total_mids;
        }
      );
      forEach(Stages, (stage) => {
        const si = StagesIndexMapping[stage];
        const dataRow = {
          stage,
          currentTotal: currentTotal[si],
          upcomingTotal: upcomingTotal[si],
          dataObject: getNewArray(Stages.length),
        };
        forEach(
          transitionImpact,
          ({
            stage_prod = 'Purchase',
            stage_new = 'Purchase',
            total_mids = 517,
          }) => {
            if (stage_prod !== stage) {
              return;
            }
            dataRow.dataObject[StagesIndexMapping[stage_new]] = {
              data: total_mids,
              from: stage_prod,
              to: stage_new,
            };
          }
        );
        data.push(dataRow);
      });
      return {
        data,
        impact: upcomingTotal.at(-1) - currentTotal.at(-1),
      };
    }
);

export const isGivenExprimentTypeModelFnSelector = createSelector(
  getModelUpdateDataSelector,
  getProductSelected,
  ({ modelInstances }, productSelected) =>
    (exprimentType) =>
      filter(modelInstances, ({ product, experiment_type_name }) => {
        if (
          product === productSelected &&
          experiment_type_name === exprimentType
        ) {
          return true;
        }
        return false;
      }).length > 0
);

export const getAcountIdentificationRatesFnSelector = createSelector(
  getModelUpdateDataSelector,
  getProductSelected,
  (
      { accountBehaviourIdentificationRates, profileFitIdentificationRates },
      productSelected
    ) =>
    (exprimentType) => {
      let identificationRates = [];
      if (MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour === exprimentType) {
        identificationRates = accountBehaviourIdentificationRates;
      } else if (
        MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit === exprimentType
      ) {
        identificationRates = profileFitIdentificationRates;
      }
      const filteredList = filter(
        identificationRates,
        ({ product }) => product === productSelected
      );
      return filteredList[0] || {};
    }
);

// Selector to get signal categories
export const signalCategoriesSelector = createSelector(
  getSignalsDataSelector,
  (signalData) => (signalType) => {
    const data = signalData[signalType].data;
    const categories = [{ label: 'All Categories', key: 'All Categories' }];
    if (data && data.length > 0) {
      const sortedResults = orderBy(data, 'conversion_lift', 'desc');
      sortedResults.forEach((res) => {
        const formattedCategory =
          categoryFormatters[res.category] || formatCaps(res.category);
        if (
          categories.findIndex(
            (element) => element.label === formattedCategory
          ) === -1
        ) {
          categories.push({ label: formattedCategory, key: res.category });
        }
      });
    }
    return categories;
  }
);

// Selector to get signal data
export const signalDataSelector = createSelector(
  getSignalsDataSelector,
  (signalData) => (signalType) => signalData[signalType].data
);

// Selector to get selected category for a signal
export const signalSelectedCategorySelector = createSelector(
  getSignalsDataSelector,
  (signalData) => (signalType) => signalData[signalType].selectedCategory
);

// Selector to check if there's an error loading data for a signal
export const signalErrorLoadingDataSelector = createSelector(
  getSignalsDataSelector,
  (signalData) => (signalType) => signalData[signalType].errorLoadingData
);

// Selector to check if data is loading for a signal
export const signalLoadingDataSelector = createSelector(
  getSignalsDataSelector,
  (signalData) => (signalType) => signalData[signalType].loadingData
);

// Selector to get data display type and order for a signal
export const signalDataDisplaySelector = createSelector(
  getSignalsDataSelector,
  (signalData) => (signalType) => ({
    dataType: signalData[signalType].dataDisplayType,
    dataOrder: signalData[signalType].dataDisplayOrder,
  })
);
