import React from 'react';
import { Grid as GridWithType } from '@sixsense/core/components';
import { SingleRoleDropdownComponent } from './SingleRoleDropdown';
import styles from './AppsSingleRolesDropdown.module.scss';
import PropTypes from 'prop-types';
import { DUMMY_SELECT_VALUE } from '../../../constants';


const Grid = GridWithType;

export const AppsSingleRolesDropdownComponent = ({
  moreOptions = [],
  isSelectedUserPrimaryAdmin = false,
  appsDisabledMap,
  appsValueMap,
  appsOnChangeMap,
  colSize = 3,
  getRoleTitle = (sn) => sn,
  wrapperRef,
  appsList,
  isNoRoleAssign,
  isSelectRole,
  noRoleAssignValue = -1,
  selectRoleValue = DUMMY_SELECT_VALUE,
}) => (
  <Grid
    templateColumns={Array(colSize).fill()
        .map(() => '1fr')
        .join(' ')}
    columnGap={16}
  >
    {appsList.map(({ id: appId, shortName, longName }, index) => (
      <Grid.Cell
        row={Math.floor(index / colSize + 1)}
        column={(index % colSize) + 1}
      >
        <Grid templateColumns="1fr">
          <Grid.Cell row="1" column="1" className={styles.label}>
            {getRoleTitle(shortName, longName)}
          </Grid.Cell>
          <Grid.Cell row="2" column="1">
            <SingleRoleDropdownComponent
              disabled={appsDisabledMap[appId]}
              value={appsValueMap[appId]}
              onChange={(roleId) => appsOnChangeMap[appId](roleId, appId)}
              isSelectedUserPrimaryAdmin={isSelectedUserPrimaryAdmin}
              appId={appId}
              wrapperRef={wrapperRef}
              moreOptions={moreOptions}
              isNoRoleAssign={isNoRoleAssign}
              isSelectRole={isSelectRole}
              noRoleAssignValue={noRoleAssignValue}
              selectRoleValue={selectRoleValue}
            />
          </Grid.Cell>
        </Grid>
      </Grid.Cell>
      ))}
  </Grid>
  );

AppsSingleRolesDropdownComponent.propTypes = {
  moreOptions: PropTypes.any,
  isSelectedUserPrimaryAdmin: PropTypes.bool,
  appsDisabledMap: PropTypes.any,
  appsValueMap: PropTypes.any,
  appsOnChangeMap: PropTypes.any,
  colSize: PropTypes.number,
  wrapperRef: PropTypes.any,
  getRoleTitle: PropTypes.func,
  appsList: PropTypes.array,
  isNoRoleAssign: PropTypes.bool,
  isSelectRole: PropTypes.bool,
  noRoleAssignValue: PropTypes.any,
  selectRoleValue: PropTypes.any,
};
