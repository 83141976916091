// @ts-ignore
import React from 'react';
import s3Logo from 'images/IntegrationLogos/s3-logo.svg';
import { FeaturedIcon } from '@sixsense/core/components';
import { LayersTwo, Database, Dataflow } from '@sixsense/core/icons';

export const S3_REDUX_STATE_KEY:string = 's3';
export const S3_ROUTE:string = 's3';
export const S3_ACTION_BASESTRING:string = 'settings/integration/s3';
export const S3_REFRESH_ACTIONNAME:string = 'settings/integration/s3/REFRESH';

export const S3SummaryConfig:Object = {
  header: {
    logo: s3Logo,
    mainTitle: 'S3',
    category: 'Object Storage',
    subTitle: 'by Amazon.com Inc.',
  },
  about: {
    mainInfo: {
      title: 'About this Integration',
      description:
      'The 6sense S3 Integration allows your data team to easily leverage industry-leading'+
      ' 6sense data directly in your S3 Bucket to support internal reporting and modeling needs.',
    },
    metaInfo: [
      {
        name: 'Category',
        value: 'Object Storage',
      },
      {
        name: 'Feature Support',
        value: 'Internal Models, Dashboards & Reporting',
      },
    ],
    keyfeatures: {
      title: 'Key Features',
      features: [
        {
          name: 'Storage and Data Sets Unification',
          value: 'Using direct data pushed from 6sense to S3',
          icon: <FeaturedIcon theme="light-circle" icon={Database} color="Gray" />,
        },
        {
          name: 'Build internal models, dashboard, and reports',
          value: 'Leveraging unified data sets',
          icon: <FeaturedIcon theme="light-circle" icon={LayersTwo} color="Gray" />,
        },
        {
          name: 'Automate and Streamline Workflows',
          value: 'With direct integrations into S3 for internal reporting '+
          'and modeling, reducing operational overhead',
          icon: <FeaturedIcon theme="light-circle" icon={Dataflow} color="Gray" />,
        },
      ],
    },
  },
  help: {
    links: [
      {
        title: 'Installation Guide',
        list: [
          {
            // eslint-disable-next-line max-len
            href: 'https://support.6sense.com/knowledge-base/6sense-s3-setting-up-bucket-policy-permissions/',
            text: '6sense S3 Integrations',
          },
        ],
      },
    ],
  },
};

export const S3_FORM_STEP_IDS = Object.freeze({
  PREREQUISITES: 'prerequisites',
  CONNECT: 'connect',
  CONFIGURE: 'configure',
});

export const S3_FORM_NAMES = Object.freeze({
  CONNECT: 'snowflake_connect',
  CONFIGURE: 'snowflake_configure',
});
