import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, uniqBy } from 'lodash';
import { Row, Text, Link } from 'v2-components';
import {
  toDisplayfp,
  formatDate,
  truncateString,
  capitalize,
  wrapUrlWith6SiPreviewParam,
} from 'utils/utils';
import { DATE_FORMAT } from 'utils/constants';
import { TablePreviewLayout } from 'aa-components';
import styles from './PreviewPane.module.scss';
import {
  VIEW_CAMPAIGN_DETAILS_ROUTE,
} from 'routes/Advertising/routes/Campaigns/routes/CreateCampaign/constants';

const { AdPreview } = TablePreviewLayout;

const AdMetaData = ({ title, subTitle }) => (
  <Row flexDirection={Row.FLEX_DIRECTION.COLUMN} className={styles.creativeMetaData}>
    <Text color={Text.COLOR.GREY} className={`${styles.modalTitleMain} ${styles.metaDataText}`}>
      {title}
    </Text>
    <Text color={Text.COLOR.GREY1} className={styles.metaDataText}>
      {subTitle}
    </Text>
  </Row>
);

AdMetaData.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
};

const AdPlacementPreview = (props) => {
  const { selectedPlacementPreview, showAvailablePlacements, extraAdPreviewData } = props;
  const {
    name,
    description,
    placement_type,
    url,
    created,
    updated,
    campaigns,
    creative,
    icon,
    title,
    body_text,
    call_to_action,
    sponsor,
    ad_size,
  } = selectedPlacementPreview;

  const adPreviewProps = {
    placementType: placement_type,
    bodyText: body_text,
    callToAction: call_to_action,
    clickUrl: url,
    htmlDimension: ad_size,
    creative,
    icon,
    sponsor,
    title,
    showAvailablePlacements,
  };

  const NA = '-';
  const stopValues = [undefined, null, NA, ''];
  const toDisplay = toDisplayfp(stopValues, NA);
  const toDisplayForCampaign = toDisplayfp(stopValues, 'None');

  const [placementNameDisplay, descriptionDisplay] = toDisplay()(name, description);
  const placementTypeDisplay = toDisplay((value) => `${capitalize(value)} Ad`)(placement_type);
  const urlDisplay = url ? (
    <Link
      newWindow
      link={wrapUrlWith6SiPreviewParam(url)}
      id={'link_timeline_url'}
      className={styles.urlItem}
    >
      {truncateString(url, 80)}
    </Link>
  ) : (
    '-'
  );
  const [createdDateDisplay, updatedDateDisplay] = toDisplay(formatDate(DATE_FORMAT))(
    created,
    updated
  );
  const uniqCampaignNames = uniqBy(campaigns, 'campaign_name');
  const campaignsDisplay = toDisplayForCampaign((value) => {
    if (value.length) {
      return map(value, ({ campaign_name, id }) => (
        <Row key={id}>
          <Link link={`/campaigns/${VIEW_CAMPAIGN_DETAILS_ROUTE}/${id}/`} newWindow isInternal>
            {campaign_name}
          </Link>
        </Row>
      ));
    }
    return undefined;
  })(uniqCampaignNames);

  return (
    <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
      {isEmpty(selectedPlacementPreview) ? (
        <Row>
          <Text className={`${styles.modalTitle} ${styles.modalTitleMain}`}>
            Select an Ad from the list to preview.
          </Text>
        </Row>
      ) : (
        <Fragment>
          <Row>
            <Text className={`${styles.modalTitle} ${styles.modalTitleMain}`}>
              {
                placement_type === 'html5' || placement_type === 'html5_dynamic'
                  ? 'Preview not available' : 'Ad Preview'
              }
            </Text>
          </Row>
          <AdPreview {...adPreviewProps} extraAdPreviewData={extraAdPreviewData} />
          <AdMetaData key="1" title={'Ad Name'} subTitle={placementNameDisplay} />
          <AdMetaData key="2" title={'Description'} subTitle={descriptionDisplay} />
          <AdMetaData key="3" title={'Ad Type'} subTitle={placementTypeDisplay} />
          <AdMetaData key="4" title={'Click URL'} subTitle={urlDisplay} />
          <AdMetaData key="5" title={'Creation Date'} subTitle={createdDateDisplay} />
          <AdMetaData key="6" title={'Last Modified Date'} subTitle={updatedDateDisplay} />
          <AdMetaData key="7" title={'Associated Campaigns'} subTitle={campaignsDisplay} />
        </Fragment>
      )}
    </Row>
  );
};

AdPlacementPreview.propTypes = {
  selectedPlacementPreview: PropTypes.object,
  extraAdPreviewData: PropTypes.object,
  showAvailablePlacements: PropTypes.bool,
};

export default AdPlacementPreview;
