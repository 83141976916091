import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'old_apitokenmanagement',
    name: 'API Key',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const sagas = require('./sagas');
          const reducer = require('./modules');
          injectReducer('apikeysv2', reducer.default);
          injectSagas('setApiKeysv2', sagas.default);
          cb(null, containers.APIKeysContainer);
        },
        'settings_apikeysv2',
      );
    },
  };
}
