import React, { useRef } from 'react';
import { AAColor } from '@sixsense/core/style';
import { css } from 'styles/emotion';
import { Flex, Input } from '@sixsense/core/components';
import { Icon, Link, Tooltip2 } from 'v2-components';
import { DragSource, useDrop } from 'react-dnd';
import { SalesStage } from '../types';
import SalesStageCard from './SalesStage';
import useStageConfigState from '../useStageConfigState';
import useStageConfigPermissions from '../hooks/useStageConfigPermissions';

const styles ={
  container: css({
    position: 'relative',
    padding: '10px 15px',
    backgroundColor: '#EFF2F5',
    border: '1px solid #C6CACE',
    margin: '15px 0',
    borderRadius: 2,
    color: AAColor.GREY,
    '&:first-child': {
      paddingTop: 20,
    },
    '&:last-child': {
      paddingBottom: 20,
    },
  }),
  singleContainer: css({
    marginLeft: 15,
  }),
  dragIcon: css({
    height: 20,
    margin: '0 15px',
  }),
  moreIcon: css({
    marginLeft: 10,
    fontSize: 20,
    cursor: 'pointer',
    color: '#001F32',
  }),
  title: css({
    flex: 1,
    border: '1px solid #C6CACE',
    fontSize: 14,
    lineHeight: '18px',
    padding: '7px 15px',
    borderRadius: 2,
    fontWeight: 'normal',
    backgroundColor: '#fff',
    marginRight: 15,
  }),
  input: css({
    flex: 1,
    border: '1px solid #C6CACE',
    borderRadius: 2,
    fontWeight: 'normal',
    backgroundColor: '#fff',
    marginRight: 15,
  }),
  children: css({
    marginLeft: 55,
    marginTop: 13,
    borderRadius: 4,
    marginRight: 32,
  }),
};

type Props = {
  name: string;
  stages: SalesStage[];
  index: number;
  isDragging: boolean;
  connectDragSource: any;
  connectDragPreview: any;
  disabled?: boolean;
  displayGroup: boolean;
}

const StageGroup = ({
  name,
  stages,
  index,
  isDragging,
  connectDragSource,
  connectDragPreview,
  disabled,
  displayGroup,
}: Props) => {

  const {
    moveGroup,
    moveStage,
    ungroup,
    selectedStages,
    toggleStage,
    toggleVisibility,
    renameMap,
    setRenameMap,
  } = useStageConfigState();

  const { canEdit } = useStageConfigPermissions();
  const dragSourceRef = useRef(null);
  connectDragSource(dragSourceRef);

  const [, drop] = useDrop({
    accept: 'SALES_STAGE_GROUP',
    canDrop: () => false,
    hover: (item: any) => {
      if (!disabled && item.id !== name) {
        moveGroup(item.name, index);
      }
    },
  });

  const [{ isStageOver }, stageDrop] = useDrop({
    accept: 'SALES_STAGE',
    canDrop: () => canEdit && !disabled,
    drop: (item: any) => {
      moveStage(item.name, index);
    },
    collect: (monitor) => ({
      isStageOver: monitor.isOver(),
    }),
  });

  let opacity = 1;
  if (isDragging) {
    opacity = 0.1;
  }
  if (disabled) {
    opacity = 0.7;
  }

  if (stages.length === 1 && !displayGroup) {
    return (
      <Tooltip2
        placement="top"
        overlay={disabled ? '6sense activity stages cannot be reconfigured' : undefined}
      >
        <div
          ref={(canEdit && !disabled) ? (
            (ref) => connectDragPreview(drop(stageDrop(ref)))
          ) : undefined}
          className={styles.singleContainer}
          style={{
            opacity,
            pointerEvents: disabled ? 'none' : undefined,
          }}
        >
          <Flex alignItems="center">
            <div style={{ width: 15 }}>{index + 1}.</div>
            {canEdit ? (
              <div ref={dragSourceRef} className={styles.dragIcon}>
                <Icon
                  type="drag_indicator"
                  size={Icon.SIZE.LARGE}
                  pointer
                  color={disabled ? Icon.COLORS.WHITE : Icon.COLORS.GREY2}
                />
              </div>
            ) : (
              <div className={styles.dragIcon} />
            )}
            <div style={{ flex: 1 }}>
              <SalesStageCard
                key={stages[0].name}
                {...stages[0]}
                viewOnly={!canEdit}
                checked={selectedStages.has(name)}
                onSelect={() => toggleStage(name)}
                toggleVisibility={disabled ? () => {} : () => toggleVisibility(index, 0)}
              />
            </div>
          </Flex>
        </div>
      </Tooltip2>
    );
  }

  return (
    <div
      ref={(canEdit && !disabled) ? (
        (ref) => connectDragPreview(drop(stageDrop(ref)))
      ) : undefined}
      className={styles.container}
      style={{
        opacity,
        pointerEvents: disabled ? 'none' : undefined,
      }}
    >
      <Flex alignItems="center">
        <div style={{ width: 15 }}>{index + 1}.</div>
        {canEdit ? (
          <div ref={dragSourceRef} className={styles.dragIcon}>
            <Icon type="drag_indicator" size={Icon.SIZE.LARGE} pointer color={Icon.COLORS.GREY2} />
          </div>
        ) : (
          <div className={styles.dragIcon} />
        )}
        <Input
          className={styles.input}
          value={renameMap[name] === undefined ? name : renameMap[index]}
          onChange={(value) => setRenameMap({ ...renameMap, [name]: value })}
        />
        {canEdit ? (
          <Link
            onClick={() => {
              ungroup(name);
              setRenameMap({ ...renameMap, [name]: undefined });
            }}
          >
            <b>Ungroup</b>
          </Link>
        ) : (
          <div style={{ width: 50 }} />
        )}
      </Flex>
      <div
        className={styles.children}
        style={{
          border: '2px solid transparent',
          borderColor: isStageOver ? AAColor.BLUE : 'transparent',
        }}
      >
        {stages.map((stage, i) => (
          <SalesStageCard
            key={stage.name}
            {...stage}
            toggleVisibility={disabled ? () => {} : () => toggleVisibility(index, i)}
            checked
            viewOnly={!canEdit}
            grouped
          />
        ))}
      </div>
    </div>
  );
};

export default DragSource(
  'SALES_STAGE_GROUP',
  {
    beginDrag: (props: Props) => ({
      id: props.name,
      name: props.name,
      originalIndex: props.index,
    }),
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }),
)(StageGroup);
