import { createSelector } from "reselect";
import {
  isPredictiveSelector,
  orgSelector,
  predictiveProductsSelector,
} from "modules/user/selectors";
import { isEmpty } from "lodash";

const bulkUpdateEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `org/${orgId}/sixsense_campaign/bulk_update/`
);

export const productsSelector = createSelector(
  predictiveProductsSelector,
  (products = []) =>
    products
      .filter(({ name, display_name }) => name && display_name)
      .map(({ name, display_name }) => ({ label: display_name, value: name }))
);

export const hasPredictiveProductsSelector = createSelector(
  productsSelector,
  isPredictiveSelector,
  (products = [], hasPredictive) => hasPredictive && !isEmpty(products)
);

export const selectors = {
  bulkUpdateEndpointSelector,
  productsSelector,
  hasPredictiveProductsSelector,
};
