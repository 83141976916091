import moment from 'moment';
import { useEffect, useState } from 'react';
import { FiscalYearPreviewTableDataRowType } from './types';
import { SelectProps } from '@sixsense/core/components';
import { Permission, usePermissionCheck } from '@sixsense/rbac';
import { formatDateUTC } from './utils';
import {
  useFiscalYearSettingsQuery,
  useFiscalYearDetailSettingsQuery,
} from '@sixsense/core/organization';

export const useOptionsData = () => {
  const [radioValue, setRadioValue] = useState(undefined);
  const [selectValue, setSelectValue] = useState<SelectProps<String>>(null);
  const {
    data: fiscalYearSettingsData,
  } = useFiscalYearSettingsQuery();

  // Used to intialize state
  useEffect(() => {
    if (fiscalYearSettingsData?.fy_based_on) {
      setRadioValue(fiscalYearSettingsData?.fy_based_on);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiscalYearSettingsData?.fy_based_on]);

  useEffect(() => {
    if (fiscalYearSettingsData?.start_month) {
      setSelectValue({
        label: moment.months()[fiscalYearSettingsData?.start_month - 1],
        value: fiscalYearSettingsData?.start_month,
        selected: true,
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiscalYearSettingsData?.start_month]);

  return {
    radioValue,
    setRadioValue,
    selectValue,
    setSelectValue,
  };
};

export const usePreviewTableData = (
  radioValue: string,
  selectValue: SelectProps<string>,
) => {
  const {
    data: fiscalYearDetailData,
    isLoading: fiscalYearDetailLoading,
    isFetching: fiscalYearDetailFetching,
  } = useFiscalYearDetailSettingsQuery(
    radioValue,
    selectValue?.value,
  );

  const year = fiscalYearDetailData?.year;
  const startDates = fiscalYearDetailData?.fiscal_period_start_dates;
  const endDates = fiscalYearDetailData?.fiscal_period_end_dates;

  const tableData: FiscalYearPreviewTableDataRowType[] = [
    {
      id: 0,
      index: 0,
      period: `FY ${year}`,
      startDate: `${formatDateUTC(startDates?.[0])}`,
      endDate: `${formatDateUTC(endDates?.[endDates.length - 1])}`,
    },
  ];

  // Format table data
  // Months are 1-indexed based on database.
  // eslint-disable-next-line no-unused-expressions
  startDates?.forEach((startDate, quarter) => {
    tableData.push({
      id: quarter + 1,
      index: quarter + 1,
      period: `Q${quarter + 1}`,
      startDate: `${formatDateUTC(startDate)}`,
      endDate: `${formatDateUTC(endDates?.[quarter])}`,
    });
  });

  return {
    tableData,
    fiscalYearDetailLoading,
    fiscalYearDetailFetching,
  };
};

export const useSettingsFiscalYearViewPermissions = () =>
  usePermissionCheck([Permission.SETTINGS_FISCAL_YEAR_VIEW]);
