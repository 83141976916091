import React from "react";
import { Text } from "@sixsense/core/components";
import { localeString } from "utils/utils";
import { NA } from "./common/NA";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { isDraftOrScheduledCampaign } from "../../../utils";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const ACCOUNTS_REACHED = {
  sortByKey: "accounts_reached",
  customizable: true,
  key: "accounts-reached",
  csvColumnKey: "account_reached",
  defaultSelected: true,
  title: <Label name={CAMPAIGN_LABELS.accountsReached} />,
  dataIndex: "campaign_data.accounts_reached",
  width: pxToRem(160),
  render: (accountsReached, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (isDraftOrScheduledCampaign(campaignStatus)) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {localeString(accountsReached)}
      </Text.Body>
    );
  },
  align: "right",
};
