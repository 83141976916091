import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/core.scss';
import { connect } from 'react-redux';
import Account from './Account';
import Settings from './Settings';
import Search from './Search';
import { orgObjSelector } from 'modules/user/selectors';
import TopBar from '@sixsense/core/components/skeletal/TopBar';
import TopNavPathTitle from './TopNavPathTitle';
import { HelpCircle } from '@sixsense/core/icons';
import { useFeatureFlagsCheck } from '@sixsense/core/featureFlags';
import { FEATURE_FLAGS } from 'constants/featureFlags';

function TopNavComponent(props) {
  const { currentPath, isSandbox } = props;

  const shouldCommandbarDisplay = useFeatureFlagsCheck(FEATURE_FLAGS.shouldCommandbarDisplay);

  return (
    <TopBar
      currentApp={'ABM'}
      title={
        <TopNavPathTitle currentPath={currentPath} isSandbox={isSandbox} />
      }
    >
      <Search />
      {shouldCommandbarDisplay && (
        <TopBar.IconButton
          icon={HelpCircle}
          onClick={() => window.CommandBar.toggleHelpHub()}
          data-6si-name="top-bar--icon-button"
          data-6si-id="abm_help_icon"
        />
      )}
      <Settings />
      <TopBar.AppSwitcher />
      <Account />
    </TopBar>
  );
}

TopNavComponent.propTypes = {
  currentPath: PropTypes.string,
  isSandbox: PropTypes.bool,
};

TopNavComponent.defaultProps = {};

const mapStateToProps = (state) => ({
  isSandbox: orgObjSelector(state)?.is_sandbox,
});

export default connect(mapStateToProps, {})(TopNavComponent);
