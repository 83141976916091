export const TECHNOLOGIES_CONFIRM_TEXT = `Are you sure you want to
 remove the selected technologies from your list?`;
export const TECHNOLOGY_CONFIRM_TEXT = `Are you sure you want to
 remove this technology from your list?`;
export const ADD_TECHNOLOGIES_SUCCESS_MSG = 'Successfully added technology';
export const DELETE_TECHNOLOGIES_SUCCESS_MSG = 'Successfully removed technologies';
export const ORDER_TECHNOLOGIES_SUCCESS_MSG = 'Successfully changed technologies order';
export const TECHNOLOGIES_ERROR_MSG = `There was an error 
. Please try again later`;
export const TECHNOLOGIES_DISABLED_TOOTLTIP = 'Select a Product to Add Technologies';
