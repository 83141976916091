import React from "react";
import { Text } from "@sixsense/core/components";
import { toFixedfp } from "utils/utils";
import { isDraftOrScheduledCampaign } from "../../../utils";
import { NA } from "./common/NA";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const AVG_INCREASE_IN_ACCOUNT_ENGAGEMENT = {
  sortByKey: "avg_increase_engagement",
  key: "avg-increase-engagement",
  csvColumnKey: "avg_increase_in_account_engagement",
  title: <Label name={CAMPAIGN_LABELS.avgIncreaseInAccountEngagement} />,
  dataIndex: "campaign_data.avg_increase_engagement",
  width: pxToRem(250),
  render: (avgIncreaseEngagement, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (isDraftOrScheduledCampaign(campaignStatus)) {
      return <NA />;
    }

    const timesIncreased = 1 + avgIncreaseEngagement / 100;
    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {toFixedfp(2)(timesIncreased)}x
      </Text.Body>
    );
  },
  align: "right",
};
