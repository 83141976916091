import { getAsyncInjectors } from 'store/utils';
import SetMAP from './routes/map';
import Slack from './routes/slack';
import SalesEnablement from './routes/SalesEnablement';
import Manage from './routes/Manage';
import SetCV2 from './routes/cv2';
import SetCRMSandbox from './routes/crm_sandbox';
// import EmailServer from './routes/EmailServer';
import EmailServerV2 from './routes/EmailServerV2';
import CRMV3 from './routes/crm';
import TrustRadius from './routes/TrustRadius';
import TagSetup from './routes/TagSetup';
import G2 from './routes/G2';
import BomboraConfig from './routes/BomboraConfig';
import CRMSandboxV2 from './routes/crm_sandbox_v2';
import Saleswhale from './routes/Saleswhale';
import SocialIntegration from './routes/SocialAdsIntegration';
import Webtag from './routes/Webtag';
import Snowflake from './routes/Snowflake';
import S3 from './routes/S3';
import LinkedInAdvertising from './routes/LinkedinAdvertising';
import SalesforceSep from './routes/SalesforceSep';
import MAPV2 from './routes/mapv2';
import { INTEGRATIONS_ROUTE } from 'utils/constants';
import Peerspot from './routes/Peerspot';
import Gartner from './routes/Gartner';
import BomboraV2 from './routes/Bombora_v2';
import { last } from 'lodash';
import ApiKeysv2 from './routes/ApiKeysv2';
import APITokenManagement from './routes/APITokenManagement';
import Gong from './routes/Gong';
import TechTarget from './routes/TechTarget';
import SourceForge from './routes/SourceForge';


export default function createRoutes(store) {
  const { injectReducer } = getAsyncInjectors(store);
  return {
    path: last(INTEGRATIONS_ROUTE.split('/')),
    name: 'Platform Integration',
    indexRoute: {
      onEnter: (nextState, replace) => replace(`/${INTEGRATIONS_ROUTE}/manage`),
    },

    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const reducer = require('./modules');
        injectReducer('integrationsRoot', reducer.default);
        cb(null, containers.IntegrationContainer);
      }, 'settings_integration');
    },
    childRoutes: [
      Manage(store),
      SetMAP(store),
      Slack(),
      SalesEnablement(store),
      SetCV2(store),
      SetCRMSandbox(store),
      // EmailServer(store),
      EmailServerV2(store),
      CRMV3(store),
      SocialIntegration(store),
      G2(store),
      BomboraConfig(store),
      TrustRadius(store),
      TagSetup(store),
      G2(store),
      BomboraConfig(store),
      CRMSandboxV2(store),
      Saleswhale(store),
      Webtag(store),
      LinkedInAdvertising(store),
      Peerspot(store),
      Gartner(store),
      Snowflake(store),
      SalesforceSep(store),
      ApiKeysv2(store),
      S3(store),
      BomboraV2(store),
      MAPV2(store),
      APITokenManagement(store),
      Gong(store),
      TechTarget(store),
      SourceForge(store),
    ],
  };
}
