import { CAMPAIGN_CLASSIFICATION_ACTIONS } from "./constants";
import { get, lowerCase } from "lodash";
import { browserHistory } from "react-router";
import { CAMPAIGNS_ROUTE } from "routes/AdvertisingDS/routes/Campaigns/constants";
import { maybePlural } from "utils/utils";
import { CAMPAIGN_LIST_ROUTE } from "routes/AdvertisingDS/routes/Campaigns/routes/ListCampaigns/constants";
import { ADVERTISING_BASE_ROUTE } from "../../constants";

export const isNotCampaignListRoute = () => {
  const location = browserHistory.getCurrentLocation();
  return (
    get(location, "pathname") !==
    `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGN_LIST_ROUTE}`
  );
};

export const getSuccessMessageForAction = ({
  payload: {
    uiAction = lowerCase(CAMPAIGN_CLASSIFICATION_ACTIONS.move),
    folder,
    itemIds: campaign_ids,
  },
}) => {
  const entity = maybePlural(campaign_ids.length, "Campaign");
  const preposition =
    uiAction === lowerCase(CAMPAIGN_CLASSIFICATION_ACTIONS.move) ? "to" : "in";
  // Examples:
  // uiAction == move: 2 Campaigns moved successfully to "New Folder"
  // uiAction == restore: 2 Campaigns restored successfully in "New Folder"
  // uiAction == archive: 4 Campaigns archived successfully.
  const message = `${campaign_ids.length} ${entity} ${uiAction}d successfully`;
  return uiAction === lowerCase(CAMPAIGN_CLASSIFICATION_ACTIONS.archive)
    ? `${message}.`
    : `${message} ${preposition} "${folder.name}".`;
};
