export const TOGGLE_VISIBILITY =
  "CAMPAIGNS/NEW_CAMPAIGN_DOWNLOAD_DRAWER/TOGGLE_VISIBILITY";
export const toggleVisibility = (
  isVisible,
  campaignIds,
  additionalQueryParams,
  noOfCampaigns,
  dateRange
) => ({
  type: TOGGLE_VISIBILITY,
  isVisible,
  campaignIds,
  additionalQueryParams,
  noOfCampaigns,
  dateRange,
});

export const SUBMIT_DOWNLOAD_FORM =
  "CAMPAIGNS/NEW_CAMPAIGN_DOWNLOAD_DRAWER/SUBMIT";
export const submitDownloadForm = (payload) => ({
  type: SUBMIT_DOWNLOAD_FORM,
  payload,
});

export const EXPORT_SUCCESS =
  "CAMPAIGNS/NEW_CAMPAIGN_DOWNLOAD_DRAWER/EXPORT_SUCCESS";
export const exportSuccess = () => ({
  type: EXPORT_SUCCESS,
});

export const EXPORT_FAILED =
  "CAMPAIGNS/NEW_CAMPAIGN_DOWNLOAD_DRAWER/EXPORT_FAILED";
export const exportFailed = (error) => ({
  type: EXPORT_FAILED,
  error,
});

export const CANCEL_EXPORT =
  "CAMPAIGNS/NEW_CAMPAIGN_DOWNLOAD_DRAWER/CANCEL_EXPORT";
export const cancelExport = () => ({
  type: CANCEL_EXPORT,
});

export const TOGGLE_MINIMISED =
  "CAMPAIGNS/NEW_CAMPAIGN_DOWNLOAD_DRAWER/TOGGLE_MINIMISED";
export const toggleMinimised = (payload) => ({
  type: TOGGLE_MINIMISED,
  payload,
});

export const TOGGLE_ASK_FOR_EMAIL =
  "CAMPAIGNS/NEW_CAMPAIGN_DOWNLOAD_DRAWER/TOGGLE_ASK_FOR_EMAIL";
export const toggleAskForEmail = (payload) => ({
  type: TOGGLE_ASK_FOR_EMAIL,
  payload,
});

export const UPDATE_FORM_STATE =
  "CAMPAIGNS/NEW_CAMPAIGN_DOWNLOAD_DRAWER/UPDATE_FORM_STATE";
export const updateFormState = (payload) => ({
  type: UPDATE_FORM_STATE,
  payload,
});
