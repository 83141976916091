import { createSelector } from "reselect";
import { get, invert, isEmpty, pick, filter } from "lodash";
import {
  formValueSelector,
  getFormSyncErrors,
  getFormValues as getFormValuesrf,
} from "redux-form";
import {
  orgFlagsSelector,
  orgObjSelector,
  orgReadySelector,
  orgSelector,
} from "modules/user/selectors";
import {
  DOWNLOAD_ACCOUNTS_MAX,
  SPONSORED_CONTENT_POSTER_STATUS,
  strings,
} from "utils/constants";
import { coerceLocaleString } from "utils/utils";
import { sumKeysInObject } from "./utils";
import { CAMPAIGN_DOWNLOAD_FORM, CAMPAIGN_SOURCE } from "./constants";
import { campaignLabelsStateGenerator } from "./stateGenerators";
import { PROMISE_STATES } from "modules/global/constants";
import { FEATURE_FLAGS } from "constants/featureFlags";
import { isFeatureFlagEnabledForOrg } from "routes/AdvertisingDS/featureGating/utils";
import { linkedinAdsPagesDucks } from "routes/AdvertisingDS/ducks";
import { Permission } from "@sixsense/rbac";

const {
  stateGenerators: { linkedinIntegratedPagesStateGenerator },
} = linkedinAdsPagesDucks;

export const routeStateSelector = (state) => state.campaigns;

const campaignLoadingSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.loadCampaigns.loading
);
const campaignErrorSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.loadCampaigns.error
);

export const loadedSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.loadCampaigns.loaded
);

// if orgReady is false then loading is false
export const campaignsRouteLoadingSelector = createSelector(
  campaignLoadingSelector,
  orgReadySelector,
  campaignLabelsStateGenerator.promiseStateSelector,
  (campaignsLoading, orgReady, campaignLabelsPromiseState) => {
    const campaignLabelsLoading =
      campaignLabelsPromiseState === PROMISE_STATES.PENDING;

    return orgReady === false
      ? false
      : campaignsLoading || campaignLabelsLoading;
  }
);

export const errorSelector = createSelector(
  campaignErrorSelector,
  (campaignsError) => campaignsError
);

export const campaignOptionsSelector = createSelector(
  routeStateSelector,
  (routeState) => ({
    ...get(routeState, "campaignOptions.options", {}),
  })
);

export const endpointObjSelector = createSelector(orgSelector, (orgId) => {
  const endpointObj = {
    [CAMPAIGN_SOURCE.INTERNAL]: `org/${orgId}/sixsense_campaign/`,
    [CAMPAIGN_SOURCE.CONTEXTUAL]: `org/${orgId}/sixsense_contextual_campaign/`,
    [CAMPAIGN_SOURCE.RETARGETING]: `org/${orgId}/sixsense_retargeting_campaign/`,
    [CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING]: `org/${orgId}/linkedin_ads_campaign/`,
  };
  return endpointObj;
});

const deactivateCampaignSelector = createSelector(
  routeStateSelector,
  ({ deactivateCampaign }) => deactivateCampaign
);

const cloneCampaignSelector = createSelector(
  routeStateSelector,
  ({ cloneCampaign }) => cloneCampaign
);
export const deactivateModalVisibleSelector = createSelector(
  deactivateCampaignSelector,
  (deactiveateState) => deactiveateState && deactiveateState.visible
);
export const deactivateModalLoadingStateSelector = createSelector(
  deactivateCampaignSelector,
  (deactiveateState) =>
    pick(deactiveateState, ["loading", "error", "errorMessage"])
);

export const selectedCampaignNameSelector = createSelector(
  deactivateCampaignSelector,
  (deactiveateState) => deactiveateState && deactiveateState.campaignName
);

export const cloneModalVisibleSelector = createSelector(
  cloneCampaignSelector,
  (cloneState) => cloneState && cloneState.visible
);
export const cloneModalLoadingStateSelector = createSelector(
  cloneCampaignSelector,
  (cloneState) =>
    pick(cloneState, ["loading", "error", "errorMessage", "folderList"])
);

export const campaignToCloneSelector = createSelector(
  cloneCampaignSelector,
  ({ campaign }) => campaign
);

export const campaignToDeactivateSelector = createSelector(
  deactivateCampaignSelector,
  ({ campaign }) => campaign
);

// alert errors
export const alertPropsSelector = createSelector(
  routeStateSelector,
  (routeState) => ({
    alert: routeState.campaignStateAction.error,
    alertMessage: strings.LIST_CAMPAIGN_ACTION_MESSAGE_FAILURE,
    closeable: true,
  })
);

// transaction Id
export const transactionIdSelector = createSelector(
  routeStateSelector,
  ({ transaction }) => transaction.transactionId
);

// download campaign
export const downloadCampaignSelector = createSelector(
  routeStateSelector,
  ({ download }) => download
);

export const downloadFormValueSelector = formValueSelector(
  CAMPAIGN_DOWNLOAD_FORM
);

export const updatedCampaignAnalyticsDataSelector = (
  campaignAnalyticsData,
  placementAnalyticsData,
  campaign
) =>
  campaignAnalyticsData.map((campAnalytic) => {
    let totalOfAllPlacementData = {};
    const updatedTotalOfAllPlacementData = {};
    const campaignPlacementData = placementAnalyticsData
      .filter(({ campaign_id }) => campAnalytic.campaignId === campaign_id)
      .map((result) => {
        const viewTrough = parseInt(result.view_through);
        let videoStats = {};
        if (campaign.campaign_subtype === "video") {
          videoStats = {
            videoRequest: parseInt(result.video_service_count),
            videoError: parseInt(result.video_error_count),
            videoStart: parseInt(result.video_start_count),
            videoSkip: parseInt(result.video_skip_count),
            video25: parseInt(result.video_first_quartile_count),
            video50: parseInt(result.video_half_point_count),
            video75: parseInt(result.video_third_quartile_count),
            video100: parseInt(result.video_completion_count),
          };
          return { viewTrough, ...videoStats };
        }
        return { viewTrough };
      });
    if (!isEmpty(campaignPlacementData)) {
      totalOfAllPlacementData = sumKeysInObject(campaignPlacementData);
      Object.keys(totalOfAllPlacementData).forEach((key) => {
        if (
          key !== "viewTrough" &&
          totalOfAllPlacementData[key] !== undefined
        ) {
          updatedTotalOfAllPlacementData[key] = `"${coerceLocaleString(
            totalOfAllPlacementData[key]
          )}"`;
        }
      });
    }
    return {
      ...campAnalytic,
      ...updatedTotalOfAllPlacementData,
    };
  });

export const updatedPlacementsGroupAnalyticsDataSelector = (
  placementGroupAnalyticsData,
  placementAnalyticsData,
  campaign
) =>
  placementGroupAnalyticsData.map((groupAnalytic) => {
    let totalOfAllPlacementsGroupData = {};
    const updatedTotalOfAllPlacementsGroupData = {};
    const placementsGroupAllData = placementAnalyticsData
      .filter(
        ({ placement_group_id }) =>
          groupAnalytic.placement_group_id === placement_group_id &&
          campaign.campaign_subtype === "video"
      )
      .map((result) => {
        let videoStats = {};
        videoStats = {
          videoRequest: parseInt(result.video_service_count),
          videoError: parseInt(result.video_error_count),
          videoStart: parseInt(result.video_start_count),
          videoSkip: parseInt(result.video_skip_count),
          video25: parseInt(result.video_first_quartile_count),
          video50: parseInt(result.video_half_point_count),
          video75: parseInt(result.video_third_quartile_count),
          video100: parseInt(result.video_completion_count),
        };
        return { ...videoStats };
      });

    if (!isEmpty(placementsGroupAllData)) {
      totalOfAllPlacementsGroupData = sumKeysInObject(placementsGroupAllData);

      Object.keys(totalOfAllPlacementsGroupData).forEach((key) => {
        if (
          key !== "viewTrough" &&
          totalOfAllPlacementsGroupData[key] !== undefined
        ) {
          updatedTotalOfAllPlacementsGroupData[key] = `"${coerceLocaleString(
            totalOfAllPlacementsGroupData[key]
          )}"`;
        }
      });
    }
    return {
      ...groupAnalytic,
      ...updatedTotalOfAllPlacementsGroupData,
    };
  });

export const downloadCampaignsAccountEndpoint = (
  extraFields,
  campaignSource,
  campaignId
) =>
  createSelector(orgSelector, (org) => {
    if (!campaignId) {
      return (
        `query/${org}/${campaignSource}_campaign/analytics/?limit=${DOWNLOAD_ACCOUNTS_MAX}` +
        `&offset=0${extraFields ? `&extra_fields=${extraFields}` : ""}`
      );
    }
    return (
      `query/${org}/campaign/${campaignId}/analytics/?limit=${DOWNLOAD_ACCOUNTS_MAX}` +
      `&offset=0${extraFields ? `&extra_fields=${extraFields}` : ""}`
    );
  });

export const campaignOrgSelector = createSelector(
  routeStateSelector,
  (routeState) => get(routeState, "campaignsOrg", {})
);

export const campaignFormFillsSelector = createSelector(
  routeStateSelector,
  (routeState) => get(routeState, "formFills", {})
);

export const getInfluencedConversionsFormsModalVisibility = createSelector(
  campaignFormFillsSelector,
  (formFills) => get(formFills, "visible", false)
);

export const getInfluencedConversionsForms = createSelector(
  campaignFormFillsSelector,
  (formFills) => formFills
);

export const getInfluencedConversionsFormsListCurrentPage = createSelector(
  campaignFormFillsSelector,
  (formFills) => get(formFills, "currentPage", 1)
);

export const getInfluencedConversionsFormsListRecordsPerPage = createSelector(
  campaignFormFillsSelector,
  (formFills) => get(formFills, "recordsPerPage", 10)
);

export const getInfluencedConversionsFormsFillsCampaignId = createSelector(
  campaignFormFillsSelector,
  (formFills) => get(formFills, "campaignId", null)
);

export const getInfluencedConversionsFormsFillsMid = createSelector(
  campaignFormFillsSelector,
  (formFills) => get(formFills, "mid", null)
);

export const getSelectedFilterDates = createSelector(
  campaignFormFillsSelector,
  (formFills) => get(formFills, "dateFilter", null)
);

export const getInfluencedConversionFormsFillsCampaignOrAccountName =
  createSelector(campaignFormFillsSelector, (formFills) =>
    get(formFills, "campaignOrAccountName", "")
  );

export const getInfluencedConversionFormsFillsDownloadingStatus =
  createSelector(campaignFormFillsSelector, (formFills) =>
    get(formFills, "downloading", false)
  );

export const getInfluencedConversionsFormsSearch = createSelector(
  campaignFormFillsSelector,
  (formFills) => get(formFills, "searchBy", "")
);

export const getInfluencedConversionsFormsSort = createSelector(
  campaignFormFillsSelector,
  (formFills) => ({
    sortBy: get(formFills, "sortBy", ""),
    sortingOrder: get(formFills, "sortingOrder", ""),
  })
);

export const campaignSourceSelector = createSelector(
  (state) =>
    get(
      CAMPAIGN_SOURCE,
      get(
        invert(CAMPAIGN_SOURCE),
        get(state, "route.locationBeforeTransitions.query.campaign_source")
      ),
      CAMPAIGN_SOURCE.INTERNAL
    ),
  (campaignSource) => campaignSource
);

export const getFormValues = (formName) =>
  createSelector(getFormValuesrf(formName), (formValues) =>
    JSON.parse(JSON.stringify({ ...formValues }).replace(/"\s+|\s+"/g, '"'))
  );

export const linkedinIntegratedPagesSelector = createSelector(
  orgFlagsSelector,
  linkedinIntegratedPagesStateGenerator.dataSelector,
  (orgFeatureFlags, linkedinIntegratedPages) => {
    const hasLinkedInAdvertising = isFeatureFlagEnabledForOrg({
      orgFeatureFlags,
      featureIdentifier: FEATURE_FLAGS.hasLinkedinAdvertising,
    });

    return hasLinkedInAdvertising ? linkedinIntegratedPages : [];
  }
);

export const linkedInPagesAccessRevokedSelector = createSelector(
  linkedinIntegratedPagesSelector,
  (linkedInPages) =>
    filter(
      linkedInPages.map((page) =>
        page.sponsored_content_poster_status ===
        SPONSORED_CONTENT_POSTER_STATUS.REVOKED
          ? page.id
          : null
      )
    )
);

export const linkedInPagesAccessRejectedSelector = createSelector(
  linkedinIntegratedPagesSelector,
  (linkedInPages) =>
    filter(
      linkedInPages.map((page) =>
        page.sponsored_content_poster_status ===
        SPONSORED_CONTENT_POSTER_STATUS.REJECTED
          ? page.id
          : null
      )
    )
);

export const linkedInDisabledPagesSelector = createSelector(
  linkedinIntegratedPagesSelector,
  (linkedInPages) =>
    filter(linkedInPages.map((page) => (page.is_disabled ? page.id : null)))
);

export const downloadFormErrorSelector = getFormSyncErrors(
  CAMPAIGN_DOWNLOAD_FORM
);

export const usersEndpointSelector = createSelector(
  orgObjSelector,
  ({ org_auth_id }) =>
    `auth/v3/organizations/${org_auth_id}/users/?limit=3000&permission_filters=${Permission.CAMPAIGN_VIEW}&is_active=True`
);

export const campaignOptionsEndpointSelector = createSelector(
  orgSelector,
  campaignSourceSelector,
  (orgId, campaignSource) => {
    const endpointObj = {
      [CAMPAIGN_SOURCE.INTERNAL]: `org/${orgId}/sixsense_campaign/`,
      [CAMPAIGN_SOURCE.EXTERNAL]: `org/${orgId}/external_campaign/`,
      [CAMPAIGN_SOURCE.CONTEXTUAL]: `org/${orgId}/sixsense_contextual_campaign/`,
      [CAMPAIGN_SOURCE.RETARGETING]: `org/${orgId}/sixsense_retargeting_campaign/`,
      [CAMPAIGN_SOURCE.LINKEDIN]: `org/${orgId}/linkedin_campaign`,
      [CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING]: `org/${orgId}/linkedin_ads_campaign/`,
    };
    return endpointObj[campaignSource];
  }
);

export const contextualTopicsEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `org/${orgId}/contextual_topics/?limit=1000`
);

export const purchaseOrdersEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `org/${orgId}/purchase_orders/`
);
