export const MAP = 'showMAP';
export const CRM = 'toCRM';
export const MARKETO = 'Marketo';
export const ELOQUA = 'Eloqua';
export const PARDOT = 'Pardot';
export const HUBSPOT = 'Hubspot';
export const SALESFORCE = 'Salesforce';
export const MARKETO_FORM = 'marketo_credentials_form';
export const ELOQUA_FORM = 'eloqua_credentials_form';
export const PARDOT_FORM = 'pardot_credentials_form';
export const HUBSPOT_FORM = 'hubspot_credentials_form';

export const KEY_TO_PATH = { // todo: rename this
  Marketo: 'marketocredential',
  Eloqua: 'eloquacredential',
  Pardot: 'pardotcredential',
  Hubspot: 'hubspotcredential',
};

export const KEY_TO_TYPE = {
  Marketo: 1,
  Salesforce: 2,
  Eloqua: 3,
  Pardot: 8,
  Hubspot: 9,
};
export const TYPE_ID_TO_NAME = {
  1: 'Marketo',
  2: 'Salesforce', // this is crm, but these should be moved to integrations const, not just map/crm
  3: 'Eloqua',
  8: 'Pardot',
  9: 'Hubspot',
};

export const KEY_TO_FIELDS = {
  Marketo: [
    'client_id',
    'client_secret',
    'identity_end_point',
    'rest_end_point',
  ],
  Eloqua: ['username', 'password', 'company_name', 'password_policy'],
  Pardot: ['username', 'password', 'userkey'],
  Hubspot: ['username', 'password', 'apikey'],
};

export const KEY_TO_OAUTH_FIELDS = {
  Pardot: ['username', 'unique_platform_id', 'pardot_api_version',
    'is_configured', 'is_valid', 'authorization_status', 'sync_source', 'platform_username'],
  Hubspot: ['username', 'is_configured', 'is_valid', 'authorization_status',
    'sync_source', 'platform_username'],
};

export const MARKETO_FIELDS = [
  'client_id',
  'client_secret',
  'identity_end_point',
  'rest_end_point',
];
export const ELOQUA_FIELDS = ['username', 'password', 'company_name', 'password_policy'];
export const PARDOT_FIELDS = ['username', 'password', 'userkey'];
export const HUBSPOT_FIELDS = ['username', 'password', 'apikey'];
export const EMPTY_MARKETO_FORM = {
  client_id: '',
  client_secret: '',
  identity_end_point: '',
  rest_end_point: ' ',
};
export const EMPTY_ELOQUA_FORM = {
  username: '',
  password: '',
  company_name: '',
};
export const EMPTY_PARDOT_FORM = {
  username: '',
  password: '',
  userkey: '',
  unique_platform_id: '',
  pardot_api_version: 4,
};
export const EMPTY_HUBSPOT_FORM = {
  username: '',
  password: '',
  apikey: '',
};

export const MAP_TO_FORMNAME = {
  [MARKETO]: MARKETO_FORM,
  [ELOQUA]: ELOQUA_FORM,
  [PARDOT]: PARDOT_FORM,
  [HUBSPOT]: HUBSPOT_FORM,
};

export const DEFAULT_PW = '************';
export const HIDDEN_CREDENTIALS = {
  [MARKETO]: (x) => ({ client_secret: x ? '' : DEFAULT_PW }),
  [ELOQUA]: (x) => ({ password: x ? '' : DEFAULT_PW }),
  [PARDOT]: (x) => ({ password: x ? '' : DEFAULT_PW }),
  [HUBSPOT]: (x) => ({ password: x ? '' : DEFAULT_PW }),
};

// statuses
export const SETUP = 'setup';
export const ERROR = 'error';
export const ACTIVE = 'active';
export const VERIFIED = 'verified';
export const PROCESS = 'process';
export const NOT_SUPPORTED = 'not_supported';
export const UNKNOWN = 'unknown';
export const OAUTH_ERROR = 'oauth_error';
export const OAUTH_SETUP = 'oauth_setup';


export const NON_PASSWORD_FIELDS = {
  [MARKETO]: ['Marketo_rest_limit', 'client_secret'],
  [SALESFORCE]: ['tbd'],
  [ELOQUA]: ['password', 'Eloqua_bulk_limit'],
  [PARDOT]: ['password', 'Pardot_rest_limit'],
  [HUBSPOT]: ['password', 'Hubspot_rest_limit'],
};

export const REQUIRED_FIELDS = {
  [MARKETO]: ['client_secret'],
  [SALESFORCE]: ['tbd'],
  [ELOQUA]: ['password', 'password_policy'],
  [PARDOT]: ['password'],
  [HUBSPOT]: ['password'],
};


export const CREDENTIAL_FIELDS = {
  [MARKETO]: [
    'client_id',
    'client_secret',
    'identity_end_point',
    'rest_end_point',
  ],
  [SALESFORCE]: ['tbd'],
  [ELOQUA]: ['username', 'company_name', 'password_policy'],
  [PARDOT]: ['username', 'userkey', 'pardot_api_version', 'is_configured'],
  [HUBSPOT]: ['username', 'apikey', 'is_configured'],
};

export const CREDENTIAL_ERR_MSG = {
  [MARKETO]: 'Client ID or Client Secret are',
  [SALESFORCE]: 'tbd',
  [ELOQUA]: 'Username or Company Name are',
  [PARDOT]: 'Username or User Key',
  [HUBSPOT]: 'Username or API Key',
};

export const OAUTH_INITIATED = 'INITIATED';
export const OAUTH_COMPLETED = 'COMPLETED';
export const COMPLETED_REFRESH ='COMPLETED_REFRESH';
export const FAILED_AUTH = 'FAILED_AUTH';
export const INVALID_VERSION = 'INVALID_VERSION';
export const RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED';
export const OAUTH_POLL_TIMEOUT = 180;
export const PARDOT_VERSIONS = [
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];

export const PARDOT_DEFAULT_API_VERSION = 4;
export const API_VERSION = 'api_version';

export const MIGRATION_FROM_BASIC_TO_OAUTH = 'BasicToOAuth';
