import DashboardRoute from './routes/Dashboard';
import AccountRoute from './routes/Account';
import KeywordRoute from './routes/Keyword';
import InboxRoute from './routes/Inbox';

export default function createRoutes(store) {
  return {
    path: 'discover',
    name: 'Discover',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/discover/dashboard'),
    },
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          cb(null, containers.Discover);
        },
        'discover'
      );
    },
    childRoutes: [
      DashboardRoute(store),
      AccountRoute(store),
      KeywordRoute(store),
      InboxRoute(store),
    ],
  };
}
