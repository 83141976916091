import { SYNC_STATES } from './constants';
import { mapValues } from 'lodash';

const initialState = {};

// Used by the user to control polling
const POLL_INIT = 'SAGA_POLLING/POLL_INIT';
const poll = (channel, guid, toPoll, onSuccess, onFailure, config = {}) => {
  const {
    pollFrequency = 10000, // 10 seconds
    isDone = (result) => result,
  } = config;
  return {
    type: POLL_INIT,
    channel,
    guid,
    toPoll,
    onSuccess,
    onFailure,
    config: {
      pollFrequency,
      isDone,
    },
  };
};

const CANCEL_POLL = 'SAGA_POLLING/CANCEL_POLL';
const cancelPoll = (channel, guid) => ({
  type: CANCEL_POLL,
  channel,
  guid,
});
// End user controls

// Only this module should be using this
const HEARTBEAT = 'SAGA_POLLING/HEARTBEAT';
const heartbeat = (channel, guid, data) => ({
  type: HEARTBEAT,
  channel,
  guid,
  data,
});

const POLL_START = 'SAGA_POLLING/POLL_START';
const pollStart = (channel, guid, task) => ({
  type: POLL_START,
  channel,
  guid,
  task,
});
// End module only actions

// reducer for just one channel
function channelReducer(state = initialState, action) {
  switch (action.type) {
    case POLL_INIT: {
      const { guid, toPoll, onSuccess, onFailure, config } = action;
      return {
        ...state,
        [guid]: {
          toPoll,
          onSuccess,
          onFailure,
          config,
          data: {
            sync_state: SYNC_STATES.IN_PROGRESS,
          },
        },
      };
    }
    case HEARTBEAT: {
      const { guid, data } = action;
      return {
        ...state,
        [guid]: {
          ...state[guid],
          data,
        },
      };
    }
    case POLL_START: {
      const { guid, task } = action;
      return {
        ...state,
        [guid]: {
          ...state[guid],
          task,
          started: new Date().valueOf(),
        },
      };
    }
    case CANCEL_POLL: {
      const { guid } = action;
      return mapValues(
        state,
        (pollState, guidKey) => {
          if (guid === undefined || guid === guidKey) {
            return {
              ...pollState,
              data: {
                sync_state: SYNC_STATES.CANCELED,
              },
            };
          }
          return pollState;
        }
      );
    }
    default:
      return state;
  }
}

function pollsReducer(state = initialState, action) {
  switch (action.type) {
    case HEARTBEAT:
    case POLL_START:
    case CANCEL_POLL:
    case POLL_INIT: {
      const { channel } = action;
      return {
        ...state,
        [channel]: channelReducer(state[channel], action),
      };
    }
    default: {
      return state;
    }
  }
}

export function createReducer() {
  return pollsReducer;
}

export const actions = {
  poll,
  cancelPoll,
  pollStart,
  heartbeat,
};

export const actionTypes = {
  POLL_INIT,
  CANCEL_POLL,
};
