export default function createRoutes() {
  return {
    path: 'segment/:id/:objectType',
    name: 'segment-edit',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const ObjectListRoute = require('./SegmentCreation');
        cb(null, ObjectListRoute.default);
      }, 'segment-edit');
    },
  };
}
