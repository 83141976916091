import ImageNode from './ImageNode';
import TopLevelNode from './TopLevelNode';
import MappingTypeNode, { MAPPING_SUB_TYPES as SUB_TYPES } from './MappingTypeNode';
import MappedParentNode from './MappedParentNode';
import MappedChildNode from './MappedChildNode';
import AnnotationNode from './AnnotationNode';

export const NODE_TYPES = {
  IMAGE_NODE: 'IMAGE_NODE',
  TOP_LEVEL_NODE: 'TOP_LEVEL_NODE',
  MAPPING_TYPE_NODE: 'MAPPING_TYPE_NODE',
  MAPPED_PARENT_NODE: 'MAPPED_PARENT_NODE',
  MAPPED_CHILD_NODE: 'MAPPED_CHILD_NODE',
  ANNOTATION_NODE: 'ANNOTATION_NODE',
};

export const nodeTypes = {
  [NODE_TYPES.IMAGE_NODE]: ImageNode,
  [NODE_TYPES.TOP_LEVEL_NODE]: TopLevelNode,
  [NODE_TYPES.MAPPING_TYPE_NODE]: MappingTypeNode,
  [NODE_TYPES.MAPPED_PARENT_NODE]: MappedParentNode,
  [NODE_TYPES.MAPPED_CHILD_NODE]: MappedChildNode,
  [NODE_TYPES.ANNOTATION_NODE]: AnnotationNode,

};

export const MAPPING_SUB_TYPES = SUB_TYPES;
