import { stateGenerators } from './stateGenerators';
import { combineReducers } from 'redux';
import { createReducerUtil } from 'utils/core';
import { SET_AD_LIBRARY_CLASSIFICATION_MODAL_VISIBILITY } from './types';

const {
  adLibraryClassifications,
  createAdsClassification,
  changeClassification,
  deleteAdsClassification,
  renameClassification,
} = stateGenerators;

const adLibraryClassificationModalInitialState = {
  visible: false,
  placementIds: [],
  uiAction: undefined,
};

const adLibraryClassificationModal = createReducerUtil(adLibraryClassificationModalInitialState, {
  [SET_AD_LIBRARY_CLASSIFICATION_MODAL_VISIBILITY]: (
    state, { visible, placementIds, uiAction }
  ) => {
    if (visible === false) {
      return adLibraryClassificationModalInitialState;
    }

    return ({
      ...state,
      visible,
      placementIds,
      uiAction,
    });
  },
});

export const reducers = combineReducers({
  [adLibraryClassifications.stateKey]: adLibraryClassifications.reducer,
  [createAdsClassification.stateKey]: createAdsClassification.reducer,
  adLibraryclassificationsModal: adLibraryClassificationModal,
  [changeClassification.stateKey]: changeClassification.reducer,
  [deleteAdsClassification.stateKey]: deleteAdsClassification.reducer,
  [renameClassification.stateKey]: renameClassification.reducer,
});
