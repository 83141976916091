import { difference, uniq } from 'lodash';

export const reArrangeFolders = (sequence, folderList) => {
  const pullMatchingType = (orderedList, classificationType) => {
    const allMatchingFolders = folderList
      .filter(({ classification_type }) => classification_type === classificationType);

    return [
      ...orderedList,
      ...allMatchingFolders,
    ];
  };

  return sequence.reduce(pullMatchingType, []);
};

export function getMissingFolderTypes(folderList, requiredTypes) {
  const folderTypes = uniq(folderList.map((folder) => folder.classification_type));
  return difference(requiredTypes, folderTypes);
}
