export default function createRoutes() {
  return {
    path: 'jobs',
    name: 'jobs',
    getComponent(nextState, cb) {
      require.ensure(
          [],
          (require) => {
            const container = require('./containers/JobsContainer');
            cb(null, container.JobsContainer);
          },
          'jobs',
        );
    },
  };
}
