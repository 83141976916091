import InfluencedConversionsRoute from './routes/InfluencedConversions';

export default function createRoutes(store) {
  return {
    path: 'campaign',
    name: 'Campaign',
    getComponent(_nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          cb(null, containers.CampaignContainer);
        },
        'reports_campaign'
      );
    },
    childRoutes: [
      InfluencedConversionsRoute(store),
    ],
  };
}
