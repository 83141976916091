import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'v2-components';
import styles from './DateFilter.module.scss';
import { classNames } from 'utils/utils';
import moment from 'moment';
import RangePicker from 'v2-components/RangePicker';
import Row from 'v2-components/Row';
import { DATE_FILTER_OPTIONS_VALUES, DEFAULT_OPTIONS } from './constants';
import { pxToRem } from '@sixsense/core/style';

const DateFilter = ({
  className,
  onChange,
  selectProps,
  disable,
  disabledDate,
  options,
  customDateRangeValue,
  value,
  dateRange,
}) => {
  const internalDateRangeValue = dateRange.length ?
    [moment(dateRange[0]), moment(dateRange[1])] : [];

  return (
    <Row className={classNames(className, styles.container)}>
      <Select
        icon={Select.DATE_ICON}
        disabled={disable}
        value={value}
        onChange={(v) => onChange(v)}
        options={options}
        width={pxToRem(140)}
        containerClassName={styles.deliveryTimeFrame}
        {...selectProps}
      />
      {value === customDateRangeValue &&
        (<RangePicker
          defaultPickerValue={[moment().subtract(30, 'day'), moment()]}
          value={internalDateRangeValue}
          disabled={disable}
          className={styles.rangePicker}
          onChange={(_, nextDateRange) => onChange(
            customDateRangeValue,
            nextDateRange[0],
            nextDateRange[1]
          )}
          disabledDate={disabledDate}
          hideInputOnPopup
        />)}
    </Row>
  );
};
DateFilter.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectProps: PropTypes.object,
  disable: PropTypes.bool,
  disabledDate: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.node,
  })),
  customDateRangeValue: PropTypes.any,
  dateRange: PropTypes.arrayOf(PropTypes.object),
};
DateFilter.defaultProps = {
  value: DEFAULT_OPTIONS[0].value,
  dateRange: [],
  options: DEFAULT_OPTIONS,
  customDateRangeValue: DATE_FILTER_OPTIONS_VALUES.dateRange,
  disabledDate: (date) => date.isSameOrAfter(moment(), 'day'),
};

export default DateFilter;
