import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import {
  DropdownSearch,
  Icon,
} from 'v2-components';
import '../../styles/core.scss';
import { connect } from 'react-redux';
import { actions } from '../Navbar/modules';
import {
  accountListSelector,
  displaySearchSelector,
  searchInputSelector,
  globalSearchLoadingSelector,
} from '../Navbar/selectors';
import styles from './Search.module.scss';
import { orgLoadingSelector, orgObjSelector } from 'modules/user/selectors';
import TopBar from '@sixsense/core/components/skeletal/TopBar';
import { SearchSm } from '@sixsense/core/icons';
import { classNames } from 'utils/utils';
import { DESIGN_SYSTEM_ESCAPE_CLASS } from '@sixsense/core/style';

function SearchComponent(props) {
  const {
    accounts,
    search,
    searchInput,
    setSearchInput,
    resetInput,
    showSearchBar,
    toggleSearchBar,
    selectAccount,
    loading,
  } = props;

  return (
    <React.Fragment>
      <div
        className={classNames(
          styles.searchBar,
          !showSearchBar ? styles.hidden : undefined,
          DESIGN_SYSTEM_ESCAPE_CLASS,
        )}
      >
        {showSearchBar ? (
          <DropdownSearch
            iconColor={Icon.COLORS.GREY}
            loaderColor={Icon.COLORS.GREY}
            id={'global_accounts_search'}
            handleSearchResultSelect={({ mid }) => {
              browserHistory.push(`/discover/account/detail/${mid}`);
              selectAccount(mid);
              setTimeout(() => resetInput(), 0);
            }}
            searchData={accounts}
            // Just give me everything
            filterOption={() => true}
            mapOption="name_country"
            handleInput={(userInput) => {
              setSearchInput(userInput);
              if (userInput.length && userInput.trim().length) {
                search();
              }
            }}
            value={searchInput}
            searchBar
            iconPlacement={'left'}
            inputClassName={styles.searchInput}
            onBlur={toggleSearchBar}
            loading={loading}
            dropdownClassName={styles.dropdown}
            placeholder={'Search Accounts...'}
            autoFocus
          />
        ) : null}
      </div>
      {showSearchBar ? null : (
        <TopBar.IconButton
          onClick={toggleSearchBar}
          icon={SearchSm}
          data-6si-id="abm_search_icon"
        />
      )}
    </React.Fragment>
  );
}

SearchComponent.propTypes = {
  accounts: PropTypes.array,
  search: PropTypes.func,
  searchInput: PropTypes.string,
  setSearchInput: PropTypes.func,
  resetInput: PropTypes.func,
  showSearchBar: PropTypes.bool,
  toggleSearchBar: PropTypes.func,
  selectAccount: PropTypes.func,
  loading: PropTypes.bool,
};

SearchComponent.defaultProps = {
  accounts: [],
  searchInput: '',
};

const mapStateToProps = (state) => ({
  accounts: accountListSelector(state),
  showSearchBar: displaySearchSelector(state),
  searchInput: searchInputSelector(state),
  loading: globalSearchLoadingSelector(state),
  orgLoading: orgLoadingSelector(state),
  isOrgDeleted: orgObjSelector(state)?.is_deleted,
  isSandbox: orgObjSelector(state)?.is_sandbox,
});

export default connect(mapStateToProps, actions)(SearchComponent);
