import React from 'react';
import PropTypes from 'prop-types';

import styles from './Pill.module.scss';
import { Tooltip } from 'v2-components';

function Pill(props) {
  const {
    className,
    children,
    inactive,
    selected,
    id,
    onClick,
    notClickable,
    tooltipTitle,
    tooltipTrigger,
    overlayClassName,
  } = props;

  let background = selected ? styles.selected : styles.active;
  background = inactive ? styles.inactive : background;

  let classText = `${styles.pill} ${background}`;
  classText = className ? `${classText} ${className}` : classText;
  // disable hover effect if notClickable
  classText = notClickable ? `${classText} ${styles.noClick}` : classText;

  return (
    <Tooltip overlayClassName={overlayClassName} title={tooltipTitle} trigger={tooltipTrigger}>
      <div
        className={classText} id={id} key={id} onClick={(e) => {
          e.stopPropagation();
          if (onClick) {
            onClick(id);
          }
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
}

Pill.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  inactive: PropTypes.bool,
  selected: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  notClickable: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  tooltipTrigger: PropTypes.string,
  overlayClassName: PropTypes.string,
};

Pill.defaultProps = {
  inactive: false,
  selected: false,
};

export default Pill;
