import { createSelector } from 'reselect';
import {
  getFormValues as getFormValuesrf, getFormSyncErrors,
} from 'redux-form';
import {
  DUMMY_AD_PLACEMENT_FORM,
} from './constants';

import {
  get,
  isEmpty,
  omit,
  reduce,
  some,
  uniqueId,
  map,
  find,
} from 'lodash';
import { orgSelector, userSelector } from 'modules/user/selectors';

export const routeStateSelector = (state) => state.adGroups;

export const getActiveAdGroupsSelector = createSelector(
  routeStateSelector,
  ({ placementGroups }) => placementGroups.filter((group) => !group.is_deleted)
);

export const initialPlacementGroupsSelector = createSelector(
  routeStateSelector,
  ({ initialPlacementGroups }) => initialPlacementGroups.filter((group) => !group.is_deleted)
);

export const placementTypeSelector = createSelector(
  routeStateSelector,
  ({ placementType }) => placementType
);


export const adSelectionModalStateSelector = createSelector(
  routeStateSelector,
  ({ adSelectionModalState }) => adSelectionModalState
);


export const adSelModalVisibleSelector = createSelector(
  adSelectionModalStateSelector,
  ({ modalVisible }) => modalVisible
);

export const saveModalStateSelector = createSelector(
  routeStateSelector,
  ({ saveModalState }) => saveModalState
);


export const getAdConfigNamesSelector = createSelector(
  routeStateSelector,
  ({ placementGroups }) =>
    placementGroups
      .filter(({ is_deleted }) => !is_deleted)
      .map(({ placement_group_name }) => placement_group_name)
);


export const adPlacementSelector = createSelector(
  routeStateSelector,
  ({ adPlacement }) => adPlacement
);


export const adPlacementListSelector = createSelector(
  adPlacementSelector,
  ({ adPlacementList }) => adPlacementList
);


export const totalPlacementsSelector = createSelector(
  adPlacementSelector,
  ({ totalPlacements }) => totalPlacements
);


export const placementSearchInputSelector = createSelector(
  adPlacementSelector,
  ({ searchInput }) => searchInput
);

export const placementLimitSelector = createSelector(
  adPlacementSelector,
  ({ limit }) => limit
);

export const placementOffsetSelector = createSelector(
  adPlacementSelector,
  ({ offset }) => offset
);


export const isLoadingSelector = createSelector(
  adPlacementSelector,
  ({ loading }) => loading
);


export const placementPreviewSelector = createSelector(
  adPlacementSelector,
  ({ selectedPlacement }) => selectedPlacement
);

export const showAllSelectedPlacementSelector = createSelector(
  adPlacementSelector,
  ({ showAllSelectedPlacement }) => showAllSelectedPlacement
);


export const selectedAdPlacementsListSelector = createSelector(
  adPlacementSelector,
  ({ selectedAdPlacementsList }) => selectedAdPlacementsList
);


export const orderBySelector = createSelector(
  adPlacementSelector,
  ({ orderBy }) => orderBy
);


export const orderingSelector = createSelector(
  adPlacementSelector,
  ({ ordering }) => ordering
);

export const placementEndpointSelector = createSelector(
  orgSelector,
  placementSearchInputSelector,
  placementLimitSelector,
  placementOffsetSelector,
  placementTypeSelector,
  orderBySelector,
  orderingSelector,
  (orgId, search, limit, offset, creativeType, orderBy, ordering) =>
    `org/${orgId}/${creativeType}_placement/?search=${search}&limit=${limit}&offset=${offset}` +
    `&ordering=${ordering === 'desc' ? '-' : ''}${orderBy}&is_deleted=False&show_in_library=True`
);

export const placementGroupsSelectors = createSelector(
  routeStateSelector,
  ({ placementGroups }) => placementGroups
);


export const placementGroupsRequestBodySelector = (isCreate, isExternalCampaign) => createSelector(
  placementGroupsSelectors,
  getActiveAdGroupsSelector,
  (placementGroups, activePlacementGroups) => {
    let reqBody = placementGroups;
    if (isCreate) {
      reqBody = activePlacementGroups;
    }
    return reqBody.map((group) => {
      const placementIds = group.placements.map(
        (placement) => (isExternalCampaign && placement.pid) || placement.id);
      return ({
        ...group,
        placements: placementIds,
      });
    });
  }
);
export const placementGroupErrorSelector = (isExternalCampaignSelector) => createSelector(
  getActiveAdGroupsSelector,
  isExternalCampaignSelector,
  (placementGroups, isExternalCampaign) => {
    const adsDeletedFromAdGroup = !isExternalCampaign && some(placementGroups, (group) =>
    isEmpty(group.placements.filter((pl) => pl.is_deleted === false)));
    return isEmpty(placementGroups) ||
      some(placementGroups, (group) => isEmpty(group.placements)) || adsDeletedFromAdGroup;
  }
);


// dummy ads
export const dummyAdsStateSelector = createSelector(
  routeStateSelector,
  ({ dummyAdsState }) => dummyAdsState
);
export const selectedAdCreateTypeSelector = createSelector(
  dummyAdsStateSelector,
  (routeState) => routeState.selectedAdCreateType
);

export const createdDummyAdsSelector = createSelector(
  getFormValuesrf(DUMMY_AD_PLACEMENT_FORM),
  (formValues) => get(formValues, 'dummy_ads', [])
);

export const uploadedAdsSelector = createSelector(
  dummyAdsStateSelector,
  (routeState) => routeState.uploadedAds
);

export const groupNameSelector = createSelector(
  dummyAdsStateSelector,
  (routeState) => routeState.groupNameToAdd
);

export const dummyAdsSelector = createSelector(
  selectedAdCreateTypeSelector,
  dummyAdsStateSelector,
  getFormValuesrf(DUMMY_AD_PLACEMENT_FORM),
  groupNameSelector,
  (createType, routeState, formValue, groupName) => {
    let dummyAds = [];
    if (createType === 'uploadAd') {
      dummyAds = routeState.uploadedAds;
    } else {
      dummyAds = get(formValue, 'dummy_ads', []);
    }
    return dummyAds.map((ad) => ({ id: uniqueId(), isNew: true, saved: false, groupName, ...ad }));
  }
);

export const modalVisibleSelector = createSelector(
  dummyAdsStateSelector,
  (routeState) => routeState.modalVisible
);

export const uploadLoadingSelector = createSelector(
  dummyAdsStateSelector,
  (routeState) => routeState.upload.loading
);

export const disabledAdButtonSelector = createSelector(
  getFormSyncErrors(DUMMY_AD_PLACEMENT_FORM),
  selectedAdCreateTypeSelector,
  uploadedAdsSelector,
  createdDummyAdsSelector,
  (syncErrors, createType, uploadedAds, createdDummyAds) => {
    if (createType === 'uploadAd') {
      return !(uploadedAds.length > 0);
    }
    if (createdDummyAds.length > 0) {
      const emptyAd = createdDummyAds.find(({ name, url }) => !name || !url);
      const hasSyncError = get(syncErrors, 'dummy_ads', []).find((error) => error);
      return emptyAd !== undefined || hasSyncError !== undefined;
    }
    return true;
  }
);

export const showConfirmPopupSelector = createSelector(
  selectedAdCreateTypeSelector,
  uploadedAdsSelector,
  createdDummyAdsSelector,
  (createType, uploadedAds, createdDummyAds) => (uploadedAds.length > 0 ||
    (createdDummyAds.length > 0 &&
      some(createdDummyAds, (ad) => !!ad.name || !!ad.url || !!ad.external_id)))
);
export const unsavedDummyAdsSelector = createSelector(
  getActiveAdGroupsSelector,
  (adGroups) =>
    reduce(
      adGroups,
      (unsavedAds, adGroup) => {
        const unsavedPlacements = adGroup.placements.filter((ad) => ad.saved === false);
        return unsavedAds.concat(unsavedPlacements);
      },
      []
    )
);

export const dummyAdReqBodySelector = (dummyAd) =>
  createSelector(
    userSelector,
    (userId) => ({
      ...omit(dummyAd, 'isNew', 'saved', 'id', 'groupName'),
      user_id: userId,
    })
  );

export const downloadTrackerListSelector = (groupName) =>
  createSelector(
    getActiveAdGroupsSelector,
    (adGroups) => {
      const selectedAdGroup = find(adGroups, { placement_group_name: groupName });
      const placementData = map(selectedAdGroup.placements, (pl) => ({
        id: `"${pl.id}"`,
        ad: `"${pl.name}"`,
        adGroup: `"${groupName}"`,
        url: `"${pl.url}"`,
        external_id: `"${pl.external_id == null ? '-' : pl.external_id}"`,
        created_date: `"${pl.created}"`,
        updated_date: `"${pl.updated}"`,
        impression_tracker: `"${pl.trackers.impression_url}"`,
        click_tracker: `"${pl.trackers.click_url}"`,
      }));
      return placementData;
    }
  );
