export default function createRoutes() {
  return {
    path: 'detail/:id',
    name: 'accountDetail',
    getComponent(_nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        cb(null, containers.AccountDetails);
      }, 'discover_account_detail');
    },
  };
}
