import React, { Fragment } from 'react';
import { ActionDropdown, ActionItem, Modal, Tooltip, Icon, Link, Row, Text } from 'v2-components';
import { get, isEmpty, startCase } from 'lodash';
import styles from './CAMPAIGN_NAME.module.scss';
import { newParamsNaviate } from 'utils/navigate';
import {
  CAMPAIGN_ACTION_DELETE,
  CAMPAIGN_SOURCE,
  CAMPAIGN_SOURCE_LABELS,
  CAMPAIGN_STATES,
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_X,
  CAMPAIGNS_ROUTE,
} from 'routes/Advertising/routes/Campaigns/constants';
import { campaignActionsCheck, getNeedActionCampaignIndicators } from '../../../utils';
import { connect } from 'react-redux';
import { actions as routeAction } from '../../../modules';
import PropTypes from 'prop-types';
import {
  htmlStrings,
  LINKEDIN_ADVERTISING_TOOLTIPS,
  LINKEDIN_PAGE_STATUS,
  LINKEDIN_POSTER_ACCESS_REJECTED,
  LINKEDIN_POSTER_ACCESS_REVOKED,
  SPONSORED_CONTENT_POSTER_STATUS,
} from 'utils/constants';
import * as campaignCSVExportModalActions from 'aa-components/CampaignCSVExportModal/actions';
import { filterBySelector } from '../commonSelectors';
import { classNames } from 'utils/utils';
import { Permission, usePermissionCheck } from '@sixsense/rbac';
import {
  CAMPAIGNS_CONFIGURATION_ROUTE,
  CONFIGURATION_MODES,
} from '../../CampaignConfiguration/constants';
import { browserHistory } from 'react-router';
import
  NeedActionIndicator, { summaryTooltip }
  from '../../../components/NeedActionIndicator/NeedActionIndicator';
import { If } from 'babel-plugin-jsx-control-statements';
import LinkedInIcon from 'images/linkedin_hq.svg';
import { emptyObjectType, folderType } from 'routes/Advertising/routes/Campaigns/propTypes';
import {
  ArchiveCampaignActionItem,
} from 'routes/Advertising/routes/Campaigns/components/ArchiveCampaignActionItem';
import {
  campaignClassificationDucks,
} from 'routes/Advertising/ducks';
import {
  CLASSIFICATION_TYPES,
} from 'routes/Advertising/ducks/campaignClassifications/constants';
import { PROMISE_STATES } from 'modules/global/constants';
import { DELETE_CAMPAIGN_CONFIRM_TEXT } from '../constants';
import { ADVERTISING_BASE_ROUTE, STATIC_TREE_NODE_KEYS } from 'routes/Advertising/constants';
import { genCampaignTreeNodeKey } from 'routes/Advertising/utils';
import { treeNodeSelectionsSelector } from '../../../../../selectors';
import { campaignsStateGenerators } from 'routes/Advertising/routes/Campaigns/ducks';
import {
  linkedInDisabledPagesSelector,
  linkedInPagesAccessRejectedSelector,
  linkedInPagesAccessRevokedSelector,
} from '../../../selectors';
import { CampaignActionItem } from 'routes/Advertising/components/CampaignActionItem';
import { pxToRem } from '@sixsense/core/style';

const {
  constants: {
    CAMPAIGN_CLASSIFICATION_ACTIONS,
  },
} = campaignClassificationDucks;
const {
  bulkUpdateStateGenerator: campaignsBulkUpdateStateGenerator,
} = campaignsStateGenerators;

const CampaignNameComponent = ({
  campaignName,
  campaign,
  showDeactivationModal,
  showCampaignCloneModal,
  campaignStateActionPrompt,
  toggleDownloadModalVisibility,
  filterBy,
  setCampaignClassificationModalVisibility,
  setArchiveCampaignModalVisibility,
  folderList,
  archivedFolder,
  selectedFolders,
  isDeletingCampaign,
  deleteCampaign,
  linkedinAccessRevokedPageIds,
  linkedinAccessRejectedPageIds,
  linkedinDisabledPageIds,
}) => {
  const campaignSource = campaign.campaign_source;
  const campaignStatus = get(campaign, 'campaign_status', null);
  const isDraftCampaign = campaignStatus === CAMPAIGN_STATUS_X.draft;
  const hasEditPermission = usePermissionCheck([Permission.CAMPAIGN_EDIT]);
  const hasArchivedFolder = !isEmpty(archivedFolder);
  const campaignAllowedActions = campaignActionsCheck(campaign, hasArchivedFolder);
  const isArchivedCampaign =
    get(campaign, 'folder.classification_type') === CLASSIFICATION_TYPES.CAMPAIGNS_ARCHIVED;
  const showMoveToOption = hasEditPermission && !isDraftCampaign;
  const showEditOption = hasEditPermission && !isArchivedCampaign && [
    CAMPAIGN_SOURCE.INTERNAL,
    CAMPAIGN_SOURCE.CONTEXTUAL,
    CAMPAIGN_SOURCE.RETARGETING,
    CAMPAIGN_SOURCE.EXTERNAL,
    CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING,
  ].includes(campaignSource) && campaignStatus !== CAMPAIGN_STATUS.completed.key;
  const showViewConfigOption = !showEditOption && !isArchivedCampaign && [
    CAMPAIGN_SOURCE.INTERNAL,
    CAMPAIGN_SOURCE.CONTEXTUAL,
    CAMPAIGN_SOURCE.RETARGETING,
    CAMPAIGN_SOURCE.EXTERNAL,
  ].includes(campaignSource);
  const showViewOption = hasEditPermission ? !isDraftCampaign : true;
  const showDownloadOption = !isDraftCampaign;
  const needActionIndicators = getNeedActionCampaignIndicators(campaign)
    .map(({ message, type, tooltip }) => ({ message, type, tooltip }))
    .filter((m) => !isEmpty(m));
  const isViewingAllCampaigns = get(selectedFolders, '[0]') ===
    genCampaignTreeNodeKey(STATIC_TREE_NODE_KEYS.CAMPAIGN_ALL);

  let linkedinPageStatus = '';
  if (campaignSource === CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING) {
    if (linkedinAccessRevokedPageIds.includes(campaign.linkedin_ads_page_id)) {
      needActionIndicators.push({ message: LINKEDIN_POSTER_ACCESS_REVOKED });
      linkedinPageStatus = SPONSORED_CONTENT_POSTER_STATUS.REVOKED;
    }
    if (linkedinAccessRejectedPageIds.includes(campaign.linkedin_ads_page_id)) {
      needActionIndicators.push({ message: LINKEDIN_POSTER_ACCESS_REJECTED });
      linkedinPageStatus = SPONSORED_CONTENT_POSTER_STATUS.REJECTED;
    }
    if (linkedinDisabledPageIds.includes(campaign.linkedin_ads_page_id)) {
      linkedinPageStatus = LINKEDIN_PAGE_STATUS.DISABLED;
    }
  }

  // TODO: Rename CAMPAIGN_STATUS_X
  const isArchiveDisabled =
    campaignStatus === CAMPAIGN_STATUS_X.active ||
    campaignStatus === CAMPAIGN_STATUS_X.scheduled;
  const moveOrRestoreAction =
    isArchivedCampaign
      ? CAMPAIGN_CLASSIFICATION_ACTIONS.restore
      : CAMPAIGN_CLASSIFICATION_ACTIONS.move;

  const needActionIndicatorsDisplay = needActionIndicators.length !== 0 ? (
    <Tooltip title={summaryTooltip(needActionIndicators)}>
      <div>
        <NeedActionIndicator className={'aam-t--10'} type={needActionIndicators[0].type}>
          {needActionIndicators[0].message}
          {needActionIndicators.length > 1 && (
            <span
              className={styles.moreNeedActionCount}
            >+{needActionIndicators.length - 1} more</span>
          )}
        </NeedActionIndicator>
      </div>
    </Tooltip>
  ) : null;

  const archivedCampaignIcon = isViewingAllCampaigns && isArchivedCampaign && (
    <Tooltip overlay={<Text color="white">Archived</Text>} placement="right">
      <Icon
        type={'inventory_2'}
        className={styles.iconClass}
        theme="outlined"
        tooltipText="Archived"
        tooltipWrapped
      />
    </Tooltip>
  );

  const confirmAndDeleteDraftCampaign = () => {
    if (campaignStatus === CAMPAIGN_STATUS_X.draft) {
      Modal.prompt({
        loading: isDeletingCampaign,
        onOk: () => deleteCampaign([
            { id: campaign.id, transaction: -1, actions: [CAMPAIGN_ACTION_DELETE] },
        ]),
        type: Modal.PROMPT_TYPES.CONFIRM,
        okText: startCase(CAMPAIGN_ACTION_DELETE),
        content: DELETE_CAMPAIGN_CONFIRM_TEXT,
      });
    }
  };

  // TODO: onExportAsCsv is currently duplicated here and in CampaignToolbar, need to de-duplicate
  const onExportAsCsv = () => {
    const additionalQueryParams = {
      ...filterBy,
      campaign_state: CAMPAIGN_STATES.submitted,
      classification_id: get(selectedFolders, '[0].id'),
    };

    toggleDownloadModalVisibility(true, [campaign.id], additionalQueryParams, 1);
  };

  return (<Row alignItems={'center'} className={styles.container}>
    <Text
      type={Text.TYPE.SUBBODY}
      title={campaignName}
      className={styles.campaignName}
    >
      <Link
        link={isDraftCampaign ?
          // eslint-disable-next-line max-len
          `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGNS_CONFIGURATION_ROUTE}/edit/${campaign.id}` :
          `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/view/${campaign.id}/`
        }
        className={classNames(styles.noDrag, styles.textOverflow)}
        isInternal
        dataPendo={'campaignListClickCampaignName'}
      >
        <Text
          color={Text.COLOR.BLUE}
          pointer
        >
          {campaignName}
        </Text>
      </Link>
      <Row alignItems="center" className="aam-t--5">
        {[CAMPAIGN_SOURCE.LINKEDIN, CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING].includes(
            campaignSource
          ) ? (
            <Fragment>
              <img
                src={LinkedInIcon}
                alt={CAMPAIGN_SOURCE_LABELS[campaignSource]}
                className={styles.linkedInIcon}
              />
              &nbsp;
              <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.GREY1}>
                {CAMPAIGN_SOURCE_LABELS[campaignSource].replace('LinkedIn ', '')}
              </Text>
            </Fragment>
          ) : (
            <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.GREY1}>
              {CAMPAIGN_SOURCE_LABELS[campaignSource]}
            </Text>
          )}
        <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.GREY1}>
            &nbsp; &#9679; ID: {campaign.id}
        </Text>
        {archivedCampaignIcon}
      </Row>
      {needActionIndicatorsDisplay}
    </Text>

    <ActionDropdown
      id="folder-actions"
      leftIcon="more_horiz"
      leftIconClass={styles.ellipsisIconClass}
      leftIconProps={{ size: Icon.SIZE.XLARGE }}
      className={styles.menu}
    >
      {showViewOption && (<ActionItem
        id="view-campaign"
        action={() => newParamsNaviate(
          `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/view/${campaign.id}/`
        )}
      >
        <Text>
          View
        </Text>
      </ActionItem>)}
      {showEditOption &&
        CampaignActionItem({
          isDisabled: !isDraftCampaign && linkedinPageStatus !== '',
          tooltipText: LINKEDIN_ADVERTISING_TOOLTIPS[linkedinPageStatus],
          onClick: () => browserHistory.push(
            // eslint-disable-next-line max-len
            `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGNS_CONFIGURATION_ROUTE}/${CONFIGURATION_MODES.edit}/${campaign.id}`
          ),
          label: 'Edit',
          id: 'Edit',
          dataPendo: 'Edit',
        })}
      <If condition={showViewConfigOption}>
        <ActionItem
          id="view-campaign-config"
          key="view-campaign-config"
          action={() => browserHistory.push(
            // eslint-disable-next-line max-len
            `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGNS_CONFIGURATION_ROUTE}/${CONFIGURATION_MODES.edit}/${campaign.id}`
          )}
        >
          <Text>
            View Configuration
          </Text>
        </ActionItem>
      </If>
      {showMoveToOption && (<ActionItem
        id="move-campaign"
        key="move-campaign"
        action={() =>
          setCampaignClassificationModalVisibility(true, [campaign.id], moveOrRestoreAction)
        }
        data-pendo={`campaigns-${moveOrRestoreAction}ToFolder`}
      >
        <Text>
          {isArchivedCampaign ? 'Restore' : 'Move to...'}
        </Text>
      </ActionItem>)}
      {campaignAllowedActions.canDeactivate && (<ActionItem
        id="deactivate-campaign"
        key="deactivate-campaign"
        action={() => showDeactivationModal(campaign)}
      >
        <Text>
          Deactivate
        </Text>
      </ActionItem>)}
      {campaignAllowedActions.canActivate && CampaignActionItem({
        isDisabled: !isDraftCampaign && linkedinPageStatus !== '',
        tooltipText: LINKEDIN_ADVERTISING_TOOLTIPS[linkedinPageStatus],
        onClick: () => campaignStateActionPrompt(
          true,
          campaign,
          'activate',
          htmlStrings.ACTIVATE_WARNING_MESSAGE,
        ),
        label: 'Activate',
        id: 'activate',
        dataPendo: 'activate',
      })}
      {campaignAllowedActions.canDelete && (<ActionItem
        id="delete-campaign"
        key="delete-campaign"
        action={confirmAndDeleteDraftCampaign}
      >
        <Text>
          Delete
        </Text>
      </ActionItem>)}
      {campaignAllowedActions.canEnd && (<ActionItem
        id="end-campaign"
        key="end-campaign"
        action={() => campaignStateActionPrompt(
          true,
          campaign,
          'end',
          htmlStrings.END_WARNING_MESSAGE,
        )}
      >
        <Text>
          Mark as Complete
        </Text>
      </ActionItem>)}
      {campaignAllowedActions.canClone &&
        CampaignActionItem({
          isDisabled: !isDraftCampaign && linkedinPageStatus !== '',
          tooltipText: LINKEDIN_ADVERTISING_TOOLTIPS[linkedinPageStatus],
          onClick: () => showCampaignCloneModal(campaign, folderList),
          label: 'Clone',
          id: 'Clone',
          dataPendo: 'Clone',
        })}
      {showDownloadOption && (<ActionItem
        id="download-campaign"
        data-pendo={'exportAsCsvIndividualCampaign'}
        key="download-campaign"
        action={onExportAsCsv}
      >
        <Text>
          Export as CSV
        </Text>
      </ActionItem>)}
      {campaignAllowedActions.canArchive &&
          // TODO(Umesh Kadam): Fix this!
          // Ant'd's Menu.Item loses css when embedded in other component.
          // I know this is an anti-pattern but there is currently no way to get around this.
          // see related issues https://github.com/ant-design/ant-design/issues/4853
          // https://github.com/ant-design/ant-design/issues/5540
          // https://github.com/ant-design/ant-design/issues/18022#issuecomment-618670738
        ArchiveCampaignActionItem({
          isDraftCampaign,
          isArchiveDisabled,
          onClick: () => setArchiveCampaignModalVisibility(true, [campaign.id]),
        })
      }
    </ActionDropdown>
  </Row>);
};
CampaignNameComponent.propTypes = {
  campaignName: PropTypes.string.isRequired,
  campaign: PropTypes.object.isRequired,
  showDeactivationModal: PropTypes.func.isRequired,
  showCampaignCloneModal: PropTypes.func.isRequired,
  campaignStateActionPrompt: PropTypes.func.isRequired,
  toggleDownloadModalVisibility: PropTypes.func.isRequired,
  setCampaignClassificationModalVisibility: PropTypes.func.isRequired,
  filterBy: PropTypes.object.isRequired,
  folderList: PropTypes.arrayOf(folderType).isRequired,
  selectedFolders: PropTypes.array.isRequired,
  setArchiveCampaignModalVisibility: PropTypes.func.isRequired,
  archivedFolder: PropTypes.oneOfType([folderType, emptyObjectType]).isRequired,
  isDeletingCampaign: PropTypes.bool.isRequired,
  deleteCampaign: PropTypes.func.isRequired,
  linkedinAccessRejectedPageIds: PropTypes.array,
  linkedinAccessRevokedPageIds: PropTypes.array,
  linkedinDisabledPageIds: PropTypes.array,
};
const mapStateToProps = (state) => ({
  filterBy: filterBySelector(state),
  folderList: campaignClassificationDucks.selectors.classificationChoicesSelector(state),
  selectedFolders: treeNodeSelectionsSelector(state),
  archivedFolder: campaignClassificationDucks.selectors.archivedFolderSelector(state),
  // TODO: right now only the delete action takes place using this generator. Hence the name.
  // this would be generalized in the near future to handle other updates
  isDeletingCampaign:
    campaignsBulkUpdateStateGenerator.promiseStateSelector(state) === PROMISE_STATES.PENDING,
  linkedinAccessRevokedPageIds: linkedInPagesAccessRevokedSelector(state),
  linkedinAccessRejectedPageIds: linkedInPagesAccessRejectedSelector(state),
  linkedinDisabledPageIds: linkedInDisabledPagesSelector(state),
});
const dispatchToProps = ({
  showDeactivationModal: routeAction.showDeactivationModal,
  showCampaignCloneModal: routeAction.showCampaignCloneModal,
  campaignStateActionPrompt: routeAction.campaignStateActionPrompt,
  toggleDownloadModalVisibility: campaignCSVExportModalActions.toggleVisibility,
  setCampaignClassificationModalVisibility:
    campaignClassificationDucks.actionCreators.setClassificationModalVisibility,
  setArchiveCampaignModalVisibility: routeAction.setArchiveCampaignModalVisibility,
  deleteCampaign: campaignsBulkUpdateStateGenerator.loadAction,
});
const CampaignName = connect(mapStateToProps, dispatchToProps)(CampaignNameComponent);

export const CAMPAIGN_NAME = {
  sortByKey: 'campaign_name',
  fixed: 'left',
  customizable: false,
  key: 'campaign-name',
  csvColumnKey: 'name',
  title: 'Campaign',
  dataIndex: 'campaign_name',
  width: pxToRem(370),
  render: (campaignName, campaign) => (<CampaignName
    campaignName={campaignName}
    campaign={campaign}
  />),
};
