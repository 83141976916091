import React from 'react';
import { Handle, Position } from 'reactflow';
import PropTypes from 'prop-types';
import { DesignSystemThemeHoc } from '../../DesignSystemThemeHOC';

const NodeContainer = ({ isConnectable, children }) => (
  <React.Fragment>
    <Handle
      type="target"
      position={Position.Top}
      style={{ visibility: 'hidden' }}
      onConnect={(params) => console.log('handle onConnect', params)}
      isConnectable={isConnectable}
    />
    <DesignSystemThemeHoc>{children}</DesignSystemThemeHoc>
    <Handle
      type="source"
      position={Position.Bottom}
      style={{ visibility: 'hidden' }}
      onConnect={(params) => console.log('handle onConnect', params)}
      isConnectable={isConnectable}
    />
  </React.Fragment>
);

NodeContainer.propTypes = {
  isConnectable: PropTypes.bool,
  children: PropTypes.element,
};

export default NodeContainer;
