import React, { useState, useEffect } from 'react';
import { useRequest } from '@sixsense/core';
import { ProductCardComponent } from './ProductCardComponent';
import { css } from 'styles/emotion';
import { Alert, useToast } from '@sixsense/core/components';
import { Rocket } from '@sixsense/core/icons';

const styles = {
  aiwriterAlert: css({
    marginTop: '28px',
  }),
};

export const ProductCard = ({ orgId, onCollapseChange }) => {
  const [showEmailWriterAlert, setShowEmailWriterAlert] =
    useState<Boolean>(true);
  const [collapsedState, setCollapsedState] = useState<Array<Boolean>>([]);
  const [activeSteps, setActiveSteps] = useState({});
  const [productList, setProductList] = useState([]);
  const [productListApiSuccess, setProductListApiSuccess] = useState(false);
  const request = useRequest();
  const toast = useToast();

  const toggleCollapseValue = (productIndex: number) => {
    setCollapsedState((prevState) =>
      prevState?.map((collapsed, i) => (i === productIndex ? !collapsed : true))
    );
    setActiveSteps((prevSteps) => ({
      ...prevSteps,
      [productIndex]: 0,
    }));
    onCollapseChange(collapsedState);
  };

  const getProductList = async () => {
    const productsUrl = `sales-ai-settings/${orgId}/admin/email-writer-product-summaries/`;
    const fetchProducts: any = await request(productsUrl, {
      method: 'GET',
    });
    if (fetchProducts?.status === 'success') {
      setProductList(fetchProducts?.data || []);
      const products = fetchProducts?.data;
      let isCollapseOnDraftSet = false;
      const collapsedStateVal = products?.map((product) => {
        const isShowCollapseOnDraft =
          !isCollapseOnDraftSet &&
          product?.summaries?.length === 0 &&
          product?.has_attributes;
        if (isShowCollapseOnDraft) isCollapseOnDraftSet = true;
        // eslint-disable-next-line no-param-reassign
        product.is_draft = product?.summaries.length === 0 && product?.has_attributes;
        // eslint-disable-next-line no-param-reassign
        product.is_show_collapse_on_draft = isShowCollapseOnDraft;
        return true;
      });
      setCollapsedState(collapsedStateVal);
      const noShowCollapseOnDraft = !products?.some(
        (product) => product.is_show_collapse_on_draft
      );

      // If no product has draft, set an additional flag for the first active product
      if (noShowCollapseOnDraft) {
        for (const product of products) {
          if (product?.summaries?.length > 0 && product?.is_latest) {
            product.is_active_summary = true;
            break; // Only set the flag for the latest draft generated
          }
        }
      }

      if (products?.length > 0) {
        const hasActiveSummary = products?.some((product) =>
          product?.summaries?.some((summary) => summary?.status === 'active')
        );
        setShowEmailWriterAlert(!hasActiveSummary);
      }
      setProductListApiSuccess(true);
    } else {
      setProductListApiSuccess(false);
      toast.show({
        type: 'icon',
        icon: Rocket,
        iconColor: 'Error',
        title: 'Oops!',
        subtitle: 'Something went wrong. Please try again',
      });
    }
  };

  useEffect(() => {
    getProductList();
  }, []);

  const closeAlert = () => {
    setShowEmailWriterAlert(!showEmailWriterAlert);
  };

  return (
    <>{productListApiSuccess && (
      <>{showEmailWriterAlert && (
      <Alert
        type="warning"
        description={'Finish adding your product information to enable your ' +
              'Sales Representative to use the AI Writer'}
        closable
        size="sm"
        className={styles.aiwriterAlert}
        onDismiss={closeAlert}
        banner={'true'}
      />
        )}{productList?.map((product, index) => (
          <ProductCardComponent
            key={product?.product}
            product={product}
            productIndex={index}
            orgId={orgId}
            isCollapsed={collapsedState[index]}
            toggleCollapseValue={() => toggleCollapseValue(index)}
            activeSteps={activeSteps}
            setActiveSteps={setActiveSteps}
            getProductList={getProductList}
            productListApiSuccess={productListApiSuccess}
          />
        ))}</>
    )}</>

  );
};
