
import * as qs from 'qs';
import { browserHistory } from 'react-router';
import { getAppUrlWithOrgName } from 'routes/Settings/routes/Manage/utils';
import { isSiteLoadedInIframe } from './utils';
import { mergeParamsNavigate } from './navigate';

export const MULTIAPP_CHILD_IFRAME_ID = 'multi_app--child--iframe'; // use this ID for all iframes

export const MULTI_APP_EVENT_NAME = {
  LISTENERS: {
    PARENT_TO_ABM_LOAD_APP_SETTINGS_LIST_PAGE: 'PARENT_TO_ABM.loadAppSettingsListPage',
    PARENT_TO_ABM_TO_CHILD_DATA_PASSTHROUGH: 'PARENT_TO_ABM_TO_CHILD.dataPassThrough',
    PARENT_TO_ABM_REDIRECT_TO_ABM_SETTINGS_PATH: 'PARENT_TO_ABM.redirectToABMSettingsPath',
    CHILD_TO_ABM_HEADLESS_APP_UNLOADED: 'CHILD_TO_ABM.headlessAppUnLoaded',
    CHILD_TO_ABM_TO_PARENT_DATA_PASSTHROUGH: 'CHILD_TO_ABM_TO_PARENT.dataPassThrough',
    PARENT_TO_ABM_UPDATE_ABM_DRAWER_STATE: 'PARENT_TO_ABM.updateABMDrawerState',
    CHILD_TO_ABM_UPDATE_ABM_DRAWER_STATE: 'CHILD_TO_ABM.updateABMDrawerState',
    CHILD_TO_ABM_APPHEADER_CONFIG: 'CHILD_TO_ABM.appHeaderConfig',
    CHILD_TO_ABM_UNLOAD_SETTINGS_DRAWER: 'CHILD_TO_ABM.unloadSettingsDrawer',
    CHILD_TO_ABM_ACTIVE_PATH_SYNC: 'CHILD_TO_ABM.ActivePathSync',
    CHILD_TO_ABM_TO_PARENT_DOCUMENT_CLICKED: 'CHILD_TO_ABM_TO_PARENT.documentClicked',
  },
  EMITTERS: {
    ABM_TO_CHILD_LOAD_APP_SETTINGS_LISTPAGE: 'ABM_TO_CHILD.loadAppSettingsListPage',
    ABM_TO_CHILD_LOAD_DEFAULT_PAGE: 'ABM_TO_CHILD.loadDefaultPage',
    ABM_TO_PARENT_SETTINGS_DRAWER_LOADED: 'ABM_TO_PARENT.settingsDrawerLoaded',
    ABM_TO_PARENT_SETTINGS_DRAWER_UNLOADED: 'ABM_TO_PARENT.settingsDrawerUnLoaded',
    ABM_TO_PARENT_ACTIVE_PATH_SYNC: 'ABM_TO_PARENT.ActivePathSync',
    ABM_TO_CHILD_LOAD_MAPPING_PROFILES_PAGE: 'ABM_TO_CHILD.loadMappingProfilesPage',
  },
};


// to which events should be sent.

// ABM -> Parent (workflows/SI/..) Supports Workflows & SI now. Add new ones to allowedParentOrigins
const allowedIframeEndpoints = [
  getAppUrlWithOrgName(window.process.env.WORKFLOWS_UI_ENDPOINT),
  getAppUrlWithOrgName(window.process.env.SI_UI_ENDPOINT),
];
export const emitMultiAppEventToParentWindow = (eventData) => { // ABM To Parent window
  try {
    if (window.parent && isSiteLoadedInIframe()) {
      allowedIframeEndpoints.forEach((appOrigin) => {
        // only one (to Active parent) will be posted
        window.parent.postMessage(eventData, appOrigin);
      });
    }
  } catch (e) {
    console.info(e); // all other postMessages will be caught here.
  }
};

export const emitMultiAppEventToChildIframe = (eventData, iframeSrc) => {
  const iframeSettingsElement = document.getElementById(MULTIAPP_CHILD_IFRAME_ID);
  try {
    if (iframeSettingsElement) {
      iframeSettingsElement.contentWindow.postMessage(eventData, iframeSrc);
    }
  } catch (e) {
    console.info(e);
  }
};

export const setActiveSearchParam = (params) => {
  const nextActivePath = params.activePath;
  // Parse the existing query parameters
  const prevSearchParams = qs.parse(
    browserHistory.getCurrentLocation().search,
    { ignoreQueryPrefix: true }
  );
  const prevActivePath = prevSearchParams.activePath;

  if (prevActivePath !== nextActivePath) {
    // Set the new query parameter
    const nextParamsObject = {
      ...prevSearchParams,
      ...params,
    };

    const nextSearchParams = qs.stringify(nextParamsObject, { addQueryPrefix: true });

    browserHistory.replace({
      pathname: browserHistory.getCurrentLocation().pathname,
      search: nextSearchParams,
    });
  }
};

export const getCurrentSearchParamValue = (paramName) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
};

export const trimStartingSlash = (inputString) => {
  if (inputString.startsWith('/')) {
    return inputString.slice(1);
  }
  return inputString;
};

export const setActivePath = (activePath) => {
  if (activePath) {
    mergeParamsNavigate(window.location.pathname, { activePath });
  }
};
