import React from "react";
import { Text } from "@sixsense/core/components";
import { NA } from "./common/NA";
import {
  productsSelector,
  hasPredictiveProductsSelector,
} from "../../../ducks/selectors";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";
import { connect } from "react-redux";
import { get, find } from "lodash";
import PropTypes from "prop-types";
import { CAMPAIGN_DESCRPITORS_FLAG } from "routes/AdvertisingDS/featureGating/constants";
import { CAMPAIGN_SOURCE } from "../../../constants";
import { PRODUCT_COLUMN_KEY } from "../constants";

const ProductDisplayComponent = ({
  product,
  productList = [],
  hasPredictiveProducts,
}) => {
  if (!hasPredictiveProducts || !product) return <NA />;

  const selectedProduct = find(productList, (prod) => prod.value === product);
  const displayValue = get(selectedProduct, "label");

  return (
    <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
      {displayValue || <NA />}
    </Text.Body>
  );
};

ProductDisplayComponent.propTypes = {
  product: PropTypes.string,
  productList: PropTypes.array,
  hasPredictiveProducts: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  productList: productsSelector(state),
  hasPredictiveProducts: hasPredictiveProductsSelector(state),
});

const ProductDisplay = connect(mapStateToProps)(ProductDisplayComponent);

export const PRODUCT = {
  key: PRODUCT_COLUMN_KEY,
  csvColumnKey: PRODUCT_COLUMN_KEY,
  title: <Label name={CAMPAIGN_LABELS.product} />,
  dataIndex: "campaign_meta.product",
  width: pxToRem(200),
  featureFlagKey: CAMPAIGN_DESCRPITORS_FLAG,
  render: (product, campaign) => {
    if (campaign.campaign_source === CAMPAIGN_SOURCE.EXTERNAL) return <NA />;

    return <ProductDisplay product={product} />;
  },
  align: "left",
};
