/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrchestrationIconStyles.module.scss';
import { Text, Tooltip2 as Tooltip, } from 'v2-components';

const EnvProduction = ({ className }) =>
(
  <Tooltip
    placement={'top'}
    overlay={'Production'}
  >
    <div className={`${styles.environmentIcon} ${styles.pdIcon} ${className}`}>
      <Text type={Text.TYPE.MINI} color={Text.COLOR.WHITE}>P</Text>
    </div>
  </Tooltip>
)

export default EnvProduction;