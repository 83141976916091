export default function createRoutes() {
  return {
    path: 'activities',
    name: 'activities',
    getComponent(nextState, cb) {
      import('./containers/Activities').then((page) => {
        cb(null, page.default);
      });
    },
  };
}
