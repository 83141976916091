import { useState } from 'react';
import { get, has } from 'lodash';
import { AUDIENCE_ROUTES } from 'routes/AudienceWorkflows/constants';
import { newParamsNavigate } from 'utils/navigate';
import {
  MULTI_APP_EVENT_NAME,
  emitMultiAppEventToChildIframe,
  emitMultiAppEventToParentWindow,
  setActivePath,
  setActiveSearchParam,
} from 'utils/multiAppEventUtils';
import { getAppUrlWithOrgName } from 'routes/Settings/routes/Manage/utils';
import { useSelector } from '@sixsense/core/versioned/react-redux';
import { settingsConfigSelector } from 'modules/user/selectors';

export const useAudienceWorkflowsListeners = () => {
  const [appHeaderProps, setAppHeaderProps] = useState({
    title: AUDIENCE_ROUTES.DEFAULT.TITLE,
  });
  const [audienceIframeLoading, setAudienceIframeLoading] = useState(true);

  const triggerMouseDownEvent = () => {
    const mousedownEvent = new MouseEvent('mousedown', {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    document.dispatchEvent(mousedownEvent);
  };

  const mutliAppEventListener = (event) => {
    const multiAppEventData = event.data;
    const isMultiAppEvent = has(multiAppEventData, 'multiAppEvent');
    if (isMultiAppEvent) {
      const multiAppEventName = get(multiAppEventData, 'multiAppEvent');
      switch (multiAppEventName) {
        case MULTI_APP_EVENT_NAME.LISTENERS.CHILD_TO_ABM_TO_PARENT_DATA_PASSTHROUGH:
          if (get(multiAppEventData, 'appMounted')) {
            // spinner deactivate
            setAudienceIframeLoading(false);
          }
          break;
        case MULTI_APP_EVENT_NAME.LISTENERS.CHILD_TO_ABM_TO_PARENT_DOCUMENT_CLICKED:
          triggerMouseDownEvent();
          break;
        case MULTI_APP_EVENT_NAME.LISTENERS.CHILD_TO_ABM_ACTIVE_PATH_SYNC:
          // ActivePathChanged event
          if (multiAppEventData?.activePath) {
            setActiveSearchParam({ activePath: multiAppEventData?.activePath });
          }
          if (audienceIframeLoading) { // if still loading, deactivate spinner
            setAudienceIframeLoading(false);
          }
          break;
        case MULTI_APP_EVENT_NAME.LISTENERS.CHILD_TO_ABM_HEADLESS_APP_UNLOADED: {
          // headless app unloaded. prbably due to session timeout. reload to login.
          window.location.reload();
          break;
        }
        case MULTI_APP_EVENT_NAME.LISTENERS.CHILD_TO_ABM_APPHEADER_CONFIG: {
          const { backBtnClickEvtName, ...rest } = multiAppEventData?.appHeaderConfig;
          const backBtnClickHandler = backBtnClickEvtName ? () => emitMultiAppEventToChildIframe({
            multiAppEvent: backBtnClickEvtName,
          }, getAppUrlWithOrgName(window.process.env.WORKFLOWS_UI_ENDPOINT)) : undefined;
          setAppHeaderProps({
            ...rest,
            onBackBtnCick: backBtnClickHandler,
          });
          break;
        }
        default:
          break;
      }
    }
  };

  return {
    appHeaderProps,
    mutliAppEventListener,
    audienceIframeLoading,
  };
};


export const useSettingsDrawerListeners = (
  resetAppSettingSelection,
  updateDrawerStateFromEvent,
) => {
  /**
   * Read the detailed documentation below -
   * https://6sense.atlassian.net/wiki/spaces/ENG/pages/3165749896/Multi-App+Settings+Framework
   *
   * MULTI APP SETTINGS FRAMEWORK CONCEPT ****************
   * There are 3 contexts (window obj) in settings page implementation
   * 1. Parent Window - ex: workflows.6sense.com/settings  !----
   *      This is just a portal that has an iframe that loads the child window (abm).
   * 2. ABM Window - abm.6sense.com/settings/manage?currentApp='workflows' !----
   *     ABM instance loaded from wf parent. will have another iframe inside this if wf is selected
   *      The inner iframe will load a Child instance that renders wf settings in headless mode
   * 3. Child - wf.6sense.com/settings-headless  !----
   *      Settings detail pages of apps like workflows..
   * ************** ************** *************
   *  type TMultiAppEvent = {
   *     multiAppEvent: any of the below //! Events
   *     iframeRedirectUrl?: string;
   *     abmRedirectUrl?: string;
   *     expandDrawer?: boolean;
   *     appName?: string; // needed only if a parent app want to load another app's settings
   *   }
   //! Events
   //! PARENT_TO_ABM.updateABMDrawerState
   //! CHILD_TO_ABM.updateABMDrawerState
   //! PARENT_TO_ABM.loadAppSettingsListPage
   //! CHILD_TO_ABM_TO_PARENT.dataPassThrough
   //! PARENT_TO_ABM_TO_CHILD.dataPassThrough
   //! PARENT_TO_ABM.redirectToABMSettingsPath
   //! ABM_TO_PARENT.settingsDrawerUnLoaded
   //! ABM_TO_PARENT.settingsDrawerLoaded
   * event.data is of type TMultiAppEvent;
   */
  const { iFrameContainerURL } = useSelector(settingsConfigSelector);

  const mutliAppEventListener = (event) => {
    const multiAppEventData = event.data;
    const isMultiAppEvent = has(multiAppEventData, 'multiAppEvent');
    if (isMultiAppEvent) {
      const multiAppEventName = get(multiAppEventData, 'multiAppEvent');
      switch (multiAppEventName) {
        case MULTI_APP_EVENT_NAME.LISTENERS.PARENT_TO_ABM_UPDATE_ABM_DRAWER_STATE:
        case MULTI_APP_EVENT_NAME.LISTENERS.CHILD_TO_ABM_UPDATE_ABM_DRAWER_STATE:
          updateDrawerStateFromEvent(multiAppEventData);
          break;
        case MULTI_APP_EVENT_NAME.LISTENERS.CHILD_TO_ABM_UNLOAD_SETTINGS_DRAWER:
          // if reload/session timeout event is received
          emitMultiAppEventToParentWindow({
            multiAppEvent: MULTI_APP_EVENT_NAME.EMITTERS.ABM_TO_PARENT_SETTINGS_DRAWER_UNLOADED,
            // transmit to parent
          });
          break;
        case MULTI_APP_EVENT_NAME.LISTENERS.PARENT_TO_ABM_LOAD_APP_SETTINGS_LIST_PAGE:
          // Parent --> ABM
          // emited from Parent when settings icon on topnav is clicked in an app
          resetAppSettingSelection(multiAppEventData);
          emitMultiAppEventToChildIframe({
            multiAppEventData,
            multiAppEvent: MULTI_APP_EVENT_NAME.EMITTERS.ABM_TO_CHILD_LOAD_APP_SETTINGS_LISTPAGE,
          }, iFrameContainerURL); // notify child
          // that abm has selected the app in settings left-drawer and expanded the drawer.
          // child should render the app settings list page.
          break;
        case MULTI_APP_EVENT_NAME.LISTENERS.PARENT_TO_ABM_REDIRECT_TO_ABM_SETTINGS_PATH:
          // Parent --> ABM
          // emited from Parent if parent window wants to redirect ABM to any internal ABM route
          // selectedApp will be default App in the drawer.
          resetAppSettingSelection(multiAppEventData);
          if (has(multiAppEventData, 'abmRedirectUrl')) {
            newParamsNavigate(get(multiAppEventData, 'abmRedirectUrl'), {});
          }
          updateDrawerStateFromEvent(multiAppEventData);
          break;
        case MULTI_APP_EVENT_NAME.LISTENERS.PARENT_TO_ABM_TO_CHILD_DATA_PASSTHROUGH:
          // PASS THROUGH EVENT.
          emitMultiAppEventToChildIframe(multiAppEventData, iFrameContainerURL);
          // ABM simply transmits data received
          // from parent to child. Make sure not to emit this event from child to avoid
          // infinite loops. Always pass this event from parent.
          updateDrawerStateFromEvent(multiAppEventData);
          break;
        case MULTI_APP_EVENT_NAME.LISTENERS.CHILD_TO_ABM_TO_PARENT_DATA_PASSTHROUGH:
          // PASS THROUGH EVENT.
          emitMultiAppEventToParentWindow(multiAppEventData);
          // ABM simply transmits data received from
          // child to parent. Make sure not to emit this event from parent to avoid
          // infinite loops.  Always pass this event from child.
          updateDrawerStateFromEvent(multiAppEventData);
          break;
        case MULTI_APP_EVENT_NAME.LISTENERS.CHILD_TO_ABM_ACTIVE_PATH_SYNC:
          updateDrawerStateFromEvent(multiAppEventData);
          setActivePath(multiAppEventData.activePath);
          break;
        default:
          break;
      }
    }
  };

  return mutliAppEventListener;
};
