export default function createRoutes() {
  return {
    path: 'mapping-review/:dataSource/:classificationType',
    name: 'mapping-review-taxonomy',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const container = require('./containers/MappingReviewContainer');
          cb(null, container.MappingReviewContainer);
        },
        'taxv2-mapping-review'
      );
    },
  };
}
