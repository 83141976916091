import { useRequest } from '@sixsense/core';
import { Flex } from '@sixsense/core/components';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import React, { useState } from 'react';
import { css } from 'styles/emotion';
import { Button, Text } from 'v2-components';
import useStageConfigState from './useStageConfigState';
import { actions as globalActions } from 'modules/global';
import useStageConfigPermissions from './hooks/useStageConfigPermissions';
import { useOrg } from 'hooks';
import { TagsIcon } from 'routes/Orchestrations/components';

const styles = {
  container: css({
    padding: '10px 30px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 2px 0 rgba(74,87,94,0.2)',
  }),
};

const StageConfigHeader = () => {
  const { stageConfig, renameMap } = useStageConfigState();
  const { canEdit } = useStageConfigPermissions();
  const { id: orgId } = useOrg();

  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();

  const request = useRequest();

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const finalConfig = stageConfig.map((stageGroup) => ({
        ...stageGroup,
        group_name: renameMap[stageGroup.group_name] || stageGroup.group_name,
      }));
      await request(`org/${orgId}/sales_stage_config/`, {
        method: 'POST',
        body: JSON.stringify({ stage_config: finalConfig }),
      });
      setIsSaving(false);
      dispatch(globalActions.showNotification('success', 'Configuration successfully saved.'));
    } catch (e) {
      setIsSaving(false);
      dispatch(globalActions.showNotification('error', 'Error saving configuration.'));
    }
  };

  return (
    <Flex className={styles.container}>
      <span>
        <Text type={Text.TYPE.TITLE} weight="bold">
          Opportunity Stage Configuration {orgId !== 4 && <TagsIcon type="BETA_TAG" />}{' '}
        </Text>
        <Text type={Text.TYPE.SUBBODY}>
          — The configured order of stages/stage groups will populate the Funnel Insights Report
        </Text>
      </span>
      <div style={{ flex: 1 }} />
      {canEdit ? (
        <React.Fragment>
          <Button onClick={() => window.history.back()}>
            Back
          </Button>
          <div style={{ width: 15 }} />
          <Button type="primary" onClick={handleSave} disabled={isSaving}>
            Save
          </Button>
        </React.Fragment>
      ) : null}
    </Flex>
  );
};

export default StageConfigHeader;
