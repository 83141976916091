import React from 'react';

/* eslint max-len: [2, 100000] */
export function NotSetUp() {
  return (
    <svg height="220" viewBox="0 0 698 280" xmlns="http://www.w3.org/2000/svg">
      <title>6sense</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M230.674 66.995c0-23.834-19.32-43.153-43.154-43.153-20.128 0-36.985 13.795-41.756 32.435-.467-.02-.925-.07-1.397-.07-17.874 0-32.365 14.49-32.365 32.365-11.916 0-21.577 9.66-21.577 21.576s9.66 21.577 21.577 21.577h118.672c17.873 0 32.365-14.49 32.365-32.365 0-17.874-14.493-32.365-32.366-32.365z"
          stroke="#0A0B09"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M665.225 137.755c0 3.394-2.777 6.17-6.17 6.17H634.37c-3.394 0-6.17-2.776-6.17-6.17V82.22c0-3.394 2.776-6.17 6.17-6.17h24.682c3.394 0 6.17 2.776 6.17 6.17v55.535"
          fill="#666763"
        />
        <path fill="#4CB9B7" d="M628.2 131.584h37.025V88.39H628.2" />
        <path
          d="M631.286 131.584l18.127-18.126m15.812-25.068l-36.253 36.252m18.127 6.942l17.74-17.74M644.59 88.39l-10.124 10.124"
          stroke="#FFFFFE"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M665.225 137.755c0 3.394-2.777 6.17-6.17 6.17H634.37c-3.394 0-6.17-2.776-6.17-6.17V82.22c0-3.394 2.776-6.17 6.17-6.17h24.682c3.394 0 6.17 2.776 6.17 6.17v55.535zm0-6.17H628.2h37.025zM628.2 88.39h37.025H628.2zm18.513-6.17h6.17-6.17zm-3.085 55.535h6.17-6.17z"
          stroke="#0E0F11"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path fill="#454543" d="M59.554 168.23H74.15v-58.378H59.553" />
        <path
          fill="#454543"
          d="M63.203 109.852H70.5v-7.297h-7.297M103.338 66.068c0 20.15-16.335 36.487-36.487 36.487-20.15 0-36.485-16.337-36.485-36.487 0-20.153 16.335-36.486 36.486-36.486 20.153 0 36.488 16.333 36.488 36.486"
        />
        <path
          d="M96.04 66.068c0 16.12-13.067 29.19-29.19 29.19-16.12 0-29.188-13.07-29.188-29.19 0-16.123 13.068-29.19 29.19-29.19 16.12 0 29.19 13.067 29.19 29.19"
          fill="#13BBB2"
        />
        <path
          d="M103.338 66.068c0 20.15-16.335 36.487-36.487 36.487-20.15 0-36.485-16.337-36.485-36.487 0-20.153 16.335-36.486 36.486-36.486 20.153 0 36.488 16.333 36.488 36.486zm-7.297 0c0 16.12-13.067 29.19-29.19 29.19-16.12 0-29.188-13.07-29.188-29.19 0-16.123 13.068-29.19 29.19-29.19 16.12 0 29.19 13.067 29.19 29.19zM59.555 168.23H74.15v-58.378H59.553v58.378zm0-51.08H74.15 59.553zm14.595 43.783H59.553 74.15zm-10.947-51.08v-7.298 7.297zm7.297 0v-7.298 7.297z"
          stroke="#0E0F11"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.96 66.068c0-12.1 9.79-21.892 21.89-21.892"
          stroke="#FFFFFE"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M472.33 115.556c-9.288 0-16.82-7.523-16.82-16.816s7.532-16.815 16.82-16.815V48.28c-27.866 0-50.46 22.59-50.46 50.46s22.594 50.46 50.46 50.46c13.938 0 26.552-5.65 35.686-14.775l-23.794-23.793c-3.046 3.044-7.247 4.924-11.892 4.924"
          fill="#4CB9B7"
        />
        <path
          d="M484.222 86.85l23.794-23.795c-9.134-9.123-21.748-14.774-35.686-14.774v33.645c4.645 0 8.846 1.88 11.892 4.924"
          fill="#FFFFFE"
        />
        <path
          d="M508.74 62.998l-23.795 23.794c3.047 3.046 4.928 7.247 4.928 11.89 0 4.646-1.88 8.846-4.928 11.893l23.794 23.793c9.123-9.137 14.773-21.752 14.773-35.685 0-13.933-5.65-26.547-14.774-35.685"
          fill="#F6E667"
        />
        <path
          d="M522.79 98.74c0 27.87-22.593 50.46-50.46 50.46-27.866 0-50.46-22.59-50.46-50.46s22.594-50.46 50.46-50.46c27.867 0 50.46 22.59 50.46 50.46zm-33.64 0c0 9.293-7.53 16.816-16.82 16.816-9.288 0-16.82-7.523-16.82-16.816s7.532-16.815 16.82-16.815c9.29 0 16.82 7.522 16.82 16.815zm-16.82-16.815V48.28v33.645zm11.892 4.924l23.794-23.795-23.794 23.794zm0 23.782l23.794 23.793-23.794-23.793z"
          stroke="#0E0F11"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path fill="#FFFFFE" d="M588.464 237.256h97.183v-53.722h-97.183" />
        <path
          fill="#CDCCC7"
          d="M613.16 274.28h49.365v-6.17H613.16M693.378 243.427v6.17c0 3.41-2.76 6.17-6.17 6.17h-98.73c-3.41 0-6.17-2.76-6.17-6.17v-6.17M648.64 255.768h-21.596l-1.543 12.34h24.684l-1.543-12.34"
        />
        <path
          d="M588.478 237.256h98.73V181.72h-98.73v55.536zm98.73-61.706h-98.73c-3.41 0-6.17 2.76-6.17 6.17v61.707h111.07V181.72c0-3.41-2.76-6.17-6.17-6.17z"
          fill="#666763"
        />
        <path
          d="M588.478 237.256h98.73V181.72h-98.73v55.536zm98.73-61.706h-98.73c-3.41 0-6.17 2.76-6.17 6.17v61.707h111.07V181.72c0-3.41-2.76-6.17-6.17-6.17zm6.17 67.877v6.17c0 3.41-2.76 6.17-6.17 6.17h-98.73c-3.41 0-6.17-2.76-6.17-6.17v-6.17h111.07zm-44.737 12.34h-21.596L625.5 268.11h24.684l-1.543-12.342zm-35.48 18.513h49.365v-6.17H613.16v6.17zm21.597-24.683h6.17-6.17z"
          stroke="#0A0B09"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path fill="#F6E667" d="M588.478 191.17h4.658v-55.903h-4.658" />
        <path fill="#FFFFFE" d="M639.722 116.633h-41.927v74.538h51.245v-65.22l-9.318-9.317" />
        <path
          d="M639.722 116.633h-41.927v74.538h51.245v-65.22l-9.318-9.317zm0 0v9.317h9.318l-9.318-9.317zm-51.244 74.538h4.658v-55.903h-4.658v55.904zm2.33-60.56l-2.33 4.657h4.658l-2.33-4.658zm20.963 9.316h23.294-23.293zm0 4.66h23.294-23.293zm0 4.657h23.294-23.293z"
          stroke="#0A0B09"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path fill="#F6E667" d="M296.145 241.884h12.34V62.936h-12.34" />
        <path
          d="M296.145 248.055h12.34V62.935h-12.34v185.12zm6.17-197.46l-6.17 12.34h12.34l-6.17-12.34zm0 12.34v178.95-178.95zm-3.084-6.17h6.17-6.17z"
          stroke="#0E0F11"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path fill="#4CB9B7" d="M277.633 241.884h12.342V81.448h-12.342" />
        <path
          d="M277.633 248.055h12.342V81.448h-12.342v166.607zm6.17-178.948l-6.17 12.34h12.342l-6.17-12.34zm0 12.34v160.437V81.448zm-3.084-6.17h6.17-6.17z"
          stroke="#0E0F11"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M610.41 214.67c0 32.682-26.492 59.174-59.173 59.174-32.682 0-59.175-26.492-59.175-59.174s26.493-59.175 59.175-59.175c32.68 0 59.174 26.493 59.174 59.175"
          fill="#13BBB2"
        />
        <path
          d="M600.55 214.67c0 27.235-22.08 49.312-49.313 49.312-27.236 0-49.313-22.077-49.313-49.312 0-27.235 22.077-49.312 49.313-49.312 27.232 0 49.312 22.077 49.312 49.312"
          fill="#FFFFFE"
        />
        <path
          d="M551.237 254.12c-21.79 0-39.45-17.662-39.45-39.45 0-21.79 17.66-39.45 39.45-39.45 21.783 0 39.45 17.66 39.45 39.45 0 21.788-17.667 39.45-39.45 39.45z"
          fill="#13BBB2"
        />
        <path
          d="M551.237 244.257c-16.342 0-29.588-13.245-29.588-29.587s13.245-29.587 29.587-29.587c16.347 0 29.587 13.245 29.587 29.587s-13.24 29.587-29.587 29.587z"
          fill="#FFFFFE"
        />
        <path
          d="M570.96 214.67c0 10.895-8.825 19.725-19.723 19.725-10.893 0-19.725-8.83-19.725-19.725 0-10.896 8.832-19.725 19.725-19.725 10.898 0 19.724 8.83 19.724 19.725"
          fill="#13BBB2"
        />
        <path
          d="M551.237 224.532c-5.447 0-9.863-4.416-9.863-9.862 0-5.447 4.416-9.863 9.863-9.863 5.45 0 9.862 4.416 9.862 9.863 0 5.446-4.414 9.862-9.863 9.862z"
          fill="#FFFFFE"
        />
        <path
          d="M610.41 214.67c0 32.682-26.492 59.174-59.173 59.174-32.682 0-59.175-26.492-59.175-59.174s26.493-59.175 59.175-59.175c32.68 0 59.174 26.493 59.174 59.175zm-9.86 0c0 27.235-22.08 49.312-49.313 49.312-27.236 0-49.313-22.077-49.313-49.312 0-27.235 22.077-49.312 49.313-49.312 27.232 0 49.312 22.077 49.312 49.312zm-9.864 0c0 21.788-17.666 39.45-39.45 39.45-21.788 0-39.45-17.662-39.45-39.45 0-21.79 17.662-39.45 39.45-39.45 21.784 0 39.45 17.66 39.45 39.45zm-9.862 0c0 16.342-13.24 29.587-29.587 29.587-16.342 0-29.588-13.245-29.588-29.587s13.245-29.587 29.587-29.587c16.347 0 29.587 13.245 29.587 29.587zm-9.863 0c0 10.895-8.825 19.725-19.723 19.725-10.893 0-19.725-8.83-19.725-19.725 0-10.896 8.832-19.725 19.725-19.725 10.898 0 19.724 8.83 19.724 19.725zm-9.86 0c0 5.446-4.414 9.862-9.863 9.862-5.447 0-9.863-4.416-9.863-9.862 0-5.447 4.416-9.863 9.863-9.863 5.45 0 9.862 4.416 9.862 9.863zm-9.863 0h98.624-98.623zm78.9 0l9.86-9.863-9.86 9.863zm9.86 0l9.864-9.863L640 214.67zm-9.86 0l9.86 9.862-9.86-9.862zm9.86 0l9.864 9.862L640 214.67z"
          stroke="#0E0F11"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M259.122 235.713V99.96c0-3.41 2.76-6.17 6.17-6.17h191.29c3.41 0 6.17 2.76 6.17 6.17v135.753"
          fill="#454543"
        />
        <path
          d="M265.292 235.713H234.44v-1.542 1.543c0 6.822 5.52 12.342 12.34 12.342h123.413-18.512 123.413c6.82 0 12.34-5.52 12.34-12.342v-1.542 1.543h-30.852"
          fill="#CDCCC7"
        />
        <path fill="#FFFFFE" d="M265.292 229.543h191.29V99.96H265.29" />
        <path
          d="M265.292 229.543h191.29V99.96H265.29v129.583zm197.46 6.17h-203.63 203.63zm-203.63 0V99.96c0-3.41 2.76-6.17 6.17-6.17h191.29c3.41 0 6.17 2.76 6.17 6.17v135.753h-203.63zm6.17 0H234.44c0 6.822 5.52 12.342 12.34 12.342h123.413-18.512 123.413c6.82 0 12.34-5.52 12.34-12.342H265.294zm74.047 6.17h43.194v-6.17H339.34v6.17zm-74.048-80.217v49.365-49.364z"
          stroke="#0A0B09"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path fill="#AEAFAB" d="M265.292 211.03h61.706v-49.364h-61.706" />
        <path fill="#CDCCC7" d="M518.287 127.728l-6.17-3.086v30.853l6.17-3.085v-24.682" />
        <path
          fill="#454543"
          d="M345.51 149.325h6.17v-18.512h-6.17M505.946 192.52h6.17V87.62h-6.17"
        />
        <path
          fill="#CDCCC7"
          d="M505.946 90.704v98.73l-86.39-33.94v-30.852l86.39-33.938M376.363 155.495h24.683l-9.256 37.024h-24.683l9.256-37.025"
        />
        <path
          d="M419.557 155.495l86.39 33.94-86.39-33.94zm0-30.853l86.39-33.938-86.39 33.938zm-18.51 30.853l-9.257 37.024 9.256-37.025zm104.9-64.79v98.73-98.73zm0 101.814h6.17V87.62h-6.17v104.9zm12.34-64.792l-6.17-3.086v30.853l6.17-3.085v-24.682zm-166.606 12.34h-6.17 6.17z"
          stroke="#0A0B09"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M339.34 204.86h6.17c6.17 0 12.34 6.17 18.512 6.17h33.938c3.408 0 6.17-2.762 6.17-6.17 0-3.407-2.762-6.17-6.17-6.17h3.086c3.407 0 6.17-2.763 6.17-6.17 0-3.41-2.763-6.172-6.17-6.172h3.085c3.41 0 6.17-2.762 6.17-6.17s-2.76-6.17-6.17-6.17h3.086c3.408 0 6.17-2.764 6.17-6.17 0-3.41-2.762-6.172-6.17-6.172h-30.853v-6.17h-12.34c-12.342 0-12.342 12.34-18.513 12.34h-6.17"
          fill="#F6E667"
        />
        <path
          d="M265.292 211.03h61.706v-49.364h-6.17l-55.536 49.365zm61.706 0h12.34v-49.364H327v49.365zm0-37.023h12.34H327zm6.17 6.17v6.17-6.17zm-12.34-18.51h-55.536 55.536zm18.51 43.193h6.172c6.17 0 12.34 6.17 18.512 6.17h33.938c3.408 0 6.17-2.762 6.17-6.17 0-3.407-2.762-6.17-6.17-6.17h3.086c3.407 0 6.17-2.763 6.17-6.17 0-3.41-2.763-6.172-6.17-6.172h3.085c3.41 0 6.17-2.762 6.17-6.17s-2.76-6.17-6.17-6.17h3.086c3.408 0 6.17-2.764 6.17-6.17 0-3.41-2.762-6.172-6.17-6.172H374.82l-35.48 43.194zm24.684-49.365c-12.34 0-12.34 12.342-18.512 12.342l18.512-12.342zm-24.683 12.342h6.17-6.17zm35.48-6.17l1.543-6.172-1.543 6.172z"
          stroke="#0A0B09"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path fill="#13BBB2" d="M351.68 155.495h67.877v-30.853H351.68" />
        <path
          d="M351.68 155.495h67.877v-30.853H351.68v30.853zm-6.17-6.17h6.17v-18.512h-6.17v18.512zm37.024-18.512v18.512-18.512zm-6.17 0v18.512-18.512zm12.34 0v18.512-18.512zm6.17 0v18.512-18.512zm24.683 9.256h86.39-86.39zm114.157 0h6.17-6.17zm-3.086-9.257l6.17-1.543-6.17 1.543zm0 18.512l6.17 1.542-6.17-1.542z"
          stroke="#0A0B09"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g>
          <path
            d="M126.904 144.025c0 13.632 11.053 24.683 24.682 24.683 13.63 0 24.683-11.05 24.683-24.683 0-4.503-1.22-8.712-3.333-12.34h-42.7c-2.114 3.628-3.333 7.837-3.333 12.34z"
            fill="#13BBB2"
          />
          <path
            d="M157.757 120.152V76.15h-12.34v44.002c-10.646 2.744-18.513 12.377-18.513 23.873 0 13.632 11.053 24.683 24.682 24.683 13.63 0 24.683-11.05 24.683-24.683 0-11.496-7.877-21.13-18.513-23.873zM142.33 76.15h18.512-18.512zm3.086 37.022h3.085-3.084zm0-6.17h3.085-3.084zm0-6.17h3.085-3.084zm0-6.172h3.085-3.084zm0-6.17h3.085-3.084zm0-6.17h3.085-3.084zm-15.18 49.364h42.7-42.7z"
            stroke="#0E0F11"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M145.416 147.11c0 1.705-1.38 3.086-3.086 3.086-1.704 0-3.085-1.38-3.085-3.085 0-1.703 1.38-3.085 3.085-3.085s3.086 1.382 3.086 3.086zm12.34 6.17c0 1.705-1.38 3.087-3.084 3.087-1.705 0-3.086-1.382-3.086-3.086 0-1.703 1.38-3.084 3.086-3.084 1.704 0 3.085 1.38 3.085 3.085zm6.17-12.34c0 1.704-1.38 3.085-3.084 3.085-1.704 0-3.085-1.38-3.085-3.085 0-1.704 1.38-3.085 3.085-3.085s3.085 1.38 3.085 3.085z"
            stroke="#FFFFFE"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            fill="#FFFFFE"
            d="M523.686 42.882V83.03h13.383v6.69l6.69-6.69h40.147V42.88h-60.22"
          />
          <path
            stroke="#0A0B09"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M523.686 42.882V83.03h13.383v6.69l6.69-6.69h40.147V42.88z"
          />
          <path
            fill="#FFFFFE"
            d="M617.363 89.72v40.148H603.98v6.69l-6.69-6.69h-40.148V89.72h60.22"
          />
          <path
            stroke="#0A0B09"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M617.363 89.72v40.148H603.98v6.69l-6.69-6.69h-40.148V89.72z"
          />
          <path
            fill="#13BBB2"
            d="M563.834 123.176h46.838V96.41h-46.838M530.378 76.338h46.838V49.573h-46.838"
          />
          <path
            d="M530.378 76.338h46.838V49.573h-46.838v26.765zm46.838-26.765L553.796 66.3 530.38 49.574h46.838zM558.48 62.955l18.736 13.383-18.737-13.383zm-28.102 13.383l18.735-13.383-18.735 13.383zm33.456 46.838h46.838V96.41h-46.838v26.766zm20.073-16.728v6.69l6.692-3.344-6.693-3.346z"
            stroke="#0A0B09"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M524.523 96.41c2.76 10.334 11.54 18.195 22.362 19.604M616.306 75.867c-2.76-10.332-11.54-18.193-22.362-19.603"
            stroke="#0A0B09"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="0,8"
          />
        </g>
        <path
          d="M259.01 155.4h-39.525c-3.41 0-6.17 2.76-6.17 6.17v95.645c0 1.704-1.382 3.086-3.086 3.086-1.705 0-3.086-1.38-3.086-3.085v-77.132c0-5.112-4.145-9.256-9.256-9.256-5.112 0-9.256 4.144-9.256 9.256v21.597c0 1.704-1.38 3.085-3.085 3.085-1.704 0-3.086-1.38-3.086-3.085v-9.256c0-3.412-2.758-6.17-6.17-6.17-3.41 0-6.17 2.758-6.17 6.17v64.79c0 8.527-6.9 15.426-15.426 15.426-8.527 0-15.427-6.9-15.427-15.426v-80.217c0-1.703-1.38-3.085-3.085-3.085-1.704 0-3.085 1.382-3.085 3.085v77.133c0 3.41-2.76 6.17-6.17 6.17-3.412 0-6.172-2.76-6.172-6.17v-27.768c0-5.11-4.144-9.256-9.256-9.256s-9.257 4.145-9.257 9.256v9.256c0 6.822-5.52 12.34-12.34 12.34"
          stroke="#0A0B09"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g>
          <path
            fill="#4A90E2"
            d="M117.826 117.284H74.632l-12.34 15.426 12.34 15.427h43.194v-30.853"
          />
          <g strokeWidth="4" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="#0A0B09" d="M117.826 116.284H74.632l-12.34 15.426 12.34 15.427h43.194z" />
            <path
              d="M86.973 138.454l16-16-16 16zm16-2.666c0 1.472-1.194 2.666-2.667 2.666-1.472 0-2.666-1.194-2.666-2.666 0-1.473 1.194-2.667 2.666-2.667 1.473 0 2.667 1.195 2.667 2.668zM92.306 125.12c0 1.474-1.194 2.668-2.666 2.668-1.473 0-2.667-1.194-2.667-2.667 0-1.472 1.194-2.666 2.667-2.666 1.472 0 2.666 1.194 2.666 2.667z"
              stroke="#FFFFFE"
            />
          </g>
        </g>
        <path fill="#666763" d="M367.75 85.968h-6.17l-3.085-6.17h12.34l-3.084 6.17" />
        <path fill="#AEAFAB" d="M354.846 79.154h18.512V60.642h-18.512" />
        <path
          d="M373.278 60.535c0-7.407 3.085-7.9 8.198-13.4 4.465-4.467 7.228-10.638 7.228-17.453C388.704 16.052 377.656 5 364.022 5c-13.63 0-24.683 11.05-24.683 24.682 0 6.815 2.762 12.986 7.23 17.454 5.11 5.5 8.196 5.992 8.196 13.4"
          fill="#F6E667"
        />
        <path
          d="M354.766 79.047h18.512V60.535h-18.512v18.512zm18.512-18.512c0-7.407 3.085-7.9 8.198-13.4 4.465-4.467 7.228-10.638 7.228-17.453C388.704 16.052 377.656 5 364.022 5c-13.63 0-24.683 11.05-24.683 24.682 0 6.815 2.762 12.986 7.23 17.454 5.11 5.5 8.196 5.992 8.196 13.4h18.512zM357.85 23.51h12.343-12.342zm12.343 0l-6.17 37.025 6.17-37.024zm-6.17 37.025L357.85 23.51l6.172 37.025zm-9.257 6.17h18.512-18.512z"
          stroke="#0E0F11"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M354.846 73.412h18.512" fill="#FFFFFE" />
        <path
          d="M354.846 73.412h18.512-18.512zm12.34 12.34h-6.17l-3.084-6.17h12.34l-3.085 6.17z"
          stroke="#0E0F11"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M345.725 29.896c0-10.223 8.288-18.51 18.51-18.51"
          stroke="#FFFFFE"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M172.662 155.366h82.523"
          stroke="#0A0B09"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g>
          <path
            d="M227.034 90.303c0 13.63-11.05 24.682-24.682 24.682-13.632 0-24.683-11.05-24.683-24.682 0-13.632 11.05-24.683 24.682-24.683 13.632 0 24.682 11.05 24.682 24.683"
            fill="#FFFFFE"
          />
          <path
            d="M211.606 87.217c0 5.11-4.144 9.255-9.255 9.255-5.11 0-9.254-4.144-9.254-9.255 0-5.112 4.143-9.255 9.255-9.255 5.112 0 9.256 4.143 9.256 9.255M202.547 96.473c-7.767 0-14.417 4.785-17.165 11.566l7.23 4.858 9.934 2.087 9.256-1.797 7.802-5.41c-2.81-6.643-9.39-11.305-17.057-11.305"
            fill="#13BBB2"
          />
          <path
            d="M227.034 90.303c0 13.63-11.05 24.682-24.682 24.682-13.632 0-24.683-11.05-24.683-24.682 0-13.632 11.05-24.683 24.682-24.683 13.632 0 24.682 11.05 24.682 24.683zm-15.428-3.086c0 5.11-4.144 9.255-9.255 9.255-5.11 0-9.254-4.144-9.254-9.255 0-5.112 4.143-9.255 9.255-9.255 5.112 0 9.256 4.143 9.256 9.255zm-26.42 20.822c2.748-6.782 9.398-11.567 17.166-11.567 7.667 0 14.245 4.662 17.056 11.305l-34.222.26z"
            stroke="#0A0B09"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M114.884 201.775c0 1.704-1.382 3.085-3.086 3.085s-3.085-1.38-3.085-3.085c0-1.704 1.38-3.085 3.085-3.085 1.704 0 3.086 1.38 3.086 3.085"
          fill="#FFFFFE"
        />
        <g>
          <path
            d="M92.695 228.543c0 24.538-19.903 44.44-44.44 44.44-24.548 0-44.442-19.902-44.442-44.44 0-24.548 19.894-44.442 44.44-44.442 24.54 0 44.442 19.895 44.442 44.443"
            fill="#4CB9B7"
          />
          <path
            d="M92.695 228.543c0 24.538-19.903 44.44-44.44 44.44-24.548 0-44.442-19.902-44.442-44.44 0-24.548 19.894-44.442 44.44-44.442 24.54 0 44.442 19.895 44.442 44.443zM48.255 184.1v88.884V184.1zm22.22 44.443c0 24.538-9.95 44.44-22.22 44.44-12.274 0-22.222-19.902-22.222-44.44 0-24.548 9.948-44.442 22.22-44.442 12.273 0 22.222 19.895 22.222 44.443zm22.22 0H3.813h88.882z"
            stroke="#0E0F11"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.952 186.617c0 5.112-9.256 21.597-9.256 21.597S8.44 191.73 8.44 186.617c0-5.112 4.145-9.256 9.256-9.256 5.112 0 9.256 4.145 9.256 9.257"
            fill="#DE485E"
          />
          <path
            d="M20.782 186.617c0 1.704-1.382 3.085-3.086 3.085-1.703 0-3.085-1.38-3.085-3.085 0-1.704 1.383-3.085 3.086-3.085 1.704 0 3.086 1.38 3.086 3.085z"
            stroke="#0E0F11"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.952 186.617c0 5.112-9.256 21.597-9.256 21.597S8.44 191.73 8.44 186.617c0-5.112 4.145-9.256 9.256-9.256 5.112 0 9.256 4.145 9.256 9.257z"
            stroke="#0E0F11"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g>
            <path
              d="M36.208 226.726c0 5.112-9.256 21.597-9.256 21.597s-9.256-16.485-9.256-21.597c0-5.112 4.145-9.256 9.256-9.256 5.112 0 9.256 4.144 9.256 9.256"
              fill="#DE485E"
            />
            <path
              d="M30.038 226.726c0 1.704-1.382 3.085-3.086 3.085-1.703 0-3.085-1.38-3.085-3.084 0-1.704 1.382-3.085 3.085-3.085 1.704 0 3.086 1.382 3.086 3.086z"
              stroke="#0E0F11"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M36.208 226.726c0 5.112-9.256 21.597-9.256 21.597s-9.256-16.485-9.256-21.597c0-5.112 4.145-9.256 9.256-9.256 5.112 0 9.256 4.144 9.256 9.256z"
              stroke="#0E0F11"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g>
            <path
              d="M79.403 207.443c0 5.11-9.256 21.597-9.256 21.597s-9.256-16.486-9.256-21.597c0-5.112 4.145-9.256 9.257-9.256 5.11 0 9.256 4.144 9.256 9.256"
              fill="#DE485E"
            />
            <path
              d="M73.232 207.443c0 1.704-1.382 3.085-3.085 3.085-1.704 0-3.086-1.38-3.086-3.085 0-1.704 1.383-3.085 3.087-3.085 1.703 0 3.085 1.38 3.085 3.085z"
              stroke="#0E0F11"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M79.403 207.443c0 5.11-9.256 21.597-9.256 21.597s-9.256-16.486-9.256-21.597c0-5.112 4.145-9.256 9.257-9.256 5.11 0 9.256 4.144 9.256 9.256z"
              stroke="#0E0F11"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g>
            <path
              d="M67.06 239.067c0 5.112-9.255 21.597-9.255 21.597s-9.255-16.485-9.255-21.597c0-5.11 4.144-9.256 9.255-9.256 5.112 0 9.256 4.146 9.256 9.257"
              fill="#DE485E"
            />
            <path
              d="M60.89 239.067c0 1.704-1.38 3.085-3.085 3.085-1.703 0-3.085-1.38-3.085-3.085 0-1.704 1.382-3.085 3.085-3.085 1.704 0 3.086 1.38 3.086 3.085z"
              stroke="#0E0F11"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M67.06 239.067c0 5.112-9.255 21.597-9.255 21.597s-9.255-16.485-9.255-21.597c0-5.11 4.144-9.256 9.255-9.256 5.112 0 9.256 4.146 9.256 9.257z"
              stroke="#0E0F11"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <g>
          <path
            d="M107.684 182.202c0 1.702-1.38 3.085-3.085 3.085-1.703 0-3.086-1.383-3.086-3.085 0-1.706 1.383-3.085 3.085-3.085 1.704 0 3.084 1.38 3.084 3.085"
            fill="#AEAFAB"
          />
          <path
            d="M102.91 170.907c-6.817 0-12.342 5.526-12.342 12.34v18.513c0 6.816 5.525 12.34 12.34 12.34 6.816 0 12.342-5.524 12.342-12.34v-18.512c0-6.815-5.526-12.34-12.34-12.34"
            fill="#FFFFFE"
          />
          <path
            d="M102.91 167.742c-6.817 0-12.342 5.525-12.342 12.34v18.513c0 6.815 5.525 12.34 12.34 12.34 6.816 0 12.342-5.525 12.342-12.34v-18.512c0-6.816-5.526-12.34-12.34-12.34zm3.084 12.34c0 1.703-1.38 3.086-3.085 3.086-1.704 0-3.087-1.383-3.087-3.085 0-1.706 1.383-3.086 3.086-3.086 1.704 0 3.084 1.38 3.084 3.086zm-3.085-18.51V155.4v6.17zm6.17 0l1.156-3.086-1.157 3.085zm-12.342 0l-1.157-3.086 1.158 3.085z"
            stroke="#0E0F11"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M121.054 229.285l-9.256 18.512v6.17c0 3.412 2.76 6.172 6.17 6.172h30.854c3.41 0 6.17-2.76 6.17-6.172v-6.17l-9.255-18.513h-24.683"
            fill="#13BBB2"
          />
          <path
            d="M127.225 216.944h3.085m-3.085-6.17h3.085m-3.085-6.17h3.085m-3.085-6.172h3.085m-3.085-6.17h3.085m-3.085-6.17h3.085m-3.085-6.17h3.085"
            stroke="#0E0F11"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M136.48 240.084c0 1.704-1.38 3.085-3.085 3.085-1.703 0-3.085-1.382-3.085-3.086s1.382-3.085 3.085-3.085c1.704 0 3.086 1.38 3.086 3.084zm-10.798 5.018c0 1.704-1.38 3.086-3.085 3.086-1.704 0-3.085-1.382-3.085-3.086s1.38-3.085 3.085-3.085c1.704 0 3.085 1.38 3.085 3.085zm20.88 2.057c0 1.703-1.38 3.084-3.084 3.084-1.704 0-3.085-1.38-3.085-3.085s1.38-3.087 3.085-3.087c1.704 0 3.085 1.382 3.085 3.086z"
            stroke="#FFFFFE"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M124.14 173.75h18.51-18.51zm3.085 43.194V173.75h12.34v43.194l15.428 30.853v6.17c0 3.412-2.76 6.172-6.17 6.172h-30.854c-3.412 0-6.172-2.76-6.172-6.172v-6.17l15.427-30.854zm-6.17 12.34h24.682-24.683z"
            stroke="#0E0F11"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M250.924 156.78v-3.332l-6.97-1.495c-.154-.78-.362-1.536-.62-2.273l5.298-4.785-1.665-2.885-6.797 2.194c-.514-.592-1.068-1.145-1.662-1.66l2.198-6.795-2.887-1.67-4.786 5.3c-.74-.255-1.494-.467-2.274-.618l-1.494-6.97h-3.33l-1.495 6.97c-.78.15-1.535.363-2.273.617l-4.785-5.3-2.886 1.667 2.196 6.796c-.592.516-1.147 1.07-1.66 1.66l-6.797-2.193-1.667 2.885 5.297 4.785c-.254.737-.465 1.494-.614 2.273l-6.973 1.493v3.334l6.974 1.494c.15.78.36 1.536.615 2.272l-5.297 4.787 1.667 2.886 6.797-2.197c.513.592 1.067 1.146 1.66 1.66l-2.198 6.798 2.888 1.665 4.785-5.297c.737.256 1.494.467 2.274.616l1.495 6.974h3.33l1.495-6.974c.78-.15 1.538-.36 2.274-.616l4.785 5.297 2.886-1.665-2.198-6.797c.594-.514 1.148-1.068 1.666-1.66l6.793 2.194 1.665-2.884-5.3-4.785c.26-.737.468-1.493.62-2.274l6.972-1.494"
            fill="#FFFFFE"
          />
          <path
            d="M250.924 156.78v-3.33l-6.97-1.497c-.154-.78-.362-1.536-.62-2.273l5.298-4.785-1.665-2.885-6.797 2.194c-.514-.592-1.068-1.145-1.662-1.66l2.198-6.795-2.887-1.67-4.786 5.3c-.74-.255-1.494-.467-2.274-.618l-1.494-6.97h-3.33l-1.495 6.97c-.78.15-1.535.363-2.273.617l-4.785-5.3-2.886 1.667 2.196 6.796c-.592.516-1.147 1.07-1.66 1.66l-6.797-2.193-1.667 2.885 5.297 4.785c-.254.737-.465 1.494-.614 2.273l-6.973 1.493v3.334l6.974 1.494c.15.78.36 1.536.615 2.272l-5.297 4.787 1.667 2.886 6.797-2.197c.513.592 1.067 1.146 1.66 1.66l-2.198 6.798 2.888 1.666 4.785-5.298c.737.256 1.494.467 2.274.617l1.495 6.973h3.33l1.495-6.973c.78-.15 1.538-.36 2.274-.617l4.785 5.298 2.886-1.666-2.198-6.796c.594-.515 1.148-1.07 1.666-1.66l6.793 2.193 1.665-2.884-5.3-4.785c.26-.737.468-1.493.62-2.274l6.972-1.494z"
            stroke="#0E0F11"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M233.785 148.93c-1.582-1.583-3.768-2.562-6.184-2.562v8.747l6.185-6.186"
            fill="#4CB9B7"
          />
          <path
            d="M233.785 148.92l-6.184 6.184 6.185 6.186c1.582-1.583 2.562-3.768 2.562-6.185s-.98-4.604-2.562-6.186"
            fill="#DE485E"
          />
          <path
            d="M227.6 146.368c-4.832 0-8.746 3.913-8.746 8.748 0 4.833 3.914 8.746 8.747 8.746 2.417 0 4.603-.98 6.185-2.56l-6.184-6.187v-8.747"
            fill="#F6E667"
          />
          <path
            d="M236.347 155.116c0 4.833-3.914 8.746-8.746 8.746-4.832 0-8.746-3.913-8.746-8.746 0-4.835 3.914-8.748 8.747-8.748s8.747 3.913 8.747 8.748zm-8.746 0v-8.748 8.747zm0 0l6.145-6.146-6.144 6.145zm0 0l6.17 6.17-6.17-6.17z"
            stroke="#0E0F11"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path fill="#FFF" d="M182.696 246.723h43.784v-29.19h-43.784" />
          <path
            d="M182.696 246.723h43.784v-29.19h-43.784v29.19zm43.784-29.19L204.588 236.3l-21.892-18.766h43.784zm-43.784 29.19l17.026-14.595-17.026 14.595zm26.757-14.595l17.027 14.595-17.027-14.595z"
            stroke="#0E0F11"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}
