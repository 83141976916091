import React, { createRef, createContext, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './AdBlockContext.module.scss';

const { Provider, Consumer } = createContext();

/**
 * This context is super easy to use, and will let you know if an ad blocker is in use
 * by the visitor. Wrapping a component will add a boolean prop called 'adBlockEnabled'
 *
 * The context provider is already placed at the root level of abm, in app.js, so
 * to use this you only need to wrap whatever component you want to use with the
 * consumer. Consumer is exported by default, so you just need to wrap your component:
 *
 * example:
 * import { AdBlockContext as AdBlockConsumer } from 'contexts';
 * const MyComponent = (props) => {...}
 * export default AdBlockConsumer(MyComponent);
 */
class AdBlockProvider extends Component {
  static propTypes = { children: PropTypes.any }
  constructor() {
    super();
    this.adBlockRef = createRef();
  }

  render() {
    return (
      <Fragment>
        <Provider value={{ enabled: this.adBlockRef }}>
          {this.props.children}
        </Provider>
        <div className={styles.adContainer} ref={this.adBlockRef}>
          <div className={`banner_ad ${styles.ad}`} />
        </div>
      </Fragment>
    );
  }
}

const AdBlockConsumer = (WrappedComponent) => (props) => (
  <Consumer>
    {({ enabled }) =>
      <WrappedComponent
        {...props}
        adBlockEnabled={enabled.current.clientHeight !== 1}
      />
    }
  </Consumer>
);

AdBlockConsumer.AdBlockProvider = AdBlockProvider;

export default AdBlockConsumer;
