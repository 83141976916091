import { Flex, Text } from '@sixsense/core/components';
import { get } from 'lodash';
import React, { memo } from 'react';
import { DesignSystemThemeHoc } from '../../DesignSystemThemeHOC';

const webText = 'Activities is a set of pre-defined webpage categories.';
const defaultText = `Action & Channels are set of pre-defined actions 
performed by users through pre-defined channels. They always go in combination.`;

const AnnotationNode = (props) => {

  const dataSource = get(props, 'data.dataSource');

  return (
    <React.Fragment>
      <DesignSystemThemeHoc>
        <Flex style={{ textWrap: 'wrap', width: '400px' }}>
          <Text.Body>{dataSource === 'web' ? webText : defaultText}</Text.Body>
        </Flex>
      </DesignSystemThemeHoc>
    </React.Fragment>
  );
};

export default memo(AnnotationNode);
