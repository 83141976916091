import {
  NULLEXPR,
  BINARY_EXPRESSIONS,
  NEGATIVE_BINARY_EXPRESSIONS,
  LOGICAL_EXPRESSIONS,
} from 'ast-redux/constants';
import { TLoc } from 'ast-redux/zipper';
import { includes } from 'lodash';

/* Generic type verification */
const not = (func) => (loc) => !func(loc);
const isNull = (node) => !node || node.type === NULLEXPR;
const isA = (...types) => (loc) => includes(types, TLoc(loc).node().type);
const isNot = (...types) => (loc) => !isA(...types)(TLoc(loc));
const isBin = (loc) => BINARY_EXPRESSIONS.has(TLoc(loc).node().type);
const isNegBin = (loc) => NEGATIVE_BINARY_EXPRESSIONS.has(TLoc(loc).node().type);
const isLogical = (loc) => LOGICAL_EXPRESSIONS.has(TLoc(loc).node().type);
const isBinOrNegBin = (loc) => isBin(loc) || isNegBin(loc);

export {
  not,
  isNull,
  isA,
  isNot,
  isBin,
  isNegBin,
  isBinOrNegBin,
  isLogical,
};
