import { Role, User } from './types';
import { DUMMY_SELECT_VALUE } from './constants';
import { isEqual, map, filter } from 'lodash';


const getRole = (user: any, roles, appId: number) => {
  if (user && user.roles && user.roles.length > 0) {
    const filteredRoleByAppId = user.roles.filter(({ app_id }) => {
      if (app_id === appId) {
        return true;
      }
      return false;
    });
    if (filteredRoleByAppId && filteredRoleByAppId.length > 0) {
      const role_id = filteredRoleByAppId[0].role_id;
      return roles.find(({ id }) => id === role_id);
    }
  }
  return null;
};

function getRoleNameByAppId(orgUser, roles, appId) {
  const role = getRole(orgUser, roles, appId);
  if (role) {
    return role.name;
  }
  return '-';
}

function getRoleIdByAppId(orgUser, roles, appId) {
  const role = getRole(orgUser, roles, appId);
  if (role) {
    return role.id;
  }
  return -1;
}

function getRoleIdMap(orgUser, roles, appsList) {
  const roleIdMap = {};
  for (const { id } of appsList) {
    roleIdMap[id] = getRoleIdByAppId(orgUser, roles, id);
  }
  return roleIdMap;
}

function getRoleNameMap(orgUser, roles, appsList) {
  const roleNameMap = {};
  for (const { id } of appsList) {
    roleNameMap[id] = getRoleNameByAppId(orgUser, roles, id);
  }
  return roleNameMap;
}

function getRoleLicenseLabel(orgUser, roles, appId) {
  const role = getRole(orgUser, roles, appId);
  if (role) {
    return role.licenseLabel;
  }
  return null;
}

function getRoleLicenseLabelMap(orgUser, roles, appsList) {
  const roleIdMap = {};
  for (const { id } of appsList) {
    roleIdMap[id] = getRoleLicenseLabel(orgUser, roles, id);
  }
  return roleIdMap;
}

export const isStatusExpiredOrDisabled = (status) =>
  status === 'inviteExpired' || status === 'disabled';

const isAnyOneStatusExpiredOrDisabled = (...args) =>
  args.some((roleIdValue) => isStatusExpiredOrDisabled(roleIdValue));

const isRoleIdValid = (roleId) =>
  roleId !== -1 &&
  roleId !== undefined &&
  roleId !== null &&
  roleId !== DUMMY_SELECT_VALUE;
export const isAnyOneRoleIdValid = (...args: any[]) =>
  args.some((roleIdValue) => isRoleIdValid(roleIdValue));

export const isPossibleNewExtraLicenceStatusListAndRoleIdsList = (
  status: any[],
  roleIds: any[]
) =>
  isAnyOneStatusExpiredOrDisabled(...status) && isAnyOneRoleIdValid(...roleIds);

export const formatOrgUser = (
  orgUser: any,
  roles: Role[],
  appsList: any[]
): User => ({
  id: orgUser.id,
  username: orgUser.user.username || orgUser.user.email,
  email: orgUser.user.email || orgUser.user.username,
  status: orgUser.status,
  firstName: orgUser.first_name || orgUser.user.first_name,
  lastName: orgUser.last_name || orgUser.user.last_name,
  title: orgUser.title,
  roleNameMap: getRoleNameMap(orgUser, roles, appsList),
  roleIdMap: getRoleIdMap(orgUser, roles, appsList),
  roleLicenseLabelMap: getRoleLicenseLabelMap(orgUser, roles, appsList),
  invitedBy: orgUser.invited_by,
  invitedDate: orgUser.invite_date,
  lastLogin: orgUser.last_login,
  userId: orgUser.user.id,
});

export const toApiUser = (user: User): any => ({
  username: user.username,
  email: user.email,
  organizationUserId: user.id,
});

export const isTwoArrayEqualWithPrimitiveTypeData = (array1, array2) =>
  [...array1].sort().join(',') === [...array2].sort().join(',');

export const isTwoObjectEqualWithPrimitiveTypeData = (obj1, obj2) =>
  isEqual(obj1, obj2);

export const reduceAppListToObject = (
  appsList,
  getAppIdValue: (appId: number | string) => any
) =>
  appsList.reduce(
    (obj, { id: appId }) => ({ ...obj, [appId]: getAppIdValue(appId) }),
    {}
  );


// filter the disabled roles (except selected role so that we don't show numberic value)
// and use upper case for group label options.
export const refiningRoleOptions = (
  roles: {
    value: string;
    disabled: boolean;
    options: any[];
    label: string;
  }[],
  selectedValue: string | number) => filter(map(roles, (role) => {
    if (role.options) {
      return {
        ...role,
        label: role.label.toUpperCase(),
        options: filter(
          role.options, ({ disabled, value }) => !disabled || selectedValue === value),
      };
    }
    return {
      ...role,
    };
  }), ({ disabled, value }) => !disabled || selectedValue === value);
