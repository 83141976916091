import React from 'react';
import Text from 'v2-components/Text';
import { localeString } from 'utils/utils';
import { NA } from './common/NA';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';
import { isDraftOrScheduledCampaign } from '../../../utils';

export const IMPRESSIONS = {
  sortByKey: 'media_impressions',
  customizable: true,
  key: 'impressions',
  csvColumnKey: 'impressions',
  defaultSelected: true,
  title: <Label name={CAMPAIGN_LABELS.impressions} />,
  dataIndex: 'campaign_data.media_impressions',
  width: 110,
  render: (impressions, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (isDraftOrScheduledCampaign(campaignStatus)) {
      return (<NA />);
    }

    return (<Text
      type={Text.TYPE.SUBBODY}
    >
      {localeString(impressions)}
    </Text>);
  },
  csvOverrides: {
    dataIndex: 'download_stats.media_impressions',
    render: (impressions, campaign) => {
      const campaignStatus = campaign.campaign_status;
      if (isDraftOrScheduledCampaign(campaignStatus)) {
        return '-';
      }

      return `"${localeString(parseInt(impressions) || 0)}"`;
    },
  },
};
