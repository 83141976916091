import React from 'react';
import PropTypes from 'prop-types';
import styles from './ActivityHeader.module.scss';
import {
  Tabs,
  Radio,
  Checkbox,
  Text,
} from 'v2-components';
import { allViewTabs, colorMap } from './constants';
import { Row, Col } from 'react-flexbox-grid';
import { LocalState } from 'HOCS';

const DropdownLabel = ({ label, color }) => (
  <div className={styles.dropdownLabel}>
    <div
      className={styles.legendCircle}
      style={{ border: `2px solid ${color}` }}
    />
    <Text>
      {label}
    </Text>
  </div>
);

DropdownLabel.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
};

const HeaderComponent = (props) => {
  const {
    selectedViewTab,
    selectedTab,
    viewAction,
    rollUpAction,
    visibleSeries,
    visibleSeriesAction,
    menuVisible,
    handleChange,

    tabOptions,
    tabs,
  } = props;

  const individualDropdownMenu = (
    <div className={styles.dropdownMenu}>
      {
        tabs.slice(1).map((item) => (
          <div
            className={styles.dropdownItem}
            key={item.value}
          >
            <Checkbox
              key={item.value}
              checked={visibleSeries.has(item.value)}
              onChange={(e) => {
                visibleSeriesAction(item.value);
                e.stopPropagation();
              }}
            />
            <DropdownLabel
              color={colorMap[item.value]}
              label={item.label}
            />
          </div>
        ))
      }
    </div>
  );

  const rollUpRadio = (
    <Radio
      selected={selectedViewTab === allViewTabs[0].value}
      item={allViewTabs[0]}
      key="rollUpRadio"
      action={viewAction}
    />
  );

  const individualRadio = (
    <Radio
      selected={selectedViewTab === allViewTabs[1].value}
      item={allViewTabs[1]}
      action={viewAction}
      key="IndividualRadio"
      isDropdown
      dropdownVisible={menuVisible}
      handleVisibleChange={(isVisible) => handleChange(isVisible)}
      dropdownMenu={individualDropdownMenu}
      dropdownPlacement="bottomRight"
    />
  );

  return (
    <Col xs={12} className={styles.header}>
      <Row className={styles.headerTabs} middle="xs">
        <Col xs={10}>
          <Tabs
            items={tabs}
            action={rollUpAction}
            selected={selectedTab}
          />
        </Col>
      </Row>
      {
        selectedTab === tabOptions.all ?
          <Row end="xs" className={styles.headerRadios}>
            {[
              rollUpRadio,
              individualRadio,
            ]}
          </Row> : null
      }
    </Col>
  );
};

HeaderComponent.propTypes = {
  viewAction: PropTypes.func,
  rollUpAction: PropTypes.func,
  selectedViewTab: PropTypes.string,
  selectedTab: PropTypes.string,
  visibleSeries: PropTypes.object,
  visibleSeriesAction: PropTypes.func,
  menuVisible: PropTypes.bool,
  handleChange: PropTypes.func,

  tabOptions: PropTypes.object,
  tabs: PropTypes.arrayOf(PropTypes.object),
};

export default LocalState({
  localState: {
    menuVisible: false,
  },
  changeToState: (menuVisible) => ({ menuVisible }),
})(HeaderComponent);
