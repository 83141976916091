import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';
import { actions as globalActions } from 'modules/global';
import { adEndpointSelector, baseSelector, createAdPayloadSelector } from './selectors';
import { ACTION_PREFIX } from './constants';

const { showNotification } = globalActions;


export const createAdStateGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: `${ACTION_PREFIX}/CREATE_AD`,
  endpointSelector: adEndpointSelector,
  stateKey: 'createAd',
  method: 'POST',
  requestOptions: createAdPayloadSelector,
  onFailureActions: [
    () => showNotification(
      'error',
      'Failed to create ad. Please try again',
    ),
  ],
});
