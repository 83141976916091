import { fetchSaga } from 'store/sagas';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { actions, actionTypes } from '.';
import { orgSelector } from 'modules/user/selectors';
import { actions as globalActions } from 'modules/global';

const { showNotification } = globalActions;

const {
  REQUEST_INTEGRATION,
  REQUEST_INTEGRATION_STATE,
  REFRESH_TOKEN,
  REMOVE_OUTREACH_TOKEN,
} = actionTypes;
const {
  requestIntegrationSuccess,
  requestIntegrationError,
  requestTokenStateError,
  requestTokenStateSuccess,
  refreshTokenSucess,
  refreshTokenError,
  requestTokenState,
} = actions;

function* requestIntegrationSaga(request) {
  const orgId = yield select(orgSelector);
  const endpoint = `outreach/${orgId}/request_authorization`;
  try {
    const resp = yield call(request, endpoint, 'POST');
    const redirect_url = resp.redirect_url;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isSafari = userAgent.match('safari') && !userAgent.match('chrome');
    if (isSafari) {
      window.location = redirect_url;
    } else {
      window.open(redirect_url);
    }
    yield put(requestIntegrationSuccess());
  } catch (e) {
    yield put(requestIntegrationError());
  }
}

function* requestTokenStateSaga(request) {
  const orgId = yield select(orgSelector);
  const endpoint = `outreach/${orgId}/token_state`;
  try {
    const { state } = yield call(request, endpoint, 'GET');
    yield put(requestTokenStateSuccess({ state }));
  } catch (e) {
    yield put(requestTokenStateError());
  }
}

function* refreshTokenSaga(request) {
  const orgId = yield select(orgSelector);
  const endpoint = `outreach/${orgId}/refresh`;
  try {
    const { success } = yield call(request, endpoint, 'GET');
    if (success) {
      yield put(refreshTokenSucess());
    } else {
      yield put(refreshTokenError());
    }
  } catch (e) {
    yield put(refreshTokenError());
  }

}

function* removeTokenSaga(request) {
  const orgId = yield select(orgSelector);
  try {
    yield call(request, `outreach/${orgId}/delete`, 'DELETE');
    yield put(requestTokenState());
    yield put(showNotification('success', 'Successfully removed Outreach Integration'));
  } catch (e) {
    yield put(showNotification('error', 'Unable to remove outreach integration at this time'));
  }
}

function* watchRequestIntegration(request) {
  yield takeEvery(REQUEST_INTEGRATION, requestIntegrationSaga, request);
}

function* watchRequestTokenState(request) {
  yield takeEvery(REQUEST_INTEGRATION_STATE, requestTokenStateSaga, request);
}
function* watchRefreshToken(request) {
  yield takeEvery(REFRESH_TOKEN, refreshTokenSaga, request);
}
function* watchRemoveOutreachCredentials(request) {
  yield takeEvery(REMOVE_OUTREACH_TOKEN, removeTokenSaga, request);
}

export default [
  fetchSaga(watchRequestIntegration),
  fetchSaga(watchRequestTokenState),
  fetchSaga(watchRefreshToken),
  fetchSaga(watchRemoveOutreachCredentials),
];
