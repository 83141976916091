import { defineSharedState } from '@sixsense/core/shared-state';

export const selectedTabState = defineSharedState({
  path: 'usermanagement.selectedTab',
  defaultValue: 'users',
});

type SelectedRoleId = undefined | number;

export const selectedRoleState = defineSharedState<SelectedRoleId>({
  path: 'usermanagement.selectedRoleId',
  defaultValue: undefined,
});

type SelectedUserId = undefined | number;

export const selectedUserState = defineSharedState<SelectedUserId>({
  path: 'usermanagement.selectedUserId',
  defaultValue: undefined,
});

export const showRoleComparisonState = defineSharedState({
  path: 'usermanagement.selectedUserId',
  defaultValue: false,
});
