import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { FormComponent } from 'v2-HOCS';
import { Icon } from 'v2-components';
import { values, omit } from 'lodash';

import styles from './Input.module.scss';
import { COLORS } from '../../v2-styles/constants';

const InputComponent = (props) => {
  const { type, reference } = props;
  switch (type) {
    case 'textarea':
      return <textarea {...props} />;
    default:
      return <input {...omit(props, ['reference'])} ref={reference} />;
  }
};

InputComponent.propTypes = {
  type: PropTypes.string,
  reference: PropTypes.any,
};

function Input(props) {
  const {
    id,
    content,
    title,
    popoverPlacement,
    className,
    placeholder,
    type,
    icon,
    iconLocation,
    iconColor,
    iconClass = styles.icon,
    onBlur,
    onChange,
    onDragStart,
    onDrop,
    onFocus,
    onKeyPress,
    onKeyDown,
    onKeyUp,
    onClick,
    value,
    reference,
    noBorder,
    size,
    disabled,
    autoFocus,
    rightSideComponent,
    maxLength,
    isClearable = false,
    clearFn,
  } = props;

  let classText = noBorder ? styles.inputNoBorder : styles.input;
  let inputIcon = null;

  if (icon) {
    inputIcon = <Icon type={icon} color={iconColor} className={iconClass} />;
    classText += ` ${styles[`pad${iconLocation}`]}`;
  }

  if (disabled) {
    classText += ` ${styles.disabled}`;
  }

  const clearButton = (
    <Icon
      type="clear"
      iconColor={iconColor}
      onClick={() => {
        clearFn();
        if (reference && reference.current) {
          reference.current.focus();
        }
      }}
      className={styles.clearButton}
    />
  );

  classText = className ? `${classText} ${className}` : classText;

  const baseInput = (
    <div id={id} className={styles.inputRow}>
      <div className={styles.inputContainer}>
        {iconLocation === 'left' && inputIcon}
        <InputComponent
          onBlur={onBlur}
          onFocus={onFocus}
          onDragStart={onDragStart}
          onChange={onChange}
          onDrop={onDrop}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onClick={onClick}
          value={value}
          className={classText}
          placeholder={placeholder}
          type={type}
          reference={reference}
          size={size}
          disabled={disabled}
          autoFocus={autoFocus}
          maxLength={maxLength}
        />
        {isClearable && clearButton}
        {iconLocation === 'right' && inputIcon}
        {rightSideComponent}
      </div>
    </div>
  );

  return content ? (
    <Popover content={content} title={title} placement={popoverPlacement}>
      {baseInput}
    </Popover>
  ) : baseInput;
}

const STRING_ELEMENT = [PropTypes.string, PropTypes.element, PropTypes.number, PropTypes.bool];

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  title: PropTypes.oneOfType(STRING_ELEMENT),
  content: PropTypes.oneOfType(STRING_ELEMENT),
  icon: PropTypes.oneOfType(STRING_ELEMENT),
  iconLocation: PropTypes.oneOf(['left', 'right']),
  iconColor: PropTypes.oneOf(values(COLORS)),
  iconClass: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onDragStart: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDrop: PropTypes.func,
  noBorder: PropTypes.bool,
  size: PropTypes.number,
  popoverPlacement: PropTypes.oneOf([
    'top',
    'topLeft',
    'topRight',
    'leftTop',
    'left',
    'leftBottom',
    'rightTop',
    'right',
    'rightBottom',
    'bottomLeft',
    'bottom',
    'bottomRight',
  ]),
  reference: PropTypes.any,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  rightSideComponent: PropTypes.element,
  maxLength: PropTypes.number,
  isClearable: PropTypes.bool,
  clearFn: PropTypes.func,
};

Input.defaultProps = {
  content: false,
  type: 'text',
  disabled: false,
  rightSideComponent: null,
};

const mapInputToProps = (input) => ({
  ...input,
  onBlur: (proxy, event) => {
    proxy.persist();
    if (proxy.target.value !== '') {
      input.onBlur(proxy, event);
    }
  },
});

Input.form = FormComponent(Input, mapInputToProps);

export default Input;
