import { get } from 'lodash';
import qs from 'qs';
import { SOCIAL_WINDOW_MESSAGING_TYPE } from '../../../constants';
import { AD_NETWORKS } from 'constants/campaigns';

const GoogleAuthorizationDetailsConsumer = () => {
  const params = qs.parse(get(window, 'location.search', ''), { ignoreQueryPrefix: true });
  const windowOpener = window.opener;
  const locationOrigin = window.location.origin;
  const error = get(params, 'error');
  const code = get(params, 'code');
  const state = get(params, 'state');

  if (error && windowOpener) {
    windowOpener.postMessage({
      error,
      type: SOCIAL_WINDOW_MESSAGING_TYPE[AD_NETWORKS.google],
    }, locationOrigin);
  } else if (code && state && windowOpener) {
    windowOpener.postMessage({
      code,
      state,
      type: SOCIAL_WINDOW_MESSAGING_TYPE[AD_NETWORKS.google],
    }, locationOrigin);
  }

  window.close();
  return null;
};

export default GoogleAuthorizationDetailsConsumer;
