import { CAMPAIGN_SOURCE } from '../../constants';

export const VIEW_CAMPAIGN_DETAILS_ROUTE = 'view';
export const CAMPAIGN_CONFIG_FORM = 'CAMPAIGN_CONFIG_FORM';
export const REQUIRED_FOR_SAVE = 'campaignName';
export const JOB_TARGETING_FEE = 4;
export const FORM_LAYOUT = { inputSize: 3, labelSize: 3 };
export const CAMPAIGN_CREATIVE_GROUP_FORM = 'CAMPAIGN_CREATIVE_GROUP_FORM';

export const LINKEDIN_CONFIGURATION_TAB_ITEM = {
  label: 'Configuration (View only)',
  value: 'linkedin_config',
  hasError: false,
};
export const PLACEMENTS_TAB_ITEM = {
  label: 'Ads',
  value: 'placements',
};
export const PERFORMANCE_TAB_ITEM = {
  label: 'Performance',
  value: 'performance',
};

export const FORM_TYPE = Object.freeze({
  LINKEDIN_CONFIGURATION: LINKEDIN_CONFIGURATION_TAB_ITEM.value,
  PLACEMENTS: 'placements',
  PERFORMANCE: 'performance',
});

export const ROUTE_ACTION_FORM = Object.freeze({
  view: FORM_TYPE.PERFORMANCE,
  edit: FORM_TYPE.CONFIG,
  [LINKEDIN_CONFIGURATION_TAB_ITEM.value]: FORM_TYPE.LINKEDIN_CONFIGURATION,
  tracking: FORM_TYPE.AD_GROUPS,
});

export const CAMPAIGN_SOURCE_OPTIONS = {
  INTERNAL: {
    label: 'Display Campaign',
    value: CAMPAIGN_SOURCE.INTERNAL,
  },
  CONTEXTUAL: {
    label: 'Contextual Campaign',
    value: CAMPAIGN_SOURCE.CONTEXTUAL,
  },
  RETARGETING: {
    label: 'Retargeting Campaign',
    value: CAMPAIGN_SOURCE.RETARGETING,
  },
  EXTERNAL: {
    label: 'External Display Campaign',
    value: CAMPAIGN_SOURCE.EXTERNAL,
  },
};

export const SUBMIT_TYPES = Object.freeze({
  SUBMIT: 'SUBMIT',
  SAVE: 'SAVE',
});

export const PLACEMENT_GROUP_ERROR_MESSAGE =
  'Please add AdGroups and Ads to the campaign before submit.';

export const LEAVE_CREATE_CAMPAIGN = 'All unsaved changes will be deleted.';

export const REQUIRED_FIELD_ERROR_MSG = 'Field is required to submit.';

export const DATE_ERROR_MSG = 'Start date must not be in past in UTC.';

export const DAYPARTING_HELP_TEXT = 'Define when you would like the campaign to serve the Ads';

export const DAYPARTING_NOTE = '(Note: Above time will be applicable for selected days)';

export const DAYS_OF_WEEK = {
  ALL_DAYS: 'all_day',
  WEEKDAYS: 'weekdays',
};

export const TIME_SELECTION = {
  ALL_TIME: 'all_time',
  SPECIFIC_TIME: 'specific_time',
};
export const EMC_CREATION_TYPES = {
  MACROS: 'macro_url',
  URL: 'static_url',
};

export const EMC_CREATION_TYPES_OPTIONS = [
  { label: 'Tracking with Ad Macros', value: EMC_CREATION_TYPES.MACROS },
  { label: 'Tracking with Static Ad URL(s)', value: EMC_CREATION_TYPES.URL },
];

export const EMC_CREATION_TYPES_TEXT = {
  [EMC_CREATION_TYPES.MACROS]:
    '(You can use a single tracker across all creatives of a campaign)',
  [EMC_CREATION_TYPES.URL]:
    '(You will get a unique tracker per ad/placement) ',
};

// Google DFP
export const MACRO_BLOCKED_AD_PROVIDERS = [15, 19, 35];

export const STATIC_URL_BLOCKED_AD_PROVIDERS = [
  30, // linkedin
  31, // facebook
];

export const SUPPORTED_REDIRECT_URL_MODES = {
  ALL: 'all',
  PILOT: 'pilot',
  NULL: null,
};

export const SAMPLE_TEMPLATE_CSV_CLICK_TRACKERS = '/org/retrieve_s3object?' +
  'filename=ClickTrackerSampleTemplate.csv';

export const TARGETED_ACCOUNTS_SORT_OPTIONS = [
  {
    label: 'Most Visitors',
    value: 'user_count',
  },
  {
    label: 'Name',
    value: 'firm_name',
  },
  {
    label: 'Most Keywords',
    value: 'keyword_count',
  },
  {
    label: 'Most Activities',
    value: 'activity_count',
  },
  {
    label: 'Last Activity',
    value: 'latest_activity',
  },
];

export const DATE_RANGE_OPTIONS = [
  { value: 'all', label: 'Lifetime' },
  { value: 'week', label: 'Last 7 days' },
  { value: 'month', label: 'Last 30 Days' },
  { value: 'date_range', label: 'Date Range' },
];
export const HOURS_IN_A_DAY = 24;

export const FORM_ERRORS = {
  NO_ACCOUNT_IN_SEGMENT: 'No accounts in this segment are currently reachable. ' +
    'Try modifying or changing the segment',
  REQUIRED_FIELD: 'Field is required to submit.',
  CAMPAIGN_NAME_FOR_SAVE: 'Campaign Name is required to save campaign.',
  SHOULD_HAVE_ATLEAST_ONE_DEVICE_TARGETED: 'Please select atleast one device for targeting.',
};

export const TARGETABLE_ACCOUNT_LIMIT = 100000;

export const TARGETING_DEVICES = {
  desktop: 'Desktop',
  mobile: 'Mobile',
  tablet: 'Tablet',
};

export const TARGETING_DEVICES_OPTIONS = [
  { label: 'Desktop', value: 'desktop' },
  { label: 'Mobile', value: 'mobile' },
  { label: 'Tablet', value: 'tablet' },
];

export const DEFAULT_TARGETED = ['desktop', 'mobile', 'tablet'];

export const CYBER_SECURITY = 'Cyber Security';
export const BETA_JOB_FUNCTIONS_IDS = [30];

const GOOGLE_ADWORDS_ID = 20;
export const SHOW_COVID_WARNING_TO_PROVIDER_IDS = [
  GOOGLE_ADWORDS_ID,
];

export const PUBLISHER_LIST_TYPE = {
  BLACKLIST: 'blacklist',
  WHITELIST: 'whitelist',
};

export const MAX_ALLOWED_URLS = 5;

export const SAMPLE_TEMPLATE_PUBLISHER_URLS = '/org/retrieve_s3object?' +
  'filename=AdInventorySample.csv';

export const AD_INVENTORY_TYPES = {
  appAndWeb: 'app_and_web',
  webOnly: 'web_only',
  appOnly: 'app_only',
};
export const INVENTORY_TARGET_OPTIONS = [
    { label: 'App & Web', value: AD_INVENTORY_TYPES.appAndWeb },
    { label: 'Web only', value: AD_INVENTORY_TYPES.webOnly },
    { label: 'App Only', value: AD_INVENTORY_TYPES.appOnly },
];

export const DEFAULT_TARGETED_INVENTORY = 'app_and_web';

export const SYSTEM_SEGMENT = 'system';

export const COUNTRY_TARGETING = {
  GLOBAL_OPTION_VALUE: -1,
  GLOBAL_NAME: 'All',
  FIELD_NAME: 'country_targets',
};
