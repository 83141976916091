import React from 'react';
import { Text, Link, Modal } from 'v2-components';

const PreRequisites = () => {
  const preRequisites = [
    {
      text: (<span>
        Opt In for 6sense Segments for Facebook <i>(This action can only be done by an admin)</i>
      </span>),
    },
    {
      text: 'Ensure a',
      linkText: 'Facebook Business Manager Account',
      link: 'https://www.facebook.com/business/help/1710077379203657',
      predicateText: 'is created.',
    },
    {
      text: 'Ensure at-least 1',
      linkText: 'Ad Account',
      link: 'https://www.facebook.com/business/help/910137316041095',
      predicateText: 'is created within Facebook.',
    },
  ];

  return (<div>
    {preRequisites.map(({ text, linkText, link, predicateText }, index) => (
      <div key={linkText}>
        <Text type={Text.TYPE.SUBBODY}>
          {index + 1}. {text}
          <Link
            newWindow
            link={link}
          >
            &nbsp;{linkText}
          </Link>
          &nbsp;{predicateText}
        </Text>
      </div>
    ))}
  </div>);
};

const PreRequisitesModal = () => (
    Modal.prompt({
      type: Modal.PROMPT_TYPES.INFO,
      title: 'Pre-requisite Steps',
      width: 650,
      content: <PreRequisites />,
    })
  );

export default PreRequisitesModal;
