import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'custom6QA',
    name: 'custom6QA',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        // We need the segment sagas/reducer for loading the segment filters config
        const segmentReducer = require('../../../Segments/routes/Segment/modules');
        const segmentSagas = require('../../../Segments/routes/Segment/sagas');
        injectReducer('segmentsSegment', segmentReducer.default);
        injectSagas('segmentsSegment', segmentSagas.default);

        const containers = require('./containers');
        cb(null, containers.SixQAPage);
      }, 'custom6QA');
    },
  };
}
