import { Organization } from '@sixsense/core/types';
import { FUNNEL_INSIGHTS_ORG_WHITELIST } from 'constants/featurePermissions';
import moment from 'moment';

// Limit to showing the most recent 12 months.
export const formatDateRangeOptions = (dates, limit = 12) =>
  dates.sort().reverse().slice(0, limit).map((date) => ({
    label: moment(date).format('MMM, YYYY'),
    value: date,
  }));

export const spanFromDate = (date) => ({
  label: moment(date).format('MMM, YYYY'),
  value: {
    startDate: date,
    endDate: moment(date).endOf('month').format('YYYY-MM-DD'),
  },
});

export const checkFunnelInsightsAccess = (org: Organization, username: string) => {
  if (org.hasPredictive || org.has_predictive) {
    return true;
  }
  if (org.name === '6sense') {
    return true;
  }
  const userWhitelist: Set<string>|undefined = FUNNEL_INSIGHTS_ORG_WHITELIST[
    org.name?.toLowerCase()
  ];
  if (userWhitelist) {
    return userWhitelist.has(username);
  }
  return false;
};

export const shouldShowDollar = (country, flag = false) => flag || country === 'United States';

export const addDollarSignIfNeeded = (data, flag = false) => flag ? `$${data}` : data;
