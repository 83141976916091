import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Tooltip, Loading2, CustomIcon, Col } from 'v2-components';
import { ImageContext } from 'contexts';
import styles from './Image.module.scss';

const errMessage = 'Something went wrong loading this image, try refreshing the page.';

export const ImageErrorComponent = (errorMessage, className, iconType = CustomIcon.CLOSE_ICON) => {
  const ErrorComponent = (props) => (
    <Tooltip title={props.customMessage || errorMessage} overlayStyle={{ maxWidth: '35rem' }}>
      <Row
        justifyContent={Row.JUSTIFY_CONTENT.CENTER}
        alignItems={Row.ALIGN_ITEMS.CENTER}
        className={`${props.containerClassName}`}
      >
        <Col className={`${styles.errorImageContainer}`}>
          <CustomIcon classIcon={className} iconType={iconType} />
        </Col>
      </Row>
    </Tooltip>
  );
  ErrorComponent.propTypes = {
    containerClassName: PropTypes.string,
    customMessage: PropTypes.string,
  };
  return ErrorComponent;
};

const ImgProps = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  stretch: PropTypes.bool,
  imgHeight: PropTypes.number,
  imgWidth: PropTypes.number,
  ErrorComponent: PropTypes.any,
};

const Img = (props) => {
  const {
    error,
    imageProps: {
      src,
      alt,
      height: h,
      width: w,
      className,
      containerClassName,
      stretch,
      imgHeight,
      imgWidth,
      ErrorComponent,
    } = {},
  } = props;

  let height = h;
  let width = w;

  /*
    depending on the container for the image, you might need to
    pass a classname for the image specifying max height/width
  */

  if (!stretch) {
    height = imgWidth > imgHeight ? null : height;
    width = imgHeight > imgWidth ? null : width;
  }

  return error ? (
    <ErrorComponent
      containerClassName={containerClassName}
    />
  ) : (
    <div style={stretch ? { height, width } : {}} className={containerClassName}>
      <img src={src} alt={alt} height={height} width={width} className={className} />
    </div>
  );
};

Img.propTypes = {
  error: PropTypes.bool,
  imageProps: PropTypes.shape({ ...ImgProps }),
};

class Image extends Component {
  static propTypes = {
    context: PropTypes.oneOf(['Global', 'Local', 'None']),
    LoadingComponent: PropTypes.any,
    ErrorComponent: PropTypes.any,

    addImage: PropTypes.func,
    loadImageSuccess: PropTypes.func,
    loadImageFailure: PropTypes.func,
    ...ImgProps,
  };

  static defaultProps = {
    LoadingComponent: Loading2.LoadingComponent,
    ErrorComponent: ImageErrorComponent(errMessage, styles.errorIcon, CustomIcon.BROKEN_IMAGE_ICON),
    context: 'None',
    stretch: false,
    addImage: () => {},
    loadImageSuccess: () => {},
    loadImageFailure: () => {},
  };
  constructor(props) {
    super(props);
    props.addImage(props.src);
    this.state = {
      loading: true,
      error: false,
      imageProps: {
        src: props.src,
        alt: props.alt,
        height: props.height,
        width: props.width,
        className: props.className,
        containerClassName: props.containerClassName,
        stretch: props.stretch,
        ErrorComponent: props.ErrorComponent,
      },
    };
  }

  // after the loading spinner has mounted, load the image. This allows
  // the rest of the page to render while we async load the actual img
  componentDidMount() {
    const { loadImageSuccess, loadImageFailure, src } = this.props;
    const { Image: SalvadorDali } = window;
    const img = new SalvadorDali();
    img.src = src;
    img.onload = () => {
      loadImageSuccess(src);
      this.setState({
        loading: false,
        imageProps: {
          ...this.state.imageProps,
          imgHeight: img.height,
          imgWidth: img.width,
        },
      });
    };
    img.onerror = () => {
      loadImageFailure(src);
      this.setState({ loading: false, error: true });
    };
  }

  /* waiting for all images on the page(route) to load before showing any.
  This context could also be consumed at a container level */
  renderGlobal() {
    const { LoadingComponent, loadingComplete } = this.props;
    if (loadingComplete === undefined) {
      throw new Error(`
        Images must be children of an image context provider to use the global render.
      `);
    }
    return loadingComplete ? (
      <Img imageProps={this.state.imageProps} error={this.state.error} />
    ) : (
      <LoadingComponent />
    );
  }

  // only pay attention to this specific image's loading state (kept locally, but also in store)
  renderLocal() {
    const { LoadingComponent } = this.props;
    return this.state.loading ? (
      <LoadingComponent />
    ) : (
      <Img imageProps={this.state.imageProps} error={this.state.error} />
    );
  }

  // idgaf about loading, just put the image on the got damn page
  renderNone() {
    return <Img imageProps={this.state.imageProps} error={this.state.error} />;
  }

  render() {
    return this[`render${this.props.context}`]();
  }
}

const { ImageConsumer } = ImageContext;

// the image provider context here is spread into props
// so the Image component will have access to them
export default (props) => <ImageConsumer>{(ctxt) => <Image {...props} {...ctxt} />}</ImageConsumer>;
