import React from "react";
import { CAMPAIGN_STATUS_X } from "../../../constants";
import { NA } from "./common/NA";
import CampaignPerformanceIndicator from "../../../components/CampaignPerformanceIndicator/CampaignPerformanceIndicator";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { pxToRem } from "@sixsense/core/style";

export const CAMPAIGN_PERFORMANCE = {
  sortByKey: "campaign_performance",
  key: "campaign-performance",
  csvColumnKey: "campaign_performance",
  title: <Label name={CAMPAIGN_LABELS.campaignPerformance} />,
  defaultSelected: true,
  dataIndex: "campaign_performance",
  width: pxToRem(180),
  render: (campaignPerformance, campaign) => {
    const performanceDataExists = Boolean(campaignPerformance);
    const status = campaign.campaign_status;

    if (
      (status && status === CAMPAIGN_STATUS_X.draft) ||
      performanceDataExists === false
    ) {
      return <NA />;
    }

    return <CampaignPerformanceIndicator campaign={campaign} showAsBadge />;
  },
};
