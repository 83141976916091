import {
  getAccountSimpleChartModelUpdateStagesDataFnSelector,
  getAccountParetoChartModelUpdateConversionRateDataFnSelector,
  getAccountParetoChartModelMetricsConversionRateDataFnSelector,
  getAccountSimpleChartModelMetricsStagesDataFnSelector,
} from './selectors';

export const MODEL_INSIGHTS_ROUTE = 'modelinsights';
export const MODEL_UPDATE_ROUTE = 'modelperformance';

export const EXPRIMENT_TYPE_TO_MODEL_MAPPING_LABEL = {
  company_activity: 'Account Behavior',
  company_bp: 'Account Profile Fit',
};

export const MODEL_TO_EXPRIMENT_TYPE_MAPPING = {
  accountBehaviour: 'company_activity',
  accountProfileFit: 'company_bp',
};

export const STAGES_FOR_MODELS = {
  accountProfileFit: ['Weak', 'Moderate', 'Strong'],
  accountBehaviour: ['Awareness', 'Consideration', 'Decision', 'Purchase'],
  contactBehaviour: ['C', 'B', 'A'],
};
export const STAGES_INDEX_MAP_FOR_MODELS = {
  accountProfileFit: {
    Weak: 0,
    Moderate: 1,
    Strong: 2,
  },
  accountBehaviour: {
    Awareness: 0,
    Consideration: 1,
    Decision: 2,
    Purchase: 3,
  },
  contactBehaviour: {
    C: 0,
    B: 1,
    A: 2,
  },
};

export const PARETO_CHART_MODEL_UPDATE_MAPPING = {
  accountBehaviour: {
    dataType: ['perc_opened'],
    dataTypeUnit: ['%'],
    liftDataType: ['lift'],
    liftDataTypeUnit: ['×'],
    plotDataType: ['baseline'],
    stageDataType: ['buying_stage'],
  },
  accountProfileFit: {
    dataType: ['conversion_rate_created_opps', 'conversion_rate_won_opps'],
    dataTypeUnit: ['%', '%'],
    liftDataType: [
      'conversion_lift_for_created_opps',
      'conversion_lift_for_won_opps',
    ],
    liftDataTypeUnit: ['×', '×'],
    plotDataType: ['baseline_created_ops', 'baseline_won_ops'],
    stageDataType: ['profile_fit', 'profile_fit'],
  },
};

export const MULTIPLIER_TYPE_MODEL_UPDATE_MAPPING = {
  accountBehaviour: ['lift'],
  accountProfileFit: [
    'conversion_lift_for_created_opps',
    'conversion_lift_for_won_opps',
  ],
};

export const PRODUCT_DATA_TYPE_MAPPING = {
  accountBehaviour: 'product',
  accountProfileFit: 'product',
};

export const SIMPLE_CHART_MODEL_UPDATE = {
  accountBehaviour: [
    {
      label: 'Accounts',
      value: 'perc_total_accounts',
      valueUnit: '%',
      extraValueType: 'mid_can_track',
      extraValueUnit: ' ',
    },
    {
      label: 'Open Opps',
      value: 'perc_total_opptys',
      valueUnit: '%',
      extraValueType: 'did_open',
      extraValueUnit: ' ',
    },
  ],
  accountProfileFit: [
    {
      label: 'Accounts',
      value: 'perc_total_accounts',
      valueUnit: '%',
      extraValueType: 'total_accounts',
      extraValueUnit: ' ',
    },
    {
      label: 'Open Opps',
      value: 'perc_total_opptys',
      valueUnit: '%',
      extraValueType: 'total_opptys',
      extraValueUnit: ' ',
    },
    {
      label: 'Won Opps',
      value: 'perc_wons',
      valueUnit: '%',
      extraValueType: 'total_wons',
      extraValueUnit: ' ',
    },
  ],
};

// Model Metrix Variable
export const MULTIPLIER_TYPE_MODEL_METRICS_MAPPING = {
  accountBehaviour: [(data) => data.lift],
  accountProfileFit: [
    (data) => data.open_pct / data.baseline_open_accounts,
    (data) => data.win_pct / data.baseline_won_accounts,
  ],
};

export const PARETO_CHART_MODEL_METRICS_MAPPING = {
  accountBehaviour: {
    dataType: ['perc_opened'],
    dataTypeUnit: ['%'],
    liftDataType: [(data) => data.lift],
    liftDataTypeUnit: ['×'],
    plotDataType: ['baseline'],
    stageDataType: ['buying_stage'],
  },
  accountProfileFit: {
    dataType: ['open_pct', 'win_pct'],
    dataTypeUnit: ['%', '%'],
    liftDataType: [
      (data) => data.open_pct / data.baseline_open_accounts,
      (data) => data.win_pct / data.baseline_won_accounts,
    ],
    liftDataTypeUnit: ['×', '×'],
    plotDataType: ['baseline_open_accounts', 'baseline_won_accounts'],
    stageDataType: ['product_scores_profile_fit', 'product_scores_profile_fit'],
  },
};

export const SIMPLE_CHART_MODEL_METRICS = {
  accountBehaviour: [
    {
      label: 'Accounts',
      value: 'perc_total_accounts',
      valueUnit: '%',
      extraValueType: 'total_accounts',
      extraValueUnit: ' ',
    },
    {
      label: 'Open Opps',
      value: 'perc_total_opptys',
      valueUnit: '%',
      extraValueType: 'total_created_ops',
      extraValueUnit: ' ',
    },
  ],
  accountProfileFit: [
    {
      label: 'Accounts',
      value: 'perc_total_accounts',
      valueUnit: '%',
      extraValueType: 'total',
      extraValueUnit: ' ',
    },
    {
      label: 'Open Opps',
      value: 'perc_open_accounts',
      valueUnit: '%',
      extraValueType: 'did_open',
      extraValueUnit: ' ',
    },
    {
      label: 'Won Opps',
      value: 'perc_won_accounts',
      valueUnit: '%',
      extraValueType: 'did_won',
      extraValueUnit: ' ',
    },
  ],
  contactsBehaviour: [
    {
      label: 'Contacts',
      value: 'perc_total_accounts',
      valueUnit: '%',
      extraValueType: 'total_accounts',
      extraValueUnit: ' ',
    },
    {
      label: 'Open Opps',
      value: 'perc_total_opptys',
      valueUnit: '%',
      extraValueType: 'total_created_ops',
      extraValueUnit: ' ',
    },
  ],
  contactsProfileFit: [
    {
      label: 'Contacts',
      value: 'perc_total_accounts',
      valueUnit: '%',
      extraValueType: 'total',
      extraValueUnit: ' ',
    },
    {
      label: 'Open Opps',
      value: 'perc_open_accounts',
      valueUnit: '%',
      extraValueType: 'did_open',
      extraValueUnit: ' ',
    },
    {
      label: 'Won Opps',
      value: 'perc_won_accounts',
      valueUnit: '%',
      extraValueType: 'did_won',
      extraValueUnit: ' ',
    },
  ],
};

export const TAB_OPTIONS = {
  ACCOUNT: 'account',
  CONTACTS: 'contacts',
  SIGNALS: 'signals',
};

export const CONTACT_SOURCE_BEHAVIOURS = {
  CRM_LEAD: 'CML',
  MAP_CONTACT: 'MAC',
  CRM_CONTACT: 'CMC',
};

export const CONTACT_SOURCE_PROFILE_FIT = {
  CRM_LEAD: 'CRM Lead',
  MAP_CONTACT: 'MAP Contact',
  CRM_CONTACT: 'CRM Contact',
};

export const CONTACT_TYPE = {
  contactBehaviours: 'contactBehaviours',
  contactProfileFit: 'contactProfileFit',
};

export function formatNumber(num) {
  if (isNaN(+num)) {
    return num;
  }
  return (+num).toLocaleString();
}

export const LOADING_MAPPING = {
  LOADING_ACCOUNT_BEHAVIOURS: 'LOADING_ACCOUNT_BEHAVIOURS',
  LOADING_CONTACT_BEHAVIOURS: 'LOADING_CONTACT_BEHAVIOURS',
  LOADING_CONTACT_PROFILE_FIT: 'LOADING_CONTACT_PROFILE_FIT',
};

export const ELEMENT_ID_CONST = {
  modelUpdateAccountBehaviour: 'modelUpdateAccountBehaviour',
  modelUpdateAccountProfileFit: 'modelUpdateAccountProfileFit',
  accountBehaviour: 'accountBehaviour',
  accountProfileFit: 'accountProfileFit',
  contactBehaviour: 'contactBehaviour',
  contactProfileFit: 'contactProfileFit',
};

export const ELEMENT_ID_CONST_TO_CHARTS_DATA = {
  modelUpdateAccountBehaviour: {
    title: 'Account Behavior Comparison Summary',
    simpleChartDataFnSelector:
      getAccountSimpleChartModelUpdateStagesDataFnSelector,
    paretoChartDataFnSelector:
      getAccountParetoChartModelUpdateConversionRateDataFnSelector,
    exprimentTypeForModel: MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour,
    simpleChartOptions: SIMPLE_CHART_MODEL_UPDATE.accountBehaviour,
    paretoChartOptions: PARETO_CHART_MODEL_UPDATE_MAPPING.accountBehaviour,
    categories: STAGES_FOR_MODELS.accountBehaviour,
    seriesTitle: 'Buying Stages',
    nameLabel: ['Open Opps'],
  },
  modelUpdateAccountProfileFit: {
    title: 'Account Profile Fit Comparison Summary',
    simpleChartDataFnSelector:
      getAccountSimpleChartModelUpdateStagesDataFnSelector,
    paretoChartDataFnSelector:
      getAccountParetoChartModelUpdateConversionRateDataFnSelector,
    exprimentTypeForModel: MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit,
    simpleChartOptions: SIMPLE_CHART_MODEL_UPDATE.accountProfileFit,
    paretoChartOptions: PARETO_CHART_MODEL_UPDATE_MAPPING.accountProfileFit,
    categories: STAGES_FOR_MODELS.accountProfileFit,
    seriesTitle: 'Profile Fit',
    nameLabel: ['Open Opps', 'Won Opps'],
  },
  accountBehaviour: {
    title: 'Account Behavior Summary',
    simpleChartDataFnSelector:
      getAccountSimpleChartModelMetricsStagesDataFnSelector,
    paretoChartDataFnSelector:
      getAccountParetoChartModelMetricsConversionRateDataFnSelector,
    exprimentTypeForModel: MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour,
    simpleChartOptions: SIMPLE_CHART_MODEL_METRICS.accountBehaviour,
    paretoChartOptions: PARETO_CHART_MODEL_METRICS_MAPPING.accountBehaviour,
    categories: STAGES_FOR_MODELS.accountBehaviour,
    seriesTitle: 'Buying Stages',
    nameLabel: ['Open Opps'],
  },
  accountProfileFit: {
    title: 'Account Profile Fit Summary',
    simpleChartDataFnSelector:
      getAccountSimpleChartModelMetricsStagesDataFnSelector,
    paretoChartDataFnSelector:
      getAccountParetoChartModelMetricsConversionRateDataFnSelector,
    exprimentTypeForModel: MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit,
    simpleChartOptions: SIMPLE_CHART_MODEL_METRICS.accountProfileFit,
    paretoChartOptions: PARETO_CHART_MODEL_METRICS_MAPPING.accountProfileFit,
    categories: STAGES_FOR_MODELS.accountProfileFit,
    seriesTitle: 'Profile Fit',
    nameLabel: ['Open Opps', 'Won Opps'],
  },
  contactBehaviour: {
    title: 'Contact Behavior Summary',
    simpleChartDataFnSelector:
      getAccountSimpleChartModelMetricsStagesDataFnSelector,
    paretoChartDataFnSelector:
      getAccountParetoChartModelMetricsConversionRateDataFnSelector,
    exprimentTypeForModel: MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountBehaviour,
    simpleChartOptions: SIMPLE_CHART_MODEL_METRICS.contactsBehaviour,
    paretoChartOptions: PARETO_CHART_MODEL_METRICS_MAPPING.accountBehaviour,
    categories: STAGES_FOR_MODELS.contactBehaviour,
    seriesTitle: 'Engagement Grade',
    nameLabel: ['Open Opps'],
  },
  contactProfileFit: {
    title: 'Contact Profile Fit Summary',
    simpleChartDataFnSelector:
      getAccountSimpleChartModelMetricsStagesDataFnSelector,
    paretoChartDataFnSelector:
      getAccountParetoChartModelMetricsConversionRateDataFnSelector,
    exprimentTypeForModel: MODEL_TO_EXPRIMENT_TYPE_MAPPING.accountProfileFit,
    simpleChartOptions: SIMPLE_CHART_MODEL_METRICS.contactsProfileFit,
    paretoChartOptions: PARETO_CHART_MODEL_METRICS_MAPPING.accountProfileFit,
    categories: STAGES_FOR_MODELS.accountProfileFit,
    seriesTitle: 'Profile Fit',
    nameLabel: ['Open Opps', 'Won Opps'],
  },
};

export const CHARTS_MAPPING_IDS = {
  accountBehaviour: 'accountBehaviour',
  accountProfileFit: 'accountProfileFit',
  accountModelUpdateBehaviour: 'accountModelUpdateBehaviour',
  accountModelUpdateProfileFit: 'accountModelUpdateProfileFit',
  contactBehaviour: 'contactBehaviour',
  contactProfileFit: 'contactProfileFit',
};
