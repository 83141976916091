import React from 'react';
import { Button, Row, Text } from 'v2-components';
import { setPermissions } from '@sixsense/rbac';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { defineRequestState, useRequestValue } from '@sixsense/core/request';
import { ResultBoundary } from '@sixsense/core/components';

const roleListRequestState = defineRequestState({
  path: 'DEBUG/RBAC_ROLES',
  getConfig: () => ({
    url: 'auth/v3/roles/',
  }),
});

const RBACRoles = () => {
  const dispatch = useDispatch();
  const result = useRequestValue(roleListRequestState, []);

  return (
    <ResultBoundary result={result}>
      {({ data }) => data.results.map((role) => (
        <Row
          justifyContent={Row.JUSTIFY_CONTENT.SPACE_BETWEEN}
          style={{ padding: '4px 0' }} key={role.name}
        >
          <Text>{role.name}</Text>
          <Button
            onClick={() => {
              dispatch(setPermissions(role.permissions));
            }}
          >
            Set permission
          </Button>
        </Row>
      ))}
    </ResultBoundary>
  );
};

export default RBACRoles;
