import React, { useState } from 'react';
import { css } from 'styles/emotion';
import { Text, Row } from 'v2-components';
import { BRANDED, GENERIC } from '../constants';
import { capitalize } from 'lodash';
import { CategoryHelpModal } from '../components/Modals';
import { AAColor } from '@sixsense/core/style';

const styles = {
  body: css({
    fontSize: '0.8rem',
  }),
  title: css({
    fontSize: '1rem',
    marginBottom: '5px',
  }),
  hoverColor: css({
    color: `${AAColor.WHITE}`,
  }),
  learnMore: css({
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
};

type Props = {
  category: string;
  isSelected?: boolean;
};

const CategoryDescription = ({ category, isSelected } : Props) => {
  const descriptions = {
    [BRANDED]: "Keywords that are closely related to your or your competitor's product. ",
    [GENERIC]: 'Keywords that have a wider scope and do not include brands/branded words. ',
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [hover, setHover] = useState(false);

  if (isSelected) {
    return <Text color={hover ? '' : Text.COLOR.AA_BLUE}>{capitalize(category)}</Text>;
  }

  return (
    <Row
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      flexDirection={Row.FLEX_DIRECTION.COLUMN}
      className={`${hover && styles.hoverColor}`}
    >
      <Text
        className={styles.title}
        bold
        color={!hover && Text.COLOR.GREY}
      >
        {capitalize(category)}
      </Text>
      <Text className={styles.body} type={Text.TYPE.BODY} color={!hover && Text.COLOR.GREY4}>
        {descriptions[category]}
        <Text
          className={`${styles.body} ${styles.learnMore}`}
          color={!hover && Text.COLOR.BLUE}
          onClick={() => setModalVisible(true)}
          id="learn-more"
        >
          Learn more
        </Text>
      </Text>
      {modalVisible && <CategoryHelpModal onCancel={() => setModalVisible(false)} />}
    </Row>
  );
};

export default CategoryDescription;
