import React from 'react';

/* eslint max-len: [2, 4000] */
// Using 1000 for now.... might need to bump it up
export function AdBlockerIcon(props) {
  return (
    <svg
      {...props}
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <title>AdBlocker Icon</title>
      <desc>AdBlocker Logo</desc>
      <defs>
        <path
          d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 L12,2 Z M4,12 C4,7.58 7.58,4 12,4 C13.85,4 15.55,4.63 16.9,5.69 L5.69,16.9 C4.63,15.55 4,13.85 4,12 L4,12 Z M12,20 C10.15,20 8.45,19.37 7.1,18.31 L18.31,7.1 C19.37,8.45 20,10.15 20,12 C20,16.42 16.42,20 12,20 L12,20 Z"
          id="path-1"
        />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Material_icons_library" transform="translate(-338.000000, -2245.000000)">
          <g id="ic_block" transform="translate(336.000000, 2243.000000)">
            <mask id="mask-2" fill="white">
              <use href="#path-1" />
            </mask>
            <g id="Mask" />
            <g id="Colors/Gray.2" mask="url(#mask-2)" fill="#C1C7CC" fillRule="evenodd">
              <g transform="translate(-13.000000, -13.000000)">
                <path d="M0,0 L50,0 L50,50 L0,50 L0,0 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
