export default function createRoutes() {
  return {
    path: 'manage',
    name: 'Manage',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const ManageRoute = require('./SegmentIndex');
        cb(null, ManageRoute.default);
      }, 'manage');
    },
  };
}
