export const CAMPAIGN_LIST_ROUTE = 'list';

export const ROUTE_STATE_KEY = 'campaignList';

export const CAMPAIGN_LIST_PAGE_SIZE = 10;

export const COLUMNS_STORAGE_KEY = 'campaignListColumns';
export const FILTER_BY_STORAGE_KEY = 'campaignsFilterBy';

export const COLUMN_CATEGORIES = {
  hideForCustomize: 'hideForCustomize',
  general: 'general',
  retargetingCampaign: 'retargetingCampaign',
  externalMedia: 'externalMedia',
  linkedIn: 'linkedIn',
};

export const SORT_ORDER = {
  asc: 'asc',
  desc: 'desc',
};

export const GLOBAL_LIST_LABEL = 'All Campaigns';

export const PAGE_NUMBER_SEARCH_PARAM = 'page';

export const CAMPAIGN_ARCHIVING_CRITERIA = {
  MULTIPLE_SELECTIONS:
    'You seem to have selected one or more active campaigns. ' +
    'Please deactivate or complete the campaigns before archiving.',
  SINGLE_SELECTION:
    'Active campaign cannot be archived. ' +
    'Please deactivate or complete the campaign before archiving.',
  NO_EMC:
    'External Media Campaigns cannot be archived as they may still be ' +
    'running actively in their respective external platforms.',
};

export const DELETE_CAMPAIGN_CONFIRM_TEXT =
  'This campaign will be deleted permanently. Do you want to continue?';
