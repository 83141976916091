import React, { useEffect, useState } from 'react';
import { PageHeader } from 'HOCS';
import { compose } from 'redux';
import { SalesPermission, withPermissionBoundary } from '@sixsense/rbac';
import {
  AccessDenied,
  Loading2 as Loading,
  Tooltip2 as Tooltip,
  Icon,
} from 'v2-components';
import ComponentsAndMetricsHeader from '../components/ComponentsAndMetricsHeader';
import { NavigationCard } from '../components/NavigationCard';
import { DateRangeCard } from '../components/DateRangeCard';
import { pxToRem, AAColor } from '@sixsense/core/style';
import { useRequest, useRequestValue } from '@sixsense/core';
import { componentsAndMetricsRequestState } from '../state';
import { useOrg } from 'hooks';
import {
  COMPONENTS,
  HEADERS,
  METRICS,
  DATA_SETTINGS,
  DATA_SETTINGS_PRODUCTS_MAP,
  DATA_SETTINGS_NOT_APPLICABLE_PLANS,
  PLAN_NOT_ALLOWED_GEN_AI,
} from '../constants';
import { ComponentAndMetricsType } from '../types';
import { ResultStatus } from '@sixsense/core/shared/async-result/types';
import { connect } from 'react-redux';
import { siPackagePlanNameSelector } from 'routes/Settings/selectors';
import PropTypes from 'prop-types';
import { css } from 'styles/emotion';
import { Flex } from '@sixsense/core/components';
import { MAINTENANCE_INFO } from 'routes/Settings/constants';

const styles = {
  headerWrap: css({
    paddingTop: pxToRem(20),
    paddingRight: pxToRem(20),
    paddingBottom: pxToRem(20),
    paddingLeft: pxToRem(20),
  }),
  headerTitle: css({
    color: AAColor.GREY,
    fontSize: pxToRem(14),
    fontWeight: 'bold',
    textTransform: 'uppercase',
  }),
  tooltip: css({
    cursor: 'pointer',
    paddingTop: pxToRem(3),
  }),
};

type NavigationCardHeaderProps = {
  heading: string;
  description: string;
  tooltip?: string;
};
const SETTINGS_DATA_COMPONENTS = DATA_SETTINGS.COMPONENTS[0].list;
export const NavigationCardHeader = ({
  heading,
  description,
  tooltip = '',
}: NavigationCardHeaderProps) => (
  <Flex className={styles.headerWrap} gap={9}>
    <span className={styles.headerTitle}>{heading} &nbsp; — </span>
    <span> {description} </span>
    {tooltip && (
      <div className={styles.tooltip}>
        <Tooltip
          overlayStyle={{ maxWidth: '600px' }}
          placement={'right'}
          overlay={tooltip}
        >
          <Icon type={'info'} theme="outlined" size="14px" />
        </Tooltip>
      </div>
    )}
  </Flex>
);

const ComponentsAndMetrics = ({ siPackagePlanName }) => {
  const request = useRequest();
  const LoadingComponent = Loading.LoadingComponent;
  const { id: orgId } = useOrg();
  const componentsSettings = useRequestValue(
    componentsAndMetricsRequestState,
    orgId
  );
  const [loading, setLoading] = useState<Boolean>(false);
  const [metricsConfig, setMetricsConfig] =
    useState<ComponentAndMetricsType>(Object);
  const [productsConfigured, setProductsConfigured] = useState(Object);

  useEffect(() => {
    const { data, status } = componentsSettings;
    if (status === ResultStatus.LOADED) {
      setMetricsConfig(data);
      getConfiguredProducts();
    }
  }, [componentsSettings]);

  useEffect(() => {
    if (PLAN_NOT_ALLOWED_GEN_AI.includes(siPackagePlanName)) {
      COMPONENTS.COMPONENTS = COMPONENTS.COMPONENTS.map((component) => ({
        ...component,
        list: component.list.filter((item) => item.label !== 'AI Insights'),
      }));
    }
  }, [siPackagePlanName]);

  if (!siPackagePlanName) return <AccessDenied />;

  const onChange = async (body) => {
    try {
      setLoading(true);
      const res: ComponentAndMetricsType = await request(
        `settings/${orgId}/components-and-metrics/${metricsConfig.id}/`,
        {
          method: 'PATCH',
          body: JSON.stringify(body),
        }
      );
      setMetricsConfig({ ...metricsConfig, ...res });
      setLoading(false);
    } catch (ex) {
      setLoading(false);
    }
  };

  const getConfiguredProducts = async () => {
    try {
      setLoading(true);
      const res: any = await request(
        `settings/${orgId}/settings-configured-products/`
      );
      const warningMsgs = {};
      for (const data of SETTINGS_DATA_COMPONENTS) {
        if (data) {
          const count = res[DATA_SETTINGS_PRODUCTS_MAP[data.value]];
          const productNotConfigured = res.products - count;
          if (productNotConfigured > 0) {
            warningMsgs[
              data.value
            ] = `${productNotConfigured}/${res.products} ${data.warning}`;
          }
        }
      }
      setProductsConfigured(warningMsgs);
      setLoading(false);
    } catch (ex) {
      setLoading(false);
    }
  };
  for (const data in SETTINGS_DATA_COMPONENTS) {
    if (SETTINGS_DATA_COMPONENTS[data]) {
      SETTINGS_DATA_COMPONENTS[data].isVisible =
        !DATA_SETTINGS_NOT_APPLICABLE_PLANS[
          SETTINGS_DATA_COMPONENTS[data].value
        ].includes(siPackagePlanName);
    }
  }

  if (MAINTENANCE_INFO.with_read_only) return <AccessDenied message={MAINTENANCE_INFO.message} />;

  return (
    <React.Fragment>
      {loading && <LoadingComponent level="cover" loading />}
      <NavigationCard
        contents={COMPONENTS.COMPONENTS}
        config={metricsConfig}
        onChange={onChange}
        header={
          <NavigationCardHeader
            heading={COMPONENTS.LABEL}
            description={COMPONENTS.DESCRIPTION}
          />
        }
      />
      <NavigationCard
        config={metricsConfig}
        contents={METRICS.METRICS}
        onChange={onChange}
        header={
          <NavigationCardHeader
            heading={METRICS.LABEL}
            description={METRICS.DESCRIPTION}
          />
        }
      />
      <NavigationCard
        config={metricsConfig}
        contents={DATA_SETTINGS.COMPONENTS}
        warningMessages={productsConfigured}
        onChange={onChange}
        header={
          <NavigationCardHeader
            heading={DATA_SETTINGS.LABEL}
            description={DATA_SETTINGS.DESCRIPTION}
            tooltip={DATA_SETTINGS.TOOLTIP}
          />
        }
      />
      <DateRangeCard
        config={metricsConfig}
        onChange={onChange}
        header={
          <NavigationCardHeader
            heading={HEADERS.LABEL}
            description={HEADERS.DESCRIPTION}
          />
        }
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  siPackagePlanName: siPackagePlanNameSelector(state),
});
ComponentsAndMetrics.propTypes = {
  siPackagePlanName: PropTypes.string,
};

export default compose(
  withPermissionBoundary({
    allow: (permissions) =>
      [
        SalesPermission.SETTINGS_COMPONENTS_METRICS_VIEW,
        SalesPermission.SETTINGS_COMPONENTS_METRICS_EDIT,
      ].some((permission) => permissions.has(permission)),
    renderDenied: () => <AccessDenied />,
  }),
  connect(mapStateToProps),
  PageHeader([ComponentsAndMetricsHeader])
)(ComponentsAndMetrics);
