import React from 'react';
import { compose } from 'redux';
import { withRouter, WithRouterProps } from 'react-router';
import { MODEL_UPDATE_ROUTE } from '../ModelUpdate/constants';

const ModelMetricsRedirectComponent = ({ router }: WithRouterProps) => {
  React.useEffect(() => {
    router.replace(`/reports/admin/${MODEL_UPDATE_ROUTE}`);
  }, []);
  return null;
};
export const ModelMetricsRedirect = compose(
  withRouter,
)(ModelMetricsRedirectComponent);
