import React from 'react';
import { css, cx } from 'styles/emotion';
import { Permission, usePermissionCheck } from '@sixsense/rbac';
import { useSharedState, useSetSharedValue } from '@sixsense/core';
import moment from 'moment';
import { Text, Checkbox2, Icon, Pill, Tooltip2 as Tooltip } from 'v2-components';
import { Keyword } from '../../types';
import { Flex } from '@sixsense/core/components';
import { capitalize } from 'lodash';
import { deactivatedConfigState, restoreKeywordsVisibleState } from '../../state';
import { KW_EXP, OTHER } from '../../constants';

const styles = {
  column: css({
    wordBreak: 'break-word',
    paddingRight: '15px',
  }),
  actionColumn: css({
    width: '5%',
    display: 'flex',
    justifyContent: 'center',
  }),
  container: css({
    padding: '20px',
    paddingLeft: '24px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #C6CACE',
    minHeight: '70px',
  }),
  pill: css({
    padding: '0.125rem 0.625rem !important',
    margin: '0px !important',
    borderRadius: '2px !important',
  }),
  branded: css({
    fontWeight: 'bold',
    background: '#FFEFC8 !important',
    border: '1px solid #EBA300',
    color: '#EBA300',
    '&:hover': {
      backgroundColor: '#FFEFC8 !important',
    },
  }),
  generic: css({
    fontWeight: 'bold',
    background: '#E2E7EC !important',
    border: '1px solid #9299A3',
    color: '#9299A3',
    '&:hover': {
      backgroundColor: '#E2E7EC !important',
    },
  }),
  undoButton: css({
    padding: 7,
    border: '1px solid #bfbfbf',
    borderRadius: '2px',
  }),
  moreText: css({
    color: '#1890FF',
    marginLeft: 10,
    '&:hover': {
      color: '#40A9FF',
    },
  }),
};

type Props = {
  keywordData: Keyword;
  products: any;
};

const DeactivatedTableRow = ({ keywordData, products }: Props) => {
  const {
    id,
    category,
    keyword,
    updated,
    keyword_products,
    updated_by,
  } = keywordData;

  const [config, setConfig] = useSharedState(deactivatedConfigState);
  const setModalVisible = useSetSharedValue(restoreKeywordsVisibleState);
  const determineSelectedKeywords = (keywordId, checked) => {
    const keywords = [...config.selectedValues];
    if (checked) {
      keywords.push(keywordId);
      return keywords;
    }
    return config.selectedValues.filter((v) => v !== keywordId);
  };

  const canEdit = usePermissionCheck([Permission.SETTINGS_KEYWORDS_EDIT]);

  const updatedKeywordProducts = keyword_products.map((kw_product) => ({
    ...kw_product,
    name: products.find((p) => p.id === kw_product.product)?.displayName || '' }),
  ).filter((kw) => kw.name);

  const productText = (data) => {
    if (!data.length) {
      return <Text>--</Text>;
    }
    const firstItem = <Text>{data[0].name === KW_EXP ? OTHER : data[0].name}</Text>;
    return data.length === 1 ? firstItem :
    <React.Fragment>
      {firstItem}
      <Tooltip
        overlay={<Flex direction="column">{data.map((d) =>
          <span>{d.name === KW_EXP ? OTHER : d.name}</span>)
        }</Flex>}
      >
        <Text className={styles.moreText}>+{data.length - 1} more</Text>
      </Tooltip>
    </React.Fragment>;
  };

  return (
    <div className={styles.container}>
      {canEdit && <span className={styles.column} style={{ width: '3%' }}>
        <Checkbox2
          checked={config.selectedValues.includes(id)}
          onChange={(e) => setConfig({ ...config,
            selectedValues: determineSelectedKeywords(id, e.target.checked) })}
          size={Icon.SIZE.LARGE}
        />
      </span>}
      <span className={styles.column} style={{ width: '20%' }}>
        <Text>{keyword}</Text>
      </span>
      <span className={styles.column} style={{ width: '15%', paddingLeft: '35px' }}>
        <Pill
          className={cx(
            styles.pill,
            category === 'branded' ? styles.branded : styles.generic
          )}
          notClickable
        >
          {capitalize(category)}
        </Pill>
      </span>
      <span className={styles.column} style={{ width: '15%' }}>
        <Text>{moment(updated).format('MMM DD, YYYY')}</Text>
      </span>
      <span className={styles.column} style={{ width: '20%' }}>
        <Text>{updated_by || '--'}</Text>
      </span>
      <span className={styles.column} style={{ width: '20%' }}>
        <Text>{productText(updatedKeywordProducts)}</Text>
      </span>
      <span className={styles.column} style={{ width: '5%' }}>
        {canEdit && <Tooltip overlay="Restore">
          <Icon
            type="undo"
            color={Icon.COLORS.GREY}
            className={styles.undoButton}
            pointer
            onClick={() => {
              setConfig({ ...config,
                selectedValues: [id] });
              setModalVisible(true);
            }}
          />
        </Tooltip>}
      </span>
    </div>
  );
};

export default DeactivatedTableRow;
