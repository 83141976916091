import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'ast-redux';
import { NullExpr } from 'ast-redux/constructors';
import { hydrateOrder } from 'aa-ast/utils';
import { astSelector } from 'ast-redux/selectors';

export const Ast = (config) => (WrappedComponent) => {
  const { key, destroyOnUnmount } = config;

  class AstClass extends Component {
    getChildContext() {
      return {
        _astRedux: {
          key,
        },
      };
    }

    componentWillMount() {
      const { createAst, InitialAst, keyHit } = this.props;
      if (!keyHit) {
        createAst(
          key,
          InitialAst ? hydrateOrder(InitialAst) : NullExpr(),
        );
      }
    }

    componentWillUnmount() {
      if (destroyOnUnmount) {
        const { destroyAst } = this.props;
        destroyAst();
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  AstClass.childContextTypes = {
    _astRedux: PropTypes.object,
  };

  AstClass.propTypes = {
    createAst: PropTypes.func,
    InitialAst: PropTypes.object,
    keyHit: PropTypes.bool,
    destroyAst: PropTypes.func,
  };

  const astActions = {
    createAst: actions.createAst,
    destroyAst: () => actions.destroyAst(key),
    resetAst: () => actions.resetAst(key),
  };

  const mapStateToProps = (state) => ({
    keyHit: Boolean(astSelector(key)(state)),
  });

  return connect(mapStateToProps, astActions)(AstClass);
};
