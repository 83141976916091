// Core helpers go in this file

export const createReducerUtil = (initialState, handlers, refreshActionType) =>
  function reducer(state = initialState, action) {
    const shouldRefreshState = refreshActionType &&
      (Array.isArray(refreshActionType) ?
        refreshActionType.includes(action.type) :
        action.type === refreshActionType
      );
    if (shouldRefreshState) {
      return initialState;
    }

    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
