import React, { Component } from 'react';
import { values } from 'lodash';
import styles from './Accordian.module.scss';
import PropTypes from 'prop-types';

const POSITIONS = Object.freeze({
  TOP: 'top',
  BOTTOM: 'bottom',
});

export class Accordian extends Component {
  static propTypes = {
    defaultOpen: PropTypes.bool,
    ClickableComponent: PropTypes.func.isRequired,
    clickableProps: PropTypes.object,
    disable: PropTypes.bool,
    content: PropTypes.node.isRequired,
    position: PropTypes.oneOf(values(POSITIONS)),
    accordianClassName: PropTypes.string,
    className: PropTypes.string,
    nested: PropTypes.bool,
    open: PropTypes.bool,
  };

  static defaultProps = {
    defaultOpen: false,
    className: '',
    accordianClassName: '',
    position: POSITIONS.TOP,
    disable: false,
    clickableProps: {},
    open: null,
  };

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      open: props.defaultOpen,
    };

    this.renderClickable = this.renderClickable.bind(this);
    this.grow = this.grow.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  componentDidMount() {
    this.grow();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disable !== this.props.disable && this.props.disable) {
      this.toggleOpen(true);
    } else {
      this.grow();
    }

    if (this.props.open !== null) {
      if (prevProps.open === true && this.props.open === false) {
        this.toggleOpen(true);
      }
    }
  }

  isOpen() {
    if (this.props.open === null) {
      return this.state.open;
    }
    return this.props.open;
  }

  toggleOpen(forceClose) {
    const inner = this.inner;
    const outer = this.outer;
    const open = forceClose === true ? forceClose : this.isOpen();
    if (inner && outer) {
      if (open) {
        outer.style.height = '0px';
        this.setState({ open: false });
      } else {
        this.grow();
        this.setState({ open: true });
      }
    }
  }

  grow() {
    const inner = this.inner;
    const outer = this.outer;

    if (this.isOpen() && inner && outer) {
      outer.style.height = `${inner.clientHeight}px`;
    }
  }

  renderClickable() {
    const { disable, ClickableComponent, clickableProps } = this.props;

    const open = this.isOpen();

    return (
      <div
        className={disable ? styles.disable : styles.clickable}
        onClick={disable ? null : this.toggleOpen}
      >
        <ClickableComponent
          {...clickableProps}
          open={disable === true ? false : open}
          disable={disable}
        />
      </div>
    );
  }

  render() {
    const { content, position, accordianClassName, className, nested } = this.props;

    const growStyles =
      nested && this.isOpen()
        ? {
          display: 'table',
          width: '100%',
          transition: 'height 0.15s ease-in-out',
        }
        : {};

    return (
      <div className={`${styles.container} ${className}`}>
        {position === POSITIONS.TOP ? this.renderClickable() : null}
        <div
          className={`${styles.items} ${accordianClassName}`}
          style={growStyles}
          ref={(outer) => {
            this.outer = outer;
          }}
        >
          <div
            ref={(inner) => {
              this.inner = inner;
            }}
          >
            {content}
          </div>
        </div>
        {position === POSITIONS.BOTTOM ? this.renderClickable() : null}
      </div>
    );
  }
}

Accordian.POSITIONS = POSITIONS;

export default Accordian;
