/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo } from 'react';
import { useRequest } from '@sixsense/core/request';
import { DefaultRole, App } from '@sixsense/rbac';
import { useDispatch, useSelector } from '@sixsense/core/versioned/react-redux';
import { formattedAllUsersSelector } from '../selectors';
import { User } from '../types';
import { actions as globalActions } from 'modules/global';
import { orgNameSelector } from 'modules/user/selectors';
import { actions } from '../modules';
import { pluralize } from 'utils/utils';
import { forEach } from 'lodash';

type Roles = {
  [key: string]:number
}

type UpdateParams = {
  is_active?: boolean,
  roles?: Roles,
  is_limited?: boolean;
}

const useUserActions = () => {

  const request = useRequest();

  const users: User[] = useSelector(formattedAllUsersSelector);
  const orgName: string = useSelector(orgNameSelector);
  const userMap: { [id: number]: User } = useMemo(() => {
    const out = {};
    forEach(users, (user) => {
      out[user.id] = user;
    });
    return out;
  }, [users]);

  const dispatch = useDispatch();
  const handleResponses = async (
    proms: Promise<any>[],
    successActionWord: string = 'updated',
    failureActionWord: string = 'updating',
  ) => {
    const resps = await Promise.all(proms.map((prom) => prom.then(() => true).catch(() => false)));
    const successCount = resps.filter((success) => success).length;
    const failureCount = resps.length - successCount;
    if (successCount) {
      dispatch(
        globalActions.showNotification(
          'success',
          `Successfully ${successActionWord} ${successCount} ${pluralize('user', successCount > 1)}`
        )
      );
    }
    if (failureCount) {
      dispatch(
        globalActions.showNotification(
          'error',
          `Error ${failureActionWord} ${failureCount} ${pluralize('user', failureCount > 1)}`
        )
      );
    }
    dispatch(actions.loadAllUsers());
    return resps;
  };

  const handleResponsesBulkUpdate = async (
    body: any,
    countUser: any,
    successActionWord: string = 'updated',
    failureActionWord: string = 'updating',
  ) => {
    let resps = {};
    try {
      resps = await request(
        'auth/v3/organizationusers/',
        {
          method: 'PATCH',
          body: JSON.stringify(body),
        }
      );
      dispatch(
        globalActions.showNotification(
          'success',
          `Successfully ${successActionWord} ${countUser} ${pluralize('user', countUser > 1)}`
        )
      );
    } catch (e) {
      dispatch(
        globalActions.showNotification(
          'error',
          `Error ${failureActionWord} ${countUser} ${pluralize('user', countUser > 1)}`
        )
      );
    } finally {
      dispatch(actions.loadAllUsers());
    }
    return resps;
  };

  const updateUsers = useCallback(
    (ids: number[], params: UpdateParams, ...args) => handleResponses(ids.map((id) => request(
      `auth/v3/organizationusers/${id}/`,
      {
        method: 'PATCH',
        body: JSON.stringify(params),
      }
    )), ...args),
  [request]);

  const enableUsers = useCallback(
    (ids: number[]) => updateUsers(ids, { is_active: true }, 'enabled', 'enabling'),
    [updateUsers]
  );

  const disableUsers = useCallback(
    (ids: number[]) => {
      const body: any = {
        organizationuser_ids: ids,
        is_active: false,
      };
      return handleResponsesBulkUpdate(body, ids.length, 'disabled', 'disabling');
    },
    [handleResponsesBulkUpdate]
  );

  const changeUserRoles = useCallback(
    (ids: number[], roles: Roles) => {
      const body: UpdateParams = {
        roles,
      };
      if (roles[`${App.ABM}`] !== null && roles[`${App.ABM}`] !== undefined) {
        body.is_limited = roles[`${App.ABM}`] === DefaultRole.SALES_USER;
      }
      return updateUsers(ids, body);
    },
    [updateUsers]
  );

  const deleteUsers = useCallback((ids: number[]) => handleResponses(ids.map(
    (id) => request(
      `auth/v3/organizationusers/${id}/`,
      { method: 'DELETE' },
    )), 'deleted', 'deleting'),
    [request]
  );

  const reinviteUsers = useCallback((ids: number[]) => handleResponses(ids.map(
    (id) => request(
      'auth/v3/user_invite/',
      {
        method: 'POST',
        body: JSON.stringify({
          email: userMap[id].email,
          organization: orgName,
        }),
      },
    )), 'reinvited', 'reinviting'),
    [request]
  );

  return {
    enableUsers,
    disableUsers,
    changeUserRoles,
    deleteUsers,
    reinviteUsers,
  };
};

export default useUserActions;
