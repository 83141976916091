export const DISPLAY_VALUES_ACTIONS = {
  export_to_crm_enabled: 'Add to Salesforce/HubSpot',
  export_to_sep_enabled: 'Add to SalesLoft/Outreach/Sales Engagement',
  download_enabled: 'Export People and Company Records as CSV',
  export_dashboard_report_enabled: 'Export Dashboard Report as CSV',
  find_on_salesnav_enabled: 'Find on Sales Navigator',
  find_on_linkedin_enabled: 'Find on LinkedIn',
  unlock_enabled: 'Unlock',
  view_contacts_on_salesnav_enabled: 'View similar Contacts on SalesNav',
  view_contacts_on_linkedin_enabled: 'View similar Contacts on LinkedIn',
  no_longer_at_company_enabled: 'Mark - No Longer at the Company',
  switch_to_old_or_new_app: 'Switch old or new app',
  toggle_app_for_only_si_role: 'Toggle app for only si role',
};

export const INTEGRATIONS_LABELS = {
  salesloft: 'Add to SalesLoft',
  outreach: 'Add to Outreach',
  hvs: 'Add to Sales Cadence',
  Salesforce: 'Export to Salesforce',
  hubspot: 'Export to Hubspot',
  Dynamics: 'Export to Dynamics',
  HubspotCrm: 'Export to Hubspot',
};

export const SI_APP_COLUMN_HEADERS = ['Actions', 'Sales Intelligence'];
export const CRM_LINK = '/settings/integration/manage?intType=crm&tabName=all';
export const SEP_LINK = '/settings/integration/manage?intType=sep&tabName=all';

