import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardTitle.module.scss';
import { Text, Tooltip, Icon } from 'v2-components';

function CardTitle(props) {
  const { title, infoTooltipText, children } = props;

  return (
    <div className={styles.outer}>
      <div className={styles.headerContent}>
        <span>
          {title && (
            <Text className={styles.titleText} bold uppercase weight={Text.WEIGHT.BOLD}>
              {title}
            </Text>
          )}
          {infoTooltipText && (
            <Tooltip placement="right" title={infoTooltipText}>
              <span>
                <Icon
                  className={styles.offsetInfoIcon}
                  type="help"
                  size="small"
                  color={Icon.COLORS.BLUE}
                />
              </span>
            </Tooltip>
          )}
        </span>
        {children}
      </div>
    </div>
  );
}

CardTitle.propTypes = {
  title: PropTypes.string,
  infoTooltipText: PropTypes.string,
  children: PropTypes.element,
};

export default CardTitle;
