/**
 * Create the store with asynchronously loaded reducers
 */

import { compact } from 'lodash';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import createReducer from 'reducers';
import { stopTransition, crashReport } from './middleware';
import { persistStore } from 'redux-persist';
import { getLogRocketMiddleware } from 'lib/logrocket';
import { isLocal } from 'utils/utils';
import { Url } from 'url';

const sagaMiddleware = createSagaMiddleware();

// not really necessary as this doesn't break, but will prevent some noise in #aa
const url = new Url(window?.location?.href);
const notProduction = url.host !== 'abm.6sense.com';

export default function configureStore(initialState = {}, history) {
  const middlewares = [
    stopTransition,
    isLocal() ? 0 : crashReport,
    sagaMiddleware,
    routerMiddleware(history),
    getLogRocketMiddleware(),
  ];

  const enhancers = [applyMiddleware(...compact(middlewares))];

  // If Redux DevTools Extension is installed use it,
  // otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    notProduction &&
      typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers));
  persistStore(store);

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.asyncReducers = {}; // Async reducer registry

  if (module.hot) {
    module.hot.accept('reducers', () => {
      import('reducers').then((reducerModule) => {
        const createReducers = reducerModule.default;
        const nextReducers = createReducers(store.asyncReducers);

        store.replaceReducer(nextReducers);
      });
    });
  }

  return store;
}
