import React from 'react';
import EmptyTacticImage from './images/empty_tactic.svg';

import styles from './OrchestrationIconStyles.module.scss';

type EmptyTacticProps = {
  className?: string,
  size?: 'small' | 'medium' | 'large',
}

function EmptyTactic({ className, size }: EmptyTacticProps) {
  return (
    <img
      src={EmptyTacticImage}
      alt="empty_tactic"
      className={`${styles.emptyTacticIcon} ${styles[size]} ${styles.beta} ${className} `}
    />
  );
}

export default EmptyTactic;
