import React from 'react';
import Text from 'v2-components/Text';
import { localeString } from 'utils/utils';
import { NA } from './common/NA';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';
import { isDraftOrScheduledCampaign } from '../../../utils';

export const ACCOUNTS_REACHED = {
  sortByKey: 'accounts_reached',
  customizable: true,
  key: 'accounts-reached',
  csvColumnKey: 'account_reached',
  defaultSelected: true,
  title: <Label name={CAMPAIGN_LABELS.accountsReached} />,
  dataIndex: 'campaign_data.accounts_reached',
  width: 200,
  render: (accountsReached, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (isDraftOrScheduledCampaign(campaignStatus)) {
      return (<NA />);
    }

    return (<Text
      type={Text.TYPE.SUBBODY}
    >
      {localeString(accountsReached)}
    </Text>);
  },
};
