export default function createRoutes() {
  return {
    path: 'details',
    name: 'details',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('../../containers/ObjectDetails/containers');
          cb(null, containers.ObjectDetails);
        },
        'settings_standardization_details'
      );
    },
  };
}
