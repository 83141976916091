export default function createRoutes() {
  return {
    path: 'slack',
    name: 'Slack Settings',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const SlackContainer = require('./containers/SlackContainer');
          cb(null, SlackContainer.default);
        },
        'slack-integration',
      );
    },
  };
}
