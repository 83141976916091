import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';

const FLEX_DIRECTION = Object.freeze({
  ROW: 'row',
  ROW_REVERSE: 'row-reverse',
  COLUMN: 'column',
  COLUMN_REVERSE: 'column-reverse',
});

const FLEX_WRAP = Object.freeze({
  NOWRAP: 'nowrap',
  WRAP: 'wrap',
  WRAP_REVERSE: 'wrap-reverse',
});

const JUSTIFY_CONTENT = Object.freeze({
  FLEX_START: 'flex-start',
  FLEX_END: 'flex-end',
  CENTER: 'center',
  SPACE_BETWEEN: 'space-between',
  SPACE_AROUND: 'space-around',
  SPACE_EVENLY: 'space-evenly',
});

const ALIGN_ITEMS = Object.freeze({
  FLEX_START: 'flex-start',
  FLEX_END: 'flex-end',
  CENTER: 'center',
  BASELINE: 'baseline',
  STRETCH: 'stretch',
});

const ALIGN_CONTENT = Object.freeze({
  FLEX_START: 'flex-start',
  FLEX_END: 'flex-end',
  CENTER: 'center',
  STRETCH: 'stretch',
  SPACE_BETWEEN: 'space-between',
  SPACE_AROUND: 'space-around',
  SPACE_EVENLY: 'space-evenly',
});

/**
 * @param {bool} fill - will apply width: 100%
*/
function Row(props) {
  const {
    className,
    children,
    flexDirection = FLEX_DIRECTION.ROW,
    flexWrap = FLEX_WRAP.NOWRAP,
    justifyContent = JUSTIFY_CONTENT.FLEX_START,
    alignItems = ALIGN_ITEMS.STRETCH,
    alignContent = ALIGN_CONTENT.STRETCH,
    onClick,
    style,
    fill,
    ...rest
  } = props;

  const rowStyle = {
    ...style,
    display: 'flex',
    flexDirection,
    flexWrap,
    justifyContent,
    alignItems,
    alignContent,
  };

  // need to do it this way to remain compatible in places width has been
  // passed in the style prop
  if (fill) {
    rowStyle.width = '100%';
  }

  return (
    <div onClick={onClick} style={rowStyle} className={className} {...rest}>
      {children}
    </div>
  );
}

Row.propTypes = {
  flexWrap: PropTypes.oneOf(values(FLEX_WRAP)),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  flexDirection: PropTypes.oneOf(values(FLEX_DIRECTION)),
  justifyContent: PropTypes.oneOf(values(JUSTIFY_CONTENT)),
  alignItems: PropTypes.oneOf(values(ALIGN_ITEMS)),
  alignContent: PropTypes.oneOf(values(ALIGN_CONTENT)),
  style: PropTypes.any,
  onClick: PropTypes.func,
  fill: PropTypes.bool,
};

Row.FLEX_DIRECTION = FLEX_DIRECTION;
Row.FLEX_WRAP = FLEX_WRAP;
Row.JUSTIFY_CONTENT = JUSTIFY_CONTENT;
Row.ALIGN_ITEMS = ALIGN_ITEMS;
Row.ALIGN_CONTENT = ALIGN_CONTENT;
export default Row;
