import { combineReducers } from 'redux';
import {
  loadEventJobsCountStateGenerator,
  loadInitJobsStateGenerator,
  loadMoreJobsStateGenerator,
  loadProductJobsCountStateGenerator,
} from '../stateGenerators';
import {
  actionTypes as jobsListActionTypes,
  actions as jobsListActions,
  jobsListReducer,
} from './jobsList';

export const jobsReducer = combineReducers({
  jobsList: jobsListReducer,
  [loadInitJobsStateGenerator.stateKey]: loadInitJobsStateGenerator.reducer,
  [loadMoreJobsStateGenerator.stateKey]: loadMoreJobsStateGenerator.reducer,
  [loadEventJobsCountStateGenerator.stateKey]: loadEventJobsCountStateGenerator.reducer,
  [loadProductJobsCountStateGenerator.stateKey]: loadProductJobsCountStateGenerator.reducer,
});

export const actions = {
  ...jobsListActions,
};

export const actionTypes = {
  ...jobsListActionTypes,
};
