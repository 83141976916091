import React from 'react';

/**
 * @param {*} hideWhen function that accepts props, will hide if returns true
 */
const HideWhen = (hideWhen) => (WrappedComponent) => (props) => {
  if (hideWhen(props)) {
    return null;
  }
  return <WrappedComponent {...props} />;
};

export default HideWhen;
