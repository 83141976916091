import moment from 'moment';
import React from 'react';
import { SixsenseColor, ThemeColor } from '@sixsense/core/style';
import {
  Card,
  Divider,
  Flex,
  Button,
  Tooltip,
  Icon,
  Badge,
} from '@sixsense/core/components';
import { useSharedState } from '@sixsense/core';
import { PermissionBoundary, Permission } from '@sixsense/rbac';
import {
  allocateSiCreditsModalOpenState,
  dataCreditsUsageState,
  distributeDrawerOpenState,
} from '../state';
import { CreditDisplayWithInfo } from './CreditDisplayWithInfo';
import { TCreditConfig } from '../types';

const styles = {
  card: (isDisabled: boolean) => ({
    backgroundColor: isDisabled ? SixsenseColor.Gray.LIGHT_4 : 'auto',
    margin: 'unset',
    width: '100%',
    pointerEvents: isDisabled ? 'none' : 'auto', // Disable click and hover
    opacity: isDisabled ? 0.6 : 1, // Optional: add opacity to indicate it's disabled
  }),
};

const CreditDetailsComponent = ({
  creditData,
  sections,
  label,
  canDistributeCredits,
  canAllocateCreditsToSiUsers,
  tooltip,
  isDisabled,
}: TCreditConfig) => {
  const [, setIsAllocateDrawerOpen] = useSharedState(allocateSiCreditsModalOpenState);
  const [, setIsDistributeDrawerOpen] = useSharedState(distributeDrawerOpenState);
  const [dataCreditsUsageFlag] = useSharedState(dataCreditsUsageState);
  const creditInfo = {
    total_allocated_credits:
      creditData.total_allocated_credits || 0,
    total_used_credits: creditData.total_used_credits || 0,
    total_credits: creditData.total_credits || 0,
    expiry_date: creditData.expiry_date
      ? moment.utc(creditData.expiry_date).format('MMM DD, YYYY')
      : '-',
    updated: creditData.updated
      ? moment(creditData.updated).format('MMM DD, YYYY')
      : '-',
    total_unallocated_credits: creditData.total_unallocated_credits || 0,
    total_balance_credits: creditData.total_balance_credits || 0,
  };


  return (
    <Flex direction="column">
      <Card style={styles.card(isDisabled)} onSelect={function noRefCheck() {}}>
        {label ? (
          <Card.Header divider={false}>
            <Card.Header.Title style={{ textTransform: 'none' }}>
              <Flex alignItems={'center'} justifyContent={'space-between'} gap={20}>
                <Flex gap={5} alignItems="center">
                  {label}
                  <Tooltip
                    placement="top"
                    trigger={['hover']}
                    overlayStyle={{ maxWidth: '300px' }}
                    overlay={tooltip}
                  >
                    <Icon
                      style={{ cursor: 'pointer' }}
                      className="ml2"
                      type={'MdInfoOutline'}
                      size={'small'}
                    />
                  </Tooltip>
                  {isDisabled && <Badge color={'gray'}>Disabled</Badge>}
                </Flex>
                <Flex>
                  {canAllocateCreditsToSiUsers && (
                    <PermissionBoundary
                      allow={(p) =>
                        p.has(Permission.SETTINGS_CREDITS_ALLOCATE_TO_SI_USERS)
                      }
                    >
                      {() => (
                        <Button
                          className="ml4"
                          hierarchy="secondary"
                          onClick={() => setIsAllocateDrawerOpen(true)}
                          disabled={isDisabled}
                        >
                          Allocate by User
                        </Button>
                      )}
                    </PermissionBoundary>
                  )}
                  {dataCreditsUsageFlag && canDistributeCredits && (
                    <PermissionBoundary
                      allow={(p) =>
                        p.has(Permission.SETTINGS_CREDITS_DISTRIBUTE)
                      }
                    >
                      {() => (
                        <Button
                          hierarchy="primary"
                          onClick={() => setIsDistributeDrawerOpen(true)}
                          disabled={creditData.is_expired}
                        >
                          Distribute Credits
                        </Button>
                      )}
                    </PermissionBoundary>
                  )}
                </Flex>
              </Flex>
            </Card.Header.Title>
          </Card.Header>
        ) : (
          <></>
        )}
        <Card.Content>
          <Flex justifyContent={'space-between'} gap={20}>
            <Flex gap={50} justifyContent={'flex-start'} className="w100" wrap="wrap">
              {sections
                .map((section, index) => (
                  <Flex>
                    {index!== 0 && <span className="mr4">
                      <Divider direction={'vertical'} color={ThemeColor.Gray.LIGHT_2} />
                    </span>}
                    <CreditDisplayWithInfo
                      label={section.label}
                      value={creditInfo[section.key]}
                    />
                  </Flex>
                ))}
            </Flex>
          </Flex>
        </Card.Content>
      </Card>
    </Flex>

  );
};

export default CreditDetailsComponent;
