import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Row,
  CustomScrollBar,
  Select,
  Button,
  Tooltip2 as Tooltip,
  Icon,
  Link,
} from 'v2-components';
import styles from './BulkAction.module.scss';
import { connect } from 'react-redux';
import {
  appsListSelector,
  isAppEditUserPermissionMapSelector,
  adminRoleIdListSelector,
  isAppAdminAssignmentUserPermissionMapSelector,
  getIsAppLicenseUserLimitExceedMapSelector,
} from '../../selectors';
import { useSelector } from '@sixsense/core/versioned/react-redux';
import useUserActionOptions from '../../hooks/useUserActionOption';
import {
  BULK_ACTION_DEFAULT_FILTER,
  DUMMY_SELECT_VALUE,
  MAX_USER_SELECT_LIMIT_FOR_BULK_UPDATE,
  NoRoleAssignSelectedOptionForEditUserValue,
} from '../../constants';
import { Grid } from '@sixsense/core/components';
import { classNames } from 'utils/utils';
import { cloneDeep, forOwn, forEach, flatten } from 'lodash';
import {
  isViewOnlyEnabledFor6senseAccessSelector,
   userObjectSelector } from 'modules/user/selectors';
import { BulkUpdateErrorMsgObject } from '../Messages';
import { reduceAppListToObject } from '../../utils';
// eslint-disable-next-line max-len
import { AppsSingleRolesDropdownComponent } from '../Utils-Components/RolesDropdown/AppsSingleRolesDropdown';

const BulkActionComponent = (props) => {
  const {
    changeVisible,
    visibility,
    selectedUsers,
    isSAML,
    setFilterApplied,
    users,
  } = props;

  const { username: logedInUserName } = useSelector(userObjectSelector);
  const isViewOnlyEnabledFor6senseAccess = useSelector(isViewOnlyEnabledFor6senseAccessSelector);

  const appsList = useSelector(appsListSelector);

  const adminRoleIdList = useSelector(adminRoleIdListSelector);

  const canEditApps = useSelector(isAppEditUserPermissionMapSelector);

  const canAssignAdminApps = useSelector(
    isAppAdminAssignmentUserPermissionMapSelector
  );
  const getIsAppLicenseUserLimitExceedMap = useSelector(
    getIsAppLicenseUserLimitExceedMapSelector
  );

  const selectedUsersObjectList = (users || []).filter(({ id }) =>
    selectedUsers.has(id)
  );

  const isLogedInUserSelected = selectedUsersObjectList.find(
    ({ username }) => username === logedInUserName
  );

  const isMaxUserSelectLimitExceed =
    selectedUsers.size > MAX_USER_SELECT_LIMIT_FOR_BULK_UPDATE;

  // Initial State Setting start
  const [actionFilter, setActionFilter] = useState(
    BULK_ACTION_DEFAULT_FILTER.values.actionFilter
  );
  const [roleFilter, setRoleFilter] = useState(
    cloneDeep(BULK_ACTION_DEFAULT_FILTER.values.roleFilter)
  );

  const [isAppLicenseUserLimitExceedMap, isAnyAppLicenseUserLimitExceed] = React.useMemo(() => {
    // licenseing precompute

    const actionFilterStatusMap = {
      enable: 'enabled',
      disable: 'disabled',
      delete: 'deleted',
      reinvite: 'reinvited',
    };

    const appsLicenseUserMapList = selectedUsersObjectList.map(
      ({ status, roleIdMap }) =>
        appsList.map(({ id: appId }) => {
          let newRoleId = -1;
          if (
            roleFilter[appId] !== undefined &&
            roleFilter[appId] !== null &&
            roleFilter[appId] !== DUMMY_SELECT_VALUE
          ) {
            newRoleId = roleFilter[appId];
          }
          if (actionFilter === DUMMY_SELECT_VALUE) {
            return [status, 1, roleIdMap[appId], newRoleId];
          }

          return [
            actionFilterStatusMap[actionFilter],
            1,
            roleIdMap[appId],
            newRoleId,
          ];
        })
    );
    return getIsAppLicenseUserLimitExceedMap(flatten(appsLicenseUserMapList));
  }, [
    actionFilter,
    appsList, getIsAppLicenseUserLimitExceedMap, selectedUsersObjectList, roleFilter]);

  // Initial State Setting end

  const { options: actionOptions } = useUserActionOptions(
    selectedUsers,
    isSAML,
    isAnyAppLicenseUserLimitExceed

  );

  React.useEffect(() => {
    // resetting
    setActionFilter(BULK_ACTION_DEFAULT_FILTER.values.actionFilter);
    setRoleFilter(cloneDeep(BULK_ACTION_DEFAULT_FILTER.values.roleFilter));
  }, [visibility]);

  const isSelectionDefault =
    BULK_ACTION_DEFAULT_FILTER.isEqualFuncObject.actionFilter(actionFilter) &&
    BULK_ACTION_DEFAULT_FILTER.isEqualFuncObject.roleFilter(roleFilter);

  const checkClickedOutside = (ref, visibile) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          if (visibile) {
            changeVisible(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () =>
        document.removeEventListener('mousedown', handleClickOutside);
    }, [ref, visibile]);
  };

  const wrapperRef = useRef(null);
  checkClickedOutside(wrapperRef, visibility);

  const getPerAppPossibleGhostUser = () => {
    const noRoleAssignAppIds = [];
    forOwn(roleFilter, (value, key) => {
      if (roleFilter[key] === NoRoleAssignSelectedOptionForEditUserValue) {
        noRoleAssignAppIds.push(key);
      }
    });

    if (noRoleAssignAppIds.length === 0) {
      return {
        isError: false,
        msg: '',
        showMsg: false,
      };
    }

    const ghostUserEmailsList = selectedUsersObjectList
      .filter(({ roleIdMap }) =>
        appsList.every(({ id: appId }) => {
          if (roleFilter[appId] === -1) {
            return true;
          }
          if (
            roleFilter[appId] === undefined ||
            roleFilter[appId] === null ||
            roleFilter[appId] === DUMMY_SELECT_VALUE
          ) {
            return roleIdMap[appId] === -1;
          }
          return false;
        })
      )
      .map(({ username }) => username);

    if (ghostUserEmailsList.length === 0) {
      return {
        isError: false,
        msg: '',
        showMsg: false,
      };
    }
    return {
      isError: true,
      msg: BulkUpdateErrorMsgObject.PossibleGhostUserList(ghostUserEmailsList),
      showMsg: true,
    };
  };

  const getExpiredInviteDisableMsg = () => {
    // expired user list.
    const expiredInviteUserList = [];
    forEach(selectedUsersObjectList, ({ status, username }) => {
      if (status === 'inviteExpired') {
        expiredInviteUserList.push(username);
      }
    });
    if (expiredInviteUserList.length > 0) {
      return {
        disabled: true,
        msg: BulkUpdateErrorMsgObject.expiredUserRoleDisabledSelected(
          expiredInviteUserList
        ),
        showMsg: true,
      };
    }
    return {
      disabled: false,
      msg: '',
      showMsg: false,
    };
  };

  const expiredInviteDisableMsgObject = getExpiredInviteDisableMsg();

  const {
    isError: isGhostUserPossible,
    msg: ghostUserErrorMsg,
    showMsg: showGhostUserMsg,
  } = getPerAppPossibleGhostUser();

  const rolesDisabledByAppIdMap = React.useMemo(() => {
    // admin assignment precompute
    const adminListSelectedAppMap = {};

    forEach(appsList, ({ id: appId }) => {
      forEach(selectedUsersObjectList, ({ roleIdMap, username }) => {
        if (adminRoleIdList.includes(roleIdMap[appId])) {
          if (adminListSelectedAppMap[appId]) {
            adminListSelectedAppMap[appId].push(username);
          } else {
            adminListSelectedAppMap[appId] = [username];
          }
        }
      });
    });

    const getLicenseingDisabledObject = (appId, shortName) => {
      let disableObj = null;
      forOwn(
        isAppLicenseUserLimitExceedMap[appId],
        (isAppLicenseUserLimitExceed, licenseLabel) => {
          if (
            isAppLicenseUserLimitExceed
          ) {
            disableObj = {
              saveButtonDisable: true,
              msg: BulkUpdateErrorMsgObject.LicenseingLimitExceed(
                shortName,
                licenseLabel
              ),
              showMsg: true,
            };
          }
        }
      );
      if (disableObj) {
        return disableObj;
      }
      return null;
    };

    const disabledRoleByAppId = (appId, shortName) => {
      if (!selectedUsers.size) {
        return {
          disabled: true,
          msg: '',
          showMsg: false,
        };
      }
      if (!canEditApps[appId]) {
        return {
          disabled: true,
          msg: '',
          showMsg: false,
        };
      }

      if (
        actionFilter === 'disable' ||
        actionFilter === 'delete' ||
        actionFilter === 'reinvite'
      ) {
        return {
          disabled: true,
          msg: '',
          showMsg: false,
        };
      }

      const isAdminSelectedApp =
        adminListSelectedAppMap[appId] &&
        adminListSelectedAppMap[appId].length > 0;
      if (!canAssignAdminApps[appId] && isAdminSelectedApp) {
        return {
          disabled: true,
          msg: BulkUpdateErrorMsgObject.AdminAssignNoAccess(
            adminListSelectedAppMap,
            appId,
            shortName
          ),
          showMsg: true,
          disabledChooseAction: true,
        };
      }

      const licenseingDisabledObject = getLicenseingDisabledObject(
        appId,
        shortName
      );

      if (licenseingDisabledObject) {
        return licenseingDisabledObject;
      }
      return {
        disabled: false,
        msg: '',
        showMsg: false,
      };
    };
    const rolesDisabledByAppIdMapLocal = {};
    forEach(appsList, ({ id: appId, shortName }) => {
      rolesDisabledByAppIdMapLocal[appId] = disabledRoleByAppId(
        appId,
        shortName
      );
    });
    return rolesDisabledByAppIdMapLocal;
  }, [
    appsList,
    actionFilter,
    adminRoleIdList,
    canAssignAdminApps,
    canEditApps,
    selectedUsers.size,
    selectedUsersObjectList,
    isAppLicenseUserLimitExceedMap,
  ]);

  const getDisabledUserSelected = () => {
    const disabledUserList = selectedUsersObjectList
      .filter(
        ({ status }) => status === 'disabled' && actionFilter !== 'enable'
      )
      .map(({ username }) => username);
    if (disabledUserList && disabledUserList.length > 0) {
      return {
        disabled: true,
        msg: BulkUpdateErrorMsgObject.disabledUserSelected(disabledUserList),
        showMsg: true,
      };
    }
    return {
      disabled: false,
      msg: '',
      showMsg: false,
    };
  };

  const disabledUserSelectedErrorObject = getDisabledUserSelected();

  React.useEffect(() => {
    if (rolesDisabledByAppIdMap && disabledUserSelectedErrorObject) {
      const updatedRoles = {};
      let isDiff = false;
      forEach(appsList, ({ id: appId }) => {
        updatedRoles[appId] = roleFilter[appId];
        if (disabledUserSelectedErrorObject.disabled) {
          updatedRoles[appId] = DUMMY_SELECT_VALUE;
        }
        if (rolesDisabledByAppIdMap[appId].disabled) {
          updatedRoles[appId] = DUMMY_SELECT_VALUE;
        }
        if (updatedRoles[appId] !== roleFilter[appId]) {
          isDiff = true;
        }
      });
      if (isDiff) {
        setRoleFilter(updatedRoles);
      }
    }
  }, [
    rolesDisabledByAppIdMap,
    disabledUserSelectedErrorObject,
    appsList,
    roleFilter,
  ]);

  const renderRolesPerAppDropdown = () => (
    <Grid.Cell
      className={classNames(
        styles.label,
        styles.multiAppRoleDropDown,
        styles.w100
      )}
    >
      <AppsSingleRolesDropdownComponent
        getRoleTitle={(sn) => `Change ${sn} role`}
        appsDisabledMap={reduceAppListToObject(
          appsList,
          (appId) =>
            rolesDisabledByAppIdMap[appId].disabled ||
            disabledUserSelectedErrorObject.disabled ||
            expiredInviteDisableMsgObject.disabled
        )}
        appsOnChangeMap={reduceAppListToObject(
          appsList,
          (appId) => (val) =>
            setRoleFilter((prev) => ({
              ...prev,
              [appId]: val,
            }))
        )}
        appsValueMap={reduceAppListToObject(
          appsList,
          (appId) => roleFilter[appId] || DUMMY_SELECT_VALUE
        )}
        wrapperRef={wrapperRef}
        isNoRoleAssign
        isSelectRole
        appsList={appsList}
      />
    </Grid.Cell>
  );

  const title = (
    <Row
      justifyContent={Row.JUSTIFY_CONTENT.SPACE_BETWEEN}
      alignItems={Row.ALIGN_ITEMS.CENTER}
      className={styles.filterRow}
    >
      <Text className={classNames(styles.boldText)}>Bulk Actions</Text>
      <Row
        onClick={() => {
          setFilterApplied(BULK_ACTION_DEFAULT_FILTER.values);
          setActionFilter(BULK_ACTION_DEFAULT_FILTER.values.actionFilter);
          setRoleFilter(
            cloneDeep(BULK_ACTION_DEFAULT_FILTER.values.roleFilter)
          );
        }}
      >
        <Text
          onClick={() => {}}
          className={classNames(
            isSelectionDefault ? styles.disabledText : null,
            styles.resetText
          )}
        >
          Reset All
        </Text>
      </Row>
    </Row>
  );

  const getToolTipMsg = () => (
    <React.Fragment>
      Make sure the list of users selected are in the allowed status if a
      specific action is not available to you.{' '}
      <Link
        link={
          // eslint-disable-next-line max-len
          'https://support.6sense.com/knowledge-base/1500002052742-user-management-rbac/#bulk-change-user-roles'
        }
        newWindow
      >
        Learn More
      </Link>
    </React.Fragment>
  );

  const statusRender = (
    <Row
      flexDirection={Row.FLEX_DIRECTION.COLUMN}
      alignItems={Row.ALIGN_ITEMS.FLEX_START}
      className={styles.filterRow}
    >
      <Text
        className={classNames(styles.normalText, styles.mb8, styles.flexCenter)}
      >
        Choose Action{' '}
        <Tooltip overlay={getToolTipMsg()}>
          <span className={classNames(styles.flexCenter)}>
            <Icon type="help" className={styles.tooltip} />
          </span>
        </Tooltip>
      </Text>
      <Select
        disabled={
          !selectedUsers.size ||
          appsList.some(
            ({ id: appId }) =>
              rolesDisabledByAppIdMap[appId].disabledChooseAction
          )
        }
        options={actionOptions}
        onChange={(val) => setActionFilter(val)}
        value={actionFilter || DUMMY_SELECT_VALUE}
        width={'187px'}
        dropdownMatchSelectWidth={false}
        getPopupContainer={() => wrapperRef.current}
        containerClassName={classNames(styles.customStyleSelector)}
      />
    </Row>
  );

  const rolesRender = (
    <Row
      className={classNames(styles.w100)}
      flexDirection={Row.FLEX_DIRECTION.COLUMN}
    >
      <Row className={classNames(styles.mt8, styles.w100)}>
        {renderRolesPerAppDropdown()}
      </Row>
    </Row>
  );

  const body = (
    <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
      {statusRender}
      <Row
        justifyContent={Row.JUSTIFY_CONTENT.CENTER}
        alignItems={Row.ALIGN_ITEMS.FLEX_START}
        className={styles.filterRow}
        flexDirection={Row.FLEX_DIRECTION.COLUMN}
      >
        {rolesRender}
        {appsList.map(({ id: appId }) => {
          const { showMsg, msg } = rolesDisabledByAppIdMap[appId];
          if (showMsg) {
            return msg;
          }
          return null;
        })}
        {isLogedInUserSelected
          ? BulkUpdateErrorMsgObject.MySelfEmailSelected()
          : null}
        {isMaxUserSelectLimitExceed
          ? BulkUpdateErrorMsgObject.MaxSelectionLimitExceed()
          : null}
        {showGhostUserMsg ? ghostUserErrorMsg : null}
        {disabledUserSelectedErrorObject.showMsg
          ? disabledUserSelectedErrorObject.msg
          : null}
        {expiredInviteDisableMsgObject.showMsg
          ? expiredInviteDisableMsgObject.msg
          : null}
      </Row>
    </Row>
  );

  const footer = (
    <Row
      justifyContent={Row.JUSTIFY_CONTENT.FLEX_END}
      alignItems={Row.ALIGN_ITEMS.CENTER}
      className={`${styles.filterRow} ${styles.advSearchFooter}`}
    >
      <Button
        className={styles.cancelButton}
        onClick={() => {
          changeVisible(false);
          setFilterApplied(BULK_ACTION_DEFAULT_FILTER);
        }}
      >
        Cancel
      </Button>
      <Button
        type={Button.PRIMARY}
        onClick={() => {
          setFilterApplied({
            actionFilter,
            roleFilter,
          });
          changeVisible(false);
        }}
        disabled={
          !selectedUsers.size ||
          isSelectionDefault ||
          isLogedInUserSelected ||
          isMaxUserSelectLimitExceed ||
          isGhostUserPossible ||
          disabledUserSelectedErrorObject.disabled ||
          appsList.some(
            ({ id: appId }) => rolesDisabledByAppIdMap[appId].saveButtonDisable
          ) ||
          isViewOnlyEnabledFor6senseAccess
        }
      >
        Save
      </Button>
    </Row>
  );
  return (
    <div
      ref={wrapperRef}
      className={`${styles.container} ${!visibility ? styles.hidden : ''}`}
    >
      <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
        {title}
        <CustomScrollBar className={styles.scrollbarContainer}>
          {body}
        </CustomScrollBar>
        {footer}
      </Row>
    </div>
  );
};

BulkActionComponent.propTypes = {
  changeVisible: PropTypes.func,
  visibility: PropTypes.bool,
  selectedUsers: PropTypes.object,
  isSAML: PropTypes.bool,
  setFilterApplied: PropTypes.func,
  users: PropTypes.array,
};

const mapStateToProps = () => ({});

export const BulkAction = connect(mapStateToProps, null)(BulkActionComponent);
