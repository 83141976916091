import { get } from "lodash";
import { stateGenerator } from "../stateGenerator";
import { CAMPAIGN_BULK_UPDATE_ACTION_TYPE } from "./types";
import { maybePlural } from "utils/utils";
import { actions as globalActions } from "modules/global";
import { selectors } from "./selectors";
import { NOTIFICATION_TYPE } from "modules/global/constants";
import { resetCampaignListSelections } from "../routes/ListCampaigns/actions";

const { showNotification } = globalActions;

const bulkUpdateStateGenerator = stateGenerator({
  actionPrefix: CAMPAIGN_BULK_UPDATE_ACTION_TYPE,
  endpointSelector: selectors.bulkUpdateEndpointSelector,
  stateKey: "campaignsBulkUpdate",
  baseStateSelector: (state) => state.campaigns,
  method: "PATCH",
  onSuccessActions: [
    (_, rootAction) => {
      const uiAction = get(rootAction, "payload[0].actions[0]", "");
      const campaigns = maybePlural(
        get(rootAction, "payload.length"),
        "Campaign"
      );
      return showNotification(
        NOTIFICATION_TYPE.SUCCESS,
        `Successfully ${uiAction}d ${campaigns}`
      );
    },
    () => resetCampaignListSelections(),
  ],
  onFailureActions: [
    (e, rootAction) => {
      const uiAction = get(rootAction, "payload[0].actions[0]", "");
      const campaigns = maybePlural(
        get(rootAction, "payload.length"),
        "Campaign"
      );
      const message = uiAction
        ? `Failed to ${uiAction} ${campaigns}.`
        : "Failed to complete the action.";
      // TODO: Need a better way to receive error messages from the backend.
      const description = get(e, "nativeError.body.0.campaign[0]");
      return showNotification(NOTIFICATION_TYPE.ERROR, message, description);
    },
  ],

  requestOptions: (_, { payload }) => ({ body: JSON.stringify(payload) }),
});

export const stateGenerators = {
  bulkUpdateStateGenerator,
};
