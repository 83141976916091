import { zipObject } from 'lodash';
import PropTypes from 'prop-types';

export const EDIT = 'edit';
export const CREATE = 'create';
export const EDIT_CUSTOM_CRM = 'edit custom crm';
export const EDIT_CUSTOM_MAP = 'edit custom map';
export const MP_SUPPORT_URL =
  'https://support.6sense.com/hc/en-us/articles/360045344214-Setting-up-Mapping-Profiles';

const SALESFORCE = 'salesforce';
const DYNAMICS = 'dynamics';
const ELOQUA = 'eloqua';
const MARKETO = 'marketo';
const PARDOT = 'pardot';
const HUBSPOT = 'hubspot';
const HUBSPOT_CRM = 'hubspotcrm';
const SALESLOFT = 'salesloft';
const OUTREACH = 'outreach';
const CONVERSATIONAL_EMAIL = 'conversational_email';

export const SYSTEMS = {
  SALESFORCE,
  DYNAMICS,
  ELOQUA,
  MARKETO,
  PARDOT,
  HUBSPOT,
  HUBSPOT_CRM,
  SALESLOFT,
  OUTREACH,
  CONVERSATIONAL_EMAIL,
};

export const CRM_SYSTEMS = [
  SYSTEMS.SALESFORCE,
  SYSTEMS.DYNAMICS,
  SYSTEMS.HUBSPOT_CRM,
];

export const MAPPING_DESCRIPTION = `Set up mapping profiles to map 6sense
fields with your CRM/MAP/SEP fields.`;
export const CUSTOM_CATEGORIES = {
  EMPLOYEES: 'Employee Range',
  REVENUE: 'Revenue Range',
  INDUSTRY_LEGACY: 'Industry (Legacy)',
  INDUSTRY_V2: 'Primary Industry',
  COUNTRY: 'Country',
  STATE: 'State',
};
// category => tab display name
export const objectTypeToTabMap = {
  employees: CUSTOM_CATEGORIES.EMPLOYEES,
  revenue: CUSTOM_CATEGORIES.REVENUE,
  industry: CUSTOM_CATEGORIES.INDUSTRY_LEGACY,
  industry_v2: CUSTOM_CATEGORIES.INDUSTRY_V2,
  country: CUSTOM_CATEGORIES.COUNTRY,
  state: CUSTOM_CATEGORIES.STATE,
};
// tab display name => category
export const tabMap = {
  [CUSTOM_CATEGORIES.COUNTRY]: 'country',
  [CUSTOM_CATEGORIES.STATE]: 'state',
  [CUSTOM_CATEGORIES.EMPLOYEES]: 'employees',
  [CUSTOM_CATEGORIES.REVENUE]: 'revenue',
  [CUSTOM_CATEGORIES.INDUSTRY_LEGACY]: 'industry',
  [CUSTOM_CATEGORIES.INDUSTRY_V2]: 'industry_v2',
};
// tab display names => api lits name (move this)
export const valuesTabMap = {
  [CUSTOM_CATEGORIES.COUNTRY]: 'country_list',
  [CUSTOM_CATEGORIES.INDUSTRY_LEGACY]: 'industry_list',
  // [CUSTOM_CATEGORIES.INDUSTRY_V2]: 'industry_list',
  [CUSTOM_CATEGORIES.STATE]: 'country_list',
  [CUSTOM_CATEGORIES.EMPLOYEES]: 'employees',
  [CUSTOM_CATEGORIES.REVENUE]: 'revenue',
};
// types
export const CartFieldMappingType = PropTypes.shape({
  leadField: PropTypes.string.isRequired,
  leadFieldLabel: PropTypes.string.isRequired,
  leadFieldType: PropTypes.string.isRequired,
  systemField: PropTypes.string.isRequired,
  systemFieldLabel: PropTypes.string.isRequired,
  systemFieldType: PropTypes.string.isRequired,
  custom: PropTypes.bool.isRequired,
});

export const ProfileType = PropTypes.shape({
  mappingId: PropTypes.number.isRequired,
  mappingName: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  systemName: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  cartMappingFields: PropTypes.arrayOf(CartFieldMappingType),
});

export const SystemFieldType = PropTypes.shape({
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
});

export const _statelessCountries = [
  'AN', 'AQ', 'AW', 'BL', 'BV', 'CC', 'CS', 'CW', 'CX', 'EH', 'FK', 'GI',
  'GS', 'HM', 'IO', 'MF', 'MO', 'NF', 'NU', 'PN', 'SX', 'TC', 'VA', 'VG',
];
const _pairs = Array(_statelessCountries.length).fill(true);
export const statelessCountries = zipObject(_statelessCountries, _pairs);

export const SALESFORCE_ACCOUNT_MAPPING_OPTION = {
  label: 'Salesforce Account',
  value: 'salesforce_account',
};
export const HUBSPOT_CRM_ACCOUNT_MAPPING_OPTION = {
  label: 'Hubspot CRM Company (Account)',
  value: 'hubspotcrm_account',
};
export const DYNAMICS_ACCOUNT_MAPPING_OPTION = {
  label: 'Microsoft Dynamics Account',
  value: 'dynamics_account',
};
export const mpTypeOptions = {
  [SYSTEMS.SALESFORCE]: [
    SALESFORCE_ACCOUNT_MAPPING_OPTION,
    { label: 'Salesforce Contact', value: 'salesforce_contact' },
    { label: 'Salesforce Lead', value: 'salesforce_lead' },
  ],
  [SYSTEMS.DYNAMICS]: [
    DYNAMICS_ACCOUNT_MAPPING_OPTION,
    { label: 'Microsoft Dynamics Contact', value: 'dynamics_contact' },
    { label: 'Microsoft Dynamics Lead', value: 'dynamics_lead' },
  ],
  [SYSTEMS.ELOQUA]: [
    { label: 'Eloqua Lead', value: 'eloqua_lead' },
  ],
  [SYSTEMS.MARKETO]: [
    { label: 'Marketo Lead', value: 'marketo_lead' },
  ],
  [SYSTEMS.PARDOT]: [
    { label: 'Pardot Lead', value: 'pardot_lead' },
  ],
  [SYSTEMS.HUBSPOT]: [
    { label: 'Hubspot MAP Lead', value: 'hubspot_lead' },
  ],
  [SYSTEMS.HUBSPOT_CRM]: [
    HUBSPOT_CRM_ACCOUNT_MAPPING_OPTION,
    { label: 'Hubspot CRM Contact', value: 'hubspotcrm_contact' },
  ],
};

export const MP_ENVS = {
  PRODUCTION: { id: 1, name: 'PRODUCTION' },
  SANDBOX: { id: 2, name: 'SANDBOX' },
};

export const PRIMARY_V2_INDUSTRY_FIELDS = [
  'primary_industry_1',
  'primary_industry_2',
  'primary_industry_3',
];

// the number of supported sub-industries per primary industry
export const MAX_SUB_INDUSTRY_FIELD_CT = 3;

// mapping of system name to display, mostly needed because hubspot crm
export const systemNameMap = {
  [SYSTEMS.DYNAMICS]: 'Dynamics',
  [SYSTEMS.ELOQUA]: 'Eloqua',
  [SYSTEMS.HUBSPOT]: 'Hubspot MAP',
  [SYSTEMS.HUBSPOT_CRM]: 'Hubspot CRM',
  [SYSTEMS.MARKETO]: 'Marketo',
  [SYSTEMS.PARDOT]: 'Pardot',
  [SYSTEMS.SALESFORCE]: 'Salesforce',
};

export const STATE_EXCLUSIONS_LABEL = 'State Exclusions by Country';
