import { defineRequestState } from '@sixsense/core/request';

type RequestParams = {
  orgId: number;
  mid?: string;
  queryParams: string;
}

export const topKeywordGroupsRequestState = defineRequestState({
  path: 'dashboard.top.keywordgroups',
  getConfig: ({ orgId, mid, queryParams }: RequestParams) => ({
    url: mid ?
      `query/${orgId}/account/${mid}/top_kw_groups/?${queryParams}`:
      `query/${orgId}/top_kw_groups/?${queryParams}`,
  }),
});
