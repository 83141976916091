import React from 'react';
import PropTypes from 'prop-types';
import styles from './CustomScrollBar.module.scss';

const CustomScrollBar = (props) => {
  const {
    children,
    className,
    ...rest
  } = props;

  return (
    <div className={`${styles.scrollBarContainer} ${className}`} {...rest}>
      {children}
    </div>
  );
};

CustomScrollBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default CustomScrollBar;
