import { combineReducers } from 'redux';
import { createReducerUtil } from 'utils/core';
import { idToIntegrationNameMap } from './constants';

const LOAD = 'INTEGRATIONS/MANAGE/LOAD';
const REFRESH = 'INTEGRATIONS/MANAGE/REFRESH';
const LOAD_SUCCESS = 'INTEGRATIONS/MANAGE/LOAD_SUCCESS';
const LOAD_FAILURE = 'INTEGRATIONS/MANAGE/LOAD_FAILURE';
function load() {
  return { type: LOAD };
}
function refresh() {
  return { type: REFRESH };
}

function loadSuccess(data = { categories: [], integrations: [] }) {
  return {
    type: LOAD_SUCCESS,
    categories: data.categories.filter(({ is_deleted }) => !is_deleted),
    integrations: data.integrations.filter(({ is_deleted, source_type }) =>
    !is_deleted && source_type !== 'webtag_smartformfill'),
  };
}

function loadFailure() {
  return { type: LOAD_FAILURE };
}

const SET_ACTIVE_CATEGORY = 'INTEGRATIONS/MANAGE/SET_ACTIVE_CATEGORY';

const SET_SEARCH_KEYWORD = 'INTEGRATIONS/MANAGE/SET_SEARCH_KEYWORD';

function setSearchKeyword(keyword) {
  return { type: SET_SEARCH_KEYWORD, keyword };
}

const CHANGE_TAB = 'INTEGRATIONS/MANAGE/CHANGE_TAB';
function changeTab(selectedTab) {
  return { type: CHANGE_TAB, selectedTab };
}

const SET_MY_INTEGRATIONS_STATE = 'INTEGRATIONS/SET_MY_INTEGRATION_STATE';
function setMyIntegrationsState(stateKeys) {
  return { type: SET_MY_INTEGRATIONS_STATE, stateKeys };
}
const LOAD_MY_INTEGRATIONS = 'INTEGRATIONS/LOAD_MY_INTEGRATIONS';
function loadMyIntegrations() {
  return { type: LOAD_MY_INTEGRATIONS };
}

const GET_INTEGRATION_STATUS = 'INTEGRATIONS/GET_INTEGRATION_STATUS';
function getIntegrationStatus(stateKey, integrationDetails) {
  return { type: GET_INTEGRATION_STATUS, stateKey, integrationDetails };
}
const GET_INTEGRATION_STATUS_SUCCESS = 'INTEGRATIONS/GET_INTEGRATION_STATUS_SUCCESS';
function getIntegrationStatusSuccess(stateKey, successState) {
  return { type: GET_INTEGRATION_STATUS_SUCCESS, stateKey, successState };
}
const GET_INTEGRATION_STATUS_FAILURE = 'INTEGRATIONS/GET_INTEGRATION_STATUS_FAILURE';
function getIntegrationStatusFailure(stateKey, errorMessage) {
  return { type: GET_INTEGRATION_STATUS_FAILURE, stateKey, errorMessage };
}

// integration statuses for all integrations
const GET_ALL_INTEGRATION_STATUS = 'INTEGRATIONS/GET_ALL_INTEGRATION_STATUS';
function getAllIntegrationStatus() {
  return { type: GET_ALL_INTEGRATION_STATUS };
}
const GET_ALL_INTEGRATION_STATUS_SUCCESS = 'INTEGRATIONS/GET_ALL_INTEGRATION_STATUS_SUCCESS';
function getAllIntegrationStatusSuccess(integrationStatuses) {
  return { type: GET_ALL_INTEGRATION_STATUS_SUCCESS, integrationStatuses };
}
const GET_ALL_INTEGRATION_STATUS_FAILURE = 'INTEGRATIONS/GET_ALL_INTEGRATION_STATUS_FAILURE';
function getAllIntegrationStatusFailure(errorMessage) {
  return { type: GET_ALL_INTEGRATION_STATUS_FAILURE, errorMessage };
}

const GET_GENERIC_INTEGRATION_STATUS_LOAD_SUCCESS =
'INTEGRATIONS/GET_GENERIC_INTEGRATION_STATUS_LOAD_SUCCESS';

export const actionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAILURE,
  SET_ACTIVE_CATEGORY,
  SET_SEARCH_KEYWORD,
  CHANGE_TAB,

  // MY INTEGRATIONS
  LOAD_MY_INTEGRATIONS,
  GET_INTEGRATION_STATUS,
  GET_ALL_INTEGRATION_STATUS,
};

export const actions = {
  load,
  refresh,
  loadSuccess,
  loadFailure,
  setSearchKeyword,
  changeTab,

  // MY INTEGRATIONS
  setMyIntegrationsState,
  loadMyIntegrations,

  getIntegrationStatus,
  getIntegrationStatusSuccess,
  getIntegrationStatusFailure,

    // all integration statuses
  getAllIntegrationStatus,
  getAllIntegrationStatusSuccess,
  getAllIntegrationStatusFailure,
  refreshStatus,
};

const categoriesReducer = createReducerUtil([], {
  [LOAD_SUCCESS]: (_, action) => action.categories,
});

const integrationsReducer = createReducerUtil([], {
  [LOAD_SUCCESS]: (_, action) => action.integrations,
});

const searchKeywordReducer = createReducerUtil('', {
  [SET_SEARCH_KEYWORD]: (_, action) => action.keyword ? action.keyword : '',
}, REFRESH);

const selectedTabReducer = createReducerUtil('My Integrations', {
  [CHANGE_TAB]: (_, action) => action.selectedTab,
});

const statusLoaderPresets = {
  successState: undefined,
  loaded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorStatus: undefined,
};

const integrationStatusReducer = createReducerUtil({}, {
  [SET_MY_INTEGRATIONS_STATE]: (_, action) => {
    const newState = {};
    action.stateKeys.forEach((currentKey) => {
      newState[currentKey] = {
        ...statusLoaderPresets,
      };
    });
    return newState;
  },
  [GET_INTEGRATION_STATUS]: (state, action) => ({
    ...state,
    [action.stateKey]: {
      ...state[action.stateKey],
      successState: undefined,
      loaded: false,
      loading: true,
      error: false,
      errorMessage: undefined,
      errorStatus: undefined,
    },
  }),
  [GET_INTEGRATION_STATUS_SUCCESS]: (state, action) => ({
    ...state,
    [action.stateKey]: {
      ...state[action.stateKey],
      successState: action.successState,
      loaded: true,
      loading: false,
      error: false,
      errorMessage: undefined,
      errorStatus: undefined,
    },
  }),
  [GET_INTEGRATION_STATUS_FAILURE]: (state, action) => ({
    ...state,
    [action.stateKey]: {
      ...state[action.stateKey],
      successState: 0,
      loaded: true,
      loading: false,
      error: true,
      errorMessage: action.errorMessage,
      errorStatus: undefined,
    },
  }),
});

const allIntegrationStatusReducer = createReducerUtil({ loading: true }, {
  [GET_ALL_INTEGRATION_STATUS]: (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: false,
    errorMessage: undefined,
    errorStatus: undefined,
  }),
  [GET_ALL_INTEGRATION_STATUS_SUCCESS]: (state, action) => ({
    ...state,
    loaded: true,
    loading: false,
    error: false,
    ...action.integrationStatuses,
  }),
  [GET_ALL_INTEGRATION_STATUS_FAILURE]: (state, action) => ({
    ...state,
    loaded: true,
    loading: false,
    error: true,
    errorMessage: action.errorMessage.errorMessage,
    errorStatus: action.errorMessage.errorStatus,
  }),
  [GET_GENERIC_INTEGRATION_STATUS_LOAD_SUCCESS]: (state, action) => {
    const result = {};
    let integrationName = idToIntegrationNameMap[action.payload[0].integration_type_id];
    const status = action.payload.find((stat) => stat.is_sandbox===false);
    result[integrationName] = status;
    const sandbox_status = action.payload.find((stat) => stat.is_sandbox===true);
    if (sandbox_status) {
      integrationName+= '_sandbox';
      result[integrationName] = sandbox_status;
    }
    return {
      ...state,
      loaded: true,
      loading: false,
      error: false,
      ...result,
    };
  },
});

const REFRESH_STATUS = 'REFRESH_STATUS';
function refreshStatus() {
  return {
    type: REFRESH_STATUS,
  };
}

const topLevelLoadingReducer = createReducerUtil(true, {
  [GET_ALL_INTEGRATION_STATUS_FAILURE]: () => false,
  [GET_ALL_INTEGRATION_STATUS_SUCCESS]: () => false,
  [REFRESH_STATUS]: () => true,
});


export default combineReducers({
  categories: categoriesReducer,
  integrations: integrationsReducer,
  searchKeyword: searchKeywordReducer,
  selectedTab: selectedTabReducer,
  integrationStatuses: integrationStatusReducer,
  topLevelLoading: topLevelLoadingReducer,
  allIntegrationStatus: allIntegrationStatusReducer,
});
