import React, { useState } from 'react';
import { useOrg } from 'hooks';
import { PageHeader } from 'HOCS';
import PsychographicsHeader from '../components/PsychographicsHeader';
import { Card, Flex, Loading, Tooltip } from '@sixsense/core/components';
import ListItem from '../../SalesConfig/routes/Technologies/components/ListItem';
import { useSharedState, useRequest } from '@sixsense/core';
import { css } from 'styles/emotion';
import {
  psychographicsState, psychographicsSearchState,
  userPsychographicsState,
} from '../state';
import { Button, DropdownSearch, Col, Modal, AccessDenied } from 'v2-components';
import DnDList from '../../SalesConfig/components/DnDList';
import { get, map, uniqBy } from 'lodash';
import { actions as globalActions } from 'modules/global';
import { useDispatch, useSelector } from '@sixsense/core/versioned/react-redux';
import ProductCategorySelect from '../components/ProductCategorySelect';
import useCurrentUser from 'hooks/useCurrentUser';
import { useDebounce } from 'react-use';
import {
  PSYCHOGRAPHICS_CONFIRM_TEXT, PSYCHOGRAPHIC_CONFIRM_TEXT,
  ADD_PSYCHOGRAPH_SUCCESS_MSG, ORDER_PSYCHOGRAPH_SUCCESS_MSG,
  PSYCHOGRAPH_ERROR_MSG, DELETE_PSYCHOGRAPH_SUCCESS_MSG,
  PRODUCT_LABELS, PSYCHOGRAPH_DISABLED_TOOTLTIP,
} from '../constants';
import moment from 'moment';
import { AddPsychographicsType, OrderPsychographicsType, ProductCategory } from '../types';
import { withPermissionBoundary } from '@sixsense/rbac/hocs';
import { compose } from 'redux';
import { SalesPermission, usePermissionCheck } from '@sixsense/rbac';
import { connect } from 'react-redux';
import { siPackagePlanNameSelector } from 'routes/Settings/selectors';
import PropTypes from 'prop-types';
import { SI_PlANS, MAINTENANCE_INFO } from 'routes/Settings/constants';
import { getTechnoPsychoJobCreatedDate, reduceTechoPsychoJobDataByProduct } from '../utils';
import { orgObjSelector, isViewOnlyEnabledFor6senseAccessSelector } from 'modules/user/selectors';


const styles = {
  categoryDropdown: css({
    width: '200px',
    height: '36px',
  }),
  category: css({
    width: '100%',
  }),
  container: css({
    width: '100%',
  }),
  psychographicsDropdown: css({
    height: '100%',
    padding: '6px',
  }),
  pyschographicsListWrapper: css({
    background: 'white',
    border: '1px solid #C6CACE',
    marginTop: '20px',
    padding: '0px !important',
  }),
  searchBar: css({
    width: '350px',
    flex: '0 1 auto',
    paddingTop: '4px',
  }),
  buttonStyle: css({
    height: '36px',
  }),
  removeButtonStyle: css({
    height: '36px',
    background: '#FF583D',
    borderRadius: '2px',
  }),
  removeButtonLabel: css({
    color: '#ffffff !important',
  }),
  headerRow: css({
    borderBottom: '1px solid #c6cace',
    padding: '15px 30px',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
  }),
  error: css({
    marginTop: '10px',
  }),
  actionComponent: css({
    textAlign: 'left',
  }),
  actionComponentHeader: css({
    textAlign: 'left',
    fontWeight: 'bold',
  }),
  actionComponentFlex: css({
    justifyContent: 'space-between',
  }),
};

const PscyhographicsContainer = ({ siPackagePlanName, org }) => {
  const { id: orgId } = useOrg();
  const user = useCurrentUser();
  const request = useRequest();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useSharedState(
    psychographicsSearchState
  );
  const [psychographics, setPsychographics] =
    useSharedState(psychographicsState);
  const [userPsychographics, setUserPsychographics] = useSharedState(
    userPsychographicsState
  );
  const [selectedCategory, setSelectedCategory] = useState<ProductCategory>(Object);
  const [selectedPsychograph, setSelectedPsychograph] = useState<String>(null);
  const [loading, setLoading] = useState<Boolean>(false);
  const productsLabels = [
    ...get(org, 'packages.SI.products', []),
    ...PRODUCT_LABELS,
  ].reduce((acc, data) => ({ ...acc, [data.name]: data.display_name }), {});

  useDebounce(
    () => {
      if (!searchInput && selectedPsychograph) {
        setSelectedPsychograph(null);
      }
      if (searchInput) {
        psychographicsSearch(searchInput);
      }
    },
    400,
    [searchInput]
  );
  const hasEditPermission = usePermissionCheck([SalesPermission.SETTINGS_PSYCHOGRAPHICS_EDIT]);
  const isViewOnlyEnabled = useSelector(isViewOnlyEnabledFor6senseAccessSelector);
  if (!siPackagePlanName || siPackagePlanName === SI_PlANS.PLAN_F) return <AccessDenied />;
  if (MAINTENANCE_INFO.with_read_only) return <AccessDenied message={MAINTENANCE_INFO.message} />;
  const alertMessage = (type: string, msg: string) => {
    dispatch(globalActions.showNotification(type, msg));
  };

  const isAllCategoriesSelected = selectedCategory?.name === 'all';

  const checkSelected = () =>
    userPsychographics.length &&
    userPsychographics.every((el: any) => el.isSelected);
  const disableRemoveButton = () =>
    userPsychographics.length < 1 ||
    !userPsychographics.some((el) => el.isSelected) ||
    isAllCategoriesSelected;

  const onCheckSelectAll = (el) => {
    setUserPsychographics(
      userPsychographics.map((x) => ({ ...x, isSelected: el.target.checked }))
    );
  };

  const checkPsychograph = (id: number) => {
    setUserPsychographics(
      userPsychographics.map((x) =>
        x.id === id ? { ...x, isSelected: !x.isSelected } : x
      )
    );
  };

  const removeDuplicates = (array: any[], duplicateArray: any[]) => {
    const names = duplicateArray.map((el) => el.name);
    return uniqBy(array, 'psychograph').filter((val) => !names.includes(val.psychograph));
  };

  const resetState = () => {
    setPsychographics([]);
    setSearchInput('');
  };

  const onChangeCategory = () => {
    resetState();
    getUserPsychohraphics();
  };

  const addPsychographics = async () => {
    setLoading(true);
    const psy =
      psychographics?.find(
        (el) => el.psychograph === selectedPsychograph
      ) || {};
    const body: AddPsychographicsType = {
      name: psy.psychograph,
      psychograph_id: psy.psychograph_id,
      org_id: orgId,
      product: selectedCategory?.name,
      created_by: user.id,
      updated_by: user.id,
    };
    try {
      await request(
        `settings/${orgId}/psychographs/?product=${selectedCategory?.name}`,
        {
          method: 'POST',
          body: JSON.stringify(body),
        }
      );
      setLoading(false);
      getUserPsychohraphics();
      resetState();
      alertMessage('success', ADD_PSYCHOGRAPH_SUCCESS_MSG);
    } catch (e) {
      setLoading(false);
      alertMessage('error', PSYCHOGRAPH_ERROR_MSG);
    }
  };

  const removePsychographics = async () => {
    const selectedPsychographics = userPsychographics
      .filter((el) => el.isSelected)
      .map((e) => ({ id: e.id }));
    try {
      await request(`settings/${orgId}/psychographs/bulk_delete/`, {
        method: 'PATCH',
        body: JSON.stringify(selectedPsychographics),
      });
      getUserPsychohraphics();
      alertMessage(
        'success',
        DELETE_PSYCHOGRAPH_SUCCESS_MSG
      );
    } catch (e) {
      getUserPsychohraphics();
      alertMessage('error', PSYCHOGRAPH_ERROR_MSG);
    }
  };

  const orderPsychographics = async (oldIndex: number, newIndex: number) => {
    const body: OrderPsychographicsType = {
      order: userPsychographics[newIndex].order,
      updated_by: user.id,
    };
    try {
      await request(
        `settings/${orgId}/psychographs/${userPsychographics[oldIndex].id}/
?product=${selectedCategory?.name}`,
        {
          method: 'PATCH',
          body: JSON.stringify(body),
        }
      );
      getUserPsychohraphics();
      alertMessage('success', ORDER_PSYCHOGRAPH_SUCCESS_MSG);
    } catch (e) {
      alertMessage('error', PSYCHOGRAPH_ERROR_MSG);
    }
  };

  const psychographicsSearch = async (search) => {
    try {
      const result = await request(
        `query/${orgId}/autocomplete/psychograph/?input_string=${search}`
      );
      setPsychographics(removeDuplicates(result?.suggestions || [], userPsychographics));
    } catch (ex) {
      setPsychographics([]);
    }
  };

  const getUserPsychohraphics = async () => {
    try {
      setLoading(true);
      const isSingleProduct = !isAllCategoriesSelected
        ? `?product=${selectedCategory?.name}`
        : '';
      let result : any = await request(
        `settings/${orgId}/psychographs/${isSingleProduct}`
      );
      if (!isSingleProduct) {
        result = reduceTechoPsychoJobDataByProduct(result);
      }
      setUserPsychographics(result || []);
      setLoading(false);
    } catch (ex) {
      setUserPsychographics([]);
      setLoading(false);
    }
  };

  const productsTooltip = (products) => (
    <Tooltip
      overlay={
        <div>
          {products.map((el) => (
            <div>{productsLabels[el.product] || el.product} </div>
          ))}
        </div>
      }
      placement="right"
    >
      <Flex>
        <a>&nbsp;+{products.length} more</a>
      </Flex>
    </Tooltip>
  );

  const psychographicsMappedList = map(
    userPsychographics || [],
    (psy, index) => (
      <ListItem
        siDisabled={isAllCategoriesSelected}
        index={index + 1}
        key={psy.id}
        label={psy.name}
        ActionComponent={
          <Flex className={styles.actionComponentFlex}>
            <div className={styles.actionComponent}>
              <Flex>
                {productsLabels[psy.product] || psy.product}
                {psy.products && psy.products.length ? productsTooltip(psy.products) :'' }
              </Flex>
            </div>
            <div className={styles.actionComponent}>
              {moment(getTechnoPsychoJobCreatedDate(psy)).format('MMM DD, YYYY')}
            </div>
          </Flex>
        }
        isSelected={!!psy.isSelected}
        onCheck={() => checkPsychograph(psy.id)}
      />
    )
  );
  const removeLabel =
    userPsychographics.filter((el) => el.isSelected).length > 1
      ? PSYCHOGRAPHICS_CONFIRM_TEXT
      : PSYCHOGRAPHIC_CONFIRM_TEXT;

  return (
    <div>
      {loading && <Loading.Spinner level="page" />}
      <Flex direction="row" gap={8}>
        <ProductCategorySelect
          onChange={onChangeCategory}
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
        />
        <div className={styles.searchBar} style={{ flex: 1 }}>
          {
            <DropdownSearch
              iconPlacement={'false'}
              id={'relevant_psychographics_search'}
              handleSearchResultSelect={(psy) => {
                setSelectedPsychograph(psy);
              }}
              searchData={psychographics || []}
              filterOption={() => true}
              mapOption="psychograph"
              handleInput={(userInput) => {
                if (!isAllCategoriesSelected) {
                  setSearchInput(userInput);
                  if (selectedPsychograph) {
                    setSelectedPsychograph(null);
                  }
                }
              }}
              value={searchInput}
              valueAccessor={'psychograph'}
              searchBar
              placeholder={'Search and add psychographic phrases'}
            />
          }
        </div>
        <div style={{ flex: 1 }}>
          <Button
            className={styles.buttonStyle}
            tooltipContent={isAllCategoriesSelected && PSYCHOGRAPH_DISABLED_TOOTLTIP}
            onClick={addPsychographics}
            type="primary"
            disabled={
              !selectedPsychograph ||
              !selectedCategory?.name ||
              loading ||
              isAllCategoriesSelected ||
              !hasEditPermission ||
              isViewOnlyEnabled
            }
          >
            Add
          </Button>
        </div>
        <div>
          <Button
            disabled={disableRemoveButton() || !hasEditPermission || isViewOnlyEnabled}
            labelClass={styles.removeButtonLabel}
            className={styles.removeButtonStyle}
            onClick={() =>
              Modal.prompt({
                type: Modal.PROMPT_TYPES.CONFIRM,
                title: removeLabel,
                onOk() {
                  removePsychographics();
                },
              })
            }
          >
          Remove
          </Button>
        </div>
      </Flex>
      <Card.Content className={styles.pyschographicsListWrapper}>
        <Col>
          <ListItem
            label={`Psychographic Phrases (${psychographicsMappedList.length})`}
            isSelected={checkSelected()}
            onCheck={(el) => {
              if (!isAllCategoriesSelected) {
                onCheckSelectAll(el);
              }
            }}
            ActionComponent={
              <Flex className={styles.actionComponentFlex}>
                <div className={styles.actionComponentHeader}>
                  Product Category
                </div>
                <div className={styles.actionComponentHeader}>Created On</div>
              </Flex>
            }
          />
          {psychographicsMappedList.length ? (
            <DnDList
              scrollableId={'appBody'}
              options={psychographicsMappedList}
              onSortEnd={({ oldIndex, newIndex }) => {
                if (oldIndex !== newIndex && !isAllCategoriesSelected) {
                  orderPsychographics(oldIndex, newIndex);
                }
              }}
            />
          ) : null}
        </Col>
      </Card.Content>
    </div>
  );
};
const mapStateToProps = (state) => ({
  siPackagePlanName: siPackagePlanNameSelector(state),
  org: orgObjSelector(state),
});
PscyhographicsContainer.propTypes = {
  siPackagePlanName: PropTypes.string,
  org: PropTypes.object,
};

export default compose(
  withPermissionBoundary({
    allow: (permissions) => [
      SalesPermission.SETTINGS_PSYCHOGRAPHICS_VIEW,
      SalesPermission.SETTINGS_PSYCHOGRAPHICS_EDIT,
    ].some((permission) => permissions.has(permission)),
    renderDenied: () => <AccessDenied />,
  }),
  connect(mapStateToProps),
  PageHeader([PsychographicsHeader]),
)(PscyhographicsContainer);
