import {
  SET_CAMPAIGN_CLASSIFICATION_MODAL_VISIBILITY,
} from './types';
import { createReducerUtil } from 'utils/core';
import { stateGenerators } from './stateGenerators';
import { combineReducers } from 'redux';

const {
  renameClassification,
  deleteClassificationStateGenerator,
  createClassification,
  changeClassification,
} = stateGenerators;

const campaignClassificationModalInitialState = {
  visible: false,
  campaignIds: [],
  uiAction: undefined,
};

const campaignClassificationModal = createReducerUtil(campaignClassificationModalInitialState, {
  [SET_CAMPAIGN_CLASSIFICATION_MODAL_VISIBILITY]: (state, { visible, campaignIds, uiAction }) => {
    if (visible === false) {
      return campaignClassificationModalInitialState;
    }

    return ({
      ...state,
      visible,
      campaignIds,
      uiAction,
    });
  },
});

export const reducers = combineReducers({
  folders: stateGenerators.campaignClassifications.reducer,
  classificationsModal: campaignClassificationModal,
  changeClassification: changeClassification.reducer,
  newClassification: createClassification.reducer,
  [deleteClassificationStateGenerator.stateKey]:
    deleteClassificationStateGenerator.reducer,
  [renameClassification.stateKey]: renameClassification.reducer,
});
