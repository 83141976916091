import { call } from 'redux-saga/effects';
import request from 'utils/request';

function pollSegmentSync(segment) {
  const { org, id } = segment;
  function* _pollSegmentSync() {
    const result = yield call(request, `org/${org.id}/segment/${id}/sync/`);
    return result;
  }
  return _pollSegmentSync;
}

export {
  pollSegmentSync,
};
