import { Flex } from '@sixsense/core/components';
import React, { Fragment } from 'react';
import { css } from 'styles/emotion';
import { Icon, Popover, Text } from 'v2-components';
import PscychographicsUsedImg from '../images/PsychograhicsTab.png';

export const TECHNOLOGIES_POPOVER_TEXT = (
  <div>
    <div>
    Navigate to the Additional Insights section of Sales Intelligence
     to view if the Psychographs used by the account.
    </div>
    <br />
    <div>
    By default all the Psychographs will be shown, you can configure to view only
     Relevant Psychographs by enabling "View only Relevant Psychographs"
      under Component and Metrics <a href="/settings/componentsmetrics">here</a>.
    </div>
    <br />
  </div>
);

const styles = {
  container: css({
    padding: '10px 30px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 2px 0 rgba(74,87,94,0.2)',
  }),
  subBody: css({
    marginLeft: '12px',
  }),
  icon: css({
    cursor: 'pointer',
    width: '13px',
    height: '13px',
    marginLeft: '6px',
    marginTop: '6px',
  }),
  technologiesUsedImg: css({
    marginTop: 'ptr(10px)',
    width: '100%',
  }),
};

const PopoverContent = () => (
  <Fragment>
    {TECHNOLOGIES_POPOVER_TEXT}
    <img
      alt={''}
      className={styles.technologiesUsedImg}
      src={PscychographicsUsedImg}
    />
  </Fragment>
);

const PsychographicsHeader = () => (
  <Flex className={styles.container}>
    <span>
      <Text type={Text.TYPE.TITLE} weight="bold">
            Relevant Psychographics
      </Text>
      <Text type={Text.TYPE.SUBBODY} className={styles.subBody}>
      — Select terms describing values, affinities, topics,
       and interests relevant to your sales team and your go-to-market strategy
        </Text>
    </span>
    <Popover popoverContent={<PopoverContent />} placement={'right'} trigger={'click'}>
      <div>
        <Icon className={styles.icon} color={Icon.COLORS.BLUE} type="help" />
      </div>
    </Popover>
  </Flex>
  );

export default PsychographicsHeader;
