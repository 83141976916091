import React from 'react';
import PropTypes from 'prop-types';
import { Loading2, Modal } from 'v2-components';
import { connect } from 'react-redux';
import { formStateSelector, modalStateSelector } from './selectors';
import { classNames, maybePlural } from 'utils/utils';
import Text from 'v2-components/Text';
import RadioGroup from 'v2-components/RadioGroup';
import styles from './CampaignCSVExportModal.module.scss';
import Select from 'v2-components/Select';
import * as actions from './actions';
import { updateFormState as updateFormStateAction } from './actions';
import Row from 'v2-components/Row';
import Button from 'v2-components/Button';
import DateFilter from 'routes/Advertising/routes/Campaigns/components/DateFilter/DateFilter';
import { DEFAULT_OPTIONS as dataFilterOptions, DATE_FILTER_OPTIONS_VALUES } from
    'routes/Advertising/routes/Campaigns/components/DateFilter/constants';
import Tooltip2 from 'v2-components/Tooltip2';
import {
  ANALYTICS_TYPE,
  ANALYTICS_TYPE_OPTIONS,
  GROUP_BY_OPTIONS,
  TIME_BREAKDOWN_OPTIONS,
} from './constants';
import Loading from '@sixsense/core/components/Loading';
import { PROMISE_STATES } from 'modules/global/constants';
import Icon from 'v2-components/Icon';
import moment from 'moment';
import { emailReportStateGenerator } from './stateGenerators';
import { If } from 'babel-plugin-jsx-control-statements';
import { css } from 'styles/emotion';

const FormComponent = ({
  onSubmit,
  loading,
  cancelExport,
  updateFormState,
  dateRange,
  analyticsType,
  accountType,
  groupBy,
  timeBreakdown,
}) => {
  const accountAnalyticsSelected = analyticsType === ANALYTICS_TYPE.accounts;

  const formInvalid = (() => {
    if (dateRange.value === DATE_FILTER_OPTIONS_VALUES.dateRange) {
      if (!dateRange.from || !dateRange.to) {
        return true;
      }
    }

    return false;
  })();

  return (
    <div>
      <div className={styles.body}>
        <Text type={Text.TYPE.BODY} bold>
          Do you want to export campaign analytics shown in the dashboard or the analytics for
          Accounts reached across these campaigns?
        </Text>
        <RadioGroup
          disableGroup={loading}
          className={styles.analyticsType}
          isVertical
          options={[
            {
              label: 'Campaign Analytics',
              value: ANALYTICS_TYPE.campaign,
              containerClassName: styles.customSpacing,
              disable: loading,
            },
            {
              label: 'Analytics for Accounts reached',
              value: ANALYTICS_TYPE.accounts,
              containerClassName: styles.customSpacing,
              disable: loading,
            },
          ]}
          onChange={(value) => updateFormState({ analyticsType: value })}
          selectedValue={analyticsType}
        />
        <RadioGroup
          disableGroup={loading}
          className={styles.accountsReachedOptions}
          isVertical
          options={
            ANALYTICS_TYPE_OPTIONS.map((option) => ({
              ...option,
              disable: (analyticsType !== ANALYTICS_TYPE.accounts || loading),
            }))
          }
          selectedValue={accountType}
          onChange={(value) => updateFormState({ accountType: value })}
        />
        <br />
        <Tooltip2
          overlay={accountAnalyticsSelected ? (<span>
            “Timeframe” is not applicable <br />for Account analytics
          </span>) : null}
          placement={'right'}
        >
          <Text
            type={Text.TYPE.BODY}
            bold
            color={accountAnalyticsSelected ? Text.COLOR.TRANSLUCENT_GREY : null}
          >
            Timeframe
          </Text>
        </Tooltip2>
        <DateFilter
          options={dataFilterOptions.map(({ label, ...rest }) => ({
            ...rest,
            label,
            dataPendo: `campaignsExportAsCSVDeliveryTimeFrame-${label.replaceAll(' ', '')}`,
          }))}
          disable={loading || accountAnalyticsSelected}
          className={classNames(
            styles.dateRangeFilter,
            accountAnalyticsSelected && styles.disabled
          )}
          value={dateRange.value}
          onChange={(value, from = null, to = null) => {
            updateFormState({ dateRange: { value, from, to } });
          }}
          selectProps={{
            width: 230,
            tether: true,
            id: 'date-range-CSV-export',
            dropdownRender: (menu) => (<React.Fragment>
              <div
                style={{ padding: '7px 10px' }}
              >
                <Text bold type={Text.TYPE.BODY}>Select Delivery Timeframe</Text>
              </div>
              {menu}
            </React.Fragment>),
          }}
          dateRange={(dateRange.from && dateRange.to) ?
            [moment(dateRange.from), moment(dateRange.to)] : undefined}
        />
        <br />
        <Tooltip2
          overlay={accountAnalyticsSelected ? (<span>
            “Group by” is not applicable <br />for Account analytics
          </span>) : null}
          placement={'right'}
        >
          <Text
            type={Text.TYPE.BODY}
            bold
            color={accountAnalyticsSelected ? Text.COLOR.TRANSLUCENT_GREY : null}
          >
            Group By
          </Text>
        </Tooltip2>
        <Select
          disabled={accountAnalyticsSelected || loading}
          containerClassName={classNames(
            styles.groupBy,
            accountAnalyticsSelected && styles.disabled,
          )}
          width={'100%'}
          maxWidth={'100%'}
          id={'group_by_ad'}
          onChange={(value) => updateFormState({ groupBy: value })}
          value={groupBy}
          tether
          options={GROUP_BY_OPTIONS}
        />
        <br />
        <Tooltip2
          overlay={accountAnalyticsSelected ? (<span>
            “Time breakdown” is not applicable <br />for Account analytics
          </span>) : null}
          placement={'right'}
        >
          <Text
            type={Text.TYPE.BODY}
            bold
            color={accountAnalyticsSelected ? Text.COLOR.TRANSLUCENT_GREY : null}
          >
            Time breakdown
          </Text>
        </Tooltip2>
        <RadioGroup
          className={styles.timeBreakdown}
          isVertical
          disableGroup={accountAnalyticsSelected || loading}
          options={loading ?
            TIME_BREAKDOWN_OPTIONS.map((o) => ({ ...o, loading })) :
            TIME_BREAKDOWN_OPTIONS}
          selectedValue={timeBreakdown}
          onChange={(value) => updateFormState({ timeBreakdown: value })}
        />
      </div>
      <Row className={styles.footer} alignItems={'center'} justifyContent={'flex-end'}>
        {loading ? (<Text
          onClick={() => {
            cancelExport();
          }}
          color={Text.COLOR.ERROR}
          bold
          pointer
        >
          Cancel Export
        </Text>) : (<Button
          isHeaderButton
          type={Button.PRIMARY}
          disabled={formInvalid}
          onClick={() => onSubmit({
            analyticsType,
            accountType,
          })}
        >
          Export Now
        </Button>)}
      </Row>
    </div>
  );
};
FormComponent.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.func,
  cancelExport: PropTypes.func,
  updateFormState: PropTypes.func,
  dateRange: PropTypes.object,
  analyticsType: PropTypes.string,
  accountType: PropTypes.string,
  groupBy: PropTypes.string,
  timeBreakdown: PropTypes.string,
};
const formStateToProps = (state) => ({
  dateRange: formStateSelector(state).dateRange,
  analyticsType: formStateSelector(state).analyticsType,
  accountType: formStateSelector(state).accountType,
  groupBy: formStateSelector(state).groupBy,
  timeBreakdown: formStateSelector(state).timeBreakdown,
});
const formDispatchToProps = ({
  updateFormState: updateFormStateAction,
});
const Form = connect(formStateToProps, formDispatchToProps)(FormComponent);

// eslint-disable-next-line react/prop-types,max-len
const Minimised = ({ onExpand, cancelExport, askForEmailReport, sendEmail, emailRequestLoading }) => {
  const PREPARING_VIEW = (<React.Fragment>
    <div className={styles.minimisedStatusIcon}>
      <Loading2.LoadingComponent size={'small'} />
    </div>
    <Row>
      <div>
        <div>
          <Text bold>Preparing your CSV...</Text>
        </div>
        <div className={styles.topSpacing}>
          <Text
            color={Text.COLOR.GREY1}
            italic
          >
            Export will start automatically once <br /> your file is ready.
          </Text>
        </div>
      </div>
      <Row
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignItems={'flex-end'}
        className={styles.rightSide}
      >
        <Icon
          type={'open_in_new'}
          className={styles.expandIcon}
          color={Icon.COLORS.BLUE}
          pointer
          onClick={onExpand}
        />
        <Text
          color={Text.COLOR.ERROR}
          pointer
          onClick={cancelExport}
        >
          Cancel Export
        </Text>
      </Row>
    </Row>
  </React.Fragment>);

  const EMAIL_REPORT = (<React.Fragment>
    <div className={styles.minimisedStatusIcon}>
      <Icon size={Icon.SIZE.XLARGE} type={'error_outline'} color={Icon.COLORS.ERROR} />
    </div>
    <Row>
      <div>
        <div>
          <Text>Sorry it’s taking longer than expected…</Text>
        </div>
        <div className={styles.topSpacing}>
          <Button
            onClick={emailRequestLoading ? null : sendEmail}
            loading={emailRequestLoading}
            loadingText={<Text type={Text.TYPE.SUBBODY}>
              Requesting email...
            </Text>}
            className={css`
              border: 0;
              padding: 0;
            `}
          >
            <Text color={Text.COLOR.BLUE} type={Text.TYPE.SUBBODY}>
              Email me the report
            </Text>
          </Button>
        </div>
      </div>
      <span className={styles.rightSide}>
        <Icon
          type={'open_in_new'}
          className={classNames(styles.expandIcon)}
          color={Icon.COLORS.BLUE}
          pointer
          onClick={onExpand}
        />
      </span>
    </Row>
  </React.Fragment>);


  return (
    <Row className={styles.minimisedContainer}>
      {askForEmailReport ? EMAIL_REPORT : PREPARING_VIEW}
    </Row>);
};

const CampaignCSVExportModalComponent = (props) => {
  const {
    visible,
    campaignIds,
    toggleVisibility,
    onSubmit,
    loading,
    cancelExport,
    isMinimised,
    toggleMinimised,
    noOfCampaigns,
    askForEmailReport,
    sendEmail,
    toggleAskForEmail,
    emailRequestLoading,
  } = props;

  const numberOfCampaigns = campaignIds.length || noOfCampaigns;
  const title = loading ?
    (<Row alignItems={'center'}>
      <Loading.Spinner size={25} />
      &nbsp; &nbsp;
      <Text bold>Preparing your CSV -</Text>
      <Text italic> {numberOfCampaigns} {maybePlural(numberOfCampaigns, 'Campaign')}</Text>
    </Row>)
    : (<Text bold>
      Export as CSV -
      <Text italic> {numberOfCampaigns} {maybePlural(numberOfCampaigns, 'Campaign')}</Text>
    </Text>);

  const onClose = () => {
    if (loading) {
      toggleMinimised(true);
      return;
    }

    toggleVisibility(false);
  };

  return isMinimised ? <Minimised
    onExpand={() => toggleMinimised(false)}
    cancelExport={cancelExport}
    askForEmailReport={askForEmailReport}
    sendEmail={sendEmail}
    emailRequestLoading={emailRequestLoading}
  /> : (
    <React.Fragment>
      <Modal
        visible={visible}
        title={null}
        footer={null}
        onCancel={onClose}
        destroyOnClose
        bodyStyle={{ padding: 0 }}
        closable={false}
      >
        <Row
          className={styles.titleContainer}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          {title}
          {loading ?
            (<Icon
              type={'open_in_new'}
              color={Icon.COLORS.BLUE}
              className={styles.minimiseIcon}
              onClick={() => toggleMinimised(true)}
              pointer
            />) :
            (<Icon type={'close'} onClick={onClose} pointer />)}
        </Row>
        <Form
          onSubmit={onSubmit}
          loading={loading}
          cancelExport={cancelExport}
          askForEmailReport={askForEmailReport}
        />
      </Modal>
      <If condition={askForEmailReport}>
        <Modal
          visible
          title={<Text bold>Sorry it’s taking longer than expected…</Text>}
          footer={null}
          onCancel={() => toggleAskForEmail(false)}
          destroyOnClose
          closable
          maskClosable={false}
          style={{
            width: 640,
            top: 200,
          }}
        >
          <Text type={Text.TYPE.BODY} color={Text.COLOR.GREY1}>
            Looks like the report is too large. You can either have the report emailed to you
            once it’s ready, or try to narrow down the settings to download a lighter report.
          </Text>
          <Row justifyContent={'flex-end'} className={'aam-t--20'}>
            <Button onClick={() => toggleAskForEmail(false)}>
              Change Settings
            </Button>
            <Button
              type={Button.PRIMARY}
              className={'aam-l--10'}
              onClick={emailRequestLoading ? null : sendEmail}
              loading={emailRequestLoading}
              loadingText={'Requesting email...'}
            >
              Email me the report
            </Button>
          </Row>
        </Modal>
      </If>
    </React.Fragment>
  );
};

CampaignCSVExportModalComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  campaignIds: PropTypes.array,
  toggleVisibility: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  cancelExport: PropTypes.func.isRequired,
  isMinimised: PropTypes.bool.isRequired,
  toggleMinimised: PropTypes.func.isRequired,
  noOfCampaigns: PropTypes.number,
  askForEmailReport: PropTypes.bool,
  toggleAskForEmail: PropTypes.func,
  emailRequestLoading: PropTypes.bool,
  sendEmail: PropTypes.func,
};

const mapStateToProps = (state) => ({
  visible: modalStateSelector(state).visible,
  campaignIds: modalStateSelector(state).campaignIds,
  loading: modalStateSelector(state).promiseState === PROMISE_STATES.PENDING,
  isMinimised: modalStateSelector(state).isMinimised,
  noOfCampaigns: modalStateSelector(state).noOfCampaigns,
  askForEmailReport: modalStateSelector(state).askForEmailReport,
  emailRequestLoading: emailReportStateGenerator
    .promiseStateSelector(state) === PROMISE_STATES.PENDING,
});
const mapDispatchToProps = {
  toggleVisibility: actions.toggleVisibility,
  onSubmit: actions.submitDownloadForm,
  cancelExport: actions.cancelExport,
  toggleMinimised: actions.toggleMinimised,
  toggleAskForEmail: actions.toggleAskForEmail,
  sendEmail: emailReportStateGenerator.loadAction,
};

const CampaignCSVExportModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignCSVExportModalComponent);

export default CampaignCSVExportModal;
