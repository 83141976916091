import { coerceLocaleString } from 'utils/utils';
import { capitalize, map } from 'lodash';
import moment from 'moment';
import { BOOLCONST, INTCONST, OR } from 'ast-redux/constants';

export const formatAlert = ({
  id,
  name,
  created = '2018-12-25',
  latest_sent,
  active_emails = [],
  segment: {
    name: segmentName,
    account_count = 0,
  },
  schedule: { frequency },
  all_account_owners,
  alert_type,
  is_active,
  loading,
  is_active_last_updated: lastUpdated,
}) => ({
  id,
  name,
  alertType: capitalize(alert_type),
  segmentName,
  frequency: capitalize(frequency),
  all_account_owners,
  activeEmails: active_emails,
  createdDate: created,
  createdDateFormatted: moment(created).format('MMM DD, YYYY'),
  lastSent: latest_sent !== null
    ? moment(latest_sent.deadline).format('MMM DD, YYYY')
    : 'N/A',
  accountCount: `${coerceLocaleString(account_count)} Accounts`,
  isActive: is_active,
  loading,
  lastUpdated: moment(lastUpdated).format('MMM DD, YYYY'),
});

export const formatFilterset = (filterset) => {
  const updatedFilters = map(filterset.filters, (filter) => {
    const updatedFilterValues = map(filter.filter_values, (val) => ({
      value: val.value,
      display_name: val.display_name,
      metadata: JSON.parse(val.metadata.replace(/'/g, '"')),
    }));
    return ({
      filter: {
        relationship_position: filter.relationship_position,
        variable: filter.variable,
        operator: filter.operator,
        value_relationship: filter.value_relationship,
        metadata: JSON.parse(filter.metadata.replace(/'/g, '"')),
      },
      filter_values: updatedFilterValues,
    });
  }).sort((a, b) => a.relationship_position < b.relationship_position ? -1 : 1);
  return ({
    relationship: filterset.relationship,
    filters: updatedFilters,
  });
};

export const cloneRelationshipNode = (pos, node) => {
  if (node.type === INTCONST) {
    if (parseInt(node.value) === pos) {
      return {
        lhs: { type: INTCONST, value: pos },
        rhs: { type: INTCONST, value: pos + 1 },
        type: OR,
      };
    }
    return parseInt(node.value) > pos ?
      { type: INTCONST, value: parseInt(node.value) + 1 } : node;
  }
  const newNode = node;
  newNode.lhs = cloneRelationshipNode(pos, node.lhs);
  newNode.rhs = cloneRelationshipNode(pos, node.rhs);
  return newNode;
};

export const formatFiltersetResponse = (filterset, org, user, category) => {
  const filtersResponse = map(filterset.filters, (filter, index) => {
    const filterValuesResponse = map(filter.filter_values, (val) => {
      const usingBool = val.metadata.type === BOOLCONST;
      let value = val.value;
      // Have to send boolean as a string for serializer
      if (usingBool) {
        value = value && value !== 'False' ? 'True' : 'False';
      }
      return {
        value,
        display_name: value,
        metadata: JSON.stringify(val.metadata),
        created_by_id: user,
        updated_by_id: user,
        org,
        filter: 1, // This is required on serializer but we'll switch it on the backend
      };
    });
    return ({
      ...filter.filter,
      created_by_id: user,
      updated_by_id: user,
      org,
      filter_values: filterValuesResponse,
      metadata: JSON.stringify(filter.filter.metadata),
      relationship_position: index + 1,
      filterset: 1, // This is required on serializer but we'll switch it on the backend
    });
  });
  return {
    ...filterset,
    filters: filtersResponse,
    org,
    created_by_id: user,
    updated_by_id: user,
    filterset_type: 5,
    category: category || 'segment',
  };
};

export const safelyDecodeURIComponent = (uri) => {
  try {
    return decodeURIComponent(uri);
  } catch (e) {
    // invalid URI strings with '%' signs (Ex: '100% marks') can't be used with
    // decodeURIComponent, hence the try
    console.error(`URI Component not decodable: ${uri}`);
    return uri;
  }
};

