export default function createRoutes() {
  return {
    path: 'segment(/:segmentId)',
    name: 'segmentPerformanceReport',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        cb(null, containers.SegmentPerformancePage);
      }, 'segmentPerformanceReport');
    },
  };
}
