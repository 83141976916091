import React from 'react';
import styles from './FormComponent.module.scss';
import { Row, Col, Text } from 'v2-components';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { BORDER_ERROR_EXCEPTIONS_COMPONENTS } from 'v2-HOCS/Constants';

const FormComponent = (WrappedComponent, mapInputToProps) => {
  /* eslint-disable react/prefer-stateless-function */
  class FormClass extends React.Component {
    render() {
      const {
        input,
        inputType,
        meta,
        label,
        required,
        hintText,
        border = true,
        onWarn,
        formLayout = {},
        rightExtra,
        loading = false,
        forceError,
        forceErrorMessage,
        inputClassName,
        labelClassName,
        separatorClassName,
        rightExtraClassName,
        containerClassName,
        errorClassName,
      } = this.props;

      const { touched, error, invalid, warning } = meta || {};

      const {
        labelSize = '35%',
        betweenSize = label ? 14 : 0,
        inputSize = '30%',
        rightExtraSize = '35%',
        marginBottom = 14,
        marginTop = 0,
        marginLeft = 0,
        marginRight = 0,
        stackedMargin = 5,
        stacked,
        justifyLabel = 'flex-end',
        justify = 'flex-end',
        justifyRightExtra = 'flex-start',
      // justifyInput = { start: 'xs' },
      } = formLayout;

      let classNameInput = styles.input;
      let classNameLabel = styles.label;
      let classNameMsg = styles.msgHint;
      let msg = hintText;

      if ((forceError || invalid) && (touched || (error && error.forceShowError)) && !loading) {
        if (border && !BORDER_ERROR_EXCEPTIONS_COMPONENTS.includes(inputType)) {
          classNameInput += ` ${styles.inputError}`;
        }
        classNameLabel += ` ${styles.labelError}`;
        classNameMsg = styles.msgError;
        msg = (error && error.value) || error || forceErrorMessage;
      }

      if (warning && touched) {
        if (onWarn) {
          onWarn(warning);
        }
      }

      const seperator = (
        <Col className={separatorClassName} flexBasis="content">
          {betweenSize > 0 && <div style={{ width: betweenSize }} />}
        </Col>
      );

      let rightExtraDisplay = null;
      if (rightExtra) {
        rightExtraDisplay = (
          <Col flexBasis={stacked ? 'content' : rightExtraSize} className={rightExtraClassName}>
            <Row justifyContent={justifyRightExtra}>
              {seperator}
              <Col flexBasis="content">{rightExtra}</Col>
            </Row>
          </Col>
        );
      } else {
        rightExtraDisplay = <Col flexBasis={stacked ? 'content' : rightExtraSize} />;
      }

      const requiredDisplay = required ? <Text color={Text.COLOR.BLUE}>*</Text> : null;
      const mappedInput = mapInputToProps ? mapInputToProps(input, this.props) : input;

      const componentProps = omit({ ...this.props, ...mappedInput }, [
        'formLayout',
        'input',
        'meta',
        'label',
        'required',
        'border',
        'hintText',
        'onWarn',
      ]);

      const style = {
        margin: `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px`,
      };

      const labelComponent = label
        ? (
          <Col flexBasis={labelSize} className={labelClassName}>
            <Row justifyContent={justifyLabel} className={classNameLabel}>
              <Text weight={Text.WEIGHT.BOLD}>{label}</Text>
              {requiredDisplay}
            </Row>
          </Col>
        )
        : null;

      const inputComponent = (
        <Col flexBasis={inputSize} className={inputClassName}>
          <WrappedComponent
            {...componentProps}
            className={`${componentProps.className} ${classNameInput}`}
          />
          {msg && <div className={`${styles.msg} ${classNameMsg} ${errorClassName}`}>{msg}</div>}
        </Col>
      );

      if (stacked) {
        return (
          <div style={style} className={containerClassName}>
            <Row alignItems="center" style={{ marginBottom: stackedMargin }}>
              {labelComponent}
              {rightExtraDisplay}
            </Row>
            <Row alignItems="center">{inputComponent}</Row>
          </div>
        );
      }

      return (
        <div style={style} className={containerClassName}>
          <Row justifyContent={justify}>
            {labelComponent}
            {seperator}
            {inputComponent}
            {rightExtraDisplay}
          </Row>
        </div>
      );
    }
  }

  FormClass.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    required: PropTypes.bool,
    border: PropTypes.bool,
    formLayout: PropTypes.object,
    hintText: PropTypes.string,
    onWarn: PropTypes.func,
    loading: PropTypes.bool,
    rightExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    forceError: PropTypes.bool,
    forceErrorMessage: PropTypes.string,
    inputClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    separatorClassName: PropTypes.string,
    rightExtraClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    errorClassName: PropTypes.string,
    inputType: PropTypes.string,
  };

  return FormClass;
};

export default FormComponent;
