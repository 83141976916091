import React from 'react';
import PropTypes from 'prop-types';
import { decorators, errors } from 'utils/validators';
import { Field } from 'redux-form';
import { formLayout } from './constants';
import { Input, Row, Tooltip2 } from 'v2-components';
import FieldLabel from '../FieldLabels';

const FormInput = Input.form;

const TITLE_FIELD = 'title';

const Title = ({
  showCopyToAll,
  disableCopyToAll,
  fieldSyncError,
  value,
  onCopyToAll,
  maxLength,
  required,
  label,
  id,
  className,
  disabled,
  labelTooltip,
  tooltip,
}) => {
  const fieldLabel = maxLength ? `${label} (max ${maxLength} characters)` : label;

  return (<React.Fragment>
    <FieldLabel
      showCopyToAll={showCopyToAll}
      label={fieldLabel}
      copyConfirmLabel={label}
      onCopyToAll={() => onCopyToAll({ [TITLE_FIELD]: value })}
      disableCopyToAll={disableCopyToAll || !!fieldSyncError}
      required={required}
      labelTooltip={labelTooltip}
      rightExtra={<Row justifyContent={Row.JUSTIFY_CONTENT.FLEX_END}>
        {(value && value.length) || 0} Characters
      </Row>}
      className={'aam-b--10'}
    />
    <Tooltip2
      overlay={tooltip}
      placement={'top'}
    >
      <Field
        className={className}
        id={id}
        name={TITLE_FIELD}
        type="text"
        formLayout={formLayout}
        component={FormInput}
        validate={[
          ...(required ? [errors.isRequired] : []),
          ...(maxLength ? [decorators.forceError(errors.maxLength(maxLength))] : []),
        ]}
        placeholder={label}
        disabled={disabled}
      />
    </Tooltip2>
  </React.Fragment>);
};
Title.propTypes = {
  value: PropTypes.string,
  labelTooltip: PropTypes.string,
  fieldSyncError: PropTypes.string,
  onCopyToAll: PropTypes.func,
  showCopyToAll: PropTypes.bool,
  disableCopyToAll: PropTypes.bool,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.node,
};

export default Title;
export {
  TITLE_FIELD,
};
