import { createSelector } from 'reselect';
import { get, isEqual } from 'lodash';
import { collect } from 'zipper/utils';

const MODULE_KEY = 'astRedux';

const stateSelector = (state) => state[MODULE_KEY];

export const astSelector = (astKey) => createSelector(
  stateSelector,
  (astRoot) => get(astRoot, [astKey, 'ast']),
);

export const astBaseSelector = (astKey) => createSelector(
  stateSelector,
  (astRoot) => get(astRoot, astKey)
);

export const astUpdatedSelector = (astKey) => createSelector(
  astBaseSelector(astKey),
  (astBase = {}) => {
    const { ast, InitialAst } = astBase;
    return !isEqual(ast, InitialAst);
  }
);

export const emptyFilterSelector = (astKey) => createSelector(
  astBaseSelector(astKey),
  (astBase = {}) => {
    let emptyValues = [];

    if (astBase && astBase.ast && astBase.ast.type !== 'NullExpr') {
      emptyValues = collect(astBase.ast, (loc) =>
        loc.node().value === '',
      );
    } else {
      return true;
    }

    return emptyValues.length > 0;
  }
);
