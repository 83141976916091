import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from './Tab';
import styles from './HorizontalTabs.module.scss';
import { forEach } from 'lodash';

const HorizontalTabs = ({ items, selected, labelClassName }) => {
  const leftAligned = [];
  const rightAligned = [];
  forEach(items, (item) => {
    const { TabComponent, justify, value, tabProps } = item;
    (justify === 'left' ? leftAligned : rightAligned).push(
      <TabComponent
        key={value}
        selected={selected === value}
        labelClassName={labelClassName}
        {...tabProps}
      />
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.container}>{ leftAligned }</div>
      <div className={styles.flexItem} />
      <div className={styles.container}>{ rightAligned }</div>
    </div>
  );
};

HorizontalTabs.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.string,
  labelClassName: PropTypes.string,
};

HorizontalTabs.Tab = Tab;

export default HorizontalTabs;
