import React from "react";
import { Text } from "@sixsense/core/components";
import { COLUMN_CATEGORIES } from "../constants";
import { isDraftOrScheduledCampaign } from "../../../utils";
import { NA } from "./common/NA";
import { localeString } from "utils/utils";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const CLICKS = {
  sortByKey: "media_clicks",
  category: COLUMN_CATEGORIES.general,
  customizable: true,
  defaultSelected: true,
  key: "clicks",
  csvColumnKey: "clicks",
  title: <Label name={CAMPAIGN_LABELS.clicks} />,
  dataIndex: "campaign_data.media_clicks",
  width: pxToRem(110),
  render: (mediaClicks, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (isDraftOrScheduledCampaign(campaignStatus)) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {localeString(mediaClicks || 0)}
      </Text.Body>
    );
  },
  align: "right",
};
