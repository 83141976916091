/* eslint-disable max-len */
import React from 'react';
import { FeaturedIcon } from '@sixsense/core/components';
import { ChevronSelectorHorizontal, Lightbulb, Scales } from '@sixsense/core/icons';
// @ts-ignore
import webtagLogo from 'images/IntegrationLogos/webtag-logo.svg';
// @ts-ignore
import _6senseIcon from 'images/IntegrationLogos/6sense-icon.svg';


export const WEBTAG_REDUX_STATE_KEY:string = 'webtag';
export const WEBTAG_ROUTE:string = 'webtag';
export const WEBTAG_ACTION_BASESTRING:string = 'settings/integration/webtag';
export const WEBTAG_REFRESH_ACTIONNAME:string = 'settings/integration/webtag/REFRESH';

export const WEBTAG_ADD_DOMAINS_FORM_NAME = 'webtag-add-domains';
export const WEBTAG_BASIC_DETAILS_FORM_NAME = 'webtag-basic-details';
export const WEBTAG_CONFIGURATION_FORM_NAME = 'webtag-configuration';
export const WEBTAG_EMAIL_DETAILS_FORM_NAME = 'webtag-email-details';

export const WebtagSummaryConfig:Object = {
  header: {
    logo: webtagLogo,
    mainTitle: 'WebTag',
    category: 'Website Tracking',
    subTitle: 'by 6sense',
  },
  about: {
    mainInfo: {
      title: 'About this Integration',
      description:
          'The 6sense WebTag is a small block of JavaScript code that\'s'+
          ' placed on your website to de-anonymize website visitors at the '+
          'account level in real time. By collecting a combination of IP address,'+
          ' cookie, and device-level data, WebTags help to shine a light on a your Dark Funnel™,'+
          ' providing sales and marketing with the insights they need to uncover, prioritize,'+
          ' and understand which accounts are in market for your solutions today. ',
    },
    metaInfo: [
      {
        name: 'Category',
        value: 'Website Tracking',
      },
      {
        name: 'Feature Support',
        value: 'Account Identification, Segmentation, Predictive Models, Intent Data',
      },
    ],
    keyfeatures: {
      title: 'Key Features',
      features: [
        {
          name: 'Enable Website Tracking',
          value: 'For site visits',
          icon: <FeaturedIcon
            icon={ChevronSelectorHorizontal}
            theme="light-circle-outline"
            color="Gray"
          />,
        },
        {
          name: 'Identify In-Market Accounts',
          value:
              'By shining a light on anonymous buyers in real time',
          icon: <FeaturedIcon
            icon={Lightbulb}
            theme="light-circle-outline"
            color="Gray"
          />,
        },
        {
          name: 'Prioritize the Right Accounts',
          value: 'And at the right time in their buyer journey',
          icon: <img width="40px" src={_6senseIcon} alt="" />,
        },
        {
          name: 'Deliver Personalized Experiences',
          value: 'Based on an account’s confidence score',
          icon: <FeaturedIcon
            icon={Scales}
            theme="light-circle-outline"
            color="Gray"
          />,
        },
      ],
    },
  },
  help: {
    links: [
      {
        title: 'Additional Resources',
        list: [
          {
            href: 'https://support.6sense.com/knowledge-base/6sense-webtag-2-0-setup/',
            text: 'How to install?',
          },
          {
            href: 'https://support.6sense.com/knowledge-base/360041636473-how-to-check-if-6sense-web-tag-is-firing/',
            text: 'Instructions to check if 6sense WebTag is Firing',
          },
          {
            href: 'https://support.6sense.com/knowledge-base/11851584555155-6sense-webtag-configurations',
            text: 'Know more about WebTag Configurations',
          },
          {
            href: 'https://support.6sense.com/knowledge-base/install-webtag-2-0-using-google-tag-manager-gtm/',
            text: 'Install WebTag 2.0 using Google Tag Manager (GTM)',
          },
          {
            href: 'https://support.6sense.com/knowledge-base/webtag-2-0-guide-adobe-launch-data-collection-installation/',
            text: 'WebTag 2.0 Guide: Adobe Launch (Data Collection) Installation',
          },
          {
            href: 'https://support.6sense.com/knowledge-base/11867180025747-install-the-company-details-api-on-google-tag-manager-6sense-webtag-v2-0',
            text: 'WebTag 2.0 Guide: Install the Company Details API on Google Tag Manager',
          },
          {
            href: 'https://support.6sense.com/knowledge-base/webtag-2-0-guide-install-the-company-details-api-on-adobe-launch-data-collection/',
            text: 'WebTag 2.0 Guide: Install the Company Details API on Adobe Launch (Data Collection)',
          },
          {
            href: 'https://support.6sense.com/knowledge-base/webtag-v2-0-guide-custom-gtm-data-layer-variable-names-for-the-company-details-api-integration/',
            text: 'WebTag v2.0 Guide: Custom GTM Data Layer Variable Names for the Company Details API Integration',
          },
          {
            href: 'https://support.6sense.com/knowledge-base/9519604695571-hubspot-form-submit-behavior-6sense-web-tag-use-troubleshooting/',
            text: 'HubSpot Form Submit Behavior: 6sense Web Tag Use Troubleshooting',
          },
          {
            href: 'https://support.6sense.com/knowledge-base/8486228079251-marketo-form-submit-behavior-6sense-web-tag-use-troubleshooting/',
            text: 'Marketo Form Submit Behavior: 6sense Web Tag Use Troubleshooting',
          },
        ],
      },
    ],
  },
};

export const WEBTAG_FORM_STEP_IDS = Object.freeze({
  BASIC_DETAILS: 'basic_details',
  CONFIGURE: 'configure',
  INSTALL_TAG: 'install_tag',
});

export const WEBTAG_STATUSES = Object.freeze({
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  INACTIVE: 'INACTIVE',
  ERROR: 'ERROR',
});


export const WEBTAG_1_NAME = 'WebTag 1.0';
