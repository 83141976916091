export default function createRoutes() {
  return {
    path: 'jobs',
    name: 'jobs',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('../../containers/TaxonomyJobs/containers');
        cb(null, containers.TurbotaxJobs);
      }, 'settings_taxonomy_jobs');
    },
  };
}
