import { SORT_UPDATED_DATE } from 'modules/segments/constants';
import { LOAD as LOAD_STRING, LOOKALIKE_MIN, SEGMENT_POLL_CHANNEL } from './constants';
import { actionTypes as segmentActionTypes } from 'modules/segments';
import { omit, map, isEmpty, cloneDeep } from 'lodash';
import { pollSegmentSync } from './pollers';
import { actions as pollingActions } from 'saga-polling';
import {
  actionTypes as publishedSegmentActionTypes,
} from '../../../Settings/routes/PublishedSegments/modules';
import { SIXSENSE_NETWORK } from 'v2-components/SegmentCard/constants';
import { computeRangeSelection } from 'utils/utils';

const initialUpload = {
  loading: false,
  validating: false,
  error: '',
  parsed: false,
};

const initialLoadingState = {
  loading: false,
  loaded: false,
  error: false,
  errorMessage: undefined,
  errorStatus: undefined,
};

const initialMatchStats = {
  namedAccounts: [],
  matchedAccounts: [],
  unmatchedAccounts: [],
  duplicates: [],
};

const loadingState = {
  loading: true,
  loaded: false,
  error: false,
  errorMessage: undefined,
  errorStatus: undefined,
};

const initialSyncState = {
  loading: true,
  loaded: false,
  error: false,
};

const initialState = {
  sortBy: SORT_UPDATED_DATE,
  typeFilter: '',
  viewFilter: 'all',
  search: '',
  currentAction: 'edit',
  actionSegmentId: null,
  showAction: false,
  noData: false,
  loaded: false,
  loading: true,
  loadingMore: false,
  loadMoreError: false,
  error: false,
  errorMessage: false,
  errorStatus: false,
  segmentsList: [],
  segmentsListLoading: false,
  offset: 0,
  pageSize: 20,
  deletingSegment: false,
  deleteSegmentError: false,
  cloningSegment: false,
  cloningSegmentError: false,
  segmentToCloneId: null,
  upload: { ...initialUpload },
  lookalikeCount: LOOKALIKE_MIN,
  showingSaveCloneModal: false,
  saveModalVisible: false,
  totalSegmentCount: null,
  sync: initialSyncState,
  segmentCountries: null,
  matchStats: { ...initialMatchStats, ...initialLoadingState },
  crm: {
    selectedCrmList: { submitted: false },
    selectedCrmReport: { submitted: false },
    loadCrmLists: { ...initialLoadingState, crmLists: [] },
    loadCrmListData: { ...initialLoadingState },
    loadCrmReports: {
      ...initialLoadingState,
      permissionError: false,
      crmReports: [],
      searchingReports: false,
    },
    loadCrmReportData: { ...initialLoadingState, reportErrorMessage: undefined },
    loadCrmCredentials: { ...initialLoadingState, areCredentialsValid: false },
    verifyCrmCredentials: { ...initialLoadingState, isValid: false },
    syncFromAccountList: false,
    syncFromReport: false,
  },
  unsortedFolder: {},
  selectedSegments: [],
  selectedTag: {},
  createFolderVisibility: false,
  renameFolderVisibility: false,
  detailFolderVisibility: false,
  renameTagVisibility: false,
  manageTagVisibility: false,
  editSegTagsVisibility: false,
  moveSegmentVisibility: false,
  advancedSearchVisibility: false,
  deleteSegmentVisibility: false,
  deleteFolderVisibility: false,
  manageTagsToCreate: [],
  segTagsToCreate: [],
  tagsToAdd: [],
  tagsToRemove: [],
  tagSearchValue: '',
  segTagsManage: [],
  advancedSearch: {
    broken: [],
    folders: [],
    tags: [],
    untagged: [],
    created_by: [],
    updated_by: [],
    activity: [],
    creation_type: [],
    published: [],
  },
  tempAdvancedSearch: {
    broken: [],
    folders: [],
    tags: [],
    untagged: [],
    created_by: [],
    updated_by: [],
    activity: [],
    creation_type: [],
    published: [],
  },
  createdUserDropDownLoading: false,
  updatedUserDropDownLoading: false,
  dropDownUsers: [],
  segmentMoving: false,
  creatingFolder: false,
  deletingFolder: false,
  renamingFolder: false,
  modifyingTags: false,
  editSegTagMode: false,
  segmentDependencies: {
    loading: false,
    loaded: false,
    error: false,
    dependencyData: {},
  },
  segmentSyncSummary: {
    segmentSyncSummaryData: {},
    loading: false,
    error: false,
  },
  createSegmentMode: SIXSENSE_NETWORK,
  advancedSearchLoading: false,
  allFolders: false,
};

const {
  CREATE_SEGMENT_SUCCESS,
  UPDATE_SEGMENT_SUCCESS,
} = segmentActionTypes;

const LOAD = LOAD_STRING;
function load() {
  return { type: LOAD };
}

const LOAD_SUCCESS = 'SEGMENTS_MANAGE/LOAD_SUCCESS';
function loadSuccess(segments, totalCount) {
  return { type: LOAD_SUCCESS, segments, totalCount };
}

const LOAD_FAIL = 'SEGMENTS_MANAGE/LOAD_FAIL';
function loadFail(errorMessage, errorStatus) {
  return { type: LOAD_FAIL, errorMessage, errorStatus };
}

const LOAD_MORE = 'SEGMENTS_MANAGE/LOAD_MORE';
function loadMore(offset) {
  return { type: LOAD_MORE, offset };
}

const SYNC_DONE = 'SEGMENTS_MANAGE/SYNC_DONE';
const syncDone = (segmentID) => ({
  type: SYNC_DONE,
  segmentID,
});
const SYNC_FAILED = 'SEGMENTS_MANAGE/SYNC_FAIL';
const syncFail = (segmentID, error) => ({
  type: SYNC_FAILED,
  segmentID,
  error,
});

const FETCH_AND_UPDATE_SEGMENT = 'SEGMENTS_MANAGE/FETCH_AND_UPDATE_SEGMENT';
const fetchAndUpdateSegment = (segmentId) => ({
  type: FETCH_AND_UPDATE_SEGMENT,
  segmentId,
});

const FETCH_AND_UPDATE_SEGMENT_IN_LIST = 'SEGMENTS_MANAGE/FETCH_AND_UPDATE_SEGMENT_IN_LIST';
const fetchAndUpdateSegmentInList = (segmentId) => ({
  type: FETCH_AND_UPDATE_SEGMENT_IN_LIST,
  segmentId,
});

const UPDATE_SEGMENT_IN_LIST = 'SEGMENTS_MANAGE/UPDATE_SEGMENT_IN_LIST';
const updateSegmentInList = (segmentId, updatedSegmentData) => ({
  type: UPDATE_SEGMENT_IN_LIST,
  id: segmentId,
  data: updatedSegmentData,
});

const LOAD_COUNTRIES_OF_SEGMENT = 'SEGMENTS_MANAGE/LOAD_COUNTRIES_OF_SEGMENT';
const loadCountriesSupportedBySegment = (segmentID) => ({
  type: LOAD_COUNTRIES_OF_SEGMENT,
  segmentID,
});

const LOAD_COUNTRIES_OF_SEGMENT_SUCCESS = 'SEGMENTS_MANAGE/LOAD_COUNTRIES_OF_SEGMENT_SUCCESS';
const loadCountriesSupportedBySegmentSuccess = (segmentCountries) => ({
  type: LOAD_COUNTRIES_OF_SEGMENT_SUCCESS,
  segmentCountries,
});

const LOAD_COUNTRIES_OF_SEGMENT_FAILED = 'SEGMENTS_MANAGE/LOAD_COUNTRIES_OF_SEGMENT_FAIL';
const loadCountriesSupportedBySegmentFail = (error) => ({
  type: LOAD_COUNTRIES_OF_SEGMENT_FAILED,
  error,
});

const FETCH_SEGMENT_DEPENDENCIES = 'SEGMENTS_MANAGE/FETCH_SEGMENT_DEPENDENCIES';
const fetchSegmentDependencies = (segmentIds) => ({
  type: FETCH_SEGMENT_DEPENDENCIES,
  segmentIds,
});

const FETCH_SEGMENT_DEPENDENCIES_SUCCESS = 'SEGMENTS_MANAGE/FETCH_SEGMENT_DEPENDENCIES_SUCCESS';
const fetchSegmentDependenciesSuccess = (dependencyData) => ({
  type: FETCH_SEGMENT_DEPENDENCIES_SUCCESS,
  dependencyData,
});


const FETCH_SEGMENT_DEPENDENCIES_FAILED = 'SEGMENTS_MANAGE/FETCH_SEGMENT_DEPENDENCIES_FAILED';
const fetchSegmentDependenciesFailed = (error) => ({
  type: FETCH_SEGMENT_DEPENDENCIES_FAILED,
  error,
});

const SEGMENT_SYNC_PREFIX = 'SEGMENT_SYNC';
const FETCH_SEGMENT_SYNC_SUMMARY = `${SEGMENT_SYNC_PREFIX}/FETCH_SEGMENT_SYNC_SUMMARY`;
const fetchSegmentSyncSummary = (segmentId) => ({
  type: FETCH_SEGMENT_SYNC_SUMMARY,
  segmentId,
});

const FETCH_SEGMENT_SYNC_SUMMARY_SUCCESS =
  `${SEGMENT_SYNC_PREFIX}/FETCH_SEGMENT_SYNC_SUMMARY_SUCCESS`;
const fetchSegmentSyncSummarySuccess = (syncSummaryData) => ({
  type: FETCH_SEGMENT_SYNC_SUMMARY_SUCCESS,
  syncSummaryData,
});


const FETCH_SEGMENT_SYNC_SUMMARY_FAILED =
  `${SEGMENT_SYNC_PREFIX}/FETCH_SEGMENT_SYNC_SUMMARY_FAILED`;
const fetchSegmentSyncSummaryFailed = (error) => ({
  type: FETCH_SEGMENT_SYNC_SUMMARY_FAILED,
  error,
});

function pollSync(segment) {
  return pollingActions.poll(
    SEGMENT_POLL_CHANNEL,
    segment.id,
    pollSegmentSync(segment),
    () => syncDone(segment.id),
    (e) => syncFail(segment.id, e),
    {
      isDone: (result) => result.status === 'sync_done',
    }
  );
}
function cancelPollSync() {
  return pollingActions.cancelPoll(
    SEGMENT_POLL_CHANNEL,
  );
}

const REFRESH = 'SEGMENTS_MANAGE/REFRESH';
function refresh() {
  return { type: REFRESH };
}


const SET_ACTION = 'SEGMENTS_MANAGE/SET_ACTION';
function setAction(config, segmentId) {
  return { type: SET_ACTION, config, id: segmentId };
}

const CANCEL_ACTION = 'SEGMENTS_MANAGE/CANCEL_ACTION';
function cancelAction() {
  return { type: CANCEL_ACTION };
}

const SET_SORT_BY = 'SEGMENTS_MANAGE/SET_SORT_BY';
function setSortby(value) {
  return { type: SET_SORT_BY, value };
}

const SET_VIEW_BY = 'SEGMENTS_MANAGE/SET_VIEW_BY';
function setViewBy(viewFilter) {
  window.localStorage.setItem('_6siSegmentListView', viewFilter);
  return { type: SET_VIEW_BY, viewFilter };
}

const SET_TYPE_FILTER = 'SEGMENTS_MANAGE/SET_TYPE_FILTER';
function setTypeFilter(value) {
  return { type: SET_TYPE_FILTER, value };
}

const SET_SEARCH = 'SEGMENTS_MANAGE/SET_SEARCH';
function setSearch(value) {
  return { type: SET_SEARCH, value };
}

const RUN_ADVANCED_SEARCH = 'SEGMENTS_MANAGE/RUNADVANCED_SEARCH';
function runAdvancedSearch() {
  return { type: RUN_ADVANCED_SEARCH };
}


const SYNC_SEGMENT_REQUEST = 'SEGMENTS_MANAGE/SYNC_SEGMENT';
const syncSegment = (id) => ({
  type: SYNC_SEGMENT_REQUEST,
  loading: true,
  error: false,
  id,
});

const SYNC_SEGMENT_SUCCESS = 'SEGMENTS_MANAGE/SYNC_SEGMENT_SUCCESS';
const syncSegmentSuccess = (id) => ({
  type: SYNC_SEGMENT_SUCCESS,
  loading: false,
  error: false,
  id,
});

const SYNC_SEGMENT_FAILURE = 'SEGMENTS_MANAGE/SYNC_SEGMENT_FAILURE';
const syncSegmentFailure = (id) => ({
  type: SYNC_SEGMENT_FAILURE,
  loading: false,
  error: true,
  id,
});

const DELETE_SEGMENT = 'SEGMENTS_MANAGE/DELETE_SEGMENT';
const deleteSegment = (ids) => ({
  type: DELETE_SEGMENT,
  ids,
});

const DELETE_SEGMENT_SUCCESS = 'SEGMENTS_MANAGE/DELETE_SEGMENT_SUCCESS';
const deleteSegmentSuccess = (ids) => ({
  type: DELETE_SEGMENT_SUCCESS,
  ids,
});

const DELETE_SEGMENT_FAIL = 'SEGMENTS_MANAGE/DELETE_SEGMENT_FAIL';
const deleteSegmentFail = (ids) => ({
  type: DELETE_SEGMENT_FAIL,
  ids,
});

const CHANGE_SAVE_MODAL_VISIBILITY =
  'SEGMENTS_MANAGE/CHANGE_SAVE_MODAL_VISIBILITY';
const changeSaveModalVisibility = (visible) => ({
  type: CHANGE_SAVE_MODAL_VISIBILITY,
  visible,
});

const SHOW_SAVE_CLONE_MODAL = 'SEGMENTS_MANAGE/SHOW_SAVE_CLONE_MODAL';
const SHOWING_SAVE_CLONE_MODAL = 'SEGMENTS_MANAGE/SHOWING_SAVE_CLONE_MODAL';
const CLONE_SEGMENT = 'SEGMENTS_MANAGE/CLONE_SEGMENT';
const CLONE_SEGMENT_SUCCESS = 'SEGMENTS_MANAGE/CLONE_SEGMENT_SUCCESS';
const CLONE_SEGMENT_FAILURE = 'SEGMENTS_MANAGE/CLONE_SEGMENT_FAILURE';

function showSaveCloneModal(segmentID, showingSaveModal) {
  return { type: SHOW_SAVE_CLONE_MODAL, segmentID, showingSaveModal };
}

function openCloneSegmentModal(segmentID, showingSaveModal) {
  return { type: SHOWING_SAVE_CLONE_MODAL, segmentID, showingSaveModal };
}

function cloneSegment(segment, formValues = {}) {
  return { type: CLONE_SEGMENT, segment, formValues };
}

function cloneSegmentSuccess(segment) {
  return { type: CLONE_SEGMENT_SUCCESS, segment };
}

function cloneSegmentFailure(errorStatus, errorMessage) {
  return { type: CLONE_SEGMENT_FAILURE, errorStatus, errorMessage };
}

const LOAD_ACCOUNT_STATS = 'SEGMENTS_MANAGE/LOAD_ACCOUNT_STATS';
const loadAccountStats = (segmentId) => ({
  type: LOAD_ACCOUNT_STATS,
  segmentId,
});

const CHECK_ACCOUNT_STATS = 'SEGMENTS_MANAGE/CHECK_ACCOUNT_STATS';
const checkAccountStats = (accounts) => ({
  type: CHECK_ACCOUNT_STATS,
  accounts,
});

const CHECK_ACCOUNT_EXTERNAL_STATS = 'SEGMENTS_MANAGE/CHECK_ACCOUNT_EXTERNAL_STATS';
const checkAccountExternalStats = (accounts) => ({
  type: CHECK_ACCOUNT_EXTERNAL_STATS,
  accounts,
});

const ACCOUNT_STATS_SUCCESS = 'SEGMENTS_MANAGE/ACCOUNT_STATS_SUCCESS';
const accountStatsSuccess = (matchStatistics) => ({
  type: ACCOUNT_STATS_SUCCESS,
  matchStatistics,
});

const ACCOUNT_STATS_FAIL = 'SEGMENTS_MANAGE/ACCOUNT_STATS_FAIL';
const accountStatsFail = (error) => ({
  type: ACCOUNT_STATS_FAIL,
  error,
});

const RESET_ACCOUNT_MATCH_STATS = 'SEGMENTS_MANAGE/RESET_ACCOUNT_MATCH_STATS';
const resetMatchStats = () => ({
  type: RESET_ACCOUNT_MATCH_STATS,
});

const START_PARSE_ACCOUNTS = 'SEGMENTS_MANAGE/START_PARSE_ACCOUNTS';
const startParseAccounts = () => ({
  type: START_PARSE_ACCOUNTS,
});

const ERROR_PARSE_ACCOUNTS = 'SEGMENTS_MANAGE/ERROR_PARSE_ACCOUNTS';
const errorParseAccounts = (error) => ({
  type: ERROR_PARSE_ACCOUNTS,
  error,
});

const SET_LOOKALIKE_COUNT = 'SEGMENTS_MANAGE/SET_LOOKALIKE_COUNT';
const setLookalikeCount = (count, maxValue, minValue) => ({
  type: SET_LOOKALIKE_COUNT,
  count,
  maxValue,
  minValue,
});

const RESET_OFFSET = 'SEGMENTS_MANAGE/RESET_OFFSET';
const resetOffset = () => ({
  type: RESET_OFFSET,
});

const SET_SEGMENTS_LIST_LOADING = 'SEGMENTS_MANAGE/SET_SEGMENTS_LIST_LOADING';
const setSegmentsListLoading = () => ({
  type: SET_SEGMENTS_LIST_LOADING,
});

const TOGGLE_SYNC_CRM_ACCOUNTS_LIST = 'SEGMENTS/MANAGE/TOGGLE_SYNC_CRM_ACCOUNTS_LIST';
const toggleSyncFromAccountList = () => ({
  type: TOGGLE_SYNC_CRM_ACCOUNTS_LIST,
});

const TOGGLE_SYNC_CRM_REPORTS = 'SEGMENTS/MANAGE/TOGGLE_USE_CRM_REPORTS';
const toggleSyncFromReport = () => ({
  type: TOGGLE_SYNC_CRM_REPORTS,
});

const RESET_CRM_SYNC_TOGGLES = 'SEGMENTS_MANAGE/RESET_CRM_SYNC_TOGGLES';
const resetCrmSyncToggles = () => ({
  type: RESET_CRM_SYNC_TOGGLES,
});

const LOAD_CRM_LISTS_REQUEST = 'SEGMENTS_MANAGE/LOAD_CRM_LISTS_REQUEST';
const LOAD_CRM_LISTS_SUCCESS = 'SEGMENTS_MANAGE/LOAD_CRM_LISTS_SUCCESS';
const LOAD_CRM_LISTS_FAILURE = 'SEGMENTS_MANAGE/LOAD_CRM_LISTS_FAILURE';
const SAVE_CRM_LISTS_ERROR = 'SEGMENTS_MANAGE/SAVE_CRM_LISTS_ERROR';

const loadCrmLists = () => ({
  type: LOAD_CRM_LISTS_REQUEST,
  ...loadingState,
});

const loadCrmListsSuccess = (crmLists) => ({
  type: LOAD_CRM_LISTS_SUCCESS,
  loading: false,
  loaded: true,
  crmLists,
});

const loadCrmListsFailure = (errorMessage) => ({
  type: LOAD_CRM_LISTS_FAILURE,
  loading: false,
  error: true,
  errorMessage,
});

const saveLoadCrmListsError = (errorMessage, errorState) => ({
  type: SAVE_CRM_LISTS_ERROR,
  errorMessage,
  errorState,
});

const SEARCH_CRM_REPORTS_REQUEST = 'SEGMENTS_MANAGE/SEARCH_CRM_REPORTS_REQUEST';
const SEARCH_CRM_REPORTS_SUCCESS = 'SEGMENTS_MANAGE/SEARCH_CRM_REPORTS_SUCCESS';
const SEARCH_CRM_REPORTS_FAILURE = 'SEGMENTS_MANAGE/SEARCH_CRM_REPORTS_FAILURE';
const SAVE_CRM_REPORTS_ERROR = 'SEGMENTS_MANAGE/SAVE_CRM_REPORTS_ERROR';

const searchCrmReports = (searchValue) => ({
  type: SEARCH_CRM_REPORTS_REQUEST,
  reportSearchTerm: searchValue,
  searchingReports: true,
});

const searchCrmReportsSuccess = (crmReports) => ({
  type: SEARCH_CRM_REPORTS_SUCCESS,
  searchingReports: false,
  crmReports,
});

const searchCrmReportsFailure = (errorMessage, permissionError) => ({
  type: SEARCH_CRM_REPORTS_FAILURE,
  searchingReports: false,
  error: true,
  errorMessage,
  permissionError,
});

const saveLoadCrmReportsError = (errorMessage, errorState) => ({
  type: SAVE_CRM_REPORTS_ERROR,
  errorMessage,
  errorState,
});

const LOAD_CRM_LIST_DATA_REQUEST = 'SEGMENTS_MANAGE/LOAD_CRM_LIST_DATA_REQUEST';
const LOAD_CRM_LIST_DATA_SUCCESS = 'SEGMENTS_MANAGE/LOAD_CRM_LIST_DATA_SUCCESS';
const LOAD_CRM_LIST_DATA_FAILURE = 'SEGMENTS_MANAGE/LOAD_CRM_LIST_DATA_FAILURE';

const loadCrmListData = () => ({
  type: LOAD_CRM_LIST_DATA_REQUEST,
  ...loadingState,
});

const loadCrmListDataSuccess = () => ({
  type: LOAD_CRM_LIST_DATA_SUCCESS,
  loading: false,
  loaded: true,
});

const loadCrmListDataFailure = (errorMessage, errorStatus) => ({
  type: LOAD_CRM_LIST_DATA_FAILURE,
  loading: false,
  error: true,
  errorMessage,
  errorStatus,
});

const LOAD_CRM_REPORT_DATA_REQUEST = 'SEGMENTS_MANAGE/LOAD_CRM_REPORT_DATA_REQUEST';
const LOAD_CRM_REPORT_DATA_SUCCESS = 'SEGMENTS_MANAGE/LOAD_CRM_REPORT_DATA_SUCCESS';
const LOAD_CRM_REPORT_DATA_FAILURE = 'SEGMENTS_MANAGE/LOAD_CRM_REPORT_DATA_FAILURE';

const loadCrmReportData = () => ({
  type: LOAD_CRM_REPORT_DATA_REQUEST,
  ...loadingState,
});

const loadCrmReportDataSuccess = () => ({
  type: LOAD_CRM_REPORT_DATA_SUCCESS,
  loading: false,
  loaded: true,
});

const loadCrmReportDataFailure = (errorMessage, errorStatus, reportErrorMessage) => ({
  type: LOAD_CRM_REPORT_DATA_FAILURE,
  loading: false,
  error: true,
  errorMessage,
  errorStatus,
  reportErrorMessage,
});

const VALIDATE_CRM_REPORT_FAILURE = 'SEGMENTS_MANAGE/VALIDATE_CRM_REPORT_FAILURE';
const validateCrmReportFailure = (errorMessage) => ({
  type: VALIDATE_CRM_REPORT_FAILURE,
  loading: false,
  error: true,
  validationError: errorMessage,
});

const SELECT_CRM_LIST = 'SEGMENTS_MANAGE/SELECT_CRM_LIST';
const selectCrmList = (list) => ({
  type: SELECT_CRM_LIST,
  selectedCrmList: { ...list },
});

const UNSELECT_CRM_LIST = 'SEGMENTS_MANAGE/UNSELECT_CRM_LIST';
const unselectCrmList = () => ({
  type: UNSELECT_CRM_LIST,
  matchStats: { ...initialMatchStats, ...initialLoadingState },
  selectedCrmList: { submitted: false },
  syncFromAccountList: false,
});

const SELECT_CRM_REPORT = 'SEGMENTS_MANAGE/SELECT_CRM_REPORT';
const selectCrmReport = (report) => ({
  type: SELECT_CRM_REPORT,
  selectedCrmReport: { ...report },
});

const UNSELECT_CRM_REPORT = 'SEGMENTS_MANAGE/UNSELECT_CRM_REPORT';
const unselectCrmReport = () => ({
  type: UNSELECT_CRM_REPORT,
  matchStats: { ...initialMatchStats, ...initialLoadingState },
  selectedCrmReport: { submitted: false },
  syncFromReport: false,
});

const LOAD_AND_VERIFY_CRM_CREDENTIALS_REQUEST =
  'SEGMENTS_MANAGE/LOAD_AND_VERIFY_CRM_CREDENTIALS_REQUEST';
const LOAD_CRM_CREDENTIALS_SUCCESS =
  'SEGMENTS_MANAGE/LOAD_CRM_CREDENTIALS_SUCCESS';
const LOAD_CRM_CREDENTIALS_FAILURE =
  'SEGMENTS_MANAGE/LOAD_CRM_CREDENTIALS_FAILURE';

const loadCrmCredentials = () => ({
  type: LOAD_AND_VERIFY_CRM_CREDENTIALS_REQUEST,
  ...loadingState,
});

const loadCrmCredentialsSuccess = (areCredentialsValid) => ({
  type: LOAD_CRM_CREDENTIALS_SUCCESS,
  loading: false,
  loaded: true,
  areCredentialsValid,
});

const loadCrmCredentialsFailure = (errorMessage, errorStatus) => ({
  type: LOAD_CRM_CREDENTIALS_FAILURE,
  loading: false,
  error: true,
  errorMessage,
  errorStatus,
});

const VERIFY_CRM_CREDENTIALS_SUCCESS =
  'SEGMENTS_MANAGE/VERIFY_CRM_CREDENTIALS_SUCCESS';
const VERIFY_CRM_CREDENTIALS_FAILURE =
  'SEGMENTS_MANAGE/VERIFY_CRM_CREDENTIALS_FAILURE';

const verifyCrmCredentialsSuccess = (isValid) => ({
  type: VERIFY_CRM_CREDENTIALS_SUCCESS,
  loading: false,
  loaded: true,
  isValid,
});

const verifyCrmCredentialsFailure = (errorMessage, errorStatus) => ({
  type: VERIFY_CRM_CREDENTIALS_FAILURE,
  loading: false,
  error: true,
  errorMessage,
  errorStatus,
});

const DOWNLOAD_ACCOUNTS = 'SEGMENTS_MANAGE/DOWNLOAD_ACCOUNTS';
function downloadAccounts(componentReference, fileName) {
  return { type: DOWNLOAD_ACCOUNTS, componentReference, fileName };
}

const SET_CREATE_FOLDER_VISIBILITY = 'SEGMENTS_MANAGE/SET_CREATE_FOLDER_VISIBILITY';
function setCreateFolderVisibility(visibility) {
  return { type: SET_CREATE_FOLDER_VISIBILITY, visibility };
}

const SET_RENAME_FOLDER_VISIBILITY = 'SEGMENTS_MANAGE/SET_RENAME_FOLDER_VISIBILITY';
function setRenameFolderVisibility(visibility) {
  return { type: SET_RENAME_FOLDER_VISIBILITY, visibility };
}

const SET_RENAME_TAG_VISIBILITY = 'SEGMENTS_MANAGE/SET_RENAME_TAG_VISIBILITY';
function setRenameTagVisibility(visibility) {
  return { type: SET_RENAME_TAG_VISIBILITY, visibility };
}

const SET_DETAIL_FOLDER_VISIBILITY = 'SEGMENTS_MANAGE/SET_DETAIL_FOLDER_VISIBILITY';
function setDetailFolderVisibility(visibility) {
  return { type: SET_DETAIL_FOLDER_VISIBILITY, visibility };
}

const SET_MANAGE_TAG_VISIBILITY = 'SEGMENTS_MANAGE/SET_MANAGE_TAG_VISIBILITY';
function setManageTagVisibility(visibility) {
  return { type: SET_MANAGE_TAG_VISIBILITY, visibility };
}

const SET_MOVE_SEGMENT_VISIBILITY = 'SEGMENTS_MANAGE/SET_MOVE_SEGMENT_VISIBILITY';
function setMoveSegmentVisibility(visibility) {
  return { type: SET_MOVE_SEGMENT_VISIBILITY, visibility };
}

const SET_ADVANCED_SEARCH_VISIBILITY = 'SEGMENTS_MANAGE/SET_ADVANCED_SEARCH_VISIBILITY';
function setAdvancedSearchVisibility(visibility) {
  return { type: SET_ADVANCED_SEARCH_VISIBILITY, visibility };
}

const SET_DELETE_SEGMENT_VISIBILITY = 'SEGMENTS_MANAGE/SET_DELETE_SEGMENT_VISIBILITY';
function setDeleteSegmentVisibility(visibility) {
  return { type: SET_DELETE_SEGMENT_VISIBILITY, visibility };
}

const SET_DELETE_FOLDER_VISIBILITY = 'SEGMENTS_MANAGE/SET_DELETE_FOLDER_VISIBILITY';
function setDeleteFolderVisibility(visibility) {
  return { type: SET_DELETE_FOLDER_VISIBILITY, visibility };
}

const CREATE_FOLDER = 'SEGMENTS_MANAGE/CREATE_FOLDER';
function createFolder(name) {
  return { type: CREATE_FOLDER, name };
}

const CREATE_FOLDER_SUCCESS = 'SEGMENTS_MANAGE/CREATE_FOLDER_SUCCESS';
function createFolderSuccess() {
  return { type: CREATE_FOLDER_SUCCESS };
}

const CREATE_FOLDER_FAIL = 'SEGMENTS_MANAGE/CREATE_FOLDER_FAIL';
function createFolderFail(errorMessage) {
  return { type: CREATE_FOLDER_FAIL, errorMessage };
}

const RENAME_FOLDER = 'SEGMENTS_MANAGE/RENAME_FOLDER';
function renameFolder(name, id) {
  return { type: RENAME_FOLDER, name, id };
}

const RENAME_FOLDER_SUCCESS = 'SEGMENTS_MANAGE/RENAME_FOLDER_SUCCESS';
function renameFolderSuccess() {
  return { type: RENAME_FOLDER_SUCCESS };
}

const RENAME_FOLDER_FAIL = 'SEGMENTS_MANAGE/RENAME_FOLDER_FAIL';
function renameFolderFail(errorMessage) {
  return { type: RENAME_FOLDER_FAIL, errorMessage };
}

const DELETE_FOLDER = 'SEGMENTS_MANAGE/DELETE_FOLDER';
function deleteFolder(folders) {
  return { type: DELETE_FOLDER, folders };
}

const DELETE_FOLDER_SUCCESS = 'SEGMENTS_MANAGE/DELETE_FOLDER_SUCCESS';
function deleteFolderSuccess() {
  return { type: DELETE_FOLDER_SUCCESS };
}

const DELETE_FOLDER_FAIL = 'SEGMENTS_MANAGE/DELETE_FOLDER_FAIL';
function deleteFolderFail(errorMessage) {
  return { type: DELETE_FOLDER_FAIL, errorMessage };
}

const CHANGE_SELECTED_SEGMENT = 'SEGMENTS_MANAGE/CHANGE_SELECTED_SEGMENT';
function changeSelectedSegment(segment) {
  return { type: CHANGE_SELECTED_SEGMENT, segment };
}

const SELECT_OR_UNSELECT_SEGMENT = 'SEGMENTS_MANAGE/SELECT_OR_UNSELECT_SEGMENT';
function selectOrUnselectSegment(segment) {
  return { type: SELECT_OR_UNSELECT_SEGMENT, segment };
}

const BULK_SELECT_OR_UNSELECT_SEGMENTS = 'SEGMENTS_MANAGE/BULK_SELECT_OR_UNSELECT_SEGMENTS';
function bulkSelectOrUnselectSegments(segment, lastSelected) {
  return { type: BULK_SELECT_OR_UNSELECT_SEGMENTS, segment, lastSelected };
}

const SELECT_ALL_UNSELECT_ALL = 'SEGMENTS_MANAGE/SELECT_ALL_UNSELECT_ALL';
function selectAllUnselectAll() {
  return { type: SELECT_ALL_UNSELECT_ALL };
}

const RENAME_TAG = 'SEGMENTS_MANAGE/RENAME_TAG';
function renameTag(oldName, newName, id) {
  return { type: RENAME_TAG, oldName, newName, id };
}

const DELETE_TAG = 'SEGMENTS_MANAGE/DELETE_TAG';
function deleteTag(name, id) {
  return { type: DELETE_TAG, name, id };
}

const CREATE_MANAGE_TAG = 'SEGMENTS_MANAGE/CREATE_MANAGE_TAG';
function createManageTag(name, user) {
  return { type: CREATE_MANAGE_TAG, name, user };
}

const CREATE_SEG_TAG = 'SEGMENTS_MANAGE/CREATE_SEG_TAG';
function createSegTag(name) {
  return { type: CREATE_SEG_TAG, name };
}

const SET_TAG_SEARCH = 'SEGMENTS_MANAGE/SET_TAG_SEARCH';
function setTagSearch(searchValue) {
  return { type: SET_TAG_SEARCH, searchValue };
}

const SET_SELECTED_TAG = 'SEGMENTS_MANAGE/SET_SELECTED_TAG';
function setSelectedTag(tag) {
  return { type: SET_SELECTED_TAG, tag };
}

const ADD_TAG_TO_ADD = 'SEGMENTS_MANAGE/ADD_TAG_TO_ADD';
function addTagToAdd(name, segTags) {
  return { type: ADD_TAG_TO_ADD, name, segTags };
}

const ADD_TAG_TO_REMOVE = 'SEGMENTS_MANAGE/ADD_TAG_TO_REMOVE';
function addTagToRemove(tag) {
  return { type: ADD_TAG_TO_REMOVE, tag };
}

const RESET_TAG_EDITS = 'SEGMENTS_MANAGE/RESET_TAG_EDITS';
function resetEdits() {
  return { type: RESET_TAG_EDITS };
}

const MODIFY_MANAGE_TAGS = 'SEGMENTS_MANAGE/MODIFY_MANAGE_TAGS';
function modifyManageTags() {
  return { type: MODIFY_MANAGE_TAGS };
}

const MODIFY_SEG_TAGS = 'SEGMENTS_MANAGE/MODIFY_SEG_TAGS';
function modifySegTags(segments) {
  return { type: MODIFY_SEG_TAGS, segments };
}

const MODIFY_TAGS_SUCCESS = 'SEGMENTS_MANAGE/MODIFY_TAGS_SUCCESS';
function modifyTagsSuccess() {
  return { type: MODIFY_TAGS_SUCCESS };
}

const MODIFY_TAGS_FAIL = 'SEGMENTS_MANAGE/MODIFY_TAGS_FAIL';
function modifyTagsFail(errorMessage) {
  return { type: MODIFY_TAGS_FAIL, errorMessage };
}

const MOVE_SEGMENT_TO_FOLDER = 'SEGMENTS_MANAGE/MOVE_SEGMENT_TO_FOLDER';
function moveSegmentToFolder(segments, folder) {
  return { type: MOVE_SEGMENT_TO_FOLDER, segments, folder };
}

const MOVE_SEGMENT_TO_FOLDER_SUCCESS = 'SEGMENTS_MANAGE/MOVE_SEGMENT_TO_FOLDER_SUCCESS';
function moveSegmentToFolderSuccess() {
  return { type: MOVE_SEGMENT_TO_FOLDER_SUCCESS };
}

const MOVE_SEGMENT_TO_FOLDER_FAIL = 'SEGMENTS_MANAGE/MOVE_SEGMENT_TO_FOLDER_FAIL';
function moveSegmentToFolderFail(errorMessage) {
  return { type: MOVE_SEGMENT_TO_FOLDER_FAIL, errorMessage };
}

const SET_EDIT_SEG_TAGS = 'SEGMENTS_MANAGE/SET_EDIT_SEG_TAGS';
function showEditSegTags(visibility) {
  return { type: SET_EDIT_SEG_TAGS, visibility };
}

const SET_SEG_TAGS_MANAGE = 'SEGMENTS_MANAGE/SET_SEG_TAGS_MANAGE';
function setSegTagsManage(tags) {
  return { type: SET_SEG_TAGS_MANAGE, tags };
}

const MODIFY_ADVANCED_SEARCH = 'SEGMENTS_MANAGE/MODIFY_ADVANCED_SEARCH';
function modifyAdvancedSearch(key, value, temp) {
  return { type: MODIFY_ADVANCED_SEARCH, key, value, temp };
}

const SET_ADVANCED_SEARCH_FOLDERS = 'SEGMENTS_MANAGE/SET_ADVANCED_SEARCH_FOLDERS';
function setAdvancedSearchFolders(folder) {
  return { type: SET_ADVANCED_SEARCH_FOLDERS, folder };
}

const SET_CREATED_USER_DROPDOWN_SEARCH = 'SEGMENTS_MANAGE/SET_CREATED_USER_DROPDOWN_SEARCH';
function setCreatedUserDropdownSearch(searchVal) {
  return { type: SET_CREATED_USER_DROPDOWN_SEARCH, searchVal };
}

const SET_UPDATED_USER_DROPDOWN_SEARCH = 'SEGMENTS_MANAGE/SET_UPDATED_USER_DROPDOWN_SEARCH';
function setUpdatedUserDropdownSearch(searchVal) {
  return { type: SET_UPDATED_USER_DROPDOWN_SEARCH, searchVal };
}

const USER_DROPDOWN_SEARCH_SUCCESS = 'SEGMENTS_MANAGE/USER_DROPDOWN_SEARCH_SUCCESS';
function userDropdownSearchSuccess(users) {
  return { type: USER_DROPDOWN_SEARCH_SUCCESS, users };
}

const USER_DROPDOWN_SEARCH_FAIL = 'SEGMENTS_MANAGE/USER_DROPDOWN_SEARCH_FAIL';
function userDropdownSearchFail(errorMessage) {
  return { type: USER_DROPDOWN_SEARCH_FAIL, errorMessage };
}

const CLEAR_TEMP_ADVANCED_SEARCH = 'SEGMENTS_MANAGE/CLEAR_TEMP_ADVANCED_SEARCH';
function clearTempAdvancedSearch() {
  return { type: CLEAR_TEMP_ADVANCED_SEARCH };
}

const SET_EDIT_SEG_TAG_MODE = 'SEGMENTS_MANAGE/SET_EDIT_SEG_TAG_MODE';
function setEditSegTagMode(mode) {
  return { type: SET_EDIT_SEG_TAG_MODE, mode };
}

const MODIFY_SEGMENT_TAGS = 'SEGMENTS_MANAGE/MODIFY_SEGMENT_TAGS';
function modifySegmentTags(tagsToAdd, tagsToRemove, segIds) {
  return { type: MODIFY_SEGMENT_TAGS, tagsToAdd, tagsToRemove, segIds };
}

const SET_CREATE_SEGMENT_MODE = 'SEGMENTS_MANAGE/SET_CREATE_SEGMENT_MODE';
function setCreateSegmentMode(mode) {
  return {
    type: SET_CREATE_SEGMENT_MODE,
    mode,
  };
}

const SET_ADV_SEARCH_ALL_FOLDERS_TOGGLE = 'SET_ADV_SEARCH_ALL_FOLDERS_TOGGLE';
function setAdvAllFolderToggle(mode) {
  return {
    type: SET_ADV_SEARCH_ALL_FOLDERS_TOGGLE,
    mode,
  };
}

function segmentsManageReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD: {
      return { ...state, loading: true };
    }
    case LOAD_SUCCESS: {
      const { segments, totalCount } = action;

      return {
        ...state,
        loading: false,
        loaded: true,
        loadMoreError: false,
        loadingMore: false,
        advancedSearchLoading: false,
        segmentsList: segments,
        totalSegmentCount: totalCount,
        segmentsListLoading: false,
        selectedSegments: [],
        manageTagsToCreate: [],
        segTagsToCreate: [],
        tagsToRemove: [],
        tagsToAdd: [],
      };
    }
    case UPDATE_SEGMENT_IN_LIST: {
      const { segmentsList } = state;
      const { selectedSegments } = state;
      const { id, data } = action;

      const index = segmentsList.findIndex((segment) => segment.id === id);
      const updatedSegmentsList = [...segmentsList];
      if (index !== -1) {
        updatedSegmentsList.splice(index, 1, data);
      }

      const selectedSegmentIndex = selectedSegments.findIndex((selectedSegment) =>
        selectedSegment.id === id);
      const updatedSelectedSegmentsList = [...selectedSegments];
      if (selectedSegmentIndex !== -1) {
        updatedSelectedSegmentsList.splice(selectedSegmentIndex, 1, data);
      }
      return {
        ...state,
        segmentsList: updatedSegmentsList,
        selectedSegments: updatedSelectedSegmentsList,
      };
    }
    case LOAD_FAIL: {
      const { errorMessage, errorStatus } = action;

      return {
        ...state,
        loading: false,
        error: true,
        errorMessage,
        errorStatus,
      };
    }
    case SET_SEGMENTS_LIST_LOADING:
      return {
        ...state,
        segmentsListLoading: true,
      };
    case LOAD_MORE: {
      const offset = action.offset;
      return {
        ...state,
        selectedSegments: [],
        loadingMore: true,
        offset,
      };
    }
    case REFRESH: {
      return { ...initialState };
    }
    case RESET_OFFSET: {
      return {
        ...state,
        offset: 0,
        totalSegmentCount: 0,
        segmentsList: [],
      };
    }
    case SET_SORT_BY: {
      const { value } = action;
      return { ...state, sortBy: value };
    }
    case SET_VIEW_BY: {
      const { viewFilter } = action;
      return { ...state, viewFilter };
    }
    case SET_TYPE_FILTER: {
      const { value } = action;
      return { ...state, typeFilter: value, offset: 0 };
    }
    case SET_SEARCH: {
      const { value } = action;
      return { ...state, search: value };
    }
    case SET_ACTION: {
      const { config, id } = action;
      return {
        ...state,
        actionConfig: config,
        actionSegmentId: id,
        showAction: true,
      };
    }
    case CANCEL_ACTION: {
      return {
        ...state,
        showAction: false,
      };
    }
    case CREATE_SEGMENT_SUCCESS: {
      const { segment } = action;
      return {
        ...state,
        creatingSegment: false,
        segmentsList: {
          ...state.segmentsList,
          [segment.id]: segment,
        },
      };
    }
    case UPDATE_SEGMENT_SUCCESS: {
      const { segment } = action;
      return {
        ...state,
        creatingSegment: false,
        segmentsList: {
          ...state.segmentsList,
          [segment.id]: segment,
        },
      };
    }
    case DELETE_SEGMENT: {
      return {
        ...state,
        deleteSegmentError: false,
        deletingSegment: true,
      };
    }
    case DELETE_SEGMENT_SUCCESS: {
      const { ids } = action;
      return {
        ...state,
        showAction: false,
        deleteSegmentError: false,
        deletingSegment: false,
        deleteSegmentVisibility: false,
        segmentsList: omit(state.segmentsList, ids),
        segmentDependencies: {
          loading: false,
          loaded: false,
          error: false,
          dependencyData: {},
        },
      };
    }
    case DELETE_SEGMENT_FAIL: {
      return {
        ...state,
        showAction: false,
        deletingSegment: false,
        deleteSegmentError: true,
      };
    }
    case SHOWING_SAVE_CLONE_MODAL: {
      const { segmentID, showingSaveModal } = action;
      return {
        ...state,
        showingSaveCloneModal: showingSaveModal,
        segmentToCloneId: segmentID,
        inEditMode: showingSaveModal,
        cloningSegmentError: false,
      };
    }
    case CLONE_SEGMENT: {
      return {
        ...state,
        cloningSegment: true,
        cloningSegmentError: false,
      };
    }
    case CLONE_SEGMENT_SUCCESS: {
      const { segment } = action;
      return {
        ...state,
        cloningSegment: false,
        cloningSegmentError: false,
        showingSaveCloneModal: false,
        segmentsList: { ...state.segmentsList, [segment.id]: segment },
      };
    }
    case CLONE_SEGMENT_FAILURE: {
      const { errorStatus, errorMessage } = action;
      return {
        ...state,
        cloningSegment: false,
        cloningSegmentError: true,
        cloningSegmentErrorStatus: errorStatus,
        cloningSegmentErrorMessage: errorMessage,
      };
    }
    case LOAD_ACCOUNT_STATS:
    case CHECK_ACCOUNT_EXTERNAL_STATS:
    case CHECK_ACCOUNT_STATS: {
      const { accounts } = action;
      return {
        ...state,
        matchStats: {
          ...state.matchStats,
          ...loadingState,
          namedAccounts: accounts || [],
        },
      };
    }
    case ACCOUNT_STATS_SUCCESS: {
      const { matchStatistics } = action;
      return {
        ...state,
        matchStats: {
          ...state.matchStats,
          loading: false,
          loaded: true,
          matchedAccounts: matchStatistics.matched_accounts,
          unmatchedAccounts: matchStatistics.unmatched_accounts,
          namedAccounts: matchStatistics.named_accounts,
          duplicates: matchStatistics.duplicates,
          crmDataType: matchStatistics.crm_data_type,
        },
      };
    }
    case ACCOUNT_STATS_FAIL: {
      const { error } = action;
      return {
        ...state,
        matchStats: {
          ...state.matchStats,
          loading: false,
          error: true,
          errorMessage: error,
        },
      };
    }
    case RESET_ACCOUNT_MATCH_STATS: {
      return {
        ...state,
        matchStats: {
          ...initialMatchStats,
          ...initialLoadingState,
        },
      };
    }
    case START_PARSE_ACCOUNTS: {
      return {
        ...state,
        upload: {
          ...initialUpload,
          validating: true,
        },
      };
    }
    case ERROR_PARSE_ACCOUNTS: {
      const { error } = action;

      return {
        ...state,
        upload: {
          ...state.upload,
          error,
        },
      };
    }
    case SET_LOOKALIKE_COUNT: {
      const { count, maxValue, minValue } = action;
      return {
        ...state,
        lookalikeCount: Math.max(Math.min(count, maxValue), minValue),
      };
    }
    case CHANGE_SAVE_MODAL_VISIBILITY: {
      const { visible } = action;
      return {
        ...state,
        saveModalVisible: visible,
      };
    }
    case SYNC_SEGMENT_SUCCESS:
    case SYNC_SEGMENT_FAILURE:
    case SYNC_SEGMENT_REQUEST: {
      const payload = omit(action, 'type');
      return {
        ...state,
        sync: {
          ...state.sync,
          ...payload,
        },
      };
    }
    case LOAD_COUNTRIES_OF_SEGMENT_SUCCESS:
    case LOAD_COUNTRIES_OF_SEGMENT_FAILED: {
      const payload = omit(action, 'type');
      return {
        ...state,
        ...payload,
      };
    }
    case SYNC_DONE: {
      const { segmentID } = action;
      const { segmentsList } = state;
      return {
        ...state,
        segmentsList: map(
          segmentsList,
          (segment) => segment.id === segmentID
            ? { ...segment, sync_status: 'sync_done' }
            : segment
        ),
      };
    }
    case TOGGLE_SYNC_CRM_ACCOUNTS_LIST: {
      return {
        ...state,
        crm: {
          ...state.crm,
          syncFromAccountList: true,
          syncFromReport: false,
        },
      };
    }
    case TOGGLE_SYNC_CRM_REPORTS: {
      return {
        ...state,
        crm: {
          ...state.crm,
          syncFromAccountList: false,
          syncFromReport: true,
        },
      };
    }

    case RESET_CRM_SYNC_TOGGLES: {
      return {
        ...state,
        crm: {
          ...state.crm,
          syncFromAccountList: false,
          syncFromReport: false,
        },
      };
    }

    case LOAD_CRM_LISTS_REQUEST:
    case LOAD_CRM_LISTS_FAILURE:
    case SAVE_CRM_LISTS_ERROR:
    case LOAD_CRM_LISTS_SUCCESS: {
      const payload = omit(action, 'type');
      return {
        ...state,
        crm: {
          ...state.crm,
          loadCrmLists: { ...state.crm.loadCrmLists, ...payload },
        },
      };
    }
    case SEARCH_CRM_REPORTS_REQUEST:
    case SEARCH_CRM_REPORTS_FAILURE:
    case SEARCH_CRM_REPORTS_SUCCESS:
    case SAVE_CRM_REPORTS_ERROR: {
      const payload = omit(action, 'type');
      return {
        ...state,
        crm: {
          ...state.crm,
          loadCrmReports: { ...state.crm.loadCrmReports, ...payload },
        },
      };
    }

    case SELECT_CRM_LIST: {
      return {
        ...state,
        crm: {
          ...state.crm,
          selectedCrmList: {
            ...state.crm.selectedCrmList,
            ...action.selectedCrmList,
          },
        },
      };
    }

    case UNSELECT_CRM_LIST: {
      return {
        ...state,
        matchStats: action.matchStats,
        crm: {
          ...state.crm,
          selectedCrmList: action.selectedCrmList,
          loadCrmListData: { ...initialLoadingState },
        },
      };
    }

    case SELECT_CRM_REPORT: {
      return {
        ...state,
        crm: {
          ...state.crm,
          selectedCrmReport: {
            ...state.crm.selectedCrmReport,
            ...action.selectedCrmReport,
          },
        },
      };
    }

    case UNSELECT_CRM_REPORT: {
      return {
        ...state,
        matchStats: action.matchStats,
        crm: {
          ...state.crm,
          selectedCrmReport: action.selectedCrmReport,
          loadCrmReports: { ...state.crm.loadCrmReports, crmReports: [] },
          loadCrmReportData: { ...initialLoadingState, reportErrorMessage: undefined },
        },
      };
    }

    case LOAD_CRM_LIST_DATA_REQUEST:
    case LOAD_CRM_LIST_DATA_FAILURE: {
      const payload = omit(action, 'type');
      return {
        ...state,
        crm: {
          ...state.crm,
          loadCrmListData: { ...state.crm.loadCrmListData, ...payload },
        },
      };
    }
    case LOAD_CRM_LIST_DATA_SUCCESS: {
      const payload = omit(action, 'type', 'data');
      return {
        ...state,
        crm: {
          ...state.crm,
          loadCrmListData: {
            ...state.crm.loadCrmListData,
            ...payload,
          },
          selectedCrmList: { ...state.crm.selectedCrmList, submitted: true },
        },
      };
    }

    case LOAD_CRM_REPORT_DATA_REQUEST:
    case LOAD_CRM_REPORT_DATA_FAILURE:
    case VALIDATE_CRM_REPORT_FAILURE: {
      const payload = omit(action, 'type');
      return {
        ...state,
        crm: {
          ...state.crm,
          loadCrmReportData: { ...state.crm.loadCrmReportData, ...payload },
        },
      };
    }
    case LOAD_CRM_REPORT_DATA_SUCCESS: {
      const payload = omit(action, 'type', 'data');
      return {
        ...state,
        crm: {
          ...state.crm,
          loadCrmReportData: {
            ...state.crm.loadCrmListReport,
            ...payload,
          },
          selectedCrmReport: { ...state.crm.selectedCrmReport, submitted: true },
        },
      };
    }

    case LOAD_AND_VERIFY_CRM_CREDENTIALS_REQUEST:
    case LOAD_CRM_CREDENTIALS_FAILURE:
    case LOAD_CRM_CREDENTIALS_SUCCESS: {
      const payload = omit(action, 'type');
      return {
        ...state,
        crm: {
          ...state.crm,
          loadCrmCredentials: { ...state.crm.loadCrmCredentials, ...payload },
        },
      };
    }

    case VERIFY_CRM_CREDENTIALS_FAILURE:
    case VERIFY_CRM_CREDENTIALS_SUCCESS: {
      const payload = omit(action, 'type');
      return {
        ...state,
        crm: {
          ...state.crm,
          verifyCrmCredentials: {
            ...state.crm.verifyCrmCredentials,
            ...payload,
          },
        },
      };
    }
    case MOVE_SEGMENT_TO_FOLDER: {
      return {
        ...state,
        segmentMoving: true,
      };
    }
    case MOVE_SEGMENT_TO_FOLDER_SUCCESS: {
      const advancedSearch = state.advancedSearch;
      const newAdvancedSearch = state.allFolders ?
        { ...advancedSearch } : { ...initialState.advancedSearch };
      newAdvancedSearch.folders = cloneDeep(advancedSearch.folders);
      return {
        ...state,
        segmentMoving: false,
        error: false,
        search: '',
        moveSegmentVisibility: false,
        advancedSearch: newAdvancedSearch,
      };
    }
    case MOVE_SEGMENT_TO_FOLDER_FAIL: {
      const errorMessage = action.errorMessage;
      return {
        ...state,
        segmentMoving: false,
        error: true,
        errorMessage,
      };
    }
    case SET_SELECTED_TAG: {
      const selectedTag = action.tag;
      return {
        ...state,
        selectedTag,
      };
    }
    case SET_CREATE_FOLDER_VISIBILITY: {
      const createFolderVisibility = action.visibility;
      return {
        ...state,
        createFolderVisibility,
      };
    }
    case SET_RENAME_FOLDER_VISIBILITY: {
      const renameFolderVisibility = action.visibility;
      return {
        ...state,
        renameFolderVisibility,
      };
    }
    case SET_RENAME_TAG_VISIBILITY: {
      const renameTagVisibility = action.visibility;
      return {
        ...state,
        renameTagVisibility,
      };
    }
    case SET_DETAIL_FOLDER_VISIBILITY: {
      const detailFolderVisibility = action.visibility;
      return {
        ...state,
        detailFolderVisibility,
      };
    }
    case SET_MOVE_SEGMENT_VISIBILITY: {
      const moveSegmentVisibility = action.visibility;
      return {
        ...state,
        moveSegmentVisibility,
      };
    }
    case SET_ADVANCED_SEARCH_VISIBILITY: {
      const advancedSearchVisibility = action.visibility;
      const { advancedSearch } = state;
      const tempAdvancedSearch = {};
      for (const [key, value] of Object.entries(advancedSearch)) {
        tempAdvancedSearch[key] = [...value];
      }
      return {
        ...state,
        advancedSearchVisibility,
        tempAdvancedSearch,
      };
    }
    case SET_MANAGE_TAG_VISIBILITY: {
      const manageTagVisibility = action.visibility;
      if (!manageTagVisibility) {
        const { segTagsManage } = state;
        const resetTags = segTagsManage.map((tag) => {
          const origName = tag.name;
          return {
            ...tag,
            newName: origName,
            is_deleted: false,
          };
        });
        return {
          ...state,
          manageTagVisibility,
          segTagsManage: resetTags,
          manageTagsToCreate: [],
          tagSearchValue: '',
        };
      }
      return {
        ...state,
        manageTagVisibility,
        tagSearchValue: '',
      };
    }
    case SET_EDIT_SEG_TAGS: {
      const editSegTagsVisibility = action.visibility;
      if (!editSegTagsVisibility) {
        return {
          ...state,
          segTagsToCreate: [],
          tagsToRemove: [],
          tagsToAdd: [],
          editSegTagsVisibility,
        };
      }
      return {
        ...state,
        editSegTagsVisibility,
      };
    }
    case SET_DELETE_SEGMENT_VISIBILITY: {
      const deleteSegmentVisibility = action.visibility;
      return {
        ...state,
        deleteSegmentVisibility,
        segmentDependencies: {
          loading: false,
          loaded: false,
          error: false,
          dependencyData: state.segmentDependencies ? state.segmentDependencies.dependencyData : {},
        },
      };
    }
    case SET_DELETE_FOLDER_VISIBILITY: {
      const deleteFolderVisibility = action.visibility;
      return {
        ...state,
        deleteFolderVisibility,
      };
    }
    case CREATE_FOLDER: {
      return {
        ...state,
        creatingFolder: true,
      };
    }
    case CREATE_FOLDER_SUCCESS: {
      return {
        ...state,
        createFolderVisibility: false,
        error: false,
        creatingFolder: false,
        search: '',
        advancedSearch: { ...initialState.advancedSearch },
      };
    }
    case CREATE_FOLDER_FAIL: {
      const errorMessage = action.errorMessage;
      return {
        ...state,
        error: true,
        errorMessage,
        creatingFolder: false,
      };
    }
    case RENAME_FOLDER: {
      return {
        ...state,
        renamingFolder: true,
      };
    }
    case RENAME_FOLDER_SUCCESS: {
      return {
        ...state,
        renameFolderVisibility: false,
        error: false,
        renamingFolder: false,
        advancedSearch: { ...initialState.advancedSearch },
      };
    }
    case RENAME_FOLDER_FAIL: {
      const errorMessage = action.errorMessage;
      return {
        ...state,
        error: true,
        errorMessage,
        renamingFolder: false,
      };
    }
    case MODIFY_MANAGE_TAGS: {
      return {
        ...state,
        modifyingTags: true,
      };
    }
    case MODIFY_SEG_TAGS: {
      return {
        ...state,
        modifyingTags: true,
      };
    }
    case MODIFY_TAGS_SUCCESS: {
      return {
        ...state,
        manageTagVisibility: false,
        error: false,
        modifyingTags: false,
        editSegTagMode: false,
        manageTagsToCreate: [],
        segTagsToCreate: [],
        tagsToAdd: [],
        tagsToRemove: [],
      };
    }
    case MODIFY_TAGS_FAIL: {
      const errorMessage = action.errorMessage;
      return {
        ...state,
        error: true,
        errorMessage,
        modifyingTags: false,
      };
    }
    case DELETE_FOLDER: {
      return {
        ...state,
        deletingFolder: true,
      };
    }
    case DELETE_FOLDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        deleteFolderVisibility: false,
        deletingFolder: false,
        search: '',
      };
    }
    case DELETE_FOLDER_FAIL: {
      const errorMessage = action.errorMessage;
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage,
        deleteFolderVisibility: false,
        deletingFolder: false,
      };
    }
    case CHANGE_SELECTED_SEGMENT: {
      const selectedSegment = action.segment;
      return {
        ...state,
        selectedSegments: [selectedSegment],
        segTagsToCreate: [],
        tagsToAdd: [],
        tagsToRemove: [],
        editSegTagMode: false,
      };
    }
    case SELECT_OR_UNSELECT_SEGMENT: {
      const segment = action.segment;
      const { selectedSegments } = state;
      const curSelected = selectedSegments.filter((selectedSegment) =>
        selectedSegment.id !== segment.id);
      const newSelected = curSelected.length === selectedSegments.length ?
        curSelected.concat([segment]) : curSelected;
      return {
        ...state,
        selectedSegments: newSelected,
      };
    }
    case BULK_SELECT_OR_UNSELECT_SEGMENTS: {
      const { segment, lastSelected } = action;
      // If there is no 'last selected' and this is the first segment clicked
      if (isEmpty(lastSelected)) {
        return {
          ...state,
          selectedSegments: [segment],
        };
      }
      const { selectedSegments, segmentsList } = state;
      const newSelectedSegments = computeRangeSelection(
        segmentsList,
        segment,
        lastSelected,
        selectedSegments,
        (a, b) => a.id === b.id,
      );
      return {
        ...state,
        selectedSegments: newSelectedSegments,
      };
    }
    case SELECT_ALL_UNSELECT_ALL: {
      const { segmentsList, selectedSegments } = state;
      if (selectedSegments.length === segmentsList.length) {
        return {
          ...state,
          selectedSegments: [],
        };
      }
      const segmentListCopy = [...segmentsList];
      return {
        ...state,
        selectedSegments: segmentListCopy,
      };
    }
    case RENAME_TAG: {
      const { oldName, newName, id } = action;
      if (id) {
        const { segTagsManage } = state;
        const tempTagList = map(segTagsManage, (tag) => {
          if (tag.id === id) {
            return { ...tag, newName };
          }
          return { ...tag };
        });
        return {
          ...state,
          renameTagVisibility: false,
          segTagsManage: tempTagList,
        };
      }
      const { manageTagsToCreate } = state;
      const tempTagList = map(manageTagsToCreate, (tag) => {
        if (tag.newName === oldName) {
          return { ...tag, newName };
        }
        return { ...tag };
      });
      return {
        ...state,
        renameTagVisibility: false,
        manageTagsToCreate: tempTagList,
      };
    }
    case DELETE_TAG: {
      const { name, id } = action;
      if (id) {
        const { segTagsManage } = state;
        const tempTagList = map(segTagsManage, (tag) => {
          if (tag.id === id) {
            return { ...tag, is_deleted: true };
          }
          return { ...tag };
        });
        return {
          ...state,
          segTagsManage: tempTagList,
        };
      }
      const { manageTagsToCreate } = state;
      const tempTagList = manageTagsToCreate.filter((tag) => tag.newName !== name);
      return {
        ...state,
        manageTagsToCreate: tempTagList,
      };
    }
    case CREATE_MANAGE_TAG: {
      const { name, user } = action;
      const { manageTagsToCreate } = state;
      const newTag = {
        newName: name,
        created_by: user,
        is_deleted: false,
      };
      const newTagsToCreate = [...manageTagsToCreate, newTag];
      return {
        ...state,
        manageTagsToCreate: newTagsToCreate,
        tagSearchValue: '',
      };
    }
    case CREATE_SEG_TAG: {
      const { name } = action;
      const { segTagsToCreate } = state;
      const newTag = {
        newName: name,
        is_deleted: false,
      };
      const newTagsToCreate = [...segTagsToCreate, newTag];
      return {
        ...state,
        segTagsToCreate: newTagsToCreate,
      };
    }
    case SET_SEG_TAGS_MANAGE: {
      const { tags } = action;
      const tempTags = [...tags];
      return {
        ...state,
        segTagsManage: tempTags,
      };
    }
    case ADD_TAG_TO_ADD: {
      const { name, segTags } = action;
      const { tagsToRemove, tagsToAdd } = state;
      const tag = segTags.filter((segTag) => segTag.name === name)[0];
      if (tagsToRemove.includes(tag.id)) {
        const filteredTagsToRemove = tagsToRemove.filter((id) => id !== tag.id);
        return {
          ...state,
          tagsToRemove: filteredTagsToRemove,
          tagSearchValue: '',
        };
      }
      const newTagsToAdd = tagsToAdd.concat(tag.id);
      return {
        ...state,
        tagsToAdd: newTagsToAdd,
      };
    }
    case ADD_TAG_TO_REMOVE: {
      const { tag } = action;
      if (tag.id) {
        const { tagsToRemove, tagsToAdd } = state;
        const tempTagsToAdd = tagsToAdd.filter((addTag) => addTag !== tag.id);
        if (tagsToAdd.length !== tempTagsToAdd.length) {
          return {
            ...state,
            tagsToAdd: tempTagsToAdd,
          };
        }
        const newTagsToRemove = tagsToRemove.concat(tag.id);
        return {
          ...state,
          tagsToRemove: newTagsToRemove,
        };
      }
      const { segTagsToCreate } = state;
      const tempTagList = segTagsToCreate.filter((cTag) => cTag.newName !== tag.newName);
      return {
        ...state,
        segTagsToCreate: tempTagList,
      };
    }
    case RESET_TAG_EDITS: {
      return {
        ...state,
        segTagsToCreate: [],
        tagsToAdd: [],
        tagsToRemove: [],
        editSegTagMode: false,
      };
    }
    case SET_TAG_SEARCH: {
      const tagSearchValue = action.searchValue;
      return {
        ...state,
        tagSearchValue,
      };
    }
    case RUN_ADVANCED_SEARCH: {
      const { tempAdvancedSearch } = state;
      const advancedSearch = {};
      for (const [key, value] of Object.entries(tempAdvancedSearch)) {
        advancedSearch[key] = [...value];
      }
      return {
        ...state,
        advancedSearch,
      };
    }
    case MODIFY_ADVANCED_SEARCH: {
      const { key, value, temp } = action;
      const { advancedSearch, tempAdvancedSearch } = state;
      const newAdvancedSearch = temp ? { ...tempAdvancedSearch } : { ...advancedSearch };
      if (newAdvancedSearch[key].includes(value)) {
        newAdvancedSearch[key] = newAdvancedSearch[key].filter((val) => value !== val);
      } else {
        newAdvancedSearch[key] = [...newAdvancedSearch[key], value];
      }
      return temp ? {
        ...state,
        tempAdvancedSearch: newAdvancedSearch,
      } : {
        ...state,
        offset: 0,
        selectedSegments: [],
        loadingMore: true,
        advancedSearchLoading: true,
        advancedSearch: newAdvancedSearch,
      };
    }
    case SET_ADVANCED_SEARCH_FOLDERS: {
      const folder = action.folder;
      const { advancedSearch } = state;
      const newAdvancedSearch = state.allFolders ?
      { ...advancedSearch } : { ...initialState.advancedSearch };
      newAdvancedSearch.folders = folder ? [folder.name] : [];
      return {
        ...state,
        advancedSearch: newAdvancedSearch,
      };
    }
    case CLEAR_TEMP_ADVANCED_SEARCH: {
      const tempAdvancedSearch = {
        folders: [],
        tags: [],
        untagged: [],
        created_by: [],
        updated_by: [],
        activity: [],
        creation_type: [],
        published: [],
      };
      const advancedSearch = {
        folders: [],
        tags: [],
        untagged: [],
        created_by: [],
        updated_by: [],
        activity: [],
        creation_type: [],
        published: [],
      };
      return {
        ...state,
        advancedSearch,
        tempAdvancedSearch,
      };
    }
    case SET_CREATED_USER_DROPDOWN_SEARCH: {
      return {
        ...state,
        createdUserDropDownLoading: true,
      };
    }
    case SET_UPDATED_USER_DROPDOWN_SEARCH: {
      return {
        ...state,
        updatedUserDropDownLoading: true,
      };
    }
    case USER_DROPDOWN_SEARCH_SUCCESS: {
      const dropDownUsers = action.users;
      return {
        ...state,
        createdUserDropDownLoading: false,
        updatedUserDropDownLoading: false,
        error: false,
        dropDownUsers,
      };
    }
    case USER_DROPDOWN_SEARCH_FAIL: {
      const errorMessage = action.errorMessage;
      return {
        ...state,
        createdUserDropDownLoading: false,
        updatedUserDropDownLoading: false,
        error: true,
        errorMessage,
      };
    }
    case SET_EDIT_SEG_TAG_MODE: {
      const editSegTagMode = action.mode;
      return {
        ...state,
        editSegTagMode,
      };
    }
    case MODIFY_SEGMENT_TAGS: {
      const { tagsToAdd, tagsToRemove, segIds } = action;
      const { segmentsList, selectedSegments } = state;
      const newSegmentList = segmentsList.map((segment) => {
        if (segIds.includes(segment.id)) {
          const origTags = [...segment.seg_tags];
          const filteredTags = origTags.filter((tag) => !tagsToRemove.includes(tag));
          const newTags = filteredTags.concat(tagsToAdd);
          return { ...segment, seg_tags: newTags };
        }
        return segment;
      });
      const newSelected = selectedSegments.map((segment) => {
        if (segIds.includes(segment.id)) {
          const origTags = [...segment.seg_tags];
          const filteredTags = origTags.filter((tag) => !tagsToRemove.includes(tag));
          const newTags = filteredTags.concat(tagsToAdd);
          return { ...segment, seg_tags: newTags };
        }
        return segment;
      });
      return {
        ...state,
        segmentsList: newSegmentList,
        selectedSegments: newSelected,
      };
    }
    case FETCH_SEGMENT_DEPENDENCIES:
      return {
        ...state,
        segmentDependencies: {
          dependencyData: {},
          loading: true,
          loaded: false,
          error: false,
        },
      };
    case FETCH_SEGMENT_DEPENDENCIES_SUCCESS:
      return {
        ...state,
        segmentDependencies: {
          loading: false,
          loaded: true,
          error: false,
          dependencyData: action.dependencyData,
        },
      };
    case FETCH_SEGMENT_DEPENDENCIES_FAILED:
      return {
        ...state,
        segmentDependencies: {
          dependencyData: {},
          loading: false,
          loaded: true,
          error: action.error,
        },
      };
    case FETCH_SEGMENT_SYNC_SUMMARY:
      return {
        ...state,
        segmentSyncSummary: {
          segmentSyncSummaryData: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_SEGMENT_SYNC_SUMMARY_SUCCESS:
      return {
        ...state,
        segmentSyncSummary: {
          loading: false,
          error: false,
          segmentSyncSummaryData: action.syncSummaryData,
        },
      };
    case FETCH_SEGMENT_SYNC_SUMMARY_FAILED:
      return {
        ...state,
        segmentSyncSummary: {
          segmentSyncSummaryData: {},
          loading: false,
          error: action.error,
        },
      };
    case publishedSegmentActionTypes.UPDATE_PUBLISHED_TAGS_SUCCESS: {
      const { segmentId, tags } = action;
      const newTags = tags.map((tag) => ({
        is_deleted: false,
        segment_tag_id: tag,
        segment_id: segmentId,
      }));
      const newSegmentList = map(state.segmentsList, (seg) => {
        if (segmentId === seg.id) {
          return { ...seg, tags: [...seg.tags, ...newTags] };
        }
        return seg;
      });
      let newSelectedSegment;
      if (state.selectedSegments.length) {
        newSelectedSegment = {
          ...state.selectedSegments[0],
          tags: [...state.selectedSegments[0].tags, ...newTags],
        };
      }
      return {
        ...state,
        segmentsList: newSegmentList,
        selectedSegments: newSelectedSegment ? [newSelectedSegment] : [],
      };
    }
    case SET_CREATE_SEGMENT_MODE:
      return {
        ...state,
        createSegmentMode: action.mode,
      };
    case SET_ADV_SEARCH_ALL_FOLDERS_TOGGLE:
      return {
        ...state,
        allFolders: action.mode,
      };
    default:
      return state;
  }
}

export const actions = {
  load,
  loadSuccess,
  loadFail,
  loadMore,
  setAction,
  cancelAction,
  setSortby,
  setViewBy,
  setSearch,
  setTypeFilter,
  refresh,
  deleteSegment,
  deleteSegmentSuccess,
  deleteSegmentFail,
  checkAccountStats,
  checkAccountExternalStats,
  loadAccountStats,
  accountStatsSuccess,
  accountStatsFail,
  startParseAccounts,
  errorParseAccounts,
  setLookalikeCount,
  showSaveCloneModal,
  openCloneSegmentModal,
  cloneSegment,
  cloneSegmentSuccess,
  cloneSegmentFailure,
  changeSaveModalVisibility,
  resetOffset,
  setSegmentsListLoading,
  toggleSyncFromAccountList,
  toggleSyncFromReport,
  resetCrmSyncToggles,
  loadCrmLists,
  loadCrmListsFailure,
  loadCrmListsSuccess,
  saveLoadCrmListsError,
  searchCrmReports,
  searchCrmReportsFailure,
  searchCrmReportsSuccess,
  saveLoadCrmReportsError,
  loadCrmListData,
  loadCrmListDataFailure,
  loadCrmListDataSuccess,
  loadCrmReportData,
  loadCrmReportDataFailure,
  loadCrmReportDataSuccess,
  validateCrmReportFailure,
  selectCrmList,
  unselectCrmList,
  selectCrmReport,
  unselectCrmReport,
  loadCrmCredentials,
  loadCrmCredentialsFailure,
  loadCrmCredentialsSuccess,
  verifyCrmCredentialsFailure,
  verifyCrmCredentialsSuccess,
  downloadAccounts,
  syncSegment,
  syncSegmentFailure,
  syncSegmentSuccess,
  pollSync,
  cancelPollSync,
  loadCountriesSupportedBySegment,
  loadCountriesSupportedBySegmentSuccess,
  loadCountriesSupportedBySegmentFail,
  updateSegmentInList,
  fetchAndUpdateSegment,
  fetchAndUpdateSegmentInList,
  setSelectedTag,
  setCreateFolderVisibility,
  setRenameFolderVisibility,
  setDetailFolderVisibility,
  setManageTagVisibility,
  setRenameTagVisibility,
  setMoveSegmentVisibility,
  setAdvancedSearchVisibility,
  setDeleteSegmentVisibility,
  setDeleteFolderVisibility,
  showEditSegTags,
  createFolder,
  createFolderSuccess,
  createFolderFail,
  renameFolder,
  renameFolderSuccess,
  renameFolderFail,
  deleteFolder,
  deleteFolderSuccess,
  deleteFolderFail,
  changeSelectedSegment,
  selectOrUnselectSegment,
  bulkSelectOrUnselectSegments,
  selectAllUnselectAll,
  renameTag,
  deleteTag,
  createManageTag,
  createSegTag,
  setTagSearch,
  addTagToAdd,
  addTagToRemove,
  modifyManageTags,
  modifySegTags,
  modifyTagsSuccess,
  modifyTagsFail,
  moveSegmentToFolder,
  moveSegmentToFolderSuccess,
  moveSegmentToFolderFail,
  setSegTagsManage,
  modifyAdvancedSearch,
  clearTempAdvancedSearch,
  setAdvancedSearchFolders,
  setCreatedUserDropdownSearch,
  setUpdatedUserDropdownSearch,
  userDropdownSearchSuccess,
  userDropdownSearchFail,
  resetEdits,
  setEditSegTagMode,
  modifySegmentTags,
  runAdvancedSearch,
  fetchSegmentDependencies,
  fetchSegmentDependenciesSuccess,
  fetchSegmentDependenciesFailed,
  resetMatchStats,
  setCreateSegmentMode,
  setAdvAllFolderToggle,
  fetchSegmentSyncSummary,
  fetchSegmentSyncSummarySuccess,
  fetchSegmentSyncSummaryFailed,
};

export const actionTypes = {
  LOAD,
  LOAD_MORE,
  REFRESH,
  DELETE_SEGMENT,
  DELETE_SEGMENT_SUCCESS,
  CHECK_ACCOUNT_STATS,
  CHECK_ACCOUNT_EXTERNAL_STATS,
  LOAD_ACCOUNT_STATS,
  CLONE_SEGMENT,
  CLONE_SEGMENT_SUCCESS,
  SHOW_SAVE_CLONE_MODAL,
  SET_SORT_BY,
  SET_SEARCH,
  RUN_ADVANCED_SEARCH,
  LOAD_CRM_LISTS_REQUEST,
  LOAD_CRM_LISTS_FAILURE,
  LOAD_CRM_LISTS_SUCCESS,
  SAVE_CRM_LISTS_ERROR,
  SEARCH_CRM_REPORTS_REQUEST,
  SEARCH_CRM_REPORTS_FAILURE,
  SEARCH_CRM_REPORTS_SUCCESS,
  SAVE_CRM_REPORTS_ERROR,
  LOAD_CRM_LIST_DATA_REQUEST,
  LOAD_CRM_LIST_DATA_FAILURE,
  LOAD_CRM_LIST_DATA_SUCCESS,
  LOAD_CRM_REPORT_DATA_REQUEST,
  LOAD_CRM_REPORT_DATA_FAILURE,
  LOAD_CRM_REPORT_DATA_SUCCESS,
  VALIDATE_CRM_REPORT_FAILURE,
  SELECT_CRM_LIST,
  UNSELECT_CRM_LIST,
  SELECT_CRM_REPORT,
  UNSELECT_CRM_REPORT,
  RESET_CRM_SYNC_TOGGLES,
  LOAD_AND_VERIFY_CRM_CREDENTIALS_REQUEST,
  LOAD_CRM_CREDENTIALS_FAILURE,
  LOAD_CRM_CREDENTIALS_SUCCESS,
  VERIFY_CRM_CREDENTIALS_FAILURE,
  VERIFY_CRM_CREDENTIALS_SUCCESS,
  DOWNLOAD_ACCOUNTS,
  SET_VIEW_BY,
  SYNC_SEGMENT_REQUEST,
  SYNC_SEGMENT_SUCCESS,
  LOAD_COUNTRIES_OF_SEGMENT,
  FETCH_AND_UPDATE_SEGMENT,
  FETCH_AND_UPDATE_SEGMENT_IN_LIST,
  UPDATE_SEGMENT_IN_LIST,
  CREATE_FOLDER,
  RENAME_FOLDER,
  DELETE_FOLDER,
  MODIFY_MANAGE_TAGS,
  MODIFY_SEG_TAGS,
  MOVE_SEGMENT_TO_FOLDER,
  SET_CREATED_USER_DROPDOWN_SEARCH,
  SET_UPDATED_USER_DROPDOWN_SEARCH,
  FETCH_SEGMENT_DEPENDENCIES,
  FETCH_SEGMENT_DEPENDENCIES_SUCCESS,
  FETCH_SEGMENT_DEPENDENCIES_FAILED,
  RESET_ACCOUNT_MATCH_STATS,
  MODIFY_ADVANCED_SEARCH,
  CLEAR_TEMP_ADVANCED_SEARCH,
  FETCH_SEGMENT_SYNC_SUMMARY,
  FETCH_SEGMENT_SYNC_SUMMARY_SUCCESS,
  FETCH_SEGMENT_SYNC_SUMMARY_FAILED,
};

export default segmentsManageReducer;
