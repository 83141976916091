import { getAsyncInjectors } from 'store/utils';
import CreateCampaignRoute from './routes/CreateCampaign';
import CampaignConfiguration from './routes/CampaignConfiguration';
import ListCampaigns from './routes/ListCampaigns';
import { CAMPAIGNS_ROUTE } from './constants';
import { ADVERTISING_BASE_ROUTE } from '../../constants';
import { CAMPAIGN_LIST_ROUTE } from './routes/ListCampaigns/constants';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: CAMPAIGNS_ROUTE,
    name: 'Campaigns',
    indexRoute: {
      onEnter: (nextState, replace) => replace(
        `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGN_LIST_ROUTE}`
      ),
    },
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('campaigns', reducer.default);
        injectSagas('campaigns', sagas.default);

        const inProductHelpReducer = require('./containers/InProductFAQs/modules');
        injectReducer('campaignInProductFAQs', inProductHelpReducer.default);

        cb(null, containers.Campaigns);
      }, 'campaign');
    },
    childRoutes: [
      CreateCampaignRoute(store),
      CampaignConfiguration(store),
      ListCampaigns(store),
    ],
  };
}
