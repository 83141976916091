import { AND, BOOLCONST, CONST, INTCONST, NULLEXPR, OR, VAREXPR } from 'ast-redux/constants';
import { getConstType } from 'ast-redux/constructors';
import { cloneDeep, concat, isArray } from 'lodash';
import { toAST, walkWith } from '.';
import * as astObjects from './astClasses';

const CONST_OPS = [INTCONST, BOOLCONST, CONST];
const BINARY_OPS = [
  'LtOp',
  'LeOp',
  'EqOp',
  'NeOp',
  'NotEqOp',
  'GeOp',
  'GtOp',
  'RegExpOp',
  'StartsWithOp',
  'ContainsOp',
  'NotContainsOp',
  'EndsWithOp',
  'LikeOp',
  'NotLikeOp',
  'RLikeOp',
  'NotRLikeOp',
  'IsNotOp',
  'IsOp',
  'RenameOp',
];

const filtersetFormatHelper = (relationship, filters) => {
  if (relationship.type === INTCONST) {
    if (!relationship.value) {
      throw new Error('At least one filter has no values.');
    }

    const nextFilter = filters[relationship.value - 1];

    if (nextFilter === null) {
      throw new Error('Expected number of filters does not match.');
    }

    return filterFormatForAst(nextFilter);
  }
  if (relationship.type === AND || relationship.type === 'And') {
    if (!relationship.lhs || !relationship.rhs) {
      throw new Error('Filters structured incorrectly.');
    }

    return new astObjects.ModelAnd(
      filtersetFormatHelper(relationship.lhs, filters),
      filtersetFormatHelper(relationship.rhs, filters)
    );
  }

  if (relationship.type === OR || relationship.type === 'Or') {
    if (!relationship.lhs || !relationship.rhs) {
      throw new Error('Filters structured incorrectly.');
    }

    return new astObjects.ModelOr(
      filtersetFormatHelper(relationship.lhs, filters),
      filtersetFormatHelper(relationship.rhs, filters)
    );
  }

  if (relationship.type === NULLEXPR) {
    return new astObjects.NullExpr();
  }

  throw new Error('Filters structured incorrectly.');
};

const filterFormatForAst = ({ filter_values: filterValues, filter }) => {
  if (filterValues.length === 0) {
    return filterValueToAst(filter, { value: '' });
  }

  if (filterValues.length === 1) {
    return filterValueToAst(filter, filterValues[0]);
  }

  let current;

  if (filter.value_relationship === 'OR') {
    current = new astObjects.ModelOr(
      filterValueToAst(filter, filterValues.pop()),
      filterValueToAst(filter, filterValues.pop())
    );

    while (filterValues.length > 0) {
      current = new astObjects.ModelOr(current, filterValueToAst(filter, filterValues.pop(-1)));
    }
  } else {
    current = new astObjects.ModelAnd(
      filterValueToAst(filter, filterValues.pop(-1)),
      filterValueToAst(filter, filterValues.pop(-1))
    );
    while (filterValues.length > 0) {
      current = new astObjects.ModelAnd(current, filterValueToAst(filter, filterValues.pop(-1)));
    }
  }

  return current;
};

const filterValueToAst = ({ variable, operator }, { value }) => {
  const AstClass = astObjects[operator];
  if (!AstClass) {
    throw new Error(`Invalid operator [${operator}] for atleast one of the filter.`);
  }

  return new AstClass(new astObjects.VarExpr(variable), new astObjects.Const(value));
};

/**
 * Walks through filters and relations and create equivalent AST tree object.
 *
 * Currently supports only binary operators in filterset values.
 * @param {*} filterSet object { relationship, filters }
 * @returns
 */
export const filtersetToAst = (filterSet) => {
  const { relationship, filters } = cloneDeep(filterSet);
  return filtersetFormatHelper(relationship, filters);
};

const astMergeSameCondition = (astJson) => {

  // Walk with ast json, to merge the multiple OR operation with same variable or operation type
  // to make a array of filter values.
  walkWith((expr) => {
    if (expr.type === 'ModelOr' || expr.type === 'Or') {
      // Check if operations are same.
      if (expr.lhs.type === expr.rhs.type && BINARY_OPS.includes(expr.lhs.type)) {
        let leftVariable;
        let rightVariable;
        // Get the left variable based on lhs VarExpr name.
        if (expr.lhs.lhs.type === VAREXPR) {
          leftVariable = expr.lhs.lhs.name;
        } else if (expr.lhs.rhs.type === VAREXPR) {
          leftVariable = expr.lhs.rhs.name;
        }

        // Get the right variable based on lhs VarExpr name.
        if (expr.rhs.lhs.type === VAREXPR) {
          rightVariable = expr.rhs.lhs.name;
        } else if (expr.rhs.rhs.type === VAREXPR) {
          rightVariable = expr.rhs.rhs.name;
        }

        if (leftVariable && rightVariable && leftVariable === rightVariable) {
          // Merge the values to array if variables and operation both are same.
          let constData = [];
          if (CONST_OPS.includes(expr.lhs.lhs.type)) {
            constData = concat(expr.lhs.lhs.value, constData);
          }
          if (CONST_OPS.includes(expr.lhs.rhs.type)) {
            constData = concat(expr.lhs.rhs.value, constData);
          }
          if (CONST_OPS.includes(expr.rhs.lhs.type)) {
            constData = concat(expr.rhs.lhs.value, constData);
          }
          if (CONST_OPS.includes(expr.rhs.rhs.type)) {
            constData = concat(expr.rhs.rhs.value, constData);
          }

          // eslint-disable-next-line no-param-reassign
          expr.type = expr.lhs.type;
          // eslint-disable-next-line no-param-reassign
          expr.lhs = {
            name: leftVariable,
            type: VAREXPR,
          };
          // eslint-disable-next-line no-param-reassign
          expr.rhs = {
            value: constData,
            type: CONST,
          };
        }
      }
    }
  }, astJson);

  return toAST(astJson);
};

/**
 * Walk through the AST and converts it to filterset object.
 *
 * Currently supports only binary operators in filterset values.
 * @param {*} ast AST class object.
 * @returns {*} { filters, relationship}
 */
export const astToFilterset = (astJSON) => {
  const filters = [];
  let relationship = { type: NULLEXPR };
  const relArr = [];
  const mergedAst = astMergeSameCondition(astJSON);

  // Walk again with AST for actual filterset translation.
  walkWith((expr) => {
    if (expr instanceof astObjects.LogicalOperator) {
      const rhs = relArr.pop();
      const lhs = relArr.pop();

      // Merge the relationship in case of logical ops
      relationship = {
        lhs,
        rhs,
        type: expr.constructor.name,
      };
      relArr.push(relationship);
    } else if (expr instanceof astObjects.BinaryExpr) {
      // In case of binary ops, get the variable and const from lhs and rhs.
      // Push that variable and values in filters array and as relation as IntConst
      let variable;
      let values;
      let valueAssigned;

      if (expr.lhs instanceof astObjects.VarExpr) {
        variable = expr.lhs.name;
      } else if (expr.rhs instanceof astObjects.VarExpr) {
        variable = expr.rhs.name;
      }

      if (expr.lhs instanceof astObjects.Const) {
        values = expr.lhs.value;
        valueAssigned = true;
      } else if (expr.rhs instanceof astObjects.Const) {
        values = expr.rhs.value;
        valueAssigned = true;
      }

      if (variable && valueAssigned) {
        if (!isArray(values)) {
          values = [values];
        }

        filters.push({
          filter: {
            variable,
            operator: expr.constructor.name,
            // Assuming that value relationship will be OR always.
            value_relationship: 'OR',
            metadata: {},
            // harcoded false// support for filtersetToAst pending, cause BE doesnt support that yet
            isCaseSensitive: false,
          },
          filter_values: values.map((value) => ({
            value,
            display_name: value,
            metadata: {
              type: getConstType(value),
            },
          })),
        });

        relationship = { type: INTCONST, value: `${filters.length}` };
        relArr.push(relationship);
      }
    }
  }, mergedAst);

  return {
    filters,
    relationship,
  };
};
