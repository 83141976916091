export const SALESWHALE_PATH = '6sense-conversational-email';

export const SALESWHALE_STATE_KEY = 'saleswhale';

export const SALESWHALE_ACTIONNAME_BASE_STRING = 'SETTINGS/INTEGRATION/SALESWHALE';

export const SALESWHALE_FORM_STEP_IDS = Object.freeze({
  CONNECT: 'connect',
  DATASYNC: 'datasync',
});

export const SALESWHALE_API_KEY_FORM_NAME = 'saleswhale-api-key';

export const SALESWHALE_REFRESH_ACTION_NAME = `${SALESWHALE_ACTIONNAME_BASE_STRING}/REFRESH`;

export const API_LIMITS_MODAL_FORM = 'API_LIMITS_MODAL_FORM';

export const PERMISSION_CHECK_QUEUE_MSG = 'Permission check retry initiated successfully.';

export const UNKNOWN_ERROR_MSG = 'Unknown error, please try again in sometime.';
