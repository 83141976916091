import { Flex } from '@sixsense/core/components';
import React, { useState, useMemo } from 'react';
import { Button, Modal, Text, Input, Loading } from 'v2-components';
import { GroupSelect } from '../';
import { css } from 'styles/emotion';
import { useSharedState, useRequest, useSetSharedValue } from '@sixsense/core';
import {
  bulkAddToGroupConfigState,
  groupsConfigState,
  DEFAULT_BULK_ADD_GROUPS_CONFIG_STATE,
  keywordGroupSelectQueryState,
} from '../../state';
import {
  validateBulkAddToGroupsConfig,
  parseKeywords,
  normalizeKeyword,
  getUpdatedConfig,
  resetConfig,
} from '../../utils';
import { actions as globalActions } from 'modules/global';
import { useOrg } from 'hooks';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { NEW_GROUP, GROUPS } from '../../constants';
import { InvalidKeywordsModal, CreateGroupModal } from '../Modals';
import { maybePlural } from 'utils/utils';
import { AAColor } from '@sixsense/core/style';

type Props = {
  onCancel: () => void;
};

const styles = {
  button: css({
    marginRight: '10px',
    height: '30px',
  }),
  select: css({
    placeContent: 'flex-start !important',
    marginTop: '10px',
    marginBottom: '200px',
  }),
  error: css({
    marginBottom: '20px',
  }),
  loadingIcon: css({
    marginLeft: '10px',
  }),
  label: css({
    lineHeight: 3,
  }),
  dropdownStyle: css`
    width: 40%;
    margin-bottom: 200px;
    margin-top: 10px;
    :global(.ant-select-selection) {
        border:none !important;
        box-shadow: none !important;
        :global(.ant-select-selection__choice){
          background-color: #E5F2FA;
        }
      }
  `,
  option: css({
    whiteSpace: 'normal !important',
    borderBottom: `1px solid ${AAColor.GREY4}`,
  }),
  newGroupLabel: css({
    marginLeft: '5px',
  }),
  backIcon: css({
    marginRight: '10px',
  }),
  input: css({
    width: '30em',
    minHeight: '8em',
    resize: 'vertical',
    margin: '10px 0 10px 0',
  }),
};

const BulkAddToGroupModal = ({ onCancel }: Props) => {
  const [config, setBulkConfig] = useSharedState(bulkAddToGroupConfigState);
  const [kwConfig, setKwConfig] = useSharedState(groupsConfigState);
  const setGroupSelectQuery = useSetSharedValue(keywordGroupSelectQueryState);
  const [duplicateKeywords, setDuplicateKeywords] = useState([]);
  const [invalidModalVisible, setinvalidModalVisible] = useState(false);
  const [createGroupModalVisible, setCreateGroupModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  // Load Groups
  const org = useOrg();
  const request = useRequest();
  const dispatch = useDispatch();
  const validation = useMemo(() => validateBulkAddToGroupsConfig(config), [config]);

  // Make sure to reset modal values on close.
  const onClose = () => {
    onCancel();
    setGroupSelectQuery(undefined);
    setBulkConfig({ ...DEFAULT_BULK_ADD_GROUPS_CONFIG_STATE });
    setDirty(false);
  };

  const addToGroups = async () => {
    let filteredKws = [...config.keywords];
    // Need to check whether form is dirty, ie. did the user close the invalid keywords
    // modal and try to save again or did they save from the invalid modal.
    if (duplicateKeywords.length > 0 && dirty) {
      const invalidKws = duplicateKeywords.map((v) => normalizeKeyword(v));
      filteredKws = config.keywords.filter((v) => !invalidKws.includes(normalizeKeyword(v)));
    }
    setDuplicateKeywords([]);

    // Need to make sure that after filtering out invalid keywords (if the validation failed)
    // there is still keywords to save
    if (filteredKws.length > 0) {
      try {
        const requestUrl = `organization/${org.id}/product_keyword/${0}/keyword/?is_bulk=True`;
        const body = {
          action: 'add_to',
          config: {
            location: 'existing_group',
            group_name: null,
            group_ids: config.keyword_groups.filter((v) => v !== NEW_GROUP),
            target_product_ids: null,
            keywords: filteredKws.map((keyword) => keyword.trim()),
            normalized_keywords: filteredKws.map((keyword) => normalizeKeyword(keyword.trim())),
          },
        };
        setLoading(true);
        await request(requestUrl, {
          method: 'PATCH',
          body: JSON.stringify(body),
        });
        dispatch(globalActions.showNotification('success',
        `Successfully added to ${maybePlural(config.keyword_groups.length, 'group')}`));
        onClose();
        // Refresh main keywords page
        const keywordPageConfig = getUpdatedConfig(kwConfig);
        keywordPageConfig.recentlyAddedKws = [...kwConfig.recentlyAddedKws].concat(filteredKws);
        resetConfig(keywordPageConfig, setKwConfig);
      } catch (e) {
        // 400 Request means server validation failed (ie. duplicate keywords.)
        if (e.errorStatus === 400) {
          const { body } = e;
          setDuplicateKeywords(body.keywords);
          setinvalidModalVisible(true);
          setDirty(true);
        }
      }
      setLoading(false);
    }
  };

  const setKeywordGroups = (val) => {
    setBulkConfig({ ...config, keyword_groups: val });
  };

  const footer = (
    <Flex direction="column" style={{ padding: '10px 0' }}>
      <Flex justifyContent="flex-end">
        <Button
          pointer
          className={styles.button}
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
        <Button
          pointer
          className={styles.button}
          type={'primary'}
          disabled={loading}
          onClick={() => {
            setDirty(true);
            if (validation.status === 'VALID') {
              addToGroups();
            }
          }}
        >
        Add Keywords to groups
        </Button>
        {loading && (
          <Loading
            size="small"
            spinnerWidth={15}
            spinnerHeight={15}
            pageLevel={false}
            className={styles.loadingIcon}
          />
        )}
      </Flex>
    </Flex>
  );

  return (
    <Modal
      visible
      bodyStyle={{ padding: 0, minHeight: 400 }}
      footer={footer}
      width="1250px"
      maskClosable={false}
      onCancel={() => onClose()}
    >
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9', alignContent: 'center' }}>
        <Text type={Text.TYPE.TITLE} bold>
          Add Existing Keywords to Groups
        </Text>
      </Flex>
      <div style={{ padding: '20px 30px' }}>
        <Flex
          direction="column"
          style={{ marginTop: '10px' }}
        >
          {(dirty && validation.status === 'INVALID' && validation.error.includes('select')) && (
            <Text className={styles.error} color={Text.COLOR.ERROR}>{validation.error}</Text>
          )}
          <Text bold>Keywords</Text>
        </Flex>
        <Input
          value={config.keywords.join('\n')}
          className={styles.input}
          type={'textarea'}
          placeholder={'List existing keywords, each on a separate line.'}
          onChange={({ target: { value } }) =>
            setBulkConfig({ ...config, keywords: parseKeywords(value) })}
        />
        {(dirty && validation.status === 'INVALID' && !validation.error.includes('select')) && (
          <Text className={styles.error} color={Text.COLOR.ERROR}>{validation.error}</Text>
        )}
        <div style={{ marginTop: '20px' }}>
          <Text className={styles.label} bold>Add to a keyword group</Text>
          <GroupSelect
            groups={config.keyword_groups}
            setGroups={setKeywordGroups}
            setCreateGroupModalVisible={setCreateGroupModalVisible}
            width={40}
          />
        </div>
        {invalidModalVisible && (
          <InvalidKeywordsModal
            onCancel={() => {
              setinvalidModalVisible(false);
              setDirty(false);
            }}
            config={config}
            invalidKeywords={duplicateKeywords}
            onSave={addToGroups}
            type={GROUPS}
          />
        )}
        {createGroupModalVisible && (
          <CreateGroupModal
            onCancel={() => setCreateGroupModalVisible(false)}
            fromBulkAddGroups
          />
        )}
      </div>
    </Modal>
  );
};

export default BulkAddToGroupModal;
