import TechnologiesRoute from './routes/Technologies';
import PersonaMap from './routes/PersonaMap';
import Navigation from './routes/Navigation';
import Activities from './routes/Activities';
import TalkingPointRules from './routes/TalkingPointRules';
import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: 'salesconfig',
    name: 'salesconfig',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/settings/salesconfig/navigation'),
    },

    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./routes/Navigation/sagas');
        const reducer = require('./routes/Navigation/modules');
        injectReducer('salesConfigNavigation', reducer.default);
        injectSagas('navigation', sagas.default);
        cb(null, containers.SalesConfig);
      }, 'settings_sales');
    },
    childRoutes: [
      TechnologiesRoute(store),
      PersonaMap(store),
      Navigation(store),
      Activities(store),
      TalkingPointRules(store),
    ],
  };
}
