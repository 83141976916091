import { DefaultRole } from '@sixsense/rbac';
import { UserStatus } from './types';
import { isEmpty, every } from 'lodash';

export const DEFAULT_ROLE_ORDER = {
  [DefaultRole.PRIMARY_ADMINISTRATOR]: 0,
  [DefaultRole.ADMINISTRATOR]: 1,
  [DefaultRole.OPERATION_USER]: 2,
  [DefaultRole.MARKETING_USER]: 3,
  [DefaultRole.VIEW_ONLY_USER]: 4,
  [DefaultRole.INSIGHTS_USER]: 5,
  [DefaultRole.SALES_USER]: 6,
  [DefaultRole.USER__LEGACY]: 7,
};

export const PASSWORD_EXPIRATION_LIMIT = 90;

export const INVITE_EXPIRATION_LIMIT = 3;

// eslint-disable-next-line no-undef
export const STATUS_LABEL_MAP: Record<UserStatus, string> = {
  enabled: 'Enabled',
  disabled: 'Disabled',
  invited: 'Invited',
  inviteExpired: 'Expired Invite',
};

export const NoRoleSelectedOptionForInviteUserValue = -1;
export const NoRoleAssignSelectedOptionForEditUserValue = -1;

export const DUMMY_SELECT_VALUE = 'DUMMY_SELECT_VALUE';

export const ADVANCED_SEARCH_DEFAULT_FILTER = {
  values: {
    statusFilter: 'enabled',
    rolesFilterAppMap: {},
    isEmail: false,
    emailsListFilter: [],
  },
  isEqualFuncObject: {
    statusFilter: (newVal) => newVal === 'enabled',
    rolesFilterAppMap: (newVal) => isEmpty(newVal),
    isEmail: (newVal) => newVal === false,
    emailsListFilter: (newVal) => newVal.length === 0,
  },
};

export const BULK_ACTION_DEFAULT_FILTER = {
  values: {
    actionFilter: DUMMY_SELECT_VALUE,
    roleFilter: {},
  },
  isEqualFuncObject: {
    actionFilter: (newVal) => newVal === DUMMY_SELECT_VALUE,
    roleFilter: (newVal) => every(newVal, (roleId) => (
        roleId === undefined || roleId === null || roleId === DUMMY_SELECT_VALUE
      )
    ),
  },
};

export const MAX_USER_SELECT_LIMIT_FOR_BULK_UPDATE = 100;

export const errorNotificationContainerElementId = 'errorNotificationContainerElementId';

export const appBodyElementId = 'appBody';

export const OWNER = 'Owner';
export const ADMIN = 'Admin';
export const USER = 'User';
export const L_USER = 'Limited User';


// these are roles for users within THEIR OWN organization
export const ORGANIZATION_ROLES = [OWNER, USER, L_USER];


// These are here as a temporary way to allow 6sense access across platforms
export const INTERNAL_USER = 'Internal User';
export const STAFF = 'Staff';
export const SUPER_USER = 'Super User';
export const DEV = 'Developer';
export const ACCESSIBLE_INTERNAL_ROLES = [INTERNAL_USER, STAFF, SUPER_USER, DEV];

// forbidden from owner endpoint
export const FORBIDDEN_INTERNAL_ROLES = [INTERNAL_USER, DEV];

export const CHANGE_ROLE_PERMISSIONS = [OWNER];
export const REMIND_OR_RESET_PERMISSIONS = [OWNER];
export const VIEW_SETTINGS_PERMISSIONS = [OWNER, USER];
export const VIEW_CAMPAIGN_PERMISSIONS = [OWNER, USER];
export const VIEW_ORCHESTRATION_PERMISSIONS = [OWNER, USER];
export const VIEW_UMGMT_PERMISSIONS = [...ACCESSIBLE_INTERNAL_ROLES, OWNER];
