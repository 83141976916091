import { uniqBy } from 'lodash';
import { select, put, call } from 'redux-saga/effects';
import { fetchSaga, debounce } from 'store/sagas';
import { orgSelector } from 'modules/user/selectors';
import { actionTypes, actions } from 'modules/autoComplete';
import { unpackAutocomplete } from 'modules/autoComplete/utils';

const { LOAD_DATA } = actionTypes;
const { loadSuccess } = actions;

function* loadOptions(request, action) {
  const { column, value, key } = action;

  try {
    const org = yield select(orgSelector);
    let endpoint = action.endpoint || `query/${org}/autocomplete/${column}/`;
    if (value) {
      endpoint = `${endpoint}?input_string=${encodeURIComponent(value)}`;
    }
    const options = yield call(request, endpoint);
    const unpackedOptions = unpackAutocomplete(column, options);
    const uniqueOptions = uniqBy(unpackedOptions, 'value');
    yield put(loadSuccess(uniqueOptions, column, key));
  } catch (e) {
    // Add logic for load failed
    console.log(e); // eslint-disable-line no-console
  }
}

function* watchLoadAutoComplete(request) {
  yield debounce(400, [LOAD_DATA], loadOptions, request);
}

export default [fetchSaga(watchLoadAutoComplete)];
