import React, { Fragment } from 'react';
import { Input, Text } from '@sixsense/core/components';
import { ThemeColor } from '@sixsense/core/style';
import { FieldRenderProps } from 'react-final-form';
import { commaSeparatedNumber } from '../../utils';
import { useSelector } from '@sixsense/core/versioned/react-redux';
import { isViewOnlyEnabledFor6senseAccessSelector } from 'modules/user/selectors';

export const DistributeField = (props: FieldRenderProps<any, HTMLElement, any>) => {
  const { input, meta: { error }, disabled } = props;
  const isViewOnlyUser = useSelector(isViewOnlyEnabledFor6senseAccessSelector);
  return (
    <Fragment>
      <Input
        {...input}
        type={'number'}
        inputProps={{
          min: 0,
          onBlur: (e: any) => input.onBlur(e),
          onFocus: (e: any) => input.onFocus(e),
          style: { textAlign: 'right' },
        }}
        className="w100 br1"
        onChange={(val: string) => {
          if (!disabled) {
            input.onChange(parseInt(val));
          }
        }}
        title={commaSeparatedNumber(input?.value)}
        disabled={!!disabled || isViewOnlyUser}
      />
      {error && <Text className="my1" color={`var(${ThemeColor.Error.DEFAULT})`}>
        {error}
      </Text>}
    </Fragment>
  );
};
