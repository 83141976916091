import UsageRoute from './routes/Usage';
import ValueRoute from './routes/Value';
import ModelRoute from './routes/Model';
import ModelUpdateRoute from './routes/ModelUpdate';

export default function createRoutes(store) {
  return {
    path: 'admin',
    name: 'Admin',
    getComponent(_nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          cb(null, containers.ReportsContainer);
        },
        'reports_admin'
      );
    },
    childRoutes: [
      UsageRoute(store),
      ValueRoute(store),
      ModelRoute(store),
      ModelUpdateRoute(store),
    ],
  };
}
