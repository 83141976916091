import React, { Fragment } from "react";
import {
  Flex,
  Text,
  Link,
  Tooltip,
  Avatar,
  Dot,
} from "@sixsense/core/components";
import { get, isEmpty } from "lodash";
import styles from "./CAMPAIGN_NAME.module.scss";
import {
  CAMPAIGN_SOURCE,
  CAMPAIGN_SOURCE_LABELS,
  CAMPAIGN_STATUS_X,
  CAMPAIGNS_ROUTE,
} from "routes/AdvertisingDS/routes/Campaigns/constants";
import { getNeedActionCampaignIndicators } from "../../../utils";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { classNames } from "utils/utils";
import { CAMPAIGNS_CONFIGURATION_ROUTE } from "../../CampaignConfiguration/constants";
import NeedActionIndicator, {
  summaryTooltip,
} from "../../../components/NeedActionIndicator/NeedActionIndicator";

import LinkedInIcon from "images/linkedin_hq.svg";
import SixsenseIcon from "images/6sense-icon.svg";
import { CLASSIFICATION_TYPES } from "routes/AdvertisingDS/ducks/campaignClassifications/constants";
import {
  ADVERTISING_BASE_ROUTE,
  STATIC_TREE_NODE_KEYS,
} from "routes/AdvertisingDS/constants";
import { genCampaignTreeNodeKey } from "routes/AdvertisingDS/utils";
import { treeNodeSelectionsSelector } from "../../../../../selectors";
import { TextColor } from "@sixsense/core/style/variables";
import { Archive } from "@sixsense/core/icons";
import { pxToRem } from "@sixsense/core/style";
import { campaignListStateGenerator } from "../stateGenerators";
import { VIEW_CAMPAIGN_DETAILS_ROUTE } from "routes/Advertising/routes/Campaigns/routes/CreateCampaign/constants";

const CampaignNameComponent = ({ campaignName, campaign, selectedFolders }) => {
  const campaignSource = campaign.campaign_source;
  const campaignStatus = get(campaign, "campaign_status", null);
  const isDraftCampaign = campaignStatus === CAMPAIGN_STATUS_X.draft;

  const isArchivedCampaign =
    get(campaign, "folder.classification_type") ===
    CLASSIFICATION_TYPES.CAMPAIGNS_ARCHIVED;

  const needActionIndicators = getNeedActionCampaignIndicators(campaign)
    .map(({ message, type, tooltip }) => ({ message, type, tooltip }))
    .filter((m) => !isEmpty(m));
  const isViewingAllCampaigns =
    get(selectedFolders, "[0]") ===
    genCampaignTreeNodeKey(STATIC_TREE_NODE_KEYS.CAMPAIGN_ALL);

  const needActionIndicatorsDisplay =
    needActionIndicators.length !== 0 ? (
      <Tooltip
        trigger="hover"
        overlay={summaryTooltip(needActionIndicators)}
        placement="bottom"
      >
        <Flex>
          <NeedActionIndicator type={get(needActionIndicators[0], "type")} />
        </Flex>
      </Tooltip>
    ) : null;

  const archivedCampaignIcon = isViewingAllCampaigns && isArchivedCampaign && (
    <Tooltip
      trigger="hover"
      overlay="Archived"
      placement="right"
      className="ml4"
    >
      <Archive />
    </Tooltip>
  );

  function renderCampaignSource() {
    switch (campaignSource) {
      case CAMPAIGN_SOURCE.LINKEDIN:
      case CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING:
        return (
          <Fragment>
            <Flex alignItems="center">
              <Avatar
                src={LinkedInIcon}
                alt={CAMPAIGN_SOURCE_LABELS[campaignSource]}
                size="2xs"
              />
              &nbsp;
              <Text.Body color={`var(${TextColor.SECONDARY})`}>
                {CAMPAIGN_SOURCE_LABELS[campaignSource].replace(
                  "LinkedIn ",
                  ""
                )}
              </Text.Body>
            </Flex>
          </Fragment>
        );
      case CAMPAIGN_SOURCE.INTERNAL:
      case CAMPAIGN_SOURCE.RETARGETING:
      case CAMPAIGN_SOURCE.CONTEXTUAL:
        return (
          <Fragment>
            <Flex alignItems="center">
              <Avatar
                src={SixsenseIcon}
                alt={CAMPAIGN_SOURCE_LABELS[campaignSource]}
                size="2xs"
              />
              &nbsp;
              <Text.Body color={`var(${TextColor.SECONDARY})`}>
                {CAMPAIGN_SOURCE_LABELS[campaignSource].replace("6sense ", "")}
              </Text.Body>
            </Flex>
          </Fragment>
        );
      default:
        return (
          <Text.Body color={`var(${TextColor.SECONDARY})`}>
            {CAMPAIGN_SOURCE_LABELS[campaignSource]}
          </Text.Body>
        );
    }
  }

  return (
    <div className={styles.container}>
      <Flex>
        <Flex className={styles.campaignNameWrapper}>
          <a
            href={
              isDraftCampaign
                ? // eslint-disable-next-line max-len
                  `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGNS_CONFIGURATION_ROUTE}/edit/${campaign.id}`
                : `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${VIEW_CAMPAIGN_DETAILS_ROUTE}/${campaign.id}/`
            }
          >
            <Link
              className={classNames(styles.noDrag, styles.textOverflow)}
              dataPendo={"campaignListClickCampaignName"}
            >
              <Tooltip
                trigger={"hover"}
                overlay={campaignName}
                placement="bottom"
              >
                <Text.Body
                  weight="semibold"
                  color={`var(${TextColor.HEAD})`}
                  className={styles.campaignName}
                >
                  {campaignName}
                </Text.Body>
              </Tooltip>
            </Link>
          </a>
        </Flex>
        <Flex>{needActionIndicatorsDisplay}</Flex>
      </Flex>
      <Flex alignItems="center">
        {renderCampaignSource()}
        <Text.Body color={`var(${TextColor.SECONDARY})`}>
          <Dot size="sm" className={"mx1"} color="Gray" />
          ID: {campaign.id}
        </Text.Body>
        {archivedCampaignIcon}
      </Flex>
    </div>
  );
};
CampaignNameComponent.propTypes = {
  campaignName: PropTypes.string.isRequired,
  campaign: PropTypes.object.isRequired,
  selectedFolders: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  selectedFolders: treeNodeSelectionsSelector(state),
});

const CampaignName = connect(mapStateToProps)(CampaignNameComponent);

const CAMPAIGN_HEADER = "Campaign";

const Header = ({ totalDataCount }) =>
  totalDataCount > 1
    ? `${totalDataCount} ${CAMPAIGN_HEADER}s`
    : `${totalDataCount} ${CAMPAIGN_HEADER}`;

Header.propTypes = {
  totalDataCount: PropTypes.number,
};

const stateToProps = (state) => ({
  totalDataCount: get(
    campaignListStateGenerator.dataSelector(state),
    "count",
    0
  ),
});

const CampaignHeader = connect(stateToProps)(Header);

export const CAMPAIGN_NAME = {
  sortByKey: "campaign_name",
  fixed: "left",
  customizable: false,
  key: "campaign-name",
  csvColumnKey: "name",
  title: <CampaignHeader />,
  dataIndex: "campaign_name",
  width: pxToRem(325),
  render: (campaignName, campaign) => (
    <CampaignName campaignName={campaignName} campaign={campaign} />
  ),
};
