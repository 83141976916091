import React, { useState } from 'react';
import { Flex } from '@sixsense/core/components';
import { css } from 'styles/emotion';
import { Button, Modal, Text, Loading, TetheredSelect } from 'v2-components';
import { useOrg } from 'hooks';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { actions as globalActions } from 'modules/global';
import { useRequest, useSharedValue, useSharedState } from '@sixsense/core';
import { maybePlural } from 'utils/utils';
import { getConfigState, getUpdatedConfig, resetConfig } from '../../utils';
import { orgProductState, activePageState } from '../../state';
import { KW_EXP, OTHER } from '../../constants';

const styles = {
  dropdownStyle: css`
  width: 100%;
  margin-top: 10px;
  :global(.ant-select-selection) {
      border:none !important;
      box-shadow: none !important;
      :global(.ant-select-selection__choice){
        background-color: #E5F2FA;
      }
    }
`,
};

const { Option } = TetheredSelect;

type Props = {
  onCancel: () => void;
  action: string;
  keywords?: Number[];
};

const AddToProductModal = ({ onCancel, action, keywords }: Props) => {
  const MAIN = 'MAIN';
  const WARNING = 'WARNING';
  const activePage = useSharedValue(activePageState);
  const state = getConfigState(activePage, false);
  const [config, setConfig] = useSharedState(state);
  const [loading, setLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [contentState, setContentState] = useState(MAIN);
  const org = useOrg();
  const products = useSharedValue(orgProductState);

  const request = useRequest();
  const dispatch = useDispatch();

  // Product_id is 0 since it is unused in the bulk_update calls
  const requestUrl = `organization/${org.id}/product_keyword/${0}/keyword/`;
  const body = {
    action: 'add_to',
    keyword_ids: keywords,
    config: {
      location: 'existing_product',
      group_name: null,
      group_ids: null,
      target_product_ids: selectedProducts,
    },
  };
  let action_word = 'adding';
  let action_word_past = 'added';

  if (action === 'Move') {
    body.action = 'move_to';
    action_word = 'moving';
    action_word_past = 'moved';
  }

  const closeModal = () => {
    setSelectedProducts([]);
    setContentState(MAIN);
    onCancel();
  };

  const moveToProduct = async () => {
    setLoading(true);
    try {
      await request(requestUrl, {
        method: 'PATCH',
        body: JSON.stringify(body),
      });
      dispatch(globalActions.showNotification('success', `Successfully ${action_word_past} to
                                              ${maybePlural(selectedProducts.length, 'product')}`));
      closeModal();
      const updatedConfig = getUpdatedConfig(config);
      resetConfig(updatedConfig, setConfig);
    } catch (e) {
      const errorMessage = `There was an issue ${action_word} to
                            ${maybePlural(selectedProducts.length, 'product')}.
                            Please try again later.`;
      dispatch(globalActions.showNotification('error', errorMessage));
    }
    setLoading(false);
  };

  const productOptions = products ? products.map((p) => ({
    value: p.id,
    label: p.displayName === KW_EXP ? OTHER : p.displayName,
  })) : [];

  const buttonText = `${action}${contentState === MAIN ? ' here' : ''}`;

  const selectedProductText = selectedProducts.map((i) => {
    const productName = products.find((p) => p.id === i).displayName;
    return productName === KW_EXP ? `"${OTHER}"` : `"${productName}"`;
  }).join(',');

  const footer = (
    <Flex justifyContent="flex-end" style={{ padding: '10px 0' }}>
      <Button
        pointer
        onClick={() => {
          closeModal();
        }}
      >
        Cancel
      </Button>
      <Button
        pointer
        className="aam-l--10"
        type={'primary'}
        onClick={() => {
          if (contentState === MAIN) {
            setContentState(WARNING);
          } else {
            moveToProduct();
          }
        }}
        disabled={loading || !selectedProducts.length}
      >
        {buttonText}
      </Button>
      {loading && (
        <Loading
          size="small"
          spinnerWidth={15}
          spinnerHeight={15}
          pageLevel={false}
          className="aam-l--10"
        />
      )}
    </Flex>
  );

  const mainContent = (
    <React.Fragment>
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9' }} direction="column">
        <Text type={Text.TYPE.TITLE} bold>
          {action} {keywords.length} {maybePlural(keywords.length, 'keyword')} to
          another product category
        </Text>
        <Text type={Text.TYPE.SUBBODY}>
          {action === 'Add' ?
            'This will not remove the keywords from the current product category.' :
            'This will remove the keywords from the current product category.'}
        </Text>
      </Flex>
      <Flex style={{ padding: '20px' }} direction="column">
        <Text bold>Select product category</Text>
        <TetheredSelect
          id={'exportObjProductSelect'}
          className={styles.dropdownStyle}
          onChange={(val) => setSelectedProducts(val)}
          value={selectedProducts}
          mode={'multiple'}
          tokenSeparators={[',']}
          size={'large'}
          placeholder={'Select Product'}
          filterOption={(inputValue, option) => {
            const name = option.props?.optionMeta?.label || '';
            return name.toLowerCase().includes(inputValue.toLowerCase());
          }}
        >
          {productOptions.map((v) =>
            <Option
              optionMeta={v}
              key={v.value}
              value={v.value}
            >
              {v.label}
            </Option>
          )}
        </TetheredSelect>
      </Flex>
    </React.Fragment>
  );

  const warningContent = action === 'Add' ? (
    <Flex style={{ padding: '20px' }}>
      <Text>
        You're adding {keywords.length} {maybePlural(keywords.length, 'keyword')} to{' '}
        {selectedProductText} {maybePlural(selectedProducts.length, 'category', 'categories')}.
        This will not remove the keywords from the current product category.
      </Text>
    </Flex>
  ) : (
    <React.Fragment>
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9' }}>
        <Text type={Text.TYPE.TITLE} bold>
          Are you sure you want to move the {maybePlural(keywords.length, 'keyword')}?
        </Text>
      </Flex>
      <Flex style={{ padding: '20px' }}>
        <Text>
          Moving keywords to another product category removes the keywords
          from the current product category. Do you wish to continue?
        </Text>
      </Flex>
    </React.Fragment>
  );

  return (
    <Modal
      visible
      bodyStyle={{ padding: 0 }}
      footer={footer}
      maskClosable={false}
      closable={false}
      width={600}
    >
      {contentState === MAIN ? mainContent : warningContent}
    </Modal>
  );
};

export default AddToProductModal;
