import React, { useEffect } from 'react';
import { Select } from 'v2-components';
import { useRequestValue } from '@sixsense/core/request';
import { productsRequestState } from './state';
import { useFlags } from 'contexts/FlagContext';
import { ResultStatus } from '@sixsense/core/shared/async-result/types';

const ALL_PRODUCT_OPTION = {
  label: 'All Products',
  value: '__all__',
};

type Props = {
  value?: string;
  onChange: (product: string) => void;
  showAllOption?: boolean,
  disabled?: boolean,
}

const ProductSelect = ({ value, onChange, showAllOption = false, ...props }: Props) => {
  const { has_predictive: hasPredictive } = useFlags();
  const result = useRequestValue(productsRequestState, { hasPredictive });

  let placeholder;
  let disabled = props.disabled;
  let options = [];
  let defaultValue;
  switch (result.status) {
    case ResultStatus.LOADING:
      placeholder = 'Loading...';
      disabled = true;
      break;
    case ResultStatus.ERROR:
      placeholder = 'Error Loading Products';
      disabled = true;
      break;
    case ResultStatus.LOADED:
      options = result.data;
      defaultValue = options[0].value;
      if (showAllOption) {
        options = [ALL_PRODUCT_OPTION, ...options];
      }
      disabled = options.length < 2;
      break;
    default:
      break;
  }

  useEffect(() => {
    if (!value && defaultValue) {
      onChange(defaultValue);
    }
  }, [value, defaultValue]);

  if (!hasPredictive) {
    return null;
  }

  if (options.length === 1) {
    return <div style={{ fontSize: 16, fontWeight: 'bold', width: 200 }}>{options[0].label}</div>;
  }

  return (
    <Select
      placeholder={placeholder}
      disabled={disabled}
      value={value || defaultValue}
      onChange={onChange}
      options={options}
      {...props}
    />
  );
};

export default ProductSelect;
