import React from 'react';
import { Flex, Text, Button } from '@sixsense/core/components';
import { css } from 'styles/emotion';
import vectorImg from '../images/vector_illustration.svg';

const styles = {
  generatingFailureBody: css({
    padding: '54px',
    textAlign: 'center',
  }),
  tryBtn: css({
    padding: '0px 20px !important',
    borderRadius: '4px !important',
    height: '28px !important',
  }),
};

type SummaryGenerationErroProps = {
  generateSummary: any;
  setSummary: any;
  setSummaryAPIFailed: any;
};

export const SummaryGenerationError = ({
  generateSummary,
  setSummary,
  setSummaryAPIFailed,
}: SummaryGenerationErroProps) => {
  const handleTryAgain = () => {
    setSummary(null);
    setSummaryAPIFailed(false);
    generateSummary();
  };
  return (
    <Flex
      gap={20}
      direction={'column'}
      alignItems="center"
      className={styles.generatingFailureBody}
    >
      <img alt={'Error generating summary'} src={vectorImg} />
      <Text size={16} weight={600}>
        Error generating summary
      </Text>
      <Text weight={400} style={{ marginTop: '-12px' }}>
        We experienced an error in our system. Try again to
        <br /> regenerate the summary.
      </Text>
      <Button onClick={handleTryAgain} className={styles.tryBtn}>
        Try Again
      </Button>
    </Flex>
  );
};
