/* eslint-disable max-len */
import { CRM, CRM_EVENT, CRM_TASK, WEB, MAP } from '../../constants';
import {
  ELOQUA,
  HUBSPOT,
  MARKETO,
  PARDOT,
} from 'routes/Settings/routes/Integration/routes/map/constants';

export const SORT_ORDER_DESC = 'desc';
export const SORT_ORDER_ASC = 'asc';

export const PRESET_STRING = 'SETTINGS/TAXONOMY_V2/MAPPING_REVIEW';

// Mapping status
export const STATUS_MAPPED = 'Mapped';
export const STATUS_UNMAPPED = 'Unmapped';
export const STATUS_EXCLUDED = 'Excluded';

export const SKIP_IN_ADV_FILTER_COLUMNS = [
  'created_dt',
  'updated_dt',
  'updated_by',
  'event',
  'products',
  'activity_count',
  'mode_of_mapping',
  'action',
  'channel',
  'mapped_type',
  'activity',
  'rule',
];

export const QUICK_FILTERS = [
  'status',
  'mode_of_mapping',
  'created_dt',
  'updated_dt',
  'rule_id',
  'updated_by',
  'activity_mapping',
  'channel',
  'products',
];

export const DEFAULT_FILTER_OP_VAL = 'contains';
export const DEFAULT_FILTER_OP_ADV = {
  value: DEFAULT_FILTER_OP_VAL,
  label: DEFAULT_FILTER_OP_VAL,
};

export const FILTER_MATCH_OPTIONS = [
  {
    value: 'exact_match',
    label: 'is equal to',
  },
  DEFAULT_FILTER_OP_ADV,
  { value: 'do_not_match', label: 'does not contain' },
];

export const STATUS_ITEMS = [
  [STATUS_MAPPED, STATUS_MAPPED],
  [STATUS_UNMAPPED, STATUS_UNMAPPED],
  [STATUS_EXCLUDED, STATUS_EXCLUDED],
];

export const MODE_ITEMS = [
  ['Auto', 'model'],
  ['Manual', 'manual'],
  ['Rule Based', 'rule'],
];

export const DEDUPE_FIELDS = {
  [WEB]: ['url'],
  [CRM]: ['campaign_type', 'member_status', 'campaign_name'],
  [CRM_EVENT]: ['subject', 'type'],
  [CRM_TASK]: ['subject', 'type'],
};

export const PRODUCT_DEDUPE_FIELDS = {
  [WEB]: ['url'],
  [MAP]: ['campaign', 'asset', 'email_subject'],
  [CRM]: ['campaign_name', 'campaign_description'],
};

export const DEDUPE_FIELDS_MAP = {
  [MARKETO]: ['activity_type', 'programs', 'campaign_name'],
  [HUBSPOT]: ['activity_type', 'type', 'campaign_name'],
  [PARDOT]: ['activity_type', 'type_name', 'campaign_name', 'type'],
  [ELOQUA]: ['activity_type', 'asset', 'campaign_name'],
};
