import { actionCreators } from "./actions";
import { stateGenerators } from "./stateGenerators";
import { selectors } from "./selectors";
import { reducers } from "./reducers";
import * as constants from "./constants";

export const campaignClassifications = {
  actionCreators,
  stateGenerators,
  selectors,
  constants,
  reducers,
};
