/* "yeah, we'll remove antd someday" */
import React from 'react';
import PropTypes from 'prop-types';
import { LocaleProvider, DatePicker } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { FormComponent } from 'HOCS';
import styles from './RangePicker.module.scss';
import moment from 'moment';
import { classNames } from 'utils/utils';

function RangePicker({ hideInputOnPopup, dropdownClassName, ...props }) {
  return (
    <div className={`${styles.rangePicker} ${props.className}`}>
      <LocaleProvider locale={enUS}>
        <DatePicker.RangePicker
          {...props}
          dropdownClassName={classNames(
            hideInputOnPopup && styles.hideInputOnPopup,
            dropdownClassName,
          )}
          className={[props.className, styles.container].join(' ')}
        />
      </LocaleProvider>
    </div>
  );
}

const mapInputToProps = (input) => {
  let props = {
    onChange: (date, dateString) => {
      input.onChange(dateString);
      input.onBlur(dateString);
    },
  };
  if (input.value) {
    props = {
      ...props,
      value: input.value.map((dt) => (dt === '' ? '' : moment(dt))),
    };
  }
  return props;
};

RangePicker.propTypes = {
  className: PropTypes.string,
  hideInputOnPopup: PropTypes.bool,
  dropdownClassName: PropTypes.string,
};

RangePicker.form = FormComponent(RangePicker, mapInputToProps);

export default RangePicker;
