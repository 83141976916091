import { createSelector } from "reselect";
import { ROUTE_STATE_KEY } from "./constants";
import { filtersStateToQueryParamsObject } from "./utils";
import {
  defaultValue as timeframeDefaultValue,
  persistedTimeframeFilterStateSelector,
} from "modules/persisted/campaignListTimeframe";
import { get, some, isEmpty } from "lodash";
import { campaignClassificationDucks } from "routes/AdvertisingDS/ducks";
import { userSelector } from "modules/user/selectors";
import { getFilterOptionsForClassification } from "./components/FilterByPanel/utils";
import { selectedTreeNodeSelector } from "routes/AdvertisingDS/selectors";
import { treeNodeKeysUtils } from "routes/AdvertisingDS/utils";
import {
  linkedinIntegratedPagesSelector,
  purchaseOrdersEndpointSelector,
} from "../../selectors";
import { productsSelector } from "../../ducks/selectors";
import { stateGenerator } from "../../stateGenerator";
import { actions as globalActions } from "modules/global";

export const baseSelector = (state) => state[ROUTE_STATE_KEY];
const { showNotification } = globalActions;

export const loadPurchaseOrdersStateGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  initialData: [],
  actionPrefix: `CAMPAIGNS/LIST/PURCHASE_ORDERS`,
  endpointSelector: purchaseOrdersEndpointSelector,
  receivedDataTransform: ({ results = [] }) =>
    results.map(({ name, id }) => ({ label: name, value: `${id}` })),
  stateKey: "purchaseOrders",
  onFailureActions: [
    (e) =>
      showNotification(
        "error",
        "Failed to fetch purchase orders lists",
        e.errorMessage
      ),
  ],
});

export const globalStateSelector = createSelector(baseSelector, (state) =>
  get(state, "globalState")
);

export const filtersSelector = createSelector(
  globalStateSelector,
  selectedTreeNodeSelector,
  ({ filters }, selectedTreeNodeKey) => get(filters, selectedTreeNodeKey) || {}
);

export const hasFiltersAppliedSelector = createSelector(
  filtersSelector,
  (filterCategories) =>
    !isEmpty(filterCategories) &&
    some(filterCategories, (category) => some(category, (filter) => filter))
);

export const filterBySelector = createSelector(
  filtersSelector,
  userSelector,
  campaignClassificationDucks.selectors.archivedFolderSelector,
  campaignClassificationDucks.stateGenerators.campaignClassifications
    .dataSelector,
  selectedTreeNodeSelector,
  linkedinIntegratedPagesSelector,
  productsSelector,
  loadPurchaseOrdersStateGenerator.dataSelector,
  (
    filters,
    userId,
    archiveFolder,
    allFolders,
    selectedTreeNode,
    linkedinPages,
    products,
    purchaseOrders
  ) => {
    const selectedFolderObject = treeNodeKeysUtils.getFolderObject(
      selectedTreeNode,
      allFolders
    );
    const selectedClassificationType = get(
      selectedFolderObject,
      "classification_type"
    );
    const filterOptions = getFilterOptionsForClassification(
      selectedClassificationType,
      userId,
      get(archiveFolder, "id"),
      linkedinPages,
      products,
      purchaseOrders
    );
    // TODO: need to handle browser refresh. Currently, some options may get lost
    // because we have different filters for different folders
    return filtersStateToQueryParamsObject(filters, filterOptions);
  }
);

export const dateRangeFilterSelector = createSelector(
  selectedTreeNodeSelector,
  persistedTimeframeFilterStateSelector,
  (selectedTreeNodeKey, dateRangeFilter) => {
    const folderId = treeNodeKeysUtils.extractFolderId(selectedTreeNodeKey);

    return dateRangeFilter[folderId] || timeframeDefaultValue;
  }
);
