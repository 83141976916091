import {
  Permission,
  ConversationalEmailPermission,
  SalesPermission,
  WorkflowsPermission,
  AllPermission,
  App,
} from '@sixsense/rbac';
import { Package, Addon } from '@sixsense/core/types';
import { FEATURE_FLAGS } from 'constants/featureFlags';
import { hasRequiredFlags } from '@sixsense/core/featureFlags/utils';
import { FeatureFlagsObject } from '@sixsense/core/featureFlags/types';

type PackagePlanItem = {
  app_name: string;
  display_name: string;
  is_deleted: boolean;
  name: string;
  plan: { is_deleted: boolean, name: string };
};
type PackagePlan = { [key: string]: PackagePlanItem };

type PermissionConfig = {
  title: string;
  description: string;
  view: AllPermission;
  edit: AllPermission | null;
  accessCheck?: (
    arg: {
      packages: Set<Package>,
      addons: Set<Addon>,
      packagePlan?: PackagePlan,
    },
  ) => boolean;
  featureCheck?:(arg: { flags: FeatureFlagsObject }) => boolean;
}

type PermissionGroupConfig = {
  title: string;
  permissions: PermissionConfig[];
  accessCheck?: (
    arg: {
      packages: Set<Package>,
      addons: Set<Addon>,
      packagePlan?: PackagePlan,
    }) => boolean;
  featureCheck?:(arg: { flags: FeatureFlagsObject }) => boolean;
}

export const ROLE_PERMISSION_CONFIG: PermissionGroupConfig[] = [
  {
    title: 'Insights',
    permissions: [
      {
        title: 'Dashboard',
        description: 'View Dashboard',
        view: Permission.DASHBOARD_VIEW,
        edit: null,
      },
      {
        title: 'Account Detail',
        description: 'View Account Detail Page',
        view: Permission.ACCOUNT_DETAILS_VIEW,
        edit: null,
      },
      {
        title: 'Segment',
        description: 'View or edit segments. Edit permission also allows user to create folders,' +
        ' tags, move segments into different folders, and put tags on segments.',
        view: Permission.SEGMENT_VIEW,
        edit: Permission.SEGMENT_EDIT,
      },
      {
        title: 'Reporting',
        description: 'View reporting about segment performance',
        view: Permission.REPORTING_VIEW,
        edit: null,
      },
    ],
  },
  {
    title: 'Campaign',
    permissions: [
      {
        title: 'Campaign',
        description: 'View or edit campaigns. Edit permission also allows configuring LinkedIn ' +
          'integration and Facebook integration.',
        view: Permission.CAMPAIGN_VIEW,
        edit: Permission.CAMPAIGN_EDIT,
      },
      {
        title: 'Ads',
        description: 'View or edit Ad library',
        view: Permission.ADS_VIEW,
        edit: Permission.ADS_EDIT,
      },
    ],
  },
  {
    title: 'Orchestration',
    permissions: [
      {
        title: 'Orchestration',
        description: 'View or setup orchestration',
        view: Permission.ORCHESTRATION_VIEW,
        edit: Permission.ORCHESTRATION_EDIT,
      },
    ],
  },
  {
    title: 'Alerts',
    permissions: [
      {
        title: 'Alerts',
        description: 'Edit alerts',
        view: Permission.ALERT_EDIT,
        edit: Permission.ALERT_EDIT,
      },
    ],
  },
  {
    title: 'Settings: Data',
    permissions: [
      {
        title: 'Ad Inventory Exclusions',
        description: 'View or edit ad inventory exclusion',
        view: Permission.SETTINGS_EXCLUSIONS_INVENTORY_VIEW,
        edit: Permission.SETTINGS_EXCLUSIONS_INVENTORY_EDIT,
      },
      {
        title: 'Website URL Exclusions',
        description: 'View or edit website url exclusion',
        view: Permission.SETTINGS_EXCLUSIONS_WEBSITE_VIEW,
        edit: Permission.SETTINGS_EXCLUSIONS_WEBSITE_EDIT,
      },
      {
        title: 'Company Exclusions',
        description: 'View or edit company exclusion',
        view: Permission.SETTINGS_EXCLUSIONS_COMPANY_VIEW,
        edit: Permission.SETTINGS_EXCLUSIONS_COMPANY_EDIT,
      },
      {
        title: 'Keywords',
        description: 'View or edit keywords',
        view: Permission.SETTINGS_KEYWORDS_VIEW,
        edit: Permission.SETTINGS_KEYWORDS_EDIT,
      },
      {
        title: 'Bombora',
        description: 'View or edit Bombora topics',
        view: Permission.SETTINGS_BOMBORA_VIEW,
        edit: Permission.SETTINGS_BOMBORA_EDIT,
        accessCheck: ({ addons }) => addons.has(Addon.BOMBORA),
      },
      {
        title: 'Web Tag',
        description: 'View web tag and edit domain inclusion',
        view: Permission.SETTINGS_WEB_TAG_VIEW,
        edit: Permission.SETTINGS_WEB_TAG_EDIT,
      },
      {
        title: '6QA Customization',
        description: 'View or edit 6QA configurations',
        view: Permission.SETTINGS_CUSTOM6QA_VIEW,
        edit: Permission.SETTINGS_CUSTOM6QA_EDIT,
      },
      {
        title: 'Opportunity Stage Configuration',
        description: 'View or edit opportunity stage configuration.',
        view: Permission.SETTINGS_OPP_STAGE_CONFIG_VIEW,
        edit: Permission.SETTINGS_OPP_STAGE_CONFIG_EDIT,
      },
      {
        title: 'Data Taxonomy',
        description: 'View and Edit Data Taxonomy.',
        view: Permission.SETTINGS_TAXONOMY_VIEW,
        edit: Permission.SETTINGS_TAXONOMY_EDIT,
      },
    ],
  },
  {
    title: 'Settings: Integration',
    permissions: [
      {
        title: 'API Key',
        description: 'View API key',
        view: Permission.SETTINGS_API_KEY_VIEW,
        edit: Permission.SETTINGS_API_KEY_VIEW,
      },
      {
        title: 'Integrations',
        description: 'View and edit configurations for CRM, MAP, SEP, Slack integration. ' +
          'Facebook integration is managed by campaign permission. ',
        view: Permission.SETTINGS_INTEGRATION_CRM_VIEW,
        edit: Permission.SETTINGS_INTEGRATION_CRM_EDIT,
      },
      {
        title: '6sense Segments for Facebook Integration Opt In/Out',
        description: 'Opt In or Opt Out from "6sense Segments for Facebook" under Integrations',
        view: Permission.CAMPAIGN_VIEW,
        edit: Permission.FACEBOOK_INTEGRATION_CONSENT_EDIT,
      },
      {
        title: '6sense Segments for Google Integration Opt In/Out',
        description: 'Opt In or Opt Out from "6sense Segments for Google Ads" under Integrations',
        view: Permission.CAMPAIGN_VIEW,
        edit: Permission.GOOGLE_ADS_INTEGRATION_CONSENT_EDIT,
        featureCheck: ({ flags }) =>
            hasRequiredFlags(FEATURE_FLAGS.hasGoogleAds, flags),
      },
      {
        title: 'LinkedIn Advertising Integration Opt In/Out',
        description: 'Opt In or Opt Out from "LinkedIn Advertising" under Integrations',
        view: Permission.CAMPAIGN_VIEW,
        edit: Permission.LINKEDIN_ADS_INTEGRATION_CONSENT_EDIT,
      },
      {
        title: 'Mapping Profiles',
        description: 'View or edit Mappings Profile',
        view: Permission.SETTINGS_MAPPINGS_PROFILE_EDIT,
        edit: Permission.SETTINGS_MAPPINGS_PROFILE_EDIT,
      },
      {
        title: 'Unified Mapping Profiles',
        description: 'View or edit Unified Mappings Profiles',
        view: WorkflowsPermission.MAPPING_PROFILE_VIEW,
        edit: WorkflowsPermission.MAPPING_PROFILE_EDIT,
      },
    ],
  },
  {
    title: 'Settings: Apps',
    permissions: [
      {
        title: 'Contact & Lead Orchestration',
        description: 'Edit contact purchase limit and rule for account matching',
        view: Permission.SETTINGS_CONTACT_PURCHASE_LIMIT_EDIT,
        edit: Permission.SETTINGS_CONTACT_PURCHASE_LIMIT_EDIT,
        accessCheck: ({ packages }) => (
          packages.has(Package.ADVANCED) || packages.has(Package.ESSENTIALS)
        ),
      },
      {
        title: 'Notifications',
        description: 'View or edit notifications',
        view: Permission.SETTINGS_NOTIFICATIONS_VIEW,
        edit: Permission.SETTINGS_NOTIFICATIONS_EDIT,
      },
      {
        title: 'Published Segments',
        description: 'View or edit published segments to account detail page, sales intelligence ' +
          'and external systems via orchestration',
        view: Permission.SETTINGS_PUBLISH_SEGMENT_VIEW,
        edit: Permission.SETTINGS_PUBLISH_SEGMENT_EDIT,
      },
      {
        title: 'Sales Intelligence',
        description: 'View and edit Sales Intelligence customizations',
        view: Permission.SETTINGS_SALES_INTELLIGENCE_VIEW,
        edit: Permission.SETTINGS_SALES_INTELLIGENCE_EDIT,
        accessCheck: ({ packages }) => packages.has(Package.ADVANCED),
      },
      {
        title: 'Talking Points',
        description: 'Edit Talking Points',
        view: Permission.SETTINGS_SALES_INTELLIGENCE_TPR_EDIT,
        edit: Permission.SETTINGS_SALES_INTELLIGENCE_TPR_EDIT,
        accessCheck: ({ packages }) => packages.has(Package.ADVANCED),
      },
      {
        title: 'Custom Match Rules - Account Creation',
        description: 'View and edit custom match rules for account creation',
        view: WorkflowsPermission.MATCH_RULES_ACCOUNT_CREATION_VIEW,
        edit: WorkflowsPermission.MATCH_RULES_ACCOUNT_CREATION_EDIT,
      },
      {
        title: 'Custom Match Rules - Contact Purchasing Tie-breakers',
        description: 'View and edit custom match rules for contact purchase tie-breaking',
        view: WorkflowsPermission.MATCH_RULES_CONTACT_PURCHASE_TIE_VIEW,
        edit: WorkflowsPermission.MATCH_RULES_CONTACT_PURCHASE_TIE_EDIT,
      },
    ],
  },
  {
    title: 'Settings: Actions',
    permissions: [
      {
        title: 'Actions in Platform and Apps',
        description: 'Show or hide actions in the platform and apps',
        view: Permission.SETTINGS_ACTIONS_VIEW,
        edit: Permission.SETTINGS_ACTIONS_EDIT,
        accessCheck: ({ packages }) => packages.has(Package.ADVANCED),
      },
    ],
  },
  {
    title: 'User Management',
    permissions: [
      {
        title: 'User Management',
        description: 'View or edit users. Only Primary Administrator is able to assign ' +
          'administrator role to users.',
        view: Permission.SETTINGS_USER_MANAGEMENT_VIEW,
        edit: Permission.SETTINGS_USER_MANAGEMENT_EDIT_USER,
      },
    ],
  },
  {
    title: 'Administrator Report',
    permissions: [
      {
        title: 'Administrator Report',
        description: 'View Administrator reports',
        view: Permission.SETTINGS_ADMIN_REPORTS_VIEW,
        edit: null,
      },
    ],
  },
  {
    featureCheck: ({ flags }) =>
        hasRequiredFlags(FEATURE_FLAGS.hasAudienceWorkflowsGA, flags),
    title: 'Audience Workflows',
    permissions: [
      {
        title: 'Audience Workflows',
        description: 'View Dashboard',
        view: WorkflowsPermission.AUDIENCE_WORKFLOWS_VIEW,
        edit: WorkflowsPermission.AUDIENCE_WORKFLOWS_EDIT,
      },
      {
        title: 'AWF Credit Reporting',
        description: 'View Audience Workflows credit reports',
        view: WorkflowsPermission.CREDIT_REPORTS_VIEW,
        edit: null,
      },
      {
        title: 'Run History',
        description: 'View Workflows run history',
        view: WorkflowsPermission.AUDIENCE_RUN_HISTORY_VIEW,
        edit: WorkflowsPermission.AUDIENCE_RUN_HISTORY_EDIT,
      },
    ],
  },
  {
    title: 'Settings: Credits',
    permissions: [
      {
        title: 'Track credit usage',
        description: 'Monitor the distribution, usage, balance, and expiration of credits.',
        view: Permission.SETTINGS_CREDITS_VIEW,
        edit: null,
      },
      {
        title: 'Distribute credits among apps',
        description: 'Distribute available credits from your account pool ' +
        'to individual 6sense apps',
        view: null,
        edit: Permission.SETTINGS_CREDITS_DISTRIBUTE,
      },
    ],
  },
];

export const ROLE_PERMISSION_CONFIG_CE: PermissionGroupConfig[] = [
  {
    title: 'AI Writer',
    permissions: [
      {
        title: 'AI Writer',
        description: 'Use the AI Writer to generate email content',
        view: ConversationalEmailPermission.AI_WRITER_VIEW,
        edit: ConversationalEmailPermission.AI_WRITER_EDIT,
      },
    ],
  },
  {
    title: 'Campaigns',
    permissions: [
      {
        title: 'Campaigns',
        description: 'Create, manage and view Conversational Email Campaigns',
        view: ConversationalEmailPermission.CAMPAIGNS_VIEW,
        edit: ConversationalEmailPermission.CAMPAIGNS_EDIT,
      },
    ],
  },
  {
    title: 'Contacts',
    permissions: [
      {
        title: 'Contacts',
        description: 'Create, manage and view Conversational Email Contacts',
        view: ConversationalEmailPermission.CONTACTS_VIEW,
        edit: ConversationalEmailPermission.CONTACTS_EDIT,
      },
    ],
  },
  {
    title: 'Workflows',
    permissions: [
      {
        title: 'Workflows',
        description: 'Create, manage and view Conversational Email Workflows',
        view: ConversationalEmailPermission.WORKFLOWS_VIEW,
        edit: ConversationalEmailPermission.WORKFLOWS_EDIT,
      },
    ],
  },
  {
    title: 'Integrations',
    permissions: [
      {
        title: 'Integrations',
        description: 'Create, manage and view Conversational Email Integrations',
        view: ConversationalEmailPermission.INTEGRATIONS_VIEW,
        edit: ConversationalEmailPermission.INTEGRATIONS_EDIT,
      },
    ],
  },
  {
    title: 'Conversations',
    permissions: [
      {
        title: 'Conversations',
        description: 'Create, manage and view Conversational Email Conversations',
        view: ConversationalEmailPermission.CONVERSATIONS_VIEW,
        edit: ConversationalEmailPermission.CONVERSATIONS_EDIT,
      },
    ],
  },
  {
    title: 'Analytics',
    permissions: [
      {
        title: 'Analytics',
        description: 'View performance insights of your Conversational Email Campaigns',
        view: ConversationalEmailPermission.ANALYTICS_VIEW,
        edit: ConversationalEmailPermission.ANALYTICS_EDIT,
      },
    ],
  },
  {
    title: 'Settings',
    permissions: [
      {
        title: 'AI Inbox',
        description: 'Connect, edit or disconnect an AI Inbox',
        view: ConversationalEmailPermission.SETTINGS_AI_INBOX_VIEW,
        edit: ConversationalEmailPermission.SETTINGS_AI_INBOX_EDIT,
      },
      {
        title: 'Account',
        description: 'View or update account settings',
        view: ConversationalEmailPermission.SETTINGS_ACCOUNT_VIEW,
        edit: ConversationalEmailPermission.SETTINGS_ACCOUNT_EDIT,
      },
      {
        title: 'Sales Reps',
        description: 'Create, manage or remove Sales Reps',
        view: ConversationalEmailPermission.SETTINGS_SALES_REPS_VIEW,
        edit: ConversationalEmailPermission.SETTINGS_SALES_REPS_EDIT,
      },
      {
        title: 'Human Review',
        description: 'View or change human review settings',
        view: ConversationalEmailPermission.SETTINGS_HUMAN_REVIEW_VIEW,
        edit: ConversationalEmailPermission.SETTINGS_HUMAN_REVIEW_EDIT,
      },
      {
        title: 'Notification',
        description: 'View or change notification settings',
        view: ConversationalEmailPermission.SETTINGS_NOTIFICATION_VIEW,
        edit: ConversationalEmailPermission.SETTINGS_NOTIFICATION_EDIT,
      },
      {
        title: 'Limits',
        description: 'View or change email limit settings',
        view: ConversationalEmailPermission.SETTINGS_LIMITS_VIEW,
        edit: ConversationalEmailPermission.SETTINGS_LIMITS_EDIT,
      },
      {
        title: 'Team',
        description: 'View or change team settings',
        view: ConversationalEmailPermission.SETTINGS_TEAM_VIEW,
        edit: ConversationalEmailPermission.SETTINGS_TEAM_EDIT,
      },
    ],
  },
];

export const ROLE_PERMISSION_CONFIG_SI: PermissionGroupConfig[] = [
  {
    title: 'Insights',
    permissions: [
      {
        title: 'SI Dashboards',
        description: '',
        view: SalesPermission.DASHBOARD_VIEW,
        edit: null,
      },
      {
        title: 'Discovery',
        description: '',
        view: SalesPermission.DISCOVERY_VIEW,
        edit: null,
      },
      {
        title: 'List Management',
        description: '',
        view: SalesPermission.LIST_VIEW,
        edit: null,
      },
      {
        title: 'Account Detail',
        description: '',
        view: SalesPermission.ACCOUNT_DETAILS_VIEW,
        edit: null,
      },
      {
        title: 'People Detail',
        description: '',
        view: SalesPermission.PEOPLE_DETAILS_VIEW,
        edit: null,
      },
      {
        title: 'Chrome Extension',
        description: '',
        view: SalesPermission.CHROME_EXT_VIEW,
        edit: null,
      },
      {
        title: 'Reporting',
        description: 'View reporting about segment performance',
        view: Permission.SETTINGS_ADMIN_REPORTS_VIEW,
        edit: null,
      },
    ],
  },
  {
    title: 'SETTINGS: DATA',
    permissions: [
      {
        title: 'Company Exclusions',
        description: 'View or edit company exclusion',
        view: Permission.SETTINGS_EXCLUSIONS_COMPANY_VIEW,
        edit: Permission.SETTINGS_EXCLUSIONS_COMPANY_EDIT,
      },
      {
        title: 'Keywords',
        description: 'View or edit keywords',
        view: Permission.SETTINGS_KEYWORDS_VIEW,
        edit: Permission.SETTINGS_KEYWORDS_EDIT,
      },
      {
        title: 'Bombora',
        description: 'View or edit Bombora topics',
        view: Permission.SETTINGS_BOMBORA_VIEW,
        edit: Permission.SETTINGS_BOMBORA_EDIT,
        accessCheck: ({ addons }) => addons.has(Addon.BOMBORA),
      },
      {
        title: 'Web Tag',
        description: 'View web tag and edit domain inclusion',
        view: Permission.SETTINGS_WEB_TAG_VIEW,
        edit: Permission.SETTINGS_WEB_TAG_EDIT,
      },
      {
        title: '6QA Customization',
        description: 'View or edit 6QA configurations',
        view: Permission.SETTINGS_CUSTOM6QA_VIEW,
        edit: Permission.SETTINGS_CUSTOM6QA_EDIT,
      },
      {
        title: 'Data Taxonomy',
        description: 'View and Edit Data Taxonomy.',
        view: Permission.SETTINGS_TAXONOMY_VIEW,
        edit: Permission.SETTINGS_TAXONOMY_EDIT,
      },
      {
        title: 'Relevant Psychographics​',
        description: 'Select topics, themes, initiatives, and principles relevant ' +
         'to your sales team and your go-to-market strategy',
        view: SalesPermission.SETTINGS_PSYCHOGRAPHICS_VIEW,
        edit: SalesPermission.SETTINGS_PSYCHOGRAPHICS_EDIT,
      },
      {
        title: 'Relevant Technographics​',
        description: 'Use exclusions relating to technologies ' +
          'in use to focus analytics and campaigns on relevant data',
        view: SalesPermission.SETTINGS_TECHNOGRAPHICS_VIEW,
        edit: SalesPermission.SETTINGS_TECHNOGRAPHICS_EDIT,
      },
      {
        title: 'Relevant Job Titles',
        description: 'Select job titles relevant to your sales team and your go-to-market strategy',
        view: SalesPermission.SETTINGS_JOBTITLES_VIEW,
        edit: SalesPermission.SETTINGS_JOBTITLES_EDIT,
      },
    ],
  },
  {
    title: 'SETTINGS: INTEGRATIONS',
    permissions: [
      {
        title: 'Integrations',
        description: 'View and edit configurations for CRM, MAP, SEP, Slack integration. ' +
          'Facebook integration is managed by campaign permission. ',
        view: Permission.SETTINGS_INTEGRATION_CRM_VIEW,
        edit: Permission.SETTINGS_INTEGRATION_CRM_EDIT,
      },
    ],
  },
  {
    title: 'SETTINGS: Sales Intelligence',
    permissions: [
      {
        title: 'SI Integration Settings',
        description: 'Control synchronization, manage update dependencies, and configure ' +
          'export settings in integrations between Sales Intelligence and CRM platforms and SEPs',
        view: SalesPermission.SETTINGS_INTEGRATION_VIEW,
        edit: SalesPermission.SETTINGS_INTEGRATION_EDIT,
      },
      {
        title: 'Talking Points',
        description: 'Add and manage curated or automated custom talking points',
        view: SalesPermission.SETTINGS_TALKING_POINT_VIEW,
        edit: SalesPermission.SETTINGS_TALKING_POINT_EDIT,
      },
      {
        title: 'Action In SI Platform',
        description: 'Show or hide actions in the Sales Intelligence platform and SI apps',
        view: SalesPermission.SETTINGS_SI_ACTIONS_VIEW,
        edit: SalesPermission.SETTINGS_SI_ACTIONS_EDIT,
      },
      {
        title: 'Components & Metrics',
        description: 'Configure the Sales Intelligence experience (dashboards, detail pages) to ' +
        'display insights most relevant to your organization',
        view: SalesPermission.SETTINGS_COMPONENTS_METRICS_VIEW,
        edit: SalesPermission.SETTINGS_COMPONENTS_METRICS_EDIT,
      },
      {
        title: 'Activities',
        description: 'Customize how and what kinds of sales- and marketing-related ' +
        'activities are shown in the Sales Intelligence experience',
        view: SalesPermission.SETTINGS_ACTIVITIES_VIEW,
        edit: SalesPermission.SETTINGS_ACTIVITIES_EDIT,
      },
      {
        title: 'Persona',
        description: 'Customize what job levels and job functions are ' +
        'displayed in the persona map based to your organization’s needs',
        view: SalesPermission.SETTINGS_PERSONA_VIEW,
        edit: SalesPermission.SETTINGS_PERSONA_EDIT,
      },
      {
        title: 'SI Alert',
        description: 'Manage Sales Intelligence alerts',
        view: SalesPermission.SETTINGS_ALERT_VIEW,
        edit: SalesPermission.SETTINGS_ALERT_EDIT,
      },
      {
        title: 'SI App Credit',
        description: 'Track SI credits and allocate credits among SI users',
        view: SalesPermission.SETTINGS_CREDIT_VIEW,
        edit: SalesPermission.SETTINGS_CREDIT_EDIT,
      },
      {
        title: 'Mapping Profiles',
        description: 'View or edit Mappings Profile',
        view: SalesPermission.SETTINGS_SI_MAPPING_PROFILES_EDIT,
        edit: SalesPermission.SETTINGS_SI_MAPPING_PROFILES_EDIT,
      },
    ],
  },
  {
    title: 'SEGMENTS: APPS',
    permissions: [
      {
        title: 'Notifications',
        description: 'View or edit notifications',
        view: Permission.SETTINGS_NOTIFICATIONS_VIEW,
        edit: Permission.SETTINGS_NOTIFICATIONS_EDIT,
      },
    ],
  },

  {
    title: 'Settings: Actions',
    permissions: [
      {
        title: 'Actions in Platform and Apps',
        description: 'Show or hide actions in the platform and apps',
        view: Permission.SETTINGS_ACTIONS_VIEW,
        edit: Permission.SETTINGS_ACTIONS_EDIT,
        accessCheck: ({ packages }) => packages.has(Package.ADVANCED),
      },
    ],
  },
  {
    title: 'User Management',
    permissions: [
      {
        title: 'User Management',
        description: 'View or edit users. Only Primary Administrator is able to assign ' +
          'administrator role to users.',
        view: Permission.SETTINGS_USER_MANAGEMENT_VIEW,
        edit: Permission.SETTINGS_USER_MANAGEMENT_EDIT_USER,
      },
    ],
  },
  {
    title: 'ADMIN REPORTS',
    permissions: [
      {
        title: 'Admin Reports',
        description: 'View Administrator reports',
        view: Permission.SETTINGS_ADMIN_REPORTS_VIEW,
        edit: null,
      },
    ],
  },
  {
    title: 'Settings: Credits',
    permissions: [
      {
        title: 'Track credit usage',
        description: 'Monitor the distribution, usage, balance, and expiration of credits.',
        view: Permission.SETTINGS_CREDITS_VIEW,
        edit: null,
      },
      {
        title: 'Distribute credits among apps',
        description: 'Distribute available credits from your account pool ' +
        'to individual 6sense apps',
        view: null,
        edit: Permission.SETTINGS_CREDITS_DISTRIBUTE,
      },
      {
        title: 'Allocate credits to SI Users',
        description: 'Allocate credits from the total SI credit balance to individual users',
        view: null,
        edit: Permission.SETTINGS_CREDITS_ALLOCATE_TO_SI_USERS,
      },
    ],
  },
];

export const ROLE_PERMISSION_CONFIG_WORKFLOWS: PermissionGroupConfig[] = [
  {
    title: 'Dashboard',
    permissions: [
      {
        title: 'Data Dashboard',
        description: 'View Data Workflows Dashboard',
        view: WorkflowsPermission.DATA_DASHBOARD_VIEW,
        edit: WorkflowsPermission.DATA_DASHBOARD_EDIT,
      },
      {
        title: 'Audience Dashboard',
        description: 'View Audience Workflows Dashboard',
        view: WorkflowsPermission.AUDIENCE_DASHBOARD_VIEW,
        edit: WorkflowsPermission.AUDIENCE_DASHBOARD_EDIT,
      },
    ],
  },
  {
    title: 'Alerts',
    permissions: [
      {
        title: 'Alerts & Notifications',
        description: 'View and Edit Alerts',
        view: WorkflowsPermission.ALERTS_AND_NOTIFICATIONS_VIEW,
        edit: WorkflowsPermission.ALERTS_AND_NOTIFICATIONS_EDIT,
      },
    ],
  },

  {
    title: 'Settings',
    permissions: [
      {
        title: 'Mapping Profile',
        description: 'View or Edit mapping profiles',
        view: WorkflowsPermission.MAPPING_PROFILE_VIEW,
        edit: WorkflowsPermission.MAPPING_PROFILE_EDIT,
      },

      {
        title: 'Merge Settings',
        description: 'View or Edit Merge Settings',
        view: WorkflowsPermission.MERGE_SETTINGS_VIEW,
        edit: WorkflowsPermission.MERGE_SETTINGS_EDIT,
      },
      {
        title: 'Tie Breaker Settings',
        description: 'View or Edit Tie break settings',
        view: WorkflowsPermission.TIE_BREAK_SETTINGS_VIEW,
        edit: WorkflowsPermission.TIE_BREAK_SETTINGS_EDIT,
      },
      {
        title: 'Round Robin Pool',
        description: 'View or Edit Round Robin Pools',
        view: WorkflowsPermission.ROUND_ROBIN_VIEW,
        edit: WorkflowsPermission.ROUND_ROBIN_EDIT,
      },
      {
        title: 'Territory Management',
        description: 'View or Edit Territory Management',
        view: WorkflowsPermission.TERRITORY_MGMT_VIEW,
        edit: WorkflowsPermission.TERRITORY_MGMT_EDIT,
      },
      {
        title: 'User Management',
        description: `View or edit users. Only Primary Administrator is able to
        assign administrator role to users`,
        view: Permission.SETTINGS_USER_MANAGEMENT_EDIT_USER,
        edit: Permission.SETTINGS_USER_MANAGEMENT_VIEW,
      },
    ],
  },
  {
    title: 'Settings: Credits',
    permissions: [
      {
        title: 'Track credit usage',
        description: 'Monitor the distribution, usage, balance, and expiration of credits.',
        view: Permission.SETTINGS_CREDITS_VIEW,
        edit: null,
      },
      {
        title: 'Distribute credits among apps',
        description: 'Distribute available credits from your account pool ' +
        'to individual 6sense apps',
        view: null,
        edit: Permission.SETTINGS_CREDITS_DISTRIBUTE,
      },
    ],
  },
  {
    title: 'Audience Workflows',
    permissions: [
      {
        title: 'Workflows',
        description: 'View or Edit Audience Workflows',
        view: WorkflowsPermission.AUDIENCE_WORKFLOWS_VIEW,
        edit: WorkflowsPermission.AUDIENCE_WORKFLOWS_EDIT,
      },
      {
        title: 'Run History',
        description: 'View workflows Run history',
        view: WorkflowsPermission.AUDIENCE_RUN_HISTORY_VIEW,
        edit: WorkflowsPermission.AUDIENCE_RUN_HISTORY_EDIT,
      },
      {
        title: 'Audit Logs',
        description: 'View workflows Audit logs',
        view: WorkflowsPermission.AUDIENCE_AUDIT_LOGS_VIEW,
        edit: WorkflowsPermission.AUDIENCE_AUDIT_LOGS_EDIT,
      },
    ],
    accessCheck: ({ packagePlan }) => {
      const hasAudienceBeta = packagePlan?.audience_workflows?.plan?.name === 'beta'
        && packagePlan?.audience_workflows?.plan?.is_deleted === false;

      return hasAudienceBeta;
    },
  },

  {
    title: 'Data Workflows',
    permissions: [
      {
        title: 'Workflows',
        description: 'View or Edit Data Workflows',
        view: WorkflowsPermission.DATA_WORKFLOWS_VIEW,
        edit: WorkflowsPermission.DATA_WORKFLOWS_EDIT,
      },
      {
        title: 'Real Time Workflows',
        description: 'View or edit real time workflows',
        view: WorkflowsPermission.DATA_REAL_TIME_WORKFLOWS_VIEW,
        edit: WorkflowsPermission.DATA_REAL_TIME_WORKFLOWS_EDIT,
      },
      {
        title: 'Run History',
        description: 'View data workflows Run history',
        view: WorkflowsPermission.DATA_RUN_HISTORY_VIEW,
        edit: WorkflowsPermission.DATA_RUN_HISTORY_EDIT,
      },
      {
        title: 'Audit Logs',
        description: 'View data workflows Audit logs',
        view: WorkflowsPermission.DATA_AUDIT_LOGS_VIEW,
        edit: WorkflowsPermission.DATA_AUDIT_LOGS_EDIT,
      },
    ],
  },

  {
    title: 'Data Health Dashboard',
    permissions: [
      {
        title: 'Data Health Dashbaord',
        description: 'View or edit Data Health Report',
        view: WorkflowsPermission.DATA_HEALTH_DASHBOARD_VIEW,
        edit: WorkflowsPermission.DATA_HEALTH_DASHBOARD_EDIT,
      },
    ],
  },

];

export const getRolePermissionConfigByAppId = (appId) => {
  if (appId === App.ABM) {
    return ROLE_PERMISSION_CONFIG;
  }
  if (appId === App.CE) {
    return ROLE_PERMISSION_CONFIG_CE;
  }
  if (appId === App.SALES) {
    return ROLE_PERMISSION_CONFIG_SI;
  }
  if (appId === App.WORKFLOWS) {
    return ROLE_PERMISSION_CONFIG_WORKFLOWS;
  }

  return ROLE_PERMISSION_CONFIG;
};
