import { hotSwapActionsFactory } from './actionTypes';

export const hotSwapReducerFactory = (prefix) => {
  const hotSwapActions = hotSwapActionsFactory(prefix);

  return {
    [hotSwapActions.FOLDER_LIST_HOT_SWAP_REPLACE]: (state, action) => {
      const updated = state.data.map((folder) =>
        folder.id === action.payload.id ? action.payload : folder
      );

      return {
        ...state,
        data: updated,
      };
    },
    [hotSwapActions.FOLDER_LIST_HOT_SWAP_ADD]: (state, action) => ({
      ...state,
      data: [
        ...state.data,
        action.payload,
      ],
    }),
    [hotSwapActions.FOLDER_LIST_HOT_SWAP_DELETED]: (state, action) => {
      const folderIdsToRemove = action.payload.map((id) => parseInt(id));
      return {
        ...state,
        data: state.data.filter(
          ({ id }) => !folderIdsToRemove.includes(id)
        ),
      };
    },
  };
};

