import {
  includes, get, map, pickBy, keys, findIndex, some,
  isEqual, slice, isEmpty, trim, startsWith, toString,
  every,
} from 'lodash';
import moment from 'moment';
import { stringFormat, re_weburl, parseStringToNumber } from 'utils/utils';
import { strings, EMAIL_REGEX } from 'utils/constants';

// Error Types
const REQUIRED_FIELD = 'Required Field';
const REQUIRED_DOMAINFIELD = 'emptyDomain';
const EMPTY_DOMAINFIELD = 'subdomain is required';
const NO_SPECIAL_CHAR_AT_START_MSG = 'Name cannot start with special character';

// Decorators
const stringInputDecorator = (wrappedFun) => (wrappedFunArgs) => (value, allValues) => {
  const trimmed = value ? value.replace(/^\s+|\s+$/g, '') : value;
  if (wrappedFunArgs) {
    return wrappedFun(wrappedFunArgs)(trimmed, allValues);
  }
  return wrappedFun(trimmed, allValues);
};

const forceError = (validationFun) => (value, allValues) => {
  const errors = validationFun(value, allValues);
  // Put in these checks if we want to chain these decorators one day
  if (Boolean(errors) === false) {
    return undefined;
  }

  return { forceShowError: true, value: errors };
};
// Errors
const isRequired = (value) => {
  let requiredField = value;
  if (requiredField && typeof (requiredField) === 'string') {
    requiredField = value.trim();
  }
  return requiredField ? undefined : REQUIRED_FIELD;
};

const isRequiredDomain = (value) => {
  let requiredField = value;
  if (requiredField && typeof (requiredField) === 'string') {
    requiredField = value.trim();
  }
  return requiredField ? undefined : REQUIRED_DOMAINFIELD;
};

const isEmptySubDomain = (value) => {
  let requiredField = value;
  if (requiredField && typeof (requiredField) === 'string') {
    requiredField = value.trim();
  }
  return requiredField ? undefined : EMPTY_DOMAINFIELD;
};

const minApiLimit = (min) => (value) => value && Number(value.toString().trim()) < min
  ? stringFormat(strings.MIN_API_LIMIT, { min: min.toLocaleString() })
  : undefined;

const dateRequired = (value) => ((value[0] !== '' && value[1] !== '')
  ? undefined
  : REQUIRED_FIELD);
const minDateSpan = (min, unit) => (value) => {
  const displayUnit = min === 1 ? unit.replace(/s$/, '') : unit;
  return moment(value[1]).diff(moment(value[0]), unit) >= min
    ? undefined
    : stringFormat(strings.MIN_DATE_SPAN, { min, unit: displayUnit });
};
const maxDateSpan = (max, unit) => (value) =>
  moment(value[1]).diff(moment(value[0]), unit) <= max
    ? undefined
    : stringFormat(strings.MAX_DATE_SPAN, { max, unit });
const maxLength = (max) => (value) =>
  value && value.length > max
    ? stringFormat(strings.MAX_LENGTH, { max })
    : undefined;
const isNumber = (value) =>
  value && isNaN(Number(value))
    ? strings.IS_NUMBER
    : undefined;
const isCurrency = (value) =>
  value && !/^\d+(?:\.\d{0,2})*$/.test(value)
    ? strings.IS_CURRENCY
    : undefined;

const isEmail = (value) => value && !EMAIL_REGEX.test(value) ? strings.INVALID_EMAIL : undefined;

const isMultiEmail = (value) => value && value.split(',').some(
  (email) => !EMAIL_REGEX.test(email.trim()) // removing whitespace to space out inviting users
) ? strings.INVALID_EMAIL : undefined;

const minLength = (min) => (value) =>
  value && value.length < min
    ? stringFormat(strings.MIN_LENGTH, { min })
    : undefined;

const minAmount = (min, customErrorMsg) => (value) =>
  (!value) || (value && (parseStringToNumber(value)) >= min)
    ? undefined
    : customErrorMsg || stringFormat(strings.MIN_AMOUNT, { min: min.toLocaleString() });

const greaterThan = (gtkey, gtDisplay) => (value, allValues) => {
  const msg = stringFormat(strings.GREATER_THAN, { gtDisplay });
  return (value >= (parseInt(allValues[gtkey], 10) || 0)) ? undefined : msg;
};

const lessThan = (ltkey, customErrorMessage) => (value, allValues) => {
  const msg = customErrorMessage ||
    stringFormat(strings.LESS_THAN, { ltDisplay: allValues[ltkey] });
  return (parseStringToNumber(value) <=
    (parseStringToNumber(allValues[ltkey]) || Infinity)) ? undefined : msg;
};

/**
 * @deprecated Use isRequired function instead
 */
const isFieldRequired = (msg) => (value) => (value ? undefined : msg);


const alphanumerical = (value) => { // all validation for name
  if (!/^(\w|\s)+$/.test(value) && value.length) {
    return strings.ALPHANUMERICAL;
  }
  return undefined;
};

const subdomainConfig = (value) => { // all validation for name
  if (!/^(\w|\s|-)+$/.test(value) && value.length) {
    return strings.SUBDOMAIN_CONFIG;
  }
  return undefined;
};

// String validation for human input. url.com, www.url.net, etc will all pass.
const urlValidator = (str) => {
  const trimmed = str.trim();
  return trimmed.includes('.');
};

const validWebsite = (value) => {
  // eslint-disable-next-line max-len
  if (value && !re_weburl.test(value)) {
    return strings.CLICK_URL;
  }
  return undefined;
};

const notIn = (collection) => (value = '') => includes(
  collection.map((item) => item.toLowerCase().trim()),
  value.toLowerCase().trim())
  ? strings.NAME_EXISTS
  : undefined;

// Upload Checks
const fileSizeCheck = (value, allValues) => {
  // Apply this on the radio group. so value is the select size value
  const actualSize = get(allValues, 'creative[0].size');
  if (actualSize === undefined) {
    return undefined;
  }
  if (actualSize !== value) {
    return stringFormat(strings.FILE_SIZE, { value, actual_size: allValues.actual_size });
  }
  return undefined;
};

const imageAspectRatioCheck = (value, allValues) => {
  // Apply this on the radio group. so value is the select size value
  const actualSize = get(allValues, 'icon-creative[0].size');
  if (actualSize === undefined) {
    return undefined;
  }
  const actualSizeSpilted = actualSize.split('x');
  const tempWidth = parseInt(actualSizeSpilted[0], 10);
  const tempHeight = parseInt(actualSizeSpilted[1], 10);
  if (tempWidth < 300) {
    return stringFormat(strings.ICON_MAX_WIDTH);
  }
  if (tempWidth !== tempHeight) {
    return stringFormat(strings.ICON_FILE_EXPECT_RATIO);
  }
  return undefined;
};

const imageMinMaxDimensionsCheck = (minDimension) => (value, allValues) => {
  // Apply this on the radio group. so value is the select size value
  const actualSize = get(allValues, 'creative[0].size');
  if (actualSize === undefined) {
    return undefined;
  }
  const actualSizeSpilted = actualSize.split('x');
  const tempWidth = parseInt(actualSizeSpilted[0], 10);
  const tempHeight = parseInt(actualSizeSpilted[1], 10);

  const minDimensionSpilted = minDimension.split('x');
  const minWidth = parseInt(minDimensionSpilted[0], 10);
  const MinHeight = parseInt(minDimensionSpilted[1], 10);

  if (tempWidth < minWidth || tempHeight < MinHeight) {
    const desiredSize = `${minWidth}x${MinHeight}`;
    return stringFormat(strings.IMAGE_MAXMIN_DIMENSIONS, { value: actualSize, desiredSize });
  }
  return undefined;
};

const videoSizeCheck = (value, allValues) => {
  // Apply this on the radio group. so value is the select size value
  const actualSize = get(allValues, 'creative[0].size');
  const actualFileType = get(allValues, 'creative[0].file_type');
  const html5SupportVideoTypes = ['video/mp4', 'video/ogg', 'video/webm'];
  if (actualSize === undefined) {
    return undefined;
  }
  if (html5SupportVideoTypes.indexOf(actualFileType.toLowerCase()) !== -1) {
    if (value.includes('>')) {
      const valueSizeWithoutGreaterThan = value.split('>');
      const valueSizeSpilted = valueSizeWithoutGreaterThan[1].split('x');
      const actualSizeSpilted = actualSize.split('x');
      if (
        parseInt(actualSizeSpilted[0], 10) <= parseInt(valueSizeSpilted[0], 10) &&
        parseInt(actualSizeSpilted[1], 10) <= parseInt(valueSizeSpilted[1], 10)
      ) {
        return stringFormat(strings.VIDEO_SIZE, { value, actual_size: actualSize });
      }
    } else if (actualSize !== value) {
      return stringFormat(strings.VIDEO_SIZE, { value, actual_size: actualSize });
    }
  }
  return undefined;
};

const fileListCheck = (fileList) => fileList instanceof Array && fileList.length > 0
  ? undefined
  : 'Atleast one creative is required';

const fileTypeCheck = (allowedFileType) => (file) => {
  const fileType = get(file, 'file.type');
  return fileType && !includes(allowedFileType, fileType)
    ? stringFormat(strings.FILE_TYPE, { type: fileType })
    : undefined;
};

/**
 * This is for array fields. We only raise error if it is partially filled.
 * So empty array field is chill.
 * @param {array} values - array of values given by the fieldsArray validator
 */
const allOrNothing = (values) => {
  const errors = map(values, (fieldValue) => {
    const filledFieldsCount = keys(pickBy(fieldValue, (value) => value)).length;
    if (!(filledFieldsCount === 0 || filledFieldsCount === 2)) {
      return strings.ALL_OR_NOTHING;
    }
    return undefined;
  });
  return findIndex(errors, (item) => item !== undefined) === -1 ? undefined : errors;
};
const noDuplicates = (string = strings.NO_DUPLICATES) => (values) => {
  const errors = map(values, (fieldValue, index) => (
    some(slice(values, 0, index), (i) => isEqual(i, fieldValue) && !isEmpty(fieldValue))
      ? string
      : undefined
  ));
  return findIndex(errors, (item) => item !== undefined) === -1 ? undefined : errors;
};

const isCurrencyAmount = (value) =>
  ((value && isNaN(Number(value.trim().replace('$', '')))) || value === '$')
    ? strings.IS_CURRENCY
    : undefined;

const minStartDateSpan = (endDateKey, min, unit) => (value, allValues) => {
  const displayUnit = min === 1 ? unit.replace(/s$/, '') : unit;
  return moment(allValues[endDateKey]).diff(moment(value), unit) >= min
    ? undefined
    : allValues[endDateKey] && stringFormat(strings.MIN_DATE_SPAN, { min, unit: displayUnit });
};
const minEndDateSpan = (startDateKey, min, unit) => (value, allValues) => {
  const displayUnit = min === 1 ? unit.replace(/s$/, '') : unit;
  return moment(value).diff(moment(allValues[startDateKey]), unit) >= min
    ? undefined
    : allValues[startDateKey] && stringFormat(strings.MIN_DATE_SPAN, { min, unit: displayUnit });
};
// This is hacky. all of this will leave once we get a nice attacheable component
const fileLargenessCheck = (file) => get(file, 'file.size', 0) > 150000
  ? stringFormat(strings.FILE_LARGE, { size: Math.round(file.file.size / 1000).toString() })
  : undefined;

const videoFileLargenessCheck = (file) => get(file, 'file.size', 0) > 125000000
  ? stringFormat(strings.VIDEO_FILE_LARGE, { size: Math.round(file.file.size / 1000).toString() })
  : undefined;

// Warn
const maxValue = (max, string = strings.MAX_VALUE) => (value) => value && value > max
  ? stringFormat(string, { max: max.toLocaleString() })
  : undefined;

const sameAs = (as, string) => (value, allValues) => value !== allValues[as] ? string : undefined;

const minNumber = (min) => (value) => value && Number(value.toString().trim()) < min
  ? stringFormat(strings.MIN_NUMBER, { min: min.toLocaleString() })
  : undefined;
const maxNumber = (max, customErrorMessage) => (value) => {
  const errorMessage = customErrorMessage ||
    stringFormat(strings.MAX_NUMBER, { max: max?.toLocaleString() });

  return value && Number(value.toString().trim()) > max
    ? errorMessage
    : undefined;
};

const liberalUrlValidator = (onError = 'Invalid URL', onSuccess = undefined) => (url) => {
  if (url && url.includes(' ')) {
    return onError;
  }
  // eslint-disable-next-line max-len
  const regex = new RegExp('(([a-z]{3,6}://)|(^|\\s))([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,13}[\\.\\?\\=\\&\\%\\/\\w\\-]*\\b([^@]|$)');
  return regex.test(url) ? onSuccess : onError;
};

const multipleUrlValidator = (onError = 'Multiple URL', onSuccess = undefined) => (url) => {
  const urls = url.split(',');
  const hasMultipleUrls = urls && urls.length > 1 && every(
  urls, (val) => errors.liberalUrlValidator(false, true)(val));
  if (hasMultipleUrls) {
    return onError;
  }
  return onSuccess;
};

const noWebProtocol = (str) =>
  !str.toLowerCase().includes('https://') &&
  !str.toLowerCase().includes('http://') &&
  str.trim().includes('.');

const noSubDomain = (str) => {
  // Quick exit
  if (str.split('.').length < 3) {
    return true;
  }
  // Check if url is using public suffix like .co.uk
  const parsedStr = str.split('/')[0];
  const psl = require('psl');
  const parsedDomain = psl.get(parsedStr);
  const hasSubDomain = parsedDomain !== parsedStr;

  // psl.get returns ca.gov for stuff like dmv.ca.gov so need to have list of suffix exceptions
  if (hasSubDomain && strings.SUBDOMAIN_EXCEPTIONS.some((ps) => parsedStr.includes(ps))) {
    return parsedStr.split('.').length < 4;
  }
  return !hasSubDomain;
};

const domainExists = (str, domains) => {
  let normalizedStr = str.replace(/\s/g, '').toLowerCase();
  if (!(str.includes('http://') || (str.includes('https://')))) {
    normalizedStr = 'http://'.concat(normalizedStr);
  }
  let url;
  try {
    url = new URL(normalizedStr);
  } catch (e) {
    return false;
  }
  return !domains.includes(url.hostname);
};

const TPRRuleExists = (value, list, index, isEdit) => {
  const currentIndex = findIndex(list, { name: trim(value) });
  if (isEdit) {
    return currentIndex !== -1 && currentIndex !== index ?
    'This rule name already exists. Give it a unique name.' : false;
  }
  return currentIndex !== -1 ? 'This rule name already exists. Give it a unique name.' : false;
};

const noSpecialCharAtStart = (invalidCharsList, errorMessage = NO_SPECIAL_CHAR_AT_START_MSG) =>
  (value) =>
    (value && typeof (value) === 'string' && some(invalidCharsList,
      (char) => startsWith(trim(value), char))) ? errorMessage: undefined;

const maxDecimalLimit = (decimalLimit, customErrorMsg) => (value) => {
  const decimalPart = value && (toString(value).split('.')[1]);
  return (decimalPart && (decimalPart.length > decimalLimit)) ?
          (customErrorMsg || `Maximum ${decimalLimit} decimal place number allowed`) : undefined;
};

export const errors = {
  isRequired,
  isRequiredDomain,
  isEmptySubDomain,
  minApiLimit,
  isNumber,
  isCurrency,
  isCurrencyAmount,
  isEmail,
  isMultiEmail,
  minDateSpan,
  maxDateSpan,
  minLength: stringInputDecorator(minLength),
  maxLength: stringInputDecorator(maxLength),
  alphanumerical: stringInputDecorator(alphanumerical)(),
  subdomainConfig: stringInputDecorator(subdomainConfig)(),
  validWebsite: stringInputDecorator(validWebsite)(),
  urlValidator,
  fileTypeCheck,
  minAmount,
  dateRequired,
  greaterThan,
  lessThan,
  fileSizeCheck,
  imageAspectRatioCheck,
  imageMinMaxDimensionsCheck,
  videoSizeCheck,
  notIn,
  passwordInconsistent: sameAs('password', strings.PASSWORD_INCONSISTENT),
  isFieldRequired,
  sameAs,
  fileListCheck,
  allOrNothing,
  noDuplicates,
  fileLargenessCheck,
  videoFileLargenessCheck,
  // This is a reused one so create a list that can be used everywhere
  validNameList: [/* stringInputDecorator(maxLength)(30), */
    stringInputDecorator(alphanumerical)()],
  minStartDateSpan,
  minEndDateSpan,
  minNumber,
  maxNumber,
  liberalUrlValidator,
  noWebProtocol,
  noSubDomain,
  domainExists,
  TPRRuleExists,
  noSpecialCharAtStart,
  maxDecimalLimit,
  multipleUrlValidator,
};

export const decorators = {
  forceError,
};

export const warnings = {
  maxValue,
};

export const errorTypes = {
  REQUIRED_FIELD,
};
