/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Text } from 'v2-components';
import { css, cx } from 'styles/emotion';
import { pxToRem } from '@sixsense/core/style';
import { Flex, Loading, ResultBoundary } from '@sixsense/core/components';
import { Pagination } from 'antd';
import { cloneDeep } from 'lodash';

import moment from 'moment';

const styles = {
  userSection: css({
    background: '#FFFFFF',
    border: '1px solid #C6CACE',
    marginTop: '15px',
    padding: '20px 30px 24px 30px',
  }),
  table: css({
    fontSize: pxToRem(13),
    color: '#001F32',
    width: '100%',
    minHeight: '15vh',
  }),
  headerRow: css({
    height: pxToRem(40),
    border: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  }),
  row: css({
    height: pxToRem(60),
    border: '1px solid #C6CACE',
    ':hover': {
      background: '#F9FAFB',
      button: {
        display: 'block',
      },
    },
    button: {
      display: 'none',
    },
  }),
  cell: css({
    padding: `0 ${pxToRem(10)}`,
    position: 'relative',
    '&:first-child': {
      paddingLeft: pxToRem(40),
    },
    '&:last-child': {
      paddingRight: pxToRem(40),
    },
  }),
  checkboxCell: css({
    paddingLeft: `${pxToRem(16)}`,
  }),
  nameCell: css({
    width: '20%',
    textAlign: 'left',
  }),
  sortIcon: css({
    position: 'absolute',
    left: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
  }),
  checkbox: css({
    width: 20,
  }),
  editButton: css({
    border: 'none',
    fontSize: `${pxToRem(12)}`,
    backgroundColor: '#ffffff00',
    padding: '0px',
  }),
  editButtonText: css({
    margin: '4px',
  }),
  footer: css({
    marginTop: `${pxToRem(20)}`,
    '.ant-pagination': {
      width: '100%',
    },
    '.ant-pagination-total-text': {
      width: '40%',
    },
  }),
  nousers: css({
    position: 'absolute',
    marginLeft: '35%',
    padding: '30px',
  }),
};

const NoUsers = () => (
  <Flex
    className={styles.nousers}
    direction="row" alignItems="center" justifyContent="center"
  >
    <Text>No data found</Text>
  </Flex>
);


const HEADER_CONFIG = [
  { label: 'Name', sortKey: 'username', className: styles.nameCell },
  { label: 'Email', sortKey: 'email' },
  { label: 'Company Website', sortKey: 'role' },
  { label: 'Lead Id', sortKey: 'roleId' },
  { label: 'Request Date', sortKey: 'balance' },
];

const pagination = {
  pageSize: 25,
  initialPage: 1,
};

const showTotal = (total, range) => (
  <Text>
    {' '}
    Showing {range[0]} - {range[1]} of {total} records
  </Text>
);

type UserListProps = {
  page: number;
  setPage: (page: number) => void;
  users: any;
};

const UserList = (props: UserListProps) => {
  const { page, setPage, users } = props;
  const [paginatedUsers, setPaginatedUsers] = useState([]);

  useEffect(() => {
    if (users.data?.length) {
      const userData = cloneDeep(users.data);
      setPaginatedUsers(userData.slice((page - 1) * pagination.pageSize,
      page * pagination.pageSize));
    }
  }, [page, users]);

  return (
    <ResultBoundary
      renderLoading={() => <Loading.Spinner level="page" />}
      result={users}
    >
      {({ data }) => (
        <div className={styles.userSection}>
          <div>
            <table className={styles.table}>
              <thead>
                <tr className={styles.headerRow}>
                  {HEADER_CONFIG.map((config) => (
                    <th className={cx(styles.cell, config.className)}>
                      {config.label}
                    </th>
                  ))}
                  <th />
                </tr>
              </thead>
              <tbody>
                {(paginatedUsers || []).map((user) => (
                  <tr key={user.id} className={styles.row}>
                    <td className={cx(styles.cell, styles.nameCell)}>
                      {`${user.first_name} ${user.last_name}` || '-'}
                    </td>
                    <td className={styles.cell}>
                      {user.email || '-'}
                    </td>
                    <td className={styles.cell}>
                      {user.company_website || '-'}
                    </td>
                    <td className={styles.cell}>{user.people_id || '-'}</td>
                    <td className={styles.cell}>
                      {moment(user.requested_at).format('D MMM YYYY') || '-'}</td>
                  </tr>
                ))}
                {paginatedUsers?.length === 0 && <NoUsers />}
              </tbody>
            </table>

            <Flex justifyContent="flex-start" className={styles.footer}>
              <Pagination
                current={page}
                pageSize={pagination.pageSize}
                onChange={(nextPage) => setPage(nextPage)}
                total={data?.length || 0}
                showTotal={showTotal}
              />
            </Flex>
          </div>
        </div>
      )}
    </ResultBoundary>
  );
};

export default UserList;
