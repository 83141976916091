import { useRequestValue } from '@sixsense/core';
import { useOrg } from 'hooks';
import { keywordRequestState } from '../state';
import { PageFilters, PaginationState } from '../types';
import { getQueryParams, getPaginationParams } from '../utils';

const useKeywordRequest = (
  filters: PageFilters,
  paginationConfig: PaginationState,
  counter: number,
) => {
  const org = useOrg();
  const queryParams = getQueryParams(filters, true);
  const paginationParams = getPaginationParams(paginationConfig);

  const keywordsResult = useRequestValue(
    keywordRequestState,
    {
      orgId: org.id,
      queryParams,
      paginationParams,
      counter,
    }
  );

  return keywordsResult;
};

export default useKeywordRequest;
