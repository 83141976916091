import React from 'react';
import PropTypes from 'prop-types';
import { SixSenseLogo, Text, Row, UserCondition } from 'v2-components';
import { COLORS } from 'v2-styles/constants';
import '../../styles/core.scss';
import { connect } from 'react-redux';
import { actions } from './modules';
import { globalSearchLoadingSelector } from './selectors';
import styles from './Navbar.module.scss';
import { orgLoadingSelector, orgObjSelector } from 'modules/user/selectors';

import TopNav from '../TopNav/index';
import {
  DESIGN_SYSTEM_ROOT_CLASS,
  SCOPED_DESIGN_SYSTEM_THEME,
  ThemeProvider,
} from '@sixsense/core/style';

export function NavbarComponent(props) {
  const { isPrelogin, currentPath, orgLoading, isOrgDeleted } =
    props;

  if (isPrelogin) {
    return (
      <div className={styles.navBarContainer}>
        <SixSenseLogo light className={styles.logo} />
      </div>
    );
  }
  return (
    <ThemeProvider theme={SCOPED_DESIGN_SYSTEM_THEME} nonce={window.__CSP_NONCE__}>
      <div className={DESIGN_SYSTEM_ROOT_CLASS}>
        <TopNav currentPath={currentPath} />
        {orgLoading || !isOrgDeleted ? null : (
          <UserCondition showFlags={['is_staff', 'is_dev', 'is_superuser']}>
            <Row
              className={styles.inactiveBanner}
              alignItems={Row.ALIGN_ITEMS.CENTER}
              justifyContent={Row.ALIGN_ITEMS.CENTER}
            >
              <Text color={COLORS.WHITE} className={styles.username}>
                This is an inactive instance. If it needs to be reactivated, please reach{' '}
                out to the Solutions Consulting Team to assist.
              </Text>
            </Row>
          </UserCondition>
        )}
      </div>
    </ThemeProvider>
  );
}

NavbarComponent.propTypes = {
  isPrelogin: PropTypes.bool,
  currentPath: PropTypes.string,
  orgLoading: PropTypes.bool,
  isOrgDeleted: PropTypes.bool,
};

NavbarComponent.defaultProps = {};

const mapStateToProps = (state) => ({
  loading: globalSearchLoadingSelector(state),
  orgLoading: orgLoadingSelector(state),
  isOrgDeleted: orgObjSelector(state)?.is_deleted,
});

export const Navbar = connect(mapStateToProps, actions)(NavbarComponent);
