import { toLower, has, get } from 'lodash';

export const downloadGuide = () => { };

const getActualType = (type) => {
  switch (toLower(type)) {
    case 'string':
    case 'id':
    case 'reference':
    case 'email':
    case 'picklist':
    case 'textarea':
    case 'encryptedstring':
    case 'multipicklist':
    case 'multiselectpicklist':
    case 'address':
    case 'phone':
    case 'phonenumber':
    case 'url':
    case 'enumeration':
    case 'lead_function':
    case 'phone_number':
    case 'text':
    case 'owner':
    case 'entityname':
    case 'state':
    case 'status':
    case 'customer':
    case 'memo':
    case 'array': // Outreach has array of strings
    case 'emailaddress': // for eloqua
    case 'select': // picklist equivalant in hubspot
      return 'string';

    case 'datetime':
    case 'date':
    case 'timestamp':
      return 'date';

    case '_boolean':
    case 'bool':
    case 'boolean':
    case 'radio': // hubspot
      return 'boolean';

    case 'float':
    case 'decimal':
      return 'float';

    case '_double':
    case 'percent':
    case 'double':
    case 'money':
      return 'double';

    case '_int':
    case 'number':
    case 'integer':
    case 'int':
    case 'bigint':
    case 'currency': // integer(Annual Revenue) <-- currency(annualRevenue)
      return 'int';

    case 'empty':
      return 'empty';

    // We cannot map our values to these Dynamics fields:
    case 'uniqueidentifier':
    case 'virtual':
    case 'image':
    case 'file':
      return 'INVALID';

    default:
      return 'INVALID';
  }
};

const compatibilityMap = {
  string: ['string'],
  int: ['string', 'int', 'double', 'float'],
  double: ['string', 'int', 'double', 'float'],
  float: ['string', 'int', 'double', 'float'],
  date: ['string', 'date'],
  boolean: ['string', 'boolean'],
  empty: ['string', 'int', 'double', 'float', 'date', 'boolean'],
};

export const areConflictingTypes = (leadFieldType = '', systemFieldType = '') => {
  if (systemFieldType.length > 0 && leadFieldType.length > 0) {
    const actualLeadType = getActualType(leadFieldType);
    const actualCustomerType = getActualType(systemFieldType);

    return !compatibilityMap[actualLeadType].includes(actualCustomerType);
  }

  return false;
};

export const getMaintenanceInfo = () => {
  try {
    const maintenanceString = get(window, 'process.env.MAINTENANCE_STATUS', '');
    const maintenanceInfo = JSON.parse(maintenanceString.replace(/&quot;/g, '"'));

    // ensure maintenance info has at least a message and app list
    if (!has(maintenanceInfo, 'message') || !has(maintenanceInfo, 'apps')) {
      throw new Error('Maintenance info seems to be malformed');
    }
    return maintenanceInfo;
  } catch (err) {
    return '';
  }
};

export function toSnakeCase(str) {
  return str
      .toLowerCase()
      .replace(/[^\w\s]/g, '')
      .replace(/\s+/g, '_');
}
