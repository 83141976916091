import React from 'react';
import { Modal, Text } from 'v2-components';

export const LINKEDIN_SPONSORED_CONTENT_POSTER_REQUEST_SENT_PROMPT = {
  type: Modal.PROMPT_TYPES.INFO,
  title: 'Sponsored Content Poster Access Request Sent!',
  width: 600,
  content: <Text color={Text.COLOR.GREY1} type={Text.TYPE.SUBBODY}>
    If you are the administrator of your LinkedIn business account, sign in to
    LinkedIn to approve the request from 6sense:
    <ul className="aam-t--10">
      <li>
        Go to the&nbsp;
        <b>LinkedIn company page &gt; Settings &gt; Manage Admins</b>
      </li>
      <li className="aam-t--5">
        Click 'Accept' to grant access to 6sense to be your sponsored content poster.
      </li>
    </ul>
      If you are not the administrator of your business LinkedIn account, reach
      out to your administrator to approve our request.
  </Text>,
};

// facebook is being used in the api calls , we only need to show Meta in the front-end side.
export const AD_NETWORKS = {
  google: 'google',
  linkedin: 'linkedin',
  facebook: 'facebook',
  meta: 'meta',
  google_ads: 'google_ads',
};

export const AD_NETWORK_LABELS = {
  [AD_NETWORKS.google]: 'Google Ads',
  [AD_NETWORKS.google_ads]: 'Google Ads',
  [AD_NETWORKS.linkedin]: 'LinkedIn',
  [AD_NETWORKS.facebook]: 'Meta',
  [AD_NETWORKS.meta]: 'Meta',
};

export const SEGMENT_SYNC_AD_PROVIDER_KEY = {
  [AD_NETWORKS.google]: 'google_ads',
};
