import { filter, values } from "lodash";
import {
  FEATURE_IDENTIFIERS,
  POSSIBLE_VALUE_DISPLAY_TYPES,
} from "routes/AdvertisingDS/constants";
import {
  CAMPAIGN_SOURCE,
  CAMPAIGN_SOURCE_LABELS,
  CAMPAIGN_STATUS_LABELS,
  CAMPAIGN_STATUS_X,
} from "routes/AdvertisingDS/routes/Campaigns/constants";
import { DATE_FORMAT as GLOBAL_DATE_FORMAT } from "utils/constants";

export const FILTER_KEYS = {
  campaignSource: "campaign_source__in",
  campaignStatus: "campaign_status__in",
  createdBy: "created_by_id",
  folders: "classification_id__in",
  campaigns: "id__in",
  product: "product__in",
  purchaseOrder: "purchase_order__in",
};

export const FILTER_LABELS = {
  createdBy: "Created By",
  campaignType: "Campaign Type",
  status: "Status",
  folders: "Campaign Folder",
  campaigns: "Campaigns",
  product: "Product",
  purchaseOrder: "Purchase Order (PO)",
};

export const CREATED_BY_ALL = "all";

export const CREATED_BY_ME_FILTER_OPTION = {
  label: null,
  filterKey: FILTER_KEYS.createdBy,
  // undefined will be replaced with actualt logged in use's id in a selector before consumption of this constant
  choices: [{ label: "Created By Me", value: undefined }],
};

export const CAMPAIGN_TYPE_FILTER_OPTION = {
  label: FILTER_LABELS.campaignType,
  filterKey: FILTER_KEYS.campaignSource,
  choices: [
    CAMPAIGN_SOURCE.INTERNAL,
    CAMPAIGN_SOURCE.RETARGETING,
    CAMPAIGN_SOURCE.CONTEXTUAL,
    CAMPAIGN_SOURCE.EXTERNAL,
    CAMPAIGN_SOURCE.LINKEDIN,
    CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING,
  ].map((source) => ({
    label: CAMPAIGN_SOURCE_LABELS[source],
    value: source,
  })),
};

export const CAMPAIGN_STATUS_FILTER_OPTION = {
  label: FILTER_LABELS.status,
  filterKey: FILTER_KEYS.campaignStatus,
  choices: filter(
    values(CAMPAIGN_STATUS_X).map(
      (status) =>
        ![CAMPAIGN_STATUS_X.draft, CAMPAIGN_STATUS_X.scheduled].includes(
          status
        ) && {
          label: CAMPAIGN_STATUS_LABELS[status],
          value: status,
        }
    )
  ),
};

export const FOLDER_FILTER_OPTION = {
  label: FILTER_LABELS.folders,
  filterKey: FILTER_KEYS.folders,
  choices: [],
};

export const CAMPAIGNS_FILTER_OPTION = {
  label: FILTER_LABELS.campaigns,
  filterKey: FILTER_KEYS.campaigns,
  choices: [],
};

export const PRODUCT_FILTER_OPTION = {
  label: FILTER_LABELS.product,
  filterKey: FILTER_KEYS.product,
  choices: [],
  newFeatureKey: FEATURE_IDENTIFIERS.CBD_FILTERS,
};

export const PURCHASE_ORDER_FILTER_OPTION = {
  label: FILTER_LABELS.purchaseOrder,
  filterKey: FILTER_KEYS.purchaseOrder,
  choices: [],
  newFeatureKey: FEATURE_IDENTIFIERS.CBD_FILTERS,
};

export const FILTER_OPTIONS = [
  CREATED_BY_ME_FILTER_OPTION,
  CAMPAIGN_TYPE_FILTER_OPTION,
  CAMPAIGN_STATUS_FILTER_OPTION,
  FOLDER_FILTER_OPTION,
  CAMPAIGNS_FILTER_OPTION,
  PRODUCT_FILTER_OPTION,
  PURCHASE_ORDER_FILTER_OPTION,
];

export const DATE_COLUMN_KEY = "date";

export const SORT_ORDER = {
  asc: "asc",
  desc: "desc",
};

export const DATE_FORMAT = GLOBAL_DATE_FORMAT;

export const COLUMNS_STORAGE_KEY = "performanceTrendColumns";

export const DATA_PENDO_PREFIX = "ptr_list";

export const DIMENSION_COLUMN_KEY = "dimension";
export const BUDGET_SPENT_KEY = "budget_spent";
export const MEDIA_IMPRESSIONS_KEY = "media_impressions";
export const CPM_KEY = "cpm";
export const CLICK_COUNT_KEY = "click_count";
export const CTR_KEY = "ctr";
export const CPC_KEY = "cpc";
export const VIEWABILITY_PERCENT_KEY = "viewability_percent";
export const ACCOUNTS_REACHED_KEY = "accounts_reached";
export const ACCOUNTS_ENGAGED_KEY = "accounts_engaged";
export const ACCOUNT_CTR_KEY = "account_ctr";
export const USERS_VIEW_THROUGH_KEY = "users_view_through";
export const VTR_KEY = "vtr";
export const ACCOUNT_VTR_KEY = "account_vtr";
export const COST_PER_ACCOUNT_ENGAGED_KEY = "cost_per_account_engaged";
export const COST_PER_ACCOUNT_REACHED_KEY = "cost_per_account_reached";

export const LABEL_KEYS = {
  budgetSpent: BUDGET_SPENT_KEY,
  mediaImpressions: MEDIA_IMPRESSIONS_KEY,
  cpm: CPM_KEY,
  clicks: CLICK_COUNT_KEY,
  ctr: CTR_KEY,
  cpc: CPC_KEY,
  viewabilityPercent: VIEWABILITY_PERCENT_KEY,
  accountsReached: ACCOUNTS_REACHED_KEY,
  accountsEngaged: ACCOUNTS_ENGAGED_KEY,
};

export const DISPLAY_LABELS = {
  [LABEL_KEYS.budgetSpent]: "Spend",
  [LABEL_KEYS.mediaImpressions]: "Impressions",
  [LABEL_KEYS.cpm]: "eCPM",
  [LABEL_KEYS.clicks]: "Clicks",
  [LABEL_KEYS.ctr]: "CTR",
  [LABEL_KEYS.cpc]: "eCPC",
  [LABEL_KEYS.viewabilityPercent]: "Viewability",
  [LABEL_KEYS.accountsReached]: "Accounts Reached",
  [LABEL_KEYS.accountsEngaged]: "Accounts Engaged",
};

export const VALUE_DISPLAY_TYPE_MAP = {
  [DISPLAY_LABELS[LABEL_KEYS.budgetSpent]]:
    POSSIBLE_VALUE_DISPLAY_TYPES.CURRENCY,
  [DISPLAY_LABELS[LABEL_KEYS.cpm]]: POSSIBLE_VALUE_DISPLAY_TYPES.CURRENCY,
  [DISPLAY_LABELS[LABEL_KEYS.cpc]]: POSSIBLE_VALUE_DISPLAY_TYPES.CURRENCY,

  [DISPLAY_LABELS[LABEL_KEYS.ctr]]: POSSIBLE_VALUE_DISPLAY_TYPES.PERCENTAGE,
  [DISPLAY_LABELS[LABEL_KEYS.viewabilityPercent]]:
    POSSIBLE_VALUE_DISPLAY_TYPES.PERCENTAGE,
};

export const DIMENSION_KEY = "dimension";

export const PERFORMANCE_TREND_LEFT_STAT_KEY = "trendStatLeft";
export const PERFORMANCE_TREND_RIGHT_STAT_KEY = "trendStatRight";
export const HYPHEN = "-";
