import React from 'react';
import { Row, Text, Link } from 'v2-components';
import moment from 'moment';
import styles from './helpContent.module.scss';

export const DEFAULT_REPORT_DATES = [];
for (let i = 1; i < 12; i++) {
  const month = moment().subtract(i, 'months');
  const startDate = moment(month).startOf('month').format('YYYY-MM-DD');

  DEFAULT_REPORT_DATES.push(
    {
      label: moment(startDate).format('MMM, YYYY'),
      value: startDate,
    },
  );
  if (startDate === '2019-01-01') {
    break;
  }
}

// header
export const USAGE = 'usage';
export const MODEL = 'model';
export const VALUE = 'value';

// value metric keys
export const CREATED_OPPS = 'opportunities';
export const NO_OPPS = 'no_opportunities';
export const ACTIVITY_OPP = 'activities';
export const CAMPAIGN_TO_OPP = 'campaigns';
export const WEB_MATCH_RATES = 'match_rates';
export const CAMPAIGN_TO_OPPORTUNITY_LIFETIME = 'campaigns_lifetime';

export const VALUE_METRICS = [
  CREATED_OPPS,
  NO_OPPS,
  ACTIVITY_OPP,
  CAMPAIGN_TO_OPP,
  WEB_MATCH_RATES,
];

export const TITLE_MAP = {
  [CREATED_OPPS]: 'accounts worked by sales after 6sense predicted',
  [NO_OPPS]: 'accounts with no created opportunities',
  [ACTIVITY_OPP]: 'activities by source for accounts with opportunities',
  [CAMPAIGN_TO_OPP]: 'campaigns influencing opportunities',
  [WEB_MATCH_RATES]: 'website match rates',
};

// eslint-disable-next-line max-len
const WEBSITE_MATCH_RATES_KB_LINK='https://support.6sense.com/knowledge-base/5554972691347-pageview-match-rates/';

// tooltips:
export const getOpportunitiesToolTip = (isGlobalVMReportingEnabled = false) => (
  <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
    <Text className={styles.section}>
        This analytic shows the buying-stage distribution across accounts in
        which at least one opportunity was created during the selected month
        for the chosen product category. For each account, the maximum buying
        stage in the duration between 90 days prior to the opportunity
        creation date and the first worked date is selected.
    </Text>
    <Text className={styles.section}>Some important definitions to note:</Text>
    <Text className={styles.section}>
      <Text space bold>Worked by Sales:</Text>
      For this analytic, we consider an account to be “worked” if any
      tracked task or sales outreach event we can observe from your CRM
      captured data occurred after 6sense’s maximum buying-stage prediction
      date, but before opportunity creation.
    </Text>
    <Text className={styles.section}>
      <Text space bold>Exclude Irrelevant Opportunities:</Text>
      Choosing this option excludes any created opportunities not
      considered relevant for the product’s models, as determined during data
      onboarding for your account. Please reach out to your Customer Success
      Manager for any questions on which opportunities were considered as relevant.
    </Text>
    <Text className={styles.section}>
      <Text bold space>Exclude $0 Opportunities:</Text>
      Choosing this option limits this analytic to show only those
      opportunities that have an estimated opportunity size. Blank
      opportunity values would also be considered as $0. This filter is
      based on the data-mapping done for your systems at the time of onboarding.
    </Text>
    <Text className={styles.section}>
      <Text bold space>Estimated Pipeline:</Text>
      The numbers shown here are for US dollars only (as foreign
      currencies and conversions are not currently supported). {
        isGlobalVMReportingEnabled ? '' : `Hence $US
      numbers for pipeline are only shown when “United States” is chosen in
      the country selector.`}
    </Text>
  </Row>
);
export const NO_OPP_TT = (
  <Text>
    This analytic shows the possible value via missed opportunities in
    buying-stage distribution across accounts that were predicted to be
    in-market by 6sense, but where no opportunity was created in the selected
    month and in the preceding 3 months. It reflects each account's maximum
    buying stage during the selected month.
  </Text>
);
export const ACTIVITY_SOURCE_TT = (
  <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
    <Text className={styles.section}>
      This analytic shows the value of the 6sense Embedded CDP by connecting
      data across available and integrated sources for accounts with
      opportunities, prior to an opportunity being created (pre-pipeline) and
      after an opportunity is created (post-pipeline).
    </Text>
    <Text className={styles.section}>
      <Text space bold>Relevant Opportunities:</Text>
      Choosing this option excludes any created opportunities not
      considered relevant for the product’s models, as determined during data
      onboarding for your account. Please reach out to your Customer Success
      Manager for any questions on which opportunities were considered as relevant.
    </Text>
  </Row>
);

export const CAMPAIGN_TT = (
  <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
    <Text className={styles.section}>
      This analytic shows how 6sense display campaigns have influenced
      pipeline creation across accounts during a specific month. The month
      chosen in the analytic represents the month during which campaigns ran, not
      the opportunity created month.
    </Text>

    <Text className={styles.section}>Some important points to note:</Text>
    <Text bold className={styles.section}>
        $0 Opportunities are not included within this analytic.
    </Text>
    <Text className={styles.section}>
      <Text space bold>Engaged:</Text>
      Includes all accounts that have done click-throughs or
      view-throughs across campaigns during the selected month.
    </Text>
    <Text className={styles.section}>
      <Text space bold>Increased Engagement:</Text>
      Includes all accounts that have increased engagement across individual
      campaigns during the selected month.
    </Text>
  </Row>
);

export const MATCH_RATES_TT = (
  <Text>
    This analytic shows the value of matching web activities on the tagged
    website using the 6sense Company Identity Graph. The graph showcases the
    total number of matched pageviews, as well as the effective pageview match-rate trend.
    Matched events are then available across the platform for all use cases, including:
    segmentation, enrichment, personalization, display campaigns, predictive insights, and
    account/lead scoring. <Link
      link={WEBSITE_MATCH_RATES_KB_LINK}
      newWindow
    >
      <span style={{ padding: '3px' }}>Learn more</span>
    </Link>
  </Text>
);

export const VM_TOOLTIP = (
  <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
    <Text className={styles.section}>
        Value Metrics updates monthly; it includes and reflects any CRM or MAP
        changes to accounts, opportunities and activity for 12 months of
        historical value metrics analytics. Any analytics older than 12 months
        will not be updated.
    </Text>
    <Text>
        Note: If none of your pipeline numbers are showing in this report, it means
        6sense was not provided data during onboarding. Please work with your
        Customer Success Manager to ensure the data mappings can be completed for your account.
    </Text>
  </Row>
);

export const OPP_LINK = 'vm_opps';
export const NOOPP_LINK = 'vm_noopp';
export const CAMPAIGN_LINK = 'vm_campaign';
export const ACTIVITY_LINK = 'vm_activity';
export const MATCH_RATE_LINK = 'vm_matchrates';
export const VM_REGION = ['NA', 'EMEA', 'APAC', 'LATAM'];
export const LOCATION_FILTER_INFO_MESSAGE = (
  <span>
    You can modify the region definition
    <br /> by selecting /deselecting more
    <br />
    countries from the list.{' '}
    <Link
      link="https://support.6sense.com/knowledge-base/360058551753-value-metrics/"
      newWindow
    >
      Learn More
    </Link>
  </span>
);
