import React from 'react';
import { Text, CustomIcon, Icon, Row, Tooltip2 } from 'v2-components';
import styles from './Button.module.scss';
import { COLORS } from '../../v2-styles/constants';

type Props = {
  id?: string;
  children?: React.ReactNode;
  onClick?: Function;
  onMouseDown?: Function;
  type?: 'primary' | 'secondary' | 'dropdown';
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  className?: string;
  loading?: boolean;
  loadingText?: React.ReactNode;
  labelClass?: string;
  disabled?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
  tooltipPlacement?: string;
  tooltipContent?: React.ReactNode;
  tooltipOverlayStyle?: React.ReactNode;
  isHeaderButton?: boolean;
  dataPendo?: string,
}

const Button = (props: Props) => {
  const {
    id,
    type,
    className,
    children,
    iconRight = null,
    iconLeft = null,
    onClick,
    loading = false,
    loadingText,
    labelClass = '',
    disabled = false,
    htmlType = 'button',
    tooltipPlacement,
    tooltipContent,
    tooltipOverlayStyle,
    isHeaderButton,
    dataPendo,
    onMouseDown,
  } = props;

  const borderStyle = type === 'primary' ? styles.primary : styles.achromatic;
  const dropDownHover = type === 'dropdown' ? styles.hover : '';
  const disabledStyle = disabled ? styles.disabled : '';

  let labelColor;
  if (type === 'primary') {
    labelColor = COLORS.WHITE;
  } else if (type === 'secondary') {
    labelColor = COLORS.BLUE;
  } else {
    labelColor = COLORS.GREY;
  }

  const leftIcon =
    typeof iconLeft === 'string' ? (
      <Icon type={iconLeft} className={`${styles[labelColor]}`} />
    ) : (
      iconLeft
    );
  const rightIcon =
    typeof iconRight === 'string' ? (
      <Icon type={iconRight} className={`${styles[labelColor]}`} />
    ) : (
      iconRight
    );

  let classText = `
    ${styles.defaultBtn}
    ${borderStyle}
    ${dropDownHover}
    ${disabledStyle}
    ${className || undefined}
    ${isHeaderButton ? styles.headerButton : null}
  `;

  const label =
    typeof children === 'string' ? (
      <Text color={labelColor} className={`${styles.label} ${labelClass}`}>
        {children}
      </Text>
    ) : (
      children
    );

  if (loading) {
    const loadingLabel = loadingText || 'Loading...';
    classText = `${classText} ${styles.loading}`;

    return (
      <button
        disabled={disabled}
        type={htmlType}
        id={id}
        onClick={() => onClick && onClick(id)}
        className={classText}
      >
        <CustomIcon
          iconType={CustomIcon.LOADING_CIRCLE_NOTCH}
          classIcon={`${styles[labelColor]}`}
        />
        <Text className={styles.loading} color={labelColor}>
          {loadingLabel}
        </Text>
      </button>
    );
  }

  const button = (
    <button
      disabled={disabled}
      type={htmlType}
      id={id}
      onClick={(e) => {
        e.stopPropagation();
        if (disabled) {
          return;
        }
        if (onClick) {
          onClick(id);
        }
      }}
      className={classText}
      data-pendo={dataPendo}
      onMouseDown={onMouseDown}
    >
      <Row
        alignItems={Row.ALIGN_ITEMS.CENTER}
        justifyContent={Row.JUSTIFY_CONTENT.CENTER}
        flexWrap={Row.FLEX_WRAP.NOWRAP}
      >
        {leftIcon}
        {label}
        {rightIcon}
      </Row>
    </button>
  );
  return (
    <React.Fragment>
      {tooltipContent !== undefined ? (
        <Tooltip2
          placement={tooltipPlacement}
          overlay={tooltipContent}
          overlayStyle={tooltipOverlayStyle}
        >
          {button}
        </Tooltip2>
      ) : (
        button
      )}
    </React.Fragment>
  );
};

Button.PRIMARY = 'primary';
Button.SECONDARY = 'secondary';
Button.DROPDOWN = 'dropdown';

export default Button;
