import { defineRequestState, defineSharedState } from '@sixsense/core';

const PATH_PREFIX = 'settings.generativeai';

export const productSummaryAttributesSharedState = defineSharedState({
  path: `${PATH_PREFIX}.productSummaryAttributes`,
  defaultValue: [],
});

export const componentsAndMetricsRequestState = defineRequestState({
  path: `${PATH_PREFIX}.config`,
  getConfig: (orgId : number) => ({
    url: `settings/${orgId}/components-and-metrics/`,
  }),
});

export const getAllProductsSummaryRequestState = defineRequestState({
  path: `${PATH_PREFIX}.allProductSummary`,
  getConfig: (orgId : number) => ({
    url: `sales-ai-settings/${orgId}/admin/email-writer-product-summaries/`,
  }),
});

export const getAIWriterFeatureEnablement = defineRequestState({
  path: `${PATH_PREFIX}.getFeatureEnablementProductAttributes`,
  getConfig: (orgId: number) => ({
    url: `sales-ai-settings/${orgId}/admin/feature-enablement/?feature_name=email_writer`,
  }),
  transformData: (data: any) => data?.data,
});
