/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrchestrationIconStyles.module.scss';
import InsideViewLogo from './images/insideview.svg';

const InsideView = ({ className, size }) =>
  (
    <img src={InsideViewLogo}
      className={`${styles.default} ${className} ${styles[size]}`}
    />
  )

export default InsideView;