import { orgSelector } from 'modules/user/selectors';
import { createSelector } from 'reselect';
import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';
import {
  PRESET_STRING,
} from '../../constants';
import { initializeAssigneeForm } from './utils';

export const baseSelector = (state) => state.taxonomyV2.preferences;

const loadCoverageThresholdEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `turbotax/taxonomy/org/${orgId}/turbotax_coverage_threshold/`
);

const SAVE_THRESHOLD_COVERAGE_SUCCESS = `${PRESET_STRING}/SAVE_THRESHOLD_COVERAGE_SUCCESS`;


const saveThresholdCoverageReducer = {
  [SAVE_THRESHOLD_COVERAGE_SUCCESS]: (state, action) => ({
    ...state,
    data: action.coverage,
  }),
};

const SAVE_ASSIGNEE_SUCCESS = `${PRESET_STRING}/SAVE_ASSIGNEE_SUCCESS`;


const saveAdminReducer = {
  [SAVE_ASSIGNEE_SUCCESS]: (state, action) => ({
    ...state,
    data: action.assigees,
  }),
};

export const INITIALIZE_COVERAGE_THRESHOLD = `${PRESET_STRING}/INITIALIZE_COVERAGE_THRESHOLD`;


function initializeCoverageThreshold(data) {
  return {
    type: INITIALIZE_COVERAGE_THRESHOLD,
    data,
  };
}

export const loadCoverageThresholdGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: `${PRESET_STRING}/COVERAGE_THRESHOLD`,
  endpointSelector: loadCoverageThresholdEndpointSelector,
  stateKey: 'coverageThreshold',
  initialData: [],
  onSuccessActions: [(data) => initializeCoverageThreshold(data)],
  onFailureActions: [],
  method: 'GET',
  attachReducer: saveThresholdCoverageReducer,
});

const loadAssigneeEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `turbotax/taxonomy/org/${orgId}/turbotax_assignee/`
);

const CHANGE_ADMIN = `${PRESET_STRING}/CHANGE_ADMIN`;

// adding the action here to avoid circular dependency
export function changeAdminAssignee(admin) {
  return {
    type: CHANGE_ADMIN,
    admin,
  };
}

export const loadAssigneeGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: `${PRESET_STRING}/LOAD_ASSIGNEE`,
  endpointSelector: loadAssigneeEndpointSelector,
  stateKey: 'loadAssignee',
  initialData: [],
  onSuccessActions: [
    (data) => {
      const admin = data.find((d) => d.data_source === 'all');
      if (admin) {
        return changeAdminAssignee(admin.name);
      }
      return changeAdminAssignee('');
    },
    (data) => {
      const assignees = data.filter((d) => d.data_source !== 'all');
      return initializeAssigneeForm(assignees);
    },
  ],
  onFailureActions: [],
  method: 'GET',
  attachReducer: saveAdminReducer,
});
