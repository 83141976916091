import update from 'immutability-helper';
import { forEach, get, map, omit } from 'lodash';
import { combineReducers } from 'redux';
import { PRESET_STRING, SORT_ORDER_DESC } from './constants';
import {
  downloadJobsStateGenerator,
  loadOverrideUserStateGenerator,
  loadRuleNamesStateGenerator,
} from './stateGenerators';

const initialLoadingState = {
  loading: true,
  loaded: false,
  error: false,
};

// Initial State
const initialState = {
  allSelected: false,
  mappingsData: [],
  ...initialLoadingState,
  globalConfig: null,
  mappingColumns: [],
  sort: {
    sortAttr: 'activity_count',
    sortOrder: SORT_ORDER_DESC,
  },
  pagination: {
    pageNumber: 1,
    pageSize: 50,
  },
  totalRecords: 0,
};

const LOAD_MAPPING_DATA_REQUEST = `${PRESET_STRING}/LOAD_MAPPING_DATA_REQUEST`;
const LOAD_MAPPING_DATA_FAILURE = `${PRESET_STRING}/LOAD_MAPPING_DATA_FAILURE`;
const LOAD_MAPPING_DATA_SUCCESS = `${PRESET_STRING}/LOAD_MAPPING_DATA_SUCCESS`;

const loadMappingData = () => ({
  type: LOAD_MAPPING_DATA_REQUEST,
  loading: true,
  loaded: false,
  error: false,
  mappingsData: [],
});

const loadMappingDataFailure = () => ({
  type: LOAD_MAPPING_DATA_FAILURE,
  loading: false,
  error: true,
});

const loadMappingDataSuccess = (mappingsData, totalRecords) => ({
  type: LOAD_MAPPING_DATA_SUCCESS,
  loading: false,
  loaded: true,
  error: false,
  mappingsData,
  totalRecords,
});

const SET_GLOBAL_CONFIG = `${PRESET_STRING}/SET_GLOBAL_CONFIG`;
const setInitConfig = (globalConfig, pageSize) => ({
  type: SET_GLOBAL_CONFIG,
  ...initialState,
  pagination: { pageSize, pageNumber: 1 },
  globalConfig,
});

const UPDATE_REVIEW_STATUS = `${PRESET_STRING}/UPDATE_REVIEW_STATUS`;
const updateReviewStatus = (recordIndices, isReviewed) => ({
  type: UPDATE_REVIEW_STATUS,
  recordIndices,
  isReviewed,
});

const UPDATE_MANUAL_MAPPINGS = `${PRESET_STRING}/UPDATE_MANUAL_MAPPINGS`;
const updateManualMappings = (recordIndices, mapping) => ({
  type: UPDATE_MANUAL_MAPPINGS,
  recordIndices,
  mapping,
});

const SET_MAPPING_DATA_OVERRIDES = `${PRESET_STRING}/SET_MAPPING_DATA_OVERRIDES`;
const setMappingData = (indices, overrides) => ({
  type: SET_MAPPING_DATA_OVERRIDES,
  indices,
  overrides,
});

const ON_RECORD_SELECTION_CHANGE = `${PRESET_STRING}/ON_RECORD_SELECTION_CHANGE`;
const onRecordSelectionChange = (index) => ({
  type: ON_RECORD_SELECTION_CHANGE,
  index,
});

const ON_ALL_SELECTED_CHANGE = `${PRESET_STRING}/ON_ALL_SELECTED_CHANGE`;
const onAllSelectedChange = (value) => ({
  type: ON_ALL_SELECTED_CHANGE,
  value,
});

const SET_MAPPING_COLUMNS = `${PRESET_STRING}/SET_MAPPING_COLUMNS`;
const setMappingColumns = (mappingColumns) => ({
  type: SET_MAPPING_COLUMNS,
  mappingColumns,
});

const SET_PAGE_INFO = `${PRESET_STRING}/SET_PAGE_INFO`;

const setPageInfo = (pageNumber, pageSize) => ({
  type: SET_PAGE_INFO,
  pagination: {
    pageNumber,
    pageSize,
  },
});

const SET_SORT_INFO = `${PRESET_STRING}/SET_SORT_INFO`;

const setSortInfo = (sortAttr, sortOrder) => ({
  type: SET_SORT_INFO,
  sort: { sortAttr, sortOrder },
});

function reviewMappingReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_MAPPING_DATA_REQUEST:
    case LOAD_MAPPING_DATA_FAILURE:
    case LOAD_MAPPING_DATA_SUCCESS:
    case SET_MAPPING_COLUMNS:
    case SET_PAGE_INFO:
    case SET_SORT_INFO: {
      const payload = omit(action, 'type');
      return { ...state, ...payload };
    }
    case SET_GLOBAL_CONFIG: {
      const payload = omit(action, 'type');
      return { ...state, ...payload };
    }
    case ON_RECORD_SELECTION_CHANGE: {
      const index = get(action, 'index');
      return update(state, {
        mappingsData: {
          [index]: { $toggle: ['checked'] },
        },
      });
    }
    case ON_ALL_SELECTED_CHANGE: {
      const value = get(action, 'value');
      const mappingsData = get(state, 'mappingsData');

      return {
        ...state,
        mappingsData: map(mappingsData, (m) => ({ ...m, checked: value })),
      };
    }
    case SET_MAPPING_DATA_OVERRIDES: {
      const { indices, overrides } = action;
      const updates = {};
      // eslint-disable-next-line no-return-assign
      forEach(indices, (index) => (updates[index] = { $merge: overrides }));

      return update(state, {
        mappingsData: updates,
      });
    }
    case UPDATE_REVIEW_STATUS:
    case UPDATE_MANUAL_MAPPINGS: {
      const { recordIndices } = action;
      const updates = {};
      forEach(
        recordIndices,
        // eslint-disable-next-line no-return-assign
        (index) => (updates[index] = { $merge: { updating: true } })
      );

      return update(state, {
        mappingsData: updates,
      });
    }
    default:
      return state;
  }
}

export const actionTypes = {
  LOAD_MAPPING_DATA_FAILURE,
  LOAD_MAPPING_DATA_REQUEST,
  LOAD_MAPPING_DATA_SUCCESS,
  UPDATE_REVIEW_STATUS,
  UPDATE_MANUAL_MAPPINGS,
  SET_GLOBAL_CONFIG,
};

export const actions = {
  loadMappingData,
  loadMappingDataFailure,
  loadMappingDataSuccess,
  updateReviewStatus,
  setInitConfig,
  setMappingColumns,
  onRecordSelectionChange,
  onAllSelectedChange,
  setPageInfo,
  setSortInfo,
  updateManualMappings,
  setMappingData,
};

export default combineReducers({
  reviewData: reviewMappingReducer,
  [loadRuleNamesStateGenerator.stateKey]: loadRuleNamesStateGenerator.reducer,
  [loadOverrideUserStateGenerator.stateKey]: loadOverrideUserStateGenerator.reducer,
  [downloadJobsStateGenerator.stateKey]: downloadJobsStateGenerator.reducer,
});
