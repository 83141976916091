import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'sandbox',
    name: 'CRM Sandbox V2',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const sagas = require('./sagas');
          const reducer = require('./modules');
          injectReducer('crm_sandbox_v2', reducer.default);
          injectSagas('crm_sandbox_v2', sagas.default);

          const formWizardReducer = require('../../components/FormWizard2/modules');
          injectReducer('SettingsFormWizard', formWizardReducer.default);
          const dataSyncsReducer = require('../../components/DataSyncs/modules');
          injectReducer('DataSyncs', dataSyncsReducer.default);
          cb(null, containers.CRMFormContainer);
        },
        'crm_sandbox_v2',
      );
    },
  };
}
