import * as ast from '../ast';
import Immutable from 'immutable';

const toAST = (expr) => {
  // Convert api response to js AST. Express anyone?
  const type = expr.type.toLowerCase();

  switch (type) {
    case 'varexpr':
      return new ast.VarExpr(expr.name);
    case 'const':
    case 'intconst':
    case 'boolconst':
      return new ast.Const(expr.value);
    case 'nullexpr':
      return new ast.NullExpr();
    case 'notop':
      return new ast.NotOp(toAST(expr.expr));
    case 'func':
      return new ast.Func(expr.name, Immutable.List(expr.exprs.map((e) => toAST(e))));
    case 'scanop':
      return new ast.ScanOp(expr.name);
    case 'joinop':
      return new ast.JoinOp(toAST(expr.left), toAST(expr.right), toAST(expr.bool_op));
    case 'leftjoinop':
      return new ast.LeftJoinOp(toAST(expr.left), toAST(expr.right), toAST(expr.bool_op));
    case 'unionallop':
      return new ast.UnionAllOp(toAST(expr.left), toAST(expr.right));
    case 'aliasexpr':
      return new ast.AliasExpr(toAST(expr.expr), expr.alias);
    case 'projectop':
      return new ast.ProjectOp(
        toAST(expr.relation),
        Immutable.List(expr.exprs.map((e) => toAST(e)))
      );
    case 'selectionop':
      return new ast.SelectionOp(toAST(expr.relation), toAST(expr.bool_op));
    case 'betweenop':
      return new ast.BetweenOp(toAST(expr.expr), toAST(expr.ge_op), toAST(expr.le_op));
    case 'cast':
      return new ast.Cast(toAST(expr.expr), expr.cast_type);
    case 'inop':
      return new ast.InOp(toAST(expr.expr), Immutable.List(expr.exprs.map((e) => toAST(e))));
    case 'notinop':
      return new ast.NotInOp(toAST(expr.expr), Immutable.List(expr.exprs.map((e) => toAST(e))));
    case 'casewhen':
      return new ast.CaseWhen(
        Immutable.List(
          expr.conditions.map(
            (cond) =>
              new ast.Condition({
                when: toAST(cond.when),
                then: toAST(cond.then),
              })
          )
        ),
        expr.default ? toAST(expr.default) : null
      );
    default: {
      const AstClass = ast[expr.type];

      if (ast[expr.type]) {
        return new AstClass(toAST(expr.lhs), toAST(expr.rhs));
      }

      throw new Error(`No match for ${expr.type}`);
    }
  }
};

export default toAST;
