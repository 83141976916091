import React from 'react';
import { Grid as GridWithType } from '@sixsense/core/components';
import { appsListSelector } from '../../../selectors';
import { MultiRoleDropdownComponent } from './MultiRoleDropdown';
import { useSelector } from '@sixsense/core/versioned/react-redux';
import { classNames } from 'utils/utils';
import styles from './AppsMultiRolesDropdown.module.scss';
import PropTypes from 'prop-types';
import { DUMMY_SELECT_VALUE } from '../../../constants';

const Grid = GridWithType;

export const AppsMultiRolesDropdownComponent = ({
  moreOptions = [],
  isSelectedUserPrimaryAdmin,
  appsDisabledMap,
  appsValueMap,
  appsOnChangeMap,
  colSize = 3,
  wrapperRef,
  getRoleTitle = (sn) => sn,
  includeAdmin,
  isNoRoleAssign,
  isSelectRole,
  noRoleAssignValue = -1,
  selectRoleValue = DUMMY_SELECT_VALUE,
}) => {
  const appsList = useSelector(appsListSelector);
  return (
    <Grid
      templateColumns={Array(colSize).fill()
      .map(() => '1fr')
      .join(' ')} columnGap={16} rowGap={16}
    >
      {appsList.map(({ id: appId, shortName, longName }, index) => (
        <Grid.Cell
          row={Math.floor(index / colSize + 1)}
          column={(index % colSize) + 1}
        >
          <Grid templateColumns="1fr" rowGap={8}>
            <Grid.Cell
              row="1"
              column="1"
              className={classNames(styles.normalText)}
            >
              {getRoleTitle(shortName, longName)}
            </Grid.Cell>
            <Grid.Cell row="2" column="1">
              <MultiRoleDropdownComponent
                disabled={appsDisabledMap[appId]}
                onChange={(val) => appsOnChangeMap[appId](val, appId)}
                value={appsValueMap[appId]}
                wrapperRef={wrapperRef}
                appId={appId}
                isSelectedUserPrimaryAdmin={isSelectedUserPrimaryAdmin}
                moreOptions={moreOptions}
                includeAdmin={includeAdmin}
                isNoRoleAssign={isNoRoleAssign}
                isSelectRole={isSelectRole}
                noRoleAssignValue={noRoleAssignValue}
                selectRoleValue={selectRoleValue}
              />
            </Grid.Cell>
          </Grid>
        </Grid.Cell>
      ))}
    </Grid>
  );
};

AppsMultiRolesDropdownComponent.propTypes = {
  moreOptions: PropTypes.any,
  isSelectedUserPrimaryAdmin: PropTypes.bool,
  appsDisabledMap: PropTypes.any,
  appsValueMap: PropTypes.any,
  appsOnChangeMap: PropTypes.any,
  colSize: PropTypes.number,
  wrapperRef: PropTypes.any,
  getRoleTitle: PropTypes.func,
  includeAdmin: PropTypes.any,
  isNoRoleAssign: PropTypes.bool,
  isSelectRole: PropTypes.bool,
  noRoleAssignValue: PropTypes.any,
  selectRoleValue: PropTypes.any,
};
