import React from 'react';
import Text from 'v2-components/Text';
import { formatDate } from 'utils/utils';
import { DATE_FORMAT } from 'utils/constants';
import { NA } from './common/NA';
import { CAMPAIGN_SOURCE } from '../../../constants';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';

export const END_DATE = {
  sortByKey: 'end_date',
  key: 'end-date',
  csvColumnKey: 'end_date',
  title: <Label name={CAMPAIGN_LABELS.endDate} />,
  dataIndex: 'end_date',
  width: 130,
  render: (endDate, campaign) => {
    const noEndDate = campaign.is_always_on === true ? 'No end date' : '-';
    if (campaign.campaign_source === CAMPAIGN_SOURCE.EXTERNAL) {
      return (<NA />);
    }

    return (<Text
      type={Text.TYPE.SUBBODY}
    >
      {endDate ? formatDate(DATE_FORMAT)(endDate) : noEndDate}
    </Text>);
  },
};
