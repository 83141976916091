import { connect } from "react-redux";
import { BID_TYPE_KEY } from "../constants";
import { campaignLabelsStateGenerator } from "../stateGenerators";

const Label = ({ labels, name }) => labels[name] || name;

const stateToProps = (state) => ({
  labels: campaignLabelsStateGenerator.dataSelector(state),
});
export default connect(stateToProps)(Label);

export const CAMPAIGN_LABELS = {
  segmentName: "segment_name",
  campaignStatus: "status",
  campaignSpend: "spend",
  accountsNewlyEngaged: "accounts_newly_engaged",
  accountsReached: "account_reached",
  accountsWithIncreasedEngagement: "accounts_with_increased_engagement",
  accountCtr: "account_ctr",
  campaignSubtype: "campaign_subtype",
  audienceReached: "audience_reached",
  avgIncreaseInAccountEngagement: "avg_increase_in_account_engagement",
  accountVtr: "account_vtr",
  bidType: BID_TYPE_KEY,
  budget: "budget",
  campaignPerformance: "campaign_performance",
  campaignType: "campaign_type",
  clicks: "clicks",
  cpc: "cpc",
  createdBy: "created_by",
  ctr: "ctr",
  cpm: "cpm",
  endDate: "end_date",
  externalId: "external_id",
  impressions: "impressions",
  influencedFormFills: "influenced_form_fills",
  lastUpdated: "last_updated",
  linkedinCampaignId: "linkedin_campaign_id",
  matchRate: "match_rate",
  adProviderName: "ad_provider_name",
  startDate: "start_date",
  totalClicks: "total_clicks",
  totalImpressions: "total_impressions",
  userViewThrough: "user_view_through",
  vtr: "vtr",
  archivedOn: "archived_on",
  archivedBy: "archived_by",
  createdOn: "created_on",
  maxDailySpend: "daily_spend_amount",
  frequencyCap: "frequency_cap",
  viewabilityPercent: "viewability_percent",
  purchaseOrder: "purchase_order",
  product: "product",
};
