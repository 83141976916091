// todo: this file needs some cleanup!
// split into subreducers in the sam pattern we have for creation.
import { omit, isEmpty, invert } from 'lodash';
import {
  quickLookInitialState,
  quickLookActions,
  quickLookActionTypes,
  quickLookReducer,
  aiHistoryInitialState,
  aiHistoryActions,
  aiHistoryActionTypes,
  aiHistoryReducer,
} from './subreducers';
import { STATUS_STATE } from 'utils/constants';

const quickLookActionStrings = invert(quickLookActionTypes);
const aiHistoryActionStrings = invert(aiHistoryActionTypes);

export const localStorageOrchFilterKey = '_6s_orchestrationFilterCategory';

const commonStateObject = {
  loading: false,
  loaded: true,
  error: false,
};

const histroryCountResetObject = {
  eligible_records_count: 0,
  skipped_records_count: 0,
  enriched_records_count: 0,
  failed_records_count: 0,
  accounts_in_segment: 0,
  accounts_processed: 0,
  contacts_pushed: 0,
  contacts_with_errors: 0,
  contacts_purchased: 0,
  account_created_records_count: 0,
  lead_converted_records_count: 0,
};

const LOAD = 'ORCHESTRATIONS/LOAD';
function load() {
  return { type: LOAD };
}

const SET_SORTBY = 'ORCHESTRATION/LIST/SET_SORT';
function setSortBy(sortBy) { return { type: SET_SORTBY, sortBy }; }

const BACK_FROM_VIEW_ORCHESTRATION_HISTORY = 'ORCHESTRATIONS/BACK_FROM_VIEW_ORCHESTRATION_HISTORY';
function backFromOrchestrationHistory() {
  return { type: BACK_FROM_VIEW_ORCHESTRATION_HISTORY };
}

const VIEW_ORCHESTRATION_HISTORY = 'ORCHESTRATIONS/VIEW_ORCHESTRATION_HISTORY';
const VIEW_ORCHESTRATION_HISTORY_SUCCESS = 'ORCHESTRATIONS/VIEW_ORCHESTRATION_HISTORY_SUCCESS';
const VIEW_ORCHESTRATION_HISTORY_FAILURE = 'ORCHESTRATIONS/VIEW_ORCHESTRATION_HISTORY_FAILURE';
function viewOrchestrationHistory(id, subActionType) {
  return { type: VIEW_ORCHESTRATION_HISTORY, id, subActionType, loading: true };
}

function viewOrchestrationHistorySuccess(id, historyJobData, dateIndex) {
  return {
    type: VIEW_ORCHESTRATION_HISTORY_SUCCESS,
    ...commonStateObject,
    isHistory: true,
    id,
    historyJobData,
    dateIndex,
  };
}

function viewOrchestrationHistoryFailure(errMessage, errStatus) {
  return {
    type: VIEW_ORCHESTRATION_HISTORY_FAILURE,
    ...STATUS_STATE.FAIL,
    blockLevelLoading: false,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}

const GET_HISTORY_DATA_BY_RUN_ID = 'ORCHESTRATIONS/GET_HISTORY_DATA_BY_RUN_ID';
const GET_HISTORY_DATA_BY_RUN_ID_SUCEESS = 'ORCHESTRATIONS/GET_HISTORY_DATA_BY_RUN_ID_SUCEESS';
const GET_HISTORY_DATA_BY_RUN_ID_FAILURE = 'ORCHESTRATIONS/GET_HISTORY_DATA_BY_RUN_ID_FAILURE';

function getHistoryDataByRunId(pageSize, pageNumber, fromPagination) {
  let blockLevelData = {};
  if (fromPagination) {
    blockLevelData = {
      blockLevelLoading: true,
    };
  }
  return { type: GET_HISTORY_DATA_BY_RUN_ID, pageSize, pageNumber, ...blockLevelData };
}

function getHistoryDataByRunIdSuccess(histroryDataByRunId, currPage) {
  return {
    ...commonStateObject,
    blockLevelLoading: false,
    type: GET_HISTORY_DATA_BY_RUN_ID_SUCEESS,
    histroryDataByRunId,
    currPage,
  };
}

function getHistoryDataByRunIdFailure(errMessage, errStatus) {
  return {
    type: GET_HISTORY_DATA_BY_RUN_ID_FAILURE,
    ...STATUS_STATE.FAIL,
    errorStatus: errStatus,
    errorMessage: errMessage,
    blockLevelLoading: false,
  };
}

const GET_ORCH_ERROR_BY_RUN_ID = 'ORCHESTRATIONS/GET_ORCH_ERROR_BY_RUN_ID';
const GET_ORCH_ERROR_BY_RUN_ID_SUCEESS = 'ORCHESTRATIONS/GET_ORCH_ERROR_BY_RUN_ID_SUCEESS';
const GET_ORCH_ERROR_BY_RUN_ID_FAILURE = 'ORCHESTRATIONS/GET_ORCH_ERROR_BY_RUN_ID_FAILURE';

function getOrchErrorByRunId() {
  return { type: GET_ORCH_ERROR_BY_RUN_ID, blockLevelLoading: true };
}

function getOrchErrorByRunIdSuccess(orchErrorByRunId) {
  let orcheErrorLoading = true;
  if (!isEmpty(orchErrorByRunId)) {
    orcheErrorLoading = false;
  }
  return {
    type: GET_ORCH_ERROR_BY_RUN_ID_SUCEESS,
    orchErrorByRunId,
    blockLevelLoading: orcheErrorLoading,
  };
}

function getOrchErrorByRunIdFailure(errMessage, errStatus) {
  return {
    type: GET_ORCH_ERROR_BY_RUN_ID_FAILURE,
    ...STATUS_STATE.FAIL,
    blockLevelLoading: false,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}

const GET_HISTORY_COUNT_BY_RUN_ID = 'ORCHESTRATIONS/GET_HISTORY_COUNT_BY_RUN_ID';
const GET_HISTORY_COUNT_BY_RUN_ID_SUCEESS = 'ORCHESTRATIONS/GET_HISTORY_COUNT_BY_RUN_ID_SUCEESS';
const GET_HISTORY_COUNT_BY_RUN_ID_FAILURE = 'ORCHESTRATIONS/GET_HISTORY_COUNT_BY_RUN_ID_FAILURE';

function getHistoryCountByRunId() {
  return { type: GET_HISTORY_COUNT_BY_RUN_ID };
}

function getHistoryCountByRunIdSuccess(histroryCountByRunId) {
  return {
    type: GET_HISTORY_COUNT_BY_RUN_ID_SUCEESS,
    histroryCountByRunId,
  };
}

function getHistoryCountByRunIdFailure(errMessage, errStatus) {
  return {
    type: GET_HISTORY_COUNT_BY_RUN_ID_FAILURE,
    ...STATUS_STATE.FAIL,
    blockLevelLoading: false,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}

const ORCHESTRATION_HISTORY_DETAIL_MODAL_OPEN = 'ORCHESTRATIONS/HISTORY/DETAIL_MODAL_OPEN';
function onModalOpen(historyItemIndex, modalDataUsingAPI) {
  const { ispushAccountModalData } = modalDataUsingAPI;
  const modalObject = {
    type: ORCHESTRATION_HISTORY_DETAIL_MODAL_OPEN,
    isHistoryDetailModal: true,
    historyItemIndex,
    modalDataUsingAPI,
  };
  if (ispushAccountModalData) {
    modalObject.modalLoading = true;
  }
  return modalObject;
}

const ORCHESTRATION_HISTORY_DETAIL_MODAL_CLOSE = 'ORCHESTRATIONS/HISTORY/CLOSE_DETAIL_MODAL';
function onModalDismiss() {
  return {
    type: ORCHESTRATION_HISTORY_DETAIL_MODAL_CLOSE,
    isHistoryDetailModal: false,
    historyItemIndex: null,
    modalDataUsingAPI: {},
    modalLoading: false,
  };
}

const ORCHESTRATION_HISTORY_DETAIL_MODAL_OPEN_SUCCESS =
  'ORCHESTRATIONS/HISTORY/DETAIL_MODAL_OPEN_SUCCESS';
function onModalOpenSuccess(modalDataUsingAPI) {
  return {
    type: ORCHESTRATION_HISTORY_DETAIL_MODAL_OPEN_SUCCESS,
    modalDataUsingAPI,
    modalLoading: false,
  };
}

const ORCHESTRATION_HISTORY_DETAIL_MODAL_OPEN_FAILURE =
  'ORCHESTRATION_HISTORY_DETAIL_MODAL_OPEN_FAILURE';
function onModalOpenFailure() {
  return {
    type: ORCHESTRATION_HISTORY_DETAIL_MODAL_OPEN_FAILURE,
    modalLoading: false,
  };
}

const HISTORY_UPDATE_SUBACTION_DATA = 'ORCHESTRATIONS/HISTORY/UPDATE_SUBACTION_DATA';
function updateSubActionData(subActionType) {
  return {
    type: HISTORY_UPDATE_SUBACTION_DATA,
    blockLevelLoading: true,
    currPage: 1,
    subActionType,
  };
}
const LOADING_HISTORY_CUSTOM = 'ORCHESTRATION/HISTORY/SET_LOADING';
const setHistoryLoading = (loading) => ({ type: LOADING_HISTORY_CUSTOM, loading });

const HISTORY_UPDATE_DATE_INDEX = 'ORCHESTRATIONS/HISTORY/HISTORY_UPDATE_DATE_INDEX';
function updateDateIndex(dateIndex, subActionType) {
  const objectToReturn = {
    type: HISTORY_UPDATE_DATE_INDEX,
    dateIndex,
    blockLevelLoading: true,
    currPage: 1,
    orchErrorByRunId: [],
    histroryCountByRunId: histroryCountResetObject,
  };
  if (subActionType) {
    objectToReturn.subActionType = subActionType;
  }
  return objectToReturn;
}


const DELETE_ORCHESTRATION = 'ORCHESTRATIONS/DELETE_ORCHESTRATION';
const DELETE_ORCHESTRATION_SUCCESS = 'ORCHESTRATIONS/DELETE_ORCHESTRATION_SUCCESS';
const DELETE_ORCHESTRATION_FAILURE = 'ORCHESTRATIONS/DELETE_ORCHESTRATION_FAILURE';

function deleteOrchestration(id) {
  return { type: DELETE_ORCHESTRATION, loading: true, id };
}
function deleteOrchestrationSuccess(id) {
  return {
    type: DELETE_ORCHESTRATION_SUCCESS,
    ...commonStateObject,
    id,
  };
}
function deleteOrchestrationFailure(errMessage, errStatus) {
  return {
    type: DELETE_ORCHESTRATION_FAILURE,
    ...STATUS_STATE.FAIL,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}


const ENABLE_EXPORT_SCORES_ORCHESTRATION = 'ORCHESTRATIONS/ENABLE_EXPORT_SCORES_ORCHESTRATION';
function enableExportScoresOrchestration(id, shouldEnable) {
  return { type: ENABLE_EXPORT_SCORES_ORCHESTRATION, loading: true, id, shouldEnable };
}

const ENABLE_EXPORT_SCORES_ORCHESTRATION_SUCCESS =
  'ORCHESTRATIONS/ENABLE_EXPORT_SCORES_ORCHESTRATION_SUCCESS';
function enableExportScoresOrchestrationSuccess(id, shouldEnable) {
  return {
    type: ENABLE_EXPORT_SCORES_ORCHESTRATION_SUCCESS,
    ...commonStateObject,
    id,
    shouldEnable,
  };
}

const ENABLE_EXPORT_SCORES_ORCHESTRATION_FAILURE =
  'ORCHESTRATIONS/ENABLE_EXPORT_SCORES_ORCHESTRATION_FAILURE';
function enableExportScoresOrchestrationFailure(errMessage, errStatus) {
  return {
    type: ENABLE_EXPORT_SCORES_ORCHESTRATION_FAILURE,
    ...STATUS_STATE.FAIL,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}


const DOWNLOAD_CSV_HISTORY = 'ORCHESTRATIONS/HISTORY/DOWNLOAD_CSV';
function downloadCSVForHistory(fileName, previewType, metaObject) {
  return {
    type: DOWNLOAD_CSV_HISTORY,
    downloading: true,
    fileName,
    previewType,
    metaObject,
  };
}

const DOWNLOAD_CSV_HISTORY_SUCCESS =
  'ORCHESTRATIONS/HISTORY/DOWNLOAD_CSV_HISTORY_SUCCESS';
function downloadCSVForHistorySuccess() {
  return {
    type: DOWNLOAD_CSV_HISTORY_SUCCESS,
    downloading: false,
    error: false,
  };
}

const DOWNLOAD_CSV_HISTORY_FAILURE =
  'ORCHESTRATIONS/HISTORY/DOWNLOAD_CSV_HISTORY_FAILURE';
function downloadCSVForHistoryFailure(errMessage, errStatus) {
  return {
    type: DOWNLOAD_CSV_HISTORY_FAILURE,
    downloading: false,
    ...STATUS_STATE.FAIL,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}

const VIEW_ORCHESTRATION_HISTORY_SUMMARY =
  'ORCHESTRATIONS/SUMMARY/VIEW_ORCHESTRATION_HISTORY_SUMMARY';
function viewOrchestrationHistorySummary(id, subActionType) {
  return { type: VIEW_ORCHESTRATION_HISTORY_SUMMARY, id, subActionType };
}

const VIEW_PAGINATED_SUMMARY =
  'ORCHESTRATIONS/SUMMARY/VIEW_PAGINATED_SUMMARY';
function viewPaginatedSummary() {
  // don't know what's loading doing here. need to look at it
  return { type: VIEW_PAGINATED_SUMMARY, paginateLoading: true };
}

const RESET_SUMMARY_PAGINATE =
  'ORCHESTRATIONS/SUMMARY/RESET_SUMMARY_PAGINATE';
function resetSummaryPaginate() {
  return { type: RESET_SUMMARY_PAGINATE, paginateLoading: false };
}

const LOAD_SUMMARY_PAGINATE_REQUEST =
  'ORCHESTRATIONS/SUMMARY/LOAD_SUMMARY_PAGINATE_REQUEST';
function loadSummaryPaginateRequest(pageSize, currentPage) {
  const object = {
    type: LOAD_SUMMARY_PAGINATE_REQUEST,
    paginateLoading: true,
  };

  // Added this becuase if we don't pass the pageSize and currentPage
  // Then we can use the current size and page to reload the summary again
  if (pageSize && currentPage) {
    object.pageSize = pageSize;
    object.currentPage = currentPage;
    object.reprocessedJobDetails = {};
  }

  return object;
}

const LOAD_SUMMARY_PAGINATE_SUCCESS = 'ORCHESTRATIONS/SUMMARY/LOAD_SUMMARY_PAGINATE_SUCCESS';
function loadSummaryPaginateSuccess(pageData) {
  return {
    type: LOAD_SUMMARY_PAGINATE_SUCCESS,
    ...commonStateObject,
    pageData,
    paginateLoading: false,
  };
}

const LOAD_SUMMARY_PAGINATE_FAILURE = 'ORCHESTRATIONS/SUMMARY/LOAD_SUMMARY_PAGINATE_FAILURE';
function loadSummaryPaginateFailure(errMessage, errStatus) {
  return {
    type: LOAD_SUMMARY_PAGINATE_FAILURE,
    ...STATUS_STATE.FAIL,
    paginateLoading: false,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}

const LOAD_JOBS_COUNT_SUCCESS = 'ORCHESTRATIONS/SUMMARY/LOAD_JOBS_COUNT_SUCCESS';
function loadJobsCountSuccess(totalJobsCount) {
  const objectToReturn = {
    type: LOAD_JOBS_COUNT_SUCCESS,
    ...commonStateObject,
    totalJobsCount,
  };
  // Added this case if the summary count is 0 then hide the loader
  if (totalJobsCount?.total_count === 0) {
    objectToReturn.paginateLoading = false;
  }
  return objectToReturn;
}

const LOAD_JOBS_COUNT_FAILURE = 'ORCHESTRATIONS/SUMMARY/LOAD_JOBS_COUNT_FAILURE';
function loadJobsCountFailure(errMessage, errStatus) {
  return {
    type: LOAD_JOBS_COUNT_FAILURE,
    ...STATUS_STATE.FAIL,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}

const DOWNLOAD_SUMMARY = 'ORCHESTRATIONS/SUMMARY/DOWNLOAD_CSV_SUMMARY';
function downloadCSVForSummary(fileName, summaryType, csvMeta) {
  return { type: DOWNLOAD_SUMMARY, csvDownloadLoading: true, fileName, summaryType, csvMeta };
}

const DOWNLOAD_SUMMARY_SUCCESS =
  'ORCHESTRATIONS/SUMMARY/DOWNLOAD_SUMMARY_SUCCESS';
function downloadCSVForSummarySuccess() {
  return {
    type: DOWNLOAD_SUMMARY_SUCCESS,
    csvDownloadLoading: false,
    error: false,
  };
}

const DOWNLOAD_SUMMARY_FAILURE =
  'ORCHESTRATIONS/SUMMARY/DOWNLOAD_SUMMARY_FAILURE';
function downloadCSVForSummaryFailure(errMessage, errStatus) {
  return {
    type: DOWNLOAD_SUMMARY_FAILURE,
    csvDownloadLoading: false,
    error: true,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}

const SWITCH_TO_HISTORY = 'ORCHESTRATIONS/SUMMARY/SWITCH_TO_HISTORY';
function switchToHistory({ historyType = 'default', errorCategory = '', orchestrationId } = {}) {
  const objectToReturn = {
    type: SWITCH_TO_HISTORY,
    isHistory: true,
    isSummary: false,
  };

  // just pass these as undefined?
  if (historyType) {
    objectToReturn.historyType = historyType;
  }
  if (errorCategory) {
    objectToReturn.errorCategory = errorCategory;
  }
  if (orchestrationId) {
    objectToReturn.id = orchestrationId;
  }
  return objectToReturn;
}

const SWITCH_TO_SUMMARY = 'ORCHESTRATIONS/SUMMARY/SWITCH_TO_SUMMARY';
function switchToSummary() {
  const objectToReturn = {
    type: SWITCH_TO_SUMMARY,
    isHistory: false,
    isSummary: true,
  };
  return objectToReturn;
}

const LOAD_ALL_JOBS = 'ORCHESTRATIONS/HISTORY/LOAD_ALL_JOBS';
function loadAllJobs(sp_id) {
  const objectToReturn = {
    type: LOAD_ALL_JOBS,
  };
  if (sp_id) {
    objectToReturn.id = sp_id;
  }
  return objectToReturn;
}

const LOADING_ALL_JOBS = 'ORCHESTRATIONS/HISTORY/LOADING_ALL_JOBS';
function loadingAllJobs() {
  return {
    type: LOADING_ALL_JOBS,
    jobsLoading: true,
  };
}

const LOAD_ALL_JOBS_SUCCESS =
  'ORCHESTRATIONS/HISTORY/LOAD_ALL_JOBS_SUCCESS';
function loadAllJobsSuccess(historyJobData) {
  return {
    type: LOAD_ALL_JOBS_SUCCESS,
    jobsLoading: false,
    historyJobData,
    error: false,
  };
}

const LOAD_ALL_JOBS_FAILURE =
  'ORCHESTRATIONS/HISTORY/LOAD_ALL_JOBS_FAILURE';
function loadAllJobsFailure(errMessage, errStatus) {
  return {
    type: LOAD_ALL_JOBS_FAILURE,
    jobsLoading: false,
    error: true,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}

// error logs summary
const VIEW_ERROR_LOGS =
  'ORCHESTRATIONS/SUMMARY/VIEW_ERROR_LOGS';
function viewErrorLogs() {
  return { type: VIEW_ERROR_LOGS, errorLogsLoading: true };
}

const LOAD_ERROR_LOGS_PAGINATE_REQUEST =
  'ORCHESTRATIONS/SUMMARY/LOAD_ERROR_LOGS_PAGINATE_REQUEST';
function loadErrorLogsPaginateRequest(errorLogsPageSize, errorLogsCurrentPage) {
  return {
    type: LOAD_ERROR_LOGS_PAGINATE_REQUEST,
    errorLogsLoading: true,
    errorLogsPageSize,
    errorLogsCurrentPage,
  };
}

const LOAD_ERROR_LOGS_PAGINATE_SUCCESS = 'ORCHESTRATIONS/SUMMARY/LOAD_ERROR_LOGS_PAGINATE_SUCCESS';
function loadErrorLogsPaginateSuccess(errorLogsData) {
  return {
    type: LOAD_ERROR_LOGS_PAGINATE_SUCCESS,
    ...commonStateObject,
    errorLogsData,
    errorLogsLoading: false,
  };
}

const LOAD_ERROR_LOGS_PAGINATE_FAILURE = 'ORCHESTRATIONS/SUMMARY/LOAD_ERROR_LOGS_PAGINATE_FAILURE';
function loadErrorLogsPaginateFailure(errMessage, errStatus) {
  return {
    type: LOAD_ERROR_LOGS_PAGINATE_FAILURE,
    ...STATUS_STATE.FAIL,
    errorLogsLoading: false,
    logsErrorStatus: errStatus,
    logsErrorMessage: errMessage,
  };
}


const VIEW_HISTORY_ERROR_LOG =
  'ORCHESTRATIONS/HISTORY/VIEW_HISTORY_ERROR_LOG';
function viewHistoryForErrorLog() {
  return { type: VIEW_HISTORY_ERROR_LOG, errorLogHistoryLoading: true };
}

const LOAD_ERROR_LOG_HISTORY_PAGINATE_REQUEST =
  'ORCHESTRATIONS/HISTORY/LOAD_ERROR_LOG_HISTORY_PAGINATE_REQUEST';
function loadErrorLogHistoryPaginateRequest(errorLogHistoryPageSize, errorLogHistoryCurrentPage) {
  return {
    type: LOAD_ERROR_LOG_HISTORY_PAGINATE_REQUEST,
    errorLogHistoryLoading: true,
    errorLogHistoryPageSize,
    errorLogHistoryCurrentPage,
  };
}

const LOAD_ERROR_LOG_HISTORY_PAGINATE_SUCCESS =
  'ORCHESTRATIONS/HISTORY/LOAD_ERROR_LOG_HISTORY_PAGINATE_SUCCESS';
function loadErrorLogHistoryPaginateSuccess(errorLogHistoryData) {
  return {
    type: LOAD_ERROR_LOG_HISTORY_PAGINATE_SUCCESS,
    ...commonStateObject,
    errorLogHistoryData,
    errorLogHistoryLoading: false,
  };
}

const LOAD_ERROR_LOG_HISTORY_PAGINATE_FAILURE =
  'ORCHESTRATIONS/HISTORY/LOAD_ERROR_LOG_HISTORY_PAGINATE_FAILURE';
function loadErrorLogHistoryPaginateFailure(errMessage, errStatus) {
  return {
    type: LOAD_ERROR_LOG_HISTORY_PAGINATE_FAILURE,
    ...STATUS_STATE.FAIL,
    errorLogHistoryLoading: false,
    logHistoryErrorStatus: errStatus,
    logHistoryErrorMessage: errMessage,
  };
}

const DOWNLOAD_ERROR_LOG_HISTORY = 'ORCHESTRATIONS/HISTORY/DOWNLOAD_ERROR_LOG_HISTORY';
function downloadCSVForErrorLogHistory(fileName, previewType, metaObject) {
  return {
    type: DOWNLOAD_ERROR_LOG_HISTORY,
    csvErrorLogsHistoryLoading: true,
    fileName,
    previewType,
    metaObject,
  };
}

const DOWNLOAD_ERROR_LOG_HISTORY_SUCCESS =
  'ORCHESTRATIONS/HISTORY/DOWNLOAD_ERROR_LOG_HISTORY_SUCCESS';
function downloadCSVForErrorLogHistorySuccess() {
  return {
    type: DOWNLOAD_ERROR_LOG_HISTORY_SUCCESS,
    csvErrorLogsHistoryLoading: false,
    error: false,
  };
}

const DOWNLOAD_ERROR_LOG_HISTORY_FAILURE =
  'ORCHESTRATIONS/HISTORY/DOWNLOAD_ERROR_LOG_HISTORY_FAILURE';
function downloadCSVForErrorLogHistoryFailure(errMessage, errStatus) {
  return {
    type: DOWNLOAD_ERROR_LOG_HISTORY_FAILURE,
    csvErrorLogsHistoryLoading: false,
    error: true,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}

const REPROCESS_PURCHASE_CONTACTS = 'ORCHESTRATIONS/SUMMARY/REPROCESS_PURCHASE_CONTACTS';
function reprocessPurchaseContacts(summaryApiParams) {
  return { type: REPROCESS_PURCHASE_CONTACTS, paginateLoading: true, summaryApiParams };
}

const REPROCESS_PURCHASE_CONTACTS_SUCCESS =
  'ORCHESTRATIONS/SUMMARY/REPROCESS_PURCHASE_CONTACTS_SUCCESS';
function reprocessPurchaseContactsSuccess(reprocessedJobDetails) {
  return {
    type: REPROCESS_PURCHASE_CONTACTS_SUCCESS,
    paginateLoading: false,
    reprocessedJobDetails,
  };
}

const REPROCESS_PURCHASE_CONTACTS_FAILURE =
  'ORCHESTRATIONS/SUMMARY/REPROCESS_PURCHASE_CONTACTS_FAILURE';
function reprocessPurchaseContactsFailure(errMessage, errStatus) {
  return {
    type: REPROCESS_PURCHASE_CONTACTS_FAILURE,
    paginateLoading: false,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}

const REFRESH = 'ORCHESTRATION/REFRESH';
function refresh() {
  return { type: REFRESH };
}

const defaultStatus = {
  loading: false,
  loaded: false,
  error: false,
  errorStatus: null,
  errorMessage: null,
};
const historyInitialState = {
  downloading: false,
  loading: false,
  loaded: false,
  error: false,
  blockLevelLoading: false,
  errorStatus: null,
  errorMessage: null,
  isHistory: false,
  id: null,
  dateIndex: null,
  isHistoryDetailModal: false,
  historyJobData: [],
  historyItemIndex: null,
  modalDataUsingAPI: {},
  modalLoading: false,
  histroryDataByRunId: {},
  histroryCountByRunId: {},
  currPage: 1,
  orchErrorByRunId: [],
  jobsLoading: false,
  historyType: 'default',

  // error logs
  errorCategory: '',
  errorLogHistoryData: {},
  errorLogHistoryLoading: false,
  errorLogHistoryPageSize: undefined,
  errorLogHistoryCurrentPage: undefined,
  csvErrorLogsHistoryLoading: false,
};

const summaryInitialState = {
  ...defaultStatus,
  isSummary: false,
  pageData: [],
  currentPage: undefined,
  pageSize: undefined,
  totalJobsCount: {},
  paginateLoading: false,
  csvDownloadLoading: false,

  // error logs
  errorLogsData: {},
  errorLogsLoading: false,
  errorLogsPageSize: undefined,
  errorLogsCurrentPage: undefined,

  // this will store all the params we need to call APIs on summary page
  // check in SummaryListLayout.js how we use
  summaryApiParams: {},
};

export const initialState = {
  sortBy: 'updatedOn',
  sortOrder: 'desc',
  listFilter: window.localStorage.getItem(localStorageOrchFilterKey) || 'all',
  search: '',
  history: {
    ...historyInitialState,
  },
  summary: {
    ...summaryInitialState,
  },
  ...defaultStatus,

  quickLook: quickLookInitialState,
  aiHistory: aiHistoryInitialState,
};

const SET_ORCH_SEARCH = 'ORCHESTRATIONS/LIST/SET_SEARCH';
const setSearch = (search) => ({ type: SET_ORCH_SEARCH, search });
const SET_ORCH_LIST_FILTER = 'ORCHESTRATIONS/LIST/SET_FILTER';
const setFilter = (listFilter) => {
  window.localStorage.setItem(localStorageOrchFilterKey, listFilter);
  return ({ type: SET_ORCH_LIST_FILTER, listFilter });
};
const BACK_TO_SUMMARY = 'ORCHESTRATION/LIST/BACK_TO_SMMARY';
const backToSummary = () => ({ type: BACK_TO_SUMMARY });

function orchestrationReducer(state = initialState, action) {
  if (action.type in quickLookActionStrings) {
    return {
      ...state,
      quickLook: quickLookReducer(state.quickLook, action),
    };
  }

  if (action.type in aiHistoryActionStrings) {
    return {
      ...state,
      aiHistory: aiHistoryReducer(state.aiHistory, action),
    };
  }

  switch (action.type) {
    case BACK_TO_SUMMARY: {
      const { history, summary } = state;
      return {
        ...state,
        history: { ...history, isHistory: false },
        summary: { ...summary, isSummary: true },
      };
    }
    case SET_ORCH_SEARCH:
    case SET_ORCH_LIST_FILTER:
    case DELETE_ORCHESTRATION:
    case DELETE_ORCHESTRATION_FAILURE:
    case ENABLE_EXPORT_SCORES_ORCHESTRATION:
    case ENABLE_EXPORT_SCORES_ORCHESTRATION_FAILURE: {
      const payload = omit(action, 'type');
      return { ...state, ...payload };
    }
    case SET_SORTBY: {
      const { sortBy } = action;
      const { sortBy: currentSortBy, sortOrder } = state;
      const opposite = { desc: 'asc', asc: 'desc' };
      return {
        ...state,
        sortBy,
        sortOrder: sortBy === currentSortBy ? opposite[sortOrder] : 'desc',
      };
    }
    case DELETE_ORCHESTRATION_SUCCESS: {
      const payload = omit(action, 'type', 'id');
      return {
        ...state,
        ...payload,
      };
    }
    case ENABLE_EXPORT_SCORES_ORCHESTRATION_SUCCESS: {
      const payload = omit(action, 'type', 'id', 'shouldEnable');
      return {
        ...state,
        ...payload,
      };
    }
    case BACK_FROM_VIEW_ORCHESTRATION_HISTORY: {
      const payload = omit(action, 'type');
      return {
        ...state,
        history: {
          ...historyInitialState,
        },
        summary: {
          ...summaryInitialState,
        },
        ...payload,
      };
    }

    case LOADING_HISTORY_CUSTOM:
    case VIEW_ORCHESTRATION_HISTORY:
    case VIEW_ORCHESTRATION_HISTORY_SUCCESS:
    case VIEW_ORCHESTRATION_HISTORY_FAILURE:
    case HISTORY_UPDATE_SUBACTION_DATA:
    case ORCHESTRATION_HISTORY_DETAIL_MODAL_OPEN:
    case ORCHESTRATION_HISTORY_DETAIL_MODAL_CLOSE:
    case HISTORY_UPDATE_DATE_INDEX:
    case GET_HISTORY_DATA_BY_RUN_ID:
    case GET_HISTORY_DATA_BY_RUN_ID_FAILURE:
    case GET_HISTORY_DATA_BY_RUN_ID_SUCEESS:
    case GET_HISTORY_COUNT_BY_RUN_ID:
    case GET_HISTORY_COUNT_BY_RUN_ID_SUCEESS:
    case GET_HISTORY_COUNT_BY_RUN_ID_FAILURE:
    case DOWNLOAD_CSV_HISTORY:
    case DOWNLOAD_CSV_HISTORY_SUCCESS:
    case DOWNLOAD_CSV_HISTORY_FAILURE:
    case GET_ORCH_ERROR_BY_RUN_ID:
    case GET_ORCH_ERROR_BY_RUN_ID_SUCEESS:
    case GET_ORCH_ERROR_BY_RUN_ID_FAILURE:
    case ORCHESTRATION_HISTORY_DETAIL_MODAL_OPEN_SUCCESS:
    case ORCHESTRATION_HISTORY_DETAIL_MODAL_OPEN_FAILURE:
    case LOAD_ALL_JOBS:
    case LOAD_ALL_JOBS_SUCCESS:
    case LOAD_ALL_JOBS_FAILURE:
    case LOADING_ALL_JOBS:
    case VIEW_HISTORY_ERROR_LOG:
    case LOAD_ERROR_LOG_HISTORY_PAGINATE_REQUEST:
    case LOAD_ERROR_LOG_HISTORY_PAGINATE_SUCCESS:
    case LOAD_ERROR_LOG_HISTORY_PAGINATE_FAILURE:
    case DOWNLOAD_ERROR_LOG_HISTORY:
    case DOWNLOAD_ERROR_LOG_HISTORY_SUCCESS:
    case DOWNLOAD_ERROR_LOG_HISTORY_FAILURE: {
      const { history } = state;
      return {
        ...state,
        history: {
          ...history,
          ...omit(action, 'type'),
        },
      };
    }

    // Need to move this to upper module reducer
    case VIEW_ORCHESTRATION_HISTORY_SUMMARY: {
      const { history } = state;
      const { id, subActionType } = action;
      return {
        ...state,
        history: { ...history, id, subActionType },
      };
    }

    case SWITCH_TO_HISTORY:
    case SWITCH_TO_SUMMARY: {
      const { summary, history } = state;
      const { isSummary } = action;
      return {
        ...state,
        history: { ...history, ...omit(action, 'type', 'isSummary') },
        summary: { ...summary, isSummary },
      };
    }

    case LOAD_SUMMARY_PAGINATE_REQUEST:
    case LOAD_SUMMARY_PAGINATE_SUCCESS:
    case LOAD_SUMMARY_PAGINATE_FAILURE:
    case LOAD_JOBS_COUNT_SUCCESS:
    case LOAD_JOBS_COUNT_FAILURE:
    case DOWNLOAD_SUMMARY:
    case DOWNLOAD_SUMMARY_SUCCESS:
    case DOWNLOAD_SUMMARY_FAILURE:
    case VIEW_PAGINATED_SUMMARY:
    case VIEW_ERROR_LOGS:
    case LOAD_ERROR_LOGS_PAGINATE_REQUEST:
    case LOAD_ERROR_LOGS_PAGINATE_SUCCESS:
    case LOAD_ERROR_LOGS_PAGINATE_FAILURE:
    case RESET_SUMMARY_PAGINATE:
    case REPROCESS_PURCHASE_CONTACTS:
    case REPROCESS_PURCHASE_CONTACTS_SUCCESS:
    case REPROCESS_PURCHASE_CONTACTS_FAILURE: {
      const { summary } = state;
      return {
        ...state,
        summary: { ...summary, ...omit(action, 'type') },
      };
    }

    case REFRESH: {
      return {
        ...initialState,
        // When we change the page then the refresh gets called
        // it loads the initial/previous local storage data we stored.
        // so to get the correct data from localstorae added entry here as well.
        // @JOHN let me know if it makes sense.
        listFilter: window.localStorage.getItem(localStorageOrchFilterKey) || 'all',
      };
    }
    default:
      return state;
  }
}

export const actionTypes = {
  LOAD,
  DELETE_ORCHESTRATION,
  ENABLE_EXPORT_SCORES_ORCHESTRATION,
  VIEW_ORCHESTRATION_HISTORY,
  GET_HISTORY_DATA_BY_RUN_ID,
  GET_HISTORY_COUNT_BY_RUN_ID,
  HISTORY_UPDATE_SUBACTION_DATA,
  HISTORY_UPDATE_DATE_INDEX,
  DOWNLOAD_CSV_HISTORY,
  GET_ORCH_ERROR_BY_RUN_ID,
  ORCHESTRATION_HISTORY_DETAIL_MODAL_OPEN,
  VIEW_ORCHESTRATION_HISTORY_SUMMARY,
  LOAD_SUMMARY_PAGINATE_REQUEST,
  DOWNLOAD_SUMMARY,
  LOAD_ALL_JOBS,
  VIEW_PAGINATED_SUMMARY,
  VIEW_ERROR_LOGS,
  LOAD_ERROR_LOGS_PAGINATE_REQUEST,
  VIEW_HISTORY_ERROR_LOG,
  LOAD_ERROR_LOG_HISTORY_PAGINATE_REQUEST,
  DOWNLOAD_ERROR_LOG_HISTORY,
  BACK_TO_SUMMARY,

  ...quickLookActionTypes,
  ...aiHistoryActionTypes,

  REPROCESS_PURCHASE_CONTACTS,
};

export const actions = {
  load,
  setSortBy,
  refresh,
  deleteOrchestration,
  deleteOrchestrationSuccess,
  deleteOrchestrationFailure,
  enableExportScoresOrchestration,
  enableExportScoresOrchestrationSuccess,
  enableExportScoresOrchestrationFailure,
  viewOrchestrationHistory,
  viewOrchestrationHistorySuccess,
  viewOrchestrationHistoryFailure,
  backFromOrchestrationHistory,
  updateSubActionData,
  updateDateIndex,
  onModalOpen,
  onModalDismiss,
  getHistoryDataByRunId,
  getHistoryDataByRunIdSuccess,
  getHistoryDataByRunIdFailure,
  getHistoryCountByRunId,
  getHistoryCountByRunIdSuccess,
  getHistoryCountByRunIdFailure,
  downloadCSVForHistory,
  downloadCSVForHistorySuccess,
  downloadCSVForHistoryFailure,
  getOrchErrorByRunId,
  getOrchErrorByRunIdSuccess,
  getOrchErrorByRunIdFailure,
  setHistoryLoading,
  onModalOpenSuccess,
  onModalOpenFailure,
  setFilter,
  setSearch,

  viewOrchestrationHistorySummary,
  loadSummaryPaginateRequest,
  loadSummaryPaginateSuccess,
  loadSummaryPaginateFailure,
  loadJobsCountSuccess,
  loadJobsCountFailure,
  downloadCSVForSummary,
  downloadCSVForSummarySuccess,
  downloadCSVForSummaryFailure,
  switchToHistory,
  loadAllJobs,
  loadAllJobsSuccess,
  loadAllJobsFailure,
  backToSummary,
  loadingAllJobs,
  viewPaginatedSummary,

  viewErrorLogs,
  loadErrorLogsPaginateRequest,
  loadErrorLogsPaginateSuccess,
  loadErrorLogsPaginateFailure,
  switchToSummary,

  viewHistoryForErrorLog,
  loadErrorLogHistoryPaginateRequest,
  loadErrorLogHistoryPaginateSuccess,
  loadErrorLogHistoryPaginateFailure,
  downloadCSVForErrorLogHistory,
  downloadCSVForErrorLogHistorySuccess,
  downloadCSVForErrorLogHistoryFailure,
  resetSummaryPaginate,

  // quicklook
  ...quickLookActions,

  // ai history
  ...aiHistoryActions,

  reprocessPurchaseContacts,
  reprocessPurchaseContactsSuccess,
  reprocessPurchaseContactsFailure,
};

export default orchestrationReducer;
