import React from 'react';
import {
  Flex,
  Text,
  FeaturedIcon,
  Modal,
  Button,
  Card,
} from '@sixsense/core/components';
import { AlertTriangle } from '@sixsense/core/icons';

export const UnsavedChangesModal = ({
  onClickCloseHandler,
  onClickContinueHandler,
}: any) => {
  const closeHandler = () => {
    onClickCloseHandler();
  };

  const continueHandler = () => {
    onClickContinueHandler();
  };

  return (
    <Modal isOpen width={'512px'}>
      <Card style={{ width: '512px', borderRadius: '8px' }}>
        <Flex direction="column" gap={20} style={{ padding: '24px' }}>
          <FeaturedIcon
            icon={() => <AlertTriangle fill="#A15C07" size="24" />}
            color="Warning"
            size="lg"
          />
          <Flex direction="column" gap={8}>
            <Text.SectionHeading size={18}>
              You have unsaved changes
            </Text.SectionHeading>
            <Text color={'#4B555E'}>
              Leaving this page will discard any changes
            </Text>
          </Flex>

          <div style={{ marginLeft: 'auto', paddingTop: '16px' }}>
            <Button
              hierarchy="secondary"
              onClick={() => continueHandler()}
              size={'large'}
              style={{
                marginRight: '16px',
                borderRadius: '6px',
                height: '36px',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => closeHandler()}
              size={'large'}
              style={{ borderRadius: '6px', height: '36px' }}
            >
              Discard Changes
            </Button>
          </div>
        </Flex>
      </Card>
    </Modal>
  );
};
