import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: '/password_reset',
    name: 'Reset Password',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const sagas = require('./sagas');
          const reducer = require('./modules');
          injectReducer('passwordReset', reducer.default);
          injectSagas('passwordReset', sagas.default);
          cb(null, containers.PasswordReset);
        },
        'auth_password_reset'
      );
    },
  };
}
