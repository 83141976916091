import { defineSharedState } from '@sixsense/core';
import {
  TechnographicsSearchState,
  TechnographicsState,
  UserTechnographicsType,
} from './types';

const PATH_PREFIX = 'settings.technographics';
export const technographicsSearchState =
  defineSharedState<TechnographicsSearchState>({
    path: `${PATH_PREFIX}.technographicsSearchState`,
    defaultValue: '',
  });

export const technographicsState =
  defineSharedState<TechnographicsState>({
    path: `${PATH_PREFIX}.technographicsState`,
    defaultValue: [],
  });

export const userTechnographicsState = defineSharedState <UserTechnographicsType>({
  path: `${PATH_PREFIX}.userTechnographicsState`,
  defaultValue: [],
});

