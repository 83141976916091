import { orgSelector } from 'modules/user/selectors';
import { createSelector } from 'reselect';

const bulkUpdateEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `org/${orgId}/sixsense_campaign/bulk_update/`,
);

export const selectors = {
  bulkUpdateEndpointSelector,
};
