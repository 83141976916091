import { FILTER_TYPES } from './constants';
import { each, map, reduce, find, times, filter } from 'lodash';
import { compose } from 'redux';
import { activityKeys } from '../routes/Segments/routes/Analytics/constants';
import { COLORS } from '../v2-styles/constants';
import moment from 'moment';

export const applyFilter = (exacts, functions, range) => (filterType, filterValue) => {
  switch (filterType) {
    case FILTER_TYPES.EXACT:
      return exacts.add(filterValue);
    case FILTER_TYPES.RANGE: {
      /* eslint-disable no-param-reassign */
      range[0] = filterValue.lhs;
      range[1] = filterValue.rhs;
      return range;
      /* eslint-enable */
    }
    case FILTER_TYPES.GREATER_THAN: {
      functions.push((d) => d !== '' && d > filterValue.rhs ? d : null);
      return functions;
    }
    case FILTER_TYPES.LESS_THAN: {
      functions.push((d) => d !== '' && d < filterValue.rhs ? d : null);
      return functions;
    }
    default:
      throw new Error('BROOO only works with exact filters rn sorry bout it');
  }
};

export const filtersApplier = (filterApplier) => (dimension, filters) => {
  const exacts = new Set([]);
  const functions = [];
  const range = [];
  const fA = filterApplier(exacts, functions, range);
  each(filters, (value) => {
    const { filterType, filterValue } = value;
    fA(filterType, filterValue);
  });

  if (exacts.size + functions.length + range.length === 0) {
    dimension.filterAll();
  } else {
    dimension.filterFunction((d) => {
      const funcs = new Set(map(functions, (fun) => fun(d)));
      if (range[0] <= d && range[1] >= d) {
        return true;
      }
      return exacts.has(d) || funcs.has(d);
    });
  }
};

const fiftyShadesOfBlue = (idx, max, value, colors) => {
  const minBlueLevel = 0.25;
  const doubleAABlueLightness = 84;
  const blueLevel = Math.max((value / max), minBlueLevel);

  const lightnessValue = doubleAABlueLightness - (42 * blueLevel);

  if (colors === 'technograph') {
    return `hsla(203, 100%, ${lightnessValue}%, 1)`;
  }

  return idx < colors.length
    ? colors[idx]
    : colors[colors.length - 1];
};

/* dimensionAccessors */

const coerceToString = (dimensionKey, na = '') => (d) => {
  const dimData = d[dimensionKey];
  const coerce = (data) => {
    let coercedData;
    if (typeof (data || na) === 'string') {
      coercedData = (data || na).toString();
    } else if (data instanceof Array) {
      coercedData = data.map((key) => coerce(key));
    } else {
      coercedData = data;
    }
    return coercedData;
  };
  return coerce(dimData);
};

const keyInRecord = (key) => (record) => record[key];

export const dimensionAccessors = {
  coerceToString,
  keyInRecord,
};

/* mapReducers */
const topN = (n = Infinity, offset = 0) => (d) => d
  .group()
  .reduceCount()
  .top(n, offset)
  .filter((record) => record.key && record.key !== '');

const all = () => (d) => d
  .group()
  .all()
  .slice(0)
  .filter((record) => record.key && record.key !== '');


const formatWorldMapData = (n = Infinity, offset = 0) => (d) => (
  d
    .group()
    .reduceCount()
    .top(n, offset)
    .map((record) => ({
      countryName: record.key instanceof Array
        ? record.key[0]
        : record.key,
      z: record.value,
    }))
);

const industryColors = [
  COLORS.AA_BLUE,
  COLORS.AA_YELLOW,
  COLORS.AA_GREEN,
  COLORS.AA_MAROON,
  COLORS.AA_PURPLE,
  COLORS.AA_GREY,
];

const formatTreemapData = (
  n = Infinity,
  offset = 0,
  colors = industryColors,
) => (d) => {
  let max;
  return (
    d
      .group()
      .reduceCount()
      .top(n, offset)
      .filter((record) => record.key && record.key !== '')
      .map((record, idx) => {
        if (idx === 0) { max = record.value; }
        return ({
          name: record.key instanceof Array ?
            record.key[0] :
            record.key,
          value: record.value,
          color: fiftyShadesOfBlue(idx, max, record.value, colors),
        });
      })
  );
};

const groupByType = (data) => (
  data.reduce((grouped, record) => {
    if (grouped[record.key[0]]) {
      grouped[record.key[0]].push([record.key[1], record.value]);
    } else {
      /* eslint-disable no-param-reassign */
      grouped[record.key[0]] = [[record.key[1], record.value]];
      /* eslint-enable */
    }
    return grouped;
  }, {})
);

const toSeries = (data) => reduce(
  data,
  (accumulator, groupedActivities, activityType) => {
    accumulator.push({
      name: activityType,
      data: groupedActivities,
    });
    return accumulator;
  },
  []
);

const xToDate = (data) => reduce(
  data,
  (accumulator, series) => {
    accumulator.push({
      name: series.name,
      data: series.data.map((point) => ([
        new Date(point[0]).getTime(),
        point[1],
      ])),
    });
    return accumulator;
  },
  []
);

const fillMissingDates = (dateRange) => (data) => {
  const startDate = moment.utc(dateRange[0]);
  const endDate = moment.utc(dateRange[1]);
  const startText = startDate.format('YYYY-MM-DD');
  const delta = endDate.diff(startDate, 'd', false);
  const range = times(
    delta,
    () => startDate.add(1, 'd').format('YYYY-MM-DD')
  );
  range.unshift(startText);
  return reduce(
    data,
    (acc, item) => {
      const existingDates = {};
      each(
        item.data,
        (record) => { existingDates[record[0]] = record[1]; }
      );
      acc.push({
        name: item.name,
        data: map(
          range,
          (date) => {
            if (existingDates[date]) {
              return [
                date,
                existingDates[date],
              ];
            }
            return [
              date,
              0,
            ];
          }
        ),
      });
      return acc;
    },
    []
  );
};

const fillX = (xVals) =>
  (d) => {
    const dMap = reduce(
      d,
      (acc, record) => {
        acc[record.key] = record.value;
        return acc;
      },
      {}
    );

    const xSet = new Set(xVals);

    return map(
      xVals,
      (xKey) => ({
        key: xKey,
        value: dMap[xKey] ? dMap[xKey] : 0,
      })
    ).concat(filter(
      d,
      (record) => !xSet.has(record.key) && !xSet.has(parseInt(record.key, 10))
    ));
  };

const orderX = (orderLookup, na = '') => (d) => compose(
  (data) => {
    let numNA = 0;
    const orderedData = reduce(
      data,
      (accumulator, record) => {
        if (orderLookup[record.key]) {
          /* eslint-disable no-param-reassign */
          accumulator[orderLookup[record.key]] = record;
          /* eslint-enable */
        }
        if (record.key && record.key === na) {
          numNA = record.value;
        }
        return accumulator;
      },
      {});
    return Object.keys(orderedData)
      .sort((a, b) => parseInt(a, 10) > parseInt(b, 10))
      .map((position) => orderedData[position]).concat([{ numNA }]);
  }
)(all()(d));

const groupKeysByInterval = (n) => (data) => {
  // rounds up to group numerical values by numerical keys at given interval
  const groupLookup = reduce(
    data,
    (accum, record) => {
      const group = Math.ceil(record.key / n) * n;
      if (accum[group]) {
        /* eslint-disable no-param-reassign */
        accum[group] += record.value;
      } else {
        accum[group] = record.value;
        /* eslint-enable */
      }
      return accum;
    },
    {}
  );
  return reduce(
    // {key: val} => {key: key, value: value}]
    groupLookup,
    (accum, val, key) => {
      /* eslint-disable no-param-reassign */
      accum.push({
        key: parseInt(key, 10),
        value: val,
      });
      /* eslint-enable */
      return accum;
    },
    []
  );
};

const collapseAfter = (ceiling = Infinity) => (data) => {
  const uncollapsedData = data.filter((record) => record.key <= ceiling);
  const collapsedData = data.filter((record) => record.key > ceiling);
  uncollapsedData.push({
    key: `${ceiling + 1}+`,
    value: reduce(
      collapsedData,
      (accum, record) => {
        /* eslint-disable no-param-reassign */
        accum += record.value;
        /* eslint-enable */
        return accum;
      },
      0
    ),
  });
  return [uncollapsedData, collapsedData];
};

const daysSince = (data) => data.map((record) => ({
  // converts date keys to days behind now
  ...record,
  key: Math.floor(
    (new Date().getTime() - new Date(record.key).getTime()) /
    (1000 * 60 * 60 * 24)
  ),
}));

const orderSeries = (key, order) => (data) => {
  const ordered = [];
  each(
    order,
    (item) => {
      const val = find(
        data,
        (series) => series.name === item
      );
      if (val) {
        ordered.push(val);
      }
    }
  );
  return ordered;
};

const fillMissingTypes = (types) => (data) => {
  each(
    types,
    (type) => {
      if (!(type in data)) {
        /* eslint-disable no-param-reassign */
        data[type] = [];
        /* eslint-enable */
      }
    }
  );
  return data;
};

const formatLineData = () => (d, { dateRange }) => compose(
  xToDate,
  fillMissingDates(dateRange),
  orderSeries(name, activityKeys),
  toSeries,
  fillMissingTypes(activityKeys),
  groupByType,
)(all()(d));

export const mapReducers = {
  topN,
  all,
  formatWorldMapData,
  formatTreemapData,
  formatLineData,
  orderX,
  daysSince,
  groupKeysByInterval,
  collapseAfter,
  fillX,
};

const recordsOfFilters = (filters, dataset) => {
  // get the record associated with a list of applied filter values
  const filterSet = new Set(filters);
  const filterRecords = reduce(
    dataset,
    (acc, record) => {
      const name = record.name;
      if (filterSet.has(name)) {
        acc[name] = record;
      }
      return acc;
    },
    {}
  );
  return filters.map((f) => filterRecords[f]);
};
const numOfNAs = (dataset) => (dataset[dataset.length - 1] || {}).numNA;

export const helpers = {
  recordsOfFilters,
  numOfNAs,
};
