import { GOOGLE_ADS_INTEGRATION_ROUTE } from './constants';

export default function createRoutes() {
  return {
    path: GOOGLE_ADS_INTEGRATION_ROUTE,
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./container');
          cb(null, containers.default);
        },
        'googleSync'
      );
    },
  };
}
