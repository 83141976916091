

type Deps = {
  signedUrlEndpoint: string;
  fetchOptions?: any;
};

class S3GenAIFileUploader {
  signedUrlEndpoint: string;
  fetchOptions: any;
  abortControllerMap: Map<File, AbortController>;

  constructor({ signedUrlEndpoint, fetchOptions = {} }: Deps) {
    this.signedUrlEndpoint = signedUrlEndpoint;
    this.abortControllerMap = new Map();
    this.fetchOptions = fetchOptions;
  }

  upload(file: File, { onProgress, onComplete, onError }: any): any {
    this.abortControllerMap.set(file, new AbortController());
    const finalHeaders = {
      'Content-Type': 'application/json',
      'X-CSRFToken': this.fetchOptions.headers.get('X-CSRFToken'),
    };
    fetch(this.signedUrlEndpoint, {
      method: 'POST',
      body: JSON.stringify({
        filename: file.name,
      }),
      headers: finalHeaders,
      signal: this.abortControllerMap.get(file)?.signal,
    })
      .then(async (resp) => {
        try {
          onProgress(0);
          const { data } = await resp.json();
          onProgress(20);
          const formData = new FormData();
          for (const [key, value] of Object.entries(data.fields)) {
            formData.append(key, value);
          }
          onProgress(40);
          formData.append('file', file);
          const uploadResponse = await fetch(data.url, {
            method: 'POST',
            body: formData,
          });
          onProgress(60);
          if (uploadResponse.ok) {
            onProgress(100);
            onComplete(data);
          } else {
            onError('Error uploading file.');
          }
        } catch (error) {
          onError(error.toString());
        }
      })
      .catch((e) => {
        onError(e.toString());
      });
    return () => {
      const abortController = this.abortControllerMap.get(file);
      if (abortController) {
        abortController.abort();
      }
    };
  }
}

export default S3GenAIFileUploader;
