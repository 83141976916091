import { defineRequestState, defineSharedState } from '@sixsense/core';
import { PATH_PREFIX } from './constants';
import { ActivitiesArray } from './types';

export const activitiesState = defineSharedState<ActivitiesArray>({
  path: `${PATH_PREFIX}.activities`,
  defaultValue: [],
});

export const componentsAndMetricsRequestState = defineRequestState({
  path: `${PATH_PREFIX}.componentsandmetrics`,
  getConfig: ({ orgId }) => ({
    url: `settings/${orgId}/components-and-metrics/`,
  }),
  transformData: (data: any) => data,
});
