import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from '@sixsense/core/components';
import NodeContainer from './NodeContainer';
import { getFlowLogo } from '../../../constants';
import { css } from 'styles/emotion';
import { pxToRemUtil } from '../../../utils';
import { HelpCircle } from '@sixsense/core/icons';


const styles = {
  logo: css({
    marginBottom: pxToRemUtil(-20),
  }),
};

const ImageNode = ({ data, isConnectable }) => {
  const { integrationType, containerName } = data;
  const logo = getFlowLogo(integrationType);
  return (
    <NodeContainer data={data} isConnectable={isConnectable}>
      <Flex direction="column">
        <img src={logo} alt={`${integrationType}_logo`} className={styles.logo} />
        <Flex justifyContent="center" alignItems="center" gap="3px">
          <Text.Title>{containerName}</Text.Title>
          &nbsp;
          <Flex id={`help_${containerName}_mapping_hierarchy`}>
            <HelpCircle />
          </Flex>
        </Flex>
      </Flex>
    </NodeContainer>
  );
};

ImageNode.propTypes = {
  data: PropTypes.any,
  isConnectable: PropTypes.bool,
};

export default ImageNode;
