import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Text,
  Accordian,
  Tooltip2 as Tooltip,
  Tooltip as Tooltip1,
} from 'v2-components';
import { NEGATIVE_BINARY_EXPRESSIONS } from 'ast-redux/constants';
import { readableFiltersetRelationship } from 'aa-ast/utils';
import {
  readableFiltersetFormatterForDisplay,
  readableAtleastValueOpFormatterForDisplay,
} from 'modules/segments/formatters';
import { slice, isNumber } from 'lodash';
import { DATE_LABELS } from 'utils/constants';
import styles from './AstDisplay.module.scss';
import moment from 'moment';
import { KEYWORD_GROUP_COL } from 'routes/Segments/constants';
import { predictiveProductsSelector } from 'modules/user/selectors';

// const renderDuplicates = (conditions, field, delim, customStyles) => (
//   <Fragment>
//     {conditions.map((condition) => (
//       <Row key={`${condition}`} className={styles.dupRow}>
//         <div className={styles.filler} />
//         <Text
//           color={Text.COLOR.BLACK}
//           className={`${customStyles.astDisplay_delim} ${styles.labelPadding}`}
//           weight="bold"
//         >
//           {`${delim}`}
//         </Text>
//         <Text className={customStyles.astDisplay_condDisplayName} color={Text.COLOR.AA_GREY}>
//           {`${condition.display_name}`}
//         </Text>
//       </Row>
//     ))}
//   </Fragment>
// );

const countingFilterPreview = (filterConfig, filter, filterVals) => {
  const filterValsText = <Text color={Text.COLOR.AA_GREY}>{filterVals[0].display_name}</Text>;
  const allFilterVals = filterVals.length === 1 ? filterValsText : (
    <Row flexDirection={Row.FLEX_DIRECTION.ROW}>
      {filterValsText}
      <Tooltip
        placement="topRight"
        overlayStyle={{ minWidth: '200px' }}
        overlay={<Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>{filterVals.map((d) =>
          <span>{d.display_name}</span>)
        }</Row>}
      >
        <Text className={styles.moreText}>+{filterVals.length - 1} more</Text>
        <Text color={Text.COLOR.AA_GREY}>,</Text>
      </Tooltip>
    </Row>
  );

  switch (filter.variable) {
    case KEYWORD_GROUP_COL: {
      let dtVal = filter.metadata.span || filter.metadata.date_between || '';
      if (filter.metadata.date_between) {
        const dateRange = filter.metadata.date_between.split(':');
        const startDate = dateRange[1];
        const endDate = dateRange[0];
        dtVal =
          `${moment(startDate).format('MMM DD YYYY')} and ${moment(endDate).format('MMM DD YYYY')}`;
      }
      return (
        <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
          <Text color={Text.COLOR.AA_GREY}>{filterConfig.label}:</Text>
          <Text color={Text.COLOR.AA_GREY}>
            {`At least ${filter.metadata.value_count}
            Keyword(s) from any of these keyword groups: `}
          </Text>
          {allFilterVals}
          <Text color={Text.COLOR.AA_GREY}>
            researched more than a total of {filter.metadata.total_count} times
            {filter.metadata.span ? ' during the ' : ''}
            {filter.metadata.date_between ? ' between ' : ''}
            {dtVal ? `${filter.metadata.date_between ? dtVal : DATE_LABELS[dtVal]}` : ''}
          </Text>
        </Row>
      );
    }
    default:
      return null;
  }
};

const AstDisplayComponent = (props) => {
  const {
    title,
    className,
    remove,
    filterset,
    filterClassName,
    numberClassName,
    filtersConfig,
    products,
    // this will be a css object which contains css for specific part of the AST filters
    // we can add that style into it and add the respective class entry here.
    customStyles = {},
  } = props;

  const filterItems = filterset.filters.map((filter, index) => {
    const filterVals = filter.filter_values;
    const duplicates = slice(filterVals, 1);
    let delim = filter.filter.value_relationship.toLowerCase(); // eslint-disable-line
    if (NEGATIVE_BINARY_EXPRESSIONS.has(filter.filter.operator)) {
      delim = delim === 'and' ? 'or' : 'and';
    }

    const Top = (dups) => (
      <Row
        alignItems={Row.ALIGN_ITEMS.CENTER}
        className={`${remove ? styles.removableItem : ''}`}
        justifyContent={Row.JUSTIFY_CONTENT.SPACE_BETWEEN}
      >
        <Row alignItems={numberClassName ? null : Row.ALIGN_ITEMS.CENTER}>
          <Text
            className={`${styles.numberPadding} ${numberClassName}`}
            color={Text.COLOR.AA_GREY}
            weight={'bold'}
          >
            {index + 1}.
          </Text>
          <Text
            color={Text.COLOR.AA_GREY}
            className={customStyles.astDisplay_alignInline}
          >
            {filter.filter.variable === 'keyword_group'
              ? countingFilterPreview(
                  filtersConfig[filter.filter.variable],
                  filter.filter,
                  filterVals
                )
              : readableFiltersetFormatterForDisplay(
                  filtersConfig[filter.filter.variable],
                  filter.filter,
                  dups,
                  products
                )}

            <Text
              color={Text.COLOR.AA_GREY}
              className={`${customStyles.astDisplay_initFilterValue} aam-l--5`}
            >
              {'dateType' in filterVals[0].metadata &&
              filterVals[0].metadata.dateType === 'unknown_date'
                ? 'Unknown Date'
                : ''}
              {'dateType' in filterVals[0].metadata &&
              filterVals[0].metadata.dateType === 'any_date'
                ? 'Any Date'
                : ''}
              {(!('dateType' in filterVals[0].metadata) ||
                (filterVals[0].metadata.dateType !== 'unknown_date' &&
                  filterVals[0].metadata.dateType !== 'any_date')) &&
              !(filter.filter.variable === 'keyword_group')
                ? filterVals[0].display_name
                : ''}
            </Text>

            {dups && !(filter.filter.variable === 'keyword_group') ? (
              <Fragment>
                <Text
                  pointer={isNumber(dups) ? dups : false}
                  color={Text.COLOR.BLUE}
                  className={styles.others}
                >
                  <Tooltip1
                    autoAdjustOverflow
                    title={
                      <pre>
                        {filterVals
                          .map((filterVal) => filterVal.display_name)
                          .join('\n')}
                      </pre>
                    }
                  >
                    + {slice(filterVals, 1).length}{' '}
                    {`other${slice(filterVals, 1).length > 1 ? 's' : ''}`}
                    {filter.filter.metadata.is_counting ? (
                      <Text color={Text.COLOR.AA_GREY}>,</Text>
                    ) : (
                      ''
                    )}
                  </Tooltip1>
                </Text>
              </Fragment>
            ) : (
              ''
            )}
          </Text>
        </Row>
      </Row>
    );

    return (
      <Row
        flexDirection={Row.FLEX_DIRECTION.COLUMN}
        key={`${filterVals.join('')}_${index + 1}`}
        className={`${styles.spanRow} ${filterClassName}`}
      >
        {duplicates.length && !(filter.filter.variable === 'keyword_group') ? (
          <Accordian
            ClickableComponent={Top}
          />
        ) : (
          Top(duplicates.length)
        )}
        {filter.filter.metadata.is_counting && !(filter.filter.variable === 'keyword_group') ? (
          <Row alignItems={numberClassName ? null : Row.ALIGN_ITEMS.CENTER}>
            <Text
              className={`${styles.numberPadding} ${numberClassName}`}
              color={Text.COLOR.AA_GREY} weight={'bold'}
            />
            <Text color={Text.COLOR.AA_GREY} className={customStyles.astDisplay_alignInline}>
              {` ${readableAtleastValueOpFormatterForDisplay(filter.filter)}`}
            </Text>
          </Row>
        ) : ''}
      </Row>
    );
  });

  return (
    <Row
      flexDirection={Row.FLEX_DIRECTION.COLUMN}
      className={`${styles.fullWidthRow} ${className}`}
      key={`${title}-ast-display`}
    >
      {title}
      <Row className={`${styles.spanRow} ${filterClassName}`}>
        <Text className={styles.labelPadding} color={Text.COLOR.AA_GREY}>
          Filter Logic:
        </Text>
        <Text color={Text.COLOR.AA_GREY} className={customStyles.astDisplay_filterLogic}>
          {readableFiltersetRelationship(filterset.relationship)}
        </Text>
      </Row>
      {filterItems}
    </Row>
  );
};

AstDisplayComponent.propTypes = {
  title: PropTypes.element,
  className: PropTypes.string,
  remove: PropTypes.func,
  filterClassName: PropTypes.string,
  numberClassName: PropTypes.string,
  filtersConfig: PropTypes.object,
  filterset: PropTypes.object,
  customStyles: PropTypes.object,
  products: PropTypes.any,
};


const mapStateToProps = (state) => ({
  products: predictiveProductsSelector(state),
});

export const AstDisplay = connect(mapStateToProps)(AstDisplayComponent);
