import { omit, keys } from 'lodash';
import { STATUS_STATE } from 'utils/constants';
import {
  newParamsNavigate,
  getCurrentRoute,
  getCurrentParams,
  mergeParamsNavigate,
} from 'utils/navigate';
import { withoutReducer } from 'routes/Orchestrations/utils';
import { AI_HISTORY_CONDITIONS } from '../../constants';

export const initialState = {
  [AI_HISTORY_CONDITIONS.CATEGORY]: undefined, // (performance, tactics, approvals, audit trail)
  [AI_HISTORY_CONDITIONS.TAB]: undefined, // tabs per-category type
  [AI_HISTORY_CONDITIONS.TACTIC]: undefined,
  [AI_HISTORY_CONDITIONS.START_DATE]: undefined,
  [AI_HISTORY_CONDITIONS.END_DATE]: undefined,
  supportedMetrics: {},
  shouldRequestSummaryStats: true,
  csvStatus: {
    downloading: false,
  },
  summaryStats: {
    ...STATUS_STATE.DEFAULT,
    data: [],
  },
  aiRecords: {
    ...STATUS_STATE.DEFAULT,
    data: [],
  },
  aiEvents: {
    ...STATUS_STATE.DEFAULT,
    data: [],
  },
  aiEventsModal: {
    visible: false,
    ...STATUS_STATE.DEFAULT,
    modalData: {},
    modalMeta: {},
  },
};

// this action does what fetchAiHistory does. It does not trigger api calls and
// does not update qparams.
const SET_AI_HIST_CONDITION = 'ORCHESTRATION/HISTORY/AI/SET_CONDTION';
const setAiHistoryCondition = (field, value) => ({
  type: SET_AI_HIST_CONDITION,
  ...{ [field]: value },
});

// this function updates one of the fields that could possibly trigger a refetch
// the load saga will be triggered from this action.
const FETCH_AI_HISTORY = 'ORCHESTRATION/HISTORY/AI/FETCH_HIST';
const fetchAiHistory = (field, value) => {
  if (!field) {
    return { type: FETCH_AI_HISTORY };
  }
  const updateField = { [field]: value };

  // if we're changing category, the tab needs to get reset. For now, removing
  // this qparam and letting the saga re-sync it.
  if (field === AI_HISTORY_CONDITIONS.CATEGORY) {
    newParamsNavigate(
      getCurrentRoute(),
      { ...omit(getCurrentParams(), AI_HISTORY_CONDITIONS.TAB), ...updateField },
    );
  } else if (field !== AI_HISTORY_CONDITIONS.TACTIC) {
    mergeParamsNavigate(getCurrentRoute(), updateField);
  }
  return { type: FETCH_AI_HISTORY, ...updateField };
};
const LOAD_AI_HIST = 'ORCHESTRATION/HISTORY/AI/LOAD_HISTORY';
const loadAiHistory = () => ({ type: LOAD_AI_HIST });


const LOAD_SUPPORTED_METRICS_SUC = 'ORCHESTRATION/HISTORY/AI/LOAD_METRICS/SUC';
const LOAD_SUPPORTED_METRICS_FAIL = 'ORCHESTRATION/HISTORY/AI/LOAD_METRICS/FAIL';
const loadSupportedMetricsSuc = (supportedMetrics) => ({
  type: LOAD_SUPPORTED_METRICS_SUC,
  supportedMetrics,
});
const loadSupportedMetricsFail = () => ({
  type: LOAD_SUPPORTED_METRICS_FAIL,
  supportedMetrics: { error: true },
});

// this is garbage. as soon as i get a free opportunity, will replace
// this crap with gregs lib or rupinders or another solution that doesnt require this.
const LOAD_SUMMARY_STAT_REQ = 'ORCHESTRATION/HISTORY/AI/LOAD_SUMMARY_STAT_REQ';
const LOAD_SUMMARY_STAT_SUC = 'ORCHESTRATION/HISTORY/AI/LOAD_SUMMARY_STAT_SUC';
const LOAD_SUMMARY_STAT_FAIL = 'ORCHESTRATION/HISTORY/AI/LOAD_SUMMARY_STAT_FAIL';
const LOAD_RECORDS_REQ = 'ORCHESTRATION/HISTORY/AI/LOAD_RECORDS_REQ';
const LOAD_RECORDS_SUC = 'ORCHESTRATION/HISTORY/AI/LOAD_RECORDS_SUC';
const LOAD_RECORDS_FAIL = 'ORCHESTRATION/HISTORY/AI/LOAD_RECORDS_FAIL';
const loadSummaryReq = () => ({ type: LOAD_SUMMARY_STAT_REQ, ...STATUS_STATE.REQ });
const loadSummarySuc = (data) => ({
  type: LOAD_SUMMARY_STAT_SUC,
  ...STATUS_STATE.SUC,
  data,
});
const loadSummaryFail = () => ({ type: LOAD_SUMMARY_STAT_FAIL, ...STATUS_STATE.FAIL });

const loadRecordsReq = () => ({ type: LOAD_RECORDS_REQ, ...STATUS_STATE.REQ });
const loadRecordsSuc = ([data]) => ({ type: LOAD_RECORDS_SUC, ...STATUS_STATE.SUC, data });
const loadRecordsFail = () => ({ type: LOAD_RECORDS_FAIL, ...STATUS_STATE.FAIL });

const DOWNLOAD_AI_CSV_HISTORY = 'ORCHESTRATIONS/HISTORY/AI/DOWNLOAD_AI_CSV_HISTORY';
function downloadAIHistoryCSV(fileName, previewType, metaObject) {
  return {
    type: DOWNLOAD_AI_CSV_HISTORY,
    downloading: true,
    fileName,
    previewType,
    metaObject,
  };
}

const DOWNLOAD_AI_CSV_HISTORY_SUCCESS =
  'ORCHESTRATIONS/HISTORY/AI/DOWNLOAD_AI_CSV_HISTORY_SUCCESS';
function downloadAIHistoryCSVSuccess() {
  return {
    type: DOWNLOAD_AI_CSV_HISTORY_SUCCESS,
    downloading: false,
    error: false,
  };
}

const DOWNLOAD_AI_CSV_HISTORY_FAILURE =
  'ORCHESTRATIONS/HISTORY/AI/DOWNLOAD_AI_CSV_HISTORY_FAILURE';
function downloadAIHistoryCSVFailure(errorMessage, errorStatus) {
  return {
    type: DOWNLOAD_AI_CSV_HISTORY_FAILURE,
    downloading: false,
    error: true,
    errorStatus,
    errorMessage,
  };
}

const LOAD_EVENTS_REQ = 'ORCHESTRATION/HISTORY/AI/LOAD_EVENTS_REQ';
const LOAD_EVENTS_SUC = 'ORCHESTRATION/HISTORY/AI/LOAD_EVENTS_SUC';
const LOAD_EVENTS_FAIL = 'ORCHESTRATION/HISTORY/AI/LOAD_EVENTS_FAIL';

const loadEventsReq = () => ({ type: LOAD_EVENTS_REQ, ...STATUS_STATE.REQ });
const loadEventsSuc = (data) => ({ type: LOAD_EVENTS_SUC, ...STATUS_STATE.SUC, data });
const loadEventsFail = () => ({ type: LOAD_EVENTS_FAIL, ...STATUS_STATE.FAIL });

const OPEN_AT_EVENT_MODAL = 'ORCHESTRATION/HISTORY/AI/OPEN_AT_EVENT_MODAL';
const openATEventModal = (visible, modalMeta = {}) =>
  ({ type: OPEN_AT_EVENT_MODAL, visible, modalMeta });

const aiHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_AI_HIST: {
      return initialState;
    }
    case LOAD_SUMMARY_STAT_SUC: {
      return {
        ...state,
        shouldRequestSummaryStats: false,
        summaryStats: withoutReducer(state.summaryStats, action),
      };
    }

    case LOAD_SUMMARY_STAT_REQ:
    case LOAD_SUMMARY_STAT_FAIL: {
      return { ...state, summaryStats: withoutReducer(state.summaryStats, action, 'type') };
    }
    case LOAD_RECORDS_REQ:
    case LOAD_RECORDS_SUC:
    case LOAD_RECORDS_FAIL: {
      return { ...state, aiRecords: withoutReducer(state.aiRecords, action, 'type') };
    }
    case LOAD_EVENTS_REQ:
    case LOAD_EVENTS_SUC:
    case LOAD_EVENTS_FAIL: {
      return { ...state, aiEvents: withoutReducer(state.aiEvents, action, 'type') };
    }
    case DOWNLOAD_AI_CSV_HISTORY:
    case DOWNLOAD_AI_CSV_HISTORY_SUCCESS:
    case DOWNLOAD_AI_CSV_HISTORY_FAILURE: {
      return { ...state, csvStatus: withoutReducer(state.csvStatus, action, 'type') };
    }
    case FETCH_AI_HISTORY: {
      /*
       * The load history saga has been set up to get called on every field
       * change that modifies the conditions in the post body. However, we
       * don't need to re-fetch the summary stats if we are only changing
       * the tab within a category. For example: swapping between pending
       * and approved tab under the approvals category.
       *
       * In this case, if we're modifying a field other than tab then the
       * summary stats do need to be re-fetched. We track that change
       * with shouldRequestSummaryStats state.
       *
       * the summary stat saga is wrapped in a check to ensure we only fetch
       * if this state is true
      */
      const updateField = omit(action, 'type');
      const [fieldName] = keys(updateField);

      const onlyChangingTab = fieldName === 'tab';

      if (onlyChangingTab) {
        return state;
      }

      return { ...state, shouldRequestSummaryStats: true };
    }
    case OPEN_AT_EVENT_MODAL: {
      return { ...state, aiEventsModal: withoutReducer(state.aiEventsModal, action, 'type') };
    }
    default:
      return withoutReducer(state, action);
  }
};

export const actions = {
  setAiHistoryCondition,
  fetchAiHistory,
  loadAiHistory,
  loadSupportedMetricsSuc,
  loadSupportedMetricsFail,
  loadSummaryReq,
  loadSummarySuc,
  loadSummaryFail,
  loadRecordsReq,
  loadRecordsSuc,
  loadRecordsFail,
  downloadAIHistoryCSV,
  downloadAIHistoryCSVSuccess,
  downloadAIHistoryCSVFailure,
  loadEventsReq,
  loadEventsSuc,
  loadEventsFail,
  openATEventModal,
};

export const actionTypes = {
  SET_AI_HIST_CONDITION,
  FETCH_AI_HISTORY,
  LOAD_AI_HIST,
  LOAD_SUPPORTED_METRICS_SUC,
  LOAD_SUPPORTED_METRICS_FAIL,
  LOAD_SUMMARY_STAT_REQ,
  LOAD_SUMMARY_STAT_SUC,
  LOAD_SUMMARY_STAT_FAIL,
  LOAD_RECORDS_REQ,
  LOAD_RECORDS_SUC,
  LOAD_RECORDS_FAIL,
  DOWNLOAD_AI_CSV_HISTORY,
  DOWNLOAD_AI_CSV_HISTORY_SUCCESS,
  DOWNLOAD_AI_CSV_HISTORY_FAILURE,
  LOAD_EVENTS_REQ,
  LOAD_EVENTS_SUC,
  LOAD_EVENTS_FAIL,
  OPEN_AT_EVENT_MODAL,
};

export default aiHistoryReducer;
