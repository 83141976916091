import { getAsyncInjectors } from 'store/utils';
import { taxonomyV2Reducer } from './modules';
import Jobs from './routes/jobs';
import Overview from './routes/overview';
import MappingReview from './routes/mappingReview';
import RuleManager from './routes/ruleManager';
import Preferences from './routes/preferences';
import ProductUrlConfig from './routes/productUrlConfig';
import { taxonomyV2Sagas } from './sagas';


export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: 'taxonomyV2',
    name: 'TaxonomyV2',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/settings/taxonomyV2/overview'),
    },

    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const container = require('./containers/TaxonomyContainer');
        injectReducer('taxonomyV2', taxonomyV2Reducer);
        injectSagas('taxonomyV2', taxonomyV2Sagas);
        cb(null, container.TaxonomyContainer);
      }, 'taxonomyV2');
    },
    childRoutes: [
      Overview(store),
      RuleManager(store),
      Jobs(store),
      Preferences(store),
      MappingReview(store),
      ProductUrlConfig(store),
      {
        path: ':dataSource',
        name: 'dataSource',
        getComponent(nextState, cb) {
          const container = require('./components/DataSource');
          require.ensure(
            [],
            () => {
              cb(null, container.DataSource);
            },
            'datasource',
          );
        },
      },
    ],
  };
}
