import { getAsyncInjectors } from 'store/utils';
import { SALESWHALE_PATH, SALESWHALE_STATE_KEY } from './constants';

export default function createRoute(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: SALESWHALE_PATH,
    name: 'Saleswhale status',
    getComponent(_nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const reducer = require('./modules');
        const sagas = require('./sagas');

        injectReducer(SALESWHALE_STATE_KEY, reducer.default);
        injectSagas(SALESWHALE_STATE_KEY, sagas.default);

        const formWizardReducer =
          require('routes/Settings/routes/Integration/components/FormWizard2/modules');
        injectReducer('SettingsFormWizard', formWizardReducer.default);
        const dataSyncsReducer = require('../../components/DataSyncs/modules');
        injectReducer('DataSyncs', dataSyncsReducer.default);
        cb(null, containers.SaleswhaleContainer);
      }, 'saleswhale');
    },
  };
}
