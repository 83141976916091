import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import UserContext from 'contexts/UserContext';
import { SalesSegmentPage } from '@sixsense/core/segments/examples';
import FilterPlayground from '@sixsense/core/segments/examples/FilterPlayground';
import { ThemeProvider } from '@sixsense/core/style';
import { DESIGN_SYSTEM_THEME } from '@sixsense/core/style/theme';
import { RestSegmentApiClient } from '@sixsense/core/segments/api';
import {
  FileUploaderProvider,
  S3FileUploader,
} from '@sixsense/core/components/data-entry/FileUpload';
import { SegmentApiClientProvider } from '@sixsense/core/segments/context';
import { ToastProvider } from '@sixsense/core/components';
import { QueryClient, QueryClientProvider } from '@sixsense/core/versioned/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
  },
});

const Saas = ({ user, location }) => {
  const segmentApiClient = new RestSegmentApiClient({
    orgId: user.organization.id,
    charcoalApiEndpoint: `${window.location.origin}/charcoal`,
    tallyApiEndpoint: `${window.location.origin}/tally`,
    axeApiEndpoint: `${window.location.origin}/axe`,
    app: 'abm',
    username: user.username,
  });

  const fileUploader = useMemo(() => new S3FileUploader({
    signedUrlEndpoint: `${window.location.origin}/tally/${user.organization.id}/get_csv_url/`,
  }), [user.organization.id]);

  return (
    !window.process.env.SIXSENSE_ENV.startsWith('prod')
    || (user && (user.is_internaluser || user.is_dev || user.is_staff))
  ) ? (
    <QueryClientProvider client={queryClient}>
      <SegmentApiClientProvider value={segmentApiClient}>
        <FileUploaderProvider value={fileUploader}>
          <ThemeProvider theme={DESIGN_SYSTEM_THEME} nonce={window.__CSP_NONCE__}>
            <ToastProvider>
              {location.pathname === '/saas-frontend/playground' ? (
                <FilterPlayground />
              ) : (
                <SalesSegmentPage />
              )}
            </ToastProvider>
          </ThemeProvider>
        </FileUploaderProvider>
      </SegmentApiClientProvider>
    </QueryClientProvider>
) : null;
};

Saas.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
};

export default UserContext.UserConsumer(Saas);
