import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import qs from 'qs';
import { Flex, Skeleton, Text } from '@sixsense/core/components';
import { LinkBroken, Link, ChevronRight, SlashDivider } from '@sixsense/core/icons';
import { DesignSystemColor } from '@sixsense/core/style';
import NodeContainer from './NodeContainer';
import { connect } from 'react-redux';
import {
  mappingTotalsAndPercentagesSelector,
  mappingDataLoadingSelector,
 } from '../../../selectors';
import { capitalize } from 'lodash';

export const MAPPING_SUB_TYPES = {
  UNMAPPED: 'unmapped',
  MAPPED: 'mapped',
  EXCLUDED: 'excluded',
};

const MAPPING_TYPE_ICON = {
  [MAPPING_SUB_TYPES.UNMAPPED]: <LinkBroken color={DesignSystemColor.White.DEFAULT} size={'20'} />,
  [MAPPING_SUB_TYPES.MAPPED]: <Link color={DesignSystemColor.White.DEFAULT} size={'20'} />,
  [MAPPING_SUB_TYPES.EXCLUDED]: (
    <Flex style={{ position: 'relative' }}>
      <Link color={DesignSystemColor.White.DEFAULT} size={'20'} />
      <Flex style={{ position: 'absolute', rotate: '108deg' }}>
        <SlashDivider color={DesignSystemColor.White.DEFAULT} size={'20'} />
      </Flex>
    </Flex>
  ),
};

const MAPPING_ICON_BG_COLOR = {
  [MAPPING_SUB_TYPES.UNMAPPED]: DesignSystemColor.Red.DEFAULT,
  [MAPPING_SUB_TYPES.MAPPED]: DesignSystemColor.Green.DARK_1,
  [MAPPING_SUB_TYPES.EXCLUDED]: DesignSystemColor.Gray.DEFAULT,
};

const MAPPING_TYPE_TITLE = {
  [MAPPING_SUB_TYPES.UNMAPPED]: 'Unmapped Data',
  [MAPPING_SUB_TYPES.MAPPED]: 'Mapped Data',
  [MAPPING_SUB_TYPES.EXCLUDED]: 'Excluded Data',
};

const MappingTypeNodeComponent = ({
  data,
  isConnectable,
  mappingTotalsAndPercentages,
  mappingDataLoading,
}) => {
  const {
    type,
    taxonomyType='activity',
   } = data;
  const mappingTotalsObj = mappingTotalsAndPercentages.find((row) => row.type === taxonomyType);
  return (
    <NodeContainer data={data} isConnectable={isConnectable}>
      <Flex
        direction="column"
        gap="12px"
        style={{
          width: '168px',
          padding: '12px 16px 12px 16px',
          borderRadius: '8px',
          backgroundColor: '#FFF',
          boxShadow: '0px 2px 4px -2px #10182514, 0px 4px 8px -2px #10182533',
        }}
      >
        <Flex justifyContent="space-between">
          <Flex
            style={{ background: MAPPING_ICON_BG_COLOR[type],
              borderRadius: '5px',
              padding: '5px',
            }}
          >
            {MAPPING_TYPE_ICON[type]}
          </Flex>
          <ChevronRight
            onClick={() => browserHistory.push({
              pathname: '/settings/taxonomyV2/mapping-review/' +
                  `${data.dataSource}/${data.taxonomyType}`,
              search: `?${qs.stringify({
                qcfilters: {
                  status: [`${capitalize(type)}`],
                },
              })}`,
            })}
            style={{ cursor: 'pointer' }}
          />
        </Flex>
        <Flex direction="column">
          <Flex>
            <Text.Body
              color={DesignSystemColor.Gray.DARK_4}
              weight="semibold"
            >
              {MAPPING_TYPE_TITLE[type]}
            </Text.Body>
          </Flex>
          <Flex>
            {mappingDataLoading ? (
              <Skeleton style={{ height: 20, width: 100 }} variant="rectangular" />
              ): (
                <React.Fragment>
                  <Text.RegularText>{
        Intl.NumberFormat('en-US').format(mappingTotalsObj[type])}</Text.RegularText>
                  <Text.RegularText>
                    &nbsp;{`(${mappingTotalsObj[`${type}Percentage`]}%)`}
                  </Text.RegularText>
                </React.Fragment>
              )}
          </Flex>
        </Flex>
      </Flex>
    </NodeContainer>
  );
};

MappingTypeNodeComponent.propTypes = {
  data: PropTypes.any,
  isConnectable: PropTypes.bool,
  mappingTotalsAndPercentages: PropTypes.object,
  mappingDataLoading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
  mappingTotalsAndPercentages: mappingTotalsAndPercentagesSelector(ownProps.data.dataSource)(state),
  mappingDataLoading: mappingDataLoadingSelector(state),
});

const MappingTypeNode = connect(mapStateToProps, null)(MappingTypeNodeComponent);
export default memo(MappingTypeNode);
