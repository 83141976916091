import React from "react";
import { NA } from "./common/NA";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import {
  VIEWABILITY_METRICS_DOJO_LINK,
  VIEWABILITY_TOOLTIP_INFO,
  VIEWABILITY as viewabilityFlagIdentifier,
} from "routes/AdvertisingDS/constants";
import { get } from "lodash";
import {
  formatViewabilityPercent,
  isViewabilityAvailable,
} from "../../../utils";
import { Flex, Text, Tooltip, Link } from "@sixsense/core/components";
import { InfoCircle } from "@sixsense/core/icons";
import { RootColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const VIEWABILITY = {
  sortByKey: "viewability_percent",
  key: "viewability",
  csvColumnKey: "viewability_percent",
  featureFlagKey: viewabilityFlagIdentifier,
  defaultSelected: true,
  title: (
    <Flex alignItems="center">
      <Label name={CAMPAIGN_LABELS.viewabilityPercent} />
      <Tooltip
        trigger={"hover"}
        overlay={
          <Flex direction="column">
            <Flex alignItems="center">
              <Text.Body color={`var(${RootColor.White})`}>
                Viewability
              </Text.Body>
            </Flex>
            <Text.Body color={`var(${RootColor.White})`}>
              {VIEWABILITY_TOOLTIP_INFO} &nbsp;
              <a
                href={VIEWABILITY_METRICS_DOJO_LINK}
                target={"_blank"}
                rel="noopener noreferrer"
              >
                <Link>Learn more</Link>
              </a>
            </Text.Body>
          </Flex>
        }
        placement={"top"}
      >
        <InfoCircle className="ml1" />
      </Tooltip>
    </Flex>
  ),
  dataIndex: "campaign_data.viewability_percent",
  width: pxToRem(150),
  render: (viewabilityPercent = 0, campaign) => {
    const { campaign_source } = campaign;
    const showViewability = isViewabilityAvailable(
      campaign_source,
      get(campaign, "end_date"),
      get(campaign, "campaign_status")
    );
    if (showViewability) {
      return (
        <Text.Body>{formatViewabilityPercent(viewabilityPercent)}%</Text.Body>
      );
    }
    return <NA />;
  },
  align: "right",
};
