import React, { useRef } from 'react';
import { css } from 'styles/emotion';
import { Input, Icon } from 'v2-components';
import { Flex } from '@sixsense/core/components';
import { AAColor } from '@sixsense/core/style';

const styles = {
  searchBar: css({
    justifyContent: 'center',
    marginLeft: '10px',
    width: '100%',
  }),
  innerSearchBar: css({
    display: 'flex',
    alignItems: 'center',
    height: '36px !important',
    paddingLeft: '35px !important',
  }),
  clearButton: css({
    marginLeft: -25,
    marginRight: '10px',
    cursor: 'pointer',
  }),
  hidden: css({
    display: 'none',
  }),
  disabled: css({
    background: `${AAColor.WHITE} !important`,
    color: `${AAColor.GREY} !important`,
  }),
  searchIcon: css({
    paddingLeft: '10px',
    zIndex: 15,
  }),
};

type SearchBarProps = {
  value: string,
  setValue: (value: string) => void,
  disabled?: boolean;
  placeholder: string;
  containerClassName?: string;
};


const SearchBar = ({
  value,
  setValue,
  disabled,
  placeholder,
  containerClassName,
} : SearchBarProps) => {
  const inputRef = useRef();

  const clearFn = () => setValue('');
  const clearButton = (
    <Icon
      type="clear"
      color={Icon.COLORS.GREY2}
      onClick={() => {
        clearFn();
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
      className={`${styles.clearButton} ${!value ? styles.hidden : ''}`}
    />
  );

  return (
    <Flex
      direction="column"
      className={`${styles.searchBar} ${containerClassName}`}
    >
      <Input
        id="filterSearch"
        placeholder={placeholder}
        iconLocation="left"
        icon="search"
        iconColor={Icon.COLORS.GREY2}
        iconClass={styles.searchIcon}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={`${styles.innerSearchBar} ${disabled && styles.disabled}`}
        reference={inputRef}
        rightSideComponent={clearButton}
        disabled={disabled}
      />
    </Flex>
  );
};

export default SearchBar;
