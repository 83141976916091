export default function createRoutes() {
  return {
    path: 'analytics/',
    name: 'SegmentAnalytics',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        cb(null, containers.AnalyticsRoutesContainer);
      }, 'segmentsAnalytics');
    },
  };
}
