import React from 'react';
/* eslint max-len: [2, 4000] */
// Using 1000 for now.... might need to bump it up
export function CheckCircle(props) {
  return (
    <svg width="20px" {...props} height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>CheckCircle Icon</title>
      <desc>CheckCircle Logo</desc>
      <defs>
        <path d="M10.0833333,15.5833333 L11.9166667,15.5833333 L11.9166667,10.0833333 L10.0833333,10.0833333 L10.0833333,15.5833333 L10.0833333,15.5833333 Z M11,1.83333333 C5.94,1.83333333 1.83333333,5.94 1.83333333,11 C1.83333333,16.06 5.94,20.1666667 11,20.1666667 C16.06,20.1666667 20.1666667,16.06 20.1666667,11 C20.1666667,5.94 16.06,1.83333333 11,1.83333333 L11,1.83333333 Z M11,18.3333333 C6.9575,18.3333333 3.66666667,15.0425 3.66666667,11 C3.66666667,6.9575 6.9575,3.66666667 11,3.66666667 C15.0425,3.66666667 18.3333333,6.9575 18.3333333,11 C18.3333333,15.0425 15.0425,18.3333333 11,18.3333333 L11,18.3333333 Z M10.0833333,8.25 L11.9166667,8.25 L11.9166667,6.41666667 L10.0833333,6.41666667 L10.0833333,8.25 L10.0833333,8.25 Z" id="path-1" />
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="A-Warning-Message" transform="translate(-139.000000, -127.000000)">
          <g id="Campaign-Time-Lag-Message" transform="translate(108.000000, 111.000000)">
            <g id="ic_info_outline" transform="translate(30.000000, 15.000000)">
              <mask id="mask-2" fill="white">
                <use href="#path-1" />
              </mask>
              <g id="Mask" />
              <g id="Colors/Blue.1" mask="url(#mask-2)" fill="#0082D4" fillRule="evenodd">
                <g transform="translate(-11.916667, -11.916667)" id="Colors/Blue.2">
                  <path d="M0,0 L45.8333333,0 L45.8333333,45.8333333 L0,45.8333333 L0,0 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>);
}
