import React from 'react';
import { Icon, Row, Text } from 'v2-components';
import { If } from 'babel-plugin-jsx-control-statements';
import CopyToAll from './CopyToAll';
import PropTypes from 'prop-types';
import Tooltip2 from 'v2-components/Tooltip2';
import { css, cx } from 'styles/emotion';

const FieldLabel = ({
  label,
  required,
  showCopyToAll,
  onCopyToAll,
  disableCopyToAll,
  copyConfirmLabel,
  labelTooltip,
  rightExtra,
  className,
}) => (<Row justifyContent={'space-between'} className={className}>
  <Row alignItems={'center'}>
    <Text bold>{label}</Text>
    {required && (<Text color={Text.COLOR.BLUE}>*</Text>)}
    <If condition={labelTooltip}>
      <span className={cx('aam-l--5')}>
        <Tooltip2 overlay={labelTooltip} className={css`display: flex`} placement={'top'}>
          <Icon
            type={'info_outlined'}
            color={Icon.COLORS.AA_GREY}
            size={Icon.SIZE.LARGE}
            className={cx(css`width: 20px`)}
          />
        </Tooltip2>
      </span>
    </If>
    <If condition={showCopyToAll}>
      <CopyToAll
        fieldLabel={copyConfirmLabel || label}
        className={'aam-l--10'}
        onClick={onCopyToAll}
        disabled={disableCopyToAll}
      />
    </If>
  </Row>
  {rightExtra}
</Row>);
FieldLabel.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  labelTooltip: PropTypes.string,
  copyConfirmLabel: PropTypes.string,
  required: PropTypes.bool,
  showCopyToAll: PropTypes.bool,
  onCopyToAll: PropTypes.func,
  disableCopyToAll: PropTypes.bool,
  rightExtra: PropTypes.node,
};

export default FieldLabel;
