import React from 'react';
import PropTypes from 'prop-types';
import { Row, Modal, Icon, Text, Button } from 'v2-components';
import styles from './PromptModal.module.scss';

/**
* @param {bool} visible
* @param {string} title - Title of prompt
* @param {string or react element} - Content of prompt
* @param {bool} loading - disables prompt buttons if waiting for something
* to finish loading
* @param {func} onOk - will be invoked on clicking OK button
* @param {func} onCancel - will be invoked on click cancel button
* @param {string} okText - optional text for OK button: default text is ‘Ok’
* @param {string} okId - Id for Ok button
* @param {object} type - type of prompt to be displayed. One of:
* (Modal.PROMPT_TYPES.confirm, MODAL.PROMPT_TYPES.info)
*/

const PromptModal = (props) => {
  const {
    visible, title, content, loading, onOk, onCancel, okText,
    okId, type, width, iconClassName, cancelText,
  } = props;

  return (
    <Modal
      visible={visible}
      closable={false}
      maskClosable={false}
      footer={null}
      width={width}
    >
      <Row flexDirection={Row.FLEX_DIRECTION.COLUMN} className={styles.content}>
        <Row>
          <Icon
            type={type === Modal.PROMPT_TYPES.CONFIRM ? 'help' : 'info'}
            color={Text.COLOR.YELLOW}
            className={`${styles.icon} ${iconClassName}`}
          />
          <Row className={styles.textContainer} flexDirection={Row.FLEX_DIRECTION.COLUMN}>
            {title ? <Text bold className={styles.title}>{title}</Text> : null}
            <Text>{content}</Text>
          </Row>
        </Row>
        <Row justifyContent={Row.JUSTIFY_CONTENT.FLEX_END} className={styles.buttonBlocks}>
          {type === Modal.PROMPT_TYPES.CONFIRM ? (
            <Button
              className={styles.cancel}
              disabled={loading}
              onClick={onCancel}
            >
              {cancelText}
            </Button>) : null
          }
          <Button
            type={Button.PRIMARY}
            id={okId}
            className={styles.ok}
            loading={loading}
            onClick={type === Modal.PROMPT_TYPES.CONFIRM ? onOk : onCancel}
          >
            {okText}
          </Button>
        </Row>
      </Row>
    </Modal>
  );
};

PromptModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  okText: PropTypes.string,
  okId: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.number,
  iconClassName: PropTypes.string,
  cancelText: PropTypes.string,
};

PromptModal.defaultProps = {
  okText: 'Ok',
  cancelText: 'Cancel',
  width: 400,
};

export default PromptModal;
