import React from 'react';

/* eslint max-len: [2, 100000] */
export function Segment(props) {
  return (
    <svg {...props} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <title>Artboard 3</title>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <path
            d="M881.58 44.85c0-16.494-13.365-29.858-29.857-29.858H114.46c-16.49 0-29.855 13.364-29.855 29.857v173.165c0 .418.108.813.13 1.242.025.717.133 1.398.216 2.114.216 1.864.587 3.68 1.124 5.423.19.597.322 1.183.55 1.756.87 2.304 1.97 4.5 3.355 6.496.047.06.07.143.12.215l284.77 402.63v345.356c0 10.486 5.504 20.207 14.497 25.605 4.73 2.83 10.032 4.252 15.358 4.252 4.825 0 9.662-1.17 14.056-3.51l156.747-83.6c9.722-5.194 15.8-15.32 15.8-26.344V637.89L876.11 235.25c.047-.073.07-.157.12-.216 1.384-1.995 2.483-4.192 3.354-6.497.227-.585.36-1.17.55-1.756.537-1.742.907-3.558 1.122-5.42.084-.717.192-1.398.215-2.114.025-.418.133-.813.133-1.242V44.85h-.024zM144.317 74.704h677.548V188.16H144.318V74.704zm392.766 536.46c-3.57 5.04-5.48 11.07-5.48 17.232v253.337l-97.035 51.747V628.398c0-6.174-1.91-12.193-5.482-17.233L172.132 247.872h621.896L537.084 611.164z"
            fill="#B0BEC5"
          />
          <g fill="#109E96">
            <path
              d="M936.22 294.377H419.168v86.175H936.22v-86.175zm-28.725 57.45H447.893v-28.725h459.602v28.725zM936.22 468.867H419.168v86.176H936.22v-86.176zm-28.725 57.45H447.893v-28.725h459.602v28.726z"
              stroke="#109E96"
              strokeWidth=".2"
              strokeLinejoin="round"
            />
            <path
              d="M936.22 638.498H419.168v86.175H936.22v-86.175zm-28.725 57.45H447.893v-28.725h459.602v28.725z"
              stroke="#109E96"
              strokeWidth=".2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M289.905 634.77c-26.176 0-47.397 21.22-47.397 47.396 0 26.177 21.22 47.396 47.397 47.396 26.176 0 47.396-21.22 47.396-47.395 0-26.176-21.218-47.398-47.395-47.398zm0 66.067c-10.294 0-18.67-8.376-18.67-18.67 0-10.296 8.376-18.673 18.67-18.673 10.293 0 18.67 8.378 18.67 18.672 0 10.293-8.375 18.67-18.67 18.67zM289.905 462.42c-26.176 0-47.397 21.218-47.397 47.395 0 26.177 21.22 47.397 47.397 47.397 26.176 0 47.396-21.22 47.396-47.395 0-26.176-21.218-47.398-47.395-47.398zm0 66.066c-10.294 0-18.67-8.376-18.67-18.67 0-10.296 8.376-18.672 18.67-18.672 10.293 0 18.67 8.377 18.67 18.67 0 10.295-8.375 18.672-18.67 18.672zM289.905 290.068c-26.176 0-47.397 21.22-47.397 47.396 0 26.178 21.22 47.397 47.397 47.397 26.176 0 47.396-21.218 47.396-47.394s-21.218-47.398-47.395-47.398zm0 66.068c-10.294 0-18.67-8.376-18.67-18.672 0-10.295 8.376-18.67 18.67-18.67 10.293 0 18.67 8.377 18.67 18.67 0 10.294-8.375 18.672-18.67 18.672z" />
          </g>
        </g>
        <text fontFamily="Open Sans, sans-serif" fontSize="12">
          <tspan x="1036.346" y="936" fill="#337AB7">
            Support
          </tspan>{' '}
          <tspan x="1079.69" y="936" fontSize="14" fill="#333">
            {' '}·{' '}
          </tspan>{' '}
          <tspan x="1091.366" y="936" fill="#337AB7">
            Terms Of Service
          </tspan>{' '}
          <tspan x="1184.042" y="936" fontSize="14" fill="#333">
            {' '}·{' '}
          </tspan>{' '}
          <tspan x="1195.718" y="936" fill="#33A6B8" />{' '}
          <tspan x="1199.054" y="936" fill="#333">
            Copyright © 2017 6sense
          </tspan>
        </text>
      </g>
    </svg>
  );
}
