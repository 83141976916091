import { jobsSagas } from '../routes/jobs/sagas';
import { reviewMappingSagas } from '../routes/mappingReview/sagas';
import { overviewSagas } from '../routes/overview/sagas';
import { preferencesSagas } from '../routes/preferences/sagas';
import { productUrlConfigSagas } from '../routes/productUrlConfig/sagas';
import { ruleManagerSagas } from '../routes/ruleManager/sagas';
import {
  loadMappedActivitesStateGenerator,
  loadProductTagMappingGenerator,
  loadRecordsReviewStatusCountGenerator,
  loadUsersGenerator,
} from '../stateGenerators';
import { commonSagas } from './commonSagas';

export const taxonomyV2Sagas = [
  ...ruleManagerSagas,
  ...overviewSagas,
  ...jobsSagas,
  ...commonSagas,
  ...reviewMappingSagas,
  ...preferencesSagas,
  ...productUrlConfigSagas,
  loadMappedActivitesStateGenerator.saga,
  loadRecordsReviewStatusCountGenerator.saga,
  loadProductTagMappingGenerator.saga,
  loadUsersGenerator.saga,
];
