import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'v2-components';
import { DevTools } from 'dev-tools';
import SandboxBadge from 'images/sandbox-badge.svg';
import { pxToRem } from '@sixsense/core/style';
import { Permission, usePermissionCheck } from '@sixsense/rbac';
import { browserHistory } from 'react-router';
import styles from './TopNavPathTitle.module.scss';
import { capitalize } from 'lodash';
import { useSharedValue } from '@sixsense/core';
import { pageTitleState } from 'hooks/usePageTitle';
import { actions as userActions } from 'modules/user/index';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import {
  MODEL_UPDATE_ROUTE,
} from '../../routes/Reports/routes/Reports/routes/ModelUpdate/constants';
import { AUDIENCE_ROUTES } from 'routes/AudienceWorkflows/constants';

const ADVERTISING = 'ADVERTISING';

const SETTINGS_HOME_PATH = 'settings';


const defaultTitleGenerator = (path) => path.split('_').join(' ').toUpperCase();
const defaultSubTitleGenerator = () => '';
const getTitle = ({
  homePath,
  titleGenerator = defaultTitleGenerator,
  subTitleGenerator = defaultSubTitleGenerator,
  skipTitleFilter,
}) => ({
  pathTitle: titleGenerator(homePath),
  pathSubTitle: subTitleGenerator(),
  skipTitleFilter,
});

const TopNavPathTitle = (props) => {
  const { currentPath, isSandbox } = props;
  const dispatch = useDispatch();
  const hasCampaignEditPermission = usePermissionCheck([Permission.CAMPAIGN_EDIT]);
  const hasAdsEditPermission = usePermissionCheck([Permission.ADS_EDIT]);

  const pageTitleOverride = useSharedValue(pageTitleState);

  const pathsConfig = [
    {
      homePath: 'advertising',
      titleGenerator: () => (hasCampaignEditPermission || hasAdsEditPermission) ?
        ADVERTISING : `${ADVERTISING} (VIEW ONLY)`,
    },
    {
      homePath: SETTINGS_HOME_PATH,
    },
    {
      homePath: 'segments',
    },
    {
      homePath: 'discover/inbox',
      titleGenerator: () => 'INBOX',
    },
    {
      homePath: 'dashboard',
    },
    {
      homePath: 'discover',
    },
    {
      homePath: 'alerts',
    },
    {
      homePath: 'linkedinpopup',
    },
    {
      homePath: AUDIENCE_ROUTES.DEFAULT.PATH,
      titleGenerator: () => AUDIENCE_ROUTES.DEFAULT.TITLE,
    },
    {
      homePath: 'audience',
    },
    {
      homePath: 'orchestrations',
    },
    {
      homePath: 'storybook',
    },
    {
      homePath: 'saas',
    },
    {
      homePath: `reports/admin/${MODEL_UPDATE_ROUTE}`,
      titleGenerator: () => 'Model Insights',
      subTitleGenerator: () => 'Understand your model performance',
      skipTitleFilter: true,
    },
    {
      homePath: 'reports',
    },
    {
      homePath: 'smartformfill',
      titleGenerator: () => 'SMART FORM FILL',
    },
  ];

  const getPathTitle = () => {
    if (['discover/keywords', 'discover/account'].includes(currentPath)) {
      return {
        pathTitle: 'DISCOVER',
      };
    }
    if (currentPath.includes('orchestrations/')) {
      return {
        pathTitle: 'ORCHESTRATION',
      };
    }

    const matchedPathConfig = pathsConfig.find((pathConfig) =>
      currentPath.includes(pathConfig.homePath)
    );

    return getTitle(matchedPathConfig || { homePath: '' });
  };

  const {
    pathTitle,
    pathSubTitle,
    skipTitleFilter = false,
  } = getPathTitle();
  const returnHome = () => {
    if ([
      '/discover/keywords',
      '/discover/account',
      '/discover/dashboard',
    ].includes(currentPath)) {
      browserHistory.push('/discover/dashboard');
    } else {
      const pathHome = pathsConfig
        .find(
          (pathConfig) => currentPath.includes(pathConfig.homePath)
        ).homePath;
      if (pathHome === SETTINGS_HOME_PATH) {
        dispatch(userActions.setResetAppSettingRandom(Math.random()));
      }

      browserHistory.push(`/${pathHome}`);
    }
  };

  return pageTitleOverride || (<Row
    alignItems={Row.ALIGN_ITEMS.CENTER}
  >
    <span
      className={styles.pathTitleContainer}
      onClick={() => returnHome()}
    >
      {skipTitleFilter ? pathTitle : capitalize(pathTitle)}
    </span>
    {pathSubTitle ? <span
      className={styles.pathSubTitleContainer}
      onClick={() => returnHome()}
    >
      {pathSubTitle}
    </span> : null}
    <Row
      alignItems={Row.ALIGN_ITEMS.CENTER}
    >
      {!window.process.env.SIXSENSE_ENV.startsWith('prod') ? (
        <DevTools />
          ) : null}
      {isSandbox ? (
        <img
          src={SandboxBadge}
          style={{ marginLeft: pxToRem(20), marginRight: pxToRem(20) }}
          alt="sandbox"
        />
          ) : null}
    </Row>
  </Row>);
};
TopNavPathTitle.propTypes = {
  currentPath: PropTypes.string,
  isSandbox: PropTypes.bool,
};
export default TopNavPathTitle;
