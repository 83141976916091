export const hasRequiredValues = (
  // input: Record<string, any>,
  input: any,
  keys: string[]
): boolean => {
  // Check if input is undefined or null
  if (input === undefined || input === null) {
    return false;
  }

  return keys?.every((key) => {
    const value = input[key];
    return (
      value !== undefined &&
      value !== null &&
      ((typeof value === 'string' && value?.trim() !== '') ||
        (Array.isArray(value) && value.length > 0))
    );
  });
};

export const hasRequiredMarketingValues = (objectsArray: any): boolean => {
  // objectsArray: Array<Record<string, any>>
  if (!Array.isArray(objectsArray) || objectsArray.length === 0) {
    return false; // Return false if the input is not an array or is empty
  }
  return objectsArray.every((obj) => {
    for (const key in obj) {
      if (!obj[key] || obj[key] === null || (typeof obj[key] === 'string' && !obj[key].trim())) {
        return false;
      }
    }
    return true;
  });
};

export const validateFiles = (file: File, items) => {
  if (!validateFileType(file)) {
    return Promise.reject('Invalid File Type');
  } else if (file.size / 1024 / 1024 > 1) {
    return Promise.reject('File is too large');
  } else if (items?.length >= 3) {
    return Promise.reject('Maximum only 3 files allowed');
  }
  return Promise.resolve();
};

export const validateFileType = (file: File) => {
  if (!file) {
    console.error('No file provided.');
    return false;
  }
  const fileName = file.name;
  const validExtensions = ['txt', 'docx', 'pdf'];
  const fileExtension = fileName.split('.').pop().toLowerCase();

  return validExtensions.includes(fileExtension);
};

export const validateUrl = (url) => {
  const urlRegex =
  /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?(\?[^\s#]*)?(#[^\s]*)?$/i;
  return urlRegex.test(url);
};

export const editCaretPosition = (element) => {
  const target = document.createTextNode('');
  element.appendChild(target);
  const isTargetFocused = document.activeElement === element;
  if (target !== null && target.nodeValue !== null && isTargetFocused) {
    const sel = window.getSelection();
    if (sel !== null) {
      const range = document.createRange();
      range.setStart(target, target.nodeValue.length);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
    if (element instanceof HTMLElement) element.focus(); // event if this sub element focus
  }
};

export const checkFieldLengthAndUpdateError = (
  index,
  value,
  maxLength,
  errorStateSetter
) => {
  console.log(index, value, maxLength, errorStateSetter, value?.length, value?.length > maxLength);
  if (value?.length > maxLength) {
    errorStateSetter((prevErrors) => {
      const updatedErrors = [...prevErrors];
      const errorIndex = updatedErrors.findIndex((error) => error.index === index);
      if (errorIndex === -1) {
        updatedErrors.push({ index, hasError: true });
      } else {
        updatedErrors[errorIndex].hasError = true;
      }
      return updatedErrors;
    });
  } else {
    errorStateSetter((prevErrors) =>
      prevErrors.filter((error) => error.index !== index)
    );
  }
};
