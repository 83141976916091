import { Flex } from '@sixsense/core/components';
import React from 'react';
import { Button, Modal, Text, Icon, Link } from 'v2-components';
import { css } from 'styles/emotion';
import { capitalize } from 'lodash';
import { BRANDED, GENERIC } from '../../constants';
import { TablePreviewLayout } from 'aa-components';
import { AAColor } from '@sixsense/core/style';

const { Table } = TablePreviewLayout;

const styles = {
  button: css({
    marginRight: '10px',
    height: '30px',
  }),
  helpIcon: css({
    marginRight: '10px',
  }),
  column: css({
    wordBreak: 'break-word',
    paddingRight: '50px',
    fontSize: '1rem',
    color: `${AAColor.GREY}`,
  }),
  container: css({
    padding: '20px',
    paddingLeft: '24px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #C6CACE',
    borderTop: 'none',
  }),
  faqIcon: css({
    marginLeft: '5px',
    color: `${AAColor.BLUE}`,
  }),
};

const tableHeaders = [
  { columnName: 'Keyword Types', className: css({ width: '20%' }) },
  { columnName: 'Definition', className: css({ width: '60%' }) },
  { columnName: 'Example', className: css({ width: '20%' }) },
];

const categoryDescriptions = {
  [BRANDED]: `Keywords that are closely related to your or your competitor's product. 
    Research activities for Branded keywords are weighted heavily in the model.`,
  [GENERIC]: `These keywords have a wider scope and do not include brands/branded words. Keywords
    should be selected around needs and pain points that people are trying to solve.`,
};

const categoryExamples = {
  [BRANDED]: '6sense company details api',
  [GENERIC]: 'account based ip',
};

type RowProps = {
  category: string;
}

const TableRow = ({ category }: RowProps) => (
  <div className={styles.container}>
    <span className={styles.column} style={{ width: '20%' }}>
      {capitalize(category)}
    </span>
    <span className={styles.column} style={{ width: '60%' }}>
      {categoryDescriptions[category]}
    </span>
    <span className={styles.column} style={{ width: '20%' }}>
      {categoryExamples[category]}
    </span>
  </div>
);

type Props = {
  onCancel: () => void;
};

const CategoryHelpModal = ({ onCancel }: Props) => {
  const footer = (
    <Flex justifyContent={'space-between'} style={{ padding: '10px 0' }}>
      <Link
        link={'https://support.6sense.com/hc/en-us/articles/360051969374-Keyword-Administration'}
        newWindow
      >
        <Flex style={{ cursor: 'pointer', alignItems: 'center' }}>
          <Text color={Text.COLOR.BLUE}>Go to FAQs</Text>
          <Icon type="exit_to_app" className={styles.faqIcon} />
        </Flex>
      </Link>
      <Flex>
        <Button
          pointer
          className={styles.button}
          onClick={() => onCancel()}
        >
          OK
        </Button>
      </Flex>
    </Flex>
  );

  const tableBody = [BRANDED, GENERIC].map((v) => <TableRow category={v} />);

  return (
    <Modal
      visible
      bodyStyle={{ padding: 0 }}
      footer={footer}
      width="900px"
      maskClosable={false}
      onCancel={() => onCancel()}
    >
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9', alignItems: 'center' }}>
        <Icon type="help" className={styles.helpIcon} />
        <Text type={Text.TYPE.TITLE} bold>Help</Text>
      </Flex>
      <div style={{ padding: '20px', marginBottom: '100px' }}>
        <Table
          tableHeader={tableHeaders}
          tableBody={tableBody}
        />
      </div>
    </Modal>
  );
};

export default CategoryHelpModal;
