import { Card, Flex, ResultBoundary } from '@sixsense/core/components';
import { Button, Tooltip2, Link } from 'v2-components';
import React, { useEffect, useMemo } from 'react';
import StageGroup from './components/StageGroup';
import useStageConfigState from './useStageConfigState';
import { useDrop } from 'react-dnd';
import { useRequestValue } from '@sixsense/core';
import { mergeResults } from '@sixsense/core/shared/async-result/utils';
import { stageConfigRequestState, stageOptionsRequestState } from './state';
import { useOrg } from 'hooks';
import { PageHeader } from 'HOCS';
import StageConfigHeader from './StageConfigHeader';
import { SalesStageGroup, StageOption } from './types';
import { buildConfigState } from './utils';
import useStageConfigPermissions from './hooks/useStageConfigPermissions';
import { browserHistory } from 'react-router';
import useCurrentUser from 'hooks/useCurrentUser';
import { checkFunnelInsightsAccess } from 'routes/Reports/routes/Reports/utils';

const STATIC_STAGES = new Set(['No Activity', 'Intent', 'Reached', 'Engaged']);

type Props = {
  stageConfig: SalesStageGroup[];
  stageOptions: StageOption[];
}

const SalesStageConfigPage = ({ stageConfig: initialStageConfig, stageOptions }: Props) => {

  const { canEdit } = useStageConfigPermissions();

  const { stageConfig, groupSelected, selectedStages, setStageConfig } = useStageConfigState();

  useEffect(() => {
    setStageConfig(buildConfigState(initialStageConfig, stageOptions));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStageConfig, stageOptions]);

  const [, drop] = useDrop({ accept: 'SALES_STAGE_GROUP' });

  return (
    <Card style={{ width: '60%', minWidth: 800 }}>
      <Card.Header>
        <Flex justifyContent="space-between" alignItems="center">
          <span>
            <Card.Header.Title>Stages </Card.Header.Title>
            <Card.Header.Subtitle>
              — Rename, Reorder*, Hide and Group stages as per your workflow
            </Card.Header.Subtitle>
          </span>
          {canEdit ? (
            <Tooltip2
              overlay={selectedStages.size < 1 ? 'Select at least 1 stages to group' : null}
            >
              <Button
                type="primary"
                isHeaderButton
                disabled={selectedStages.size < 1}
                onClick={groupSelected}
              >
                Group Selected
              </Button>
            </Tooltip2>
          ) : null}
        </Flex>
      </Card.Header>
      <Card.Content>
        <div ref={drop}>
          {stageConfig.map(({ group_name, stages, display_group }, i) => (
            <StageGroup
              key={group_name}
              name={group_name}
              stages={stages}
              displayGroup={display_group}
              index={i}
              disabled={STATIC_STAGES.has(group_name)}
            />
          ))}
        </div>
      </Card.Content>
    </Card>
  );
};

const CONFIG_FAQ = 'https://support.6sense.com/hc/en-us/articles/4404831069459';

const ConfigHelpSection = () =>
  <Card style={{ fontSize: 14, height: 'fit-content', marginBottom: 20 }}>
    <Card.Content>
      <div style={{ fontWeight: 'bold', marginBottom: 20 }}>
        How to configure your opportunity stages:
      </div>
      <div style={{ marginTop: '10px' }}>
        1. Identify which stages from the list you want to show in the report by clicking
        the <b>eye</b> icon on the right.
      </div>
      <div style={{ marginTop: '10px' }}>
        2. Order the stages from top to bottom to match your internal flow.
      </div>
      <div style={{ marginTop: '10px' }}>
        3. Group stages as needed by checking the box to the left of each stage name, and then
        clicking <b>Group Selected</b> at the top.
      </div>
      <div style={{ marginTop: '20px' }}>
        Newly saved configurations will take up to 24 hours to be reflected in Funnel Insights.
      </div>
      <div style={{ marginTop: '20px' }}>
        Please refer to the
        <Link link={CONFIG_FAQ} newWindow> Report Configuration support article </Link>
        for more details.
      </div>
    </Card.Content>
  </Card>;

const SixsenseStageDefinitions = () =>
  <Card style={{ fontSize: 14, height: 'fit-content' }}>
    <Card.Content>
      <div style={{ fontWeight: 'bold', marginBottom: 20 }}>
        6sense-based Stage Definitions:
      </div>
      <div style={{ marginTop: '10px' }}>
        <b>No Activity:</b> Accounts with no intent, marketing, or sales activities.
      </div>
      <div style={{ marginTop: '10px' }}>
        <b>Intent:</b> Accounts expressing intent that have not been reached or
        engaged by marketing or sales activities.
      </div>
      <div style={{ marginTop: '10px' }}>
        <b>Reached:</b> Accounts that have been reached but not engaged by marketing
        or sales activities.
      </div>
      <div style={{ marginTop: '10px' }}>
        <b>Engaged:</b> Accounts that have been engaged by marketing or sales activities.
      </div>
      <div style={{ marginTop: '10px' }}>
        <b>6QA:</b> Accounts that have met your 6sense Qualified Account threshold.
      </div>
    </Card.Content>
  </Card>;

const StageConfigContainer = () => {
  const org = useOrg();
  const { username } = useCurrentUser();

  const result = useRequestValue(stageConfigRequestState, org.id);
  const optionsResult = useRequestValue(stageOptionsRequestState, org.id);

  const mergedResult = useMemo(() => mergeResults({
    stageConfig: result,
    stageOptions: optionsResult,
  }), [result, optionsResult]);

  const hasAccess = useMemo(() => checkFunnelInsightsAccess(org, username), [org, username]);

  useEffect(() => {
    if (!hasAccess) {
      browserHistory.replace('/');
    }
  }, [hasAccess]);

  if (!hasAccess) {
    return null;
  }


  return (
    <ResultBoundary result={mergedResult}>
      {({ data }) => (
        <Flex>
          <SalesStageConfigPage {...data} />
          <div>
            <ConfigHelpSection />
            <SixsenseStageDefinitions />
          </div>
        </Flex>
      )}
    </ResultBoundary>
  );
};

export default PageHeader([StageConfigHeader])(StageConfigContainer);
