import React from "react";
import { Text } from "@sixsense/core/components";
import { formatDate } from "utils/utils";
import { DATE_FORMAT } from "utils/constants";
import { NA } from "./common/NA";
import { CAMPAIGN_SOURCE } from "../../../constants";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const END_DATE = {
  sortByKey: "end_date",
  key: "end-date",
  csvColumnKey: "end_date",
  title: <Label name={CAMPAIGN_LABELS.endDate} />,
  dataIndex: "end_date",
  width: pxToRem(130),
  render: (endDate, campaign) => {
    const noEndDate = campaign.is_always_on === true ? "No end date" : "-";
    if (campaign.campaign_source === CAMPAIGN_SOURCE.EXTERNAL) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {endDate ? formatDate(DATE_FORMAT)(endDate) : noEndDate}
      </Text.Body>
    );
  },
};
