export default function createRoutes() {
  return {
    path: 'psychographics',
    name: 'psychographics',
    getComponent(nextState, cb) {
      import('./containers/Psychographics').then((page) => {
        cb(null, page.default);
      });
    },
  };
}

