import React from 'react';
import Text from 'v2-components/Text';
import { COLUMN_CATEGORIES } from '../constants';
import { CAMPAIGN_SOURCE } from '../../../constants';
import { NA } from './common/NA';
import { safePercent } from 'utils/utils';
import { ROW_DATA_TYPES } from 'aa-components/CampaignCSVExportModal/constants';
import { get } from 'lodash';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';

export const MATCH_RATE = {
  category: COLUMN_CATEGORIES.externalMedia,
  key: 'match-rate',
  csvColumnKey: 'match_rate',
  title: <Label name={CAMPAIGN_LABELS.matchRate} />,
  dataIndex: 'campaign_data',
  width: 128,
  render: (campaignData, campaign) => {
    if (campaign.campaign_source !== CAMPAIGN_SOURCE.EXTERNAL) {
      return (<NA />);
    }

    const { media_impressions, total_media_impressions } = campaignData;
    const matchRate = total_media_impressions ?
      safePercent(media_impressions, total_media_impressions, 3) :
      0;

    return (<Text type={Text.TYPE.SUBBODY}>{matchRate}%</Text>);
  },
  csvOverrides: {
    dataIndex: 'download_stats',
    render: (downloadStats, campaign, dataType) => {
      if (campaign.campaign_source === CAMPAIGN_SOURCE.EXTERNAL ||
        dataType === ROW_DATA_TYPES.aggregated) {

        const mediaImpressions = get(downloadStats, 'media_impressions', 0);
        const totalMediaImpressions = get(downloadStats, 'total_media_impressions', 0);
        const matchRate = totalMediaImpressions ?
          safePercent(mediaImpressions, totalMediaImpressions, 3) :
          0;
        return `"${matchRate}%"`;
      }

      return '-';
    },
  },
};
