import React from 'react';
import { BetaTag } from '../assets';

const BETA_TAG = 'BETA_TAG';

type TTagsIconProps = {
  className?: string;
  size?: string;
  type: string;
}

const TagsIcon = (props: TTagsIconProps) => {
  const { className, size, type } = props;
  switch (type) {
    case BETA_TAG:
      return <BetaTag className={className} size={size} />;
    default:
      return null;
  }
};

TagsIcon.BETA_TAG = BETA_TAG;

export default TagsIcon;
