import React from 'react';
import { Flex, ResultBoundary, Spinner } from '@sixsense/core/components';
import { useRequestValue, useSharedState } from '@sixsense/core';
import { usersListRefetcherState, usersListRequestState } from '../../state';
import { CreditErrorDisplay } from '../CreditErrorDisplay';


const STRINGS = {
  FETCH_ERROR: 'Error Fetching Users List. Please try again.',
};

type TUsersListFetcherProps = {
  orgId: number;
  orgPoolId: number;
  children: (data: any) => React.ReactNode;
};
const UsersListFetcher = ({
  orgId,
  orgPoolId,
  children,
}: TUsersListFetcherProps) => {
  const [refreshOnChange] = useSharedState(usersListRefetcherState);

  const nodeListResult = useRequestValue(usersListRequestState, {
    orgId,
    orgPoolId,
    refreshOnChange,
  });
  return (
    <ResultBoundary
      result={nodeListResult}
      renderLoading={() => (
        <Flex className="w100 h100 dFlexCenter">
          <Spinner />
        </Flex>
      )}
      renderError={() => <CreditErrorDisplay message={STRINGS.FETCH_ERROR} />}
    >
      {({ data }) => children(data)}
    </ResultBoundary>
  );
};

export default UsersListFetcher;
