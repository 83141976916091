import React from 'react';
import styles from './RadioGroup.module.scss';
import PropTypes from 'prop-types';
import { Row, Radio } from 'v2-components';
import { classNames } from 'utils/utils';
import { isEmpty } from 'lodash';

const radioGroupPropTypes = {
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  options: PropTypes.array,
};

/**
* Radio Group Component
*
* @param input - redux-form Input
* @param {string} className - Apply styles to parent container
* @param {func} onChange - to invoke when clicking Radio Button
* @param {array} options - Array to populate Radio options
* @param {string} labelKey - key name to show the radio option Label,
* @param {string} valueKey - key name to get the value of the selected radio option,
* @param {string} selectedValue - Send this value in we are not using redux-form Field Wrapper
* @param {bool} isVertical - If we want to place the radio options vertically.
* @param {bool} disableGroup - Disable radio group conditionally.
*/

const RadioGroup = (props) => {
  const {
    input = {},

    onChange,
    options,
    labelKey,
    valueKey,
    className,
    isVertical,
    selectedValue,
    disableGroup,
    // As of now using restProps to send the required props to handle/style inner Radio component
    ...restProps
  } = props;

  const { value: formValue = '' } = input;
  const selectedRadioValue = formValue || selectedValue;

  const onChangeEvent = (v) => {
    if (!isEmpty(input)) { input.onChange(v); }
    onChange(v);
  };

  const radioStyles = `${styles.radioStyle}`;
  const radioOptionsMenu = (radioOptions, restProp) => radioOptions.map((radioOption) =>
  (
    <div className={radioStyles} key={radioOption[valueKey]}>
      <Radio
        selected={radioOption[valueKey] === selectedRadioValue}
        action={onChangeEvent}
        item={{ value: radioOption[valueKey], label: radioOption[labelKey] }}
        radioClassName={classNames(styles.radioClass,
          disableGroup && styles.disabled)}
        {...radioOption}
        {...restProp}
      >
        {radioOption[labelKey]}
      </Radio>
    </div>
  ));

  const radioGroupStyles = `${disableGroup ? styles.disabledGroupStyle : undefined}`;

  return (
    <Row
      className={classNames(styles.container, className, radioGroupStyles)}
      flexDirection={isVertical ? Row.FLEX_DIRECTION.COLUMN : undefined}
    >
      {radioOptionsMenu(options, restProps)}
    </Row>
  );
};

RadioGroup.defaultProps = {
  onChange: () => ({}),
  labelKey: 'label',
  valueKey: 'value',
  isVertical: false,
  disableGroup: false,
};

RadioGroup.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  onChange: PropTypes.func,
  selectedValue: PropTypes.string,
  isVertical: PropTypes.bool,
  disableGroup: PropTypes.bool,
  className: PropTypes.string,
  ...radioGroupPropTypes,
};

export default RadioGroup;
