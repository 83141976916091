import React, { useEffect } from 'react';
import { compose } from 'redux';
import { PageHeader } from 'HOCS';
import { Permission, withPermissionBoundary } from '@sixsense/rbac';
import KeywordsPageHeader from './KeywordsPageHeader';
import { AccessDenied } from 'v2-components';
import IntentKeywordsContainer from './containers/IntentKeywordsContainer';
import KeywordGroupsContainer from './containers/KeywordGroupsContainer';
import DeactivatedKeywordsContainer from './containers/DeactivatedKeywordsContainer';
import KeywordGroupKwListContainer from './containers/KeywordGroupKwListContainer';
import { KEYWORDS, GROUPS, DEACTIVATED, GROUP_KW_LIST } from './constants';
import { activePageState, orgProductState, productsRequestState,
   siKeywordsValidationState } from './state';
import { useSharedValue, useSharedState, useRequestValue, useSetSharedValue } from '@sixsense/core';
import { ResultBoundary, Loading } from '@sixsense/core/components';
import { siPackagePlanNameSelector, isOrgSiOnlySelector } from 'routes/Settings/selectors';
import { connect } from 'react-redux';
import { SI_PlANS } from 'routes/Settings/constants';

type ContainerProps = {
  products: any;
}

type KeywordsProps = {
  siPackagePlanName : string,
  isOrgOnlySI: boolean,
}

const PageContainer = ({ products }: ContainerProps) => {
  const [orgProducts, setOrgProducts] = useSharedState(orgProductState);
  const activePage = useSharedValue(activePageState);

  useEffect(() => {
    if (!orgProducts.length) {
      setOrgProducts(products.filter((p) => !p.isDeleted));
    }
  });

  switch (activePage) {
    case KEYWORDS:
      return <IntentKeywordsContainer />;
    case GROUPS:
      return <KeywordGroupsContainer />;
    case GROUP_KW_LIST:
      return <KeywordGroupKwListContainer />;
    case DEACTIVATED:
      return <DeactivatedKeywordsContainer />;
    default:
      return null;
  }
};

const KeywordsPage = ({ siPackagePlanName, isOrgOnlySI = false } : KeywordsProps) => {
  const productsRequest = useRequestValue(productsRequestState, []);
  const setSiValidations = useSetSharedValue(siKeywordsValidationState);
  setSiValidations({
    keywordGroup: siPackagePlanName ?
    (siPackagePlanName !== SI_PlANS.PLAN_D || !isOrgOnlySI) : true,
    addKeyword: siPackagePlanName ? !isOrgOnlySI : true,
  });
  return (
    <ResultBoundary
      result={productsRequest}
      renderLoading={() => <Loading.Spinner level="page" />}
    >
      {({ data }) => (
        <PageContainer products={data} />
      )}
    </ResultBoundary>
  );
};
const mapStateToProps = (state) => ({
  siPackagePlanName: siPackagePlanNameSelector(state),
  isOrgOnlySI: isOrgSiOnlySelector(state),
});

export default compose(
  withPermissionBoundary({
    allow: (permissions) => [
      Permission.SETTINGS_KEYWORDS_VIEW,
      Permission.SETTINGS_KEYWORDS_EDIT,
    ].some((permission) => permissions.has(permission)),
    renderDenied: () => <AccessDenied accessDenied />,
  }),
  connect(mapStateToProps),
  PageHeader([KeywordsPageHeader])
)(KeywordsPage);

