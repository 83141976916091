/* eslint-disable no-unused-vars */
/** @jsx jsx */
import React, { MouseEventHandler } from 'react';
import { jsx, css } from '@emotion/react';
import { CloseButton, FeaturedIcon, Flex, Text } from '@sixsense/core/components';
import { FileUploadItem } from '@sixsense/core/components/data-entry/FileUpload';
import { FileDoc } from '@sixsense/core/icons';
import { BorderRadius, RootColor, TextColor, ThemeColor } from '@sixsense/core/style';


export type FileUploadItemProps = {
  item: FileUploadItem;
  onRemove: () => void;
  showFileIcon?: boolean;
  disabled?: boolean;
};

const FileUploadIcon = () => (
  <FeaturedIcon
    color="Primary"
    icon={FileDoc}
    size="sm"
    theme="light-circle-outline"
  />
);

const styles = {
  container: css({
    position: 'relative',
    padding: 16,
    border: `1px solid var(${RootColor.Gray.LIGHT_2})`,
    borderRadius: `var(${BorderRadius.sm})`,
    backgroundColor: `var(${RootColor.White})`,
  }),
  doneContainer: css({
    borderColor: `var(${ThemeColor.Primary.DEFAULT})`,
  }),
  closeButton: css({
    position: 'absolute',
    top: 6,
    right: 6,
  }),
  text: css({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
};

const FileListItem = ({
  item,
  showFileIcon,
  onRemove,
  disabled,
}: FileUploadItemProps) => {

  const handleClose: MouseEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onRemove();
  };

  return (
    <Flex
      gap={16}
      alignItems={'center'}
      css={[styles.container, styles.doneContainer]}
    >
      {showFileIcon ? (
        <FileUploadIcon fileName={item.file.name} status={item.uploadStatus.status} />
      ) : null}
      <Flex gap={4} css={{ flex: 1, overflow: 'hidden' }}>
        <Text.Body
          weight="semibold"
          color={`var(${TextColor.HEAD})`}
          css={styles.text}
        >
          {item.file.name}
        </Text.Body>
      </Flex>
      <Flex gap={4} css={{ flex: 1, overflow: 'hidden' }}>
        <CloseButton
          size="small"
          onClick={handleClose}
          css={styles.closeButton}
          disabled={disabled}
        />
      </Flex>
    </Flex>
  );
};

export default FileListItem;
