import React from 'react';

/* eslint max-len: [2, 100000] */
export function Galaxy() {
  return (
    <svg width="121" height="109" viewBox="0 0 121 109" xmlns="http://www.w3.org/2000/svg">
      <title>Galaxy</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M69.506 57.488c-2.09 3.376-6.713 4.778-10.326 3.133-3.613-1.645-4.85-5.716-2.76-9.093 2.09-3.376 6.714-4.778 10.327-3.132 3.613 1.646 4.848 5.716 2.76 9.093"
          fill="#FFDCA1"
        />
        <path
          d="M69.506 57.488l-1.7-1.052c-.545.88-1.344 1.6-2.29 2.102-.946.5-2.032.773-3.092.772-.843 0-1.667-.17-2.415-.51-.885-.403-1.522-.974-1.953-1.63-.43-.657-.652-1.402-.653-2.188.002-.774.216-1.59.718-2.4.544-.882 1.342-1.603 2.29-2.104.945-.5 2.03-.774 3.09-.773.844 0 1.667.168 2.416.51.883.403 1.52.974 1.95 1.63.43.657.653 1.402.654 2.188 0 .773-.215 1.59-.717 2.403l1.7 1.052 1.702 1.052c.875-1.413 1.316-2.975 1.315-4.507 0-1.557-.453-3.08-1.306-4.38-.85-1.302-2.1-2.38-3.64-3.078-1.305-.595-2.7-.87-4.073-.87-1.732 0-3.44.432-4.963 1.237-1.522.806-2.868 1.997-3.82 3.533-.876 1.413-1.317 2.975-1.316 4.507 0 1.557.454 3.08 1.307 4.38.852 1.302 2.102 2.38 3.64 3.08 1.306.593 2.7.868 4.074.868 1.732 0 3.44-.432 4.962-1.237 1.523-.806 2.87-1.996 3.82-3.532l-1.7-1.052"
          fill="#2D3666"
        />
        <path
          d="M63.176 51.828c-1.1 0-2.2.554-2.79 1.506-.822 1.33-.336 2.933 1.087 3.58.405.185.84.273 1.278.273 1.1 0 2.2-.553 2.79-1.505.823-1.33.337-2.934-1.086-3.582-.405-.184-.84-.272-1.278-.272"
          fill="#FFC675"
        />
        <path
          d="M57.065 74.793c-3.578 0-7.15-.713-10.46-2.22-7.05-3.212-12.098-8.817-14.212-15.784-2.114-6.968-1.033-14.434 3.045-21.022 10.243-16.548 32.986-23.45 50.697-15.382 18.01 8.204 26.934 26.798 21.704 45.217-.303 1.062-1.41 1.678-2.47 1.378-1.064-.3-1.68-1.407-1.38-2.47 4.678-16.472-3.346-33.12-19.512-40.484-15.944-7.26-36.417-1.05-45.638 13.847-3.465 5.598-4.396 11.903-2.62 17.754 1.776 5.852 6.053 10.577 12.044 13.306 9.886 4.504 22.58.652 28.297-8.585.58-.94 1.814-1.23 2.754-.648.94.58 1.23 1.814.648 2.753-4.827 7.798-13.873 12.34-22.898 12.34M101.39 77.227c-.358 0-.72-.097-1.05-.3-.938-.58-1.23-1.812-.648-2.752.417-.675.817-1.365 1.19-2.052.524-.97 1.737-1.333 2.71-.81.97.526 1.333 1.74.807 2.712-.41.755-.848 1.514-1.306 2.254-.378.61-1.033.947-1.703.947"
          fill="#2D3666"
        />
        <path
          d="M37.423 38.358c-.36 0-.723-.097-1.05-.3-.94-.58-1.23-1.813-.65-2.753 11.22-18.13 34.03-27.76 55.467-23.424 1.082.22 1.782 1.275 1.564 2.358-.22 1.082-1.276 1.785-2.357 1.563-19.83-4.008-40.914 4.875-51.27 21.61-.38.612-1.034.948-1.704.948M100.89 19.104c-.278 0-.56-.06-.828-.18-.573-.26-1.152-.51-1.734-.747-1.023-.413-1.518-1.58-1.103-2.602.414-1.025 1.58-1.52 2.604-1.104.633.256 1.263.528 1.888.813 1.005.457 1.45 1.64.993 2.647-.335.736-1.06 1.174-1.82 1.174"
          fill="#2D3666"
        />
        <path
          d="M55.76 91.855c-5.437 0-10.867-1.085-15.895-3.374-18.01-8.203-26.935-26.797-21.704-45.217.302-1.06 1.407-1.68 2.47-1.377 1.064.3 1.68 1.406 1.38 2.47-4.68 16.472 3.346 33.12 19.513 40.483C57.466 92.1 77.94 85.89 87.16 70.993c3.465-5.597 4.395-11.904 2.62-17.755-1.775-5.852-6.052-10.577-12.043-13.306-9.886-4.505-22.58-.65-28.297 8.586-.58.938-1.814 1.23-2.754.646-.94-.58-1.23-1.813-.648-2.752 6.74-10.888 21.705-15.425 33.357-10.122 7.052 3.213 12.1 8.82 14.213 15.786C95.72 59.044 94.64 66.51 90.562 73.1c-7.335 11.85-21.084 18.754-34.8 18.755M23.358 37.794c-.32 0-.646-.077-.95-.24-.97-.526-1.333-1.74-.807-2.71.413-.765.853-1.524 1.307-2.256.58-.94 1.814-1.23 2.753-.648.94.582 1.23 1.815.647 2.753-.412.667-.812 1.358-1.188 2.052-.363.67-1.05 1.05-1.762 1.05"
          fill="#2D3666"
        />
        <path
          d="M45.166 98.017c-3.452 0-6.92-.336-10.355-1.03-1.083-.22-1.782-1.275-1.564-2.358.218-1.084 1.27-1.787 2.357-1.564 19.831 4.008 40.913-4.875 51.27-21.61.582-.938 1.814-1.228 2.754-.647.94.58 1.23 1.812.648 2.752-9.422 15.222-27.018 24.455-45.11 24.455M26.92 94.543c-.25 0-.503-.048-.75-.147-.634-.257-1.265-.528-1.89-.813-1.005-.458-1.448-1.644-.99-2.65.46-1.005 1.647-1.447 2.65-.99.573.263 1.15.51 1.732.746 1.024.413 1.518 1.58 1.103 2.602-.314.778-1.063 1.25-1.854 1.25"
          fill="#2D3666"
        />
        <path
          d="M69 103.162l-.05.154c-.1.298-.336.533-.634.632l-.154.052.154.05c.298.1.533.336.633.634l.05.153.05-.153c.1-.298.335-.534.633-.633l.155-.05-.155-.052c-.298-.1-.533-.334-.632-.632l-.05-.154"
          fill="#9AE0E6"
        />
        <path
          d="M69 99c-.432 0-.812.274-.95.684l-.84 2.525-2.526.84c-.41.138-.684.518-.684.95 0 .432.274.812.684.95l2.525.84.84 2.526c.138.41.518.684.95.684.432 0 .812-.274.948-.684l.842-2.525 2.526-.84c.41-.138.684-.518.684-.95 0-.432-.274-.812-.684-.95l-2.526-.84-.842-2.526c-.136-.41-.516-.684-.948-.684m-.05 4.316l.05-.154.05.154c.1.298.335.533.633.632l.155.052-.155.05c-.298.1-.533.336-.632.634l-.05.154-.05-.154c-.1-.298-.336-.533-.634-.633l-.154-.05.154-.052c.298-.1.533-.334.633-.632M4 50.91c-.028.032-.057.062-.09.09.033.028.062.057.09.09.028-.033.057-.062.09-.09-.033-.028-.062-.058-.09-.09"
          fill="#9AE0E6"
        />
        <path
          d="M4 47c-.432 0-.812.274-.95.684l-.59 1.776-1.777.592C.273 50.188 0 50.568 0 51c0 .432.274.812.683.948l1.776.593.59 1.776c.138.41.518.684.95.684.432 0 .812-.274.948-.684l.593-1.775 1.776-.592c.41-.136.684-.516.684-.948 0-.432-.274-.812-.684-.948L5.54 49.46l-.592-1.776C4.812 47.274 4.432 47 4 47m-.09 4c.032-.028.062-.057.09-.09.028.033.057.062.09.09-.033.028-.062.058-.09.09-.028-.032-.058-.062-.09-.09M5 68c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M45 5.162l-.05.154c-.1.298-.336.533-.633.632L44.162 6l.155.05c.297.1.532.335.632.633l.05.154.05-.154c.1-.298.336-.533.634-.632l.154-.05-.154-.052c-.298-.1-.533-.334-.633-.632L45 5.162"
          fill="#9AE0E6"
        />
        <path
          d="M45 1c-.432 0-.812.274-.948.684L43.21 4.21l-2.526.84C40.274 5.19 40 5.57 40 6c0 .432.274.812.684.95l2.526.84.842 2.526c.136.41.516.684.948.684.432 0 .812-.274.95-.684l.84-2.525 2.526-.84C49.726 6.81 50 6.43 50 6c0-.432-.274-.812-.684-.95l-2.525-.84-.84-2.526C45.81 1.274 45.43 1 45 1m-.05 4.316l.05-.153.05.153c.1.298.336.534.634.633l.154.05-.154.052c-.298.1-.533.334-.633.632l-.05.154-.05-.154c-.1-.298-.336-.533-.633-.632L44.162 6l.155-.05c.297-.1.532-.336.632-.634M77 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M27 20c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M93 94c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M119 68c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M116 34.91c-.028.032-.057.062-.09.09.033.028.062.057.09.09.028-.033.058-.062.09-.09-.032-.028-.062-.058-.09-.09"
          fill="#9AE0E6"
        />
        <path
          d="M116 31c-.432 0-.812.274-.948.683l-.592 1.776-1.776.59c-.41.138-.684.518-.684.95 0 .432.274.812.684.95l1.775.59.592 1.776c.136.41.516.684.948.684.432 0 .812-.274.948-.684l.593-1.776 1.776-.59c.41-.138.684-.518.684-.95 0-.432-.274-.812-.684-.95l-1.775-.59-.592-1.777c-.136-.41-.516-.683-.948-.683m-.09 4c.033-.028.062-.058.09-.09.028.032.058.062.09.09-.032.027-.062.057-.09.09-.028-.033-.057-.063-.09-.09"
          fill="#9AE0E6"
        />
      </g>
    </svg>
  );
}
