/* eslint-disable max-len */
import { createSelector } from 'reselect';
import {
  get,
  pick,
  find,
  omit,
  uniq,
  map,
  filter,
  lowerCase,
  isString,
  difference,
  orderBy,
  isNil,
  size,
  isEqual,
  without,
} from 'lodash';
import { queryStringParamsSelector } from 'modules/global/selectors';
import {
  orgSelector,
  predictiveProductsSelector,
  orgObjSelector,
  userSelector,
} from 'modules/user/selectors';
import { getFormValues } from 'redux-form';
import { formatAlert, formatFiltersetResponse } from './utils';
import moment from 'moment';
import { WEEKLY } from './constants';

const alertModeSelector = createSelector(
  queryStringParamsSelector,
  ({ mode }) => mode || '',
);

const segmentIdSelector = createSelector(
  queryStringParamsSelector,
  ({ segmentId }) => segmentId || '',
);


const _alertsSelector = (state) => state.alerts;

const alertFrequencySelector = createSelector(
  _alertsSelector, ({ frequency }) => frequency,
);
const alertDayOfWkSelector = createSelector(
  _alertsSelector, ({ dayOfWk }) => dayOfWk,
);
const alertSendEmptySelector = createSelector(
  _alertsSelector, ({ send_empty }) => send_empty,
);

const crmRecipientsSelector = createSelector(
  _alertsSelector,
  (alerts) => alerts.crm_recipients || []
);

const intentFilterlogicErrorSelector = createSelector(
  _alertsSelector, ({ intentFilterLogicError }) => intentFilterLogicError,
);

const alertListEndpointSelector = createSelector(
  orgSelector,
  () => 0,
  () => 1000,
  (orgId, offset, limit) => `org/${orgId}/alert/?limit=${limit}&offset=${offset}`,
);

const slackTokenState = createSelector(
  _alertsSelector,
  (alerts) => alerts.slackTokenState
);

const alertCategoryMetadataSelector = createSelector(
  _alertsSelector,
  (alerts) => alerts.alertCategoryMetadata
);

const visitedFormStepsSelector = createSelector(
  _alertsSelector,
  (alerts) => alerts.visitedFormSteps
);

const defaultEngagementActivitiesSelector = createSelector(
  _alertsSelector,
  (alerts) => alerts.defaultEngagementActivities
);

const recipientsSelector = createSelector(
  _alertsSelector,
  (alerts) => alerts.recipients
);

const categoriesListSelector = createSelector(
  _alertsSelector,
  (alerts) => alerts.categoriesList
);

const intentFiltersetSelector = createSelector(
  _alertsSelector,
  (alerts) => alerts.intentFilterset
);

const webVisitsFiltersetSelector = createSelector(
  _alertsSelector,
  (alerts) => alerts.webVisitsFilterset
);

const categoriesDetailsSelector = createSelector(
  _alertsSelector,
  (alerts) => alerts.categoriesDetail
);

const additionalSettingsSelector = createSelector(
  _alertsSelector,
  (alerts) => alerts.additionalSettings
);

const alertIsSelectTypeSelector = createSelector(
  _alertsSelector,
  (alertState) => alertState.isTypeSelect,
);

const alertTypeSelector = createSelector(
  _alertsSelector, ({ alertType }) => alertType || '',
);

const productIdSelector = createSelector(
  _alertsSelector, ({ productId }) => productId || '',
);

const isAlertActiveSelector = createSelector(
  _alertsSelector, ({ isActive }) => isActive,
);


const newAlertEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `org/${orgId}/alert/`,
);

const alertRecipinetTypEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `org/${orgId}/alert_recipient_type/`,
);

const alertCRMRecipinetListEndpointSelector = createSelector(
  orgSelector,
  recipientsSelector,
  (orgId, { recipient_account_type }) => `org/${orgId}/alert_crm_recipient/${recipient_account_type}/`,
);

const alertLoadingRecipientTypesSelector = createSelector(
  _alertsSelector,
  (alertState) => alertState.loadingRecipientTypes,
);

const alertLoadingSegmentSelector = createSelector(
  _alertsSelector,
  ({ loadingGetSegment }) => loadingGetSegment
);

const alertLoadingSelector = createSelector(
  _alertsSelector,
  (alertState) => alertState.loading
);

const alertFormFieldSelector = (path, defaultValue = '') => createSelector(
  _alertsSelector,
  (alertState) => get(alertState, path, defaultValue),
);

const alertRecipientAccountTypeSelector = createSelector(
  _alertsSelector,
  (alertState) => {
    const recipientTypes = get(alertState, 'recipients.recipientTypes');
    const recipientAccountType = get(alertState, 'recipients.recipient_account_type');
    const labelObj =
      recipientTypes && recipientTypes.find((el) => el.name === recipientAccountType);
    return labelObj && labelObj.label;
  }
);

const alertListSelector = createSelector(
  _alertsSelector,
  ({ alertList = [] }) => alertList,
);

const alertSearchTermSelector = createSelector(
  _alertsSelector,
  ({ alertSearchTerm }) => alertSearchTerm || '',
);

const alertListSortBySelector = createSelector(
  _alertsSelector,
  ({ sortBy, sortOrder }) => [sortBy, sortOrder],
);

const filteredAlertListSelector = createSelector(
  alertListSelector,
  alertSearchTermSelector,
  (alertList, term) => filter(
    alertList,
    ({ name }) => lowerCase(name).match(lowerCase(term))
  ),
);

const DATES = ['lastSent', 'createdDate'];
const formattedAlertListSelector = createSelector(
  filteredAlertListSelector,
  alertListSortBySelector,
  (alertList, [sortBy, sortOrder]) => orderBy(
    map(alertList, formatAlert),
    [(v) => DATES.includes(sortBy)
      ? moment(get(v, sortBy)).unix()
      : lowerCase(get(v, sortBy))],
    [sortOrder % 2 === 0 // eslint-disable-line
      ? (DATES.includes(sortBy) ? 'asc' : 'desc') // sort order for dates is reversed
      : (DATES.includes(sortBy) ? 'desc' : 'asc'),
    ]
  ),
);

const alertStatusStateSelector = createSelector(
  _alertsSelector,
  (alertState) => pick(alertState, ['loading', 'loaded', 'error'])
);

const _segmentSearchSelector = createSelector(
  _alertsSelector, ({ segmentSearch }) => segmentSearch,
);

const segmentsSelector = createSelector(
  _segmentSearchSelector, ({ segments = [] } = {}) => segments,
);
const segmentSearchTermSelector = createSelector(
  _segmentSearchSelector, ({ searchTerm = '' } = {}) => searchTerm,
);
const segmentLoadingSelector = createSelector(
  _segmentSearchSelector, ({ loading = false } = {}) => loading,
);
const segmentMoreAvailableSelector = createSelector(
  _segmentSearchSelector, ({ moreAvailable = false } = {}) => moreAvailable,
);
const segmentOffsetSelector = createSelector(
  _segmentSearchSelector, ({ offset = 0 } = {}) => offset,
);
const segmentLimitSelector = createSelector(
  _segmentSearchSelector, ({ limit = 100 } = {}) => limit,
);

const searchEndpointSelector = createSelector(
  orgSelector,
  segmentOffsetSelector,
  segmentLimitSelector,
  segmentSearchTermSelector,
  (org, offset, limit, searchInput) => `tally_legacy/${org}/segment/?name_contains=${searchInput}`
    + `&ordering=updated&offset=${offset}&limit=${10}`,
);

const hasCrmFlagsSelector = createSelector(
  orgObjSelector,
  ({ flags } = {}) =>
    flags.has_crm_user && flags.has_crm_account
);
const abmAlertsEnabledSelector = createSelector(
  orgObjSelector,
  ({ flags } = {}) => flags.abm_alerts_enabled !== false
);

// if crm user and has crm account, show associated with recipient type field
// if recipientTypes is empty (because /alert_recipient_type api fails or due to whatever
// reason), do not show associated with recipient type field
const canShowAccountOwnerOptionSelector = createSelector(
  hasCrmFlagsSelector,
  alertFormFieldSelector('recipients.recipientTypes', []),
  (hasCrmFlags, recipientTypes) => size(recipientTypes) !== 0 && hasCrmFlags
);

const newAlertValuesSelector = createSelector(
  _alertsSelector,
  canShowAccountOwnerOptionSelector,
  ({
    send_empty,
    recipients: {
      list = [],
      all_account_owners = true,
      associated_with_recipient = false,
      recipient_account_type = '',
      recipient_type = [],
      send_without_recipient = false,
    },
  },
    canShowPredictive
  ) => {
    const alertsValue = {
      all_account_owners,
      send_empty,
      associated_with_recipient,
    };
    if (!canShowPredictive || !associated_with_recipient || send_without_recipient) {
      alertsValue.emails = list;
    }
    if (associated_with_recipient && canShowPredictive) {
      alertsValue.recipient_account_type = recipient_account_type;
      alertsValue.send_without_recipient = send_without_recipient;
      alertsValue.recipient_type = recipient_type;
    }

    return alertsValue;
  }
);

const alertFormValueSelector = (formName) =>
  (state) => getFormValues(formName)(state);

const formValuesSelector = createSelector(
    alertFormValueSelector('editAlert'),
    (formValues) => formValues || {},
  );

// combines form values with store values and grabs product, formatted to send to api
const newAlertSelector = createSelector(
  newAlertValuesSelector,
  alertFormValueSelector('editAlert'),
  orgSelector,
  canShowAccountOwnerOptionSelector,
  userSelector,
  intentFiltersetSelector,
  webVisitsFiltersetSelector,
  alertCategoryMetadataSelector,
  categoriesDetailsSelector,
  additionalSettingsSelector,
  categoriesListSelector,
  isAlertActiveSelector,
  alertDayOfWkSelector,
  alertFormValueSelector('editAlertName'),
  alertSendEmptySelector,
  crmRecipientsSelector,
  (storeValues,
    formValues,
    org,
    canShowPredictive,
    user_id,
    intentFilterset,
    webVisitsFilterset,
    alertCategoryMetadata,
    categoriesDetails,
    additionalSettings,
    categoriesList,
    isActive,
    dayOfWk,
    newAlertName,
    send_empty,
    crm_recipients,
  ) => {
    const recipient_type = [];
    const { categories, filtersets_map } = alertCategoryMetadata;

    const getFiltersetValue = (type) => {
      switch (type) {
        case 'recent_web_visits': {
          const updatedFilters = map(webVisitsFilterset.filters, (__filter) => ({
            ...__filter,
            filter: {
              ...__filter.filter,
              variable: filtersets_map[__filter.filter.variable].filter_name,
              metadata: filtersets_map[__filter.filter.variable].metadata,
            },
          }));
          return formatFiltersetResponse(
            { ...webVisitsFilterset, filters: updatedFilters }, org, user_id, 'alert'
          );
        }
        case 'recent_intent_activities': {

          const updatedFilters = map(intentFilterset.filters, (__filter) => ({
            ...__filter,
            filter: {
              ...__filter.filter,
              variable: filtersets_map[__filter.filter.variable].filter_name,
              metadata: filtersets_map[__filter.filter.variable].metadata,
            },
          }));
          return formatFiltersetResponse({ ...intentFilterset, filters: updatedFilters }, org, user_id, 'alert');
        }
        default: {
          return null;
        }

      }
    };
    const categories_detail = map(categories, ({ type }) => {
      const filterset = getFiltersetValue(type);
      const { selection_type, sub_selections, is_enabled } = categoriesDetails[type];
      return {
        ...categoriesDetails[type],
        type,
        sub_selections,
        ...((filterset && selection_type ==='specific' && is_enabled) && { filterset }),
      };
    });
    const { show_link_to_abm, show_link_to_sales_intelligence } = additionalSettings;
    if (storeValues.recipient_account_type) {
      recipient_type.push(storeValues.recipient_account_type);
    }
    if (storeValues.recipient_type) {
      recipient_type.push(...storeValues.recipient_type);
    }

    const { alertType, alertFrequency, productCategory } = formValues;
    return {
      name: get(newAlertName, 'name'),
      org,
      ...omit(formValues, ['email_entry', 'product', 'segment', 'alertType', 'alertFrequency', 'productCategory']),
      ...storeValues,

      // we default to all account owners, but if the user is not a predictive
      //  user they can't change it and we have to do it here
      ...(canShowPredictive === false && storeValues.all_account_owners === true)
        ? { all_account_owners: false }
        : {},

      // we default to all associated_with_recipient, but if the user is not a predictive
      //  user they can't change it and we have to do it here
      ...(canShowPredictive === false && storeValues.associated_with_recipient === true)
        ? { associated_with_recipient: false }
        : {},
      user_id,
      segment_id: formValues.segment,
      ...(productCategory && { product_id: productCategory }),
      recipient_type,
      email_template: 1, // these 2 are fixed, will be modified in the future
      version: 2,
      show_link_to_abm,
      show_link_to_sales_intelligence,
      categories: categoriesList.join(','),
      categories_detail,
      is_active: isActive,
      alert_type: alertType,
      frequency: alertFrequency,
      ...(alertFrequency === WEEKLY.value && { dayOfWk }),
      send_empty,
      crm_recipients,
    };
  },
);

const alertIdSelector = createSelector(
  _alertsSelector, ({ editAlertId }) => editAlertId,
);

const _alertCurrentlyEditing = createSelector(
  _alertsSelector,
  ({ editing }) => editing,
);

const updatedAlertDataSelector = createSelector(
  alertFormValueSelector('editAlert'),
  newAlertValuesSelector,
  _alertCurrentlyEditing,
  predictiveProductsSelector,
  userSelector,
  (formValues, // form values
    stateValues, // values from state
    uneditedAlertValues, // alert object currently being edited
    products, // org's product list
    user_id,
  ) => {
    const {
      product: formProductValue = '',
      name: formNameValue,
    } = formValues;
    const {
      all_account_owners: allOwnersChangedValue,
      emails: emailListChangedValue,
      frequency: frequencyChangedValue,
      dayOfWk: dayOfWkChangedValue,
      send_empty: sendEmptyChangedValue,
      send_without_recipient: sendWithoutRecipientChangedValue,
      alert_type: alertTypeChangedValue,
      associated_with_recipient: associatedWithRecipientChangedValue,
      recipient_account_type: recipientAccountTypeChangedValue,
    } = stateValues;
    const recipientTypeChangedValue = [];
    if (recipientAccountTypeChangedValue) {
      recipientTypeChangedValue.push(recipientAccountTypeChangedValue);
    }
    if (stateValues.recipient_type) {
      recipientTypeChangedValue.push(...stateValues.recipient_type);
    }

    const {
      all_account_owners: allOwnersCurrentAlertValue,
      active_emails: activeEmailsCurrentValue,
      name: nameCurrentAlertValue,
      send_without_recipient: sendWithoutRecipientCurrentValue,
      send_empty: sendEmptyCurrentValue,
      schedule: {
        frequency: frequencyCurrentValue,
        day_of_the_week: dayOfWkCurrentValue,
      },
      alert_type: alertTypeCurrentValue,
      associated_with_recipient: associatedWithRecipientCurrentValue,
    } = uneditedAlertValues;
    const recipientAccountTypeCurrentValue = [];
    if (associatedWithRecipientCurrentValue) {
      recipientAccountTypeCurrentValue.push(associatedWithRecipientCurrentValue);
    }
    if (uneditedAlertValues.recipient_account_type) {
      recipientAccountTypeCurrentValue.push(uneditedAlertValues.recipient_account_type);
    }
    const recipientTypeCurrentValue =
      uneditedAlertValues.recipient_type.map((val) => val.recipient_type);

    // product id is required for predictive customers
    const hasProductId = (formProductValue.match(/^[0-9]+$/) || []).length;
    let updatedProduct = hasProductId ? { product_id: formProductValue } : {};
    // if it is a string, we need to find which product id this is associated with
    // antd's autocomplete whatever expects the IDs to be strings, so we need to
    // verify that the product is a string and that it is not a number
    if (isString(formProductValue) && !hasProductId) {
      const product = find(products, { display_name: formProductValue });
      updatedProduct = { product_id: get(product, 'id') };
    }

    // check name
    const updatedName = formNameValue !== nameCurrentAlertValue ? { name: formNameValue } : {};

    // check all account owners
    const updatedAccountOwners = allOwnersChangedValue !== allOwnersCurrentAlertValue
      ? { all_account_owners: allOwnersChangedValue }
      : {};

    // check emails
    // length isn't a valid check because a user may add one new email, but remove one old email
    // and lengths will be the same
    const d1 = difference(activeEmailsCurrentValue, emailListChangedValue);
    const d2 = difference(emailListChangedValue, activeEmailsCurrentValue);
    const changedEmails = d1.length || d2.length;
    const updatedEmails = changedEmails ? { emails: uniq(emailListChangedValue) } : {};

    // check frequency
    const updatedFrequency =
      frequencyChangedValue && frequencyChangedValue !== frequencyCurrentValue
        ? { frequency: frequencyChangedValue }
        : {};

    // check day of week
    const updatedDayOfWk =
      !isNil(dayOfWkChangedValue) && dayOfWkChangedValue !== dayOfWkCurrentValue
        ? { dayOfWk: dayOfWkChangedValue }
        : {};

    // check alert type
    const updatedAlertType =
      alertTypeChangedValue && alertTypeChangedValue !== alertTypeCurrentValue
        ? { alert_type: alertTypeChangedValue }
        : {};

    // check send empty option
    const updatedSendEmpty = sendEmptyCurrentValue !== sendEmptyChangedValue
      ? { send_empty: Boolean(sendEmptyChangedValue) }
      : {};
    const updatedSendWithoutRecipients =
      sendWithoutRecipientCurrentValue !== sendWithoutRecipientChangedValue ?
        { send_without_recipient: Boolean(sendWithoutRecipientChangedValue) }
        : {};
    const updatedSendWithoutUserType =
      associatedWithRecipientChangedValue !== associatedWithRecipientCurrentValue ?
        { associated_with_recipient: Boolean(associatedWithRecipientChangedValue) }
        :
        {};
    const updatedUserRelationship =
      recipientAccountTypeChangedValue !== recipientAccountTypeCurrentValue
    ? { recipient_account_type: recipientAccountTypeChangedValue } : {};
    const du1 = difference(recipientTypeCurrentValue, recipientTypeChangedValue);
    const du2 = difference(recipientTypeChangedValue, recipientTypeCurrentValue);
    const changedRecipientType = du1.length || du2.length;
    const updatedRecipientType =
      changedRecipientType ? { recipient_type: uniq(recipientTypeChangedValue) } : {};
    return {
      user_id,
      ...updatedProduct,
      ...updatedName,
      ...updatedAccountOwners,
      ...updatedEmails,
      ...updatedFrequency,
      ...updatedDayOfWk,
      ...updatedSendEmpty,
      ...updatedAlertType,
      ...updatedSendWithoutUserType,
      ...updatedUserRelationship,
      ...updatedRecipientType,
      ...updatedSendWithoutRecipients,
      version: 2,
    };
  }
);

const isAlertEditedSelector = createSelector(
  alertFormValueSelector('editAlert'),
  _alertsSelector,
  _alertCurrentlyEditing,
  (
    formValues, // form values
    stateValues, // values from state
    uneditedAlertValues, // alert object currently being edited
  ) => {
    const {
      frequency: frequencyChangedValue,
      dayOfWk: dayOfWkChangedValue,
      send_empty: sendEmptyChangedValue,
      recipients,
    } = stateValues;
    const {
      associated_with_recipient: associatedWithRecipientChangedValue,
      send_without_recipient: sendWithoutRecipientChangedValue,
      recipient_type: recipientTypeChangedValue,
      recipient_account_type: recipientAccountTypeChangedValue,
      list: emailListChangedValue,
    } = recipients;

    if (!formValues) return false;
    const {
      name: nameEditedValue,
      product: productEditedValue,
    } = formValues;

    const {
      active_emails: activeEmailsCurrentValue,
      name: nameCurrentAlertValue,
      send_without_recipient: sendWithoutRecipientCurrentValue,
      send_empty: sendEmptyCurrentValue,
      schedule: {
        frequency: frequencyCurrentValue,
        day_of_the_week: dayOfWkCurrentValue,
      },
      associated_with_recipient: associatedWithRecipientCurrentValue,
      recipient_account_type: recipientAccountTypeCurrentValue,
      recipient_type: recipientTypeCurrentValue,
      product: productCurrentDict,
    } = uneditedAlertValues;
    const recipientTypeCurrentValueArray = recipientTypeCurrentValue.map(
      (r) => r.recipient_type
    );
    const recipientTypeCurrentValueFinal =
      without(recipientTypeCurrentValueArray, recipientAccountTypeCurrentValue);
    const recipientTypeChangedValueFinal =
      without(recipientTypeChangedValue, recipientAccountTypeChangedValue);
    const productCurrentName = productCurrentDict && productCurrentDict.display_name;
    const productCurrentId = productCurrentDict && productCurrentDict.id.toString();
    return (
      nameEditedValue !== nameCurrentAlertValue ||
      !(productEditedValue === productCurrentName || productEditedValue === productCurrentId) ||
      associatedWithRecipientChangedValue !== associatedWithRecipientCurrentValue ||
      (associatedWithRecipientCurrentValue &&
        recipientAccountTypeChangedValue !== recipientAccountTypeCurrentValue) ||
      (associatedWithRecipientCurrentValue &&
        !isEqual(
          recipientTypeChangedValueFinal.sort(),
          recipientTypeCurrentValueFinal.sort()
        )
      ) ||
      (associatedWithRecipientCurrentValue &&
        sendWithoutRecipientChangedValue !== sendWithoutRecipientCurrentValue
      ) ||
      !isEqual(emailListChangedValue.sort(), activeEmailsCurrentValue.sort()) ||
      frequencyChangedValue !== frequencyCurrentValue ||
      (frequencyCurrentValue === 'weekly' && dayOfWkChangedValue !== dayOfWkCurrentValue) ||
      // below param can be false or undefined
      Boolean(sendEmptyChangedValue) !== sendEmptyCurrentValue
    );
  }
);

const shouldDisableAlertCreateSelector = createSelector(
  alertFormValueSelector('newAlert'),
  _alertsSelector,
  canShowAccountOwnerOptionSelector,
  (formValues, alerts, showPredictive) => {
    const name = formValues && formValues.name;
    const segment = formValues && formValues.segment;
    const associated_with_recipient = get(alerts, 'recipients.associated_with_recipient', false);
    const emailListLength = get(alerts, 'recipients.list.length', 0);
    return !(
      name &&
      segment &&
      ((showPredictive && associated_with_recipient) || emailListLength !== 0)
    );
  }
);

// disabled if email field is available no valid emails have been added
const shouldDisableEmailsSelector = createSelector(
  _alertsSelector,
  ({ recipients: { list, send_without_recipient, associated_with_recipient } }) =>
    (!associated_with_recipient || send_without_recipient) && list.length === 0
);

const emailInputSelector = createSelector(
  _alertsSelector,
  ({ recipients }) => recipients.emailInput || '',
);


// edit
const updateAlertEndpointSelector = createSelector(
  orgSelector,
  alertIdSelector,
  (orgId, alertId) => `org/${orgId}/alert/${alertId}/`,
);

const alertEditingSegmentSelector = createSelector(
  _alertCurrentlyEditing,
  ({ segment = {} }) => get(segment, 'name', ''),
);
const loadingAlertSelector = createSelector(
  _alertCurrentlyEditing,
  ({ loading }) => loading,
);
const alertEditingProductSelector = createSelector(
  _alertCurrentlyEditing,
  predictiveProductsSelector,
  (alert, [product] = []) => get(
    alert,
    'product.display_name',
    get(product, 'display_name', undefined), // fallback
  ),
);

const alertEditingNameSelector = createSelector(
  _alertCurrentlyEditing, ({ name }) => name
);
const alertEditingIdSelector = createSelector(
  _alertCurrentlyEditing, ({ id }) => id,
);

const alertNameSelector = createSelector(
  alertFormValueSelector('editAlertName'),
  (editAlertName) => get(editAlertName, 'name', '')
);

const alertSubjectLineSelector = createSelector(
  alertRecipientAccountTypeSelector,
  alertFormFieldSelector('recipients.associated_with_recipient'),
  canShowAccountOwnerOptionSelector,
  alertNameSelector,
  (recipientAccountType, associatedWithRecipient, showAccountOwner, alertName) =>
    `${associatedWithRecipient && showAccountOwner && recipientAccountType ?
      `<${recipientAccountType}>’s `
      :
      ''
    } 6sense Hot Accounts: ${alertName || ''}`
);
const alertCRMRecipientListSelector = createSelector(
  _alertsSelector,
  (alerts) => alerts.alertCRMRecipientList
);

export {
  alertListEndpointSelector,
  slackTokenState,
  newAlertEndpointSelector,
  alertRecipinetTypEndpointSelector,
  alertLoadingRecipientTypesSelector,
  searchEndpointSelector,
  updateAlertEndpointSelector,

  alertFrequencySelector,
  alertDayOfWkSelector,
  alertSendEmptySelector,
  alertModeSelector,
  segmentIdSelector,
  formValuesSelector,
  alertLoadingSegmentSelector,

  alertIsSelectTypeSelector,
  alertTypeSelector,

  alertFormFieldSelector,
  alertRecipientAccountTypeSelector,

  alertListSelector,
  alertSearchTermSelector,
  filteredAlertListSelector,
  formattedAlertListSelector,
  alertStatusStateSelector,
  newAlertValuesSelector,
  alertListSortBySelector,

  newAlertSelector,
  shouldDisableEmailsSelector,
  emailInputSelector,


  updatedAlertDataSelector,
  isAlertEditedSelector,
  shouldDisableAlertCreateSelector,

  // extract content of the alert currently being edited
  loadingAlertSelector,
  alertEditingSegmentSelector,
  alertEditingProductSelector,
  alertEditingNameSelector,
  alertEditingIdSelector,


  segmentsSelector, // segment autocomplete
  segmentSearchTermSelector,
  segmentLoadingSelector,
  segmentMoreAvailableSelector,

  canShowAccountOwnerOptionSelector,
  hasCrmFlagsSelector,
  alertNameSelector,
  alertSubjectLineSelector,
  _alertCurrentlyEditing,
  alertCategoryMetadataSelector,
  intentFiltersetSelector,
  webVisitsFiltersetSelector,
  categoriesDetailsSelector,
  additionalSettingsSelector,
  alertLoadingSelector,
  defaultEngagementActivitiesSelector,
  categoriesListSelector,
  recipientsSelector,
  isAlertActiveSelector,
  productIdSelector,
  visitedFormStepsSelector,
  intentFilterlogicErrorSelector,
  alertCRMRecipinetListEndpointSelector,
  alertCRMRecipientListSelector,
  crmRecipientsSelector,
  abmAlertsEnabledSelector,
};
