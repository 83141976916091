import React from 'react';
import { CopyToClipboard } from 'HOCS';
import { Text, Icon } from 'v2-components';
import styles from './UTMBuilder.module.scss';
import PropTypes from 'prop-types';
import { classNames } from 'utils/utils';

const getCopyIconStyle = (url, isCopied) => {
  if (url) {
    if (isCopied) {
      return styles.iconCopied;
    }
    return styles.iconCopyActive;
  }
  return styles.iconCopyInactive;
};

const GeneratedUrlSection = ({ isCopied, copyToClipboard, url }) => (<div
  className={styles.blockedText}
>
  <Icon
    type="content_copy"
    onClick={() => url && copyToClipboard('generatedUrl')}
    className={classNames(styles.icon, getCopyIconStyle(url, isCopied))}
  />
  <Text id="generatedUrl" className={styles.generatedUrl}>
    {url}
  </Text>
</div>);

GeneratedUrlSection.propTypes = {
  isCopied: PropTypes.string,
  copyToClipboard: PropTypes.func,
  url: PropTypes.string,
};

export default CopyToClipboard({ timeout: 3000 })(GeneratedUrlSection);
