import React from 'react';
import Text from 'v2-components/Text';
import { COLUMN_CATEGORIES } from '../constants';
import { NA } from './common/NA';
import { toFixedfp } from 'utils/utils';
import { isDraftOrScheduledCampaign } from '../../../utils';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';

export const CTR = {
  sortByKey: 'ctr',
  category: COLUMN_CATEGORIES.general,
  key: 'ctr',
  csvColumnKey: 'ctr',
  title: <Label name={CAMPAIGN_LABELS.ctr} />,
  dataIndex: 'campaign_data.ctr',
  width: 110,
  render: (ctr, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (isDraftOrScheduledCampaign(campaignStatus)) {
      return (<NA />);
    }

    return (<Text type={Text.TYPE.SUBBODY}>{toFixedfp(2)(ctr)}%</Text>);
  },
};
