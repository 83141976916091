import { Text } from 'v2-components';
import React from 'react';
import PropTypes from 'prop-types';
import CopyToAllConfirmPrompt from './CopyToAllConfirmPrompt';

const CopyToAll = (props) => {
  const {
    onClick,
    className,
    disabled,
    fieldLabel,
  } = props;
  const confirmAndCopy = () => {
    CopyToAllConfirmPrompt(fieldLabel, onClick);
  };
  return (
    <Text
      className={className}
      color={Text.COLOR.GREY1}
      type={Text.TYPE.SUBBODY}
      disabled={disabled}
      pointer={!disabled}
      onClick={() => !disabled && confirmAndCopy()}
    >
      Copy to all
    </Text>
  );
};
CopyToAll.propTypes = {
  fieldLabel: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default CopyToAll;
