export default function createRoutes() {
  return {
    path: ':objectType',
    name: 'Account List',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        cb(null, containers.AccountsRoutes);
      }, 'segments_segment_accounts');
    },
  };
}
