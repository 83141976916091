/* eslint max-len: [2, 200] */
import abmAppImg from './images/ABM App.png';
import accountDetails1 from './images/account-details-1.png';
import accountDetails2 from './images/account-details-2.png';
import qvImg from './images/quick-view.png';
import { SI_PlANS } from '../../constants';

export const KEEP_ONE_TAB_MESSAGE =
  'You must keep at least one section of Sales Intelligence visible.';
export const DATE_RANGE_OPTIONS = [
  {
    value: 'LAST_7_DAYS',
    display_name: 'Last 7 Days',
  },
  {
    value: 'LAST_30_DAYS',
    display_name: 'Last 30 Days',
  },
  {
    value: 'LAST_60_DAYS',
    display_name: 'Last 60 Days',
  },
  {
    value: 'LAST_90_DAYS',
    display_name: 'Last 90 Days',
  },
  {
    value: 'LAST_180_DAYS',
    display_name: 'Last 180 Days',
  },
];

export const COMPONENTS = {
  LABEL: 'Components',
  DESCRIPTION:
    'Deselect the sections/tabs that you wish to hide in Sales Intelligence interface',
  COMPONENTS: [
    {
      label: 'SI Dashboards',
      list: [
        {
          label: 'Dashboard',
          value: 'dashboard_enabled',
          isVisible: true,
        },
      ],
    },
    {
      label: 'Account Details',
      list: [
        {
          label: 'Highlights',
          value: 'highlight_tab_enabled',
          isVisible: true,
        },
        {
          label: 'Intent & Web Activities',
          value: 'intent_tab_enabled',
          isVisible: true,
        },
        {
          label: 'Tech & Signals',
          value: 'tech_and_signals_tab_enabled',
          isVisible: true,
        },
        {
          label: 'Persona Map',
          value: 'people_tab_enabled',
          isVisible: true,
        },
        {
          label: 'Activities & Timeline',
          value: 'activities_tab_enabled',
          isVisible: true,
        },
        {
          label: 'Company Hierarchy',
          value: 'company_hierarchy_tab_enabled',
          isVisible: true,
        },
      ],
    },
  ],
};

export const HEADERS = {
  LABEL: 'Header',
  DESCRIPTION: 'Control header elements',
  HEADERS: [],
};

export const METRICS = {
  LABEL: 'Metrics',
  DESCRIPTION: 'Control header elements',
  METRICS: [
    {
      label: '',
      list: [
        {
          label: 'Account Reach',
          value: 'reach_score_enabled',
          isVisible: true,
        },
        {
          label: 'Opportunity information',
          value: 'opportunity_info_enabled',
          exampleModalHeading: 'Opportunity Information Preview',
          isVisible: true,
          examples: [
            {
              key: 1,
              image: abmAppImg,
              title: 'ABM App - Account detail page, Scoring Trend',
              description: '',
            },
            {
              key: 2,
              image: accountDetails1,
              title: 'Account Details Page',
              description: '',
            },
            {
              key: 2,
              image: accountDetails2,
              title: 'Account Details Page',
              description: '',
            },
            {
              key: 3,
              image: qvImg,
              title: 'Quick View',
              description: '',
            },
          ],
        },
      ],
    },
  ],
};

export const DATA_SETTINGS = {
  LABEL: 'Data settings',
  DESCRIPTION:
    'Manage the Display of Technologies, Psychographics, and Job Posts',
  TOOLTIP: `Restricts the view and export of relevant technologies, psychographs, and job postings. This setting applies to all products. 
  If relevant data is not configured, the system will not display any data for that product.`,
  COMPONENTS: [{
    label: '',
    list: [
      {
        label: 'View & Export only Relevant Technologies',
        value: 'relevant_technographs_enabled',
        isVisible: false,
        warning: "Products don't have Relevant Technologies",
      },
      {
        label: 'View only Relevant Psychographs',
        value: 'relevant_psychographs_enabled',
        isVisible: false,
        warning: "Products don't have Relevant Psychographs",
      },
      {
        label: 'View Job Posting only from Relevant Job Titles',
        value: 'relevant_job_titles_enabled',
        isVisible: false,
        warning: "Products don't have Relevant Job Titles",
      },
    ],
  }],
};

export const DATA_SETTINGS_PRODUCTS_MAP = {
  relevant_technographs_enabled: 'technograph_products',
  relevant_psychographs_enabled: 'pyschograph_products',
  relevant_job_titles_enabled: 'jobtitle_products',
};

export const DATA_SETTINGS_NOT_APPLICABLE_PLANS = {
  relevant_job_titles_enabled: [SI_PlANS.PLAN_F],
  relevant_psychographs_enabled: [SI_PlANS.PLAN_F],
  relevant_technographs_enabled: [],
};

export const PLAN_NOT_ALLOWED_GEN_AI = [SI_PlANS.PLAN_C];
