import React from 'react';
import PropTypes from 'prop-types';
import styles from './Icon.module.scss';
import { Tooltip2, Row } from 'v2-components';
import { COLORS } from '../../v2-styles/constants';

/**
 * Icon replaces the current Icon component which uses antd icons, and switches
 * over to using the Material Design Icon library instead. Please see
 * https://google.github.io/material-design-icons/ for more info.
 *
 * @param {string} type - key for which icon to display, ex 'help', 'download'
 * @param {string} className
 * @param {string} color
 * @param {func} onClick
 * @param {bool} pointer
 * @param {string} size - Defaults to MEDIUM(16px). SIZE { SMALL: 8px, MEDIUM: 16px, LARGE: 32px}
 *
 * NOTE: If you need to add a new icon that isn't listed in the current Icon
 * constants below, you can find the icons and their names here:
 * https://material.io/resources/icons/?style=baseline
 *
 * You can find references to strings for commonly used icons at the BOTTOM OF THE FILE.
 * Once you find the icon you are using, please add the icon name and string to the
 * existing icon strings comment.
 */

const Icon = (props) => {
  const {
    type,
    className,
    color,
    onClick,
    pointer,
    tooltipWrapped,
    tooltipText,
    size,
    title,
    theme,
    disabled,
    id,
  } = props;

  const style = {
    fontSize: size,
  };

  const baseClass = theme ? `material-icons-${theme}` : 'material-icons';

  const icon = (
    <span
      title={title}
      onClick={onClick}
      id={id}
      className={`
        ${baseClass}
        ${styles.defaultIconNew}
        ${className}
        ${styles[color]}
        ${pointer ? styles.pointer : undefined}
        ${disabled ? styles.disabled : ''}
      `}
      style={style}

    >
      {type}
    </span>
  );

  return tooltipWrapped ? (
    <Tooltip2
      placement="bottom"
      overlay={tooltipText}
      overlayClassName={styles.overlay}
      mouseLeaveDelay={0}
    >
      <Row
        className={styles.newIconContainer}
        justifyContent={Row.JUSTIFY_CONTENT.CENTER}
        alignItems={Row.ALIGN_ITEMS.CENTER}
        onClick={onClick}
      >
        {icon}
      </Row>
    </Tooltip2>
  ) : (icon);
};

Icon.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  pointer: PropTypes.bool,
  tooltipWrapped: PropTypes.bool,
  tooltipText: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
};

Icon.SIZE = {
  SMALL: '12px',
  MEDIUM: '16px',
  LARGE: '20px',
  XLARGE: '25px',
};

Icon.THEMES = {
  OUTLINED: 'outlined',
  TWO_TONE: 'two-tone',
  ROUND: 'round',
  SHARP: 'sharp',
};

Icon.defaultProps = {
  color: '',
  size: Icon.SIZE.MEDIUM,
  theme: undefined,
  id: undefined,
};

Icon.COLORS = COLORS;

export default Icon;

/*
  Below: String References for common icons used across the platform.

  If you are adding a new icon, you can search from the available material UI
  icons here: https://material.io/resources/icons/?style=baseline.

  Many icons have _circle and _circle_outline if outlines are needed.

  You can find old Icons here: https://fontawesome.com/icons?d=gallery

  Please add the strings used for each icon you add below. This is meant to be
  a place of reference so if you forget an icon string you don't have to
  navigate to the site and look for it, and instead you can find it here :)

  HELP_ICON: 'help'
  FILE_ICON: 'insert_drive_file'
  REMOVE_ICON: 'clear'
  ERROR_ICON: 'error'
  CLOSE_ICON: 'clear'
  PLUS_ICON: 'add'
  MINUS_ICON: 'remove'
  CHECK_ICON: 'check'
  WARNING_TRIANGLE_ICON: 'warning'
  DOWNLOAD_ICON: 'get_app'
  SEARCH_ICON: 'search'
  CLONE_ICON: 'file_copy'
  ANGLE_DOWN: Down: 'keyboard_arrow_down'
  ANGLE_RIGHT: 'keyboard_arrow_right'
  ANGLE_LEFT: 'keyboard_arrow_left'
  ANGLE_UP: 'keyboard_arrow_up'
  CHEVRON_LEFT_ICON: 'chevron_left'
  CHEVRON_RIGHT_ICON: 'chevron_right'
  UPLOAD_ICON: 'cloud_upload'
  FILES_ICON: 'file_copy'
  EDIT_ICON: 'edit'
  ELLIPSIS_HORIZONTAL_ICON: 'more_horiz'
  MORE_ICON: 'more_horiz'
  ARROW_LEFT: 'arrow_back'
  ARROW_RIGHT: 'arrow_forward'
  ARROW_UP: 'arrow_upward'
  ARROW_DOWN: 'arrow_downward'
  ICON_LIST_UL: 'format_list_bulleted'
  ICON_LIST: 'format_list_bulleted'
  ICON_TABLE: 'table_chart'
  EXTERNAL_ICON_LINK: 'open_in_new'
  INFO_ICON: 'info'
  EXCLAIM_TRIANGE_ICON: 'warning'
  FILTER_ICON: 'filter_list'
  HELP_ICON: 'help'
  TIMES_CIRCLE_ICON: 'highlight_off'
  DRAG_ICON: "swap_vert"
  FOLDER: "folder"
  NEW_FOLDER: "create_new_folder"
  TAG: "local_offer"
*/
