import React from 'react';
/* eslint max-len: [2, 4000] */
// Using 1000 for now.... might need to bump it up
export function UnsyncedFrom(props) {
  return (
    <svg width="12px" {...props} height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Unsynced From LinkedIn</title>
      <g id="Linkedin-Integration-v1.2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="LI-V1.2-C1" transform="translate(-609.000000, -601.000000)" fill="#505C62" fillRule="nonzero">
          <g id="campaign.type-copy-6" transform="translate(602.000000, 595.000000)">
            <g id="ic_linkedin_unsync" transform="translate(6.000000, 5.000000)">
              <path d="M6.02419355,2.9890553 L6.02419355,1.66474654 C5.60599078,1.77246544 5.20679724,1.93721198 4.83294931,2.13997696 L5.78341014,3.09043779 C5.86578341,3.05875576 5.94182028,3.02073733 6.02419355,2.9890553 L6.02419355,2.9890553 Z M12.3605991,6.56912442 C12.3605991,5.1687788 11.7839862,3.9078341 10.8652074,2.99539171 L12.3605991,1.5 L8.55875576,1.5 L8.55875576,5.30184332 L9.9781106,3.88248848 C10.6624424,4.57315668 11.093318,5.51728111 11.093318,6.56912442 C11.093318,7.10771889 10.9729263,7.61463134 10.7701613,8.07718894 L11.7206221,9.02764977 C12.1261521,8.30529954 12.3605991,7.4625576 12.3605991,6.56912442 Z M2.39343318,1.5 L1.5,2.39343318 L2.99539171,3.88882488 C2.50748848,4.66186636 2.22235023,5.58064516 2.22235023,6.56912442 C2.22235023,7.96947005 2.79896313,9.23041475 3.71774194,10.1428571 L2.22235023,11.6382488 L6.02419355,11.6382488 L6.02419355,7.83640553 L4.60483871,9.25576037 C3.92050691,8.56509217 3.48963134,7.62096774 3.48963134,6.56912442 C3.48963134,5.93548387 3.64804147,5.33986175 3.92050691,4.81394009 L9.04032258,9.93375576 C8.88191244,10.016129 8.7235023,10.0858295 8.55875576,10.1491935 L8.55875576,11.4735023 C9.0656682,11.3404378 9.54089862,11.1313364 9.97177419,10.8652074 L11.6065668,12.5 L12.5,11.6065668 L2.39343318,1.5 Z" id="Shape" />
            </g>
          </g>
        </g>
      </g>
    </svg>);
}
