import ConfigRoute from './routes/config';

export default function createRoutes(store) {
  return {
    path: 'bombora_old',
    name: 'bombora',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/settings/integration/bombora/config'),
    },

    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        cb(null, containers.BomboraConfig);
      }, 'settings_bombora');
    },
    childRoutes: [
      ConfigRoute(store),
    ],
  };
}
