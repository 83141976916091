/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { Option, OptGroup } from 'rc-select';
import styles from './SelectGroup.module.scss';
import { Select, Checkbox } from 'v2-components';
import 'rc-select/assets/index.css';

const checkedIcon = () => <Checkbox className={styles.mr} checked />;

const uncheckedIcon = () => <Checkbox className={styles.mr} />;

const SelectGroup = (props) => {
  const { options, containerClassName, dropdownClassName } = props;
  const optionsElement = options.map(
    ({ label, options, value: parentValue, disabled }) => {
      if (!options) {
        return (
          <Option tagLabel={label} value={parentValue} disabled={disabled}>
            {label}
          </Option>
        );
      }
      return (
        <OptGroup label={label} disabled={disabled}>
          {options.map(({ label, value, tagLabel, disabled }) => (
            <Option tagLabel={`${tagLabel}`} value={value} disabled={disabled}>
              {label}
            </Option>
          ))}
        </OptGroup>
      );
    }
  );
  return (
    <Select
      {...props}
      options={optionsElement}
      containerClassName={`
        ${styles.multiSelector}
        ${containerClassName}
      `}
      menuItemSelectedIcon={(p) => {
        if (p.isSelected) {
          return checkedIcon();
        }
        return uncheckedIcon();
      }}
      dropdownClassName={`
        ${styles.multiSelectorDropdown}
        ${dropdownClassName}
      `}
      optionLabelProp={'tagLabel'}
    />
  );
};
SelectGroup.propTypes = {
  options: PropTypes.array,
  containerClassName: PropTypes.string,
  dropdownClassName: PropTypes.string,
};

export default SelectGroup;
