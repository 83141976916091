import React, { useEffect, useState } from 'react';
import { AccessDenied, Loading2 as Loading } from 'v2-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { PageHeader } from 'HOCS';
import { siPackagePlanNameSelector } from 'routes/Settings/selectors';
import { SalesPermission, withPermissionBoundary } from '@sixsense/rbac';
import PropTypes from 'prop-types';
import GenerativeAIHeader from '../components/GenerativeAIHeader';
import { AIInsightsCard } from '../components/AIInsightsCard';
import { AIWriterCard } from '../components/AIWriterCard';
import { useRequestValue } from '@sixsense/core';
import {
  componentsAndMetricsRequestState,
  getAIWriterFeatureEnablement,
} from '../state';
import { useOrg } from 'hooks';
import { ResultStatus } from '@sixsense/core/shared/async-result/types';
import { ComponentAndMetricsType } from '../types';
import { SI_PlANS } from 'routes/Settings/constants';

const GenerativeAI = ({ siPackagePlanName }) => {
  const { id: orgId } = useOrg();
  const LoadingComponent = Loading.LoadingComponent;
  const componentsSettings = useRequestValue(
    componentsAndMetricsRequestState,
    orgId
  );
  const AIWriterFeatureEnabled = useRequestValue(
    getAIWriterFeatureEnablement,
    orgId
  );
  const [enableAIWriterCard, setEnableAIWriterCard] = useState<Boolean>(false);
  const [isComponentMetricsAPIForbidden, setIsComponentMetricsAPIForbidden] =
    useState<Boolean>(false);
  const [metricsConfig, setMetricsConfig] =
    useState<ComponentAndMetricsType>(Object);
  const [loading, setLoading] = useState<Boolean>(true);

  useEffect(() => {
    const { data, status } = componentsSettings;
    if (status === ResultStatus.LOADED) {
      setLoading(false);
      setMetricsConfig(data);
    }
    if (status === ResultStatus.ERROR) {
      setLoading(false);
      setIsComponentMetricsAPIForbidden(true);
    }
    const { data: aiWriterAPIData, status: aiWriterAPIStatus } =
      AIWriterFeatureEnabled;
    if (aiWriterAPIStatus === ResultStatus.LOADED) {
      setEnableAIWriterCard(aiWriterAPIData?.is_enabled);
    }
  }, [componentsSettings, AIWriterFeatureEnabled]);

  if (
    !siPackagePlanName ||
    ![SI_PlANS.PLAN_D, SI_PlANS.PLAN_E].includes(siPackagePlanName)
  ) {
    return <AccessDenied />;
  }

  return (
    <React.Fragment>
      {loading && <LoadingComponent level="cover" loading />}
      <AIInsightsCard
        metricsConfig={metricsConfig}
        orgId={orgId}
        updateLoader={setLoading}
        isComponentMetricsAPIForbidden={isComponentMetricsAPIForbidden}
      />
      {enableAIWriterCard && <AIWriterCard
        metricsConfig={metricsConfig}
        orgId={orgId}
        updateLoader={setLoading}
        enableAIWriterCard={enableAIWriterCard}
        isComponentMetricsAPIForbidden={isComponentMetricsAPIForbidden}
      />}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  siPackagePlanName: siPackagePlanNameSelector(state),
});

GenerativeAI.propTypes = {
  siPackagePlanName: PropTypes.string,
};

export default compose(
  withPermissionBoundary({
    allow: (permissions) =>
      [
        SalesPermission.SETTINGS_GENERATIVE_AI_VIEW,
        SalesPermission.SETTINGS_GENERATIVE_AI_EDIT,
      ].some((permission) => permissions.has(permission)),
    renderDenied: () => <AccessDenied />,
  }),
  connect(mapStateToProps),
  PageHeader([GenerativeAIHeader])
)(GenerativeAI);
