/**
 * The global state selectors
 */
import { createSelector } from 'reselect';
import { get } from 'lodash';
import qs from 'qs';

const stateSelector = (state) => state.global;

const makeSelectLocationState = () => {
  let prevRoutingState;

  return (state) => {
    const routingState = state.route; // or state.route

    if (!(routingState === prevRoutingState)) {
      prevRoutingState = routingState;
    }
    return prevRoutingState;
  };
};

const promptTransitionSelector = (state) =>
  get(state, 'global.doPromptOnLeave', false);
const promptMessageSelecor = (state) =>
  get(state, 'global.promptOnLeaveMessage', '');
const promptWhenSelector = (state) =>
  get(state, 'global.promptWhen', () => true);
const keepAfterTranstionSelector = (state) =>
  get(state, 'global.keepAfterTransition', false);
const onConfirmLeaveSelector = (state) =>
  get(state, 'global.onConfirmLeave', false);

const promptSettingsSelector = createSelector(
  [
    promptTransitionSelector,
    promptWhenSelector,
    keepAfterTranstionSelector,
    onConfirmLeaveSelector,
  ],
  (
    promptTransition,
    promptWhen,
    keepAfterTransition,
    onConfirmLeave,
  ) => ({
    promptTransition,
    promptWhen,
    keepAfterTransition,
    onConfirmLeave,
  }),
);

const alertPropsSelector = (channel) => (state) =>
  state.global.alertPropsAll[channel] || {};

const routeQuerySelector = createSelector(
  makeSelectLocationState,
  (route) =>
    get(
      route,
      'route.locationBeforeTransitions.query',
      {},
    ) || {},
);

const queryStringParamsSelector = (state) => {
  const params = get(
    state,
    'route.locationBeforeTransitions.query',
    null,
  );
  return params ? qs.parse(params) : null;
};

const isViewModeSelector = (state) =>
  get(
    state,
    'route.locationBeforeTransitions.query.viewMode',
    false,
  ) === 'true';

const routePathnameSelector = (state) =>
  state.route.locationBeforeTransitions.pathname;

const globalStateSelector = createSelector(
  stateSelector,
  (global) => global.state,
);


export {
  alertPropsSelector,
  promptWhenSelector,
  isViewModeSelector,
  promptMessageSelecor,
  makeSelectLocationState,
  routeQuerySelector,
  routePathnameSelector,
  promptTransitionSelector,
  promptSettingsSelector,
  queryStringParamsSelector,
  globalStateSelector,
};
