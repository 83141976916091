/* eslint-disable */
import React from 'react';

export const Organization = ({ className }) => (
<svg id="icon_organization" className={className} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M9.83268 7.66667H13.8327C14.7661 7.66667 15.2328 7.66667 15.5893 7.84832C15.9029 8.00811 16.1579 8.26308 16.3177 8.57668C16.4993 8.9332 16.4993 9.39991 16.4993 10.3333V16M9.83268 16V3.66667C9.83268 2.73325 9.83268 2.26654 9.65103 1.91002C9.49124 1.59641 9.23627 1.34144 8.92267 1.18166C8.56615 1 8.09944 1 7.16602 1H4.16602C3.23259 1 2.76588 1 2.40936 1.18166C2.09576 1.34144 1.84079 1.59641 1.681 1.91002C1.49935 2.26654 1.49935 2.73325 1.49935 3.66667V16M17.3327 16H0.666016M4.41602 4.33333H6.91602M4.41602 7.66667H6.91602M4.41602 11H6.91602" stroke="url(#paint0_linear_1883_9042)" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
   <defs>
      <linearGradient id="paint0_linear_1883_9042" x1="0.666016" y1="16" x2="15.5831" y2="-0.574587" gradientUnits="userSpaceOnUse">
        <stop stop-color="#19CCC6"/>
        <stop offset="1" stop-color="#007BC1"/>
      </linearGradient>
   </defs>
</svg>
);

export default Organization;
