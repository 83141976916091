export const NOT_CONFIGURED = 'not_configured';
export const URLS_SUBMITTED = 'urls_submitted';
export const KEYWORDS_READY = 'keywords_done';
export const KEYWORDS_SUBMITTED = 'keywords_submitted';
export const MODELS_DONE = 'models_done';
export const MAPPINGS_READY = 'mappings_ready';
export const URL_ANALYSIS_TITLE = 'We are now analyzing URLs. This may take up to 48-72 hours';
export const URL_ANALYSIS_SUBTITLE = 'We will notify you via email once the URLs are ' +
'analyzed and Keyword configuration is made available.';
export const CONFIGURATION_DONE_TITLE = 'Thank you for providing product configuration!';
export const CONFIGURATION_DONE_SUBTITLE =
'Your data is now being utilized to train custom taxonomy models.' +
'This may take up to 48-72 hours.' +
'You will be notified via email once the taxonomy data is made available for review. ';
export const PRODUCT_CONFIG_PROPERTY_TYPE = 17;

export const CONFIGURE_URL_STEP = 0;
export const URLS_SUBMITTED_STEP = 1;
export const CONFIGURE_KEYWORD_STEP = 2;
export const SUMMARY_VIEW = 3;
export const KEYWORDS_SUBMITTED_STEP = 4;

export const COLOR_CHOICES = ['Success', 'Warning', 'Error'];
