import { getAsyncInjectors } from 'store/utils';
import { AD_LIST_BASE_ROUTE } from './constants';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: AD_LIST_BASE_ROUTE,
    name: 'adPlacementList',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('adPlacements', reducer.default);
        injectSagas('adPlacements', sagas.default);
        cb(null, containers.AdPlacementList);
      }, 'list');
    },
  };
}
