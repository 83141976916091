/* THIS IS DEPRECATED - DO NOT USE THIS COMPONENT!
 * USE CHECKBOX 2 */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as AntCheckbox } from 'antd';
import { FormComponent } from 'v2-HOCS';

// side note: For checkbox we're using the data-heap html attribute for heap analytics
function Checkbox(props) {
  // I added checkBoxLabel as a hack for form component cause it also uses label
  const { id, className, checked, onChange, label, checkBoxLabel, ...rest } = props;

  return (
    <AntCheckbox
      data-heap={id}
      className={className}
      checked={checked}
      onChange={onChange}
      {...rest}
    >
      {label || checkBoxLabel}
    </AntCheckbox>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.node,
  checkBoxLabel: PropTypes.node,
};

export default Checkbox;

const mapInputToProps = (input) => ({
  onChange: (e) => { input.onChange(e.target.checked); input.onBlur(e.target.checked); },
  checked: !!input.value,
});

Checkbox.form = FormComponent(Checkbox, mapInputToProps);
