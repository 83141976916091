import { ADVERTISING_REDUX_STATE_KEY } from "./constants";
import { stateGenerator } from "./routes/Campaigns/stateGenerator";
import { actions as globalActions } from "../../modules/global";
import {
  auditEventEndpointSelector,
  downloadEventLoggingPayloadSelector,
  searchAdFoldersEndpointSelector,
  searchCampaignsEndpointSelector,
} from "./selectors/endpointSelectors";

const { showNotification } = globalActions;

const baseSelector = (state) => state[ADVERTISING_REDUX_STATE_KEY];

export const searchAdFoldersStateGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: "AD_FOLDERS/LIST/SEARCH",
  endpointSelector: searchAdFoldersEndpointSelector,
  stateKey: "searchedAdFolders",
  initialData: [],
  receivedDataTransform: ({ results: adFolders = [] }) =>
    adFolders.map(({ name, id }) => ({ label: name, value: id })),
  onFailureActions: [
    (e) =>
      showNotification("error", "Failed to search ad folders", e.errorMessage),
  ],
});

export const searchCampaignListStateGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: "CAMPAIGNS/LIST/SEARCH",
  endpointSelector: searchCampaignsEndpointSelector,
  stateKey: "searchedCampaigns",
  initialData: [],
  receivedDataTransform: ({ results: campaigns = [] }) =>
    campaigns.map(({ campaign_name, id }) => ({
      label: campaign_name,
      value: id,
    })),
  onFailureActions: [
    (e) =>
      showNotification("error", "Failed to search campaigns", e.errorMessage),
  ],
});

export const logReportDownloadEventStateGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: `LOG_REPORT_DOWNLOAD_EVENT`,
  endpointSelector: auditEventEndpointSelector,
  stateKey: "logReportDownloadEvent",
  method: "POST",
  requestOptions: downloadEventLoggingPayloadSelector,
  onFailureActions: [
    (e) => showNotification("error", "Failed to log download event.", e),
  ],
});
