import { createSelector } from 'reselect';
import { routePathnameSelector, queryStringParamsSelector } from 'modules/global/selectors';
import {
  stagedSegmentDataSelector,
  workingSegmentNameSelector,
  unchangedFiltersetSelector,
} from 'modules/segments/selectors';
import { isNumber, includes, get, without } from 'lodash';
import { NEW_SEGMENT_IDENTIFIER } from './constants';
import { orgObjSelector, isPredictiveSelector } from 'modules/user/selectors';
import { predictive, behavioral, graphCategories, totalAccounts } from '../Analytics/constants';
import { MAX_ANALYTICS_SIZE } from 'modules/segments/constants';
import {
  FILE_UPLOAD,
  LOOKALIKE_ACCOUNTS,
  LOOKALIKE_EXTERNAL,
  AST_KEY,
  PROGRAMMATIC,
} from 'routes/Segments/constants';
import { countFilterValues, containsSegmentContainsFilter } from 'aa-ast/utils';
import { astSelector } from 'ast-redux/selectors';
import { permissionsSelector, Permission } from '@sixsense/rbac';

const routeStateSelector = (state) => state.segmentsSegment;

const segmentIdSelector = createSelector(
  routePathnameSelector,
  (pathName) => {
    // cmon react router
    if (!includes(pathName, '/segment/')) {
      return null;
    }
    const segmentId = pathName.match(/segment\/(\w+)?/)[1];
    return isNumber(segmentId) ? parseInt(segmentId) : segmentId;
  },
);

const isSegmentPathSelector = createSelector(
  routePathnameSelector,
  (routePath) => includes(routePath, 'segments/segment')
);

const isCreateSegmentSelector = createSelector(
  segmentIdSelector,
  (id) => id === NEW_SEGMENT_IDENTIFIER
);

const routeLoadedSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.loaded,
);

const routeLoadingSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.loading,
);

const segmentSelector = createSelector(
  routeStateSelector,
  (state) => state.segment,
);

const segmentTypeSelector = createSelector(
  [segmentSelector, isCreateSegmentSelector, stagedSegmentDataSelector],
  (segment, isCreate, stagedData) => {
    if (isCreate) {
      return stagedData.segment_type || segment.segment_type;
    }
    return segment.segment_type;
  }
);

const numSavedFilterValuesSelector = createSelector(
  astSelector(AST_KEY),
  (ast) => ast ? countFilterValues(ast) : 0,
);

const segmentDataForSaveSelector = createSelector(
  segmentSelector,
  (segment) => {
    const { segment_type='6sense_network', accounts_file, lookalike_count, tags=[] } = segment;
    const segData = { segment_type, tags };
    if (includes([FILE_UPLOAD, LOOKALIKE_ACCOUNTS, PROGRAMMATIC], segment_type)) {
      segData.accounts_file_id = accounts_file.id;
    }
    if (includes([LOOKALIKE_ACCOUNTS, LOOKALIKE_EXTERNAL], segment_type)) {
      segData.lookalike_count = lookalike_count;
    }
    return segData;
  }
);

const disableAnalyticsSelector = createSelector(
  segmentSelector,
  (segment) => {
    if (!segment) {
      return false;
    }
    const { segment_data: { account_count } } = segment;
    if (account_count > MAX_ANALYTICS_SIZE) {
      return true;
    }
    return false;
  }
);

const saveModalVisibleSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.saveModalVisible
);

/**
 * This is give the applicable graph categories for the org.
 * Orgs that do not have predictive do not have the graph category
 */

const accessibleGraphCategoriesSelector = (state) => {
  const isPredictive = isPredictiveSelector(state);
  if (isPredictive) {
    return graphCategories;
  }
  return without(graphCategories, predictive);
};

const isAccountListSelector = createSelector(
  routePathnameSelector,
  (pathName) => pathName.match(/segment\/\w+\/accounts?/) || false
);

const isEditSegmentSelector = createSelector(
  queryStringParamsSelector,
  (queryParam) => queryParam.mode ? 'edit' || 'create' : false
);

const selectedGraphCategorySelector = createSelector(
  [orgObjSelector, isAccountListSelector, (state) => state],
  (org, isAccountList, state) => {
    if (isAccountList) {
      return totalAccounts;
    }
    const isPredictive = isPredictiveSelector(state);
    return get(
      state,
      'route.locationBeforeTransitions.query.graph_category',
      isPredictive ? predictive : behavioral,
    );
  }
);

const filterMenusSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState ? routeState.filterMenus : {},
);

const segmentNameMapSelector = createSelector(
  routeStateSelector,
  ({ segmentNameMap }) => segmentNameMap,
);

const hasSegmentsContainsFilterSelector = createSelector(
  astSelector(AST_KEY),
  (ast) => containsSegmentContainsFilter(ast),
);

const editSegTagVisibilitySelector = createSelector(
  routeStateSelector,
  (routeState) => routeState ? routeState.editSegTagVisibility : false,
);

const tagsToCreateSelector = createSelector(
  routeStateSelector,
  (routeState) => {
    const tags = routeState.tagsToCreate;
    tags.sort((a, b) => a.newName.toLowerCase() < b.newName.toLowerCase() ? -1 : 1);
    return tags;
  }
);

const tagsToAddSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.tagsToAdd,
);

const tagsToRemoveSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.tagsToRemove,
);

const modifyingTagsSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.modifyingTags,
);

const promptLeaveSelector = (path) => createSelector(
  workingSegmentNameSelector,
  unchangedFiltersetSelector,
  segmentSelector,
  permissionsSelector,
  (workingSegmentName, unchangedFilters, segment, permissions) => {
    const { pathname: pathName } = path;
    const { name } = segment;
    const hasPermission = permissions.has(Permission.SEGMENT_EDIT);
    const hasWorkingSegmentName = workingSegmentName && workingSegmentName !== name;
    if (!pathName.match(/\/segments\/segment\/(\d+|new)/)) {
      return (!unchangedFilters || hasWorkingSegmentName) && hasPermission;
    }
    if (!pathName.match(/\/segments\/segment\/(\d+|new)\/accounts/)) {
      return !unchangedFilters && hasPermission;
    }
    return false;
  }
);

export {
  segmentIdSelector,
  segmentSelector,
  routeLoadedSelector,
  routeLoadingSelector,
  numSavedFilterValuesSelector,
  saveModalVisibleSelector,
  isCreateSegmentSelector,
  selectedGraphCategorySelector,
  accessibleGraphCategoriesSelector,
  isAccountListSelector,
  disableAnalyticsSelector,
  segmentDataForSaveSelector,
  segmentTypeSelector,
  isSegmentPathSelector,
  filterMenusSelector,
  segmentNameMapSelector,
  hasSegmentsContainsFilterSelector,
  editSegTagVisibilitySelector,
  tagsToCreateSelector,
  tagsToAddSelector,
  tagsToRemoveSelector,
  modifyingTagsSelector,
  isEditSegmentSelector,
  promptLeaveSelector,
};
