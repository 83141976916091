import React from 'react';
import Text from 'v2-components/Text';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';

export const CREATED_BY = {
  key: 'created-by',
  csvColumnKey: 'created_by',
  title: <Label name={CAMPAIGN_LABELS.createdBy} />,
  dataIndex: 'created_by',
  width: 200,
  render: (createdBy) => (<Text
    type={Text.TYPE.SUBBODY}
  >
    {createdBy && createdBy.username ? createdBy.username : '-'}
  </Text>),
};
