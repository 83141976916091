import { combineReducers } from 'redux';
import {
  createRuleStateGenerator, deleteRuleStateGenerator,
  loadImpactedRulesStateGenerator, loadRecordCountsStateGenerator,
  loadRuleHistoryStateGenerator, loadRulesStateGenerator,
  updateRuleOrderStateGenerator,
  updateRuleStateGenerator,
} from '../stateGenerators';
import {
  actionTypes as ruleEditActionTypes,
  actions as ruleEditActions,
  ruleEditReducer,
} from './ruleEdit';
import {
  actionTypes as ruleListActionTypes,
  actions as ruleListActions,
  ruleListReducer,
} from './ruleList';

export const ruleManagerReducer = combineReducers({
  ruleEdit: ruleEditReducer,
  ruleList: ruleListReducer,
  [createRuleStateGenerator.stateKey]: createRuleStateGenerator.reducer,
  [loadRulesStateGenerator.stateKey]: loadRulesStateGenerator.reducer,
  [updateRuleStateGenerator.stateKey]: updateRuleStateGenerator.reducer,
  [deleteRuleStateGenerator.stateKey]: deleteRuleStateGenerator.reducer,
  [updateRuleOrderStateGenerator.stateKey]: updateRuleOrderStateGenerator.reducer,
  [loadImpactedRulesStateGenerator.stateKey]: loadImpactedRulesStateGenerator.reducer,
  [loadRecordCountsStateGenerator.stateKey]: loadRecordCountsStateGenerator.reducer,
  [loadRuleHistoryStateGenerator.stateKey]: loadRuleHistoryStateGenerator.reducer,
});

export const actions = {
  ...ruleEditActions,
  ...ruleListActions,
};

export const actionTypes = {
  ...ruleEditActionTypes,
  ...ruleListActionTypes,
};
