import { keys, zipObject, map } from 'lodash';
import { strings } from 'utils/constants';
import { STARTSWITHOP, CONTAINSOP, ENDSWITHOP,
  NOTSTARTSWITHOP, NOTCONTAINSOP, NOTENDSWITHOP } from 'ast-redux/constants';

export const SEGMENT_REDUX_STATE_KEY = 'segments';

export const matchingOptions = [
  { value: 'starts_with', label: 'Starts With' },
  { value: '=', label: 'Equals' },
  { value: 'contains', label: 'Contains' },
  { value: 'not_contains', label: 'Does Not Contain' },
];

export const numericalOptions = [
  { value: '=', label: 'Equals' },
  { value: '>', label: 'Greater Than' },
  { value: '<', label: 'Less than' },
  { value: '!=', label: 'Not Equals To' },
];

export const operatorMap = zipObject(
  map(numericalOptions, 'value').concat(map(matchingOptions, 'value')),
  map(numericalOptions, 'label').concat(map(matchingOptions, 'label'))
);

export const DETAIL_FORM = 'segmentsDetail';
export const CLONE_FORM = 'cloneSegment';
export const MAX_ANALYTICS_SIZE = 25000;
// Programatically set routes/tabs within the modal
export const SELECT_ROUTE = 'select';
export const DETAILS_ROUTE = 'details';
export const NAMED_ACCOUNTS_ROUTE = 'named_accounts';
export const LOOKALIKE_ACCOUNTS_ROUTE = 'lookalike_accounts';
export const FIRMOGRAPHIC_ROUTE = 'firmographic';
export const BEHAVIORAL_ROUTE = 'behavioral';
export const PREDICTIVE_ROUTE = 'predictive';
export const FIRMOGRAPHIC_PREDICTIVE_ROUTE = 'firmographic_predictive';
// export const CRM_ROUTE = 'crm';

// Programatically set segment types - generates the selection tab
export const BEHAVIOR_SEGMENT = 'behavioral';
export const NAMED_ACCOUNTS_SEGMENT = 'named_accounts';
export const LOOKALIKE_ACCOUNTS_SEGMENT = 'lookalike_accounts';
export const FIRMOGRAPH_SEGMENT = 'firmographic';

// Filter type definitions, may want to explore keeping this on the server
export const BEHAVIORAL_FILTER_TYPE = 'behavioral';
export const KEYWORD_FILTER_TYPE = 'keyword';
export const FIRMOGRAPHIC_FILTER_TYPE = 'firmographic';
export const PREDICTIVE_FILTER_TYPE = 'predictive';
export const FIRMOGRAPHIC_PREDICTIVE_FILTER_TYPE = 'firmographic_predictive';
export const CAMPAIGN_ID_FILTER = 'campaign';
export const BLACKLIST_FILTER_TYPE = 'blacklist';
// export const CRM_ROUTE_FILTER_TYPE = 'crm';

// Condition types for a filter
export const NUMERIC_CONDITION_TYPE = 'numeric';
export const SELECTION_CONDITION_TYPE = 'selection';
export const MATCHING_CONDITION_TYPE = 'matching';

export const DEFAULT_NUMERIC_CONDITION = {
  operator: '=',
  value: null,
};

export const DEFAULT_SELECTION_CONDITION = {
  operator: '=',
  value: null,
};

export const DEFAULT_MATCHING_CONDITION = {
  operator: '=',
  value: '',
};

// Config used to generate the select segment page
export const segmentTypeConfigs = {
  [BEHAVIOR_SEGMENT]: {
    key: BEHAVIOR_SEGMENT,
    title: 'Behavior-Based Segment',
    text:
      'Find accounts that have exhibited specific behaviors',
    tagTitle: 'Behavioral',
    tagColor: '#109E96',
    tabs: [SELECT_ROUTE, DETAILS_ROUTE, BEHAVIORAL_ROUTE, FIRMOGRAPHIC_ROUTE, PREDICTIVE_ROUTE],
  },
  [NAMED_ACCOUNTS_SEGMENT]: {
    key: NAMED_ACCOUNTS_SEGMENT,
    title: 'Named Account Segment',
    text: 'Upload your named account list',
    tagTitle: 'Named Accounts',
    tagColor: '#2b78e4',
    tabs: [SELECT_ROUTE, DETAILS_ROUTE, NAMED_ACCOUNTS_ROUTE],
  },
  [LOOKALIKE_ACCOUNTS_SEGMENT]: {
    key: LOOKALIKE_ACCOUNTS_SEGMENT,
    title: `${strings.LOOKALIKE} Segment`,
    text: 'Find accounts that look similar to your uploaded account list',
    tagTitle: `${strings.LOOKALIKE} Accounts`,
    tagColor: '#2b78e4',
    tabs: [SELECT_ROUTE, DETAILS_ROUTE, LOOKALIKE_ACCOUNTS_ROUTE],
  },
  [FIRMOGRAPH_SEGMENT]: {
    key: FIRMOGRAPH_SEGMENT,
    title: 'Firmograph-Based Segment',
    text: 'Find accounts that match specific profile criteria' +
    ' using firmographics and technographics',
    tagTitle: 'Firmograhic',
    tagColor: '#674ea7',
    tabs: [SELECT_ROUTE, DETAILS_ROUTE, FIRMOGRAPHIC_ROUTE, FIRMOGRAPHIC_PREDICTIVE_ROUTE],
  },
};

// Used to make default when opening filters (here you cannot make a selection)
export const DEFAULT_ACTIVE_SEGMENT_KEY = keys(segmentTypeConfigs)[0];

// Used to make tabs when opening filters (Description is not included)
export const filterTabs = [BEHAVIORAL_ROUTE, FIRMOGRAPHIC_ROUTE, PREDICTIVE_ROUTE];

// default tab is the case where none of the segment types are selected,
// we still want to default to the SELECT_ROUTE
export const defaultTabs = [SELECT_ROUTE];

// Programatically generated menu tabs for the modal
export const segmentTabs = {
  [SELECT_ROUTE]: {
    title: 'Segment Type',
    key: SELECT_ROUTE,
    required: true,
    showFeedback: false,
  },
  [DETAILS_ROUTE]: {
    title: 'Specify Details',
    key: DETAILS_ROUTE,
    required: true,
    showFeedback: true,
  },
  [NAMED_ACCOUNTS_ROUTE]: {
    title: 'Upload Accounts',
    key: NAMED_ACCOUNTS_ROUTE,
    required: true,
    showFeedback: true,
  },
  [LOOKALIKE_ACCOUNTS_ROUTE]: {
    title: 'Match Accounts',
    key: LOOKALIKE_ACCOUNTS_ROUTE,
    required: true,
    showFeedback: true,
  },
  [FIRMOGRAPHIC_ROUTE]: {
    title: 'Firmographic Filters',
    key: FIRMOGRAPHIC_ROUTE,
    required: false,
    showFeedback: false,
    filterTypes: [FIRMOGRAPHIC_FILTER_TYPE],
  },
  [BEHAVIORAL_ROUTE]: {
    title: 'Behavioral Filters',
    key: BEHAVIORAL_ROUTE,
    required: false,
    showFeedback: false,
    filterTypes: [BEHAVIORAL_FILTER_TYPE, KEYWORD_FILTER_TYPE, CAMPAIGN_ID_FILTER],
  },
  [PREDICTIVE_ROUTE]: {
    title: 'Predictive Filters',
    key: PREDICTIVE_ROUTE,
    required: false,
    showFeedback: false,
    filterTypes: [PREDICTIVE_FILTER_TYPE],
  },
  [FIRMOGRAPHIC_PREDICTIVE_ROUTE]: {
    title: 'Predictive Filters',
    key: FIRMOGRAPHIC_PREDICTIVE_ROUTE,
    required: false,
    showFeedback: false,
    filterTypes: [FIRMOGRAPHIC_PREDICTIVE_FILTER_TYPE],
  },
};

export const SORT_NAME = 'name';
export const SORT_TYPE = 'segment_type';
export const SORT_UPDATED_DATE = 'updated';
export const SORT_CREATED_DATE = 'created';
export const SORT_ACCOUNT_COUNT = 'segment_data__account_count';

export const SORT_OPTIONS = [
  { value: SORT_NAME, label: 'Name' },
  { value: SORT_TYPE, label: 'Type' },
  { value: SORT_UPDATED_DATE, label: 'Last Updated Date' },
  { value: SORT_CREATED_DATE, label: 'Created Date' },
  { value: SORT_ACCOUNT_COUNT, label: 'No. of Accounts' },
];

export const CREATED_BY = 'created_by_id';
export const EDITED_BY = 'edited_by_id';
export const IN_CAMPAIGNS = 'campaign_count';
export const IN_ALERTS = 'alert_count';
export const IN_ORCHESTRATION = 'orchestration_count';
export const IN_OTHER_SEGMENTS = 'other_segment_count';
export const IN_PIPELINE_INTELLIGENCE = 'in_pipeline_count';
export const TOTAL_SYNCED_TO_LINKEDIN = 'synced_to_linkedin';
export const SYNCED_TO_FACEBOOK = 'synced_to_facebook';
export const ACTIVELY_SYNCED_TO_LINKEDIN = 'actively_synced_to_linkedin';
export const ACTIVELY_SYNCED_TO_FACEBOOK = 'actively_synced_to_facebook';
export const ACTIVELY_SYNCED_TO_GOOGLE_ADS = 'actively_synced_to_google';
export const TOTAL_SYNCED_TO_GOOGLE_ADS = 'synced_to_google';

export const VIEW_OPTIONS = [
  { value: 'all', label: 'All Segments' },
  { value: CREATED_BY, label: 'Created By Me' },
  { value: EDITED_BY, label: 'Updated By Me' },
  { value: IN_CAMPAIGNS, label: 'Used In Campaign' },
  { value: ACTIVELY_SYNCED_TO_GOOGLE_ADS, label: 'Actively synced to Google Ads' },
  { value: TOTAL_SYNCED_TO_GOOGLE_ADS, label: 'Total synced to Google Ads' },
  { value: ACTIVELY_SYNCED_TO_LINKEDIN, label: 'Actively synced to LinkedIn' },
  { value: TOTAL_SYNCED_TO_LINKEDIN, label: 'Total synced to LinkedIn' },
  { value: ACTIVELY_SYNCED_TO_FACEBOOK, label: 'Actively synced to Facebook' },
  { value: SYNCED_TO_FACEBOOK, label: 'Total synced to Facebook' },
];

export const TYPE_OPTIONS = [
  { value: '', label: 'All' },
  { value: BEHAVIOR_SEGMENT, label: 'Behavioral-Based' },
  { value: FIRMOGRAPH_SEGMENT, label: 'Firmographic-Based' },
  { value: NAMED_ACCOUNTS_SEGMENT, label: 'Named Accounts' },
  { value: LOOKALIKE_ACCOUNTS_SEGMENT, label: `${strings.LOOKALIKE} Accounts` },
];

export const DEFAULT_OP_TO_REL = {
  [STARTSWITHOP]: 'OR',
  [CONTAINSOP]: 'OR',
  [ENDSWITHOP]: 'OR',
  [NOTSTARTSWITHOP]: 'AND',
  [NOTCONTAINSOP]: 'AND',
  [NOTENDSWITHOP]: 'AND',
};

export const UNSORTED_FOLDER = 'unsorted_segment_folder';
export const PREDICTIVE_FILTERS = [PREDICTIVE_FILTER_TYPE, FIRMOGRAPHIC_PREDICTIVE_FILTER_TYPE];
export const LOOKALIKE_MIN = 100;
export const LOOKALIKE_MAX_MATCHED_ACCOUNTS = 5000;

export const MAX_FOLDERS_LIMIT = 200;
