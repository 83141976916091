import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Popover as PopoverAntd } from 'antd';
import styles from './Popover.module.scss';
import { Button } from 'v2-components';

const PopoverContent = (props) => {
  const {
    hide,
    popoverContent,
    hideOkButton,
    contentWrapper,
  } = props;
  return (
    <Fragment>
      <div
        className={`${styles.popoverContainer} ${contentWrapper}`}
      >
        {popoverContent}
      </div>
      {!hideOkButton ?
        <div className={styles.closePopoverWrap}>
          <Button onClick={hide}>OK</Button>
        </div>
      : null}
    </Fragment>

  );
};

PopoverContent.propTypes = {
  popoverContent: PropTypes.node,
  hide: PropTypes.func,
  hideOkButton: PropTypes.bool,
  contentWrapper: PropTypes.string,
};

class Popover extends React.Component {
  state = {
    visible: false,
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  render() {
    const {
      children,
      popoverContent,
      placement,
      trigger,
      hideOkButton,
      contentWrapper,
      containerWrapper,
      useCustomVisibilityChange,
      customIsVisible,
      onChangeCustomHandle,
    } = this.props;

    return (
      <PopoverAntd
        overlayClassName={`${styles.popoverWrapper} ${containerWrapper}`}
        placement={placement}
        content={
          <PopoverContent
            popoverContent={popoverContent}
            hide={this.hide}
            hideOkButton={hideOkButton}
            contentWrapper={contentWrapper}
          />
        }
        trigger={trigger}
        visible={useCustomVisibilityChange ? customIsVisible : this.state.visible}
        onVisibleChange={
          useCustomVisibilityChange ? onChangeCustomHandle : this.handleVisibleChange
        }
      >
        {children}
      </PopoverAntd>
    );
  }
}

Popover.defaultProps = {
  placement: 'right',
  trigger: 'hover',
};

Popover.propTypes = {
  children: PropTypes.object,
  popoverContent: PropTypes.node,
  placement: PropTypes.string,
  trigger: PropTypes.string,
  hideOkButton: PropTypes.bool,
  contentWrapper: PropTypes.string,
  containerWrapper: PropTypes.string,
  // backword compactible customization.
  useCustomVisibilityChange: PropTypes.bool, // flag to toggle custom change handler
  customIsVisible: PropTypes.bool, // flag to set visiblity of popover
  onChangeCustomHandle: PropTypes.func, // change handle called on visiblity change
};

export default Popover;
