import React from "react";
import { Text } from "@sixsense/core/components";
import { formatDate } from "utils/utils";
import { DATE_FORMAT } from "utils/constants";
import { CAMPAIGN_SOURCE } from "../../../constants";
import { NA } from "./common/NA";
import { ROW_DATA_TYPES } from "aa-components/CampaignCSVExportModal/constants";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const START_DATE = {
  sortByKey: "start_date",
  key: "start-date",
  csvColumnKey: "start_date",
  title: <Label name={CAMPAIGN_LABELS.startDate} />,
  dataIndex: "start_date",
  width: pxToRem(130),
  render: (startDate, campaign) => {
    if (campaign.campaign_source === CAMPAIGN_SOURCE.EXTERNAL) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {startDate ? formatDate(DATE_FORMAT)(startDate) : "-"}
      </Text.Body>
    );
  },
  csvOverrides: {
    render: (startDate, campaign, dataType) => {
      if (dataType === ROW_DATA_TYPES.aggregated) return "";

      if (
        campaign.campaign_source === CAMPAIGN_SOURCE.EXTERNAL ||
        dataType !== ROW_DATA_TYPES.campaign
      ) {
        return "-";
      }

      return startDate ? `"${formatDate(DATE_FORMAT)(startDate)}"` : "-";
    },
  },
};
