import { defineSharedState, defineRequestState } from '@sixsense/core';
import { KeywordGroup, Keyword, BulkAddToGroupConfig, SIKeywordsValidationType } from '../types';
import { KeywordRequestParams } from './keywords';

const PATH_PREFIX = 'settings.keywordsPage.groups';

type KeywordGroupSelectRequestParams = {
  orgId: number;
  searchQuery: string;
  counter: number;
}

export const keywordGroupRequestState = defineRequestState({
  path: `${PATH_PREFIX}.keywordGroups`,
  getConfig: ({ orgId, queryParams, paginationParams }: KeywordRequestParams) => ({
    url: `${queryParams.length > 0
      ? `organization/${orgId}/keyword_set/${paginationParams}${queryParams}`
      : `organization/${orgId}/keyword_set/${paginationParams}`}`,
  }),
  transformData: (data: any): KeywordGroup[] | [] => {
    if (data.results.length) {
      return data;
    }
    return [];
  },
});

export const groupKeywordsRequestState = defineRequestState({
  path: `${PATH_PREFIX}.groupKeywords`,
  getConfig: ({ orgId, queryParams, paginationParams }: KeywordRequestParams) => ({
    url: `${queryParams.length > 0
      ? `organization/${orgId}/keyword/${paginationParams}${queryParams}`
      : `organization/${orgId}/keyword/${paginationParams}`}`,
  }),
  transformData: (data: any): Keyword[] | [] => {
    if (data.results.length) {
      return data;
    }
    return [];
  },
});

export const keywordsToGroupRequestState = defineRequestState({
  path: `${PATH_PREFIX}.keywordsToGroup`,
  getConfig: ({ orgId, queryParams, paginationParams }: KeywordRequestParams) => ({
    url: `${queryParams.length > 0
      ? `organization/${orgId}/keyword/${paginationParams}${queryParams}`
      : `organization/${orgId}/keyword/${paginationParams}`}`,
  }),
  transformData: (data: any): Keyword[] | [] => {
    if (data.results.length) {
      return data;
    }
    return [];
  },
});

export const keywordGroupSelectRequestState = defineRequestState({
  path: `${PATH_PREFIX}.keywordGroupsSearch`,
  getConfig: ({ orgId, searchQuery }: KeywordGroupSelectRequestParams) => ({
    url: searchQuery
      ? `organization/${orgId}/keyword_set/?&query=${searchQuery}`
      : `organization/${orgId}/keyword_set/?`,
  }),
  transformData: (data: any): KeywordGroup[] | [] => {
    if (data.results.length) {
      return data;
    }
    return [];
  },
});

type GroupAccountsRequestParams = {
  orgId: string | number;
  // groupIds: number[];
  queryParams: string;
}

export const groupAccountsRequestState = defineRequestState({
  path: `${PATH_PREFIX}.groupActivities`,
  getConfig: ({ orgId, queryParams }: GroupAccountsRequestParams) => ({
    url: `query/${orgId}/top_kw_groups/?${queryParams}`,
  }),
});

export const keywordGroupSelectQueryState = defineSharedState<String>({
  path: `${PATH_PREFIX}.keywordGroupSelectQuery`,
  defaultValue: '',
});

export const addGroupVisibleState = defineSharedState<Boolean>({
  path: `${PATH_PREFIX}.addGroupModalVisible`,
  defaultValue: false,
});

export const renameGroupVisibleState = defineSharedState<Boolean>({
  path: `${PATH_PREFIX}.renameGroupModalVisible`,
  defaultValue: false,
});

export const deleteGroupVisibleState = defineSharedState<Boolean>({
  path: `${PATH_PREFIX}.deleteGroupModalVisible`,
  defaultValue: false,
});

export const addToGroupVisibleState = defineSharedState<Boolean>({
  path: `${PATH_PREFIX}.addKwsToGroupModalVisible`,
  defaultValue: false,
});

export const bulkAddVisibleState = defineSharedState<Boolean>({
  path: `${PATH_PREFIX}.bulkAddModalVisible`,
  defaultValue: false,
});

export const selectGroupCounter = defineSharedState<Number>({
  path: `${PATH_PREFIX}.selectGroupCounter`,
  defaultValue: 0,
});

// Which group is selected for group detail keyword list
export const selectedGroupState = defineSharedState<KeywordGroup|{}>({
  path: `${PATH_PREFIX}.selectedGroup`,
  defaultValue: {},
});

export type KeywordAccountsRequestParams = {
  orgId: string | number;
  keywordIds: number[];
  span: string;
};

export const addToGroupAccountsRequestState = defineRequestState({
  path: `${PATH_PREFIX}.keywordAccounts`,
  getConfig: ({ orgId, keywordIds, span }: KeywordAccountsRequestParams) => ({
    url: `query/${orgId}/keyword_accounts/?span=${span}`,
    method: 'POST',
    body: JSON.stringify({
      keyword_ids: keywordIds,
    }),
  }),
  transformData: (data: any) => data,
});

export const DEFAULT_BULK_ADD_GROUPS_CONFIG_STATE = {
  keywords: [],
  keyword_groups: [],
  group_options: [],
};

export const bulkAddToGroupConfigState = defineSharedState<BulkAddToGroupConfig>({
  path: `${PATH_PREFIX}.bulkAddToGroupConfig`,
  defaultValue: { ...DEFAULT_BULK_ADD_GROUPS_CONFIG_STATE },
});

export const siKeywordsValidationState = defineSharedState<SIKeywordsValidationType>({
  path: `${PATH_PREFIX}.siKeywordsValidation`,
  defaultValue: {
    keywordGroup: true,
    addKeyword: true,
  },
});
