/* eslint-disable */
import React from 'react';

export const FileCabinet = ({ solid, ...props }) => solid
  ? <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        d="M9.8954,0 C10.1312917,0.000486689739 10.3417166,0.138190876 10.4391941,0.34656439 L10.4738,0.44 L11.9928,5.9468 L11.9981925,5.97315732 L11.9981925,5.97315732 L12,6 L12,11 C11.999386,11.5125813 11.6136357,11.9349007 11.1165611,11.9931462 L11,12 L1,12 C0.487418701,11.999386 0.0650993385,11.6136357 0.00682130424,11.1165611 L-4.05857053e-05,11 L-4.05857053e-05,6 L0.00127905577,5.97298996 L0.00127905577,5.97298996 L0.0062,5.9464 L1.5262,0.44 C1.5896215,0.212793375 1.77848763,0.0467491973 2.00531779,0.00841563452 L2.1046,0 L9.8954,0 Z M7.5224,7.4 L4.4774,7.4 L4.6986,8.0632 C4.72579111,8.14483873 4.80215214,8.19993467 4.8882,8.2 L4.8882,8.2 L7.1118,8.2 C7.19784786,8.19993467 7.27420889,8.14483873 7.3014,8.0632 L7.3014,8.0632 L7.5224,7.4 Z M10.211,4 L1.789,4 L1.5132,5 L10.4868,5 L10.211,4 Z M9.8248,2.6 L2.1752,2.6 L1.9,3.6 L10.1,3.6 L9.8248,2.6 Z M9.4384,1.2 L2.5616,1.2 L2.2856,2.2 L9.7144,2.2 L9.4384,1.2 Z"
        />
      </svg>
  : <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    {...props}
    >
      <path d="M11.9928,5.9468 L10.4738,0.44 C10.4013183,0.180335286 10.1649905,0.000556216845 9.8954,0 L2.1046,0 C1.83500947,0.000556216845 1.59868171,0.180335286 1.5262,0.44 L0.0062,5.9464 C0.00172165989,5.96390458 -0.000363982608,5.9819353 -4.05857053e-05,6 L-4.05857053e-05,11 C0.000661223267,11.5520106 0.44798937,11.9993388 1,12 L11,12 C11.5520106,11.9993388 11.9993388,11.5520106 12,11 L12,6 C12,5.98202368 11.9975799,5.96412917 11.9928,5.9468 Z M1.9128,0.5468 C1.93663243,0.460427923 2.01500133,0.400445888 2.1046,0.4 L9.8954,0.4 C9.9853702,0.4 10.0642679,0.460071151 10.0882,0.5468 L11.5374,5.8 L0.4626,5.8 L1.9128,0.5468 Z M11,11.6 L1,11.6 C0.66862915,11.6 0.4,11.3313708 0.4,11 L0.4,6.2 L11.6,6.2 L11.6,11 C11.6,11.3313708 11.3313708,11.6 11,11.6 Z" />
      <path d="M1.0916,5.3212 C1.12962083,5.37110929 1.18885894,5.40028407 1.2516,5.40000206 L10.7494,5.40000206 C10.8118405,5.40000206 10.8706989,5.37084115 10.9085264,5.3211632 C10.9463538,5.27148524 10.9588092,5.20699095 10.9422,5.1468 L9.7836,0.9468 C9.75966793,0.860071151 9.6807702,0.8 9.5908,0.8 L2.4092,0.8 C2.3192298,0.8 2.24033207,0.860071151 2.2164,0.9468 L1.0578,5.1468 C1.04121414,5.20702186 1.05371717,5.27153457 1.0916,5.3212 Z M1.5132,5 L1.789,4 L10.211,4 L10.4868,5 L1.5132,5 Z M2.1752,2.6 L9.8248,2.6 L10.1,3.6 L1.9,3.6 L2.1752,2.6 Z M2.5616,1.2 L9.4384,1.2 L9.7144,2.2 L2.2856,2.2 L2.5616,1.2 Z" />
      <path d="M7.5226,6.99999969 L4.4774,6.99999969 C4.34875205,6.99984034 4.22787897,7.06156644 4.1525799,7.16587546 C4.07728083,7.27018448 4.05674712,7.40434401 4.0974,7.5264 L4.32,8.1898 C4.40157252,8.43442797 4.63033038,8.59957489 4.8882,8.6 L7.1118,8.6 C7.37009083,8.5999761 7.5993801,8.4346558 7.681,8.1896 L7.901,7.5266 C7.94164381,7.40478655 7.92133243,7.27086619 7.846397,7.16658237 C7.77146157,7.06229855 7.65101466,7.00033277 7.5226,6.99999969 Z M7.3014,8.0632 C7.27420889,8.14483873 7.19784786,8.19993467 7.1118,8.2 L4.8882,8.2 C4.80215214,8.19993467 4.72579111,8.14483873 4.6986,8.0632 L4.4774,7.4 L7.5224,7.4 L7.3014,8.0632 Z" />
    </svg>

