import React from 'react';
import {
  Link,
} from 'v2-components';
import '../../styles/core.scss';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Permission, usePermissionCheck } from '@sixsense/rbac';
import FlagContext, { useFlags } from 'contexts/FlagContext';
import {
  SETTINGS_PERMISSIONS,
} from '../../constants/featurePermissions';
import TopBar from '@sixsense/core/components/skeletal/TopBar';
import { Settings } from '@sixsense/core/icons';
import { actions as userActions } from 'modules/user/index';
import { useDispatch } from '@sixsense/core/versioned/react-redux';


function SettingsComponent() {
  const dispatch = useDispatch();
  const { reportsLandingPageEnabled } = useFlags();
  const settingsPermissions = reportsLandingPageEnabled ? SETTINGS_PERMISSIONS.filter(
    (p) => p !== Permission.SETTINGS_ADMIN_REPORTS_VIEW
  ) : SETTINGS_PERMISSIONS;
  const showSettings = usePermissionCheck(settingsPermissions);
  if (!showSettings) {
    return null;
  }

  const onSettingClick = () => {
    dispatch(userActions.setResetAppSettingRandom(Math.random()));
  };
  return (
    <Link
      link="/settings"
      isInternal
    >
      <TopBar.IconButton icon={Settings} onClick={onSettingClick} data-6si-id="abm_settings_icon" />
    </Link>
  );
}

SettingsComponent.propTypes = {
};

SettingsComponent.defaultProps = {
};

const mapStateToProps = () => ({
});

export default compose(
  FlagContext.FlagConsumer,
  connect(mapStateToProps, { }),
)(SettingsComponent);
