export const CSV_DATA_TYPE = {
  hide: true,
  customizable: false,
  key: "csv-data-type",
  csvColumnKey: "data_type",
  title: "Data Type",
  render: () => null,
  csvOverrides: {
    hide: false,
  },
};
