import { defineRequestState } from '@sixsense/core/request';

export type Product = {
  id: string;
  displayName: string;
  normalizedName: string;
  name: string;
}

type Params = {
  hasPredictive: boolean;
}

export const productsRequestState = defineRequestState({
  path: 'global.products',
  getConfig: ({ hasPredictive }: Params) => ({
    url: 'graphql/',
    method: 'POST',
    body: JSON.stringify({
      query: `query { products(isPredictive: ${
        hasPredictive
      }) { id, displayName, normalizedName, name }}`,
    }),
    cacheOptions: {
      enabled: true,
    },
  }),
  transformData: (resp: any) => resp.data.products.map((product: Product) => ({
    label: product.displayName,
    value: product.name,
  })),
});
