import React, { useState, HTMLProps } from 'react';
import { Card, Flex } from '@sixsense/core/components';
import { Icon } from 'v2-components';
import { css } from 'styles/emotion';

const styles = {
  header: css({
    cursor: 'pointer',
    userSelect: 'none',
  }),
};

type Props = HTMLProps<HTMLDivElement> & {
  title: string;
};

const Section = ({ title, children, ...props }: Props) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((lastExpanded) => !lastExpanded);
  };
  return (
    <div {...props}>
      <Card.Header onClick={toggleExpanded} className={styles.header}>
        <Flex>
          <Card.Header.Subtitle>
            {title}
          </Card.Header.Subtitle>
          <div style={{ flex: 1 }} />
          <Icon type={expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} size="24px" />
        </Flex>
      </Card.Header>
      {expanded ? (
        <div>
          <Card.Content>
            {children}
          </Card.Content>
          <Card.Divider />
        </div>
      ) : null}
    </div>
  );
};

export default Section;
