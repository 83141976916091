export const DAILY = { value: 'daily', label: 'daily' };
export const WEEKLY = { value: 'weekly', label: 'weekly' };

export const ALERT_FREQUENCY_OPTIONS = [
  DAILY, WEEKLY,
];
export const DAYZ = [
  { label: 'Monday', value: 0 },
  { label: 'Tuesday', value: 1 },
  { label: 'Wednesday', value: 2 },
  { label: 'Thursday', value: 3 },
  { label: 'Friday', value: 4 },
  { label: 'Saturday', value: 5 },
  { label: 'Sunday', value: 6 },
];

export const AlertType = {
  EMAIL: 'email',
  SLACK: 'slack',
};
export const ALERT_TYPE_OPTIONS = [{
  label: 'email',
  value: 'email',
}, {
  label: 'slack',
  value: 'slack',
}];


export const CATEGORIES_DEFAULT_VALUES = {
  new_hot_accounts: {
    category_name: 'New Hot Accounts',
    is_enabled: true,
    sub_selections: [],
  },
  recent_web_visits: {
    category_name: 'Recent Web Visits',
    selection_type: 'any',
    is_enabled: true,
    sub_selections: [],
  },
  recent_intent_activities: {
    category_name: 'Recent Intent Activities',
    selection_type: 'any',
    is_enabled: true,
    sub_selections: [],
  },
  recent_contact_engagement: {
    category_name: 'Recent Contact Engagement',
    selection_type: 'default',
    is_enabled: true,
    sub_selections: [],
  },
};

export const ALL_CRM_BLOCKED_ROLE_IDS = [6, 7];
