import {
  filter,
  find,
  flow,
  get,
  isEmpty,
  keys,
  max,
  pickBy,
  some,
} from "lodash";
import moment from "moment";
import {
  DAYS_OF_WEEK,
  TIME_SELECTION,
  SUPPORTED_REDIRECT_URL_MODES,
  SYSTEM_SEGMENT,
  COUNTRY_TARGETING,
} from "./constants";
import { ADVERTISING_BASE_ROUTE } from "routes/AdvertisingDS/constants";
import { CAMPAIGNS_ROUTE } from "routes/Advertising/routes/Campaigns/constants";
import { CAMPAIGNS_CONFIGURATION_ROUTE } from "routes/Advertising/routes/Campaigns/routes/CampaignConfiguration/constants";
import { CAMPAIGN_FIELDS } from "../../constants";

export const selectValidProfileTargets = (profileTargets) =>
  filter(
    profileTargets,
    flow(
      // it breaks if we do not wrap pickBy bc flow passes 0 as a second argument
      (i) => pickBy(i),
      (i) => keys(i).length === 2
    )
  );

const parseToString = (value) => (value && value.toString().trim()) || "";

const parsedInputValue = (value) =>
  parseToString(value).replace(/\$|>|</gi, "");

export const currencyValue = (value) => {
  const numberValue =
    (value && value !== "$" && `$${parsedInputValue(value)}`) || value;
  return numberValue;
};

export const parseInputValueToInt = (value) =>
  Number(parsedInputValue(value), 10);

export const getGroupAds = (groupId, campaignAds) =>
  filter(campaignAds, (campaignAd) => campaignAd.groupId === groupId);

export const renderInitialJobTargetingValue = (
  data,
  jobLevels,
  jobFunctions
) => {
  const jobTargeting = [];
  data.forEach(({ jobLevel }) => {
    const jobLevelValue = get(
      jobLevels.find(({ id }) => id === jobLevel),
      "display_name"
    );
    if (!some(jobTargeting, ["jobLevel", [jobLevelValue]])) {
      const newJobFunction = data
        .filter((jt) => jt.jobLevel === jobLevel)
        .map((jt) =>
          get(
            jobFunctions.find(({ id }) => id === jt.jobFunction),
            "display_name"
          )
        );

      jobTargeting.push({
        jobLevel: [jobLevelValue],
        jobFunction: newJobFunction,
      });
    }
  });
  return jobTargeting;
};

const getId = (data, item) =>
  get(
    data.find(({ display_name }) => item === display_name.trim()),
    "id",
    ""
  );

export const renderJobTargetingReqBody = (
  data,
  jobLevelValues,
  jobFunctionValues
) => {
  const jobTargeting = [];
  data
    .filter((jt) => !isEmpty(jt))
    .forEach((entry) => {
      const jobFunction = get(entry, "jobFunction", []);
      const jobLevel = get(entry, "jobLevel", []);
      jobFunction.forEach((jf) => {
        jobLevel.forEach((jl) => {
          jobTargeting.push({
            jobLevel: getId(jobLevelValues, jl),
            jobFunction: getId(jobFunctionValues, jf),
          });
        });
      });
    });
  return jobTargeting;
};

export const renderDaypartTargeting = (
  startTime,
  endTime,
  daysOfWeek,
  timeSelection
) => {
  const weekDaysSet = new Set(moment.weekdays());
  if (daysOfWeek === DAYS_OF_WEEK.WEEKDAYS) {
    weekDaysSet.delete("Sunday");
    weekDaysSet.delete("Saturday");
  }
  const daypartTargeting = [];
  if (timeSelection === TIME_SELECTION.ALL_TIME) {
    weekDaysSet.forEach((day) =>
      daypartTargeting.push({
        day: day.toLowerCase(),
        start_hour: 0,
        end_hour: 23,
      })
    );
  } else if (
    startTime &&
    endTime &&
    timeSelection === TIME_SELECTION.SPECIFIC_TIME
  ) {
    weekDaysSet.forEach((day) =>
      daypartTargeting.push({
        day: day.toLowerCase(),
        start_hour: startTime,
        end_hour: endTime,
      })
    );
  }
  return daypartTargeting;
};

const timeOptionsArray = Array(24).fill("");
export const timeOptions = (setStartTimeLabel) =>
  timeOptionsArray.map((val, index) => {
    let label = setStartTimeLabel ? `${index}:00 AM` : `${index + 1}:00 AM`;
    if (index === 0) {
      label = setStartTimeLabel ? "12:00 AM" : "01:00 AM";
    }
    if (index === 12) {
      label = setStartTimeLabel ? "12:00 PM" : "01:00 PM";
    }
    if (index > 12) {
      label = setStartTimeLabel
        ? `${index - 12}:00 PM`
        : `${index + 1 - 12}:00 PM`;
      if (index === 23 && !setStartTimeLabel) {
        label = "12:00 AM";
      }
    }
    return { label, value: index.toString() };
  });

export const renderInitialTimeSelectionValues = (
  daypartTargeting,
  daypartTimezone
) => {
  if (
    !isEmpty(daypartTargeting) &&
    (daypartTimezone ||
      daypartTargeting[0].end_hour - daypartTargeting[0].start_hour < 23)
  ) {
    return TIME_SELECTION.SPECIFIC_TIME;
  }
  return TIME_SELECTION.ALL_TIME;
};

export const renderInitialDayPartingValues = (daypartTargeting) =>
  isEmpty(daypartTargeting)
    ? {
        start_time: "0",
        end_time: "23",
        days_of_week: DAYS_OF_WEEK.ALL_DAYS,
      }
    : {
        start_time: daypartTargeting[0].start_hour.toString(),
        end_time: daypartTargeting[0].end_hour.toString(),
        days_of_week:
          daypartTargeting.length === 7
            ? DAYS_OF_WEEK.ALL_DAYS
            : DAYS_OF_WEEK.WEEKDAYS,
      };

export const getTargetCountry = (campaign) =>
  get(campaign, "country_targets[0].id", COUNTRY_TARGETING.GLOBAL_OPTION_VALUE);

export const targetedAccountsSortConfig = {
  firm_name: {
    compareFn: (a, b) => {
      if (a.firm_name > b.firm_name) return 1;
      if (a.firm_name < b.firm_name) return -1;
      return 0;
    },
  },
  user_count: {
    compareFn: (a, b) => {
      const aCount = get(a, "user_count", 0);
      const bCount = get(b, "user_count", 0);
      if (aCount > bCount) return -1;
      if (aCount < bCount) return 1;
      return 0;
    },
  },
  keyword_count: {
    compareFn: (a, b) => {
      const aCount = get(a, "keyword_count", 0);
      const bCount = get(b, "keyword_count", 0);
      if (aCount > bCount) return -1;
      if (aCount < bCount) return 1;
      return 0;
    },
  },
  activity_count: {
    compareFn: (a, b) => {
      const aCount = get(a, "activity_count", 0);
      const bCount = get(b, "activity_count", 0);
      if (aCount > bCount) return -1;
      if (aCount < bCount) return 1;
      return 0;
    },
  },
  latest_activity: {
    compareFn: (a, b) => {
      const A = (
        max([a.latest_web_activity, a.latest_kw_activity]) || "1970-01-01"
      ).toString();
      const B = (
        max([b.latest_web_activity, b.latest_kw_activity]) || "1970-01-01"
      ).toString();
      if (moment(A, "YYYY-MM-DD").isAfter(B)) return -1;
      if (moment(A, "YYYY-MM-DD").isBefore(B)) return 1;
      return 0;
    },
  },
};

export const doesSupportRedirectUrlClickTracker = (provider) => {
  const { support_redirect_url } = provider;

  return [
    SUPPORTED_REDIRECT_URL_MODES.ALL,
    SUPPORTED_REDIRECT_URL_MODES.PILOT,
  ].includes(support_redirect_url);
};

export const linkedInCampaignCreativesURL = (campaign) => {
  const linkedInAdAccountId = get(campaign, "li_account.account_id", 0);
  const linkedInCampaignId = get(campaign, "linkedin_id", 0);

  return (
    `https://www.linkedin.com/campaignmanager/accounts/${linkedInAdAccountId}/campaigns` +
    `/${linkedInCampaignId}/creatives`
  );
};

export const linkedInCampaignCreativesPerformanceURL = (campaign) => {
  const linkedInAdAccountId = get(campaign, "li_account.account_id", 0);
  const linkedInCampaignId = get(campaign, "linkedin_id", 0);

  const url =
    `https://www.linkedin.com/campaignmanager/accounts/${linkedInAdAccountId}` +
    `/creatives?campaignIds=[${linkedInCampaignId}]`;

  return encodeURI(url);
};

export const linkedInCampaignDetailsURL = (campaign) => {
  const linkedInAdAccountId = get(campaign, "li_account.account_id", 0);
  const linkedInCampaignId = get(campaign, "linkedin_id", 0);

  return (
    `https://www.linkedin.com/campaignmanager/accounts/${linkedInAdAccountId}` +
    `/campaigns/${linkedInCampaignId}/details`
  );
};

export const isSystemSegment = (segment) =>
  !!(get(segment, "segment_type", "") === SYSTEM_SEGMENT);

export const isLinkedInPosterAccessRevoked = (campaign) =>
  get(campaign, "linkedin_ads_page.sponsored_content_poster_status") ===
  "REVOKED";

export const getCampaignEditStepLink = (campaignId, stepId) =>
  `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGNS_CONFIGURATION_ROUTE}/edit/${campaignId}/?redirectStep=${stepId}`;

export const getProductFromCampaignDetails = (
  campaignMeta,
  products,
  hasPredictiveProducts
) =>
  hasPredictiveProducts &&
  !!find(
    products,
    ({ value: productValue }) =>
      productValue === get(campaignMeta, CAMPAIGN_FIELDS.product)
  )
    ? get(campaignMeta, CAMPAIGN_FIELDS.product)
    : null;
