/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import { COLORS } from '../../v2-styles/constants';
import styles from './Text.module.scss';
import { Tooltip2 as Tooltip } from 'v2-components';

const WEIGHT = {
  BOLD: 'bold',
  LIGHT: 'light',
  NORMAL: 'normal',
};
const TEXT_TYPES = {
  TITLE: 'title',
  TITLE2: 'title2',
  BODY: 'body',
  SUBBODY: 'subBody',
  MINI: 'mini',
  /**
   * DO NOT ADD ADDITIONAL TYPES!!!
   */
};

/**
 *
 * @param {bool} pointer controls whether not to add cursor: pointer on hover
 * @param {bool} space adds a trailing space to the right end of the text. This is useful
 * if you need to style one word differently within a string. Instead of creating a class
 * to add necessary padding, you can simply ad this prop
 * @param {role} this is really only so a piece of text can be accessed with vimium
 */
function Text(props) {
  const {
    id,
    type,
    color,
    bold,
    weight,
    children,
    className,
    uppercase,
    onClick,
    align,
    allowUserSelect,
    pointer,
    lightUp,
    title,
    space,
    italic,
    disabled,
    role,
    noOverflow,
    tooltipPlacement,
    tooltipClassName,
    overlayClassName,
  } = props;

  const ref = useRef(null);

  const isOverflowing = () => {
    const [dimensions, setDimensions] = useState({});
  /*
    Without this in some cases we cant access current from ref, because
    we try to access it before the ref is assigned
  */
    useEffect(() => {
      const { current: { clientHeight, clientWidth, scrollHeight, scrollWidth } } = ref;
      setDimensions({ clientHeight, clientWidth, scrollWidth, scrollHeight });
    }, [ref.current]);

    const { clientWidth, clientHeight, scrollHeight, scrollWidth } = dimensions || {};

    if (ref === undefined || ref === null) {
      return false;
    }

    return clientWidth < scrollWidth || clientHeight < scrollHeight;
  };


  const textCase = uppercase ? styles.caps : '';
  const boldFont = bold ? styles.bold : '';

  let textStyle = `
    ${styles[type]}
    ${styles[color]}
    ${styles[weight]}
    ${textCase}
    ${boldFont}
    ${styles[align]}
    ${pointer ? styles.pointer : null}
    ${lightUp ? styles.lightUp : null}
    ${italic ? styles.italic : null}
    ${noOverflow ? styles.noOverflow : null}
    ${disabled ? styles.disabled : ''}
  `;

  textStyle = className ? `${textStyle} ${className}` : textStyle;
  textStyle = !allowUserSelect ? `${textStyle} ${styles.noUserSelect}` : textStyle;

  const textElem = (
    <span
      onClick={(e) => onClick && onClick(id, e)}
      role={role}
      className={textStyle}
      id={id}
      title={title}
      ref={ref}
    >
      {children}{space ? <span>&nbsp;</span> : ''}
    </span>
  );

  return (
    <Fragment>
      {!noOverflow ?
        textElem :
        (<Tooltip
          overlay={children}
          placement={tooltipPlacement}
          mouseLeaveDelay={0}
          className={tooltipClassName}
          overlayClassName={`
            ${overlayClassName}
            ${!isOverflowing() ? styles.hideTooltip : null}
            `
          }
        >
          {textElem}
        </Tooltip>)
      }
    </Fragment>
  );
}

Text.propTypes = {
  bold: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TEXT_TYPES)),
  color: PropTypes.oneOf(values(COLORS)),
  weight: PropTypes.oneOf(Object.values(WEIGHT)), // only bold used
  uppercase: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.element,
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  align: PropTypes.oneOf(['right', 'left']),
  allowUserSelect: PropTypes.bool,
  pointer: PropTypes.bool,
  lightUp: PropTypes.bool,
  title: PropTypes.string,
  space: PropTypes.bool,
  italic: PropTypes.bool,
  disabled: PropTypes.bool,
  role: PropTypes.string,
  noOverflow: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
  tooltipClassName: PropTypes.string,
  overlayClassName: PropTypes.string,
};

Text.defaultProps = {
  type: 'body',
  color: 'grey',
  weight: 'normal',
  bold: false,
  uppercase: false,
  allowUserSelect: true,
  lightUp: false,
  space: false,
  italic: false,
  noOverflow: false,
};

Text.TYPE = Object.freeze(TEXT_TYPES);
Text.WEIGHT = Object.freeze(WEIGHT);
Text.COLOR = COLORS;

export default Text;
