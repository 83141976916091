import React from 'react';
import Text from 'v2-components/Text';
import { COLUMN_CATEGORIES } from '../constants';
import { NA } from './common/NA';
import { numberToDollar } from 'utils/utils';
import { CAMPAIGN_SOURCE } from '../../../constants';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';
import { isDraftOrScheduledCampaign } from '../../../utils';

export const eCPM = {
  sortByKey: 'cpm',
  category: COLUMN_CATEGORIES.general,
  key: 'cpm',
  csvColumnKey: 'cpm',
  title: <Label name={CAMPAIGN_LABELS.cpm} />,
  dataIndex: 'campaign_data.cpm',
  width: 110,
  render: (cpm, campaign) => {
    const campaignStatus = campaign.campaign_status;
    const campaignSource = campaign.campaign_source;
    if (campaignSource === CAMPAIGN_SOURCE.EXTERNAL ||
      isDraftOrScheduledCampaign(campaignStatus)) {
      return (<NA />);
    }

    return (<Text type={Text.TYPE.SUBBODY}>{numberToDollar(cpm)}</Text>);
  },
};
