import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TablePreviewLayout } from 'aa-components';
import { Row, Text } from 'v2-components';
import styles from './PreviewPane.module.scss';
import { toDisplayfp, formatBytes } from 'utils/utils';
import { parseFileTypeName } from './utils';

const { AdPreview } = TablePreviewLayout;

export function PreviewPane(props) {
  const { selectedCreativePreview, extraAdPreviewData } = props;
  const { s3_url, dimensions, file_type, file_size, creative_type } = selectedCreativePreview;
  const adPreviewProps = {
    placementType: creative_type,
    creative: selectedCreativePreview,
  };
  const NA = '-';
  const stopValues = [undefined, null, NA, ''];
  const toDisplay = toDisplayfp(stopValues, NA);
  const fileTypeDisplay = toDisplay()(parseFileTypeName(file_type));
  const dimensionsDisplay = toDisplay((value) => `${value}px`)(dimensions);
  const fileSize = formatBytes(file_size);
  const previewMetaData = `${fileTypeDisplay}, ${
    !dimensions ? '' : `${dimensionsDisplay}, `
  }${fileSize}`;
  return (
    <Fragment>
      <Row>
        <Text className={`${styles.modalTitle} ${styles.modalTitleMain} aam-b--5`}>
          Select a creative from the list to preview.
        </Text>
      </Row>
      {s3_url && (
        <Fragment>
          <AdPreview {...adPreviewProps} extraAdPreviewData={extraAdPreviewData} />
          <Row flexDirection={Row.FLEX_DIRECTION.COLUMN} className={styles.creativeMetaData}>
            <Text color={Text.COLOR.GREY}>{previewMetaData}</Text>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
}

PreviewPane.propTypes = {
  selectedCreativePreview: PropTypes.object,
  extraAdPreviewData: PropTypes.object,
};
