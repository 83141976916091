import {
  ACCOUNTS,
  CONTAIN_DYNAMIC_HTML_ZIP_ERROR,
  CONTAIN_HTML_ZIP_ERROR,
  CREATIVE_NAME_ERROR,
  CREATIVE_TYPE_ERROR,
  DATE_FORMAT,
  RELEASE_TYPE_LOGOS,
  VISITORS,
  LINKEDIN_VIDEO_ASSET_ATTACHMENT_TYPE,
  RAIM_DASHBOARD_USERS_SPECIAL_ACCESS,
} from './constants';
import rollbar from 'lib/rollbar';
import {
  clone,
  curry,
  difference,
  each,
  every,
  filter,
  findKey,
  first,
  flow,
  get,
  groupBy,
  has,
  includes,
  invert,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  keys,
  last,
  map,
  mapValues,
  memoize,
  orderBy,
  pick,
  pickBy,
  reduce,
  replace,
  set as lodashSet,
  size,
  slice,
  some,
  split,
  startCase,
  toString as convertToString,
  find,
  uniq,
  values as valuesFn,
  upperFirst,
} from 'lodash';
import { clone as fpClone, curry as curryFp, setWith as setWithFp } from 'lodash/fp';
import { formValueSelector, getFormSyncErrors as gfse, SubmissionError } from 'redux-form';
import moment from 'moment';
import mapfp from 'lodash/fp/map';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import momentTz from 'moment-timezone';
import jszip from 'jszip';
import {
  DYNAMIC_AD_ERROR,
  VALIDATE_DYNAMIC_AD_STRINGS,
  ASPECT_RATIO,
  LINKEDIN_PAGE_STATUS,
  SPONSORED_CONTENT_POSTER_STATUS,
} from 'utils/constants';
import { FEATURE_FLAGS } from 'constants/featureFlags';
import TransparentBackground from 'images/transparent-background.png';
import { AD_TYPE_IDS } from 'routes/Advertising/constants';
import {
  AD_TYPE_PROPERTIES,
  LINKEDIN_VIDEO_RATIO_DIMENSION_TITLE_MAPPING,
}
  from 'routes/Advertising/components/AdFormatsModal/constants';
import srtValidator, { parser } from 'srt-validator';

const isDev = process.env.SIXSENSE_ENV === 'dev';

/**
 * Dope lil decorator that calls a function only if the arguments are different than the
 * previous invocation. THIS IS NOT MEMOIZE.
 * @param {*} func the function to wrap
 */
const onceWithArgs = (func) => {
  const previousArgs = { prevArgs: [] };
  const wrapped = (...args) => {
    const { prevArgs, prevResult } = previousArgs;
    if (previousArgs.prevArgs.length === args.length
      && every(prevArgs, (value, index) => value === args[index])) {
      // Already been called with these args
      return prevResult;
    }
    previousArgs.prevArgs = args;
    previousArgs.prevResult = func(...args);
    return previousArgs.prevResult;
  };
  return wrapped;
};

const camelToDisplay = (camel) => flow(
  (n) => n.split('_'),
  mapfp((n) => n.charAt(0).toUpperCase() + n.slice(1)),
  (n) => n.join(' ')
)(camel);

function getCookie(name) {
  const pattern = `(?:^|;)\\s?${name}=(.*?)(?:;|$)`;
  const match = new RegExp(pattern, 'i').exec(document.cookie);

  if (match === null) {
    return null;
  }

  const value = decodeURIComponent(match[1]);
  const startWithQuote = /^".*/.test(value);
  const endsWithQuote = /.*"$/.test(value);

  return value.substring(startWithQuote ? 1 : 0, endsWithQuote ? value.length - 1 : value.length);
}

function isUserLoggedIn() {
  return !isEmpty(getCookie('__Secure-6sisession'));
}

function truncateString(longString, numCharacters) {
  const parsedString = longString || '';
  if (parsedString.length < numCharacters) {
    return parsedString;
  }
  return `${parsedString.slice(0, Math.max(numCharacters - 3, 1))}...`;
}

function apiToUIString(apiString) {
  return {
    a_pageload: 'Page Views',
    accounts: ACCOUNTS,
    user_count: VISITORS,
    play: 'Video Plays',
    website_events: 'Website Events',
    submit: 'Form Submits',
    click: 'Clicks',
    keywords: 'Keywords',
    all_keywords: 'All Keywords',
    generic: 'Generic Keywords',
    branded: 'Branded Keywords',
    media_impression: 'Media Impressions',
    media_click: 'Media Clicks',
    campaigns: '6sense Media Campaigns',
    third_party_media: 'External Media Campaigns',
  }[apiString];
}

function combineStateObjs(statesList) {
  const error = statesList.every((item) => item.error);
  const errorMessageIndex = statesList.find(
    (item) => typeof item.errorMessage === 'string' && item.errorMessage.length > 0
  );
  let errorMessage = '';
  if (errorMessageIndex) {
    errorMessage = statesList[errorMessageIndex].errorMessage;
  }
  const errorStatus = statesList.find((item) => typeof item.errorStatus === 'number');
  const loading = statesList.every((item) => item.loading);
  const loaded = statesList.every((item) => item.loaded);
  return {
    error,
    errorMessage,
    errorStatus,
    loading,
    loaded,
  };
}
const roundTo = (decimals) =>
  (n) => parseFloat(Math.round(n * 100) / 100).toFixed(decimals);
/* String Manipulation functoin.
 * These are helper functions to be used in views to convert
 * data into displayable strings. For an example convert a float
 * into a dollar
 */

/**
  * Convert a number into a formatted number string.
  * Number can be rounded to nearest thousand, million, or billion if place is passed
  * @param {number} number
  * @param {object} config {
      @param {boolean} abbreviate - will abbreviate and round number to nearest place value
      @param {boolean} showCents - whether or not to display cents
      @param {boolean} insertCommas - whether or not to insert commas into the formatted
        number string. DO NOT USE WITH ABBREVIATE!
      @param {boolean} compact - whether to show numbers in a compact for (no cents, K has 1 sigfig,
        M and B have 2 sig figs)
      @param {string} currencySymbol = "$" - Currency Symbol to prefix the return value
    }
  */
function numberToDollar(number = 0, config = {}) {
  const {
    abbreviate,
    showCents,
    insertCommas,
    compact = false,
    currencySymbol = '$',
  } = config;

  const abbreviations = {
    1000: 'K',
    1000000: 'M',
    1000000000: 'B',
  };
  if (showCents && number < 1) {
    return `${currencySymbol}${roundTo(2)(number)}`;
  }

  let place;
  let sigFigs = 1;
  switch (Math.abs(number) > -1) {
    case Math.abs(number) >= 999999999:
      place = 1000000000;
      sigFigs = 2;
      break;
    case Math.abs(number) >= 999999:
      place = 1000000;
      sigFigs = 2;
      break;
    case Math.abs(number) > 999:
      place = 1000;
      sigFigs = 1;
      break;
    default:
      place = 1;
      sigFigs = 0;
  }

  const fixedNumber = abbreviate
    ? parseFloat((number / place).toFixed(compact ? sigFigs : 1))
    : parseFloat(Math.round(number * 100) / 100).toFixed(2);

  const displayNumber = `${currencySymbol}${fixedNumber.toLocaleString(
    undefined,
    compact ? ({ minimumFractionDigits: sigFigs, maximumFractionDigits: sigFigs }) : undefined
  )}`;
  const canAbbreviate = abbreviate && place !== 1;

  if (canAbbreviate) {
    return `${displayNumber}${abbreviations[place]}`;
  } else if (compact) {
    return displayNumber;
  }

  const stringNumber = displayNumber.indexOf('.') > -1
    ? displayNumber
    : `${displayNumber}.00`;

  // $6969.69 => $6,969.69 ...obv for unabreviated values
  if (insertCommas) {
    const [dollarz, centz] = stringNumber.split('.');
    const dollarzNumberPart = dollarz.startsWith('$') ? dollarz.slice(1) : dollarz;
    return `${currencySymbol}${coerceLocaleString(Number(dollarzNumberPart))}.${centz}`;
  }

  return stringNumber;
}

/**
 * Given a numerator and a denominator create a string percent
 * Does not add `%`
 * @param {number} numerator
 * @param {number} denominator
 * @param {number} sigfig number of digits pass the decimal you would like to display
 */
const safePercent = (numerator, denominator, sigfig = 0) => {
  const fraction = numerator / denominator;
  if (denominator === 0) return 0;
  return coerceLocaleString(parseFloat((fraction * 100 || 0).toFixed(sigfig)));
};

function stringFormat(template, kwargs) {
  return reduce(
    keys(kwargs),
    (acc, key) => {
      const regex = new RegExp(`{${key}}`);
      return acc.replace(regex, kwargs[key]);
    },
    template
  );
}

/**
 * Given a mapping of state to output you can override the returned string.
 * Useful for state dependent strings. For an example if you had a displayMap of
 * { draft: '-' } then whenever your state would be draft you override the string to be '-'
 * @param {object} displayMap Mapping of state
 * @param {object} state
 * @param {object} value
 */
const overrideDisplayfp = (displayMap) => (state) => (value) => {
  if (Object.prototype.hasOwnProperty.call(displayMap, state)) {
    return displayMap[state];
  }
  return value;
};

/**
 * Just an easier way to combiner classNames
 */
const classNames = (...names) => filter(names).join(' ');

/**
 * Functional representation of .toLocaleString()
 * @param {number} num
 */
const localeString = (num) => num.toLocaleString();
const coerceLocaleString = (num) => (num || 0).toLocaleString();
/**
 * Check if obj is a valid number
 * @param {Number} obj
 */
const validNumber = (obj) => {
  if (typeof obj === 'number') {
    return obj;
  }
  if (typeof obj === 'string' && !isNaN(parseFloat(obj))) {
    return parseFloat(obj);
  }
  return undefined;
};

/**
 * Functional representation of moment().format(), with data arg last
 * @param {string} format How to format the date. ex 'MMM D, YYYY'
 * @param {date} date date object
 */
const formatDate = (format) => (date) => moment(date).format(format);

/**
 * This is essentially flow but with a stopping option to terminate early.
 * So for an example if your stopValues is [null, undefined] and you have funcs g(), f()
 * this will run as f(g(value)). If g(value) returns either null or undefined then the exectution
 * will end early, not call f() and return `returnValue`
 * @param {Array} stopValues what values constitutes an early stop ex [null, undefined]
 * @param {*} returnValue what to return if we stop early
 * @param {*} funcs which functions to run
 * @param {*} value value to run through the functions
 */
function toDisplay(stopValues, returnValue, funcs, value) {
  if (includes(stopValues, value)) {
    return returnValue;
  }
  let output = value;
  for (let i = 0; i < funcs.length; i++) {
    const func = funcs[i];
    output = func(output);
    if (includes(stopValues, output)) {
      return returnValue;
    }
  }
  return output;
}

/**
 * Just a helper function for toDisplay to be able to run it for multiple values
 * Ex:
 *  Instead of
 *    const displayOne = toDisplay(stopValue, returnValue, [f,g], valueOne);
 *    const displayTwo = toDisplay(stopValue, returnValue, [f,g], valueTwo);
 *  You can do
 *    const toDiplayFunc = toDisplay(stopValue, returnValue);
 *    const [displayOne, diplayTwo] = toDisplayFunc(f,g)(valueOne, valueTwo);
 * Dope
 * @param {*} stopValues same value you would put into toDisplay
 * @param {*} returnValue same value you would put into toDisplay
 */
const toDisplayfp = (stopValues, returnValue) => (...funcs) => (...values) => {
  const output = [];
  for (let j = 0; j < values.length; j++) {
    const value = values[j];
    output.push(toDisplay(stopValues, returnValue, funcs, value));
  }
  return output.length === 1 ? output[0] : output;
};

/**
 * Just a functional representation of safePercent. There is one important thing to
 * note, numeratorDenominator is an array of [numerator, denominator]. We do it this way
 * instead of separating the two into different args liks (numerator, denominator) because
 * we are only allowed one arg per function for toDisplayfp to work :/
 * @param {number} sigfig
 * @param {array} numeratorDenominator [numerator, denominator]
 */
const safePercentfp = (sigfig) => (numeratorDenominator) => {
  const [numerator, denominator] = numeratorDenominator;
  return safePercent(numerator, denominator, sigfig);
};

// Move this From here
const genSubmissionError = (data) => {
  let error = {
    _error: 'Form Submit Failed!',
    errorStatus: data.errorStatus,
    errorMessage: 'Form Submit Failed!',
  };
  if (!(data instanceof Object)) {
    // what the eff...
    throw new SubmissionError(error);
  }
  if (data.errorStatus !== 400) {
    // Okay some weird error going on. Cant give much info here
    throw new SubmissionError(error);
  }
  error = {
    ...error,
    ...mapValues(data.body || {}, (value) => value[0]),
  };
  if (has(error, 'non_fields_errors')) {
    // Uh oh... dont know what to do here, provide map for non_field_errors...
    throw new SubmissionError(error);
  }
  // Cool we are a validation error lets give more info
  throw new SubmissionError(error);
};

const getFormSyncErrors = (formName, ...args) => (state) => {
  const syncErrors = gfse(formName)(state);
  if (args) {
    return pickBy(syncErrors, (item) => !includes(args, item));
  }
  return syncErrors;
};
// To Here

function getRandomInt(min, max) {
  const diff = max - min;
  return Math.floor(Math.random() * (diff + 1)) + min;
}

const toFixedfp = (digits) => (num) => (num === 0 ? 0 : num.toFixed(digits));

const parseDate = (date) => moment.parseZone(date).format('YYYY-MM-DD');

const displayDate = (dateString) => moment(dateString).format(DATE_FORMAT);

const datesBetweenInclusive = (startDate, endDate) => {
  const dates = [startDate];
  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');
  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }
  dates.push(endDate);
  return dates;
};

/**
 *
 * @param {date string} startDate
 * @param {date string} endDate
 *
 * @returns {string}
 *  return time difference between two dates in string format
 *  containing the difference unit. In '1 d 5m 10 s' such format
 *
 * NOTE: only returns till days and not years
 */
const timeDifferenceBetween = (startDate, endDate) => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  const days = endMoment.diff(startMoment, 'days');
  const hours = endMoment.subtract(days, 'days').diff(startMoment, 'hours');
  const minutes = endMoment.subtract(hours, 'hours').diff(startMoment, 'minutes');
  const seconds = endMoment.subtract(minutes, 'minutes').diff(startMoment, 'seconds');
  let returnDateString = '';
  if (days > 0) {
    returnDateString += `${days}d `;
  }
  if (hours > 0) {
    returnDateString += `${hours}h `;
  }
  if (minutes > 0) {
    returnDateString += `${minutes}m `;
  }
  if (seconds > 0) {
    returnDateString += `${seconds}s `;
  }
  return returnDateString;
};

const isDaylightSavings = () => moment(new Date()).isDST();

const spanToQueryParams = (span) => {
  const dateRange = typeof span === 'string' ? JSON.parse(span) : span;

  return dateRange.fixedRange && ![false, 'false'].includes(dateRange.fixedRange)
    ? `span=${dateRange.timeWindow}`
    : `start_date=${parseDate(dateRange.startDate)}&end_date=${parseDate(dateRange.endDate)}`;
};

const spanToMonthAndYear = (span) => {
  const dateRange = typeof span === 'string' ? JSON.parse(span) : span;
  if (dateRange.startDate) {
    const startDate = parseDate(dateRange.startDate);
    return {
      month: moment.parseZone(startDate).format('MM'),
      year: moment.parseZone(startDate).format('YYYY'),
    };
  }
  return {};
};

export const spanToMomentRange = (span, today = moment()) => {
  if (span.fixedRange) {
    const endDate = today;
    let daysBack;
    switch (span.timeWindow) {
      case 'last_7_days':
        daysBack = 6;
        break;
      case 'last_30_days':
        daysBack = 29;
        break;
      case 'last_60_days':
        daysBack = 59;
        break;
      case 'last_90_days':
        daysBack = 89;
        break;
      case 'last_180_days':
        daysBack = 179;
        break;
      case 'current_week':
        daysBack = endDate.clone().day() - 1;
        break;
      case 'current_month':
        daysBack = endDate.clone().date() - 1;
        break;
      case 'last_4_weeks':
        daysBack = 28;
        break;
      case 'last_12_weeks':
        daysBack = 84;
        break;
      case 'last_6_months':
        daysBack = 180;
        break;
      case 'last_12_months':
        daysBack = 364;
        break;
      default:
        break;
    }
    return { startDate: endDate.clone().subtract(daysBack, 'days'), endDate };
  }
  return {
    startDate: moment.utc(span.startDate),
    endDate: moment.utc(span.endDate),
  };
};

const spanToRange = (span, today = moment(), momentFormat = 'll') => {
  const momentRange = spanToMomentRange(span, today);
  return {
    startDate: momentRange.startDate.format(momentFormat),
    endDate: momentRange.endDate.format(momentFormat),
  };
};

const timeWindowToSpan = (timeWindow) => ({
  fixedRange: true,
  timeWindow,
  startDate: null,
  endDate: null,
});

const spanToDisplayRange = (span, today = moment()) => {
  const range = spanToMomentRange(span, today);
  return `${range.startDate.format(DATE_FORMAT)} - ${range.endDate.format(DATE_FORMAT)}`;
};

const removeFromSet = (set, value) => {
  set.delete(value);
  return set;
};

/**
 * Toggles the value in the set.
 * @param {Set<VALUE_TYPE>} set
 * @param {VALUE_TYPE} value
 * @template VALUE_TYPE
 */
export const toggleSet = (set, value) => {
  if (set.has(value)) {
    set.delete(value);
  } else {
    set.add(value);
  }
};

export const pluralize = (string, isPlural, suffix = 's') => {
  if (isPlural) return `${string}${suffix}`;

  return string;
};

export const isPrelogin = (route) => {
  const AUTH_ROUTES = [
    '/set_password',
    '/growth',
    '/password_reset',
    '/activate',
  ];
  if (window.process.env.LOGIN_FLOW) {
    if (route === '/') {
      return true;
    }
  } else {
    AUTH_ROUTES.push('/login');
  }

  return AUTH_ROUTES.reduce(
    (memo, path) => memo || route.startsWith(path),
    false
  );
};

export const parseDateString = (str) => {
  const obj = moment.parseZone(str);
  return obj.isValid() ? obj.format('ll') : str;
};

export const capitalize = (val) => {
  if (isEmpty(val)) {
    return val;
  }
  return val[0].toUpperCase() + val.slice(1);
};

export const re_weburl = new RegExp(
  '^' +
  // protocol identifier
  '(?:(?:https?|ftp)://)' +
  // user:pass authentication
  '(?:\\S+(?::\\S*)?@)?' +
  '(?:' +
  // IP address exclusion
  // private & local networks
  '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
  '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
  '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
  // IP address dotted notation octets
  // excludes loopback network 0.0.0.0
  // excludes reserved space >= 224.0.0.0
  // excludes network & broacast addresses
  // (first & last IP address of each class)
  '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
  '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
  '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
  '|' +
  // host name
  '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
  // domain name
  '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+(?:-[a-z\\u00a1-\\uffff0-9]+)*))*' +
  // TLD identifier
  '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))' +
  // TLD may end with dot
  '\\.?' +
  ')' +
  // port number
  '(?::\\d{2,5})?' +
  // resource path
  '(?:[/?#]\\S*)?' +
  '$', 'i'
);

function getPathFromUrl(url) {
  return url.substring(0, url.indexOf('?') || url.length);
}

const getSegmentSpan = (segment) => segment
  ? {
    fixedRange: !segment.dynamic && segment.span !== 'custom',
    timeWindow: segment.span === 'custom' ? null : segment.span,
    startDate: segment.analytics_start_date,
    endDate: segment.analytics_end_date,
  }
  : null;

// Generators

function* combineKeys(
  data,
  combinedName,
  ...keyNames
) {
  const clonedData = clone(data, true);
  // Sorry for this one, can't use map in generator
  for (const record of clonedData) {
    const seen = new Set();
    const combined = [];
    for (const key of keyNames) {
      if (record[key]) {
        for (const item of record[key]) {
          combined.push([key, item]);
          if (!seen.has(item)) {
            seen.add(item);
            combined.push(['all', item]);
          }
        }
      }
    }
    record[combinedName] = combined;
  }
  return clonedData;
}

function* join(condFn, l1, l2) {
  for (const leftItem of l1) {
    for (const rightItem of l2) {
      if (condFn(leftItem, rightItem)) {
        yield [leftItem, rightItem];
      } else {
        yield null;
      }
    }
  }
}

const renameProp = (oldProp, newProp, {
  [oldProp]: old,
  ...others
}) => ({
  [newProp]: old,
  ...others,
});

const getAdTypeOptions = (options, selectedAdType) => {
  let updatedOption = {
    ...options,
  };
  if (selectedAdType === 'video') {
    updatedOption = renameProp('video_file_type', 'file_type', updatedOption);
    updatedOption = renameProp('video_size', 'size', updatedOption);
  } else if (selectedAdType === 'native') {
    updatedOption = renameProp('native_img_file_type', 'file_type', updatedOption);
    updatedOption = renameProp('native_icon_file_type', 'icon_file_type', updatedOption);
    /* updatedOption = renameProp('native_img_size', 'size', updatedOption); */
  } else if (selectedAdType === 'html5' || selectedAdType === 'html5_dynamic') {
    updatedOption = renameProp('html5_file_type', 'file_type', updatedOption);
    updatedOption = renameProp('html5_ad_size', 'size', updatedOption);
  }
  return updatedOption;
};

// const roundTo = (decimals) =>
//   (n) => parseFloat(Math.round(n * 100) / 100).toFixed(decimals);

const isSubset = curry(
  (superset, subset) => !difference(
    Array.from(subset),
    Array.from(superset)
  ).length
);

const appendOrDefineSet = (obj, key, val) => {
  const newObj = clone(obj);
  if (newObj[key]) {
    newObj[key].add(val);
  } else {
    newObj[key] = new Set([val]);
  }
  return newObj;
};

const appendOrDefineList = (obj, key, val) => {
  const newObj = clone(obj);
  if (newObj[key]) {
    newObj[key].push(val);
  } else {
    newObj[key] = [val];
  }
  return newObj;
};

const renderTemplate = (template, params) => replace(
  // ('column_{foo}_attribute_{bar}', { foo: '6sense', bar: 'premal' }) =>
  //   'column_6sense_attribute_premal'
  template,
  /\{(.*?)\}/,
  (match, key) => {
    if (!params[key]) {
      throw new Error(`No params exist for template variable '${key}'`);
    }
    return get(params, key).value;
  }
);

// generates a random hex color, nice to use for debugging -
// or turn on 'highlight updates' in react dev tools
const randoHex = () => `#${+Math.floor(Math.random() * 16777215).toString(16)}`;


const setIn = curryFp((path, value, obj) => setWithFp(
  fpClone, path, value, fpClone(obj))
);

const fnPush = curry((list, ...vals) => {
  const newList = clone(list);
  each(vals, (val) => newList.push(val));
  return newList;
});

/*
return differences in obj2
*/
const objectDiff = (obj1, obj2) => reduce(
  keys(obj2),
  (acc, objKey) => {
    if (!(objKey in obj1)) {
      acc[objKey] = obj2[objKey];
    }
    if (!isEqual(obj1[objKey], obj2[objKey])) {
      acc[objKey] = obj2[objKey];
    }
    return acc;
  },
  {}
);


/*
  scrolls into view the element that is attached to said ID
  * not full browser support but suck it ie *
*/

// scrollTo tries to find and scroll to element every {delay}ms for {maxAttempts} times
const scrollTo = (id, delay = 100, maxAttempts = 1, customScrollconfig = {}) => {
  const defaultScrollConfig = { behavior: 'smooth' };
  const scrollConfig = { ...defaultScrollConfig, ...customScrollconfig };
  scrollToElement(id, delay, maxAttempts, scrollConfig, 1);
};

const scrollToElement = (id, delay, maxAttempts, scrollConfig, currentAttempt) => {
  setTimeout(
    () => {
      const element = document.getElementById(id);
      if (currentAttempt > maxAttempts) return;
      if (element) {
        element.scrollIntoView(scrollConfig);
      } else {
        scrollToElement(id, delay, maxAttempts, scrollConfig, currentAttempt + 1);
      }
    },
    delay,
  );
};

const genKey = (prefix) =>
  `${prefix}_${Math.random().toString(36).slice(2)}`;

const parseStringToNumber = (value) =>
  (value && Number(value.toString().trim())) || 0;

const SIZE_CONSTANT = 14;
const convertPxToRem = (px) => px / SIZE_CONSTANT;

const getImageDimensions = (dimension = '', splitter = 'x') => {
  const [width, height] = dimension.toLowerCase().split(splitter);
  return { width: parseStringToNumber(width), height: parseStringToNumber(height) };
};

const calculateMediaSize = (dimensions, baseSize = 80) => {
  const sanitizedDimensions = dimensions.replace(/[^0-9x]+/gi, '');
  const { height, width } = getImageDimensions(sanitizedDimensions);
  let newHeight = 0;
  let newWidth = 0;
  if (height > width) {
    newHeight = baseSize;
    newWidth = (width / height) * newHeight;
  } else if (height < width) {
    newWidth = baseSize;
    newHeight = (height / width) * newWidth;
  } else {
    newWidth = baseSize;
    newHeight = baseSize;
  }
  return { height: newHeight, width: newWidth };
};

function formatKiloBytes(kbytes, decimals) {
  if (kbytes === 0) return '0 KB';
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(kbytes) / Math.log(k));
  // Uglifyjs does not like ** operator
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((kbytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const trimArrayOfString = (arr) => arr.map((val) => val.trim());

const isJSON = (v) => {
  try {
    const obj = JSON.parse(v);
    if (obj && typeof obj !== 'object') {
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};

/*
  should be used when checking for PropTypes of specific component
  e.g:
  ColumnContainer.propTypes = {
    children: childrenOf(Row, Column).isRequired,
  };
*/
const childrenOf = (...types) => {
  const fieldType = PropTypes.shape({
    type: PropTypes.oneOf(types),
  });

  return PropTypes.oneOfType([
    fieldType,
    PropTypes.arrayOf(fieldType),
  ]);
};

const copyToClipboard = (node) => {
  try {
    const range = document.createRange();
    range.selectNodeContents(node);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    // node.select();
    document.execCommand('Copy');
    sel.removeAllRanges();
    return true;
  } catch (e) {
    return false;
  }
};

const identity = (arg) => arg;

const wrappedFormValueSelector = (formName) =>
  /*
  By default the redux-form formValueSelector
  returns a string for one field and object
  for multiple fields. I don't like this.
  Let's always get an object.
  */
  (state, fields) => {
    const sel = formValueSelector(formName);
    let field = fields;
    if (isArray(fields)) {
      if (size(fields) === 1) {
        field = fields[0];
      } else {
        return sel(state, ...fields);
      }
    }
    const reduxFormResult = sel(state, field);
    return { [field]: reduxFormResult };
  };

const convertToObject = (arr) => {
  const obj = {};
  arr.reduce((acc, val) => {
    acc[val.id] = val;
    return acc;
  }, obj);
  return obj;
};

/**
 * This is used to send graphql errors to rollbar. Graphql always returns a 200,
 * even in the event of an error - so we must handle getting these errors to rollbar
 * separately
 *
 * @param {graphql error object} e the graphql error that will be sent to rollbar
 * @param {string} method
 */
const errorToRollbar = (e, method = 'GET') => {
  if (e.graphQLErrors && e.graphQLErrors.length > 0) {
    e.graphQLErrors.map(({ message, locations, path }) =>
      rollbar.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }
  if (e.networkError) {
    rollbar.configure({
      payload: { fingerprint: `${e.networkError.statusCode} ${method}` },
    }).error(`[Network Error]: ${e.networkError.stack}`);
  }
  if (!e.networkError && (!e.graphQLErrors || e.graphQLErrors.length === 0)) {
    rollbar.error(e.stack);
  }
};

export const maybePlural = (number, word, alt) => {
  if (number > 1 || number === 0) {
    return alt || `${word}s`;
  }
  return word;
};

export const select = (key, collection) => map(collection, (item) => item[key]);

export const mapPick = (pickArg, collection) =>
  map(collection, (item) => pick(item, pickArg));

export const groupByMultiple = ({
  groupers = [],
  mappers = [],
  collection = [],
  paths = [],
  prevKey = null,
}) => {
  const grouper = first(groupers);
  const mapper = first(mappers) || identity;
  const path = first(paths);

  const toGroup = get(collection, path, collection);
  let grouped = groupBy(toGroup, grouper);
  if (grouper === 'activityType') {
    grouped = groupBy(
      orderBy(
        toGroup, 'contributingToModel', 'desc'),
      (e) => e.activityType + (!e.contributingToModel ? '__NotContributing' : '')
    );
  }

  const processed = mapper(grouped, prevKey);

  const nextGroupers = slice(groupers, 1);
  const nextMappers = slice(mappers, 1);
  const nextPaths = slice(paths, 1);

  let result;
  if (size(nextGroupers)) {
    result = mapValues(processed, (dataInGroup, key) =>
      groupByMultiple({
        groupers: nextGroupers,
        mappers: nextMappers,
        paths: nextPaths,
        collection: dataInGroup,
        prevKey: key,
      }),
    );
  } else {
    result = processed;
  }

  if (size(path)) {
    lodashSet(collection, path, result);
    return collection;
  }
  return result;
};

const defaultToString = (data) => data.reduce((acc, row) => `${acc + row.join(',')}\n`, '');

const genFileSuffix = () => {
  const timeutc = moment.utc().format('YYYYMMDD');
  const largeValue = Math.pow(10, 17); // eslint-disable-line
  const hash = (Math.random() * largeValue).toString();
  return `${timeutc}${hash.slice(4, 6)}_-${hash}${hash.slice(11, 13)}`;
};

const downloadCSV = (toString = defaultToString) => (
  data,
  filenamePrefix = 'file',
  includeSuffix = true
) => {
  const stringData = toString(data);
  const fileSuffix = genFileSuffix();
  const blob = new Blob([stringData], { type: 'text/csv;charset=utf-8' });
  const fileName = includeSuffix ? `${filenamePrefix}_${fileSuffix}.csv` : `${filenamePrefix}.csv`;
  saveAs(blob, fileName);
  return { file_name: fileName, file_size: blob.size };
};

const FORBIDDEN = ['=', '+', '-', '@'];
const sanitizeCsvInput = (v) => {
  if (isNil(v)) return v;
  let value = v;
  while (FORBIDDEN.includes(value[0])) {
    value = value.slice(1);
  }
  return value;
};

const safeNumber = (number, fallback = 0) => {
  if (isNaN(number) || !isFinite(number)) {
    return fallback;
  }

  return number;
};

const getUsersTimeZone = () => {
  const timeZone = momentTz.tz.guess();
  const timezoneAbbrevation = momentTz().tz(timeZone).format('z'); // IST, EST, EDT e.t.c
  const timeZoneOffset = momentTz.tz(timeZone).utcOffset();
  const offsetInMinutes = moment.duration(Math.abs(timeZoneOffset), 'minutes');
  const formattedOffset = moment.utc(offsetInMinutes.asMilliseconds()).format('HH:mm');
  const offsetDiffSign = timeZoneOffset < 0 ? '-' : '+';

  return { timezoneAbbrevation, utcOffset: `${offsetDiffSign}${formattedOffset}` };
};

const generateRandomId = (length = 10) => {
  let randomId = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    randomId += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return randomId;
};

const stopEventPropagation = (event) => event.stopPropagation();

const getDateRangeByFilter =
  (newDateRange) => {
    let startDate = null;
    let endDate = null;
    if (newDateRange === 'week') {
      startDate = moment().subtract(6, 'd');
      endDate = moment();
    } else if (newDateRange === 'month') {
      startDate = moment().subtract(29, 'd');
      endDate = moment();
    }
    return [startDate, endDate];
  };

const disableFutureDatesInRangePicker = (current) => {
  const today = moment();
  return today.isSameOrBefore(current);
};

const isDevEnv = () => {
  const domainHost = window.location.host.split('.')[1];
  return domainHost &&
    (domainHost.match('account-analytics') || domainHost.match('localhost'));
};

const renameKeys = (keysMap, obj) => {
  const newObj = {};
  const renameMap = Object.fromEntries(keysMap.map((el) => [el.key, el.value]));
  Object.keys(obj).forEach((key) => {
    if (renameMap[key]) {
      newObj[renameMap[key]] = obj[key];
    }
  });
  return newObj;
};

export const getFileSizeInMb = (fileSize) => Number((fileSize / 1024).toFixed());

// TODO: I think some of this logic needs to be improved by considering more params
export const validateFileSize = (attachmentType, fileSize, acceptedFileSize) => {
  const isSizeInMB = attachmentType === 'video' ||
    attachmentType === 'html5' ||
    attachmentType === 'html5_dynamic' ||
    attachmentType === AD_TYPE_IDS.linkedInVideoAd ||
    attachmentType === LINKEDIN_VIDEO_ASSET_ATTACHMENT_TYPE.THUMBNAIL;
  const { maxSize, minSize } = acceptedFileSize;
  const maxFileSizeLabel = isSizeInMB ? `${getFileSizeInMb(maxSize)} MB` : `${maxSize} KB`;
  if (fileSize > maxSize) {
    return `Attached file is
      larger than the maximum supported file size of ${maxFileSizeLabel}`;
  } else if (fileSize < minSize) {
    return `Attached file is
    smaller than the minimum supported file size of ${minSize} KB`;
  }
  return null;
};

export const getCreativeFieldLabel = (placementType) => {
  let creativeFieldLabel;
  switch (placementType) {
    case 'linkedin_video':
    case 'video':
      creativeFieldLabel = 'Video';
      break;
    case 'html5':
    case 'html5_dynamic':
      creativeFieldLabel = 'HTML';
      break;
    case 'icon':
      creativeFieldLabel = 'Icon';
      break;
    default:
      creativeFieldLabel = 'Images';
      break;
  }
  return creativeFieldLabel;
};

export const validateDimensions = (allowedFileDim, dimensions, attachmentType) => {
  const fileDimension = getImageDimensions(dimensions);
  const {
    width: minAllowedWidth, height: minAllowedHeight,
  } = getImageDimensions(allowedFileDim[0]);
  let errorMessage;
  switch (attachmentType) {
    case 'icon':
    case 'video': {
      const label = getCreativeFieldLabel(attachmentType);
      if (
        fileDimension.width < minAllowedWidth || fileDimension.height < minAllowedHeight
      ) {
        errorMessage = `Selected ${label} size ${dimensions} does not match desired ` +
          ` minimum ${label.toLowerCase()} size of ${minAllowedWidth}x${minAllowedHeight}`;
      } else if (
        attachmentType === 'icon' &&
        fileDimension.width / fileDimension.height !== minAllowedWidth / minAllowedHeight
      ) {
        errorMessage = `Selected ${label.toLowerCase()} ratio does not match` +
          ` desired ${label.toLowerCase()} aspect ratio of ${ASPECT_RATIO[attachmentType]}`;
      }
      break;
    }
    case 'native':
    case 'banner':
      if (!allowedFileDim.map((dim) => dim.toLowerCase()).includes(dimensions.toLowerCase())) {
        errorMessage = `Selected ${getCreativeFieldLabel(attachmentType)}` +
          ` size ${dimensions} does not match allowed image sizes`;
      }
      break;
    case AD_TYPE_IDS.linkedInVideoAd: {
      const label = getCreativeFieldLabel(attachmentType);
      const fileAspectRatio = Math.floor((fileDimension.width / fileDimension.height) * 100) / 100;
      const fileDimensionSize = fileDimension.width * fileDimension.height;
      const adTypeDimensionList = get(AD_TYPE_PROPERTIES[attachmentType], 'acceptedDimensions');
      const dimensionType = get(LINKEDIN_VIDEO_RATIO_DIMENSION_TITLE_MAPPING, fileAspectRatio);
      const minAcceptedDimension = get(adTypeDimensionList, [dimensionType, 'min']);
      const maxAcceptedDimension = get(adTypeDimensionList, [dimensionType, 'max']);

      if (!allowedFileDim[fileAspectRatio]) {
        errorMessage = `Selected ${label.toLowerCase()} ratio does not match` +
          ` desired ${label.toLowerCase()} aspect ratio of ${ASPECT_RATIO[attachmentType]}`;
      } else if (allowedFileDim[fileAspectRatio].min > fileDimensionSize) {
        errorMessage = `Selected ${label} size ${dimensions} does not match desired ` +
          ` minimum ${label.toLowerCase()} size of ${minAcceptedDimension}`;
      } else if (allowedFileDim[fileAspectRatio].max < fileDimensionSize) {
        errorMessage = `Selected ${label} size ${dimensions} does not match desired ` +
          ` maximum ${label.toLowerCase()} size of ${maxAcceptedDimension}`;
      }
      break;
    }
    default:
      break;
  }
  return errorMessage;
};

function stringContainsSpecialChars(str) {
  const specialChars = /[!@#$%^&*+?~']+/;
  return specialChars.test(str);
}

export const validateHtmlFile = (files) => {
  const containIndexHtml = keys(files).find(
    (key) => key.split('/').pop().toLowerCase() === 'index.html');
  const totalHtmlFiles = keys(files).filter(
    (key) => key.includes('.html') && !key.includes('__MACOSX'));
  if (totalHtmlFiles.length > 1) {
    return 'Zip file should contain only single html file';
  }
  if (files && containIndexHtml) {
    return null;
  }
  return 'Invalid Zip file. Must contain an index.html file';
};

export const validateNestedZipFile = (files, attachmentType) => {
  const errorMessage = (attachmentType === 'html5') ?
    CONTAIN_HTML_ZIP_ERROR : CONTAIN_DYNAMIC_HTML_ZIP_ERROR;
  const containZipFile = some(files, (file) => (!file.dir && file.name.split('.')[1] === 'zip'));
  return containZipFile ? errorMessage : null;
};

export const validateFileNameInZip = (files) => {
  const invalidFileNames = keys(files)
    .filter((key) => {
      const filesArray = key.split('/');
      let fileName = filesArray.pop().toLowerCase();
      if (fileName === '') {
        fileName = filesArray.pop().toLowerCase();
      }
      return (fileName.charAt(0) !== '.' && stringContainsSpecialChars(fileName));
    }
    );
  const totalInvalidFileNames = invalidFileNames.length;
  if (totalInvalidFileNames > 0) {
    const invalidFileNamesArray = invalidFileNames[0].split('/');
    let firstInvalidFileName = invalidFileNamesArray.pop();
    if (firstInvalidFileName === '') {
      firstInvalidFileName = invalidFileNamesArray.pop();
    }
    if (totalInvalidFileNames > 1) {
      return `${CREATIVE_NAME_ERROR} ${firstInvalidFileName} +
      ${totalInvalidFileNames - 1} more file
      ${maybePlural(totalInvalidFileNames - 1, 'name')}`;
    }
    return `${CREATIVE_NAME_ERROR} the file name ${firstInvalidFileName}`;
  }
  return null;
};

export const validateCreativeName = (creativeName) => {
  if (stringContainsSpecialChars(creativeName)) {
    return `${CREATIVE_NAME_ERROR} the file name ${creativeName}`;
  }
  return null;
};

export const validateCreativeType = (file, types) => {
  const fileName = file.name;
  const fileTypeFromFileName = `image/${last(split(fileName, '.'))}`;
  const acceptedFileTypes = types.map((type) => type.toLowerCase());
  if (!acceptedFileTypes.includes(fileTypeFromFileName)) {
    return CREATIVE_TYPE_ERROR;
  }
  return null;
};

export const getFileContent = (file) => file.async('text');

export const validateHtmlDynamicFile = (files, checkStrings) =>
  new Promise((resolve, reject) => {
    const htmlFile = valuesFn(htmlFileInZip(files));
    const jsFiles = valuesFn(jsFilesInZip(files));
    getFileContent(htmlFile[0]).then((htmlFileString) => {
      if (some(checkStrings, (checkString) => htmlFileString.includes(checkString))) {
        return resolve();
      }
      return htmlFileString;
    }).then(() => {
      if (jsFiles && jsFiles.length > 0) {
        const jsPromiseArr = jsFiles.map(((file) => getFileContent(file)));
        return Promise.all(jsPromiseArr).then((jsFilesContent) => {
          for (const jsFileContent of jsFilesContent) {
            if (some(checkStrings, (checkString) => jsFileContent.includes(checkString))) {
              return resolve(jsFilesContent);
            }
          }
          return jsFilesContent;
        }).then(() => reject(DYNAMIC_AD_ERROR));
      }
      return reject(DYNAMIC_AD_ERROR);
    });
  });

export const htmlFileInZip = (allFiles) => pickBy(allFiles, (_, key) => key.includes('.htm'));
export const jsFilesInZip = (allFiles) => pickBy(allFiles, (_, key) => key.endsWith('.js'));

export const getLocalPreviewUrl = (file) => {
  const url = window.URL.createObjectURL(file);
  return url;
};

export const getUploadFileDetails = (
  file, attachmentType, acceptedFileDimensions,
  acceptedFileSize, acceptedFileTypes
) => new Promise((resolve, reject) => {
  let newCreative = {
    creative_type: attachmentType,
    name: file.name,
    file_type: file.type,
  };
  const creativeNameErrorMsg = validateCreativeName(file.name);
  if (creativeNameErrorMsg) {
    return reject(creativeNameErrorMsg);
  }
  const fileSizeInKb = (file.size / 1024).toFixed(1);
  const fileSizeErrorMsg = validateFileSize(attachmentType, fileSizeInKb, acceptedFileSize);
  if (fileSizeErrorMsg) {
    return reject(fileSizeErrorMsg);
  }
  let dimErrorMsg;
  if (attachmentType === 'video' || attachmentType === AD_TYPE_IDS.linkedInVideoAd) {
    const videoFileSize = `${(fileSizeInKb / 1024).toFixed(1)}`;
    const video = document.createElement('video');
    video.src = window.URL.createObjectURL(file);
    video.addEventListener('loadedmetadata', (event) => {
      const { videoWidth, videoHeight, duration } = event.target;
      const dimensions = `${videoWidth}x${videoHeight}`;
      dimErrorMsg = validateDimensions(acceptedFileDimensions, dimensions, attachmentType);
      if (dimErrorMsg) {
        return reject(dimErrorMsg);
      }
      const video_duration_ms = parseInt(duration * 1000, 10);

      if (attachmentType === AD_TYPE_IDS.linkedInVideoAd) {
        const localPreviewUrl = getLocalPreviewUrl(file);
        newCreative.localPreviewUrl = localPreviewUrl;
      }

      newCreative = {
        ...newCreative,
        dimensions,
        file_size: `${videoFileSize} mb`,
        video_duration_ms,
      };
      return resolve(newCreative);
    });
  } else if (
    attachmentType === 'banner' || attachmentType === 'native' || attachmentType === 'icon'
  ) {
    const image = new Image();
    image.src = window.URL.createObjectURL(file);
    image.onload = function onLoad() {
      const dimensions = `${this.width}x${this.height}`;
      dimErrorMsg = validateDimensions(acceptedFileDimensions, dimensions, attachmentType);
      if (dimErrorMsg) {
        return reject(dimErrorMsg);
      }
      const fileTypeErrorMsg = validateCreativeType(file, acceptedFileTypes);
      if (fileTypeErrorMsg) {
        return reject(fileTypeErrorMsg);
      }

      newCreative = {
        ...newCreative,
        file_size: `${fileSizeInKb} kb`,
        dimensions,
      };
      return resolve(newCreative);
    };
  } else if (attachmentType === 'html5' || attachmentType === 'html5_dynamic') {
    jszip.loadAsync(file).then((zip) => {
      newCreative = {
        ...newCreative,
        file_size: `${fileSizeInKb} kb`,
      };
      const html5ErrorMsg = validateHtmlFile(zip.files);
      if (html5ErrorMsg) {
        return reject(html5ErrorMsg);
      }
      const nestedZipErrorMessage = validateNestedZipFile(zip.files, attachmentType);
      if (nestedZipErrorMessage) {
        return reject(nestedZipErrorMessage);
      }
      const html5FileNameErrorMsg = validateFileNameInZip(zip.files);
      if (html5FileNameErrorMsg) {
        return reject(html5FileNameErrorMsg);
      }
      const dynamicResponse = validateHtmlDynamicFile(zip.files, VALIDATE_DYNAMIC_AD_STRINGS);
      if (attachmentType === 'html5_dynamic') {
        dynamicResponse.then(
          () => resolve(newCreative)
        ).catch((error) => reject(error));
      } else if (attachmentType === 'html5') {
        dynamicResponse.then(
          () => reject('You are trying to upload HTML5 dynamic creative for HTML5 ad')
        ).catch(() => resolve(newCreative));
      }
      return zip;
    });
  }
  if (attachmentType === 'linkedin_image') {
    const image = new Image();
    image.src = window.URL.createObjectURL(file);
    image.onload = function onLoad() {
      const dimensions = `${this.width}x${this.height}`;
      const fileTypeErrorMsg = validateCreativeType(file, acceptedFileTypes);
      if (fileTypeErrorMsg) {
        return reject(fileTypeErrorMsg);
      }

      newCreative = {
        ...newCreative,
        file_size: `${fileSizeInKb} kb`,
        dimensions,
      };
      return resolve(newCreative);
    };
  }
  if (attachmentType === LINKEDIN_VIDEO_ASSET_ATTACHMENT_TYPE.THUMBNAIL) {
    const localPreviewUrl = getLocalPreviewUrl(file);
    newCreative.localPreviewUrl = localPreviewUrl;
    return resolve(newCreative);
  }
  if (attachmentType === LINKEDIN_VIDEO_ASSET_ATTACHMENT_TYPE.CAPTION) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const captionErrors = srtValidator(reader.result);
      if (captionErrors.length) {
        return reject(
          `${upperFirst(captionErrors[0].message)} on line number ${captionErrors[0].lineNumber}.`
        );
      }
      return resolve(newCreative);
    });
    reader.readAsText(file);
  }
  return null;
});

export const parseFileTypeName = (typeName) =>
  (typeName && typeName.split('/')[1].toUpperCase()) || '';

const wrapUrlWithQueryParams = (url = '', queryParamsObject = {}) => {
  try {
    const urlObject = new URL(url);
    const newSearchParams = new URLSearchParams(queryParamsObject);
    // Not using `urlObject.searchParams.set(key, value)` as it
    // decodes existing params present in the url, leading misleading chars in final url
    if (urlObject.search) {
      return `${url}&${newSearchParams.toString()}`;
    }
    return `${url}?${newSearchParams.toString()}`;
  } catch (e) {
    return url;
  }
};

const getEmptyImage = () => {
  const img = new Image();
  img.src = TransparentBackground;
  return img;
};

const getUniqListOfStrings = (strings) => uniq(filter(map(strings, (s) => s.trim())));

const convertMBtoBytesInDecimal = (mb) => mb * 1000000;

const wrapUrlWith6SiPreviewParam = (url) =>
  wrapUrlWithQueryParams(url, { '6si_preview': 1 });

const filterFeatureFlags = (flags) => {
  const invertedFeatureFlags = invert(FEATURE_FLAGS);
  return pickBy(flags, (_, key) => has(invertedFeatureFlags, key));
};

// range selection like desktop OS typically used with shift pressed
export const computeRangeSelection = (
  allItems,
  newSelectedItem,
  lastSelectedItem,
  currentSelection,
  itemIsEquals = (a, b) => a === b,
) => {
  const lastSelectedIndex = lastSelectedItem ?
    allItems.findIndex((item) => itemIsEquals(item, lastSelectedItem)) : 0;
  const newSelectedIndex = allItems.findIndex((item) => itemIsEquals(item, newSelectedItem));
  const isSelected = currentSelection
    .findIndex((item) => itemIsEquals(item, newSelectedItem)) !== -1;
  let newSelectedFolders = [...currentSelection];

  for (let i = Math.min(lastSelectedIndex, newSelectedIndex);
    i <= Math.max(lastSelectedIndex, newSelectedIndex); i++) {
    if (isSelected) {
      newSelectedFolders = newSelectedFolders.filter((item) => !itemIsEquals(item, allItems[i]));
    } else if (currentSelection.findIndex((item) => itemIsEquals(item, allItems[i])) === -1) {
      newSelectedFolders.push(allItems[i]);
    }
  }
  return newSelectedFolders;
};

const getReleaseTypeForLogo =
  (logoName) => startCase(findKey(RELEASE_TYPE_LOGOS, (name) => name === logoName));

export const getObjectIds = memoize((objects) =>
  objects.map((object) => object.id)
);

const getHyphenSeperatedId = (id) => {
  const strId = convertToString(id);

  // As per regex, there should be atleast 6 characters in id to seperate it into 3 parts
  if (strId.length < 7) {
    return strId;
  }

  const matchedGroups = strId.match(/^(\d{3})(\d{3})(\d+)$/);
  return `${get(matchedGroups, '[1]')}-${get(matchedGroups, '[2]')}-${get(matchedGroups, '[3]')}`;
};

const isEmptyFilter = (_filter) => {
  let isEmptyFilterPresent = false;
  if (size(_filter.filters)) {
    map(_filter.filters, ({ filter_values }) => {
      if (!size(filter_values)) {
        isEmptyFilterPresent = true;
      }
    });
  }
  return isEmptyFilterPresent;
};

export const computePageStatus = ({
  sponsored_content_poster_status: accessStatus,
  is_ad_account_ready: isAccountReady,
  is_disabled: disabled,
}) => {
  if (disabled) {
    return LINKEDIN_PAGE_STATUS.DISABLED;
  }
  if (accessStatus === SPONSORED_CONTENT_POSTER_STATUS.APPROVED && isAccountReady) {
    return LINKEDIN_PAGE_STATUS.APPROVED;
  }
  if (accessStatus === SPONSORED_CONTENT_POSTER_STATUS.UNKNOWN) {
    return LINKEDIN_PAGE_STATUS.UNKNOWN;
  }
  if (
    accessStatus === SPONSORED_CONTENT_POSTER_STATUS.REJECTED ||
    accessStatus === SPONSORED_CONTENT_POSTER_STATUS.REVOKED
  ) {
    return LINKEDIN_PAGE_STATUS.NOT_APPROVED;
  }
  if (accessStatus === SPONSORED_CONTENT_POSTER_STATUS.REQUESTED || !isAccountReady) {
    return LINKEDIN_PAGE_STATUS.IN_PROGRESS;
  }

  return null;
};

export const getLinkedinPageById = (pages, pageId) => find(pages, { id: pageId });

export const isPagePosterAccessRevoked = (linkedinPage) => {
  const sponsoredContentPosterStatus = get(linkedinPage, 'sponsored_content_poster_status');

  return sponsoredContentPosterStatus === SPONSORED_CONTENT_POSTER_STATUS.REVOKED;
};

export const isPageDisabled = (linkedinPage) => get(linkedinPage, 'is_disabled', false);

// TODO: Remove the functionality from routes/Advertising/routes/Campaigns/utils.js and
// update the usage. Also move the test to this location
export const generateQueryParams = (paramsObject) => {
  const params = Object.entries(paramsObject)
    .reduce(
      (acc, [key, value]) => {
        if (!value) return acc;

        const param = new URLSearchParams(acc);
        param.set(key, isArray(value) ? value.join() : value);
        return param.toString();
      },
      '',
    );

  return params ? `?${params}` : '';
};

export const redirectToSubdomain = (orgName, removeOrgName = false) => {
  const { protocol, host } = window.location;
  const parts = host.split('.');
  // Remove the parent subdomain from the parts list
  if (orgName) {
    parts.unshift(orgName);
  }

  if (removeOrgName) {
    parts.shift();
  }

  // Set the location to the new org subdomain
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('redirect')) {
    const redir = encodeURIComponent(urlParams.get('redirect'));
    window.location = `${protocol}//${parts.join('.')}?redirect=${redir}`;
  } else {
    window.location = `${protocol}//${parts.join('.')}`;
  }
};


export const numToRoundOffWords = (num) => {
  const numInt = parseInt(num);
  if (numInt < 1000) {
    return numInt;
  }
  if (numInt < 1000000) {
    return `${Math.round(numInt / 1000)} Thousand`;
  }
  if (numInt < 1000000000) {
    return `${Math.round(numInt / 1000000)} Million`;
  }
  return `${Math.round(numInt / 1000000000)} Billion`;
};

const isLocal = () => {
  const sixsenseEnv = get(window, 'process.env.SIXSENSE_ENV', '');
  return sixsenseEnv === '';
};

const isProduction = () => {
  const sixsenseEnv = get(window, 'process.env.SIXSENSE_ENV', '');
  return sixsenseEnv === 'prod1';
};

const convertSrtToVTT = (fileText) => {
  const parserRes = parser.parse(fileText);
  const serializedVTT = parser.serialize(parserRes, 'WebVTT');
  // Characters like '&' and '<' are not supported by .vtt format
  const normalizedVTT = serializedVTT.replaceAll('&', 'and').replaceAll('<', '');
  return normalizedVTT;
};

export function setCreativeCaptionUrl(selectedCreative, updateCaptionUrl) {

  /*
  This util gets the caption url from the creative, does a fetch call to get the file blob for the
  caption. It reads the file as a string and passes the result to convertSrtToVTT to convert the
  .srt caption to .vtt format via the srt-validator library parser.

  A new .vtt format caption file is generated and it's local object url is set
  to the caption state which shows the caption on the video.
  The ref is used to remove the local object url to avoid memory leak. This was used to get the
  value of vtt caption url since the caption state is not available in useEffect cleanup function.

  The HTML5 player only supports .vtt format to show the captions.
  */

  const creativeCaptionObj = get(selectedCreative, 'caption_attachment', {});
  const hasCaptionUrl = get(creativeCaptionObj, 'captionUrl');
  if (!isEmpty(creativeCaptionObj) && !hasCaptionUrl) {
    const { s3_url, filename } = creativeCaptionObj;
    try {
      fetch(s3_url).then((res) => res.blob()).then((blob) => {
        const file = new File([blob], filename, { type: blob.type });
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          const vttText = convertSrtToVTT(reader.result);
          const vttBlob = new Blob([vttText], { type: 'text/vtt' });
          const vttCaptionUrl = window.URL.createObjectURL(vttBlob);
          updateCaptionUrl(vttCaptionUrl);
        });
        reader.readAsText(file);
      });
    } catch (e) {
      if (rollbar) {
        rollbar.error(e);
      }
    }
  }
  return null;
}

export const isSiteLoadedInIframe = () => {
  try {
    // Check if the current window is in an iframe
    return window.self !== window.top;
  } catch (e) {
    // An error might occur if there are cross-origin restrictions
    // Returning true as a default to indicate a possible iframe scenario
    return true;
  }
};

export const hasRaimDashboardAccess = (
  hasRaimDashboard,
  userId
) => (hasRaimDashboard || (valuesFn(RAIM_DASHBOARD_USERS_SPECIAL_ACCESS).includes(userId)));


export {
  apiToUIString,
  appendOrDefineList,
  appendOrDefineSet,
  calculateMediaSize,
  camelToDisplay,
  childrenOf,
  classNames,
  coerceLocaleString,
  combineKeys,
  combineStateObjs,
  convertPxToRem,
  convertToObject,
  copyToClipboard,
  datesBetweenInclusive,
  timeDifferenceBetween,
  displayDate,
  downloadCSV,
  errorToRollbar,
  fnPush,
  formatDate,
  formatKiloBytes as formatBytes,
  genKey,
  genSubmissionError,
  getAdTypeOptions,
  getCookie,
  getFormSyncErrors,
  getImageDimensions,
  getPathFromUrl,
  getRandomInt,
  getSegmentSpan,
  identity,
  isDaylightSavings,
  isDev,
  isJSON,
  isSubset,
  isUserLoggedIn,
  join,
  localeString,
  numberToDollar,
  objectDiff,
  onceWithArgs,
  overrideDisplayfp,
  parseStringToNumber,
  randoHex,
  removeFromSet,
  renderTemplate,
  roundTo,
  safeNumber,
  safePercent,
  safePercentfp,
  sanitizeCsvInput,
  scrollTo,
  setIn,
  spanToDisplayRange,
  spanToMonthAndYear,
  spanToQueryParams,
  spanToRange,
  stringFormat,
  timeWindowToSpan,
  toDisplayfp,
  toFixedfp,
  trimArrayOfString,
  truncateString,
  validNumber,
  wrappedFormValueSelector,
  getUsersTimeZone,
  generateRandomId,
  stopEventPropagation,
  getDateRangeByFilter,
  disableFutureDatesInRangePicker,
  isDevEnv,
  renameKeys,
  wrapUrlWithQueryParams,
  wrapUrlWith6SiPreviewParam,
  filterFeatureFlags,
  getUniqListOfStrings,
  convertMBtoBytesInDecimal,
  getReleaseTypeForLogo,
  getHyphenSeperatedId,
  isEmptyFilter,
  getEmptyImage,
  isLocal,
  isProduction,
};
