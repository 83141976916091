import {
  loadAuditsStateGenerator,
  loadLastReviewedOnGenerator,
  loadAssigneeGenerator,
  loadCoverageThresholdGenerator,
} from '../stateGenerators';

export const overviewSagas = [
  loadLastReviewedOnGenerator.saga,
  loadAuditsStateGenerator.saga,
  loadAssigneeGenerator.saga,
  loadCoverageThresholdGenerator.saga,
];
