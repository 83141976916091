import React from 'react';
import PropTypes from 'prop-types';
import { Pill } from 'v2-components';
import { parseFileTypeName } from 'utils/utils';
import styles from './../AdPreviewWrapper.module.scss';

const AcceptedFileTypes = (props) => {
  const {
    acceptedFileTypes,
    creativeFileType,
    customPillClass,
  } = props;

  const acceptedTypes = acceptedFileTypes.map((type) => (
    <Pill
      key={`file-type-${type}`}
      notClickable
      inactive
      className={
        `${styles.pillClass}` +
        ` ${creativeFileType === type.toLowerCase() ? styles.activePill : (customPillClass || '')}`
      }
    >
      {parseFileTypeName(type)}
    </Pill>
  ));
  return (
    <div className={styles.pillContainer}>
      {acceptedTypes}
    </div>
  );
};
AcceptedFileTypes.propTypes = {
  acceptedFileTypes: PropTypes.array,
  creativeFileType: PropTypes.string,
  customPillClass: PropTypes.string,
};

export default AcceptedFileTypes;
