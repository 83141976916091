import { map } from 'lodash';

const DROPDOWN_SEGMENTS_SEARCH = 'PUBLISHEDSEGMENTS/DROPDOWN_SEGMENTS_SEARCH';
const LOAD_DROPDOWN_SEGMENTS_SUCCESS = 'PUBLISHEDSEGMENTS/LOAD_DROPDOWN_SEGMENTS_SUCCESS';
const LOAD_DROPDOWN_SEGMENTS_FAIL = 'PUBLISHEDSEGMENTS/LOAD_DROPDOWN_SEGMENTS_FAIL';
const DROPDOWN_SEGMENTS_SET_INPUT = 'PUBLISHEDSEGMENTS/DROPDOWN_SEGMENTS_SET_INPUT';
const DROPDOWN_SEGMENTS_RESET_INPUT = 'PUBLISHEDSEGMENTS/DROPDOWN_SEGMENTS_RESET_INPUT';
const DROPDOWN_SEGMENTS_TOGGLE_SEARCH = 'PUBLISHEDSEGMENTS/DROPDOWN_SEGMENTS_TOGGLE_SEARCH';
const DROPDOWN_SEGMENT_SELECT = 'PUBLISHEDSEGMENTS/DROPDOWN_SEGMENT_SELECT';
const GET_SEGMENTS_LIST_FAIL = 'PUBLISHEDSEGMENTS/GET_SEGMENTS_LIST_FAIL';
const GET_SEGMENTS_LIST_SUCCESS = 'PUBLISHEDSEGMENTS/GET_SEGMENTS_LIST_SUCCESS';
const UNPUBLISH_SELECTED_SEGMENT = 'PUBLISHEDSEGMENTS/UNPUBLISH_SELECTED_SEGMENT';
const UNPUBLISH_SELECTED_SEGMENT_FAIL = 'PUBLISHEDSEGMENTS/UNPUBLISH_SELECTED_SEGMENT_FAIL';
const LOAD = 'PUBLISHEDSEGMENTS/LOAD';
const LOAD_SUCCESS = 'PUBLISHEDSEGMENTS/LOAD_SUCCESS';
const LOAD_FAIL = 'PUBLISHEDSEGMENTS/LOAD_FAIL';
const GET_SEGMENT_TAGS_SUCCESS = 'PUBLISHEDSEGMENTS/GET_SEGMENT_TAGS_SUCCESS';
const GET_SEGMENT_TAGS_FAIL = 'PUBLISHEDSEGMENTS/GET_SETMENT_TAGS_FAIL';
const UPDATE_PUBLISHED_TAGS = 'PUBLISHEDSEGMENTS/UPDATE_PUBLISHED_TAGS';
const UPDATE_PUBLISHED_TAGS_SUCCESS = 'PUBLISHEDSEGMENTS/UPDATE_PUBLISHED_TAGS_SUCCESS';
const UPDATE_PUBLISHED_TAGS_FAIL = 'PUBLISHEDSEGMENTS/UPDATE_PUBLISHED_TAGS_FAIL';
const UPDATE_TAG_FILTER = 'PUBLISHEDSEGMENTS/UPDATE_TAG_FILTER';
const UPDATE_MAX_TEXT_DISPLAY = 'PUBLISHED_SEGMENTS/UPDATE_MAX_TEXT_DISPLAY';
const CHECK_UNCHECK_FORM = 'PUBLISHEDSEGMENTS/CHECK_UNCHECK_FORM';
const DISMISS_MODAL = 'PUBLISHEDSEGMENTS/DISMISS_MODAL';

function resetInput() {
  return { type: DROPDOWN_SEGMENTS_RESET_INPUT };
}

function setSearchInput(searchInput) {
  return { type: DROPDOWN_SEGMENTS_SET_INPUT, searchInput };
}

function toggleSearchBar() {
  return { type: DROPDOWN_SEGMENTS_TOGGLE_SEARCH };
}

function selectDropdownSegment(segment) {
  return { type: DROPDOWN_SEGMENT_SELECT, segment };
}

function search() {
  return { type: DROPDOWN_SEGMENTS_SEARCH };
}

function loadDropdownSegmentsSuccess(dropdownSegments = []) {
  return { type: LOAD_DROPDOWN_SEGMENTS_SUCCESS, dropdownSegments };
}

function loadDropdownSegmentsFail(errorString) {
  return { type: LOAD_DROPDOWN_SEGMENTS_FAIL, errorMessage: errorString };
}

function load() {
  return { type: LOAD };
}

function loadSuccess() {
  return { type: LOAD_SUCCESS };
}

function loadFail(errorString) {
  return { type: LOAD_FAIL, errorString };
}

function getSegmentsListSuccess(segmentsList = [], segmentsCount = 0) {
  return { type: GET_SEGMENTS_LIST_SUCCESS, segmentsList, segmentsCount };
}

function getSegmentsListFail(errorString) {
  return { type: GET_SEGMENTS_LIST_FAIL, errorMessage: errorString };
}

function getSegmentTagsSuccess(segmentTags = []) {
  return { type: GET_SEGMENT_TAGS_SUCCESS, segmentTags };
}

function getSegmentTagsFail(errorMessage) {
  return { type: GET_SEGMENT_TAGS_FAIL, errorMessage };
}

function unpublishSelectedSegment(segmentId) {
  return { type: UNPUBLISH_SELECTED_SEGMENT, segmentId };
}

const RESET_EXISTING_DEPENDENCIES = 'RESET_EXISTING_DEPENDENCIES';
function resetExistingDependencies() {
  return { type: RESET_EXISTING_DEPENDENCIES };
}

function unpublishSelectedSegmentFail(errorString, existingDependencies = [], segId=0) {
  return {
    type: UNPUBLISH_SELECTED_SEGMENT_FAIL,
    errorMessage: errorString,
    existingDependencies,
    segId,
  };
}

function updatePublishedTags(segmentId,
  tags,
  checked,
  showNotification,
  skipPublishedSegmentCalls) {
  return {
    type: UPDATE_PUBLISHED_TAGS,
    segmentId,
    tags,
    checked,
    showNotification,
    skipPublishedSegmentCalls,
  };
}

function updatePublishedTagsSuccess(segmentId, tags, skipPublishedSegmentCalls) {
  return { type: UPDATE_PUBLISHED_TAGS_SUCCESS, segmentId, tags, skipPublishedSegmentCalls };
}

function updatePublishedTagsFail() {
  return { type: UPDATE_PUBLISHED_TAGS_FAIL };
}

function updateTagFilter(tagFilter) {
  return { type: UPDATE_TAG_FILTER, tagFilter };
}

function updateMaxTextDisplay() {
  return { type: UPDATE_MAX_TEXT_DISPLAY };
}

function checkUncheckForm(tagId) {
  return { type: CHECK_UNCHECK_FORM, tagId };
}

function dismiss() {
  return { type: DISMISS_MODAL };
}

const SET_SEGMENT_DEPENDENCIES = 'PUBLISHEDSEGMENTS/SET_SEGMENT_DEPENDENCIES';
function setSegmentDependencies(dependencies) {
  return { type: SET_SEGMENT_DEPENDENCIES, dependencies };
}

const initialState = {
  searchInput: '',
  segment: '',
  unpublishSegmentId: 0,
  segmentMeta: {},
  dropdownSegments: [],
  dropdownSegmentsOffset: 0,
  dropdownSegmentsLimit: 48,
  dropdownSegmentsCount: 0,
  segmentsList: [],
  segmentsCount: 0,
  segmentsListOffset: 0,
  segmentsListLimit: 0,
  sortBy: 'user_count',
  showSearchBar: false,
  dropdownLoading: false,
  asyncCallLoading: false,
  loading: false,
  loaded: false,
  visibility: false,
  segmentTags: [],
  formChecked: {},
  tagFilter: 'all',
  maxTextVisible: false,
  dependencies: {},
  hasExistingDependencies: false,
};

function publishedSegmentsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD: {
      return { ...state, loading: true, asyncCallLoading: true };
    }
    case LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        asyncCallLoading: false,
        loaded: true,
        error: false,
      };
    }
    case LOAD_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
        asyncCallLoading: false,
        errorMessage: action.errorMessage,
      };
    }
    case DROPDOWN_SEGMENTS_SET_INPUT: {
      const { searchInput } = action;
      return { ...state, searchInput };
    }
    case DROPDOWN_SEGMENTS_RESET_INPUT: {
      return { ...state, searchInput: '', showSearchBar: false };
    }
    case DROPDOWN_SEGMENT_SELECT: {
      const { segment } = action;
      return {
        ...state,
        segment: segment.name,
        segmentMeta: segment,
        visibility: true,
        maxTextVisible: false,
      };
    }
    case DROPDOWN_SEGMENTS_SEARCH: {
      return { ...state, dropdownLoading: true };
    }
    case DROPDOWN_SEGMENTS_TOGGLE_SEARCH: {
      const { showSearchBar } = state;
      return { ...state, showSearchBar: !showSearchBar };
    }
    case LOAD_DROPDOWN_SEGMENTS_SUCCESS: {
      const dropdownSegments = action.dropdownSegments;
      const dropdownSegmentsCount = action.dropdownSegmentsCount;
      return {
        ...state,
        dropdownLoading: false,
        error: false,
        dropdownSegmentsCount,
        dropdownSegments,
      };
    }
    case LOAD_DROPDOWN_SEGMENTS_FAIL: {
      return {
        ...state,
        error: true,
        dropdownLoading: false,
        errorMessage: action.errorMessage,
      };
    }
    case GET_SEGMENTS_LIST_SUCCESS: {
      const segmentsList = action.segmentsList;
      const newOffset = Math.min(action.segmentsCount,
        state.segmentsListOffset + state.segmentsListLimit);
      const segmentsCount = action.technologiesCount;
      return {
        ...state,
        segListLoading: false,
        asyncCallLoading: false,
        error: false,
        segListLoaded: true,
        segmentsListOffset: newOffset,
        segmentsCount,
        segmentsList,
        maxTextVisible: false,
      };
    }
    case GET_SEGMENTS_LIST_FAIL: {
      return {
        ...state,
        error: true,
        segListLoading: false,
        asyncCallLoading: false,
        segListLoaded: true,
        errorMessage: action.errorMessage,
      };
    }
    case GET_SEGMENT_TAGS_SUCCESS: {
      const segmentTags = action.segmentTags;

      return {
        ...state,
        segTagsLoading: false,
        asyncCallLoading: false,
        segTagsLoaded: true,
        error: false,
        segmentTags,
      };
    }
    case GET_SEGMENT_TAGS_FAIL: {
      return {
        ...state,
        error: true,
        segTagsLoading: false,
        asyncCallLoading: false,
        segTagsLoaded: true,
        errorMessage: action.errorMessage,
      };
    }
    case UNPUBLISH_SELECTED_SEGMENT: {
      return {
        ...state,
        asyncCallLoading: true,
        unpublishSegmentId: action.segmentId,
      };
    }
    case UNPUBLISH_SELECTED_SEGMENT_FAIL: {
      const { errorMessage, existingDependencies, segId } = action;
      const { dependencies } = state;
      const newDeps = { ...dependencies };
      for (let i = 0; i < existingDependencies.length; i++) {
        newDeps[segId][existingDependencies[i]] = true;
      }
      return {
        ...state,
        error: true,
        asyncCallLoading: false,
        errorMessage,
        dependencies: newDeps,
        hasExistingDependencies: existingDependencies.length !== 0,
      };
    }
    case UPDATE_PUBLISHED_TAGS: {
      return {
        ...state,
        asyncCallLoading: true,
      };
    }
    case UPDATE_PUBLISHED_TAGS_SUCCESS: {
      if (action.skipPublishedSegmentCalls) {
        return {
          ...state,
          loading: false,
          asyncCallLoading: false,
          error: false,
          segment: '',
          searchInput: '',
          segmentMeta: {},
        };
      }
      return {
        ...state,
        loading: false,
        asyncCallLoading: false,
        loaded: true,
        error: false,
        segment: '',
        searchInput: '',
        segmentMeta: {},
      };
    }
    case UPDATE_PUBLISHED_TAGS_FAIL: {
      return {
        ...state,
        error: true,
        loading: false,
        asyncCallLoading: false,
        loaded: true,
        errorMessage: action.errorMessage,
      };
    }
    case UPDATE_TAG_FILTER: {
      return {
        ...state,
        tagFilter: action.tagFilter,
      };
    }
    case UPDATE_MAX_TEXT_DISPLAY: {
      return {
        ...state,
        maxTextVisible: true,
      };
    }
    case CHECK_UNCHECK_FORM: {
      const { tagId } = action;
      const { segmentTags } = state;
      const tempTagList = map(segmentTags, (tag) => {
        if (tag.id === tagId) {
          const isSelected = tag.isSelected;
          return { ...tag, isSelected: !isSelected };
        }
        return { ...tag };
      });
      return {
        ...state,
        segmentTags: tempTagList,
      };
    }
    case DISMISS_MODAL: {
      const { segmentTags } = state;
      const tempTagList = map(segmentTags, (tag) => (
        { ...tag, isSelected: false }
      ));
      return {
        ...state,
        visibility: false,
        segmentTags: tempTagList,
      };
    }
    case SET_SEGMENT_DEPENDENCIES: {
      const { dependencies } = action;
      return {
        ...state,
        dependencies,
      };
    }
    case RESET_EXISTING_DEPENDENCIES: {
      return {
        ...state,
        hasExistingDependencies: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const actions = {
  selectDropdownSegment,
  setSearchInput,
  toggleSearchBar,
  resetInput,
  search,
  loadDropdownSegmentsSuccess,
  loadDropdownSegmentsFail,
  getSegmentsListSuccess,
  getSegmentsListFail,
  loadSuccess,
  loadFail,
  unpublishSelectedSegment,
  unpublishSelectedSegmentFail,
  load,
  getSegmentTagsFail,
  getSegmentTagsSuccess,
  updatePublishedTags,
  updatePublishedTagsSuccess,
  updatePublishedTagsFail,
  updateMaxTextDisplay,
  updateTagFilter,
  checkUncheckForm,
  dismiss,
  setSegmentDependencies,
  resetExistingDependencies,
};

export const actionTypes = {
  DROPDOWN_SEGMENTS_SEARCH,
  LOAD_DROPDOWN_SEGMENTS_SUCCESS,
  LOAD_DROPDOWN_SEGMENTS_FAIL,
  DROPDOWN_SEGMENT_SELECT,
  LOAD,
  UNPUBLISH_SELECTED_SEGMENT,
  UPDATE_PUBLISHED_TAGS,
  UPDATE_PUBLISHED_TAGS_SUCCESS,
};

export default publishedSegmentsReducer;
