import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'manage',
    name: 'Talking Point Rules',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('TPRList', reducer.default);
        injectSagas('TPRList', sagas.default);
        cb(null, containers.TalkingPointRules);
      }, 'ListTalkingPointRules');
    },
  };
}
