import { Flex } from '@sixsense/core/components';
import React, { useState } from 'react';
import { Button, Modal, Text, Loading } from 'v2-components';
import { useOrg } from 'hooks';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { groupsConfigState, selectedGroupState } from '../../state';
import { resetConfig, getUpdatedConfig } from '../../utils';
import { KeywordGroup } from '../../types';
import { maybePlural } from 'utils/utils';
import { actions as globalActions } from 'modules/global';
import { useRequest, useSharedState } from '@sixsense/core';

type Props = {
  onCancel: () => void;
  groups: KeywordGroup[];
};

// Feel like this modal and renaming/creating group modal are so similar...
// going to try making them into
// one component later like 'GroupActionModal'

const DeleteGroupModal = ({ onCancel, groups }: Props) => {
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useSharedState(groupsConfigState);
  const groupIds = config.selectedValues;
  const [keywordGroup, setKeywordGroup] = useSharedState(selectedGroupState);
  const org = useOrg();

  const request = useRequest();
  const dispatch = useDispatch();
  const deleteGroup = async () => {
    const body = {
      groups: groupIds.length > 1 ? groupIds : [keywordGroup.id],
      bulk_delete: true,
    };
    setLoading(true);
    try {
      await request(`organization/${org.id}/keyword_set/`, {
        method: 'PATCH',
        body: JSON.stringify(body),
      });
      dispatch(globalActions.showNotification('success', `Successfully deleted
        ${maybePlural(groupIds.length, 'group')}`));
      onCancel();
      const changePage = groups.length === groupIds.length;
      const updatedConfig = getUpdatedConfig(config);
      // Need to refresh to previous page if we delete the last group on that page.
      if (changePage && config.pagination.pageNumber !== 1) {
        updatedConfig.pagination.pageNumber = config.pagination.pageNumber - 1;
      }
      resetConfig(updatedConfig, setConfig);
      setKeywordGroup({});
    } catch (e) {
      dispatch(globalActions.showNotification('error', `There was an issue deleting your
        ${maybePlural(groupIds.length, 'group')}. Please try again later`));
    }
    setLoading(false);
  };

  const footer = (
    <Flex justifyContent="flex-end" style={{ padding: '10px 0' }}>
      <Button
        pointer
        onClick={() => {
          onCancel();
        }}
      >
        Cancel
      </Button>
      <Button
        pointer
        className="aam-l--10"
        type="primary"
        onClick={() => {
          deleteGroup();
        }}
        disabled={loading}
      >
        Delete
      </Button>
      {loading && (
        <Loading
          size="small"
          spinnerWidth={15}
          spinnerHeight={15}
          pageLevel={false}
          className="aam-l--10"
        />
      )}
    </Flex>
  );

  return (
    <Modal
      visible
      bodyStyle={{ padding: 0 }}
      footer={footer}
      maskClosable={false}
      closable={false}
      width={500}
    >
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9' }}>
        <Text type={Text.TYPE.TITLE} bold>
          {groupIds.length > 1 ? `Delete ${groupIds.length} groups?` :
          `Delete "${keywordGroup?.name}" group?`}
        </Text>
      </Flex>
      <Flex style={{ padding: '20px 30px' }} direction="column">
        <Text className="aam-b--20">
          Deleting a keyword group does not delete the keywords that are present in that group.
        </Text>
      </Flex>
    </Modal>
  );
};

export default DeleteGroupModal;
