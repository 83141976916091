export const DATE_FILTER_OPTIONS_VALUES = {
  last_7_days: 6,
  last_30_days: 29,
  lifetime: 'lifetime',
  dateRange: 'custom-date-range',
};

export const DEFAULT_OPTIONS = [
  { value: DATE_FILTER_OPTIONS_VALUES.last_7_days, label: 'Last 7 Days' },
  { value: DATE_FILTER_OPTIONS_VALUES.last_30_days, label: 'Last 30 Days' },
  { value: DATE_FILTER_OPTIONS_VALUES.lifetime, label: 'Lifetime' },
  { value: DATE_FILTER_OPTIONS_VALUES.dateRange, label: 'Date Range' },
].map(({ label, ...rest }) => ({
  ...rest,
  label,
  tooltipLabel: label,
}));
