/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrchestrationIconStyles.module.scss';

const ZoomInfo = ({ className, size }) =>
  (
    <svg
      className={`${styles.default} ${className} ${styles[size]}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 23"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero">
          <path d="M22.724 0a.404.404 0 01.403.406v5.45a.404.404 0 01-.403.406h-1.606a.404.404 0 01-.406-.404v-1.68l-17.116 16.4h3.521c.224 0 .406.182.406.407v1.609a.406.406 0 01-.406.406H.405A.406.406 0 010 22.594v-2.008L18.97 2.42h-1.923a.406.406 0 01-.405-.407V.401A.404.404 0 0117.047 0h5.677z"></path>
          <path d="M2.416 5.928a.406.406 0 01-.406.407H.405A.406.406 0 010 5.928V.401A.404.404 0 01.405 0H13.2c.222 0 .403.179.405.4V1.95a.404.404 0 01-.405.404H2.416v3.575z"></path>
          <path d="M20.712 16.902c0-.224.181-.406.406-.406h1.609a.404.404 0 01.402.406v5.694a.404.404 0 01-.404.404H10.953a.406.406 0 01-.4-.412V20.98c0-.225.182-.407.406-.407h9.753v-3.67z"></path>
        </g>
      </g>
    </svg>
  )

export default ZoomInfo;