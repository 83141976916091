/* eslint-disable no-unused-vars */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { css } from 'styles/emotion';
import React, { useState, useEffect } from 'react';
import FileUploadArea from '../commonComponent/FileUploadArea';
import {
  Flex,
  FileUpload,
  FileUploaderProvider,
  useToast,
} from '@sixsense/core/components';
import { AlertCircle } from '@sixsense/core/icons';
import S3GenAIFileUploader from '../commonComponent/S3GenAIFileUploader';
import { get } from 'lodash';
import { validateFiles } from '../utils';
import { useOrg } from 'hooks';
import {
  ProductSummaryAttributesType,
  UPLOAD_STATUS,
  TOAST_KEY_MAP,
} from '../constant';
import FileListItem from '../commonComponent/FileListItem';

const acceptedFileTypes = '.pdf,.docx,.txt';

type FileUploadProps = {
  productAttributes: ProductSummaryAttributesType;
  category: string;
  updateSharedState?: (key: string, value: string | string[]) => void;
  displayType?: string;
  isSaveAsDraftAPISuccess: boolean;
  setIsSaveAsDraftAPISuccess: any;
};

const fileListStyleForBtn = css({
  flexDirection: 'unset !important',
  flexWrap: 'wrap',
  gap: '8px !important',
});
const fileListStyleForBtnPadding = css({
  paddingTop: '8px',
});
const fileListItemStyleForBtn = css({
  flex: '1 1 calc(50% - 16px) !important',
  '> div': {
    padding: '8px !important',
  },
});

const separateFilesByCategory = (files, category) => {
  const matchingFiles = files?.filter((file) => file.category === category);
  const nonMatchingFiles = files?.filter((file) => file.category !== category);
  return { matchingFiles, nonMatchingFiles };
};

const FileUploadComponent = ({
  productAttributes,
  category,
  updateSharedState,
  displayType = 'button',
  isSaveAsDraftAPISuccess,
  setIsSaveAsDraftAPISuccess,
}: FileUploadProps) => {
  const toast = useToast();
  const { id: orgId } = useOrg();
  const [items, setItems] = useState([]);
  const [fetchOptions, setFetchOptions] = useState({});
  const { matchingFiles, nonMatchingFiles } = separateFilesByCategory(
    productAttributes?.documents,
    category
  );
  const [filesLength, setFilesLength] = useState();
  const [existingFiles, setExistingFiles] = useState(
    matchingFiles?.map((doc) => ({
      ...doc,
      file: {
        name: doc?.file_name || doc?.file.name,
      },
      uploadStatus: {
        status: UPLOAD_STATUS?.DONE,
      },
    })) || []
  );

  useEffect(() => {
    const headers = new Headers();
    const csrfToken = get(window, '__CSRF_TOKEN__', '');
    headers.append('X-CSRFToken', csrfToken);
    if (csrfToken) {
      setFetchOptions({ headers });
    }
  }, []);

  const updateFilesAndResetState = () => {
    setExistingFiles(
      matchingFiles?.map((doc) => ({
        ...doc,
        file: {
          name: doc?.file_name || doc?.file.name,
        },
        uploadStatus: {
          status: UPLOAD_STATUS.DONE,
        },
      }))
    );
    setItems([]);
    setIsSaveAsDraftAPISuccess(false);
  };

  useEffect(() => {
    if (isSaveAsDraftAPISuccess) {
      updateFilesAndResetState();
    }
  }, [isSaveAsDraftAPISuccess]);

  useEffect(() => {
    const existingFilesLength =
      existingFiles?.filter((file) => file?.action !== 'remove')?.length || 0;
    const totalFilesLength = existingFilesLength + (items?.length || 0);
    setFilesLength(totalFilesLength);
    if (totalFilesLength > 3) {
      // Calculate how many files to keep from items
      const filesToKeep = Math.max(3 - existingFilesLength, 0);
      // Keep only the required number of files in items
      const allowedItems = items?.slice(0, filesToKeep);
      setItems([...allowedItems]);
      // Show the toast message
      toast.show({
        type: 'icon',
        title: 'Oops!',
        subtitle: 'Maximum only 3 files allowed.',
        icon: AlertCircle,
        iconColor: 'Warning',
        duration: 5000,
        key: TOAST_KEY_MAP.TOO_MANY_FILES,
      });
    }
  }, [items, existingFiles]);

  const fileUploader = new S3GenAIFileUploader({
    signedUrlEndpoint: `/sales-ai-settings/${orgId}/admin/email-writer-generate-signed-url/`,
    fetchOptions,
  });

  const handleFileChange = (o) => {
    setItems((prevItems) => {
      const nextItems = o(prevItems);
      const existingFilesLength =
        existingFiles?.filter((file) => file?.action !== 'remove')?.length || 0;
      if (nextItems?.length + existingFilesLength > 3) {
        toast.show({
          type: 'icon',
          title: 'Oops!',
          subtitle: 'Maximum only 3 files allowed.',
          icon: AlertCircle,
          iconColor: 'Warning',
          duration: 5000,
          key: TOAST_KEY_MAP.TOO_MANY_FILES,
        });
        return prevItems; // Do not update if the limit is exceeded
      }

      // Prepare documents by combining existing files and new items
      const documents = productAttributes?.documents
        ? o(productAttributes?.documents)
        : [];
      nextItems.forEach((item) => {
        const isUploadCompleted =
          item.uploadStatus?.status === UPLOAD_STATUS.DONE;
        if (isUploadCompleted) {
          const { document_id } = item?.uploadStatus?.url;
          const documentExists = documents?.some(
            (doc) => doc?.document_id === document_id
          );
          if (!documentExists) {
            documents.push({
              ...item,
              document_id,
              action: 'add',
              category,
            });
          }
        }
      });
      // Remove duplicates and ensure only valid documents are kept
      const idsInNextItems = new Set(nextItems.map((item) => item.id));
      const filteredDocuments = documents?.filter(
        (item) =>
          idsInNextItems?.has(item.id) ||
          item?.action === 'remove' ||
          !item?.action ||
          item?.document_id
      );
      const seenIds = new Set();
      const uniqueDocuments = [...filteredDocuments, ...existingFiles].filter(
        (item) => {
          const uniqueId = item?.id || item?.document_id;
          // Check if the id has already been seen
          if (seenIds?.has(uniqueId)) {
            return false; // Skip this item as it is a duplicate
          }
          // Add the unique id to the Set and keep this item
          seenIds.add(uniqueId);
          return true;
        }
      );
      // Update shared state and return new items
      updateSharedState('documents', [...uniqueDocuments]);
      return nextItems;
    });
  };

  const onExistingFileRemove = (index: number) => {
    /* id is available on newly added files which are not yet submitted
     ** to productAttributes API. Hence we remove the entire file,
     ** for file that is uploaded, we update the action to 'remove' */
    if (existingFiles[index]?.id) {
      existingFiles.splice(index, 1);
    } else {
      existingFiles[index] = {
        ...existingFiles[index],
        action: 'remove',
      };
    }
    updateSharedState('documents', [
      ...nonMatchingFiles,
      ...items,
      ...existingFiles,
    ]);
    setExistingFiles([...existingFiles]);
  };

  return displayType === 'button' ? (
    <div>
      <Flex
        className={`${fileListStyleForBtn} ${
          filesLength > 0 ? fileListStyleForBtnPadding : ''
        }`}
      >
        {existingFiles?.map(
          (iteratingFile, index) =>
            iteratingFile?.action !== 'remove' && (
              <Flex
                direction="column"
                key={iteratingFile?.id}
                className={fileListItemStyleForBtn}
              >
                <FileListItem
                  item={iteratingFile}
                  showFileIcon
                  onRemove={() => onExistingFileRemove(index)}
                />
              </Flex>
            )
        )}
      </Flex>
      <FileUploaderProvider value={fileUploader}>
        <FileUploadArea
          items={items}
          onChange={handleFileChange}
          accept={acceptedFileTypes}
          showFileIcon
          multiple
          uploadUserMessage="TXT, PDF or DOCX (max. 1 Mb)"
          validateFile={(file) => validateFiles(file, items)}
          itemsLength={filesLength}
          existingFiles={existingFiles}
        />
      </FileUploaderProvider>
    </div>
  ) : (
    <div>
      <FileUploaderProvider value={fileUploader}>
        <FileUpload
          items={items}
          onChange={handleFileChange}
          accept={acceptedFileTypes}
          showFileIcon
          multiple
          uploadUserMessage="TXT, PDF or DOCX (max. 1 Mb)"
          validateFile={(file) => validateFiles(file, items)}
        />
      </FileUploaderProvider>
      {existingFiles?.map(
        (iteratingFile, index) =>
          iteratingFile?.action !== 'remove' && (
            <Flex
              direction="column"
              style={{ paddingTop: '10px' }}
              key={iteratingFile.id}
            >
              <FileListItem
                item={iteratingFile}
                showFileIcon
                onRemove={() => onExistingFileRemove(index)}
              />
            </Flex>
          )
      )}
    </div>
  );
};

export default FileUploadComponent;
