import { get, mapValues, reduce, keys, each, create } from 'lodash';
import { createSelector } from 'reselect';

const MODULE_KEY = 'crossfilterRedux'; // This is the root node for all crossfilters

export const crossfilterSelector = (crossfilterKey) => (state) => state[MODULE_KEY][crossfilterKey];
export const dimensionsSelector = (crossfilterKey) => (state) => (
  get(crossfilterSelector(crossfilterKey)(state), 'dimensions', {})
);
export const allAppliedFiltersSelector = (crossfilterKey) => (state) => {
  const dimensions = get(crossfilterSelector(crossfilterKey)(state), 'dimensions', {});
  const cleansed = mapValues(
    dimensions,
    (value) => ({ filters: value.filters || {}, extra: value.extra })
  );
  return create({
    _total: reduce(
      cleansed,
      (acc, value) => acc + keys(value.filters).length,
      0),
  }, cleansed);
};
export const appliedFiltersSelector = (crossfilterKey) => (dimensionKey) => (state) => (
  get(crossfilterSelector(crossfilterKey)(state), `dimensions.${dimensionKey}.filters`, {})
);
export const dimensionSelector = (crossfilterKey) => (dimensionKey) => (state) => (
  get(crossfilterSelector(crossfilterKey)(state), `dimensions.${dimensionKey}`, {})
);
export const totalSizeSelector = (crossfilterKey) => (state) => (
  get(crossfilterSelector(crossfilterKey)(state), 'crossfilter', {}).size()
);
export const serializedFiltersSelectors = (crossfilterKey) => createSelector(
  dimensionsSelector(crossfilterKey),
  (dimensions) => {
    const serializedFilters = [];
    each(dimensions, (dimension, dimensionKey) => {
      const { filters, serializer } = dimension;
      each(filters, (filter, filterKey) => {
        serializedFilters.push(serializer(filter, filterKey, dimensionKey, dimension));
      });
    });
    return serializedFilters;
  }
);

export const areFiltersAppliedSelector = (crossfilterKey) => createSelector(
  allAppliedFiltersSelector(crossfilterKey),
  (allAppliedFilters) => allAppliedFilters._total > 0
);
