// Adding this selector to avoid circular dependency.

import {
  concat,
  every,
  filter,
  get,
  includes,
  isEmpty,
  map,
  omitBy,
  pick,
  size,
  some,
  split,
  toLower,
  transform,
} from 'lodash';
import { queryStringParamsSelector } from 'modules/global/selectors';
import { mapTypeSelector } from 'modules/user/selectors';
import { SKIP_IN_ADV_FILTER_COLUMNS } from './constants';

const { createSelector } = require('reselect');

// root selector for combined state
export const mappingReviewGlobalSelector = (state) =>
  state.taxonomyV2.reviewMapping;

// root selector for review data
const reviewDataSelector = (state) => state.taxonomyV2.reviewMapping.reviewData;

// Return { dataSource, classificationType }
export const sourceAndTypeSelector = createSelector(
  reviewDataSelector,
  ({ globalConfig }) => globalConfig
);

export const mappingDataSelector = createSelector(
  reviewDataSelector,
  ({ mappingsData }) => mappingsData
);

export const someSelectedSelector = createSelector(
  mappingDataSelector,
  (mappingsData) => some(mappingsData, 'checked')
);

export const allSelectedSelector = createSelector(
  mappingDataSelector,
  (mappingsData) => every(mappingsData, 'checked')
);

export const isAnySignificantSelected = createSelector(
  mappingDataSelector,
  (mappingsData) =>
    some(
      mappingsData,
      ({ checked, is_significant, mode_of_mapping }) =>
        checked === true && is_significant && mode_of_mapping !== 'rule'
    )
);

export const selectedRowsSelector = createSelector(
  mappingDataSelector,
  someSelectedSelector,
  (mappingsData, someSelected) =>
    !someSelected ? [] : filter(mappingsData, 'checked')
);

export const sortInfoSelector = createSelector(
  reviewDataSelector,
  ({ sort }) => sort
);

export const allMappingColumnsSeletor = createSelector(
  reviewDataSelector,
  ({ mappingColumns }) => mappingColumns
);

export const previewDataSelector = createSelector(
  queryStringParamsSelector,
  ({ rule, id }) => {
    try {
      if (rule) {
        return JSON.parse(decodeURIComponent(escape(atob(rule))));
      }

      if (id) {
        return { mapping_ids: split(id, ',') };
      }

      return {};
    } catch (e) {
      return {};
    }
  }
);

export const isPreviewModeSelector = createSelector(
  queryStringParamsSelector,
  previewDataSelector,
  ({ mode }, data) => mode === 'preview' && !isEmpty(data)
);

export const viewMappingColumnsSelector = createSelector(
  allMappingColumnsSeletor,
  mapTypeSelector,
  isPreviewModeSelector,
  (mappingColumns, selectedMapType, isPreviewMode) =>
    mappingColumns.filter(
      ({ mapType, hiddenForView, hideOnPreview }) =>
        (!mapType || includes(mapType, selectedMapType)) &&
        (isPreviewMode ? !hideOnPreview : !hiddenForView)
    )
);

/**
 * Data columns are the columns which varies for each data source and
 * are not fixed in table
 */
export const dataColumnSelector = createSelector(
  allMappingColumnsSeletor,
  mapTypeSelector,
  (mappingColumns, selectedMapType) =>
    mappingColumns
      .filter(
        ({ mapType }) => !(mapType && !includes(mapType, selectedMapType))
      )
      .map(({ displayName, title, ...rest }) => ({
        ...rest,
        displayName: displayName || title,
      }))
);

export const sortableColumnsSelector = createSelector(
  viewMappingColumnsSelector,
  (mappingColumns) =>
    mappingColumns
      .filter(({ sortable = true, hiddenForView }) => sortable || hiddenForView)
      .map(({ dataIndex, displayName, title }) => ({
        key: dataIndex,
        displayName: displayName || title,
      }))
);

/**
 * Columns used in adv filters.
 */
export const advFilterColumns = createSelector(dataColumnSelector, (columns) =>
  columns.filter(
    ({ dataIndex, fixed }) =>
      !fixed && !includes(SKIP_IN_ADV_FILTER_COLUMNS, dataIndex)
  )
);

export const paginationInfoSelector = createSelector(
  reviewDataSelector,
  ({ pagination }) => pagination
);

export const loadingSelector = createSelector(
  reviewDataSelector,
  ({ loading }) => loading
);

export const errorSelector = createSelector(
  reviewDataSelector,
  ({ error }) => error
);

export const totalMappingsCountSelector = createSelector(
  reviewDataSelector,
  ({ totalRecords }) => totalRecords
);

export const dataFiltersOptionsSelector = createSelector(
  queryStringParamsSelector,
  ({ dfilters_options }) => dfilters_options || {}
);

export const dataFiltersSelector = createSelector(
  queryStringParamsSelector,
  ({ dfilters }) => dfilters || {}
);

export const quickFiltersSelector = createSelector(
  queryStringParamsSelector,
  ({ qcfilters }) => qcfilters || {}
);

export const isSignificantCheckedSelector = createSelector(
  queryStringParamsSelector,
  ({ showsignificant }) => toLower(showsignificant) === 'true'
);

export const filterCountSelector = createSelector(
  dataFiltersSelector,
  quickFiltersSelector,
  (df, qc) => size(df) + size(qc)
);

export const filterStringSelector = createSelector(
  dataFiltersSelector,
  dataFiltersOptionsSelector,
  quickFiltersSelector,
  isSignificantCheckedSelector,
  (df, dfo, qf, isSig) =>
    JSON.stringify(df) + JSON.stringify(dfo) + JSON.stringify(qf) + isSig
);

/**
 * Returns the array of tupple filters.
 *[
 *  ['created_dt', '2024-07-01_2024-07-25', 'contains'],
 *  ['mode_of_mapping', ['model' ,'rule'], 'in'],
 *  ['title', 'test', 'contains'],
 *]
 */
export const formattedFiltersSelector = createSelector(
  dataFiltersSelector,
  dataFiltersOptionsSelector,
  quickFiltersSelector,
  isSignificantCheckedSelector,
  (dataFilters, dataFilterOptions, quickFilters, isSignificantChecked) => {
    const othersFilters = pick(quickFilters, [
      'status',
      'mode_of_mapping',
      'updated_by',
      'rule_id',
      'channel',
      'products',
    ]);
    const rqFilters = concat(
      // adv filters
      transform(
        dataFilters,
        (result, value, key) => {
          if (value) {
            result.push([
              key,
              value.replace(/'/g, "''"),
              get(dataFilterOptions, key, 'contains'),
            ]);
          }

          return result;
        },
        []
      ),
      // qc filters
      transform(
        othersFilters,
        (result, value, key) => {
          if (value && value !== 'all') result.push([key, value, 'in']);
          return result;
        },
        []
      )
    );

    const created_dt = get(quickFilters, 'created_dt');
    const updated_dt = get(quickFilters, 'updated_dt');
    const review_status = get(quickFilters, 'review_status');
    const activity_mapping = get(quickFilters, 'activity_mapping');

    if (created_dt) {
      rqFilters.push(['created_dt', created_dt, 'exact_match']);
    }

    if (updated_dt) {
      rqFilters.push(['updated_dt', updated_dt, 'exact_match']);
    }

    if (review_status === 'reviewed') {
      rqFilters.push(['is_reviewed', true, 'exact_match']);
    } else if (review_status === 'unreviewed') {
      rqFilters.push(['is_reviewed', false, 'exact_match']);
    }

    if (isSignificantChecked) {
      rqFilters.push(['is_significant', true, 'exact_match']);
    }

    if (activity_mapping) {
      rqFilters.push([
        'activity_mapping',
        map(
          activity_mapping,
          ({
            predicted_action: action,
            predicted_channel: channel,
            mapped_type,
            event,
          }) => omitBy({ action, channel, mapped_type, event }, (v) => !v)
        ),
        'exact_match',
      ]);
    }

    return rqFilters;
  }
);

export const previewMappingsSelector = createSelector(
  previewDataSelector,
  ({ mapping }) => mapping
);

export const previewRuleNameSelector = createSelector(
  previewDataSelector,
  ({ name }) => name
);
