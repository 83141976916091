import { getAsyncInjectors } from 'store/utils';
import SegmentPerformanceRoutes from './routes/segment';
// import CampaignConversionsRoutes from 'routes/Advertising/routes/Campaigns';
import ManageRoute from './routes/Manage';
import ReportRoute from './routes/Reports';
import CampaignRoute from './routes/Campaign';
import CMORoute from './routes/cmo';
import SixQAPerformanceRoutes from './routes/6QAPerformance';
import savedReportRoutes from './routes/SavedReport';
import PrivacyOptOut from './routes/SI-PrivacyOptOut';

export default function createRoutes(store) {
  const {
    injectReducer,
    injectSagas,
  } = getAsyncInjectors(store);

  return {
    path: 'reports',
    name: 'reports',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/reports/manage'),
    },
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');

        const usageReportSagas = require('./routes/Reports/routes/Usage/sagas');
        const usageReportReducer = require('./routes/Reports/routes/Usage/modules');
        const valueReportSagas = require('./routes/Reports/routes/Value/sagas');
        const valueReportReducer = require('./routes/Reports/routes/Value/modules');
        const modelReportSagas = require('./routes/Reports/routes/Model/sagas');
        const modelReportReducer = require('./routes/Reports/routes/Model/modules');

        const modelUpdateReportSagas = require('./routes/Reports/routes/ModelUpdate/sagas');
        const modelUpdateReportReducer = require('./routes/Reports/routes/ModelUpdate/modules');

        const savedReportreducer = require('./routes/Manage/modules');
        const savedReportSagas = require('./routes/Manage/sagas');
        const influencedConversionsReportSagas =
          require('./routes/Campaign/routes/InfluencedConversions/sagas');
        const influencedConversionsReducer =
          require('./routes/Campaign/routes/InfluencedConversions/modules');
        injectReducer('usageReports', usageReportReducer.default);
        injectReducer('valueReports', valueReportReducer.default);
        injectReducer('modelReports', modelReportReducer.default);
        injectReducer('modelUpdate', modelUpdateReportReducer.default);
        injectReducer('influencedConversionsReport', influencedConversionsReducer.default);
        injectReducer('reportsManage', savedReportreducer.default);
        injectSagas(
          'reports',
          [
            ...usageReportSagas.default,
            ...valueReportSagas.default,
            ...modelReportSagas.default,
            ...influencedConversionsReportSagas.default,
            ...modelUpdateReportSagas.default,
          ]
        );
        injectSagas('reportsManage', savedReportSagas.default);
        cb(null, containers.ReportsContainer);
      }, 'reports');
    },
    childRoutes: [
      SegmentPerformanceRoutes(),
      ManageRoute(store),
      ReportRoute(store),
      CampaignRoute(store),
      PrivacyOptOut(),
      SixQAPerformanceRoutes(),
      CMORoute(),
      savedReportRoutes(),
      // CampaignConversionsRoutes(store),
    ],
  };
}
