export const STATE_KEY = "campaignClassifications";

export const DEFAULT_FOLDERS = {
  CAMPAIGNS_DRAFT: "campaigns_drafts_folder",
  CAMPAIGNS_ARCHIVED: "system_campaign_archive_folder",
  CAMPAIGNS_UNSORTED: "unsorted_campaign_folder",
};

export const CLASSIFICATION_TYPES = {
  CAMPAIGN_FOLDER: "campaign_folder",
  ...DEFAULT_FOLDERS,
};

export const CAMPAIGN_CLASSIFICATION_ACTIONS = {
  move: "Moving",
  archive: "Archive",
  restore: "Restore",
};

export const REQUIRED_FOLDER_TYPES = [
  DEFAULT_FOLDERS.CAMPAIGNS_UNSORTED,
  DEFAULT_FOLDERS.CAMPAIGNS_ARCHIVED,
];
