/* eslint-disable max-len */
import { defineRequestState, defineSharedState } from '@sixsense/core';
import { orderBy } from 'lodash';

const PATH_PREFIX = 'privacyoptout';
const PRIVACY_OPT_OUT_QUERY =
  'query PrivacyOptOutQuery { privacyOptOut  { response } } ';

export const usersListRequestState = defineRequestState({
  path: `${PATH_PREFIX}.userList`,
  getConfig: () => ({
    url: 'graphql/',
    method: 'POST',
    body: JSON.stringify({
      operationName: 'PrivacyOptOutQuery',
      query: PRIVACY_OPT_OUT_QUERY,
    }),
  }),
  transformData: (resp) => {
    if (resp.data?.privacyOptOut?.response) {
      return orderBy(resp.data.privacyOptOut.response, ({ requested_at }) => new Date(requested_at), ['desc']);
    }
    return [];
  },
});
export const userListState = defineSharedState({
  path: `${PATH_PREFIX}.userListState`,
  defaultValue: [],
});
