import React from "react";
import { Text } from "@sixsense/core/components";
import { isDraftOrScheduledCampaign } from "../../../utils";
import { toFixedfp } from "utils/utils";
import { NA } from "./common/NA";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const ACTR = {
  sortByKey: "account_ctr",
  key: "actr",
  csvColumnKey: "account_ctr",
  title: <Label name={CAMPAIGN_LABELS.accountCtr} />,
  dataIndex: "campaign_data.account_ctr",
  width: pxToRem(145),
  render: (actr, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (isDraftOrScheduledCampaign(campaignStatus)) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {toFixedfp(2)(actr)}%
      </Text.Body>
    );
  },
  align: "right",
};
