import { orderBy } from 'lodash';

export const reduceTechoPsychoJobDataByProduct = (data) =>
  data.reduce((n, i) => {
    const index = n.findIndex((k) => k.name === i.name);
    if (index > -1) {
      if (!n[index].products) {
        // eslint-disable-next-line no-param-reassign
        n[index].products = [];
      }
      n[index].products.push({
        product: i.product,
        created: i.created,
      });
      return n;
    }
    n.push(i);
    return n;
  }, []);

export const getTechnoPsychoJobCreatedDate = (data) => {
  if (data.products && data.products.length) {
    const dates = orderBy([...data.products,
         { created: data.created }], (i) => new Date(i.created), 'asc');
    return dates[0]?.created;
  }
  return data.created;
};
