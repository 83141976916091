import React, { createContext, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesLoadedSelector } from 'v2-components/Image/selectors';
import { actions } from 'v2-components/Image/modules';

const { Provider, Consumer } = createContext();

const ImageContext = ({ routeKey }) => {
  // class so we can mapstatetoprops after we've received the routeKey
  class ImgProviderComponent extends Component {
    static propTypes = {
      loadingComplete: PropTypes.bool,
      addImage: PropTypes.func,
      loadImageSuccess: PropTypes.func,
      loadImageFailure: PropTypes.func,
      children: PropTypes.any,
    };

    static defaultProps = {
      loadingComplete: false,
    };

    constructor(props) { // and so eslint shuts up
      super(props);
      this.state = {};
    }

    render() {
      const {
        loadingComplete,
        addImage,
        loadImageSuccess,
        loadImageFailure,
      } = this.props;

      return (
        <Provider
          value={{
            loadingComplete,
            routeKey,
            addImage,
            loadImageSuccess,
            loadImageFailure,
          }}
        >
          {this.props.children}
        </Provider>
      );
    }
  }

  const mapStateToProps = (state) => ({
    loadingComplete: imagesLoadedSelector(routeKey)(state),
  });

  const { addImage, loadImageSuccess, loadImageFailure } = actions;

  return connect(mapStateToProps, {
    addImage: addImage(routeKey),
    loadImageSuccess: loadImageSuccess(routeKey),
    loadImageFailure: loadImageFailure(routeKey),
  })(ImgProviderComponent);
};

ImageContext.ImageConsumer = Consumer;

export default ImageContext;
