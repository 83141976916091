import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Row,
  CustomScrollBar,
  Select,
  Button,
  Radio,
  Input,
} from 'v2-components';
import styles from './AdvancedSearch.module.scss';
import { connect } from 'react-redux';
import {
  getRolesListByAppSelector,
  appsListSelector,
} from '../../selectors';
import { useSelector } from '@sixsense/core/versioned/react-redux';
// import { errors } from 'utils/validators';
import { Grid } from '@sixsense/core/components';
import { classNames } from 'utils/utils';
import { cloneDeep } from 'lodash';
import {
  errorNotificationContainerElementId,
  appBodyElementId,
  ADVANCED_SEARCH_DEFAULT_FILTER,
} from '../../constants';
import {
  AppsMultiRolesDropdownComponent,
} from '../Utils-Components/RolesDropdown/AppsMultiRolesDropdown';
import { reduceAppListToObject } from '../../utils';

const STATUS_FILTER_OPTIONS = [
  { label: 'All Status', value: 'all' },
  { label: 'Enabled', value: 'enabled' },
  { label: 'Disabled', value: 'disabled' },
  { label: 'Invited', value: 'invited' },
  { label: 'Expired Invite', value: 'inviteExpired' },
];

const SAML_STATUS_FILTER_OPTIONS = [
  { label: 'All Status', value: 'all' },
  { label: 'Enabled', value: 'enabled' },
  { label: 'Disabled', value: 'disabled' },
];


const extraTopOffset = -300;

const scrollFromToElementById = (scrollFormElementId, scrollToElementId) => {
  setTimeout(() => {
    const scrollableElement = document.getElementById(scrollFormElementId);
    if (scrollableElement) {
      const scrollToElement = document.getElementById(`${scrollToElementId}`);
      if (scrollToElement) {
        scrollableElement.scrollTo({
          top: scrollToElement.offsetTop + extraTopOffset,
          behavior: 'smooth',
        });
      }
    }
  }, 1500);
};

const AdvancedSearchComponent = (props) => {
  const {
    changeVisible,
    visibility,
    selectedUsers,
    isSAML,
    setFilterApplied,
    filterApplied,
  } = props;

  const appsList = useSelector(appsListSelector);

  // Initial Status Set Start
  const [statusFilter, setStatusFilter] = useState(filterApplied.statusFilter);
  const [rolesFilterAppMap, setRolesFilterAppMap] = useState(
    cloneDeep(filterApplied.rolesFilterAppMap)
  );

  const [isEmail, setIsEmail] = useState(filterApplied.isEmail);
  const [emailsStringFilter, setEmailsStringFilter] = useState(
    filterApplied.emailsListFilter.join(', ')
  );
  // Initial Status Set End

  React.useEffect(() => {
    setStatusFilter(filterApplied.statusFilter);
    setRolesFilterAppMap(cloneDeep(filterApplied.rolesFilterAppMap));
    setIsEmail(filterApplied.isEmail);
    setEmailsStringFilter(filterApplied.emailsListFilter.join(', '));
  }, [visibility, filterApplied]);

  const checkClickedOutside = (ref, visibile) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          if (visibile) {
            changeVisible(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () =>
        document.removeEventListener('mousedown', handleClickOutside);
    }, [ref, visibile]);
  };

  const wrapperRef = useRef(null);
  checkClickedOutside(wrapperRef, visibility);

  const isSelectionDefault =
    (ADVANCED_SEARCH_DEFAULT_FILTER.isEqualFuncObject.statusFilter(
      statusFilter
    ) &&
      ADVANCED_SEARCH_DEFAULT_FILTER.isEqualFuncObject.rolesFilterAppMap(
        rolesFilterAppMap
      ) &&
      ADVANCED_SEARCH_DEFAULT_FILTER.isEqualFuncObject.emailsListFilter(
        emailsStringFilter.trim().split(/\s+,/)
      ) &&
      ADVANCED_SEARCH_DEFAULT_FILTER.isEqualFuncObject.isEmail(isEmail)) ||
    !!selectedUsers.size;

  const renderRolesPerAppDropdown = () => (
    <Grid.Cell
      className={classNames(
        styles.label,
        styles.multiAppRoleDropDown,
        styles.w100
      )}
    >
      <AppsMultiRolesDropdownComponent
        getRoleTitle={(sn, ln) => ln}
        appsDisabledMap={reduceAppListToObject(
          appsList,
          () => !!selectedUsers.size || isEmail
        )}
        appsOnChangeMap={reduceAppListToObject(
          appsList,
          (appId) => (val) =>
            setRolesFilterAppMap((prev) => ({
              ...prev,
              [appId]: val,
            }))
        )}
        appsValueMap={reduceAppListToObject(
          appsList,
          (appId) => rolesFilterAppMap[appId] || []
        )}
        wrapperRef={wrapperRef}
        includeAdmin
        isNoRoleAssign
      />
    </Grid.Cell>
  );

  const title = (
    <Row
      justifyContent={Row.JUSTIFY_CONTENT.SPACE_BETWEEN}
      alignItems={Row.ALIGN_ITEMS.CENTER}
      className={styles.filterRow}
    >
      <Text className={classNames(styles.boldText)}>Filters</Text>
      <Row
        onClick={() => {
          if (selectedUsers.size) {
            return;
          }
          setFilterApplied(ADVANCED_SEARCH_DEFAULT_FILTER.values);

          setStatusFilter(ADVANCED_SEARCH_DEFAULT_FILTER.values.statusFilter);

          setRolesFilterAppMap(cloneDeep({}));

          setIsEmail(ADVANCED_SEARCH_DEFAULT_FILTER.values.isEmail);

          setEmailsStringFilter(
            ADVANCED_SEARCH_DEFAULT_FILTER.values.emailsListFilter.join(' ,')
          );
        }}
      >
        <Text
          onClick={() => {}}
          className={classNames(
            isSelectionDefault ? styles.disabledText : null,
            styles.resetText
          )}
        >
          Reset All
        </Text>
      </Row>
    </Row>
  );

  const statusRender = (
    <Row
      flexDirection={Row.FLEX_DIRECTION.COLUMN}
      alignItems={Row.ALIGN_ITEMS.FLEX_START}
      className={styles.filterRow}
    >
      <Text className={classNames(styles.boldText, styles.mb8)}>Status</Text>
      <Select
        disabled={!!selectedUsers.size}
        options={isSAML ? SAML_STATUS_FILTER_OPTIONS : STATUS_FILTER_OPTIONS}
        onChange={(val) => setStatusFilter(val)}
        value={statusFilter}
        width={'187px'}
        dropdownMatchSelectWidth={false}
        getPopupContainer={() => wrapperRef.current}
      />
    </Row>
  );

  const rolesRender = (
    <Row
      className={classNames(styles.w100)}
      flexDirection={Row.FLEX_DIRECTION.COLUMN}
    >
      <Row alignItems={Row.ALIGN_ITEMS.CENTER}>
        <Radio
          selected={!isEmail}
          action={() => {
            setIsEmail(false);
          }}
          item={{
            label: (
              <Text className={classNames(styles.boldText)}>User Roles</Text>
            ),
          }}
          containerClassName={classNames(styles.radioContainerStyle)}
        />
      </Row>
      <Row className={classNames(styles.mt8, styles.w100)}>
        {renderRolesPerAppDropdown()}
      </Row>
    </Row>
  );
  const emailRender = (
    <Row
      className={classNames(styles.w100, styles.mt18)}
      flexDirection={Row.FLEX_DIRECTION.COLUMN}
    >
      <Row alignItems={Row.ALIGN_ITEMS.CENTER}>
        <Radio
          selected={isEmail}
          action={() => {
            setIsEmail(true);
          }}
          item={{
            label: (
              <Text className={classNames(styles.boldText)}>
                Search Specific Users
              </Text>
            ),
          }}
          containerClassName={classNames(styles.radioContainerStyle)}
        />
      </Row>

      <Row
        className={classNames(styles.w100, styles.mt8)}
        flexDirection={Row.FLEX_DIRECTION.COLUMN}
      >
        <Row className={styles.fieldLabel}>
          <Text className={classNames(styles.normalText)}>Email Addresses</Text>
        </Row>
        <Input
          disabled={!!selectedUsers.size || !isEmail}
          className={styles.textArea}
          type="textarea"
          value={emailsStringFilter}
          onChange={(e) => setEmailsStringFilter(e.target.value)}
          id={'filter_users_email_list'}
          // eslint-disable-next-line max-len
          placeholder={
            'Enter multiple email addresses (separated by a comma)\n' +
            'user1@example.com,user2@example.com,..'
          }
        />
      </Row>
    </Row>
  );

  const body = (
    <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
      {statusRender}
      <Row
        justifyContent={Row.JUSTIFY_CONTENT.CENTER}
        alignItems={Row.ALIGN_ITEMS.FLEX_START}
        className={styles.filterRow}
        flexDirection={Row.FLEX_DIRECTION.COLUMN}
      >
        {rolesRender}
        {emailRender}
      </Row>
    </Row>
  );

  const footer = (
    <Row
      justifyContent={Row.JUSTIFY_CONTENT.FLEX_END}
      alignItems={Row.ALIGN_ITEMS.CENTER}
      className={`${styles.filterRow} ${styles.advSearchFooter}`}
    >
      <Button
        className={styles.cancelButton}
        onClick={() => {
          changeVisible(false);
        }}
      >
        Cancel
      </Button>
      <Button
        type={Button.PRIMARY}
        onClick={() => {
          setFilterApplied({
            statusFilter,
            rolesFilterAppMap,
            isEmail,
            emailsListFilter: emailsStringFilter
              .trim()
              .toLowerCase()
              .split(/\s*,\s*/)
              .filter((e) => !!e),
          });
          changeVisible(false);
          scrollFromToElementById(
            appBodyElementId,
            errorNotificationContainerElementId
          );
        }}
        disabled={!!selectedUsers.size}
      >
        Apply
      </Button>
    </Row>
  );

  return (
    <div
      ref={wrapperRef}
      className={`${styles.container} ${!visibility ? styles.hidden : ''}`}
    >
      <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
        {title}
        <CustomScrollBar className={styles.scrollbarContainer}>
          {body}
        </CustomScrollBar>
        {footer}
      </Row>
    </div>
  );
};

AdvancedSearchComponent.propTypes = {
  changeVisible: PropTypes.func,
  visibility: PropTypes.bool,
  selectedUsers: PropTypes.object,
  isSAML: PropTypes.bool,
  setFilterApplied: PropTypes.func,
  filterApplied: PropTypes.object,
};

const AdvancedSearchWrapper = (props) => {
  // eslint-disable-next-line react/prop-types
  if (!props.visibility) {
    return null;
  }
  return <AdvancedSearchComponent {...props} />;
};

const mapStateToProps = (state) => ({
  getRolesListByApp: getRolesListByAppSelector(state),
});

export const AdvancedSearch = connect(
  mapStateToProps,
  null
)(AdvancedSearchWrapper);
