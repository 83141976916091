import { createSelector } from 'reselect';
import { pickBy, map, keys } from 'lodash';

const MODULE_KEY = 'upload2';
/* todo:
    - make better selectors
*/

// select the entire list of files, regardless of status
export const fileListSelector = (uploadKey) => (state) => (
  state[MODULE_KEY][uploadKey]
    ? state[MODULE_KEY][uploadKey].fileList
    : {}
);

// all files with pending status
  // dropped, and awaiting beforeUpload OR dropped, but form status error
export const pendingFileSelector = (uploadKey) => createSelector(
  fileListSelector(uploadKey),
  (fileList) => pickBy(fileList, { status: 'pending' })
);

// validated and good to go for beforeUpload
export const validFileSelector = (uploadKey) => createSelector(
  fileListSelector(uploadKey),
  (fileList) => pickBy(fileList, { status: 'okay' }),
);

// passed beforeUpload and ready to be processed
export const readyFileSelector = (uploadKey) => createSelector(
  fileListSelector(uploadKey),
  (fileList) => pickBy(fileList, { status: 'ready' }),
);

// successfully uploaded to s3/previously uploaded files
export const uploadedFileSelector = (uploadKey) => createSelector(
  fileListSelector(uploadKey),
  (fileList) => pickBy(fileList, { status: 'uploaded' })
);

// select all files with a status of error
export const invalidFileSelector = (uploadKey) => createSelector(
  fileListSelector(uploadKey),
  (fileList) => pickBy(fileList, { status: 'error' }),
);

// files currently uploading to aws
export const uploadingFileSelector = (uploadKey) => createSelector(
  fileListSelector(uploadKey),
  (fileList) => pickBy(fileList, { status: 'uploading' }),
);

export const uploadingS3FileSelector = (uploadKey) => createSelector(
  fileListSelector(uploadKey),
  (fileList) => pickBy(fileList, { status: 'uploadingS3' }),
);

// any files that have not been uploaded
// used to select all files for re-processing after the form enters an error state
export const stagnantFileSelector = (uploadKey) => createSelector(
  fileListSelector(uploadKey),
  (fileList) => pickBy(fileList, (file) => file.status !== 'uploaded'),
);

/*
export const stagnantFileSelector = (uploadKey, fileName) => createSelector(
  fileListSelector(uploadKey),
  (fileList) => fileList,
  (fileList) => pickBy(fileList, (file) => file.status !== 'uploaded'),
);
*/


// overall status of the form
export const formStatusSelector = (state) => (
  state[MODULE_KEY]
    ? state[MODULE_KEY].formStatus
    : null
);

// select an individual file
export const fileSelector = (uploadKey, fileName) => createSelector(
  fileListSelector(uploadKey),
  (fileList) => fileList[fileName],
);

// fileLimit from store that was pass in cofig
export const fileLimitSelector = (uploadKey) => (state) => (
  state[MODULE_KEY][uploadKey]
    ? state[MODULE_KEY][uploadKey].fileLimit
    : 1
);

// attachmentType from store that was passed in config
export const attachmentSelector = (uploadKey) => (state) => (
  state[MODULE_KEY][uploadKey]
    ? state[MODULE_KEY][uploadKey].attachmentType
    : null
);

export const fileIdSelector = (uploadKey) => createSelector(
  uploadedFileSelector(uploadKey),
  (files) => map(files, (file) => file.s3Url.attachment)
);

export const hasFilesSelector = (uploadKey) => createSelector(
  uploadedFileSelector(uploadKey),
  (files) => !!keys(files).length
);
