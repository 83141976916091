export const JOB_TITLES_CONFIRM_TEXT = `Are you sure you want to
 remove the selected job titles from your list?`;
export const JOB_TITLE_CONFIRM_TEXT = `Are you sure you want to
 remove this job title from your list?`;
export const ADD_JOB_TITLES_SUCCESS_MSG = 'Successfully added job title';
export const DELETE_JOB_TITLE_SUCCESS_MSG = 'Successfully removed job title';
export const ORDER_JOB_TITLE_SUCCESS_MSG = 'Successfully changed job titles order';
export const JOB_TITLE_ERROR_MSG = `There was an error 
. Please try again later`;
export const JOB_TITLE_DUPLICATE_ERROR_MSG = 'Duplicate jobtitle';
export const JOB_TITLE_DISABLED_TOOTLTIP = 'Select a Product to Add Job Titles';
