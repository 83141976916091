import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { rolesSelector } from '../../selectors';
import { Role } from '../../types';
import RoleSummaryCard from './RoleSummaryCard';
import RolePermissionsCard from './RolePermissionsCard';
import { compose } from 'redux';
import { withRouter, WithRouterProps } from 'react-router';
import { useSelector } from '@sixsense/core/versioned/react-redux';

type Props = {
  roleMap: { [key: string]: Role };
  getAppIdFromRoleId: (id:number) => any;
} & WithRouterProps;

const RoleDetailPage = ({ params, router }: Props) => {
  const roles = useSelector(rolesSelector);

  const selectedRole = React.useMemo(() =>
    roles.find(({ id }) => id === +params.id) || {}, [roles]);

  const appId = (roles.find(({ id }) => id === +params.id) || {}).app;

  useEffect(() => {
    if (!selectedRole) {
      router.push('/settings/usermanagement/roles');
    }
  }, [selectedRole]);

  return selectedRole ? (
    <React.Fragment>
      <RoleSummaryCard role={selectedRole} />
      <RolePermissionsCard appId={appId} role={selectedRole} />
    </React.Fragment>
  ) : null;
};

const mapStateToProps = () => ({});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(RoleDetailPage);
