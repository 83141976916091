import { createSelector } from 'reselect';
import { reduce, set, each, isEqual, keys } from 'lodash';
import { renderTemplate } from 'utils/utils';
import { SEGMENT_REDUX_STATE_KEY, UNSORTED_FOLDER } from 'modules/segments/constants';
import { genFilterDisplayObj } from './utils';
import { crmTypeSelector } from 'modules/user/selectors';
import {
  integrationsStateSelector,
  areCRMCredsValidatingSelector,
  isG2ConfiguredSelector,
} from 'modules/integrations/selectors';
import { INTEGRATIONS_TO_VALIDATE } from 'modules/integrations/constants';

const formatFiltersConfig = (config) =>
  // render all params with template for fast lookup.
  // update label to reflect param
  // exclude filters without display property
  reduce(
    config,
    (acc, val, key) => {
      if (val.filter_display_category) {
        const { template, params } = val;
        acc[key] = val;
        if (template && params) {
          each(params, (paramList, paramKey) => {
            each(paramList, (param) => {
              acc[
                renderTemplate(template, {
                  [paramKey]: param,
                })
              ] = {
                ...val,
                label: val.label,
                activeParam: param,
              };
            });
          });
        }
      }
      return acc;
    },
    {},
  );


const routeStateSelector = (state) => state[SEGMENT_REDUX_STATE_KEY];

const isLoadingSelector = createSelector(
  crmTypeSelector,
  isG2ConfiguredSelector,
  areCRMCredsValidatingSelector,
  integrationsStateSelector,
  routeStateSelector,
  (crmType, isG2Configured, areCRMCredsValidating, integrationsState, routeState) => {
    let isRouteLoading = routeState.loading;
    if (crmType) isRouteLoading = areCRMCredsValidating || isRouteLoading;
    if (isG2Configured) {
      isRouteLoading = integrationsState[INTEGRATIONS_TO_VALIDATE.G2].loading || isRouteLoading;
    }
    return isRouteLoading;
  }
);

const isLoadedSelector = createSelector(
  crmTypeSelector,
  isG2ConfiguredSelector,
  areCRMCredsValidatingSelector,
  integrationsStateSelector,
  routeStateSelector,
  (crmType, isG2Configured, areCRMCredsValidating, integrationsState, routeState) => {
    let isRouteLoaded = routeState.loaded;
    if (crmType) isRouteLoaded = !areCRMCredsValidating && isRouteLoaded;
    if (isG2Configured) {
      isRouteLoaded = !integrationsState[INTEGRATIONS_TO_VALIDATE.G2].loading && isRouteLoaded;
    }
    return isRouteLoaded;
  }
);

const INVALID_FILTER = {
  label: 'Unknown Filter',
  legacy_label: null,
  description: null,
  options: [],
  column: '__INVALID__',
  form_input_type: 'selection',
  filter_category: [],
  filter_sub_category: null,
  filter_display_category: null,
  is_autocomplete: false,
  is_fetchable: false,
  is_date_dependent: false,
  sort_order: 'desc',
  default_operator: 'EqOp',
  is_popular: false,
  allowed_operators: [],
  mutually_exclusive: false,
  excluded_date_options: null,
  autocomplete_endpoint: null,
  is_lazy: false,
  disabled: false,
  disabled_message: null,
  info_message: 'This filter is no longer valid, please remove it.',
  is_nullable: false,
  icon: null,
  is_counting_filter: false,
  counting_variable: null,
};

const configHandler = {
  get(obj, prop) {
    return prop in obj ? obj[prop] : INVALID_FILTER;
  },
};

const filtersConfigSelector = createSelector(
  routeStateSelector,
  (routeState) => {
    const config = formatFiltersConfig(routeState.filtersConfig);
    return new Proxy(config, configHandler);
  },
);

const templateDataSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.templateData,
);

const filtersConfigLoadingSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.configLoading,
);

const isCreatingSegmentSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.creatingSegment,
);

const isUpdatingSegmentSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.updatingSegment
);

const segmentIsCreatingOrUpdatingSelector = createSelector(
  [isCreatingSegmentSelector, isUpdatingSegmentSelector],
  (creating, updating) => creating || updating
);

const stagedSegmentDataSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.stagedSegmentData,
);

const logicErrorSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.logicError
);

const filtersByCategorySelector = createSelector(
  filtersConfigSelector,
  (config) =>
    reduce(
      config,
      (acc, filterObj) => {
        const {
          filter_display_category,
          column,
          is_popular,
        } = filterObj;
        if (filter_display_category) {
          const path = [filter_display_category, column];
          set(acc, path, filterObj);
          if (is_popular) {
            set(acc, ['popular', column], filterObj);
          }
        }
        return acc;
      },
      {},
    ),
);

const getFilterOrderIndex = (category) => {
  switch (category) {
    case '6sense AI':
      return 0;
    case 'Company Profile':
      return 1;
    case 'Opportunity':
      return 2;
    case 'intent':
      return 3;
    case 'Advertising':
      return 4;
    case 'Website Engagement':
      return 5;
    case 'Contact Engagement':
      return 6;
    case 'CRM':
      return 7;
    case 'MAP':
      return 8;
    case 'other':
      return 9;
    default:
      return null;
  }
};
// new serializer for the Add Filters component UI
// which replaces the old - SelectFilters component
const addFiltersSelector = createSelector(
  filtersByCategorySelector,
  (filterConfig) => {
    const finalResult = [];
    for (const [key1, value1] of Object.entries(filterConfig)) {
      // eslint-disable-next-line no-continue
      if (['popular', 'sixqa'].includes(key1)) { continue; }
      const index = getFilterOrderIndex(key1);
      const filterObject = { filterDisplayCategory: key1, filters: [] };
      for (const value2 of Object.values(value1)) {
        filterObject.filters.push(genFilterDisplayObj(value2));
      }
      finalResult.splice(index, 0, filterObject);
    }
    return finalResult;
  },
);

const sixQAFiltersSelector = createSelector(
  filtersByCategorySelector,
  (filterConfig) => {
    const excludedCats = ['Company Profile', 'other', 'popular', 'sixqa'];
    const usedKeys = Object.keys(filterConfig).filter((key) => !excludedCats.includes(key));
    const config = usedKeys.map((key) => {
      const obj = key === 'CRM' ?
        { ...filterConfig.sixqa, ...filterConfig.CRM } : filterConfig[key];
      return {
        filterDisplayCategory: key,
        filters: Object.values(obj).map(
          (filter) => genFilterDisplayObj(filter)
        ).filter((filt) => !filt.filterLabel.includes('6QA')),
      };
    });
    return config;
  }
);

const segFoldersSelector = createSelector(
  routeStateSelector,
  (routeState) => {
    const { segFolders } = routeState;
    const unsortedFolder = segFolders.filter(
      (folder) => folder.classification_type === UNSORTED_FOLDER
    );
    const otherFolders = segFolders.filter(
      (folder) => folder.classification_type !== UNSORTED_FOLDER
    );
    otherFolders.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
    return unsortedFolder.concat(otherFolders);
  }
);

const segTagsSelector = createSelector(
  routeStateSelector,
  (routeState) => {
    const tags = routeState.segTags;
    tags.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
    return tags;
  }
);

const folderErrorSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.folderError,
);

const selectedFoldersSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.selectedFolders,
);

const selectedFolderSelector = createSelector(
  selectedFoldersSelector,
  (selectedFolders) => selectedFolders.length > 0 ? selectedFolders[0] : {},
);

const folderToCreateSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.folderToCreate,
);

const tagsToCreateSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.tagsToCreate,
);

const savedFiltersetSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.initialFilterset,
);

const filtersetSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.editFilterset,
);

const workingSegmentNameSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.workingSegmentName,
);

const emptyFilterSelector = createSelector(
  filtersetSelector,
  filtersConfigSelector,
  (filterset, filtersConfig) => {
    for (const filter of filterset.filters) {
      const config = filtersConfig[filter.filter.variable];
      if (config.param_field_version === 2) {
        // means it's a new parameterized filter and need to ensure
        // at least 1 value exists for each param
        const params = filter.filter.metadata.params;
        if (!params) {
          return true;
        }
        for (const k of keys(config.param_labels)) {
          if (!params[k] || params[k].includes('', [], {})) {
            return true;
          }
        }
      }
      if (filter.filter_values.length === 0) {
        return true;
      }
    }
    return !filterset.filters.length;
  }
);

const unchangedFiltersetSelector = createSelector(
  routeStateSelector,
  (routeState) => isEqual(routeState.initialFilterset, routeState.editFilterset),
);


const numFilterValuesSelector = createSelector(
  filtersetSelector,
  (filterset) => {
    let vals = 0;
    for (const filter of filterset.filters) {
      vals += filter.filter_values.length;
    }
    return vals;
  }
);

const createCampaignModalSelector = createSelector(
  routeStateSelector,
  ({ createCampaignModal }) => createCampaignModal,
);

export {
  isLoadedSelector,
  isLoadingSelector,
  filtersConfigSelector,
  templateDataSelector,
  filtersConfigLoadingSelector,
  filtersByCategorySelector,
  segmentIsCreatingOrUpdatingSelector,
  isCreatingSegmentSelector,
  isUpdatingSegmentSelector,
  stagedSegmentDataSelector,
  logicErrorSelector,
  segFoldersSelector,
  segTagsSelector,
  folderErrorSelector,
  selectedFolderSelector,
  selectedFoldersSelector,
  folderToCreateSelector,
  tagsToCreateSelector,
  savedFiltersetSelector,
  filtersetSelector,
  emptyFilterSelector,
  unchangedFiltersetSelector,
  numFilterValuesSelector,
  createCampaignModalSelector,
  addFiltersSelector,
  sixQAFiltersSelector,
  workingSegmentNameSelector,
};
