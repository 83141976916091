export default function createRoutes() {
  return {
    path: 'list',
    name: 'list',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('../../containers/ObjectList/containers');
          cb(null, containers.ObjectList);
        },
        'settings_standardization_list'
      );
    },
  };
}
