import {
  createAdStateGenerator,
  adOptionsStateGenerator,
  creativesOptionsStateGenerator,
} from "./stateGenerators";

export const sagas = [
  createAdStateGenerator.saga,
  adOptionsStateGenerator.saga,
  creativesOptionsStateGenerator.saga,
];
