import React, { Component } from 'react';
import PropTypes from 'prop-types';
import enUS from 'antd/lib/locale-provider/en_US';
import { LocaleProvider } from 'antd';
import { Navbar, Footer, SideNav } from 'containers';
import { actions as userActions } from 'modules/user';
import { actions as labelActions } from 'modules/labels';
import { StateComponent, AlertListener } from 'HOCS';
import { Loading2, NotFound, Row } from 'v2-components';
import { ScrollContainer } from 'react-router-scroll';
import { htmlStrings } from 'utils/constants';
import {
  orgReadySelector,
  userSelectionLoadingSelector,
  isSettingsIframeSelect,
  isSettingsManageRouteSelector,
  isSettingsRouteSelector,
} from 'modules/user/selectors';
import { AdBlockContext, FlagContext, UserContext } from 'contexts';
import styles from './App.module.scss';
import { ApolloProvider } from 'react-apollo';
import { createApolloClient } from '../../gql';
import { connect } from 'react-redux';
import { GlobalStateProvider } from 'contexts/GlobalStateContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CubeProvider } from '@sixsense/core/cube';
import { CampaignCSVExportModal } from '../../aa-components';
import {
  ThemeProvider,
  SCOPED_DESIGN_SYSTEM_THEME,
  DESIGN_SYSTEM_ROOT_CLASS,
} from '@sixsense/core/style';
// eslint-disable-next-line max-len
import CampaignCSVExportDrawer from 'routes/AdvertisingDS/routes/Campaigns/containers/CampaignCSVExportDrawer/CampaignCSVExportDrawer';
import AppProviderWrapper from './AppProviderWrapper';
import { classNames } from 'utils/utils';
import { LogrocketInitializer } from 'lib/logrocket';
import Drawer from 'routes/Settings/components/Drawer';
import { includes } from 'lodash';
import { AUDIENCE_ROUTES } from 'routes/AudienceWorkflows/constants';

const AdBlockProvider = AdBlockContext.AdBlockProvider;

const { load: userLoadAction } = userActions;
const { loadLabels } = labelActions;

const apolloClient = createApolloClient();

// manages standard layout for each page
export class AppComponent extends Component {
  static propTypes = {
    children: PropTypes.element,
    components: PropTypes.arrayOf(PropTypes.element),
    pathname: PropTypes.string,
    orgReady: PropTypes.bool,
    userLoading: PropTypes.bool,
    userLoaded: PropTypes.bool,
    userLoad: PropTypes.func,
    labelsLoaded: PropTypes.bool,
    loadLabels: PropTypes.func,
    labelsLoading: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    appBodyPosition: PropTypes.string,
    isSettingsIframe: PropTypes.bool,
    isSettingsManageRoute: PropTypes.bool,
    isSettingsRoute: PropTypes.bool,
  }

  componentWillMount() {
    if (!this.props.userLoaded) {
      this.props.userLoad();
    }
    if (!this.props.labelsLoaded) {
      this.props.loadLabels();
    }
  }

  render() {
    const {
      userLoading,
      labelsLoading,
      components,
      isFullWidth,
      appBodyPosition,
      isSettingsIframe,
      isSettingsManageRoute,
      isSettingsRoute,
      pathname,
    } = this.props;

    const isSettingsApplyStyle = isSettingsRoute && !isSettingsManageRoute;
    const isAudienceWorkflowsRoute = includes(pathname, AUDIENCE_ROUTES.DEFAULT.PATH);
    const loading = userLoading || labelsLoading;
    const isIframeRoute = isAudienceWorkflowsRoute || isSettingsIframe;

    let content;
    if (loading) {
      content = <Loading2 size="large" />;
    } else {
      content = (
        <LocaleProvider locale={enUS}>
          <ScrollContainer scrollKey={'body'}>

            <Row
              flexDirection={Row.FLEX_DIRECTION.COLUMN}
              className={classNames(
                styles.appBody,
                isFullWidth ? styles.appBodyFullWidth : null,
                isAudienceWorkflowsRoute ? styles.appBodyFullHeight : null,
              )}
              style={{ position: appBodyPosition }}
              id="appBody"
            >
              <CampaignCSVExportModal />
              <ThemeProvider theme={SCOPED_DESIGN_SYSTEM_THEME} nonce={window.__CSP_NONCE__}>
                <div className={DESIGN_SYSTEM_ROOT_CLASS}>
                  <CampaignCSVExportDrawer />
                </div>
              </ThemeProvider>
              {this.props.children}
              {!isIframeRoute && <Footer />}
            </Row>

          </ScrollContainer>
        </LocaleProvider>
      );
    }

    const AlertedNavbar = AlertListener('navbar')(Navbar);
    const isNavigation = this.props.orgReady && !isSettingsIframe;
    const navigation = isNavigation ? (
      <div
        className={classNames(
          DESIGN_SYSTEM_ROOT_CLASS,
          styles.navigationContainer
        )}
      >
        <SideNav currentPath={pathname} />
      </div>
    ) : null;

    return (
      <DndProvider backend={HTML5Backend}>
        <CubeProvider apiUrl={`${window.location.origin}/cube`}>
          <ApolloProvider client={apolloClient}>
            <AdBlockProvider>
              <FlagContext>
                <UserContext>
                  <LogrocketInitializer>
                    <GlobalStateProvider>
                      <AppProviderWrapper>
                        <Row className={styles.appWrapper}>
                          <ThemeProvider
                            theme={SCOPED_DESIGN_SYSTEM_THEME}
                            nonce={window.__CSP_NONCE__}
                          >
                            {navigation}
                          </ThemeProvider>
                          <Drawer drawerType="overlay" isNavigation={isNavigation} />
                          <Row
                            className={styles.flexSet}
                            flexDirection={Row.FLEX_DIRECTION.COLUMN}
                          >
                            {!isSettingsIframe ? (
                              <header className={styles.header}>
                                <AlertedNavbar
                                  orgReady={this.props.orgReady}
                                  currentPath={this.props.pathname}
                                />
                              </header>
                            ) : null}
                            <Row
                              className={classNames(
                              styles.fireFoxFix,
                              isSettingsIframe
                                ? styles.containerSettingsIframe
                                : null
                            )}
                            >
                              <Row
                                className={classNames(
                                  styles.contentContainer,
                                  isSettingsIframe
                                    ? styles.containerSettingsIframe
                                    : null,
                                  isAudienceWorkflowsRoute
                                    ? styles.audienceContainer
                                    : null
                                )}
                                flexDirection={Row.FLEX_DIRECTION.COLUMN}
                              >
                                <div
                                  className={classNames(
                                    isSettingsApplyStyle
                                      ? styles.containerHeaderSettings
                                      : null
                                  )}
                                >
                                  {components}
                                </div>
                                {content}
                              </Row>
                            </Row>
                          </Row>
                        </Row>
                      </AppProviderWrapper>
                    </GlobalStateProvider>
                  </LogrocketInitializer>
                </UserContext>
              </FlagContext>
            </AdBlockProvider>
          </ApolloProvider>
        </CubeProvider>
      </DndProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  orgReady: orgReadySelector(state),
  userLoading: userSelectionLoadingSelector(state),
  pathname: state.route.locationBeforeTransitions.pathname,
  labelsLoading: state.labels.loading,
  userLoaded: state.user.loaded,
  labelsLoaded: state.labels.loaded,
  errorMessage: htmlStrings.TOP_LEVEL_ERROR,
  components: state.global.components,
  error: state.user.error || state.labels.error,
  isFullWidth: state.global.isFullWidth,
  appBodyPosition: state.global.appBodyPosition,
  isSettingsManageRoute: isSettingsManageRouteSelector(state),
  isSettingsIframe: isSettingsIframeSelect(state),
  isSettingsRoute: isSettingsRouteSelector(state),
});

export const App = connect(
  mapStateToProps,
  {
    userLoad: userLoadAction,
    loadLabels,
  }
)(StateComponent(AppComponent, NotFound));
