import React from 'react';
import { Checkbox2 } from 'v2-components';
import { COLUMN_CATEGORIES } from '../constants';
import { connect } from 'react-redux';
import {
  isCampaignSelectedSelector,
  isCurrentPageIndeterminateSelector,
  isCurrentPageSelectedSelector,
} from '../selectors';
import * as actions from '../actions';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { campaignListStateGenerator } from '../stateGenerators';
import styles from './ROW_SELECTION.module.scss';
import {
  CAMPAIGN_STATUS_X,
  CAMPAIGN_SOURCE,
} from 'routes/Advertising/routes/Campaigns/constants';
import { PROMISE_STATES } from 'modules/global/constants';
import { campaignClassificationDucks } from 'routes/Advertising/ducks';

const {
  constants: {
    CLASSIFICATION_TYPES,
  },
} = campaignClassificationDucks;

const SelectionComponent = ({
  isSelected,
  toggleCampaignListSelection,
  campaignId,
  campaignStatus,
  campaignSource,
  campaignClassificationType,
}) => (<Checkbox2
  size={Checkbox2.SIZE.LARGE}
  checked={isSelected}
  onChange={
    () => toggleCampaignListSelection({
      campaignId,
      campaignStatus,
      campaignSource,
      campaignClassificationType,
    })
  }
/>);
SelectionComponent.propTypes = {
  isSelected: PropTypes.bool,
  toggleCampaignListSelection: PropTypes.func.isRequired,
  campaignId: PropTypes.number.isRequired,
  campaignStatus: PropTypes.oneOf(Object.values(CAMPAIGN_STATUS_X)),
  campaignSource: PropTypes.oneOf(Object.values(CAMPAIGN_SOURCE)),
  campaignClassificationType: PropTypes.oneOf(Object.values(CLASSIFICATION_TYPES)),
};
const actionToProps = ({
  toggleCampaignListSelection: actions.campaignListRowSelection,
});
const stateToProps = (state, { campaignId }) => ({
  isSelected: isCampaignSelectedSelector(campaignId)(state),
});
const Selection = connect(stateToProps, actionToProps)(SelectionComponent);

const HeaderComponent = ({
  isSelected,
  isIndeterminate,
  campaignListPageSelection,
  campaignList,
  campaignListPromiseState,
}) => (
  <Checkbox2
    size={Checkbox2.SIZE.LARGE}
    disabled={campaignListPromiseState !== PROMISE_STATES.SUCCESS}
    checked={isSelected}
    indeterminate={isIndeterminate}
    onChange={() =>
      campaignListPageSelection({
        isSelected,
        campaignsOnPage: campaignList.map(
          ({ id, campaign_status, campaign_source, folder: { classification_type } }) => ({
            id,
            campaignStatus: campaign_status,
            campaignSource: campaign_source,
            campaignClassificationType: classification_type,
          })
        ),
      })
    }
    iconClassName={styles.checkBoxIcon}
  />
);
HeaderComponent.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  isIndeterminate: PropTypes.bool.isRequired,
  campaignListPageSelection: PropTypes.func.isRequired,
  campaignList: PropTypes.arrayOf(PropTypes.object),
  campaignListPromiseState: PropTypes.string.isRequired,
};
const headerStateToProps = (state) => ({
  isSelected: isCurrentPageSelectedSelector(state),
  isIndeterminate: isCurrentPageIndeterminateSelector(state),
  campaignList: get(campaignListStateGenerator.dataSelector(state), 'results', []),
  campaignListPromiseState: campaignListStateGenerator.promiseStateSelector(state),
});
const headerActionToProps = {
  campaignListPageSelection: actions.campaignListPageSelection,
};
const Header = connect(headerStateToProps, headerActionToProps)(HeaderComponent);

export const ROW_SELECTION = {
  category: COLUMN_CATEGORIES.hideForCustomize,
  fixed: 'left',
  customizable: false,
  key: 'row_selection',
  title: (<Header />),
  dataIndex: 'id',
  width: 36,
  render: (campaignId, { campaign_status, campaign_source, folder: { classification_type } }) => (
    <Selection
      campaignId={campaignId}
      campaignStatus={campaign_status}
      campaignSource={campaign_source}
      campaignClassificationType={classification_type}
    />
  ),
  csvOverrides: {
    hide: true,
  },
};
