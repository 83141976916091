import React from 'react';
import { PropTypes } from 'prop-types';
import { ActionItem, Tooltip, Text } from 'v2-components';

export const ArchiveCampaignActionItem = ({
  isDraftCampaign,
  isArchiveDisabled,
  onClick,
}) =>
  !isDraftCampaign
    ? (
      <ActionItem
        id="archive-campaign"
        key="archive-campaign"
        action={onClick}
        disabled={isArchiveDisabled}
        data-pendo="archiveCampaign"
      >
        <Tooltip
          overlay={
              isArchiveDisabled && (
                <Text color="white">
                  Please deactivate this campaign before archiving.
                </Text>
              )
            }
          placement="left"
        >
            Archive
          </Tooltip>
      </ActionItem>
    )
    : null;

ArchiveCampaignActionItem.propTypes = {
  isDraftCampaign: PropTypes.bool.isRequired,
  isArchiveDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ArchiveCampaignActionItem;
