import {
  ADVERTISING_BASE_ROUTE,
  ADVERTISING_CATEGORIES,
  AD_TYPE_FILTERS,
  AD_TYPE_IDS,
  AD_TYPE_LABELS,
  LINKEDIN_PREVIEW_OPTIONS,
  PAGE_NUMBER_SEARCH_PARAM,
  SELECTED_FOLDER_SEARCH_PARAM,
  STATIC_TREE_NODE_KEYS,
} from './constants';
import { CLASSIFICATION_TYPES, DEFAULT_FOLDERS } from './ducks/campaignClassifications/constants';
import { AD_LIBRARY_BASE_ROUTE } from './routes/AdLibrary/constants';
import { AD_LIST_BASE_ROUTE } from './routes/AdLibrary/routes/AdPlacementList/constants';
import { CAMPAIGNS_ROUTE } from './routes/Campaigns/constants';
import { CAMPAIGN_LIST_ROUTE } from './routes/Campaigns/routes/ListCampaigns/constants';
import {
  find as findObject,
  get,
  intersection,
  isArray,
  isBoolean,
  isEmpty,
  isEqual,
  mapValues,
  omit,
  pick,
  pickBy,
  some,
  uniqBy,
} from 'lodash';
import * as qs from 'qs';
import { browserHistory } from 'react-router';
import { generateQueryParams } from './routes/Campaigns/utils';
import { computePageStatus, computeRangeSelection } from '../../utils/utils';
import { LINKEDIN_PAGE_STATUS } from 'utils/constants';
import { CLICK_URL_FIELD } from './components/AdForms/components/ClickUrl';
import { TITLE_FIELD } from './components/AdForms/components/Title';
import { DESCRIPTION_FIELD } from './components/AdForms/components/Description';

export const pullUnSortedFolderForward = (folderList) => {
  const unsortedFolder = folderList
    .find(
      (folder) => folder.classification_type === DEFAULT_FOLDERS.CAMPAIGNS_UNSORTED
    );

  const sortedFolders = folderList
    .filter(
      (folder) => folder.classification_type !== DEFAULT_FOLDERS.CAMPAIGNS_UNSORTED
    )
    .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

  return [unsortedFolder, ...sortedFolders];
};

export const addKeyPrefix = (prefix, node) => ({
  ...node,
  key: `${prefix}/${node.key}`,
  ...(node.children ? { children: node.children.map((n) => addKeyPrefix(prefix, n)) } : {}),
});

export const getAdvertisingViewTypeFromFolderKey = (key) => key.split('/')[0];

export const computeAdsFiltersData = (filters, labelMap={}) => {
  const filterBy = mapValues(filters, (value, key) => {
    if (isArray(value)) {
      return value.map(({ value: v }) => v);
    } else if (isBoolean(value)) {
      return value ? [key]:[];
    }
    return [];
  });

  const filterState = mapValues(filters, (value, key) => {
    if (isArray(value)) {
      return value.reduce((filterObject, v) => ({ ...filterObject, [get(v, 'value')]: true }), {});
    } else if (isBoolean(value)) {
      return value ? { [key]: value }:{};
    }
    return [];
  });

  const allPossibleFilterOptions = Object.values(mapValues(filters, (value, key) => {
    if (isArray(value)) {
      return { label: '', filterKey: key, choices: value };
    } else if (isBoolean(value)) {
      return { label: '', filterKey: key, choices: [{ label: get(labelMap, key, key), value: key }],
      };
    }
    return [];
  }));

  return { filterBy, filterState, allPossibleFilterOptions };
};

export const getModifiedFilters = (filterState, filters) => {
  const updatedFilters = mapValues(filters, (value, key) => {
    if (isArray(value)) {
      return value.filter(({ value: v }) => get(filterState, `${key}.${v}`));
    } else if (isBoolean(value)) {
      return get(filterState, `${key}.${key}`, false);
    }
    return value;
  });
  return updatedFilters;
};

export const treeNodeKeysUtils = {
  separator: '/',
  addCategoryAsPrefix(prefix, key) { return `${prefix}${this.separator}${key}`; },
  extractCategoryFromPrefix(key) { return key.split(this.separator)[0]; },
  extractFolderId(key) { return key && key.split(this.separator)[1]; },
  getFolderObject(treeNodeKey, folderList) {
    const folderId = this.extractFolderId(treeNodeKey);

    if (STATIC_TREE_NODE_KEYS.CAMPAIGN_DRAFT_FOLDER === folderId) {
      return {
        classification_type: CLASSIFICATION_TYPES.CAMPAIGNS_DRAFT,
        name: 'Drafts',
      };
    }

    if (!isNaN(parseInt(folderId))) {
      return folderList.find(({ id }) => id === parseInt(folderId));
    }

    return null;
  },
};

export const addPrefixToTreeNodeKeys = (prefix, node) => ({
  ...node,
  key: treeNodeKeysUtils.addCategoryAsPrefix(prefix, node.key),
  ...(
    node.children ?
      { children: node.children.map((n) => addPrefixToTreeNodeKeys(prefix, n)) } :
      {}
  ),
});

export const isDirectoryTreeVisible = (testPathname) => [
  `/${ADVERTISING_BASE_ROUTE}/${AD_LIBRARY_BASE_ROUTE}/${AD_LIST_BASE_ROUTE}/`,
  `/${ADVERTISING_BASE_ROUTE}/${AD_LIBRARY_BASE_ROUTE}/${AD_LIST_BASE_ROUTE}`,
  `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGN_LIST_ROUTE}/`,
  `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGN_LIST_ROUTE}`,
].includes(testPathname);

export const genCampaignTreeNodeKey = (key) => treeNodeKeysUtils
  .addCategoryAsPrefix(ADVERTISING_CATEGORIES.CAMPAIGNS, key);
export const genAdLibraryTreeNodeKey = (key) => treeNodeKeysUtils
  .addCategoryAsPrefix(ADVERTISING_CATEGORIES.AD_LIBRARY, key);

export const syncSelectedFolderToSearchParams = (selectedFolderId) => {
  const prevSearchParamsObject = qs.parse(
    browserHistory.getCurrentLocation().search,
    { ignoreQueryPrefix: true }
  );
  const nextSearchParamsObject = pickBy({
    ...prevSearchParamsObject,
    [SELECTED_FOLDER_SEARCH_PARAM]: selectedFolderId,
  });

  if (isEqual(nextSearchParamsObject, prevSearchParamsObject) === false) {
    const nextSearchParams = generateQueryParams(nextSearchParamsObject);
    browserHistory.replace({
      pathname: browserHistory.getCurrentLocation().pathname,
      search: nextSearchParams,
    });
  }
};

export const getCampaignsFolderFromQuery = (queryParams, campaignFolders) => {
  const folderIdInQueryParams = get(queryParams, SELECTED_FOLDER_SEARCH_PARAM);
  const validCampaignFolder = campaignFolders.some(({ id }) =>
    genCampaignTreeNodeKey(id) === folderIdInQueryParams);
  const oneOfCampaignsStaticTreeNodes = [
    genCampaignTreeNodeKey(STATIC_TREE_NODE_KEYS.CAMPAIGN_DRAFT_FOLDER),
    genCampaignTreeNodeKey(STATIC_TREE_NODE_KEYS.CAMPAIGN_ALL),
  ].includes(folderIdInQueryParams);
  if (oneOfCampaignsStaticTreeNodes || validCampaignFolder) {
    return folderIdInQueryParams;
  }

  return null;
};

export const getAdLibraryFolderFromQuery = (queryParams, adLibraryFolders) => {
  const folderIdInQueryParams = get(queryParams, SELECTED_FOLDER_SEARCH_PARAM);
  const validAdLibraryFolder = adLibraryFolders.some(({ id }) =>
    genAdLibraryTreeNodeKey(id) === folderIdInQueryParams);
  const oneOfAdLibraryStaticTreeNodes = [
    genAdLibraryTreeNodeKey(STATIC_TREE_NODE_KEYS.AD_LIBRARY_ALL),
  ].includes(folderIdInQueryParams);
  if (oneOfAdLibraryStaticTreeNodes || validAdLibraryFolder) {
    return folderIdInQueryParams;
  }
  return null;
};

export const syncSearchParams = (filters, filterKeys) => {
  const prevSearchParams = qs.parse(
    browserHistory.getCurrentLocation().search,
    { ignoreQueryPrefix: true }
    );

  const nextFiltersSearchParams = filters;
  const allFiltersKeys = Object.values(filterKeys);
  const filtersSearchParamsToOmit = allFiltersKeys
    .filter((filterKey) => Object.keys(nextFiltersSearchParams).includes(filterKey) === false);

  const nextParamsObject = {
    ...omit(prevSearchParams, filtersSearchParamsToOmit),
    ...nextFiltersSearchParams,
  };
  const nextSearchParams = generateQueryParams(nextParamsObject);

  browserHistory.replace({
    pathname: browserHistory.getCurrentLocation().pathname,
    search: nextSearchParams,
  });
};

export const createIncrementedName = (allNames, newItemName) => {
  let foundUnique = false;
  let matchesFound = 0;
  let suggestedName = newItemName;
  const find = (query) => allNames.find((name) => name.toLowerCase() === query.toLowerCase());

  while (foundUnique === false) {
    suggestedName = matchesFound ? `${newItemName}(${matchesFound})` : newItemName;
    if (!find(suggestedName)) {
      foundUnique = true;
    } else {
      matchesFound += 1;
    }
  }
  return suggestedName;
};

export const computeMultiSelection = (
  selectedKeys,
  controlDown,
  shiftDown,
  latestTreeData,
  clickedNodeKey,
  lastClickedNodeKey,
  selections,
) => {
  const multiSelectBlackList = [
    genCampaignTreeNodeKey(STATIC_TREE_NODE_KEYS.CAMPAIGN_DRAFT_FOLDER),
    genCampaignTreeNodeKey(STATIC_TREE_NODE_KEYS.CAMPAIGN_ALL),
    genAdLibraryTreeNodeKey(STATIC_TREE_NODE_KEYS.AD_LIBRARY_ROOT),
    genAdLibraryTreeNodeKey(STATIC_TREE_NODE_KEYS.AD_LIBRARY_ALL),
  ];
  const multiSelectionNodesWhitelist = [
    genCampaignTreeNodeKey(STATIC_TREE_NODE_KEYS.CAMPAIGN_ALL),
    genAdLibraryTreeNodeKey(STATIC_TREE_NODE_KEYS.AD_LIBRARY_ALL),
  ];

  const oneOfBlackedListedSelected = intersection(
    multiSelectBlackList,
    selectedKeys,
  ).length > 0;
  const selectionContainsDifferentCategories = uniqBy(
    selectedKeys,
    (k) => treeNodeKeysUtils.extractCategoryFromPrefix(k)
  ).length !== 1;
  const discardPreviousSelection = some([
    oneOfBlackedListedSelected, selectionContainsDifferentCategories,
  ]);

  if (!discardPreviousSelection) {
    if (controlDown) {
      return selectedKeys;
    } else if (shiftDown) {
      const selectionPool = (() => {
        const findChildren = (node, nodeKeyToFind) => {
          if (node.key === nodeKeyToFind) {
            return node.children;
          }
          if (node.children) {
            return node.children.reduce(
              (acc, child) => {
                if (acc) return acc;

                return findChildren(child, nodeKeyToFind);
              },
              null,
            );

          }

          return null;
        };

        let allSiblings = null;
        let index = 0;
        while (!allSiblings) {
          const parentNodeKey = multiSelectionNodesWhitelist[index];
          const allChildren = findChildren({ children: latestTreeData }, parentNodeKey);
          const allChildrenKeys = allChildren.map((node) => node.key);
          if (allChildrenKeys.includes(clickedNodeKey)) {
            allSiblings = allChildrenKeys;
          }
          index++;
        }

        return allSiblings;
      })();

      const nextSelection = computeRangeSelection(
        selectionPool,
        clickedNodeKey,
        lastClickedNodeKey,
        selections
      );

      if (nextSelection.length !== 0) {
        return nextSelection;
      }
    }
  }

  return [clickedNodeKey];
};

export const syncPageNumberToSearchParams = (pageNumber) => {
  const prevSearchParams = browserHistory.getCurrentLocation().query;

  const relatedParams = pick(prevSearchParams, [PAGE_NUMBER_SEARCH_PARAM]);
  if (isEqual(relatedParams, { [PAGE_NUMBER_SEARCH_PARAM]: `${pageNumber}` })) {
    return;
  }

  prevSearchParams[PAGE_NUMBER_SEARCH_PARAM] = pageNumber;

  const nextSearchParams = generateQueryParams(prevSearchParams);

  browserHistory.replace({
    pathname: browserHistory.getCurrentLocation().pathname,
    search: nextSearchParams,
  });
};

export const pageNumberQueryParamsValidator = (params) => {
  const pageNumber = parseInt(get(params, PAGE_NUMBER_SEARCH_PARAM));

  if (pageNumber || pageNumber === 0) return pageNumber;

  return null;
};

export const callToActionOptionTransform = (optionChoices) => optionChoices
  .map(([value, label]) => ({
    value,
    label: label.replace('_', ' '),
  }));

export const linkedinAdPreviewOptions = (creativeDimensions) => {
  const creativeWidth = Number(creativeDimensions?.split('x')[0]);
  const creativeHeight = Number(creativeDimensions?.split('x')[1]);
  const isVerticalCreative = creativeHeight > creativeWidth;

  return [
    ...(!isVerticalCreative ? [LINKEDIN_PREVIEW_OPTIONS.desktop] : []),
    LINKEDIN_PREVIEW_OPTIONS.mobile,
  ];
};

export const validateLinkedinAdForEdit = (ad, linkedinPages) => {
  const associatedCampaigns = get(ad, 'campaigns', []);
  const tooltips = {
    [LINKEDIN_PAGE_STATUS.DISABLED]: 'You can not edit this ad since it is associated ' +
    'with a campaign whose LinkedIn page is disabled.',
    [LINKEDIN_PAGE_STATUS.NOT_APPROVED]: 'You can not edit this ad since it is associated ' +
    'with a campaign whose LinkedIn page is not approved.',
    [LINKEDIN_PAGE_STATUS.UNKNOWN]: 'You can not edit this ad since we couldn\'t fetch ' +
    'Poster Access from LinkedIn. Either try refreshing the page or contact Support!',
  };

  return associatedCampaigns.reduce((tooltip, campaign) => {
    if (tooltip) return tooltip;

    const pageId = get(campaign, 'linkedin_ads_page_id');
    const page = linkedinPages.find(({ id }) => id === pageId);

    return tooltips[computePageStatus(page)];
  }, null);
};

export const linkedinDisabledFieldsSubmittedCampaigns = {
  [AD_TYPE_IDS.linkedInSingleImageAd]: {
    [CLICK_URL_FIELD]: true,
    [TITLE_FIELD]: true,
    [DESCRIPTION_FIELD]: true,
  },
  [AD_TYPE_IDS.linkedInVideoAd]: {
    [CLICK_URL_FIELD]: true,
    [TITLE_FIELD]: true,
  },
};

export const isLinkedinAdFieldDisabled = (ad, fieldName) => {
  const isUsedInSubmittedCampaign = get(ad, 'is_used_in_submitted_campaign', false);
  const placementType = get(ad, 'placement_type');
  const isConcernedField = get(linkedinDisabledFieldsSubmittedCampaigns,
    [placementType, fieldName]);

  return isUsedInSubmittedCampaign && isConcernedField;
};

export const getAdTypeLabelAndLogo = (currentAdType) => {
  const adType = findObject(AD_TYPE_FILTERS, (item) => item.adTypes.includes(currentAdType));

  if (!isEmpty(adType)) {
    return {
      label: AD_TYPE_LABELS[currentAdType],
      icon: adType.icon,
    };
  }
  return {};
};
