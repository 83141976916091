import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Text, Input, Col, Link, Alert } from 'v2-components';
import styles from './NewCampaignModal.module.scss';
import { classNames } from 'utils/utils';
import backArrow from 'images/arrow_back.svg';
import LinkedInIcon from 'images/linkedin_hq.svg';
import { errors, decorators } from 'utils/validators';
import { reduxForm, Field, stopSubmit, isValid, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import {
  INVALID_CHARS,
  strings,
  CAMPAIGN_SOURCE,
  RETARGETING_CAMPAIGN_WARNING,
} from 'utils/constants';
import { get, some, debounce } from 'lodash';
import { CREATE_CAMPAIGN_FORM, CAMPAIGN_TYPES_CONFIG } from './constants';
import { connect } from 'react-redux';
import { orgFlagsSelector, orgSelector } from 'modules/user/selectors';
import { apiRequests } from './apiRequests';
import { browserHistory } from 'react-router';
import { CAMPAIGNS_ROUTE } from 'routes/Advertising/routes/Campaigns/constants';
import {
  CAMPAIGNS_CONFIGURATION_ROUTE,
  CONFIGURATION_MODES,
  LOCATION_STATE_KEYS as CONFIGURATION_LOCATION_STATE_KEYS,
} from 'routes/Advertising/routes/Campaigns/routes/CampaignConfiguration/constants';
import { ADVERTISING_BASE_ROUTE } from '../../routes/AdvertisingDS/constants';
import { isFeatureFlagEnabledForOrg } from 'routes/Advertising/featureGating/utils';
import { If } from 'babel-plugin-jsx-control-statements';
import { setSyncErrors } from 'store/actions';
import LinkedInNativeCampaign from './LinkedinCampaign';
import { isLinkedInCampaign } from 'routes/Advertising/routes/Campaigns/utils';
import { ReleaseTypeLogo } from 'aa-components/ReleaseLogos';

const FormInput = Input.form;
const SELECT_CAMPAIGN_TYPE_STEP = 'selectCampaignType';
const SELECT_CAMPAIGN_NAME_STEP = 'selectCampaignName';
const LINKEDIN_CAMPAIGN_STEP = 'linkedInCampaign';

const FIELDS = {
  NAME: 'name',
  TYPE: 'type',
};

const formLayout = { rightExtraSize: '0%', inputSize: '100%' };
const DEFAULT_SELECTED_CAMPAIGN = get(CAMPAIGN_TYPES_CONFIG, '[0].types[0]');

const InfoWithLearnMore = ({ info, learnMoreURL }) => (
  <Row className="aam-t--15">
    <Alert
      className={'aam-b--10'}
      type={'info'}
      showIcon
      description={
        <React.Fragment>
          <Text color={Text.COLOR.AA_GREY1}>{info}&nbsp;</Text>
          <If condition={learnMoreURL}>
            <Link link={learnMoreURL} newWindow>
              Learn more
            </Link>
          </If>
        </React.Fragment>
      }
    />
  </Row>
);

InfoWithLearnMore.propTypes = {
  info: PropTypes.string,
  learnMoreURL: PropTypes.string,
};


const CampaignTypeSelectionForm = ({ input, setActiveStep, orgFeatureFlags }) => {
  const [
    selectedCampaignTypeForDescription,
    setSelectedCampaignTypeForDescription,
  ] = useState(DEFAULT_SELECTED_CAMPAIGN);
  const selectedCampaignTypeIcon = get(selectedCampaignTypeForDescription, 'icon');
  const campaignDescriptionDetails = get(selectedCampaignTypeForDescription, 'details') || {};

  return (
    <Row className={styles.container}>
      <Row
        className={styles.campaignTypeContainer}
        flexDirection={Row.FLEX_DIRECTION.COLUMN}
      >
        {CAMPAIGN_TYPES_CONFIG.map(({ sectionLabel, types, subText }) => {
          const hasAccessToSomeCampaignTypes = some(
            types,
            ({ type }) => isFeatureFlagEnabledForOrg({ orgFeatureFlags, featureIdentifier: type })
          );
          return (<If condition={hasAccessToSomeCampaignTypes}>
            <Col key={sectionLabel}>
              <Col>
                <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
                  {sectionLabel ? <Text
                    className={styles.sectionLabel}
                    bold
                  >
                    {sectionLabel}
                  </Text>:null}
                  {subText ? <Text
                    className={styles.subText}
                  >
                    {subText}
                  </Text>:null}
                </Row>
                <div className={styles.sectionTiles}>
                  {types.map((config) => {
                    const {
                      name, description, icon, type,
                      actionBtnLabel, releaseType, dataPendo, releaseTypeCssClass,
                    } = config;
                    const isFeatureEnabled = isFeatureFlagEnabledForOrg({
                      orgFeatureFlags,
                      featureIdentifier: type,
                    });

                    return (isFeatureEnabled && <Row
                      key={type}
                      className={styles.cardWrapper}
                      onMouseOver={(e) => {
                        e.stopPropagation();
                        setSelectedCampaignTypeForDescription(config);
                        const debouncedSetDescription =
                          debounce(setSelectedCampaignTypeForDescription, 300);
                        debouncedSetDescription(config);
                      }}
                    >
                      <Row
                        alignItems={Row.ALIGN_ITEMS.FLEX_START}
                        justifyContent={Row.JUSTIFY_CONTENT.SPACE_BETWEEN}
                        className={classNames(styles.card, styles.cardOutline,
                      selectedCampaignTypeForDescription.name === name ? styles.selected :'')}
                      >
                        <Row
                          className={styles.campaignTypeInfo}
                          flexDirection={Row.FLEX_DIRECTION.COLUMN}
                          justifyContent={Row.JUSTIFY_CONTENT.SPACE_BETWEEN}
                        >
                          <Row
                            justifyContent={Row.JUSTIFY_CONTENT.SPACE_BETWEEN}
                            alignItems={Row.ALIGN_ITEMS.CENTER}
                          >
                            <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
                              <Text
                                className={releaseType ? 'aam-b--5' : styles.cardLabel}
                                color={Text.COLOR.AA_GREY}
                                bold
                              >
                                {name}
                              </Text>
                              <If condition={releaseType}>
                                <ReleaseTypeLogo
                                  releaseType={releaseType}
                                  className={
                                    releaseTypeCssClass
                                      ? styles[releaseTypeCssClass]
                                      : styles.releaseTypeLogo
                                  }
                                />
                              </If>
                            </Row>
                            <img
                              className={styles.icon}
                              // eslint-disable-next-line import/no-dynamic-require
                              src={require(`images/${icon}`)}
                              alt="Campaign type icon"
                            />
                          </Row>
                          <Text
                            className={styles.description}
                            color={Text.COLOR.AA_GREY1}
                          >
                            {description}
                          </Text>
                          <Row>
                            <Button
                              className={styles.actionBtnLabel}
                              type={Button.PRIMARY}
                              onClick={() => {
                                input.onChange(type);
                                if (type === CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING) {
                                  setActiveStep(LINKEDIN_CAMPAIGN_STEP);
                                } else {
                                  setActiveStep(SELECT_CAMPAIGN_NAME_STEP);
                                }
                              }}
                              htmlType="submit"
                              dataPendo={dataPendo}
                            >
                              {actionBtnLabel}
                            </Button>
                          </Row>
                        </Row>
                      </Row>
                    </Row>);
                  })
                }
                </div>
              </Col>
            </Col>
          </If>);
        }
      )}
      </Row>
      <Row className={styles.campaignDescriptionContainer}>
        <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
          <Row
            alignItems={Row.ALIGN_ITEMS.CENTER}
            className={styles.descriptionSection}
          >
            <img
              className={styles.icon}
              // eslint-disable-next-line import/no-dynamic-require
              src={require(`images/${selectedCampaignTypeIcon}`)}
              alt="Campaign type icon"
            />
            <Text
              className={styles.campaignType}
              bold
            >
              {get(selectedCampaignTypeForDescription, 'name')}
            </Text>
          </Row>
          <Row
            className={styles.descriptionSection}
            flexDirection={Row.FLEX_DIRECTION.COLUMN}
          >
            <Text
              className={styles.descriptionLabel}
              bold
            >
            Summary
          </Text>
            <Text
              color={Text.COLOR.AA_GREY}
            >
              {get(selectedCampaignTypeForDescription, 'details.summary')}
            </Text>
          </Row>
          <Row
            className={styles.descriptionSection}
            flexDirection={Row.FLEX_DIRECTION.COLUMN}
          >
            <Text
              className={styles.descriptionLabel}
              bold
            >
            Objective
          </Text>
            <Text
              color={Text.COLOR.AA_GREY}
            >
              {get(selectedCampaignTypeForDescription, 'details.objective')}
            </Text>
          </Row>
          <Row
            className={styles.descriptionSection}
            flexDirection={Row.FLEX_DIRECTION.COLUMN}
          >
            <Text
              className={styles.descriptionLabel}
              bold
            >
            Additional resources
          </Text>
            {get(selectedCampaignTypeForDescription, 'details.additionalResources', [])
              .map((resource) => (
                <Link
                  key={resource.urlText}
                  newWindow
                  link={resource.url}
                  onClick={resource.onClick}
                  className={styles.resourceLinks}
                >
                  {resource.urlText}
                </Link>
              ))
            }
          </Row>
          <If condition={get(campaignDescriptionDetails, 'note.info')}>
            <InfoWithLearnMore
              info={campaignDescriptionDetails.note.info}
              learnMoreURL={get(campaignDescriptionDetails, 'note.learnMoreURL')}
            />
          </If>
        </Row>
      </Row>
    </Row>
  );
};

CampaignTypeSelectionForm.propTypes = {
  input: PropTypes.object,
  setActiveStep: PropTypes.func,
  orgFeatureFlags: PropTypes.object,
};

const CampaignTypeSelectionComponent = ({
  closeModal,
  setActiveStep,
  orgFeatureFlags,
}) => (
  <form>
    <Field
      id="campaign_type"
      name={FIELDS.TYPE}
      type={'text'}
      component={CampaignTypeSelectionForm}
      setActiveStep={setActiveStep}
      required
      closeModal={closeModal}
      formLayout={formLayout}
      orgFeatureFlags={orgFeatureFlags}
    />
  </form>
  );

CampaignTypeSelectionComponent.propTypes = {
  closeModal: PropTypes.func,
  setActiveStep: PropTypes.func,
  orgFeatureFlags: PropTypes.object,
};

const CampaignTypeSelection = compose(
    reduxForm({
      form: CREATE_CAMPAIGN_FORM,
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true,
      initialValues: { [FIELDS.TYPE]: DEFAULT_SELECTED_CAMPAIGN.type },
    }),
)(CampaignTypeSelectionComponent);


const SelectCampaignNameComponent = ({
  closeModal,
  handleSubmit,
  submitting,
  handleNameChange,
  loading,
  disableSubmit,
  setApiRequestLoading,
  selectedCampaignType,
}) => {
  const debouncedSearch = useMemo(() => debounce(handleNameChange, 500), []);

  const handleInputChange = (e) => {
    setApiRequestLoading(Boolean(get(e, 'target.value')));
    debouncedSearch({ name: e.target.value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Col className={styles.campaignName}>
          <Field
            id="campaign_name"
            className={styles.campaignNameInput}
            placeholder={'Untitled campaign'}
            name={FIELDS.NAME}
            type="text"
            component={FormInput}
            required
            formLayout={formLayout}
            validate={[
              errors.isRequired,
              decorators.forceError(errors.maxLength(250)),
              decorators.forceError(errors.noSpecialCharAtStart(INVALID_CHARS)),
            ]}
            autoFocus
            onFocus={(e) => e.currentTarget.select()}
            onChange={handleInputChange}
          />
          <Text italic className={styles.instruction} color={Text.COLOR.AA_GREY1}>
          Press ”Enter” to continue
          </Text>
          <If condition={selectedCampaignType === CAMPAIGN_SOURCE.RETARGETING}>
            <InfoWithLearnMore
              info={RETARGETING_CAMPAIGN_WARNING.INFO}
              learnMoreURL={RETARGETING_CAMPAIGN_WARNING.LEARN_MORE_URL}
            />
          </If>
        </Col>
        <Row className={styles.footer} justifyContent={Row.JUSTIFY_CONTENT.FLEX_END}>
          <Button className={styles.buttonSpacing} onClick={closeModal}>Cancel</Button>
          <Button
            loading={submitting || loading} disabled={loading || submitting || disableSubmit}
            type={Button.PRIMARY} htmlType="submit"
          >
          Create campaign
        </Button>
        </Row>
      </div>
    </form>);
};

SelectCampaignNameComponent.propTypes = {
  closeModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  handleNameChange: PropTypes.func,
  loading: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  setApiRequestLoading: PropTypes.func,
  selectedCampaignType: PropTypes.string,
};

const SelectCampaignName = compose(
  reduxForm({
    form: CREATE_CAMPAIGN_FORM,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)(SelectCampaignNameComponent);

const NewCampaignModalComponent = (props) => {
  const {
    visible,
    closeModal,
    destroy: clearForm,
    orgId,
    stopFormSubmit,
    selectedFolderId,
    defaultCampaignType,
    orgFeatureFlags,
    extraRouteStateData = {},
    isFormValid,
    setFormError,
    selectedCampaignType,
  } = props;

  const [activeStep, setActiveStep] =
    useState(defaultCampaignType ? SELECT_CAMPAIGN_NAME_STEP : SELECT_CAMPAIGN_TYPE_STEP);
  const [apiRequestLoading, setApiRequestLoading] = useState(false);
  const [linkedInCampaign, setLinkedInCampaign] = useState({
    hasMultiplePages: null,
    selected_page_name: '',
    selected_page_id: null,
  });

  // To reset wizard form fields
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearForm, []);

  const onSubmit = ({ name: campaignName, type: campaignSource }) => {
    if (!apiRequestLoading) {
      closeModal();
      let state = {
        ...extraRouteStateData,
      };
      if (isLinkedInCampaign({ campaign_source: campaignSource })) {
        state = {
          [CONFIGURATION_LOCATION_STATE_KEYS.linkedinPageId]: linkedInCampaign.selected_page_id,
          ...state,
        };
      }
      browserHistory.push({
      // eslint-disable-next-line max-len
        pathname: `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGNS_CONFIGURATION_ROUTE}/${CONFIGURATION_MODES.create}/${campaignSource || defaultCampaignType}`,
        state: {
          [CONFIGURATION_LOCATION_STATE_KEYS.campaignName]: campaignName,
          [CONFIGURATION_LOCATION_STATE_KEYS.folderId]: selectedFolderId,
          ...state,
        },
      });
    }
  };

  const handleNameChange = ({ name }) => new Promise(async () => {
    if (name) {
      const { count } = await apiRequests.getMatchingCampaigns({ orgId, name });
      setApiRequestLoading(false);
      if (count) {
        setFormError(CREATE_CAMPAIGN_FORM,
          { name: { value: strings.NAME_EXISTS, forceShowError: true } });
        return;
      }
    }
    stopFormSubmit(CREATE_CAMPAIGN_FORM);
  });

  const titleRow = (
    <Row>
      <If
        condition={activeStep === SELECT_CAMPAIGN_NAME_STEP &&
          !defaultCampaignType && !linkedInCampaign.selected_page_id}
      >
        <img
          className={styles.backIcon}
          src={backArrow}
          alt="Back button"
          height={20}
          width={20}
          onClick={() => {
            setActiveStep(SELECT_CAMPAIGN_TYPE_STEP);
          }}
        />
      </If>
      <If
        condition={activeStep === LINKEDIN_CAMPAIGN_STEP ||
          activeStep === SELECT_CAMPAIGN_NAME_STEP && linkedInCampaign.selected_page_id}
      >
        <div className={styles.linkedInTitleRow}>
          <img
            className={styles.backIcon}
            src={backArrow}
            alt="Back button"
            height={20}
            width={20}
            onClick={() => {
              if (linkedInCampaign.hasMultiplePages) {
                setActiveStep(LINKEDIN_CAMPAIGN_STEP);
              } else {
                setActiveStep(SELECT_CAMPAIGN_TYPE_STEP);
              }
              setLinkedInCampaign({
                ...linkedInCampaign,
                selected_page_name: '',
                selected_page_id: null,
              });
            }}
          />
          <img
            className={styles.icon}
            src={LinkedInIcon}
            alt="LinkedIn icon"
          />
        </div>
      </If>
      <Text
        type={Text.TYPE.TITLE}
        weight={Text.WEIGHT.BOLD}
        className={activeStep !== SELECT_CAMPAIGN_NAME_STEP ? 'aam-l--10' : ''}
      >
        <If condition={activeStep === SELECT_CAMPAIGN_NAME_STEP}>
          <If condition={linkedInCampaign.selected_page_id}>
            <Text type={Text.TYPE.TITLE2}>
              {linkedInCampaign.selected_page_name}
            </Text>
            <br />
          </If>
          Give your campaign a name
        </If>
        <If condition={activeStep === SELECT_CAMPAIGN_TYPE_STEP}>
          Select Campaign Type
        </If>
        <If condition={activeStep === LINKEDIN_CAMPAIGN_STEP}>
          Select LinkedIn Page
          <br />
          <Text type={Text.TYPE.TITLE2}>
            Tell us which LinkedIn page you want to sponsor your content from
          </Text>
        </If>
      </Text>
    </Row>
  );

  return (<Modal
    title={titleRow}
    width={'80vw'}
    footer={null}
    visible={visible}
    onCancel={closeModal}
    bodyStyle={{ padding: '0px' }}
    destroyOnClose
  >
    {(() => {
      switch (activeStep) {
        case SELECT_CAMPAIGN_TYPE_STEP:
          return (<CampaignTypeSelection
            closeModal={closeModal}
            setActiveStep={setActiveStep}
            orgFeatureFlags={orgFeatureFlags}
          />);

        case SELECT_CAMPAIGN_NAME_STEP:
          return (<SelectCampaignName
            closeModal={closeModal}
            onSubmit={onSubmit}
            handleNameChange={handleNameChange}
            loading={apiRequestLoading}
            setApiRequestLoading={setApiRequestLoading}
            disableSubmit={!isFormValid}
            selectedCampaignType={selectedCampaignType}
          />);

        case LINKEDIN_CAMPAIGN_STEP:
          return (<LinkedInNativeCampaign
            closeModal={closeModal}
            setActiveStep={setActiveStep}
            setLinkedInCampaign={setLinkedInCampaign}
          />);

        default:
          return null
          ;
      }
    })()}
  </Modal>);
};

NewCampaignModalComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  destroy: PropTypes.func,
  orgId: PropTypes.number,
  stopFormSubmit: PropTypes.func,
  selectedFolderId: PropTypes.number,
  defaultCampaignType: PropTypes.string,
  extraRouteStateData: PropTypes.object,
  orgFeatureFlags: PropTypes.object,
  isFormValid: PropTypes.bool,
  setFormError: PropTypes.func,
  selectedCampaignType: PropTypes.string,
};

const NewCampaignModal = compose(
  reduxForm({
    form: CREATE_CAMPAIGN_FORM,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  connect(
    (state) => ({
      orgId: orgSelector(state),
      orgFeatureFlags: orgFlagsSelector(state),
      isFormValid: isValid(CREATE_CAMPAIGN_FORM)(state),
      selectedCampaignType: formValueSelector(CREATE_CAMPAIGN_FORM)(state, FIELDS.TYPE),
    }),
    {
      stopFormSubmit: stopSubmit,
      setFormError: setSyncErrors,
    }
  )
)(NewCampaignModalComponent);

export default NewCampaignModal;
