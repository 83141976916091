import {
  createRuleStateGenerator, deleteRuleStateGenerator,
  loadImpactedRulesStateGenerator, loadRecordCountsStateGenerator,
  loadRuleHistoryStateGenerator, loadRulesStateGenerator,
  updateRuleOrderStateGenerator,
  updateRuleStateGenerator,
} from '../stateGenerators';

export const ruleManagerSagas = [
  loadRulesStateGenerator.saga,
  createRuleStateGenerator.saga,
  updateRuleStateGenerator.saga,
  deleteRuleStateGenerator.saga,
  updateRuleOrderStateGenerator.saga,
  loadImpactedRulesStateGenerator.saga,
  loadRecordCountsStateGenerator.saga,
  loadRuleHistoryStateGenerator.saga,
];
