import React, { useState } from "react";
import Text from "v2-components/Text";
import { COLUMN_CATEGORIES } from "../constants";
import { CAMPAIGN_SOURCE } from "../../../constants";
import { NA } from "./common/NA";
import { Button, Link, Row } from "v2-components";
import Modal from "v2-components/Modal";
import styles from "./externalIds.module.scss";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { pxToRem } from "@sixsense/core/style";

// eslint-disable-next-line react/prop-types
const LocalModal = ({ children, content, title }) => {
  const [visible, setVisible] = useState(false);

  const renderFooter = <Button onClick={() => setVisible(false)}>OK</Button>;

  return (
    <span>
      <Modal
        title={title}
        visible={visible}
        width={250}
        onCancel={() => setVisible(false)}
        footer={renderFooter}
      >
        {content}
      </Modal>
      <div onClick={() => setVisible(true)}>{children}</div>
    </span>
  );
};

export const EXTERNAL_ID = {
  category: COLUMN_CATEGORIES.externalMedia,
  key: "external-id",
  csvColumnKey: "external_id",
  title: <Label name={CAMPAIGN_LABELS.externalId} />,
  dataIndex: "external_id",
  width: pxToRem(184),
  render: (externalIds, campaign) => {
    if (campaign.campaign_source !== CAMPAIGN_SOURCE.EXTERNAL) {
      return <NA />;
    }

    let externalIdDisplay = "-";
    if (externalIds) {
      if (externalIds.length > 1) {
        externalIdDisplay = (
          <Row>
            {externalIds[0]}
            &nbsp;
            <LocalModal
              content={
                <div className={styles.twoColumnGrid}>
                  {externalIds.slice(1).map((id) => (
                    <div key={id} className={styles.gridItem}>
                      {id}
                    </div>
                  ))}
                </div>
              }
              title={"External IDs"}
            >
              <Link onlyActiveOnIndex={false}>
                +{externalIds.length - 1} more
              </Link>
            </LocalModal>
          </Row>
        );
      } else {
        externalIdDisplay = externalIds[0];
      }
    }

    return <Text type={Text.TYPE.SUBBODY}>{externalIdDisplay}</Text>;
  },
};
