import React from 'react';
import PropTypes from 'prop-types';
import { Pill, Row, Text } from 'v2-components';
import styles from './../AdPreviewWrapper.module.scss';
import { VIDEO_DURATION } from 'utils/constants';

const AcceptedVideoDuration = (props) => {
  const {
    creativeDuration,
    customPillClass,
  } = props;

  const checkDuration = (duration) => {
    if (parseInt(duration) > 0 && parseInt(duration) <= 15000) {
      return VIDEO_DURATION[0];
    }
    if (parseInt(duration) > 15000 && parseInt(duration) <= 30000) {
      return VIDEO_DURATION[1];
    }
    return null;
  };

  const activePill = checkDuration(creativeDuration);
  const acceptedDurations = VIDEO_DURATION.map((videoDuration) => (
    <Pill
      key={`file-size-${videoDuration}`}
      notClickable
      inactive
      className={
        `${styles.pillClass}` +
        ` ${activePill === videoDuration ? styles.activePill : (customPillClass || '')}`
      }
    >
      {` ${videoDuration} sec`}
    </Pill>
  ));

  return (
    <React.Fragment>
      <Row>
        {acceptedDurations}
      </Row>
      <Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
        <Text type={Text.TYPE.BODY}>
        We recommend that your creative contains the skippable
        element if you want to serve video ads over 15 seconds in duration.
        </Text>
      </Row>
    </React.Fragment>
  );
};
AcceptedVideoDuration.propTypes = {
  creativeDuration: PropTypes.number,
  customPillClass: PropTypes.string,
};

export default AcceptedVideoDuration;
