/*
 * This is the main reducer for inbox. If you are working with new notification
 * types, their state should be handled by a corresponding reducer. This file
 * should only work with state that applies globally to the inbox. Follow the pattern
 * below with approval to set up your own subreducer.
 *
 * This pattern allows for highly specific and involved state operations on any future
 * notification types without impacting existing types. It also keeps type specific
 * state isolated within its own reducer.
*/
import { omit, invert, cloneDeep, filter, isEmpty } from 'lodash';
import {
  formatNotification,
  formatNotifications,
  temporaryHackTranslationToListShape,
} from './utils';
import {
  approvalReducer,
  approvalActions,
  approvalActionTypes,
} from './subreducers';

const approvalActionStrings = invert(approvalActionTypes);

const initialLoadingState = {
  loading: false,
  error: false,
  loaded: false,
};

const initialState = {
  ...initialLoadingState,
  notificationTypes: [],
  notifications: [],
  notificationDetails: {},
  dismissNotificationId: undefined,
  singleRequest: false,
};

const RESET_INBOX_STATE = 'INBOX/RESET_INBOX_STATE';
const resetInboxState = () => ({ type: RESET_INBOX_STATE });

const LOAD_NOTIFICATION_TYPES_SUC = 'INBOX/LOAD_NOTIFICATINO_TYPES';
const LOAD_NOTIFICATION_TYPES_FAIL = 'INBOX/LOAD_NOTIFICATION_TYPES_FAIL';
const loadNotificationTypesSuc = (notificationTypes) => ({
  type: LOAD_NOTIFICATION_TYPES_SUC,
  notificationTypes,
});
const loadNotificationTypeFail = () => ({ type: LOAD_NOTIFICATION_TYPES_FAIL });

const LOAD_SINGLE_NOTIFICATION_SUC = 'INBOX/LOAD_SINGLE_NOTIFICATION_SUC ';
const loadSingleNotificationSuccess = (notification) => ({
  type: LOAD_SINGLE_NOTIFICATION_SUC,
  notification,
});

const LOAD_SINGLE_NOTIFICATION_FAIL = 'INBOX/LOAD_SINGLE_NOTIFICATION_FAIL ';
const loadSingleNotificationFailure = () => ({
  type: LOAD_SINGLE_NOTIFICATION_FAIL,
  loaded: true,
  loading: false,
  error: true,
  singleRequest: true,
});

const LOAD_INBOX = 'INBOX/LOAD_INBOX';
export const LOAD_INBOX_SUC = 'INBOX/LOAD_INBOX_SUC';
const LOAD_INBOX_FAIL = 'INBOX/LOAD_INBOX_FAIL';

const loadInbox = (notificationId) => ({ type: LOAD_INBOX, loading: true, notificationId });
const loadInboxSuc = (response) => ({
  type: LOAD_INBOX_SUC,
  response,
});

const loadInboxFail = () => ({
  type: LOAD_INBOX_FAIL,
  loading: false,
  loaded: true,
  error: true,
});

const SELECT_NOTIFICATION = 'INBOX/SELECT_NOTIFICATION';
const selectNotification = (notificationId) => ({ type: SELECT_NOTIFICATION, notificationId });

const DISMISS_NOTIFICATION = 'INBOX/DISMISS_NOTIFICATION';
const DISMISS_NOTIFICATION_SUCCESS = 'INBOX/DISMISS_NOTIFIATION/SUCCESS';
const dismissNotification = (dismissNotificationId) => ({
  type: DISMISS_NOTIFICATION,
  dismissNotificationId,
});
const dismissNotificationSuc = () => ({ type: DISMISS_NOTIFICATION_SUCCESS });

const RETURN_TO_INBOX = 'INBOX/RETURN_TO_WHOLE_INBOX';
const returnToInbox = () => ({ type: RETURN_TO_INBOX, singleRequest: false });

// These actions are affecting top level state and thus must live here,
// not the subreducer.
const APPROVAL_SUCCESS = 'INBOX/APPROVAL/SUCCESS';
const APPROVAL_FAILURE = 'INBOX/APPROVAL/FAILURE';
const approvalSuccess = (notification) => ({ type: APPROVAL_SUCCESS, notification });
const approvalFailure = () => ({ type: APPROVAL_FAILURE });

function inboxReducer(state = initialState, action) {
  /*
   * Subreducer cases handled here:
  */
  if (action.type in approvalActionStrings) {
    const { notificationDetails, updatedBy } = state;
    return {
      ...state,
      notificationDetails: approvalReducer(notificationDetails, action, updatedBy),
    };
  }
  /* end subreducer checks */


  /*
   * Main inbox state handled here:
  */
  switch (action.type) {
    case SELECT_NOTIFICATION: {
      const { notificationId } = action;
      const { notifications, notificationDetails } = state;
      const notification = cloneDeep(notifications[notificationId]);

      // do not reset to the initial notification state if the selected ID
      // is already the current selected notification, otherwise the
      // notification detail specific state will be erased
      if (notificationId === notificationDetails.id) {
        return state;
      }

      return {
        ...state,
        notificationDetails: notification,
      };
    }

    case LOAD_SINGLE_NOTIFICATION_SUC: {
      const { notificationTypes } = state;
      const { notification } = action;
      return {
        ...state,
        notifications: temporaryHackTranslationToListShape(notification, notificationTypes),
        loaded: true,
        loading: false,
        singleRequest: true,
      };
    }
    case LOAD_INBOX_SUC: {
      const { notificationTypes, notificationDetails } = state;
      const { response } = action;
      const { results: notifications } = response;

      const formattedNotifications = formatNotifications(
        filter(
          notifications,
          // temporary...
          (v) => {
            const hasOrcMeta = v?.info?.approvalRequest?.orcNotificationMeta;
            return hasOrcMeta;
          },
        ),
        notificationTypes,
      );

      // notification detail state needs to get reset to empty in the scenario of
      // reloading the list from having viewed a single notification. The single
      // notification api can return results that aren't necessarily in the list.
      // The list api call only fetches notifications in a certain state, so closed
      // notifications don't show up. Closed notifications could be viewed(individually)
      // only through links from other places - particularly orchestrations web engagement
      // orchestration history page. See the condition we're accounting for on the video
      // attached to this ticket: https://6sense.atlassian.net/browse/ORCH-2098
      return {
        ...state,
        loading: false,
        loaded: true,
        notifications: formattedNotifications,
        notificationDetails: isEmpty(formattedNotifications) ? {} : notificationDetails,
      };
    }
    case DISMISS_NOTIFICATION_SUCCESS: {
      const { dismissNotificationId, notifications } = state;

      return {
        ...state,
        notifications: omit(notifications, dismissNotificationId),
        notificationDetails: {},
        dismissNotificationId: undefined,
      };
    }
    case LOAD_NOTIFICATION_TYPES_SUC:
    case RETURN_TO_INBOX:
    case DISMISS_NOTIFICATION:
    case LOAD_INBOX_FAIL: {
      return { ...state, ...omit(action, 'type') };
    }
    case LOAD_INBOX: {
      return { ...state, ...omit(action, ['type', 'notificationId']) };
    }
    case APPROVAL_SUCCESS: {
      const { notification } = action;
      const { notifications, notificationTypes, notificationDetails } = state;

      const updatedNotification = formatNotification(
        { ...notificationDetails, ...notification },
        notificationTypes,
      );

      return {
        ...state,
        notifications: {
          ...notifications,
          [notification.id]: updatedNotification,
        },

        // for now...
        notificationDetails: cloneDeep(updatedNotification),
      };
    }
    case RESET_INBOX_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}

export const actions = {
  resetInboxState,
  loadNotificationTypesSuc,
  loadNotificationTypeFail,
  loadSingleNotificationSuccess,
  loadSingleNotificationFailure,
  loadInbox,
  loadInboxSuc,
  loadInboxFail,

  selectNotification,

  dismissNotification,
  dismissNotificationSuc,

  returnToInbox,

  approvalSuccess,
  approvalFailure,

  ...approvalActions,
};

export const actionTypes = {
  LOAD_NOTIFICATION_TYPES_SUC,
  LOAD_INBOX,
  LOAD_INBOX_SUC,
  LOAD_INBOX_FAIL,
  SELECT_NOTIFICATION,
  DISMISS_NOTIFICATION,
  DISMISS_NOTIFICATION_SUCCESS,
  RETURN_TO_INBOX,
  APPROVAL_SUCCESS,
  ...approvalActionTypes,
};

export default inboxReducer;
