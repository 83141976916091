import { Flex } from '@sixsense/core/components';
import React from 'react';
import { css } from 'styles/emotion';
import { Checkbox2, Icon, ShowHideUnder, Text } from 'v2-components';
import { AAColor } from '@sixsense/core/style';


const styles = {
  disabled: css({
    cursor: 'not-allowed',
  }),
  wrapper: css({
    display: 'inline-block',
  }),
  menuContainer: css({
    background: '#fff',
    fontSize: 14,
    padding: '8px 0',
    lineHeight: '20px',
    color: '#001F32',
    fontWeight: 'normal',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.2)',
    maxHeight: 400,
    minWidth: '100%',
    width: '175px',
    zIndex: 20,
    right: 0,
    border: `1px solid ${AAColor.GREY3}`,
    marginTop: 1,
  }),
  container: css({
    height: '36px',
    border: `1px solid ${AAColor.GREY3}`,
    backgroundColor: `${AAColor.WHITE}`,
    marginLeft: 10,
    alignItems: 'center',
    padding: '0 15px 0 10px',
    borderRadius: '2px',
    cursor: 'pointer',
  }),
  icon: css({
    marginRight: '5px',
  }),
  dropdown: css({
    margin: '10px 0 0 20px',
  }),
  title: css({
    marginBottom: 25,
  }),
  label: css({
    fontSize: 14,
  }),
  filterNumber: css({
    marginLeft: 5,
    border: `1px solid ${AAColor.ERROR}`,
    padding: '1px 4px',
    borderRadius: '8px',
    background: `${AAColor.ERROR}`,
    color: `${AAColor.WHITE}`,
  }),
};

type Props = {
  config: any;
  setConfig: any;
  disabled: boolean;
  extraClass?: any;
};

const FilterBy = ({ config, setConfig, disabled, extraClass }: Props) => {

  const handleClick = (checked, value) => {
    if (checked) {
      setConfig({
        ...config,
        filters: { ...config.filters, filterBy: [...config.filters.filterBy].concat(value) },
      });
    } else {
      setConfig({
        ...config,
        filters: {
          ...config.filters, filterBy: [...config.filters.filterBy].filter((v) => v !== value),
        },
      });
    }
  };

  const showFilterNumber = config.filters.filterBy.length > 0;

  return (
    <span className={styles.wrapper}>
      <ShowHideUnder
        useDocument
        containerClassName={`${styles.menuContainer} ${disabled && styles.disabled}`}
        Toggle={({ showFn }) => (
          <Flex
            className={`${styles.container} ${extraClass}`}
            onClick={disabled ? undefined : showFn}
          >
            <Icon className={styles.icon} type="filter_list" />
            <Text color={Text.COLOR.GREY1}>Filter By</Text>
            {showFilterNumber && (
              <Text type={Text.TYPE.MINI} className={styles.filterNumber}>
                1
              </Text>
            )}
          </Flex>
        )}
        ToShow={({ showFn }) => (
          <Flex className={styles.dropdown} direction="column">
            <Text className={styles.title} bold type={Text.TYPE.TITLE}>Filter By</Text>
            <Flex style={{ marginBottom: 20 }}>
              <Checkbox2
                size={Icon.SIZE.LARGE}
                checked={config.filters.filterBy.includes('ungrouped')}
                onChange={(e) => {
                  handleClick(e.target.checked, 'ungrouped');
                  showFn();
                }}
              />
              <Text className={styles.label}>Ungrouped</Text>
            </Flex>
          </Flex>
        )}
      />
    </span>
  );

};

export default FilterBy;
