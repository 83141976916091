import { Badge, Flex, Skeleton, Text } from '@sixsense/core/components';
import { ChevronRight, CornerDownRight } from '@sixsense/core/icons';
import { DesignSystemColor } from '@sixsense/core/style';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { memo } from 'react';
import { browserHistory } from 'react-router';
import { EVENT } from '../../../constants';

import NodeContainer from './NodeContainer';

const subTypeTextMap = {
  Intent: 'Intent',
  Reach: 'Reach',
  null: 'Not used in Models',
};

const getTitleComponent = (title) => {
  if (Array.isArray(title)) {
    const key = title.join(',');
    return (
      <Flex key={key} gap="4px">
        {title.map((item, index) => (
          <React.Fragment>
            {!!index && <CornerDownRight />}
            <Text.Body weight="semibold" color={DesignSystemColor.Gray.DARK_4}>
              {item}
            </Text.Body>
          </React.Fragment>
        ))}
      </Flex>
    );
  }
  return (
    <Text.Body weight="semibold" color={DesignSystemColor.Gray.DARK_4}>
      All Products
    </Text.Body>
  );
};

const MappedChildNode = ({ data, isConnectable }) => {
  const loading = false;
  const {
    usedInModels,
    subType,
    title,
    activityCount,
    percentage,
    taxonomyType,
  } = data;
  const titleComp = getTitleComponent(title);
  let BadgeComponent = <div />;
  if (taxonomyType === EVENT) {
    BadgeComponent = usedInModels ? (
      <Badge color={subType === 'Intent' ? 'primary' : 'success'}>
        {subTypeTextMap[subType]}
      </Badge>
    ) : (
      <Badge color={'gray'}>{subTypeTextMap[subType]}</Badge>
    );
  }
  return (
    <NodeContainer data={data} isConnectable={isConnectable}>
      <Flex
        direction="column"
        style={{
          margin: '0',
          width: '350px',
          borderRadius: '8px',
          background: '#FFFFFF',
          padding: '16px 12px 16px 12px',
          boxShadow: '0px 2px 4px -2px #10182514, 0px 4px 8px -2px #10182533',
        }}
      >
        <Flex justifyContent="space-between" alignItems="center">
          {titleComp}
          <Flex>
            {/* <Tooltip
            overlay={<Flex direction="column">
              <Text.RegularText weight="bold">{title}</Text.RegularText>
              <Text.RegularText>&nbsp;{data.definition}</Text.RegularText>
            </Flex>
            } placement="top"
          >
            <InfoCircle />
          </Tooltip> */}
            <ChevronRight
              onClick={() =>
                browserHistory.push({
                  pathname:
                    '/settings/taxonomyV2/mapping-review/' +
                    `${data.dataSource}/${data.taxonomyType}`,
                  search: `?${qs.stringify({
                    qcfilters: data.qcfilters,
                  })}`,
                })
              }
              style={{ cursor: 'pointer' }}
            />
          </Flex>
        </Flex>
        <Flex>
          {loading ? (
            <Skeleton
              style={{ height: 20, width: 100 }}
              variant="rectangular"
            />
          ) : (
            <React.Fragment>
              <Text.RegularText>
                {Intl.NumberFormat('en-US').format(activityCount || 0)}
              </Text.RegularText>
              {taxonomyType === EVENT && (
                <Text.RegularText>&nbsp;({percentage}%)</Text.RegularText>
              )}
            </React.Fragment>
          )}
        </Flex>
        <Flex className="aam-t--10">{BadgeComponent}</Flex>
      </Flex>
    </NodeContainer>
  );
};

MappedChildNode.propTypes = {
  data: PropTypes.any,
  isConnectable: PropTypes.bool,
};

export default memo(MappedChildNode);
