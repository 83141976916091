import { getAsyncInjectors } from 'store/utils';
import { AD_EDIT_BASE_ROUTE } from './constants';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: `${AD_EDIT_BASE_ROUTE}/:id`,
    name: 'editAdPlacement',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('editAdPlacement', reducer.default);
        injectSagas('editAdPlacement', sagas.default);
        cb(null, containers.EditAdPlacement);
      }, 'edit_ad_placement');
    },
  };
}
