import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid/lib';

import { Tooltip } from 'antd';
import { Text, Icon } from 'v2-components';

import styles from './KeyPoint.module.scss';
import { COLORS } from '../../v2-styles/constants';

function KeyPoint(props) {
  const {
    id,
    tooltip,
    onClick,
    className,
    type,
    change,
    trend,
    children,
    label,
    labelColor,
    middleComponent,
    rightComponent,
    alignTop,
    alignBottom,
    topRowClassName,
    bottomRowClassName,
    numbered,
    link,
  } = props;

  let right = null;
  let middle = null;
  let left = (
    <Text type="title" className={styles.content}>
      {children}
    </Text>
  );

  let keyPointLabel = (
    <Text
      type={Text.TYPE.SUBBODY}
      color={labelColor || COLORS.GREY1}
      className={styles.keyPointLabel}
    >
      {label}
    </Text>
  );

  let bottomRowAlignment = alignBottom;
  const topRowAlignment = alignTop;

  // Status summary key point
  let statusIcon = 'keyboard_arrow_up';
  let iconColor = Icon.COLORS.ACTIVE;

  switch (type) {
    case 'stats':
      keyPointLabel = <Text>{label}</Text>;
      left = (
        <Text
          color={link ? COLORS.BLUE : undefined}
          type={Text.TYPE.TITLE}
          className={`
            ${styles.stats}
            ${link && styles.hover}
          `}
        >
          {children}
        </Text>
      );
      break;
    case 'accountStat':
      keyPointLabel = (
        <Text
          type={Text.TYPE.SUBBODY}
          color={COLORS.GREY1}
          className={styles.accountStat}
        >
          {label}
        </Text>
      );
      left = (
        <Text
          weight={'bold'}
          type={Text.TYPE.TITLE}
          className={`${styles.stats}${styles.accountStat}`}
        >
          {children}
        </Text>
      );
      bottomRowAlignment = 'bottom';
      break;
    case 'label':
      break;
    case 'status':
      bottomRowAlignment = 'middle';
      statusIcon = trend !== 'positive' ? 'keyboard_arrow_down' : statusIcon;
      iconColor = trend !== 'positive' ? iconColor = Icon.COLORS.ERROR : iconColor;
      middle = <Icon type={statusIcon} color={iconColor} className={styles.statusIcon} />;
      right = (
        <Text type="body" className={styles.statusLabel}>
          {change}
        </Text>
      );
      break;
    case 'dashboardLink':
      keyPointLabel = (
        <div className={`${styles.dashboardUrl} ${onClick ? styles.hover : ''}`}>
          <Text color={onClick ? COLORS.BLUE : COLORS.DARK_GREY}>
            {label}
          </Text>
        </div>
      );
      left = numbered
        ? <Text className={styles.numbered}>{middleComponent}</Text>
        : null;
      middle = <Text color={COLORS.BLUEGREY}>{children}</Text>;
      break;
    case 'smallStat':
      keyPointLabel = <Text type={Text.TYPE.SUBBODY} color={COLORS.GREY1}>{label}</Text>;
      left = <Text weight={Text.WEIGHT.BOLD}>{children}</Text>;
      bottomRowAlignment = 'bottom';
      break;
    default: // You should probably make a new case if you are relying on this
      keyPointLabel = typeof label === 'string' ? keyPointLabel : label;
      left = typeof children === 'string' ? left : children;
      middle = middleComponent;
      right = rightComponent;
      break;
  }


  const topRow = {
    [topRowAlignment]: 'xs',
    style: !topRowClassName ? { margin: '0px', padding: '0px' } : {},
  };

  const bottomRow = {
    [bottomRowAlignment]: 'xs',
    style: !bottomRowClassName ? { margin: '0px', padding: '0px' } : {},
  };

  return (
    <div className={className} id={id} onClick={() => onClick && onClick(id)}>
      <Tooltip title={tooltip}>

        <Row {...topRow} className={topRowClassName}>
          <Col>
            {keyPointLabel}
          </Col>
        </Row>

        <Row {...bottomRow} className={bottomRowClassName}>
          <Col>
            {left}
          </Col>
          <Col>
            {middle}
          </Col>
          <Col>
            {right}
          </Col>
        </Row>

      </Tooltip>
    </div>
  );
}

const STRING_ELEMENT = [PropTypes.string, PropTypes.element];

KeyPoint.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  children: PropTypes.oneOfType(STRING_ELEMENT.concat([PropTypes.number, PropTypes.node])),
  className: PropTypes.string,
  type: PropTypes.oneOf(['status', 'label', 'stats', 'accountStat', 'dashboardLink', 'smallStat']),
  change: PropTypes.string,
  trend: PropTypes.oneOf(['positive', 'negative']),
  label: PropTypes.oneOfType(STRING_ELEMENT),
  middleComponent: PropTypes.oneOfType(STRING_ELEMENT),
  rightComponent: PropTypes.oneOfType(STRING_ELEMENT),
  alignTop: PropTypes.oneOf(['start', 'end', 'between', 'bottom', 'middle']),
  alignBottom: PropTypes.oneOf(['start', 'end', 'between', 'bottom', 'middle']),
  bottomRowClassName: PropTypes.string,
  topRowClassName: PropTypes.string,
  numbered: PropTypes.bool,
  link: PropTypes.bool,
  labelColor: PropTypes.string,
};

// label is always the label in the top row
// children are always the leftmost component in the bottom row

KeyPoint.defaultProps = {
  alignTop: 'start',
  alignBottom: 'start',
  className: '',
};

KeyPoint.STATUS = 'status';
KeyPoint.LABEL = 'label';
KeyPoint.STATS = 'stats';
KeyPoint.ACCOUNT_STAT = 'accountStat';
KeyPoint.DASHBOARD_LINK = 'dashboardLink';
KeyPoint.SMALL_STAT = 'smallStat';

export default KeyPoint;
