import React from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, filter } from 'lodash';
import { Modal, Text, Col, Checkbox2 as Checkbox, Button } from 'v2-components';
import styles from './PublishSegmentModal.module.scss';
import {
  TAG_OPTIONS,
  SALES_INTELLIGENCE,
  ORCHESTRATION,
  GTM,
  GTM_INFO,
  SIXSENSE_APIS,
} from '../../routes/Settings/routes/PublishedSegments/constants';

export const PublishSegmentModal = (props) => {
  const {
    visibility,
    dismiss,
    tags,
    checkUncheckForm,
    onSubmit,
    hasPredictive,
    isSFDCSynced,
    hasEssentials,
    publishedSegmentTags,
    isGTM,
    publishedSegmentsOnlyInSixSenseAPI,
  } = props;

  const publishButtonDisabled = isEmpty(filter(tags, (tag) => tag.isSelected === true));
  const renderFooter = [
    <div className={styles.header}>
      <Button
        onClick={dismiss}
        className={styles.cancelButton}
      >
        Cancel
      </Button>
      <Button
        onClick={() => onSubmit()}
        type="primary"
        disabled={publishButtonDisabled}
      >
        Publish Segment
      </Button>
    </div>,
  ];

  const formCheckboxes = map(tags, (tag) => {
    const disabled = ((tag.name === SALES_INTELLIGENCE && !hasPredictive) ||
      (tag.name === ORCHESTRATION && !hasEssentials && !isSFDCSynced)) ||
      (tag.name === SIXSENSE_APIS && !publishedSegmentsOnlyInSixSenseAPI);
    const filteredTags = publishedSegmentTags?
        publishedSegmentTags.filter((val) => !val.is_deleted) : [];
    const hasBeenPublishedOrUnpublished = filteredTags?
    filteredTags.some((value) => value.segment_tag_id === tag.id) : false;

    return (
      <Checkbox
        iconClassName={styles.icon}
        containerClassName={styles.modalRow}
        label={TAG_OPTIONS.find((entry) => entry.value === tag.name).label}
        checked={tag.isSelected || hasBeenPublishedOrUnpublished}
        disabled={disabled || hasBeenPublishedOrUnpublished}
        key={tag.id}
        onChange={() => {
          if (!isGTM && tag.name === GTM) {
            Modal.prompt({
              type: Modal.PROMPT_TYPES.INFO,
              title: 'Ineligible Segment',
              content: GTM_INFO,
            });
          } else {
            checkUncheckForm(tag.id);
          }
        }}
      />
    );
  });

  return (
    <Modal
      title="Select Use Cases for Published Segment"
      visible={visibility}
      onCancel={dismiss}
      footer={renderFooter}
      width={500}
    >
      <Col className={styles.modalContent}>
        <Text bold>Select the relevant use cases for this Segment:</Text>
        {formCheckboxes}
      </Col>
    </Modal>
  );
};

PublishSegmentModal.propTypes = {
  visibility: PropTypes.bool,
  dismiss: PropTypes.func,
  tags: PropTypes.any,
  checkUncheckForm: PropTypes.func,
  onSubmit: PropTypes.func,
  hasPredictive: PropTypes.bool,
  isSFDCSynced: PropTypes.bool,
  hasEssentials: PropTypes.bool,
  publishedSegmentTags: PropTypes.array,
  isGTM: PropTypes.bool,
  publishedSegmentsOnlyInSixSenseAPI: PropTypes.bool,
};


export default PublishSegmentModal;
