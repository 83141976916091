export default function createRoutes() {
  return {
    path: 'source',
    name: 'source',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('../../containers/SourceList/containers');
          cb(null, containers.SourceList);
        },
        'settings_standardization_source'
      );
    },
  };
}
