import { loadProductTagMappingGenerator } from '../../stateGenerators';
import { fetchSaga } from 'store/sagas';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { orgSelector, orgPropertiesSelector } from 'modules/user/selectors';
import { productURLsSelector, productKeywordsSelector } from './selectors';
import {
  originalKeywordsSelector,
  originalProductURLsListSelector,
} from '../../selectors';
import { toaster } from '@sixsense/core';
import { concat, forEach, get, groupBy } from 'lodash';
import {
  NOT_CONFIGURED,
  PRODUCT_CONFIG_PROPERTY_TYPE,
  KEYWORDS_SUBMITTED,
  URLS_SUBMITTED,
  URLS_SUBMITTED_STEP,
  KEYWORDS_SUBMITTED_STEP,
} from './constants';

import { actionTypes, actions } from './modules';

const {
  SAVE_URLS_REQUEST,
  SAVE_KEYWORDS_REQUEST,
  INITIALIZE_URLS,
  INITIALIZE_VALIDATION_URLS_REQUEST,
  VALIDATE_URLS_REQUEST,
} = actionTypes;

const DEFAULT_KEYWORD_WEIGHTS = {
  strong: 0.75,
  moderate: 0.5,
  weak: 0.25,
};

function* saveUrlsSaga(request) {
  const org = yield select(orgSelector);
  const urlMappings = yield select(productURLsSelector);
  // const editState = yield select(editStateSelector);
  const propertyEndpoint = `organization/${org}/properties/update/`;
  const scrapydEndpoint = `turbotax/taxonomy/org/${org}/turbotax/submit_urls/`;
  // const resetModelEndpoint = `turbotax/taxonomy/org/${org}/turbotax/reset_product_model/`;
  // const validateUrlEndPoint = `turbotax/taxonomy/org/${org}/turbotax/validate_urls/`;
  /*
    This saga accomplishes the following:
      1. Delete any old URL tags (if the customer is editing configuration)
      2. Submit the configured urls to trigger scrapyd web scraping
      3. Change the org property for the product taxonomy state to "urls_submitted"
      4. If the user is editing the configuration, hit the taxonomy endpoint to reset
        product model/keywords.
  */
  const urlData = [];
  let validatingURL;
  try {
    for (const mapping of urlMappings) {
      // Add urls to scraping request body
      urlData.push({
        product: mapping.product,
        urls: mapping.urls.map((v) => v.url),
      });

      // Validate the URLs
      // for (const { url } of mapping.urls) {
      //   validatingURL = url;
      //   yield call(request, validateUrlEndPoint, 'POST', { body: JSON.stringify([url]) });
      // }
    }

    validatingURL = null;

    // // Submit scraping request
    yield call(request, scrapydEndpoint, 'POST', {
      body: JSON.stringify(urlData),
    });
    // // Reset keywords/model if the user is editing configuration
    // // if (editState !== null) {
    // //   yield call(request, resetModelEndpoint, 'POST', { body: JSON.stringify([]) });
    // // }
    // // Update the org property.
    const propertyData = {
      property_type_id: PRODUCT_CONFIG_PROPERTY_TYPE,
      choice: URLS_SUBMITTED,
    };
    yield call(request, propertyEndpoint, 'PUT', {
      body: JSON.stringify(propertyData),
    });
    toaster.showSuccess('URLs submitted successfully.');
    yield put(actions.setCurrentStep(URLS_SUBMITTED_STEP));
    yield put(actions.setCurrentTaxonomyState(URLS_SUBMITTED));
    yield put(loadProductTagMappingGenerator.loadAction());
    yield put(actions.saveURLSuccess());
  } catch (e) {
    let message = 'Unable to submit URLs at this time. Please try again later';

    if (validatingURL) {
      message = `Validation failed for URL: '${validatingURL}'. Please try again.`;
    }

    toaster.showError(message);
    yield put(actions.saveURLFailure());
  }
}

const createKeywordsRequest = (product, strong, moderate, weak) => {
  const body = { product };
  const keywordsData = [];
  const weights = [];
  for (const keyword of strong) {
    keywordsData.push(keyword.value);
    const weight =
      keyword.updated || keyword.id === null
        ? DEFAULT_KEYWORD_WEIGHTS.strong
        : keyword.weight;
    weights.push(weight);
  }
  for (const keyword of moderate) {
    keywordsData.push(keyword.value);
    const weight =
      keyword.updated || keyword.id === null
        ? DEFAULT_KEYWORD_WEIGHTS.moderate
        : keyword.weight;
    weights.push(weight);
  }
  for (const keyword of weak) {
    keywordsData.push(keyword.value);
    const weight =
      keyword.updated || keyword.id === null
        ? DEFAULT_KEYWORD_WEIGHTS.weak
        : keyword.weight;
    weights.push(weight);
  }
  body.tags = keywordsData;
  body.weights = weights;
  return [body];
};

function* saveKeywordsSaga(request) {
  const org = yield select(orgSelector);
  const keywordMappings = yield select(productKeywordsSelector);
  const propertyEndpoint = `organization/${org}/properties/update/`;
  const tagsEndpoint = `turbotax/taxonomy/org/${org}/turbotax_product_tag/keyword/`;
  // const triggerModelCreationEndpoint = `turbotax/taxonomy/org/${org}/turbotax/submit_keywords/`;
  /*
    This endpoint accomplishes the following:
      1. Create new keywords (deletes all the old ones, and saves the ones that are configured
        from the UI ie. confirmed by the customer)
      2. Set the product configuration org property to 'keywords_submitted'.
      3. Hit an API endpoint in davinci to let it know keywords are ready for
      product modeling to proceed.
  */
  try {
    // Save keywords (delete old ones and resave the new ones (along with any added/modified ones)
    let keywordsBody = [];
    for (const mapping of keywordMappings) {
      const product = mapping.product;
      const strong = mapping.keywords.strong.filter((v) => !v.isDeleted);
      const moderate = mapping.keywords.moderate.filter((v) => !v.isDeleted);
      const weak = mapping.keywords.weak.filter((v) => !v.isDeleted);
      // Create any new keywords that were added (if there are any).
      keywordsBody = keywordsBody.concat(
        createKeywordsRequest(product, strong, moderate, weak)
      );
    }
    yield call(request, tagsEndpoint, 'POST', {
      body: JSON.stringify(keywordsBody),
    });
    // Call to davinci endpoint here to trigger product model creation.
    // yield call(request, triggerModelCreationEndpoint, 'POST');
    // Update Product State to 'keywords_submitted'.
    const propertyData = {
      property_type_id: PRODUCT_CONFIG_PROPERTY_TYPE,
      choice: KEYWORDS_SUBMITTED,
    };
    yield call(request, propertyEndpoint, 'PUT', {
      body: JSON.stringify(propertyData),
    });
    toaster.showSuccess('Configuration Successfully Submitted');
    yield put(actions.setCurrentStep(KEYWORDS_SUBMITTED_STEP));
    yield put(actions.setCurrentTaxonomyState(KEYWORDS_SUBMITTED));
    yield put(loadProductTagMappingGenerator.loadAction());
    yield put(actions.saveKeywordsSuccess());
  } catch (e) {
    toaster.showError(
      'Unable to submit Keywords at this time. Please try again later'
    );
    yield put(actions.saveKeywordsFailure());
  }
}

function* initializeURLSaga() {
  const properties = yield select(orgPropertiesSelector);
  const productState = get(properties, 'turbotax_product_taxonomy_state');

  let originalProductTags = yield select(originalProductURLsListSelector);
  const originalProductKeywords = yield select(originalKeywordsSelector);
  originalProductTags = originalProductTags.map(({ urls, ...rest }) => ({
    ...rest,
    urls:
      urls.length < 5
        ? concat(
            urls,
            [...Array(5 - urls.length)].fill({
              id: null,
              url: '',
              updated: false,
              isDeleted: false,
              touched: false,
            })
          )
        : urls,
  }));
  if (productState !== NOT_CONFIGURED) {
    yield put(actions.setCurrentTaxonomyState(productState));
  }

  yield put(actions.setProductURLs(originalProductTags));
  yield put(actions.setProductKeywords(originalProductKeywords));
}

function* initializeValidationURLsSaga() {
  const urlList = yield select(productURLsSelector);
  const responses = urlList.reduce((acc, { urls, product }) => {
    forEach(urls, ({ url }) => {
      acc.push({
        url,
        isValid: true,
        validationLoading: false,
        product,
        serverError: false,
      });
    });

    return acc;
  }, []);
  try {
    const modifiedResponses = groupBy(responses, 'product');
    yield put(actions.initializeValidationURLSuccess(modifiedResponses));
  } catch (e) {
    console.log(e);
  }
}

function* validateURLSaga(request, args) {
  const org = yield select(orgSelector);
  const { product, index, url } = args.data;
  // const urlObj = urlValidation[product][index];
  // if (urlObj?.url === url) {
  //   return;
  // }
  let resultObj = {};
  try {
    const validateUrlEndPoint = `turbotax/taxonomy/org/${org}/turbotax/validate_urls/`;
    yield call(request, validateUrlEndPoint, 'POST', {
      body: JSON.stringify([url]),
    });
    resultObj = {
      url,
      isValid: true,
      product,
      index,
      serverError: false,
    };
  } catch (e) {
    resultObj = {
      url,
      isValid: false,
      product,
      index,
      serverError: true,
    };
  }
  yield put(actions.validateURLsSuccess(resultObj));
}

function* watchSaveURLs(request) {
  yield takeLatest(SAVE_URLS_REQUEST, saveUrlsSaga, request);
}

function* initializeURLs() {
  yield takeLatest(INITIALIZE_URLS, initializeURLSaga);
}

function* watchSaveKeywords(request) {
  yield takeLatest(SAVE_KEYWORDS_REQUEST, saveKeywordsSaga, request);
}

function* watchInitializeValidationURLs(request) {
  yield takeLatest(
    INITIALIZE_VALIDATION_URLS_REQUEST,
    initializeValidationURLsSaga,
    request
  );
}

function* watchValidateURL(request) {
  yield takeEvery(VALIDATE_URLS_REQUEST, validateURLSaga, request);
}

export const productUrlConfigSagas = [
  initializeURLs,
  fetchSaga(watchSaveURLs),
  fetchSaga(watchSaveKeywords),
  fetchSaga(watchInitializeValidationURLs),
  fetchSaga(watchValidateURL),
];
