import { FEATURE_FLAGS } from 'constants/featureFlags';
import {
  CAMPAIGN_SOURCE,
  LINKEDIN_CAMPAIGNS_AD_TYPE_LABEL,
} from '../routes/Campaigns/constants';
import { EXTERNAL_ID }
  from '../routes/Campaigns/routes/ListCampaigns/ColumnDefinitions/EXTERNAL_ID';
import { MATCH_RATE } from '../routes/Campaigns/routes/ListCampaigns/ColumnDefinitions/MATCH_RATE';
import { PROVIDER } from '../routes/Campaigns/routes/ListCampaigns/ColumnDefinitions/PROVIDER';
import { TOTAL_CLICKS } from
  '../routes/Campaigns/routes/ListCampaigns/ColumnDefinitions/TOTAL_CLICKS';
import { TOTAL_IMPRESSIONS }
  from '../routes/Campaigns/routes/ListCampaigns/ColumnDefinitions/TOTAL_IMPRESSIONS';
import { AD_TYPE_IDS, LINKEDIN_CPC_BIDDING, VIEWABILITY } from '../constants';
import { LINKEDIN_ADVERTISING_STATE_KEY } from 'utils/constants';
import {
  LINKEDIN_LOW_AUDIENCE_COUNT,
} from '../routes/Campaigns/routes/ListCampaigns/components/FilterByPanel/constants';

export const UNIT_TEST_FEATURE_FLAG = 'has_unit_test';
export const UNIT_TEST_IDENTIFIER = 'unit_test_identifier';

export const FEATURE_FLAGS_AND_IDENTIFIERS_MAPPING = {
  has_contextual_targeting: [
    CAMPAIGN_SOURCE.CONTEXTUAL,
  ],
  // This is used in test. Do not delete
  [UNIT_TEST_FEATURE_FLAG]: [
    UNIT_TEST_IDENTIFIER,
  ],
  [FEATURE_FLAGS.hasEmc]: [
    CAMPAIGN_SOURCE.EXTERNAL,
    EXTERNAL_ID.key,
    MATCH_RATE.key,
    PROVIDER.key,
    TOTAL_CLICKS.key,
    TOTAL_IMPRESSIONS.key,
  ],
  [FEATURE_FLAGS.hasLinkedinAdvertising]: [
    LINKEDIN_CAMPAIGNS_AD_TYPE_LABEL,
    AD_TYPE_IDS.linkedInSingleImageAd,
    AD_TYPE_IDS.linkedInVideoAd,
    CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING,
    FEATURE_FLAGS.hasLinkedinAdvertising,
    LINKEDIN_ADVERTISING_STATE_KEY,
    LINKEDIN_LOW_AUDIENCE_COUNT,
    LINKEDIN_CPC_BIDDING,
  ],
  [FEATURE_FLAGS.hasOptimizeViewability]: [
    VIEWABILITY,
  ],
};
