import React from 'react';
import { css, cx } from 'styles/emotion';
import { useSharedState, useSetSharedValue } from '@sixsense/core';
import { Text, Checkbox2, Icon, ActionDropdown, ActionItem }
from 'v2-components';
import { Permission, usePermissionCheck } from '@sixsense/rbac';
import moment from 'moment';
import { KeywordGroup } from '../../types';
import { GROUP_KW_LIST } from '../../constants';
import {
  groupsConfigState,
  activePageState,
  groupsKeywordsConfigState,
  renameGroupVisibleState,
  deleteGroupVisibleState,
  selectedGroupState,
} from '../../state';

const styles = {
  column: css({
    wordBreak: 'break-word',
    paddingRight: '15px',
  }),
  actionColumn: css({
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
  }),
  container: css({
    padding: '20px',
    paddingLeft: '24px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #C6CACE',
    minHeight: '70px',
  }),
  dropdown: css({
    width: '150px',
    '&:hover': {
      backgroundColor: '#DBF4F3',
    },
  }),
  dropdownIcon: css({
    color: '#505C62',
    fontSize: '24px !important',
  }),
  nameText: css({
    color: '#1890FF',
    '&:hover': {
      color: '#40A9FF',
    },
  }),
  userColumn: css({
    wordBreak: 'break-word',
  }),
};

type Props = {
  groupData: KeywordGroup;
};

const GroupTableRow = ({ groupData }: Props) => {

  const {
    id,
    name,
    keyword_count,
    updated,
    username,
    keyword_accounts,
  } = groupData;

  const [config, setConfig] = useSharedState(groupsConfigState);
  const [kwGroupConfig, setKwGroupConfig] = useSharedState(groupsKeywordsConfigState);
  const setActivePage = useSetSharedValue(activePageState);
  const setSelectedGroup = useSetSharedValue(selectedGroupState);
  const setRenameModalVisible = useSetSharedValue(renameGroupVisibleState);
  const setDeleteModalVisible = useSetSharedValue(deleteGroupVisibleState);

  const canEdit = usePermissionCheck([Permission.SETTINGS_KEYWORDS_EDIT]);

  const determineSelectedGroups = (keywordId, checked) => {
    const groups = [...config.selectedValues];
    if (checked) {
      groups.push(keywordId);
      return groups;
    }
    return config.selectedValues.filter((v) => v !== keywordId);
  };

  const changeView = (groupId) => {
    setSelectedGroup(groupData);
    setKwGroupConfig({
      ...kwGroupConfig,
      filters: { ...kwGroupConfig.filters, keyword_item__keyword_set_id: groupId },
    });
    setActivePage(GROUP_KW_LIST);
  };

  return (
    <div className={styles.container}>
      {canEdit && <span className={styles.column} style={{ width: '3%' }}>
        <Checkbox2
          checked={config.selectedValues.includes(id)}
          onChange={(e) => setConfig({ ...config,
            selectedValues: determineSelectedGroups(id, e.target.checked) })}
          size={Icon.SIZE.LARGE}
        />
      </span>}
      <span className={styles.column} style={{ width: '25%' }}>
        <Text pointer onClick={() => changeView(id)} className={styles.nameText}>{name}</Text>
      </span>
      <span className={cx(styles.column, styles.actionColumn)}>
        {config.selectedValues.length < 2 && canEdit &&
          <ActionDropdown
            id="group-actions"
            rightIcon="more_horiz"
            rightIconClass={styles.dropdownIcon}
          >
            <ActionItem
              id="change-name"
              key="change-name"
              className={styles.dropdown}
              action={() => {
                setSelectedGroup({ ...groupData, tempName: groupData.name });
                setRenameModalVisible(true);
              }}
            >
              Rename
            </ActionItem>
            <ActionItem
              id="delete-group"
              key="delete-group"
              className={styles.dropdown}
              action={() => {
                setSelectedGroup(groupData);
                setDeleteModalVisible(true);
              }}
            >
              Delete
            </ActionItem>
          </ActionDropdown>
        }
      </span>
      <span className={styles.column} style={{ width: '13%' }}>
        <Text>{keyword_count}</Text>
      </span>
      <span className={styles.column} style={{ width: '17%' }}>
        <Text>{keyword_accounts === 0 ? '--' : keyword_accounts}</Text>
      </span>
      <span className={styles.column} style={{ width: '15%' }}>
        <Text>{moment(updated).format('MMM DD, YYYY')}</Text>
      </span>
      <span className={styles.userColumn} style={{ width: '17%' }}>
        <Text>{username || '--'}</Text>
      </span>
    </div>
  );
};

export default GroupTableRow;
