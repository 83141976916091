import React from 'react';
import Text from 'v2-components/Text';
import { COLUMN_CATEGORIES } from '../constants';
import { NA } from './common/NA';
import { numberToDollar } from 'utils/utils';
import { CAMPAIGN_SOURCE } from '../../../constants';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';
import { isDraftOrScheduledCampaign } from '../../../utils';

export const CPC = {
  category: COLUMN_CATEGORIES.general,
  sortByKey: 'cpc',
  key: 'cpc',
  csvColumnKey: 'cpc',
  title: <Label name={CAMPAIGN_LABELS.cpc} />,
  dataIndex: 'campaign_data',
  width: 110,
  render: (campaignData, campaign) => {
    const campaignStatus = campaign.campaign_status;
    const campaignSource = campaign.campaign_source;
    if (campaignSource === CAMPAIGN_SOURCE.EXTERNAL ||
      isDraftOrScheduledCampaign(campaignStatus)) {
      return (<NA />);
    }

    const cpc = campaignData.cpc || 0;
    return (<Text type={Text.TYPE.SUBBODY}>{numberToDollar(cpc)}</Text>);
  },
};
