export const TARGET_AUDIENCE_FORM_NAME = 'TARGET_AUDIENCE';
export const TARGET_AUDIENCE_STORE_KEY = 'targetAudience';
export const TARGET_AUDIENCE_FORM_FIELDS = {
  SEGMENT: 'segment',
  FILTERS: 'filters',
  GLOBAL_MSG_DUMMY: 'globalMsg',
};

export const URL_TABS = {
  CONTAINS: { label: 'URL Contains', value: 'contains' },
  EQUALS: { label: 'URL Equals', value: 'equals' },
};

export const AUDIENCE_CRITERIA_VALIDATION_MSG = {
  CRITERIA_MISSING: 'Please select a criteria',
  REQUIRED: 'Required',
};

export const CRITERIA_FIELD_TYPES = {
  NUMERIC: 'numeric',
  COMPLEX: 'complex',
};

export const FILTER_CRITERIA = {
  INCLUDE_ANY: {
    filterLabel: 'INCLUDE',
    matchTypeLabel: 'ANY',
    filterKey: 'include_any',
  },
  INCLUDE_ALL: {
    filterLabel: 'INCLUDE',
    matchTypeLabel: 'ALL',
    filterKey: 'include_all',
  },
  EXCLUDE_ANY: {
    filterLabel: 'EXCLUDE',
    matchTypeLabel: 'ANY',
    filterKey: 'exclude_any',
  },
};

export const CRITERIA_TYPE = {
  ALL_WEBSITE_VISITORS: { key: 'all_website_visitors', label: 'All website visitors' },
  VISITED_SPECIFIC_WEB_PAGE: {
    key: 'visited_specific_web_page',
    label: 'Page specific website visitors',
  },
};
