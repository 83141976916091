import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./DateFilter.module.scss";
import moment from "moment";
import { DATE_FILTER_OPTIONS_VALUES, DEFAULT_OPTIONS } from "./constants";
import {
  DatePicker,
  Dropdown,
  Flex,
  InputGroup,
  Tooltip,
  Button,
} from "@sixsense/core/components";
import { Calendar, ChevronDown } from "@sixsense/core/icons";
import responsiveStyles from "routes/AdvertisingDS/responsive.module.scss";
import { filterStatDates } from "routes/AdvertisingDS/utils";

const DateFilter = ({
  className,
  onChange,
  disable,
  customDateRangeValue,
  value,
  dateRange,
  menu,
  fullWidth,
}) => {
  const [internalDateRangeValue, setInternalDateRangeValue] = useState([
    undefined,
    undefined,
  ]);

  const dateRangeTooltipText = `Delivery Timeframe: ${
    DEFAULT_OPTIONS.find((option) => value === option.value).label
  }`;

  useEffect(() => {
    if (dateRange.length) {
      setInternalDateRangeValue(
        dateRange.map((dateString) => moment(dateString).toDate())
      );
    } else {
      setInternalDateRangeValue([undefined, undefined]);
    }
  }, [dateRange]);

  const selectedOptionLabel = DEFAULT_OPTIONS.find(
    (option) => option.value === value
  );

  const handleRangeChange = (range) => {
    if (range[0] && range[1]) {
      onChange(
        customDateRangeValue,
        moment(range[0]).format("YYYY-MM-DD"),
        moment(range[1]).format("YYYY-MM-DD")
      );
    }
    setInternalDateRangeValue(range);
  };

  return (
    <Flex className={fullWidth ? "w100" : null}>
      {value !== customDateRangeValue && (
        <Dropdown trigger={["click"]} menu={menu}>
          <Tooltip
            trigger={"hover"}
            overlay={dateRangeTooltipText}
            placement="top"
          >
            <Button
              disabled={disable}
              className={className}
              hierarchy="secondary"
              size="md"
            >
              <Flex textAlign="center" className={fullWidth ? "w100" : null}>
                <Calendar size={20} />
                <Flex className="ml2">{selectedOptionLabel.label}</Flex>
              </Flex>
            </Button>
          </Tooltip>
        </Dropdown>
      )}

      {value === customDateRangeValue && (
        <Flex className={className}>
          <InputGroup
            className={fullWidth ? styles.fullWidthInputButton : null}
          >
            <Dropdown trigger={["click"]} menu={menu}>
              <Tooltip
                trigger={"hover"}
                overlay={dateRangeTooltipText}
                placement="top"
              >
                <Button
                  disabled={disable}
                  hierarchy="secondary"
                  size="md"
                  leadingIcon={Calendar}
                  trailingIcon={ChevronDown}
                  className={styles.fullWidthInputButton}
                >
                  <span className={responsiveStyles.removeOnSmallScreen}>
                    {selectedOptionLabel.label}
                  </span>
                </Button>
              </Tooltip>
            </Dropdown>
            <DatePicker.Range
              icon={null}
              disabled={disable}
              className={styles.inputDatePicker}
              value={internalDateRangeValue}
              onChange={handleRangeChange}
              filterDate={filterStatDates}
            />
          </InputGroup>
        </Flex>
      )}
    </Flex>
  );
};

DateFilter.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disable: PropTypes.bool,
  customDateRangeValue: PropTypes.any,
  dateRange: PropTypes.arrayOf(PropTypes.object),
  menu: PropTypes.any,
  fullWidth: PropTypes.bool,
};

DateFilter.defaultProps = {
  value: DEFAULT_OPTIONS[0].value,
  dateRange: [],
  options: DEFAULT_OPTIONS,
  customDateRangeValue: DATE_FILTER_OPTIONS_VALUES.dateRange,
  fullWidth: false,
};

export default DateFilter;
