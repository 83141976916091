import React from 'react';
import { get } from 'lodash';
import Text from 'v2-components/Text';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';

export const ARCHIVED_BY = {
  key: 'archived-by',
  csvColumnKey: 'archived_by',
  title: <Label name={CAMPAIGN_LABELS.archivedBy} />,
  dataIndex: 'campaign_meta.archived_by',
  width: 200,
  render: (archivedBy) => (<Text
    type={Text.TYPE.SUBBODY}
  >
    {get(archivedBy, 'username', '-')}
  </Text>),
};
