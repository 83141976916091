export default function createRoutes() {
  return {
    path: 'activity',
    name: 'activity',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('../../containers/EventTaxonomy/containers');
        cb(null, containers.EventTaxonomy);
      }, 'partner_settings_taxonomy_event');
    },
  };
}
