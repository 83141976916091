import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';
import { Row, Select, Text } from 'v2-components';
import styles from './PaginateCard2.module.scss';

const SEARCH_TYPE_OPTIONS = [10, 20, 50, 100].map((value) => ({
  label: value,
  value,
}));
const ENTER_KEY_CODE = 13;

/**
 * Pagination with dynamic page size and goto page option.
 * Taken from services/controlcenter/frontend/src/HOCS/PaginateCard2
 */
const PaginateCard = (_props = {}) => {
  const { extraFooter = null, showEmpty = true } = _props;
  return (WrappedComponent) => {
    function PaginateCardComponent(props) {
      const {
        dataset,
        totalDataCount,
        reset,
        requestData,
        key,
        currentPage: page,
        pageSize,
        pageOptions,
        disablePagination,
        dropdownClassName,
      } = props;
      const [inputVal, setInputVal] = useState(page);

      const showPageInfo = () => {
        const items = pageSize * page;
        const start = totalDataCount === 0 ? 0 : items - pageSize + 1;
        const end = items > totalDataCount ? totalDataCount : items;
        return `Showing ${start} - ${end} out of ${
          totalDataCount ? totalDataCount.toLocaleString() : 0
        } records`;
      };

      const setPageSize = (val) => {
        // logic to retain current page no. may be required later
        // const newPage = Math.floor(offset / val) + 1;
        requestData(1, val); // for now switch to page 1
      };

      const totalCount = totalDataCount || dataset.length;
      const showFooter = totalCount > 0 || showEmpty;
      const maxPages = Math.max(Math.ceil(totalCount / pageSize), 1);
      const newPageOptions = pageOptions
        ? pageOptions.map((value) => ({ label: value, value }))
        : SEARCH_TYPE_OPTIONS;

      const onPageChange = (pageNumber, pagesize1 = pageSize) => {
        requestData(pageNumber, pagesize1);
      };

      useEffect(() => {
        const shouldReset = page > maxPages;
        if (reset && shouldReset) onPageChange(1);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [requestData, dataset, totalDataCount, reset, pageSize]);

      useEffect(() => {
        setInputVal(page);
      }, [page]);

      const PaginationFooter = (
        <div key={key}>
          <Row className={styles.paginateOuter} justifyContent="center">
            <Row className={styles.paginationInfo}>
              <Text className={styles.mAuto} type={Text.TYPE.SUBBODY} color={Text.COLOR.AA_GREY1}>
                Records per page
              </Text>
              <Select
                placeholder={'View Search Type'}
                options={newPageOptions}
                value={pageSize}
                onChange={setPageSize}
                containerClassName={`${styles.pageSizeDropdown} ${
                  disablePagination && styles.disabledItem
                }`}
                dropdownClassName={dropdownClassName}
                disabled={disablePagination}
              />
              <div className={styles.divider} />
              <Text
                className={styles.PageInfo}
                type={Text.TYPE.SUBBODY}
                color={Text.COLOR.AA_GREY1}
              >
                {showPageInfo()}
              </Text>
            </Row>

            <Row className={styles.Pagination}>
              <div>
                <Pagination
                  total={totalCount}
                  pageSize={pageSize}
                  current={page}
                  showLessItems
                  onChange={onPageChange}
                  onShowSizeChange={onPageChange}
                  disabled={disablePagination}
                />
              </div>
              <div className={styles.paginationQuickLink}>
                <Text className={styles.mAuto} type={Text.TYPE.SUBBODY} color={Text.COLOR.AA_GREY1}>
                  or Go to Page
                </Text>
                <input
                  type="text"
                  value={inputVal}
                  min={1}
                  className={`${styles.input} ${disablePagination && styles.disabledItem}`}
                  max={maxPages}
                  disabled={disablePagination}
                  onKeyDown={(event) => {
                    const value = event.target.value;
                    if (event.which === ENTER_KEY_CODE && value > 0 && value <= maxPages) {
                      onPageChange(Number(value));
                    }
                  }}
                  onChange={({ target: { value } }) => {
                    if (value === '' || (value > 0 && value <= maxPages && /d*/.test(value))) {
                      setInputVal(value);
                    }
                  }}
                />
                <Text className={styles.mAuto} type={Text.TYPE.SUBBODY} color={Text.COLOR.AA_GREY1}>
                  of {maxPages}
                </Text>
              </div>
            </Row>
          </Row>
          {extraFooter}
        </div>
      );
      return (
        <WrappedComponent
          {...props}
          footer={showFooter ? PaginationFooter : null}
          pageSize={pageSize}
        />
      );
    }

    PaginateCardComponent.propTypes = {
      dataset: PropTypes.array.isRequired,
      key: PropTypes.string,
      requestData: PropTypes.func.isRequired,
      totalDataCount: PropTypes.number,
      currentPage: PropTypes.number,
      pageSize: PropTypes.number,
      reset: PropTypes.bool,
      pageOptions: PropTypes.arrayOf(PropTypes.number),
      disablePagination: PropTypes.bool,
      dropdownClassName: PropTypes.string,
    };
    PaginateCardComponent.defaultProps = {
      totalDataCount: 0,
      currentPage: 1,
      pageSize: 10,
    };
    return PaginateCardComponent;
  };
};

PaginateCard.makeDataset = (page, pageSize, dataset) => {
  const start = pageSize * (page - 1);
  const end = pageSize * page;
  return dataset.slice(start, end);
};

export default PaginateCard;
