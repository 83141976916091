import { defineSharedState } from '@sixsense/core';
import {
  JobTitlesSearchState,
  JobTitlesStateArray,
  UserJobTitlesType,
} from './types';

const PATH_PREFIX = 'settings.jobtitles';

export const jobTitlesSearchState =
  defineSharedState<JobTitlesSearchState>({
    path: `${PATH_PREFIX}.jobTitlesSearchState`,
    defaultValue: '',
  });

export const jobTitlesState =
  defineSharedState<JobTitlesStateArray>({
    path: `${PATH_PREFIX}.jobTitles`,
    defaultValue: [],
  });

export const userJobTitlesState = defineSharedState<UserJobTitlesType>({
  path: `${PATH_PREFIX}.userJobTitles`,
  defaultValue: [],
});

