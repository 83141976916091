import React from "react";
import { Text } from "@sixsense/core/components";
import { CAMPAIGN_SOURCE, CAMPAIGN_STATUS_X } from "../../../constants";
import { numberToDollar } from "utils/utils";
import { NA } from "./common/NA";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const SPEND = {
  sortByKey: "budget_spent",
  customizable: true,
  defaultSelected: true,
  key: "spend",
  csvColumnKey: "spend",
  title: <Label name={CAMPAIGN_LABELS.campaignSpend} />,
  dataIndex: "campaign_data.budget_spent",
  width: pxToRem(110),
  render: (spend, campaign) => {
    const { campaign_source } = campaign;
    if (campaign_source === CAMPAIGN_SOURCE.EXTERNAL) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {numberToDollar(spend, { insertCommas: true })}
      </Text.Body>
    );
  },
  csvOverrides: {
    dataIndex: "download_stats.budget_spent",
    render: (spend, campaign) => {
      const { campaign_source, campaign_status } = campaign;
      if (
        campaign_source === CAMPAIGN_SOURCE.EXTERNAL ||
        [CAMPAIGN_STATUS_X.draft, CAMPAIGN_STATUS_X.scheduled].includes(
          campaign_status
        )
      ) {
        return "-";
      }

      return `"${numberToDollar(spend, { insertCommas: true })}"`;
    },
  },
  align: "right",
};
