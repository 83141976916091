import { createSelector } from 'reselect';
import { every } from 'lodash';

export const routeImagesSelector = (routeKey) => (state) => state.images[routeKey] || {};

/* check that all images have finished loading as opposed to loaded
  and leave specific details up to the component level */
export const imagesLoadedSelector = (routeKey) => createSelector(
  routeImagesSelector(routeKey),
  (routeImages = {}) => every(routeImages, { loading: false }),
);

