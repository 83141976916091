import { get } from "lodash";
import { orgSelector } from "modules/user/selectors";
import { createSelector } from "reselect";

export const searchCampaignsEndpointSelector = createSelector(
  orgSelector,
  (_, action) => get(action, "payload.searchValue", ""),
  (orgId, searchValue) =>
    `org/${orgId}/campaigns_list_lite/?limit=50&search=${searchValue}`
);

export const searchAdFoldersEndpointSelector = createSelector(
  orgSelector,
  (_, action) => get(action, "payload.searchValue", ""),
  (orgId, searchValue) =>
    `org/${orgId}/placement_classification/?search=${searchValue}`
);

export const auditEventEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `org/${orgId}/campaign/audit_event/`
);

export const downloadEventLoggingPayloadSelector = createSelector(
  (_, action) => action,
  ({ payload }) => ({
    body: JSON.stringify({ ...payload, event_type: "download_event" }),
  })
);
