import { createSelector } from 'reselect';
import {
  orgSelector,
} from 'modules/user/selectors';
import { uniqBy } from 'lodash';
import { config } from './constants';

// Search segments lazy fetching
const routeStateSelector = (state) => state.DynamicList;

const offsetSelector = createSelector(
  routeStateSelector,
  (dynamicList) => dynamicList.offset
);

export const searchInputSelector = createSelector(
  routeStateSelector,
  (dynamicList) => dynamicList.searchInput
);

export const searchLoadingSelector = createSelector(
  routeStateSelector,
  (dynamicList) => dynamicList.loading
);

export const moreAvailableSelector = createSelector(
  routeStateSelector,
  (dynamicList) => dynamicList.moreAvailable
);

export const endpointSelector = (name) => createSelector(
  orgSelector,
  offsetSelector,
  searchInputSelector,
  /* eslint-disable */
  (org, offset, searchInput) => {
    const defaultEndpoint = `org/${org}/${name}/?name_contains=${searchInput}&ordering=-updated&offset=${offset}&limit=25`;
    const segmentLiteEndpoint = `orchestration/segment/${defaultEndpoint}`;
    const tallyLegacyEndpoint = `tally_legacy/${org}/segment/?name_contains=${searchInput}&ordering=-updated&offset=${offset}&limit=25`;

    if (name === config.segment_lite_orc.name) {
      return segmentLiteEndpoint;
    }

    if (name === config.orc_segment.name) {
      return tallyLegacyEndpoint;
    }

    return defaultEndpoint;
  /* eslint-enable */
  },
);

export const listSelector = createSelector(
  routeStateSelector,
  searchInputSelector,
  ({ list = [] } = {}, searchInput) => {
    const searchResults = uniqBy(list, 'name').sort((x, y) => {
      let first = x.name.toLowerCase().indexOf(searchInput);
      let second = y.name.toLowerCase().indexOf(searchInput);
      first = first !== -1 ? first : 0;
      second = second !== -1 ? second : 0;

      return first > second ? 1 : -1;
    });
    return searchResults;
  }
);
