import React from 'react';

/* eslint max-len: [2, 10000] */
// Using 1000 for now.... might need to bump it up
export function Astronaut() {
  return (
    <svg width="110" height="106" viewBox="0 0 110 106" xmlns="http://www.w3.org/2000/svg">
      <title>Astronaut</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M100.653 84.106l4.95-4.95c1.363-1.36 2.052-3.165 2.05-4.95 0-1.784-.688-3.588-2.05-4.95-1.362-1.362-3.165-2.05-4.95-2.05-1.785 0-3.59.688-4.95 2.05L84.388 80.57c-.59.59-1.346.877-2.12.88-.775-.003-1.53-.29-2.12-.88-.59-.59-.877-1.346-.88-2.12.002-.776.288-1.53.88-2.122l15.556-15.556c1.362-1.362 2.052-3.166 2.05-4.95 0-1.785-.69-3.588-2.05-4.95l-4.95-4.95c-.782-.78-2.05-.78-2.83 0-.78.78-.78 2.048 0 2.83l4.95 4.95c.59.59.877 1.345.88 2.12-.004.774-.29 1.53-.88 2.12L77.32 73.5c-1.364 1.36-2.052 3.165-2.05 4.95-.002 1.784.686 3.587 2.05 4.95 1.362 1.362 3.165 2.05 4.95 2.05 1.784 0 3.588-.688 4.95-2.05L98.53 72.084c.59-.59 1.347-.876 2.123-.878.773.002 1.53.29 2.12.878.59.59.877 1.347.88 2.12-.003.776-.29 1.532-.88 2.123l-4.948 4.95c-.782.78-.782 2.047 0 2.828.78.78 2.046.78 2.828 0"
          fill="#2D3666"
        />
        <path
          d="M89 93.91c-.028.032-.057.062-.09.09.033.028.062.057.09.09.028-.033.057-.062.09-.09-.033-.028-.062-.058-.09-.09"
          fill="#9AE0E6"
        />
        <path
          d="M89 90c-.432 0-.812.274-.948.684l-.592 1.775-1.776.592c-.41.136-.684.516-.684.948 0 .432.274.812.684.95l1.775.59.592 1.777c.136.41.516.683.948.683.432 0 .812-.274.948-.683l.593-1.776 1.776-.59c.41-.138.684-.518.684-.95 0-.432-.274-.812-.684-.948l-1.775-.593-.592-1.776c-.136-.41-.516-.684-.948-.684m-.09 4c.033-.028.062-.057.09-.09.028.033.057.062.09.09-.033.028-.062.057-.09.09-.028-.033-.057-.062-.09-.09M45 3.91c-.028.032-.057.062-.09.09.033.028.062.057.09.09.028-.033.057-.062.09-.09-.033-.028-.062-.058-.09-.09"
          fill="#9AE0E6"
        />
        <path
          d="M45 0c-.432 0-.812.274-.948.683L43.46 2.46l-1.776.59c-.41.137-.684.518-.684.95 0 .432.274.812.684.95l1.775.59.592 1.776c.136.41.516.684.948.684.432 0 .812-.274.948-.684l.593-1.776 1.776-.59C48.726 4.81 49 4.43 49 4c0-.432-.274-.813-.684-.95l-1.775-.59L45.95.682C45.812.273 45.432 0 45 0m-.09 4c.033-.028.062-.058.09-.09.028.032.057.062.09.09-.033.028-.062.057-.09.09-.028-.033-.057-.062-.09-.09M7.983 24.74l-.05.155c-.1.298-.336.533-.633.632l-.155.052.155.05c.297.1.532.334.632.632l.05.154.052-.154c.1-.298.335-.533.633-.632l.154-.05-.153-.053c-.298-.1-.533-.334-.633-.632l-.05-.154"
          fill="#9AE0E6"
        />
        <path
          d="M7.983 20.58c-.432 0-.812.273-.948.683l-.843 2.526-2.525.84c-.41.137-.684.517-.684.95 0 .43.274.81.684.948l2.526.842.842 2.526c.136.41.516.683.948.683.432 0 .812-.275.95-.684l.84-2.526 2.526-.842c.41-.137.683-.517.683-.95 0-.43-.274-.81-.684-.948l-2.527-.842-.84-2.525c-.138-.41-.518-.684-.95-.684m-.05 4.315l.05-.153.05.153c.1.298.336.534.634.633l.154.05-.153.053c-.298.1-.533.335-.633.633l-.05.154-.052-.154c-.1-.298-.335-.533-.632-.632l-.155-.05.155-.052c.297-.1.532-.335.632-.633M50 100.162l-.05.154c-.1.298-.335.533-.633.632l-.154.052.154.05c.298.1.532.335.632.633l.05.154.05-.154c.1-.298.336-.533.634-.632l.154-.05-.154-.052c-.298-.1-.533-.334-.633-.632l-.05-.154"
          fill="#9AE0E6"
        />
        <path
          d="M50 96c-.432 0-.812.274-.948.684l-.842 2.526-2.526.84c-.41.138-.684.518-.684.95 0 .432.274.812.684.95l2.526.84.842 2.526c.136.41.516.684.948.684.432 0 .812-.274.95-.684l.84-2.525 2.526-.84c.41-.138.684-.518.684-.95 0-.432-.274-.812-.684-.95l-2.526-.84-.84-2.526C50.81 96.274 50.43 96 50 96m-.05 4.316l.05-.153.05.153c.1.298.336.534.634.633l.154.05-.154.052c-.298.1-.533.334-.633.632l-.05.154-.05-.154c-.1-.298-.335-.533-.633-.632l-.154-.052.154-.05c.298-.1.532-.336.632-.634M108 53c-1.104 0-2 .895-2 2 0 1.104.896 2 2 2 1.105 0 2-.896 2-2 0-1.105-.895-2-2-2M66 85c-1.104 0-2 .896-2 2s.896 2 2 2c1.105 0 2-.896 2-2s-.895-2-2-2M8 49c-1.104 0-2 .896-2 2s.896 2 2 2c1.105 0 2-.896 2-2s-.895-2-2-2M28 21c-1.105 0-2 .895-2 2 0 1.104.895 2 2 2s2-.896 2-2c0-1.105-.895-2-2-2"
          fill="#9AE0E6"
        />
        <path
          d="M87.217 50.667l7.78-7.778c1.95-1.954 1.95-5.12 0-7.072L68.124 8.948c-1.953-1.953-5.118-1.953-7.07 0l-7.78 7.778 33.942 33.94"
          fill="#FFDCA1"
        />
        <path
          d="M51.357 17.29l-.123.123.305.305c-.08-.137-.14-.28-.183-.427m3.334.85c-.39.39-.902.587-1.414.587-.342 0-.685-.088-.992-.264l3.63 3.63 1.365-1.364-2.59-2.59"
          fill="#FFE5B9"
        />
        <path
          d="M64.59 9.483c-.775.002-1.53.29-2.122.88L54.69 18.14l2.59 2.588 6.414-6.415c.975-.975 2.255-1.463 3.535-1.463.365 0 .73.04 1.09.12.106-.224.217-.446.334-.665l-1.943-1.942c-.59-.59-1.346-.877-2.12-.88"
          fill="#FFC675"
        />
        <path
          d="M83.734 47.184l-1.306 1.306 2.94 2.94c-.3-.723-.154-1.588.435-2.177l-2.07-2.07"
          fill="#FFE5B9"
        />
        <path
          d="M91.64 35.29c-.094.05-.187.098-.28.145.656 1.775.272 3.85-1.153 5.275l-6.473 6.474 2.07 2.07 7.777-7.78c.59-.59.878-1.346.88-2.12-.002-.775-.29-1.53-.88-2.122l-1.94-1.942"
          fill="#FFF2CD"
        />
        <path
          d="M86.454 52.516l.654.654.553-.552c-.145.033-.293.05-.442.05-.26 0-.52-.05-.764-.152"
          fill="#FFE5B9"
        />
        <path
          d="M88.632 52.08l7.778-7.777c1.363-1.362 2.052-3.165 2.05-4.95.002-1.785-.687-3.588-2.05-4.95L69.54 7.533c-1.362-1.363-3.165-2.052-4.95-2.05-1.785-.002-3.588.687-4.95 2.05l-7.778 7.78c-.78.78-.78 2.046 0 2.827.78.78 2.048.78 2.83 0l7.777-7.778c.59-.59 1.345-.877 2.12-.88.774.003 1.53.29 2.122.88l26.87 26.87c.59.59.876 1.347.878 2.12-.002.776-.29 1.532-.878 2.122l-7.778 7.778c-.782.782-.782 2.048 0 2.83.78.78 2.047.78 2.828 0"
          fill="#2D3666"
        />
        <path fill="#E8F3FF" d="M57.52 69.052L34.89 46.425 63.176 18.14l22.627 22.628" />
        <path
          d="M57.52 69.052l1.414-1.414L37.72 46.425 63.176 20.97l19.8 19.798-26.87 26.87 1.413 1.414 1.414-1.414-1.415 1.414 1.414 1.414 28.283-28.284c.373-.373.586-.888.586-1.414 0-.527-.213-1.042-.586-1.414L64.59 16.726c-.372-.372-.887-.586-1.414-.586-.527 0-1.042.214-1.414.586L33.477 45.01c-.78.782-.78 2.048 0 2.83l22.628 22.626c.372.373.888.586 1.414.586.526 0 1.04-.213 1.414-.586l-1.415-1.414"
          fill="#2D3666"
        />
        <path
          d="M34.892 46.425l-5.657 5.657-26.87 26.87 8.485 8.485L33.478 64.81c1.562-1.562 4.095-1.562 5.657 0 1.562 1.562 1.562 4.095 0 5.657L16.507 93.094l8.485 8.485 26.87-26.87 5.657-5.658L34.89 46.425z"
          fill="#E8F3FF"
        />
        <path
          fill="#FFF"
          d="M86.935 41.5L52.83 75.743l-26.87 26.87-4.68-4.69 26.87-26.87L82.244 36.81"
        />
        <path
          fill="#B8DAF2"
          d="M32.345 56.85L11.66 77.535l-6.69-2.69L27.655 52.16 62.28 16.803l3.982 5.302"
        />
        <path
          d="M95.703 32.282c-6.64 6.64-17.403 6.64-24.042 0-6.638-6.638-6.638-17.402 0-24.04C78.3 1.6 89.065 1.6 95.704 8.239c6.64 6.64 6.64 17.404 0 24.042"
          fill="#E8F3FF"
        />
        <path
          d="M94.44 7.87c4.43 6.6 3.73 15.62-2.11 21.46-3.318 3.317-7.665 4.977-12.01 4.977-3.294 0-6.586-.952-9.43-2.857.24.28.5.56.77.83 3.32 3.32 7.67 4.98 12.02 4.98s8.7-1.66 12.02-4.98c5.81-5.81 6.54-14.77 2.18-21.37-.27-.32-.56-.63-.86-.93-.8-.8-1.67-1.51-2.58-2.11"
          fill="#F3F9FF"
        />
        <path
          d="M12.264 77.537c-2.343-2.343-6.142-2.343-8.485 0L2.364 78.95l8.485 8.486 1.414-1.414c2.343-2.343 2.343-6.142 0-8.485"
          fill="#E8F3FF"
        />
        <path
          d="M13.54 79.803c-1.91-.38-3.96.17-5.43 1.64l-1.42 1.42L2 78.173l1.42-1.41c2.34-2.35 6.14-2.35 8.48 0 .86.86 1.41 1.93 1.64 3.04"
          fill="#B8DAF2"
        />
        <path
          d="M26.406 91.68c-2.343-2.344-6.142-2.344-8.485 0l-1.414 1.413 8.486 8.486 1.414-1.416c2.343-2.343 2.343-6.142 0-8.485"
          fill="#E8F3FF"
        />
        <path
          d="M91.33 27.91c-4.28 2.96-10.19 2.53-14.01-1.28-.35-.35-.68-.72-.97-1.11-2.9-3.79-3-9.02-.32-12.91 4.29-2.95 10.2-2.53 14.02 1.29.69.69 1.26 1.44 1.73 2.24 2.13 3.65 1.98 8.26-.45 11.77"
          fill="#78D5DD"
        />
        <path
          d="M81.118 9.82c-2.183 0-4.366.645-6.24 1.933-2.95 4.29-2.53 10.2 1.29 14.02.553.554 1.158 1.037 1.795 1.45-.22-.187-.435-.385-.643-.593-.35-.35-.68-.72-.97-1.11-2.9-3.79-3-9.02-.32-12.91 1.874-1.29 4.057-1.934 6.24-1.934 2.077 0 4.153.584 5.963 1.755-2.05-1.742-4.582-2.61-7.115-2.61"
          fill="#8CD5E6"
        />
        <path
          d="M82.27 10.676c-2.183 0-4.366.646-6.24 1.934-2.68 3.89-2.58 9.12.32 12.91.29.39.62.76.97 1.11.208.208.423.406.643.594.296.19.598.368.905.53l-.056-.108c-3.147-6.207 1.937-12.918 8.037-12.918 1.283 0 2.613.297 3.92.96l.108.055c-.51-.97-1.17-1.89-1.98-2.7-.215-.214-.437-.42-.665-.612-1.81-1.17-3.886-1.754-5.963-1.754"
          fill="#48BBC7"
        />
        <path
          d="M24.992 75.045c-.448 0-.896-.17-1.237-.513l-8.486-8.485c-.684-.684-.684-1.79 0-2.475.683-.683 1.79-.683 2.474 0l8.485 8.486c.683.683.683 1.79 0 2.474-.342.342-.79.513-1.238.513M29.234 70.802c-.448 0-.896-.17-1.237-.513l-8.485-8.486c-.683-.684-.683-1.79 0-2.475.684-.684 1.79-.684 2.475 0l8.484 8.485c.685.683.685 1.79 0 2.474-.34.34-.788.512-1.236.512M39.135 89.186c-.448 0-.896-.17-1.237-.513L29.41 80.19c-.682-.686-.682-1.793 0-2.477.685-.682 1.792-.682 2.476 0l8.486 8.487c.684.682.684 1.79 0 2.474-.34.34-.79.512-1.237.512M43.377 84.944c-.448 0-.896-.17-1.237-.513l-8.486-8.484c-.683-.684-.683-1.79 0-2.475.684-.682 1.79-.682 2.475 0l8.484 8.487c.684.683.684 1.79 0 2.474-.34.343-.79.514-1.237.514"
          fill="#2D3666"
        />
        <path
          d="M49.034 18.14l-16.97 16.97-3.536 3.536c-1.953 1.953-1.953 5.12 0 7.07 1.953 1.954 5.118 1.954 7.07 0l3.537-3.534 24.04-24.042c-3.904-3.905-10.236-3.905-14.14 0"
          fill="#E8F3FF"
        />
        <path
          d="M61.56 17.234l-1.78 1.78c-2.75-.17-5.57.8-7.68 2.91l-16.97 16.97-3.54 3.53c-1.05 1.06-1.54 2.46-1.45 3.84-1.18-.07-2.34-.55-3.23-1.45-1.96-1.96-1.96-5.12 0-7.07l3.53-3.54 16.97-16.97c3.91-3.9 10.24-3.9 14.15 0"
          fill="#B8DAF2"
        />
        <path
          d="M54.69 28.375c-.448 0-.896-.17-1.237-.512l-7.072-7.07c-.682-.684-.682-1.793 0-2.476.685-.683 1.792-.683 2.476 0l7.07 7.072c.685.68.685 1.79 0 2.473-.34.34-.788.512-1.236.512"
          fill="#2D3666"
        />
        <path
          d="M85.803 40.768L61.76 64.81l-3.534 3.535c-1.953 1.953-1.953 5.12 0 7.07 1.952 1.954 5.118 1.954 7.07 0l3.537-3.534 16.97-16.97c3.905-3.905 3.905-10.237 0-14.142"
          fill="#E8F3FF"
        />
        <path
          d="M86.414 56.49l-16.98 16.97-3.53 3.53c-.98.98-2.26 1.47-3.54 1.47-1.28 0-2.56-.49-3.53-1.47-.89-.9-1.38-2.06-1.45-3.23.1.01.2.01.3.01 1.28 0 2.56-.48 3.53-1.46l20.51-20.51c2.1-2.11 3.07-4.92 2.9-7.67l1.79-1.79c3.9 3.91 3.9 10.24 0 14.15"
          fill="#FFF"
        />
        <path
          d="M84.39 58.074c-.45 0-.897-.17-1.238-.513l-7.07-7.07c-.684-.684-.684-1.79 0-2.475.683-.683 1.79-.683 2.474 0l7.07 7.072c.684.683.684 1.79 0 2.474-.34.343-.79.514-1.237.514"
          fill="#2D3666"
        />
        <path fill="#FFDCA1" d="M61.762 53.496L50.448 42.182l7.07-7.07 11.315 11.313" />
        <path
          d="M53.276 42.182l8.485 8.485 4.245-4.242-8.486-8.486-4.244 4.242zm8.485 13.314c-.51 0-1.022-.195-1.413-.586L49.034 43.596c-.78-.78-.78-2.047 0-2.828l7.07-7.07c.376-.376.885-.587 1.415-.587s1.038.212 1.413.587L70.247 45.01c.375.376.586.884.586 1.415 0 .53-.21 1.04-.586 1.414l-7.072 7.07c-.39.39-.902.586-1.414.586z"
          fill="#2D3666"
        />
        <path d="M32.063 35.11l7.07 7.072" fill="#E8F3FF" />
        <path
          d="M39.135 43.932c-.448 0-.896-.17-1.237-.513l-7.072-7.072c-.683-.683-.683-1.79 0-2.475.684-.683 1.79-.683 2.475 0l7.072 7.072c.684.683.684 1.79 0 2.474-.34.34-.79.512-1.237.512"
          fill="#2D3666"
        />
        <path d="M68.833 71.88l-7.07-7.07" fill="#E8F3FF" />
        <path
          d="M68.833 73.63c-.448 0-.896-.17-1.237-.512l-7.072-7.07c-.683-.685-.683-1.792 0-2.476.684-.683 1.79-.683 2.475 0l7.07 7.072c.684.683.684 1.79 0 2.474-.34.342-.79.513-1.237.513M50.448 32.618c-.448 0-.896-.17-1.237-.513l-7.07-7.07c-.684-.684-.684-1.792 0-2.475.683-.684 1.79-.684 2.474 0l7.07 7.07c.685.684.685 1.792 0 2.475-.34.342-.788.513-1.236.513M80.147 62.316c-.45 0-.897-.17-1.238-.513l-6.953-6.953c-.684-.683-.684-1.79 0-2.474.683-.684 1.79-.684 2.474 0l6.953 6.953c.684.682.684 1.79 0 2.473-.34.342-.788.513-1.236.513M101.36 21.555c-.513 0-1.024-.195-1.415-.586-.78-.782-.78-2.048 0-2.83l4.243-4.242c.78-.78 2.047-.78 2.828 0 .78.782.78 2.048 0 2.828l-4.243 4.243c-.39.39-.902.585-1.414.585"
          fill="#2D3666"
        />
        <path
          d="M85.096 20.848c-.512 0-1.023-.195-1.414-.586-.385-.385-.874-.588-1.414-.588-1.105 0-2-.895-2-2 0-1.104.895-2 2-2 1.6 0 3.107.625 4.242 1.76.78.78.78 2.047 0 2.828-.39.39-.902.586-1.414.586"
          fill="#FFF"
        />
        <path
          d="M97.81 10.82c-6.6-4.43-15.62-3.73-21.46 2.11-5.84 5.84-6.54 14.86-2.11 21.46-.91-.6-1.78-1.31-2.58-2.11-6.64-6.64-6.64-17.4 0-24.04 6.64-6.64 17.4-6.64 24.04 0 .8.8 1.51 1.67 2.11 2.58"
          fill="#B8DAF2"
        />
        <path
          d="M72.284 30.018c.044.042.085.085.123.13.207.24.432.483.666.716 2.835 2.835 6.602 4.396 10.607 4.396 4.005 0 7.772-1.56 10.606-4.394 5.018-5.018 5.827-12.948 1.925-18.853-.54-.832-1.188-1.622-1.924-2.358-2.834-2.834-6.6-4.395-10.606-4.395-4.005 0-7.772 1.56-10.606 4.394-5.53 5.53-5.866 14.447-.79 20.364M83.68 39.26c-5.075 0-9.845-1.977-13.434-5.566-.268-.267-.526-.543-.77-.82-.04-.038-.077-.077-.112-.118-6.552-7.494-6.164-18.884.882-25.93 3.59-3.59 8.36-5.566 13.434-5.566 5.075 0 9.845 1.977 13.434 5.566.932.93 1.753 1.938 2.44 2.99 4.94 7.476 3.916 17.522-2.44 23.878-3.59 3.59-8.36 5.566-13.434 5.566"
          fill="#2D3666"
        />
        <path
          d="M77.473 14.054c-2.003 3.175-1.83 7.25.466 10.25.225.306.488.605.794.91 2.99 2.986 7.626 3.485 11.154 1.253 1.778-2.81 1.853-6.428.165-9.32-.392-.668-.87-1.286-1.417-1.833-2.984-2.984-7.623-3.487-11.163-1.26m7.634 17.8c-3.35 0-6.68-1.294-9.198-3.808-.453-.45-.833-.887-1.166-1.333-3.41-4.46-3.562-10.592-.36-15.238.138-.2.313-.375.514-.513 5.166-3.553 12.132-2.91 16.566 1.524.787.786 1.474 1.675 2.04 2.642 2.532 4.337 2.323 9.8-.53 13.92-.136.198-.308.37-.506.507-2.23 1.542-4.802 2.298-7.36 2.298M50.447 19.554L29.94 40.06c-1.168 1.17-1.168 3.073 0 4.243 1.17 1.17 3.074 1.17 4.244 0L60.168 18.32c-3.065-1.808-7.09-1.396-9.72 1.234zM32.063 49.18c-1.793 0-3.585-.684-4.95-2.048-2.728-2.73-2.728-7.17 0-9.9L47.62 16.726c4.68-4.678 12.29-4.678 16.97 0 .375.375.586.883.586 1.414 0 .53-.21 1.04-.586 1.414L37.012 47.132c-1.364 1.364-3.157 2.047-4.95 2.047zM85.626 43.773L59.64 69.76c-1.17 1.17-1.17 3.072 0 4.242s3.073 1.17 4.243 0L84.39 53.496c1.51-1.51 2.342-3.52 2.342-5.657 0-1.454-.384-2.848-1.106-4.067M61.762 78.877c-1.793 0-3.585-.682-4.95-2.047-2.73-2.73-2.73-7.17 0-9.9L84.39 39.355c.78-.782 2.046-.78 2.827 0 2.266 2.266 3.515 5.28 3.515 8.485 0 3.204-1.25 6.218-3.515 8.484L66.71 76.83c-1.363 1.364-3.156 2.047-4.948 2.047"
          fill="#2D3666"
        />
        <path
          d="M19.335 93.094l5.657 5.657 29.7-29.698-19.8-19.8-29.7 29.7 5.658 5.657 21.214-21.214c1.132-1.134 2.64-1.758 4.242-1.758 1.602 0 3.11.624 4.243 1.758 2.34 2.34 2.34 6.145 0 8.484L19.334 93.094zm5.657 10.485c-.51 0-1.023-.196-1.414-.587l-8.485-8.485c-.78-.78-.78-2.047 0-2.828L37.72 69.052c.78-.78.78-2.05 0-2.828-.755-.756-2.073-.756-2.827 0L12.263 88.85c-.374.376-.883.587-1.413.587s-1.04-.21-1.414-.586L.95 80.366c-.78-.78-.78-2.046 0-2.828L33.48 45.01c.78-.78 2.046-.78 2.828 0L58.934 67.64c.375.375.586.884.586 1.414 0 .53-.21 1.04-.586 1.414l-32.528 32.527c-.39.39-.902.586-1.414.586z"
          fill="#2D3666"
        />
        <path
          d="M26.634 100.573l-1.41 1.41-4.69-4.68 1.41-1.42c1.48-1.48 2.03-3.53 1.64-5.44 1.12.22 2.18.77 3.05 1.64 2.34 2.35 2.34 6.15 0 8.49"
          fill="#FFF"
        />
        <path
          d="M5.193 78.95l5.656 5.658c.755-.756 1.17-1.76 1.17-2.828 0-1.068-.415-2.073-1.17-2.83-1.56-1.558-4.096-1.558-5.657 0m5.656 10.486c-.53 0-1.04-.21-1.415-.586L.95 80.365c-.78-.78-.78-2.047 0-2.828l1.415-1.414c3.12-3.12 8.193-3.12 11.312 0 1.512 1.51 2.344 3.52 2.344 5.657s-.83 4.146-2.343 5.656l-1.414 1.414c-.375.375-.883.586-1.414.586M19.335 93.094l5.657 5.657c.756-.755 1.172-1.76 1.172-2.828 0-1.068-.416-2.072-1.172-2.828-.756-.756-1.76-1.172-2.828-1.172-1.07 0-2.073.416-2.83 1.172m5.658 10.485c-.51 0-1.023-.196-1.414-.587l-8.485-8.485c-.78-.78-.78-2.047 0-2.828l1.414-1.414c1.51-1.512 3.52-2.344 5.657-2.344s4.146.832 5.656 2.344c1.512 1.51 2.344 3.52 2.344 5.656 0 2.137-.832 4.147-2.344 5.657l-1.414 1.413c-.39.39-.902.586-1.414.586"
          fill="#2D3666"
        />
        <path d="M82.557 37.52l3.246 3.247" fill="#E8F3FF" />
        <path
          d="M85.804 42.768c-.512 0-1.023-.195-1.414-.585l-3.247-3.247c-.78-.78-.78-2.047 0-2.828.78-.78 2.047-.78 2.828 0l3.248 3.245c.78.782.78 2.048 0 2.83-.39.39-.902.585-1.414.585"
          fill="#2D3666"
        />
        <path d="M63.176 18.14l3.042 3.042" fill="#E8F3FF" />
        <path
          d="M66.218 23.182c-.512 0-1.023-.195-1.414-.585l-3.042-3.04c-.78-.783-.78-2.05 0-2.83.78-.78 2.047-.78 2.828 0l3.042 3.04c.78.782.78 2.048 0 2.83-.39.39-.902.585-1.414.585"
          fill="#2D3666"
        />
      </g>
    </svg>
  );
}
