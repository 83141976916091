export const AD_PLACEMENT_FORM = 'AD_PLACEMENT_FORM';

export const SUBMIT_TYPE = {
  SHOW_PREVIEW: 'showPreview',
  SAVE_AD: 'saveAd',
};
export const CREATIVE_LIMIT = 5;
export const HTML5_SUPPORT_VIDEO = [
  'video/mp4',
  'video/webm',
  'video/mov',
  'video/quicktime',
];
export const FORM_ERROR_MSG = 'Please fill all the details before submit';

export const CREATIVE_UPLOAD_KEY = 'adplacement_creative_upload_key';
export const ICON_UPLOAD_KEY = 'adplacement_icon_upload_key';

export const VIDEO_DIM = ['1280x720', '>1280x720'];

export const NATIVE_REQ_FIELDS = [
  'creative',
  'icon',
  'title',
  'url',
  'sponsor',
  'body_text',
  'call_to_action',
];

export const CREATIVE_FAILURE_ERROR_MSG =
  'Creative could not be saved, Please try again';

export const AD_PLACEMENT_FAILURE_MESSAGE = 'Ad could not be saved, please try again';

export const CREATIVE_LABEL_ERROR_MESSAGE = 'Label should not be more than 100 characters';

export const SAMPLE_DYNAMIC_HTML5_TEMPLATE_DOWNLOAD_URL = '/org/retrieve_s3object?' +
  'filename=sample_HTML5_dynamic_text_and_landing_personalization_300x250.zip';

export const SAMPLE_HTML5_TEMPLATE_DOWNLOAD_URL = '/org/retrieve_s3object?' +
  'filename=6sense_HTML5_sample.zip';

const SCRIPT_TAG_START_STRING = '<script';
const SCRIPT_TAG_END_STRING = '</script>';
const LINK_TAG_START_STRING = '<link';
const LINK_TAG_END_STRING = '>';

const JS_TAG_START_STRING = '<script>';
const JS_TAG_END_STRING = '</script>';
const STYLE_TAG_START_STRING = '<style>';
const STYLE_TAG_END_STRING = '</style>';

export const TAG_STRING = {
  js: {
    START: SCRIPT_TAG_START_STRING,
    END: SCRIPT_TAG_END_STRING,
  },
  css: {
    START: LINK_TAG_START_STRING,
    END: LINK_TAG_END_STRING,
  },
};

export const TAG_STRING_OFFSET = {
  js: 9,
  css: 1,
};

export const REPLACE_TAG = {
  js: {
    START: JS_TAG_START_STRING,
    END: JS_TAG_END_STRING,
  },
  css: {
    START: STYLE_TAG_START_STRING,
    END: STYLE_TAG_END_STRING,
  },
};

export const AD_BASE_ROUTE = 'ad';
