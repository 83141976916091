import { CAMPAIGN_NAME } from './CAMPAIGN_NAME';
import { STATUS } from './STATUS';
import { SPEND } from './SPEND';
import { BUDGET } from './BUDGET';
import { ACCOUNTS_REACHED } from './ACCOUNTS_REACHED';
import { CAMPAIGN_PERFORMANCE } from './CAMPAIGN_PERFORMANCE';
import { IMPRESSIONS } from './IMPRESSIONS';
import { AD_TYPE } from './AD_TYPE';
import { AVTR } from './AVTR';
import { COMMON_CONFIG } from './COMMON_CONFIG';
import { AUDIENCE_REACHED } from './AUDIENCE_REACHED';
import { ACTR } from './ACTR';
import { LAST_UPDATED } from './LAST_UPDATED';
import { CREATED_BY } from './CREATED_BY';
import { START_DATE } from './START_DATE';
import { END_DATE } from './END_DATE';
import { EXTERNAL_ID } from './EXTERNAL_ID';
import { MATCH_RATE } from './MATCH_RATE';
import { TOTAL_IMPRESSIONS } from './TOTAL_IMPRESSIONS';
import { TOTAL_CLICKS } from './TOTAL_CLICKS';
import { PROVIDER } from './PROVIDER';
import { LINKEDIN_CAMPAIGN_ID } from './LINKEDIN_CAMPAIGN_ID';
import { ROW_SELECTION } from './ROW_SELECTION';
import { INFLUENCED_FORM_FILLS } from './INFLUENCED_FORM_FILLS';
import { CLICKS } from './CLICKS';
import { eCPM } from './eCPM';
import { ACCOUNTS_NEWLY_ENGAGED } from './ACCOUNTS_NEWLY_ENGAGED';
import { CTR } from './CTR';
import { ACCOUNTS_WITH_INCREASED_ENGAGEMENT } from './ACCOUNTS_WITH_INCREASED_ENGAGEMENT';
import { AVG_INCREASE_IN_ACCOUNT_ENGAGEMENT } from './AVG_INCREASE_IN_ACCOUNT_ENGAGEMENT';
import { VIEWS } from './VIEWS';
import { VTR } from './VTR';
import { CPC } from './CPC';
import { ARCHIVED_BY } from './ARCHIVED_BY';
import { ARCHIVED_ON } from './ARCHIVED_ON';
import { CREATED_ON } from './CREATED_ON';
import { DAILY_SPEND } from './DAILY_SPEND';
import { FREQUENCY_CAP } from './FREQUENCY_CAP';
import { VIEWABILITY } from './VIEWABILITY';
import { BID_TYPE } from './BID_TYPE';

const ALL_COLUMNS = [
  ROW_SELECTION,
  CAMPAIGN_NAME,
  STATUS,
  CAMPAIGN_PERFORMANCE,
  SPEND,
  BUDGET,
  ACCOUNTS_REACHED,
  IMPRESSIONS,
  CLICKS,
  VIEWABILITY,
  CTR,
  VTR,
  BID_TYPE,
  eCPM,
  CPC,
  VIEWS,
  AD_TYPE,
  AVTR,
  ACTR,
  LAST_UPDATED,
  CREATED_BY,
  START_DATE,
  END_DATE,
  AUDIENCE_REACHED,
  EXTERNAL_ID,
  MATCH_RATE,
  TOTAL_IMPRESSIONS,
  TOTAL_CLICKS,
  PROVIDER,
  LINKEDIN_CAMPAIGN_ID,
  INFLUENCED_FORM_FILLS,
  ACCOUNTS_NEWLY_ENGAGED,
  ACCOUNTS_WITH_INCREASED_ENGAGEMENT,
  AVG_INCREASE_IN_ACCOUNT_ENGAGEMENT,
  DAILY_SPEND,
  FREQUENCY_CAP,
].map((columnConfig) => {
  const onHeaderCell = () => ({ sortByKey: columnConfig.sortByKey });

  return ({
    ...COMMON_CONFIG,
    onHeaderCell,
    ...columnConfig,
  });
});

const ARCHIVED_CAMPAIGNS_COLUMNS = [
  ROW_SELECTION,
  CAMPAIGN_NAME,
  ARCHIVED_ON,
  ARCHIVED_BY,
  SPEND,
  BUDGET,
  AD_TYPE,
].map((columnConfig) => {
  const onHeaderCell = () => ({ sortByKey: columnConfig.sortByKey });

  return ({
    ...COMMON_CONFIG,
    onHeaderCell,
    ...columnConfig,
  });
});

const DRAFT_CAMPAIGNS_COLUMNS = [
  // TODO: Disabling row selection functionality until
  // https://6sense.atlassian.net/browse/CAM-2419 is addressed
  // ROW_SELECTION,
  CAMPAIGN_NAME,
  START_DATE,
  END_DATE,
  CREATED_BY,
  CREATED_ON,
  BUDGET,
  AD_TYPE,
].map((columnConfig) => {
  const onHeaderCell = () => ({ sortByKey: columnConfig.sortByKey });

  return ({
    ...COMMON_CONFIG,
    onHeaderCell,
    ...columnConfig,
  });
});


const getDefaultColumns = (allColumns) => allColumns.filter(({ customizable, defaultSelected }) =>
  customizable === false || defaultSelected
);

const getNonCustomizableColumns = (allColumns) => allColumns.filter(({ customizable }) =>
customizable === false
);


export {
  getDefaultColumns,
  getNonCustomizableColumns,
  DRAFT_CAMPAIGNS_COLUMNS,
  ARCHIVED_CAMPAIGNS_COLUMNS,
};
export default ALL_COLUMNS;
