import React from 'react';
import { copyToClipboard } from 'utils/utils';

export default ({ timeout = 3000 }) => (WrappedComponent) => {
  class CopyToClipboardWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isCopied: '',
      };
    }
    componentWillUnmount() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    }

    onCopyToClipboard = (id) => {
      copyToClipboard(document.getElementById(id));
      this.setCopyState(id);
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.setCopyState('');
      }, timeout);
    }

    setCopyState = (isCopied) => {
      this.setState({ isCopied });
    }


    render() {
      const {
        state: { isCopied },
        props,
      } = this;
      return (
        <WrappedComponent {...props} isCopied={isCopied} copyToClipboard={this.onCopyToClipboard} />
      );
    }
  }
  return CopyToClipboardWrapper;
};

