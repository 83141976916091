export const STATE_KEY = 'adLibraryClassifications';

export const ACTION_PREFIX = 'ADVERTISING/AD_LIBRARY_CLASSIFICATION';

export const AD_LIBRARY_CLASSIFICATION_TYPES = {
  UNSORTED_FOLDER: 'system_unsorted_placement_folder',
  FOLDER: 'placement_folder',
};


export const AD_LIBRARY_CLASSIFICATION_ACTIONS = {
  move: 'Move',
};

export const REQUIRED_FOLDER_TYPES = [
  AD_LIBRARY_CLASSIFICATION_TYPES.UNSORTED_FOLDER,
];
