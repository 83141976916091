import { orgSelector } from 'modules/user/selectors';
import { createSelector } from 'reselect';
import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';
import { EVENT, PRODUCT } from '../../constants';
import { JOBS_PRESET_STRING } from './constants';
import { actions as jobsListActions } from './modules/jobsList';
import { jobsRootSelector } from './selectors';

const loadJobsEndpointSelector = createSelector(
    orgSelector,
    (_, loadAction) => loadAction,
    (orgId, loadAction) => {
      const offset = loadAction.payload.offset || 0;
      let url = `turbotax/taxonomy/org/${orgId}/turbotax_export_jobs/` +
        `?limit=10&offset=${offset}` +
        `&type=${loadAction.payload.classificationType}`;
      if (loadAction.payload.createdBy) {
        url += `&created_by=${loadAction.payload.createdBy}`;
      }
      if (loadAction.payload.dataSource) {
        url += `&data_source=${loadAction.payload.dataSource}`;
      }
      if (loadAction.payload.createdAfter) {
        url += `&created_after=${loadAction.payload.createdAfter}`;
      }
      if (loadAction.payload.createdBefore) {
        url += `&created_before=${loadAction.payload.createdBefore}`;
      }
      return url;
    }
);

export const loadInitJobsStateGenerator = stateGenerator({
  baseStateSelector: jobsRootSelector,
  actionPrefix: `${JOBS_PRESET_STRING}/JOBS`,
  endpointSelector: loadJobsEndpointSelector,
  stateKey: 'loadInitJobs',
  onSuccessActions: [
    (response) => jobsListActions.setJobs(response.results),
    (response, loadAction) =>
      jobsListActions.updateCounts({ [loadAction.payload.classificationType]: response.count }),
  ],
  onFailureActions: [
    () => jobsListActions.setJobs([]),
    (_, loadAction) =>
      jobsListActions.updateCounts({ [loadAction.payload.classificationType]: 0 }),
  ],
  method: 'GET',
});

export const loadMoreJobsStateGenerator = stateGenerator({
  baseStateSelector: jobsRootSelector,
  actionPrefix: `${JOBS_PRESET_STRING}/MORE_JOBS`,
  endpointSelector: loadJobsEndpointSelector,
  stateKey: 'loadMoreJobs',
  onSuccessActions: [(response) => jobsListActions.appendJobs(response.results)],
  onFailureActions: [],
  method: 'GET',
});


const loadEventJobsCountEndpointSelector = createSelector(
  orgSelector,
  (orgId) => {
    const url = `turbotax/taxonomy/org/${orgId}/turbotax_export_jobs/` +
      '?limit=0&offset=0' +
      `&type=${EVENT}`;
    return url;
  }
);

export const loadEventJobsCountStateGenerator = stateGenerator({
  baseStateSelector: jobsRootSelector,
  actionPrefix: `${JOBS_PRESET_STRING}/LOAD_EVENT_JOBS_COUNT`,
  endpointSelector: loadEventJobsCountEndpointSelector,
  stateKey: 'loadEventCountJobs',
  onSuccessActions: [(response) => jobsListActions.updateCounts({ [EVENT]: response.count })],
  onFailureActions: [() => jobsListActions.updateCounts({ [EVENT]: 0 })],
  method: 'GET',
});

const loadProductJobsCountEndpointSelector = createSelector(
  orgSelector,
  (orgId) => {
    const url = `turbotax/taxonomy/org/${orgId}/turbotax_export_jobs/` +
      '?limit=0&offset=0' +
      `&type=${PRODUCT}`;
    return url;
  }
);

export const loadProductJobsCountStateGenerator = stateGenerator({
  baseStateSelector: jobsRootSelector,
  actionPrefix: `${JOBS_PRESET_STRING}/LOAD_PRODUCT_JOBS_COUNT`,
  endpointSelector: loadProductJobsCountEndpointSelector,
  stateKey: 'loadProductCountJobs',
  onSuccessActions: [(response) => jobsListActions.updateCounts({ [PRODUCT]: response.count })],
  onFailureActions: [() => jobsListActions.updateCounts({ [PRODUCT]: 0 })],
  method: 'GET',
});
