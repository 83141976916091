// Doing my best Scott impression
// https://github.com/trivio/zipper
import { last, concat, filter, isEqual, dropRight, map } from 'lodash';
import { fnPush } from 'utils/utils';

class Path {
  constructor(l, r, pnodes, ppath, changed) {
    this.l = l;
    this.r = r;
    this.pnodes = pnodes;
    this.ppath = ppath;
    this.changed = changed;
  }

  _replace = (name, value) => {
    const newPath = new Path(
      this.l,
      this.r,
      this.pnodes,
      this.ppath,
      this.changed,
    );
    newPath[name] = value;
    return newPath;
  };
}

export class Loc {
  constructor(
    current,
    path,
    isBranch,
    getChildren,
    makeNode,
    cascadeRemove,
    breadcrumbs = [],
  ) {
    this.current = current;
    this.path = path;
    this.isBranch = isBranch;
    this.getChildren = getChildren;
    this.makeNode = makeNode;
    this.cascadeRemove = cascadeRemove;
    this.breadcrumbs = breadcrumbs;
  }

  // Context
  node = () => this.current;
  children = () => this.branch() && this.getChildren(this.current);
  branch = () => this.isBranch(this.current);
  root = () => this.top().current;
  atEnd = () => !this.path;
  shouldCascadeRemove = () => this.cascadeRemove(this);

  // Manipulation

  _replace = (name, value) => {
    const nextAst = new Loc(
      this.current,
      this.path,
      this.isBranch,
      this.getChildren,
      this.makeNode,
      this.cascadeRemove,
      this.breadcrumbs,
    );
    nextAst[name] = value;
    return nextAst;
  };

  replace = (value) => {
    if (this.path) {
      return this._replace('current', value)._replace(
        'path',
        this.path._replace('changed', true),
      );
    }
    return this._replace('current', value);
  };

  remove = () => {
    // Remove node return parent, slight variation from trivio zipper
    // which removes node and returns previous postorder node
    const path = this.path;
    if (!path) {
      throw new Error('Removing root node unsupported');
    }
    let nextAst = this;
    const { l, r, pnodes, ppath } = nextAst.path;

    nextAst = nextAst
      ._replace('path', ppath && ppath._replace('changed', true))
      ._replace(
        'current',
        nextAst.makeNode(
          last(pnodes).value,
          map(concat(l, r), (pathNode) => pathNode.value),
        ),
      );

    if (nextAst.shouldCascadeRemove()) {
      // replace parent(now current) with sibling(now only child)
      // if it relies on the child just removed. For example, the sibling of
      // a removed node whose parent is and/or should replace the and/or node
      nextAst = nextAst.replace(
        filter(nextAst.children(), (child) => !!child)[0].value,
      );
    }
    return nextAst;
  };

  // Navigation

  down = () => {
    const children = this.children();
    if (children) {
      const newPathNode = this.newPathNode();
      const path = new Path(
        [],
        children.slice(1),
        this.path ? concat(this.path.pnodes, newPathNode) : [newPathNode],
        this.path,
        false,
      );
      const nextLoc = children[0];
      return this._replace('current', nextLoc.value)
        ._replace('path', path)
        ._replace('breadcrumbs', fnPush(this.breadcrumbs, nextLoc.key));
    }
    return null;
  };

  up = () => {
    if (this.path) {
      const { l, r, pnodes, ppath, changed } = this.path;

      if (pnodes) {
        const pnode = last(pnodes).value;
        if (changed) {
          return this._replace(
            'current',
            this.makeNode(
              pnode,
              concat(
                map(l, (lNode) => lNode.value),
                this.current,
                map(r, (rNode) => rNode.value),
              ),
            ),
          )._replace('path', ppath && ppath._replace('changed', true));
        }
        return this._replace('current', pnode)
          ._replace('path', ppath)
          ._replace('breadcrumbs', dropRight(this.breadcrumbs));
      }
    }
    return null;
  };

  siblings = () => {
    if (!this.atEnd()) {
      return filter(
        this.up().children(),
        (child) => !isEqual(child.value, this.current),
      );
    }
    return null;
  };

  right = () => {
    if (this.path && this.path.r.length) {
      const l = this.path.l;
      const r = this.path.r;
      const rightOfThis = r[0];
      const rightOfRightOfThis = r.slice(1);
      return this._replace(
        'path',
        this.path
          ._replace('l', concat(l, this.newPathNode()))
          ._replace('r', rightOfRightOfThis),
      )
        ._replace('current', rightOfThis.value)
        ._replace(
          'breadcrumbs',
          fnPush(dropRight(this.breadcrumbs), rightOfThis.key),
        );
    }
    return null;
  };

  left = () => {
    if (this.path && this.path.l.length) {
      const l = this.path.l;
      const r = this.path.r;
      const leftOfThis = last(l);
      const leftOfLeftOfThis = l.slice(0, -1);
      return this._replace(
        'path',
        this.path
          ._replace('l', leftOfLeftOfThis)
          ._replace('r', concat(this.newPathNode(), r)),
      )
        ._replace('current', leftOfThis.value)
        ._replace(
          'breadcrumbs',
          fnPush(dropRight(this.breadcrumbs), leftOfThis.key),
        );
    }
    return null;
  };

  top = () => {
    let loc = this;
    while (loc.path) {
      loc = loc.up();
    }
    return loc;
  };

  newPathNode = () => ({
    key: last(this.breadcrumbs),
    value: this.current,
  });

  leftmost = () => {
    const path = this.path;
    if (path) {
      const { l, r } = this.path;
      const t = concat(l, this.newPathNode(), r);
      const leftNode = t[0];
      return this._replace(
        'path',
        path._replace('l', [])._replace('r', t.slice(0, -1)),
      )
        ._replace('current', leftNode.value)
        ._replace(
          'breadcrumbs',
          fnPush(dropRight(this.breadcrumbs), leftNode.key),
        );
    }
    return this;
  };

  rightmost = () => {
    const path = this.path;
    if (path) {
      const { l, r } = this.path;
      const t = concat(l, this.newPathNode(), r);
      const rightNode = last(t);
      return this._replace(
        'path',
        path._replace('l', t.slice(0, -1))._replace('r', []),
      )
        ._replace('current', rightNode.value)
        ._replace(
          'breadcrumbs',
          fnPush(dropRight(this.breadcrumbs), rightNode.key),
        );
    }
    return this;
  };

  leftmostDescendent = () => {
    let loc = this;
    while (loc.branch()) {
      const descendent = loc.down();
      if (descendent) {
        loc = descendent;
      } else {
        break;
      }
    }
    return loc;
  };

  rightmostDescendent = () => {
    let loc = this;
    while (loc.branch()) {
      const descendent = loc.down();
      if (descendent) {
        loc = descendent.rightmost();
      } else {
        break;
      }
    }
    return loc;
  };

  postorderNext = () => {
    const rightLoc = this.right();
    return rightLoc ? rightLoc.leftmostDescendent() : this.up();
  };
}

export const zipper = (root, isBranch, children, makeNode, cascadeRemove) =>
  new Loc(root, null, isBranch, children, makeNode, cascadeRemove);
