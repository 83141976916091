import React from "react";
import { upperCase } from "lodash";
import { Text } from "@sixsense/core/components";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";
import { LINKEDIN_CPC_BIDDING } from "routes/AdvertisingDS/constants";
import { COLUMN_CATEGORIES } from "../constants";
import { NA } from "./common/NA";
import {
  CAMPAIGN_SOURCE,
  BID_TYPE_KEY,
  DEFAULT_BID_TYPE,
} from "../../../constants";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";

export const BID_TYPE = {
  width: pxToRem(110),
  key: BID_TYPE_KEY,
  dataIndex: BID_TYPE_KEY,
  category: COLUMN_CATEGORIES.general,
  csvColumnKey: CAMPAIGN_LABELS.bidType,
  featureFlagKey: LINKEDIN_CPC_BIDDING,
  title: <Label name={CAMPAIGN_LABELS.bidType} />,
  render: (bidType, campaign) => {
    const campaignSource = campaign.campaign_source;
    if (
      campaignSource === CAMPAIGN_SOURCE.EXTERNAL ||
      campaignSource === CAMPAIGN_SOURCE.LINKEDIN
    ) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {upperCase(bidType || DEFAULT_BID_TYPE)}
      </Text.Body>
    );
  },
  align: "right",
};
