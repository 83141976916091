import React from 'react';
import PropTypes from 'prop-types';
import { Row, Text } from 'v2-components';
import styles from './AdPreviewWrapper.module.scss';
import { TablePreviewLayout } from 'aa-components';

const { AdPreview } = TablePreviewLayout;

const AdPreviewWrapper = ({
  adPlacement,
  showPreview,
  placementType,
  showAvailablePlacements,
  extraAdPreviewData,
}) => {
  const {
    ad_size: htmlDimension,
    icon = {},
    creative = {},
  } = adPlacement;
  const uniqKey = `previewImg_${creative && creative.id}`;
  const adPreviewProps = {
    creative,
    icon,
    placementType,
    htmlDimension,
    sponsor: adPlacement.sponsor,
    title: adPlacement.title,
    bodyText: adPlacement.body_text,
    callToAction: adPlacement.call_to_action,
    clickUrl: adPlacement.url,
    key: uniqKey,
    showAvailablePlacements,
  };

  return (
    <Row flexDirection="column" className={styles.container}>
      <Text className={styles.item} bold>Preview</Text>
      {!showPreview ? (
        <div>
          <Text>
            {placementType === 'native'
                  ? 'Complete the Ad to preview'
                  : 'Upload creative to preview'}
          </Text>
        </div>
          ) : (
            <AdPreview {...adPreviewProps} extraAdPreviewData={extraAdPreviewData} />
          )}
    </Row>
  );
};
AdPreviewWrapper.propTypes = {
  adPlacement: PropTypes.object,
  showPreview: PropTypes.bool,
  placementType: PropTypes.string,
  showAvailablePlacements: PropTypes.bool,
  extraAdPreviewData: PropTypes.object,
};

export default AdPreviewWrapper;
