/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrchestrationIconStyles.module.scss';
import { Text, Tooltip2 as Tooltip } from 'v2-components';

const EnvSandbox = ({ className }) =>
(
  <Tooltip
    placement={'top'}
    overlay={'Sandbox'}
  >
    <div className={`${styles.environmentIcon} ${styles.sbIcon} ${className}`}>
      <Text type={Text.TYPE.MINI} color={Text.COLOR.WHITE}>S</Text>
    </div>
  </Tooltip>
)

export default EnvSandbox;