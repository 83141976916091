import React from 'react';
import { css, cx } from 'styles/emotion';
import { Permission, usePermissionCheck } from '@sixsense/rbac';
import {
  Text,
  Checkbox2,
  Icon,
  Pill,
  Tooltip2 as Tooltip,
}
from 'v2-components';
import { Flex } from '@sixsense/core/components';
import { Keyword } from '../../types';
import { capitalize } from 'lodash';
import KeywordActions from './KeywordActions';
import { PageConfig } from '../../state';
import moment from 'moment';
import { KW_EXP, OTHER } from '../../constants';

const styles = {
  column: css({
    wordBreak: 'break-word',
    paddingRight: '15px',
  }),
  userColumn: css({
    wordBreak: 'break-word',
  }),
  actionColumn: css({
    width: '5%',
    display: 'flex',
    justifyContent: 'center',
  }),
  container: css({
    padding: '20px',
    paddingLeft: '24px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #C6CACE',
    minHeight: '70px',
  }),
  disableBorder: css({
    borderBottom: 'none !important',
  }),
  dropdown: css({
    width: '150px',
    '&:hover': {
      backgroundColor: '#DBF4F3',
    },
  }),
  dropdownIcon: css({
    color: '#505C62',
    fontSize: '24px !important',
  }),
  pill: css({
    padding: '0.125rem 0.625rem !important',
    margin: '0px !important',
    borderRadius: '2px !important',
  }),
  keywordPill: css({
    background: '#FFF !important',
    border: '1px solid #C6CACE',
    color: '#000',
    '&:hover': {
      backgroundColor: '#FFF !important',
    },
  }),
  branded: css({
    fontWeight: 'bold',
    background: '#FFEFC8 !important',
    border: '1px solid #EBA300',
    color: '#EBA300',
    '&:hover': {
      backgroundColor: '#FFEFC8 !important',
    },
  }),
  generic: css({
    fontWeight: 'bold',
    background: '#E2E7EC !important',
    border: '1px solid #9299A3',
    color: '#9299A3',
    '&:hover': {
      backgroundColor: '#E2E7EC !important',
    },
  }),
  moreText: css({
    color: '#1890FF',
    marginLeft: 10,
    '&:hover': {
      color: '#40A9FF',
    },
  }),
};

type Props = {
  keywordData: Keyword;
  products: any;
  config: PageConfig,
  setConfig: () => void;
  hideActions?: boolean;
  fromAddToGroup: boolean;
};

const KeywordTableRow = ({
  keywordData,
  products,
  config,
  setConfig,
  hideActions,
  fromAddToGroup,
}: Props) => {
  const {
    id,
    category,
    keyword,
    keyword_groups,
    keyword_products,
    keyword_accounts,
    client_added,
  } = keywordData;

  const canEdit = usePermissionCheck([Permission.SETTINGS_KEYWORDS_EDIT]);

  const determineSelectedKeywords = (checked) => {
    const keywords = [...config.selectedValues];
    if (checked) {
      // Push full keyword objs for adding to group
      if (fromAddToGroup) {
        keywords.push(keywordData);
      } else {
        keywords.push(id);
      }
      return keywords;
    }
    // Remove values if unchecking box
    if (fromAddToGroup) {
      return config.selectedValues.filter((v) => v.id !== id);
    }
    return config.selectedValues.filter((v) => v !== id);
  };

  const determineChecked = () => {
    if (fromAddToGroup) {
      return config.selectedValues.map((v) => v.id).includes(id);
    }
    return config.selectedValues.includes(id);
  };

  const updatedKeywordProducts = keyword_products.map((kw_product) => ({
    ...kw_product,
    name: products.find((p) => p.id === kw_product.product)?.displayName || '' }),
  ).filter((kw) => kw.name);

  const groupOrProductText = (data) => {
    if (!data.length) {
      return <Text>--</Text>;
    }
    const firstItem = <Text>{data[0].name === KW_EXP ? OTHER : data[0].name}</Text>;
    return data.length === 1 ? firstItem :
    <React.Fragment>
      {firstItem}
      <Tooltip
        overlay={<Flex direction="column">{data.map((d) =>
          <span>{d.name === KW_EXP ? OTHER : d.name}</span>)
        }</Flex>}
      >
        <Text className={styles.moreText}>+{data.length - 1} more</Text>
      </Tooltip>
    </React.Fragment>;
  };

  return (
    <div className={styles.container}>
      {canEdit && <span className={styles.column} style={{ width: '3%' }}>
        {<Checkbox2
          checked={determineChecked()}
          onChange={(e) => setConfig({ ...config,
            selectedValues: determineSelectedKeywords(e.target.checked) })}
          size={Icon.SIZE.LARGE}
        />}
      </span>}
      <span className={styles.column} style={{ width: '20%' }}>
        <Text>{keyword}</Text>
      </span>
      <span className={cx(styles.column, styles.actionColumn)}>
        {config.selectedValues.length < 2 && !hideActions && canEdit &&
          <KeywordActions selectedKeywords={[keywordData]} />
        }
      </span>
      <span className={styles.column} style={{ width: '10%' }}>
        <Pill
          className={cx(
            styles.pill,
            category === 'branded' ? styles.branded : styles.generic
          )}
          notClickable
        >
          {capitalize(category)}
        </Pill>
      </span>
      <span className={styles.column} style={{ width: '13%' }}>
        <Text>{keyword_accounts === 0 ? '--' : keyword_accounts}</Text>
      </span>
      <span className={styles.column} style={{ width: '20%' }}>
        {groupOrProductText(keyword_groups)}
      </span>
      <span className={styles.column} style={{ width: '15%', paddingLeft: '15px' }}>
        {groupOrProductText(updatedKeywordProducts)}
      </span>
      <span className={styles.userColumn} style={{ width: '10%' }}>
        <Text>{moment(client_added).format('MMM DD, YYYY')}</Text>
      </span>
    </div>
  );
};

export default KeywordTableRow;
