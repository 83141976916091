import { navigate, toParams, getCurrentParams, mergeParamsNavigate } from 'utils/navigate';
import { get } from 'lodash';
import { curry } from 'lodash/fp';

export const setInitialFilter = curry(
  (column, operator, value, extraConfig = {}) => {
    let currentParams;
    if (get(extraConfig, 'clearOtherParams')) {
      currentParams = {};
    } else {
      currentParams = getCurrentParams();
    }
    currentParams.initialFilter = {
      column,
      operator,
      value,
      ...extraConfig,
    };
    let newParams = toParams(currentParams);
    newParams += '&segment_type=6sense_network';
    navigate('/segments/segment/new/accounts/', newParams);
  },
);

export const navigateToKeywords = (category) => {
  mergeParamsNavigate('/discover/keywords', { category });
};
