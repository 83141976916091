import { IntegrationState } from './constants';

const initialState = {
  loading: false,
  error: false,
  integration: undefined,
};

const REQUEST_TOKEN_STATE = 'SLACK/REQUEST_TOKEN_STATE';
const requestTokenState = () => ({ type: REQUEST_TOKEN_STATE });

const REQUEST_TOKEN_STATE_SUCCESS = 'SLACK/REQUEST_TOKEN_STATE_SUCCESS';
const requestTokenStateSuccess = ({ state, workspace }) => ({
  type: REQUEST_TOKEN_STATE_SUCCESS,
  state,
  workspace,
});

const REQUEST_TOKEN_STATE_ERROR = 'SLACK/REQUEST_TOKEN_STATE_ERROR';
const requestTokenStateError = () => ({ type: REQUEST_TOKEN_STATE_ERROR });


const REQUEST_INTEGRATION = 'ALERTS/SLACK/REQUEST_INTEGRATION';
const requestIntegration = () => ({ type: REQUEST_INTEGRATION });

const REQUEST_INTEGRATION_SUCCESS = 'ALERTS/SLACK/REQUEST_INTEGRATION_SUCCESS';
const requestIntegrationSuccess = () => ({ type: REQUEST_INTEGRATION_SUCCESS });

const REQUEST_INTEGRATION_ERROR = 'ALERTS/SLACK/REQUEST_INTEGRATION_ERROR';
const requestIntegrationError = () => ({ type: REQUEST_INTEGRATION_ERROR });

export const actionTypes = { REQUEST_INTEGRATION, REQUEST_INTEGRATION_STATE: REQUEST_TOKEN_STATE };
export const actions = {
  requestTokenState,
  requestTokenStateSuccess,
  requestTokenStateError,
  requestIntegration,
  requestIntegrationSuccess,
  requestIntegrationError,
};

export default function slackReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_TOKEN_STATE:
    case REQUEST_INTEGRATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUEST_TOKEN_STATE_SUCCESS: {
      return {
        integration: { state: action.state, workspace: action.workspace },
        loading: false,
        error: false,
      };
    }
    case REQUEST_INTEGRATION_SUCCESS: {
      return {
        loading: false,
        error: false,
        integration: { state: IntegrationState.PENDING },
      };
    }
    case REQUEST_TOKEN_STATE_ERROR:
    case REQUEST_INTEGRATION_ERROR: {
      return {
        loading: false,
        error: true,
      };
    }
    default:
      return state;
  }
}
