import { slice, get } from 'lodash';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorMessage: '',
  topError: '',
  notFound: false,
  forbidden: false,
  promptOnLeaveMessage: '',
  doPromptOnLeave: false,
  promptWhen: () => true,
  alertPropsAll: {},
  components: [],
  presentationMode: false,
  isFullWidth: false,
  appBodyPosition: undefined,
  state: {},
};

const LOAD_GLOBAL = 'GLOBAL/LOAD';
const LOAD_GLOBAL_SUCCESS = 'GLOBAL/SUCCESS';
const LOAD_GLOBAL_FAIL = 'GLOBAL/ERROR';

function load() {
  return { type: LOAD_GLOBAL };
}

const LOAD_GLOBAL_LABELS_REQUEST = 'GLOBAL/LOAD_LABELS_REQUEST';
const LOAD_GLOBAL_LABELS_SUCCESS = 'GLOBAL/LOAD_LABELS_SUCCESS';
const LOAD_GLOBAL_LABELS_FAIL = 'GLOBAL/LOAD_LABELS_FAIL';

const SHOW_GLOBAL_ERROR = 'GLOBAL/TOP_ERROR';
const DISMISS_GLOBAL_ERROR = 'GLOBAL/DISMISS_TOP_ERROR';

function showGlobalError(message) {
  return { type: SHOW_GLOBAL_ERROR, message };
}

function dismissGlobalError() {
  return { type: DISMISS_GLOBAL_ERROR };
}

const UPDATE_ALERT = 'GLOBAL/UPDATE_ALERT';
function updateAlert(channel, alertProps) {
  return { type: UPDATE_ALERT, channel, alertProps };
}

const SHOW_NOTIFICATION = 'GLOBAL/SHOW_NOTIFICATION';
function showNotification(
  notificationType,
  message,
  description,
  duration,
  notificationProps,
) {
  return {
    type: SHOW_NOTIFICATION,
    notificationType,
    message,
    description,
    duration,
    notificationProps,
  };
}

const SET_PROMPT_ON_LEAVE = 'GLOBAL/SET_PROMPT_ON_LEAVE';
const CLEAR_PROMPT_ON_LEAVE = 'GLBOAL/CLEAR_PROMPT_ON_LEAVE';
const PROMPT_ON_LEAVE = 'GLOBAL/PROMPT_ON_LEAVE';
function setPromptOnLeave(
  message,
  promptWhen,
  keepAfterTransition,
  onConfirmLeave,
) {
  return {
    type: SET_PROMPT_ON_LEAVE,
    message,
    promptWhen,
    keepAfterTransition,
    onConfirmLeave,
  };
}
/**
 *
 * @param {*} toDestination a call back that will send you to the location that
 * you would like to go to.
 */
function promptOnLeave(toDestination, onConfirmLeave) {
  return { type: PROMPT_ON_LEAVE, toDestination, onConfirmLeave };
}
function clearPromptOnLeave() {
  return { type: CLEAR_PROMPT_ON_LEAVE };
}

const SHOW_NOT_FOUND = 'GLOBAL/SHOW_GLOBAL_NOT_FOUND';

function showGlobalNotFound(notFound) {
  return { type: SHOW_NOT_FOUND, notFound };
}

const SHOW_FORBIDDEN = 'GLOBAL/SHOW_FORBIDDEN';
function showGlobalForbidden(forbidden = true) {
  return { type: SHOW_FORBIDDEN, forbidden };
}

const SET_TOOLBAR = 'GLOBAL/SET_TOOLBAR';
const APPEND_TOOLBAR = 'GLOBAL/APPEND_TOOLBAR';
const CLEAR_TOOLBAR = 'GLOBAL/CLEAR_TOOLBAR';
const POP_TOOLBAR = 'GLOBAL/POP_TOOLBAR';

function setToolBar(components) {
  return { type: SET_TOOLBAR, components };
}

function appendToolBar(component) {
  return { type: APPEND_TOOLBAR, component };
}

function clearToolBar() {
  return { type: CLEAR_TOOLBAR };
}

function popToolBar() {
  return { type: POP_TOOLBAR };
}

const SET_APP_BODY_POSITION = 'GLOBAL/SET_APP_BODY_POSITION';
const setAppBodyPosition = (position) => ({
  type: SET_APP_BODY_POSITION,
  position,
});


const PRESENTATION_MODE = 'GLOBAL/TOGGLE_PRESENTATION_MODE';

function togglePresentationMode() {
  return { type: PRESENTATION_MODE };
}

const SET_FULL_WIDTH = 'GLOBAL/SET_FULL_WIDTH';

function setFullWidth(isFullWidth) {
  return { type: SET_FULL_WIDTH, isFullWidth };
}

const SET_STATE = 'GLOBAL/SET_STATE';
const setGlobalState = (path, value) => ({ type: SET_STATE, path, value });

function appReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_GLOBAL: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_GLOBAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        loaded: true,
      };
    }
    case LOAD_GLOBAL_FAIL: {
      const { message } = action;
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: message,
      };
    }
    case SHOW_GLOBAL_ERROR: {
      const { message } = action;
      return {
        ...state,
        topError: message,
      };
    }
    case SHOW_NOT_FOUND: {
      const { notFound } = action;
      return {
        ...state,
        notFound,
      };
    }
    case SHOW_FORBIDDEN: {
      const { forbidden } = action;
      return {
        ...state,
        forbidden,
      };
    }
    case SET_PROMPT_ON_LEAVE: {
      const {
        message,
        promptWhen,
        keepAfterTransition,
        onConfirmLeave,
      } = action;
      return {
        ...state,
        promptOnLeaveMessage: message,
        promptWhen,
        keepAfterTransition,
        doPromptOnLeave: true,
        onConfirmLeave,
      };
    }
    case CLEAR_PROMPT_ON_LEAVE: {
      return {
        ...state,
        promptOnLeaveMessage: '',
        doPromptOnLeave: false,
        promptWhen: () => true,
      };
    }
    case UPDATE_ALERT: {
      const { alertProps = {}, channel } = action;
      const { alertPropsAll } = state;

      return {
        ...state,
        alertPropsAll: { ...alertPropsAll, [channel]: alertProps },
      };
    }
    case SET_TOOLBAR: {
      const { components } = action;

      return {
        ...state,
        components,
      };
    }
    case APPEND_TOOLBAR: {
      const { component } = action;

      return {
        ...state,
        components: [...state.components, component],
      };
    }
    case POP_TOOLBAR: {
      return {
        ...state,
        components: slice(
          state.components,
          0,
          -1
        ),
      };
    }
    case CLEAR_TOOLBAR: {
      return {
        ...state,
        components: [],
      };
    }
    case PRESENTATION_MODE: {
      const { presentationMode } = state;

      return {
        ...state,
        presentationMode: !presentationMode,
      };
    }
    case SET_FULL_WIDTH: {
      const { isFullWidth } = action;

      return {
        ...state,
        isFullWidth,
      };
    }
    case SET_STATE: {
      const { path, value } = action;
      if (get(state.state, path) === value) {
        return state;
      }
      return setDeep(state, `state.${path}`, value);
    }
    case SET_APP_BODY_POSITION: {
      const { position } = action;
      return {
        ...state,
        appBodyPosition: position,
      };
    }
    default:
      return state;
  }
}

const setDeep = (src, pathStr, value) => {
  const path = pathStr.split('.');
  const lastKey = path.pop();
  const out = { ...src };
  let curr = out;
  for (const key of path) {
    curr[key] = { ...curr[key] };
    curr = curr[key];
  }
  curr[lastKey] = value;
  return out;
};

export const actions = {
  load,
  showGlobalError,
  dismissGlobalError,
  showNotification,
  showGlobalNotFound,
  showGlobalForbidden,
  setPromptOnLeave,
  clearPromptOnLeave,
  promptOnLeave,
  updateAlert,
  setToolBar,
  clearToolBar,
  appendToolBar,
  popToolBar,
  togglePresentationMode,
  setFullWidth,
  setGlobalState,
  setAppBodyPosition,
};

export const actionTypes = {
  LOAD_GLOBAL,
  SHOW_GLOBAL_ERROR,
  SHOW_NOTIFICATION,
  SHOW_NOT_FOUND,
  PROMPT_ON_LEAVE,
  LOAD_GLOBAL_LABELS_REQUEST,
  LOAD_GLOBAL_LABELS_SUCCESS,
  LOAD_GLOBAL_LABELS_FAIL,
};

export default appReducer;
