import { LAST_7_DAYS } from "@sixsense/core/organization/components/FiscalYearDatePicker/constants";
import { css } from "styles/emotion";
import { TextColor } from "@sixsense/core/style";

export const HOMEPAGE_DASHBOARD_ROUTE = "dashboard";
export const PAGE_STATE_KEY = "HOMEPAGE_DASHBOARD";
export const LAST_UPDATED_TIMESTAMP_DATE_FORMAT = "MMM D, YYYY h:mma";
export const DEFAULT_SELECTED_DATE_PICKER_OPTION = {
  type: "relative",
  ...LAST_7_DAYS,
};

export const ADVERTISING_ENGAGEMENTS_TAB = {
  key: "advertising",
  label: "Advertising",
};
export const CE_ENGAGEMENTS_TAB = {
  key: "conversational_email",
  label: "Conversational Email",
};
export const ORCHESTRACTIONS_ENGAGEMENTS_TAB = {
  key: "orchestrations",
  label: "Orchestrations",
};

export const WORKFLOWS_ENGAGEMENTS_TAB = {
  key: "workflows",
  label: "Workflows",
};
export const MAP_CAMPAIGNS_TAB = {
  key: "map_campaigns",
  label: "MAP Campaigns",
};
export const CRM_CAMPAIGNS_TAB = {
  key: "crm_campaigns",
  label: "CRM Campaigns",
};

export const ENGAGEMENTS_TABS = [
  ADVERTISING_ENGAGEMENTS_TAB,
  WORKFLOWS_ENGAGEMENTS_TAB,
  ORCHESTRACTIONS_ENGAGEMENTS_TAB,
  // CE_ENGAGEMENTS_TAB,
  MAP_CAMPAIGNS_TAB,
  CRM_CAMPAIGNS_TAB,
];

export const WORKFLOWS_STATUS_CONFIG = {
  PUBLISHED: {
    label: "Published",
    color: "success",
  },
  ARCHIVED: {
    label: "Archived",
    color: "warning",
  },
  DRAFT: {
    label: "Draft",
    color: "warning",
  },
  HISTORICAL: {
    label: "Historical",
    color: "warning",
  },
  INACTIVE_DRAFT: {
    label: "Inactive Draft",
    color: "warning",
  },
  UNPUBLISHED: {
    label: "Unpublished",
    color: "error",
  },
};

export const WORKFLOW_SCHEDULE_MAP = {
  DAY: "Daily",
  ON_DEMAND: "On-Demand",
  WEEK: "Weekly",
};

export const DATA_ERROR_EMPTY_STATE_MESSAGE = {
  title: "Something went wrong",
  description:
    "We are having trouble getting this data. Please refresh the page or try again later.",
};

export const TRUNCATE_WITH_ELLIPSES_STYLE = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const HOVER_LINK_STYLE = css`
  cursor: pointer;
  &:hover {
    color: ${`var(${TextColor.LINK_PRIMARY_LIGHT})`};
  }
`;

export const DISCOVER_INTENT_CHART_OPTIONS = {
  type: "areaspline",
  showMarker: false,
  plotOptions: {
    series: {
      type: "areaspline",
    },
  },
  yAxisTitle: "Accounts",
  xAxisType: "datetime",
  yAxisLineWidth: 1,
  xAxisLineWidth: 1,
  showYAxisGridline: true,
  enableLegend: false,
  height: 185,
};

export const BUYING_STAGES_ICP_OPTIONS = [
  {
    label: "All ICPs",
    value: "all",
  },
  {
    label: "Strong",
    value: "Strong",
  },
  {
    label: "Moderate",
    value: "Moderate",
  },
  {
    label: "Weak",
    value: "Weak",
  },
];

export const BUYING_STAGES_SOURCES = [
  {
    label: "All Sources",
    value: "all",
  },
  {
    label: "In CRM",
    value: "crm",
  },
  {
    label: "Not in CRM",
    value: "noCrm",
  },
];

export const BUYING_STAGES_PRODUCTS = [
  {
    label: "6Sense",
    value: "6sense",
  },
  {
    label: "Sales Intelligence",
    value: "slintel",
  },
];

export const CRM_AND_MAP_CAMPAIGNS_ACCOUNTS_KEY = "accounts";
