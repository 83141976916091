
export default function createRoutes() {
  return {
    path: 'contact_details',
    name: 'contact_details',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        cb(null, containers.ContactDetails);
      }, 'contact_details');
    },
  };
}

