import { replace } from 'lodash';
import { CREDIT_MSGS } from '../../constants';
import { commaSeparatedNumber } from '../../utils';

export const validateCreditDistribution = (
  formValues,
  fieldConfiguration,
  totalAvailableCredits,
) => {
  const errors = {};
  let totalSum = 0;

  // Iterate through each field in the formValues
  fieldConfiguration.forEach((config) => {
    const value = parseFloat(formValues[config.name]);

    // Check if the field is required and greater than  0
    if (isNaN(value)) {
      errors[config.name] = CREDIT_MSGS.DISTRIBUTE_CREDIT_REQUIRED;
    } else if (value < 0 || value % 1 !== 0) {
      errors[config.name] = CREDIT_MSGS.CREDIT_GREATER_THAN_ZERO;
    } else {
      totalSum += value;
    }
  });

  // Check if the total sum of all fields is less than total Remaining Credits
  if (totalSum > totalAvailableCredits) {
    errors.sum = replace(
      CREDIT_MSGS.DISTRIBUTE_CREDIT_MAX_ERR,
      '{total}',
      commaSeparatedNumber(totalAvailableCredits));
  }

  if (totalSum === 0) {
    errors.zero_credits = CREDIT_MSGS.DISTRIBUTE_CREDIT_MIN_ERR;
  }

  return errors;
};
