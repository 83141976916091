import React from "react";
import { Text } from "@sixsense/core/components";
import { NA } from "./common/NA";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";
import { get } from "lodash";
import { CAMPAIGN_DESCRPITORS_FLAG } from "routes/AdvertisingDS/featureGating/constants";
import { CAMPAIGN_SOURCE } from "../../../constants";

export const PURCHASE_ORDER = {
  key: "purchase-order",
  csvColumnKey: "purchase_order",
  title: <Label name={CAMPAIGN_LABELS.purchaseOrder} />,
  dataIndex: "purchase_order",
  featureFlagKey: CAMPAIGN_DESCRPITORS_FLAG,
  width: pxToRem(200),
  render: (purchase_order, campaign) => {
    if (
      campaign.campaign_source === CAMPAIGN_SOURCE.EXTERNAL ||
      !purchase_order
    ) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {get(purchase_order, "name")}
      </Text.Body>
    );
  },
  align: "left",
};
