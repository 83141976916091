import { COLORS } from 'v2-styles/constants';
import { isDaylightSavings } from 'utils/utils';

export const ANALYTIC_TYPES = Object.freeze({
  TREND: 'date',
  TIME: 'time',
  IMPRESSION: 'impression',
  VIEWABILITY: 'viewability',
  DEVICE: 'device',
  DOMAIN: 'domain',
  JLJF: 'jljf',
  VALUE_METRICS: 'value_metrics',
  PERFORMANCE: 'performance',
});

export const ANALYTIC_TYPES_DISPLAY_LABELS = Object.freeze({
  date: 'Daily Campaign Performance',
  domain: 'Domain Performance',
  value_metrics: 'Value Metrics',
  jljf: 'Job Level and Job Function Performance',
});

export const VALUE_METRICS_CATEGORIES = [
  'Reached',
  'Engaged',
  'Increased Engagement',
  'Pipeline',
  'Closed Won',
];

export const VALUE_METRICS_STATS_REQUIRED = [
  'reached_accounts',
  'engaged_accounts',
  'increased_engagement_accounts',
  'pipeline_accounts',
  'closed_won_accounts',
  'closed_won_pipeline',
  'total_pipeline',
  'pipeline_opportunities',
];

export const VALUE_METRICS_META_REQUIRED = [
  'campaign_spend',
  'total_pipeline',
  'closed_won_pipeline',
];

export const DEFAULT_VALUE_METRICS_DATA = {
  STATS_REQUIRED: {
    reached_accounts: 0,
    engaged_accounts: 0,
    increased_engagement_accounts: 0,
    pipeline_accounts: 0,
    closed_won_accounts: 0,
    closed_won_pipeline: 0,
    total_pipeline: 0,
    pipeline_opportunities: 0,
  },
  META_REQUIRED: {
    campaign_spend: 0,
    total_pipeline: 0,
    closed_won_pipeline: 0,
  },
};

export const DISTRIBUTION_ANALYTICS = new Set([
  ANALYTIC_TYPES.TIME,
  ANALYTIC_TYPES.DEVICE,
  ANALYTIC_TYPES.DOMAIN,
  ANALYTIC_TYPES.TREND,
]);

export const US = 'United States';
export const eCPC_LABEL = 'eCPC';

export const DISPLAY_LABELS = Object.freeze({
  impression_count: 'Impressions',
  click_count: 'Clicks',
  account_count: 'Accounts Reached',
  audience_count: 'Audience',
  conversions: 'Conversions',
  spend: 'Spend',
  cpm: 'CPM',
  cpc: eCPC_LABEL,
  cost_per_conversion: 'Cost/Conversion',
  accounts_newly_engaged: 'Accounts Newly Engaged',
  accounts_with_increased_engagement: 'Accounts with Increased Engagement',
});

export const VALUE_METRICS_DISPLAY_LABELS = Object.freeze({
  Reached: 'Reached Accounts',
  Engaged: 'Engaged Accounts',
  'Increased Engagement': 'Increased Engagement',
  Pipeline: 'Pipeline Accounts',
  'Closed Won': 'Closed Won Accounts',
});

// NOTE: avoid using this const
export const statsSequence = [
  'account_count',
  'audience_count',
  'impression_count',
  'click_count',
];

export const GRAPH_COLORS = Object.freeze({
  impression_count: COLORS.AA_YELLOW,
  click_count: COLORS.AA_MAROON,
  account_count: COLORS.AA_6SENSE_BLUE,
  viewable: COLORS.AA_BLUE,
  notViewable: COLORS.AA_GREEN,
  audience_count: COLORS.AA_6SENSE_GREEN,
});

export const accountSortOptions = [
  { label: 'Name', value: 'firm_name' },
  { label: 'Impressions', value: 'impression_count' },
  { label: 'Clicks', value: 'click_count' },
  { label: 'Spend', value: 'spend' },
  { label: 'Latest Impression', value: 'latest_impression' },
  { label: 'Influenced Form Fills', value: 'form_fill_count' },
];

export const accountFilterOptions = [
  {
    optGroupLabel: 'Engagement type',
    options: [
      { label: 'Newly Engaged', value: 'newly_engaged' },
      { label: 'Increased Engagement', value: 'increased_engagement' },
    ],
  },
  // {
  //   optGroupLabel: 'Conversion type',
  //   options: [
  //     { label: 'Has Conversions', value: 'has_conversions' },
  //   ],
  // },
];

export const filterTags = {
  newly_engaged: 'Newly Engaged',
  increased_engagement: 'Increased Engagement',
  has_conversions: 'Has Conversions',
};

export const headerTabItems = ['Performance', 'AdGroups', 'Ads', 'Configuration'];

export const CAMPAIGN_CONFIG_FORM = 'CAMPAIGN_CONFIG_FORM';

const isDst = isDaylightSavings();
const tzLabel = isDst ? 'Daylight' : 'Standard';
export const TIME_DISTRIBUTION_TIMEZONES = [
  { label: 'UTC', value: 'UTC' },
  { label: `Eastern ${tzLabel} Time (UTC-0${isDst ? 4 : 5}:00)`, value: 'America/New_York' },
  { label: `Central ${tzLabel} Time (UTC-0${isDst ? 5 : 6}:00)`, value: 'America/Chicago' },
  { label: `Mountain ${tzLabel} Time (UTC-0${isDst ? 6 : 7}:00)`, value: 'America/Denver' },
  { label: `Pacific ${tzLabel} Time (UTC-0${isDst ? 7 : 8}:00)`, value: 'America/Los_Angeles' },
];

export const CURRENCY_STATS = ['Spend', 'CPM', 'CPC'];

export const UNFILTERED_CHARTS = ['VALUE_METRICS'];

export const ANALYTICS_TYPES_DOWNLOAD_HEADER = {
  [ANALYTIC_TYPES.TREND]: [
    'Date',
    'Account reached',
    'Impression',
    'Clicks',
    'Spend',
    'CPM',
    eCPC_LABEL,
  ],
  [ANALYTIC_TYPES.DOMAIN]: [
    'Domain',
    'Accounts reached',
    'Impressions',
    'Clicks',
  ],
  [ANALYTIC_TYPES.JLJF]: [
    'Job Level and Job Function',
    'Accounts Reached',
    'Impressions',
    'Clicks',
  ],
};

export const PIPE_ONLY_REV = 'PipeOnlyRev_ALLStatus';
export const ALL_REV = 'AllRev_ALLStatus';

export const VIEWABILITY_DEFINITION =
  'Percentage of ad impressions that were measured as viewable by the user.';


export const LINKEDIN_JOB_PROFILE_VALUES = {
  jobLevel: 'job_level',
  jobFunction: 'job_function',
};

export const LINKEDIN_JOB_PROFILE_OPTIONS = [
  {
    label: 'Job Level Distribution',
    value: LINKEDIN_JOB_PROFILE_VALUES.jobLevel,
  },
  {
    label: 'Department Distribution',
    value: LINKEDIN_JOB_PROFILE_VALUES.jobFunction,
  },
];

export const LINKEDIN_JOB_PROFILE_AVG_CTR_KEY = 'Avg CTR';

export const LINKEDIN_JOB_PROFILE_DOWNLOAD_HEADERS = {
  [LINKEDIN_JOB_PROFILE_VALUES.jobLevel]: [
    'Job Level',
    'Impressions',
    'Clicks',
    LINKEDIN_JOB_PROFILE_AVG_CTR_KEY,
  ],
  [LINKEDIN_JOB_PROFILE_VALUES.jobFunction]: [
    'Job Function',
    'Impressions',
    'Clicks',
    LINKEDIN_JOB_PROFILE_AVG_CTR_KEY,
  ],
};

export const LINKEDIN_JOB_PROFILE_DOWNLOAD_REPORT_LABEL = {
  [LINKEDIN_JOB_PROFILE_VALUES.jobLevel]: LINKEDIN_JOB_PROFILE_OPTIONS[0].label,
  [LINKEDIN_JOB_PROFILE_VALUES.jobFunction]: LINKEDIN_JOB_PROFILE_OPTIONS[1].label,
};
