import { Permission } from '@sixsense/rbac';

const PAGE_STATE_KEY = 'firstPartyConsent';
const FIRST_PARTY_CONSENT_PAGE_ROUTE = 'firstPartyUserConsent';
const FIRST_PARTY_CONSENT_STATE_KEY = 'firstPartyConsentState';
const USER_CONSENT_DENIED_FILE_LIST_STATE_KEY = 'userConsentDeniedFileListState';
const USER_CONSENT_DENIED_FILE_UPLOAD_STATE_KEY = 'userConsentDeniedFileListUploadState';

const FIRST_PARTY_CONSENT_CHOICES = {
  UNSPECIFIED: 'unspecified',
  GRANTED: 'granted',
};

const KILOBYTE = 1024;

const SAMPLE_TEMPLATE_FIRST_PARTY_CONTACTS =
  '/org/retrieve_s3object?filename=ExcludedContactsListSample.csv';

const FIRST_PARTY_CONSENT_UPDATE_PERMISSION = Permission.GOOGLE_ADS_INTEGRATION_CONSENT_EDIT;

export {
  PAGE_STATE_KEY,
  FIRST_PARTY_CONSENT_PAGE_ROUTE,
  FIRST_PARTY_CONSENT_STATE_KEY,
  USER_CONSENT_DENIED_FILE_LIST_STATE_KEY,
  FIRST_PARTY_CONSENT_CHOICES,
  USER_CONSENT_DENIED_FILE_UPLOAD_STATE_KEY,
  KILOBYTE,
  SAMPLE_TEMPLATE_FIRST_PARTY_CONTACTS,
  FIRST_PARTY_CONSENT_UPDATE_PERMISSION,
};
