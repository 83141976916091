import { find, forEach, get, isEmpty, join } from 'lodash';

const COLUMNS_STORAGE_KEY = 'taxonomyv2MappingColumns';
const TAXONOMY_PAGE_SIZE = 'taxonomyv2PageSize';

const getKey = (localStorageKeyValues) =>
  join([COLUMNS_STORAGE_KEY, ...localStorageKeyValues], '.');

export const getDefaultColumnsWithHidden = (columns) => {
  const mappedColumns = [];
  forEach(columns, ({ defaultHidden, ...rest }) => {
    mappedColumns.push({
      ...rest,
      defaultHidden,
      hiddenForView: !!defaultHidden,
    });
  });

  return mappedColumns;
};

/**
 * Methods check if the columns for given config is saved in local storage.
 * If it is saved, then returns array on columns with updated order.
 *
 * @param {Array} columns
 * @param {Array} localStorageKeyValues
 * @returns Array
 */
export const initializeColumns = (columns, localStorageKeyValues) => {
  const savedColumns = JSON.parse(
    localStorage.getItem(getKey(localStorageKeyValues))
  );
  const mappedColumns = [];

  if (isEmpty(savedColumns)) {
    return getDefaultColumnsWithHidden(columns);
  }

  forEach(savedColumns, ({ key, hiddenForView }) => {
    const col = find(columns, { key });

    if (col) {
      mappedColumns.push({
        ...col,
        hiddenForView,
      });
    }
  });

  // If new column is added in the columns, reset the saved list and return the default columns.
  // This will only happen in case future column addition.
  if (mappedColumns.length < columns.length) {
    localStorage.removeItem(getKey(localStorageKeyValues));
    return getDefaultColumnsWithHidden(columns);
  }

  return mappedColumns;
};

/**
 * Method updates the order of columns as per sorted columns and saves the same in local storage.
 *
 * @param {Array} columns
 * @param {Array} sortedColumns
 * @param {Array} localStorageKeyValues
 * @returns Array
 */
export const updateColumns = (
  columns,
  sortedColumns,
  localStorageKeyValues
) => {
  const mappedColumns = sortedColumns.map(({ key, hiddenForView }) => ({
    ...find(columns, (c) => c.key === key),
    hiddenForView,
  }));

  const columnsToSave = mappedColumns.map(({ key, hiddenForView }) => ({
    key,
    hiddenForView,
  }));

  localStorage.setItem(
    getKey(localStorageKeyValues),
    JSON.stringify(columnsToSave)
  );

  return mappedColumns;
};
/**
 * Method resets the local storage key, and initialize columns using source columns
 *
 * @param {Array} columns - source columns
 * @param {Array} localStorageKeyValues - [classification_type, data_sourc]
 * @returns
 */
export const resetColumns = (columns, localStorageKeyValues) => {
  localStorage.removeItem(getKey(localStorageKeyValues));
  return getDefaultColumnsWithHidden(columns);
};

/**
 * Method sets the pageSize in localStorage
 * @returns Number
 */
export const updatePageSize = (pageSize) => {
  localStorage.setItem(TAXONOMY_PAGE_SIZE, pageSize);
  return pageSize;
};

/**
 * Method returns the pageSize from localStorage
 * @returns Number
 */
export const initPageSize = () => {
  let pageSize = localStorage.getItem(TAXONOMY_PAGE_SIZE);

  if (!pageSize) {
    pageSize = 50;
  }

  return pageSize;
};

/**
 * returns the first non empty value from object for keys in fields
 *
 * @param {Object} record
 * @param {Array} fields
 * @returns
 */
export const coalesceEmpty = (record, fields) => {
  let value;
  // eslint-disable-next-line consistent-return
  forEach(fields, (key) => {
    value = get(record, key);
    if (!isEmpty(value)) return false;
  });

  return value;
};
