import { createSelector } from 'reselect';
import { orgSelector, isPredictiveSelector, is6SenseOrgSelector } from 'modules/user/selectors';
import { routePathnameSelector } from 'modules/global/selectors';
import {
  cloneDeep,
  filter,
  flow,
  get,
  isEmpty,
  keys,
  mapValues,
  merge,
  reduce,
  some,
  values,
  pick,
  find,
  every,
} from 'lodash';
import entriesfp from 'lodash/fp/entries';
import mapfp from 'lodash/fp/map';
import sortByfp from 'lodash/fp/sortBy';
import fromPairsfp from 'lodash/fp/fromPairs';
import mergefp from 'lodash/fp/merge';
import { ANALYTIC_TYPES,
  DISPLAY_LABELS,
  DISTRIBUTION_ANALYTICS,
  GRAPH_COLORS,
  VALUE_METRICS_STATS_REQUIRED,
  VALUE_METRICS_META_REQUIRED,
  DEFAULT_VALUE_METRICS_DATA,
  statsSequence,
  PIPE_ONLY_REV,
  ALL_REV,
} from './constants';

import {
  camelToDisplay,
  capitalize,
  coerceLocaleString,
  numberToDollar,
  safePercent,
} from 'utils/utils';
import { placementGroupsSelectors } from '../AdGroups/selectors';
import { DOWNLOAD_ACCOUNTS_MAX } from 'utils/constants';
import { formatViewabilityPercent, getDomainStatsData, getJLJFStatsData } from 'routes/Advertising/routes/Campaigns/utils';
import momentTz from 'moment-timezone';
import moment from 'moment';
import { getFormattedDate, getAvailableDataEndDate } from './utils';
import { CAMPAIGN_SOURCE } from '../../constants';

const {
  TREND,
  TIME,
  IMPRESSION,
  VIEWABILITY,
  DEVICE,
  DOMAIN,
  JLJF,
  VALUE_METRICS,
  PERFORMANCE,
} = ANALYTIC_TYPES;

export const campaignAnlayticsSelector = (state) => state.campaignAnalytics;

const placementGroupSelector = createSelector(
  placementGroupsSelectors,
  (plGroup) => plGroup
);

export const campaignDetailsSelector = (state) =>
  get(state, 'createCampaign.newCampaign.campaign', {});

const placementAnalyticsSelector = createSelector(
  campaignAnlayticsSelector,
  (analytics) => analytics.placementAnalytics
);

export const saveModalStateSelector = createSelector(
  placementAnalyticsSelector,
  ({ saveModalState } = {}) => saveModalState
);

export const adPlacementSelector = createSelector(
  placementAnalyticsSelector,
  ({ adPlacement } = {}) => adPlacement
);

const downloadAccountsStateSelector = createSelector(
  campaignAnlayticsSelector,
  (analytics) => analytics.download
);

export const showDownloadModalSelector = createSelector(
  downloadAccountsStateSelector,
  (dlState) => dlState.showDownloadModal
);

export const downloadAccountsLoadingSelector = createSelector(
  downloadAccountsStateSelector,
  (dlState) => dlState.loading
);

export const downloadAccountsErrorSelector = createSelector(
  downloadAccountsStateSelector,
  (dlState) => dlState.error
);

export const accountListSelector = (state) => state.campaignAnalytics.accountList.accountList;
export const sortBySelector = (state) => state.campaignAnalytics.accountList.sortBy;
export const offsetSelector = (state) => state.campaignAnalytics.accountList.offset;
export const accounstListErrorSelector = (state) => state.campaignAnalytics.accountList.error;
export const totalCountSelector = (state) => state.campaignAnalytics.accountList.totalCount;
export const filtersAppliedSelector = (state) => state.campaignAnalytics.accountList.filtersApplied;

export const accountListFullLoadingSelector = (state) =>
  state.campaignAnalytics.accountList.loading && state.campaignAnalytics.accountList.offset === 0;

export const accountListPartialLoadingSelector = (state) =>
  state.campaignAnalytics.accountList.loading;

export const campaignIDSelector = createSelector(
  routePathnameSelector,
  (path) => {
    try {
      return parseInt(
        path
          .replace(/\/$/, '')
          .split('/')
          .reverse()[0],
        10
      );
      // parseInt(path.match(/\/campaigns\/create\/(-?\w+)\/?/)[1], 10);
    } catch (e) {
      return undefined;
    }
  }
);

const isSavedCampaignSelector = createSelector(
  campaignIDSelector,
  (campaignId) => !([NaN].includes(campaignId))
);

export const accountListLoadedSelector = (state) => state.campaignAnalytics.accountList.loaded;

export const isExternalCampaignSelector = createSelector(
  campaignDetailsSelector,
  (campaignDetails) => campaignDetails.campaign_source === CAMPAIGN_SOURCE.EXTERNAL
);

export const campaignSourceSelector = createSelector(
  campaignDetailsSelector,
  (campaignDetails) => campaignDetails.campaign_source
);

export const linkedinJobProfileTypeSelector = createSelector(
  campaignAnlayticsSelector,
  campaignSourceSelector,
  ({ analytics }, campaignSource) =>
    campaignSource === CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING ?
    get(analytics, 'linkedinJobProfileType') :
    null
);

export const analyticsEndpointSelector = (analytic, selectedDateRange) =>
  createSelector(
    orgSelector,
    campaignIDSelector,
    campaignSourceSelector,
    linkedinJobProfileTypeSelector,
    (orgId, campaignId, campaignSource, linkedinProfileType) => {
      let baseUrl = DISTRIBUTION_ANALYTICS.has(analytic)
        ? `query/${orgId}/campaign/${campaignId}/analytics/distribution`
        : `query/${orgId}/campaign/${campaignId}/analytics`;
      if (analytic === JLJF && isEmpty(selectedDateRange)) {
        if (campaignSource === CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING) {
          baseUrl = `${baseUrl}/profile/?profile_type=${linkedinProfileType}`;
        } else {
          baseUrl = `${baseUrl}/profile/`;
        }
      } else if (analytic === VALUE_METRICS) baseUrl = `${baseUrl}/value_metrics/`;
      else if (!isEmpty(selectedDateRange)) {
        const [startDate, endDate] = getFormattedDate(selectedDateRange);
        if (analytic === JLJF) {
          if (campaignSource === CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING) {
            baseUrl = `${baseUrl}/profile/?start_date=${startDate}&end_date=${endDate}&profile_type=${linkedinProfileType}`;
          } else {
            baseUrl = `${baseUrl}/profile/?start_date=${startDate}&end_date=${endDate}`;
          }
        } else baseUrl = `${baseUrl}/${analytic}/?start_date=${startDate}&end_date=${endDate}`;
      } else baseUrl = `${baseUrl}/${analytic}/`;

      return baseUrl;
    }
  );

export const placementsEndpointSelector = createSelector(
  orgSelector,
  campaignIDSelector,
  (orgId, campaignId) => `query/${orgId}/campaign/${campaignId}/analytics`
);

export const endpointSelector = (forDownload, byCRMId) =>
  createSelector(
    campaignAnlayticsSelector,
    campaignIDSelector,
    orgSelector,
    sortBySelector,
    (campaignAnalytics, campaignID, orgID, sortByField) => {
      const { limit, offset, filtersApplied } = campaignAnalytics.accountList;
      let extraFields = '';
      if (byCRMId) {
        extraFields = 'external_id';
      }
      // if (isVideoCampaign) {
      //   extraFields.push('video_stats');
      // }
      // extraFields = extraFields.join(', ');
      const engagementIn = filtersApplied.length
        ? filtersApplied.map(camelToDisplay).join(',')
        : '';
      const respLimit = forDownload ? DOWNLOAD_ACCOUNTS_MAX : limit;
      const respOffset = forDownload ? 0 : offset;
      /* eslint max-len: [2, 1000] */
      return `query/${orgID}/campaign/${campaignID}/analytics/?limit=${respLimit}&offset=${respOffset}&sort_by=${sortByField}${
        engagementIn ? `&engagement_in=${engagementIn}` : ''
      }${extraFields ? `&extra_fields=${extraFields}` : ''}`;
    }
  );

export const caTimeZoneSelector = createSelector(
  placementAnalyticsSelector,
  ({ zone }) => zone
);

export const selectedDateRangeSelector = createSelector(
  placementAnalyticsSelector,
  ({ selectedDateRange }) => selectedDateRange
);

export const chartOptionsSelector = createSelector(
  placementAnalyticsSelector,
  ({ chartOptions }) => chartOptions
);

export const adsFilterOptionsSelector = createSelector(
  placementAnalyticsSelector,
  ({ adsFilterOptions }) => adsFilterOptions
);

export const selectedDateRangeForAdsSelector = createSelector(
  placementAnalyticsSelector,
  ({ selectedDateRangeForAds }) => selectedDateRangeForAds
);

export const analyticSelector = (analytic) =>
  createSelector(
    campaignAnlayticsSelector,
    ({ analytics }) => (isEmpty(analytics[analytic]) ? [] : analytics[analytic])
  );

export const trendDistributionSelector = createSelector(
  analyticSelector(TREND),
  ({ results = {} }) =>
    flow(
      entriesfp,
      sortByfp([([day]) => day])
    )(results)
);

export const disableDownloadingCampaignAnalyticsSelector = (analytic) => createSelector(
  analyticSelector(analytic),
  ({ results = {} }) => isEmpty(results)
);

export const campaignPerformanceDistributionSelector = createSelector(
  analyticSelector(PERFORMANCE),
  ({ results = {} }) => {
    if (isEmpty(results)) return ({ ctr: 0, impression_count: 0, account_ctr: 0, audience_count: 0, account_vtr: 0, account_count: 0, cpm: 0, cpc: 0, spend: 0 });
    const { ctr, account_ctr, audience_count, account_vtr, impression_count, account_count, cpm, cpc, spend } = results[0];
    return {
      ctr,
      account_ctr,
      account_vtr,
      audience_count,
      impression_count: coerceLocaleString(Math.round(impression_count)),
      account_count: coerceLocaleString(Math.round(account_count)),
      cpm: `$${coerceLocaleString(cpm.toFixed(2))}`,
      cpc: `$${coerceLocaleString(cpc.toFixed(2))}`,
      spend: `$${coerceLocaleString(spend.toFixed(2))}`,
    };
  }
);

export const valueMetricsDistributionSelector = createSelector(
  analyticSelector(VALUE_METRICS),
  ({ results = [], exclude }) => {
    const { STATS_REQUIRED } = DEFAULT_VALUE_METRICS_DATA;
    const filteredResults = results.filter((result) => {
      if (exclude) {
        return result.filter_flag === PIPE_ONLY_REV;
      }
      return result.filter_flag === ALL_REV;
    });
    const noPipelineData = find(filteredResults, ({ existing_account }) => existing_account === 0);
    const pipelineData = find(filteredResults, 'existing_account');
    const dataWithNoPipeline = isEmpty(noPipelineData) ? STATS_REQUIRED :
    pick(noPipelineData, VALUE_METRICS_STATS_REQUIRED);
    const dataWithPipeline = isEmpty(pipelineData) ? STATS_REQUIRED :
    pick(pipelineData, VALUE_METRICS_STATS_REQUIRED);

    return { dataWithPipeline, dataWithNoPipeline };
  }
);

export const isDownloadingValueMetricsReportSelector = createSelector(
  campaignAnlayticsSelector,
  ({ downloadValueMetrics }) => !!(downloadValueMetrics && downloadValueMetrics.isDownloading)
);

export const disableVMReportDownloadSelector = createSelector(
  valueMetricsDistributionSelector,
  (valueMetricsDistribution) => every(values(valueMetricsDistribution.dataWithNoPipeline), (item) => item === 0)
);

export const valueMetricsMetaDistributionSelector = createSelector(
  analyticSelector(VALUE_METRICS),
  ({ results = [] }) => {
    const { META_REQUIRED } = DEFAULT_VALUE_METRICS_DATA;
    const filteredResults = results.filter((result) => result.filter_flag === ALL_REV);
    const noPipelineData = find(filteredResults, ({ existing_account }) => existing_account === 0);
    const pipelineData = find(filteredResults, 'existing_account');
    const dataWithNoPipeline = isEmpty(noPipelineData) ? META_REQUIRED :
    pick(noPipelineData, VALUE_METRICS_META_REQUIRED);
    const dataWithPipeline = isEmpty(pipelineData) ? META_REQUIRED :
    pick(pipelineData, VALUE_METRICS_META_REQUIRED);

    return { dataWithPipeline, dataWithNoPipeline };
  }
);

export const valueMetricsDistributionLoadingSelector = createSelector(
  analyticSelector(VALUE_METRICS),
  ({ loading }) => loading
);

export const valueMetricsExcludeSelector = createSelector(
  analyticSelector(VALUE_METRICS),
  ({ exclude }) => exclude
);

function padToTwoDigits(i) {
  return i <= 9 ? `0${i}` : i;
}

function countUniques(arr) {
  const obj = {};
  arr.forEach((e) => { obj[e] = true; });
  return Object.keys(obj).length;
}

function serialize(hourlyAggregatedStats, defaultStatsValue, statsConfig) {
  const final = [];
  for (let hour = 0; hour < 24; hour++) {
    const hourStat = get(hourlyAggregatedStats, [hour], defaultStatsValue);
    const serialisedStats = statsConfig.map((stat) => stat.key)
      .reduce((acc, statKey) => {
        const serializerFunction = statsConfig.find((stats) => stats.key === statKey).serialise;
        const stat = hourStat[statKey];
        return ({ ...acc, [statKey]: serializerFunction(stat) });
      }, {});
    final.push(serialisedStats);
  }
  return final;
}

function formatStatsForChart(final, statsConfig) {
  const labelsMap = statsConfig.reduce((acc, { key, label }) => ({
    ...acc,
    [key]: label,
  }), {});

  const replaceKeysWithLabels = (stats) =>
    Object.keys(stats).reduce((acc, statKey) => ({
      ...acc,
      [labelsMap[statKey]]: stats[statKey],
    }), {});

  return final.map((stat, hourOfDay) => ([
    momentTz(hourOfDay, 'hh').format('ha'),
    replaceKeysWithLabels(stat),
  ]));
}

const getSerializedTimeData = (results, zone, statsConfig) => {
  const defaultStatsValue = statsConfig.map((stat) => stat.key)
    .reduce((acc, statKey) =>
      ({ ...acc, [statKey]: statsConfig.find((stats) => stats.key === statKey).defaultValue }), {});
  const hourlyAggregatedStats = results.reduce((accumulator, { date, stats }) => {
    const updatedAccumulator = {};

    for (let hour = 0; hour < 24; hour++) {
      const hourInTimezone = momentTz.utc(`${date}T${padToTwoDigits(hour)}`).tz(zone).hour();

      const hourStats = get(stats, [hour], defaultStatsValue);
      const currentStatsInTimezone = get(accumulator, [hourInTimezone], defaultStatsValue);

      updatedAccumulator[hourInTimezone] = statsConfig.map((stat) => stat.key)
        .reduce((acc, statKey) => {
          const addFunction = statsConfig.find((stat) => stat.key === statKey).add;
          return ({
            ...acc,
            [statKey]: addFunction(currentStatsInTimezone[statKey], hourStats[statKey]),
          });
        }, {});
    }

    return ({
      ...updatedAccumulator,
    });
  }, {});
  return serialize(hourlyAggregatedStats, defaultStatsValue, statsConfig);
};

const getTimeStatsData = (results, zone, type) => {
  const statsConfig = (() => {
    switch (type) {
      case CAMPAIGN_SOURCE.RETARGETING: {
        return [
          {
            defaultValue: [],
            add: (a, b) => ([...a, ...b]),
            serialise: countUniques,
            label: 'Accounts',
            key: 'mids',
          },
          {
            defaultValue: [],
            add: (a, b) => ([...a, ...b]),
            serialise: countUniques,
            label: 'Audience',
            key: 'audiences',
          },
          {
            defaultValue: 0,
            add: (a, b) => a + b,
            serialise: (a) => a,
            label: 'Impressions',
            key: 'impression_count',
          },
          {
            defaultValue: 0,
            add: (a, b) => a + b,
            serialise: (a) => a,
            label: 'Clicks',
            key: 'click_count',
          },
        ];
      }
      default: {
        return [
          {
            defaultValue: [],
            add: (a, b) => ([...a, ...b]),
            serialise: countUniques,
            label: 'Accounts',
            key: 'mids',
          },
          {
            defaultValue: 0,
            add: (a, b) => a + b,
            serialise: (a) => a,
            label: 'Impressions',
            key: 'impression_count',
          },
          {
            defaultValue: 0,
            add: (a, b) => a + b,
            serialise: (a) => a,
            label: 'Clicks',
            key: 'click_count',
          },
        ];
      }
    }
  })();
  const serialised = getSerializedTimeData(results, zone, statsConfig);
  return formatStatsForChart(serialised, statsConfig);
};

export const timeDistributionSelector = createSelector(
  analyticSelector(TIME),
  caTimeZoneSelector,
  campaignSourceSelector,
  ({ results = [] }, zone, campaignSource) => getTimeStatsData(results, zone, campaignSource)
);

export const impressionsSelector = createSelector(
  analyticSelector(IMPRESSION),
  ({ results = [] }) => {
    const defaultImpressionRanges = {
      '0-9': null,
      '10-19': null,
      '20-49': null,
      '50-99': null,
      '100-249': null,
      '250-499': null,
      '500-999': null,
      '1000-4999': null,
      '5000-10000': null,
      '10000+': null,
    };

    return flow(
      fromPairsfp,
      mergefp(defaultImpressionRanges),
      entriesfp,
      mapfp(([key, value]) => ({
        key,
        value,
      })),
    )(results).map(({ value, ...rest }) => {
      if (value === 0) return { value: null, ...rest };

      return { value, ...rest };
    });
  }
);

export const measurabilitySelector = createSelector(
  analyticSelector(VIEWABILITY),
  ({ results: [[, measurability]] = [[]] } = {}) =>
    measurability ? `${+(measurability * 100).toFixed(2)}% Measurable` : ''
);

export const viewabilitySelector = createSelector(
  analyticSelector(VIEWABILITY),
  ({ results: [[viewable]] = [[]] } = {}) =>
    viewable
      ? [
        {
          name: 'Viewable',
          y: +(viewable * 100).toFixed(2),
          color: GRAPH_COLORS.viewable,
        },
        {
          name: 'Not Viewable',
          y: +((1 - viewable) * 100).toFixed(2),
          color: GRAPH_COLORS.notViewable,
        },
      ]
      : []
);

export const deviceDistributionSelector = createSelector(
  analyticSelector(DEVICE),
  ({ results = {} }) =>
    flow(
      entriesfp,
      sortByfp([([, { account_count: ct }]) => ct]),
      mapfp(([device, stats]) => ({
        device,
        y: stats.account_count,
        // NOTE: dirty hacks to get stats in needed sequence, find a better way !
        other: statsSequence.map((key, index) => {
          if (stats[key] === undefined) {
            return null;
          }

          return ({
            name: DISPLAY_LABELS[key],
            y: stats[key],
            color: GRAPH_COLORS[key],
            legendIndex: 4 - index,
          });
        }).filter((stat) => stat),
      }))
    )(results)
);

export const domainDistributionSelector = createSelector(
  analyticSelector(DOMAIN),
  campaignSourceSelector,
  ({ results }, campaignSource) => getDomainStatsData(results, campaignSource)
);

export const jljfDistributionSelector = createSelector(
  analyticSelector(JLJF),
  campaignSourceSelector,
  ({ results }, campaignSource) => getJLJFStatsData(results, campaignSource)
);

export const placementQueueSelector = createSelector(
  placementAnalyticsSelector,
  ({ queue, retrieved }) => ({ queue, retrieved })
);

export const newPlacementGroupSelector = createSelector(
  placementAnalyticsSelector,
  ({ newAdGroups }) => newAdGroups
);

export const getNewAdGroupNamesSelector = createSelector(
  newPlacementGroupSelector,
  (newAdGroups) => newAdGroups.map(({ placement_group_name }) => placement_group_name)
);

export const placementsEmptyCheckSelector = createSelector(
  newPlacementGroupSelector,
  (newAdGroups) => some(newAdGroups, (group) => group.placements.length === 0)
);

const placementDetails = (pgId, placements) =>
  reduce(
    placements,
    (acc, pl) => {
      const { id, name, is_deleted, external_id, creative = {} } = pl;
      let auditValue = get(pl, 'network_state.placement.state', 'pending');
      let auditMsg = get(pl, 'network_state.placement.msg', '');
      let s3_url = '';
      if (creative) {
        s3_url = creative.s3_url;
      }
      switch (auditValue) {
        case 'error':
          auditValue = 'failed';
          auditMsg = auditMsg[0];
          break;
        case 'unknown':
          auditValue = 'pending';
          break;
        default:
          break;
      }
      return {
        ...acc,
        [id]: {
          name,
          external_id: external_id === null ? '-' : external_id,
          placement_id: id,
          placement_group_id: pgId,
          status: is_deleted,
          url: s3_url,
          auditStatus: capitalize(auditValue),
          meta: { ...pl, auditMessage: auditMsg },
        },
      };
    },
    {}
  );

// only keep those placements which has analytics
const paWithDetailsSelector = createSelector(
  placementAnalyticsSelector,
  placementGroupSelector,
  newPlacementGroupSelector,
  ({ results } = {}, placementGroup = [], newPlacementGroup = []) => {
    const deepClonedAnalytics = cloneDeep(results);
    const placementsDetails = reduce(
      [...newPlacementGroup, ...placementGroup],
      (total, pg) => {
        const {
          id,
          placements,
          deleted_placements = [],
          is_deleted,
          placement_group_name,
        } = pg;
        if (is_deleted && deepClonedAnalytics[id] === undefined) {
          return total;
        }
        const placementInAnalytics = keys(get(deepClonedAnalytics, `${id}.placements`, []));
        const actualDeletedPlacemnts = filter(placements, { is_deleted: true });
        const checkPlacmntsInAnalytics = placementInAnalytics.length
          ? filter(
            [...actualDeletedPlacemnts, ...deleted_placements],
            ({ id: plId }) => placementInAnalytics.indexOf(String(plId)) !== -1
          ).map((pl) => ({ ...pl, is_deleted: true })) // marked as deleted forcefully to show the status
          : [];
        let uniqPlacements = [
          // ...placements,
          // ...deleted_placements.map((pl) => ({ ...pl, is_deleted: true })),
          ...filter(placements, { is_deleted: false }),
          ...checkPlacmntsInAnalytics,
        ];
        if (is_deleted) {
          // forcefully delete all placements if ad group is deleted
          uniqPlacements = [...uniqPlacements.map((pl) => ({ ...pl, is_deleted: true }))];
        }

        // let allPlacmntsNotDeleted = some(uniqPlacements, (pl) => pl.is_deleted === false);
        // if (isUnsaved) allPlacmntsNotDeleted = true;
        return {
          ...total,
          [id]: {
            placements: { ...placementDetails(id, uniqPlacements) },
            total: {
              placement_group_name,
              status: is_deleted, // allPlacmntsNotDeleted ? is_deleted : true,
              meta: { ...pg },
            },
          },
        };
      },
      {}
    );
    return merge(deepClonedAnalytics, placementsDetails);
  }
);

// TODO: refactor to make it possible for humans to understand
export const paSelector = createSelector(
  paWithDetailsSelector,
  isExternalCampaignSelector,
  (results = {}, isExternalCampaign) => mapValues(results, (placementGroup = {}) => {
    const pgStatus = get(placementGroup, 'total.status', false);
    const pgMeta = get(placementGroup, 'total.meta', {});
    const formattedPlacementGroup = reduce(
      placementGroup.placements,
      (
        accTotal,
        {
          placement_id,
          account_vtr,
          account_ctr,
          account_count,
          impression_count, // matched impressions
          click_count, // matched clicks
          ctr,
          cpc,
          cpm,
          spend,
          vtr,
          name,
          status,
          url,
          auditStatus,
          meta,
          external_id,
          total_impression_count,
          total_click_count,
          audience_count,
          matched_audience_count,
          viewability_percent = 0,
        },
      ) => {
        // these are the aggregated stats for the placement group.
        /* eslint-disable */
          accTotal.total.placement_id.push(placement_id); // collection of placement ids

          // these are the individual placements
          accTotal.placements[placement_id] = {
            status: status !== undefined ? status : 'pending',
            name: name !== undefined ? name : 'pending',
            url: url !== undefined ? url : 'pending',
            auditStatus: auditStatus,
            account_count: coerceLocaleString(account_count),
            audience_count: coerceLocaleString(audience_count),
            matched_audience_count: coerceLocaleString(matched_audience_count),
            impression_count: coerceLocaleString(impression_count), // matched impressions
            account_vtr: `${safePercent(account_vtr, 1, 3)}%`,
            account_ctr: `${safePercent(account_ctr, 1, 3)}%`,
            click_count: coerceLocaleString(click_count), // matched clicks
            ctr: `${safePercent(ctr, 1, 3)}%`,
            cpm: numberToDollar(cpm),
            cpc: cpc !== 0 ? numberToDollar(cpc) : '$0.00',
            vtr: `${safePercent(vtr, 1, 3)}%`,
            spend: numberToDollar(spend),
            viewability_percent: `${formatViewabilityPercent(viewability_percent)}%`,
            ...(isExternalCampaign
              ? { total_impression_count: coerceLocaleString(total_impression_count) }
              : {}),
            ...(isExternalCampaign
              ? { total_click_count: coerceLocaleString(total_click_count) }
              : {}),
            ...(isExternalCampaign ? { placement_id } : {}),
            ...(isExternalCampaign ? { external_id } : {}),
            meta,
          };

          return accTotal;
        },
        {
          total: {
            placement_id: [],
            impression_count: 0, // matched impression
            account_vtr: 0,
            account_ctr: 0,
            click_count: 0, // matched clicks
            ctr: 0,
            cpc: 0,
            cpm: 0,
            spend: 0,
            vtr: 0,
            audience_count: 0,
            matched_audience_count: 0,
            viewability_percent: 0,
            ...(isExternalCampaign ? { total_impression_count: 0 } : {}),
            ...(isExternalCampaign ? { total_click_count: 0 } : {}),
            status: pgStatus,
            meta: pgMeta,
          },
          placements: {},
        },
      );

      const {
        total: {
          spend: totalSpend,
          impression_count: totalImpressions, // matched
          click_count: totalClicks, // matched
          total_impression_count: sumOfTotalImpressions,
          total_click_count: sumOfTotalClicks,
          ctr,
        } = {},
      } = placementGroup;
      const totalCostPerMimps = totalSpend / (totalImpressions / 1000);
      const totalCostPerClick = totalSpend / totalClicks;
      const totalCtr = ctr;

      // these total fields are an aggregate of the individual placements in the group
      formattedPlacementGroup.total.click_count = coerceLocaleString(totalClicks); // matched click
      formattedPlacementGroup.total.impression_count = coerceLocaleString(totalImpressions); // matched impression
      if (isExternalCampaign) {
        formattedPlacementGroup.total.total_impression_count = coerceLocaleString(
          sumOfTotalImpressions,
        );
        formattedPlacementGroup.total.total_click_count = coerceLocaleString(sumOfTotalClicks);
      }
      formattedPlacementGroup.total.ctr = `${safePercent(totalCtr, 1, 3)}%`;
      formattedPlacementGroup.total.spend =
        totalSpend === 0 || isNaN(totalSpend) || !isFinite(totalSpend)
          ? '$0.00'
          : numberToDollar(totalSpend);
      formattedPlacementGroup.total.cpc =
        totalCostPerClick === 0 || isNaN(totalCostPerClick) || !isFinite(totalCostPerClick)
          ? '$0.00'
          : numberToDollar(totalCostPerClick);
      formattedPlacementGroup.total.cpm =
        totalCostPerMimps === 0 || isNaN(totalCostPerMimps) || !isFinite(totalCostPerMimps)
          ? '$0.00'
          : numberToDollar(totalCostPerMimps);

      const {
        total: {
          vtr: totalVtr,
          account_vtr: totalAvtr,
          account_ctr: totalActr,
          account_count: totalUniqueAccounts,
          audience_count: totalUniqueAudienceCount,
          matched_audience_count: totalUniqueMatchedAudienceCount,
          placement_group_name: placementGroupName,
          viewability_percent: totalViewabilityPercent = 0
        } = {},
      } = placementGroup;

      // these fields come from a separate api call, they're set in the total
      // object in the reducer, and don't come from or rely on the placements
      formattedPlacementGroup.total.account_count = coerceLocaleString(totalUniqueAccounts);
      formattedPlacementGroup.total.audience_count = coerceLocaleString(totalUniqueAudienceCount);
      formattedPlacementGroup.total.matched_audience_count = coerceLocaleString(totalUniqueMatchedAudienceCount);
      formattedPlacementGroup.total.placement_group_id = coerceLocaleString(placementGroupName);
      formattedPlacementGroup.total.vtr = `${safePercent(totalVtr, 1, 3)}%`;
      formattedPlacementGroup.total.account_vtr = `${safePercent(totalAvtr, 1, 3)}%`;
      formattedPlacementGroup.total.account_ctr = `${safePercent(totalActr, 1, 3)}%`;
      formattedPlacementGroup.total.viewability_percent = `${formatViewabilityPercent(totalViewabilityPercent)}%`;
      return formattedPlacementGroup;
      /* eslint-enable */
  })
);

export const paLoadingSelector = createSelector(
  placementAnalyticsSelector,
  ({ loading } = {}) => loading
);

export const paErrorSelector = createSelector(
  placementAnalyticsSelector,
  ({ error, errorMessage = 'Error' } = {}) => ({ error, errorMessage })
);

export const paGroupUpdateErrorSelector = createSelector(
  placementAnalyticsSelector,
  ({ adGroupStates: { error, errorMessage = 'Error' } }) => ({ error, errorMessage })
);

export const hasNewAdGroupsSelector = createSelector(
  newPlacementGroupSelector,
  (newAdGroups) => newAdGroups.length > 0
);

export const placementGroupReqBodySelector = createSelector(
  newPlacementGroupSelector,
  (newAdGroups) =>
    newAdGroups.map((group) => ({
      is_deleted: false,
      placement_group_name: group.placement_group_name,
      placements: group.placements.map((placement) => placement.id),
    }))
);

export const placementsSelector = createSelector(
  paSelector,
  (formattedPlacementGroups) =>
    reduce(
      formattedPlacementGroups,
      (result, v) => {
        values(v.placements).forEach((placement) => result.push(placement));
        return result;
      },
      []
    )
);

export const adPlacementListSelector = createSelector(
  adPlacementSelector,
  ({ adPlacementList }) => adPlacementList
);

export const totalPlacementsSelector = createSelector(
  adPlacementSelector,
  ({ totalPlacements }) => totalPlacements
);

export const placementSearchInputSelector = createSelector(
  adPlacementSelector,
  ({ searchInput }) => searchInput
);

export const placementLimitSelector = createSelector(
  adPlacementSelector,
  ({ limit }) => limit
);

export const placementOffsetSelector = createSelector(
  adPlacementSelector,
  ({ offset }) => offset
);

export const isLoadingSelector = createSelector(
  adPlacementSelector,
  ({ loading }) => loading
);

export const placementPreviewSelector = createSelector(
  adPlacementSelector,
  ({ selectedPlacement }) => selectedPlacement
);

export const showAllSelectedPlacementSelector = createSelector(
  adPlacementSelector,
  ({ showAllSelectedPlacement }) => showAllSelectedPlacement
);

export const selectedAdPlacementsListSelector = createSelector(
  adPlacementSelector,
  ({ selectedAdPlacementsList }) => selectedAdPlacementsList
);

export const orderBySelector = createSelector(
  adPlacementSelector,
  ({ orderBy }) => orderBy
);

export const orderingSelector = createSelector(
  adPlacementSelector,
  ({ ordering }) => ordering
);

export const getPlacementTypeSelector = createSelector(
  adPlacementSelector,
  ({ campaignSubtype }) => campaignSubtype
);

export const groupNameSelector = createSelector(
  adPlacementSelector,
  ({ groupName }) => groupName
);

export const adModalVisibilitySelector = createSelector(
  adPlacementSelector,
  ({ modalVisible }) => modalVisible
);

export const selectedPlacementGroupSelector = createSelector(
  adPlacementSelector,
  ({ placementGroup }) => placementGroup
);

export const updatedGroupIdSelector = createSelector(
  adPlacementSelector,
  ({ updatedGroupId }) => updatedGroupId
);

export const isNewAdGroupSelector = createSelector(
  adPlacementSelector,
  ({ isNewAdGroup }) => isNewAdGroup
);

export const isVideoCampaignSelector = createSelector(
  campaignIDSelector,
  campaignDetailsSelector,
  (campaignID, campaign) => campaign.campaign_subtype === 'video'
);

export const adEndpointSelector = createSelector(
  orgSelector,
  placementSearchInputSelector,
  placementLimitSelector,
  placementOffsetSelector,
  getPlacementTypeSelector,
  orderBySelector,
  orderingSelector,
  (orgId, search, limit, offset, creativeType, orderBy, ordering) =>
    `org/${orgId}/${creativeType}_placement/?search=${search}&limit=${limit}&offset=${offset}` +
    `&ordering=${ordering === 'desc' ? '-' : ''}${orderBy}&is_deleted=False`
);

export const downloadAccountListSelector = (accountList, byCRMId) =>
  createSelector(
    isVideoCampaignSelector,
    (isVideoCampaign) => {
      const formattedAccounts = accountList.map((list) => {
        const {
          firm_name: firmName,
          firm_country: firmCountry,
          firm_domain: firmDomain,
          latest_impression: latestImpression,
          click_count: clickCount,
          spend,
          engagement,
          impression_count: impressionCount,
          mid,
          external_id: externalId,
          crm_name: crmName,
          crm_website: crmWebsite,
          crm_country: crmCountry,
          video_service_count = 0,
          video_error_count = 0,
          video_start_count = 0,
          video_skip_count = 0,
          video_first_quartile_count = 0,
          video_third_quartile_count = 0,
          video_half_point_count = 0,
          video_completion_count = 0,
          form_fill_count = 0,
        } = list;

        const finalMid = `"${mid}"`;
        const name = `"${firmName}"`;
        const country = `"${firmCountry}"`;
        const domain = `"${firmDomain}"`;
        const impressions = `"${coerceLocaleString(impressionCount)}"`;
        const clicks = `"${coerceLocaleString(clickCount)}"`;
        const finalSpend = spend ? `"${numberToDollar(spend)}"` : '$0.00';
        const finalCRMName = crmName ? `"${crmName}"` : '';
        const finalCRMCountry = crmCountry ? `"${crmCountry}"` : '';
        const finalCRMDomain = crmWebsite ? `"${crmWebsite}"` : '';
        const formFillCount = `"${coerceLocaleString(form_fill_count)}"`;
        let videoStats = {};

        if (isVideoCampaign) {
          videoStats = {
            videoRequest: `"${coerceLocaleString(video_service_count)}"`,
            videoError: `"${coerceLocaleString(video_error_count)}"`,
            videoStart: `"${coerceLocaleString(video_start_count)}"`,
            videoSkip: `"${coerceLocaleString(video_skip_count)}"`,
            video25: `"${coerceLocaleString(video_first_quartile_count)}"`,
            video50: `"${coerceLocaleString(video_half_point_count)}"`,
            video75: `"${coerceLocaleString(video_third_quartile_count)}"`,
            video100: `"${coerceLocaleString(video_completion_count)}"`,
          };
        }
        if (byCRMId) {
          return {
            externalId,
            finalMid,
            finalCRMName,
            finalCRMCountry,
            finalCRMDomain,
            impressions,
            clicks,
            finalSpend,
            engagement,
            latestImpression,
            formFillCount,
            ...videoStats,
          };
        }

        return {
          name,
          country,
          domain,
          impressions,
          clicks,
          finalSpend,
          engagement,
          latestImpression,
          formFillCount,
          ...videoStats,
        };
      });
      return filter(formattedAccounts, (act) => !!act);
    });

export const downloadAccountsSelector = (accountList, byCRMId, isVideo, isListDownload) => () => {
  const formattedAccounts = accountList.map((list) => {
    const {
      campaign_id: campaignId,
      firm_name: firmName,
      firm_country: firmCountry,
      firm_domain: firmDomain,
      latest_impression: latestImpression,
      click_count: clickCount,
      spend,
      engagement,
      impression_count: impressionCount,
      mid,
      external_id: externalId,
      crm_name: crmName,
      crm_website: crmWebsite,
      crm_country: crmCountry,
      video_service_count = 0,
      video_error_count = 0,
      video_start_count = 0,
      video_skip_count = 0,
      video_first_quartile_count = 0,
      video_third_quartile_count = 0,
      video_half_point_count = 0,
      video_completion_count = 0,
      form_fill_count = 0,
    } = list;

    const finalMid = `"${mid}"`;
    const name = `"${firmName}"`;
    const country = `"${firmCountry}"`;
    const domain = `"${firmDomain}"`;
    const impressions = `"${coerceLocaleString(impressionCount)}"`;
    const clicks = `"${coerceLocaleString(clickCount)}"`;
    const finalSpend = spend ? `"${numberToDollar(spend)}"` : '$0.00';
    const finalCRMName = crmName ? `"${crmName}"` : '';
    const finalCRMCountry = crmCountry ? `"${crmCountry}"` : '';
    const finalCRMDomain = crmWebsite ? `"${crmWebsite}"` : '';
    const formFillCount = `"${coerceLocaleString(form_fill_count)}"`;
    let videoStats = {};

    if (isVideo) {
      videoStats = {
        videoRequest: `"${coerceLocaleString(video_service_count)}"`,
        videoError: `"${coerceLocaleString(video_error_count)}"`,
        videoStart: `"${coerceLocaleString(video_start_count)}"`,
        videoSkip: `"${coerceLocaleString(video_skip_count)}"`,
        video25: `"${coerceLocaleString(video_first_quartile_count)}"`,
        video50: `"${coerceLocaleString(video_half_point_count)}"`,
        video75: `"${coerceLocaleString(video_third_quartile_count)}"`,
        video100: `"${coerceLocaleString(video_completion_count)}"`,
      };
    }

    if (byCRMId) {
      return {
        ...(isListDownload && { campaignId }),
        externalId,
        finalMid,
        finalCRMName,
        finalCRMCountry,
        finalCRMDomain,
        impressions,
        clicks,
        finalSpend,
        engagement,
        latestImpression,
        formFillCount,
        ...videoStats,
      };
    }

    return {
      ...(isListDownload && { campaignId }),
      name,
      country,
      domain,
      impressions,
      clicks,
      finalSpend,
      engagement,
      latestImpression,
      formFillCount,
      ...videoStats,
    };
  });
  return filter(formattedAccounts, (act) => !!act);
};

export const isRetargetingCampaignSelector = createSelector(
  campaignSourceSelector,
  (campaignSource) => campaignSource === CAMPAIGN_SOURCE.RETARGETING,
);

export const enableValueMetricsSelector = createSelector(
  isPredictiveSelector,
  campaignSourceSelector,
  is6SenseOrgSelector,
  (isPredictive, campaignSource, is6senseOrg) => {
    if (campaignSource === CAMPAIGN_SOURCE.RETARGETING) {
      return isPredictive && is6senseOrg;
    }

    return isPredictive && [
      CAMPAIGN_SOURCE.INTERNAL,
      CAMPAIGN_SOURCE.CONTEXTUAL,
      CAMPAIGN_SOURCE.LINKEDIN,
      CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING,
    ].includes(campaignSource);
  }
);

export const currentCampaignSelector = createSelector(
  campaignDetailsSelector,
  (campaignDetails) => (campaignDetails),
);

export const disableChartFilterSelector = createSelector(
  currentCampaignSelector,
  isExternalCampaignSelector,
  (campaignDetails, isExternalCampaign) => {
    const campaignStartDate = isExternalCampaign ? moment(campaignDetails.created) : campaignDetails.start_date;
    const translationProcessed = get(campaignDetails, 'campaign_data.date', null);
    const dateInPast = moment(campaignStartDate).isBefore(moment(), 'day');
    return !dateInPast || !translationProcessed;
  }
);

export const getFilterChartStartEndDatesSelector = createSelector(
  currentCampaignSelector,
  isSavedCampaignSelector,
  (campaign, isSavedCampaign) => {
    const {
      start_date,
      created,
      end_date: campaignEndDate,
      campaign_data = {},
    } = campaign;
    if (!isSavedCampaign) {
      return { availableDataStartDate: null, availableDataEndDate: null };
    }
    const currentDay = moment();
    const lastTranslated = campaign_data.date || currentDay;
    const endDateForExternalCampaign = campaign_data.latest_impression || currentDay;
    let availableDataEndDate;
    const availableDataStartDate = start_date || created;
    if (campaign.campaign_source === CAMPAIGN_SOURCE.EXTERNAL) {
      availableDataEndDate = endDateForExternalCampaign;
      availableDataEndDate = getAvailableDataEndDate(availableDataEndDate, lastTranslated);
    } else if (campaignEndDate) { // campaign has end date
      availableDataEndDate = getAvailableDataEndDate(campaignEndDate, lastTranslated);
    } else {
      availableDataEndDate = lastTranslated;
    }
    return { availableDataStartDate, availableDataEndDate };
  }
);

export const visibleAdGroupNames = createSelector(
  paSelector,
  (data) => Object.values(data)
      .map((placementGroup) => placementGroup.total.placement_group_id)
);
