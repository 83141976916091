import React from 'react';
import { Tabs, Button, Icon, Text } from 'v2-components';
import { Flex } from '@sixsense/core/components';
import { Permission, usePermissionCheck } from '@sixsense/rbac';
import {
  TABS,
  DEACTIVATED,
  GROUPS,
  GROUP_KW_LIST,
  KEYWORDS,
} from './constants';
import { css } from 'styles/emotion';
import {
  activePageState,
  addKeywordsVisibleState,
  addGroupVisibleState,
  addToGroupVisibleState,
  selectedGroupState,
  keywordsConfigState,
  groupsConfigState,
  groupsKeywordsConfigState,
  addKwToGroupConfigState,
  deactivatedConfigState,
  bulkAddVisibleState,
  DEFAULT_PAGINATION_STATE,
  DEFAULT_PAGE_FILTERS_STATE,
  DEFAULT_SORT_ORDER_STATE,
  siKeywordsValidationState,
} from './state';
import { useSharedState, useSharedValue } from '@sixsense/core/';
import { destroyAllParamsNavigate } from 'utils/navigate';
import {
  AddKeywordsModal,
  CreateGroupModal,
  AddToGroupModal,
  BulkAddToGroupModal,
} from './components/Modals';
import { AAColor } from '@sixsense/core/style';
import { resetState } from './utils';

const styles = {
  container: css({
    padding: '13px 30px 0',
    backgroundColor: '#fff',
    borderBottom: '1px solid #C6CACE',
    justifyContent: 'space-between',
  }),
  tab: css({
    marginRight: '20px',
  }),
  tabContainer: css({
    marginLeft: '15px',
  }),
  button: css({
    color: `${AAColor.GREY4}`,
    marginRight: '10px',
  }),
  label: css({
    marginBottom: '13px',
  }),
  activeTab: css({
    color: '#000000',
    fontWeight: 'bold',
  }),
  buttonHeight: css({
    height: '30px',
  }),
  bulkAddGroups: css({
    fontSize: '24px',
    marginRight: '10px',
    lineHeight: '1px',
  }),
};

const KeywordsPageHeader = () => {
  const [activePage, setActivePage] = useSharedState(activePageState);
  const [kwModalVisible, setKwModalVisible] = useSharedState(addKeywordsVisibleState);
  const [groupModalVisible, setGroupModalVisible] = useSharedState(addGroupVisibleState);
  const [addToGroupVisible, setAddToGroupModalVisible] = useSharedState(addToGroupVisibleState);
  const [bulkAddVisible, setBulkAddVisible] = useSharedState(bulkAddVisibleState);
  const selectedGroup = useSharedValue(selectedGroupState);
  const siValidations = useSharedValue(siKeywordsValidationState);

  // Don't think there's an easier way to reset the state.
  const [, setKeywordsConfig] = useSharedState(keywordsConfigState);
  const [, setGroupsConfig] = useSharedState(groupsConfigState);
  const [, setGroupKeywordsConfig] = useSharedState(groupsKeywordsConfigState);
  const [, setAddKwToGroupConfig] = useSharedState(addKwToGroupConfigState);
  const [, setDeactivatedConfig] = useSharedState(deactivatedConfigState);

  const refreshState = (newPage) => {
    setActivePage(newPage);
    resetState(setKeywordsConfig, KEYWORDS);
    resetState(setGroupsConfig, GROUPS);
    resetState(setGroupKeywordsConfig, GROUP_KW_LIST);
    resetState(setAddKwToGroupConfig, KEYWORDS);
    resetState(setDeactivatedConfig, DEACTIVATED);
  };

  const canEdit = usePermissionCheck([Permission.SETTINGS_KEYWORDS_EDIT]);

  let buttonText;
  let onClick;
  switch (activePage) {
    case GROUPS:
      buttonText = 'Create new Keyword Group';
      onClick = () => setGroupModalVisible(true);
      break;
    case GROUP_KW_LIST:
      buttonText = 'Add Keywords to this Group';
      onClick = () => {
        setAddKwToGroupConfig({
          filters: { ...DEFAULT_PAGE_FILTERS_STATE[KEYWORDS] },
          sort: { ...DEFAULT_SORT_ORDER_STATE[KEYWORDS] },
          pagination: { ...DEFAULT_PAGINATION_STATE },
          spanFilter: 'last_7_days',
          selectedValues: [],
        });
        setAddToGroupModalVisible(true);
      };
      break;
    default:
      buttonText = 'Add Keywords';
      onClick = () => setKwModalVisible(true);
  }

  const bulkAddToGroupsButton = canEdit ? (
    <Flex alignItems="baseline">
      <Button
        className={`${styles.button} ${styles.buttonHeight}`}
        onClick={() => setBulkAddVisible(true)}
      >
        Add Existing Keywords
      </Button>
      <Text color={Text.COLOR.GREY4} className={styles.bulkAddGroups}>|</Text>
    </Flex>
  ) : null;

  const getTabs = () => {
    if (!siValidations.keywordGroup) {
      return TABS.filter((el) => el.value !== GROUPS);
    }
    return TABS;
  };

  return (
    <Flex className={styles.container}>
      {activePage !== GROUP_KW_LIST ?
        <Tabs
          items={getTabs()}
          selected={activePage}
          type="underline"
          action={(v) => setActivePage(v)}
          tabClassName={styles.tab}
          labelClassName={styles.label}
          containerClassName={styles.tabContainer}
          activeLabelClassName={styles.activeTab}
        /> :
        <Flex alignContent="center" style={{ marginBottom: '13px' }}>
          <Icon
            type="west"
            size={Icon.SIZE.XLARGE}
            pointer
            onClick={() => setActivePage(GROUPS)}
          />
          <Text
            bold
            type="title"
            className="aam-l--10"
          >"{selectedGroup?.name}" group
          </Text>
        </Flex>
      }
      <Flex>
        {activePage === GROUPS && bulkAddToGroupsButton}
        {activePage !== GROUP_KW_LIST && <Button
          className={`${styles.button} ${styles.buttonHeight}`}
          onClick={() => {
            destroyAllParamsNavigate('/settings/manage');
            refreshState(KEYWORDS);
          }}
        >
          Exit
        </Button>}
        {activePage !== DEACTIVATED && canEdit &&
        <Button
          className={styles.buttonHeight}
          type="primary"
          onClick={() => onClick()}
        >
          {buttonText}
        </Button>}
      </Flex>
      {bulkAddVisible && <BulkAddToGroupModal onCancel={() => setBulkAddVisible(false)} />}
      {kwModalVisible && <AddKeywordsModal onCancel={() => setKwModalVisible(false)} />}
      {groupModalVisible && <CreateGroupModal onCancel={() => setGroupModalVisible(false)} />}
      {addToGroupVisible && (
        <AddToGroupModal
          onCancel={() => setAddToGroupModalVisible(false)}
          selectedGroup={selectedGroup}
        />
      )}
    </Flex>
  );
};

export default KeywordsPageHeader;
