import React from 'react';
import { Dropdown } from 'antd';
import PropTypes from 'prop-types';
import styles from './TetheredDropdown.module.scss';

const TetheredDropdown = (props) => {
  const { children, id } = props;
  return (
    <div className={styles.outer} id={id}>
      <Dropdown
        {...props}
        getPopupContainer={() => document.getElementById(id)}
      >
        { children }
      </Dropdown>
    </div>
  );
};

TetheredDropdown.propTypes = {
  children: PropTypes.object,
  id: PropTypes.string,
};

export default TetheredDropdown;

