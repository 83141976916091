import { takeLatest, put, call, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { actionTypes, actions } from './index';
import rollbar from 'lib/rollbar';
import { fetchSaga } from 'store/sagas';
import { getOrg } from './utils';
import { strings, codes } from 'utils/constants';
import { actions as globalActions } from 'modules/global';
import { getOAuthRedirectUrl, pollOAuth } from './OAuthUtils';
import { orgSelector } from 'modules/user/selectors';
import { includes, lowerCase, first, values } from 'lodash';

const { showNotification } = globalActions;

const { PRELOGIN_GENERIC_ERROR } = strings;
const { HTTP_400_BAD_REQUEST } = codes;

const {
  INITIALIZE_AUTH,
  INITIALIZE_OAUTH,
  LOAD_AUTH_USER,
  LOGOUT,
} = actionTypes;

const {
  setAuthOrgName,
  loadAuthUserSuccess,
  loadAuthUserFailed,
} = actions;

export function* onInitializeAuth() {
  const authOrgName = getOrg();
  yield put(setAuthOrgName(authOrgName));
}

export function* onInitializeOAuth(request, action) {
  const {
    onSuccessCallback,
    onFailureCallback,
    sync_source_type,
    ...rest
  } = action.initiationParams;
  const result = yield getOAuthRedirectUrl({ request,
    postBody: {
      sync_source_type,
      ...rest,
    } });

  const orgId = yield select(orgSelector);

  if (result.error) {
    if (rollbar && rollbar.error) {
      rollbar.error(`OAuth util failed for orgId: ${orgId} and 
      sync_source_type: ${sync_source_type} when trying to get the redirection url.`);
    }
    return onFailureCallback(result.errorMessage);
  }

  return yield pollOAuth({
    redirectionProperties: result.redirectionProperties,
    request,
    successCallback: onSuccessCallback,
    errorCallback: onFailureCallback,
  });
}

export function passwordErrorHandler(e) {
  const isBadRequest = e.errorStatus === HTTP_400_BAD_REQUEST;
  e.errorMessage = isBadRequest ? e.body : PRELOGIN_GENERIC_ERROR;
  throw e;
}

export function* redirectHandler(path) {
  yield delay(5000);
  window.location = path;
}

export function* loadAuthUser(request, action) {
  const { userId, token } = action;

  try {
    const endpoint = `auth/v1/search/users/id/${userId}/token/${token}/`;
    const userPayload = yield call(request, endpoint);
    yield put(loadAuthUserSuccess(userPayload));
  } catch (e) {
    if (e.errorStatus === 404) {
      const errorMsg = lowerCase(first(values(e.body)));
      if (includes(errorMsg, 'invalid or expired token')) {
        const INVALID_TOKEN_MSG = 'The link has expired. ' +
          'Reach out to your Platform Administrator to resend the invite.';
        yield put(showNotification('error', INVALID_TOKEN_MSG, null, 15));
      }
    }

    yield put(loadAuthUserFailed(e.errorMessage));
  }
}

export function* logout(request) {
  try {
    yield call(request, '/auth/v1/logout/');
  } catch (e) {
    yield put(showNotification('error', 'Error: Unable to logout.'));
  }
}

export function* watchInitializeAuth() {
  yield takeLatest(INITIALIZE_AUTH, onInitializeAuth);
}

export function* watchInitializeOAuth(...args) {
  yield takeLatest(INITIALIZE_OAUTH, onInitializeOAuth, ...args);
}

export function* watchLoadAuthUser(request) {
  yield takeLatest(LOAD_AUTH_USER, loadAuthUser, request);
}

export function* watchLogout(request) {
  yield takeLatest(LOGOUT, logout, request);
}

export default [
  fetchSaga(watchLoadAuthUser),
  fetchSaga(watchInitializeOAuth),
  fetchSaga(watchLogout),
  watchInitializeAuth,
];
