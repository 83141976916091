import React, { useState } from 'react';
import { css } from 'styles/emotion';
import { ActionDropdown, ActionItem, Text, Button, Modal } from 'v2-components';
import { Menu } from 'antd';
import { AAColor } from '@sixsense/core/style';
import { useOrg } from 'hooks';
import { useSharedValue, useRequest, useSharedState } from '@sixsense/core';
import { activePageState, selectedGroupState } from '../../state';
import { GROUP_KW_LIST } from '../../constants';
import { getConfigState, resetConfig, getUpdatedConfig } from '../../utils';
import { Keyword } from '../../types';
import { maybePlural } from 'utils/utils';
import { actions as globalActions } from 'modules/global';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import {
  CreateGroupModal,
  AddToProductModal,
  RemoveFromProductModal,
  ExistingGroupModal,
} from '../Modals';

const styles = {
  dropdownIcon: css({
    color: '#505C62',
    fontSize: '24px !important',
  }),
  button: css({
    color: '#505C62',
  }),
  takeActionButton: css({
    marginLeft: 10,
    height: '36px',
  }),
  overrideAntdMenuStyles: css`
    .ant-menu-submenu-title {
      color: ${AAColor.GREY};
      font-size: 1rem;
    }
    .ant-menu-submenu-title:hover {
      background-color: ${AAColor.BLUE} !important;
      color: ${AAColor.WHITE} !important;
    }
    .ant-menu-item:hover {
      background-color: ${AAColor.BLUE} !important;
      color: ${AAColor.WHITE} !important;
    }
  `,
  overrideAntdMenuItemStyles: css`
    .ant-menu-item {
      color: ${AAColor.GREY};
      font-size: 1rem;
    }
    .ant-menu-item:hover {
      background-color: ${AAColor.BLUE} !important;
      color: ${AAColor.WHITE} !important;
    }
  `,
};

const { SubMenu } = Menu;

// Wonky ass antd crashes the page if this is a component instead of a function
const NestedDropdown = (label, setAction, actionType, menuItems) => (
  <SubMenu
    className={styles.overrideAntdMenuStyles}
    popupClassName={styles.overrideAntdMenuItemStyles}
    title={label}
  >
    {menuItems.map((v) =>
      <Menu.Item
        onClick={() => {
          setAction(actionType);
          v.onClick();
        }}
      >
        {v.label}
      </Menu.Item>)}
  </SubMenu>
);

type Props = {
  multiSelect?: boolean;
  selectedKeywords: Keyword[];
}

const KeywordActions = ({ multiSelect, selectedKeywords }: Props) => {

  const activePage = useSharedValue(activePageState);
  const selectedGroup = useSharedValue(selectedGroupState);
  const state = getConfigState(activePage, false);
  const [config, setConfig] = useSharedState(state);
  const [createGroupModalVisible, setCreateGroupModalVisible] = useState(false);
  const [existingGroupModalVisible, setExistingGroupModalVisible] = useState(false);
  const [addToProductModalVisible, setAddToProductModalVisible] = useState(false);
  const [removeFromProductModal, setRemoveFromProductModalVisible] = useState(false);
  const [action, setAction] = useState('Add');
  const org = useOrg();
  const request = useRequest();
  const dispatch = useDispatch();

  const keywordIds = selectedKeywords.map((kw) => kw?.id);
  const categories = new Set();
  selectedKeywords.forEach((kw) => categories.add(kw?.category));
  const hasBothCategories = categories.has('branded') && categories.has('generic');
  const showBranded = !categories.has('branded') || hasBothCategories;
  const showGeneric = !categories.has('generic') || hasBothCategories;

  const menuItems = [
    { label: 'New Group', onClick: () => setCreateGroupModalVisible(true) },
    { label: 'Existing Group', onClick: () => setExistingGroupModalVisible(true) },
    { label: 'Another Product Category', onClick: () => setAddToProductModalVisible(true) },
  ];

  const removeFromGroup = async () => {
    const body = {
      config: { id: selectedGroup.id },
      keywords_to_remove: keywordIds,
    };
    try {
      await request(`organization/${org.id}/keyword_set/`, {
        method: 'PATCH',
        body: JSON.stringify(body),
      });
      dispatch(globalActions.showNotification('success',
      `Successfully removed ${maybePlural(keywordIds.length, 'keyword')}
        from ${selectedGroup.name}`));

      const newConfig = getUpdatedConfig(config);
      resetConfig(newConfig, setConfig);
    } catch (e) {
      dispatch(globalActions.showNotification('error',
      `There was an issue removing your ${maybePlural(keywordIds.length, 'keyword')}
        from ${selectedGroup.name}.
      Please try again later`));
    }
  };

  const switchCategory = async (category) => {
    const body = {
      action: 'switch_category',
      keyword_ids: keywordIds,
      config: {
        new_category: category,
      },
    };

    try {
      // Product_id is 0 since it is unused in the bulk_update calls
      await request(`organization/${org.id}/product_keyword/${0}/keyword/`, {
        method: 'PATCH',
        body: JSON.stringify(body),
      });
      dispatch(globalActions.showNotification('success',
      `Successfully changed ${maybePlural(keywordIds.length, 'keyword')} to ${category}`));
      const newConfig = getUpdatedConfig(config);
      resetConfig(newConfig, setConfig);
    } catch (e) {
      dispatch(globalActions.showNotification('error',
      `There was an issue changing your
      ${maybePlural(keywordIds.length, 'keyword')} to ${category}. Please try again later`));
    }
  };

  const deactivate = async () => {
    const body = {
      action: 'delete',
      keyword_ids: keywordIds,
      config: {},
    };

    try {
      // Product_id is 0 since it is unused in the bulk_update calls
      await request(`organization/${org.id}/product_keyword/${0}/keyword/`, {
        method: 'PATCH',
        body: JSON.stringify(body),
      });
      dispatch(globalActions.showNotification(
        'success',
        `Successfully deactivated ${maybePlural(keywordIds.length, 'keyword')}.`
      ));
      const newConfig = getUpdatedConfig(config);
      resetConfig(newConfig, setConfig);
    } catch (e) {
      dispatch(globalActions.showNotification(
        'error',
        `There was an issue deactivating your ${maybePlural(keywordIds.length, 'keyword')}.
        Please try again later`
      ));
    }
  };

  return (
    <React.Fragment>
      <ActionDropdown
        id="keyword-actions"
        rightIcon={!multiSelect && 'more_horiz'}
        rightIconClass={styles.dropdownIcon}
        buttonLabel={multiSelect &&
          <Button iconRight="keyboard_arrow_down" className={styles.takeActionButton}>
            <Text color={Text.COLOR.GREY}>Take Action</Text>
          </Button>
        }
      >
        {NestedDropdown('Add to...', setAction, 'Add', menuItems)}
        {NestedDropdown('Move to...', setAction, 'Move', menuItems)}
        {showGeneric && <ActionItem
          id="change-category"
          key="change-category"
          action={() => switchCategory('generic')}
        >
          Change to Generic
        </ActionItem>}
        {showBranded && <ActionItem
          id="change-category"
          key="change-category"
          action={() => switchCategory('branded')}
        >
          Change to Branded
        </ActionItem>}
        {activePage === GROUP_KW_LIST && <ActionItem
          id="remove-group"
          key="remove-group"
          action={() => Modal.prompt({
            type: Modal.PROMPT_TYPES.CONFIRM,
            title: 'Remove Keywords',
            content: `Remove ${selectedKeywords.length}
              ${maybePlural(selectedKeywords.length, 'keyword')}
              from "${selectedGroup.name}" group?`,
            onOk() {
              removeFromGroup();
            },
          })}
        >
          Remove from this group
        </ActionItem>}
        <ActionItem
          id="remove-product"
          key="remove-product"
          action={() => setRemoveFromProductModalVisible(true)}
        >
          Remove from Product Category
        </ActionItem>
        <ActionItem
          id="deactivate"
          key="deactivate"
          action={() => deactivate()}
        >
          Deactivate
        </ActionItem>
      </ActionDropdown>
      {createGroupModalVisible && (
        <CreateGroupModal
          onCancel={() => setCreateGroupModalVisible(false)}
          keywords={keywordIds}
          action={action}
        />
      )}
      {existingGroupModalVisible && (
        <ExistingGroupModal
          onCancel={() => setExistingGroupModalVisible(false)}
          keywords={keywordIds}
          action={action}
          setCreateGroupVisible={setCreateGroupModalVisible}
        />
      )}
      {addToProductModalVisible && (
        <AddToProductModal
          visible={addToProductModalVisible}
          onCancel={() => setAddToProductModalVisible(false)}
          keywords={keywordIds}
          action={action}
        />
      )}
      {removeFromProductModal && (
        <RemoveFromProductModal
          onCancel={() => setRemoveFromProductModalVisible(false)}
          keywords={selectedKeywords}
        />
      )}
    </React.Fragment>
  );
};

export default KeywordActions;
