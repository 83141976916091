import React from 'react';
import PropTypes from 'prop-types';
import { Text, Icon, TetheredDropdown, Row } from 'v2-components';
import styles from './Radio.module.scss';
import { classNames } from '../../utils/utils';

const Radio = (props) => {
  const {
    containerClassName,
    selected,
    item,
    action,
    isDropdown,
    dropdownMenu,
    dropdownPlacement,
    dropdownVisible,
    handleVisibleChange,
    dropdownDisabled,
    dontColorText,
    radioClassName,
    disable,
  } = props;

  // todo: this should be a html radio tag, not styled divs
  const RadioComponent = () => (
    <Row
      justifyContent={Row.JUSTIFY_CONTENT.SPACE_BETWEEN}
      alignItems={Row.ALIGN_ITEMS.CENTER}
      className={classNames(styles.radio, radioClassName, disable && styles.disabled)}
      onClick={() => disable ? null : action(item.value)}
    >
      <div
        className={`${styles.indicatorOuter} ${selected ? styles.selectedOuter : undefined}`}
      >
        <div
          className={classNames(
            styles.indicatorInner,
            selected && styles.selected,
            disable && styles.disabled
          )}
        />
      </div>
      <div className={styles.radioLabel}>
        <Text color={(selected && !dontColorText) ? 'blue' : undefined}>
          {item.label}
        </Text>
      </div>
    </Row>
  );

  return (
    <div className={`${styles.radioOuter} ${containerClassName}`}>
      {
        isDropdown ?
          <TetheredDropdown
            overlay={dropdownMenu}
            trigger={['click']}
            placement={dropdownPlacement}
            visible={dropdownVisible}
            onVisibleChange={(isVisible) => handleVisibleChange(isVisible)}
            disabled={dropdownDisabled}
            id="activity-chart-individual-dropdown"
          >
            <div className={styles.dropdownOuter}>
              <RadioComponent />
              <div className={styles.icon}>
                <Icon
                  iconType="keyboard_arrow_down"
                  color={selected ? Icon.COLORS.BLUE : Icon.COLORS.BLACK}
                />
              </div>
            </div>
          </TetheredDropdown> :
          <RadioComponent />
      }
    </div>
  );
};

Radio.defaultProps = {
  dontColorText: false,
  disable: false,
};

Radio.propTypes = {
  action: PropTypes.func,
  containerClassName: PropTypes.string,
  dropdownDisabled: PropTypes.bool,
  dropdownMenu: PropTypes.element,
  dropdownPlacement: PropTypes.string,
  dropdownVisible: PropTypes.bool,
  handleVisibleChange: PropTypes.func,
  isDropdown: PropTypes.bool,
  item: PropTypes.object,
  selected: PropTypes.bool,
  dontColorText: PropTypes.bool,
  disable: PropTypes.bool,
  radioClassName: PropTypes.string,
};

export default Radio;
