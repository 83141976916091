import { STATE_KEY } from "./constants";
import { createSelector } from "reselect";
import { orgSelector } from "modules/user/selectors";
import { get } from "lodash";

export const baseSelector = (state) => state.advertising[STATE_KEY];

export const adEndpointSelector = createSelector(
  orgSelector,
  (_, { payload }) => get(payload, "placement_type"),
  (orgId, placement_type) => `org/${orgId}/${placement_type}_placement/`
);

export const creativeEndpointSelector = createSelector(
  orgSelector,
  (_, { payload }) => get(payload, "type"),
  (orgId, type) => `org/${orgId}/${type}_creative/`
);

export const createAdPayloadSelector = createSelector(
  (_, action) => action,
  (action) => ({
    body: JSON.stringify(action.payload),
  })
);
