export default function createRoutes() {
  return {
    path: '6qa-analytics',
    name: 'SixQAAnalyticsPage',
    getComponent(nextState, cb) {
      // @ts-ignore
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          cb(null, containers.default);
        },
        'SixQAAnalyticsPage'
      );
    },
  };
}
