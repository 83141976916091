import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import request from 'utils/request';
import { AutoComplete, Icon, TetheredSelect, Loading } from 'v2-components';
import { orgSelector } from 'modules/user/selectors';
import styles from './SegmentSelect.module.scss';

const { Option } = TetheredSelect;

const fetchSegments =
  async (
    orgId,
    searchInput,
    controller,
    multi,
    { offset = 0, limit = 20, publishedTo = undefined } = {}
  ) => {
    const endpoint = `tally_legacy/${orgId}/segment/?ordering=name__length`
      + `&name_contains=${encodeURIComponent(searchInput)}&offset=${offset}&limit=${limit}`
      + `${publishedTo ? `&published_to=${publishedTo}` : ''}`;
    const { results } = await request(endpoint, 'GET', { signal: controller.signal });
    return multi ?
    results
    : results.map((segment) => ({ label: segment.name, value: segment }));
  };

const SegmentSelect = ({ orgId, value, onChange, onDeselect, multi, disabled = false }) => {

  const controllerRef = useRef(undefined);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const segNames = value ? value.map((seg) => seg.name) : [];

  useEffect(() => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    if (searchInput) {
      const controller = new AbortController();
      controllerRef.current = controller;
      setLoading(true);
      setOptions([]);
      fetchSegments(orgId, searchInput, controller, multi).then((nextOptions) => {
        setOptions(nextOptions);
      }).then(() => {
        controllerRef.current = undefined;
        setLoading(false);
      }).catch((err) => {
        if (err.name === 'AbortError') {
          controllerRef.current = undefined;
        } else {
          setLoading(false);
        }
      });
    } else {
      setOptions([]);
      setLoading(false);
    }
  }, [searchInput, orgId, multi]);

  const handleChange = (segmentId) => {
    const segment = options.find((seg) => seg.id === segmentId);
    if (segment) {
      onChange(segment);
    }
  };

  return (
    <span
      title={!multi && value?.length ? (value[0].name || String(value[0].id)) : undefined}
      style={{ position: 'relative' }}
    >
      {multi ? (
        <TetheredSelect
          className={styles.multiSelect}
          id="seg_report_multi_select"
          onSearch={(val) => setSearchInput(val)}
          onSelect={(option) => handleChange(option)}
          onDeselect={(option) => onDeselect(option)}
          filterOption={(inputVal, option) =>
            option.props.children.toLowerCase().includes(inputVal.toLowerCase())
          }
          dropdownClassName={styles.dropdown}
          mode="multiple"
          value={segNames}
          placeholder="Type to select..."
          onFocus={(val) => setSearchInput(val)}
          disabled={disabled}
          notFoundContent={loading ? <Loading
            size="small"
            thin
            spinnerWidth={15}
            spinnerHeight={15}
            pageLevel={false}
          /> : null}
        >
          {
            options.filter((seg) => !segNames.includes(seg.name)).map((segment) => (
              <Option
                id={`${segment.id}_report_select_id`}
                key={`${segment.id}_report_select_key`}
                value={segment.id}
              >
                {segment.name}
              </Option>
            ))
          }
        </TetheredSelect>)
      : (
        <AutoComplete
          className={styles.container}
          style={{ height: 36 }}
          loadOptions={(val) => setSearchInput(val)}
          loading={loading}
          value={value?.length ? (value[0].name || String(value[0].id)) : undefined}
          onChange={(option) => onChange(option ? option.value : undefined)}
          options={options}
          disabled={disabled}
        />)
      }
      <Icon
        className={styles.searchIcon}
        type="search"
        size={Icon.SIZE.LARGE}
        color={Icon.COLORS.GREY1}
      />
    </span>
  );
};

SegmentSelect.propTypes = {
  orgId: PropTypes.number,
  value: PropTypes.object,
  onChange: PropTypes.func,
  onDeselect: PropTypes.func,
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  orgId: orgSelector(state),
});

export default connect(mapStateToProps)(SegmentSelect);
