export const CAMPAIGN_CREATIVE_GROUP_FORM = 'CAMPAIGN_CREATIVE_GROUP_FORM';

export const CAMPAIGN_CREATIVE_FORM = 'CAMPAIGN_CREATIVE_FORM';
export const CREATIVE_TYPE_WARNING = 'If you proceed, all saved' +
' Ads will be deleted. Do you wish to continue?';

export const REMOVE_AD_GROUP_WARNING = 'Are you sure you want to remove ad group?';

export const SELECT_CREATIVE_TYPE_WARNING = 'Please select creative type';
export const FILE_TYPES = {
  'image/png': 'PNG',
  'image/jpeg': 'JPG',
  'video/mp4': 'MP4',
};

export const PLACEMENT_GROUP_ERROR_MESSAGE =
  'Please add AdGroups and Ads to the campaign before submit.';

export const LEAVE_CREATE_CAMPAIGN = 'All unsaved changes will be deleted.';

export const DUMMY_AD_PLACEMENT_FORM = 'DUMMY_AD_PLACEMENT_FORM';
export const AD_CREATE_TYPES = [
  { label: 'Create Ads', value: 'createAd' },
  { label: 'Upload CSV', value: 'uploadAd' },
];

export const MANUAL_ADD = 'manualAdd';
export const UPLOAD_CSV = 'uploadCSV';
export const INPUT_CLICK_TRACKERS_MODE = [
  { label: 'Add click trackers', value: MANUAL_ADD },
  { label: 'Upload CSV file', value: UPLOAD_CSV },
];

export const UPLOAD_AD_KEY = 'uploadDummyAds';

export const PROVIDER_TYPES = [
  { label: 'Select Provider from List', value: 'saved' },
  { label: 'Add New Provider', value: 'unsaved' },
];

export const CAMPAIGN_TRACKERS_CSV_HEADER = [
  'ID',
  'Ad',
  'AdGroup',
  'Click URL',
  'External ID',
  'Created Date',
  'Updated Date',
  'Copy Impr. Tracker',
  'Copy Click Tracker',
];

export const CREATE_AD_LIMIT_MSG = 'Create upto 20 ads,' +
' if you want to add more ads then please select Upload CSV';

// eslint-disable-next-line
export const SAMPLE_TEMPLATE_DOWNLOAD_URL = '/org/retrieve_s3object?' +
  'filename=fa70a2_SampleTemplate.csv';

export const UPLOAD_AD_LIMIT = 300;
