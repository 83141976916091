/* eslint-disable no-unused-vars */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { useFileUploader } from '@sixsense/core/components/data-entry/FileUpload/context';
import FileUploadItemList from '@sixsense/core/components/data-entry/FileUpload/FileUploadItemList';
import {
  FileUploadProps,
} from '@sixsense/core/components/data-entry/FileUpload/FileUpload';
import useFileListState from '@sixsense/core/components/data-entry/FileUpload/useFileListState';
import FileUploadButton from './FileUploadButton';

const componentsContainer = (items, existingFiles) => css({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginTop: (items?.length === 0 || existingFiles?.length > 0)
    ? '8px' : '0px',
  '> div': {
    flexDirection: 'unset !important',
    flexWrap: 'wrap',
    gap: '8px !important',
    '> div': {
      flex: '1 1 calc(50% - 16px) !important',
      padding: '8px !important',
    },
  },
});


type AcceptProps = {
  itemsLength: number;
  existingFiles: Array;
};

const FileUploadArea = ({
  disabled,
  items,
  multiple,
  onChange,
  showFileIcon = true,
  uploadUserMessage,
  accept,
  validateFile,
  itemsLength,
  existingFiles,
}: FileUploadProps & AcceptProps) => {
  const fileUploader = useFileUploader();

  const { addFiles, removeItem, retryItem } = useFileListState({
    items,
    onChange,
    fileUploader,
    validateFile,
  });

  return (
    <React.Fragment>
      <div css={componentsContainer(items, existingFiles)}>
        {items?.length ? (
          <FileUploadItemList
            items={items}
            showFileIcon={showFileIcon}
            removeItem={removeItem}
            retryItem={retryItem}
            disabled={disabled}
          />
      ) : null}
        {itemsLength < 3 &&
        <FileUploadButton
          accept={accept}
          disabled={disabled}
          uploadUserMessage={uploadUserMessage}
          multiple={multiple}
          addFiles={addFiles}
        />
      }
      </div>
    </React.Fragment>
  );
};

export default FileUploadArea;
