import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ErrorComponent } from 'OLD_components';
import Disabled from './Disabled';
import NoData from './NoData';
import ZeroData from './ZeroData';
import { Loading2 as Loading } from 'v2-components';

const StateComponent = (
  WrappedComponent,
  errorComponent,
  ZeroDataComponent = ZeroData,
  NoDataComponent = NoData,
  LoadingComponent = Loading,
  DisabledComponent = Disabled,
) =>
  class StateClass extends PureComponent {
    // Default to page level defaults
    static propTypes = {
      error: PropTypes.bool,
      errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      disabled: PropTypes.bool,
      disabledMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      zeroData: PropTypes.bool,
      noData: PropTypes.bool,
      onNoData: PropTypes.func,
      noDataMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      zeroDataMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      onRefresh: PropTypes.func,
      loading: PropTypes.bool,
      className: PropTypes.string,
      size: PropTypes.string,
      noDataIcon: PropTypes.string,
      noDataButtonText: PropTypes.string,
      loaded: PropTypes.bool,
    };

    static defaultProps = {
      size: 'med1',
      loaded: true,
      errorMessage: 'Looks like we hit a snag. Please Refresh',
    };

    render() {
      const {
        error,
        errorMessage,

        disabled,
        disabledMessage,

        zeroData,
        zeroDataMessage,

        noData,
        noDataMessage,
        noDataIcon,
        noDataButtonText,
        onNoData,

        onRefresh,
        loading,
        className,
        size,
        loaded,
      } = this.props;

      if (loading || (!loaded && !error)) {
        return <LoadingComponent className={className} loading />;
      }

      if (error) {
        const ErrorDisplayComponent = errorComponent || ErrorComponent;
        return (
          <ErrorDisplayComponent
            className={className}
            message={errorMessage}
            onClick={onRefresh}
            size={size}
          />
        );
      }

      if (disabled) {
        return <DisabledComponent message={disabledMessage} />;
      }

      if (noData) {
        return (
          <NoDataComponent
            iconType={noDataIcon}
            className={className}
            size={size}
            buttonText={noDataButtonText}
            onClick={onNoData}
            message={noDataMessage}
          />
        );
      }

      if (zeroData) {
        return (
          <ZeroDataComponent
            message={zeroDataMessage}
            onClick={onRefresh} //
            className={className}
            size={size}
          />
        );
      }
      return <WrappedComponent {...this.props} />;
    }
  };

export default StateComponent;
