import React, { useRef } from 'react';
import { AAColor } from '@sixsense/core/style';
import { css } from 'styles/emotion';
import { Flex } from '@sixsense/core/components';
import { Checkbox2, Icon, Tooltip2 } from 'v2-components';
import { DragSource } from 'react-dnd';

const styles = {
  container: css({
    padding: '10px 0',
    color: AAColor.GREY,
  }),
  dragIcon: css({
    height: 20,
  }),
  visibilityIcon: css({
    height: 20,
  }),
  title: css({
    flex: 1,
    border: '1px solid #C6CACE',
    fontSize: 14,
    lineHeight: '18px',
    padding: '7px 15px',
    borderRadius: 2,
    fontWeight: 'normal',
    backgroundColor: '#fff',
    marginRight: 15,
  }),
  source: css({
    color: '#7E888E',
    fontSize: 12,
    fontStyle: 'italic',
  }),
  checkbox: css({
    marginRight: 17,
  }),
};

type Props = {
  name: string;
  source: string;
  isDragging: boolean;
  connectDragSource: any;
  connectDragPreview: any;
  checked?: boolean;
  grouped?: boolean;
  onSelect?: () => void;
  hidden?: boolean;
  viewOnly?: boolean;
  toggleVisibility: () => void;
}

const SOURCE_LABEL_MAP = {
  SIXSENSE: '6sense',
  CRM_OPPORTUNITY_STAGE: 'CRM Opportunity Status',
};

const SalesStage = ({
  name,
  source,
  isDragging,
  connectDragSource,
  connectDragPreview,
  checked,
  grouped,
  onSelect,
  hidden,
  toggleVisibility,
  viewOnly,
}: Props) => {

  const dragSourceRef = useRef(null);
  connectDragSource(dragSourceRef);

  const dragPreviewRef = useRef(null);
  connectDragPreview(dragPreviewRef);

  return (
    <div
      ref={viewOnly ? undefined : dragPreviewRef}
      className={styles.container}
      style={{ opacity: isDragging ? 0.4 : 1 }}
    >
      <Flex alignItems="center">
        {(viewOnly && !grouped) ? null : (
          <Checkbox2
            size={Icon.SIZE.LARGE}
            placement="none"
            iconClassName={styles.checkbox}
            checked={checked}
            disabled={grouped}
            onChange={onSelect}
          />
        )}
        <Flex
          className={styles.title}
          justifyContent="space-between"
          style={{ opacity: hidden ? 0.5 : 1 }}
        >
          <span>{name}</span>
          <span className={styles.source}>Source: {SOURCE_LABEL_MAP[source]}</span>
        </Flex>
        <Tooltip2
          overlay={viewOnly ? '' : (
            'Hide stage.  Once hidden, this stage will stop showing in the reports.'
          )}
        >
          <Icon
            type="visibility_off"
            pointer
            onClick={viewOnly ? undefined : toggleVisibility}
            color={hidden ? Icon.COLORS.GREY1 : Icon.COLORS.GREY3}
          />
        </Tooltip2>
      </Flex>
    </div>
  );
};

export default DragSource(
  'SALES_STAGE',
  {
    beginDrag: (props: Props) => ({
      id: props.name,
      name: props.name,
    }),
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }),
)(SalesStage);
