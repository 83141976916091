// this file needs to be moved, but i'm not going to do the file organization
// for orc history right now. this is a temporary location and should be placed
// with other subreducers when they come.
//
// need to figure out how to handle error states
import { omit } from 'lodash';

export const initialState = {
  quickLookId: 0,

  mpLoading: false,
  mappingProfile: {},

  mlLoading: false,
  matchingLogic: undefined,

  commonFieldsLoading: false,
  commonFields: [],

  targets: [],
  targetsLoading: false,

  filterTypeList: [],
  filterTypesLoading: false,

  cdoFields: [],
  cdoFieldsLoading: false,

  tacticCampaignStates: {},
};

const OPEN_QUICK_LOOK = 'ORC/QL/SET_ID';
const openQuickLook = (quickLookId) => ({
  type: OPEN_QUICK_LOOK,
  ...initialState,
  quickLookId,
});

const LOAD_MP_SUC = 'ORC/QL/LOAD_MP_SUC';
const loadMappingProfileSuc = (mappingProfile) => ({
  type: LOAD_MP_SUC,
  mappingProfile,
  mpLoading: false,
});
const LOAD_MP_FAIL = 'ORC/QL/LOAD_MP_FAIL';
const loadMappingProfileFail = () => ({
  type: LOAD_MP_FAIL,
  mpLoading: false,
  error: true,
});

const LOAD_ML_FAIL = 'ORC/QL/LOAD_ML_FAIL';
const loadL2aRuleFail = () => ({ type: LOAD_ML_FAIL, mlLoading: false });
const LOAD_ML_SUC = 'ORC/QL/LOAD_ML_SUC';
const loadL2aRuleSuc = (matchingLogic) => ({ type: LOAD_ML_SUC, matchingLogic, mlLoading: false });

const LOAD_COMMON_FIELDS_SUC = 'ORC/QL/LOAD_CF_SUC';
const LOAD_COMMON_FIELDS_FAIL = 'ORC/QL/LOAD_CF_FAIL';
const loadCommonFieldsSuc = (commonFields) => ({
  type: LOAD_COMMON_FIELDS_SUC,
  commonFields,
  commonFieldsLoading: false,
});
const loadCommonFieldsFail = () => ({ type: LOAD_COMMON_FIELDS_FAIL, commonFieldsLoading: false });

const LOAD_TARGET_SUC = 'ORC/QL/LOAD_TARGET_SUC';
const LOAD_TARGET_FAIL = 'ORC/QL/LOAD_TARGET_FAIL';
const loadTargetsSuc = (targets) => ({ type: LOAD_TARGET_SUC, targets, targetsLoading: false });
const loadTargetsFail = () => ({ type: LOAD_TARGET_FAIL, targetLoading: false });

const LOAD_FT_SUC = 'ORC/QL/LOAD_FT_SUC';
const loadFilterTypesSuc = (filterTypeList) => ({
  type: LOAD_FT_SUC,
  filterTypeList,
  filterTypesLoading: false,
});
const LOAD_FT_FAIL = 'ORC/QL/LOAD_FT_SUC';
const loadFilterTypesFail = () => ({ type: LOAD_FT_FAIL, filterTypesLoading: false, error: true });

const SET_LOADING = 'ORC/QL/SET_LOADING_STATE';
const setLoadingState = (loadingKey) => ({ type: SET_LOADING, [loadingKey]: true });

const LOAD_CDO_FIELDS_SUC = 'ORC/QL/LOAD_CDO_FIELDS_SUC';
const LOAD_CDO_FIELDS_FAIL = 'ORC/QL/LOAD_CDO_FIELDS_FAIL';
const loadCdoFieldsSuc = (cdoFields) => ({
  type: LOAD_CDO_FIELDS_SUC,
  cdoFieldsLoading: false,
  cdoFields,
});
const loadCdoFieldsFail = () => ({
  type: LOAD_CDO_FIELDS_FAIL,
  cdoFieldsLoading: false,
  error: true,
});

const LOAD_CAMPAIGN_STATES = 'ORC/QL/LOAD_CAMPAIGN_STATES';
const loadCampaignStates = (tacticCampaignStates) => ({
  type: LOAD_CAMPAIGN_STATES,
  tacticCampaignStates,
});


// use withoutreducer util from file org pr when merged...
const quickLookReducer = (state = initialState, action) => ({
  ...state,
  ...omit(action, 'type'),
});

export const actionTypes = {
  OPEN_QUICK_LOOK,
  SET_LOADING,

  LOAD_MP_SUC,
  LOAD_MP_FAIL,

  LOAD_ML_SUC,
  LOAD_ML_FAIL,

  LOAD_COMMON_FIELDS_SUC,
  LOAD_COMMON_FIELDS_FAIL,
  LOAD_TARGET_SUC,
  LOAD_TARGET_FAIL,

  LOAD_FT_SUC,
  LOAD_FT_FAIL,

  LOAD_CDO_FIELDS_FAIL,
  LOAD_CDO_FIELDS_SUC,

  LOAD_CAMPAIGN_STATES,
};

export const actions = {
  openQuickLook,
  loadMappingProfileSuc,
  loadMappingProfileFail,
  setLoadingState,

  loadL2aRuleSuc,
  loadL2aRuleFail,

  loadCommonFieldsFail,
  loadCommonFieldsSuc,

  loadTargetsSuc,
  loadTargetsFail,

  loadFilterTypesSuc,
  loadFilterTypesFail,

  loadCdoFieldsSuc,
  loadCdoFieldsFail,

  loadCampaignStates,
};

export default quickLookReducer;
