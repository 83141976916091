import React from 'react';
import Text from 'v2-components/Text';
import { CAMPAIGN_SOURCE_LABELS } from '../../../constants';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';

export const CAMPAIGN_TYPE = {
  customizable: false,
  key: 'campaign-type',
  csvColumnKey: 'campaign_source',
  title: <Label name={CAMPAIGN_LABELS.campaignType} />,
  dataIndex: 'campaign_source',
  width: 150,
  render: (campaignSource) => {
    const campaignTypeLabel = CAMPAIGN_SOURCE_LABELS[campaignSource];

    return (<Text
      type={Text.TYPE.SUBBODY}
      title={campaignSource}
    >
      {campaignTypeLabel}
    </Text>);
  },
};
