import { createSelector } from 'reselect';
import { get, filter } from 'lodash';
import { orgSelector } from 'modules/user/selectors';
import { CLASSIFICATION_TYPES, DEFAULT_FOLDERS, STATE_KEY } from './constants';
import { DRAFT_FOLDER_LABEL } from 'routes/Advertising/constants';

const baseSelector = (state) => state.advertising[STATE_KEY];
const foldersSelector = (state) => baseSelector(state).folders.data;
const classificationModalSelector = (state) => baseSelector(state).classificationsModal;

const classificationEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `org/${orgId}/campaign_classification/`
);

const fetchClassificationEndpointSelector = createSelector(
  classificationEndpointSelector,
  (classificationEndpoint) => `${classificationEndpoint}?offset=0&limit=500`
);

export const userCreatedFolderSelector = createSelector(
  foldersSelector,
  (folders) =>
    [...folders].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
);

export const defaultFolderSelector = createSelector(
  foldersSelector,
  (folders) => folders
);

export const archivedFolderSelector = createSelector(
  defaultFolderSelector,
  (defaultFolders) =>
    defaultFolders.find(
      (folder) =>
        folder.classification_type === DEFAULT_FOLDERS.CAMPAIGNS_ARCHIVED
    ) || {}
);

export const unsortedFolderSelector = createSelector(
  foldersSelector,
  (folders) =>
    folders.find(
      (folder) => folder.classification_type === CLASSIFICATION_TYPES.CAMPAIGNS_UNSORTED
    )
);

const classificationChoicesSelector = createSelector(
  foldersSelector,
  (allFolders) =>
    filter(allFolders, (folder) =>
      get(folder, 'classification_type') !== CLASSIFICATION_TYPES.CAMPAIGNS_ARCHIVED,
    )
);

const existingFolderNamesSelector = createSelector(
  foldersSelector,
  (folders) =>
    [
      DRAFT_FOLDER_LABEL,
      ...(folders.map((folder) => folder.name)),
    ]
);

const changeClassificationEndpointSelector = createSelector(
  [classificationEndpointSelector, (_, action) => action],
  (classificationEndpoint, action) =>
    `${classificationEndpoint}${action.payload.folder.id}/`
);

const changeClassificationPayloadSelector = createSelector(
  (_, action) => action,
  (action) => ({
    body: JSON.stringify({
      campaign_ids: action.payload.itemIds,
    }),
  }),
);

const createClassificationPayloadSelector = createSelector(
  (_, action) => action,
  (action) => ({
    body: JSON.stringify({
      name: action.payload,
      is_deleted: false,
      campaign_ids: [],
      classification_type: CLASSIFICATION_TYPES.CAMPAIGN_FOLDER,
    }),
  }),
);

const campaignClassificationModalSelector = createSelector(
  classificationModalSelector,
  (classificationsModal) => classificationsModal
);

const bulkDeleteClassificationEndpointSelector = createSelector(
  classificationEndpointSelector,
  (classificationEndpoint) => `${classificationEndpoint}bulk_delete/`,
);

export const selectors = {
  baseSelector,
  classificationChoicesSelector,
  defaultFolderSelector,
  unsortedFolderSelector,
  archivedFolderSelector,
  userCreatedFolderSelector,
  existingFolderNamesSelector,
  classificationEndpointSelector,
  fetchClassificationEndpointSelector,
  changeClassificationPayloadSelector,
  changeClassificationEndpointSelector,
  campaignClassificationModalSelector,
  createClassificationPayloadSelector,
  bulkDeleteClassificationEndpointSelector,
};
