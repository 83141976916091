import React, { useState } from 'react';
import { Flex } from '@sixsense/core/components';
import { css } from 'styles/emotion';
import { Button, Modal, Text, Loading, Input } from 'v2-components';
import { useOrg } from 'hooks';
import { useSharedState, useRequest } from '@sixsense/core';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { groupsConfigState, selectedGroupState } from '../../state';
import { getUpdatedConfig, resetConfig } from '../../utils';
import { actions as globalActions } from 'modules/global';

const styles = {
  input: css({
    width: '100%',
  }),
};

type Props = {
  onCancel: () => void;
};

const RenameGroupModal = ({ onCancel }: Props) => {
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useSharedState(groupsConfigState);
  const [keywordGroup, setKeywordGroup] = useSharedState(selectedGroupState);
  const tempName = keywordGroup.tempName;

  const org = useOrg();
  const request = useRequest();
  const dispatch = useDispatch();

  const onClose = () => {
    onCancel();
    setKeywordGroup({ ...keywordGroup, tempName });
  };

  const renameGroup = async () => {
    const body = {
      config: {
        id: keywordGroup.id,
        name: tempName,
      },
    };
    setLoading(true);
    try {
      await request(`organization/${org.id}/keyword_set/`, {
        method: 'PATCH',
        body: JSON.stringify(body),
      });
      dispatch(globalActions.showNotification('success', 'Successfully renamed group'));
      onClose();
      const updatedConfig = getUpdatedConfig(config);
      resetConfig(updatedConfig, setConfig);
      setKeywordGroup({});
    } catch (e) {
      let errorMessage = 'There was an issue renaming your group. Please try again later';
      if (e.errorStatus === 400) {
        errorMessage = e.body;
      }
      dispatch(globalActions.showNotification('error', errorMessage));
    }
    setLoading(false);
  };


  const footer = (
    <Flex justifyContent="flex-end" style={{ padding: '10px 0' }}>
      <Button
        pointer
        onClick={() => {
          onClose();
        }}
      >
        Cancel
      </Button>
      <Button
        pointer
        className="aam-l--10"
        type={'primary'}
        onClick={() => {
          renameGroup();
        }}
        disabled={loading || !tempName || tempName === keywordGroup.name}
      >
        Rename
      </Button>
      {loading && (
        <Loading
          size="small"
          spinnerWidth={15}
          spinnerHeight={15}
          pageLevel={false}
          className="aam-l--10"
        />
      )}
    </Flex>
  );

  return (
    <Modal
      visible
      bodyStyle={{ padding: 0 }}
      footer={footer}
      maskClosable={false}
      closable={false}
      width={400}
    >
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9' }}>
        <Text type={Text.TYPE.TITLE} bold>Rename {keywordGroup.name}</Text>
      </Flex>
      <Flex style={{ padding: '20px 30px' }}>
        <Input
          value={tempName}
          onChange={(e) => setKeywordGroup({ ...keywordGroup, tempName: e.target.value })}
          className={styles.input}
          placeholder={'Enter keyword group name'}
          size={450}
          onFocus={(e) => e.target.select()}
          autoFocus
        />
      </Flex>
    </Modal>
  );
};

export default RenameGroupModal;
