import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Pill, Tooltip } from 'v2-components';
import { calculateMediaSize } from 'utils/utils';
import styles from './../AdPreviewWrapper.module.scss';
import { cx } from 'styles/emotion';

const AcceptedDimensions = (props) => {
  const {
    acceptedFileDimensions,
    customPillClass,
  } = props;
  const getDimensionsWithImages = (dimensions) => {
    let baseSize = 60;
    if (dimensions === '320x50' || dimensions === '728x90') baseSize = 100;
    const { height, width } = calculateMediaSize(dimensions, baseSize);
    return (
      <Row flexDirection="column" alignItems={'center'}>
        <Col>
          <div style={{ height, width, border: '1px solid white', marginBottom: 5 }} />
        </Col>
        <Col>{dimensions}</Col>
      </Row>
    );
  };
  const acceptedDimensions = acceptedFileDimensions.map((dim) => (
    <Pill
      notClickable
      key={`file-size-${dim}`}
      inactive
      className={cx(styles.pillClass, customPillClass)}
    >
      <Tooltip title={getDimensionsWithImages(dim)}>{dim}</Tooltip>
    </Pill>
    ));
  return (
    <div className={styles.pillContainer}>
      {acceptedDimensions}
    </div>
  );
};

AcceptedDimensions.propTypes = {
  acceptedFileDimensions: PropTypes.array,
  customPillClass: PropTypes.string,
};

export default AcceptedDimensions;
