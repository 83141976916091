import React from 'react';
import { Checkbox2 as Checkbox } from 'v2-components';
import {
  Permission,
  setPermissions,
  usePermissions,
  SalesPermission,
  WorkflowsPermission,
} from '@sixsense/rbac';
import { useDispatch } from '@sixsense/core/versioned/react-redux';

const ALL_PERMISSIONS = [
  ...Object.values(Permission),
  ...Object.values(SalesPermission),
  ...Object.values(WorkflowsPermission),
];

const RBACPermissions = () => {
  const dispatch = useDispatch();
  const permissions = usePermissions();

  const setPermission = (permission: Permission | SalesPermission, val: boolean) => {
    const nextPermissions = new Set(permissions);
    if (val) {
      nextPermissions.add(permission);
    } else {
      nextPermissions.delete(permission);
    }
    dispatch(setPermissions(Array.from(nextPermissions)));
  };

  return (
    <div>
      {ALL_PERMISSIONS.map((permission) => (
        <Checkbox
          label={permission}
          checked={permissions.has(permission)}
          onChange={(e) => setPermission(permission, e.target.checked)}
          key={permission}
        />
      ))}
    </div>
  );
};

export default RBACPermissions;
