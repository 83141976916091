import {
  L2A, NET_NEW_ACCOUNTS, L2C,
  EXPORT_SCORES,
  PUSH_ESI_CONTACTS,
} from '../CreateOrchestration/constants';

export const HISTORY_PAGE_SIZE = 10;
export const SUMMARY_PAGE_SIZE = 10;

export const AI_HISTORY_CONDITIONS = {
  CATEGORY: 'category',
  TACTIC: 'tactic',
  TAB: 'tab',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
};

const catEnrichEligible = {
  label: {
    account: 'Accounts Eligible',
    contact: 'Contacts Eligible',
    lead: 'Leads Eligible',
    malead: 'Leads Eligible',
  },
  value: 'eligible',
  count_value: 'eligible_records_count',
  // eslint-disable-next-line max-len
  tooltip: 'The records selected according to your Type configuration. This includes records that may be skipped during the run itself.',
};
const catEnrichSkipped = {
  label: {
    account: 'Accounts Skipped',
    contact: 'Contacts Skipped',
    lead: 'Leads Skipped',
    malead: 'Leads Skipped',
  },
  value: 'skipped',
  count_value: 'skipped_records_count',
  // eslint-disable-next-line max-len
  tooltip: 'The records excluded due to your Limitations configuration or due to 6sense determining that no new data was available.',
};

const catEnrichEnriched = {
  label: {
    account: 'Accounts Enriched',
    contact: 'Contacts Enriched',
    lead: 'Leads Enriched',
    malead: 'Leads Enriched',
  },
  value: 'enriched',
  count_value: 'enriched_records_count',
  tooltip: 'The records where at least one field was successfully enriched with new data.',
};
const catEnrichErrors = {
  label: {
    account: 'Accounts with Errors',
    contact: 'Contacts with Errors',
    lead: 'Leads with Errors',
    malead: 'Leads with Errors',
  },
  value: 'failed',
  count_value: 'failed_records_count',
  // eslint-disable-next-line max-len
  tooltip: 'The records where at least one field failed to enrich due to external errors, at the level of the field(s) and/or entire record itself.',
};

const catSegmentEnriched = {
  label: {
    account: 'Accounts Updated',
    contact: 'Contacts Updated',
    lead: 'Leads Updated',
    malead: 'Leads Updated',
  },
  value: 'enriched',
  count_value: 'enriched_records_count',
  tooltip: 'The records where at least one field was successfully updated with new data.',
};

const catSegmentErrors = {
  label: {
    account: 'Accounts with Errors',
    contact: 'Contacts with Errors',
    lead: 'Leads with Errors',
    malead: 'Leads with Errors',
  },
  value: 'failed',
  count_value: 'failed_records_count',
  // eslint-disable-next-line max-len
  tooltip: 'The records where at least one field failed to update due to external errors, at the level of the field(s) and/or entire record itself.',
};

const catPushAccountsInSegment = {
  label: 'Accounts in Segment',
  value: 'accounts_in_segment',
  count_value: 'accounts_in_segment',
  tooltip: '',
};
const catPushAccountsProcessed = {
  label: 'Accounts Processed',
  value: 'accounts_processed',
  count_value: 'accounts_processed',
  tooltip: '',
};
const catPushContactsPushed = {
  label: 'Contacts Pushed',
  value: 'contacts_pushed',
  count_value: 'contacts_pushed',
  tooltip: '',
};
const catPushContactsWithErrors = {
  label: 'Contacts with Errors',
  value: 'contacts_with_errors',
  count_value: 'contacts_with_errors',
  tooltip: '',
};
const catPurchaseAccountsInSegment = {
  label: 'Accounts in Segment',
  value: 'accounts_in_segment',
  count_value: 'accounts_in_segment',
  tooltip: '',
};
const catPurchaseAccountsProcessed = {
  label: 'Accounts Processed',
  value: 'accounts_processed',
  count_value: 'accounts_processed',
  tooltip: '',
};
const catPurchaseContactsPurchased = {
  label: 'Contacts Purchased',
  value: 'contacts_purchased',
  count_value: 'contacts_purchased',
  tooltip: '',
};
const catPurchaseContactsPushed = {
  label: 'Contacts Pushed',
  value: 'contacts_pushed',
  count_value: 'contacts_pushed',
  tooltip: '',
};
const catPurchaseContactsWithErrors = {
  label: 'Contacts with Errors',
  value: 'contacts_with_errors',
  count_value: 'contacts_with_errors',
  tooltip: '',
};
const catCreateAccountsIdentified = {
  label: 'Accounts Identified',
  value: 'eligible',
  count_value: 'eligible_records_count',
  tooltip: '',
};
const catCreateAccountsCreated = {
  label: 'Accounts Created',
  value: 'account_created',
  count_value: 'account_created_records_count',
  tooltip: '',
};
const catCreateAccountsWithErrors = {
  label: 'Accounts with Errors',
  value: 'failed',
  count_value: 'failed_records_count',
  tooltip: '',
};

const catL2CConverted = {
  label: {
    lead: 'Leads Converted',
  },
  value: 'lead_converted',
  count_value: 'lead_converted_records_count',
  tooltip: '',
};

const catExportTotal = {
  label: {
    account: 'Total Accounts',
    contact: 'Total Contacts',
    lead: 'Total Leads',
  },
  value: 'TOTAL',
  count_value: 'total_records_count',
  // eslint-disable-next-line max-len
  tooltip: '',
};

const catExportSuccessful = {
  label: 'Successful Exports',
  value: 'SUCCESS',
  count_value: 'successful_records_count',
  tooltip: '',
};

const catExportFailed = {
  label: 'Failed Exports',
  value: 'FAILURE',
  count_value: 'failed_records_count',
  tooltip: '',
};

const catExportUnprocessed = {
  label: 'Unprocessed Exports',
  value: 'UNPROCESSED',
  count_value: 'unprocessed_records_count',
  tooltip: '',
};

const catExportPending = {
  label: 'Pending Exports',
  value: 'PENDING',
  count_value: 'pending_records_count',
  tooltip: '',
};

const catESIContactsIdentified = {
  label: 'Contacts Identified',
  value: 'contacts_identified',
  count_value: 'contacts_identified',
  tooltip: '',
};

export const CATEGORY_DATA = {
  ENRICH: [
    catEnrichEligible,
    catEnrichSkipped,
    catEnrichEnriched,
    catEnrichErrors,
  ],
  ENRICH_SEGMENTS: [
    catEnrichEligible,
    catEnrichSkipped,
    catSegmentEnriched,
    catSegmentErrors,
  ],
  PUSH_CONTACTS: [
    catPushAccountsInSegment,
    catPushAccountsProcessed,
    catPushContactsPushed,
    catPushContactsWithErrors,
  ],
  PURCHASE: [
    catPurchaseAccountsInSegment,
    catPurchaseAccountsProcessed,
    catPurchaseContactsPurchased,
    catPurchaseContactsPushed,
    catPurchaseContactsWithErrors,
  ],
  [L2A]: [ // todo
    catEnrichEligible,
    catEnrichSkipped,
    catSegmentEnriched,
    catSegmentErrors,
  ],
  [L2C]: [
    catEnrichEligible,
    catL2CConverted,
    catSegmentErrors,
  ],
  [NET_NEW_ACCOUNTS]: [
    catCreateAccountsIdentified,
    catCreateAccountsCreated,
    catCreateAccountsWithErrors,
  ],
  [EXPORT_SCORES]: [
    catExportTotal,
    catExportSuccessful,
    catExportFailed,
    catExportUnprocessed,
    catExportPending,
  ],
  [PUSH_ESI_CONTACTS]: [
    catESIContactsIdentified,
    catPurchaseContactsPushed,
    catPurchaseContactsWithErrors,
  ],
};

const website = { label: '6sense Website', value: 'FIRMO_DOMAIN', style: 'website' };
const country = { label: '6sense Country', value: 'FIRMO_COUNTRY', style: 'country' };
const csvDownload = { label: '', value: 'csv_download', style: 'csvDownload' };

const enrichCommonObj = [
  { ...csvDownload },
  { label: 'ID', value: 'ID', style: 'Id' },
  { label: 'Name', value: 'NAME', style: 'name' },
  { label: '6sense Account Name', value: 'FIRMO_NAME', style: 'companyName' },
  { ...website },
  { ...country },
];
const pushCommonObj = [
  { ...csvDownload },
];

const enrichEligible = {
  data: [...enrichCommonObj],
  headingClass: 'historyEnrichAccounts',
};
const enrichSkipped = {
  data: [...enrichCommonObj],
  headingClass: 'historyEnrichAccounts',
};
const enrichEnriched = {
  data: [...enrichCommonObj],
  headingClass: 'historyEnrichAccounts',
};
const enrichFailed = {
  data: [
    ...enrichCommonObj,
    { label: 'Error', value: 'ERROR', style: 'error' },
  ],
  headingClass: 'historyEnrichAccountsError',
};

const pushAccountsInSegment = {
  data: [
    ...pushCommonObj,
    { label: '6sense Company Name', value: 'FIRMO_NAME', style: 'name' },
    { label: 'Website', value: 'FIRMO_DOMAIN', style: 'website' },
    { label: 'Country', value: 'FIRMO_COUNTRY', style: 'country' },
  ],
  headingClass: 'historyPushAccountsInSegment',
};

const pushAccountsProcessed = {
  data: [
    ...pushCommonObj,
    { label: '6sense Company Name', value: 'FIRMO_NAME', style: 'name' },
    { label: 'Website', value: 'FIRMO_DOMAIN', style: 'website' },
    { label: 'Country', value: 'FIRMO_COUNTRY', style: 'country' },
    { label: 'Existing Contacts', value: 'TOTAL_CONTACTS', style: 'existingContacts' },
    { label: 'Eligible Contacts', value: 'ELIGIBLE_CONTACTS', style: 'eligibleContacts' },
    { label: 'Contacts Pushed', value: 'CONTACTS_PUSHED', style: 'contactsPushed' },
  ],
  headingClass: 'historyPushAccountsProcessed',
};
const pushContactsPushed = {
  data: [
    ...pushCommonObj,
    { label: 'ID', value: 'ID', style: 'Id' },
    { label: 'Name', value: 'NAME', style: 'name' },
    { label: 'Title', value: 'TITLE', style: 'title' },
    { label: 'Company', value: 'FIRMO_COMPANY', style: 'companyName' },
    { label: 'Country', value: 'FIRMO_COUNTRY', style: 'country' },
  ],
  headingClass: 'historyPushContactsPushed',
};
const pushContactsWithErrors = {
  data: [
    ...pushCommonObj,
    { label: 'ID', value: 'ID', style: 'Id' },
    { label: 'Name', value: 'NAME', style: 'name' },
    { label: 'Title', value: 'TITLE', style: 'title' },
    { label: 'Company', value: 'FIRMO_COMPANY', style: 'companyName' },
    { label: 'Country', value: 'FIRMO_COUNTRY', style: 'country' },
    { label: 'Error', value: 'REASON', style: 'error' },
  ],
  headingClass: 'historyPushContactsError',
};

const purchaseAccountsProcessed = {
  data: [
    ...pushCommonObj,
    { label: '6sense Company Name', value: 'FIRMO_NAME', style: 'name' },
    { label: 'Website', value: 'FIRMO_DOMAIN', style: 'website' },
    { label: 'Country', value: 'FIRMO_COUNTRY', style: 'country' },
    { label: 'Total New People', value: 'TOTAL_CONTACTS', style: 'totalNewPeople' },
    { label: 'Eligible New People', value: 'ELIGIBLE_CONTACTS', style: 'eligibleNewPeople' },
    { label: 'Records Purchased', value: 'CONTACTS_PURCHASED', style: 'recordsPurchased' },
  ],
  headingClass: 'historyPurchaseAccountsProcessed',
};

const purchasePurchased = {
  data: [
    ...pushCommonObj,
    { label: 'Name', value: 'NAME', style: 'name' },
    { label: 'Title', value: 'TITLE', style: 'title' },
    { label: 'Company', value: 'COMPANY', style: 'companyName' },
    { label: 'Country', value: 'COUNTRY', style: 'country' },
    { label: 'Email available', value: 'EMAIL', style: 'emailAvailable' },
    {
      label: 'Direct Dial Phone available',
      value: 'MOBILE_PHONE',
      style: 'directDialPhoneAvailable',
    },
    {
      label: 'Company Phone available',
      value: 'PHONE',
      style: 'companyPhoneAvailable',
    },
    { label: 'Source', value: 'SOURCE', style: 'source' },
  ],
  headingClass: 'historyPurchasePurchased',
};

const purchaseContactsWithErrors = {
  data: [
    ...pushCommonObj,
    { label: 'Email', value: 'EMAIL', style: 'Id' },
    { label: 'Name', value: 'NAME', style: 'name' },
    { label: 'Title', value: 'TITLE', style: 'title' },
    { label: 'Company', value: 'COMPANY', style: 'companyName' },
    { label: 'Country', value: 'COUNTRY', style: 'country' },
    { label: 'Error', value: 'REASON', style: 'error' },
  ],
  headingClass: 'historyPushContactsError',
};
const purchaseContactsPushed = {
  data: [
    ...pushCommonObj,
    { label: 'Email', value: 'EMAIL', style: 'Id' },
    { label: 'Name', value: 'NAME', style: 'name' },
    { label: 'Title', value: 'TITLE', style: 'title' },
    { label: 'Company', value: 'COMPANY', style: 'companyName' },
    { label: 'Country', value: 'COUNTRY', style: 'country' },
  ],
  headingClass: 'historyPushContactsPushed',
};

const createAccount = {
  data: [
    { ...csvDownload },
    { label: 'ID', value: 'ID', style: 'Id' },
    { label: '6sense Account Name', value: 'FIRMO_NAME', style: 'name' },
    { label: '6sense Website', value: 'FIRMO_DOMAIN', style: 'website' },
    { label: 'Country', value: 'FIRMO_COUNTRY', style: 'country' },
  ],
  headingClass: 'historyCreateAccount',
};
const createAccountWithError = {
  data: [
    ...createAccount.data,
    { label: 'Error', value: 'ERROR', style: 'error' },
  ],
  headingClass: 'historyCreateAccountError',
};

const leadsEligible = {
  data: [
    { ...csvDownload },
    { label: '{system} Lead ID', value: 'INTERNAL_ID', style: 'Id' },
    { label: 'Name', value: 'NAME', style: 'name' },
    { label: '6sense Account Name', value: 'FIRMO_NAME', style: 'companyName' },
    { ...website },
    { ...country },
  ],
  headingClass: 'historyEnrichAccounts',
};

const leadsConverted = {
  data: [
    { ...csvDownload },
    { label: '{system} Lead ID', value: 'INTERNAL_ID', style: 'LeadId' },
    { label: 'ID', value: 'ID', style: 'Id' },
    { label: 'Name', value: 'NAME', style: 'name' },
    { label: '6sense Account Name', value: 'FIRMO_NAME', style: 'companyName' },
    { ...website },
    { ...country },
  ],
  headingClass: 'historyL2CConverted',
};

const leadsErrored = {
  data: [
    ...leadsEligible.data,
    { label: 'Error', value: 'ERROR', style: 'error' },
  ],
  headingClass: 'historyEnrichAccountsError',
};

const exportsCommonObj = [
  { ...csvDownload },
  { label: '# Name', value: 'NAME', style: 'name' },
  { label: '# ID', value: 'ID', style: 'Id' },
  { label: '# Email', value: 'EMAIL', style: 'email' },
  { label: '# Account', value: 'FIRMO_NAME', style: 'companyName' },
  { ...country, label: 'Country' },
  { label: 'Product', value: 'PRODUCT', style: 'product' },
];

const exportsAccountObj = [
  { ...csvDownload },
  { label: '# Name', value: 'FIRMO_NAME', style: 'name' },
  { ...website, label: '# Website' },
  { label: '# ID', value: 'ID', style: 'Id' },
  { ...country, label: 'Country' },
  { label: 'Product', value: 'PRODUCT', style: 'product' },
];

const exportsConfig = {
  lead: {
    data: [...exportsCommonObj],
    headingClass: 'historyExportsCommon',
  },
  contact: {
    data: [...exportsCommonObj],
    headingClass: 'historyExportsCommon',
  },
  account: {
    data: [...exportsAccountObj],
    headingClass: 'historyExportsAccount',
  },
};

const exportsConfigFailure = {
  lead: {
    data: [...exportsCommonObj, { label: 'Reason', value: 'ERROR', style: 'error' }],
    headingClass: 'historyExportsCommonWithError',
  },
  contact: {
    data: [...exportsCommonObj, { label: 'Reason', value: 'ERROR', style: 'error' }],
    headingClass: 'historyExportsCommonWithError',
  },
  account: {
    data: [...exportsAccountObj, { label: 'Reason', value: 'ERROR', style: 'error' }],
    headingClass: 'historyExportsAccountWithError',
  },
};

const pushESIContactsPushed = {
  data: [
    ...pushCommonObj,
    { label: 'Company', value: 'COMPANY', style: 'companyName' },
    { label: 'First Name', value: 'FIRSTNAME', style: 'firstName' },
    { label: 'Last Name', value: 'LASTNAME', style: 'lastName' },
    { label: 'Email', value: 'EMAIL', style: 'email' },
  ],
  headingClass: 'historyPushESIContacts',
};

const pushESIContactsWithErrors = {
  data: [
    ...pushESIContactsPushed.data,
    { label: 'Error', value: 'REASON', style: 'error' },
  ],
  headingClass: 'historyPushESIContactsError',
};

const pushESIContactsIdentified = {
  data: [
    ...pushESIContactsPushed.data,
  ],
  headingClass: 'historyPushESIContacts',
};

export const HISTORY_TABLE_HEADINGS = {
  ENRICH_eligible: enrichEligible,
  ENRICH_skipped: enrichSkipped,
  ENRICH_enriched: enrichEnriched,
  ENRICH_failed: enrichFailed,
  ENRICH_SEGMENTS_eligible: enrichEligible,
  ENRICH_SEGMENTS_skipped: enrichSkipped,
  ENRICH_SEGMENTS_enriched: enrichEnriched,
  ENRICH_SEGMENTS_failed: enrichFailed,
  PUSH_CONTACTS_accounts_in_segment: pushAccountsInSegment,
  PUSH_CONTACTS_accounts_processed: pushAccountsProcessed,
  PUSH_CONTACTS_contacts_pushed: pushContactsPushed,
  PUSH_CONTACTS_contacts_with_errors: pushContactsWithErrors,
  PURCHASE_accounts_in_segment: pushAccountsInSegment,
  PURCHASE_contacts_pushed: purchaseContactsPushed,
  PURCHASE_contacts_with_errors: purchaseContactsWithErrors,
  PURCHASE_accounts_processed: purchaseAccountsProcessed,
  PURCHASE_contacts_purchased: purchasePurchased,

  L2A_eligible: enrichEligible,
  L2A_skipped: enrichSkipped,
  L2A_enriched: enrichEnriched,
  L2A_failed: enrichFailed,
  NET_NEW_ACCOUNTS_eligible: createAccount,
  NET_NEW_ACCOUNTS_account_created: createAccount,
  NET_NEW_ACCOUNTS_failed: createAccountWithError,

  L2C_eligible: leadsEligible,
  L2C_lead_converted: leadsConverted,
  L2C_failed: leadsErrored,

  EXPORT_SCORES_TOTAL: exportsConfig,
  EXPORT_SCORES_SUCCESS: exportsConfig,
  EXPORT_SCORES_FAILURE: exportsConfigFailure,
  EXPORT_SCORES_UNPROCESSED: exportsConfigFailure,
  EXPORT_SCORES_PENDING: exportsConfig,

  [`${PUSH_ESI_CONTACTS}_contacts_identified`]: pushESIContactsIdentified,
  [`${PUSH_ESI_CONTACTS}_contacts_pushed`]: pushESIContactsPushed,
  [`${PUSH_ESI_CONTACTS}_contacts_with_errors`]: pushESIContactsWithErrors,
};
const csvPushContactsPushed = [
  { label: 'ID', value: 'ID' },
  { label: 'Name', value: 'NAME' },
  { label: 'Email', value: 'EMAIL' },
  { label: 'Phone', value: 'PHONE' },
  { label: 'Title', value: 'TITLE' },
  { label: 'Title Function', value: 'TITLE_FUNCTION' },
  { label: 'Title Level', value: 'TITLE_LEVEL' },
  { label: 'Company', value: 'FIRMO_COMPANY' },
  { label: 'Website', value: 'FIRMO_DOMAIN' },
  { label: 'Country', value: 'FIRMO_COUNTRY' },
  { label: 'State', value: 'FIRMO_STATE' },
  { label: 'City', value: 'FIRMO_CITY' },
  { label: 'Error', value: 'REASON' },
];
const csvPurchaseContacts = [
  { label: 'ID', value: 'ID' },
  { label: 'Email', value: 'EMAIL' },
  { label: 'Account ID', value: 'ACCOUNTID' },
  { label: 'Name', value: 'NAME' },
  { label: 'Website', value: 'CANONICALDOMAIN' },
  { label: 'Country', value: 'COUNTRY' },
  { label: 'State', value: 'STATE' },
  { label: 'City', value: 'CITY' },
  { label: 'Street', value: 'STREET' },
  { label: 'Title', value: 'TITLE' },
  { label: 'Title Function', value: 'TITLE_FUNCTION' },
  { label: 'Title Level', value: 'TITLE_LEVEL' },
  { label: 'Company', value: 'COMPANY' },
  { label: 'Industry', value: 'Z_INDUSTRY' },
  { label: 'Direct Dial Phone', value: 'MOBILE_PHONE' },
  { label: 'Company Phone', value: 'PHONE' },
  { label: 'Source', value: 'SOURCE' },
  { label: 'Error', value: 'REASON' },
];

export const CSV_HEADINGS = {
  PUSH_CONTACTS_accounts_in_segment: pushAccountsInSegment.data,
  PUSH_CONTACTS_accounts_processed: pushAccountsProcessed.data,
  PUSH_CONTACTS_contacts_pushed: csvPushContactsPushed,
  PUSH_CONTACTS_contacts_with_errors: pushContactsWithErrors.data,
  PURCHASE_accounts_in_segment: pushAccountsInSegment.data,
  PURCHASE_accounts_processed: purchaseAccountsProcessed.data,
  PURCHASE_contacts_purchased: csvPurchaseContacts,
  PURCHASE_contacts_pushed: csvPurchaseContacts,
  PURCHASE_contacts_with_errors: csvPurchaseContacts,
  [`${PUSH_ESI_CONTACTS}_contacts_pushed`]: pushESIContactsPushed.data,
  [`${PUSH_ESI_CONTACTS}_contacts_with_errors`]: pushESIContactsWithErrors.data,
  [`${PUSH_ESI_CONTACTS}_contacts_identified`]: pushESIContactsIdentified.data,
};
