import React from 'react';
import { PropTypes } from 'prop-types';
import { ActionItem, Tooltip, Text } from 'v2-components';

export const CampaignActionItem = ({
  label, isDisabled, tooltipText, onClick, id, dataPendo,
}) => (
  <ActionItem
    id={`${id}-campaign`}
    key={`${id}-campaign`}
    action={onClick}
    disabled={isDisabled}
    data-pendo={`${dataPendo}Campaign`}
  >
    <Tooltip
      overlay={isDisabled && <Text color="white">{tooltipText}</Text>}
      placement="left"
    >
      {label}
    </Tooltip>
  </ActionItem>
);

CampaignActionItem.defaultProps = {
  isDisabled: false,
};

CampaignActionItem.propTypes = {
  label: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  tooltipText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  dataPendo: PropTypes.string.isRequired,
};

export default CampaignActionItem;
