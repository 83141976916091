import React from 'react';
import PropTypes from 'prop-types';
import { flow, map, some } from 'lodash';
import { Checkbox, Col, Row } from 'v2-components';
import { TablePreviewLayout } from 'aa-components';
import { NewPaginationComponent } from 'HOCS';
import { toDisplayfp, formatDate, truncateString } from 'utils/utils';
import { DATE_FORMAT, CREATIVE_LIMIT } from 'utils/constants';
import styles from './CreativesList.module.scss';
import { parseFileTypeName } from './utils';

const { Media, Table } = TablePreviewLayout;

function CreativesListComponent(props) {
  const {
    dataset,
    showCreativePreview,
    selectedCreativePreview,
    isLoading,
    orderCreativeList,
    ordering,
    orderBy,
    selectedCreativeList = [],
    isMultipleAdPlacement,
    selectedLocalCreatives,
    addLocalCreativeToState,
  } = props;
  const selectedCreativeId = selectedCreativePreview.id || '';
  const NA = '-';
  const stopValues = [undefined, null, NA, ''];
  const toDisplay = toDisplayfp(stopValues, NA);
  const addPlacementToState = (creative) => () => {
    let newSelected;
    if (isMultipleAdPlacement) {
      const isPresent = some(selectedLocalCreatives, { id: creative.id });
      if (isPresent) {
        newSelected = selectedLocalCreatives.filter((crtv) => crtv.id !== creative.id);
      } else {
        newSelected = [...selectedLocalCreatives, creative];
      }
    } else {
      newSelected = [creative];
    }
    flow([() => showCreativePreview(creative), () => addLocalCreativeToState(newSelected)])();
  };
  const tableHeader = [
    {
      columnName: 'Creative',
      className: styles.column0,
    },
    {
      columnName: 'File Name',
      className: styles.column1,
      key: 'name',
    },
    {
      columnName: 'Type',
      className: styles.column2,
      key: 'file_type',
    },
    {
      columnName: 'Label(s)',
      className: styles.column3,
      key: 'labels',
    },
    {
      columnName: 'Dimensions',
      className: styles.column4,
      key: 'dimensions',
    },
    {
      columnName: 'Upload Date',
      className: styles.column5,
      key: 'created',
    },
  ];
  if (isMultipleAdPlacement) {
    tableHeader.unshift({
      columnName: 'Select',
      className: `${styles.column} ${styles.column00}`,
    });
  }
  const tableBody = map(dataset, (creative) => {
    const { id, name, file_type, labels, dimensions, created } = creative;

    const creativeNameDisplay = toDisplay()(name);
    const fileTypeDisplay = toDisplay()(parseFileTypeName(file_type));
    const labelDisplay = toDisplay((value) => value.join(', '))(labels);
    const dimensionsDisplay = toDisplay((value) => `${value}px`)(dimensions);
    const createdDisplay = toDisplay(formatDate(DATE_FORMAT))(created);
    const isSelected = some(selectedCreativeList, { id });
    const isLocallySelected = some(selectedLocalCreatives, { id });
    return (
      <Row
        className={`${styles.tableBodyRow} ${
          id === selectedCreativeId ? styles.selectedCreative : ''
        }`}
        alignItems={Row.ALIGN_ITEMS.CENTER}
        onClick={!isSelected ? addPlacementToState(creative) : () => showCreativePreview(creative)}
        key={`${name}_${id}`}
      >
        {isMultipleAdPlacement && (
          <Col flexGrow={0} className={styles.column00}>
            <Checkbox
              onChange={
                !isSelected ? addPlacementToState(creative) : () => showCreativePreview(creative)
              }
              checked={isSelected || isLocallySelected}
              disabled={isSelected}
            />
          </Col>
        )}
        <Col flexGrow={0} className={styles.column0}>
          <Media containerClass={styles.mediaContainer} {...creative} />
        </Col>
        <Col flexGrow={0} className={styles.column1}>
          {truncateString(creativeNameDisplay, 32)}
        </Col>
        <Col flexGrow={0} className={styles.column2}>
          {fileTypeDisplay}
        </Col>
        <Col flexGrow={0} className={styles.column3}>
          {truncateString(labelDisplay, 20)}
        </Col>
        <Col flexGrow={0} className={styles.column4}>
          {dimensionsDisplay}
        </Col>
        <Col flexGrow={0} className={styles.column5}>
          {createdDisplay}
        </Col>
      </Row>
    );
  });

  return (
    <Table
      tableHeader={tableHeader}
      isLoading={isLoading}
      tableBody={tableBody}
      orderList={orderCreativeList}
      ordering={ordering}
      orderBy={orderBy}
    />
  );
}

CreativesListComponent.propTypes = {
  dataset: PropTypes.array,
  showCreativePreview: PropTypes.func,
  selectedCreativePreview: PropTypes.object,
  isLoading: PropTypes.bool,
  orderCreativeList: PropTypes.func,
  ordering: PropTypes.string,
  orderBy: PropTypes.string,
  selectedCreativeList: PropTypes.array,
  isMultipleAdPlacement: PropTypes.bool,
  selectedLocalCreatives: PropTypes.array,
  addLocalCreativeToState: PropTypes.func,
};

export const CreativesList = NewPaginationComponent({
  pageSize: CREATIVE_LIMIT,
})(CreativesListComponent);
