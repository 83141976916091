import React from 'react';
import PropTypes from 'prop-types';
import styles from './ListItem.module.scss';
import { Row, Col, Checkbox, Tooltip, Icon } from 'v2-components';

const ListItem = (props) => {
  const {
    label,
    onCheck,
    isSelected,
    ActionComponent,
    index,
    disabled,
    siDisabled,
  } = props;

  // Index is undefined means Its a Header row.

  const toolTipText = disabled ? 'This technology is no longer tracked by 6sense and' +
    ' will no longer be visible in Sales Intelligence.' : null;

  let titleClassName = `${styles.titleStyle}`;
  if (!index) {
    titleClassName += ` ${styles.headerLabel}`;
  } else if (disabled) {
    titleClassName += ` ${styles.disabled}`;
  }

  let actionStyleClassName = `${styles.actionStyle}`;
  let listItemStyleClassName = `${styles.listStyle}`;
  if (index) {
    actionStyleClassName += ` ${styles.technologyActionStyle}`;
    listItemStyleClassName += ` ${styles.listItemStyle}`;
  }

  return (
    <Row className={listItemStyleClassName}>
      <Col flexBasis={'2%'}>
        {index && !disabled ?
          <div className={styles.sortableIcon}>
            <Icon
              type="swap_vert"
              color={Icon.COLORS.AA_GREY}
            />
          </div> : null
        }
      </Col>
      <Col flexBasis={'3%'}>
        <Checkbox
          onChange={onCheck}
          checked={isSelected}
          disabled={siDisabled}
        />
      </Col>
      <Col flexBasis={'65%'} className={titleClassName}>
        <Tooltip title={toolTipText}>{index ? `${index}.` : null} {label}</Tooltip>
      </Col>
      <Col flexBasis={'30%'} className={actionStyleClassName}>
        {ActionComponent}
      </Col>
    </Row>

  );
};

ListItem.propTypes = {
  label: PropTypes.any,
  onCheck: PropTypes.func,
  isSelected: PropTypes.bool,
  ActionComponent: PropTypes.node,
  index: PropTypes.any,
  disabled: PropTypes.bool,
  siDisabled: PropTypes.bool,
};

export default ListItem;
