import React from 'react';
/* eslint max-len: [2, 4000] */
// Using 1000 for now.... might need to bump it up
export function Syncing(props) {
  return (
    <svg width="10px" {...props} color="white" height="12px" viewBox="0 0 10 12" version="1.1" xmlnsXlink="http://www.w3.org/2000/svg">
      <title>Syncing</title>
      <desc>Syncing Icon</desc>
      <defs>
        <path d="M1.42444444,4.66666667 L2.31666667,5.4320664 C2.04166667,5.86719091 1.88888889,6.37046745 1.88888889,6.89995631 C1.88888889,8.63521188 3.53277778,10.0454347 5.55555556,10.0454347 L5.55555556,10.0454347 L5.55555556,8.4726955 L8,10.5696811 L5.55555556,12.6666667 L5.55555556,11.0939275 C2.85444444,11.0939275 0.666666667,9.21712538 0.666666667,6.89995631 C0.666666667,6.07688947 0.947777778,5.31148973 1.42444444,4.66666667 L1.42444444,4.66666667 Z M5.11111111,0.666666667 L5.11111111,2.23940585 C7.81222222,2.23940585 10,4.11620795 10,6.43337702 C10,7.25644386 9.71888889,8.0218436 9.24222222,8.66666667 L9.24222222,8.66666667 L8.35,7.90126693 C8.625,7.46614242 8.77777778,6.96286588 8.77777778,6.43337702 C8.77777778,4.69812145 7.13388889,3.28789865 5.11111111,3.28789865 L5.11111111,3.28789865 L5.11111111,4.86063783 L2.66666667,2.76365225 L5.11111111,0.666666667 Z" id="path-1" />
      </defs>
      <g id="Linkedin-Integration" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="A5" transform="translate(-611.000000, -486.000000)">
          <g id="campaign.type-copy-5" transform="translate(602.000000, 480.000000)">
            <g id="ic_check_circle" transform="translate(8.000000, 5.000000)">
              <g id="🎨-Color" transform="translate(0.333333, 0.333333)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <use id="Mask" fill="#505C62" fillRule="nonzero" xlinkHref="#path-1" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>);
}
