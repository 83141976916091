import { Permission } from '@sixsense/rbac';
import {
  VIEW_CAMPAIGN_DETAILS_ROUTE,
} from 'routes/Advertising/routes/Campaigns/routes/CreateCampaign/constants';

export const DELETE_WARNING_MESSAGE = {
  title: 'Are you sure you want to delete this segment?',
  content: 'Deleting a segment is an irreversible action.',
};

// to prevent circular dependencies
export const LOAD = 'SEGMENTS_MANAGE/LOAD';

export const CREATE = 'create';
export const EDIT = 'edit';

export const LOOKALIKE_MIN = 100;

export const CREATE_KEY = 'createSegment';
export const EDIT_KEY = 'editSegment';

const SEGMENT_ACCOUNT_STATS_ERROR = `There was a problem analyzing the file.
  Please try again.`;

const SEGMENT_FILE_NAME_OVER_LIMIT = `Error: the file name must be less than 128 characters.
  Please change the file name and re-submit.`;

const SEGMENT_CSV_NEED_RECORDS = 'csv has no records.';
export const strings = {
  SEGMENT_ACCOUNT_STATS_ERROR,
  SEGMENT_CSV_NEED_RECORDS,
  SEGMENT_FILE_NAME_OVER_LIMIT,
};

export const CREDENTIALS_NOT_SETUP = 'Crm Credentials Not Set up';
export const CREDENTIALS_EXPIRED = 'Crm Credentials Expired';
export const UNKNOWN_ERROR = 'Unknown Error';
export const SEGMENT_POLL_CHANNEL = 'segment_sync';

export const CRM_LISTS_ERROR_TYPE = {
  CREDENTIALS_NOT_SETUP,
  CREDENTIALS_EXPIRED,
  UNKNOWN_ERROR,
};

export const SWITCH_STATES = {
  ON: 'On',
  OFF: 'Off',
};

export const SWITCH_SIZES = {
  SMALL: 'small',
  LARGE: 'default',
};

// Below: Known Errors that for CRM Report syncs that come from the SFDC API
// as well as validation errors from nsync (wrong report type, over record limit etc.)
export const REPORT_ERRORS = [
  {
    error: 'Max API Limit reached',
    message: '* Daily CRM API Limits have been reached, please try again later. '
     + 'Contact your administrator about increasing these limits.',
  },
  {
    error: 'Could not login at this time',
    message: '* Unable to log into SFDC instance, please contact '
     + 'your administrator about resetting your SFDC credentials.',
  },
  {
    error: 'sObject type \'Report\' is not supported.',
    message: '* Unable to sync Report to 6sense due to insufficient permissions. Please refer to '
    + 'the Segment Creation FAQs to review the Salesforce permissions required to successfully '
    + 'sync Reports.',
  },
  {
    error: 'You don’t have sufficient privileges to perform this operation.',
    message: '* Unable to sync Report to 6sense due to insufficient permissions. Please refer to '
    + 'the Segment Creation FAQs to review the Salesforce permissions required to successfully '
    + 'sync Reports.',
  },
  {
    error: 'INVALID_TYPE',
    message: '* Unable to sync Report to 6sense due to insufficient permissions. Please refer to '
    + 'the Segment Creation FAQs to review the Salesforce permissions required to successfully '
    + 'sync Reports.',
  },
  {
    error: 'The report definition is obsolete.',
    message: '* Unable to sync Report to 6sense due to insufficient permissions. Please confirm '
    + 'with your SFDC Admin that Report objects are enabled for integration with '
    + '6sense.',
  },
  // Below: errors due to Salesforce API limitations (wrong report type, over record limit)
  // These errors that are returned do not come directly from the SFDC API, but from nsync when
  // validating the report.
  {
    error: 'Custom Report Type',
    message: '* The selected Report could not be synced because it is a Custom Report. '
    + 'Please refer to the Segment Creation FAQs for supported CRM Report types.',
  },
  {
    error: 'Unsupported Report Type',
    message: '* The selected Report could not be synced because it does not match one of the '
    + 'supported report types. Only Reports based on Accounts, Leads, or Contacts may be synced.',
  },
  {
    error: 'Over record limit',
    message: '* The selected Report could not be synced because it contains more than 2,000 '
    + 'records.',
  },
];

export const ACCOUNT_ERRORS = [
  {
    error: 'Over record limit',
    message: '* The selected Account List could not be synced because it contains more than 25,000 '
    + 'records.',
  },
];

export const ItemTypes = {
  SEGMENT: 'segment',
};

export const PUBLISHED_OPTIONS = {
  1: 'Segment Name Enrichment',
  2: 'Account Details',
  3: 'Sales Intelligence',
  4: 'GTM Segment Nomination',
  5: '6sense APIs',
};

export const MAX_TAG_LIMIT = 20;

export const SEGMENT_DEPS_USED_IN = [
  'campaigns',
  'alerts',
  'segments',
  'orchestrations',
  'workflows',
];

export const INTERNAL_SEGMENT_DEPS_USED_IN = ['campaigns', 'orchestrations', 'alerts'];

export const SEGMENT_DEPS_SYNCED = ['synced_to_linkedin', 'synced_to_fb', 'synced_to_google'];

export const SEGMENT_DEPS_PUBLISHED = ['published_to'];

const dependencyTemplateFactory = (
  title,
  description,
  formatter,
  contentType,
  singleDependencyTitle,
  permissions,
) => ({
  title,
  description,
  formatter,
  contentType,
  singleDependencyTitle,
  permissions,
});

export const FORMATTED_DEPENDENCY_TEMPATES = {
  campaigns: dependencyTemplateFactory(
    'Campaigns',
    'Please delete these campaigns first if you wish to delete this segment.',
    (campaignId) => `/campaigns/${VIEW_CAMPAIGN_DETAILS_ROUTE}/${campaignId}`,
    Array,
    'This segment is currently being used in the following campaigns:',
    [Permission.CAMPAIGN_EDIT]
  ),
  alerts: dependencyTemplateFactory(
    'Alerts',
    'Please delete these alerts first if you wish to delete this segment.',
    (alertId) => `/alerts/${alertId}/?mode=edit`,
    Array,
    'This segment is currently being used in the following alerts:',
    [Permission.ALERT_EDIT]
  ),
  orchestrations: dependencyTemplateFactory(
    'Orchestrations',
    'Please delete these orchestrations first if you wish to delete this segment.',
    (orchestrationId) => `/orchestrations/manage/${orchestrationId}/?action=summary`,
    Array,
    'This segment is currently being used in the following orchestrations:',
    [Permission.ORCHESTRATION_EDIT],
  ),
  workflows: dependencyTemplateFactory(
    'Workflows',
    'Please delete or archive these workflows first if you wish to delete this segment.',
    (orchestrationId, playType) => `${window.process.env.WORKFLOWS_UI_ENDPOINT
      }canvas/${playType}/${orchestrationId}/edit`,
    Array,
    'This segment is currently being used in the following workflows:',
    [Permission.ORCHESTRATION_EDIT],
  ),
  synced_to_google: dependencyTemplateFactory(
    'Google Ads',
    `Please remove this sync first and delete any 
    related campaigns if you wish to delete this segment.`,
    null,
    Boolean,
    `This segment is currently synced to Google Ads. 
    Please remove this sync first and delete any 
    related campaigns if you wish to delete this segment.`,
  ),
  synced_to_linkedin: dependencyTemplateFactory(
    'LinkedIn Sync',
    `Please remove this sync first and delete any 
    related campaigns if you wish to delete this segment.`,
    null,
    Boolean,
    `This segment is currently synced to LinkedIn. 
    Please remove this sync first and delete any 
    related campaigns if you wish to delete this segment.`,
  ),
  synced_to_fb: dependencyTemplateFactory(
    'Facebook Sync',
    `Please remove this sync first and delete any 
    related campaigns if you wish to delete this segment.`,
    null,
    Boolean,
    `This segment is currently synced to Facebook. Please remove 
    this sync first and delete any related campaigns 
    if you wish to delete this segment.`
  ),
  published_to: dependencyTemplateFactory(
    'Published for the following 6sense workflows and experiences',
    'Please unpublish this segment first if you wish to delete it.',
    () => '/settings/publishedsegments',
    Array,
    'This segment is currently being published in the following methods:',
    [Permission.SETTINGS_PUBLISH_SEGMENT_EDIT]
  ),
  segments: dependencyTemplateFactory(
    'Segments',
    `Please delete these segments first, or remove this 
    segment as an included filter, if you wish to delete it.`,
    (segmentId) => `/segments/segment/${segmentId}/analytics/`,
    Array,
    'This segment is currently being used as a filter in the following segments:',
    [Permission.SEGMENT_EDIT]
  ),
};

export const SEGMENT_FIELDS_TO_FETCH = [
  'id',
  'name',
  'segment_type',
  'segment_data',
  'created_by',
  'org',
  'filterset',
  'tags',
  'seg_tags',
  'ad_network_segment_sync_status',
  'accounts_file',
  'edited_by',
  'external_list',
  'is_deleted',
  'is_dynamic',
  'is_editable',
  'is_published',
  'is_sync_dependent',
  'is_syncable',
  'is_updateable',
  'is_valid',
  'is_updated',
  'folder',
  'updated',
  'created',
  'named_account_count',
  'is_crm_filter_in_use',
  'is_map_filter_in_use',
];

export const SEGMENT_VIEW_PERMISSION = [
  Permission.SEGMENT_VIEW,
];
