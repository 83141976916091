import {
  CLASSIFICATIONS,
  SET_CAMPAIGN_CLASSIFICATION_DRAWER_VISIBILITY,
} from "./types";
import { CAMPAIGN_CLASSIFICATION_ACTIONS } from "./constants";
import { hotSwapActionsFactory } from "../advertisingClassificationCommon/actionTypes";

const hotSwapActions = hotSwapActionsFactory(CLASSIFICATIONS);

const addClassificationHotSwap = (payload) => ({
  type: hotSwapActions.FOLDER_LIST_HOT_SWAP_ADD,
  payload,
});

const replaceClassificationHotSwap = (payload) => ({
  type: hotSwapActions.FOLDER_LIST_HOT_SWAP_REPLACE,
  payload,
});

const deleteClassificationHotSwap = (payload) => ({
  type: hotSwapActions.FOLDER_LIST_HOT_SWAP_DELETED,
  payload,
});

export const setClassificationDrawerVisibility = (
  visible,
  campaignIds,
  uiAction = visible ? CAMPAIGN_CLASSIFICATION_ACTIONS.move : undefined
) => ({
  type: SET_CAMPAIGN_CLASSIFICATION_DRAWER_VISIBILITY,
  visible,
  campaignIds,
  uiAction,
});

export const actionCreators = {
  addClassificationHotSwap,
  replaceClassificationHotSwap,
  deleteClassificationHotSwap,
  setClassificationDrawerVisibility,
};
