import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'v2-components';
import { CenterInParent } from 'HOCS';
import styles from './StateComponent.module.scss';

const Disabled = ({ message }) => (
  <Text color={Text.COLOR.WHITE} className={styles.noDataTxt}>
    {message}
  </Text>
);

Disabled.propTypes = { message: PropTypes.string };
Disabled.defaultProps = { message: 'Unavailable' };

export default CenterInParent({ fillParent: true })(Disabled);
