import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid/lib';
import styles from './Tabs.module.scss';
import { COLORS } from 'v2-styles/constants';
import { Text, Tooltip, Icon } from 'v2-components';

export const Tabs = (props) => {
  const {
    items,
    selected,
    action,
    align,
    tabClassName,
    containerClassName,
    labelClassName,
    activeLabelClassName,
    type,
  } = props;

  const tabType = {
    bubbleSelectedText: COLORS.WHITE,
    bubbleDisabled: COLORS.GREY1,
    bubbleErrorText: COLORS.ERROR,

    underlineDisabledText: COLORS.GREY1,
    underlineSelectedText: COLORS.BLUE,
    underlineErrorText: COLORS.ERROR,

    boxDisabledText: COLORS.GREY1,
    boxSelectedText: COLORS.BLUE,
    boxErrorText: COLORS.ERROR,
  };

  const buttons = items.map((item) => {
    const { disabled, tooltip, hasError, actionItem } = item;

    const tab = typeof item === 'string' ? item : item.value;
    let classText = `${styles[`${type}tab`]} ${disabled ? styles[`${type}disabled`] : ''}`;
    let labelClass;
    let textColor;
    if (selected === tab) {
      classText = `
        ${classText}
        ${styles[`${type}Selected`]}
      `;
      labelClass = `
        ${labelClassName}
        ${activeLabelClassName}
      `;

      textColor = tabType[`${type}SelectedText`];
    }

    if (disabled) {
      textColor = tabType[`${type}DisabledText`];
    }

    const handleTabChange = disabled ? () => {} : action;
    classText = tabClassName ? `${classText} ${tabClassName}` : classText;

    return (
      <Tooltip key={tab} title={tooltip}>
        <Col className={classText} onClick={() => handleTabChange(tab)}>
          <Text color={textColor} className={`${styles.noWrap} ${labelClass}`}>
            {item.label || item}
            {actionItem && actionItem}
            {hasError && <Icon
              type="error"
              color={Icon.COLORS.ERROR}
              className={styles.errorIcon}
            />}
          </Text>
        </Col>
      </Tooltip>
    );
  });

  let containerStyle = `${styles.container} ${styles[`${type}Container`]}`;
  if (containerClassName) {
    containerStyle += ` ${containerClassName}`;
  }

  const alignment = { [align]: 'xs' };

  return (
    <Row {...alignment} className={containerStyle}>
      {buttons}
    </Row>
  );
};

Tabs.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  selected: PropTypes.string,
  action: PropTypes.func,
  align: PropTypes.oneOf(['start', 'end', 'between']),
  containerClassName: PropTypes.string,
  tabClassName: PropTypes.string,
  activeLabelClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.oneOf(['bubble', 'underline', 'box']),
};

Tabs.BUBBLE = 'bubble';
Tabs.UNDERLINE = 'underline';
Tabs.BOX = 'box';

Tabs.defaultProps = {
  align: 'between',
  type: 'bubble',
};

export default Tabs;
