import {
  PTR_DIMENSIONS,
  PTR_GEOGRAPHY_DIMENSION_GROUPS,
} from "routes/AdvertisingDS/constants";

export const PAGE_STATE_KEY = "advertisingReports";

export const DAY = "day";
export const WEEK = "week";
export const MONTH = "month";
export const LAST_MONTH = "last_month";
export const MONTH_TO_DATE = "month_to_date";
export const LAST_QUARTER = "last_quarter";
export const QUARTER_TO_DATE = "quarter_to_date";
export const DATE_RANGE = "date_range";
const QUARTER = "quarter";
const YEAR = "year";

export const STATS_INTERVALS = [
  { label: "By Day", value: DAY },
  { label: "By Week", value: WEEK },
  { label: "By Month", value: MONTH },
  { label: "By Quarter", value: QUARTER },
  { label: "By Year", value: YEAR },
];

export const STAT_INTERVAL_LABELS = {
  [DAY]: "Day",
  [WEEK]: "Week",
  [MONTH]: "Month",
  [QUARTER]: "Quarter",
  [YEAR]: "Year",
};

export const INTERVAL_TO_DATE_FORMAT_MAP = {
  chartAxis: {
    [DAY]: { label: "MMM D", subLabel: "ddd" },
    [WEEK]: { label: "MMM D", subLabel: undefined },
    [MONTH]: { label: "MMM", subLabel: "YYYY" },
    [QUARTER]: { label: "MMM", subLabel: "YYYY" },
    [YEAR]: { label: undefined, subLabel: "YYYY" },
  },
  table: {
    [DAY]: "MMM D, YYYY, ddd",
    [WEEK]: "MMM D, YYYY",
    [MONTH]: "MMMM, YYYY",
    [QUARTER]: "MMMM, YYYY",
    [YEAR]: "YYYY",
  },
};

export const DIMENSIONS_OPTIONS = [
  { value: PTR_DIMENSIONS.TIME, label: "Time" },
  { value: PTR_DIMENSIONS.CAMPAIGN, label: "Campaign" },
  { value: PTR_DIMENSIONS.CAMPAIGN_TYPE, label: "Campaign Types" },
  { value: PTR_DIMENSIONS.SEGMENT, label: "Segment" },
  { value: PTR_DIMENSIONS.PURCHASE_ORDER, label: "Purchase Order" },
  { value: PTR_DIMENSIONS.PRODUCT, label: "Product" },
  { value: PTR_DIMENSIONS.ACCOUNT, label: "Account" },
  { value: PTR_DIMENSIONS.GEOGRAPHY, label: "Geography" },
];

const TIME_GROUP_OPTIONS = [
  { label: "Day", value: DAY },
  { label: "Week", value: WEEK },
  { label: "Month", value: MONTH },
  { label: "Quarter", value: QUARTER },
  { label: "Year", value: YEAR },
];

const SEGMENT_GROUPS = {
  SEGMENT: "segment",
  SEGMENT_FOLDER: "segment_folder",
};

const SEGMENT_GROUP_OPTIONS = [
  { value: SEGMENT_GROUPS.SEGMENT, label: "Individual Segment" },
  { value: SEGMENT_GROUPS.SEGMENT_FOLDER, label: "Segment Folder" },
];

export const CAMPAIGN_GROUPS = {
  CAMPAIGN: "campaign",
  CAMPAIGN_FOLDER: "campaign_folder",
};

const CAMPAIGN_GROUP_OPTIONS = [
  { value: CAMPAIGN_GROUPS.CAMPAIGN, label: "Individual Campaign" },
  { value: CAMPAIGN_GROUPS.CAMPAIGN_FOLDER, label: "Campaign Folder" },
];

const GEOGRAPHY_GROUP_OPTIONS = [
  { value: PTR_GEOGRAPHY_DIMENSION_GROUPS.REGION, label: "Region" },
  { value: PTR_GEOGRAPHY_DIMENSION_GROUPS.COUNTRY, label: "Country" },
];

export const DIMENSION_GROUP_OPTIONS = {
  [PTR_DIMENSIONS.TIME]: TIME_GROUP_OPTIONS,
  [PTR_DIMENSIONS.SEGMENT]: SEGMENT_GROUP_OPTIONS,
  [PTR_DIMENSIONS.CAMPAIGN]: CAMPAIGN_GROUP_OPTIONS,
  [PTR_DIMENSIONS.GEOGRAPHY]: GEOGRAPHY_GROUP_OPTIONS,
};

export const PTR_REPORT_EVENT_LOGGING_ID_MAP = {
  [DAY]: 101,
  [WEEK]: 102,
  [MONTH]: 103,
  [QUARTER]: 104,
  [YEAR]: 105,
  [CAMPAIGN_GROUPS.CAMPAIGN]: 106,
  [CAMPAIGN_GROUPS.CAMPAIGN_FOLDER]: 107,
  [PTR_DIMENSIONS.CAMPAIGN_TYPE]: 108,
  [SEGMENT_GROUPS.SEGMENT]: 109,
  [SEGMENT_GROUPS.SEGMENT_FOLDER]: 110,
  [PTR_DIMENSIONS.PURCHASE_ORDER]: 111,
  [PTR_DIMENSIONS.PRODUCT]: 112,
  [PTR_DIMENSIONS.ACCOUNT]: 113,
  [PTR_GEOGRAPHY_DIMENSION_GROUPS.REGION]: 114,
  [PTR_GEOGRAPHY_DIMENSION_GROUPS.COUNTRY]: 115,
};
