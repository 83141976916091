import { COLUMNS_STORAGE_KEY, FILTER_BY_STORAGE_KEY } from './constants';

export const campaignListColumnsStorage = {
  get: () => JSON.parse(localStorage.getItem(COLUMNS_STORAGE_KEY)),
  set: (userSelectedColumnsObj) =>
    localStorage.setItem(COLUMNS_STORAGE_KEY, JSON.stringify(userSelectedColumnsObj)
  ),
};

export const filterByStorage = {
  applyFiltersGloballyStorageKey: `${FILTER_BY_STORAGE_KEY}GlobalFilters`,
  getApplyFiltersGlobally() {
    const savedConfig = JSON.parse(localStorage.getItem(this.applyFiltersGloballyStorageKey));

    return Boolean(savedConfig);
  },
  setApplyFiltersGlobally(isChecked) {
    localStorage.setItem(
      this.applyFiltersGloballyStorageKey,
      JSON.stringify(isChecked)
    );
  },
};
