import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tab.module.scss';
import { COLORS } from 'v2-styles/constants';
import { CountDisplay, Text, Row, Tooltip } from 'v2-components';

export const Tab = (props) => {
  const {
    type,
    onClick,
    num,
    label,
    selected,
    hideCount,
    disabled,
    disabledMessage,
    labelClassName,
  } = props;

  let displayLabel;
  if (type === 'graph') {
    displayLabel = (
      <Row justifyContent={Row.JUSTIFY_CONTENT.CENTER}>
        <div className={`${styles.label} ${labelClassName}`}>{label}</div>
        {num ? (
          <CountDisplay className={styles.filterCounter}>
            {num}
          </CountDisplay>
        ) : null}
      </Row>
    );
  } else if (hideCount) {
    displayLabel = (
      <div className={`${styles.label} ${labelClassName}`}>{label}</div>
    );
  } else {
    displayLabel = (
      <div className={`${styles.label} ${labelClassName}`}>
        {label} ({num})
      </div>
    );
  }

  if (disabled) {
    displayLabel = (
      <Tooltip title={disabledMessage}>
        {displayLabel}
      </Tooltip>
    );
  }

  let textColor = COLORS.GREY;

  if (disabled) {
    textColor = COLORS.GREY3;
  }

  if (selected) {
    textColor = COLORS.BLUE;
  }

  return (
    <Row
      alignItems={Row.ALIGN_ITEMS.CENTER}
      className={`
        ${styles.tab}
        ${selected && styles.selected}
        ${disabled && styles.disabled}
      `}
      onClick={() => !disabled && onClick && onClick()}
    >
      <Text
        color={textColor}
        weight={selected ? 'bold' : 'normal'}
      >
        {displayLabel}
      </Text>
    </Row>
  );
};

Tab.propTypes = {
  type: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  num: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  hideCount: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  labelClassName: PropTypes.string,
};
