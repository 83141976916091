import React from "react";
import moment from "moment";
import { get, isEmpty } from "lodash";
import mapfp from "lodash/fp/map";
import {
  DISPLAY_LABELS,
  CURRENCY_STATS,
  ANALYTICS_TYPES_DOWNLOAD_HEADER,
  LINKEDIN_JOB_PROFILE_DOWNLOAD_HEADERS,
  LINKEDIN_JOB_PROFILE_DOWNLOAD_REPORT_LABEL,
  LINKEDIN_JOB_PROFILE_AVG_CTR_KEY,
} from "./constants";
import { CAMPAIGN_SOURCE } from "../../constants";
import { coerceLocaleString, downloadCSV } from "utils/utils";
import {
  ANALYTIC_TYPES,
  ANALYTIC_TYPES_DISPLAY_LABELS,
} from "routes/AdvertisingDS/routes/Campaigns/routes/Analytics/constants";
import { CAMPAIGN_DOWNLOAD_META } from "routes/AdvertisingDS/routes/Campaigns/constants";
import ReactDOMServer from "react-dom/server";
import { css } from "styles/emotion";

const formatStatValue = (statValue, statName, campaignStats) => {
  if (statName === "click_count")
    return coerceLocaleString(Math.round(statValue));
  return campaignStats[statName];
};

function yAxisLabelFormatterFunction() {
  const hasCurrency = CURRENCY_STATS.includes(this.axis.userOptions.title.text);
  return hasCurrency ? `$ ${this.value}` : `${this.value}`;
}

export const getCampaignTrendChartOptions = (
  trendStatLeft,
  trendStatRight,
  campaignStats
) => {
  const {
    meta: { leftStatMeta, rightStatMeta },
  } = campaignStats;
  return {
    type: "line",
    showMarker: false,
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    legendFormatter: (obj) => {
      let returnHTML;

      if (obj.name === DISPLAY_LABELS[trendStatLeft]) {
        returnHTML = ReactDOMServer.renderToString(
          <div
            className={css`
              font-weight: 600;
            `}
          >
            <div>{obj.name}</div>
            <div>
              {formatStatValue(
                leftStatMeta.total.toFixed(2),
                trendStatLeft,
                campaignStats
              )}
            </div>
          </div>
        );
      } else {
        returnHTML = ReactDOMServer.renderToString(
          <div
            className={css`
              font-weight: 600;
            `}
          >
            <div>{obj.name}</div>
            <div>
              {formatStatValue(
                rightStatMeta.total.toFixed(2),
                trendStatRight,
                campaignStats
              )}
            </div>
          </div>
        );
      }
      return returnHTML;
    },
    xAxisTitle: `Days`,
    yAxisTitle: DISPLAY_LABELS[trendStatLeft],
    xAxisType: "datetime",
    additionalYAxes: [
      {
        title: {
          text: DISPLAY_LABELS[trendStatRight],
        },
        labels: {
          formatter() {
            const hasCurrency = CURRENCY_STATS.includes(
              this.axis.userOptions.title.text
            );
            return hasCurrency ? `$ ${this.value}` : this.value;
          },
        },
        allowDecimals: false,
        opposite: true,
      },
    ],
    yAxis: {
      title: {
        text: DISPLAY_LABELS[trendStatLeft],
      },
      allowDecimals: false,
    },
    yAxisLineWidth: 0,
    xAxisLineWidth: 1,
    yAxisLabelFormatter: yAxisLabelFormatterFunction,
  };
};

export const getAnalyticsOptions = (campaignSource) => {
  const isEMC = campaignSource === CAMPAIGN_SOURCE.EXTERNAL;
  const options = [
    { value: "account_count", label: "Accounts Reached" },
    { value: "click_count", label: isEMC ? "Matched Clicks" : "Clicks" },
    { value: "cpc", label: "eCPC" },
    { value: "cpm", label: "eCPM" },
    {
      value: "impression_count",
      label: isEMC ? "Matched Impressions" : "Impressions",
    },
    { value: "spend", label: "Spend" },
  ];

  if (campaignSource === CAMPAIGN_SOURCE.RETARGETING) {
    options.unshift({ value: "audience_count", label: "Audience Reached" });
  }
  return options;
};

export const getUpdatedAnalyticsOptions = (
  selectedLeft,
  selectedRight,
  campaignSource
) => {
  const ANALYTICS_OPTIONS = getAnalyticsOptions(campaignSource);
  const updatedLeft = ANALYTICS_OPTIONS.filter(
    (option) => !(option.value === selectedRight)
  );
  const updatedRight = ANALYTICS_OPTIONS.filter(
    (option) => !(option.value === selectedLeft)
  );
  return [updatedLeft, updatedRight];
};

export const getFormattedDate = (selectedDateRange) => {
  const startDate = selectedDateRange[0].format("YYYY-MM-DD");
  const endDate = selectedDateRange[1].format("YYYY-MM-DD");
  return [startDate, endDate];
};

export const getAvailableDataEndDate = (campaignEndDate, lastTranslated) => {
  const isRunningCampaign = moment(campaignEndDate).isAfter(lastTranslated);
  if (isRunningCampaign) return lastTranslated;
  return moment(campaignEndDate).add(2, "day");
};

const getAnalyticTypeDownloadHeaders = (analyticType, meta) => {
  const campaignSource = get(meta, "campaignSource");
  const profileType = get(meta, "profileType");
  if (
    analyticType === ANALYTIC_TYPES.JLJF &&
    campaignSource === CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING
  ) {
    return LINKEDIN_JOB_PROFILE_DOWNLOAD_HEADERS[profileType];
  }
  return ANALYTICS_TYPES_DOWNLOAD_HEADER[analyticType];
};

const getAnalyticTypeReportNameAndTitle = (analyticType, meta) => {
  const campaignSource = get(meta, "campaignSource");
  const profileType = get(meta, "profileType");
  if (
    analyticType === ANALYTIC_TYPES.JLJF &&
    campaignSource === CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING
  ) {
    const reportTitle = LINKEDIN_JOB_PROFILE_DOWNLOAD_REPORT_LABEL[profileType];
    const reportName = reportTitle.replace(/ /g, "_").toLowerCase();
    return {
      title: reportTitle,
      name: reportName,
    };
  }
  return {
    title: ANALYTIC_TYPES_DISPLAY_LABELS[analyticType],
    name: analyticType,
  };
};

const getFieldsByAnalyticType = (type, data, meta) => {
  switch (type) {
    case ANALYTIC_TYPES.TREND: {
      const date = data[0];
      const { account_count, impression_count, click_count, spend, cpm, cpc } =
        data[1];
      return [
        date,
        account_count,
        impression_count,
        click_count,
        `$${coerceLocaleString(spend.toFixed(2))}`,
        `$${coerceLocaleString(cpm.toFixed(2))}`,
        `$${coerceLocaleString(cpc.toFixed(2))}`,
      ];
    }
    case ANALYTIC_TYPES.DOMAIN: {
      const domain = data[0];
      const { Clicks, Impressions } = data[1];
      return [domain, data[1]["Accounts Reached"], Impressions, Clicks];
    }
    case ANALYTIC_TYPES.JLJF: {
      const jljf = data[0];
      const { Clicks, Impressions } = data[1];
      const { campaignSource } = meta;
      if (campaignSource === CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING) {
        return [
          jljf,
          Impressions,
          Clicks,
          `${data[1][LINKEDIN_JOB_PROFILE_AVG_CTR_KEY]}%`,
        ];
      }
      return [jljf, data[1]["Accounts Reached"], Impressions, Clicks];
    }
    default:
      return null;
  }
};

export const formatDateRange = (selectedDateRange) => {
  const [startDate, endDate] = getFormattedDate(selectedDateRange);
  return [CAMPAIGN_DOWNLOAD_META[0].label, `${startDate} to ${endDate}`];
};

export const getMetaDataForCSV = (analyticType, selectedDateRange, meta) => [
  formatDateRange(selectedDateRange),
  [
    CAMPAIGN_DOWNLOAD_META[1].label,
    getAnalyticTypeReportNameAndTitle(analyticType, meta).title,
  ],
  [CAMPAIGN_DOWNLOAD_META[3].label, moment().toString()],
  [], // Show two empty rows after period row
  [],
];

export const downloadCampaignAnalyticsByType = (
  dataset,
  analyticType,
  selectedDateRange,
  meta
) => {
  const headers = getAnalyticTypeDownloadHeaders(analyticType, meta);
  const formatAccount = (accountData) =>
    getFieldsByAnalyticType(analyticType, accountData, meta);
  const downloadFn = downloadCSV();
  const data = mapfp(formatAccount)(dataset);
  return downloadFn(
    [
      ...getMetaDataForCSV(analyticType, selectedDateRange, meta),
      headers,
      ...data,
    ],
    `${getAnalyticTypeReportNameAndTitle(analyticType, meta).name}_report`
  );
};

export const isBudgetExhausted = (testCampaign) => {
  const budgetSpent = get(testCampaign, "campaign_data.budget_spent");
  const budget = get(testCampaign, "budget");

  return Number(budgetSpent) > Number(budget);
};

export const isLinkedInCampaign = (campaignSource) =>
  [CAMPAIGN_SOURCE.LINKEDIN, CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING].includes(
    campaignSource
  );

export const formatPercentage = (value) => `${value.toFixed(2)}%`;

export const shouldShowDollar = (country, flag = false) =>
  flag || country === "United States";

export const hasNoData = (data) => isEmpty(data);
