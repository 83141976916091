import { createSelector } from 'reselect';
import {
  originalProductURLsListSelector,
  originalKeywordsSelector,
} from '../../selectors';
import { isEmpty, get, pick, forEach } from 'lodash';
import moment from 'moment';
import {
  NOT_CONFIGURED,
  URLS_SUBMITTED,
  KEYWORDS_READY,
  KEYWORDS_SUBMITTED,
  MODELS_DONE,
  MAPPINGS_READY,
} from './constants';
import { validateURL } from './utils';

const baseSelector = (state) => state.taxonomyV2.producturlconfig;

export const productURLsSelector = createSelector(
  baseSelector,
  ({ productURLs }) => productURLs
);

export const productKeywordsSelector = createSelector(
  baseSelector,
  ({ productKeywords }) => productKeywords
);

export const currentTaxonomyStateSelector = createSelector(
  baseSelector,
  ({ currentTaxonomyState }) => currentTaxonomyState
);

export const productKeywordsDiffSelector = createSelector(
  originalKeywordsSelector,
  productKeywordsSelector,
  (originalKeywords, currentKeywords) => {
    const retVal = originalKeywords.map((obj, index) => {
      const originalObj = pick(get(obj, 'keywords', {}), [
        'strong',
        'moderate',
        'weak',
      ]);
      const currentObj = pick(get(currentKeywords[index], 'keywords', {}), [
        'strong',
        'moderate',
        'weak',
      ]);
      const diff = Object.keys(originalObj).reduce((acc, key) => {
        const originalKeywordArr = originalObj[key];
        const currentKeywordArr = currentObj[key];
        const result = isEmpty(currentKeywordArr)
          ? {}
          : currentKeywordArr.reduce(
              (acc1, currKeyword, idx) => {
                const orgKeyword = originalKeywordArr[idx] || {};
                if (isEmpty(orgKeyword)) {
                  acc1.newKeywords.push(currKeyword);
                } else if (orgKeyword.isDeleted !== currKeyword.isDeleted) {
                  acc1.deletedKeywords.push(currKeyword);
                }
                return acc1;
              },
              { newKeywords: [], deletedKeywords: [] }
            );
        if (!isEmpty(result.newKeywords) || !isEmpty(result.deletedKeywords)) {
          acc[key] = result;
        }
        return acc;
      }, {});
      return {
        product: obj.product,
        diff,
      };
    });
    return retVal;
  }
);

export const productURLDiffSelector = createSelector(
  originalProductURLsListSelector,
  productURLsSelector,
  (originalProductURLs, currentProductURLs) =>
    currentProductURLs.map((currentObj, idx) => {
      const product = currentObj.product;
      const newURLs = currentObj.urls.filter((urlObj) => urlObj.id === null);
      const originalObj = originalProductURLs[idx];
      // TODO:  we don't have an operational updated flag to check as of now.
      const modifiedURLs = originalObj.urls.reduce((acc, item1) => {
        const diffObj = currentObj.urls.find(
          (item2) => item2.id === item1.id && item2.url !== item1.url
        );
        if (diffObj) {
          acc.push({ newUrl: diffObj.url, oldUrl: item1.url });
        }
        return acc;
      }, []);
      const deletedURLs = originalObj.urls.filter(
        (item1) => !currentObj.urls.some((item2) => item2.id === item1.id)
      );
      return {
        product,
        newURLs,
        modifiedURLs,
        deletedURLs,
      };
    })
);

export const saveURLLoadingSelector = createSelector(
  baseSelector,
  ({ saveURL }) => saveURL.loading
);

export const urlValidationSelector = createSelector(
  baseSelector,
  ({ urlValidation }) => urlValidation
);

export const validationFailedUrlsSelector = createSelector(
  urlValidationSelector,
  (validationObj) =>
    Object.values(validationObj).reduce((acc, urlList) => {
      forEach(urlList, ({ url, isValid, serverError }) => {
        if (url && isValid === false && serverError) {
          acc.push(url);
        }
      });

      return acc;
    }, [])
);

export const isSaveURLBtnDisabledSelector = createSelector(
  baseSelector,
  productURLDiffSelector,
  urlValidationSelector,
  ({ productURLs }, diffs = [], validationObj) => {
    const condition1 = productURLs.some((tag) =>
      tag.urls.some((urlObj) => isEmpty(urlObj.url))
    );
    const condition2 = productURLs.some((tag) => tag.urls.length < 5);
    const condition3 = productURLs.some((tag) =>
      tag.urls.some((urlObj) =>
        validateURL(
          urlObj.url,
          tag.urls.map((_urlObj) => _urlObj.url)
        )
      )
    );
    const condition4 = diffs.every(
      (diffObj) =>
        diffObj.newURLs.length === 0 &&
        diffObj.modifiedURLs.length === 0 &&
        diffObj.deletedURLs.length === 0
    );
    const condition5 = Object.values(validationObj).some((productValidation) =>
      productValidation.some(
        (urlObj) => urlObj.isValid === null || urlObj.isValid === false
      )
    );
    return condition1 || condition2 || condition3 || condition4 || condition5;
  }
);

export const isURLConfigValidSelector = createSelector(
  currentTaxonomyStateSelector,
  (productState) =>
    productState !== NOT_CONFIGURED &&
    [
      URLS_SUBMITTED,
      KEYWORDS_READY,
      KEYWORDS_SUBMITTED,
      MODELS_DONE,
      MAPPINGS_READY,
    ].includes(productState)
);

export const isURLAnalysisStepValidSelector = createSelector(
  currentTaxonomyStateSelector,
  (productState) =>
    productState !== NOT_CONFIGURED &&
    [KEYWORDS_READY, KEYWORDS_SUBMITTED, MODELS_DONE, MAPPINGS_READY].includes(
      productState
    )
);

export const isKeywordsStepValidSelector = createSelector(
  currentTaxonomyStateSelector,
  (productState) =>
    productState !== NOT_CONFIGURED &&
    [KEYWORDS_SUBMITTED, MODELS_DONE, MAPPINGS_READY].includes(productState)
);

export const isURLAnalysisStepEnabledSelector = createSelector(
  currentTaxonomyStateSelector,
  (productState) =>
    productState !== NOT_CONFIGURED && [URLS_SUBMITTED].includes(productState)
);

export const isKeywordsStepEnabledSelector = createSelector(
  currentTaxonomyStateSelector,
  (productState) =>
    productState !== NOT_CONFIGURED &&
    [KEYWORDS_READY, KEYWORDS_SUBMITTED, MODELS_DONE, MAPPINGS_READY].includes(
      productState
    )
);

export const currentStepSelector = createSelector(
  baseSelector,
  ({ currentStep }) => currentStep
);

export const saveKeywordLoadingSelector = createSelector(
  baseSelector,
  ({ saveKeywords }) => saveKeywords.loading
);

export const keywordCountsSelector = createSelector(
  productKeywordsSelector,
  (productKeywords) =>
    productKeywords.reduce((acc, mapping) => {
      const product = get(mapping, 'product');
      const strongKeywords = get(mapping, 'keywords.strong');
      const moderateKeywords = get(mapping, 'keywords.moderate');
      const weakKeywords = get(mapping, 'keywords.weak');
      const keywordCount =
        strongKeywords.filter((v) => !v.isDeleted).length +
        moderateKeywords.filter((v) => !v.isDeleted).length +
        weakKeywords.filter((v) => !v.isDeleted).length;
      acc[product] = keywordCount;
      return acc;
    }, {})
);

export const isSaveKeywordBtnDisabledSelector = createSelector(
  keywordCountsSelector,
  (keywordCounts) => Object.values(keywordCounts).some((count) => count < 10)
);

export const productURLsAndKeywordsSelector = createSelector(
  productURLsSelector,
  productKeywordsSelector,
  (productURLs, productKeywords) =>
    productURLs.map((productURLObj, index) => {
      const productKeywordObj = productKeywords[index];
      return {
        product: productURLObj.product,
        displayName: productURLObj.displayName,
        urls: productURLObj.urls,
        keywords: productKeywordObj ? productKeywordObj.keywords : {},
      };
    })
);

export const lastUpdatedProgressSelector = createSelector(
  originalProductURLsListSelector,
  (productURLList) => {
    const latestUpdatedDate = productURLList.reduce(
      (latestDate, productURL) => {
        const latestProductURLDate = productURL.urls.reduce(
          (latestDateInProduct, url) => {
            if (
              !latestDateInProduct ||
              moment(url.updated_date).diff(moment(latestDateInProduct)) > 0
            ) {
              return url.updated_date;
            }
            return latestDateInProduct;
          },
          null
        );
        if (
          !latestDate ||
          moment(latestProductURLDate).diff(moment(latestDate)) > 0
        ) {
          return latestProductURLDate;
        }
        return latestDate;
      },
      null
    );
    if (!latestUpdatedDate) return 0;
    const duration = moment.duration(moment().diff(moment(latestUpdatedDate)));
    const hours = duration.asHours();
    // 48 hrs
    return Math.floor((hours / 48) * 100);
  }
);
