import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: '/password_update',
    name: 'Update Password',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const sagas = require('./sagas');
          const reducer = require('./modules');
          injectReducer('passwordUpdate', reducer.default);
          injectSagas('passwordUpdate', sagas.default);
          cb(null, containers.PasswordUpdate);
        },
        'auth_password_update'
      );
    },
  };
}
