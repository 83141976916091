import React, { Component } from 'react';
import PropTypes from 'prop-types';
import enUS from 'antd/lib/locale-provider/en_US';
import { Spin, LocaleProvider } from 'antd';
import { Row, Col, Link, Text, Button } from 'v2-components';
import { actions } from 'modules/auth';
import { Footer } from '../Footer';
import GirlWayTooHappyAboutMarketingSmh from 'images/login.png';
import styles from './Auth.module.scss';

// manages standard layout for auth page
export class AppComponent extends Component {
  componentWillMount() {
    this.props.initialize();
  }

  render() {
    const { loading } = this.props;

    let content;
    if (loading) {
      content = (
        <Col className={`${styles.appBody} ${styles.loadingFrame}`}>
          <Col className={styles.loadingWrapper}>
            <Spin size="large" />
          </Col>
        </Col>
      );
    } else {
      content = (
        <LocaleProvider locale={enUS}>
          <Col className={styles.appBody}>
            <Col className={styles.contentChildren}>{this.props.children}</Col>
            <div className={styles.footerDiv}>
              <Footer className={styles.preloginFooter} isPrelogin />
            </div>
          </Col>
        </LocaleProvider>
      );
    }

    return (
      <Row className={styles.authContainer}>
        <div
          className={styles.leftFrame}
        >

          <Row flexDirection={Row.FLEX_DIRECTION.COLUMN} className={styles.textContainer}>
            <Text className={styles.text} color={Text.COLOR.WHITE} type={Text.TYPE.TITLE}>
              Get the latest insights on
            </Text>
            <Text className={styles.text} color={Text.COLOR.WHITE} type={Text.TYPE.TITLE}>
                B2B marketing and sales
            </Text>
            <Row justifyContent={Row.JUSTIFY_CONTENT.CENTER}>
              <Link link="https://6sense.com/blog/" newWindow>
                <Button type={Button.PRIMARY} className={styles.visitBlogButton}>
                  Visit Blog
                </Button>
              </Link>
            </Row>
          </Row>

          <img className={styles.img} src={GirlWayTooHappyAboutMarketingSmh} alt="login art" />

        </div>

        {content}
      </Row>
    );
  }
}

import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

AppComponent.propTypes = {
  children: PropTypes.element,
  initialize: PropTypes.func,
  loading: PropTypes.bool,
};

export const Auth = connect(mapStateToProps, actions)(AppComponent);

// replace return with this stuff after conference is over:
/* ( */
// <Row className={styles.authContainer}>
// <Row
// flexDirection={Row.FLEX_DIRECTION.COLUMN}
// alignItems={Row.ALIGN_ITEMS.CENTER}
// className={styles.leftFrame}
// >

// <Row flexDirection={Row.FLEX_DIRECTION.COLUMN} className={styles.textContainer}>
// <Text className={styles.text} color={Text.COLOR.WHITE} type={Text.TYPE.TITLE}>
// Get the latest insights on
// </Text>
// <Text className={styles.text} color={Text.COLOR.WHITE} type={Text.TYPE.TITLE}>
// B2B marketing and sales
// </Text>
// <Row justifyContent={Row.JUSTIFY_CONTENT.FLEX_END}>
// {[> <Link link="https://6sense.com/blog/" newWindow>
// <Button type={Button.PRIMARY}>
// Visit our blog
// </Button>
// </Link>
// */}
// <Link link="https://breakthrough.6sense.com/" newWindow>
// <Button type={Button.PRIMARY}>
// Find out more
// </Button>
// </Link>
// </Row>
// </Row>

// <img className={styles.img} src={GuyWayTooHappyAboutMarketingSmh} alt="login art" />

// </Row>

// {content}
// </Row>
/* ) */
