import React from 'react';
import Link from 'v2-components/Link';
import Row from 'v2-components/Row';
import { NA } from './common/NA';
import { actions as campaignActions } from './../../../modules';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { localeString } from 'utils/utils';
import { getSelectedDateRangeSelector } from './../selectors';
import { is6SenseCampaign, isDraftOrScheduledCampaign } from '../../../utils';
import { hasUnifiedContactsSelector } from 'modules/user/selectors';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';

const InfluencedFormFillsComponent = ({
  influencedFormFillsCount,
  campaign,
  loadInfluencedConversionsFormsList,
  selectedDateRange,
  hasUnifiedContacts }) => (
    <Row>
      {(influencedFormFillsCount && hasUnifiedContacts) ? <Link
        onClick={() =>
        loadInfluencedConversionsFormsList({
          campaignId: get(campaign, 'id'),
          campaignOrAccountName: get(campaign, 'campaign_name'),
          dateFilter: selectedDateRange,
        })
        }
      >
        {influencedFormFillsCount}
      </Link>: influencedFormFillsCount}
    </Row>
  );

InfluencedFormFillsComponent.propTypes = {
  campaign: PropTypes.object,
  influencedFormFillsCount: PropTypes.number,
  loadInfluencedConversionsFormsList: PropTypes.func,
  selectedDateRange: PropTypes.object,
  hasUnifiedContacts: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  selectedDateRange: getSelectedDateRangeSelector(state),
  hasUnifiedContacts: hasUnifiedContactsSelector(state),
});

const InfluencedFormFills = connect(mapStateToProps, {
  loadInfluencedConversionsFormsList: campaignActions.loadInfluencedConversionsFormsList,
})(InfluencedFormFillsComponent);

export const INFLUENCED_FORM_FILLS = {
  sortByKey: 'influenced_form_fills',
  customizable: true,
  key: 'influenced_form_fills',
  csvColumnKey: 'influenced_form_fills',
  title: <Label name={CAMPAIGN_LABELS.influencedFormFills} />,
  dataIndex: 'campaign_data.influenced_form_fills',
  width: 110,
  render: (influenced_form_fills, campaign) => {
    const { campaign_status: campaignStatus } = campaign;
    if (
      !is6SenseCampaign(campaign) ||
      isDraftOrScheduledCampaign(campaignStatus)
    ) {
      return (<NA />);
    }

    return (<InfluencedFormFills
      influencedFormFillsCount={influenced_form_fills}
      campaign={campaign}
    />);
  },
  csvOverrides: {
    dataIndex: 'download_stats.influenced_form_fills',
    render: (influenced_form_fills, campaign) => {
      const campaignStatus = campaign.campaign_status;
      if (isDraftOrScheduledCampaign(campaignStatus)) {
        return '-';
      }

      return `"${localeString(parseInt(influenced_form_fills) || 0)}"`;
    },
  },
};
