import React from 'react';
import PropTypes from 'prop-types';
import {
  Pill,
  Text,
  Row,
} from 'v2-components';

import styles from './KeyWord.module.scss';

function KeyWord(props) {
  const {
    id,
    before = null,
    after = null,
    children,
    className,
    inactive,
    selected,
    toolTip,
    trigger,
    handleClick,
    notClickable = false,
    theme = KeyWord.Theme.DEFAULT,
    overlayClassName,
  } = props;

  let pillClassName = getPillClassName({ theme, inactive, selected });
  if (className) {
    pillClassName += ` ${className}`;
  }

  function renderKeyword() {
    return (
      <Row alignItems={Row.ALIGN_ITEMS.CENTER}>
        {before}
        <Text type={Text.TYPE.SUBBODY} className={styles.keywordText}>
          {children}
        </Text>
        {after}
      </Row>
    );
  }

  return (
    <Pill
      selected={selected}
      inactive={inactive}
      id={id}
      className={pillClassName}
      onClick={handleClick}
      tooltipTitle={toolTip}
      tooltipTrigger={trigger}
      notClickable={notClickable}
      overlayClassName={overlayClassName}
    >
      {renderKeyword()}
    </Pill>
  );
}

KeyWord.propTypes = {
  after: PropTypes.node,
  before: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  id: PropTypes.string,
  toolTip: PropTypes.string,
  inactive: PropTypes.bool,
  selected: PropTypes.bool,
  trigger: PropTypes.oneOf(['click', 'hover']),
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  handleClick: PropTypes.func,
  notClickable: PropTypes.bool,
  theme: PropTypes.shape({
    base: PropTypes.string.isRequired,
    inactive: PropTypes.string,
    selected: PropTypes.string,
  }),
};

KeyWord.defaultProps = {
  selected: false,
  inactive: false,
  trigger: 'hover',
  overlayClassName: '',
};

KeyWord.Theme = {
  DEFAULT: {
    base: styles.themeDefault,
    inactive: styles.themeDefault_inactive,
    selected: styles.themeDefault_selected,
  },
  BLUE_SQUARED: {
    base: styles.themeBlueSquared,
    inactive: styles.themeBlueSquared_inactive,
  },
  GREEN_INVERTED: {
    base: styles.themeGreenInverted,
  },
};

const getPillClassName = ({ theme, inactive, selected }) => {
  const classNames = [theme.base];
  if (inactive && theme.inactive) {
    classNames.push(theme.inactive);
  }
  if (selected && theme.selected) {
    classNames.push(theme.selected);
  }
  return classNames.join(' ');
};

export default KeyWord;
