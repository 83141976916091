import { Modal } from 'v2-components';

const CopyToAllConfirmPrompt = (fieldLabel, onOk) => {
  Modal.prompt({
    type: Modal.PROMPT_TYPES.CONFIRM,
    width: 500,
    title: 'Use on All Ads',
    content: 'This will overwrite/apply to all uploaded creatives.',
    okText: `Use ${fieldLabel} on All Ads`,
    onOk() {
      onOk();
    },
  });
};

export default CopyToAllConfirmPrompt;
