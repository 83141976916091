export default function createRoutes() {
  return {
    path: 'rule-manager',
    name: 'rule-manager',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const container = require('./containers/RuleManagerContainer');
          cb(null, container.RuleManagerContainer);
        },
        'rule-manager',
      );
    },
  };
}
