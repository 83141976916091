import React from "react";
import { PropTypes } from "prop-types";
import { Dropdown, Text, Tooltip } from "@sixsense/core/components";
import styles from "./styles.module.scss";

const CampaignActionItem = ({
  label,
  isDisabled,
  tooltipText,
  onClick,
  id,
  dataPendo,
  icon,
}) => (
  <Dropdown.Menu.Item
    id={`${id}-campaign`}
    key={`${id}-campaign`}
    onClick={onClick}
    disabled={isDisabled}
    data-6si-id={`campaign_list-action_dropdown-${dataPendo}`}
    data-testid={`campaign_list-action_dropdown-${dataPendo}`}
    before={icon}
  >
    <Tooltip
      overlay={isDisabled && <Text color="white">{tooltipText}</Text>}
      placement="left"
      trigger="hover"
    >
      <Text className={styles.dropdownMenuItemLabel}>{label}</Text>
    </Tooltip>
  </Dropdown.Menu.Item>
);

CampaignActionItem.defaultProps = {
  isDisabled: false,
};

CampaignActionItem.propTypes = {
  icon: PropTypes.elementType,
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  tooltipText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  dataPendo: PropTypes.string.isRequired,
};

export default CampaignActionItem;
