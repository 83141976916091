/*

TODO: Move these constants to someplace else and delete this file

 */


const SIXSENSE_NETWORK = '6sense_network';
const FILE_UPLOAD = 'file_upload';
const PROGRAMMATIC = 'programmatic';
const LOOKALIKE_ACCOUNTS = 'lookalike_accounts';
const EXTERNAL_LIST = 'external_list';
const LOOKALIKE_EXTERNAL = 'lookalike_external';
const ZENIQ_TAL = 'zeniq_tal';
const LOOKALIKE_TYPES = new Set([LOOKALIKE_ACCOUNTS, LOOKALIKE_EXTERNAL]);
export const MAP_AD_ACCOUNTS_FORM = 'MAP_AD_ACCOUNTS_FORM';
const AST_KEY = 'create_edit_segment';
const CREATE_KEY = 'create_segment_upload_id';
const LINKEDIN_STATES = {
  'Syncing to LinkedIn': {
    key: 'Syncing to LinkedIn',
    display_text: 'Syncing',
    tooltip_text: 'LinkedIn is currently building your matched audience;' +
    ' this can take up to 48 hours before it is ready to serve impressions.',
  },
  'Synced to LinkedIn': {
    key: 'Synced to LinkedIn',
    display_text: 'Synced',
    tooltip_text: 'The segment is fully available to be used in a campaign.' +
    ' The campaign should be able to serve right away.',
  },
  'Expired on LinkedIn': {
    key: 'Expired on LinkedIn',
    display_text: 'Inactive',
    tooltip_text: 'The segment has not been used in an active campaign for at least 30 days.',
  },
  'LinkedIn Sync Failed': {
    key: 'LinkedIn Sync Failed',
    display_text: 'Failed',
    tooltip_text: 'There was a failure when uploading lists. Please try again.',
  },
  UNSYNCED: {
    key: 'Unsynced from LinkedIn',
    display_text: 'Unsynced',
  },
  Deleted: {
    key: 'Deleted',
    display_text: 'Deleted',
    tooltip_text: 'LI audience was deleted from account\'s campaign manager, ' +
    'please toggle sync if need to link again.',
  },
  Disabled: {
    key: 'Disabled',
    display_text: 'Disabled',
    tooltip_text: 'Sync has been disable as it has more than 1M accounts.',
  },
};

export {
  SIXSENSE_NETWORK,
  FILE_UPLOAD,
  LOOKALIKE_ACCOUNTS,
  EXTERNAL_LIST,
  LOOKALIKE_EXTERNAL,
  AST_KEY,
  CREATE_KEY,
  LOOKALIKE_TYPES,
  ZENIQ_TAL,
  LINKEDIN_STATES,
  PROGRAMMATIC,
};
