import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import '../../styles/core.scss';
import styles from './AppContainer.module.scss';
// import { NotFound } from 'vcomponents';
import { isPrelogin } from 'utils/utils';
import { connect } from 'react-redux';
import { RBACProvider, permissionsSelector } from '@sixsense/rbac';
import {
  orgSelector,
  userIsStaffSelector,
  userLoadedSelector,
} from 'modules/user/selectors';
import { Maintenance, NotFound, Forbidden } from 'v2-components';
import { FeatureFlagsProvider } from '@sixsense/core/featureFlags';
import { ToastProvider } from '@sixsense/core/components';

// change to true for maintenance
const UNDER_MAINTENANCE = false;


export function AppContainerComponent(props) {
  const {
    children,
    notFound,
    forbidden,
    location,
    stateLocation,
    router,
    staffUser,
    userLoaded,
    permissions,
    isExternalSixsenseUser,
  } = props;

  const className = isPrelogin(location.pathname) ? styles.preloginContainer : styles.appContainer;
  const rbacInfo = useMemo(
    () => ({ permissions, isExternalSixsenseUser }),
    [permissions, isExternalSixsenseUser]
  );

  let content = children;

  if (notFound) {
    content = (
      <NotFound
        router={router}
        location={router.location.pathname}
        stateLocation={stateLocation}
      />
    );
  }
  if (forbidden) {
    content = (
      <Forbidden
        router={router}
        location={router.location.pathname}
        stateLocation={stateLocation}
      />
    );
  }

  return (
    <RBACProvider value={rbacInfo}>
      <ToastProvider>
        <FeatureFlagsProvider>
          <div id="appContainer" className={className}>
            {(UNDER_MAINTENANCE && !staffUser && userLoaded) ? <Maintenance /> : content}
          </div>
        </FeatureFlagsProvider>
      </ToastProvider>
    </RBACProvider>
  );
}

AppContainerComponent.propTypes = {
  location: PropTypes.object,
  children: PropTypes.element,
  stateLocation: PropTypes.string,
  notFound: PropTypes.bool,
  forbidden: PropTypes.bool,
  router: PropTypes.object.isRequired,
  staffUser: PropTypes.bool,
  userLoaded: PropTypes.bool,
  permissions: PropTypes.object,
  isExternalSixsenseUser: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  notFound: state.global.notFound,
  forbidden: state.global.forbidden,
  stateLocation: state.route.locationBeforeTransitions.pathname,
  staffUser: userIsStaffSelector(state),
  userLoaded: userLoadedSelector(state),
  permissions: permissionsSelector(state),
  isExternalSixsenseUser: userIsStaffSelector(state) && orgSelector(state) !== 4,

});

export const AppContainer = connect(mapStateToProps)(AppContainerComponent);
