import { map, findIndex, reduce, get, set } from 'lodash';

const emptyInviteFormState = {
  invalidEmails: [],
  validEmails: [],
  invitedUsers: {},
  formStatus: null,
  errorMessage: null,
  error: false,
};

const initialState = {
  // owner
  owner: {},

  roles: [],
  rolesLoading: false,
  rolesError: undefined,

  authType: '',
  authTypeError: undefined,

  // user types
  active: [],
  deactivated: [],
  invited: [],
  users: [],
  usersLoaded: false,
  ownerLoaded: false,
  error: false,
  userListErrorMessage: undefined,

  // invite modal state:
  inviteModalVisible: false,
  invitedUsers: {},
  invalidEmails: [],
  formStatus: null,
  errorMessage: undefined,

  // swap modal state:
  swapModalVisible: false,

  // table header state
  userSearch: '',
  userStatus: 'active',
  sortBy: 'username',
  sortOrder: 'asc',
  appsMaxUserLicenseMap: {},
  appsPermission: {},
  iFrameUserCount: null,

  emailsFilterMissingMap: {
    notInSystem: [],
    filteredOut: [],
    paEmail: null,
  },
  usernameFilter: '',

  bulkUpdateApiFailureLog: null,
  licenseResponse: [],

  rolesTabSelection: {
    selectedAppId: null,
    selectedLicenseLabel: null,
    selectedRoleIds: [],
  },
  userOTPModal: {
    otp: '',
    isModalOpen: false,
    userId: '',
    userName: '',
    firstName: '',
    lastName: '',
  },
};

const SET_ROLES_TAB_SELECTION = 'USERMGMT/SET_ROLES_TAB_SELECTION';
const setRolesTabSelection = (rolesTabSelection) => ({
  type: SET_ROLES_TAB_SELECTION,
  rolesTabSelection,
});

const CLOSE_OTP_MODAL = 'USERMGMT/CLOSE_OTP_MODAL';
const closeOtpModal = () => ({
  type: CLOSE_OTP_MODAL,
});


const SET_LICENSE_RESPONSE = 'USERMGMT/SET_LICENSE_RESPONSE';
const setLicenseResponse = (licenseResponse) => ({
  type: SET_LICENSE_RESPONSE,
  licenseResponse,
});

const SET_BULK_UPDATE_API_FAILURE_LOG =
  'USERMGMT/SET_BULK_UPDATE_API_FAILURE_LOG';
const setBulkUpdateApiFailureLog = (bulkUpdateApiFailureLog) => ({
  type: SET_BULK_UPDATE_API_FAILURE_LOG,
  bulkUpdateApiFailureLog,
});

const SET_USERNAME_FILTER = 'USERMGMT/SET_USERNAME_FILTER';
const setUsernameFilter = (usernameFilter) => ({
  type: SET_USERNAME_FILTER,
  usernameFilter,
});

const SET_EMAILS_FILTER_MISSING_MAP = 'USERMGMT/SET_EMAILS_FILTER_MISSING_MAP';
const setEmailsFilterMissingMap = (emailsFilterMissingMap) => ({
  type: SET_EMAILS_FILTER_MISSING_MAP,
  emailsFilterMissingMap,
});

const SET_APPS_PERMISSION = 'USERMGMT/SET_APPS_PERMISSION';
const setAppsPermission = (appsPermission) => ({
  type: SET_APPS_PERMISSION,
  appsPermission,
});

const SET_APPS_MAX_USER_LICENSE_MAP = 'USERMGMT/SET_APPS_MAX_USER_LICENSE_MAP';
const setAppsMaxUserLicenseMap = (appsMaxUserLicenseMap) => ({
  type: SET_APPS_MAX_USER_LICENSE_MAP,
  appsMaxUserLicenseMap,
});

const SET_IFRAME_USER_COUNT = 'USERMGMT/SET_IFRAME_USER_COUNT';
const setIFrameUserCount = (iFrameUserCount) => ({
  type: SET_IFRAME_USER_COUNT,
  iFrameUserCount,
});

const LOAD = 'USERMGMT/LOAD';
const loadAllUsers = () => ({ type: LOAD });

const LOAD_USERS_SUCCESS = 'USERMGMT/LOAD_USERS/SUCCESS';
const LOAD_USERS_FAILURE = 'USERMGMT/LOAD_USERS/FAILURE';
const NO_USERS = 'USERMGMT/LOAD_USERS/NONE';
const loadUsersSuccess = (users, ownerId) => ({
  type: LOAD_USERS_SUCCESS,
  users,
  ownerId,
});
const loadUsersFailure = (userListErrorMessage) => ({
  type: LOAD_USERS_FAILURE,
  userListErrorMessage,
});

const loadNoUsers = () => ({ type: NO_USERS });

const LOAD_OWNER_SUCCESS = 'USERMGMT/LOAD_OWNER/SUCCESS';
const LOAD_OWNER_FAILURE = 'USERMGMT/LOAD_OWNER/FAILURE';
const loadOwnerSuccess = () => ({ type: LOAD_OWNER_SUCCESS });
const loadOwnerFailure = (userListErrorMessage) => ({
  type: LOAD_OWNER_FAILURE,
  userListErrorMessage,
});

const LOAD_ROLES_REQUEST = 'USERMGMT/LOAD_ROLES/REQUEST';
const LOAD_ROLES_SUCCESS = 'USERMGMT/LOAD_ROLES/SUCCESS';
const LOAD_ROLES_FAILURE = 'USERMGMT/LOAD_ROLES/FAILURE';
const loadRolesRequest = () => ({
  type: LOAD_ROLES_REQUEST,
});
const loadRolesSuccess = (roles) => ({
  type: LOAD_ROLES_SUCCESS,
  roles,
});
const loadRolesFailure = (error) => ({
  type: LOAD_ROLES_FAILURE,
  error,
});

const LOAD_AUTH_TYPE_SUCCESS = 'USERMGMT/LOAD_AUTH_TYPE/SUCCESS';
const LOAD_AUTH_TYPE_FAILURE = 'USERMGMT/LOAD_AUTH_TYPE/FAILURE';
const loadAuthTypeSuccess = (authType) => ({
  type: LOAD_AUTH_TYPE_SUCCESS,
  authType,
});
const loadAuthTypeFailure = (error) => ({
  type: LOAD_AUTH_TYPE_FAILURE,
  error,
});

const SET_SEARCH = 'USERMGMT/SET_SEARCH';
const SET_STATUS = 'USERMGMT/SET_STATUS';
const SET_SORT_BY = 'USERMGMT/SET_SORTBY';
const setSearch = (userSearch) => ({ type: SET_SEARCH, userSearch });
const setStatus = (userStatus) => ({ type: SET_STATUS, userStatus });
const setSortBy = (sortBy) => ({ type: SET_SORT_BY, sortBy });

const SHOW_INVITE_MODAL = 'USERMGMT/SHOW_INVITE_MODAL';
const SHOW_SWAP_MODAL = 'USERMGMT/SHOW_SWAP_MODAL';
const GENERATE_USER_OTP = 'USERMGMT/GENERATE_USER_OTP';
const GENERATE_USER_OTP_SUCCESS = 'USERMGMT/GENERATE_USER_OTP_SUCCESS';

const changeInviteModalVisibility = (inviteModalVisible) => ({
  type: SHOW_INVITE_MODAL,
  inviteModalVisible,
});
const changeSwapModalVisibility = (swapModalVisible) => ({
  type: SHOW_SWAP_MODAL,
  swapModalVisible,
});

const generateUserOtp = (userId, userName, firstName, lastName) => ({
  type: GENERATE_USER_OTP,
  userId,
  userName,
  firstName,
  lastName,
});

const generateUserOtpSuccess = (otp) => ({
  type: GENERATE_USER_OTP_SUCCESS,
  otp,
});


const INVITE_USERS = 'USERMGMT/INVITE_USERS';
const inviteUsers = () => ({ type: INVITE_USERS });

const POST_INVALID_EMAILS = 'USERMGMT/POST_INVALID_EMAILS';
const postInvalidEmails = (invalidEmails) => ({
  type: POST_INVALID_EMAILS,
  invalidEmails,
});

const INVITE_USERS_SUCCESS = 'USERMGMT/INVITE_USERS/SUCCESS';
const INVITE_USERS_FAILURE = 'USERMGMT/INVITE_USERS/FAILURE';
const inviteUsersSuccess = (users, userType) => ({
  type: INVITE_USERS_SUCCESS,
  users,
  userType,
});
const inviteUsersFailure = (errorMessage) => ({
  type: INVITE_USERS_FAILURE,
  errorMessage,
});

const INVITE_FORM_SUCCESS = 'USERMGMT/INVITE_FORM/SUCCESS';
const INVITE_FORM_FAILURE = 'USERMGMT/INVITE_FORM/FAILURE';
const inviteFormSuccess = () => ({ type: INVITE_FORM_SUCCESS });
const inviteFormFailure = (errorMessage) => ({
  type: INVITE_FORM_FAILURE,
  errorMessage,
});

// helper for banner notifications
const NOTIFY = 'USERMGMT/NOTIFY';
const notify = (status, message) => ({ type: NOTIFY, status, message });

// choose new owner (from owner banner)
const SWAP_OWNER = 'USERMGMT/SWAP_OWNER';
const swapOwner = () => ({ type: SWAP_OWNER });

// select user(s)
const CHECK_USER = 'USERMGMT/CHECK_USER';
const CHECK_ALL_PAGE = 'USERMGMT/CHECK_ALL_ON_PAGE';
const CHECK_ALL_STATUS = 'USERMGMT/CHECK_ALL_OF_STATUS';
const checkUser = (id, checked) => ({ type: CHECK_USER, id, checked });
const checkAllOnPage = (idsToCheck = []) => ({
  type: CHECK_ALL_PAGE,
  idsToCheck,
});
const checkAllUsers = (status, uncheck = false) => ({
  type: CHECK_ALL_STATUS,
  status,
  uncheck,
});

// promote a user to owner(from user table)
const PROMOTE_TO_OWNER = 'USERMGMT/PROMOTE_TO_OWNER';
const PROMOTE_OWNER_SUCCESS = 'USERMGMT/PROMOTE_TO_OWNER/SUCCESS';
const PROMOTE_OWNER_FAILURE = 'USERMGMT/PROMOTE_TO_OWNER/FAILURE';
const promoteToOwner = (user, newRole) => ({
  type: PROMOTE_TO_OWNER,
  user,
  newRole,
});
const promoteOwnerSuccess = () => ({ type: PROMOTE_OWNER_SUCCESS });
const promoteOwnerFailure = () => ({ type: PROMOTE_OWNER_FAILURE });

// change user lvl between limited/basic user
const CHANGE_USER_LEVEL = 'USERMGMT/CHANGE_USER_LEVEL';
const CHANGE_USER_LEVEL_SUCCESS = 'USERMGMT/CHANGE_USER_LEVEL/SUCCESS';
const CHANGE_USER_LEVEL_FAILURE = 'USERMGMT/CHANGE_USER_LEVEL/FAILURE';
const changeUserLevel = (user, level) => ({
  type: CHANGE_USER_LEVEL,
  user,
  level,
});
const changeUserLevelSuccess = () => ({ type: CHANGE_USER_LEVEL_SUCCESS });
const changeUserLevelFailure = () => ({ type: CHANGE_USER_LEVEL_FAILURE });

// remind (owner and user)
const REMIND = 'USERMGMT/REMIND';
const REMIND_OWNER = 'USERMGMT/REMIND_OWNER';
const REMIND_SUCCESS = 'USERMGMT/REMIND/SUCCESS';
const REMIND_FAILURE = 'USERMGMT/REMIND/FAILURE';
const remindUsers = (users) => ({ type: REMIND, users });
const remindOwner = () => ({ type: REMIND_OWNER });
const remindUsersSuccess = () => ({ type: REMIND_SUCCESS });
const remindUsersFailure = () => ({ type: REMIND_FAILURE });

// deactivation
const DEACTIVATE = 'USERMGMT/DEACTIVATE';
const DEACTIVATE_SUCCESS = 'USERMGMT/DEACTIVATE/SUCCESS';
const DEACTIVATE_FAILURE = 'USERMGMT/DEACTIVATE/FAILURE';
const deactivateUsers = (users) => ({ type: DEACTIVATE, users });
const deactivateUsersSuccess = () => ({ type: DEACTIVATE_SUCCESS });
const deactivateUsersFailure = () => ({ type: DEACTIVATE_FAILURE });

// reactivation
const REACTIVATE = 'USERMGMT/REACTIVATE';
const REACTIVATE_SUCCESS = 'USERMGMT/REACTIVATE/SUCCESS';
const REACTIVATE_FAILURE = 'USERMGMT/REACTIVATE/FAILURE';
const reactivateUsers = (users) => ({ type: REACTIVATE, users });
const reactivateUsersSuccess = () => ({ type: REACTIVATE_SUCCESS });
const reactivateUsersFailure = () => ({ type: REACTIVATE_FAILURE });

// can prolly make these 2 the same similar to how change owner is
const RESET = 'USERMGMT/RESET';
const RESET_OWNER_PASSWORD = 'USERMGMT/RESET/OWNER';
const RESET_SUCCESS = 'USERMGMT/RESET/SUCCESS';
const RESET_FAILURE = 'USERMGMT/RESET/FAILURE';
const resetPasswords = (users) => ({ type: RESET, users });
const resetOwnerPassword = () => ({ type: RESET_OWNER_PASSWORD });
const resetPasswordsSuccess = () => ({ type: RESET_SUCCESS });
const resetPasswordsFailure = () => ({ type: RESET_FAILURE });

const UNALLOCATE = 'CREDITMGMT/UNALLOCATE';
const unallocateCreditUsers = (users) => ({ type: UNALLOCATE, users });

const groupUsers = (users) =>
  reduce(
    users,
    (collection, u) => {
      const category = u.is_active ? 'active' : 'deactivated';
      collection[get(u, 'user.last_login.length') ? category : 'invited'].push(
        set(u, 'checked', false)
      );
      return collection;
    },
    { active: [], deactivated: [], invited: [] }
  );

function userManagementReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH: {
      const { userSearch } = action;
      return { ...state, userSearch };
    }
    case SET_STATUS: {
      const { userStatus } = action;
      const { active, deactivated, invited } = state;

      return {
        // uncheck all checked users
        ...state,
        userStatus,
        active: map(active, (u) => set(u, 'checked', false)),
        deactivated: map(deactivated, (u) => set(u, 'checked', false)),
        invited: map(invited, (u) => set(u, 'checked', false)),
      };
    }
    case SET_SORT_BY: {
      const { sortBy: newSortBy } = action;
      const { sortBy, sortOrder } = state;

      const newSortOrder =
        sortBy === newSortBy // eslint-disable-line
          ? sortOrder === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc';

      return { ...state, sortBy: newSortBy, sortOrder: newSortOrder };
    }
    case LOAD: {
      return {
        ...state,
        usersLoaded: false,
        ownerLoaded: false,
        active: [],
        invited: [],
        deactivated: [],
      };
    }
    case PROMOTE_OWNER_SUCCESS: {
      return { ...state, swapModalVisible: false };
    }
    case LOAD_USERS_SUCCESS: {
      const { ownerId } = action;
      let { owner } = state;
      const users = [...action.users];
      if (ownerId) {
        const indexOfOwner = findIndex(users, { id: ownerId });
        if (indexOfOwner >= 0) {
          owner = users[indexOfOwner];
          if (owner) {
            owner.isOwner = true;
          }
          users.splice(indexOfOwner, 1);
        }
      }

      const groupedUsers = groupUsers(users); // active, deactivated, invited

      return {
        ...state,
        ...groupedUsers,
        owner,
        users: action.users,
        usersLoaded: true,
        ownerLoaded: true,
      };
    }
    case NO_USERS: {
      return { ...state, usersLoaded: true, ownerLoaded: true };
    }
    case LOAD_OWNER_FAILURE:
    case LOAD_USERS_FAILURE: {
      const { userListErrorMessage } = action;
      return {
        ...state,
        usersLoaded: true,
        ownerLoaded: true,
        error: true,
        userListErrorMessage,
      };
    }
    case LOAD_ROLES_REQUEST: {
      return {
        ...state,
        rolesLoading: true,
      };
    }
    case LOAD_ROLES_SUCCESS: {
      const { roles } = action;
      return {
        ...state,
        roles,
        rolesLoading: false,
        rolesError: undefined,
      };
    }
    case LOAD_ROLES_FAILURE: {
      const { error } = action;
      return {
        ...state,
        roles: {},
        rolesError: error,
        rolesLoading: false,
      };
    }
    case LOAD_AUTH_TYPE_SUCCESS: {
      const { authType } = action;
      return {
        ...state,
        authType,
        authTypeError: undefined,
      };
    }
    case LOAD_AUTH_TYPE_FAILURE: {
      const { error } = action;
      return {
        ...state,
        authType: '',
        authTypeError: error,
      };
    }
    case SHOW_INVITE_MODAL: {
      const { inviteModalVisible } = action;
      return { ...state, ...emptyInviteFormState, inviteModalVisible };
    }
    case SHOW_SWAP_MODAL: {
      const { swapModalVisible } = action;
      return { ...state, swapModalVisible };
    }

    case INVITE_USERS: {
      return { ...state, loading: true, formStatus: 'submitted' };
    }
    case POST_INVALID_EMAILS: {
      const { invalidEmails } = action;
      return { ...state, invalidEmails };
    }
    case INVITE_USERS_SUCCESS: {
      const { users, userType } = action;
      const { invitedUsers } = state;

      return {
        // use set or w/e
        ...state,
        invitedUsers: {
          ...invitedUsers,
          [userType]: users,
        },
      };
    }
    case INVITE_FORM_SUCCESS: {
      return { ...state, formStatus: 'complete' };
    }
    case INVITE_USERS_FAILURE:
    case INVITE_FORM_FAILURE: {
      const { errorMessage } = action;
      return { ...state, formStatus: 'complete', errorMessage };
    }
    case CHECK_ALL_PAGE: {
      const { idsToCheck } = action;
      const { userStatus, active, invited, deactivated } = state;

      const userTypeGroup = state[userStatus];

      const groupedUsers = {
        active,
        invited,
        deactivated,
      };

      const newCheckedUserGroup = map(userTypeGroup, (u) => ({
        ...u,
        checked: idsToCheck.includes(u.user.id) ? !u.checked : u.checked,
      }));

      const users = set(groupedUsers, userStatus, newCheckedUserGroup);
      return { ...state, ...users };
    }
    case CHECK_USER: {
      const { id, checked } = action;
      const { userStatus, active, invited, deactivated } = state;
      const userTypeGroup = state[userStatus];
      const indexOfUserToCheck = findIndex(
        userTypeGroup,
        (u) => u.user.id === id
      );

      const groupedUsers = {
        active,
        invited,
        deactivated,
      };

      const users = set(
        groupedUsers,
        `${userStatus}[${indexOfUserToCheck}].checked`,
        !checked
      );

      return { ...state, ...users };
    }
    case CHECK_ALL_STATUS: {
      const { status, uncheck } = action;
      const usersToCheck = state[status];
      return {
        ...state,
        [status]: map(usersToCheck, (u) => set(u, 'checked', !uncheck)),
      };
    }
    case SET_APPS_MAX_USER_LICENSE_MAP: {
      const { appsMaxUserLicenseMap } = action;
      return {
        ...state,
        appsMaxUserLicenseMap,
      };
    }
    case SET_APPS_PERMISSION: {
      const { appsPermission } = action;
      return {
        ...state,
        appsPermission,
      };
    }
    case SET_IFRAME_USER_COUNT: {
      const { iFrameUserCount } = action;
      return {
        ...state,
        iFrameUserCount,
      };
    }
    case SET_EMAILS_FILTER_MISSING_MAP: {
      const { emailsFilterMissingMap } = action;
      return {
        ...state,
        emailsFilterMissingMap,
      };
    }
    case SET_USERNAME_FILTER: {
      const { usernameFilter } = action;
      return {
        ...state,
        usernameFilter,
      };
    }
    case SET_BULK_UPDATE_API_FAILURE_LOG: {
      const { bulkUpdateApiFailureLog } = action;
      return {
        ...state,
        bulkUpdateApiFailureLog,
      };
    }
    case SET_LICENSE_RESPONSE: {
      const { licenseResponse } = action;
      return {
        ...state,
        licenseResponse,
      };
    }
    case SET_ROLES_TAB_SELECTION: {
      const { rolesTabSelection } = action;
      return {
        ...state,
        rolesTabSelection: {
          ...rolesTabSelection,
        },
      };
    }

    case GENERATE_USER_OTP: {
      const { userId, userName, firstName, lastName } = action;

      return {
        ...state,
        userOTPModal: {
          ...state.userOTPModal, // Preserve existing state
          userId,
          userName,
          firstName,
          lastName,
        },
      };
    }

    case GENERATE_USER_OTP_SUCCESS: {
      const { otp } = action;
      return {
        ...state,
        userOTPModal: {
          ...state.userOTPModal, // Preserve existing fields (e.g., userName, firstName, lastName)
          otp,
          isModalOpen: true,
        },
      };
    }

    case CLOSE_OTP_MODAL: {
      return {
        ...state,
        userOTPModal: {
          otp: '',
          isModalOpen: false,
          userName: '',
          firstName: '',
          lastName: '',
          userId: '',
        },
      };
    }
    default: {
      return { ...state };
    }
  }
}

export default userManagementReducer;

export const actions = {
  setSearch,
  setStatus,
  setSortBy,

  changeInviteModalVisibility,
  changeSwapModalVisibility,

  inviteUsers,
  postInvalidEmails,

  inviteUsersSuccess,
  inviteUsersFailure,
  inviteFormSuccess,
  inviteFormFailure,

  swapOwner,

  checkUser,
  checkAllOnPage,
  checkAllUsers,

  promoteToOwner,
  promoteOwnerSuccess,
  promoteOwnerFailure,

  changeUserLevel,
  changeUserLevelSuccess,
  changeUserLevelFailure,

  remindUsers,
  remindOwner,
  remindUsersSuccess,
  remindUsersFailure,

  deactivateUsers,
  deactivateUsersSuccess,
  deactivateUsersFailure,

  reactivateUsers,
  reactivateUsersSuccess,
  reactivateUsersFailure,

  resetPasswords,
  resetOwnerPassword,
  resetPasswordsSuccess,
  resetPasswordsFailure,

  loadNoUsers,
  loadUsersSuccess,
  loadUsersFailure,
  loadOwnerSuccess,
  loadOwnerFailure,
  loadRolesRequest,
  loadRolesSuccess,
  loadRolesFailure,
  loadAuthTypeSuccess,
  loadAuthTypeFailure,

  notify,
  loadAllUsers,
  setAppsMaxUserLicenseMap,

  setAppsPermission,
  setIFrameUserCount,
  setEmailsFilterMissingMap,
  setUsernameFilter,
  setBulkUpdateApiFailureLog,

  setLicenseResponse,

  setRolesTabSelection,

  unallocateCreditUsers,
  generateUserOtp,
  generateUserOtpSuccess,
  closeOtpModal,
};

export const actionTypes = {
  INVITE_USERS,
  NOTIFY,
  SWAP_OWNER,
  PROMOTE_TO_OWNER,
  CHANGE_USER_LEVEL,

  REMIND,
  REMIND_OWNER,
  RESET_OWNER_PASSWORD,
  DEACTIVATE,
  REACTIVATE,
  RESET,
  LOAD,

  UNALLOCATE,
  GENERATE_USER_OTP,
};
