/* eslint-disable max-len */
import { LINKEDIN_SPONSORED_CONTENT_POSTER_REQUEST_SENT_PROMPT } from 'constants/campaigns';
import {
  CAMPAIGN_SOURCE,
  CAMPAIGN_SOURCE_LABELS,
  RELEASE_TYPES,
  RETARGETING_CAMPAIGN_WARNING,
} from 'utils/constants';
import { Modal } from 'v2-components';

export const CREATE_CAMPAIGN_FORM = 'createCampaignForm';
export const CAMPAIGN_TYPES_CONFIG = [
  {
    sectionLabel: '6sense campaigns',
    subText: 'Build, execute, and measure programmatic campaigns through the 6sense DSP.',
    types: [
      {
        name: CAMPAIGN_SOURCE_LABELS[CAMPAIGN_SOURCE.INTERNAL],
        type: CAMPAIGN_SOURCE.INTERNAL,
        description: `Run self-serve digital display, video and native advertising 
        directly through the 6sense DSP.`,
        icon: 'display.svg',
        actionBtnLabel: 'Create now',
        details: {
          summary: `Run customizable self-serve digital display, video and native advertising 
          programs to engage target account segments and personas directly
           through the 6sense platform.`,
          objective: `Deliver low-cost, high-reach digital ‘surround’ programs to your specific 
          target audience to Generate Brand Awareness, Create 
          Solution Recall and Drive Website Engagement that provides lift to other 
          channels and influences broader pipeline creation efforts.`,
          additionalResources: [
            {
              urlText: '6sense Campaign Creation',
              url: 'https://support.6sense.com/hc/en-us/articles/4405026813971',
            },
            {
              urlText: 'FAQ: Campaign Targeting',
              url: 'https://support.6sense.com/hc/en-us/articles/360037628853-FAQ-Campaign-Targeting  ',
            },
            {
              urlText: 'FAQs: Campaign Performance Indicators',
              url: 'https://support.6sense.com/hc/en-us/articles/360055344253-FAQs-Campaign-Performance-Indicators-CPI-',
            },
            {
              urlText: 'Brand Safety and Inventory Quality in Display Advertising',
              url: 'https://support.6sense.com/hc/en-us/articles/1500010117842-Brand-Safety-Inventory-Quality-in-Display-Advertising',
            },
          ],
        },
        dataPendo: `${CAMPAIGN_SOURCE.INTERNAL}-create`,
      },
      {
        name: CAMPAIGN_SOURCE_LABELS[CAMPAIGN_SOURCE.RETARGETING],
        type: CAMPAIGN_SOURCE.RETARGETING,
        description: `Run targeted programs to re-engage people who have recently 
        visited your brand’s site.`,
        icon: 'retargeting.svg',
        actionBtnLabel: 'Create now',
        details: {
          summary: `Run highly targeted programs to re-engage people who have 
          recently visited your brand’s site. Retargeting is often the highest 
          performing display advertising tactic for B2B marketers. Potential spend is lower, 
          limited by your site traffic, making it an ideal campaign to have always running. 
          Retargeting allows customers to deliver a more effective re-engagement strategy that 
          helps encourage engaged visitors to engage more and progress their journey.`,
          objective: `Deliver effective re-engagement programs for relevant display programs and 
          CTAs to extend brand awareness and solution recall created during a site visit. Entice 
          customers to further re-engage with your brand and accelerate their journey.`,
          additionalResources: [
            {
              urlText: '6sense Visitor Retargeting',
              url: 'https://support.6sense.com/hc/en-us/articles/360048592614-6sense-Visitor-Retargeting',
            },
          ],
          note: {
            info: RETARGETING_CAMPAIGN_WARNING.INFO,
            learnMoreURL: RETARGETING_CAMPAIGN_WARNING.LEARN_MORE_URL,
          },
        },
        dataPendo: `${CAMPAIGN_SOURCE.RETARGETING}-create`,
      },
      {
        name: CAMPAIGN_SOURCE_LABELS[CAMPAIGN_SOURCE.CONTEXTUAL],
        type: CAMPAIGN_SOURCE.CONTEXTUAL,
        description: 'Run campaigns on web pages that are relevant to various B2B topics.',
        icon: 'contextual_targeting.svg',
        actionBtnLabel: 'Create now',
        details: {
          summary: `Run campaigns on web pages that are relevant to various B2B topics.
          Pick from a selection of topics that align to your brand’s focus, and reach 
          audiences in a privacy-friendly manner by targeting based on content.`,
          objective: `Run campaigns on web pages that are relevant to various B2B topics.
          Pick from a selection of topics that align to your brand’s focus, and reach 
          audiences in a privacy-friendly manner by targeting based on content.`,
          additionalResources: [
            {
              urlText: '6sense Contextual Targeting',
              url: 'https://support.6sense.com/hc/en-us/articles/5661569027091',
            },
          ],
        },
        dataPendo: `${CAMPAIGN_SOURCE.CONTEXTUAL}-create`,
      },
      {
        name: CAMPAIGN_SOURCE_LABELS[CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING],
        type: CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING,
        releaseType: RELEASE_TYPES.EARLY_ACCESS,
        releaseTypeCssClass: 'earlyAccessReleaseTag',
        description: 'Run self-serve LinkedIn campaigns directly through the 6sense platform.',
        icon: 'IntegrationLogos/linkedin-logo.svg',
        actionBtnLabel: 'Create now',
        details: {
          summary: 'Launch, manage and monitor your LinkedIn campaigns directly through the 6sense platform.',
          objective: `Improve advertising efficiency by running hyper-targeted, omni-channel campaigns 
          through the 6sense platform. Leverage 6sense data to prioritize your 
          LinkedIn spend on key accounts, only reach the people you care about by using 
          LinkedIn profile targeting, and analyze campaign performance with account-level metrics & 
          influenced pipeline reporting.`,
          additionalResources: [
            {
              urlText: "How to Approve a 'Content Poster' Request on LinkedIn",
              onClick: () => Modal.prompt(LINKEDIN_SPONSORED_CONTENT_POSTER_REQUEST_SENT_PROMPT),
            },
            {
              urlText: 'LinkedIn Advertising FAQs ',
              url: 'https://support.6sense.com/hc/en-us/articles/11609015564563',
            },
            {
              urlText: 'LinkedIn Advertising Overview',
              url: 'https://support.6sense.com/hc/en-us/articles/11495605655315',
            },
          ],
        },
        dataPendo: `${CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING}-create`,
      },
    ],
  },
  {
    sectionLabel: 'External',
    subText: '',
    types: [
      {
        name: CAMPAIGN_SOURCE_LABELS[CAMPAIGN_SOURCE.EXTERNAL],
        type: CAMPAIGN_SOURCE.EXTERNAL,
        description: `Track external media campaigns from one of our 30+ supported providers to
         discover previously unavailable account performance`,
        icon: 'emc.svg',
        actionBtnLabel: 'Track now',
        details: {
          summary: `Utilize 6sense’s account-level media program reporting capabilities to 
          understand the reach and performance of traditional display programs run on other platforms.`,
          objective: `Provide an account-based view to external programs to understand how well 
          your investments are reaching and engaging the right audiences for your business and 
          enable better optimization of your media strategy and specific external programs.`,
          additionalResources: [
            {
              urlText: 'External Media Campaigns',
              url: 'https://support.6sense.com/hc/en-us/articles/360048453594-External-Display-Campaigns',
            },
          ],
        },
        dataPendo: `${CAMPAIGN_SOURCE.EXTERNAL}-create`,
      },
    ],
  },
];
