export const EXCLUDE_MID_CATEGORIES = [
  'Analysts and Press',
  'Competitors',
  'Industry',
  'Partners',
  'Region',
  'Revenue',
  'Other (Exclude Mids)',
];

export const EXCLUDE_CRM_CATEGORIES = [
  'Person Accounts',
  'Test or Junk Accounts',
  'Other (Exclude Ids)',
];

export const FIRMO_ALLOWED_CATEGORIES = [
  'Industry',
  'Region',
  'Revenue',
  'Other (Exclude Mids)',
];

// Redux related constants (object keys, etc.)
export const MID_RULES_KEY = 'blacklistMid';
export const EXC_RULES_KEY = 'blacklistExc';
export const CRM_FILTERS = 'crm';
export const FIRMO_FILTERS = 'firmo';
// Below: the django record ids associated with the following filterset_type table records.
export const FIRMO_FILTERSET_TYPE = 4;
export const MID_FILTERSET_TYPE = 1;
export const CRM_FILTERSET_TYPE = 2;
// Op values
export const EQUALS_OP = 'equals';
export const NOT_EQUALS_OP = 'not_equal';
export const CONTAINS_OP = 'contains';
export const NOT_CONTAINS_OP = 'not_contains';
export const STARTS_WITH_OP = 'starts_with';
export const GREATER_OP = 'greater';
export const NOT_GREATER_OP = 'not_greater';
export const LESS_THAN_OP = 'less';
export const NOT_LESS_THAN_OP = 'not_less';
// Input types
export const STRING_INPUT_TYPE = 'string';
export const FLOAT_INPUT_TYPE = 'float';
export const INT_INPUT_TYPE = 'int';
export const BOOL_INPUT_TYPE = 'boolean';
export const RANGE_INPUT_TYPE = 'range';
export const COMMA_SEP_INPUT_TYPE = 'comma';
export const DATE_INPUT_TYPE = 'date';
// Specific column/values
export const TECH_STACK_COLUMN_LABEL = 'tech_stack';
export const TECH_STACK_COLUMN_VALUE = 'techname';
export const INDUSTRY_V2_COLUMN_LABEL = 'industry_v2';
export const INDUSTRY_V2_COLUMN_VALUE = 'firm_industry_v2';
export const EMPTY_FIELD = 'Empty Field';

export const STRING_OPS = [
  EQUALS_OP,
  NOT_EQUALS_OP,
  CONTAINS_OP,
  NOT_CONTAINS_OP,
  STARTS_WITH_OP,
];

export const NUMBER_DATE_OPS = [
  EQUALS_OP,
  NOT_EQUALS_OP,
  GREATER_OP,
  NOT_GREATER_OP,
  LESS_THAN_OP,
  NOT_LESS_THAN_OP,
];

export const BOOL_RANGE_OPS = [EQUALS_OP, NOT_EQUALS_OP];
export const AUTOCOMPLETE_OPS = [EQUALS_OP, NOT_EQUALS_OP];
export const COMMA_SEP_OPS = [CONTAINS_OP, NOT_CONTAINS_OP, STARTS_WITH_OP];
export const FILTER_OR_OPS = [EQUALS_OP, STARTS_WITH_OP, CONTAINS_OP];

export const OP_OPTIONS = [
  { label: 'Equals', value: EQUALS_OP },
  { label: 'Not Equal to', value: NOT_EQUALS_OP },
  { label: 'Contains', value: CONTAINS_OP },
  { label: 'Does not Contain', value: NOT_CONTAINS_OP },
  { label: 'Starts With', value: STARTS_WITH_OP },
  { label: 'Greater than', value: GREATER_OP },
  { label: 'Not greater than', value: NOT_GREATER_OP },
  { label: 'Less than', value: LESS_THAN_OP },
  { label: 'Not less than', value: NOT_LESS_THAN_OP },
];

export const INDUSTRY_V2_OPS = [
    { label: 'Equals', value: EQUALS_OP },
    { label: 'Not Equal to', value: NOT_EQUALS_OP },
];

export const DEFAULT_OP_OPTIONS = {
  string: { label: 'Equals', value: EQUALS_OP },
  float: { label: 'Greater than', value: GREATER_OP },
  int: { label: 'Greater than', value: GREATER_OP },
  date: { label: 'Equals', value: EQUALS_OP },
  boolean: { label: 'Equals', value: EQUALS_OP },
  range: { label: 'Equals', value: EQUALS_OP },
};

// Default options for filter value components when selecting a field to filter on.
export const FILTER_VALUE_DEFAULTS = {
  string: [],
  float: 0,
  int: 0,
  boolean: 'true',
  date: undefined,
  range: [],
};

export const MID_BL_DESC = `Rules configured in this section will
exclude all CRM Records that match to a 6sense Master Company record.`;

export const EXC_BL_DESC = `Rules configured in this section will only exclude
the specific CRM Records from being mastered with 6sense Master Company Data records
and presented within 6sense.`;

export const CATEGORY_DESC = {
  'Analysts and Press': `Configure rules to exclude analyst and press accounts from 
    being used in 6sense analytics and campaigns.`,
  Competitors: `Configure rules to exclude competitor accounts from 
    being used in 6sense analytics and campaigns.`,
  Industry: `Configure rules to exclude accounts in certain industries from 
    being used in 6sense analytics and campaigns.`,
  Partners: `Configure rules to exclude partner accounts from 
    being used in 6sense analytics and campaigns.`,
  Region: `Configure rules to exclude accounts in certain regions from 
    being used in 6sense analytics and campaigns.`,
  Revenue: `Configure rules to exclude accounts in certain revenue ranges from 
  being used in 6sense analytics and campaigns.`,
  'Other (Exclude Mids)': `Configure rules to exclude accounts that do not
    fit into any default categories from being used in 6sense analytics and campaigns.`,
  'Person Accounts': `Configure rules to exclude CRM person accounts, commonly used to
    represent individuals, from contributing to the 6sense Mastered Company Database.`,
  'Test or Junk Accounts': `Configure rules to exclude CRM test or junk accounts from
    contributing to the 6sense Mastered Company Database.`,
  'Other (Exclude Ids)': `Configure rules to exclude CRM accounts that do not fit any
    default categories from contributing to the 6sense Mastered Company Database.`,
};

export const CRM_DISABLED_CATEGORIES = {
  [MID_RULES_KEY]: ['Analysts and Press', 'Competitors', 'Partners'],
  [EXC_RULES_KEY]: ['Person Accounts', 'Test or Junk Accounts', 'Other (Exclude Ids)'],
};

export const CRM_DISABLED_CATEGORY_TOOLTIP = `Rules in this category will use your CRM Account 
data. To enable this category, please integrate your CRM Account with 6sense.`;

export const FIRMO_COLUMNS = [
  { dataType: STRING_INPUT_TYPE, value: 'firm_city' },
  { dataType: STRING_INPUT_TYPE, value: 'firm_name' },
  { dataType: STRING_INPUT_TYPE, value: 'firm_domain' },
  { dataType: STRING_INPUT_TYPE, value: 'firm_country' },
  { dataType: RANGE_INPUT_TYPE, value: 'firm_employee_range' },
  { dataType: FLOAT_INPUT_TYPE, value: 'firm_employee_count' },
  { dataType: STRING_INPUT_TYPE, value: 'industry_v2' },
  { dataType: STRING_INPUT_TYPE, value: 'firm_industry' },
  { dataType: STRING_INPUT_TYPE, value: 'firm_naics' },
  { dataType: STRING_INPUT_TYPE, value: 'firm_zip' },
  { dataType: STRING_INPUT_TYPE, value: 'firm_region' },
  { dataType: RANGE_INPUT_TYPE, value: 'firm_revenue_range' },
  { dataType: FLOAT_INPUT_TYPE, value: 'firm_annual_revenue' },
  { dataType: STRING_INPUT_TYPE, value: 'firm_sic' },
  { dataType: STRING_INPUT_TYPE, value: 'firm_state' },
  { dataType: STRING_INPUT_TYPE, value: 'tech_stack' },
];

export const FIRMO_FILTERS_BY_CATEGORY = {
  Industry: ['industry', 'naics', 'sic', 'firm_industry_v2'],
  Region: ['country', 'region', 'city', 'state', 'zip'],
  Revenue: ['revenue_range', 'annual_revenue'],
};
