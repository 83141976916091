import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyRouterMiddleware, browserHistory, Router } from 'react-router';
import { useScroll } from 'react-router-scroll';
import createSagas from './sagas';
import rollbar from 'lib/rollbar';
import createStore from 'store/createStore';
import { ThemeProvider } from '@sixsense/core/style/theme';
import { Provider as StoreProvider } from '@sixsense/core/versioned/react-redux';
import { RequestProvider } from '@sixsense/core/request';
import { fetchRequest } from 'utils/request';
import { initDatadog } from 'lib/datadog';
import { getHistory } from './history';

initDatadog();


const NONCE = window.__CSP_NONCE__;

__webpack_nonce__ = NONCE; // eslint-disable-line no-undef

window.Rollbar = rollbar;

const initialState = {};

const store = createStore(initialState, browserHistory);

const history = getHistory(store);

const MOUNT_NODE = document.getElementById('content');

let render = () => {
  createSagas(store);
  const { createRoutes, createAuthRoutes } = require('routes');
  const createRoutesFunc = window.process.env.LOGIN_FLOW ? createAuthRoutes: createRoutes;

  const rootRoute = {
    component: require('containers/AppContainer/AppContainer').AppContainer,
    childRoutes: createRoutesFunc(store),
  };

  ReactDOM.render(
    <ThemeProvider nonce={NONCE}>
      <RequestProvider value={fetchRequest}>
        <Provider store={store}>
          {/* We use an old version of react-redux that does not support hooks, use a version */}
          {/* locked provider to get access to redux hooks. */}
          <StoreProvider store={store}>
            <Router
              history={history}
              routes={rootRoute}
              // Scroll to top when going to a new page, imitating default browser behaviour
              // eslint-disable-next-line react-hooks/rules-of-hooks
              render={applyRouterMiddleware(useScroll())}
            />
          </StoreProvider>
        </Provider>
      </RequestProvider>
    </ThemeProvider>,
    MOUNT_NODE
  );
};

if (module.hot) {
  module.hot.accept([
    './routes/index',
    './containers/AppContainer/AppContainer',
  ], () => {
    const renderApp = render;

    // Wrap render in try/catch
    render = () => {
      try {
        setImmediate(() => {
          ReactDOM.unmountComponentAtNode(MOUNT_NODE);
          renderApp();
        });
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    };

    render();
  });
}

render();
