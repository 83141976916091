import { getAsyncInjectors } from 'store/utils';
import { combineReducers } from 'redux';
import { concat } from 'lodash';
import ObjectDetailsRoute from './routes/ObjectDetails';
import ObjectListRoute from './routes/ObjectList';
import SourceListRoute from './routes/SourceList';
import PrepeteRoute from './routes/Prepete';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'standardization',
    name: 'standardization',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/settings/standardization/source'),
    },
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');

          const objectDetailsSagas = require('./containers/ObjectDetails/sagas');
          const baseSaga = require('./sagas');
          const objectListSagas = require('./containers/ObjectList/sagas');

          const objectDetailsReducer = require('./containers/ObjectDetails/modules');
          const baseReducer = require('./modules');
          const objectListReducer = require('./containers/ObjectList/modules');

          const combinedReducers = combineReducers({
            objectDetails: objectDetailsReducer.default,
            base: baseReducer.default,
            objectList: objectListReducer.default,
          });

          const combinedSagas = concat(
            objectDetailsSagas.default,
            baseSaga.default,
            objectListSagas.default,
          );

          injectReducer('settingsStandardization', combinedReducers);
          injectSagas('settingsStandardization', combinedSagas);

          cb(null, containers.StandardizationContainer);
        },
        'settings_standardization',
      );
    },
    childRoutes: [ObjectDetailsRoute(), ObjectListRoute(), SourceListRoute(), PrepeteRoute(store)],
  };
}
