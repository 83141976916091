/* notification types */
export const CONTACT_PURCHASE_APPROVAL = 'CONTACT_PURCHASE_APPROVAL';
export const ORC_ADD_TO_CAMPAIGN = 'add_to_display_campaign';
export const APPROVAL_NOTIFICATION_TYPES = [
  CONTACT_PURCHASE_APPROVAL,
  ORC_ADD_TO_CAMPAIGN,
];

export const DISPLAY_CAMPAIGN = 'DISPLAY_CAMPAIGN';
export const DRIVE_WEB_ENGAGEMENT = 'DRIVE_WEB_ENGAGEMENT';
export const ORCHESTRATION_EXISTING_CONTACT_CAMPAIGN_PUSH =
  'ORCHESTRATION_EXISTING_CONTACT_CAMPAIGN_PUSH';

export const APPROVAL_STATUS = {
  APPROVED: 'APPROVED',
  APPROVED_PENDING: 'APPROVED_PENDING_UPDATE',
  PARTIALLY_APPROVED_PENDING: 'PARTIALLY_APPROVED_PENDING_UPDATE',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
};
export const APPROVAL_LABELS = {
  [APPROVAL_STATUS.APPROVED]: 'Approved',
  [APPROVAL_STATUS.APPROVED_PENDING]: 'Approved',
  [APPROVAL_STATUS.PARTIALLY_APPROVED_PENDING]: 'Approved',
  [APPROVAL_STATUS.PENDING]: 'Pending',
  [APPROVAL_STATUS.REJECTED]: 'Rejected',
};
export const APPROVAL_ACTIONS = {
  APPROVE_ALL: 'approved',
  PARTIAL_APPROVE: 'partially_approved',
  REJECT_ALL: 'rejected',
  DISMISS: 'dismissed',
};

export const approveSome = (recordCount, campaignName, actionType, objectType) => `
  Do you want to add ${recordCount} ${objectType}s to the
  ${actionType === ORCHESTRATION_EXISTING_CONTACT_CAMPAIGN_PUSH ? ''
  : '6sense advertising'} campaign: ${campaignName}?
  Rejected ${objectType}s will not be recommended again for this campaign.
`;

export const rejectAll = (recordCount, campaginName, actionType, objectType) => `
  Do you want to reject all ${recordCount} ${objectType}s?
  These ${objectType}s will not be recommended again for this 
  ${actionType === ORCHESTRATION_EXISTING_CONTACT_CAMPAIGN_PUSH ? '' : '6sense advertising'}
   campaign`;
