
import { createSelector } from 'reselect';
import { PROMISE_STATES } from '../../../modules/global/constants';
import { ADVERTISING_REDUX_STATE_KEY } from '../constants';
import { campaignClassificationDucks, adLibraryClassificationsDucks } from '../ducks';

const {
  stateGenerators: {
    campaignClassifications,
  },
} = campaignClassificationDucks;
const {
  stateGenerators: {
    adLibraryClassifications,
  },
} = adLibraryClassificationsDucks;

const baseSelector = (state) => state[ADVERTISING_REDUX_STATE_KEY];

const uiStateSelector = createSelector(
  baseSelector,
  ({ uiState }) => uiState,
);

export const directoryTreeLoadingSelector = createSelector(
  campaignClassifications.promiseStateSelector,
  adLibraryClassifications.promiseStateSelector,
  (campaignClassificationPromiseState, adLibraryClassificationsPromiseState) => [
    campaignClassificationPromiseState,
    adLibraryClassificationsPromiseState,
  ].some((promiseState) => [
    PROMISE_STATES.INIT,
    PROMISE_STATES.PENDING,
  ].includes(promiseState))
);

export const isUpdatingTreeDataSelector = createSelector(
  uiStateSelector,
  ({ isUpdatingTreeData }) => isUpdatingTreeData,
);

export const treeNodeSelectionsSelector = createSelector(
    uiStateSelector,
  ({ folderSelection }) => folderSelection,
);

export const selectedTreeNodeSelector = createSelector(
  treeNodeSelectionsSelector,
  (selections) => {
    if (selections.length === 1) return selections[0];

    return null;
  }
);

export const expandedFoldersSelector = createSelector(
    uiStateSelector,
  ({ expandedFolders }) => expandedFolders,
);

export const newCampaignsFolderModalVisibilitySelector = createSelector(
  baseSelector,
  ({ newCampaignsFolderModalVisible }) => newCampaignsFolderModalVisible,
);

export const renameCampaignsFolderModalVisibilitySelector = createSelector(
  baseSelector,
  ({ renameCampaignsFolderModalVisible }) => renameCampaignsFolderModalVisible,
);

export const renameAdsFolderModalVisibilitySelector = createSelector(
  baseSelector,
  ({ renameAdsFolderModalVisible }) => renameAdsFolderModalVisible,
);

export const newAdsFolderModalVisibilitySelector = createSelector(
  baseSelector,
  ({ newAdsFolderModalVisible }) => newAdsFolderModalVisible,
);

export const deleteCampaignsFolderModalVisibilitySelector = createSelector(
  baseSelector,
  ({ deleteCampaignsFolderModalVisible }) => deleteCampaignsFolderModalVisible,
);

export const deleteAdsFolderModalVisibilitySelector = createSelector(
  baseSelector,
  ({ deleteAdsFolderModalVisible }) => deleteAdsFolderModalVisible,
);

export const uploadedLinkedinVideoCreativeSelector = createSelector(
  baseSelector,
  ({ linkedinVideoAdCreatives }) => linkedinVideoAdCreatives
);
