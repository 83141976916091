import React from "react";
import { Flex, Text, Avatar } from "@sixsense/core/components";
import { NA } from "./common/NA";
import { is6SenseCampaign } from "../../../utils";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { AD_TYPE_LABELS } from "routes/AdvertisingDS/constants";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";
import { adTypeLabel } from "../utils";

export const AD_TYPE = {
  customizable: true,
  key: "ad-type",
  csvColumnKey: "campaign_subtype",
  title: <Label name={CAMPAIGN_LABELS.campaignSubtype} />,
  dataIndex: "campaign_subtype",
  width: pxToRem(170),
  render: (campaignSubType, campaign) => {
    if (!is6SenseCampaign(campaign)) {
      return <NA />;
    }

    const campaignAdType = AD_TYPE_LABELS[campaignSubType];
    const logo = adTypeLabel(campaignSubType);
    return (
      <Flex alignItems="center">
        {logo ? <Avatar src={logo} wrapperClassName="mr2" size="2xs" /> : null}
        <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
          {campaignAdType}
        </Text.Body>
      </Flex>
    );
  },
};
