export default function createRoutes() {
  return {
    path: 'published',
    name: 'segment-published',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const PublishedSegments = require('./PublishedSegments');
        cb(null, PublishedSegments.default);
      }, 'segment-published');
    },
  };
}
