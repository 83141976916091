import { createSelector } from 'reselect';
import { get, map } from 'lodash';

const baseSelector = (state) => state.sagaPolling;
const pollDetailsSelector = (channel) => (guid) => createSelector(
  baseSelector,
  (pollState) => get(pollState, `${channel}.${guid}`, {}),
);
const taskSelector = (channel) => (guid) => createSelector(
  pollDetailsSelector(channel)(guid),
  (pollDetails) => pollDetails.task,
);
const channelTaskSelector = (channel) => createSelector(
  baseSelector,
  (pollState) => map(pollState[channel], 'task'),
);

export {
  taskSelector,
  pollDetailsSelector,
  channelTaskSelector,
};
