import { PropTypes } from "prop-types";

const campaignClassification = PropTypes.shape({
  campaign_count: PropTypes.number.isRequired,
  classification_type: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  created_by: PropTypes.string.isRequired,
  edited_by: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  is_deleted: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  org: PropTypes.number.isRequired,
  updated: PropTypes.string.isRequired,
});

export const emptyObjectType = PropTypes.shape({});

export const folderType = campaignClassification;
export const folderTypes = PropTypes.shape({
  defaultFolders: PropTypes.arrayOf(campaignClassification),
  userCreatedFolders: PropTypes.arrayOf(campaignClassification),
});
