import { lowerCase } from 'lodash';
import { maybePlural } from 'utils/utils';
import { AD_LIBRARY_CLASSIFICATION_ACTIONS } from './constants';

export const getSuccessMessageForAction = ({
    payload: {
      uiAction = lowerCase(AD_LIBRARY_CLASSIFICATION_ACTIONS.move),
      folder,
      itemIds: placement_ids,
    },
  }) => {
  const entity = maybePlural(placement_ids.length, 'Placement');
  const message = `${placement_ids.length} ${entity} ${uiAction}d successfully`;
  return `${message} to "${folder.name}".`;
};
