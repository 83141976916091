import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { actions } from 'modules/global';
import { connect } from 'react-redux';

const PageHeader = (HeaderComponents, staticProps = {}) => (WrappedComponent) => {
  class ToolbarWrapper extends Component {
    static propTypes = {
      appendToolBar: PropTypes.func,
      clearToolBar: PropTypes.func,
    }

    componentDidMount() {
      map(HeaderComponents, (HeaderComponent, idx) => {
        this.props.appendToolBar(<HeaderComponent
          {...staticProps}
          {...(staticProps.isUsedAsWrappedComponent ? this.props : {})}
          key={idx}
        />);
      });
    }

    componentWillUnmount() {
      this.props.clearToolBar();
    }

    render() {
      return (
        <WrappedComponent {...this.props} />
      );
    }
  }

  return connect(() => ({}),
    {
      appendToolBar: actions.appendToolBar,
      clearToolBar: actions.clearToolBar,
      popToolBar: actions.popToolBar,
    }
  )(ToolbarWrapper);
};

export default PageHeader;
