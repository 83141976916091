/* eslint-disable no-unused-vars */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useRef } from 'react';
import { Upload } from '@sixsense/core/icons';
import { Text, Flex, Button } from '@sixsense/core/components';
import { css } from 'styles/emotion';
import {
  FileUploadDropAreaProps,
} from '@sixsense/core/components/data-entry/FileUpload/FileUploadDropArea';


const FileUploadButton = (props: FileUploadDropAreaProps) => {
  const {
    disabled = false,
    multiple,
    addFiles,
    uploadUserMessage = 'PDF or DOC (max. 5 Mb)',
    accept,
  } = props;
  const inputRef = useRef();
  const handleFiles = (inputFiles: FileList) => {
    if (inputFiles?.length) {
      const files: File[] = Array.from(inputFiles);
      addFiles(files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e?.target?.files?.length) {
      handleFiles(e?.target?.files);
    }
  };

  const btnStyle = css({
    height: 'unset !important',
    borderRadius: '4px !important',
    padding: '8px !important',
    borderColor: '#CDD1D5',
    svg: {
      height: '16px !important',
    },
  });

  return (
    <Flex alignItems="center" gap={8}>
      <Button
        hierarchy="secondary"
        leadingIcon={Upload}
        onClick={() => inputRef.current.click()}
        disabled={disabled}
        className={btnStyle}
      >
        Upload File
      </Button>
      <input
        ref={inputRef}
        multiple={multiple}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept={accept}
        type="file"
        disabled={disabled}
      />
      <Text>{uploadUserMessage}</Text>
    </Flex>
  );
};

export default FileUploadButton;
