import { defineSharedState, defineRequestState } from '@sixsense/core';
import { Keyword, AddKeywordsConfig } from '../types';
import { REC_KEYWORDS_QUERY, ORG_PRODUCTS_QUERY } from '../constants';

const PATH_PREFIX = 'settings.keywordsPage.keywords';

export const DEFAULT_ADD_KEYWORDS_CONFIG_STATE = {
  category: undefined,
  product_id: undefined,
  keywords: [],
  keyword_groups: [],
  rec_keywords: [],
  group_options: [],
  recKeywordsVisible: false,
};

export const addKeywordsConfigState = defineSharedState<AddKeywordsConfig>({
  path: `${PATH_PREFIX}.addKeywordsConfig`,
  defaultValue: { ...DEFAULT_ADD_KEYWORDS_CONFIG_STATE },
});

export type KeywordRequestParams = {
  orgId: string | number;
  queryParams: string;
  paginationParams: string;
  counter: number;
};

export const keywordRequestState = defineRequestState({
  path: `${PATH_PREFIX}.keywords`,
  getConfig: ({ orgId, queryParams, paginationParams }: KeywordRequestParams) => ({
    url: `${queryParams.length > 0
      ? `organization/${orgId}/keyword/${paginationParams}${queryParams}`
      : `organization/${orgId}/keyword/${paginationParams}`}`,
  }),
  transformData: (data: any): Keyword[] | [] => {
    if (data.results.length) {
      return data;
    }
    return [];
  },
});

export type KeywordAccountsRequestParams = {
  orgId: string | number;
  keywordIds: number[];
  span: string;
};

export const keywordAccountsRequestState = defineRequestState({
  path: `${PATH_PREFIX}.keywordAccounts`,
  getConfig: ({ orgId, keywordIds, span }: KeywordAccountsRequestParams) => ({
    url: `query/${orgId}/keyword_accounts/?span=${span}`,
    method: 'POST',
    body: JSON.stringify({
      keyword_ids: keywordIds,
    }),
  }),
  transformData: (data: any) => data,
});

export type RecommendedKeywords = string[];

export const recKeywordsState = defineSharedState<RecommendedKeywords>({
  path: `${PATH_PREFIX}.recommendedKeywords`,
  defaultValue: [],
});

export type AddKeywordsModalVisible = boolean;

export const addKeywordsVisibleState = defineSharedState<AddKeywordsModalVisible>({
  path: `${PATH_PREFIX}.addKeywordsModalVisible`,
  defaultValue: false,
});


export const genericKeywordsRequestState = defineRequestState({
  path: 'keywords.generic',
  getConfig: (orgId: number | string) => ({
    url: `organization/${orgId}/keyword/`,
  }),
  transformData: (data) => {
    if (data.results.length) {
      return data;
    }
    return [];
  },
});


export const recommendedKeywordsRequestState = defineRequestState({
  path: 'keywords.recommended',
  getConfig: (inputKeywords: any[]) => ({
    url: 'graphql/',
    method: 'POST',
    body: JSON.stringify({
      operationName: 'KeywordRecommendationsQuery',
      variables: { inputKeywords },
      query: REC_KEYWORDS_QUERY,
    }),
  }),
  transformData: (resp) => {
    if (resp.data.keywordRecommendations.length) {
      return resp.data.keywordRecommendations;
    }
    return [];
  },
});


export const productsRequestState = defineRequestState({
  path: 'keywords.products',
  getConfig: () => ({
    url: 'graphql/',
    method: 'POST',
    body: JSON.stringify({
      query: ORG_PRODUCTS_QUERY,
    }),
  }),
  transformData: (resp) => {
    if (resp.data.products.length) {
      return resp.data.products;
    }
    return [];
  },
});
