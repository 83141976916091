// todo: move to aa-components
import React from 'react';
import PropTypes from 'prop-types';
import { StateComponent, CenterInParent } from 'HOCS';
import { Text, Button } from 'v2-components';
import styles from './CardContent.module.scss';

function Content(props) {
  const { children, className } = props;
  return <div className={className}>{children}</div>;
}

const ErrorCard = ({ className, message, onClick }) => (
  <div className={`${styles.errorCard} ${className}`}>
    <Text type={Text.TYPE.TITLE}>{message}</Text>
    <Button
      className={styles.refreshButton}
      onClick={() => onClick ? onClick() : window.location.reload()}
    >
      Refresh
    </Button>
  </div>
);

ErrorCard.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
ErrorCard.defaultProps = { message: 'Looks like we hit a snag.' };

// Styles need to be applied inline for svg elements
const svgStyle = {
  height: '100%',
  width: '100%',
};

const lineStyle = {
  stroke: '#E2E7EC',
  strokeWidth: '4',
};

const x = { x1: '0', x2: '100%' };

const ZeroChartData = () => (
  <div className={styles.containerTest}>
    <div className={styles.label}>
      <Text type={Text.TYPE.TITLE}>Zero data available</Text>
    </div>

    <div>
      <svg style={svgStyle}>
        <line {...x} y1="90%" y2="50%" style={lineStyle} />
      </svg>
    </div>
    <div>
      <svg style={svgStyle}>
        <line {...x} y1="50%" y2="60%" style={lineStyle} />
      </svg>
    </div>
    <div>
      <svg style={svgStyle}>
        <line {...x} y1="60%" y2="30%" style={lineStyle} />
      </svg>
    </div>
    <div>
      <svg style={svgStyle}>
        <line {...x} y1="30%" y2="40%" style={lineStyle} />
      </svg>
    </div>
    <div>
      <svg style={svgStyle}>
        <line {...x} y1="40%" y2="10%" style={lineStyle} />
      </svg>
    </div>
  </div>
);

const CardContent = StateComponent(
  Content,
  CenterInParent({ fillParent: true })(ErrorCard),
  CenterInParent({ fillParent: true })(ZeroChartData),
);

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CardContent.Error = ErrorCard;

export default CardContent;
