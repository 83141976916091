import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: 'smartformfill',
    name: 'smartformfill',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const reducer = require('./modules/index');
          const sagas = require('./sagas/index');
          injectReducer('smartFormFill', reducer.default);
          injectSagas('smartFormFill', sagas.default);
          const integrationReducer = require('routes/Settings/routes/Integration/modules');
          injectReducer('integrationsRoot', integrationReducer.default);
          const formWizardReducer =
          require('routes/Settings/routes/Integration/components/FormWizard2/modules');
          injectReducer('SettingsFormWizard', formWizardReducer.default);
          cb(null, containers.SmartFormFillContainer);
        },
        'settings_smartFormFill'
      );
    },
  };
}
