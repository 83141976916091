import React from 'react';
/* eslint max-len: [2, 4000] */
// Using 1000 for now.... might need to bump it up

export function BetaIcon(props) {
  return (
    <svg width={30} height={16} viewBox="0 0 30 16" {...props}>
      <title>{'Beta'}</title>
      <g fill="none" fillRule="evenodd">
        <rect fill="#13BBB2" width={30} height={16} rx={2} />
        <path
          d="M6.57 12c.807 0 1.445-.182 1.916-.547.47-.364.705-.872.705-1.523 0-.46-.1-.827-.3-1.104-.2-.277-.526-.465-.979-.566v-.05a1.2 1.2 0 00.798-.492c.2-.274.3-.624.3-1.05 0-.628-.228-1.086-.685-1.375-.458-.288-1.193-.432-2.205-.432H3.898V12H6.57zM6.29 7.688h-.879V6.102h.796c.433 0 .75.059.95.178.2.119.3.315.3.588 0 .293-.092.503-.276.63-.184.127-.48.19-.891.19zm.107 3.062h-.986V8.89h.938c.846 0 1.27.296 1.27.888 0 .33-.1.573-.299.733-.198.16-.506.239-.923.239zm6.573 1.348c.426 0 .787-.032 1.084-.096.296-.063.574-.16.835-.29V10.56c-.32.15-.625.258-.918.327a4.095 4.095 0 01-.933.102c-.423 0-.752-.119-.986-.356-.235-.238-.36-.568-.376-.991h3.52v-.723c0-.775-.215-1.382-.644-1.821-.43-.44-1.03-.66-1.802-.66-.807 0-1.44.25-1.897.75-.457.5-.686 1.207-.686 2.122 0 .888.247 1.575.742 2.06.495.485 1.182.728 2.06.728zm.83-3.448h-2.09c.026-.367.129-.651.308-.852.179-.2.426-.3.742-.3.315 0 .566.1.752.3.185.2.281.485.288.852zm4.477 3.448c.54 0 .996-.083 1.368-.25V10.74c-.365.114-.678.171-.938.171a.703.703 0 01-.471-.151c-.119-.101-.178-.257-.178-.469V7.659h1.528V6.541h-1.528V5.379h-.953l-.43 1.152-.82.498v.63h.713v2.632c0 .602.136 1.054.408 1.355.272.301.706.452 1.301.452zm3.8 0c.426 0 .771-.061 1.037-.183.265-.123.523-.341.774-.657h.039l.288.742h1.04V8.362c0-.65-.195-1.136-.586-1.455-.39-.319-.952-.478-1.685-.478-.765 0-1.461.164-2.09.493l.494 1.006c.589-.264 1.102-.396 1.538-.396.566 0 .85.277.85.83v.24l-.948.029c-.817.03-1.428.181-1.833.456-.406.275-.608.703-.608 1.282 0 .554.15.98.451 1.28.301.299.714.449 1.238.449zm.56-1.09c-.481 0-.722-.21-.722-.63 0-.292.106-.51.317-.653.212-.144.534-.222.967-.235l.576-.02v.45c0 .329-.103.592-.31.79-.206.2-.482.299-.827.299z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
