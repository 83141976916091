import { ApolloClient } from 'apollo-client';
import { withClientState } from 'apollo-link-state';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { defaults, typeDefs, resolvers } from './defaults';

/**
 * Creates and returns an ApolloClient instance.
 * @returns {ApolloClient}
 */
export function createApolloClient() {
  const cache = new InMemoryCache({
    dataIdFromObject: (object) => {
      switch (object.__typename) {
        case 'KeywordRecommendationType':
          return `KeywordRecommendationType(${object.value})`;
        default:
          return defaultDataIdFromObject(object);
      }
    },
  });
  const httpLink = new HttpLink({
    uri: `${window.location.origin}/graphql/`,
    headers: {
      'X-CSRFToken': window.__CSRF_TOKEN__,
    },
  });

  const stateLink = withClientState({
    resolvers,
    defaults,
    cache,
    typeDefs,
  });

  const link = ApolloLink.from([
    stateLink,
    httpLink,
  ]);

  const client = new ApolloClient({
    cache,
    link,
  });

  return client;
}
