import React from 'react';
import { Button, Flex, Skeleton, Text } from '@sixsense/core/components';
import { SkeletonVariant } from '@sixsense/core/components/Skeleton/constant';
import { ArrowNarrowLeft } from '@sixsense/core/icons';
import PropTypes from 'prop-types';

const AppHeaderTitle = (props) => {
  const { title, subTitle, isLoading, onBackBtnCick } = props;
  if (isLoading || !title) {
    return (
      <Skeleton
        variant={SkeletonVariant.RECTANGULAR}
        style={{ height: '20px', width: '200px' }}
      />
    );
  }

  return (
    <Flex gap={20} justifyContent="flex-start" alignItems="center">
      {onBackBtnCick && (
        <Button
          data-testid="backButton"
          icon={() => <ArrowNarrowLeft />}
          onClick={() => onBackBtnCick()}
          hierarchy={'tertiary'}
        />)}
      <Text size={20}>{title}</Text>
      {subTitle && <Text.Paragraph size={16}>{subTitle}</Text.Paragraph>}
    </Flex>
  );
};

AppHeaderTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  onBackBtnCick: PropTypes.func,
};

export default AppHeaderTitle;
