import { actions as globalActions } from 'modules/global';
import {
  CREATE_CLASSIFICATIONS, FETCH_CLASSIFICATIONS, CHANGE_CLASSIFICATION,
  DELETE_CLASSIFICATION, RENAME_CLASSIFICATION,
} from './types';
import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';
import { selectors } from './selectors';
import { ACTION_PREFIX, AD_LIBRARY_CLASSIFICATION_TYPES, REQUIRED_FOLDER_TYPES } from './constants';
import { hotSwapReducerFactory } from '../advertisingClassificationCommon/reducers';
import { getMissingFolderTypes, reArrangeFolders } from '../advertisingClassificationCommon/utils';
import { get } from 'lodash';
import { actionCreators } from './actions';
import { getSuccessMessageForAction } from './utils';
import { maybePlural } from 'utils/utils';
import {
  bulkDeleteClassificationBodySelector,
  renameClassificationBodySelector,
} from '../advertisingClassificationCommon/selectors';
import { logRollbarError } from 'routes/Advertising/actions';
import * as commonActions from 'routes/Advertising/ducks/advertisingClassificationCommon/actions';

const initialState = [];

const { showNotification } = globalActions;

const adLibraryClassifications = stateGenerator({
  baseStateSelector: selectors.baseSelector,
  actionPrefix: FETCH_CLASSIFICATIONS,
  endpointSelector: selectors.fetchClassificationEndpointSelector,
  stateKey: 'classifications',
  initialData: initialState,
  receivedDataTransform: ({ results }) => reArrangeFolders(
    [AD_LIBRARY_CLASSIFICATION_TYPES.UNSORTED_FOLDER, AD_LIBRARY_CLASSIFICATION_TYPES.FOLDER],
    results,
  ),
  onSuccessActions: [
    (folders) => {
      const missingFolderTypes = getMissingFolderTypes(
        folders,
        REQUIRED_FOLDER_TYPES
      );
      if (missingFolderTypes.length > 0) {
        return logRollbarError(
          `${missingFolderTypes.toString()} not found in folder list for Ads`
        );
      }
      return commonActions.fakeAction(ACTION_PREFIX);
    },
  ],
  onFailureActions: [
    () => showNotification('error', 'Failed to fetch Ad Library folder list'),
  ],
  attachReducer: hotSwapReducerFactory(ACTION_PREFIX),
});

export const createAdsClassification = stateGenerator({
  baseStateSelector: selectors.baseSelector,
  actionPrefix: CREATE_CLASSIFICATIONS,
  endpointSelector: selectors.classificationEndpointSelector,
  stateKey: 'createClassification',
  onFailureActions: [(e) => showNotification('error', 'Failed to create folder', e.errorMessage)],
  onSuccessActions: [
    () => showNotification('success', 'Successfully created folder'),
    (payload) => actionCreators.addClassificationHotSwap(payload),
  ],
  method: 'POST',
  requestOptions: selectors.createClassificationPayloadSelector,
});

const changeClassification = stateGenerator({
  baseStateSelector: selectors.baseSelector,
  actionPrefix: CHANGE_CLASSIFICATION,
  endpointSelector: selectors.changeClassificationEndpointSelector,
  stateKey: 'adLibraryChangeClassification',
  onFailureActions: [
    (_, rootAction) => {
      const uiAction = get(rootAction, 'payload.uiAction');
      return showNotification('error', `Failed to ${uiAction}.`
      );
    },
  ],
  onSuccessActions: [
    (payload) => actionCreators.replaceClassificationHotSwap(payload),
    (_, rootAction) => showNotification(
      'success',
      getSuccessMessageForAction(rootAction),
    ),
    () => actionCreators.setClassificationModalVisibility(false),
    () => changeClassification.refreshAction(),
  ],
  method: 'PATCH',
  requestOptions: selectors.changeClassificationPayloadSelector,
});

const deleteAdsClassification = stateGenerator({
  baseStateSelector: selectors.baseSelector,
  actionPrefix: DELETE_CLASSIFICATION,
  endpointSelector: selectors.bulkDeleteClassificationEndpointSelector,
  stateKey: 'bulkDeleteFolders',
  onFailureActions: [(e) => showNotification('error', 'Failed to delete folders', e.errorMessage)],
  onSuccessActions: [
    (_, rootAction) => showNotification(
      'success',
      `Successfully deleted ${maybePlural(rootAction.payload.length, 'folder')}`
    ),
    (_, rootAction) =>
      actionCreators.deleteClassificationHotSwap(rootAction.payload),
  ],
  method: 'POST',
  requestOptions: bulkDeleteClassificationBodySelector,
});

const renameClassification = stateGenerator({
  baseStateSelector: selectors.baseSelector,
  actionPrefix: RENAME_CLASSIFICATION,
  stateKey: 'renameFolder',
  endpointSelector: selectors.changeClassificationEndpointSelector,
  onFailureActions: [() => showNotification('error', 'Failed to rename folder')],
  onSuccessActions: [
    (payload) => actionCreators.replaceClassificationHotSwap(payload),
    () => showNotification('success', 'Successfully renamed folder'),
    () => renameClassification.refreshAction(),
  ],
  method: 'PATCH',
  requestOptions: renameClassificationBodySelector,
});


export const stateGenerators = {
  adLibraryClassifications,
  changeClassification,
  createAdsClassification,
  deleteAdsClassification,
  renameClassification,
};
