import React from 'react';
import { PageHeader } from 'HOCS';
import {
  AccessDenied, Text,
} from 'v2-components';
import { SalesPermission, withPermissionBoundary } from '@sixsense/rbac';
import useOrg from 'hooks/useOrg';
import { useRequestValue } from '@sixsense/core';
import { compose } from 'redux';
import ActivitiesHeader from '../ActivitiesHeader';
import { Flex, Loading, ResultBoundary } from '@sixsense/core/components';
import { ActivitiesCard } from '../components/ActivitiesCard';
import { componentsAndMetricsRequestState } from '../state';
import { ActivitiesCardHeader } from '../components/ActivityCardHeader';
import { css } from 'styles/emotion';
import { connect } from 'react-redux';
import { siPackagePlanNameSelector } from 'routes/Settings/selectors';
import PropTypes from 'prop-types';
import { MAINTENANCE_INFO } from 'routes/Settings/constants';

const styles = {
  error: css({
    margin: '10px 10px 0 0',
  }),
};

const ErrorDisplay = () => (
  <Flex style={{ justifyContent: 'center', paddingTop: '200px' }}>
    <Text type={Text.TYPE.TITLE} className={styles.error}>
      Look's like something went wrong, please try again later!
    </Text>
  </Flex>
);


const ActivitiesContainer = ({ siPackagePlanName }) => {
  const { id: orgId } = useOrg();
  const componentsSettings = useRequestValue(componentsAndMetricsRequestState, { orgId });
  if (!siPackagePlanName) return <AccessDenied />;
  if (MAINTENANCE_INFO.with_read_only) return <AccessDenied message={MAINTENANCE_INFO.message} />;
  return (
    <ResultBoundary
      result={componentsSettings}
      renderLoading={() => <Loading.Spinner level="page" />}
      renderError={() => <ErrorDisplay />}
    >
      {({ data }) => (
        <ActivitiesCard
          componentsSettings={data}
          orgId={orgId}
          header={<ActivitiesCardHeader />}
        />
        )}
    </ResultBoundary>
  );
};
const mapStateToProps = (state) => ({
  siPackagePlanName: siPackagePlanNameSelector(state),
});
ActivitiesContainer.propTypes = {
  siPackagePlanName: PropTypes.string,
};

export default compose(
    withPermissionBoundary({
      allow: (permissions) => [
        SalesPermission.SETTINGS_ACTIVITIES_VIEW,
        SalesPermission.SETTINGS_ACTIVITIES_EDIT,
      ].some((permission) => permissions.has(permission)),
      renderDenied: () => <AccessDenied />,
    }),
    connect(mapStateToProps),
    PageHeader([ActivitiesHeader])
  )(ActivitiesContainer);
