import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { debounce } from 'lodash';
import styles from './AutoComplete.module.scss';

// This component should only be used with the autoComplete HOC.
// Direct props passed include: className, value, onChange
// the rest should be passed through by the HOC
const onInputKeyDown = (onEnter, column, id) => (event) => {
  switch (event.keyCode) {
    case 13: // ENTER
      // Override default ENTER behavior by doing stuff here and then preventing default
      onEnter(event, column, id);
      event.preventDefault();
      break;
    default:
  }
};
const noOp = () => null;

function AutoComplete(props) {
  const {
    loading,
    loadOptions,
    options,
    column,
    className,
    value,
    onChange,
    uniqueKey,
    onEnter,
    style,
  } = props;

  let noResultsText = loading ? 'Loading...' : 'No results found';
  if (value === '') {
    noResultsText = null;
  }

  let selectOptions = options;
  if (value) {
    // If a value is set, then this forces select component to only show that value
    // - We HAVE to do this because react-select calls onInputChange with an empty string
    //   whenever you lose focus or select a value. Why? idk. If you want to fix that be my guest
    // - This will only show the SELECTED value in the dropdown. which actually might be a good
    //   thing.
    selectOptions = [{ value, label: value }];
  }

  return (
    <Select
      isLoading={loading}
      className={`${className} ${styles.select}`}
      autosize={false}
      onChange={onChange}
      filterOptions={(option) => option}
      options={selectOptions}
      backspaceRemoves
      placeholder="Type to select..."
      noResultsText={noResultsText}
      onInputKeyDown={onEnter ? onInputKeyDown(onEnter, column, uniqueKey) : noOp}
      onInputChange={debounce((val) => {
        loadOptions(val, column, uniqueKey);
      }, 250)}
      value={value}
      style={style}
    />
  );
}

AutoComplete.propTypes = {
  onEnter: PropTypes.func,
  loading: PropTypes.bool,
  loadOptions: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  column: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  uniqueKey: PropTypes.string,
  style: PropTypes.object,
};

export default AutoComplete;
