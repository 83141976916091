import { SI_PlANS } from 'routes/Settings/constants';

export const PATH_PREFIX = 'settings.psychographics';
export const KW_EXP = 'Keyword Experiment';
export const OTHER = 'Other';
export const PSYCHOGRAPHICS_CONFIRM_TEXT = `Are you sure you want to
 remove the selected Psychographics from your list?`;
export const PSYCHOGRAPHIC_CONFIRM_TEXT = `Are you sure you want to
 remove this Psychographics from your list?`;
export const ADD_PSYCHOGRAPH_SUCCESS_MSG = 'Successfully added Psychographics';
export const DELETE_PSYCHOGRAPH_SUCCESS_MSG = 'Successfully removed Psychographics';
export const ORDER_PSYCHOGRAPH_SUCCESS_MSG = 'Successfully changed Psychographics order';
export const PSYCHOGRAPH_ERROR_MSG = `There was an error 
. Please try again later`;
export const PSYCHOGRAPH_DISABLED_TOOTLTIP = 'Select a Product to Add Psychographics';
export const MODELS_ENABLED_PLAN = [SI_PlANS.PLAN_E, SI_PlANS.PLAN_F];
export const PRODUCT_CATEGORIES = [
  {
    name: 'all',
    display_name: 'All Product Categories',
    id: 'all',
  }];
export const PRODUCT_LABELS = [
  { name: 'keywordexperiment', display_name: OTHER },
  { name: 'slintel', display_name: 'Sales Intelligence' },
];
