export default function createRoutes() {
  return {
    path: 'create',
    name: 'Create',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          cb(null, containers.ReportListContainer);
        },
        'reportsCreate'
      );
    },
  };
}
