import metaLogo from 'images/IntegrationLogos/meta-logo2.svg';

export const FACEBOOK_ADS_INTEGRATION_ROUTE = 'facebook-ads-manager/integration';

export const ACCOUNT_STATUSES = {
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  DISABLED: 'DISABLED',
  UNSETTLED: 'UNSETTLED',
  PENDING_RISK_REVIEW: 'PENDING_RISK_REVIEW',
  PENDING_SETTLEMENT: 'PENDING_SETTLEMENT',
  IN_GRACE_PERIOD: 'IN_GRACE_PERIOD',
  PENDING_CLOSURE: 'PENDING_CLOSURE',
  ANY_ACTIVE: 'ANY_ACTIVE',
  ANY_CLOSED: 'ANY_CLOSED',
};

export const ACCOUNT_STATUS_DEFINITIONS = {
  [ACCOUNT_STATUSES.ACTIVE]: 'Indicates an active account that is able to serve ads.',
  [ACCOUNT_STATUSES.CLOSED]: 'Indicates a closed account that is unable to serve ads.',
  [ACCOUNT_STATUSES.DISABLED]: 'Indicates a disabled account that is unable to serve ads.',
  [ACCOUNT_STATUSES.PENDING_CLOSURE]: 'Indicates an account that is pending closure.',
};

export const ACCOUNT_STATUS_TOOLTIP = {
  [ACCOUNT_STATUSES.CLOSED]: 'Your ad account is closed',
  [ACCOUNT_STATUSES.DISABLED]: 'Your ad account is disabled.',
  [ACCOUNT_STATUSES.PENDING_CLOSURE]: 'Your ad account is pending closure',
};

export const FacebookConfig = {
  header: {
    logo: metaLogo,
    mainTitle: '6sense Segments for Meta',
    category: 'Ads',
    subTitle: 'by Meta.Inc',
  },
  about: {
    mainInfo: {
      title: 'About this Integration',
      description:
      'The 6sense integration with Meta Business Manager allows revenue '+
      'teams to leverage AI insights for hyper-targeted advertising on the Meta network,'+
      ' reaching high value accounts at the right time and promoting cost efficiency.',
    },
    metaInfo: [
      {
        name: 'Category',
        value: 'Ads',
      },
      {
        name: 'Feature Support',
        value: 'Advertising, Segments',
      },
    ],
    keyfeatures: {
      title: 'Key Features',
      features: [
        {
          name: 'Target the best accounts',
          value: 'By leveraging AI-driven ICP fit predictions for each account.',
        },
        {
          name: 'Orchestrate Account Engagement',
          value:
              'Using dynamic segments for hyper-targeted campaigns and outreach.',
        },
        {
          name: 'Automate Workflows and Improve Efficiency ',
          value: 'By focusing on accounts that matter to your team',
        },
      ],
    },
  },
  help: {
    links: [
      {
        title: 'Additional Resources',
        list: [
          {
            // eslint-disable-next-line max-len
            href: 'https://support.6sense.com/hc/en-us/articles/1500001473522-FAQ-6sense-Segments-for-Facebook-Ads',
            text: 'FAQs : 6sense Segments for Meta',
          },
        ],
      },
    ],
  },
};

export const AD_ACCOUNT_TERMS_AND_CONDITION_KEY = 'tos_accepted.custom_audience_tos';

export const PERMISSION_CODES = {
  ADS_MANAGEMENT: 'ads_management',
  BUSINESS_MANAGEMENT: 'business_management',
};
export const PERMISSION_LABELS = {
  [PERMISSION_CODES.ADS_MANAGEMENT]: 'Manage ads for ad accounts that you have access to',
  [PERMISSION_CODES.BUSINESS_MANAGEMENT]: 'Manage your business',
};

export const SIGN_IN_ERROR_KEY = {
  permissionError: 'permissions_declined',
};
