import {
  TYPE_CONSTRUCTOR_MAP,
  And,
  Or,
  VarExpr,
  Const,
  NullExpr,
  EqOp,
  BetweenOp,
} from 'ast-redux/constructors';
import { AND, NEGATIVE_BINARY_EXPRESSIONS } from 'ast-redux/constants';
import { findCascadeHead } from 'aa-ast/utils';
import { walk, visitWith } from 'zipper/utils';
import { isA, isBin, not, isLogical, isNegBin } from 'ast-redux/utils';
import {
  isDateFilterParent,
  meetsAllConditions,
  isDateFilter,
  isDateFilterSibling,
  isDateCascade,
  isCascadeTopNode,
  isLastCascadeNode,
} from 'aa-ast/base';
import { TLoc } from 'ast-redux/zipper';
import { omit, values, pick, curry, reduce, set } from 'lodash';
import { renderTemplate } from 'utils/utils';
import { filterFieldTypes } from 'utils/constants';
import moment from 'moment';

const { NUMERIC, DATE, BOOLEAN } = filterFieldTypes;

// creates a duplicate node to be appended to a cascade
const duplicateNode = (loc, config, value, lastValue) => {
  const {
    is_date_dependent,
    newColumnValue,
  } = config;

  const currentNode = loc.node();
  const order = currentNode.order;

  const parent = loc.up();
  const andCascade = (parent && isA(AND)(parent)) ||
    NEGATIVE_BINARY_EXPRESSIONS.has(currentNode.type) ||
    (is_date_dependent && isNegBin(loc.down()));

  const isFirstNode = newColumnValue === lastValue;
  let nodeToCopy = currentNode;

  if (is_date_dependent) {
    nodeToCopy = loc.down().node();
  }

  const newColumnName = nodeToCopy.lhs.name;

  let newNode = TYPE_CONSTRUCTOR_MAP[nodeToCopy.type](
    VarExpr(newColumnName),
    Const(value),
  );

  if (is_date_dependent) {
    newNode = And(newNode, loc.down().right().node());
  }

  if (isFirstNode) {
    newNode.order = order;
    return newNode;
  }

  const lhs = omit(
    currentNode,
    'order',
  );

  return andCascade
    ? And(lhs, newNode, order)
    : Or(lhs, newNode, order);
};

// loc is the binary expression that we call remove on
// order is identifying that this is the first field in a cascade
const removeNode = (loc, order) => {
  const parent = TLoc(loc).up();
  // if there is not parent node => this is the only thing in the tree so return a nullexpr
  if (
    !parent ||
    (isDateFilterParent(parent) && !parent.up())
  ) {
    return NullExpr();
  }

  // if our parent is a date fitler, we'll be working off the parent;
  const location = isDateFilterParent(parent) ? parent : loc;
  // if we have a parent, and the parent has an order, this is the first child of a cascade
  const isFirstCascadeChild = location.up() && location.up().node().order;

  // we will always want to remove this loc (duh, we just clicked remove)
  // but we will have to treat this node differently if it is the very last
  // or very first filter in a cascade
  let newLoc = location.remove();
  const isEndOfCascade =
    !order &&
    (
      (isDateFilterParent(newLoc) || isBin(newLoc)) &&
      location.up().node().order
    );

  // if so, we need to create a new(replacement) node
  if (isFirstCascadeChild || isEndOfCascade) {
    const newOrder = location.up().node().order;
    // if this is the first cascade child, we're going to use the order passed to
    // this function...otherwise use the parent's order (end of a cascade)
    const replacement = TYPE_CONSTRUCTOR_MAP[newLoc.node().type](
      newLoc.node().lhs,
      newLoc.node().rhs,
      order || newOrder,
    );

    newLoc = newLoc.replace(replacement);
  }
  return newLoc.root();
};

// if the current node('s parent) is the head of the subtree, just replace the
// current logic, otherwise find the head of the cascade, then walk down and replace
const replaceCascadeLogic = (loc, path, action, logicalOperator) => {
  const swapNode = curry(createReplacement)(logicalOperator);
  const currentNode = TLoc(loc).up();

  // if no parent, this isn't a cascade, so just swap the currentNode's logic
  if (!currentNode.up()) {
    path.pop();
    return action(swapNode(currentNode), path);
  }
  // otherwise, we're in a cascade, find the head
  const [cascadeHead, pathToHead] = findCascadeHead(currentNode, path);
  const condition = meetsAllConditions([
    isLogical,
    not(isDateFilter),
    not(isDateFilterSibling),
    not(isDateFilterParent),
  ]);

  const replacer = (location) => location.replace(swapNode(location));

  const visitors = [[condition, replacer]];

  // and then walk the subtree, replacing all logical operators
  const newTree = walk(visitWith(...visitors), cascadeHead.node());
  return action(newTree, pathToHead);
};

const replaceCascadeDates = (loc, dateObj, path, action) => {
  const { fixedRange, timeWindow, startDate, endDate } = dateObj;

  const newDate = fixedRange
    ? EqOp(VarExpr('span'), Const(timeWindow))
    : BetweenOp(VarExpr('dt'), Const(startDate), Const(endDate));

  const currentLocation = TLoc(loc);
  // if we don't have a parent, this is the only node in the tree...just replace this current date.
  // the initial path is to the left hand side of the date expression, but the date expresion
  // being replaced is the right hand side of the date expression, so we need to modify the path
  if (!currentLocation.up()) {
    path.pop();
    path.push('rhs');
    return action(newDate, path);
  }

  // if we are at a date cascade, walk the subtree and replace the dates
  if (isDateCascade(currentLocation)) {
    const visitors = [
      [
        (location) => isDateFilterParent(location),
        (location) =>
          location
            .down()
            .right()
            .replace(newDate)
            .up(),
      ],
    ];
    // the path we want is 2 levels higher in the tree because this replace function
    // is called from a child of the date expr
    return action(
      walk(visitWith(...visitors), currentLocation.up().node()),
      path.slice(0, path.length - 2),
    );
  }

  // if we have parents, but aren't in a cascade, we just have one date dependent filter
  // so we directly replace the date expression
  // same deal here as if no parent, the path is pointing to the wrong side of the date expr
  path.pop();
  path.push('rhs');
  return action(newDate, path);
};

// helper function to extract the required properties of a to-be-replaced node
// and passt them to the new operation
const createReplacement = (op, loc) =>
  op(...values(pick(TLoc(loc).node(), ['lhs', 'rhs', 'order'])));

export const replaceField = (loc, fieldConfig, path, action) => {
  const {
    default_operator,
    newColumnName,
    newColumnValue,
  } = generateNodeConfig(fieldConfig);

  const newNode = TYPE_CONSTRUCTOR_MAP[default_operator](
    VarExpr(newColumnName),
    Const(newColumnValue),
    loc.node().order
  );

  action(newNode, path);
};

// generates config options for node duplication
const generateNodeConfig = (config) => {
  const {
    is_date_dependent,
    default_operator,
    form_input_type,
    mutually_exclusive,
    column,
    params,
    template,
  } = config;

  let newColumnValue;
  switch (form_input_type) {
    case NUMERIC:
      newColumnValue = 0;
      break;
    case DATE:
      newColumnValue = moment.utc().format('YYYY-MM-DD');
      break;
    case BOOLEAN:
      newColumnValue = true;
      break;
    default:
      newColumnValue = '';
      break;
  }

  const initialParams = reduce(
    params,
    (acc, val, key) => set(acc, key, val[0]),
    {},
  );

  let newColumnName = column;
  if (template && params) {
    newColumnName = renderTemplate(template, initialParams);
  }

  return {
    default_operator,
    newColumnName,
    newColumnValue,
    is_date_dependent,
    mutuallyExclusive: mutually_exclusive,
  };
};

// if the current location('s parent) is the head of a cascade, walk down the subtree to replace
// all operations, and then replace the tree in redux. Otherwise, just replace the current
const cascadeReplaceDown = (loc, value, path, action) => {
  const swapNode = curry(createReplacement)(TYPE_CONSTRUCTOR_MAP[value]);
  const parent = TLoc(loc).up();
  const currentNode = loc.node();

  // if this is the first node in the tree OR the this isn't a cascade,
  // skip walking and just replace the tree in redux
  if (!parent || !isCascadeTopNode(parent)) {
    return action(swapNode(loc), path);
  }

  // this handles an edge case where you have two of the same filters, one with duplication
  // ie: 1. buying stage = purchase   (order: 1)
  //     2. buying stage = awareness  (order: 2)
  //        buying stage = decision   (order: null - duplicate)
  // and then trying to change the operation of the first ordered filter
  const rightSideOrder = parent.node().rhs.lhs.order;
  if (rightSideOrder && rightSideOrder !== currentNode.order) {
    return action(swapNode(loc), path);
  }

  // if the above check haven't passed, this is a cascade, walk the subtree (from this node down),
  // replacing all operations and then swap out the old tree in redux;
  const condition = meetsAllConditions([isLogical, not(isDateFilter), not(isDateFilterSibling)]);
  const replacer = (location) => {
    const newLeftLoc = location.down().replace(swapNode(location.down()));
    // if at the end of the cascade, we need to replace both left and right nodes
    if (isLastCascadeNode(location)) {
      const rightReplacement = swapNode(location.down().right());
      const replacedRightNode = newLeftLoc.right().replace(rightReplacement);
      return replacedRightNode.up();
    }
    // otherwise, return the replaced left hand side node('s parent)
    return newLeftLoc.up();
  };

  // since this is triggered from an OpExpr, we want to start any verification from
  // the parent binary expression
  const visitors = [[condition, replacer]];
  const newTree = walk(visitWith(...visitors), parent.node());
  path.pop();
  return action(newTree, path);
};

// replaces operations in for date dependent filters
const replaceDateOperations = (loc, value, path, action) => {
  const swapNode = curry(createReplacement)(TYPE_CONSTRUCTOR_MAP[value]);
  const parent = TLoc(loc).up();
  // because this function is called in the opExpr, the loc at that point(for dates) always
  // has a parent, so the "parent" we need to check for is really the parent of the date
  if (!parent.up()) {
    return action(swapNode(loc), path);
  }

  // if we are at a date cascade, walk the subtree and replace the opexprs in the dates
  if (isDateCascade(parent)) {
    const visitors = [
      [
        (location) => isDateFilterParent(location),
        (location) =>
          location
            .down()
            .replace(swapNode(location.down()))
            .up(),
      ],
    ];
    // the path we want is 2 levels higher in the tree because again, this replace function
    // is called from the opexpr of a date node(a child of the actual node being replaced)
    return action(
      walk(visitWith(...visitors), parent.up().node()),
      path.slice(0, path.length - 2),
    );
  }

  // if we have parents, but aren't in a cascade, we just have one date dependent filter
  // so just replace the current node
  return action(swapNode(loc), path);
};

export {
  duplicateNode,
  removeNode,
  replaceCascadeLogic,
  replaceCascadeDates,
  createReplacement,
  generateNodeConfig,
  cascadeReplaceDown,
  replaceDateOperations,
};
