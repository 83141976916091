import React from 'react';
import { upperCase } from 'lodash';
import Text from 'v2-components/Text';
import { COLUMN_CATEGORIES } from '../constants';
import { NA } from './common/NA';
import { BID_TYPE_KEY, CAMPAIGN_SOURCE, DEFAULT_BID_TYPE } from '../../../constants';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';
import { LINKEDIN_CPC_BIDDING } from 'routes/Advertising/constants';

export const BID_TYPE = {
  width: 110,
  key: BID_TYPE_KEY,
  dataIndex: BID_TYPE_KEY,
  category: COLUMN_CATEGORIES.general,
  csvColumnKey: CAMPAIGN_LABELS.bidType,
  featureFlagKey: LINKEDIN_CPC_BIDDING,
  title: <Label name={CAMPAIGN_LABELS.bidType} />,
  render: (bidType, campaign) => {
    const campaignSource = campaign.campaign_source;
    if (
      campaignSource === CAMPAIGN_SOURCE.EXTERNAL ||
      campaignSource === CAMPAIGN_SOURCE.LINKEDIN
    ) {
      return <NA />;
    }

    return <Text type={Text.TYPE.SUBBODY}>{upperCase(bidType || DEFAULT_BID_TYPE)}</Text>;
  },
};
