import { useSelector } from '@sixsense/core/versioned/react-redux';
import { useMemo } from 'react';
import {
  isAppAdminAssignmentUserPermissionMapSelector,
  primaryAdminIdListSelector,
  adminRoleIdListSelector,
  licenseResponseSelector,
  getRoleByIdSelector,
} from '../selectors';
import { forEach } from 'lodash';

type RoleOption = {
  label: string;
  value: number;
  disabled?: boolean;
};

const useAssignableRoleAppMapOptions = ({
  isAppLicenseUserLimitExceedMap = {},
  enableAdmin = false,
}): { [key: string]: RoleOption[] } => {
  const canSetAdminApp = useSelector(
    isAppAdminAssignmentUserPermissionMapSelector
  );
  const primaryAdminIdList = useSelector(primaryAdminIdListSelector);
  const adminRoleIdList = useSelector(adminRoleIdListSelector);

  const licenseResponse = useSelector(licenseResponseSelector);
  const getRoleById = useSelector(getRoleByIdSelector);

  const roleOptionsAppMap = useMemo(() => {
    const roleOptionsAppMapLocal = {};
    forEach(licenseResponse, ({ app_id: appId, licenses }) => {
      roleOptionsAppMapLocal[appId] = [];
      const disableIds = new Set(primaryAdminIdList);
      if (!canSetAdminApp[appId]) {
        if (!enableAdmin) {
          forEach(adminRoleIdList, (id) => {
            disableIds.add(id);
          });
        }
      }
      forEach(licenses, ({ licenseLabel, role_ids }) => {
        const options = [];
        const disabled =
          ((isAppLicenseUserLimitExceedMap[appId] || {})[licenseLabel])
             || false;
        forEach(role_ids, (roleId) => {
          const role = getRoleById(roleId) || {
            name: Math.random(),
            id: Math.random(),
          };

          options.push({
            label: role.name,
            value: role.id,
            tagLabel: `${role.name} ${licenseLabel}`,
            disabled: disabled || disableIds.has(role.id),
          });
        });
        if (licenseLabel) {
          roleOptionsAppMapLocal[appId].push({
            label: licenseLabel,
            options,
            disabled,
          });
        } else {
          roleOptionsAppMapLocal[appId] = options;
        }
      });
    });
    return roleOptionsAppMapLocal;
  }, [
    canSetAdminApp,
    primaryAdminIdList,
    adminRoleIdList,
    licenseResponse,
    getRoleById,
  ]);
  return roleOptionsAppMap;
};

export default {
  useAssignableRoleAppMapOptions,
};
