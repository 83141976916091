import { reduce, map, find } from 'lodash';
import {
  APPROVAL_NOTIFICATION_TYPES,
  CONTACT_PURCHASE_APPROVAL,
  ORC_ADD_TO_CAMPAIGN,
  APPROVAL_STATUS,
} from './constants';

const additionalState = {
  [CONTACT_PURCHASE_APPROVAL]: { modalVisible: false },
  [ORC_ADD_TO_CAMPAIGN]: { modalVisible: false },
};

export const formatNotification = (notification, notificationTypes) => {
  const { info } = notification;

  // determine notification type. This is required for all notifications.
  // const notificationType = ORC_ADD_TO_CAMPAIGN;
  const notificationType = find(notificationTypes, { display_name: notification.issue })?.name
    || info?.approvalRequest?.orcNotificationMeta?.notificationRequestType;


  // process some alert fields - specific to orchestration's implementation.
  // processing of your alerts will be dependent on how your team has strucutred
  // the notifications.
  if (APPROVAL_NOTIFICATION_TYPES.includes(notificationType)) {
    const approvalState = info.approvalRequest;

    const dataWithState = map(
      approvalState.records,
      // all records come in as pending, but a checkbox does not have 3 states.
      // so set them all as approved
      (d) => ({
        ...d,
        status: d.status === APPROVAL_STATUS.PENDING ? APPROVAL_STATUS.APPROVED : d.status,
      }),
    );

    notification.info.approvalRequest = approvalState; // eslint-disable-line
    notification.info.approvalRequest.records = dataWithState; // eslint-disable-line
  }

  // i am not smart, and can't think what to call this. It is probably
  // specific to orchestration's use case and maybe not something you need to add.
  // What is this? This is the state of the notification that would indicate action
  // is required, or that the notification has already been approved and there is no aaction.
  // This controls opacifying colors and various state pieces in the detail views.
  //
  // At the notification level, this controls the opacity of cards, and whether or not
  // a notification can be dismissed.
  const noAction = APPROVAL_NOTIFICATION_TYPES.includes(notificationType)
    && info?.approvalRequest?.approval_state !== 'PENDING';

  return {
    ...notification,
    ...additionalState[notificationType],
    notificationType,
    noAction,
  };
};


/*
 * - Transforms notification list array into flat object of { [key: id]: Notification }
 * - Adds in the required notification type based STATE properties.
 *
 * *IMPORTANT*: state transormation(for display, formatting, etc) should still
 * be handled by a selector. This function only adds missing state.
*/
export const formatNotifications = (notifications, notificationTypes) => reduce(
  notifications,
  (formattedNotificationList, currentNotification) => {
    /* eslint-disable */
    formattedNotificationList[currentNotification.id] = formatNotification(currentNotification, notificationTypes);
    /* eslint-enable */
    return formattedNotificationList;
  },
  {},
);

// can't link to specific alerts wihout doing this. Looooo0000000lllll
// this will be in place until alerts service includes the category with the response
export const temporaryHackTranslationToListShape = (notification, notificationTypes) => {
  const aVeryDumbSolution = {
    ...notification?.history[0],
    id: notification?.history[0].alert,
    issue: 'Add Accounts To 6sense Advertising Campaigns',
    info: JSON.parse(notification.history[0].data).info,
  };
  return formatNotifications([aVeryDumbSolution], notificationTypes);
};
