import { get, toLower, includes, pickBy, isArray, join, mapValues, isEmpty } from 'lodash';
import {
  UTM_FORM_FIELDS,
  DEFAULT_UTM_SOURCE,
  MANUAL,
  UTM_BUILDER_FIELDS,
  UTM_QUERY_PARAMS,
 } from './constants';

export const filterOptions = (inputValue, option) => {
  const lowerOptionLabel = toLower(get(option, 'props.label', ''));
  const lowerInputValue = toLower(inputValue);
  return includes(lowerOptionLabel, lowerInputValue);
};

export const formatDynamicMacrosValue = (value) => value || [];

export const normalizeDynamicMacroValue = (value) => isEmpty(value) ? '' : value;

export const appendStringType = (str) => `${str}Type`;

export const getInitialFormValues = (url) => ({
  [UTM_FORM_FIELDS.UTM_SOURCE]: DEFAULT_UTM_SOURCE,
  [UTM_FORM_FIELDS.CLICK_URL]: url,
  ...UTM_BUILDER_FIELDS.reduce((acm, { name }) => {
    const acmCopy = { ...acm };
    acmCopy[appendStringType(name)] = MANUAL;
    return acmCopy;
  }, {}),
});


export const getSubmittedUtmQueryParamsValues = (formValues) => {
  const utmQueryParamValueMap = {
    [UTM_QUERY_PARAMS.UTM_SOURCE]: get(formValues, UTM_FORM_FIELDS.UTM_SOURCE),
    [UTM_QUERY_PARAMS.UTM_MEDIUM]: get(formValues, UTM_FORM_FIELDS.UTM_MEDIUM),
    [UTM_QUERY_PARAMS.UTM_CAMPAIGN]: get(formValues, UTM_FORM_FIELDS.UTM_CAMPAIGN),
    [UTM_QUERY_PARAMS.UTM_TERM]: get(formValues, UTM_FORM_FIELDS.UTM_TERM),
    [UTM_QUERY_PARAMS.UTM_CONTENT]: get(formValues, UTM_FORM_FIELDS.UTM_CONTENT),
  };
  const filteredUtmQueryParamValueMap = pickBy(utmQueryParamValueMap, (value) => !!value);

  return mapValues(
    filteredUtmQueryParamValueMap,
    (value) => isArray(value) ? join(value, ',') : value
  );
};

export const invalidRepetationOfUtmParams = (generatedUrl) => {
  const utm_keys= Object.keys(UTM_QUERY_PARAMS);
  try {
    const url = new URL(generatedUrl);
    const params = new URLSearchParams(url.search);
    return utm_keys.reduce((previousValue, currentValue) => (
      previousValue || params.getAll(UTM_QUERY_PARAMS[currentValue]).length > 1
    ), false);
  } catch (error) {
    return false;
  }
};
