import React from 'react';
import { Alert, Text } from 'v2-components';
import { css } from 'styles/emotion';
import googleAdsLogo from 'images/google-ads-logo.png';

export const GOOGLE_ADS_INTEGRATION_ROUTE = 'googleAdsIntegration';

export const ACCOUNT_STATUSES = {
  ENABLED: 'ENABLED',
  CANCELED: 'CANCELED',
  SUSPENDED: 'SUSPENDED',
  CLOSED: 'CLOSED',
  ACCESS_DENIED: 'ACCESS_DENIED',
};

export const ACCOUNT_STATUS_DEFINITIONS = {
  [ACCOUNT_STATUSES.ENABLED]: 'Indicates an active account able to serve ads.',
  [ACCOUNT_STATUSES.CANCELED]: `Indicates a canceled account unable to serve ads. 
    Can be reactivated by Google ads account admin user.`,
  [ACCOUNT_STATUSES.SUSPENDED]: `Indicates a suspended account unable to serve ads. 
    May only be activated by Google support.`,
  [ACCOUNT_STATUSES.CLOSED]: `Indicates a closed account unable to serve ads. 
  Test account will also have CLOSED status. Status is permanent and may not be reopened.`,
};

export const ACCOUNT_STATUS_TOOLTIP = {
  [ACCOUNT_STATUSES.CANCELED]: `Your ad account is canceled and is unable to serve ads. 
  May only be activated by google support.`,
  [ACCOUNT_STATUSES.SUSPENDED]: `Your ad account is suspended and is unable to serve ads. 
  May only be activated by google support.`,
  [ACCOUNT_STATUSES.CLOSED]: `Your ad account is closed and is unable to serve ads. 
  May only be activated by google support.`,
  [ACCOUNT_STATUSES.ACCESS_DENIED]: 'You don\'t have access to this account',
};

export const GoogleConfig = {
  header: {
    logo: googleAdsLogo,
    mainTitle: '6sense Segments for Google Ads',
    category: 'Ads',
    subTitle: 'by Google.Inc',
  },
  about: {
    mainInfo: {
      title: 'About this Integration',
      description: `The 6sense integration with Google Ads allows you to target buyers across 
      Google Ad properties by identifying matching first-party contacts (from MAP & CRM) 
      from the selected 6sense segment.`,
    },
    metaInfo: [
      {
        name: 'Category',
        value: 'Ads',
      },
      {
        name: 'Feature Support',
        value: 'Advertising, Segments',
      },
    ],
    keyfeatures: {
      title: 'Key Features',
      features: [
        {
          name: 'Target the best accounts',
          value: <React.Fragment>
            Create dynamic account segments by leveraging AI-driven ICP fit predictions to target
            <br />in-market accounts.
            </React.Fragment>,
        },
        {
          name: 'Orchestrate Account Engagement',
          value: `Use first-party customer data, such as mailing address, phone number, or 
            alternate email to target the people that matter the most.`,
        },
        {
          name: 'Automate Workflows and Improve Efficiency ',
          value: <React.Fragment>
            Reach buyers across various Google properties, such as YouTube, Gmail, Google Search,
             <br />and Google Display Network.
            </React.Fragment>,
        },
      ],
    },
    footer: (
      <Alert
        className={css({ width: '100%' })}
        type="info"
        description={
          <React.Fragment>
            <Text bold>IMPORTANT:&nbsp;</Text>
            <Text>
              This integration requires MAP and/or CRM connected in order to
              sync segments with Google. Please make sure you review the
              FAQ before enabling this integration.
            </Text>
          </React.Fragment>
        }
      />
    ),
  },
  help: {
    links: [
      {
        title: 'Additional Resources',
        list: [
          {
            /* eslint-disable max-len */
            href: 'https://support.6sense.com/knowledge-base/8899607551763-6sense-segments-for-google-ads-beta/',
            text: 'How to connect Google account?',
          },
          {
            href: 'https://support.6sense.com/hc/en-us/articles/8472281573139',
            text: 'FAQs: 6sense Segments for Google Ads',
          },
        ],
      },
    ],
  },
};
