import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: 'contactOrchestration',
    name: 'Contact Orchestration',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const sagas = require('./sagas');
          const reducer = require('./modules');
          injectReducer('contactOrchestration', reducer.default);
          injectSagas('contactOrchestration', sagas.default);
          cb(null, containers.ContactOrchestrationContainer);
        },
        'contact-purchase',
      );
    },
  };
}
