/* eslint-disable max-len */

import { TCreditConfig } from './types';

export const TOTAL_CREDIT_POOL_LABELS = [
  {
    label: 'Total',
    key: 'total_credits',
  },
  {
    label: 'Used',
    key: 'total_used_credits',
  },
  {
    label: 'Distributed Credits',
    key: 'total_allocated_credits',
  },
  {
    label: 'Not Distributed',
    key: 'total_unallocated_credits',
  },
  {
    label: 'Expires',
    key: 'expiry_date',
  },
  {
    label: 'Last Updated',
    key: 'updated',
  },
];

export const SI_CREDIT_POOL_LABELS = [
  {
    label: 'Total',
    key: 'total_credits',
  },
  {
    label: 'Used',
    key: 'total_used_credits',
  },
  {
    label: 'Allocated for Users',
    key: 'total_allocated_credits',
  },
  {
    label: 'Unallocated',
    key: 'total_unallocated_credits',
  },
];
export const WF_CREDIT_POOL_LABELS = [
  {
    label: 'Total',
    key: 'total_credits',
  },
  {
    label: 'Used',
    key: 'total_used_credits',
  },
  {
    label: 'Balance',
    key: 'total_balance_credits',
  },
];

export const STATUS_LABEL_MAP = {
  enabled: 'Enabled',
  disabled: 'Disabled',
  invited: 'Invited',
  inviteExpired: 'Expired Invite',
};

export const STATUS_FILTER_OPTIONS = [
  { label: 'All Status', value: 'all' },
  { label: 'Enabled', value: 'enabled' },
  { label: 'Disabled', value: 'disabled' },
];

export const SAML_STATUS_FILTER_OPTIONS = [
  { label: 'All Status', value: 'all' },
  { label: 'Enabled', value: 'enabled' },
  { label: 'Disabled', value: 'disabled' },
];

export const CREDIT_MSGS = {
  ADD_CREDIT_ERR_MSG: 'You have added more credits than available to allocate',
  UPDATE_CREDIT_ERR_MSG:
    'You have added more credits than available to allocate',
  REMOVE_CREDIT_ERR_MSG: 'You cannot remove more credits than are available',
  DISTRIBUTE_CREDIT_REQUIRED: 'This field is required',
  DISTRIBUTE_CREDIT_MAX_ERR: 'You cannot distribute more credits than the available {total}',
  DISTRIBUTE_CREDIT_MIN_ERR: 'You have to allocate atleast one total credit',
  CREDIT_GREATER_THAN_ZERO: 'Use only positive, whole numbers',
  INVALID_ERROR_MSG: 'Use only positive, whole numbers',
  CREDITS_ADDED_MSG: 'Credits added',
  CREDITS_BY_APPLICATION: 'Credits by Application',
  CREDITS_UPDATED_MSG: 'Credits updated',
  CREDITS_DISTRIBUTION_ERR: 'Error distributing credits',
  CREDITS_FETCHING_USERS: 'Error fetching users',
  CREDITS_ERR_FETCHING_DATA: 'Error fetching credit pools. Please try again.',
  CREDITS_INVALID_POOL_SELECTED: 'Invalid credit pool selected. Please try again.',
  CREDIT_NOT_ALLOTED: 'Credits are not allocated to your organization. Please reach out to your CSM for credit allocation!',
  CREDIT_USAGE_DISABLED: 'Please contact your CSM to complete required contract documents to enable credit allocation.',
};

export const BALANCE_FILTER_TYPES = {
  ALL: 'all',
  BELOW: 'below',
  ABOVE: 'above',
};

export const DISABLED_STATUS = 'disabled';

export const TOOLTIP_MSGS = {
  LITE: 'Credits cannot be allocated to users with an Enterprise Lite license',
  DISABLED: 'User no longer have access to SI app',
  CREDIT_POOL_EXPIRED_STATUS: 'Expired Pool user Credit Allotment  - Credit pool expired, select an active pool to allot credits to users',
  SALES_INFO: `Total shows the total credits distributed from the account pool.
  Used shows credits that are already used in Sales Intelligence.
  Allocated for Users are credits that are assigned to specific users.
  Unallocated shows credits still available for allocation to users.`,
  TOTAL_INFO: `This card shows the total credits purchased for all 6sense
  apps, how many were distributed to apps, the remaining
  credits available for distribution to apps, and key dates.`,
  DWF_INFO: `Total shows the total credits distributed from the account pool.
  Used shows credits that are already used in Data Workflows.
  Balance shows remaining unused credits.`,
  AWF_INFO: `Total shows the total credits distributed from the account pool.
  Used shows credits that are already used in ABM (Audience Workflows).
  Balance shows remaining unused credits.`,
};

export const LICENSE_TYPES = {
  LITE: 'LITE',
};

export const LICENSE_TYPES_LABEL = {
  FULL: 'Enterprise',
  LITE: 'Enterprise Lite',
};


export const creditTypeProperties = {
  total_unallocated_credits: 0,
  id: 0,
  total_allocated_credits: 0,
  total_balance_credits: 0,
  total_used_credits: 0,
  updated: '',
  created: '',
  is_deleted: false,
  org_id: 0,
  total_credits: 0,
  start_date: '',
  expiry_date: '',
  created_by: '',
  updated_by: '',
  message: '',
  is_expired: false,
  wf_org_credits_id: 0,
};

export const STATUS_MAPPER = {
  enabled: ['enabled', 'invited'],
  disabled: ['disabled', 'inviteExpired'],
};


export const CREDIT_PAGE_CONFIG: TCreditConfig[] = [
  {
    sections: TOTAL_CREDIT_POOL_LABELS,
    label: 'Total Account Credits',
    tooltip: TOOLTIP_MSGS.TOTAL_INFO,
    appCreditType: 'global',
    isDisabled: false,
  },
  {
    sections: SI_CREDIT_POOL_LABELS,
    label: 'Sales Intelligence Credits', // card label
    distributeFieldName: 'sales_intelligence', // input fieldName in distribute drawer
    distributeFieldLabel: 'Sales Intelligence', // input label in distribute drawer
    tooltip: TOOLTIP_MSGS.SALES_INFO, // tooltip for the card label
    appCreditType: 'sales_intelligence', // app credit pool type in the BE entry
    isDisabled: false,
  },
  {
    sections: WF_CREDIT_POOL_LABELS,
    label: 'Data Workflows Credits',
    distributeFieldName: 'data_workflows',
    distributeFieldLabel: 'Data Workflows',
    tooltip: TOOLTIP_MSGS.DWF_INFO,
    appCreditType: 'data_workflows',
    isDisabled: false,
  },
  {
    sections: WF_CREDIT_POOL_LABELS,
    label: 'ABM (Audience Workflows) Credits',
    distributeFieldName: 'audience_workflows',
    distributeFieldLabel: 'ABM (Audience Workflows)',
    tooltip: TOOLTIP_MSGS.AWF_INFO,
    appCreditType: 'audience_workflows',
    isDisabled: false,
  },
];
