import React, { useState } from 'react';
import { Flex } from '@sixsense/core/components';
import { Button, Modal, Text, Loading } from 'v2-components';
import { useOrg } from 'hooks';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { actions as globalActions } from 'modules/global';
import { useRequest, useSharedValue, useSharedState, useSetSharedValue } from '@sixsense/core';
import { maybePlural } from 'utils/utils';
import { activePageState, keywordGroupSelectQueryState } from '../../state';
import { getConfigState, resetConfig, getUpdatedConfig } from '../../utils';
import { GroupSelect } from '../';
import { NEW_GROUP } from '../../constants';

type Props = {
  onCancel: () => void;
  action: string;
  keywords?: Number[];
  setCreateGroupVisible: (Boolean) => void;
};

const MoveToGroupModal = ({
  onCancel,
  action,
  keywords,
  setCreateGroupVisible,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const org = useOrg();
  const activePage = useSharedValue(activePageState);
  const state = getConfigState(activePage, false);
  const [config, setConfig] = useSharedState(state);
  const setGroupSelectQuery = useSetSharedValue(keywordGroupSelectQueryState);

  const request = useRequest();
  const dispatch = useDispatch();

  const onClose = () => {
    onCancel();
    setGroupSelectQuery(undefined);
  };

  // Product_id is 0 since it is unused in the bulk_update calls
  const requestUrl = `organization/${org.id}/product_keyword/${0}/keyword/`;
  const body = {
    action: 'add_to',
    keyword_ids: keywords,
    config: {
      location: 'existing_group',
      group_name: null,
      group_ids: selectedGroups,
      target_product_ids: null,
    },
  };
  let action_word = 'adding';
  let action_word_past = 'added';

  if (action === 'Move') {
    body.action = 'move_to';
    action_word = 'moving';
    action_word_past = 'moved';
  }

  const moveToGroup = async () => {
    setLoading(true);
    try {
      await request(requestUrl, {
        method: 'PATCH',
        body: JSON.stringify(body),
      });
      dispatch(globalActions.showNotification('success',
      `Successfully ${action_word_past} to ${maybePlural(selectedGroups.length, 'group')}`));
      onClose();
      const updatedConfig = getUpdatedConfig(config);
      resetConfig(updatedConfig, setConfig);
    } catch (e) {
      let errorMessage = `There was an issue ${action_word} to
                            ${maybePlural(selectedGroups.length, 'group')}.
                            Please try again later.`;
      if (e.errorStatus === 400) {
        const respBody = e.body;
        errorMessage = respBody.detail;
      }
      dispatch(globalActions.showNotification('error', errorMessage));
    }
    setLoading(false);
  };

  const footer = (
    <Flex justifyContent="flex-end" style={{ padding: '10px 0' }}>
      <Button
        pointer
        onClick={() => onClose()}
      >
        Cancel
      </Button>
      <Button
        pointer
        className="aam-l--10"
        type={'primary'}
        onClick={() => {
          moveToGroup();
        }}
        disabled={loading || !selectedGroups.length}
      >
        {`${action} here`}
      </Button>
      {loading && (
        <Loading
          size="small"
          spinnerWidth={15}
          spinnerHeight={15}
          pageLevel={false}
          className="aam-l--10"
        />
      )}
    </Flex>
  );

  return (
    <Modal
      visible
      bodyStyle={{ padding: 0 }}
      footer={footer}
      maskClosable={false}
      closable={false}
      width={600}
    >
      <Flex style={{ padding: '20px', borderBottom: '1px solid #e9e9e9' }}>
        <Text type={Text.TYPE.TITLE} bold>
          {action} {keywords.length} {maybePlural(keywords.length, 'Keyword')}{' '}
          to existing keyword groups
        </Text>
      </Flex>
      <Flex style={{ padding: '20px' }} direction="column">
        <Text bold>Select Keyword Groups</Text>
        <GroupSelect
          groups={selectedGroups}
          setGroups={(val) => setSelectedGroups(val.filter((v) => v !== NEW_GROUP))}
          setCreateGroupModalVisible={setCreateGroupVisible}
          noCreate
        />
      </Flex>
    </Modal>
  );
};

export default MoveToGroupModal;
