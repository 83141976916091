import { createSelector } from 'reselect';
import { orgSelector } from 'modules/user/selectors';
import { AD_LIBRARY_CLASSIFICATION_TYPES, STATE_KEY } from './constants';

const baseSelector = (state) => state.advertising[STATE_KEY];
const foldersSelector = (state) => baseSelector(state).classifications.data;
const classificationModalSelector = (state) => baseSelector(state).adLibraryclassificationsModal;

const classificationEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `org/${orgId}/placement_classification/`
);

const fetchClassificationEndpointSelector = createSelector(
  classificationEndpointSelector,
  (classificationEndpoint) => `${classificationEndpoint}?offset=0&limit=500`
);

const createClassificationPayloadSelector = createSelector(
  (_, action) => action,
  (action) => ({
    body: JSON.stringify({
      name: action.payload,
      is_deleted: false,
      classification_type: AD_LIBRARY_CLASSIFICATION_TYPES.FOLDER,
    }),
  }),
);

const changeClassificationEndpointSelector = createSelector(
  [classificationEndpointSelector, (_, action) => action],
  (folderEndpoint, action) => `${folderEndpoint}${action.payload.folder.id}/`
);

const changeClassificationPayloadSelector = createSelector(
  (_, action) => action,
  (action) => ({
    body: JSON.stringify({
      placement_ids: action.payload.itemIds,
    }),
  }),
);

const existingFolderNamesSelector = createSelector(
  foldersSelector,
  (folders) => folders.map((folder) => folder.name)
);

const adLibraryClassificationModalSelector = createSelector(
  classificationModalSelector,
  (classificationsModal) => classificationsModal
);

const bulkDeleteClassificationEndpointSelector = createSelector(
  classificationEndpointSelector,
  (classificationEndpoint) => `${classificationEndpoint}bulk_delete/`,
);

export const unsortedFolderSelector = createSelector(
  foldersSelector,
  (folders) =>
    folders.find(
      (folder) => folder.classification_type === AD_LIBRARY_CLASSIFICATION_TYPES.UNSORTED_FOLDER
    )
);


export const selectors = {
  baseSelector,
  classificationEndpointSelector,
  fetchClassificationEndpointSelector,
  createClassificationPayloadSelector,
  changeClassificationEndpointSelector,
  changeClassificationPayloadSelector,
  existingFolderNamesSelector,
  adLibraryClassificationModalSelector,
  foldersSelector,
  bulkDeleteClassificationEndpointSelector,
  unsortedFolderSelector,
};
