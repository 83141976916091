import { promptSettingsSelector } from 'modules/global/selectors';
import { actions } from 'modules/global';

const { promptOnLeave, clearPromptOnLeave } = actions;

export const stopTransition = (store) => (next) => (action) => {
  const {
    promptWhen,
    promptTransition,
    keepAfterTransition,
    onConfirmLeave,
  } = promptSettingsSelector(store.getState());

  if (action.type === '@@router/LOCATION_CHANGE' && promptTransition) {
    const destination = () => next(action);
    const clear = () => next(clearPromptOnLeave());
    const toDestination = () => {
      clear();
      destination();
    };

    const shouldPrompt = promptWhen(store.getState(), action.payload);

    if (shouldPrompt) {
      store.dispatch(promptOnLeave(toDestination, onConfirmLeave));
    } else if (keepAfterTransition) {
      // Keep the prompt but change location
      destination();
    } else {
      // Remove prompt
      toDestination();
    }
  } else {
    next(action);
  }
};
