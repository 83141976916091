import { fetchSaga } from 'store/sagas';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions, actionTypes } from '.';
import { orgSelector } from 'modules/user/selectors';
import { actions as IntegrationActions }
from '../../routes/Settings/routes/Integration/modules';
import { DEFAULT_TABS, VIEW_MODES } from '../../routes/Settings/routes/Integration/constants';
import { loadGenericIntgrationStatusGenerator }
from '../../routes/Settings/routes/Integration/stateGenerators';
import { integrationStatusManageSelector }
from '../../routes/Settings/routes/Integration/routes/Manage/selectors';
import { isEmpty } from 'lodash';
import { integrationNameToIDMap } from 'routes/Settings/routes/Integration/routes/Manage/constants';


const { REQUEST_INTEGRATION,
  REQUEST_INTEGRATION_STATE,
  REQUEST_TOKEN_STATE_SUCCESS,
  REQUEST_TOKEN_STATE_ERROR,
  REQUEST_INTEGRATION_ERROR,
  REQUEST_INTEGRATION_SUCCESS,
 } = actionTypes;
const {
  requestIntegrationSuccess,
  requestIntegrationError,
  requestTokenStateError,
  requestTokenStateSuccess,
} = actions;

const {
  setTabs,
  setViewMode,
} = IntegrationActions;

function* requestIntegrationSaga(request) {
  const orgId = yield select(orgSelector);
  const endpoint = `slack/${orgId}/request_authorization`;
  try {
    const resp = yield call(request, endpoint, 'POST');
    const redirect_url = resp.redirect_url;
    window.open(redirect_url);
    yield put(requestIntegrationSuccess());
  } catch (e) {
    yield put(requestIntegrationError());
  }
}

function* requestTokenStateSaga(request) {
  const integration_status = yield select(
    integrationStatusManageSelector('slack'));
  if (isEmpty(integration_status)) {
    yield put(loadGenericIntgrationStatusGenerator.loadAction(integrationNameToIDMap.slack));
  }
  const orgId = yield select(orgSelector);
  const endpoint = `slack/${orgId}/token_state`;
  try {
    const resp = yield call(request, endpoint, 'GET');
    yield put(requestTokenStateSuccess({
      state: resp.state, workspace: resp.workspace,
    }));
  } catch (e) {
    yield put(requestTokenStateError());
  }
}

function* updateSummaryHOC(_request, action) {
  switch (action.type) {
    case REQUEST_INTEGRATION_SUCCESS :
    case REQUEST_TOKEN_STATE_SUCCESS :
      {
        const tabs = [{
          value: 'manage',
          label: 'Manage',
          default: true,
        }].concat(DEFAULT_TABS.map((tab) => ({
          ...tab,
          default: false,
        })));
        yield put(setTabs(tabs));
        yield put(setViewMode(VIEW_MODES.SUMMARY));
        break;
      }
    case REQUEST_TOKEN_STATE_ERROR:
    case REQUEST_INTEGRATION_ERROR:
      {
        yield put(setTabs(DEFAULT_TABS));
        yield put(setViewMode(VIEW_MODES.SUMMARY));
        break;
      }
    default:
      break;
  }
}

function* watchRequestIntegration(request) {
  yield takeEvery(REQUEST_INTEGRATION, requestIntegrationSaga, request);
}

function* watchRequestTokenState(request) {
  yield takeEvery(REQUEST_INTEGRATION_STATE, requestTokenStateSaga, request);
}

function* watchTokenChanges(request) {
  yield takeLatest([REQUEST_TOKEN_STATE_SUCCESS,
    REQUEST_INTEGRATION_SUCCESS,
    REQUEST_TOKEN_STATE_ERROR,
    REQUEST_INTEGRATION_ERROR,
  ],
    updateSummaryHOC, request);
}

export default [
  fetchSaga(watchRequestIntegration),
  fetchSaga(watchRequestTokenState),
  watchTokenChanges,
  loadGenericIntgrationStatusGenerator.saga,
];
