const SIXSENSE_NETWORK = '6sense_network';
const SAAS_SEGMENT = 'saas';
const FILE_UPLOAD = 'file_upload';
const PROGRAMMATIC = 'programmatic';
const LOOKALIKE_ACCOUNTS = 'lookalike_accounts';
const EXTERNAL_LIST = 'external_list';
const CRM_ACCOUNT = 'crm_account';
const CRM_REPORT = 'crm_report';
const LOOKALIKE_EXTERNAL = 'lookalike_external';
const ZENIQ_TAL = 'zeniq_tal';
const KEYWORD_GROUP_COL = 'keyword_group';
const LOOKALIKE_TYPES = new Set([LOOKALIKE_ACCOUNTS, LOOKALIKE_EXTERNAL]);
/*
  With the new dynamics integration, we now need to check for the org's crm_type field
  to make sure we grab the right crm account lists or reports :)
*/
const SALESFORCE_INT = 'Salesforce';
const DYNAMICS_INT = 'Dynamics';

const AST_KEY = 'create_edit_segment';
const CREATE_KEY = 'create_segment_upload_id';

// CRM Sources
const SALESFORCE = 'Salesforce';
const DYNAMICS = 'Dynamics';
const HUBSPOT_CRM = 'HubspotCrm';
// MAP Sources
const MARKETO = 'Marketo';
const ELOQUA = 'Eloqua';
const PARDOT = 'Pardot';
const HUBSPOT_MAP = 'Hubspot';


export {
  SIXSENSE_NETWORK,
  FILE_UPLOAD,
  SAAS_SEGMENT,
  LOOKALIKE_ACCOUNTS,
  EXTERNAL_LIST,
  LOOKALIKE_EXTERNAL,
  AST_KEY,
  CREATE_KEY,
  LOOKALIKE_TYPES,
  ZENIQ_TAL,
  PROGRAMMATIC,
  SALESFORCE_INT,
  DYNAMICS_INT,
  CRM_ACCOUNT,
  CRM_REPORT,
  SALESFORCE,
  DYNAMICS,
  HUBSPOT_CRM,
  MARKETO,
  ELOQUA,
  PARDOT,
  HUBSPOT_MAP,
  KEYWORD_GROUP_COL,
};
