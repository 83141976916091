import React from "react";
import { Text } from "@sixsense/core/components";
import { CAMPAIGN_SOURCE } from "../../../constants";
import { numberToDollar } from "utils/utils";
import { NA } from "./common/NA";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const BUDGET = {
  sortByKey: "budget",
  key: "budget",
  csvColumnKey: "budget",
  defaultSelected: true,
  title: <Label name={CAMPAIGN_LABELS.budget} />,
  dataIndex: "budget",
  width: pxToRem(110),
  render: (budget, campaign) => {
    const { campaign_source } = campaign;
    if (campaign_source === CAMPAIGN_SOURCE.EXTERNAL) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {numberToDollar(budget, { insertCommas: true })}
      </Text.Body>
    );
  },
  align: "right",
};
