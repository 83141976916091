export const CAMPAIGN_CSV_EXPORT_DRAWER_STATE_KEY = "campaignCSVExportDrawer";

export const GROUP_BY_VALUES = {
  campaigns: "campaign",
  campaignsAdGroup: "campaign, ad_group",
  campaignsAdAdGroups: "campaign, ad_group, ad",
};

export const GROUP_BY_OPTIONS = [
  {
    label: "Campaign Name",
    value: GROUP_BY_VALUES.campaigns,
    "data-pendo": "campaign_list-download-group_by-campaign",
  },
  {
    label: "Campaign and Ad Group",
    value: GROUP_BY_VALUES.campaignsAdGroup,
    "data-pendo": "campaign_list-download-group_by-campaign_ad_group",
  },
  {
    label: "Campaign, Ad Group and Ad",
    value: GROUP_BY_VALUES.campaignsAdAdGroups,
    "data-pendo": "campaign_list-download-group_by-campaign_ad_group_ad",
  },
];

export const TIME_BREAKDOWN_VALUES = {
  cumulative: "cumulative",
  week: "week",
  month: "month",
  day: "date",
};

export const TIME_BREAKDOWN_OPTIONS = [
  { label: "Cumulative", value: TIME_BREAKDOWN_VALUES.cumulative },
  {
    label: "Analytics distribution by month",
    value: TIME_BREAKDOWN_VALUES.month,
  },
  {
    label: "Analytics distribution by week",
    value: TIME_BREAKDOWN_VALUES.week,
  },
  { label: "Analytics distribution by day", value: TIME_BREAKDOWN_VALUES.day },
];

export const ROW_DATA_TYPES = {
  campaign: "campaign",
  adGroup: "adGroup",
  ad: "ad",
  aggregated: "aggregated",
};

export const ANALYTICS_TYPE = {
  campaign: "campaigns",
  accounts: "accounts",
};

export const ACCOUNTS_TYPE = {
  sixSense: "sixSense",
  crm: "crm",
};

export const ANALYTICS_TYPE_OPTIONS = [
  {
    label: "6sense Accounts",
    value: ACCOUNTS_TYPE.sixSense,
  },
  {
    label: "CRM Accounts",
    value: ACCOUNTS_TYPE.crm,
  },
];
