import React from "react";
import { PropTypes } from "prop-types";
import { Dropdown } from "@sixsense/core/components";
import { Archive } from "@sixsense/core/icons";

export const ArchiveCampaignActionItem = ({ isArchiveDisabled, onClick }) => (
  <Dropdown.Menu.Item
    id="archive-campaign"
    key="archive-campaign"
    onClick={onClick}
    disabled={isArchiveDisabled}
    data-6si-id="campaign_list-action_dropdown-archive"
    data-testid="campaign_list-action_dropdown-archive"
    before={<Archive />}
  >
    Archive
  </Dropdown.Menu.Item>
);

ArchiveCampaignActionItem.propTypes = {
  isArchiveDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ArchiveCampaignActionItem;
