import React from "react";
import googleLogo from "../../images/google_ads.svg";
import linkedInLogo from "../../images/linkedin.svg";
import metaLogo from "../../images/meta.svg";
import { Text } from "@sixsense/core/components";
import { RootColor } from "@sixsense/core/style/variables";

const SEGMENT_SYNCS_ROUTE = "segment-syncs";
const PAGE_STATE_KEY = "segmentSyncs";
const DEFAULT_TABLE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 1;

const SYNC_STATES = {
  syncing: {
    label: "syncing",
    type: "primary",
  },
  synced: {
    label: "synced",
    type: "success",
  },
  inactive: {
    label: "inactive",
    type: "gray",
  },
  failed: {
    label: "failed",
    type: "error",
  },
};

const GOOGLE_AD_PROVIDER = "google_ads";

const AD_NETWORKS = {
  google: "google",
  linkedin: "linkedin",
  facebook: "facebook",
  [GOOGLE_AD_PROVIDER]: "google",
};

const AD_NETWORK_ICONS = {
  [AD_NETWORKS.facebook]: metaLogo,
  [GOOGLE_AD_PROVIDER]: googleLogo,
  [AD_NETWORKS.linkedin]: linkedInLogo,
};

const INTEGRATION_CARD_DATA_MAPPING = [
  {
    label: "LinkedIn",
    logo: linkedInLogo,
    disabledLogo: null,
    key: AD_NETWORKS.linkedin,
    adNetwork: AD_NETWORKS.linkedin,
  },
  {
    label: "Meta",
    logo: metaLogo,
    disabledLogo: null,
    key: AD_NETWORKS.facebook,
    adNetwork: AD_NETWORKS.facebook,
  },
  {
    label: "Google Ads",
    logo: googleLogo,
    key: GOOGLE_AD_PROVIDER,
    adNetwork: AD_NETWORKS.google,
  },
];

export const GOOGLE_ADS_INTEGRATION_ROUTE = "googleAdsIntegration";
export const LINKEDIN_ADS_INTEGRATION_ROUTE = "linkedin-campaign-manager/setup";
export const FACEBOOK_ADS_INTEGRATION_ROUTE =
  "facebook-ads-manager/integration";

export const SOCIAL_INTEGRATION_SETTINGS_PAGE = {
  [AD_NETWORKS.google]: `/settings/integration/${GOOGLE_ADS_INTEGRATION_ROUTE}`,
  [GOOGLE_AD_PROVIDER]: `/settings/integration/${GOOGLE_ADS_INTEGRATION_ROUTE}`,
  [AD_NETWORKS.linkedin]: `/settings/integration/${LINKEDIN_ADS_INTEGRATION_ROUTE}`,
  [AD_NETWORKS.facebook]: `/settings/integration/${FACEBOOK_ADS_INTEGRATION_ROUTE}`,
};

const INTEGRATION_STATUS = {
  ACTIVE: "active",
  ERROR: "error",
  INACTIVE: "inactive",
  RATE_LIMITED: "rate_limited",
};
const INTEGRATION_STATUS_COLOR = {
  [INTEGRATION_STATUS.ACTIVE]: "success",
  [INTEGRATION_STATUS.ERROR]: "error",
  [INTEGRATION_STATUS.INACTIVE]: "gray",
  [INTEGRATION_STATUS.RATE_LIMITED]: "warning",
};

const INTEGRATION_STATUS_LABEL = {
  [INTEGRATION_STATUS.ACTIVE]: "Active",
  [INTEGRATION_STATUS.ERROR]: "Error",
  [INTEGRATION_STATUS.INACTIVE]: "Inactive",
  [INTEGRATION_STATUS.RATE_LIMITED]: "Rate Limited",
};

export const GOOGLE_INTEGRATION_DISABLED = (
  <React.Fragment>
    <Text.Body weight="normal" color={`var(${RootColor.Black})`}>
      Google integration requires customers to sign the updated terms to comply
      with CCPA regulations. Please connect with your CSM for guidance.
    </Text.Body>
  </React.Fragment>
);

const SEGMENT_SYNC_TOOLTIPS = {
  ZERO_ACCOUNTS: "Segment with zero accounts cannot be synced.",
  ZERO_CONTACTS: "Segment with zero estimated contacts cannot be synced.",
  MORE_THAN_MILLION_ACCOUNTS:
    "Segment with more than 1M accounts cannot be synced",
};

const AD_ACCOUNT_DISABLED_TOOLTIP = `To sync the segment, make sure your ad account 
is enabled in Integrations.`;

// TODO: Get the wordings from Sonia
const NOT_SIGNED_IN_TOOLTIP = `You need to be signed in to sync/ unsync.`;

const AD_ACCOUNT_ACCESS_DENIED_TOOLTIP =
  "You don't have access to this ad account.";

const AD_ACCOUNT_NOT_SELECTED_TOOLTIP =
  "This ad account has not been selected for syncing.";

const AD_ACCOUNT_SYNCING_TOOLTIP = `Segment is in syncing state, try after sync 
is completed`;
const SEGMENT_SYNCS_TOGGLE_STATUS = {
  ON: "On",
  OFF: "Off",
};

const SOCIAL_SEGMENT_SYNC_STATUS_KEY = "ad_network_segment_sync_status";

const ALERT_ERROR_CODE = {
  INT0003: "INT0003", // Invalid Access Token
  INT0001: "INT0001", // Access Restricted
  INT0002: "INT0002", // Refresh token about to expire
  SYNC0001: "SYNC0001", // Insufficient User Permission ( Ad account access missing )
  SYNC0002: "SYNC0002", // Insufficient User Permission ( 6sense permission )
  SYNC0003: "SYNC0003", // Insufficient User Permission ( Missing write permissions to create audience )
  SYNC0004: "SYNC0004", // Insufficient User Permission ( Missing create ad permissions )
  SYNC0008: "SYNC0008", // Insufficient User Permission ( Ad Network Role Permission )
  SYNC0005: "SYNC0005", // User Request Limit Reached ( User limit reached )
};

export const WARNING = "warning";

const ALERT_ERROR_CODE_ALERT_TYPE_MAPPING = {
  [ALERT_ERROR_CODE.INT0003]: "error", // Invalid Access Token
  [ALERT_ERROR_CODE.INT0001]: "error", // Access Restricted
  [ALERT_ERROR_CODE.INT0002]: WARNING, // Refresh token about to expire
  [ALERT_ERROR_CODE.SYNC0001]: "error", // Insufficient User Permission ( Ad account access missing )
  [ALERT_ERROR_CODE.SYNC0002]: "error", // Insufficient User Permission ( 6sense permission )
  [ALERT_ERROR_CODE.SYNC0003]: "error", // Insufficient User Permission ( Missing write permissions to create audience )
  [ALERT_ERROR_CODE.SYNC0004]: "error", // Insufficient User Permission ( Missing create ad permissions )
  [ALERT_ERROR_CODE.SYNC0008]: "error", // Insufficient User Permission ( Ad Network Role Permission )
  [ALERT_ERROR_CODE.SYNC0005]: WARNING, // User Request Limit Reached ( User limit reached )
};

const USERS_ACCESS_TOKEN = "User's Access Token";

const DEFAULT_SORT_ORDER = "default";

const SEGMENT_SYNC_SOURCE = {
  SEGMENTS: "segments",
  WORKFLOWS: "workflows",
};
const WORKFLOW_MANAGE_TOOLTIP = "Click to manage this sync from workflows.";

export {
  SEGMENT_SYNCS_ROUTE,
  PAGE_STATE_KEY,
  AD_NETWORK_ICONS,
  DEFAULT_TABLE_SIZE,
  DEFAULT_PAGE_NUMBER,
  AD_NETWORKS,
  INTEGRATION_CARD_DATA_MAPPING,
  INTEGRATION_STATUS_COLOR,
  INTEGRATION_STATUS,
  SYNC_STATES,
  AD_ACCOUNT_DISABLED_TOOLTIP,
  AD_ACCOUNT_ACCESS_DENIED_TOOLTIP,
  AD_ACCOUNT_NOT_SELECTED_TOOLTIP,
  AD_ACCOUNT_SYNCING_TOOLTIP,
  SEGMENT_SYNCS_TOGGLE_STATUS,
  GOOGLE_AD_PROVIDER,
  INTEGRATION_STATUS_LABEL,
  ALERT_ERROR_CODE_ALERT_TYPE_MAPPING,
  ALERT_ERROR_CODE,
  SOCIAL_SEGMENT_SYNC_STATUS_KEY,
  NOT_SIGNED_IN_TOOLTIP,
  SEGMENT_SYNC_TOOLTIPS,
  USERS_ACCESS_TOKEN,
  DEFAULT_SORT_ORDER,
  WORKFLOW_MANAGE_TOOLTIP,
  SEGMENT_SYNC_SOURCE,
};
