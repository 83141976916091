import Proptypes from 'prop-types';
import React, { Component } from 'react';
import { actions } from './modules';
import { crossfilterSelector, dimensionSelector, areFiltersAppliedSelector } from './selectors';
import { mapReducers } from './utils';
import { RuntimeConnect } from 'HOCS';

export const Dimension =
  (config) =>
    (WrappedComponent) => {
      const {
        dimensionKey,
        dimensionAccessor,
        serializer,
        extra = {},
        datasetToProps = () => {},
        isArray = false,
        disposeOnUnmount = false,
        mapReduce = mapReducers.topN() } = config;

      const mapPropsToMapStateToProps = (props, context) => (state) => {
        let generatedKey = dimensionKey;
        /* eslint-disable no-underscore-dangle */
        const { key } = context._crossfilterRedux;
        if (dimensionKey instanceof Function) {
          generatedKey = dimensionKey(props);
        }

        return {
        // Need this so we always rerender
          crossfilter: crossfilterSelector(key)(state),
          areFiltersApplied: areFiltersAppliedSelector(key)(state),
          dimension: dimensionSelector(key)(generatedKey)(state),
        };
      };

      const reconnectWhen = (props, nextProps) => {
        if (dimensionKey instanceof Function) {
          const generatedKey = dimensionKey(props);
          const generatedKeyNext = dimensionKey(nextProps);
          return generatedKey !== generatedKeyNext;
        }
        return false;
      };

      class DimensionClass extends Component {
        constructor(props, context) {
          super(props, context);
          if (!context._crossfilterRedux) {
            throw new Error('BROOOO This needs to be underneath a crossfilter. smdh');
          }
          let generatedKey = dimensionKey;
          if (dimensionKey instanceof Function) {
            generatedKey = dimensionKey(props);
          }
          this.state = ({ dimKey: generatedKey });
        }

        componentWillMount() {
          this.createDimension(this.props);
        }


        componentWillReceiveProps(nextProps) {
          if (reconnectWhen(this.props, nextProps)) {
            this.setState({ dimKey: dimensionKey(nextProps) });
          }
        }

        componentDidUpdate() {
          this.createDimension(this.props);
        }

        componentWillUnmount() {
          const { disposeDimension } = this.props;
          const { key } = this.context._crossfilterRedux;
          const { dimKey } = this.state;

          if (disposeOnUnmount) {
            disposeDimension(key, dimKey);
          }
        }

        createDimension(props) {
          const { key, defaultSerializer } = this.context._crossfilterRedux;
          const { dimKey } = this.state;
          const { createDimension, dimension, loading, error } = this.props;
          const boundSerializer = (serializer || defaultSerializer).bind(null, this.props);
          if (!(loading || error) && !dimension.dimension) {
            createDimension(
              key, dimKey, dimensionAccessor, isArray, boundSerializer, extra, props
            );
          }
        }

        render() {
          const {
            addFilter,
            removeFilter,
            addOrRemoveFilter,
            clearFilters,
            dimension,
            areFiltersApplied,
            loading,
            error,
          } = this.props;
          const { key } = this.context._crossfilterRedux;
          const { dimKey } = this.state;

          if (!(loading || error) && dimension.dimension === undefined) {
            return null;
          }
          const dataset = (loading || error) ? [] : mapReduce(dimension.dimension, this.props);

          return (
            <WrappedComponent
              {...this.props}
              {...datasetToProps(dataset, this.props)}
              addFilter={(filterValue, filterType) =>
                addFilter(key, dimKey, filterValue, filterType)}
              removeFilter={(filterValue, filterType) =>
                removeFilter(key, dimKey, filterValue, filterType)}
              addOrRemoveFilter={(filterValue, filterType) =>
                addOrRemoveFilter(key, dimKey, filterValue, filterType)}
              clearFilters={() => clearFilters(key, dimKey)}
              appliedFilters={dimension.filters || {}}
              areFiltersApplied={areFiltersApplied}
              dataset={dataset}
            />
          );
        }
      }

      DimensionClass.contextTypes = {
        _crossfilterRedux: Proptypes.object,
      };

      DimensionClass.propTypes = {
        createDimension: Proptypes.func,
        dimension: Proptypes.object,
        addFilter: Proptypes.func,
        removeFilter: Proptypes.func,
        addOrRemoveFilter: Proptypes.func,
        clearFilters: Proptypes.func,
        disposeDimension: Proptypes.func,
        areFiltersApplied: Proptypes.bool,
        loading: Proptypes.bool,
        error: Proptypes.string,
      };

      const dimensionActions = {
        createDimension: actions.createDimension,
        addFilter: actions.addDimensionFilter,
        removeFilter: actions.removeDimensionFilter,
        addOrRemoveFilter: actions.addOrRemoveDimensionFilter,
        clearFilters: actions.clearDimensionFilters,
        disposeDimension: actions.disposeDimension,
      };

      return RuntimeConnect(
        mapPropsToMapStateToProps,
        () => dimensionActions,
        () => (stateProps, actionProps, ownProps) => ({
          ...stateProps,
          ...actionProps,
          ...ownProps,
        }),
        reconnectWhen,
        DimensionClass.contextTypes,
      )(DimensionClass);
    };

Dimension.dimensionPropTypes = {
  addFilter: Proptypes.func,
  dataset: Proptypes.array,
  removeFilter: Proptypes.func,
  addOrRemoveFilter: Proptypes.func,
  clearFilters: Proptypes.func,
  appliedFilters: Proptypes.object,
};
/* eslint-enable */
