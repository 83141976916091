import React from 'react';
import PropTypes from 'prop-types';
import { SixSenseLogo, Text, Row, Button } from 'v2-components';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { actions } from 'modules/global';
import { isUserLoggedIn } from 'utils/utils';
import styles from './Forbidden.module.scss';
import {
  isSettingsIframeSelect,
} from 'modules/user/selectors';

export class ForbiddenComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { location, stateLocation } = nextProps;
    if (location === stateLocation && !this.state.visible) {
      this.props.showGlobalForbidden(false);
    }
  }

  render() {
    const redirectPath = isUserLoggedIn ? '/discover/dashboard' : '/login';
    const buttonLabel = isUserLoggedIn ? 'Home' : 'Login';
    const { isSettingsIframe } = this.props;
    return (
      <Row
        flexDirection={Row.FLEX_DIRECTION.COLUMN}
        alignItems={Row.ALIGN_ITEMS.CENTER}
        className={styles.container}
      >

        <SixSenseLogo branded className={styles.logo} />

        <Row
          flexDirection={Row.FLEX_DIRECTION.COLUMN}
          alignItems={Row.ALIGN_ITEMS.CENTER}
        >
          <Text type={Text.TYPE.TITLE} color={Text.COLOR.WHITE}>403 Error</Text>
          <Text type={Text.TYPE.TITLE} color={Text.COLOR.WHITE}>
            You don't have permission to view this page
          </Text>
        </Row>

        {!isSettingsIframe ? <Row
          className={styles.imgContainer}
          justifyContent={Row.JUSTIFY_CONTENT.CENTER}
          alignItems={Row.JUSTIFY_CONTENT.CENTER}
          flexDirection={Row.FLEX_DIRECTION.COLUMN}
        >
          <Button
            type={Button.PRIMARY}
            className={styles.button}
            onClick={() =>
              this.setState({ visible: false }, () => browserHistory.push(redirectPath))}
          >
            {buttonLabel}
          </Button>

        </Row> : null}

      </Row>
    );
  }
}

ForbiddenComponent.propTypes = {
  location: PropTypes.object,
  stateLocation: PropTypes.object,
  showGlobalForbidden: PropTypes.func,
  isSettingsIframe: PropTypes.bool,
};

const mapStateToProps = (state = {}) => ({
  isSettingsIframe: isSettingsIframeSelect(state),
});

const NotFound = connect(mapStateToProps, actions)(ForbiddenComponent);
export default NotFound;
