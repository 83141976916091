import React, { Fragment, useEffect, useState } from 'react';
import { Flex, Drawer, Alert, Text } from '@sixsense/core/components';
import { Button } from 'v2-components';
import { Form } from 'react-final-form';
import { commaSeparatedNumber, getColorCodeByCreditBalance } from '../../utils';
import { get } from 'lodash';
import useConfirm from '@sixsense/core/hooks/useConfirm';
import { validateCreditDistribution } from './validation';
import { FontSize } from '@sixsense/core/style';
import DistributeTable from './DistributeTable';
import { TCreditConfig } from '../../types';
import {
  appPoolRefetcherState,
  distributeCreditsByApps,
  distributeDrawerOpenState,
} from '../../state';
import { actions as globalActions } from 'modules/global';
import { useDispatch, useSelector } from '@sixsense/core/versioned/react-redux';
import { useSharedState } from '@sixsense/core';
import { CREDIT_MSGS } from '../../constants';
import { isViewOnlyEnabledFor6senseAccessSelector } from 'modules/user/selectors';

type DistributeCreditDrawerProps = {
  creditConfig: TCreditConfig[];
  orgId: number;
  orgPoolId: number;
};

export type TFieldConfiguration = {
  label: string;
  name: string;
  remainingCredits: number;
  disabled: boolean;
};

const DistributeCreditDrawer = ({
  creditConfig,
  orgId,
  orgPoolId,
}: DistributeCreditDrawerProps) => {
  const { renderedDialog, showConfirm } = useConfirm();
  const [loading, setLoading] = useState(false); // State to track button loading status
  const [isOpen, setIsOpen] = useSharedState(distributeDrawerOpenState); // for drawer visibility
  const [, setRefreshTimestamp] = useSharedState(appPoolRefetcherState); // refresh all credits
  const [formKey, setFormKey] = useState(0); // Key to force re-render of form
  const isViewOnlyUser = useSelector(isViewOnlyEnabledFor6senseAccessSelector);

  // Extract the total credit configuration for 'global' type
  const totalCreditConfig = creditConfig.find(
    (config) => config.appCreditType === 'global'
  );

  // Filter out configurations that are not 'global'
  const availableAppConfigs = creditConfig.filter(
    (config) => config.appCreditType !== 'global'
  );

  const { total_unallocated_credits, total_balance_credits } =
    totalCreditConfig?.creditData || {};

  // Calculate total remaining credits
  const totalRemainingCredits =
    total_unallocated_credits + total_balance_credits;

  // State to track the live credit balance while editing form
  const [liveCreditBalance, setliveCreditBalance] = useState(
    totalRemainingCredits
  );
  const dispatch = useDispatch(); // Hook to access the Redux store

  // Configuration for form fields based on available app configs
  const fieldConfiguration: TFieldConfiguration[] = availableAppConfigs.map(
    (config) => ({
      label: config.distributeFieldLabel, // Display label for the form field
      name: config?.distributeFieldName || '', // Field name (default to empty string if undefined)
      remainingCredits: config?.creditData.total_balance_credits, // Remaining credits for the app
      disabled: config?.isDisabled,
    })
  );

  // Initialize values for distributing credits based on the field configuration
  const initialDistributeValues = fieldConfiguration.reduce(
    (acc, config) => ({
      ...acc,
      [config.name]: config.remainingCredits || 0, // Set initial value to remaining credits or 0
    }),
    {}
  );

  // Function to update the live credit balance based on the total app sum
  const updateLiveCreditBalance = (totalAppSumUpValue: number) => {
    // (total_unallocated_credits + total_balance_credits) - totalAppSumUpValue
    setliveCreditBalance(totalRemainingCredits - totalAppSumUpValue); // Calculate new balance
  };

  // Function to show alert messages
  const alertMessage = (type: string, msg: string) => {
    dispatch(globalActions.showNotification(type, msg)); // Dispatch notification action
  };

  // Function to handle the distribution of credits
  const handleDistributeCredit = (credits) => {
    setLoading(true); // Set loading state to true
    distributeCreditsByApps(orgId, orgPoolId, credits)
      .then(() => {
        setLoading(false); // Reset loading state
        setIsOpen(false); // Close the drawer
        setRefreshTimestamp(Date.now().toString()); // this will force fetch allCredits
        alertMessage('success', CREDIT_MSGS.CREDITS_UPDATED_MSG); // Show success toast
      })
      .catch((errorMessage) => {
        setLoading(false); // Reset loading state on error
        setIsOpen(false); // Close the drawer
        alertMessage('error', errorMessage); // Show error toast
      });
  };

  useEffect(() => {
    setFormKey((prevKey) => prevKey + 1); // Increment form key to trigger reset
  }, [isOpen, creditConfig]);

  // Function to close the distribute drawer with a check on loading state
  const closeDistributeDrawer = () => {
    if (!loading) {
      // Only allow closing if not loading
      setIsOpen(false); // Close the drawer
      return true; // Indicate successful close
    }
    return undefined; // Prevent closing if loading
  };

  return open ? (
    <Fragment>
      <Drawer
        size={'lg'}
        pageLevel
        open={isOpen}
        onRequestClose={closeDistributeDrawer}
        closeOnBackdropClick={!loading}
      >
        {/* {loading && <LoadingComponent />} */}
        <Form
          initialValues={initialDistributeValues}
          key={formKey}
          onSubmit={() => {}}
          validate={(formValues) =>
            validateCreditDistribution(
              formValues,
              fieldConfiguration,
              totalRemainingCredits,
            )
          }
        >
          {({ errors, values, pristine, submitting, invalid }) => (
            <Fragment>
              <Drawer.Header
                title={
                  <Flex alignItems={'start'} className="w90" direction="column">
                    <Text size={`var(${FontSize.fs6})`}>
                      Distribute Credits
                    </Text>
                    <Text
                      weight={'normal'}
                      size={`var(${FontSize.fs2})`}
                      className="textGray mt2"
                    >
                      Distribute available credits from your account pool to
                      individual 6sense apps
                    </Text>
                  </Flex>
                }
                onRequestClose={closeDistributeDrawer}
              />
              <Drawer.Body>
                {isOpen && (
                  <Flex direction="column" gap={16}>
                    <Alert
                      type={'primary'}
                      titleText={
                        <Text size={`var(${FontSize.fs2})`} weight={'normal'}>
                          You have{' '}
                          <span
                            className="fontWeightBold"
                            style={{ color: `var(${
                              getColorCodeByCreditBalance(liveCreditBalance)})` }}
                          >
                            {`${commaSeparatedNumber(liveCreditBalance)} `}
                          </span>
                          credits in your account pool that are available for
                          distribution. Already distributed credits that are
                          removed will be returned to that account pool
                        </Text>
                      }
                    />
                    <div className="my2">
                      <DistributeTable
                        fieldConfiguration={fieldConfiguration}
                        updateLiveCreditBalance={updateLiveCreditBalance}
                      />
                    </div>
                    {get(errors, 'sum') && (
                      <Alert type={'error'} titleText={get(errors, 'sum')} />
                    )}
                  </Flex>
                )}
              </Drawer.Body>
              <Drawer.Footer>
                <Flex justifyContent="flex-end">
                  <Button
                    className={'mr2'}
                    onClick={closeDistributeDrawer}
                  >Cancel</Button>
                  <Button
                    disabled={invalid || pristine || submitting || isViewOnlyUser}
                    type="primary"
                    loading={loading}
                    onClick={() => {
                      showConfirm({
                        title: 'Distribute credits',
                        description:
                          'Are you sure you want to distribute credits?',
                        type: 'warning',
                        onConfirm: () => handleDistributeCredit(values),
                      });
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </Drawer.Footer>
            </Fragment>
          )}
        </Form>
      </Drawer>
      {renderedDialog}
    </Fragment>
  ) : null;
};

export default DistributeCreditDrawer;
