import React from 'react';
import PropTypes from 'prop-types';
import { isNil, isEmpty } from 'lodash';
import { Text, AnalyticsCard } from 'v2-components';
import { PX_REM_SIZE_FACTOR } from 'utils/constants';
import styles from './LayoutCard.module.scss';

const generateContainerSize = (padding, alignment) => {
  const remPadding = padding / PX_REM_SIZE_FACTOR;
  let height = remPadding === 0 ? '100%' : `calc(100% - ${remPadding * 2}rem)`;
  const width = height;
  if (['start', 'end'].includes(alignment)) {
    height = `calc(100% - ${remPadding}rem)`;
  }
  return { width, height };
};

const LayoutCard = ({
  title,
  disableHeader,
  footerBorder = false,
  headerBorder = false,
  size = 'defaultSize',
  loadingSize,
  containerPadding = 0,
  ExtraHeader,
  extraHeaderClass = extraHeaderClass || '',
  containerClassName = containerClassName || '',
  wrapperParentClassName = wrapperParentClassName || '',
  alignment = 'center',
  showZeroState = false,
  zeroDataWhen = () => false,
  noData = (d = []) => isNil(d) || isEmpty(d),
}) => (WrappedComponent) => {
  const LayoutCardComponent = (props) => {
    const {
      header,
      footer,
      dataset,
      className,
      loading,
      error,
      errorMessage,
      disabled,
      disabledMessage,
      noDataMessage,
      onRefresh,
      bodyClass,
      headerRowClass,
    } = props;
    const headerContentClass = `${styles.headerContent} ${extraHeaderClass}`;
    const headerTitle =
      typeof title === 'string' ? (
        <Text className={styles.titleText} bold uppercase>
          {title}
        </Text>
      ) : (
        title
      );

    let cardHeader;
    if (header) {
      cardHeader = header;
    } else {
      cardHeader = !disableHeader ? (
        <div className={styles.outerHeader}>
          <div className={headerContentClass}>
            {headerTitle}
            {ExtraHeader ? <ExtraHeader {...props} /> : null}
          </div>
        </div>
      ) : null;
    }

    const { width, height } = generateContainerSize(containerPadding, alignment);
    const containerStyle = {
      justifyContent: 'center',
      position: 'relative',
      display: 'flex',
      width,
      height,
    };

    const hasZeroData = zeroDataWhen(dataset, props);
    const hasNoData = noData(dataset, props);

    return (
      <AnalyticsCard
        responsive={size === 'responsive'}
        header={cardHeader}
        className={`
          ${styles.outer}
          ${styles[loading && loadingSize ? loadingSize : size]}
          ${containerClassName}
          ${className}
        `}
        bodyClass={`
          ${styles.bodyContainer}
          ${styles[`align${alignment}`]}
          ${bodyClass}
        `}
        loading={loading}
        footer={footer}
        zeroData={showZeroState && hasZeroData}
        noData={showZeroState && hasNoData}
        noDataMessage={noDataMessage}
        error={error}
        disabled={disabled}
        disabledMessage={disabledMessage}
        errorMessage={errorMessage}
        onRefresh={onRefresh}
        footerBorder={footerBorder}
        headerBorder={headerBorder}
        headerRowClass={headerRowClass}
      >
        <div style={containerStyle} className={wrapperParentClassName}>
          <WrappedComponent {...props} />
        </div>
      </AnalyticsCard>
    );
  };

  LayoutCardComponent.propTypes = {
    footer: PropTypes.element,
    header: PropTypes.element,
    dataset: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    className: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    onRefresh: PropTypes.func,
    errorMessage: PropTypes.string,
    noDataMessage: PropTypes.string,
    bodyClass: PropTypes.string,
    disabled: PropTypes.bool,
    disabledMessage: PropTypes.string,
    headerRowClass: PropTypes.string,
  };

  LayoutCardComponent.defaultProps = {
    dataset: [],
  };

  return LayoutCardComponent;
};

LayoutCard.SHORT = 'short';
LayoutCard.MEDIUM = 'medium';
LayoutCard.TALL = 'tall';
LayoutCard.RESPONSIVE = 'responsive';
LayoutCard.COMPRESSED = 'compressed';

export default LayoutCard;
