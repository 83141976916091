import { getAsyncInjectors } from 'store/utils';
import Google from './routes/GoogleAdsIntegration';
import Linkedin from './routes/LinkedinAdsIntegration';
import Facebook from './routes/FacebookAdsIntegration';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./container');
          const reducer = require('./modules');
          const sagas = require('./sagas');
          injectReducer('socialSync', reducer.default);
          injectSagas('socialSync', sagas.default);
          cb(null, containers.default);
        },
        'socialSync'
      );
    },
    childRoutes: [Google(store), Linkedin(store), Facebook(store)],
  };
}
