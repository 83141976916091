const ADD_IMAGE = 'IMAGES/ADD_IMAGE';
const LOAD_IMAGE_SUCCESS = 'IMAGES/LOAD_IMAGE_SUCCESS';
const LOAD_IMAGE_FAILURE = 'IMAGES/LOAD_IMAGE_FAILURE';

const addImage = (routeKey) => (src) => ({
  type: ADD_IMAGE, routeKey, src,
});

const loadImageSuccess = (routeKey) => (src) => ({
  type: LOAD_IMAGE_SUCCESS, routeKey, src,
});

const loadImageFailure = (routeKey) => (src) => ({
  type: LOAD_IMAGE_FAILURE, routeKey, src,
});

const statusState = {
  [ADD_IMAGE]: { loaded: false, loading: true, error: false },
  [LOAD_IMAGE_SUCCESS]: { loaded: true, loading: false, error: false },
  [LOAD_IMAGE_FAILURE]: { loaded: true, loading: false, error: true },
};

export default function imageReducer(state = {}, action) {
  switch (action.type) {
    case ADD_IMAGE:
    case LOAD_IMAGE_SUCCESS:
    case LOAD_IMAGE_FAILURE: {
      const { routeKey, src, type } = action;
      const images = state[routeKey];
      return {
        ...state,
        [routeKey]: { ...images, [src]: statusState[type] },
      };
    }
    default:
      return state;
  }
}

export const actions = {
  addImage,
  loadImageSuccess,
  loadImageFailure,
};
