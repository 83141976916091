import React from "react";
import { Text } from "@sixsense/core/components";
import { formatDate } from "utils/utils";
import { DATE_FORMAT } from "utils/constants";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const ARCHIVED_ON = {
  sortByKey: "archived_on",
  key: "archived-on",
  csvColumnKey: "archived_on",
  title: <Label name={CAMPAIGN_LABELS.archivedOn} />,
  dataIndex: "campaign_meta.archived_on",
  width: pxToRem(130),
  render: (archivedOn) => (
    <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
      {archivedOn ? formatDate(DATE_FORMAT)(archivedOn) : "-"}
    </Text.Body>
  ),
};
