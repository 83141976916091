import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'navigation',
    name: 'Navigation',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('salesConfigNavigation', reducer.default);
        injectSagas('navigation', sagas.default);
        cb(null, containers.Navigation);
      }, 'settings_navigation');
    },
  };
}

