import React from 'react';
import { css } from 'styles/emotion';
import { Card, Grid } from '@sixsense/core/components';
import { Role } from '../../types';
import { pxToRem } from '@sixsense/core/style';

const styles = {
  text: css({
    fontSize: pxToRem(14),
    color: '#001F32',
  }),
  title: css({
    fontWeight: 'bold',
    marginBottom: pxToRem(12),
  }),
};

type Props = {
  role: Role;
}

const RoleSummaryCard = ({ role }: Props) => (
  <Card>
    <Card.Content>
      <Grid className={styles.text} templateColumns="25% 75%">
        <Grid.Cell row={1} column={1} className={styles.title}>
          Role
        </Grid.Cell>
        <Grid.Cell row={2} column={1}>
          {role.name}
        </Grid.Cell>
        <Grid.Cell row={1} column={2} className={styles.title}>
          Description
        </Grid.Cell>
        <Grid.Cell row={2} column={2}>
          {role.description}
        </Grid.Cell>
      </Grid>
    </Card.Content>
  </Card>
);

export default RoleSummaryCard;
