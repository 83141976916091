import React from "react";
import { Text } from "@sixsense/core/components";
import { COLUMN_CATEGORIES } from "../constants";
import { CAMPAIGN_SOURCE } from "../../../constants";
import { NA } from "./common/NA";
import { localeString } from "utils/utils";
import { ROW_DATA_TYPES } from "aa-components/CampaignCSVExportModal/constants";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const TOTAL_CLICKS = {
  sortByKey: "total_media_clicks",
  category: COLUMN_CATEGORIES.externalMedia,
  key: "total-clicks",
  csvColumnKey: "total_clicks",
  title: <Label name={CAMPAIGN_LABELS.totalClicks} />,
  dataIndex: "campaign_data.total_media_clicks",
  width: pxToRem(125),
  render: (totalMediaClicks, campaign) => {
    if (campaign.campaign_source !== CAMPAIGN_SOURCE.EXTERNAL) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {localeString(totalMediaClicks || 0)}
      </Text.Body>
    );
  },
  csvOverrides: {
    dataIndex: "download_stats.total_media_clicks",
    render: (totalMediaClicks = 0, campaign, dataType) => {
      if (
        campaign.campaign_source === CAMPAIGN_SOURCE.EXTERNAL ||
        dataType === ROW_DATA_TYPES.aggregated
      ) {
        return `"${localeString(totalMediaClicks)}"`;
      }

      return "-";
    },
  },
  align: "right",
};
