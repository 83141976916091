export default function createRoutes() {
  return {
    path: 'realtimescoring(/:id)',
    name: 'realtimescoring',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        cb(null, containers.RealTimeScoringContainer);
      }, 'settings_realtimescoring');
    },
  };
}
