import { Permission } from '@sixsense/rbac';
import PropTypes from 'prop-types';

export { SYSTEMS } from 'routes/Settings/routes/Mapping/constants';

// custom
export const ENRICH_SEGMENTS = 'ENRICH_SEGMENTS';
export const ENRICH = 'ENRICH';
export const L2A = 'L2A';
export const L2C = 'L2C';
export const NET_NEW_ACCOUNTS = 'NET_NEW_ACCOUNTS';
export const PURCHASE = 'PURCHASE';
export const PUSH_CONTACTS = 'PUSH_CONTACTS';
export const EXPORT_SCORES = 'EXPORT_SCORES';
export const PUSH_ESI_CONTACTS = 'ADD_NEW_CONTACTS_FROM_EMAIL';

// ai
export const WEB_ENGAGEMENT = 'DRIVE_WEB_ENGAGEMENT';
export const CREATE_NEW_OPPORTUNITIES = 'CREATE_NEW_OPPORTUNITIES';

export const ENV_NAME_PRODUCTION = 'PRODUCTION';

export const SIXSENSE_ID = '6sense ID';

export const ActionType = PropTypes.oneOf([
  ENRICH_SEGMENTS,
  ENRICH,
  L2A,
  L2C,
  NET_NEW_ACCOUNTS,
  PURCHASE,
  PUSH_CONTACTS,
  WEB_ENGAGEMENT,
  EXPORT_SCORES,
  CREATE_NEW_OPPORTUNITIES,
  PUSH_ESI_CONTACTS,
  '',
]);

export const SUPPORTED_DESTINATIONS = {
  DYNAMICS_CAMPAIGN: 'dynamics_campaign',
  MSD_LEAD_NO_CAMPAIGN: 'dynamics_lead_no_campaign',
  MSD_CONTACT_NO_CAMPAIGN: 'dynamics_contact_no_campaign',
  SALESFORCE_LEAD_NO_CAMPAIGN: 'salesforce_lead_no_campaign',
  SALESFORCE_CONTACT_NO_CAMPAIGN: 'salesforce_contact_no_campaign',
  SALESFORCE_CAMPAIGN: 'salesforce_campaign',
  MARKETO_STATIC_LIST: 'marketo_static_list',
  HUBSPOT_STATIC_LIST: 'hubspot_static_list',
  HUBSPOTCRM_STATIC_LIST: 'hubspotcrm_static_list',
  ELOQUA_LIST: 'eloqua_list',
  PARDOT_LIST: 'pardot_list',
  MARKETO_NO_CAMPAIGN: 'marketo_no_campaign',
  HUBSPOT_NO_CAMPAIGN: 'hubspot_no_campaign',
  HUBSPOTCRM_NO_CAMPAIGN: 'hubspotcrm_no_campaign',
  ELOQUA_NO_CAMPAIGN: 'eloqua_no_campaign',
  PARDOT_NO_CAMPAIGN: 'pardot_no_campaign',
  OUTREACH_SEQUENCE: 'outreach_sequence',
  OUTREACH_NO_SEQUENCE: 'outreach_no_sequence',
  SALESLOFT_CADENCE: 'salesloft_cadence',
  SALESLOFT_NO_CADENCE: 'salesloft_no_cadence',
  CONVERSATIONAL_EMAIL_CAMPAIGN: 'conversational_email_campaign',
  CONVERSATIONAL_EMAIL_NO_CAMPAIGN: 'conversational_email_no_campaign',
  CSV: 'csv',
};

export const OBJECT_TYPES = {
  ACCOUNT: 'account',
  LEAD: 'lead',
  CONTACT: 'contact',
  MALEAD: 'malead',
};

export const CSV_DOWNLOAD_STATUS_MAPPING = {
  ENRICHED_WITH_ERRORS: {
    ENRICH: 'Enriched with errors',
    ENRICH_SEGMENTS: 'Updated with errors',
    L2A: 'Updated with errors',
    NET_NEW_ACCOUNTS: 'Updated with errors',
  },
  FAILED: {
    ENRICH: 'Errors encountered',
    ENRICH_SEGMENTS: 'Errors encountered',
    L2A: 'Errors encountered',
    NET_NEW_ACCOUNTS: 'Errors encountered',
  },
  ENRICHED: {
    ENRICH: 'Enriched',
    ENRICH_SEGMENTS: 'Updated',
    L2A: 'Updated',
    NET_NEW_ACCOUNTS: 'Updated',
  },
  SKIPPED: {
    ENRICH: 'Skipped',
    ENRICH_SEGMENTS: 'Skipped',
    L2A: 'Skipped',
    NET_NEW_ACCOUNTS: 'Skipped',
  },
  ACCOUNT_CREATE_SUCCEEDED: {
    ENRICH: 'Account created',
    ENRICH_SEGMENTS: 'Account created',
    L2A: 'Account created',
    NET_NEW_ACCOUNTS: 'Account created',
  },
  ACCOUNT_CREATE_SUCCEEDED_WITH_ERRORS: {
    ENRICH: 'Account created with errors',
    ENRICH_SEGMENTS: 'Account created with errors',
    L2A: 'Account created with errors',
    NET_NEW_ACCOUNTS: 'Account created with errors',
  },
  ACCOUNT_CREATE_FAILED: {
    ENRICH: 'Errors encountered',
    ENRICH_SEGMENTS: 'Errors encountered',
    L2A: 'Errors encountered',
    NET_NEW_ACCOUNTS: 'Errors encountered',
  },
  LEAD_CONVERT_SUCCEEDED: {
    L2C: 'Converted Successfully',
  },
  LEAD_CONVERT_FAILED: {
    L2C: 'Lead conversion failed',
  },
};

const enrichConfig = (obj) => ({
  smartactionType: `ORCHESTRATION_ENRICH_${obj}`,
  display: 'Enrich {system} {object}s',
});
const segEnrichConf = (obj) => ({
  smartactionType: `ORCHESTRATION_ENRICH_${obj}`,
  display: 'Segment Participation - {system} {object}s',
});
const segEnrichCDOConf = (obj) => ({
  smartactionType: `ORCHESTRATION_ENRICH_${obj}`,
  display: 'Segment Participation - {system} {object} CDOs',
});
const pushContactConf = {
  smartactionType: 'ORCHESTRATION_EXISTING_CONTACT_CAMPAIGN_PUSH',
  display: 'Add audience to {system}',
};
const purchaseConf = {
  smartactionType: 'ORCHESTRATION_NEW_CONTACT_CAMPAIGN_PUSH',
  display: 'Acquire new contacts to {system}',
};
const exportConfig = ({
  smartactionType: 'ORCHESTRATION_EXPORT_SCORES',
  display: 'Export Scores - {system} {object}s',
});

const cnoOthersConfig = ({
  smartactionType: 'ORCHESTRATION_EXISTING_CONTACT_CAMPAIGN_PUSH',
  display: 'Create New Opportunities',
});

const pushEsiConfig = {
  smartactionType: 'ORCHESTRATION_ADD_NEW_CONTACTS_FROM_EMAIL',
  display: 'Add New Contacts from Email',
};

const cnoDisplayCampaignConfig = ({
  smartactionType: 'DISPLAY_CAMPAIGN',
  display: 'Create New Opportunities',
});
/**
 * This is a mapping of orchestration type + (source obj/target) and
 * smartaction type and the unformatted display string
*/
export const SA_AND_DISPLAY_MAP = {
  ENRICH: {
    account: enrichConfig('CRM_ACCOUNT'),
    lead: enrichConfig('CRM_LEAD'),
    contact: enrichConfig('CRM_CONTACT'),
    malead: enrichConfig('MAP_LEAD'),
  },

  ENRICH_SEGMENTS: {
    account: segEnrichConf('CRM_ACCOUNT'),
    lead: segEnrichConf('CRM_LEAD'),
    contact: segEnrichConf('CRM_CONTACT'),
    malead: segEnrichConf('MAP_LEAD'),

    // dynamics cdo objects
    new_sixsense_lead_scores: segEnrichCDOConf('CRM_LEAD'),
    new_sixsense_account_scores: segEnrichCDOConf('CRM_ACCOUNT'),
    new_sixsense_contact_scores: segEnrichCDOConf('CRM_CONTACT'),

    // salesforce cdo objects
    sixsense__X6sense_Lead_Scores__c: segEnrichCDOConf('CRM_LEAD'),
    sixsense__X6sense_Account_Score__c: segEnrichCDOConf('CRM_ACCOUNT'),
    sixsense__X6sense_Contact_Score__c: segEnrichCDOConf('CRM_CONTACT'),

    // hubspotcrm cdo objects
    sixsense_account_scores: segEnrichCDOConf('CRM_ACCOUNT'),
    sixsense_contact_scores: segEnrichCDOConf('CRM_CONTACT'),
  },

  L2A: {
    lead: {
      smartactionType: 'ORCHESTRATION_L2A_ENRICH_CRM_LEAD',
      display: 'Match {system} {object}s to Accounts', // maybe no variables?
    },
    // this is added to support l2a (c2a) with hubspot crm.
    // it doesn't have leads. it only has contacts.
    contact: {
      smartactionType: 'ORCHESTRATION_L2A_ENRICH_CRM_CONTACT',
      display: 'Match {system} {object}s to Accounts',
    },
  },

  L2C: {
    lead: {
      smartactionType: 'ORCHESTRATION_L2C',
      display: 'Convert Leads to Contacts',
    },
  },

  NET_NEW_ACCOUNTS: new Proxy({
    account: {
      smartactionType: 'ORCHESTRATION_NET_NEW_ACCOUNTS',
      display: 'Create {system} Accounts',
    },
  },
    {
      get(ob) {
        return ob.account;
      },
    }
  ),

  PURCHASE: {
    [SUPPORTED_DESTINATIONS.SALESFORCE_LEAD_NO_CAMPAIGN]: purchaseConf,
    [SUPPORTED_DESTINATIONS.SALESFORCE_CONTACT_NO_CAMPAIGN]: purchaseConf,
    [SUPPORTED_DESTINATIONS.SALESFORCE_CAMPAIGN]: purchaseConf,
    [SUPPORTED_DESTINATIONS.MARKETO_STATIC_LIST]: purchaseConf,
    [SUPPORTED_DESTINATIONS.HUBSPOT_STATIC_LIST]: purchaseConf,
    [SUPPORTED_DESTINATIONS.HUBSPOTCRM_STATIC_LIST]: purchaseConf,
    [SUPPORTED_DESTINATIONS.ELOQUA_LIST]: purchaseConf,
    [SUPPORTED_DESTINATIONS.PARDOT_LIST]: purchaseConf,
    [SUPPORTED_DESTINATIONS.MARKETO_NO_CAMPAIGN]: purchaseConf,
    [SUPPORTED_DESTINATIONS.HUBSPOT_NO_CAMPAIGN]: purchaseConf,
    [SUPPORTED_DESTINATIONS.HUBSPOTCRM_NO_CAMPAIGN]: purchaseConf,
    [SUPPORTED_DESTINATIONS.ELOQUA_NO_CAMPAIGN]: purchaseConf,
    [SUPPORTED_DESTINATIONS.PARDOT_NO_CAMPAIGN]: purchaseConf,
    [SUPPORTED_DESTINATIONS.CSV]: { ...purchaseConf, display: 'Acquire new contacts' },

    [SUPPORTED_DESTINATIONS.OUTREACH_SEQUENCE]: purchaseConf,
    [SUPPORTED_DESTINATIONS.OUTREACH_NO_SEQUENCE]: purchaseConf,
    [SUPPORTED_DESTINATIONS.CONVERSATIONAL_EMAIL_CAMPAIGN]: purchaseConf,
    [SUPPORTED_DESTINATIONS.CONVERSATIONAL_EMAIL_NO_CAMPAIGN]: purchaseConf,
    [SUPPORTED_DESTINATIONS.SALESLOFT_CADENCE]: purchaseConf,
    [SUPPORTED_DESTINATIONS.SALESLOFT_NO_CADENCE]: purchaseConf,
    [SUPPORTED_DESTINATIONS.DYNAMICS_CAMPAIGN]: purchaseConf,
    [SUPPORTED_DESTINATIONS.MSD_LEAD_NO_CAMPAIGN]: purchaseConf,
    [SUPPORTED_DESTINATIONS.MSD_CONTACT_NO_CAMPAIGN]: purchaseConf,
  },

  PUSH_CONTACTS: {
    [SUPPORTED_DESTINATIONS.SALESFORCE_CAMPAIGN]: pushContactConf,
    [SUPPORTED_DESTINATIONS.MARKETO_STATIC_LIST]: pushContactConf,
    [SUPPORTED_DESTINATIONS.HUBSPOT_STATIC_LIST]: pushContactConf,
    [SUPPORTED_DESTINATIONS.HUBSPOTCRM_STATIC_LIST]: pushContactConf,
    [SUPPORTED_DESTINATIONS.PARDOT_LIST]: pushContactConf,
    [SUPPORTED_DESTINATIONS.ELOQUA_LIST]: pushContactConf,
    [SUPPORTED_DESTINATIONS.CSV]: pushContactConf,
    [SUPPORTED_DESTINATIONS.OUTREACH_SEQUENCE]: pushContactConf,
    [SUPPORTED_DESTINATIONS.CONVERSATIONAL_EMAIL_CAMPAIGN]: pushContactConf,
    [SUPPORTED_DESTINATIONS.SALESLOFT_CADENCE]: pushContactConf,
    [SUPPORTED_DESTINATIONS.OUTREACH_NO_SEQUENCE]: pushContactConf,
    [SUPPORTED_DESTINATIONS.CONVERSATIONAL_EMAIL_NO_CAMPAIGN]: pushContactConf,
    [SUPPORTED_DESTINATIONS.SALESLOFT_NO_CADENCE]: pushContactConf,
    [SUPPORTED_DESTINATIONS.DYNAMICS_CAMPAIGN]: pushContactConf,
  },

  EXPORT_SCORES: {
    account: exportConfig,
    lead: exportConfig,
    contact: exportConfig,
  },

  CREATE_NEW_OPPORTUNITIES: {
    other: cnoOthersConfig,
    display_campaign: cnoDisplayCampaignConfig,
  },

  [PUSH_ESI_CONTACTS]: {
    [SUPPORTED_DESTINATIONS.SALESFORCE_LEAD_NO_CAMPAIGN]: pushEsiConfig,
    [SUPPORTED_DESTINATIONS.MSD_LEAD_NO_CAMPAIGN]: pushEsiConfig,
    [SUPPORTED_DESTINATIONS.HUBSPOTCRM_NO_CAMPAIGN]: pushEsiConfig,
  },
};

export const sepSystems = ['outreach', 'salesloft', 'conversational_email'];
export const sepListTypeTargets = [
  SUPPORTED_DESTINATIONS.OUTREACH_SEQUENCE,
  SUPPORTED_DESTINATIONS.SALESLOFT_CADENCE,
  SUPPORTED_DESTINATIONS.CONVERSATIONAL_EMAIL_CAMPAIGN,
];
export const sepNonListTypeTargets = [
  SUPPORTED_DESTINATIONS.OUTREACH_NO_SEQUENCE,
  SUPPORTED_DESTINATIONS.SALESLOFT_NO_CADENCE,
  SUPPORTED_DESTINATIONS.CONVERSATIONAL_EMAIL_NO_CAMPAIGN,
];
export const sepTargets = [...sepListTypeTargets, ...sepNonListTypeTargets];

export const ORCHESTRATION_PERMISSIONS = [
  Permission.ORCHESTRATION_EDIT,
  Permission.ORCHESTRATION_VIEW,
];

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

// this is an enum of all field names we use in redux forms
// OF = Orchestration Form
// please keep alphabetized
// never use the string literal unless absolutely necessary(destructuring)
export const OF = {
  ACTION_SEGMENT: 'orchestration_action_segment',
  ACTION_TARGET: 'orchestration_action_target',
  ACTION_TYPE: 'orchestration_action_type',
  BATCH_SIZE: 'orchestration_batch_size',
  BATCH_SIZE_ENABLED: 'orchestration_batch_size_enabled',
  CDO: 'cdo',
  END_DATE: 'orch_limit_end_date',
  ENRICH_FIELD_MAPPING: 'orchestration_enrich_field_mapping',
  ENRICH_FREQ: 'orchestration_enrich_frequency',
  ENRICH_FREQ_DAY: 'orchestration_enrich_frequency_day',
  ENRICH_TYPE: 'orchestration_enrich_type',
  ENRICH_TYPE_SEGMENT: 'orchestration_enrich_type_segment',
  ENTRY_TIME_RNG: 'orch_limit_entry_time_range',
  ENTRY_VISIT_CNT: 'orch_limit_entry_visit_count',
  ENV: 'orchestration_environment',
  EXIT_TIME_RNG: 'orch_limit_exit_time_range',
  EXIT_VISIT_CNT: 'orch_limit_exit_visit_count',
  FILTERS: 'orchestration_filters',
  FILTER_CP_PRODUCT_CATEGORY: 'orc_filter_cp_product_category',
  FOCUS_OBJ_TYPE: 'orchestration_focus_object_type',
  L2C_CONVERT_STATUS: 'orchestration_lead_to_contact_convert_status',
  LIMITATIONS_FLAG: 'orchestration_limitations_flag',
  LIMITATIONS_LIMIT: 'orchestration_limitations_limit',
  NAME: 'orchestration_name',
  PRODUCT: 'orch_product_name',
  PRODUCT_LIST: 'product_list',
  PURCH_COMPANY_PHONE_FLAG: 'orc_purchase_push_companyphone_flag',
  PURCH_DIR_FLAG: 'orc_purchase_push_directdial_flag',
  PURCH_FIELD_MAPPING: 'orchestration_purchase_push_field_mapping',
  PURCH_FREQ: 'orchestration_purchase_push_frequency',
  PURCH_FREQ_DAY: 'orchestration_purchase_push_frequency_day',
  PURCH_LIMIT_ACCT_FLAG: 'orc_purchase_push_limitaccount_flag',
  PURCH_LIMIT_ACCT_VAL: 'orc_purchase_push_limitaccount_value',
  PURCH_PRIO_CONFIG_PRODUCTS: 'orc_purchase_push_prioritization_config_products',
  PURCH_TARGET: 'orchestration_purchase_push_target',
  PURCH_EMAIL_SCORE_A_PLUS: 'orc_purchase_email_confidence_score_a_plus',
  PURCH_EMAIL_SCORE_A: 'orc_purchase_email_confidence_score_a',
  PURCH_EMAIL_SCORE_B: 'orc_purchase_email_confidence_score_b',
  SOURCE: 'orchestration_source',
  PRIO_CL_CONTACTS: 'prioritize_closest_contacts',
  PLATFORM_OR_SYSTEM: 'orch_platform_or_system',
  TACTIC_CAMPAIGN: 'orch_tactic_campaign',
  TACTIC_ADD_LIMIT: 'orc_tactic_add_limit_value',
  TACTIC_ADD_LIMIT_PER_ORCH: 'orc_tactic_add_limit_value_per_orch',
  TACTIC_REMOVE_ALL_CONTACTS: 'orc_tactic_remove_all_contacts',
  MARKETING_LIST_LEAD: 'orchestration_ml_leads',
  MARKETING_LIST_CONTACT: 'orchestration_ml_contacts',
};

// this would be the same idea as the OF enum, probably mostly used in the request builder.
// there might be a better solution to this. Also, once this gets built out, we'd be able
// to easily see all of the places the UI has different naming convention than the back end
// and then we could/should consolidate.
export const API_FIELDS = {};

export const ORCH_TYPE_MAP = {
  [ENRICH]: 'ENRICH_RECORDS',
  [ENRICH_SEGMENTS]: 'ENRICH_SEGMENT_NAMES',
  [L2A]: 'L2A',
  [L2C]: 'L2C',
  [NET_NEW_ACCOUNTS]: 'NET_NEW_ACCOUNTS',
  [PUSH_CONTACTS]: 'ADD_EXISTING_CONTACTS',
  [PURCHASE]: 'PURCHASE_AND_PUSH_NEW_CONTACTS',
  [EXPORT_SCORES]: 'EXPORT_SCORES',
  [WEB_ENGAGEMENT]: WEB_ENGAGEMENT,
  [PUSH_ESI_CONTACTS]: PUSH_ESI_CONTACTS,
};
export const UNTITLED_ORCHESTRATION = 'New Untitled';

export const ORCH_CATEGORY_AI = 'AI';

export const DRAFT = 'Draft';

export const CONVERSATIONAL_EMAIL = '6sense Conversational Email';
export const CONVERSATIONAL_EMAIL_CAMPAIGN_TEXT = '6sense conversational email campaign';
export const PURCHASE_EMAIL_GRADE = {
  A_PLUS: 'A+',
  A: 'A',
  B: 'B',
};
export const EMAIL_GRADE_FORM_MAP = {
  [PURCHASE_EMAIL_GRADE.A_PLUS]: OF.PURCH_EMAIL_SCORE_A_PLUS,
  [PURCHASE_EMAIL_GRADE.A]: OF.PURCH_EMAIL_SCORE_A,
  [PURCHASE_EMAIL_GRADE.B]: OF.PURCH_EMAIL_SCORE_B,
};
