import { TextColor } from "@sixsense/core/style/variables";
import React from "react";
import { Text } from "@sixsense/core/components";
import { pxToRem } from "@sixsense/core/style";

export const CAMPAIGN_ID = {
  sortByKey: "id",
  customizable: false,
  key: "campaign-id",
  csvColumnKey: "id",
  title: "Campaign ID",
  dataIndex: "id",
  width: pxToRem(150),
  render: (campaignId) => (
    <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
      {campaignId}
    </Text.Body>
  ),
};
