import { keys, values, filter } from "lodash";
import { FEATURE_IDENTIFIERS } from "routes/AdvertisingDS/constants";
import {
  CAMPAIGN_PERFORMANCE_STATES,
  CAMPAIGN_SOURCE,
  AD_TYPE_FILTERS,
  CAMPAIGN_SOURCE_LABELS,
  CAMPAIGN_STATUS_LABELS,
  CAMPAIGN_STATUS_X,
} from "routes/AdvertisingDS/routes/Campaigns/constants";

export const CREATED_BY_ME = "Created By Me";
export const ARCHIVED_BY_ME = "Archived By Me";
export const ARCHIVED_CAMPAIGNS = "Archived Campaigns";

export const FILTER_KEYS = {
  campaignSource: "campaign_source__in",
  campaignStatus: "campaign_status__in",
  needAction: "need_action__in",
  campaignPerformance: "campaign_performance_state__in",
  createdBy: "created_by_id",
  archivedBy: "archived_by_id",
  adType: "campaign_subtype__in",
  classificationId: "classification_id",
  linkedinPageIn: "linkedin_ads_page_id__in",
  product: "product__in",
  purchaseOrder: "purchase_order__in",
};

export const FILTER_LABELS = {
  campaignType: "Campaign Type",
  status: "Status",
  needAction: "Need Action",
  delivery: "Delivery",
  adType: "Ad Type",
  linkedinPage: "LinkedIn Page",
  product: "Product",
  purchaseOrder: "Purchase Order (PO)",
};

const CAMPAIGN_TYPE_FILTER_OPTION = {
  label: FILTER_LABELS.campaignType,
  filterKey: FILTER_KEYS.campaignSource,
  choices: [
    CAMPAIGN_SOURCE.INTERNAL,
    CAMPAIGN_SOURCE.RETARGETING,
    CAMPAIGN_SOURCE.CONTEXTUAL,
    CAMPAIGN_SOURCE.EXTERNAL,
    CAMPAIGN_SOURCE.LINKEDIN,
    CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING,
  ].map((source) => ({
    label: CAMPAIGN_SOURCE_LABELS[source],
    value: source,
  })),
};

const INTERNAL_AND_RETARGETING_CAMPAIGNS = [
  CAMPAIGN_SOURCE.INTERNAL,
  CAMPAIGN_SOURCE.RETARGETING,
];

const CAMPAIGN_TYPE_FILTER_OPTION_FOR_ARCHIVED = {
  label: FILTER_LABELS.campaignType,
  filterKey: FILTER_KEYS.campaignSource,
  choices: INTERNAL_AND_RETARGETING_CAMPAIGNS.map((source) => ({
    label: CAMPAIGN_SOURCE_LABELS[source],
    value: source,
  })),
};

const CAMPAIGN_STATUS_FILTER_OPTION = {
  label: FILTER_LABELS.status,
  filterKey: FILTER_KEYS.campaignStatus,
  choices: filter(
    values(CAMPAIGN_STATUS_X).map(
      (status) =>
        status !== CAMPAIGN_STATUS_X.draft && {
          label: CAMPAIGN_STATUS_LABELS[status],
          value: status,
        }
    )
  ),
};

const AD_TYPE_FILTER_OPTION = {
  label: FILTER_LABELS.adType,
  filterKey: FILTER_KEYS.adType,
  choices: keys(AD_TYPE_FILTERS).map((key) => AD_TYPE_FILTERS[key]),
};

export const LINKEDIN_LOW_AUDIENCE_COUNT = "low_audience_count";
const NEED_ACTIONS_FILTER_OPTION = {
  label: FILTER_LABELS.needAction,
  filterKey: FILTER_KEYS.needAction,
  choices: [
    { label: "No Active Ads", value: "no_active_ads" },
    { label: "No Active Accounts", value: "no_active_accounts" },
    { label: "No Identifiers", value: "no_identifiers" },
    { label: "Creative Audit Failed", value: "creative_audit_failed" },
    { label: "Out of Budget", value: "out_of_budget" },
    { label: "Reaching Budget", value: "reaching_budget" },
    { label: "LinkedIn Audience < 300", value: LINKEDIN_LOW_AUDIENCE_COUNT },
    { label: "Low Budget", value: "budget_too_low" },
  ],
};

const CAMPAIGN_PERFORMANCE_CHOICES = [
  CAMPAIGN_PERFORMANCE_STATES.steady_delivery,
  CAMPAIGN_PERFORMANCE_STATES.overpaced_delivery,
  CAMPAIGN_PERFORMANCE_STATES.low_delivery,
  CAMPAIGN_PERFORMANCE_STATES.under_delivery,
  CAMPAIGN_PERFORMANCE_STATES.no_delivery,
];

const CAMPAIGN_PERFORMANCE_FILTER_OPTION = {
  label: FILTER_LABELS.delivery,
  filterKey: FILTER_KEYS.campaignPerformance,
  choices: CAMPAIGN_PERFORMANCE_CHOICES.map(({ key, label }) => ({
    label,
    value: key,
  })),
};

export const CREATED_BY_ME_FILTER_OPTION = {
  label: null,
  filterKey: FILTER_KEYS.createdBy,
  choices: [{ label: CREATED_BY_ME, value: undefined }],
};

export const ARCHIVED_BY_ME_FILTER_OPTION = {
  label: null,
  filterKey: FILTER_KEYS.archivedBy,
  choices: [{ label: ARCHIVED_BY_ME, value: undefined }],
};

export const ARCHIVED_CAMPAIGNS_FILTER_OPTION = {
  label: null,
  filterKey: FILTER_KEYS.classificationId,
  choices: [{ label: ARCHIVED_CAMPAIGNS, value: undefined }],
};

export const LINKEDIN_PAGES_FILTER_OPTIONS = {
  label: FILTER_LABELS.linkedinPage,
  filterKey: FILTER_KEYS.linkedinPageIn,
  // Choices will be dynamically updated since these are not static
  choices: [],
};

export const PRODUCT_FILTER_OPTION = {
  label: FILTER_LABELS.product,
  filterKey: FILTER_KEYS.product,
  choices: [],
  newFeatureKey: FEATURE_IDENTIFIERS.CBD_FILTERS,
};

export const PURCHASE_ORDERS_FILTER_OPTION = {
  label: FILTER_LABELS.purchaseOrder,
  filterKey: FILTER_KEYS.purchaseOrder,
  choices: [],
  newFeatureKey: FEATURE_IDENTIFIERS.CBD_FILTERS,
};

export const FILTER_OPTIONS = [
  CAMPAIGN_TYPE_FILTER_OPTION,
  CAMPAIGN_STATUS_FILTER_OPTION,
  NEED_ACTIONS_FILTER_OPTION,
  CAMPAIGN_PERFORMANCE_FILTER_OPTION,
  AD_TYPE_FILTER_OPTION,
];

export const FILTER_OPTIONS_FOR_ARCHIVED_AND_DRAFTS = [
  CAMPAIGN_TYPE_FILTER_OPTION_FOR_ARCHIVED,
  AD_TYPE_FILTER_OPTION,
];
