import rollbar from 'lib/rollbar';
import { userSelector } from 'modules/user/selectors';
import { sanitizeState, sanitize } from 'store/utils';
import { actions } from 'modules/global';
import { GLOBAL_ERROR_MESSAGE } from 'utils/constants';

const { showGlobalError } = actions;

// Still too slow to use this even with the changes :(
export const crashReport = (store) => (next) => (action) => {
  try {
    next(action);
  } catch (err) {
    rollbar.error(err, {
      action: sanitize(action),
      state: sanitizeState(store),
      user: userSelector(store.getState()),
    });

    // will include Doga's error here in a bit
    store.dispatch(showGlobalError(GLOBAL_ERROR_MESSAGE));
    if (process.env.NODE_ENV === 'dev') {
      console.log(err.stack); // eslint-disable-line
    }
  }
};
