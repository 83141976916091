import { COLORS } from "v2-styles/constants";
import { isDaylightSavings } from "utils/utils";
import { CategoricalColors } from "@sixsense/core/components/data-display/charts/ChartColors";

export const ANALYTIC_TYPES = Object.freeze({
  TREND: "date",
  TIME: "time",
  IMPRESSION: "impression",
  VIEWABILITY: "viewability",
  DEVICE: "device",
  DOMAIN: "domain",
  JLJF: "jljf",
  VALUE_METRICS: "value_metrics",
  PERFORMANCE: "performance",
});

export const ANALYTIC_TYPES_DISPLAY_LABELS = Object.freeze({
  date: "Daily Campaign Performance",
  domain: "Domain Performance",
  value_metrics: "Value Metrics",
  jljf: "Job Level and Job Function Performance",
});

export const VALUE_METRICS_REACHED = "Reached";
export const VALUE_METRICS_ENGAGED = "Engaged";
export const VALUE_METRICS_ENGAGEMENT = "Increased Engagement";
export const VALUE_METRICS_PIPELINE = "Pipeline";
export const VALUE_METRICS_CLOSED = "Closed Won";

export const VALUE_METRICS_CATEGORIES = [
  VALUE_METRICS_REACHED,
  VALUE_METRICS_ENGAGED,
  VALUE_METRICS_ENGAGEMENT,
  VALUE_METRICS_PIPELINE,
  VALUE_METRICS_CLOSED,
];

export const VALUE_METRICS_DEFINITIONS = {
  [VALUE_METRICS_REACHED]: {
    label: "Reached",
    value: "The number of unique accounts reached by the campaign.",
  },
  [VALUE_METRICS_ENGAGED]: {
    label: "Engaged",
    value: `The number of unique accounts that have click-throughs or
    view-throughs as a result of the campaign.`,
  },
  [VALUE_METRICS_ENGAGEMENT]: {
    label: "Increased Engagement",
    value: `Count of accounts reached that showed increased engagement on your
    website between your campaign's first and last ad impression on the
    account and the 30 day period following the last impression, than in the
    30-day period preceding the first impression on the account.`,
  },
  [VALUE_METRICS_PIPELINE]: {
    label: "Pipeline",
    value: `The number of accounts (and their combined value) that have associated
    dollar values in your connected CRM.`,
  },
  [VALUE_METRICS_CLOSED]: {
    label: "Closed Won ",
    value: `The number of opportunities (and their associated dollar values) marked
    closed won in your connected CRM.`,
  },
};

export const VALUE_METRICS_STATS_REQUIRED = [
  "reached_accounts",
  "engaged_accounts",
  "increased_engagement_accounts",
  "pipeline_accounts",
  "closed_won_accounts",
  "closed_won_pipeline",
  "total_pipeline",
  "pipeline_opportunities",
];

export const VALUE_METRICS_META_REQUIRED = [
  "campaign_spend",
  "total_pipeline",
  "closed_won_pipeline",
];

export const DEFAULT_VALUE_METRICS_DATA = {
  STATS_REQUIRED: {
    reached_accounts: 0,
    engaged_accounts: 0,
    increased_engagement_accounts: 0,
    pipeline_accounts: 0,
    closed_won_accounts: 0,
    closed_won_pipeline: 0,
    total_pipeline: 0,
    pipeline_opportunities: 0,
  },
  META_REQUIRED: {
    campaign_spend: 0,
    total_pipeline: 0,
    closed_won_pipeline: 0,
  },
};

export const DISTRIBUTION_ANALYTICS = new Set([
  ANALYTIC_TYPES.TIME,
  ANALYTIC_TYPES.DEVICE,
  ANALYTIC_TYPES.DOMAIN,
  ANALYTIC_TYPES.TREND,
]);

export const US = "United States";
export const eCPC_LABEL = "eCPC";

export const DISPLAY_LABELS = Object.freeze({
  impression_count: "Impressions",
  click_count: "Clicks",
  account_count: "Accounts Reached",
  audience_count: "Audience",
  conversions: "Conversions",
  spend: "Spend",
  cpm: "CPM",
  cpc: eCPC_LABEL,
  cost_per_conversion: "Cost/Conversion",
  accounts_newly_engaged: "Accounts Newly Engaged",
  accounts_with_increased_engagement: "Accounts with Increased Engagement",
});

export const VALUE_METRICS_DISPLAY_LABELS = Object.freeze({
  Reached: "Reached Accounts",
  Engaged: "Engaged Accounts",
  "Increased Engagement": "Increased Engagement",
  Pipeline: "Pipeline Accounts",
  "Closed Won": "Closed Won Accounts",
});

// NOTE: avoid using this const
export const statsSequence = [
  "account_count",
  "audience_count",
  "impression_count",
  "click_count",
];

export const GRAPH_COLORS = Object.freeze({
  impression_count: COLORS.AA_YELLOW,
  click_count: COLORS.AA_MAROON,
  account_count: COLORS.AA_6SENSE_BLUE,
  viewable: COLORS.AA_BLUE,
  notViewable: COLORS.AA_GREEN,
  audience_count: COLORS.AA_6SENSE_GREEN,
});

export const accountFilterOptions = {
  optGroupLabel: "Engagement type",
  options: [
    {
      label: "Newly Engaged",
      value: "newly_engaged",
      tagId: "newly-engaged-filter-option-cdp",
    },
    {
      label: "Increased Engagement",
      value: "increased_engagement",
      tagId: "increased-engagement-filter-option-cdp",
    },
  ],
};

export const filterTags = {
  newly_engaged: "Newly Engaged",
  increased_engagement: "Increased Engagement",
  has_conversions: "Has Conversions",
};

export const headerTabItems = [
  "Performance",
  "AdGroups",
  "Ads",
  "Configuration",
];

export const CAMPAIGN_CONFIG_FORM = "CAMPAIGN_CONFIG_FORM";

const isDst = isDaylightSavings();
const tzLabel = isDst ? "Daylight" : "Standard";
export const TIME_DISTRIBUTION_TIMEZONES = [
  { label: "UTC", value: "UTC" },
  {
    label: `Eastern ${tzLabel} Time (UTC-0${isDst ? 4 : 5}:00)`,
    value: "America/New_York",
  },
  {
    label: `Central ${tzLabel} Time (UTC-0${isDst ? 5 : 6}:00)`,
    value: "America/Chicago",
  },
  {
    label: `Mountain ${tzLabel} Time (UTC-0${isDst ? 6 : 7}:00)`,
    value: "America/Denver",
  },
  {
    label: `Pacific ${tzLabel} Time (UTC-0${isDst ? 7 : 8}:00)`,
    value: "America/Los_Angeles",
  },
];

export const CURRENCY_STATS = ["Spend", "CPM", "CPC"];

export const UNFILTERED_CHARTS = ["VALUE_METRICS"];

export const ANALYTICS_TYPES_DOWNLOAD_HEADER = {
  [ANALYTIC_TYPES.TREND]: [
    "Date",
    "Account reached",
    "Impression",
    "Clicks",
    "Spend",
    "CPM",
    eCPC_LABEL,
  ],
  [ANALYTIC_TYPES.DOMAIN]: [
    "Domain",
    "Accounts reached",
    "Impressions",
    "Clicks",
  ],
  [ANALYTIC_TYPES.JLJF]: [
    "Job Level and Job Function",
    "Accounts Reached",
    "Impressions",
    "Clicks",
  ],
};

export const PIPE_ONLY_REV = "PipeOnlyRev_ALLStatus";
export const ALL_REV = "AllRev_ALLStatus";

export const VIEWABILITY_DEFINITION =
  "Percentage of ad impressions that were measured as viewable by the user.";

export const IMPRESSION_DISTRIBUTION_RANGES = {
  "0-9": null,
  "10-19": null,
  "20-49": null,
  "50-99": null,
  "100-249": null,
  "250-499": null,
  "500-999": null,
  "1000-4999": null,
  "5000-10000": null,
  "10000+": null,
};

export const DEFAULT_COLUMN_WIDTH = 120;

export const DEVICE_COLORS = {
  Desktop: CategoricalColors.CATEGORICAL_4,
  Mobile: CategoricalColors.CATEGORICAL_5,
  Tablet: CategoricalColors.CATEGORICAL_6,
};

export const DEVICE_DOT_COLORS = {
  Desktop: "Pink",
  Mobile: "Violet",
  Tablet: "Warning",
};

export const LINKEDIN_JOB_PROFILE_VALUES = {
  jobLevel: "job_level",
  jobFunction: "job_function",
  jobTitle: "job_title",
};

export const LINKEDIN_JOB_PROFILE_OPTIONS = [
  {
    label: "Distribution By Job Level",
    value: LINKEDIN_JOB_PROFILE_VALUES.jobLevel,
  },
  {
    label: "Distribution By Department",
    value: LINKEDIN_JOB_PROFILE_VALUES.jobFunction,
  },
  {
    label: "Distribution By Job Title",
    value: LINKEDIN_JOB_PROFILE_VALUES.jobTitle,
  },
];

export const LINKEDIN_JOB_PROFILE_AVG_CTR_KEY = "Avg CTR";

export const LINKEDIN_JOB_PROFILE_DOWNLOAD_HEADERS = {
  [LINKEDIN_JOB_PROFILE_VALUES.jobLevel]: [
    "Job Level",
    "Impressions",
    "Clicks",
    LINKEDIN_JOB_PROFILE_AVG_CTR_KEY,
  ],
  [LINKEDIN_JOB_PROFILE_VALUES.jobFunction]: [
    "Job Function",
    "Impressions",
    "Clicks",
    LINKEDIN_JOB_PROFILE_AVG_CTR_KEY,
  ],
  [LINKEDIN_JOB_PROFILE_VALUES.jobTitle]: [
    "Job Title",
    "Impressions",
    "Clicks",
    LINKEDIN_JOB_PROFILE_AVG_CTR_KEY,
  ],
};

export const LINKEDIN_JOB_PROFILE_DOWNLOAD_REPORT_LABEL = {
  [LINKEDIN_JOB_PROFILE_VALUES.jobLevel]: "Distribution By Job Level",
  [LINKEDIN_JOB_PROFILE_VALUES.jobFunction]: "Distribution By Department",
  [LINKEDIN_JOB_PROFILE_VALUES.jobTitle]: "Distribution By Job Title",
};

export const LINKEDIN_AUDIT_MSG = {
  NON_MATCHING_LANGUAGES:
    "The ad language does not match the language selected for your campaign.",
};

export const LINKEDIN_AD_AUDIT_STATUS = {
  ERROR: "error",
  UNKNOWN: "unknown",
};

export const NON_MATCHING_LANGUAGES = "NON_MATCHING_LANGUAGES";
