import React, { useState } from 'react';
import { useOrg } from 'hooks';
import { PageHeader } from 'HOCS';
import TechnologiesHeader from '../components/TechnologiesHeader';
import { Card, Flex, Loading, Tooltip } from '@sixsense/core/components';
import ListItem from '../../SalesConfig/routes/Technologies/components/ListItem';
import { useSharedState, useRequest } from '@sixsense/core';
import { css } from 'styles/emotion';
import {
  technographicsSearchState,
  technographicsState,
  userTechnographicsState,
} from '../state';
import { Button, DropdownSearch, Modal, AccessDenied } from 'v2-components';
import DnDList from '../../SalesConfig/components/DnDList';
import { get, map, uniqBy } from 'lodash';
import { actions as globalActions } from 'modules/global';
import { useDispatch, useSelector } from '@sixsense/core/versioned/react-redux';
import ProductCategorySelect from '../../SI-Psychographics/components/ProductCategorySelect';
import useCurrentUser from 'hooks/useCurrentUser';
import { useDebounce } from 'react-use';
import {
  TECHNOLOGIES_CONFIRM_TEXT,
  TECHNOLOGY_CONFIRM_TEXT,
  ADD_TECHNOLOGIES_SUCCESS_MSG,
  DELETE_TECHNOLOGIES_SUCCESS_MSG,
  ORDER_TECHNOLOGIES_SUCCESS_MSG,
  TECHNOLOGIES_ERROR_MSG,
  TECHNOLOGIES_DISABLED_TOOTLTIP,
} from '../constants';
import moment from 'moment';
import { AddTechnographicsType, OrderTechnographicsType, ProductCategory } from '../types';
import { withPermissionBoundary } from '@sixsense/rbac/hocs';
import { compose } from 'redux';
import { SalesPermission, usePermissionCheck } from '@sixsense/rbac';
import { connect } from 'react-redux';
import { siPackagePlanNameSelector } from 'routes/Settings/selectors';
import PropTypes from 'prop-types';
import { getTechnoPsychoJobCreatedDate,
  reduceTechoPsychoJobDataByProduct } from '../../SI-Psychographics/utils';
import { PRODUCT_LABELS } from '../../SI-Psychographics/constants';
import { orgObjSelector, isViewOnlyEnabledFor6senseAccessSelector } from 'modules/user/selectors';
import { MAINTENANCE_INFO } from 'routes/Settings/constants';


const styles = {
  categoryDropdown: css({
    width: '200px',
    height: '36px',
  }),
  category: css({
    width: '100%',
  }),
  container: css({
    width: '100%',
  }),
  techDropdown: css({
    height: '100%',
    padding: '6px',
  }),
  techListWrapper: css({
    background: 'white',
    border: '1px solid #C6CACE',
    marginTop: '20px',
    padding: '0px !important',
  }),
  searchBar: css({
    width: '350px',
    flex: '0 1 auto',
    paddingTop: '4px',
  }),
  buttonStyle: css({
    height: '36px',
  }),
  removeButtonStyle: css({
    height: '36px',
    background: '#FF583D',
    borderRadius: '2px',
  }),
  removeButtonLabel: css({
    color: '#ffffff !important',
  }),
  headerRow: css({
    borderBottom: '1px solid #c6cace',
    padding: '15px 30px',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
  }),
  error: css({
    marginTop: '10px',
  }),
  actionComponent: css({
    textAlign: 'left',
  }),
  actionComponentHeader: css({
    textAlign: 'left',
    fontWeight: 'bold',
  }),
  actionComponentFlex: css({
    justifyContent: 'space-between',
  }),
};

const TechnologiesContainer = ({ siPackagePlanName, org }) => {
  const { id: orgId } = useOrg();
  const user = useCurrentUser();
  const request = useRequest();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useSharedState(
    technographicsSearchState
  );
  const [technographics, setTechnographics] =
    useSharedState(technographicsState);
  const [userTechnographics, setUserTechnographics] = useSharedState(
    userTechnographicsState
  );
  const [selectedCategory, setSelectedCategory] = useState<ProductCategory>(Object);
  const [selectedTechnograph, setSelectedTechnograph] = useState<String>(null);
  const [loading, setLoading] = useState<Boolean>(false);
  const productsLabels = [
    ...get(org, 'packages.SI.products', []),
    ...PRODUCT_LABELS,
  ].reduce((acc, data) => ({ ...acc, [data.name]: data.display_name }), {});

  useDebounce(
    () => {
      if (!searchInput && selectedTechnograph) {
        setSelectedTechnograph(null);
      }
      if (searchInput) {
        technographSearch(searchInput);
      }
    },
    400,
    [searchInput]
  );
  const hasEditPermission = usePermissionCheck([SalesPermission.SETTINGS_TECHNOGRAPHICS_EDIT]);
  const isViewOnlyEnabled = useSelector(isViewOnlyEnabledFor6senseAccessSelector);

  if (!siPackagePlanName) return <AccessDenied />;
  const isAllCategoriesSelected = selectedCategory?.name === 'all';
  if (MAINTENANCE_INFO.with_read_only) return <AccessDenied message={MAINTENANCE_INFO.message} />;
  const alertMessage = (type: string, msg: string) => {
    dispatch(globalActions.showNotification(type, msg));
  };
  const checkSelected = () =>
    userTechnographics.length &&
    userTechnographics.every((el: any) => el.isSelected);
  const disableRemoveButton = () =>
    userTechnographics.length < 1 ||
    !userTechnographics.some((el) => el.isSelected);

  const onCheckSelectAll = (el) => {
    setUserTechnographics(
      userTechnographics.map((x) => ({ ...x, isSelected: el.target.checked }))
    );
  };

  const checkListItem = (id) => {
    setUserTechnographics(
      userTechnographics.map((x) =>
        x.id === id ? { ...x, isSelected: !x.isSelected } : x
      )
    );
  };
  const removeDuplicates = (array: any[], duplicateArray: any[]) => {
    const names = duplicateArray.map((el) => el.name);
    return uniqBy(array, 'technology').filter((val) => !names.includes(val.technology));
  };

  const resetState = () => {
    setSearchInput('');
    setTechnographics([]);
  };

  const onChangeCategory = () => {
    resetState();
    getUserTechnographs();
  };

  const addTechnograph = async () => {
    setLoading(true);
    const tech =
      technographics?.find(
        (el) => el.technology === selectedTechnograph
      ) || {};
    const body: AddTechnographicsType = {
      name: tech?.technology,
      technograph_id: tech?.technology_id,
      org_id: orgId,
      product: selectedCategory?.name,
      created_by: user.id,
      updated_by: user.id,
    };
    try {
      await request(
        `settings/${orgId}/technographs/?product=${selectedCategory.name}`,
        {
          method: 'POST',
          body: JSON.stringify(body),
        }
      );
      setLoading(false);
      getUserTechnographs();
      resetState();
      alertMessage('success', ADD_TECHNOLOGIES_SUCCESS_MSG);
    } catch (e) {
      setLoading(false);
      alertMessage('error', TECHNOLOGIES_ERROR_MSG);
    }
  };

  const removeTechnologies = async () => {
    const selectedTechnographics = userTechnographics
      .filter((el) => el.isSelected)
      .map((e) => ({ id: e.id }));
    try {
      await request(`settings/${orgId}/technographs/bulk_delete/`, {
        method: 'PATCH',
        body: JSON.stringify(selectedTechnographics),
      });
      getUserTechnographs();
      alertMessage(
        'success',
        DELETE_TECHNOLOGIES_SUCCESS_MSG
      );
    } catch (e) {
      alertMessage('error', TECHNOLOGIES_ERROR_MSG);
    }
  };

  const orderTechnologies = async (oldIndex, newIndex) => {
    const body: OrderTechnographicsType = {
      order: userTechnographics[newIndex].order,
    };
    try {
      await request(
        `settings/${orgId}/technographs/${userTechnographics[oldIndex].id}/
?product=${selectedCategory.name}`,
        {
          method: 'PATCH',
          body: JSON.stringify(body),
        }
      );
      getUserTechnographs();
      alertMessage('success', ORDER_TECHNOLOGIES_SUCCESS_MSG);
    } catch (e) {
      alertMessage('error', TECHNOLOGIES_ERROR_MSG);
    }
  };

  const technographSearch = async (search) => {
    try {
      const result = await request(
        `query/${orgId}/autocomplete/technology/?input_string=${encodeURIComponent(search)}`
      );
      setTechnographics(removeDuplicates(result?.suggestions || [], userTechnographics));
    } catch (ex) {
      setTechnographics([]);
    }
  };

  const getUserTechnographs = async () => {
    try {
      setLoading(true);
      const isSingleProduct = !isAllCategoriesSelected
      ? `?product=${selectedCategory?.name}`
      : '';
      let result: any[] = await request(
        `settings/${orgId}/technographs/${isSingleProduct}`
      );
      if (!isSingleProduct) {
        result = reduceTechoPsychoJobDataByProduct(result);
      }
      setUserTechnographics(result || []);
      setLoading(false);
    } catch (ex) {
      setUserTechnographics([]);
      setLoading(false);
    }
  };
  const productsTooltip = (products) => (
    <Tooltip
      overlay={
        <div>
          {products.map((el) => (
            <div>{productsLabels[el.product] || el.product} </div>
          ))}
        </div>
      }
      placement="right"
    >
      <Flex>
        <a>&nbsp;+{products.length} more</a>
      </Flex>
    </Tooltip>
  );
  const technologiesMappedList = map(userTechnographics, (el, index) => (
    <ListItem
      siDisabled={isAllCategoriesSelected}
      index={index + 1}
      key={el.id}
      label={el.name}
      ActionComponent={
        <Flex className={styles.actionComponentFlex}>
          <div className={styles.actionComponent}>
            <Flex>
              {productsLabels[el.product] || el.product}
              {el.products && el.products.length
                ? productsTooltip(el.products)
                : ''}
            </Flex>
          </div>
          <div className={styles.actionComponent}>
            {moment(getTechnoPsychoJobCreatedDate(el)).format('MMM DD, YYYY')}
          </div>
        </Flex>
      }
      isSelected={el.isSelected}
      onCheck={() => checkListItem(el.id)}
    />
  ));

  const removeLabel =
    userTechnographics.filter((el) => el.isSelected).length > 1
      ? TECHNOLOGIES_CONFIRM_TEXT
      : TECHNOLOGY_CONFIRM_TEXT;

  return (
    <div>
      {loading && <Loading.Spinner level="page" />}
      <Flex direction="row" gap={8}>
        <ProductCategorySelect
          onChange={onChangeCategory}
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
        />
        <div className={styles.searchBar} style={{ flex: 1 }}>
          {
            <DropdownSearch
              iconPlacement={false}
              id={'relevant_technologies_search'}
              handleSearchResultSelect={(technology) => {
                setSelectedTechnograph(technology);
              }}
              searchData={technographics}
              filterOption={() => true}
              mapOption="technology"
              handleInput={(userInput) => {
                if (!isAllCategoriesSelected) {
                  setSearchInput(userInput);
                  if (selectedTechnograph) {
                    setSelectedTechnograph('');
                  }
                }
              }}
              value={searchInput || ''}
              valueAccessor={'technology'}
              searchBar
              placeholder={'Search and add technology'}
            />
          }
        </div>

        <div style={{ flex: 1 }}>
          <Button
            tooltipContent={
              isAllCategoriesSelected && TECHNOLOGIES_DISABLED_TOOTLTIP
            }
            className={styles.buttonStyle}
            onClick={addTechnograph}
            type="primary"
            disabled={
              !selectedTechnograph ||
              !selectedCategory.name ||
              loading ||
              isAllCategoriesSelected ||
              !hasEditPermission || isViewOnlyEnabled
            }
          >
            Add
          </Button>
        </div>
        <div>
          <Button
            disabled={disableRemoveButton() || !hasEditPermission || isViewOnlyEnabled}
            labelClass={styles.removeButtonLabel}
            className={styles.removeButtonStyle}
            onClick={() =>
              Modal.prompt({
                type: Modal.PROMPT_TYPES.CONFIRM,
                title: removeLabel,
                onOk() {
                  removeTechnologies();
                },
              })
            }
          >
            Remove
          </Button>
        </div>
      </Flex>
      <Card.Content className={styles.techListWrapper}>
        <ListItem
          label={`Technologies (${technologiesMappedList.length})`}
          isSelected={checkSelected()}
          onCheck={(el) => {
            if (!isAllCategoriesSelected) {
              onCheckSelectAll(el);
            }
          }}
          ActionComponent={
            <Flex className={styles.actionComponentFlex}>
              <div className={styles.actionComponentHeader}>
                Product Category
              </div>
              <div className={styles.actionComponentHeader}>Created On</div>
            </Flex>
          }
        />
        {technologiesMappedList.length ? (
          <DnDList
            scrollableId={'appBody'}
            options={technologiesMappedList}
            onSortEnd={({ oldIndex, newIndex }) => {
              if (oldIndex !== newIndex && !isAllCategoriesSelected) {
                orderTechnologies(oldIndex, newIndex);
              }
            }}
          />
        ) : null}
      </Card.Content>
    </div>
  );
};

const mapStateToProps = (state) => ({
  siPackagePlanName: siPackagePlanNameSelector(state),
  org: orgObjSelector(state),
});
TechnologiesContainer.propTypes = {
  siPackagePlanName: PropTypes.string,
  org: PropTypes.object,
};

export default compose(
  withPermissionBoundary({
    allow: (permissions) => [
      SalesPermission.SETTINGS_TECHNOGRAPHICS_VIEW,
      SalesPermission.SETTINGS_TECHNOGRAPHICS_EDIT,
    ].some((permission) => permissions.has(permission)),
    renderDenied: () => <AccessDenied />,
  }),
  connect(mapStateToProps),
  PageHeader([TechnologiesHeader]),
)(TechnologiesContainer);
