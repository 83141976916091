import linkedinSegmentSyncLogo from 'images/IntegrationLogos/linkedin-logo.svg';

export const LINKEDIN_SEGMENT_SYNC_INTEGRATION_ROUTE = 'linkedin-campaign-manager/setup';

export const ACCOUNT_STATUSES = {
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  DRAFT: 'DRAFT',
  PENDING_DELETION: 'PENDING_DELETION',
  REMOVED: 'REMOVED',
};

export const ACCOUNT_STATUS_DEFINITIONS = {
  [ACCOUNT_STATUSES.ACTIVE]: 'Indicates an active account able to serve ads.',
  [ACCOUNT_STATUSES.CANCELED]: `Indicates a canceled account unable to serve ads. 
   LinkedIn ad account once canceled can't be reactivated.`,
  [ACCOUNT_STATUSES.PENDING_DELETION]: `Indicates an account that has been requested to be 
    deleted is currently pending`,
  [ACCOUNT_STATUSES.REMOVED]: `Indicates an account which was deleted, but must remain 
    fetchable due to the existence of performance data.`,
  [ACCOUNT_STATUSES.DRAFT]: `Account is in draft status, meaning it is not 
    yet fully set up and it is not serving.`,
};

export const ACCOUNT_STATUS_TOOLTIP = {
  [ACCOUNT_STATUSES.CANCELED]: 'Your ad account is permanently canceled.',
  [ACCOUNT_STATUSES.PENDING_DELETION]: 'Your ad account is pending deletion.',
  [ACCOUNT_STATUSES.REMOVED]: 'Your ad account is deleted',
};

export const LinkedinConfig = {
  header: {
    logo: linkedinSegmentSyncLogo,
    mainTitle: '6sense Segments for LinkedIn',
    category: 'Ads',
    subTitle: 'by LinkedIn.Inc',
  },
  about: {
    mainInfo: {
      title: 'About this Integration',
      description:
      'The 6sense integration with LinkedIn Ads allows revenue '+
      'teams to leverage AI insights for hyper-targeted advertising on the LinkedIn network,'+
      ' reaching high value accounts at the right time and promoting cost efficiency.',
    },
    metaInfo: [
      {
        name: 'Category',
        value: 'Ads',
      },
      {
        name: 'Feature Support',
        value: 'Advertising, Segments',
      },
    ],
    keyfeatures: {
      title: 'Key Features',
      features: [
        {
          name: 'Target the best accounts',
          value: 'By leveraging AI-driven ICP fit predictions for each account.',
        },
        {
          name: 'Orchestrate Account Engagement',
          value:
              'Using dynamic segments for hyper-targeted campaigns and outreach.',
        },
        {
          name: 'Automate Workflows and Improve Efficiency ',
          value: 'By focusing on accounts that matter to your team',
        },
      ],
    },
  },
  help: {
    links: [
      {
        title: 'Additional Resources',
        list: [
          {
            text: '6sense Segments for LinkedIn Ads',
            href: 'https://support.6sense.com/hc/en-us/articles/360046590094',
          },
          {
            text: 'FAQs: 6sense Segments for LinkedIn Ads',
            href: 'https://support.6sense.com/hc/en-us/articles/360044773854-FAQ-LinkedIn-Push',
          },
        ],
      },
    ],
  },
};
