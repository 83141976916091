import React from 'react';
import PropTypes from 'prop-types';
import { SixsenseLogo } from 'images/SixsenseLogo';
import { LogoTxt } from 'images/LogoTxt';

export function SixSenseLogo(props) {
  const { className, light, branded } = props;

  if (branded) {
    return <LogoTxt light className={className} />;
  }

  return <SixsenseLogo light={light} className={className} />;
}

SixSenseLogo.propTypes = {
  className: PropTypes.string,
  light: PropTypes.bool,
  branded: PropTypes.bool,
};

export default SixSenseLogo;
