export default function createRoutes() {
  return {
    path: 'sep',
    name: 'SEP Settings',

    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          cb(null, containers.SEPFormContainer);
        }, 'sep'
      );
    },
  };
}
