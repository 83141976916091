import React from 'react';
import PropTypes from 'prop-types';
import { ProgressCircle, Tooltip, Flex, Skeleton } from '@sixsense/core/components';

const getCircleState = (progress) => {
  let state ='SUCCESS';
  if (progress <= 35) {
    state = 'ERROR';
  } else if (progress <= 70) {
    state = 'WARNING';
  }
  return state;
};

export const ProgressIndicator = ({
  progress,
  percentageIndictatorRequired=true,
  size='sm',
  overlay,
  loading=false,
}) => {
  const props = {
    progress,
    size,
    state: getCircleState(progress),
  };
  if (percentageIndictatorRequired) {
    // eslint-disable-next-line react/prop-types
    props.text = `${progress}`;
  }
  if (loading) {
    return (<Flex>
      <Skeleton style={{ height: 40, width: 40 }} variant="avatar" />
    </Flex>);
  }
  if (overlay) {
    return (
      <Tooltip overlay={overlay} placement="top">
        <Flex>
          <ProgressCircle
            {...props}
          />
        </Flex>
      </Tooltip>
    );
  }
  return (
    <ProgressCircle
      {...props}
    />
  );

};

ProgressIndicator.propTypes = {
  progress: PropTypes.number,
  percentageIndictatorRequired: PropTypes.bool,
  size: PropTypes.string,
  overlay: PropTypes.element,
  loading: PropTypes.bool,
};

