export const defaults = {
  keywordData: [],
  keywordModalTitle: '',
  keywordModalHeader: '',
  showKeywordCount: false,
  showActivityModal: false,
  isActivityModalOverlapped: false,
  locationFilter: [],
  activityTypeFilter: [],
  engagementReachModalData: {
    tooltipType: '',
    activitiesByDate: [],
    opportunitiesByDate: [],
    currentIndex: -1,
    __typename: 'modalMetaData',
  },

  __typename: 'defaultData',
};
