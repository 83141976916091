export const tabOptions = {
  all: 'all_account_count',
  website: 'activity_account_count',
  b2bNetwork: 'keyword_account_count',
  sixsenseMedia: 'fpm_account_count',
  externalMedia: 'tpm_account_count',
};

export const statFields = {
  // dashboard
  all_account_count: 'all_account_count',
  activity_account_count: 'event',
  keyword_account_count: 'keyword',
  fpm_account_count: 'campaign',
  tpm_account_count: 'tpm',
  crm_account_count: 'crm',
  map_account_count: 'map',


  // segment analytics
  all: 'all_account_count',
  website_events: 'event',
  keyword_events: 'keyword',
  fpm_events: 'campaign',
  tpm_events: 'tpm',
  crm_events: 'crm',
  map_events: 'map',


  // account detail
  all_activity_count: 'all_account_count',
  activity_count: 'event',
  keyword_activity_count: 'keyword',
  fpm_impression_count: 'campaign',
  tpm_impression_count: 'tpm',
  map_activity_count: 'map',
  crm_activity_count: 'crm',
};

export const nameMap = {
  // website activity
  activity_account_count: {
    name: 'Accounts',
    link: true,
    order: 0,
    filter: {
      activity_count: {
        conditions: [{
          operator: '>',
          value: 0,
        }],
      },
    },
  },
  activity_vid_count: { name: 'Visitors', link: false, order: 1 },
  activity_a_pageload_count: { name: 'Page Views', link: false, order: 2 },
  activity_submit_count: { name: 'Form Submits', link: false, order: 3 },
  activity_play_count: { name: 'Video Plays', link: false, order: 4 },

  // b2b network activity
  keyword_account_count: {
    name: 'Accounts',
    link: true,
    filter: {
      keyword_count: {
        conditions: [{
          operator: '>',
          value: 0,
        }],
      },
    },
  },
  keyword_branded_count: {
    name: 'Branded Keywords',
    link: true,
    order: 1,
    keyword: true,
    filter: 'branded',
  },
  keyword_generic_count: {
    name: 'Generic Keywords',
    link: true,
    order: 2,
    keyword: true,
    filter: 'generic',
  },
  keyword_activity_count: {
    name: 'Research Activities',
    link: false,
    order: 3,
  },

  // 6sense media campaigns
  reach_account_count: {
    name: 'Accounts Reached',
    link: true,
    order: 0,
    keyword: false,
    filter: [
      {
        fpm_impression_count: {
          conditions: [{
            operator: '>',
            value: 0,
          }],
        },
      },
      {
        tpm_impression_count: {
          conditions: [{
            operator: '>',
            value: 0,
          }],
        },
      },
    ],
  },
  account_ctr: {
    name: 'Account Clickthrough Rate',
    link: false,
    percent: true,
    order: 1,
  },
  account_vtr: {
    name: 'Account Viewthrough Rate',
    link: false,
    percent: true,
  },

  // third party media:
  total_impression_count: {
    name: 'Total Impressions',
    link: false,
    order: 1,
  },
  reach_impression_count: {
    name: 'Matched Impressions',
    link: false,
    order: 2,
  },

  engage_account_count: {
    name: 'Accounts Engaged',
    link: false,
    order: -1,
  },

  engage_count: {
    name: 'Engagement Activities',
    link: false,
    order: 0,
  },

  reach_count: {
    name: 'Reach Activities',
    link: false,
    order: 1,
  },
};

export const CRM_CATEGORIES = new Set(['crm_account_count', 'crm_events']);
export const MAP_CATEGORIES = new Set(['map_account_count', 'map_events']);
