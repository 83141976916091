export default function createRoutes() {
  return {
    path: 'privacyoptout',
    name: 'privacyoptout',
    getComponent(nextState, cb) {
      import('./containers/PrivacyOptOut').then((page) => {
        cb(null, page.default);
      });
    },
  };
}
