const GLOBAL_SEARCH = 'GLOBAL_SEARCH/SEARCH';
const LOAD_ACCOUNT_LIST_SUCCESS = 'GLOBAL_SEARCH/LOAD_ACCOUNT_LIST_SUCCESS';
const LOAD_ACCOUNT_LIST_FAIL = 'GLOBAL_SEARCH/LOAD_ACCOUNT_LIST_FAIL';
const SET_INPUT = 'GLOBAL_SEARCH/SET_INPUT';
const RESET_INPUT = 'GLOBAL_SEARCH/RESET_INPUT';
const TOGGLE_SEARCH = 'GLOBAL_SEARCH/TOGGLE_SEARCH';
const ACCOUNT_SELECT = 'GLOBAL_SEARCH/ACCOUNT_SELECT';

function resetInput() {
  return { type: RESET_INPUT };
}

function setSearchInput(searchInput) {
  return { type: SET_INPUT, searchInput };
}

function toggleSearchBar() {
  return { type: TOGGLE_SEARCH };
}

function selectAccount(mid) {
  return { type: ACCOUNT_SELECT, mid };
}

function search() {
  return { type: GLOBAL_SEARCH };
}

function loadAccountsSuccess(accounts = [], count = 0) {
  return { type: LOAD_ACCOUNT_LIST_SUCCESS, accounts, count };
}

function loadAccountsFail(errorString) {
  return { type: LOAD_ACCOUNT_LIST_FAIL, errorMessage: errorString };
}

const initialState = {
  searchInput: '',
  accounts: [],
  offset: 0,
  limit: 48,
  count: 0,
  sortBy: 'user_count',
  showSearchBar: false,
  loading: false,
};

function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INPUT: {
      const { searchInput } = action;
      return { ...state, searchInput };
    }
    case RESET_INPUT: {
      return { ...state, searchInput: '', showSearchBar: false };
    }
    case ACCOUNT_SELECT: {
      const { mid } = action;
      return { ...state, mid };
    }
    case GLOBAL_SEARCH: {
      return { ...state, loading: true };
    }
    case TOGGLE_SEARCH: {
      const { showSearchBar } = state;
      return { ...state, showSearchBar: !showSearchBar };
    }
    case LOAD_ACCOUNT_LIST_SUCCESS: {
      const accounts = action.accounts;
      const newOffset = Math.min(action.count, state.offset + state.limit);
      const count = action.count;
      return {
        ...state,
        loading: false,
        error: false,
        offset: newOffset,
        count,
        accounts,
      };
    }
    case LOAD_ACCOUNT_LIST_FAIL: {
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.errorMessage,
      };
    }
    default: {
      return state;
    }
  }
}

export const actions = {
  selectAccount,
  setSearchInput,
  toggleSearchBar,
  resetInput,
  search,
  loadAccountsSuccess,
  loadAccountsFail,
};

export const actionTypes = {
  GLOBAL_SEARCH,
  LOAD_ACCOUNT_LIST_SUCCESS,
  LOAD_ACCOUNT_LIST_FAIL,
  ACCOUNT_SELECT,
};

export default searchReducer;
