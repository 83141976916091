import React from 'react';
import { Flex } from '@sixsense/core/components';
import { css } from 'styles/emotion';
import { Text } from 'v2-components';

const styles = {
  container: css({
    padding: '10px 30px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 2px 0 rgba(74,87,94,0.2)',
  }),
  subBody: css({
    marginLeft: '12px',
  }),
};

const TalkingPointsHeader = () => (
  <Flex className={styles.container}>
    <span>
      <Text type={Text.TYPE.TITLE} weight="bold">
        Opt-Out List
      </Text>
    </span>
    <div style={{ flex: 1 }} />
  </Flex>
  );

export default TalkingPointsHeader;
