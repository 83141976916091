/**
 * defaults.js + resolvers.js should live in the base level of any route using
 * graphql and imported here
 */
import {
  defaults as accountDetailDefaults,
} from 'routes/Discover/routes/Account/routes/AccountDetail/defaults';

import {
  resolvers as accountDetailResolvers,
} from 'routes/Discover/routes/Account/routes/AccountDetail/resolvers';

const baseDefaults = {};

const baseResolvers = {};

export const defaults = {
  ...baseDefaults,

  // route specific defaults:
  ...accountDetailDefaults,
};


export const resolvers = {
  ...baseResolvers,

  // route specific resolvers:
  ...accountDetailResolvers,
};

export const typeDefs = `
type StringOption {
  label: String!
  value: String!
}
type NumberOption {
  label: String!
  value: Int!
}
type LoginInput {
  username: String!
  password: String!
  organization: String!
}
`;
