import { AD_LIBRARY_BASE_ROUTE } from './routes/AdLibrary/constants';
import { AD_BASE_ROUTE } from './routes/AdLibrary/routes/AdPlacement/constants';
import { AD_LIST_BASE_ROUTE } from './routes/AdLibrary/routes/AdPlacementList/constants';
import {
  AD_CREATE_BASE_ROUTE,
} from './routes/AdLibrary/routes/AdPlacement/routes/CreateAdPlacement/constants';
import {
  AD_EDIT_BASE_ROUTE,
} from './routes/AdLibrary/routes/AdPlacement/routes/EditAdPlacement/constants';
import { values } from 'lodash';
import sixSenseIcon from 'images/6sense-icon.svg';
import LinkedInIcon from 'images/linkedin_hq.svg';
import { FEATURE_FLAGS } from 'constants/featureFlags';

export const ADVERTISING_BASE_ROUTE = 'advertising-old';

export const ADVERTISING_REDUX_STATE_KEY = 'advertising';

export const ADVERTISING_ACTION_PREFIX = 'ADVERTISING';

export const SIXSENSE = '6Sense';

export const LINKEDIN = 'LinkedIn';

export const ADVERTISING_CATEGORIES = {
  CAMPAIGNS: 'campaigns',
  AD_LIBRARY: 'ad-library',
};

export const STATIC_TREE_NODE_KEYS = {
  CAMPAIGN_LIBRARY_ROOT: 'campaign_library',
  CAMPAIGN_DRAFT_FOLDER: 'draft_folder',
  CAMPAIGN_ALL: 'all_campaigns',
  AD_LIBRARY_ROOT: 'ad_library',
  AD_LIBRARY_ALL: 'all_ads',
};

export const ADVERTISING_PATHS = {
  advertising: `${ADVERTISING_BASE_ROUTE}`,
  adLibrary: `${ADVERTISING_BASE_ROUTE}/${AD_LIBRARY_BASE_ROUTE}`,
  adsList: `${ADVERTISING_BASE_ROUTE}/${AD_LIBRARY_BASE_ROUTE}/${AD_LIST_BASE_ROUTE}`,
  ad: `${ADVERTISING_BASE_ROUTE}/${AD_LIBRARY_BASE_ROUTE}/${AD_BASE_ROUTE}`,
  adCreate:
    `${ADVERTISING_BASE_ROUTE}/${AD_LIBRARY_BASE_ROUTE}/${AD_BASE_ROUTE}/${AD_CREATE_BASE_ROUTE}`,
  adEdit:
    `${ADVERTISING_BASE_ROUTE}/${AD_LIBRARY_BASE_ROUTE}/${AD_BASE_ROUTE}/${AD_EDIT_BASE_ROUTE}`,
};

export const FILTER_FIELDS = {
  createdByMe: 'createdByMe',
  auditStatuses: 'auditStatuses',
  folders: 'folders',
  campaigns: 'campaigns',
  adTypes: 'adTypes',
};

export const CAMPAIGN_ROW_DRAGGABLE_TYPE = 'CAMPAIGN_ROW_DRAGGABLE_TYPE';

export const SELECTED_FOLDER_SEARCH_PARAM = 'folder';

export const DRAFT_FOLDER_LABEL = 'Drafts';
export const ALL_ADS_LABEL = 'All Ads';

export const AD_LIBRARY_FILTER_FIELDS = {
  [FILTER_FIELDS.adTypes]: 'placement_type__in',
  [FILTER_FIELDS.campaigns]: 'campaign_id__in',
  [FILTER_FIELDS.folders]: 'folder_id__in',
  [FILTER_FIELDS.createdByMe]: 'created_by_me',
};

export const PAGE_NUMBER_SEARCH_PARAM = 'page';


// values also used in API calls
export const AD_TYPE_IDS = {
  bannerAd: 'banner',
  videoAd: 'video',
  nativeAd: 'native',
  html5Ad: 'html5',
  html5DynamicAd: 'html5_dynamic',
  linkedInSingleImageAd: 'linkedin_image',
  linkedInVideoAd: 'linkedin_video',
};

export const AD_TYPE_LABELS = {
  [AD_TYPE_IDS.bannerAd]: 'Banner Ad',
  [AD_TYPE_IDS.videoAd]: 'Video Ad',
  [AD_TYPE_IDS.nativeAd]: 'Native Ad',
  [AD_TYPE_IDS.html5Ad]: 'HTML5 Ad',
  [AD_TYPE_IDS.html5DynamicAd]: 'HTML5 Dynamic Ad',
  [AD_TYPE_IDS.linkedInSingleImageAd]: 'Single Image Ad',
  [AD_TYPE_IDS.linkedInVideoAd]: 'Video Ad',
};

export const AD_TYPE_FILTERS = {
  sixsense: {
    icon: sixSenseIcon,
    subTitle: SIXSENSE,
    adTypes: [
      AD_TYPE_IDS.bannerAd,
      AD_TYPE_IDS.videoAd,
      AD_TYPE_IDS.nativeAd,
      AD_TYPE_IDS.html5Ad,
      AD_TYPE_IDS.html5DynamicAd,
    ],
  },
  linkedIn: {
    icon: LinkedInIcon,
    subTitle: LINKEDIN,
    adTypes: [
      AD_TYPE_IDS.linkedInSingleImageAd,
      AD_TYPE_IDS.linkedInVideoAd,
    ],
    featureFlag: FEATURE_FLAGS.hasLinkedinAdvertising,
  },
};

export const LINKEDIN_PREVIEW_OPTIONS = {
  desktop: { label: 'Desktop feed', value: 'desktop' },
  mobile: { label: 'Mobile feed', value: 'mobile' },
};

export const VIEWABILITY = 'viewability';
export const LINKEDIN_CPC_BIDDING = 'linkedin_cpc_bidding';

export const FEATURE_IDENTIFIERS = {
  CPM_RECOMMENDATION: 'cpm_recommendation',
  BRAND_SAFETY: 'brand_safety',
};

export const NEW_FEATURES_IDENTIFIER=values(FEATURE_IDENTIFIERS);

export const AD_CLONE_NAME_SUFFIX = ' - Clone';

export const OLD_ADVERTISING_EXPERIENCE_KEY = 'oldAdvertisingExperience';
