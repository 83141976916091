import { zipper as zipper_, Loc } from '../zipper';
import {
  PRIMITIVE_EXPRESSIONS,
  LOGICAL_EXPRESSIONS,
  BINARY_EXPRESSIONS,
  NEGATIVE_BINARY_EXPRESSIONS,
  BETWEENOP,
  INOP,
  NOTOP,
  NULLEXPR,
} from 'ast-redux/constants';
import { TYPE_CONSTRUCTOR_MAP } from 'ast-redux/constructors';
import { isLogical } from 'ast-redux/utils';

const isBranch = (node) => !PRIMITIVE_EXPRESSIONS.has(node.type);

const children = (node) => {
  const nodeType = node.type;
  if (
    BINARY_EXPRESSIONS.has(nodeType) ||
      NEGATIVE_BINARY_EXPRESSIONS.has(nodeType) ||
      LOGICAL_EXPRESSIONS.has(nodeType)
  ) {
    return [
      { value: node.lhs, key: 'lhs' },
      { value: node.rhs, key: 'rhs' },
    ];
  }

  if (PRIMITIVE_EXPRESSIONS.has(nodeType)) {
    return [];
  }

  switch (nodeType) {
    // Non Generic Expressions
    case BETWEENOP:
      return [
        { value: node.ge_op, key: 'ge_op' },
        { value: node.le_op, key: 'le_op' },
        { value: node.expr, key: 'expr' },
      ];
    case INOP:
      return { value: node.exprs, key: 'exprs' };
    case NOTOP:
      return [{ value: node.expr, key: 'expr' }];
    default:
      throw new Error(`Unknown ${nodeType}`);
  }
};

const makeNode = (node, kids) => {
  const nodeType = node.type;
  if (
    BINARY_EXPRESSIONS.has(nodeType)
    || LOGICAL_EXPRESSIONS.has(nodeType)
    || NEGATIVE_BINARY_EXPRESSIONS.has(nodeType)
  ) {
    const [lhs, rhs] = kids;
    return TYPE_CONSTRUCTOR_MAP[nodeType](lhs, rhs, node.order);
  }
  switch (nodeType) {
    // Non Generic Expressions
    case NULLEXPR:
      return TYPE_CONSTRUCTOR_MAP[nodeType];
    case BETWEENOP: {
      const [ge_op, le_op, expression] = kids;
      return TYPE_CONSTRUCTOR_MAP[nodeType](ge_op, le_op, expression);
    }
    default:
      throw new Error(`Can't unpack ${nodeType}`);
  }
};

const TLoc = (loc) => {
  if (loc && loc instanceof Loc === false) {
    throw new TypeError(
      `Invalid type, this function expected a location, but may have received a node.
      Did you zipper the argument?`);
  }
  return loc;
};

const zipper = (node) =>
  zipper_(node, isBranch, children, makeNode, isLogical);

export {
  isBranch,
  children,
  makeNode,
  TLoc,
  zipper,
};
