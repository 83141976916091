import { PAGE_STATE_KEY } from "./constants";
import {
  ADVERTISING_PATHS,
  PERFORMANCE_TREND_ROUTE,
  REPORTS_ROUTE,
} from "routes/AdvertisingDS/constants";
import PerformanceTrendRoute from "./routes/PerformanceTrend";
import { getAsyncInjectors } from "store/utils";

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: REPORTS_ROUTE,
    name: "Reports",
    indexRoute: {
      onEnter: (nextState, replace) =>
        replace(`/${ADVERTISING_PATHS.reports}/${PERFORMANCE_TREND_ROUTE}`),
    },
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require("./containers");
          const reducer = require("./duck/reducers");
          const sagas = require("./duck/sagas");
          injectReducer(PAGE_STATE_KEY, reducer.default);
          injectSagas(PAGE_STATE_KEY, sagas.default);
          cb(null, containers.Reports);
        },
        "reports"
      );
    },
    childRoutes: [PerformanceTrendRoute(store)],
  };
}
