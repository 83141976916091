import React, { useEffect, useState } from 'react';
import { css } from 'styles/emotion';
import { Flex, Avatar, Toggle } from '@sixsense/core/components';
import { Text, Radio, Tooltip2 as Tooltip } from 'v2-components';
import { useRequest } from '@sixsense/core';
import writerIcon from '../images/ai_writer_icon.svg';
import { ProductCard } from './ProductCard';
import { UnsavedChangesModal } from './UnsavedChangesModal';
import {
  adminLabel,
  allUsersLabel,
  AIWRITER_NO_EDIT_PERMISSIONS_MESSAGE,
} from '../constant';

const styles = {
  card: css({
    width: '100%',
    border: '0.5px solid #E2E4E7',
    borderRadius: '6px',
    backgroundColor: '#fff',
    position: 'relative',
    marginTop: '20px',
  }),
  text: css({
    marginLeft: '15px',
  }),
  title: css({
    fontSize: '18px',
    lineHeight: '24px',
  }),
  subBody: css({
    fontSize: '14px',
    lineHeight: '24px',
    color: '#696F7B',
  }),
  toggleSwitch: css({
    position: 'absolute',
    right: '20px',
    top: '30px',
  }),
  radio: css({
    border: 'none',
    paddingLeft: 0,
    marginRight: '30px',
  }),
  topSection: css({
    padding: '20px',
  }),
  bottomSection: css({
    borderTop: '1px solid #E2E4E7',
    padding: '16px',
    paddingBottom: '50px',
  }),
  image: css({
    marginRight: '15px',
  }),
};

type Props = {
  metricsConfig: any;
  orgId: number;
  updateLoader: any;
  enableAIWriterCard: boolean;
  isComponentMetricsAPIForbidden: boolean;
};

export const AIWriterCard = ({
  metricsConfig,
  orgId,
  updateLoader,
  enableAIWriterCard,
  isComponentMetricsAPIForbidden,
}: Props) => {
  const [isEnabled, setIsEnabled] = useState<Boolean>(false);
  const [onlyAdminEnabled, setOnlyAdminEnabled] = useState<Boolean>(false);
  const [allUsersEnabled, setAllUsersEnabled] = useState<Boolean>(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [collapsedState, setCollapsedState] = useState<Array<Boolean>>([]);
  const request = useRequest();
  const toggleDisabled = !enableAIWriterCard || isComponentMetricsAPIForbidden;

  useEffect(() => {
    if (metricsConfig?.genai_email_writer_enabled) {
      setIsEnabled(true);
      setOnlyAdminEnabled(metricsConfig?.genai_email_writer_enabled_admin_only);
      setAllUsersEnabled(!metricsConfig?.genai_email_writer_enabled_admin_only);
    } else {
      setIsEnabled(false);
      setOnlyAdminEnabled(false);
      setAllUsersEnabled(false);
    }
  }, [metricsConfig]);

  const handleCollapseChange = (newCollapsedState) => {
    setCollapsedState(newCollapsedState);
  };
  const isAnyCollapsed = collapsedState.some((state) => state === true);

  const onChange = async (type: string) => {
    if (type === 'admin' && !onlyAdminEnabled) {
      postMetricsChange({ genai_email_writer_enabled_admin_only: true });
    } else if (type === 'all_users' && onlyAdminEnabled) {
      postMetricsChange({ genai_email_writer_enabled_admin_only: false });
    }
  };

  const onToggle = () => {
    if (isEnabled && isAnyCollapsed) {
      setOpenUnsavedChangesModal(true);
    } else {
      onToggleOnModalConfirm();
    }
  };

  const onToggleOnModalConfirm = () => {
    const payload = {
      genai_email_writer_enabled: !isEnabled,
    };
    postMetricsChange(payload);
  };

  const postMetricsChange = async (payload: Object) => {
    try {
      updateLoader(true);
      const res: any = await request(
        `settings/${orgId}/components-and-metrics/${metricsConfig.id}/`,
        {
          method: 'PATCH',
          body: JSON.stringify(payload),
        }
      );
      setIsEnabled(res?.genai_email_writer_enabled);
      setOnlyAdminEnabled(
        res?.genai_email_writer_enabled &&
          res?.genai_email_writer_enabled_admin_only
      );
      setAllUsersEnabled(
        res?.genai_email_writer_enabled &&
          !res?.genai_email_writer_enabled_admin_only
      );
      updateLoader(false);
    } catch (ex) {
      updateLoader(false);
    }
  };

  return (
    <div className={styles.card}>
      <Flex
        className={styles.topSection}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex>
          <Avatar src={writerIcon} className={styles.image} />
          <span className={styles.text}>
            <Text type={Text.TYPE.TITLE} className={styles.title} weight="bold">
              AI Writer
            </Text>
            <br />
            <Text type={Text.TYPE.SUBBODY} className={styles.subBody}>
              Combine your approved resources with 6sense data to create
              targeted, personalized outreach emails.{' '}
            </Text>
          </span>
        </Flex>
        <Flex
          direction="column"
          data-6si-name="container--toggle-allow-ai-writer"
          data-6si-id={`allow_ai_writer_toggle--${isEnabled}`}
        >
          <Tooltip
            placement={'left'}
            overlay={toggleDisabled && AIWRITER_NO_EDIT_PERMISSIONS_MESSAGE}
          >
            <Toggle
              disabled={toggleDisabled}
              className={styles.toggleSwitch}
              value={isEnabled}
              onChange={onToggle}
            />
          </Tooltip>
        </Flex>
      </Flex>
      {isEnabled && (
        <Flex className={styles.bottomSection} direction="column">
          <Flex>
            <Radio
              className={styles.radio}
              selected={onlyAdminEnabled}
              item={{
                label: adminLabel,
                value: 'admin',
              }}
              action={() => onChange('admin')}
            />
            <Radio
              className={styles.radio}
              item={{
                label: allUsersLabel,
                value: 'all_users',
              }}
              selected={allUsersEnabled}
              action={() => onChange('all_users')}
            />
          </Flex>
          <ProductCard orgId={orgId} onCollapseChange={handleCollapseChange} />
          {openUnsavedChangesModal && (
            <UnsavedChangesModal
              onClickCloseHandler={() => {
                setOpenUnsavedChangesModal(!openUnsavedChangesModal);
                onToggleOnModalConfirm();
              }}
              onClickContinueHandler={() => {
                setOpenUnsavedChangesModal(!openUnsavedChangesModal);
              }}
            />
          )}
        </Flex>
      )}
    </div>
  );
};
