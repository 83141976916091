import React, { useEffect, useState } from 'react';
import { css } from 'styles/emotion';
import {
  Link,
  Card,
  Flex,
  ProgressSteps,
  StepperProps,
  Grid,
  Button,
  Badge,
  useToast,
  Loading,
  Text as Text2,
} from '@sixsense/core/components';
import { CompanyProductDetails } from './CompanyProductDetails';
import { MarketingSalesMaterial } from './MarketingSalesMaterial';
import { UnsavedChangesModal } from './UnsavedChangesModal';
import { SummaryComponent } from './SummaryComponent';
import { Text } from 'v2-components';
import {
  Plus,
  ChevronUp,
  ChevronDown,
  Edit,
  CheckCircle,
  XClose,
} from '@sixsense/core/icons';
import EditableContent from './EditableContent';
import { useRequest } from '@sixsense/core';
import { Step } from '@sixsense/core/components/Stepper/Stepper';
import {
  companyProductAttributes,
  SUMMARY_STEP_SUBLABEL,
  TOAST_KEY_MAP,
} from '../constant';
import { hasRequiredMarketingValues, hasRequiredValues } from '../utils';

const STEPS: Step[] = [
  {
    completed: false,
    disabled: false,
    label: 'Company/Product Details',
    subLabel: 'Describe your company, products, partners, and competitors.',
  },
  {
    completed: false,
    disabled: true,
    label: 'Marketing/Sales Material',
    subLabel: 'Who do you sell to?',
  },
  {
    label: 'Summary',
    subLabel: SUMMARY_STEP_SUBLABEL,
    disabled: true,
  },
];

const STEP = {
  COMPANY_AND_PRODUCT: 0,
  MARKETING_SALES_MATERIAL: 1,
  SUMMARY: 2,
};

const styles = {
  productCard: css({
    margin: '20px 0px 0px 0px !important',
    padding: '16px',
    height: '68px',
  }),
  collapsedProductCard: css({
    height: 'auto',
    margin: '0px !important',
    borderTop: 'none !important',
    borderRadius: '0px 0px 6px 6px !important',
    transition: 'height 200ms ease, opacity 200ms ease',
  }),
  productTitle: css({
    fontSize: '16px',
    lineHeight: '20px',
  }),
  addProductText: css({
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
  }),
  plusIcon: css({
    marginRight: '8px',
  }),
  progressSteps: css({
    height: '260px',
    '> div': {
      height: '100%',
    },
  }),
  customBtn: css({
    borderRadius: '6px !important',
    height: '36px !important',
  }),
  nextBtn: css({
    borderRadius: '6px !important',
    height: '36px !important',
    ':disabled': {
      cursor: 'not-allowed',
      borderColor: '#99DEF7 !important',
      background: '#99DEF7 !important',
      color: '#FFFFFF !important',
    },
  }),
  editBlock: css({
    maxHeight: '300px',
    overflow: 'auto',
    '::-webkit-scrollbar': {
      width: '5px;', /* Adjust the width */
    },
    '::-webkit-scrollbar-thumb': {
      background: '#c1c1c1',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#888',
    },
  }),
  contentStyle: css({
    fontSize: '14px',
    lineHeight: '21px',
    marginTop: '8px',
    marginBottom: '20px',
    textWrap: 'wrap',
    color: '#4D5761',
  }),
  editBtn: css({
    height: '36px !important',
    minHeight: '36px !important',
    width: '36px',
    borderRadius: '6px !important',
    padding: '9px !important',
    marginLeft: '16px',
    marginRight: '16px',
    // marginLeft: 'auto',
    '> div': {
      height: '12px',
    },
  }),
  unpublishedText: css({
    fontSize: '14px',
    color: '#696F7B',
  }),
  badgeStyle: css({
    marginLeft: '8px',
    '> span': {
      fontWeight: 600,
    },
  }),
  loadingWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    padding: '40px',
  }),
};

type ProductCardProps = {
  product: any;
  productIndex: any;
  orgId: any;
  isCollapsed: any;
  toggleCollapseValue: any;
  activeSteps: any;
  setActiveSteps: any;
  getProductList: any;
  productListApiSuccess: boolean;
};

export const ProductCardComponent = ({
  product,
  productIndex,
  orgId,
  isCollapsed,
  toggleCollapseValue,
  activeSteps,
  setActiveSteps,
  getProductList,
  productListApiSuccess,
}: ProductCardProps) => {
  const [productAttributes, setProductAttributes] = useState([]);
  const [steps, setSteps] = useState<StepperProps.steps>(STEPS);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [editBtnClicked, setEditBtnClicked] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [nextButtonEnabled, setNextButtonEnabled] = useState({});
  const [isUseThisSummaryEnabled, setIsUseThisSummaryEnabled] = useState(true);
  const [isContentEditableField, setIsContentEditableField] = useState(false);
  const [editableContent, setEditableContent] = useState({});
  const [initialEditableContent, setInitialEditableContent] = useState({});
  const [isModalClickedFromParent, setIsModalClickedFromParent] =
    useState(false);
  const [generatedSummary, setGeneratedSummary] = useState('');
  const [generatedCompetitorSummary, setGeneratedCompetitorSummary] = useState([]);
  const request = useRequest();
  const toast = useToast();
  const productSummaryStatus = {};
  const isSummaryAccepted = {};
  const [isProductAttributesEdited, setIsProductAttributesEdited] =
    useState(false);
  const [isProductAttributesLoaded, setIsProductAttributesLoaded] =
    useState(false);
  const [isSaveAsDraftAPISuccess, setIsSaveAsDraftAPISuccess] = useState(false);
  const [websiteUrlError, setWebsiteUrlError] = useState<Boolean>(false);
  const [isWebsiteMaxLengthExceeded, setIsWebsiteMaxLengthExceeded] = useState<Boolean>(false);
  const [isTargetPersonaMaxLengthExceeded, setIsTargetPersonaLengthExceeded] = useState([]);
  const [isCompitetorMaxLengthExceeded, setIsCompitetorMaxLengthExceeded] = useState([]);
  const hasErrorsTargetPersona = isTargetPersonaMaxLengthExceeded?.some((error) => error.hasError)
  || isCompitetorMaxLengthExceeded?.some((error) => error.hasError);

  const nullCheckForFieldsInCurrentStep = () => {
    if (activeSteps[productIndex] === STEP.COMPANY_AND_PRODUCT) {
      return hasRequiredValues(
        productAttributes[productIndex],
        companyProductAttributes,
      ) && hasRequiredMarketingValues(
        productAttributes[productIndex]?.competitors
      );
    } else if (activeSteps[productIndex] === STEP.MARKETING_SALES_MATERIAL) {
      return hasRequiredMarketingValues(
        productAttributes[productIndex]?.personas
      );
    }
    return true;
  };

  const showErrorToast = (isSaveAsDraft = false) => {
    toast.show({
      type: 'icon',
      icon: XClose,
      iconColor: 'Error',
      title: isSaveAsDraft ? 'Failed to save draft' : 'Oops!',
      subtitle: isSaveAsDraft
        ? 'We experienced an error in our system. To try again, click Save as Draft.'
        : 'Something went wrong. Please try again',
      key: TOAST_KEY_MAP.ERROR_PUBLISHING_SUMMARY,
    });
  };

  useEffect(() => {
    const callfetchEmailWriterAPI = async () => {
      try {
        await fetchEmailWriterProductAttributes();
      } catch (error) {
        showErrorToast();
      }
    };
    if (!isProductAttributesLoaded) {
      callfetchEmailWriterAPI();
    }
  }, [productListApiSuccess]);

  useEffect(() => {
    const validate = nullCheckForFieldsInCurrentStep();
    setNextButtonEnabled({
      ...nextButtonEnabled,
      [productIndex]: {
        ...nextButtonEnabled[productIndex],
        [activeSteps[productIndex]]: validate,
      },
    });
  }, [productAttributes[productIndex], activeSteps]);

  const updateSharedState = (key: any, value: string | string[]) => {
    const updateProducts = [...productAttributes];
    updateProducts[productIndex] = {
      ...updateProducts[productIndex],
      [key]: value,
    };
    setIsProductAttributesEdited(true);
    setProductAttributes(updateProducts);
  };

  const fetchEmailWriterProductAttributes = async () => {
    const attributesUrl =
      `sales-ai-settings/${orgId}/admin/` +
      `email-writer-product-attribute/?product=${product?.product}`;
    const fetchAttributes: any = await request(attributesUrl, {
      method: 'GET',
    });
    const fetchAttributesData = fetchAttributes?.data;

    if (fetchAttributes?.status === 'success') {
      const selectedAttribute =
        fetchAttributesData?.find(
          (item) => item?.product_summary_state_status === 'inprogress'
        ) ||
        fetchAttributesData?.find(
          (item) => item?.product_summary_state_status === 'active'
        ) || {};

      setProductAttributes((prev) => {
        const selectedProductAttributes = [...prev];
        selectedProductAttributes[productIndex] = {
          ...selectedProductAttributes[productIndex],
          ...selectedAttribute,
        };
        return selectedProductAttributes;
      });
      setIsProductAttributesLoaded(true);
    } else {
      setIsProductAttributesLoaded(false);
      showErrorToast();
    }
  };

  const updateProductAttributes = async (isSaveAsDraft) => {
    try {
      const baseUrl = `sales-ai-settings/${orgId}/admin/email-writer-product-attribute`;
      const params = new URLSearchParams({
        product: product.product,
        save_as_draft: isSaveAsDraft,
      });
      const updateUrl = `${baseUrl}/?${params}`;
      const payload = { ...productAttributes[productIndex] };
      payload.documents =
        payload?.documents
          ?.filter((doc) => doc.action)
          .map(({ document_id, category, action }) => ({
            document_id,
            category,
            action,
          })) || [];
      const updateSharedDocuments = { ...productAttributes[productIndex] };
      updateSharedDocuments.documents =
        updateSharedDocuments?.documents
          ?.filter((doc) => doc?.action !== 'remove' && doc?.document_id)
          .map(({ document_id, category, file_name, file }) => ({
            document_id,
            category,
            file_name: file_name || file?.name,
          })) || [];
      updateSharedState('documents', updateSharedDocuments?.documents);
      setIsProductAttributesEdited(false);
      payload.company_product_information =
        payload?.company_product_information || '';
      payload.company_website = payload?.company_website || '';
      payload.competitors = payload?.competitors || [];
      payload.competitors =
        payload?.competitors?.length > 0
          ? payload?.competitors
          : [{ competitor: '', differentiator: '' }];
      payload.gtm_partners_and_integrations =
        payload?.gtm_partners_and_integrations || '';
      payload.personas =
        payload?.personas?.length > 0
          ? payload?.personas
          : [{ targeted_persona: '', pain_points: '', value_proposition: '' }];
      const result: any = await request(updateUrl, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      if (result?.status === 'success') {
        updateSharedState('documents', result?.data?.documents);
        setProductAttributes((prev) => {
          const updatedAttributes = [...prev];
          updatedAttributes[productIndex] = {
            ...updatedAttributes[productIndex],
            ...result?.data,
          };
          return updatedAttributes;
        });
        setIsSaveAsDraftAPISuccess(true);
             // Set Draft false if new product is added
        if (
              product?.is_draft === false &&
              !payload.status &&
              result?.data?.status === 'draft'
            ) {
               // eslint-disable-next-line no-param-reassign
          product.is_draft = true;
        }
        if (isSaveAsDraft) {
          toast.show({
            type: 'icon',
            icon: CheckCircle,
            iconColor: 'Success',
            title: 'Successfully saved',
            subtitle: 'Your changes have been saved and you can edit later.',
            key: 'summary_saved',
          });
          setIsProductAttributesEdited(false);
        }
        setIsProductAttributesEdited(false);
        return true;
      }
      return false;
    } catch (err) {
      if (err?.body?.errors?.company_website?.length > 0) {
        setWebsiteUrlError(true);
      }
      showErrorToast(isSaveAsDraft);
      return false;
    }
  };

  const handleAcceptSummary = async () => {
    try {
      const acceptSummaryUrl = `sales-ai-settings/${orgId}/admin/accept-email-writer-summary/`;
      const acceptSummaryResponse = await request(acceptSummaryUrl, {
        method: 'POST',
        body: JSON.stringify({
          product: product?.product,
        }),
      });
      if (acceptSummaryResponse?.status === 'success') {
        toast.show({
          type: 'icon',
          icon: CheckCircle,
          iconColor: 'Success',
          title: 'Success',
          subtitle: 'Product Summary Published',
          key: TOAST_KEY_MAP.SUMMARY_PUBLISHED,
        });
        getProductList();
        setShowSummary(false);
        toggleCollapseValue(productIndex);
      } else {
        showErrorToast();
      }
    } catch (err) {
      showErrorToast();
    }
  };

  useEffect(() => {
    if (!activeSteps || Object.keys(activeSteps).length === 0) {
      return; // Exit early if conditions are not met
    }
    // Access the current steps and product attributes once
    const productAttr = productAttributes[productIndex];

    // Destructure the steps for easier access
    const [stepOne, stepTwo, stepThree] = steps;

    // Define updated steps with necessary logic
    const updatedStepOne = {
      ...stepOne,
      completed: hasRequiredValues(productAttr, companyProductAttributes),
    };
    const updatedStepTwo = {
      ...stepTwo,
      completed: hasRequiredMarketingValues(productAttr?.personas),
      disabled: !hasRequiredValues(productAttr, companyProductAttributes),
    };

    // Create the new current steps array
    const updatedSteps = [updatedStepOne, updatedStepTwo, stepThree];

    // Update the steps array immutably
    setSteps([...updatedSteps]);
  }, [
    activeSteps,
    steps,
    productIndex,
    productAttributes,
    companyProductAttributes,
  ]);

  const handleStepChange = async (
    step: number,
    productindex: number,
    btntype: string
  ) => {
    let updateAttributes = true;
    const checkLastPreviousBtn =
      activeSteps[productindex] === STEP.SUMMARY && btntype === 'previous';
    if (step === STEP.SUMMARY) {
      updateAttributes = await updateProductAttributes(false);
    } else if (isProductAttributesEdited && !checkLastPreviousBtn) {
      updateAttributes = await updateProductAttributes(true);
    }
    if (
      updateAttributes ||
      !isProductAttributesEdited ||
      checkLastPreviousBtn
    ) {
      setActiveSteps((prevSteps) => ({
        ...prevSteps,
        [productIndex]: step,
      }));
    }
  };

  const handleEditButtonClick = async () => {
    setEditBtnClicked(true);
    if (showSummary) {
      setShowSummary(false);
    } else {
      toggleCollapseValue(productIndex);
    }
    if (isCollapsed && !isProductAttributesLoaded) {
      await fetchEmailWriterProductAttributes();
    }
  };

  // change below value to published to see published ui
  const getProductStatus = (arr) => {
    if (arr.some((obj) => obj?.status === 'active')) {
      return 'Published';
    }
    if (arr.some((obj) => obj?.status === 'inprogress')) {
      return 'Unpublished Changes';
    }
    return '';
  };

  const handleUseSummaryBtn = (isBeingEdited) => {
    setIsUseThisSummaryEnabled(!isBeingEdited);
  };

  productSummaryStatus[productIndex] = getProductStatus(product?.summaries);

  isSummaryAccepted[productIndex] = product?.summaries[0]?.status === 'active';

  useEffect(() => {
    if (product) {
      const activeSummaryObj = product?.summaries?.find(
        (summary) => summary?.status === 'active'
      );
      // Select the active summary, or default to the first summary if no active one is found
      const updatedSummary =
        (activeSummaryObj && activeSummaryObj?.summary) ||
        (product?.summaries && product?.summaries[0]?.summary) ||
        '';
      const updatedCompetitorSummary =
        (activeSummaryObj && activeSummaryObj?.competitors) ||
        (product?.summaries && product?.summaries[0]?.competitors) ||
        '';
      setGeneratedSummary(updatedSummary);
      setGeneratedCompetitorSummary(updatedCompetitorSummary);
    }
    if (product?.is_show_collapse_on_draft) {
      handleEditButtonClick();
    }
    if (product?.is_active_summary) {
      setShowSummary(true);
      toggleCollapseValue(productIndex);
    }
  }, [product]);

  const isProductStatus =
    productSummaryStatus[productIndex] === 'Published' ||
    productSummaryStatus[productIndex] === 'Unpublished Changes' ||
    product?.has_attributes ||
    productAttributes[productIndex]?.status === 'draft';

  const showBottomBorder = !isCollapsed && (editBtnClicked || showSummary);

  const onPrductCardCancel = () => {
    setIsModalClickedFromParent(true);
    if (isProductAttributesEdited) {
      setOpenUnsavedChangesModal(true);
    } else {
      toggleCollapseValue(productIndex);
      setOpenUnsavedChangesModal(false);
    }
  };

  const handleUnsavedModalDiscard = () => {
    if (!isModalClickedFromParent) {
      setIsContentEditableField(false);
      handleUseSummaryBtn(false);
      setEditableContent(initialEditableContent);
    } else {
      setIsWebsiteMaxLengthExceeded(false);
      setIsTargetPersonaLengthExceeded([]);
      setIsProductAttributesEdited(false);
      setIsProductAttributesLoaded(false);
      toggleCollapseValue(productIndex);
    }
    setOpenUnsavedChangesModal(!openUnsavedChangesModal);
  };

  const handleUnsavedModalContinue = () => {
    if (!isModalClickedFromParent) {
      setIsContentEditableField(true);
    } else {
      setIsModalClickedFromParent(false);
    }
    setOpenUnsavedChangesModal(!openUnsavedChangesModal);
  };

  return (
    <div key={product?.product}>
      <Card
        className={styles.productCard}
        style={{
          borderRadius: showBottomBorder ? '6px 6px 0px 0px' : '6px',
          display: 'flex',
        }}
      >
        <Flex alignItems={'center'} style={{ width: '100%' }}>
          <Text
            type={Text.TYPE.TITLE}
            className={styles.productTitle}
            weight={'bold'}
          >
            {product.display_name}
          </Text>
          {isProductStatus && (
            <React.Fragment>
              <div className={styles.badgeStyle}>
                <Badge
                  color={
                    productSummaryStatus[productIndex] === 'Published'
                      ? 'success'
                      : 'warning'
                  }
                  mode={'light'}
                >
                  {productSummaryStatus[productIndex]}
                </Badge>
              </div>
              {!product?.is_draft && (
                <Flex alignItems={'center'} style={{ marginLeft: 'auto' }}>
                  {((productAttributes[productIndex]?.status === 'draft' &&
                    isCollapsed) ||
                    (productAttributes[productIndex]?.status === 'draft' &&
                      showSummary)) && (
                      <Text className={styles.unpublishedText}>
                        Unpublished draft
                      </Text>
                  )}
                  <Button
                    icon={Edit}
                    hierarchy={'secondary'}
                    className={styles.editBtn}
                    onClick={async () => {
                      await handleEditButtonClick();
                    }}
                  />
                </Flex>
              )}
            </React.Fragment>
          )}

          {
            // eslint-disable-next-line no-nested-ternary
            isCollapsed ? (
              isProductStatus ? (
                <ChevronDown
                  size={20}
                  className={styles.addProductText}
                  style={{
                    marginLeft:
                      isProductStatus && !product?.is_draft ? '' : 'auto',
                  }}
                  onClick={async () => {
                    if (product?.is_draft) {
                      await handleEditButtonClick();
                    } else {
                      setShowSummary(true);
                      setEditBtnClicked(false);
                      toggleCollapseValue(productIndex);
                    }
                  }}
                />
              ) : (
                <Link
                  className={styles.addProductText}
                  onClick={() => {
                    setEditBtnClicked(true);
                    toggleCollapseValue(productIndex);
                  }}
                  style={{ marginLeft: 'auto' }}
                >
                  <Plus size={16} className={styles.plusIcon} />
                  Add Product Info
                </Link>
              )
            ) : (
              <ChevronUp
                size={20}
                className={styles.addProductText}
                style={{
                  marginLeft:
                    isProductStatus && !product?.is_draft ? '' : 'auto',
                }}
                onClick={() => {
                  setShowSummary(false);
                  toggleCollapseValue(productIndex);
                }}
              />
            )
          }
        </Flex>
      </Card>

      {!isCollapsed && editBtnClicked ? (
        <div>
          <Card className={styles.collapsedProductCard}>
            {isProductAttributesLoaded || !product?.has_attributes ? (
              <Grid style={{ gridTemplateColumns: '22% 78%' }}>
                <Grid.Cell
                  column={'1'}
                  row={1}
                  style={{
                    padding: '20px 18px',
                    borderRight: '1px solid #E5E7EB',
                  }}
                >
                  <div className={styles.progressSteps}>
                    <ProgressSteps
                      size={'small'}
                      onStepSelected={(step) =>
                        handleStepChange(step, productIndex, 'steps')
                      }
                      steps={steps}
                      activeStep={activeSteps[productIndex] || 0}
                      orientation={'vertical'}
                    />
                  </div>
                </Grid.Cell>
                <Grid.Cell column={'2'} row={1} style={{ padding: '20px' }}>
                  {activeSteps[productIndex] === STEP.COMPANY_AND_PRODUCT && (
                    <CompanyProductDetails
                      companyAttributes={productAttributes[productIndex]}
                      updateSharedState={updateSharedState}
                      setIsProductAttributesEdited={
                        setIsProductAttributesEdited
                      }
                      isSaveAsDraftAPISuccess={isSaveAsDraftAPISuccess}
                      setIsSaveAsDraftAPISuccess={setIsSaveAsDraftAPISuccess}
                      websiteUrlError={websiteUrlError}
                      setWebsiteUrlError={setWebsiteUrlError}
                      isWebsiteMaxLengthExceeded={isWebsiteMaxLengthExceeded}
                      setIsWebsiteMaxLengthExceeded={setIsWebsiteMaxLengthExceeded}
                      setIsCompitetorMaxLengthExceeded={setIsCompitetorMaxLengthExceeded}
                      isCompitetorMaxLengthExceeded={isCompitetorMaxLengthExceeded}
                    />
                  )}
                  {activeSteps[productIndex] ===
                    STEP.MARKETING_SALES_MATERIAL && (
                    <MarketingSalesMaterial
                      marketingSalesAttributes={productAttributes[productIndex]}
                      updateSharedState={updateSharedState}
                      isSaveAsDraftAPISuccess={isSaveAsDraftAPISuccess}
                      setIsSaveAsDraftAPISuccess={setIsSaveAsDraftAPISuccess}
                      isTargetPersonaMaxLengthExceeded={isTargetPersonaMaxLengthExceeded}
                      setIsTargetPersonaLengthExceeded={setIsTargetPersonaLengthExceeded}
                    />
                  )}
                  {activeSteps[productIndex] === STEP.SUMMARY && (
                    <SummaryComponent
                      setOpenUnsavedChangesModal={setOpenUnsavedChangesModal}
                      product={product.product}
                      productAttributes={productAttributes[productIndex]}
                      orgId={orgId}
                      isSummaryAccepted={!!isSummaryAccepted[productIndex]}
                      handleUseSummaryBtn={handleUseSummaryBtn}
                      setIsEditable={setIsContentEditableField}
                      isEditable={isContentEditableField}
                      updateSharedState={updateSharedState}
                      editableContent={editableContent}
                      setEditableContent={setEditableContent}
                      setInitialEditableContent={setInitialEditableContent}
                    />
                  )}
                </Grid.Cell>
              </Grid>
            ) : (
              <div className={styles.loadingWrapper}>
                <Loading type={'spinner'} size={25} />
              </div>
            )}
            <Grid style={{ borderTop: '1px solid #E5E7EB' }}>
              <Grid.Cell
                style={{
                  padding: '16px 20px',
                  display: 'flex',
                  gap: '8px',
                }}
              >
                <Button
                  hierarchy={'tertiary'}
                  onClick={() => onPrductCardCancel()}
                  size={'large'}
                  style={{ borderRadius: '6px', height: '36px' }}
                >
                  Cancel
                </Button>
                <Flex gap={16} style={{ marginLeft: 'auto' }}>
                  {activeSteps[productIndex] !== STEP.SUMMARY && (
                    <Button
                      size={'large'}
                      hierarchy={'secondary'}
                      className={styles.customBtn}
                      disabled={!isProductAttributesEdited ||
                        websiteUrlError ||
                        isWebsiteMaxLengthExceeded ||
                        hasErrorsTargetPersona
                        }
                      onClick={async () => {
                        if (isProductAttributesEdited) {
                          await updateProductAttributes(true);
                        }
                      }}
                    >
                      Save As Draft{!isProductAttributesEdited}
                    </Button>
                  )}
                  {activeSteps[productIndex] !== STEP.COMPANY_AND_PRODUCT && (
                    <Button
                      size={'large'}
                      hierarchy={'secondary'}
                      className={styles.customBtn}
                      disabled={hasErrorsTargetPersona}
                      onClick={() =>
                        handleStepChange(
                          activeSteps[productIndex] - 1,
                          productIndex,
                          'previous'
                        )
                      }
                    >
                      Previous
                    </Button>
                  )}
                  {activeSteps[productIndex] !== STEP.SUMMARY && (
                    <Button
                      size={'large'}
                      className={styles.nextBtn}
                      onClick={() =>
                        handleStepChange(
                          activeSteps[productIndex] + 1,
                          productIndex,
                          'next'
                        )
                      }
                      disabled={
                        websiteUrlError ||
                        isWebsiteMaxLengthExceeded ||
                        hasErrorsTargetPersona ||
                        !nextButtonEnabled[productIndex][
                          activeSteps[productIndex]
                        ] ||
                        (!isProductAttributesLoaded && product?.has_attributes)
                      }
                    >
                      Next
                    </Button>
                  )}
                  {activeSteps[productIndex] === STEP.SUMMARY && (
                    <Button
                      size={'large'}
                      className={styles.nextBtn}
                      onClick={() => handleAcceptSummary()}
                      disabled={!isUseThisSummaryEnabled}
                    >
                      Use This Summary
                    </Button>
                  )}
                </Flex>
              </Grid.Cell>
            </Grid>
          </Card>
          {openUnsavedChangesModal && (
            <UnsavedChangesModal
              onClickCloseHandler={() => handleUnsavedModalDiscard()}
              onClickContinueHandler={() => handleUnsavedModalContinue()}
            />
          )}
        </div>
      ) : (
        !isCollapsed &&
        showSummary && (
          <Card className={styles.collapsedProductCard}>
            <Grid>
              <Grid.Cell
                style={{
                  padding: '20px',
                }}
              >
                <Text2 size={16} color={'#111927'} weight={600}>
                  Summary
                </Text2>
                <div className={styles.editBlock}>
                  <EditableContent className={styles.contentStyle}>
                    {generatedSummary}
                  </EditableContent>

                  {generatedCompetitorSummary?.length > 0 && (
                    <div style={{ marginBottom: '8px' }}>
                      <Text2 size={14} color={'#111927'} weight={600}>
                         Competitors
                      </Text2>
                    </div>
                  )}

                  {generatedCompetitorSummary?.length > 0 &&
                    generatedCompetitorSummary?.map((item, index) => (
                      <div key={`competitor-${index}`}>
                        <Text2 size={14} color={'#101825'}>
                          {item.competitor}
                        </Text2>
                        <EditableContent className={styles.contentStyle}>
                          {item.summary}
                        </EditableContent>
                      </div>
                    ))}
                </div>
              </Grid.Cell>
            </Grid>
          </Card>
        )
      )}
    </div>
  );
};
