import { useRequestValue } from '@sixsense/core';
import { useOrg } from 'hooks';
import { groupAccountsRequestState } from '../state';
import { genQueryParams } from 'aa-components/KeywordGroupCard/utils';
import { lastUpdatedSelector } from 'modules/user/selectors';
import { useSelector } from '@sixsense/core/versioned/react-redux';

const useGroupAccountsRequest = (span) => {
  const org = useOrg();
  const lastUpdated = useSelector(lastUpdatedSelector);

  const groupAccountsResult = useRequestValue(
    groupAccountsRequestState,
    {
      orgId: org.id,
      queryParams: genQueryParams(lastUpdated, { fixedRange: true, timeWindow: span }),
    }
  );

  return groupAccountsResult;
};

export default useGroupAccountsRequest;
