import React from 'react';
import { PropTypes } from 'prop-types';
import { decorators, errors } from 'utils/validators';
import { Field } from 'redux-form';
import { formLayout } from './constants';
import { Input, Tooltip2 } from 'v2-components';
import commonStyles from './common.module.scss';
import FieldLabel from '../FieldLabels';
import { cx } from 'styles/emotion';

const FormInput = Input.form;

const DESCRIPTION_FIELD = 'description';
const DESCRIPTION_LABEL = 'Description';

const Description = ({
  showCopyToAll,
  onCopyToAll,
  value,
  disableCopyToAll,
  fieldSyncError,
  required,
  maxLimit,
  rightExtra,
  className,
  disabled,
  labelTooltip,
  tooltip,
}) => {
  const fieldLabel = maxLimit ?
    `${DESCRIPTION_LABEL} (max ${maxLimit} characters)` :
    DESCRIPTION_LABEL;
  const validations = [
    ...(required ? [errors.isRequired] : []),
    ...(maxLimit ? [decorators.forceError(errors.maxLength(maxLimit))] : []),
  ];

  return (
    <React.Fragment>
      <FieldLabel
        showCopyToAll={showCopyToAll}
        label={fieldLabel}
        copyConfirmLabel={DESCRIPTION_LABEL}
        onCopyToAll={() => onCopyToAll({ [DESCRIPTION_FIELD]: value })}
        disableCopyToAll={disableCopyToAll || !!fieldSyncError}
        required={required}
        labelTooltip={labelTooltip}
        rightExtra={rightExtra}
        className={'aam-b--10'}
      />
      <Tooltip2
        overlay={tooltip}
        placement={'top'}
      >
        <Field
          name={DESCRIPTION_FIELD}
          type="textarea"
          formLayout={formLayout}
          component={FormInput}
          validate={validations}
          placeholder="Description"
          className={cx(commonStyles.textArea, className)}
          disabled={disabled}
        />
      </Tooltip2>
    </React.Fragment>
  );
};

Description.propTypes = {
  maxLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  value: PropTypes.string,
  labelTooltip: PropTypes.string,
  fieldSyncError: PropTypes.string,
  className: PropTypes.string,
  onCopyToAll: PropTypes.func,
  showCopyToAll: PropTypes.bool,
  required: PropTypes.bool,
  disableCopyToAll: PropTypes.bool,
  disabled: PropTypes.bool,
  rightExtra: PropTypes.node,
  tooltip: PropTypes.node,
};

export default Description;
export { DESCRIPTION_FIELD, DESCRIPTION_LABEL };
