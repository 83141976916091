// selectors
import { createSelector } from 'reselect';
import { orgSelector } from 'modules/user/selectors';
// import { getFiltersApi, getFilterCountsByRoute } from 'modules/segments/selectors';
import { map, sortBy, startsWith, toLower, each, includes, concat, orderBy } from 'lodash';
import { Text } from 'v2-components';
import React from 'react';

const globalSearchSelector = (state) => state.globalSearch;

const offsetSelector = createSelector(globalSearchSelector, (globalSearch) => globalSearch.offset);

const limitSelector = createSelector(globalSearchSelector, (globalSearch) => globalSearch.limit);

const searchInputSelector = createSelector(
  globalSearchSelector,
  (globalSearch) => globalSearch.searchInput
);

const endpointSelector = createSelector(
  orgSelector,
  limitSelector,
  searchInputSelector,
  (org, limit, search) => (`query/${org}/autocomplete/firm_name/` +
  `?input_string=${encodeURIComponent(search)}`)
);

const accountListSelector = createSelector(
  globalSearchSelector,
  searchInputSelector,
  ({ accounts = [] } = {}, inputString) => {
    // 1. Results that match directly from left to right: confidence levels
    // 2. Results that contain the string: alphabetically
    // 3. Indirect matches: alphabetically
    const lrMatches = [];
    const containMatches = [];
    const other = [];
    each(accounts, (acc) => {
      if (startsWith(toLower(acc.firm_name || ''), toLower(inputString))) {
        lrMatches.push(acc);
      } else if (includes(toLower(acc.firm_name || ''), toLower(inputString))) {
        containMatches.push(acc);
      } else {
        other.push(acc);
      }
    });

    const sortedAccounts = concat(
      orderBy(lrMatches, (acc) => acc.weight, ['desc']),
      sortBy(
        containMatches,
        (acc) => `${(acc.firm_name || '').toLowerCase()}-${(acc.firm_country || '').toLowerCase()}`), // eslint-disable-line
      sortBy(
        other,
        (acc) => `${(acc.firm_name || '').toLowerCase()}-${(acc.firm_country || '').toLowerCase()}`), // eslint-disable-line
    );

    return map(
      sortedAccounts,
      (value) =>
        ({ ...value,
          name_country: (<div>
            <div><Text>{value.firm_name || ''}</Text></div>
            <div>{value.firm_country || ''} - {value.firm_domain || ''}</div>
          </div>),
        })
    );
  }
);

const displaySearchSelector = createSelector(
  globalSearchSelector,
  (globalSearch) => globalSearch.showSearchBar,
);

const campaignFilterSelector = createSelector(
  globalSearchSelector,
  (globalSearch) => globalSearch.campaignFilter
);

const globalAccountMidSelector = createSelector(
  globalSearchSelector,
  (globalSearch) => globalSearch.mid
);

const globalSearchLoadingSelector = createSelector(
  globalSearchSelector,
  (globalSearch) => globalSearch.loading
);

// const filtersSelector = createSelector(
//   // getFiltersApi,
//   searchInputSelector,
//   campaignFilterSelector,
//   (searchInput, camapaignFilterSwitch) => {
//     // let filters = [...segmentFilters];
//     if (searchInput) {
//       const nameClause = {
//         column: 'firm_name',
//         exclude: false,
//         conditions: [
//           {
//             operator: 'like',
//             value: `%${searchInput}%`,
//           },
//         ],
//       };
//       filters = [...filters, nameClause];
//     }
//     if (camapaignFilterSwitch) {
//       const campaignClause = {
//         column: 'fpm_impression_count',
//         exclude: false,
//         conditions: [
//           {
//             operator: '>',
//             value: 0,
//           },
//         ],
//       };
//       filters = [...filters, campaignClause];
//     }
//     return filters;
//   }
// );

const sortBySelector = createSelector(globalSearchSelector, (globalSearch) => globalSearch.sortBy);

const requestBodySelector = createSelector(sortBySelector, (sortByColumn) => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    orders: [{ column: sortByColumn, desc: true }],
    // filters,
  }),
}));

const loadBoxTextSelector = createSelector(
  globalSearchSelector,
  (globalSearch) => (globalSearch.loadMoreError ? 'Failed to Fetch Results' : 'Load More...')
);

// const filtersAppliedSelector = createSelector(getFilterCountsByRoute, (filterCounts) =>
//   Object.keys(filterCounts).reduce((sum, key) => sum + filterCounts[key], 0)
// );

export {
  globalSearchSelector,
  globalAccountMidSelector,
  offsetSelector,
  limitSelector,
  sortBySelector,
  searchInputSelector,
  campaignFilterSelector,
  endpointSelector,
  // filtersSelector,
  requestBodySelector,
  loadBoxTextSelector,
  // filtersAppliedSelector,
  accountListSelector,
  displaySearchSelector,
  globalSearchLoadingSelector,
};
