import { findIndex, clone, map } from 'lodash';
import { APPROVAL_STATUS } from '../constants';

const initialState = {};

const MARK_APPROVAL = 'INBOX/APPROVAL/MARK_APPROVAL';
const markApproval = (record_id, updatedBy) => ({ type: MARK_APPROVAL, record_id, updatedBy });

const APPROVE_ALL = 'INBOX/APPROVAL/APPROVE_ALL';
const REJECT_ALL = 'INBOX/APPROVAL/REJECT_ALL';
const PARTIAL_APPROVE = 'INBOX/APPROVAL/PARTIAL_APPROVE';
const approveAll = (updatedBy) => ({ type: APPROVE_ALL, updatedBy });
const rejectAll = (updatedBy) => ({ type: REJECT_ALL, updatedBy });
const approveSelected = (updatedBy) => ({ type: PARTIAL_APPROVE, updatedBy });

const TOGGLE_MODAL_VISIBILITY = 'INBOX/APPROVAL/TOGGLE_MODAL';
export const toggleApprovalModal = () => ({ type: TOGGLE_MODAL_VISIBILITY });

function approvalReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL_VISIBILITY: {
      const { modalVisible } = state;
      return { ...state, modalVisible: !modalVisible };
    }
    case MARK_APPROVAL: {
      const {
        record_id,
        updatedBy,
      } = action;

      const { info: x } = state;
      const records = x?.approvalRequest.records;
      const toUpdate = findIndex(records, { record_id });
      const currentApprovalValue = records[toUpdate].status;
      const info = clone(x);

      const resettingToDefaultValue = currentApprovalValue === APPROVAL_STATUS.APPROVED;
      info.approvalRequest.records[toUpdate] = {
        ...records[toUpdate],
        status: resettingToDefaultValue ? APPROVAL_STATUS.REJECTED : APPROVAL_STATUS.APPROVED,
        // since up-approve is REJECT atm, we need the user who 'up-approved'
        approver_id: updatedBy,
      };

      return {
        ...state,
        info,
      };
    }
    // todo: these 3 (approve/reject all, partial) can be one case
    case REJECT_ALL: {
      // mark all records as rejected and add user ids
      const { info: _info } = state;
      const { updatedBy } = action;
      const info = clone(_info);
      info.approvalRequest.records = map(
        info.approvalRequest.records,
        (v) => ({
          ...v,
          status: APPROVAL_STATUS.REJECTED,
          approver_id: updatedBy,
        }),
      );
      return { ...state, info };
    }
    case APPROVE_ALL: {
      // mark all records as approved and add user ids
      const { info: _info } = state;
      const { updatedBy } = action;
      const info = clone(_info);
      info.approvalRequest.records = map(
        info.approvalRequest.records,
        (v) => ({
          ...v,
          status: APPROVAL_STATUS.APPROVED,
          approver_id: updatedBy,
        }),
      );
      return { ...state, info };
    }
    case PARTIAL_APPROVE: {
      // add record ids to any records marked as approved without approver_ids
      // This happens because during the formatNotification step, we mark all
      // pending(new) records as approved, but do not have access to the user id
      // at that point without some unnecessary extra crap. Specific rejected records
      // will have approver_ids already (see MARK_APPROVAL case), but the
      // pending-converted-to-approved records won't have been touched, and could have
      // missing approvers
      const { info: _info } = state;
      const { updatedBy } = action;
      const info = clone(_info);
      info.approvalRequest.records = map(
        info.approvalRequest.records,
        (v) => ({
          ...v,
          approver_id: (!v.approver_id && v.status === APPROVAL_STATUS.APPROVED)
            ? updatedBy
            : v.approver_id,
        }),
      );
      return { ...state, info };
    }
    default:
      return state;
  }
}

export const actions = {
  markApproval,
  approveAll,
  rejectAll,
  approveSelected,
  toggleApprovalModal,
};

export const actionTypes = {
  MARK_APPROVAL,
  APPROVE_ALL,
  REJECT_ALL,
  PARTIAL_APPROVE,
  TOGGLE_MODAL_VISIBILITY,
};

export default approvalReducer;
