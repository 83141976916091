/* eslint-disable react-hooks/rules-of-hooks */
import { usePermissionCheck } from '@sixsense/rbac';
import {
  EDIT_SI_SETTINGS_PERMISSIONS,
  TPR_SETTINGS_PERMISSIONS,
  VIEW_SI_SETTINGS_PERMISSIONS,
} from '../../../../constants/featurePermissions';

export const useSISettingsReadOnlyPermissions = () => {
  const isReadOnly = !usePermissionCheck(EDIT_SI_SETTINGS_PERMISSIONS) &&
    usePermissionCheck(VIEW_SI_SETTINGS_PERMISSIONS);
  return isReadOnly;
};

export const useSISettingsTPRPermissions = () => {
  const hasTPRaccess = usePermissionCheck(TPR_SETTINGS_PERMISSIONS);
  return hasTPRaccess;
};

export const useSISettingsViewEditPermissions = () => {
  const hasSISettingsAccess = usePermissionCheck(EDIT_SI_SETTINGS_PERMISSIONS) ||
  usePermissionCheck(VIEW_SI_SETTINGS_PERMISSIONS);
  return hasSISettingsAccess;
};

