import createEmotion from 'create-emotion';

const {
  css,
  cx,
} = createEmotion({ nonce: window.__CSP_NONCE__ });

export {
  css,
  cx,
};
