import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Text, Row, Link, Icon, Select, Tooltip2, Modal, Button } from 'v2-components';
import {
  capitalize,
  formatBytes,
  setCreativeCaptionUrl,
  maybePlural,
  truncateString,
  wrapUrlWith6SiPreviewParam,
} from 'utils/utils';
import { strings } from 'utils/constants';
import styles from './AdPreview.module.scss';
import { ImageContext, AdBlockContext as AdBlockConsumer } from 'contexts';
import Media from './Media';
import { AD_TYPE_IDS, LINKEDIN_PREVIEW_OPTIONS } from 'routes/Advertising/constants';
import SmallHills from 'images/small-hills.svg';
import LinkedinLike from 'images/linkedin-like.svg';
import LinkedinComment from 'images/linkedin-comment.svg';
import LinkedinShare from 'images/linkedin-share.svg';
import { errors } from 'utils/validators';
import { css, cx } from 'styles/emotion';
import { If } from 'babel-plugin-jsx-control-statements';
import { get } from 'lodash';
import { linkedinAdPreviewOptions } from 'routes/Advertising/utils';
import { pxToRem } from '@sixsense/core/style';

const availablePlacementsOverlay = (linkedinPreviewOptions) => (<span>
  This ad will be available on
  <ul className={css`padding-inline-start: 30px`}>
    {
      linkedinPreviewOptions.map(({ label }) => <li>{label}</li>)
    }
  </ul>
</span>);

// eslint-disable-next-line react/prop-types
const LinkedinPreviewNote = ({ className }) => (<Row className={className}>
  <Icon type={'info_outline'} size={Icon.SIZE.LARGE} className={'aam-r--5'} />
  <Text color={Text.COLOR.GREY1}>
    The actual display of your ad may vary from this preview based on device,
    system preferences, and other factors.
  </Text>
</Row>);

const ShowMore = ({ children, limit }) => {
  const [truncateAfter, setTruncateAfter] = useState(limit);

  return (<React.Fragment>
    {truncateString(children, truncateAfter)}
    <If condition={children.length >= truncateAfter}>
      <Text
        onClick={() => setTruncateAfter(Number.MAX_VALUE)}
        pointer
        color={Text.COLOR.GREY1}
        type={Text.TYPE.SUBBODY}
      >
        see more
      </Text>
    </If>
  </React.Fragment>);
};
ShowMore.propTypes = {
  children: PropTypes.string,
  limit: PropTypes.number,
};

function BaseAdModel(props) {
  const {
    placementType,
    creative,
    icon,
    title = '',
    bodyText = '',
    callToAction = '',
    sponsor = '',
    clickUrl = '',
    isFullSize,
    htmlDimension,
    showAvailablePlacements,
    extraAdPreviewData,
  } = props;
  const creativeWidth = Number(get(creative, 'dimensions')?.split('x')[0]);
  const creativeHeight = Number(get(creative, 'dimensions')?.split('x')[1]);
  const linkedinPreviewOptions = linkedinAdPreviewOptions(get(creative, 'dimensions'));

  const [linkedinPreviewType, setLinkedinPreviewType] = useState(linkedinPreviewOptions[0].value);
  const { s3_url, file_size } = creative;
  const html5metaData = `${!htmlDimension ? '' : `${htmlDimension}px, `}${formatBytes(file_size)}
  , .ZIP`;
  const [captionUrl, setCaptionUrl] = useState();
  const captionUrlRef = useRef();

  const updateCaptionUrl = (vttCaptionUrl) => {
    setCaptionUrl(vttCaptionUrl);
    captionUrlRef.current = vttCaptionUrl;
  };

  useEffect(() => {
    if (placementType === AD_TYPE_IDS.linkedInVideoAd) {
      setCreativeCaptionUrl(creative, updateCaptionUrl);
    }
    return () => {
      if (captionUrlRef.current) {
        window.URL.revokeObjectURL(captionUrlRef.current);
        captionUrlRef.current = null;
      }
    };
  }, [creative, placementType]);

  const nativePreview = (
    <Row
      className={isFullSize ? styles.adPreviewFullSize : styles.adPreviewContainer}
      flexDirection={Row.FLEX_DIRECTION.COLUMN}
    >
      <Row alignItems={Row.ALIGN_ITEMS.CENTER}>
        <Media {...icon} containerClass={styles.iconContainer} />
        <Text className={styles.adTitle}>{title}</Text>
      </Row>
      <Col className={`${styles.previewImageContaier} ${styles.adMetaData}`}>
        <Media {...creative} showControls />
      </Col>
      <Col className={styles.adMetaData}>
        <Text color={Text.COLOR.GREY1} className={styles.metaDataText}>
          {!bodyText ? '-' : bodyText}
        </Text>
      </Col>
      <Col className={styles.adMetaData}>
        {clickUrl ? (
          <Link
            link={wrapUrlWith6SiPreviewParam(clickUrl)}
            newWindow
            className={styles.callToAction}
          >
            {truncateString(callToAction, 60)}
          </Link>
        ) : (
          '-'
        )}
      </Col>
      <Col className={styles.adMetaData}>
        <Text color={Text.COLOR.GREY} className={styles.metaDataText}>
          Sponsored By {!sponsor ? '-' : sponsor}
        </Text>
      </Col>
    </Row>
  );
  const html5Preview = (
    <Row className={styles.htmlPreviewClass}>
      <Media {...creative} />
      <Row flexDirection={'column'} className={styles.html5MetaDataContainer}>
        <Link link={s3_url} className={styles.html5metaDataClass}>
          Download
        </Link>
        <Text color={Text.COLOR.GREY1} className={styles.html5metaDataClass}>
          {html5metaData}
        </Text>
      </Row>
    </Row>
  );
  const videoPreview = (
    <Media {...creative} showControls containerClass={styles.videoPreviewClass} />
  );
  const bannerPreview = (
    <Row>
      <Media {...creative} containerClass={styles.bannerPreviewClass} />
    </Row>
  );

  const linkedinUrlVisible = clickUrl && !errors.validWebsite(clickUrl);
  const linkedinSmallCreativeDesktopView = creativeWidth < 401 &&
    creativeWidth > creativeHeight &&
    linkedinPreviewType === LINKEDIN_PREVIEW_OPTIONS.desktop.value;

  const getLinkedinFullSizePreviewStyle = () => (
    linkedinPreviewType === LINKEDIN_PREVIEW_OPTIONS.desktop.value ?
    { maxWidth: pxToRem(552) } : { maxWidth: pxToRem(350) }
 );

  const linkedinAdPreview = (
    <React.Fragment>
      <Row className={'aam-b--25'} justifyContent={'space-between'} alignItems={'center'}>
        <Select
          options={linkedinPreviewOptions}
          value={linkedinPreviewType}
          onChange={(nextValue) => setLinkedinPreviewType(nextValue)}
          width={130}
          dropdownClassName={isFullSize && styles.linkedinPreviewSelect}
          id={'linkedin-preview-type-select'}
        />
        <If condition={showAvailablePlacements}>
          <Tooltip2 placement={'top'} overlay={availablePlacementsOverlay(linkedinPreviewOptions)}>
            <Text className={styles.availablePlacements}>
              {linkedinPreviewOptions.length}&nbsp;
              {maybePlural(linkedinPreviewOptions.length, 'placement', 'placements')}&nbsp;
              available
            </Text>
          </Tooltip2>
        </If>
      </Row>
      <LinkedinPreviewNote className={'aam-b--25'} />
      <div
        className={styles.linkedinAdPreviewContainer}
        style={isFullSize ? getLinkedinFullSizePreviewStyle() : {}}
      >
        <Row className={styles.header}>
          <img
            src={get(extraAdPreviewData, 'pageLogoUrl', SmallHills)}
            alt={'linkedin-page-logo'}
            height="48"
            width="48"
          />
          <div className={'aam-l--10'}>
            <Text bold>{get(extraAdPreviewData, 'pageName', 'LinkedIn Page Name')}</Text>
            <br />
            <Text type={Text.TYPE.SUBBODY} className={'aam-t--5'} color={Text.COLOR.GREY1}>
              Promoted
            </Text>
          </div>
        </Row>
        <div className={styles.header}>
          <Text className={cx(!bodyText && styles.dummyValue)}>
            {bodyText ? <ShowMore limit={140}>{bodyText}</ShowMore> : 'dummy'}
          </Text>
        </div>
        <If condition={!linkedinSmallCreativeDesktopView}>
          <Media
            className={cx(styles.constraintImage)}
            containerClass={cx(styles.imageContainer)}
            {...creative}
            showControls
            captionUrl={captionUrl}
            showThumbnailAsOverlay
          />
        </If>
        <Row
          className={cx(
            styles.description,
          )}
          justifyContent={'space-between'}
        >
          <Row className={css`min-width: 0`}>
            <If condition={linkedinSmallCreativeDesktopView}>
              <Media
                className={cx(styles.linkedinSmallImage)}
                containerClass={cx(styles.smallImageContainer, 'aam-r--10')}
                {...creative}
                showControls
                captionUrl={captionUrl}
                showThumbnailAsOverlay
              />
            </If>
            <div
              className={cx(
                styles.linkedinImageDescription
              )}
            >
              <Text bold className={cx(!title && styles.dummyValue, styles.ellipsis)}>
                {title || 'dummy'}
              </Text >
              <Text
                type={Text.TYPE.SUBBODY}
                color={Text.COLOR.GREY1}
                className={cx(
                  !linkedinUrlVisible && styles.dummyValue,
                  styles.ellipsis,
                  styles.clickUrl
                )}
              >
                {linkedinUrlVisible ? new URL(clickUrl).hostname : 'dummy'}
              </Text>
            </div>
          </Row>
          <If condition={callToAction}>
            <Text className={styles.linkedinCallToAction}>
              {capitalize(callToAction?.replace('_', ' '))}
            </Text>
          </If>
        </Row>
        <Row className={styles.header} justifyContent={'space-around'}>
          <Row alignItems={'center'}>
            <img src={LinkedinLike} alt={'like-button'} />
            <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.GREY1} className={'aam-l--5'}>
              Like
            </Text>
          </Row>
          <Row alignItems={'center'} className={isFullSize && cx('aam-l--15', 'aam-r--15')}>
            <img src={LinkedinComment} alt={'comment-button'} />
            <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.GREY1} className={'aam-l--5'}>
              Comment
            </Text>
          </Row>
          <Row alignItems={'center'}>
            <img src={LinkedinShare} alt={'comment-button'} />
            <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.GREY1} className={'aam-l--5'}>
              Share
            </Text>
          </Row>
        </Row>
      </div>
    </React.Fragment>
  );
  let content;
  switch (placementType) {
    case 'native': {
      content = nativePreview;
      break;
    }
    case 'html5':
    case 'html5_dynamic': {
      content = html5Preview;
      break;
    }
    case 'video': {
      content = videoPreview;
      break;
    }
    case AD_TYPE_IDS.linkedInSingleImageAd: {
      content = linkedinAdPreview;
      break;
    }
    case AD_TYPE_IDS.linkedInVideoAd : {
      content = linkedinAdPreview;
      break;
    }
    case 'banner':
    default: {
      content = bannerPreview;
      break;
    }
  }
  return <Fragment>{content}</Fragment>;
}

BaseAdModel.propTypes = {
  placementType: PropTypes.string,
  creative: PropTypes.object,
  icon: PropTypes.object,
  title: PropTypes.string,
  bodyText: PropTypes.string,
  callToAction: PropTypes.string,
  clickUrl: PropTypes.string,
  sponsor: PropTypes.string,
  isFullSize: PropTypes.bool,
  htmlDimension: PropTypes.string,
  showAvailablePlacements: PropTypes.bool,
  extraAdPreviewData: PropTypes.object,
};

const ImageProvider = ImageContext({ routeKey: 'preview_layout' });

const FullSizeAdPreview = (props) => {

  const addKeyBinding = (e) => {
    if (e.keyCode === 27) {
      props.handleCloseIconClick();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', addKeyBinding);
    return () => document.removeEventListener('keyup', addKeyBinding);
  });

  const onClose = () => props.handleCloseIconClick();

  const isLinkedinAdType = props.placementType === AD_TYPE_IDS.linkedInSingleImageAd ||
    props.placementType === AD_TYPE_IDS.linkedInVideoAd;


  return (<ImageProvider>
    {isLinkedinAdType ?
      <Modal
        title="Ad Preview"
        width={'40vw'}
        visible={props.isFullSize}
        onCancel={onClose}
        className={styles.fullSizePreview}
        footer={<Button onClick={onClose}>Cancel</Button>}
        destroyOnClose
        style={{ top: 5 }}
      >
        <div className={styles.fullSizePreviewContent}>
          <BaseAdModel {...props} />
        </div>
      </Modal> : <div className={styles.fullSizeMask}>
        <Row
          flexDirection={Row.FLEX_DIRECTION.COLUMN}
          justifyContent={Row.JUSTIFY_CONTENT.CENTER}
          className={styles.previewContent}
          alignItems={Row.ALIGN_ITEMS.FLEX_END}
        >
          <Icon
            type="clear"
            size="24px"
            className={styles.closeIcon}
            onClick={onClose}
          />
          <BaseAdModel {...props} />
        </Row>
      </div>
    }
  </ImageProvider>
  );
};

FullSizeAdPreview.propTypes = {
  handleCloseIconClick: PropTypes.func,
  isFullSize: PropTypes.bool,
  placementType: PropTypes.string,
};

class AdPreviewComponent extends React.PureComponent {
  state = {
    isFullSize: false,
  };

  makeFullSize = (isFullSize) => () => this.setState({ isFullSize });

  render() {
    const { placementType, creative } = this.props;
    const showFullSizePreview = [
      AD_TYPE_IDS.html5Ad,
      AD_TYPE_IDS.html5DynamicAd,
    ].includes(placementType) === false;

    return (
      <ImageProvider>
        {this.state.isFullSize && (
          <FullSizeAdPreview
            handleCloseIconClick={this.makeFullSize(false)}
            {...this.props}
            isFullSize={this.state.isFullSize}
          />
        )}
        {this.props.adBlockEnabled ? (
          <Row>
            <Text>
              {strings.DISABLE_ADBLOCKER}
            </Text>
          </Row>
        ) : (
          <Fragment>
            {this.props.showPreviewIcon ? <Icon
              className={styles.previewIcon}
              size={Icon.SIZE.LARGE}
              type={'remove_red_eye'}
              onClick={this.makeFullSize(true)}
            />:(<Fragment>
              <BaseAdModel
                key={`${placementType}_${creative.s3_url}`}
                {...this.props}
                isFullSize={false}
              />
              {showFullSizePreview && (
              <Row className={styles.adMetaData}>
                <Link onClick={this.makeFullSize(true)}>Full Size Preview</Link>
              </Row>
            )}
            </Fragment>)}
          </Fragment>
        )}
      </ImageProvider>
    );
  }
}

AdPreviewComponent.propTypes = {
  placementType: PropTypes.string,
  creative: PropTypes.object,
  adBlockEnabled: PropTypes.bool,
  showPreviewIcon: PropTypes.bool,
};

const AdPreview = AdBlockConsumer(AdPreviewComponent);
AdPreview.FullSizeAdPreview = FullSizeAdPreview;

export default AdPreview;
