import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'rc-dialog';
import 'rc-dialog/assets/index.css';
import ReactDOM from 'react-dom';
import PromptModal from './PromptModal';

/**
* Modal Component
*
* This component replaces the old ant-d modal component in place of the ‘rc-dialog’
* component. See: http://react-component.github.io/dialog/
*
* The Modal component preserves all functionality from the old antd modal component,
* including the modal.confirm() and modal.info() methods (now Modal.prompt())
* which return a prompt modal component. This functionality is done using the
* PromptModal component which uses the modal component as a base.
*
* The Modal component returns an empty dialog modal which can then be customized with
* content. Modal.prompt() returns either an confirmation or info PromptModal component
* -> style and design is based off the old modal returned from our old Modal.confirm
* and Modal.info methods.
*
* see Confirmation/Information modal dialog on this page: https://ant.design/components/modal/
*
* @param {string, array or react element} children - content of Modal
* @param {string} className
* @param {func} onCancel - to invoke when clicking cancel or ‘x’ button
* @param {object} style - additional custom styles if necessary
*/

const PROMPT_TYPES = Object.freeze({
  CONFIRM: 'confirm',
  INFO: 'info',
});

const modalDiv = document.createElement('div');

const renderPromptModal = (props) => {
  document.body.appendChild(modalDiv);
  ReactDOM.render(
    <PromptModal
      visible
      {...props}
      onOk={() => {
        if (props.onOk) {
          props.onOk();
        }
        destroyModal();
      }}
      onCancel={() => {
        if (props.onCancel) {
          props.onCancel();
        }
        destroyModal();
      }}
    />,
    modalDiv
  );
};

const destroyModal = () => { ReactDOM.unmountComponentAtNode(modalDiv); };

function Modal(props) {
  const { children, className, onCancel, style, mask } = props;
  return (
    <Dialog
      {...props}
      zIndex={1000}
      mask={mask}
      wrapClassName={className}
      // animation="fade"
      // maskAnimation="fade"
      onClose={onCancel}
      style={{
        top: 80,
        ...style,
      }}
    >
      {children}
    </Dialog>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]),
  mask: PropTypes.bool,
  className: PropTypes.string,
  onCancel: PropTypes.func,
  style: PropTypes.any,
};

Modal.defaultProps = {
  mask: true,
};

Modal.prompt = renderPromptModal;
Modal.PROMPT_TYPES = PROMPT_TYPES;

export default Modal;
