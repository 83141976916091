/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrchestrationIconStyles.module.scss';
import PdlLogo from './images/pdl.png';
import BetaTagImage from './images/beta_tag.svg';

const BetaTag = ({ className, size }) =>
(
  <img src={BetaTagImage}
    className={`${styles.default} ${styles[size]} ${styles['beta']} ${className} `}
  />
)

export default BetaTag;