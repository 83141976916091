import { isEmpty, cloneDeep, set, memoize } from 'lodash';
import { CLASSIFICATION_TYPES } from 'routes/Advertising/ducks/campaignClassifications/constants';
import {
  ARCHIVED_BY_ME_FILTER_OPTION,
  ARCHIVED_CAMPAIGNS_FILTER_OPTION,
  CREATED_BY_ME_FILTER_OPTION,
  FILTER_LABELS,
  FILTER_OPTIONS,
  LINKEDIN_PAGES_FILTER_OPTIONS,
  FILTER_OPTIONS_FOR_ARCHIVED_AND_DRAFTS,
  FILTER_KEYS,
} from './constants';

function validateFilters(filterEntries, possibleFilterOptions) {
  return filterEntries
    .reduce(
      (acc, [key, value]) => {
        // Linkedin pages are dynamic. At this point in time the linkedin pages won't be pulled in
        // so we continue to retain them, only to be filtered in filtersStateToQueryParamsObject
        if (key === FILTER_KEYS.linkedinPageIn) {
          acc[key] = value.reduce((accumulator, v) => ({
            ...accumulator,
            [v]: true,
          }), {});
        }

        const maybeFilterOption = possibleFilterOptions.find(({ filterKey }) => filterKey === key);
        if (maybeFilterOption) {
          const valueMap = value
            .reduce(
              (accumulator, v) => {
                const maybeChoice = maybeFilterOption
                  .choices
                  .find(({ value: choice }) => choice === v);
                if (maybeChoice) {
                  return ({ ...accumulator, [v]: true });
                }
                return accumulator;
              },
              {},
            );
          if (isEmpty(valueMap) === false) {
            acc[key] = valueMap;
          }
        }

        return acc;
      },
      {},
    );
}

export const queryParamsToFilterState = (searchString, possibleFilterOptions) => {
  const searchParams = new URLSearchParams(searchString);
  const filterEntries = Array.from(searchParams.entries())
    .map(([key, value]) => ([key, value.split(',')]));

  return validateFilters(filterEntries, possibleFilterOptions);
};

export const getAllChoicesForFilterOption = (filterOptions) =>
  filterOptions.reduce(
    (accumulator, category) => ({
      ...accumulator,
      [category.filterKey]: category.choices.map((choice) => choice.value),
    }),
    {}
  );

const injectLinkedinPagesAsOptions = (filterOptions, linkedInPages) => {
  const needActionItemIndex = filterOptions.findIndex(
    (option) => option.label === FILTER_LABELS.needAction
  );
  if (isEmpty(linkedInPages) || needActionItemIndex === -1) {
    return filterOptions;
  }

  const linkedInFilterOptions = {
    ...LINKEDIN_PAGES_FILTER_OPTIONS,
    choices: linkedInPages.map(({ page_name, id }) => ({ label: page_name, value: id.toString() })),
  };

  return [
    ...filterOptions.slice(0, needActionItemIndex + 1),
    linkedInFilterOptions,
    ...filterOptions.slice(needActionItemIndex + 1),
  ];
};

export const getFilterOptionsForClassification = memoize(
  (classificationType, userId, archivedFolderId, linkedInPages) => {
    const createdByMeFilterOption = cloneDeep(CREATED_BY_ME_FILTER_OPTION);
    set(createdByMeFilterOption, 'choices[0].value', String(userId));

    let filterOptions = [];
    switch (classificationType) {
      case CLASSIFICATION_TYPES.CAMPAIGNS_DRAFT:
        filterOptions = [
          createdByMeFilterOption,
          ...FILTER_OPTIONS_FOR_ARCHIVED_AND_DRAFTS,
        ];
        break;
      case CLASSIFICATION_TYPES.CAMPAIGNS_ARCHIVED: {
        const archivedByMeFilterOption = cloneDeep(ARCHIVED_BY_ME_FILTER_OPTION);
        set(archivedByMeFilterOption, 'choices[0].value', String(userId));
        filterOptions = [
          createdByMeFilterOption,
          archivedByMeFilterOption,
          ...FILTER_OPTIONS_FOR_ARCHIVED_AND_DRAFTS,
        ];
        break;
      }
      case CLASSIFICATION_TYPES.CAMPAIGN_FOLDER:
      case CLASSIFICATION_TYPES.CAMPAIGNS_UNSORTED:
        filterOptions = [
          createdByMeFilterOption,
          ...FILTER_OPTIONS,
        ];
        break;
      default: {
        const archivedCampaignsFilterOption = cloneDeep(ARCHIVED_CAMPAIGNS_FILTER_OPTION);
        set(archivedCampaignsFilterOption, 'choices[0].value', String(archivedFolderId));
        filterOptions = [
          createdByMeFilterOption,
          archivedCampaignsFilterOption,
          ...FILTER_OPTIONS,
        ];
      }
    }

    return injectLinkedinPagesAsOptions(filterOptions, linkedInPages);
  }, (...args) => args.join('-')
);
