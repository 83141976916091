import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formStateSelector, modalStateSelector } from "./selectors";
import { classNames, maybePlural } from "utils/utils";
import styles from "./CampaignCSVExportDrawer.module.scss";
import * as actions from "./actions";
import { updateFormState as updateFormStateAction } from "./actions";
import DateFilter from "routes/AdvertisingDS/routes/Campaigns/components/DateFilter/DateFilter";
import {
  DEFAULT_OPTIONS as dataFilterOptions,
  DATE_FILTER_OPTIONS_VALUES,
} from "routes/AdvertisingDS/routes/Campaigns/components/DateFilter/constants";
import {
  ANALYTICS_TYPE,
  ANALYTICS_TYPE_OPTIONS,
  GROUP_BY_OPTIONS,
  TIME_BREAKDOWN_OPTIONS,
} from "./constants";
import { Spinner } from "@sixsense/core/components/Loading";
import { PROMISE_STATES } from "modules/global/constants";
import { emailReportStateGenerator } from "./stateGenerators";
import {
  Drawer,
  Text,
  Button,
  Select,
  RadioGroup,
  Flex,
  Tooltip,
  Dropdown,
  Modal,
  Card,
  CloseButton,
} from "@sixsense/core/components";
import { Download } from "@sixsense/core/icons";
import {
  RootColor,
  TextColor,
  ThemeColor,
} from "@sixsense/core/style/variables";

const FormComponent = ({
  loading,
  updateFormState,
  dateRange,
  analyticsType,
  accountType,
  groupBy,
  timeBreakdown,
}) => {
  const selectedGroupByOption = GROUP_BY_OPTIONS.find(
    (option) => option.value === groupBy
  );
  const selectedTimeBreakdownOption = TIME_BREAKDOWN_OPTIONS.find(
    (option) => option.value === timeBreakdown
  );

  const accountAnalyticsSelected = analyticsType === ANALYTICS_TYPE.accounts;
  const onTypeChange = (value, from = null, to = null) => {
    updateFormState({ dateRange: { value, from, to } });
  };

  const menu = (
    <Dropdown.Menu>
      <Dropdown.Menu.Header title="DELIVERY TIMEFRAME" />
      {dataFilterOptions.map((option) => {
        const id = `campaign_list-download-delivery_timeframe-${option.label.replaceAll(
          " ",
          ""
        )}`;
        return (
          <Dropdown.Menu.Item
            key={id}
            onClick={() => onTypeChange(option.value)}
            data-6si-id={id}
            data-testid={id}
          >
            {option.label}
          </Dropdown.Menu.Item>
        );
      })}
    </Dropdown.Menu>
  );

  return (
    <Flex direction="column">
      <Text.Body
        weight="semibold"
        color={
          loading ? `var(${TextColor.DISABLED})` : `var(${TextColor.SECONDARY})`
        }
      >
        What do you want to Download?
      </Text.Body>
      <Flex className="mt4" direction="column">
        <RadioGroup
          onChange={(value) => updateFormState({ analyticsType: value })}
          value={analyticsType}
        >
          <Flex>
            <RadioGroup.Button
              disabled={loading}
              value={ANALYTICS_TYPE.campaign}
              label="Campaign Analytics"
            />
          </Flex>
          <Flex className="mt4">
            <RadioGroup.Button
              disabled={loading}
              label="Analytics for Accounts reached"
              value={ANALYTICS_TYPE.accounts}
            />
          </Flex>
        </RadioGroup>
      </Flex>
      {!(analyticsType !== ANALYTICS_TYPE.accounts) ? (
        <Flex direction="column" className="ml6">
          <RadioGroup
            onChange={(value) => updateFormState({ accountType: value })}
            value={accountType}
          >
            {ANALYTICS_TYPE_OPTIONS.map((option) => (
              <Flex className="mt4">
                <RadioGroup.Button
                  value={option.value}
                  label={option.label}
                  disabled={
                    analyticsType !== ANALYTICS_TYPE.accounts || loading
                  }
                />
              </Flex>
            ))}
          </RadioGroup>
        </Flex>
      ) : null}
      <Flex direction="column" className="mt6">
        <Flex>
          <Tooltip
            overlay={
              accountAnalyticsSelected
                ? `“Timeframe” is not applicable
                for Account analytics`
                : null
            }
            trigger="hover"
          >
            <Text.Body weight="semibold" color={`var(${TextColor.SECONDARY})`}>
              Delivery Timeframe
            </Text.Body>
          </Tooltip>
        </Flex>
        <Flex className="mt3">
          <DateFilter
            className={styles.DateFilter}
            fullWidth
            onChange={onTypeChange}
            value={dateRange.value}
            menu={menu}
            options={dataFilterOptions.map(({ label, ...rest }) => ({
              ...rest,
              label,
              dataPendo: `campaignsExportAsCSVDeliveryTimeFrame-${label.replaceAll(
                " ",
                ""
              )}`,
            }))}
            disable={loading || accountAnalyticsSelected}
            dateRange={
              dateRange.from && dateRange.to
                ? [dateRange.from, dateRange.to]
                : undefined
            }
          />
        </Flex>
      </Flex>
      <Flex direction="column" className="mt6">
        <Flex>
          <Tooltip
            trigger="hover"
            overlay={
              accountAnalyticsSelected
                ? `“Group by” is not applicable
                    for Account analytics`
                : null
            }
            placement={"right"}
          >
            <Text.Body weight="semibold" color={`var(${TextColor.SECONDARY})`}>
              Group By
            </Text.Body>
          </Tooltip>
        </Flex>
        <Flex className="mt3">
          <Select
            placeholder="Group By"
            disabled={accountAnalyticsSelected || loading}
            className={"w100"}
            id={"campaign_list-download-group_by"}
            searchable={false}
            onChange={(option) => updateFormState({ groupBy: option.value })}
            value={selectedGroupByOption}
            options={GROUP_BY_OPTIONS}
          />
        </Flex>
      </Flex>
      <Flex direction="column" className="mt6">
        <Flex>
          <Tooltip
            overlay={
              accountAnalyticsSelected
                ? `“Time breakdown” is not applicable
                for Account analytics`
                : null
            }
            trigger="hover"
          >
            <Text.Body weight="semibold" color={`var(${TextColor.SECONDARY})`}>
              Time breakdown
            </Text.Body>
          </Tooltip>
        </Flex>
        <Flex className="mt3">
          <Select
            disabled={accountAnalyticsSelected || loading}
            className="w100"
            placeholder="Time breakdown"
            id={"campaign_list-download-time-breakdown"}
            options={
              loading
                ? TIME_BREAKDOWN_OPTIONS.map((o) => ({ ...o, loading }))
                : TIME_BREAKDOWN_OPTIONS
            }
            searchable={false}
            value={selectedTimeBreakdownOption}
            onChange={(option) =>
              updateFormState({ timeBreakdown: option.value })
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
FormComponent.propTypes = {
  loading: PropTypes.func,
  updateFormState: PropTypes.func,
  dateRange: PropTypes.object,
  analyticsType: PropTypes.string,
  accountType: PropTypes.string,
  groupBy: PropTypes.string,
  timeBreakdown: PropTypes.string,
};
const formStateToProps = (state) => ({
  dateRange: formStateSelector(state).dateRange,
  groupBy: formStateSelector(state).groupBy,
  timeBreakdown: formStateSelector(state).timeBreakdown,
});
const formDispatchToProps = {
  updateFormState: updateFormStateAction,
};
const Form = connect(formStateToProps, formDispatchToProps)(FormComponent);

// eslint-disable-next-line react/prop-types,max-len
const Minimised = ({ onExpand, cancelExport, toggleVisibility }) => {
  const PREPARING_VIEW = (
    <Flex>
      <Flex className={classNames("mr6 mb6", styles.minimisedStatusIcon)}>
        <Spinner size={"sm"} color={`var(${ThemeColor.Primary.DEFAULT})`} />
      </Flex>
      <Flex>
        <div>
          <div>
            <Text.Body
              weight="semibold"
              color={`var(${RootColor.Gray.DARK_4})`}
            >
              Preparing your download...
            </Text.Body>
          </div>
          <div className="mt1 mb4">
            <Text.Body color={`var(${RootColor.Gray.DARK_2})`}>
              Download will start automatically once your
              <br />
              file is ready.
            </Text.Body>
          </div>
          <Flex>
            <Flex className="mr4">
              <Button hierarchy="link-secondary" onClick={onExpand}>
                view
              </Button>
            </Flex>
            <Flex>
              <Button
                hierarchy="link-primary"
                destructive
                onClick={cancelExport}
              >
                Cancel Download
              </Button>
            </Flex>
          </Flex>
        </div>
        <Flex className="ml4">
          <CloseButton onClick={() => toggleVisibility(false)} />
        </Flex>
      </Flex>
    </Flex>
  );

  return <Flex className={styles.minimisedContainer}>{PREPARING_VIEW}</Flex>;
};

Minimised.propTypes = {
  onExpand: PropTypes.bool,
  cancelExport: PropTypes.func,
  toggleVisibility: PropTypes.func,
};

const CampaignCSVExportDrawerComponent = (props) => {
  const {
    visible,
    campaignIds,
    toggleVisibility,
    onSubmit,
    loading,
    cancelExport,
    isMinimised,
    toggleMinimised,
    noOfCampaigns,
    askForEmailReport,
    sendEmail,
    toggleAskForEmail,
    emailRequestLoading,
    analyticsType,
    accountType,
    dateRange,
  } = props;

  const numberOfCampaigns = campaignIds.length || noOfCampaigns;

  const formInvalid = (() => {
    if (dateRange.value === DATE_FILTER_OPTIONS_VALUES.dateRange) {
      if (!dateRange.from || !dateRange.to) {
        return true;
      }
    }

    return false;
  })();

  const onClose = () => {
    if (loading) {
      toggleMinimised(true);
      return;
    }

    toggleVisibility(false);
  };

  const handleEmailModalClose = () => {
    toggleAskForEmail(false);
    cancelExport();
  };

  let icon = Download;
  let title = "Download";
  let subtitle = `${numberOfCampaigns} ${maybePlural(
    numberOfCampaigns,
    "Campaign"
  )}`;

  if (loading) {
    icon = Spinner;
    title = "Preparing For Download...";
    subtitle = (
      <Flex direction="column">
        <Flex>Download will start automatically once your file is ready</Flex>
      </Flex>
    );
  }

  return (
    <React.Fragment>
      {isMinimised ? (
        <Minimised
          onExpand={() => toggleMinimised(false)}
          cancelExport={cancelExport}
          toggleVisibility={toggleVisibility}
        />
      ) : (
        <React.Fragment>
          <Drawer
            open={visible}
            onRequestClose={loading ? () => toggleMinimised(true) : onClose}
            pageLevel
          >
            <Drawer.Header
              icon={icon}
              title={title}
              subtitle={subtitle}
              onRequestClose={onClose}
            />
            <Drawer.Body>
              <Form
                onSubmit={onSubmit}
                loading={loading}
                cancelExport={cancelExport}
                askForEmailReport={askForEmailReport}
                analyticsType={analyticsType}
                accountType={accountType}
              />
            </Drawer.Body>
            <Drawer.Footer>
              {loading ? (
                <Button
                  hierarchy="primary"
                  onClick={cancelExport}
                  destructive
                  data-6si-id="campaign_list-download-cancel-download"
                  data-testid="campaign_list-download-cancel-download"
                >
                  Cancel Download
                </Button>
              ) : (
                <Flex>
                  <Flex className="mr3">
                    <Button
                      hierarchy="secondary"
                      onClick={cancelExport}
                      data-6si-id="campaign_list-download-cancel"
                      data-testid="campaign_list-download-cancel"
                    >
                      Cancel
                    </Button>
                  </Flex>
                  <Flex>
                    <Button
                      hierarchy="primary"
                      disabled={formInvalid || loading}
                      onClick={() =>
                        onSubmit({
                          analyticsType,
                          accountType,
                        })
                      }
                      data-6si-id="campaign_list-download-confirm_download"
                      data-testid="campaign_list-download-confirm_download"
                    >
                      Download
                    </Button>
                  </Flex>
                </Flex>
              )}
            </Drawer.Footer>
          </Drawer>

          <Modal isOpen={askForEmailReport}>
            <Card className={styles.emailPopOver}>
              <Card.Header
                title="Download is taking longer than expected"
                actions={[<CloseButton onClick={handleEmailModalClose} />]}
              />
              <Card.Content>
                <Text.Body>
                  Looks like the report is too large. You can either have the
                  report emailed to you once it's ready, or try to narrow down
                  the settings to download a lighter report.
                </Text.Body>
                <Flex justifyContent="flex-end" className="mt8">
                  <Button
                    hierarchy="secondary"
                    onClick={() => toggleMinimised(false)}
                    className="mr3"
                  >
                    Change Settings
                  </Button>
                  <Button loading={emailRequestLoading} onClick={sendEmail}>
                    Email me the Report
                  </Button>
                </Flex>
              </Card.Content>
            </Card>
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

CampaignCSVExportDrawerComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  campaignIds: PropTypes.array,
  toggleVisibility: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  cancelExport: PropTypes.func.isRequired,
  isMinimised: PropTypes.bool.isRequired,
  toggleMinimised: PropTypes.func.isRequired,
  noOfCampaigns: PropTypes.number,
  askForEmailReport: PropTypes.bool,
  toggleAskForEmail: PropTypes.func,
  emailRequestLoading: PropTypes.bool,
  sendEmail: PropTypes.func,
  analyticsType: PropTypes.string,
  accountType: PropTypes.string,
  dateRange: PropTypes.object,
};

const mapStateToProps = (state) => ({
  visible: modalStateSelector(state).visible,
  campaignIds: modalStateSelector(state).campaignIds,
  loading: modalStateSelector(state).promiseState === PROMISE_STATES.PENDING,
  isMinimised: modalStateSelector(state).isMinimised,
  noOfCampaigns: modalStateSelector(state).noOfCampaigns,
  askForEmailReport: modalStateSelector(state).askForEmailReport,
  emailRequestLoading:
    emailReportStateGenerator.promiseStateSelector(state) ===
    PROMISE_STATES.PENDING,
  analyticsType: formStateSelector(state).analyticsType,
  accountType: formStateSelector(state).accountType,
  dateRange: formStateSelector(state).dateRange,
});
const mapDispatchToProps = {
  toggleVisibility: actions.toggleVisibility,
  onSubmit: actions.submitDownloadForm,
  cancelExport: actions.cancelExport,
  toggleMinimised: actions.toggleMinimised,
  toggleAskForEmail: actions.toggleAskForEmail,
  sendEmail: emailReportStateGenerator.loadAction,
};

const CampaignCSVExportDrawer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignCSVExportDrawerComponent);

export default CampaignCSVExportDrawer;
