import { Modal } from 'v2-components';
import linkedInLogo from 'images/IntegrationLogos/linkedin-logo.svg';
import { LINKEDIN_PAGE_STATUS } from 'utils/constants';
import { LINKEDIN_SPONSORED_CONTENT_POSTER_REQUEST_SENT_PROMPT } from 'constants/campaigns';
import { Permission } from '@sixsense/rbac';

export const FEATURE_TITLE = 'LinkedIn Advertising';

export const REQUEST_POSTER_ACCESS_FORM = 'requestPosterAccess';

export const ROUTE_STATE_KEY = 'linkedinAdvertising';

export const ACTION_PREFIX = 'LINKEDIN_ADVERTISING';

export const ADDITIONAL_RESOURCES = [
  {
    text: 'How to approve content poster request on LinkedIn?',
    onClick: () => Modal.prompt(LINKEDIN_SPONSORED_CONTENT_POSTER_REQUEST_SENT_PROMPT),
  },
  {
    text: 'LinkedIn Native Integration FAQs',
    link: 'https://support.6sense.com/knowledge-base/faqs-linkedin-advertising-beta/',
  },
];

export const LinkedinAdvertisingConfig = {
  header: {
    mainTitle: FEATURE_TITLE,
    category: 'Ads',
    logo: linkedInLogo,
    subTitle: 'by LinkedIn.Inc',
  },
  about: {
    mainInfo: {
      title: 'About this Integration',
      description:
        `The 6sense integration with LinkedIn allows you to connect your 
        LinkedIn business account to launch, manage, and monitor 
        LinkedIn advertising campaigns directly through the 6sense platform. 
        This feature supports Sponsored Single Image Ad types on LinkedIn properties. 
        For additional ad types, leverage 6sense’s LinkedIn Segment sync.`,
    },
    metaInfo: [
      {
        name: 'Category',
        value: 'Ads',
      },
      {
        name: 'Feature Support',
        value: 'Advertising',
      },
    ],

    keyfeatures: {
      title: 'Key Features',
      features: [
        {
          name: 'Account targeting',
          value: '',
        },
        {
          name: 'Leverage LinkedIn Profile targeting for B2B ',
          value: '',
        }, {
          name: 'Set your own max bid prices and budget',
          value: '',
        }, {
          name: 'Forecast campaign reach',
          value: '',
        }, {
          name: 'Account-level reporting',
          value: '',
        }, {
          name: 'Pipeline & revenue reporting',
          value: '',
        },
      ],
    },
  },

  help: {
    links: [
      {
        title: 'Additional Resources',
        list: ADDITIONAL_RESOURCES.map(
          ({ text, link, onClick }) => ({ text, href: link, onClick })
        ),
      },
    ],
  },
};

export const LINKEDIN_PAGE_ERRORS = {
  // eslint-disable-next-line max-len
  [LINKEDIN_PAGE_STATUS.UNKNOWN]: "Couldn't fetch Poster Access from LinkedIn. Either try refreshing the page or contact Support!",
};

export const LINKEDIN_ADS_CONSENT_KEY = 'linkedin_native_consent';
export const LINKEDIN_ADS_CONSENT_PERMISSION = Permission.LINKEDIN_ADS_INTEGRATION_CONSENT_EDIT;
