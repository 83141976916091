import { datadogRum } from '@datadog/browser-rum';
import { isLocal as _isLocal } from 'utils/utils';
import { get, isEmpty } from 'lodash';

const isLocal = _isLocal();

const DD_APP_ID = '095330d8-7ef7-49cf-b883-13976dac8986';
const DD_CLIENT_TOKEN = 'pubfeb009a46279da34cbc0bd2aa9755056';
const DD_HEADER_KEY = 'X-DDOG-SESSION-ID';

const enabled = false;

const initDatadog = () => {
  if (!isLocal && enabled) {
    datadogRum.init({
      applicationId: DD_APP_ID,
      clientToken: DD_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'aa_ui',
      env: window.process.env.SIXSENS_ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100, // if not included, the default is 100
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask',
      allowedTracingUrls: [
        /https:\/\/.*\.abm\.6sense\.com/,
      ],
    });

    datadogRum.startSessionReplayRecording();
  }
};

const identifyDatadogUser = (user) => {
  if (!isLocal && !isEmpty(user) && enabled) {
    datadogRum.setUser({
      id: user.id,
      // email: get(user, 'username', ''),
      orgId: get(user, 'organization.id', ''),
      org: get(user, 'organization.org_name', ''),
    });
  }
};

const getDatadogSessionIdHeader = () => {
  const ctx = datadogRum.getInternalContext();
  const sessionId = ctx?.session_id;
  if (sessionId) {
    return { headers: { [DD_HEADER_KEY]: sessionId } };
  }

  return {};
};

export {
  initDatadog,
  identifyDatadogUser,
  getDatadogSessionIdHeader,
};
