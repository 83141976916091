import React from 'react';
import { Link } from 'v2-components';

export const ORCHESTRATION = 'orchestration';
export const SALES_INTELLIGENCE = 'sales_intelligence';
export const ACCOUNT_DETAILS = 'account_details';
export const GTM = 'gtm';
export const SIXSENSE_APIS = '6sense_apis';

export const FORM_PUBLISHED_SEGMENT = 'form_published_segment';
export const FORM_MODIFY_TAGS = 'form_modify_tags';

export const NO_PREDICTIVE_TOOLTIP = `Predictive is not part of your current subscription plan.
\n Contact your 6sense Customer Success manager to learn more`;

export const NOT_HAS_ESSENTIALS_TOOLTIP = `This data has not been synced or integrated.
\n Contact your 6sense Customer Success manager to learn more`;

const APITOKEN_MGMT_LINK = '/settings/integration/apitokenmanagement';

export const NO_SIXSENSE_APIS_TOOLTIP = () => (
  <React.Fragment>
    To use these settings, please enable <b>Segments</b> and then choose{' '}
    <b>Published segments</b> option in the API Settings & Configurations
    section. <br />{' '}
    <Link link={APITOKEN_MGMT_LINK} newWindow>
      Click here{' '}
    </Link>{' '}
    to enable.
  </React.Fragment>
);

const GTM_LINK = 'https://support.6sense.com/hc/en-us/articles/1500000409701';
const PUBLISHED_SEG_LINK = 'https://support.6sense.com/hc/en-us/articles/360052999093';

const PUBLISHED_LINK_SECTION = (
  <div style={{ marginBottom: '10px' }}>
    Please refer to the <Link link={PUBLISHED_SEG_LINK}>Publishing Segments support article </Link>
    for more details.
</div>
);

export const ACCOUNT_DETAILS_HELP_TEXT = (
  <React.Fragment>
    <div style={{ marginBottom: '10px' }}>
      If an Account is part of a published Segment, the Segment name will be visible within
      the Segments section on the Account Details page. If your Segment has a descriptive name,
      then showing the Segment within Account Details will be an effective way to communicate
      custom, actionable insights to your sales team.
    </div>
    {PUBLISHED_LINK_SECTION}
  </React.Fragment>
);

export const SALES_INTELLIGENCE_HELP_TEXT = (
  <React.Fragment>
    <div style={{ marginBottom: '10px' }}>
      If an Account is part of a Segment published to Sales Intelligence, the
      Segment name will be visible within the Segments section within Sales Intelligence.
      If your Segment has a descriptive name, then showing the Segments that the Account
      is associated with will be an effective way to communicate custom, actionable insights
      to your sales team.
    </div>
    {PUBLISHED_LINK_SECTION}
  </React.Fragment>
);

export const ORCHESTRATION_HELP_TEXT = (
  <React.Fragment>
    <div style={{ marginBottom: '10px' }}>
      If an Account is part of a Segment published to
      Segment Name Enrichment, the segment name can be included in the Segment Name orchestration.
      This orchestration, when configured and activated, can enrich a specified field in supported
      external systems such as CRM and MAP platforms with a list of segment names the account is
      a member of as of the date of enrichment.
    </div>
    <div style={{ marginBottom: '10px' }}>
      This will enable your team to build custom dashboards, reports, and automation based
      on an account's inclusion in one of more 6sense segments.
    </div>
    {PUBLISHED_LINK_SECTION}
  </React.Fragment>
);

const GTM_FILTER_INFO = (
  <React.Fragment>
    <div>
      1. Profile-based filters
    </div>
    <div>
      2. Integrations-based filters
    </div>
    <div>
      3. Current Predictive Profile Fit (Account) filter
    </div>
    <div style={{ marginBottom: '10px' }}>
      4. Account Source filter
    </div>
    <div>
      Please refer to the
      <Link link={PUBLISHED_SEG_LINK}> Publishing Segments support article </Link> and the
      <Link link={GTM_LINK}> filter-based segment support article</Link> for more details.
    </div>
  </React.Fragment>
);

export const GTM_HELP_TEXT = (
  <React.Fragment>
    <div style={{ marginBottom: '10px' }}>
      Accounts that are included in a Go-to-Market (GTM) Segment can be used for AI-driven
      orchestrations. Only segments created via account list upload, CRM Report Sync, CRM
      List Sync, or those using the following filters can be published as GTM Segments:
    </div>
    {GTM_FILTER_INFO}
  </React.Fragment>
);

export const GTM_INFO = (
  <React.Fragment>
    <div style={{ marginBottom: '10px' }}>
      This segment is ineligible to be a GTM Segment. Only segments created via account list
      upload, CRM Report Sync, CRM List Sync, or those using the following filters can
      be published as GTM Segments:
    </div>
    {GTM_FILTER_INFO}
  </React.Fragment>
);

export const SIXSENSE_APIS_INFO_PART1 = (
  <React.Fragment>
    If a Segment is published for All 6sense APIs, Segment details will be
    visible in these APIs.
    <ul>
      <li>
        Company Identification API - https://epsilon.6sense.com/v3/company/details
      </li>
      <li>
        Segment API - https://scribe.6sense.com/v1/company/segments
      </li>
      <li>
        Company Firmographics API - https://scribe.6sense.com/v2/people/enrichment
      </li>
      <li>
        Lead Scoring and Firmographics API - https://scribe.6sense.com/v2/people/full
      </li>
    </ul>
    <div style={{ marginBottom: '10px', marginTop: '10px' }}>
      API response will return Segment Details as ID, Name and List.
    </div>
  </React.Fragment>
);

export const SIXSENSE_APIS_INFO_PART2 = (
  <React.Fragment>
    <div style={{ marginTop: '10px' }}>
      Please refer to the &nbsp;
      <Link link="https://support.6sense.com/hc/en-us/articles/360052999093" newWindow>
        Publishing Segments support article
      </Link>&nbsp;
      for more details.
    </div>
  </React.Fragment>
);

export const TAG_OPTIONS = [
  { value: 'all', label: 'All Published Segments' },
  { value: ORCHESTRATION, label: 'Segment Name Enrichment' },
  { value: SALES_INTELLIGENCE, label: 'Sales Intelligence Experience' },
  { value: ACCOUNT_DETAILS, label: 'Account Details Experience' },
  { value: GTM, label: 'GTM Segment Nomination' },
  { value: SIXSENSE_APIS, label: '6sense APIs' },
];
