import { createSelector } from 'reselect';
import { get, isEmpty } from 'lodash';
import { campaignListStateGenerator } from './stateGenerators';
import { flattenCampaignSelections, getAllSelectedCampaigns } from './utils';
import { globalStateSelector } from './commonSelectors';
import { campaignsRouteLoadingSelector } from '../../selectors';
import { campaignClassificationDucks } from 'routes/Advertising/ducks';
import { PROMISE_STATES } from 'modules/global/constants';
import { orgFeatureFlagsSelector } from '@sixsense/core/featureFlags';
import ALL_COLUMNS from './ColumnDefinitions';
import { isFeatureFlagEnabledForOrg } from 'routes/Advertising/featureGating/utils';

export const isCampaignSelectedSelector = (campaignId) => createSelector(
  globalStateSelector,
  ({ campaignListSelections, pageNumber }) => Boolean(get(
    campaignListSelections,
    `${pageNumber}.${campaignId}`,
    false
  ))
);

export const isCurrentPageSelectedSelector = createSelector(
  globalStateSelector,
  campaignListStateGenerator.dataSelector,
  ({ campaignListSelections, pageNumber }, campaignListData) => {
    const campaignList = get(campaignListData, 'results', []);
    if (campaignList.length === 0) return false;

    const allSelected = campaignList.reduce(
      (acc, { id }) => get(campaignListSelections, `${pageNumber}.${id}`, false) && acc,
      true,
    );

    return allSelected;
  }
);

export const isCurrentPageIndeterminateSelector = createSelector(
  globalStateSelector,
  campaignListStateGenerator.dataSelector,
  ({ campaignListSelections, pageNumber }, campaignListData) => {
    const campaignList = get(campaignListData, 'results', []);
    if (campaignList.length === 0) return false;

    const anySelected = campaignList.some((campaign) => get(
      campaignListSelections,
      `${pageNumber}.${campaign.id}`,
      false
    ));

    return anySelected;
  }
);

export const campaignSelectionsSelector = createSelector(
  globalStateSelector,
  ({ campaignListSelections }) => flattenCampaignSelections(campaignListSelections)
);

export const allSelectedCampaignsSelector = createSelector(
  globalStateSelector,
  ({ campaignListSelections }) => getAllSelectedCampaigns(campaignListSelections)
);

export const getSelectedDateRangeSelector = createSelector(
  globalStateSelector,
  ({ dateRangeFilter = null }) => dateRangeFilter
);

export const getCampaignSearchTextSelector = createSelector(
  globalStateSelector,
  ({ search }) => search
);

export const isFiltersStateInitialisedSelector = createSelector(
  globalStateSelector,
  ({ filters }) => !isEmpty(filters),
);

const archiveCampaignModal = (state) => state.campaigns.archiveCampaignModal;
export const isArchiveCampaignModalVisible = createSelector(
  archiveCampaignModal,
  ({ isVisible }) => isVisible,
);

export const campaignIdsToArchive = createSelector(
  archiveCampaignModal,
  ({ campaignIds }) => campaignIds,
);

export const campaignsListLoadingSelector = createSelector(
  campaignsRouteLoadingSelector,
  campaignClassificationDucks.stateGenerators.campaignClassifications.promiseStateSelector,
  (
    routeLoading,
    campaignClassificationsPromiseState,
  ) =>
  routeLoading ||
  campaignClassificationsPromiseState === PROMISE_STATES.PENDING
);

export const campaignAllColumnsSelector = createSelector(
  orgFeatureFlagsSelector,
  (orgFeatureFlags) => ALL_COLUMNS.filter(({ featureFlagKey }) => featureFlagKey ?
    isFeatureFlagEnabledForOrg({
      orgFeatureFlags, featureIdentifier: featureFlagKey,
    }) : true
  )
);
