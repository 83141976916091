import React from 'react';
import { classNames } from 'utils/utils';
import styles from './NeedActionIndicator.module.scss';
import Icon from 'v2-components/Icon';
import { COLORS } from 'v2-styles/constants';
import Text from 'v2-components/Text';
import PropTypes from 'prop-types';
import { AUDIT_TRAIL_FAILED_MSG } from '../../constants';
import Row from 'v2-components/Row';
import { LINKEDIN_POSTER_ACCESS_REJECTED, LINKEDIN_POSTER_ACCESS_REVOKED } from 'utils/constants';

const NeedActionIndicator = ({ className, type, children }) => (<div
  className={classNames(styles.needActionIndicatorContainer, className)}
>
  <div className={styles.needActionIndicatorIcon}>
    <Icon
      type={type}
      color={type === 'warning' ? COLORS.YELLOW : COLORS.ERROR}
    /></div>
  <Text
    type={Text.TYPE.SUBBODY}
    color={type !== 'warning' && Text.COLOR.ERROR}
    className={styles.needActionIndicators}
  >
    {children}
  </Text>
</div>);
NeedActionIndicator.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['error', 'warning']),
  children: PropTypes.node,
};

export default NeedActionIndicator;

export const summaryTooltip = (needActionIndicators) => {
  if (
    needActionIndicators.length === 1 &&
    [
      AUDIT_TRAIL_FAILED_MSG,
      LINKEDIN_POSTER_ACCESS_REJECTED,
      LINKEDIN_POSTER_ACCESS_REVOKED,
    ].includes(needActionIndicators[0].message)
  ) {
    return null;
  }

  return (
    <ul
      className={
        classNames(styles.needActionTooltipTitle,
          needActionIndicators.length === 1 && styles.removeLeftPadding,
        )}
    >
      {needActionIndicators.map(({ message, tooltip }) => (
        <Row className={styles.tooltipContainer} flexDirection={Row.FLEX_DIRECTION.COLUMN}>
          {needActionIndicators.length > 1 && <li>
            <Text bold color={COLORS.WHITE}>{message}</Text>
          </li>}
          {tooltip && <div>
            <Text color={COLORS.WHITE}>{tooltip}</Text>
          </div>}
        </Row>))}
    </ul>);
};
