export default function savedReportRoutes() {
  return {
    path: ':reportId',
    name: 'savedReport',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const savedReportloader = require('./SavedReportLoader');
        cb(null, savedReportloader.default);
      }, 'savedReportloader');
    },
  };
}

