import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { generateRandomId } from 'utils/utils';

const Tooltip2 = (props) => {
  const [tooltipId, setTooltipId] = useState('');

  useEffect(() => {
    const randomId = generateRandomId();
    setTooltipId(randomId);
  }, []);

  if (props.overlay) {
    return (<Tooltip
      {...props}
      overlayStyle={{ width: 'max-content', maxWidth: '500px', ...props.overlayStyle }}
      getTooltipContainer={() =>
      document.getElementById(tooltipId) || document.getElementsByTagName('body')[0]
    }
    >
      <span style={{ position: 'relative', ...props.style }} id={tooltipId}>
        {props.children}
      </span>
    </Tooltip>);
  }
  return props.children;
};

Tooltip2.propTypes = {
  children: PropTypes.any,
  overlay: PropTypes.any,
  style: PropTypes.any,
  overlayStyle: PropTypes.object,
  ...Tooltip.propTypes,
};

export default Tooltip2;
