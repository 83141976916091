import React from 'react';

/* eslint max-len: [2, 100000] */
export function KeywordHighlight(props) {
  return (
    <svg {...props} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <title>keywords-highlight</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M965.075 534.297v-94.074c0-43.595-26.935-78.012-61.053-78.012h-745.21c-34.117 0-61.053 34.418-61.053 78.013v94.074c0 43.596 26.935 78.013 61.053 78.013h743.413c35.914 2.294 62.85-34.417 62.85-78.013zm-840.38 0v-94.074c0-25.24 16.16-43.595 34.118-43.595h743.413c19.753 0 34.118 20.65 34.118 43.595v94.074c0 25.24-16.16 43.596-34.118 43.596H158.813c-17.957 2.294-34.118-18.356-34.118-43.596zM571.82 336.97h404.03c21.548 0 39.504-22.943 39.504-50.477v-50.48c0-27.533-17.956-50.478-39.505-50.478H571.82c-21.548 0-39.505 22.945-39.505 50.48v50.478c0 27.534 17.957 50.478 39.505 50.478zm-12.57-100.956c0-9.178 5.387-16.06 12.57-16.06h404.03c7.182 0 12.57 6.882 12.57 16.06v50.48c0 9.177-5.388 16.06-12.57 16.06H571.82c-7.183 0-12.57-6.883-12.57-16.06v-50.48zM449.714 639.867H47.48c-21.548 0-39.505 22.945-39.505 50.48v50.477c0 27.534 17.957 50.48 39.505 50.48h404.03c21.547 0 39.504-22.946 39.504-50.48v-50.478c-1.795-27.534-17.957-50.48-41.3-50.48zm12.57 100.934c0 9.18-5.388 16.063-12.57 16.063H47.48c-7.183 0-12.57-6.884-12.57-16.062v-50.478c0-9.178 5.387-16.06 12.57-16.06h404.03c7.182 0 12.57 6.882 12.57 16.06v50.48h-1.797zM243.21 293.39v-64.273c0-9.888 3.59-19.776 7.182-19.776H489.22c3.59 0 7.18 9.89 7.18 19.777v64.273c0 9.888-3.59 19.776-7.18 19.776H250.39c-3.59 0-7.182-9.888-7.182-19.776zm437.972 534.38c0 4.944-3.587 9.888-7.174 9.888H541.285c-3.587 0-7.174-4.944-7.174-9.89v-32.135c0-4.944 3.588-9.888 7.175-9.888h132.723c3.587 0 7.174 4.944 7.174 9.888v32.136zm-460.4-544.035c0 5.434-3.588 10.87-7.175 10.87H80.884c-3.587 0-7.174-5.436-7.174-10.87v-35.323c0-5.435 3.587-10.87 7.174-10.87h132.723c3.587 0 7.174 5.435 7.174 10.87v35.323zm589.766 385.77v64.272c0 9.888-3.59 19.776-7.18 19.776h-260.28c-3.59 0-7.182-9.888-7.182-19.776v-64.273c0-9.89 3.59-19.777 7.18-19.777h260.28c3.59 4.944 7.182 9.888 7.182 19.777z"
          fill="#B0BEC5"
        />
        <path
          d="M450.01 520.353v-59.217c0-15.888 8.44-27.442 17.815-27.442h388.173c10.314 0 17.814 12.998 17.814 27.442v59.217c0 15.887-8.438 27.442-17.814 27.442H467.825c-9.376 1.444-17.815-11.555-17.815-27.442zm3.772-177.975c0-7.645 4.56-13.378 10.643-13.378H806.53c6.08 0 10.642 5.733 10.642 13.378v42.045c0 7.644-4.56 13.378-10.643 13.378H464.424c-6.082 0-10.643-5.733-10.643-13.377v-42.045zM174 422.766v-42.59c0-6.553 3.04-13.106 6.082-13.106h202.22c3.042 0 6.083 6.553 6.083 13.105v42.59c0 6.554-3.04 13.106-6.082 13.106h-202.22c-3.042 0-6.083-6.55-6.083-13.104zM511.24 599.08v42.59c0 6.553-3.04 13.106-6.08 13.106H284.774c-3.04 0-6.08-6.553-6.08-13.105v-42.59c0-6.552 3.04-13.105 6.08-13.105H505.16c3.04 3.276 6.08 6.553 6.08 13.105z"
          fill="#13BBB2"
        />
      </g>
    </svg>
  );
}
