import React, { useState } from 'react';
import { Loading, Flex, Card, ResultBoundary } from '@sixsense/core/components';
import { KeywordFilters, KeywordTable, NoDataComponent } from '../components';
import { DEACTIVATED, DEACTIVATED_TABLE_HEADERS } from '../constants';
import { ResultStatus } from '@sixsense/core/shared/async-result/types';
import { useSharedState, useRequestValue } from '@sixsense/core';
import { useOrg } from 'hooks';
import { maybePlural } from 'utils/utils';
import { getQueryParams, getPaginationParams } from '../utils';
import {
  deactivatedConfigState,
  restoreKeywordsVisibleState,
  deactivatedKeywordsRequestState,
} from '../state';
import { RestoreKeywordsModal, CreateGroupModal } from '../components/Modals';

const DeactivatedKeywordsContainer = () => {
  const [config, setConfig] = useSharedState(deactivatedConfigState);
  const [modalVisible, setModalVisible] = useSharedState(restoreKeywordsVisibleState);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const org = useOrg();
  const queryParams = getQueryParams({ ...config.filters, is_deleted: 1 }, true);
  const paginationParams = getPaginationParams(config.pagination);
  const keywordsResult = useRequestValue(
    deactivatedKeywordsRequestState,
    {
      orgId: org.id,
      queryParams,
      paginationParams,
      counter: config.counter,
    }
  );

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <KeywordFilters
        pageKey={DEACTIVATED}
        label={`${keywordsResult.data?.count || 0} Deactivated
          ${maybePlural(keywordsResult.data?.count || 0, 'Keyword')}`}
        multiLabel={`${config.selectedValues.length} Keywords selected`}
        disabled={keywordsResult.status === ResultStatus.LOADING}
      />
      <ResultBoundary
        result={keywordsResult}
        renderLoading={() => <Loading.Spinner level="page" />}
      >
        {({ data }) => (
          <Card.Content
            style={{
              background: 'white',
              border: '1px solid #C6CACE',
              padding: '0px',
            }}
          >
            {data.results ? (
              <KeywordTable
                fromAddToGroup={false}
                requestData={(pageNumber, pageSize) =>
                  setConfig({
                    ...config,
                    pagination: { pageSize, pageNumber },
                    selectedValues: [],
                  })}
                totalDataCount={keywordsResult.data?.count}
                pageSize={config.pagination.pageSize}
                currentPage={config.pagination.pageNumber}
                pageOptions={[25, 50, 75, 100]}
                dataset={keywordsResult.data?.results || []}
                pageKey={DEACTIVATED}
                headers={DEACTIVATED_TABLE_HEADERS}
              />
            ) :
              <NoDataComponent
                filters={config.filters}
                noDataStr={'You have not deactivated any keywords yet. When you deactivate ' +
                  'keywords, they show up in this section. You can restore them later.'}
                noMatchStr={'No Keywords match your filters.'}
              />
            }
            {modalVisible && (
              <RestoreKeywordsModal
                onCancel={() => setModalVisible(false)}
                keywords={keywordsResult.data?.results || []}
                setCreateGroupVisible={setCreateModalVisible}
              />
            )}
            {createModalVisible && (
              <CreateGroupModal
                onCancel={() => setCreateModalVisible(false)}
                keywords={config.selectedValues}
                action={'Add'}
              />
            )}
          </Card.Content>
        )}
      </ResultBoundary>
    </Flex>
  );
};

export default DeactivatedKeywordsContainer;
