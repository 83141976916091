import { concat } from 'lodash';

const initialState = {
  loading: false,
  searchInput: '',
  offset: 0,
  count: 0,
  withValue: false,
  moreAvailable: false,
  list: [],
};

// segment search bar stuff
const SEARCH = 'DYNAMIC_LIST/CREATE/SEARCH';
const SET_INPUT = 'DYNAMIC_LIST/CREATE/SET_SEGMENT_INPUT';
const RESET_INPUT = 'DYNAMIC_LIST/CREATE/RESET_SEGMENT_INPUT';
const LOAD_LIST_SUCCESS = 'DYNAMIC_LIST/CREATE/LOAD_LIST_SUCCESS';
const LOAD_LIST_FAIL = 'DYNAMIC_LIST/CREATE/LOAD_LIST_FAIL';
const LOAD_SINGLE_SUCCESS = 'DYNAMIC_LIST/CREATE/LOAD_SINGLE_SUCCESS';
const LOAD_SINGLE_FAIL = 'DYNAMIC_LIST/CREATE/LOAD_SINGLE_FAIL';
const SEARCH_MORE = 'DYNAMIC_LIST/CREATE/SEARCH_MORE';
const RESET_STATE = 'DYNAMIC_LIST/RESET';

function loadSingleSuccess(segments = {}) {
  return { type: LOAD_SINGLE_SUCCESS, segments };
}

function loadSingleFail(errorMessage) {
  return { type: LOAD_SINGLE_FAIL, errorMessage };
}

function setSearchInput(searchInput) {
  return { type: SET_INPUT, searchInput };
}

function search(name, withValue = false, filters = []) {
  return { type: SEARCH, name, withValue, filters };
}

function reset() {
  return { type: RESET_STATE };
}

function searchMore(name, filters = []) {
  return { type: SEARCH_MORE, name, filters };
}

function loadSuccess(list = {}, count = 0, hasMore = false, hasPrevious = false) {
  return { type: LOAD_LIST_SUCCESS, list, count, hasMore, hasPrevious };
}

function loadFail(errorMessage) {
  return { type: LOAD_LIST_FAIL, errorMessage };
}

function searchDynamicListReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INPUT: {
      const { searchInput } = action;
      let list = state.list;
      if (searchInput === '') {
        list = [];
      }
      return { ...state, searchInput, list, offset: 0, moreAvailable: false };
    }
    case RESET_INPUT: {
      return { ...state, searchInput: '' };
    }
    case SEARCH: {
      return { ...state, loading: true, withValue: action.withValue };
    }
    case SEARCH_MORE: {
      const { offset, list } = state;
      const newOffset = list.length + offset;
      return { ...state, loading: true, newOffset };
    }
    case LOAD_LIST_SUCCESS: {
      const { withValue, count } = state;
      const { list, hasMore, hasPrevious } = action;

      let filteredList;
      if (hasPrevious) {
        filteredList = concat(state.list, list);
      } else {
        filteredList = list;
      }
      const newCount = withValue ? count : action.count;

      return {
        ...state,
        loading: false,
        error: false,
        count: newCount,
        offset: filteredList.length,
        list: filteredList,
        moreAvailable: hasMore,
      };
    }
    case LOAD_LIST_FAIL: {
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.errorMessage,
      };
    }
    case LOAD_SINGLE_SUCCESS: {
      const list = state.list.concat(action.list);
      return { ...state, list, error: false };
    }
    case LOAD_SINGLE_FAIL: {
      return { ...state, error: true, errorMessage: action.errorMessage };
    }
    case RESET_STATE: return { ...initialState };
    default:
      return state;
  }
}

export const actions = {
  search,
  searchMore,
  loadSuccess,
  loadFail,
  setSearchInput,
  loadSingleSuccess,
  loadSingleFail,
  reset,
};

export const actionTypes = {
  SEARCH,
  SEARCH_MORE,
};

export default searchDynamicListReducer;
