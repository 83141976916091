import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'config',
    name: 'Configuration',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('config', reducer.default);
        injectSagas('config', sagas.default);
        cb(null, containers.Config);
      }, 'config');
    },
  };
}
