// selectors
// These selectors just get dingart and down, not the whole state
import { createSelector } from 'reselect';
import { map, get } from 'lodash';
import { COLORS } from 'v2-styles/constants';
import { queryStringParamsSelector } from 'modules/global/selectors';
import { predictiveProductsSelector } from 'modules/user/selectors';

const industryColors = [
  COLORS.AA_BLUE,
  COLORS.AA_GREEN,
  COLORS.AA_PURPLE,
  COLORS.AA_MAROON,
  COLORS.AA_YELLOW,
];

const stateSelector = (state) => state;

const routeStateSelector = createSelector(stateSelector, (state) => state.discoverDashboard);

const spanSelector = createSelector(
  [routeStateSelector, queryStringParamsSelector],
  (state, params) => {
    // Prefer span in querystring params
    if (params && params.span) {
      return params.span;
    }
    return state?.span;
  }
);

const topIndustrySelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.topN.industry[routeState.topN.industry.displayMetric]
);

const topUrlSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.topN.url[routeState.topN.url.displayMetric]
);

const trendMetricSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.trend.metric
);

const trendCategorySelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.trend.category
);

const trendDataSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.trend.data || []
);

const allLoadedSelector = createSelector(
  routeStateSelector,
  (routeState) =>
    routeState.stats.loaded &&
    routeState.trend.loaded &&
    routeState.total.loaded &&
    routeState.topN.loaded
);

const topIndustriesSelector = createSelector(routeStateSelector, (routeState) => {
  const industries = routeState.topN.firm_industry;
  const dataset =
    'accounts' in industries && Array.isArray(industries.accounts)
      ? industries.accounts.slice(0, 5)
      : [];

  return dataset.map(({ firm_industry, accounts }, indx) => ({
    industryName: firm_industry,
    y: accounts,
    color: industryColors[indx],
  }));
});

const topCountriesSelector = createSelector(routeStateSelector, (routeState) => {
  const _countries = routeState.topN.firm_country;
  const dataset = map(
    get(_countries, 'accounts', []),
    ({
      firm_country: countryName,
      accounts: z,
    }) => ({ countryName, z }),
  );

  // dataset is already sorted
  return dataset.slice(0, 9);
});

export const topCRMCampaignsSelector = createSelector(
  routeStateSelector,
  (routeState) => get(routeState, 'topN.crm_campaign.accounts', [])
);

export const topMAPCampaignsSelector = createSelector(
  routeStateSelector,
  (routeState) => get(routeState, 'topN.map_campaign.accounts', [])
);

const keywordStateSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.keywords
);


const brandedKeywordsStateSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.branded,
);

const brandedKeywordsSelector = createSelector(
  brandedKeywordsStateSelector,
  (brandedState) => {
    const brandedKeywords = brandedState.keywords;
    return brandedKeywords
      ? brandedKeywords.sort((x, y) => x.account_count < y.account_count)
      : [];
  }
);

const genericKeywordsStateSelector = createSelector(
  routeStateSelector,
  (routeState) => routeState.generic,
);

const genericKeywordsSelector = createSelector(
  genericKeywordsStateSelector,
  (genericState) => {
    const genericKeywords = genericState.keywords;
    return genericKeywords
      ? genericKeywords.sort((x, y) => x.account_count < y.account_count)
      : [];
  }
);

const keywordsLoadingSelector = createSelector(
  keywordStateSelector,
  (keywordState) => keywordState.loading
);

const keywordsErrorSelector = createSelector(keywordStateSelector, ({ error, errorMessage }) => ({
  error,
  errorMessage,
}));

const keywordsDataSelector = createSelector(
  keywordStateSelector,
  ({ brandedKeywords, genericKeywords }) => brandedKeywords === [] && genericKeywords === []
);

const activityChartStatusSelector = createSelector(routeStateSelector, ({ trend, stats }) => ({
  error: trend.error || stats.error,
  loading: trend.loading || stats.loading,
}));

const buyingStageSourceSelector = createSelector(
  routeStateSelector,
  (route) => route.buyingStage.source
);

const buyingStageDataSelector = createSelector(
  [routeStateSelector, buyingStageSourceSelector],
  ({ buyingStage }, source) => buyingStage[source]
);

const statsSelector = createSelector(routeStateSelector, (route) => route.stats);

const selectedProductSelector = createSelector(
  routeStateSelector,
  predictiveProductsSelector,
  ({ buyingStage = {} }, products = [{ name: '' }]) =>
    buyingStage.selectedProduct || products[0].name,
);

const buyingStageProfileFitSelector = createSelector(
  routeStateSelector,
  ({ buyingStage = {} }) => buyingStage.profileFit,
);

const reloadingSelector = createSelector(
  routeStateSelector,
  ({ buyingStage = {} }) => buyingStage.loading,
);


export {
  spanSelector,
  routeStateSelector,
  topUrlSelector,
  topIndustrySelector,
  trendCategorySelector,
  trendMetricSelector,
  trendDataSelector,
  allLoadedSelector,
  topIndustriesSelector,
  topCountriesSelector,
  brandedKeywordsSelector,
  genericKeywordsSelector,
  keywordsLoadingSelector,
  keywordStateSelector,
  keywordsErrorSelector,
  keywordsDataSelector,
  activityChartStatusSelector,
  buyingStageDataSelector,
  buyingStageSourceSelector,
  statsSelector,
  selectedProductSelector,
  buyingStageProfileFitSelector,
  reloadingSelector,
  brandedKeywordsStateSelector,
  genericKeywordsStateSelector,
};
