import { defineSharedState } from '@sixsense/core';
import { KEYWORDS, GROUPS, DEACTIVATED, GROUP_KW_LIST, ADD_TO_GROUP } from '../constants';
import { PageFilters, SortState, PaginationState } from '../types';

const PATH_PREFIX = 'settings.keywordsPage.config';

export const DEFAULT_PAGINATION_STATE = {
  pageNumber: 1,
  pageSize: 50,
};

export const DEFAULT_PAGE_FILTERS_STATE = {
  [KEYWORDS]: {
    category: 'all',
    product_id: 'all',
    is_deleted: 0,
    query: undefined,
    filterBy: [],
    sortBy: {
      column: 'normalized_keyword',
      order: 'asc',
    },
  },
  [GROUPS]: {
    query: undefined,
    sortBy: {
      column: 'name',
      order: 'asc',
    },
  },
  [GROUP_KW_LIST]: {
    category: 'all',
    product_id: 'all',
    is_deleted: 0,
    keyword_item__keyword_set_id: undefined,
    query: undefined,
    sortBy: {
      column: 'normalized_keyword',
      order: 'asc',
    },
  },
  [ADD_TO_GROUP]: {
    category: 'all',
    product_id: 'all',
    is_deleted: 0,
    query: undefined,
    filterBy: [],
    sortBy: {
      column: 'normalized_keyword',
      order: 'asc',
    },
  },
  [DEACTIVATED]: {
    category: 'all',
    product_id: 'all',
    is_deleted: 1,
    query: undefined,
    sortBy: {
      column: 'normalized_keyword',
      order: 'asc',
    },
  },
};

export const DEFAULT_SORT_ORDER_STATE = {
  [KEYWORDS]: {
    column: 'normalized_keyword',
    order: 'asc',
  },
  [GROUPS]: {
    column: 'name',
    order: 'asc',
  },
  [ADD_TO_GROUP]: {
    column: 'normalized_keyword',
    order: 'asc',
  },
  [GROUP_KW_LIST]: {
    column: 'normalized_keyword',
    order: 'asc',
  },
  [DEACTIVATED]: {
    column: 'normalized_keyword',
    order: 'asc',
  },
};

export type PageConfig = {
  filters: PageFilters,
  sort: SortState,
  pagination: PaginationState,
  selectedValues: any[];
  spanFilter?: string;
  recentlyAddedKws?: any[];
  isRefresh: boolean;
  counter: number;
};

// We can add custom types when each page needs specific config
// ex. type KeywordsConfig = PageConfig & { foo: string; }
export const keywordsConfigState = defineSharedState<PageConfig>({
  path: `${PATH_PREFIX}.keywordsConfig`,
  defaultValue: {
    filters: DEFAULT_PAGE_FILTERS_STATE[KEYWORDS],
    sort: DEFAULT_SORT_ORDER_STATE[KEYWORDS],
    pagination: DEFAULT_PAGINATION_STATE,
    selectedValues: [],
    spanFilter: 'last_7_days',
    recentlyAddedKws: [],
    isRefresh: false,
    counter: 0,
  },
});

export const groupsConfigState = defineSharedState<PageConfig>({
  path: `${PATH_PREFIX}.groupsConfig`,
  defaultValue: {
    filters: DEFAULT_PAGE_FILTERS_STATE[GROUPS],
    sort: DEFAULT_SORT_ORDER_STATE[GROUPS],
    pagination: DEFAULT_PAGINATION_STATE,
    selectedValues: [],
    spanFilter: 'last_7_days',
    isRefresh: false,
    counter: 0,
  },
});

export const groupsKeywordsConfigState = defineSharedState<PageConfig>({
  path: `${PATH_PREFIX}.groupsKeywordsConfig`,
  defaultValue: {
    filters: DEFAULT_PAGE_FILTERS_STATE[GROUP_KW_LIST],
    sort: DEFAULT_SORT_ORDER_STATE[GROUP_KW_LIST],
    pagination: DEFAULT_PAGINATION_STATE,
    selectedValues: [],
    spanFilter: 'last_7_days',
    isRefresh: false,
    counter: 0,
  },
});

export const addKwToGroupConfigState = defineSharedState<PageConfig>({
  path: `${PATH_PREFIX}.addKeywordsToGroupConfig`,
  defaultValue: {
    filters: DEFAULT_PAGE_FILTERS_STATE[ADD_TO_GROUP],
    sort: DEFAULT_SORT_ORDER_STATE[ADD_TO_GROUP],
    pagination: DEFAULT_PAGINATION_STATE,
    selectedValues: [],
    isRefresh: false,
    counter: 0,
  },
});

export const deactivatedConfigState = defineSharedState<PageConfig>({
  path: `${PATH_PREFIX}.deactivatedConfig`,
  defaultValue: {
    filters: DEFAULT_PAGE_FILTERS_STATE[DEACTIVATED],
    sort: DEFAULT_SORT_ORDER_STATE[DEACTIVATED],
    pagination: DEFAULT_PAGINATION_STATE,
    selectedValues: [],
    isRefresh: false,
    counter: 0,
  },
});

type ActivePage = string;

export const activePageState = defineSharedState<ActivePage>({
  path: `${PATH_PREFIX}.activePage`,
  defaultValue: KEYWORDS,
});
