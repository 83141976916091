import { isNumber, isEmpty, isBoolean } from 'lodash';

import {
  NULLEXPR,
  CONST,
  VAREXPR,
  INTCONST,
  BOOLCONST,
  NOTOP,
  AND,
  OR,
  LTOP,
  LEOP,
  EQOP,
  GEOP,
  GTOP,
  STARTSWITHOP,
  CONTAINSOP,
  ENDSWITHOP,
  BETWEENOP,
  NOTLTOP,
  NOTLEOP,
  NOTEQOP,
  NOTGEOP,
  NOTGTOP,
  NOTSTARTSWITHOP,
  NOTCONTAINSOP,
  NOTENDSWITHOP,
  INOP,
} from './constants';

// Primitive Expressions
const NullExpr = () => ({ type: NULLEXPR });

const getConstType = (value) => {
  if (isNumber(value) || (!isEmpty(value) && value.match(/(^[1-9][0-9]*$)|(^0$)/))) {
    return INTCONST;
  }
  if (isBoolean(value)) {
    return BOOLCONST;
  }
  return CONST;
};

const Const = (value) => ({
  value,
  type: getConstType(value),
});

const IntConst = (value) => ({
  value,
  type: INTCONST,
});

const VarExpr = (name, config) => ({ name, type: VAREXPR, ...config });

// Binary Expressions

const BinaryExpr = (type) => (lhs, rhs, order = null) => ({ lhs, rhs, type, order });

const BinaryExprNoOrder = (type) => (lhs, rhs) => ({ lhs, rhs, type });

const And = BinaryExpr(AND);
const Or = BinaryExpr(OR);
const AndNoOrder = BinaryExprNoOrder(AND);
const OrNoOrder = BinaryExprNoOrder(OR);

const LtOp = BinaryExpr(LTOP);
const LeOp = BinaryExpr(LEOP);
const EqOp = BinaryExpr(EQOP);
const GeOp = BinaryExpr(GEOP);
const GtOp = BinaryExpr(GTOP);
const StartsWithOp = BinaryExpr(STARTSWITHOP);
const ContainsOp = BinaryExpr(CONTAINSOP);
const EndsWithOp = BinaryExpr(ENDSWITHOP);

// Negative Binary Expressions

const NotLtOp = BinaryExpr(NOTLTOP);
const NotLeOp = BinaryExpr(NOTLEOP);
const NotEqOp = BinaryExpr(NOTEQOP);
const NotGeOp = BinaryExpr(NOTGEOP);
const NotGtOp = BinaryExpr(NOTGTOP);
const NotStartsWithOp = BinaryExpr(NOTSTARTSWITHOP);
const NotContainsOp = BinaryExpr(NOTCONTAINSOP);
const NotEndsWithOp = BinaryExpr(NOTENDSWITHOP);

// Other expressions

const NotOp = (expr) => ({ expr, type: NOTOP });

const BetweenOp = (expr, ge_op, le_op) => ({
  expr,
  ge_op,
  le_op,
  type: BETWEENOP,
});

const InOp = (expr, exprs) => ({
  expr,
  exprs,
  type: INOP,
});


const TYPE_CONSTRUCTOR_MAP = Object.freeze({
  [NULLEXPR]: NullExpr,
  [CONST]: Const,
  [VAREXPR]: VarExpr,
  [INTCONST]: Const,
  [BOOLCONST]: Const,
  [NOTOP]: NotOp,
  [AND]: And,
  [OR]: Or,
  [LTOP]: LtOp,
  [LEOP]: LeOp,
  [EQOP]: EqOp,
  [GEOP]: GeOp,
  [GTOP]: GtOp,
  [STARTSWITHOP]: StartsWithOp,
  [CONTAINSOP]: ContainsOp,
  [ENDSWITHOP]: EndsWithOp,
  [BETWEENOP]: BetweenOp,
  [INOP]: InOp,
  [NOTLTOP]: NotLtOp,
  [NOTLEOP]: NotLeOp,
  [NOTEQOP]: NotEqOp,
  [NOTGTOP]: NotGtOp,
  [NOTGEOP]: NotGeOp,
  [NOTSTARTSWITHOP]: NotStartsWithOp,
  [NOTCONTAINSOP]: NotContainsOp,
  [NOTENDSWITHOP]: NotEndsWithOp,
});

const SYMBOL_OP_STRING_MAP = {
  '=': EQOP,
  '>': GTOP,
  '>=': GEOP,
  '<': LTOP,
  '<=': LEOP,
  '!=': NOTEQOP,
};

export {
  NullExpr,
  Const,
  IntConst,
  VarExpr,
  NotOp,
  And,
  Or,
  AndNoOrder,
  OrNoOrder,
  LtOp,
  LeOp,
  EqOp,
  GeOp,
  GtOp,
  StartsWithOp,
  ContainsOp,
  EndsWithOp,
  NotLtOp,
  NotLeOp,
  NotEqOp,
  NotGeOp,
  NotGtOp,
  NotStartsWithOp,
  NotContainsOp,
  NotEndsWithOp,
  BetweenOp,
  InOp,
  getConstType,
  TYPE_CONSTRUCTOR_MAP,
  SYMBOL_OP_STRING_MAP,
};
