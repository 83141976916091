import React from 'react';
import { FeaturedIcon } from '@sixsense/core/components';
import { LayersTwo } from '@sixsense/core/icons';
// @ts-ignore
import salesforceLogo from 'images/IntegrationLogos/salesforce-old-logo.svg';

export const SALESFORCE_SALES_ENGAGEMENT_REDUX_STATE_KEY:string = 'SalesforceSalesEngagement';
export const SALESFORCE_SALES_ENGAGEMENT_ROUTE:string = 'hvs';
export const SALESFORCE_SALES_ENGAGEMENT_ACTION_BASESTRING:string =
  'settings/integration/salesforcesalesengagement';
export const SALESFORCE_SALES_ENGAGEMENT_REFRESH_ACTIONNAME:string =
  'settings/integration/salesforcesalesengagement/REFRESH';

export const SalesforceSepSummaryConfig: Object = {
  header: {
    logo: salesforceLogo,
    mainTitle: 'Salesforce Sales Engagement (Sales Cadence)',
    category: 'SEP',
    subTitle: 'by Salesforce.Inc',
  },
  about: {
    mainInfo: {
      title: 'About this Integration',
      description:
        'Our 6sense Sales Intelligence & Salesforce integration ' +
        'also includes Salesforce Sales Engagement and enables sellers to identify hot accounts ' +
        'and buyers in 6sense dashboards and add them directly into applicable ' +
        'Sales Engagement cadences.',
    },
    metaInfo: [
      {
        name: 'Category',
        value: 'SEP',
      },
      {
        name: 'Feature Support',
        value: 'Orchestration, Sales Intelligence',
      },
    ],
    keyfeatures: {
      title: 'Key Features',
      features: [
        {
          icon: <FeaturedIcon theme="light-circle" icon={LayersTwo} color="Gray" />,
          name: 'Prioritize Outreach',
          value: 'By identifying buyers at hot accounts',
        },
        {
          icon: <FeaturedIcon theme="light-circle" icon={LayersTwo} color="Gray" />,
          name: 'Personalize Engagement',
          value: 'With a one-click add into personalized sales cadences',
        },
        {
          icon: <FeaturedIcon theme="light-circle" icon={LayersTwo} color="Gray" />,
          name: 'Automate Workflows and Improve Efficiency ',
          value:
            'By finding and reaching accounts that matter, without leaving SFDC',
        },
      ],
    },
  },
  help: {
    links: [
      {
        title: 'Additional Resources',
        list: [
          {
            href:
              // eslint-disable-next-line max-len
              'https://support.6sense.com/knowledge-base/7417624363411-integrating-6sense-with-salesforce-sales-engagement/',
            text: 'How to install?',
          },
          {
            href:
              // eslint-disable-next-line max-len
              'https://support.6sense.com/knowledge-base/7596839464339-using-salesforce-sales-engagement-with-6sense-sales-intelligence/',
            text: 'Using Salesforce Sales Engagement with 6sense Sales Intelligence',
          },
        ],
      },
    ],
  },
};

export const SALESFORCE_SEP_FORM_STEP_IDS = Object.freeze({
  connect: 'salesforce-sep-connect',
  apilimits: 'salesforce-sep-apilimits',
  datasync: 'salesforce-sep-datasync',
});


export const SALESFORCE_SEP_API_LIMIT_FORM_NAME: string = 'salesforce_sep_api_limit';
