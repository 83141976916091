import React, { useState, Dispatch, SetStateAction } from 'react';
import { css } from 'styles/emotion';
import {
  Link,
  Flex,
  TextArea,
  useToast,
} from '@sixsense/core/components';
import { Input } from 'v2-components';
import { classNames } from 'utils/utils';
import { Plus, Minus, AlertCircle } from '@sixsense/core/icons';
import {
  PAIN_POINTS_PLACEHOLDER,
  VALUE_PROPOSITION_PLACEHOLDER,
  ProductSummaryAttributesType,
} from '../constant';
import { checkFieldLengthAndUpdateError } from '../utils';
import FileUploadComponent from './FileUploadComponent';


const styles = {
  labelStyle: css({
    marginBottom: '8px',
    fontSize: '14px',
  }),
  textArea: css({
    height: '81px',
    fontSize: '14px !important',
    overflow: 'auto !important',
    '::placeholder': {
      color: '#99A0AA',
    },
  }),
  textAreaCount: css({
    marginBottom: '8px',
    color: '#696F7B',
    marginLeft: 'auto',
  }),
  inputStyle: css({
    borderRadius: '6px',
    width: '100%',
    height: '36px',
    padding: '8px 12px',
    fontSize: '14px',
    '&:focus': {
      boxShadow: '0px 0px 0px 4px #CCF0FD',
    },
    '::-webkit-input-placeholder': {
      color: '#99A0AA',
    },
  }),
  addRemoveButton: css({
    fontSize: '14px',
    lineHeight: '20px',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
  }),
  removeBtnCss: css({
    color: '#38414E !important',
  }),
  addRemoveIcon: css({
    marginRight: '8px',
  }),
  errorMsg: css({
    color: '#D92D20',
    fontSize: '12px',
    lineHeight: '18px',
    marginTop: '3px',
  }),
};

type MarketingSalesMaterialProps = {
  marketingSalesAttributes: ProductSummaryAttributesType;
  updateSharedState: any;
  isSaveAsDraftAPISuccess: boolean;
  setIsSaveAsDraftAPISuccess: any;
  isTargetPersonaMaxLengthExceeded: boolean;
  setIsTargetPersonaLengthExceeded: Dispatch<
    SetStateAction<{ index: number; hasError: boolean }[]>
  >;
};
interface MarketingDataProps {
  targeted_persona: string;
  pain_points: string;
  value_proposition: string;
}

export const MarketingSalesMaterial = ({
  marketingSalesAttributes,
  updateSharedState,
  isSaveAsDraftAPISuccess,
  setIsSaveAsDraftAPISuccess,
  isTargetPersonaMaxLengthExceeded,
  setIsTargetPersonaLengthExceeded,
}: MarketingSalesMaterialProps) => {
  const [focusedField, setFocusedField] = useState(null);
  const [personaGroup, setPersonaGroup] = useState(
    marketingSalesAttributes?.personas || [
      { targeted_persona: '', pain_points: '', value_proposition: '' },
    ]
  );
  const personasKey = 'personas';
  const [isInputUpdated, setIsInputUpdated] = useState(false);
  const toast = useToast();
  const targettedPersonaMaxLen = 400;

  // Handler to update form input
  const handleInputChange = (index, value, name) => {
    if (value) {
      setIsInputUpdated(true);
    }
    if (name === 'targeted_persona') {
      checkFieldLengthAndUpdateError(
        index,
        value,
        targettedPersonaMaxLen,
        setIsTargetPersonaLengthExceeded,
      );
    }
    const updatedGroups = [...personaGroup];
    updatedGroups[index][name] = value;
    updateSharedState(personasKey, updatedGroups);
    setPersonaGroup(updatedGroups);

  };

  const addPersonaGroup = () => {
    if (personaGroup?.length < 20) {
      const updatedGroups = [
        ...personaGroup,
        { targeted_persona: '', pain_points: '', value_proposition: '' },
      ];
      setPersonaGroup(updatedGroups);
      updateSharedState(personasKey, updatedGroups);
    } else {
      toast.show({
        type: 'icon',
        title: 'Oops!',
        subtitle: 'You can only add up to 20 personas.',
        icon: AlertCircle,
        iconColor: 'Error',
        duration: 5000,
      });
    }
  };

  const removePersonaGroup = (index) => {
    const updatedGroups = personaGroup?.filter((_, i) => i !== index);
    setPersonaGroup(updatedGroups);
    updateSharedState(personasKey, updatedGroups);
  };

  const painPointsPlaceholder = PAIN_POINTS_PLACEHOLDER;

  return (
    <Flex direction="column">
      {personaGroup?.map(
        (
          {
            targeted_persona,
            pain_points,
            value_proposition,
          }: MarketingDataProps,
          index: number
        ) => (
          <Flex
            direction="column"
            style={{ paddingTop: index !== 0 ? '32px' : '0px' }}
            key={`personas${index}`}
          >
            <Flex direction="column">
              <Flex justifyContent="space-between">
                <label className={styles.labelStyle}>
                  {index + 1}: Target Persona
                </label>
                {personaGroup?.length > 1 && (
                  <Flex direction="column">
                    <Link
                      className={`${styles.addRemoveButton} ${styles.removeBtnCss}`}
                      onClick={() => removePersonaGroup(index)}
                    >
                      {' '}
                      <Minus size={16} className={styles.addRemoveIcon} />
                      Remove
                    </Link>
                  </Flex>
                )}
              </Flex>
              <div>
                <Input
                  maxLength={targettedPersonaMaxLen+1}
                  title="targetedPersona"
                  id="targetedpersona"
                  type="text"
                  className={classNames(styles.inputStyle)}
                  placeholder="Examples: VP of Human Resources or Chief Security Officer"
                  value={targeted_persona}
                  onChange={({ target }) =>
                    handleInputChange(index, target.value, 'targeted_persona')
                  }
                  onFocus={(e) => setFocusedField(e.target.id)}
                  autoFocus={focusedField === 'targetedpersona'}
                  onBlur={() => {
                    if (isInputUpdated) {
                      updateSharedState(personasKey, personaGroup);
                      setIsInputUpdated(false);
                    }
                  }}
                />
                {isTargetPersonaMaxLengthExceeded?.some((err) => err.index === index) && (
                  <div className={styles.errorMsg}>
                    Maximum character limit reached
                  </div>
                )}
              </div>
            </Flex>
            <Flex direction="column" style={{ paddingTop: '20px' }}>
              <Flex style={{ alignItems: 'center' }}>
                <label className={styles.labelStyle}>Pain Points</label>
                <label className={styles.textAreaCount}>
                  {pain_points?.length}/5000
                </label>
              </Flex>

              <TextArea
                id="painpoints"
                className={styles.textArea}
                type="textarea"
                value={pain_points}
                onChange={(value) =>
                  handleInputChange(index, value, 'pain_points')
                }
                placeholder={painPointsPlaceholder}
                onFocus={(e) => setFocusedField(e.target.id)}
                autoFocus={focusedField === 'painpoints'}
                maxLength={5000}
                onBlur={() => {
                  if (isInputUpdated) {
                    updateSharedState(personasKey, personaGroup);
                    setIsInputUpdated(false);
                  }
                }}
              />
            </Flex>
            <Flex direction="column" style={{ paddingTop: '20px' }}>
              <Flex style={{ alignItems: 'center' }}>
                <label className={styles.labelStyle}>Value Proposition</label>
                <label className={styles.textAreaCount}>
                  {value_proposition?.length}/5000
                </label>
              </Flex>

              <TextArea
                id="valueproposition"
                className={styles.textArea}
                type="textarea"
                value={value_proposition}
                onChange={(value) =>
                  handleInputChange(index, value, 'value_proposition')
                }
                placeholder={VALUE_PROPOSITION_PLACEHOLDER}
                onFocus={(e) => setFocusedField(e.target.id)}
                autoFocus={focusedField === 'valueproposition'}
                maxLength={5000}
                onBlur={() => {
                  if (isInputUpdated) {
                    updateSharedState(personasKey, personaGroup);
                    setIsInputUpdated(false);
                  }
                }}
              />
            </Flex>
          </Flex>
        )
      )}
      <FileUploadComponent
        productAttributes={marketingSalesAttributes}
        category="personas"
        updateSharedState={updateSharedState}
        isSaveAsDraftAPISuccess={isSaveAsDraftAPISuccess}
        setIsSaveAsDraftAPISuccess={setIsSaveAsDraftAPISuccess}
      />
      {personaGroup?.length <= 20 ? (
        <Flex direction="column" style={{ width: '190px', paddingTop: '32px' }}>
          <Link className={styles.addRemoveButton} onClick={addPersonaGroup}>
            <Plus size={16} className={styles.addRemoveIcon} />
            Add another persona
          </Link>
        </Flex>
      ) : null}
      <Flex direction="column" style={{ paddingTop: '32px' }}>
        <label className={styles.labelStyle}>Case Studies</label>
        <FileUploadComponent
          productAttributes={marketingSalesAttributes}
          category="marketing_and_sales_case_studies"
          updateSharedState={updateSharedState}
          displayType="dropbox"
          isSaveAsDraftAPISuccess={isSaveAsDraftAPISuccess}
          setIsSaveAsDraftAPISuccess={setIsSaveAsDraftAPISuccess}
        />
      </Flex>
    </Flex>
  );
};
