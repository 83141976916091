import React from 'react';
import { useSelector } from '@sixsense/core/versioned/react-redux';
import {
  authTypeSelector,
} from '../selectors';
import { Icon } from 'v2-components';
import { css } from 'styles/emotion';

const styles = {
  notificationContainer: css({
    height: '52px',
    background: '#E5F2FA',
    border: '1px solid #B2D9F2',
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    margin: '10px',
  }),
  notificationIcon: css({
    color: '#3AA1DE',
    marginLeft: '18px',
    marginRight: '12px',
  }),
  notificationText: css({
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#001F32',
  }),
  impText: css({
    fontWeight: 600,
    fontSize: '14px',
  }),
};

const Notification = () => {
  const isSaml = useSelector(authTypeSelector) === 'SAML';
  return isSaml ? (
    <div className={styles.notificationContainer}>
      <Icon type="info" className={styles.notificationIcon} />
      <span className={styles.notificationText}>
        Your organization is using <span className={styles.impText}>SAML SSO</span> for 6sense.
        Use your SAML
        application to <strong>invite, enable or disable users.</strong>
      </span>
    </div>
  ) : null;
};

export default Notification;
