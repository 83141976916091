import React from 'react';
import {
  Flex,
  Text,
  TextLabel,
} from '@sixsense/core/components';
import { commaSeparatedNumber } from '../utils';

type TCreditDisplayWithInfoProps = {
  label: string;
  value: string;
}
export const CreditDisplayWithInfo = ({ label, value }: TCreditDisplayWithInfoProps) =>
(<Flex direction={'column'}>
  <Flex alignItems="center">
    <TextLabel label={label} />
  </Flex>
  <Flex alignItems={'center'} gap={10}>
    <Text weight={'bold'} size={'1.5rem'}>
      {commaSeparatedNumber(value)}
    </Text>
  </Flex>
</Flex>);
