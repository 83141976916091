import { createReducerUtil } from "utils/core";
import {
  CANCEL_EXPORT,
  EXPORT_FAILED,
  EXPORT_SUCCESS,
  SUBMIT_DOWNLOAD_FORM,
  TOGGLE_MINIMISED,
  TOGGLE_VISIBILITY,
  TOGGLE_ASK_FOR_EMAIL,
  UPDATE_FORM_STATE,
} from "./actions";
import { PROMISE_STATES } from "modules/global/constants";
import {
  ACCOUNTS_TYPE,
  ANALYTICS_TYPE,
  GROUP_BY_VALUES,
  TIME_BREAKDOWN_VALUES,
} from "./constants";
import { combineReducers } from "redux";
import { DEFAULT_OPTIONS } from "routes/AdvertisingDS/routes/Campaigns/components/DateFilter/constants";
import { emailReportStateGenerator } from "./stateGenerators";

const drawerInitialState = {
  visible: false,
  campaignIds: [],
  noOfCampaigns: null,
  additionalQueryParams: {},
  promiseState: PROMISE_STATES.INIT,
  isMinimised: false,
  askForEmailReport: false,
};
const DrawerStateReducer = createReducerUtil(
  drawerInitialState,
  {
    [TOGGLE_VISIBILITY]: (state, action) => {
      if (action.isVisible) {
        return {
          ...state,
          visible: action.isVisible,
          campaignIds: action.campaignIds,
          noOfCampaigns: action.noOfCampaigns,
          additionalQueryParams: action.additionalQueryParams,
          isMinimised: false,
        };
      }

      return drawerInitialState;
    },
    [SUBMIT_DOWNLOAD_FORM]: (state) => ({
      ...state,
      promiseState: PROMISE_STATES.PENDING,
    }),
    [TOGGLE_MINIMISED]: (state, action) => ({
      ...state,
      visible: !action.payload,
      isMinimised: action.payload,
    }),
    [TOGGLE_ASK_FOR_EMAIL]: (state, action) => ({
      ...state,
      promiseState: PROMISE_STATES.INIT,
      askForEmailReport: Boolean(action.payload),
    }),
  },
  [CANCEL_EXPORT, EXPORT_FAILED, EXPORT_SUCCESS]
);

const formInitialState = {
  dateRange: {
    value: DEFAULT_OPTIONS[0].value,
    from: null,
    to: null,
  },
  analyticsType: ANALYTICS_TYPE.campaign,
  accountType: ACCOUNTS_TYPE.sixSense,
  groupBy: GROUP_BY_VALUES.campaigns,
  timeBreakdown: TIME_BREAKDOWN_VALUES.cumulative,
};
const formStateReducer = createReducerUtil(
  formInitialState,
  {
    [TOGGLE_VISIBILITY]: (state, action) => {
      if (action.isVisible) {
        return {
          ...state,
          dateRange: action.dateRange || state.dateRange,
        };
      }

      return formInitialState;
    },
    [UPDATE_FORM_STATE]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  [CANCEL_EXPORT]
);

export default combineReducers({
  modalState: DrawerStateReducer,
  formState: formStateReducer,
  [emailReportStateGenerator.stateKey]: emailReportStateGenerator.reducer,
});
