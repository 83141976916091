import React from 'react';
import UsersCard from './UsersCard';
import OwnerCard from './OwnerCard';
import Notification from './Notification';
import EmailsFilterMissingNotification from './EmailsFilterMissingNotification';

const UsersPage = () => (
  <React.Fragment>
    <Notification />
    <EmailsFilterMissingNotification />
    <OwnerCard owner={{}} />
    <UsersCard />
  </React.Fragment>
);

export default UsersPage;
