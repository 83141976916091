import { combineReducers } from 'redux';
import { createReducerUtil } from 'utils/core';
import { VIEW_MODES, DEFAULT_TABS as initialTabs } from './constants';
import { loadGenericIntgrationStatusGenerator,
  lastFileDropTimeStampGenerator, integrationCadenceGenerator,
  syncSourcePropertyGenerator } from './stateGenerators';


const PRESET_STRING = 'SETTINGS/INTEGRATION';

const SET_VIEW_MODE = `${PRESET_STRING}/SET_VIEW_MODE`;

export const GET_GENERIC_INTEGRATION_STATUS_LOAD_SUCCESS =
'INTEGRATIONS/GET_GENERIC_INTEGRATION_STATUS_LOAD_SUCCESS';

function setViewMode(viewMode) {
  return { type: SET_VIEW_MODE, viewMode };
}

const viewModeReducer = createReducerUtil(VIEW_MODES.SUMMARY, {
  [SET_VIEW_MODE]: (_, action) => action.viewMode,
});

const SET_TABS = `${PRESET_STRING}/SET_TABS`;

function setTabs(tabs) {
  return { type: SET_TABS, tabs };
}

const tabsReducer = createReducerUtil(initialTabs, {
  [SET_TABS]: (_, action) => action.tabs,
  // [SET_VIEW_MODE]: (state, action) => {
  //   if (action.viewMode === 'SUMMARY') {
  //     return [{
  //       value: 'manage',
  //       label: 'Manage',
  //       default: true,
  //     }].concat(state.map((t) => ({ ...t, default: false })));
  //   }
  //   return state;
  // },
});

const SET_CONFIG = `${PRESET_STRING}/SET_CONFIG`;

function setConfig(config) {
  return { type: SET_CONFIG, config };
}

const configReducer = createReducerUtil(
  {},
  {
    [SET_CONFIG]: (_, action) => action.config,
  }
);

const SET_FINISH_MODAL_VISIBILITY = `${PRESET_STRING}/SET_FINISH_MODAL_VISIBILITY`;

function setFinishModalVisibility(visibility) {
  return { type: SET_FINISH_MODAL_VISIBILITY, visibility };
}

const finishModalVisibilityReducer = createReducerUtil(false, {
  [SET_FINISH_MODAL_VISIBILITY]: (_, action) => action.visibility,
});

export const actionTypes = {
  SET_VIEW_MODE,
  SET_TABS,
  SET_CONFIG,
  SET_FINISH_MODAL_VISIBILITY,
};

export const actions = {
  setViewMode,
  setTabs,
  setConfig,
  setFinishModalVisibility,
};

export default combineReducers({
  viewMode: viewModeReducer,
  tabs: tabsReducer,
  config: configReducer,
  finishModalVisibility: finishModalVisibilityReducer,
  [loadGenericIntgrationStatusGenerator.stateKey]:
  loadGenericIntgrationStatusGenerator.reducer,
  [lastFileDropTimeStampGenerator.stateKey]:
  lastFileDropTimeStampGenerator.reducer,
  [integrationCadenceGenerator.stateKey]:
  integrationCadenceGenerator.reducer,
  [syncSourcePropertyGenerator.stateKey]:
  syncSourcePropertyGenerator.reducer,
});
