
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { LayoutCard } from 'HOCS';
import { Row, Col, Checkbox2, Text, Tooltip } from 'v2-components';
import { map, sortBy, partition } from 'lodash';
import { ACTIVITY_TYPES, TOOLTIP_MSG } from '../constants';
import { AAColor, pxToRem } from '@sixsense/core/style';
import { css } from 'styles/emotion';
import { useRequest, useSharedState } from '@sixsense/core';
import { activitiesState } from '../state';
import { Flex, Loading } from '@sixsense/core/components';
import { ActivitiesArray, ActivitiesType } from '../types';
import useCurrentUser from 'hooks/useCurrentUser';
import { SalesPermission, usePermissionCheck } from '@sixsense/rbac';
import { isViewOnlyEnabledFor6senseAccessSelector } from 'modules/user/selectors';
import { useSelector } from '@sixsense/core/versioned/react-redux';


const styles = {
  container: css({
    width: '100%',
  }),
  reachWrap: css({
    paddingTop: pxToRem(20),
    paddingRight: pxToRem(30),
    paddingBottom: pxToRem(15),
    paddingLeft: pxToRem(30),
  }),
  engagementWrap: css({
    borderRight: `1px solid ${AAColor.GREY3}`,
    paddingTop: pxToRem(20),
    paddingRight: pxToRem(30),
    paddingBottom: pxToRem(15),
    paddingLeft: pxToRem(30),
  }),
  title: css({
    fontSize: pxToRem(12),
    color: `${AAColor.GREY}`,
    marginBottom: pxToRem(10),
    lineHeight: pxToRem(16),
    fontWeight: 'bold',
  }),
  checkboxItemWrap: css({
    height: '250px',
  }),
  checkboxItem: css({
    lineHeight: '15px',
    flex: '0 0 0 !important',
  }),
  filtersCheckbox: css({
    top: '-8px',
    position: 'relative',
  }),
  otherIntentSignal: css({
    display: 'grid',
    'grid-template-columns': 'repeat(3, 1fr)',
  }),
  checkBoxContent: css({
    gap: '4px',
    pointerEvents: 'auto',
  }),
  checkBoxRow: css({
    padding: '10px 0px 10px 0px',
  }),
  activityText: css({
    fontWeight: 'lighter',
    fontSize: '13px',
    lineHeight: '18px',
    color: '#001F32',
    a: {
      ':disabled': {
        pointerEvents: 'auto',
        color: 'rgba(0, 0, 0, 0.25) !important',
      },
    },
  }),
  activityCheckBox: css({
    width: '16px',
    height: '18px',
    borderRadius: '2px',
    fontSize: '18px !important',
  }),
};
const ActivitiesCardComponent = ({ orgId, componentsSettings }) => {
  const { activities_toggle_enabled, id: componentId } = componentsSettings;
  const request = useRequest();
  const user = useCurrentUser();
  const [activities, setActivities] = useSharedState(
    activitiesState
  );
  const [loading, setLoading] = useState<Boolean>(false);
  const [activitiesToggle, setActivityToggle] = useState(activities_toggle_enabled);
  const hasEditPermission = usePermissionCheck([SalesPermission.SETTINGS_ACTIVITIES_EDIT]);
  const isViewOnlyEnabled = useSelector(isViewOnlyEnabledFor6senseAccessSelector);

  useEffect(() => {
    getActivities();
  }, []);

  const sortedActivityFilters = sortBy(
    map(activities, (item) => ({
      ...item,
      activity_type_str: ACTIVITY_TYPES[item.activity_type] || item.activity_type,
    })),
    'activity_type_str'
  );
  const [engagementFilters, otherFilters] = partition(sortedActivityFilters, {
    activity_class: 'Engagement',
  });
  const [reachFilters, otherIntentSignalFilters] = partition(otherFilters, {
    activity_class: 'Reach',
  });

  const getActivities = async () => {
    try {
      const data : ActivitiesArray = await request(
        `settings/${orgId}/activity-filter/`
      );
      setActivities(data);
    } catch (ex) {
      setActivities([]);
    }
  };

  const updateActivitiesSettings = async (item) => {
    const body = {
      activity_type: item.activity_type,
      activity_class: item.activity_class,
      org_id: orgId,
      is_enabled: !item.is_enabled,
      is_deleted: item.is_deleted,
      created_by: user.id,
      updated_by: user.id,
    };
    try {
      setLoading(true);
      const response: ActivitiesType = await request(
        `settings/${orgId}/activity-filter/${item.id}/`,
        {
          method: 'PATCH',
          body: JSON.stringify(body),
        }
      );
      const index = activities.findIndex((el) => el.id === item.id);
      activities[index]= { ...activities[index], ...response };
      setActivities([...activities]);
      setLoading(false);
    } catch (ex) {
      setLoading(true);
    }
  };

  const updateActivtyToggle = async (activitiesToggleValue) => {
    const body = {
      activities_toggle_enabled: activitiesToggleValue,
      created_by: user.id,
      updated_by: user.id,
    };
    try {
      setLoading(true);
      const res = await request(
        `settings/${orgId}/components-and-metrics/${componentId}/`,
        {
          method: 'PATCH',
          body: JSON.stringify(body),
        }
      );
      setActivityToggle(res.activities_toggle_enabled);
      setLoading(false);
    } catch (ex) {
      setLoading(true);
    }
  };

  const ShowActivitiesRow = () => (
    <Col className={styles.engagementWrap}>
      <Checkbox2
        disabled={!hasEditPermission || isViewOnlyEnabled}
        onChange={() => {
          updateActivtyToggle(!activitiesToggle);
        }}
        checked={activitiesToggle}
        label={
          <div>
            <Text>
              Include filters{' '}
              <Text>"Show Activities: All | Relevant"</Text>
            </Text>
            <br />
            <span>
              Note: Unchecking this checkbox will hide the filter on Activities
              tab and will show all the activities by default.
            </span>
          </div>
        }
        iconClassName={styles.filtersCheckbox}
      />
    </Col>
    );

  const getCheckBox =(filters) => map(filters, (item) => (
    <div key={`${item.activity_type_str}_col`} className={styles.checkBoxRow}>
      <Flex className={styles.checkBoxContent}>
        <Checkbox2
          onChange={() => updateActivitiesSettings(item)}
          checked={item.is_enabled}
          disabled={!item.is_applicable || !hasEditPermission || isViewOnlyEnabled}
          key={`${item.activity_type_str}_checkbox`}
          iconClassName={styles.activityCheckBox}
        />
        <Tooltip
          getPopupContainer={(trigger) => (trigger ? trigger.parentElement : trigger)}
          overlay={!item.is_applicable && TOOLTIP_MSG}
        >
          <a
            style={{
              pointerEvents: 'auto',
            }}
            disabled={!item.is_applicable || !hasEditPermission || isViewOnlyEnabled}
            onClick={() => item.is_applicable && hasEditPermission
               && !isViewOnlyEnabled && updateActivitiesSettings(item)}
          > <span
            className={styles.activityText}
            style={{
              color: !item.is_applicable ? 'inherit' : 'none',
            }}
          >{item.activity_type_str}</span></a>
        </Tooltip>
      </Flex>
    </div>
      ));

  const OtherEngagementActivity = () => (
    <React.Fragment>
      <div className={styles.title}>Other Engagement Signals</div>
      <div className={styles.otherIntentSignal}>
        {getCheckBox(otherIntentSignalFilters)}
      </div>
    </React.Fragment>
    );


  const EngagementActivity = () => (
    <div>
      <div className={styles.title}>Engagement Activities</div>
      <Row
        className={styles.checkboxItemWrap}
        flexWrap={Row.FLEX_WRAP.WRAP}
        flexDirection={Row.FLEX_DIRECTION.COLUMN}
      >
        {getCheckBox(engagementFilters)}
      </Row>
    </div>
  );

  const ReachActivity = () => (
    <Col flexBasis="25%" className={styles.reachWrap}>
      <div className={styles.title}>Reach Activities</div>
      <Row
        className={styles.checkboxItemWrap}
        flexWrap={Row.FLEX_WRAP.WRAP}
        flexDirection={Row.FLEX_DIRECTION.COLUMN}
      >
        {getCheckBox(reachFilters)}
      </Row>
    </Col>
  );

  return (
    <div className={styles.container}>
      {loading && <Loading.Spinner level="page" />}
      <Row className={styles.container} >
        <ShowActivitiesRow />
      </Row>
      <Row className={styles.container}>
        <Col flexBasis="75%" className={styles.engagementWrap}>
          {engagementFilters.length > 0 ? <EngagementActivity /> : null}
          {otherIntentSignalFilters.length > 0 ? <OtherEngagementActivity /> : null}
        </Col>
        {reachFilters.length > 0 ? <ReachActivity /> : null}
      </Row>
    </div>
  );
};
ActivitiesCardComponent.propTypes = {
  orgId: PropTypes.number,
  componentsSettings: PropTypes.any,
};

export const ActivitiesCard = compose(
  LayoutCard({
    size: LayoutCard.RESPONSIVE,
    alignment: 'start',
    headerBorder: true,
  })
)(ActivitiesCardComponent);
