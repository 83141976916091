import React from 'react';
import PropTypes from 'prop-types';
import { Link, Row, Text } from 'v2-components';
import { HideWhen } from 'HOCS';
import { classNames } from 'utils/utils';
import styles from './Footer.module.scss';
import moment from 'moment';

const FooterComponent = (props) => {
  const { isPrelogin, className } = props;
  const revcityBaseTrackingUrl =
    'https://revcity.6sense.com/entry/jsconnect-redirect?client_id=6961051&target' +
    '=https%3A%2F%2Frevcity.6sense.com%2F';
  return (
    <Row
      justifyContent={Row.JUSTIFY_CONTENT.CENTER}
      alignItems={Row.ALIGN_ITEMS.FLEX_END}
      className={classNames(styles.footer, className)}
    >
      <div id="app-footer">
        {!isPrelogin && (
          <Link link="https://support.6sense.com/hc/en-us" newWindow>
            <Text type={Text.TYPE.MINI} color={Text.COLOR.GREY1}>
              Knowledgebase&nbsp;&#183;&nbsp;
            </Text>
          </Link>
        )}

        <Link
          link={isPrelogin ? 'https://6sense.com/about-us/' :
            `${revcityBaseTrackingUrl}categories%2Fsupport`
          }
          newWindow
        >
          <Text type={Text.TYPE.MINI} color={Text.COLOR.GREY1}>
            {isPrelogin ? 'About 6sense' : 'Support'}&nbsp;&#183;&nbsp;
          </Text>
        </Link>

        {!isPrelogin && (
          <Link link={revcityBaseTrackingUrl} newWindow>
            <Text type={Text.TYPE.MINI} color={Text.COLOR.GREY1}>
              Community&nbsp;&#183;&nbsp;
            </Text>
          </Link>
        )}

        <Link link="https://6sense.com/terms-of-use/" newWindow>
          <Text type={Text.TYPE.MINI} color={Text.COLOR.GREY1}>
            Terms of Service&nbsp;&#183;&nbsp;
          </Text>
        </Link>

        <Text type={Text.TYPE.MINI} color={Text.COLOR.GREY1}>
          Copyright &copy; 2013-{moment().year()} 6sense
        </Text>
      </div>
    </Row>
  );
};

FooterComponent.propTypes = {
  isPrelogin: PropTypes.bool,
  className: PropTypes.string,
};

FooterComponent.defaultProps = {
  disabled: false,
};

export const Footer = HideWhen(({ disabled }) => disabled)(FooterComponent);
