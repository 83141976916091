import { useRequestValue } from '@sixsense/core';
import { useOrg } from 'hooks';
import { keywordAccountsRequestState, addToGroupAccountsRequestState } from '../state';

const useKeywordAccountsRequest = (keywordList, span, fromAddToGroup = false) => {
  const org = useOrg();
  const keywordIds = keywordList.results ? keywordList.results.map((v) => v.id) : [];
  const state = fromAddToGroup ? addToGroupAccountsRequestState : keywordAccountsRequestState;

  const keywordsAccountsResult = useRequestValue(
    state,
    {
      orgId: org.id,
      keywordIds,
      span,
    }
  );

  return keywordsAccountsResult;
};

export default useKeywordAccountsRequest;
