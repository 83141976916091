import React from "react";
import { Text } from "@sixsense/core/components";
import { CAMPAIGN_SOURCE } from "../../../constants";
import { NA } from "./common/NA";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { coerceLocaleString } from "utils/utils";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const FREQUENCY_CAP = {
  sortByKey: "frequency_capping",
  key: "frequency-cap",
  csvColumnKey: "frequency_cap",
  title: <Label name={CAMPAIGN_LABELS.frequencyCap} />,
  dataIndex: "frequency_capping",
  width: pxToRem(200),
  render: (frequencyCap, campaign) => {
    const { campaign_source } = campaign;
    if (campaign_source === CAMPAIGN_SOURCE.EXTERNAL) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {coerceLocaleString(frequencyCap)}
      </Text.Body>
    );
  },
  align: "right",
};
