import React from 'react';
/* eslint max-len: [2, 4000] */
// Using 1000 for now.... might need to bump it up
export function CheckCircleGreen(props) {
  return (
    <svg {...props} width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Check Circle</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <path d="M8,1.33333333 C4.32,1.33333333 1.33333333,4.32 1.33333333,8 C1.33333333,11.68 4.32,14.6666667 8,14.6666667 C11.68,14.6666667 14.6666667,11.68 14.6666667,8 C14.6666667,4.32 11.68,1.33333333 8,1.33333333 L8,1.33333333 Z M6.66666667,11.3333333 L3.33333333,8 L4.27333333,7.06 L6.66666667,9.44666667 L11.7266667,4.38666667 L12.6666667,5.33333333 L6.66666667,11.3333333 L6.66666667,11.3333333 Z" id="path-1" />
      </defs>
      <g id="Linkedin-Integration-v1.2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ic_check_circle" transform="translate(-1.000000, -1.000000)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <g id="Mask" />
          <g id="Colors/Green" mask="url(#mask-2)" fill="#37CA36" fillRule="evenodd">
            <g transform="translate(-8.666667, -8.666667)" id="Colors/Blue.1">
              <path d="M0,0 L33.3333333,0 L33.3333333,33.3333333 L0,33.3333333 L0,0 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>);
}
