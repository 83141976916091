import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Loading2, CustomIcon, Icon } from 'v2-components';
import { ImageErrorComponent } from 'v2-components/Image';
import styles from './Video.module.scss';
import { cx, css } from 'styles/emotion';
import { If } from 'babel-plugin-jsx-control-statements';

const errMessage = 'Something went wrong loading this image, try refreshing the page.';

const VidProps = {
  autoplay: PropTypes.bool,
  controls: PropTypes.bool,
  preload: PropTypes.oneOf(['none', 'metadata', 'auto']),
  loop: PropTypes.bool,
  src: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  stretch: PropTypes.bool,
  vidHeight: PropTypes.number,
  vidWidth: PropTypes.number,
  ErrorComponent: PropTypes.any,
};

const Vid = (props) => {
  const {
    error,
    videoRef,
    showThumbnail,
    hideThumbnail,
    videoProps: {
      autoplay = false,
      controls = false,
      preload = 'none',
      loop = false,
      src,
      height: h,
      width: w,
      className,
      containerClassName,
      stretch,
      vidHeight,
      vidWidth,
      ErrorComponent,
      captionUrl,
      thumbnailUrl,
      showThumbnailAsOverlay,
    } = {},
  } = props;

  let height = h;
  let width = w;

  /*
    depending on the container for the image, you might need to
    pass a classname for the image specifying max height/width
  */

  if (!stretch) {
    height = vidWidth > vidHeight ? null : height;
    width = vidHeight > vidWidth ? null : width;
  }

  const style = {
    displayStyle: css({
      display: showThumbnail ? 'block' : 'none',
    }),
    thumbnailBackground: css({
      backgroundImage: `url(${thumbnailUrl})`,
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      filter: 'brightness(0.5)',
    }),
  };

  const playVideo = () => {
    hideThumbnail();
    videoRef.current.play();
  };

  return error ? (
    <ErrorComponent />
  ) : (
    <div
      style={stretch ? { height, width } : { position: 'relative' }}
      className={containerClassName}
    >
      <video
        ref={videoRef}
        height={height}
        width={width}
        autoPlay={autoplay}
        controls={showThumbnail ? false : controls}
        preload={preload}
        loop={loop}
        className={className}
        style={{ maxWidth: '100%', maxHeight: '100%' }}
        poster={showThumbnail && (!showThumbnailAsOverlay ? thumbnailUrl : null)}
      >
        <source src={src} />
        {captionUrl && <track src={captionUrl} default label="Default" />}
        <ErrorComponent customMessage={'Your browser does not support the video tag.'} />
      </video>
      <If condition={thumbnailUrl && showThumbnailAsOverlay}>
        <React.Fragment>
          <div className={cx(style.displayStyle, style.thumbnailBackground)} />
          <img
            src={thumbnailUrl}
            alt="thumbnail_image"
            className={cx(styles.thumbnailImage, style.displayStyle)}
          />
          <If condition={controls}>
            <Icon
              type={'play_arrow'}
              className={cx(styles.playButton, style.displayStyle)}
              size={30}
              onClick={playVideo}
            />
          </If>
        </React.Fragment>
      </If>
    </div>
  );
};

Vid.propTypes = {
  error: PropTypes.bool,
  videoRef: PropTypes.any,
  videoProps: PropTypes.shape({ ...VidProps }),
  showThumbnail: PropTypes.bool,
  hideThumbnail: PropTypes.func,
};

class Video extends PureComponent {
  static propTypes = {
    LoadingComponent: PropTypes.any,
    ErrorComponent: PropTypes.any,
    ...VidProps,
  };

  static defaultProps = {
    LoadingComponent: Loading2.LoadingComponent,
    ErrorComponent: ImageErrorComponent(errMessage, styles.errorIcon, CustomIcon.BROKEN_IMAGE_ICON),
  };

  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.state = {
      loading: false,
      error: false,
      showThumbnail: Boolean(this.props.thumbnailUrl),
    };
  }

  componentDidMount() {
    const videoEl = this.videoRef.current;
    videoEl.onloadstart = () => {
      this.setState({ loading: true });
    };
    videoEl.onloadedmetadata = () => {
      this.setState({ loading: false });
    };
    videoEl.onerror = () => {
      this.setState({ loading: false, error: true });
    };
  }

  render() {
    const { LoadingComponent } = this.props;
    return this.state.loading ? (
      <LoadingComponent />
    ) : (
      <Vid
        videoRef={this.videoRef} videoProps={this.props} error={this.state.error}
        showThumbnail={this.state.showThumbnail}
        hideThumbnail={() => this.setState({ showThumbnail: false })}
      />
    );
  }
}

export default Video;
