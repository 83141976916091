import CRMIntegrationRoute from './routes/crm';
import SEPIntegrationRoute from './routes/sep';
import Manage from './routes/manage';
import { SALES_INTEGRATION_URL, SALES_INTEGRATION_URL_PATH } from './constants';

export default function createRoutes() {
  return {
    path: SALES_INTEGRATION_URL_PATH,
    name: SALES_INTEGRATION_URL_PATH,
    getComponent(nextState, cb) {
      import('./SalesIntegrationPage').then((page) => {
        cb(null, page.default);
      });
    },
    indexRoute: {
      onEnter: (nextState, replace) => replace(`${SALES_INTEGRATION_URL}/manage`),
    },
    childRoutes: [
      Manage(),
      CRMIntegrationRoute(),
      SEPIntegrationRoute(),
    ],
  };
}
