import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'personamap',
    name: 'PersonaMap',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('personaMap', reducer.default);
        injectSagas('personaMap', sagas.default);
        cb(null, containers.PersonaMap);
      }, 'settings_personaMap');
    },
  };
}
