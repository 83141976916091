import { SalesStage, SalesStageGroup } from './types';
import update from 'immutability-helper';
import { defineSharedState, useSharedState } from '@sixsense/core/shared-state';
import { TMP_STAGE_STATE } from './state';

type StageGroupList = SalesStageGroup[];

const stageConfigState = defineSharedState<StageGroupList>({
  path: 'REPORTING/6QA/',
  defaultValue: [{ group_name: 'ungrouped', stages: [] }, ...TMP_STAGE_STATE],
});

// eslint-disable-next-line no-undef
const selectedStageState = defineSharedState<Set<string>>({
  path: 'reporting.cmo.config.selectedStages',
  defaultValue: new Set(),
});

const renameMapState = defineSharedState<{}>({
  path: 'reporting.cmo.config.rename',
  defaultValue: {},
});

const useStageConfigState = () => {
  const [stageConfig, setStageConfig] = useSharedState(stageConfigState);
  const [selectedStages, setSelectedStages] = useSharedState(selectedStageState);
  const [renameMap, setRenameMap] = useSharedState(renameMapState);

  const moveGroup = (name: string, toIndex: number) => {
    const groupIndex = stageConfig.findIndex((group) => group.group_name === name);
    if (groupIndex !== -1 && groupIndex !== toIndex) {
      const group = stageConfig[groupIndex];
      setStageConfig(update(stageConfig, {
        $splice: [
          [groupIndex, 1],
          [toIndex, 0, group],
        ],
      }));
    }
  };

  const moveStage = (
    name: string,
    toGroupIdx: number,
  ) => {
    const groupIdx = stageConfig.findIndex(
      (group) => group.stages.find((stage) => stage.name === name)
    );
    if (groupIdx === -1 || groupIdx === toGroupIdx) {
      return;
    }
    const stage = stageConfig[groupIdx].stages.find((s) => s.name === name);
    setStageConfig(update(stageConfig, {
      [groupIdx]: {
        stages: {
          $apply: (stages) => stages.filter((s) => s.name !== name),
        },
      },
      [toGroupIdx]: {
        stages: {
          $push: [stage],
        },
      },
    }));
  };

  const addGroup = (name: string, stages: SalesStage[] = []) => {
    setStageConfig((prevConfig) => update(prevConfig, {
      $splice: [
        [1, 0, { group_name: name, stages }],
      ],
    }));
  };

  const removeGroup = (name: string) => {
    const groupIdx = stageConfig.findIndex((group) => group.group_name === name);
    if (groupIdx !== -1) {
      const { stages } = stageConfig[groupIdx];
      setStageConfig(update(stageConfig, {
        0: {
          stages: {
            $push: stages,
          },
        },
        $splice: [[groupIdx, 1]],
      }));
    }
  };

  const ungroup = (name: string) => {
    const groupIdx = stageConfig.findIndex((group) => group.group_name === name);
    if (groupIdx !== -1) {
      const { stages } = stageConfig[groupIdx];
      const newGroups: SalesStageGroup[] = stages.map((stage) => ({
        group_name: stage.name,
        stages: [stage],
      }));
      setStageConfig(update(stageConfig, {
        $splice: [[groupIdx, 1, ...newGroups]],
      }));
    }
  };

  const toggleStage = (name: string) => {
    const nextSelectedStages = new Set(selectedStages);
    if (nextSelectedStages.has(name)) {
      nextSelectedStages.delete(name);
    } else {
      nextSelectedStages.add(name);
    }
    setSelectedStages(nextSelectedStages);
  };

  const groupSelected = () => {
    if (selectedStages.size === 0) {
      return;
    }
    const newGroup: SalesStageGroup = { group_name: '', stages: [], display_group: true };
    const spliceGroups = [];
    for (let i = 0; i < stageConfig.length; i++) {
      const group = stageConfig[i];
      if (selectedStages.has(group.group_name)) {
        if (newGroup.group_name) {
          newGroup.group_name += `, ${group.group_name}`;
        } else {
          newGroup.group_name = group.group_name;
        }
        newGroup.stages = [...newGroup.stages, ...group.stages];
        spliceGroups.unshift([i, 1]);
      }
    }
    spliceGroups[spliceGroups.length - 1].push(newGroup);

    setSelectedStages(new Set());
    setStageConfig(update(stageConfig, {
      $splice: spliceGroups,
    }));
  };

  const setGroupName = (groupIdx: number, name: string) => {
    setStageConfig(update(stageConfig, {
      [groupIdx]: {
        group_name: {
          $set: name,
        },
      },
    }));
  };

  const toggleVisibility = (groupIdx: number, idx: number) => {
    setStageConfig(update(stageConfig, {
      [groupIdx]: {
        stages: {
          [idx]: {
            hidden: {
              $set: !stageConfig[groupIdx].stages[idx].hidden,
            },
          },
        },
      },
    }));
  };

  return {
    moveGroup,
    addGroup,
    removeGroup,
    moveStage,
    ungroup,
    stageConfig,
    toggleStage,
    selectedStages,
    groupSelected,
    toggleVisibility,
    setStageConfig,
    setGroupName,
    renameMap,
    setRenameMap,
  };
};

export default useStageConfigState;
