import React, { useState, Dispatch, SetStateAction } from 'react';
import { css } from 'styles/emotion';
import { Flex, TextArea, useToast, Link } from '@sixsense/core/components';
import { Input } from 'v2-components';
import { classNames } from 'utils/utils';
import {
  COMPANY_WEBSITE_PLACEHOLDER,
  COMPANY_AND_PRODUCT_PLACEHOLDER,
  COMPANY_COMPETITORS_PLACEHOLDER,
  GTM_PARTNERS_PLACEHOLDER,
  ProductSummaryAttributesType,
} from '../constant';
import { validateUrl, checkFieldLengthAndUpdateError } from '../utils';
import { Plus, Minus, AlertCircle } from '@sixsense/core/icons';
import FileUploadComponent from './FileUploadComponent';

const styles = {
  labelStyle: css({
    marginBottom: '8px',
    fontSize: '14px',
  }),
  textArea: css({
    height: '81px',
    fontSize: '14px !important',
    overflow: 'auto !important',
    '::placeholder': {
      color: '#99A0AA',
    },
  }),
  textAreaCount: css({
    marginBottom: '8px',
    color: '#696F7B',
    marginLeft: 'auto',
  }),
  inputStyle: css({
    borderRadius: '6px',
    width: '100%',
    height: '36px',
    padding: '8px 12px',
    fontSize: '14px',
    '&:focus': {
      boxShadow: '0px 0px 0px 4px #CCF0FD',
    },
    '::-webkit-input-placeholder': {
      color: '#99A0AA',
    },
  }),
  errorInput: css({
    border: '1px solid #D92D20 !important',
  }),
  errorMsg: css({
    color: '#D92D20',
    fontSize: '12px',
    lineHeight: '18px',
    marginTop: '3px',
  }),
  multiSelect: css({
    'span, input': {
      fontSize: '14px !important',
    },
    input: {
      minWidth: '100px !important',
    },
    label: {
      padding: '10px 12px !important',
    },
  }),
  addRemoveButton: css({
    fontSize: '14px',
    lineHeight: '20px',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
  }),
  removeBtnCss: css({
    color: '#38414E !important',
  }),
  addRemoveIcon: css({
    marginRight: '8px',
  }),
};

type CompanyDetailsProps = {
  companyAttributes: ProductSummaryAttributesType;
  updateSharedState: (key: any, value: string | string[]) => void;
  setIsProductAttributesEdited: Dispatch<SetStateAction<boolean>>;
  isSaveAsDraftAPISuccess: boolean;
  setIsSaveAsDraftAPISuccess: Dispatch<SetStateAction<boolean>>;
  websiteUrlError: boolean;
  setWebsiteUrlError: Dispatch<SetStateAction<boolean>>;
  isWebsiteMaxLengthExceeded: boolean;
  setIsWebsiteMaxLengthExceeded: Dispatch<SetStateAction<boolean>>;
  setIsCompitetorMaxLengthExceeded: Dispatch<
  SetStateAction<{ index: number; hasError: boolean }[]>
>;
isCompitetorMaxLengthExceeded: any;
};

interface CompetitorsDataProps {
  competitor: string;
  differentiator: string;
}

export const CompanyProductDetails = ({
  companyAttributes,
  updateSharedState,
  setIsProductAttributesEdited,
  isSaveAsDraftAPISuccess,
  setIsSaveAsDraftAPISuccess,
  websiteUrlError,
  setWebsiteUrlError,
  isWebsiteMaxLengthExceeded,
  setIsWebsiteMaxLengthExceeded,
  setIsCompitetorMaxLengthExceeded,
  isCompitetorMaxLengthExceeded,
}: CompanyDetailsProps) => {
  const [websiteurl, setWebsiteUrl] = useState(
    companyAttributes?.company_website
  );
  const [focusedField, setFocusedField] = useState(null);
  const [companyProductInfo, setCompanyProductInfo] = useState(
    companyAttributes?.company_product_information || ''
  );
  const [gtmPartnersInfo, setGtmPartnersInfo] = useState(
    companyAttributes?.gtm_partners_and_integrations || ''
  );
  const [competitorsGroup, setCompetitorsGroup] = useState(
    companyAttributes?.competitors?.length > 0 ? companyAttributes?.competitors : [
      { competitor: '', differentiator: '' },
    ]
  );
  const [isInputUpdated, setIsInputUpdated] = useState(false);
  const competitorsKey = 'competitors';
  const toast = useToast();
  const websiteUrlMaxLen = 128;
  const competitorMaxLen = 257;

  const handleWebsiteUrlChange = (e) => {
    const value = e?.target?.value || '';
    setIsWebsiteMaxLengthExceeded(value?.length > websiteUrlMaxLen);
    const isValidUrl = validateUrl(value);
    setWebsiteUrlError(!isValidUrl);
    updateSharedState('company_website', value);
    setIsProductAttributesEdited(isValidUrl);
    setWebsiteUrl(value);
  };

    // Handler to update form input
  const handleInputChange = (index, value, name) => {
    if (value) {
      setIsInputUpdated(true);
    }
    if (name === 'competitor') {
      checkFieldLengthAndUpdateError(
        index,
        value,
        competitorMaxLen,
        setIsCompitetorMaxLengthExceeded,
      );
    }
    const updatedGroups = [...competitorsGroup];
    updatedGroups[index][name] = value;
    updateSharedState(competitorsKey, updatedGroups);
    setCompetitorsGroup(updatedGroups);

  };

  const addCompetitorsGroup = () => {
    if (competitorsGroup?.length < 20) {
      const updatedGroups = [
        ...competitorsGroup,
          { competitor: '', differentiator: '' },
      ];
      setCompetitorsGroup(updatedGroups);
      updateSharedState(competitorsKey, updatedGroups);
    } else {
      toast.show({
        type: 'icon',
        title: 'Oops!',
        subtitle: 'You can only add up to 20 Competitors.',
        icon: AlertCircle,
        iconColor: 'Error',
        duration: 5000,
      });
    }
  };

  const removeCompetitorsGroup = (index) => {
    const updatedGroups = competitorsGroup?.filter((_, i) => i !== index);
    setCompetitorsGroup(updatedGroups);
    updateSharedState(competitorsKey, updatedGroups);
  };

  return (
    <div>
      <Flex direction="column">
        <label className={styles.labelStyle}>Website</label>
        <div>
          <Input
            type="text"
            maxLength={websiteUrlMaxLen+1}
            className={classNames(
              styles.inputStyle,
              (websiteUrlError || isWebsiteMaxLengthExceeded) && styles.errorInput
            )}
            placeholder={COMPANY_WEBSITE_PLACEHOLDER}
            value={websiteurl}
            onChange={handleWebsiteUrlChange}
            onFocus={() => setFocusedField('websiteurl')}
            autoFocus={focusedField === 'websiteurl'}
          />
          {websiteUrlError && (
            <div className={styles.errorMsg}>
              {`Invalid URL. Sample url: ${COMPANY_WEBSITE_PLACEHOLDER}`}
            </div>
          )}
          {isWebsiteMaxLengthExceeded && (
            <div className={styles.errorMsg}>
              Maximum character limit reached
            </div>
          )}
        </div>
      </Flex>
      <Flex direction="column" style={{ paddingTop: '20px' }}>
        <Flex style={{ alignItems: 'center' }}>
          <label className={styles.labelStyle}>
            Company and Product Information
          </label>
          <label className={styles.textAreaCount}>
            {companyProductInfo?.length}/5000
          </label>
        </Flex>

        <TextArea
          id="companyproductinfo"
          className={styles.textArea}
          type="textarea"
          value={companyProductInfo}
          onChange={(v) => {
            setCompanyProductInfo(v);
            updateSharedState('company_product_information', v);
          }}
          placeholder={COMPANY_AND_PRODUCT_PLACEHOLDER}
          onFocus={(e) => setFocusedField(e.target.id)}
          autoFocus={focusedField === 'companyproductinfo'}
          maxLength={5000}
        />

        <FileUploadComponent
          productAttributes={companyAttributes}
          category="company_and_product_information"
          updateSharedState={updateSharedState}
        />
      </Flex>
      <Flex direction="column" style={{ paddingTop: '20px' }}>
        {competitorsGroup?.map(
        (
          {
            competitor,
            differentiator,
          }: CompetitorsDataProps,
          index: number
        ) => (
          <Flex direction="column" style={{ paddingBottom: '20px' }} key={`personas${index}`}>
            <Flex direction="column">
              <Flex justifyContent="space-between">
                <label className={styles.labelStyle}>
                  {index + 1}: Competitor
                </label>
                {competitorsGroup?.length > 1 && (
                  <Flex direction="column">
                    <Link
                      className={`${styles.addRemoveButton} ${styles.removeBtnCss}`}
                      onClick={() => removeCompetitorsGroup(index)}
                    >
                      {' '}
                      <Minus size={16} className={styles.addRemoveIcon} />
                      Remove
                    </Link>
                  </Flex>
                )}
              </Flex>
              <div>
                <Input
                  title="competitor_id"
                  id="competitor_id"
                  type="text"
                  className={classNames(styles.inputStyle)}
                  placeholder="Name"
                  value={competitor}
                  maxLength={competitorMaxLen}
                  onChange={({ target }) =>
                    handleInputChange(index, target.value, 'competitor')
                  }
                  onFocus={(e) => setFocusedField(e.target.id)}
                  autoFocus={focusedField === 'competitor_id'}
                  onBlur={() => {
                    if (isInputUpdated) {
                      updateSharedState(competitorsKey, competitorsGroup);
                      setIsInputUpdated(false);
                    }
                  }}
                />
                {isCompitetorMaxLengthExceeded?.some((err) => err.index === index) && (
                <div className={styles.errorMsg}>
                      Maximum character limit reached
                    </div>
                  )}
              </div>
            </Flex>
            <Flex direction="column" style={{ paddingTop: '20px' }}>
              <Flex style={{ alignItems: 'center' }}>
                <label className={styles.labelStyle}>Differentiator</label>
                <label className={styles.textAreaCount}>
                  {differentiator?.length}/2000
                </label>
              </Flex>

              <TextArea
                id="differentiator_id"
                className={styles.textArea}
                type="textarea"
                value={differentiator}
                onChange={(value) =>
                  handleInputChange(index, value, 'differentiator')
                }
                placeholder={COMPANY_COMPETITORS_PLACEHOLDER}
                onFocus={(e) => setFocusedField(e.target.id)}
                autoFocus={focusedField === 'differentiator_id'}
                maxLength={5000}
                onBlur={() => {
                  if (isInputUpdated) {
                    updateSharedState(competitorsKey, competitorsGroup);
                    setIsInputUpdated(false);
                  }
                }}
              />
            </Flex>
          </Flex>
        )
      )}
        {competitorsGroup?.length <= 20 ? (
          <Flex direction="column" style={{ width: '190px' }}>
            <Link className={styles.addRemoveButton} onClick={addCompetitorsGroup}>
              <Plus size={16} className={styles.addRemoveIcon} />
            Add Competitors
          </Link>
          </Flex>
      ) : null}
      </Flex>
      <Flex direction="column" style={{ paddingTop: '20px' }}>
        <Flex style={{ alignItems: 'center' }}>
          <label className={styles.labelStyle}>
            GTM Partners or Integrations
          </label>
          <label className={styles.textAreaCount}>
            {gtmPartnersInfo?.length}/5000
          </label>
        </Flex>

        <TextArea
          id="gtmpartnersinfo"
          className={styles.textArea}
          type="textarea"
          value={gtmPartnersInfo}
          onChange={(v) => {
            setGtmPartnersInfo(v);
            updateSharedState('gtm_partners_and_integrations', v);
          }}
          placeholder={GTM_PARTNERS_PLACEHOLDER}
          onFocus={(e) => setFocusedField(e.target.id)}
          autoFocus={focusedField === 'gtmpartnersinfo'}
          maxLength={5000}
        />
        <FileUploadComponent
          productAttributes={companyAttributes}
          category="gtm_partners_and_integrations"
          updateSharedState={updateSharedState}
          isSaveAsDraftAPISuccess={isSaveAsDraftAPISuccess}
          setIsSaveAsDraftAPISuccess={setIsSaveAsDraftAPISuccess}
        />
      </Flex>
    </div>
  );
};
