import React from 'react';
import Text from 'v2-components/Text';
import { formatDate } from 'utils/utils';
import { DATE_FORMAT } from 'utils/constants';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';

export const CREATED_ON = {
  sortByKey: 'created_on',
  key: 'created-on',
  csvColumnKey: 'created_on',
  title: <Label name={CAMPAIGN_LABELS.createdOn} />,
  dataIndex: 'created_on',
  width: 130,
  render: (createdOn) => (<Text
    type={Text.TYPE.SUBBODY}
  >
    {createdOn ? formatDate(DATE_FORMAT)(createdOn) : '-'}
  </Text>),
};
