import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: 'trustradius',
    name: 'trustradius',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const reducer = require('./modules');
          const sagas = require('./sagas');
          injectReducer('trustradius', reducer.default);
          injectSagas('trustradius', sagas.default);

          const formWizardReducer = require('../../components/FormWizard2/modules');
          injectReducer('SettingsFormWizard', formWizardReducer.default);

          cb(null, containers.TrustRadiusContainer);
        },
        'settings_trustradius'
      );
    },
  };
}
