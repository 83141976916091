import { defineSharedState, useSetSharedValue } from '@sixsense/core';
import { ReactNode, useEffect } from 'react';

export const pageTitleState = defineSharedState<ReactNode>({
  path: 'global.page.title',
  defaultValue: undefined,
});

const usePageTitle = (title: ReactNode) => {
  const setTitle = useSetSharedValue(pageTitleState);

  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);

  useEffect(() => () => {
    // Remove the title when the component is unmounted.
    setTitle(undefined);
  }, []);
};

export default usePageTitle;
