export default function createRoutes() {
  return {
    path: 'fiscalyear',
    name: 'fiscalyear',
    getComponent(nextState, cb) {
      // @ts-ignore
      require.ensure([], (require) => {
        const containers = require('./container');
        cb(null, containers.default);
      }, 'settings_fiscalyear');
    },
  };
}
