import { createSelector } from 'reselect';
import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';
import { orgSelector } from 'modules/user/selectors';


export const baseSelector = (state) => state.integrationsRoot;

const genericIntgrationStatusEndpointSelector = createSelector(
    orgSelector,
    (_, loadAction) => loadAction,
    (orgId, loadAction) => `nsync/integration_status/organization/${orgId}/` +
        `?integration_type_ids=${loadAction.payload}`
  );


export const loadGenericIntgrationStatusGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: 'INTEGRATIONS/GET_GENERIC_INTEGRATION_STATUS',
  endpointSelector: genericIntgrationStatusEndpointSelector,
  stateKey: 'integration_status',
  initialData: [],
  receivedDataTransform: (data) => {
    const status = data.data;
    return status;
  },
  onSuccessActions: [],
  onFailureActions: [],
});


const lastFileDropTimeStampEndpointSelector = createSelector(
  orgSelector,
  (_, loadAction) => loadAction,
  // here loadAction.payload is integration type id
  (orgId, loadAction) => `nsync/last_file_drop_timestamp/organization/${orgId}/`+
  `integration/${loadAction.payload}`
);

export const lastFileDropTimeStampGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: 'INTEGRATIONS/GET_LAST_FILE_DROP_TIMESTAMP',
  endpointSelector: lastFileDropTimeStampEndpointSelector,
  stateKey: 'last_file_drop_timestamp',
  initialData: '',
  receivedDataTransform: (data) => data?.last_drop_file_time,
  onSuccessActions: [],
  onFailureActions: [],
});

const integrationCadenceEndpointSelector = createSelector(
  orgSelector,
  (_, loadAction) => loadAction,
  // here loadAction.payload is integration type id
  // eslint-disable-next-line max-len
  (orgId, loadAction) => `nsync/integration_cadence/?integration_type_id=${loadAction.payload}&organization_id=${orgId}&realtime=true`
);

export const integrationCadenceGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: 'INTEGRATIONS/GET_INTEGRATION_CADENCE',
  endpointSelector: integrationCadenceEndpointSelector,
  stateKey: 'integration_cadence',
  initialData: '',
  // WIP : need to update this
  receivedDataTransform: (data) => data[0]?.cadence,
  onSuccessActions: [],
  onFailureActions: [],
});

const syncSourcePropertyEndpointSelector = createSelector(
  orgSelector,
  (_, loadAction) => loadAction,
  (orgId, loadAction) =>
  'public/sync_source_instance_property/?sync_source_name=' +
  `${loadAction.payload}&org_id=${orgId}`
);

export const syncSourcePropertyGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: 'INTEGRATIONS/GET_INTEGRATION_SYNC_SOURCE_PROPERTY',
  endpointSelector: syncSourcePropertyEndpointSelector,
  stateKey: 'integration_sync_property',
  initialData: '',
  // eslint-disable-next-line max-len
  receivedDataTransform: (data) => data.find((property) => property.property_name === 'auth_error_message')?.property_value,
  onSuccessActions: [],
  onFailureActions: [],
});
