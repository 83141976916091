import { call, takeEvery, put, select, take, all } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import requestUpload from 'superagent';
import { fetchSaga } from 'store/sagas';
import { orgSelector } from 'modules/user/selectors';
import { actions, actionTypes } from './modules';
import { attachmentSelector, fileSelector } from './selectors';
import { values, map, get } from 'lodash';

const {
  uploadFileSuccess,
  uploadFileFailure,
  addRequestInstance,
  loadS3Url,
  loadS3UrlSuccess,
  loadS3UrlFailure,

  preUploadProgress,
  preUploadSuccess,
  preUploadFailure,
} = actions;

const {
  UPLOAD_FILE_REQUEST,
  LOAD_S3URL_REQUEST,
  LOAD_S3URL_SUCCESS,
  LOAD_S3URL_FAILURE,

  PRE_UPLOAD,

  ABORT_UPLOAD_FILES,
} = actionTypes;


function* run(requestAction, successPattern, failurePattern, uploadKey, fileKey) {
  // need custom run function because there may be multiple uploads occuring at the same time
  // and we want to make sure that we handle the results for the correct file
  yield put(requestAction());
  let cont = true;
  let action;
  while (cont) {
    action = yield take([successPattern, failurePattern]);
    if (action.fileKey === fileKey && action.uploadKey === uploadKey) {
      cont = false;
    }
  }
  if (action.type === failurePattern) {
    const error = new Error(action.errorMessage);
    error.errorStatus = action.errorStatus;
    error.errorMessage = action.errorMessage;
    return error;
  }
  return action;
}

// function* callAPI(url, file) {
//   const request = requestUpload
//     .put(url)
//     .type(file.type)
//     .send(file.file)
//     .on('progress', (event) => {
//       file.onProgress(event);
//     })
//     .on('abort', () => {
//       console.log('aborted');
//       // requestInstance.end();
//     //  file.onAbort();
//     });
//   // request[CANCEL] = () => {
//   //   request.abort();
//   // };
//   // const result = yield request;
//   return request;
// }

function* abortUploadFilesSaga(action) {
  const { uploadingS3Files } = action;
  yield all(map(values(uploadingS3Files), ({ requestInstance }) => {
    requestInstance.abort();
  }));
}

function* uploadFileSaga(action) {
  const { file, uploadKey, retryCount, preSignedUrlEndpoint, shouldPostOnPreSignedUrl } = action;
  try {
    yield run(
      () => loadS3Url(file.name, uploadKey, retryCount, preSignedUrlEndpoint),
      LOAD_S3URL_SUCCESS,
      LOAD_S3URL_FAILURE,
      uploadKey,
      file.name,
    );
    const files = yield select(fileSelector(uploadKey, file.name));
    const s3Url = files.s3Url;
    let requestInstance;

    if (shouldPostOnPreSignedUrl) {
      const preSignedPost = get(s3Url, 'presigned_post');
      const preSignedPostUrl = get(preSignedPost, 'url');
      const preSignedPostData = get(preSignedPost, 'fields');
      const formData = new FormData();
      Object.entries(preSignedPostData).forEach(([k, v]) => formData.append(k, v));
      formData.append('file', file.file);

      requestInstance = requestUpload
      .post(preSignedPostUrl)
      .send(formData)
      .on('progress', (event) => {
        file.onProgress(event);
      })
      .on('abort', () => {
        file.onAbort();
      });
    } else {
      requestInstance = requestUpload
      .put(s3Url.signed_url)
      .type(file.type)
      .send(file.file)
      .on('progress', (event) => {
        file.onProgress(event);
      })
      .on('abort', () => {
        file.onAbort();
      });
    }
    yield put(addRequestInstance(uploadKey, file.name, requestInstance));
    const response = yield requestInstance
      .then(
        (success) => success.body,
      ).catch((error) => {
        throw new Error(error);
      });
    yield put(uploadFileSuccess(uploadKey, file.name));
    file.onSuccess({ response, uploadedFiles: files });
  } catch (e) {
    file.onError(e, file);
    yield put(uploadFileFailure(e.toString(), e, uploadKey, file.name));
  }
}

function* loadS3UrlSaga(request, action) {
  const { fileKey, uploadKey, preSignedUrlEndpoint } = action;
  const attachmentType = (yield select(attachmentSelector(uploadKey)));
  const urlEncodedFIleName = encodeURIComponent(fileKey);
  try {
    const orgId = yield select(orgSelector);
    const baseUrl = preSignedUrlEndpoint || `org/${orgId}/s3url/`;
    const endpoint =
    `${baseUrl}?filename=${urlEncodedFIleName}&attachment_type=${attachmentType}`;
    const s3url = yield call(request, endpoint);
    yield put(loadS3UrlSuccess(s3url, uploadKey, fileKey));
  } catch (e) {
    if (action.retryCount > 1) {
      yield delay(500);
      yield put({ ...action, retryCount: action.retryCount - 1 });
    } else {
      yield put(loadS3UrlFailure(e.toString(), e, uploadKey, fileKey));
    }
  }
}

function* processPreUpload(action) {
  // additional file validation occurs here from provided beforeUpload fn
  const { beforeUpload, file: { file }, uploadKey, fileKey } = action;
  const response = beforeUpload(file);

  if (response instanceof Promise) {
    try {
      yield put(preUploadProgress(uploadKey, fileKey));
      const preUploadCheck = yield response
        .then(() => true)
        .catch((err) => err);

      if (preUploadCheck === true) {
        yield put(preUploadSuccess(uploadKey, fileKey));
      } else {
        yield put(preUploadFailure(preUploadCheck, uploadKey, fileKey));
      }
    } catch (errorMessage) {
      yield put(preUploadFailure(errorMessage, uploadKey, fileKey));
    }
  } else {
    yield response === true
      ? put(preUploadSuccess(uploadKey, fileKey))
      : put(preUploadFailure(response, uploadKey, fileKey));
  }
}

function* watchUploadFile() {
  yield takeEvery(UPLOAD_FILE_REQUEST, uploadFileSaga);
}

function* watchLoadS3Url(request) {
  yield takeEvery(LOAD_S3URL_REQUEST, loadS3UrlSaga, request);
}

function* watchPreUploadFile() {
  yield takeEvery(PRE_UPLOAD, processPreUpload);
}

function* watchAbortUploadFile() {
  yield takeEvery(ABORT_UPLOAD_FILES, abortUploadFilesSaga);
}
export default [
  watchUploadFile,
  fetchSaga(watchLoadS3Url),
  watchPreUploadFile,
  watchAbortUploadFile,
];
