export default function createRoutes() {
  return {
    path: 'product',
    name: 'product',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('../../containers/ProductTaxonomy/containers');
        cb(null, containers.ProductTaxonomy);
      }, 'settings_taxonomy_product');
    },
  };
}
