import React, { useState } from 'react';
import { Button, Modal, Text, Pill, Checkbox2, Icon } from 'v2-components';
import { Flex, ResultBoundary, Loading } from '@sixsense/core/components';
import { css, cx } from 'styles/emotion';
import { useSharedState, useRequestValue, useSharedValue } from '@sixsense/core';
import { useOrg } from 'hooks';
import { AAColor } from '@sixsense/core/style';
import {
  recommendedKeywordsRequestState,
  genericKeywordsRequestState,
  addKeywordsConfigState,
  addKeywordsVisibleState,
  keywordsConfigState,
} from '../../state';
import { ResultStatus } from '@sixsense/core/shared/async-result/types';
import { actions as downloadActions } from 'v2-components/Download/modules';
import { useDispatch } from '@sixsense/core/versioned/react-redux';

const styles = {
  button: css({
    marginRight: '10px',
    height: '30px',
  }),
  select: css({
    placeContent: 'flex-start !important',
    marginTop: '10px',
    marginBottom: '200px',
  }),
  error: css({
    margin: '10px 10px 0 0',
  }),
  pill: css({
    padding: '0.125rem 0.625rem !important',
    margin: '5px !important',
  }),
  keywordPill: css({
    background: '#FFF !important',
    border: '1px solid #C6CACE',
    color: '#000',
    '&:hover': {
      backgroundColor: '#FFF !important',
    },
  }),
  downloadIcon: css({
    fontSize: '18px',
    marginLeft: '10px',
  }),
  downloadButton: css({
    '&:hover': {
      color: `${AAColor.BLUE}`,
    },
  }),
};

type ContainerProps = {
  genericKeywords: any;
  selectedKeywords: any;
  setSelectedKeywords: any;
  setLoading: any;
};

const RecKeywordsWrapper = (
  { genericKeywords, selectedKeywords, setSelectedKeywords, setLoading,
}: ContainerProps) => {
  const genericKws = genericKeywords ? genericKeywords.map((v) => v.normalized_keyword) : [];
  const recommendedKeywords = useRequestValue(recommendedKeywordsRequestState, genericKws);
  const config = useSharedValue(keywordsConfigState);
  const org = useOrg();
  const dispatch = useDispatch();

  if (recommendedKeywords.status === ResultStatus.LOADED) {
    setLoading(false);
  }

  const downloadKeywords = (keywords) => {
    dispatch(downloadActions.download(
      keywords, `${org.name}_recommended_keywords`, 'rec_keywords'));
  };

  return (
    <ResultBoundary
      result={recommendedKeywords}
      renderLoading={() => <Loading.Spinner level={'page'} />}
      renderError={() => (
        <Flex style={{ justifyContent: 'center', paddingTop: '200px' }}>
          <Text type={Text.TYPE.TITLE} className={styles.error}>
            Unable to load recommended keywords. Please try again later.
          </Text>
        </Flex>
      )}
    >
      {({ data }) => {
        const recKeywords = config.recentlyAddedKws ?
          data.filter((v) => !config.recentlyAddedKws?.includes(v.value)) : data;

        let recKeywordsTitle = '0 Recommended Keywords';
        if (recKeywords.length > 0) {
          recKeywordsTitle = recKeywords.length > 100 ? '100+ Recommended Keywords' :
          `${recKeywords.length} Recommended Keywords`;
        }

        return (
          <React.Fragment>
            <Flex
              style={{ padding: '20px',
                borderBottom: '1px solid #e9e9e9',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Text type={Text.TYPE.TITLE} bold>{recKeywordsTitle}</Text>
              <Button
                onClick={() => downloadKeywords(recKeywords)}
                className={styles.downloadButton}
              >
                <Text className={styles.downloadButton}>
                  Download Recommended Keywords
                </Text>
                <Icon
                  className={styles.downloadIcon}
                  size={Icon.SIZE.MEDIUM} type="get_app"
                />
              </Button>
            </Flex>
            {data.length === 0 ? (
              <Flex style={{ justifyContent: 'center', paddingTop: '125px' }}>
                <Text type={Text.TYPE.TITLE} className={styles.error}>
                  There are no recommended keywords at this time. Please try again later.
                </Text>
              </Flex>
            ) : (
              <div style={{ padding: '20px 30px', maxHeight: '500px', overflowY: 'scroll' }}>
                {recKeywords.map((v, i) => i < 100 ? (
                  <Pill className={cx(styles.pill, styles.keywordPill)}>
                    <Flex direction="row">
                      <Checkbox2
                        checked={selectedKeywords.includes(v.value)}
                        onChange={() => {
                          if (selectedKeywords.includes(v.value)) {
                            setSelectedKeywords(selectedKeywords.filter((k) => k !== v.value));
                          } else {
                            setSelectedKeywords([...selectedKeywords].concat(v.value));
                          }
                        }}
                        size={Icon.SIZE.LARGE}
                      />
                      <Text>{v.value}</Text>
                    </Flex>
                  </Pill>
                ) : null
                )}
              </div>
            )}
          </React.Fragment>
        );
      }}
    </ResultBoundary>
  );
};

type WrapperProps = {
  selectedKeywords: any;
  setSelectedKeywords: any;
  setLoading: any;
}

const RecommendedKeywords = (
  { selectedKeywords, setSelectedKeywords, setLoading }: WrapperProps) => {
  const org = useOrg();
  const genericKeywords = useRequestValue(genericKeywordsRequestState, org.id);

  return (
    <ResultBoundary
      result={genericKeywords}
      renderLoading={() => <Loading.Spinner level={'page'} />}
      renderError={() => (
        <Flex style={{ justifyContent: 'center', paddingTop: '200px' }}>
          <Text type={Text.TYPE.TITLE} className={styles.error}>
            Unable to load recommended keywords. Please try again later.
          </Text>
        </Flex>
      )}
    >
      {({ data }) => (
        <RecKeywordsWrapper
          genericKeywords={data.results}
          selectedKeywords={selectedKeywords}
          setSelectedKeywords={setSelectedKeywords}
          setLoading={setLoading}
        />
      )}
    </ResultBoundary>
  );
};

type Props = {
  onCancel: any;
  visible: boolean;
};

const RecKeywordsModal = ({ onCancel, visible }: Props) => {
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  // Need this at the top so we can disable the Add Keywords Button.
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useSharedState(addKeywordsConfigState);
  const [, setModalVisible] = useSharedState(addKeywordsVisibleState);

  const footer = (
    <Flex direction="column" justifyContent="flex-end" style={{ padding: '10px 0' }}>
      <div>
        <Button
          pointer
          className={styles.button}
          onClick={() => {
            onCancel();
            setSelectedKeywords([]);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={loading || selectedKeywords.length < 1}
          pointer
          className={styles.button}
          type={'primary'}
          onClick={() => {
            setConfig({
              ...config,
              keywords: selectedKeywords,
              rec_keywords: selectedKeywords,
            });
            setModalVisible(true);
            setSelectedKeywords([]);
          }}
        >
          Add Keywords
        </Button>
      </div>
    </Flex>
  );

  return (
    <Modal
      visible={visible}
      bodyStyle={{ padding: 0, minHeight: 400 }}
      footer={footer}
      width="1250px"
      maskClosable={false}
      closable={false}
    >
      <RecommendedKeywords
        selectedKeywords={selectedKeywords}
        setSelectedKeywords={setSelectedKeywords}
        setLoading={setLoading}
      />
    </Modal>
  );
};


export default RecKeywordsModal;
