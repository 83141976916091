export const CRM_TABS = [
  {
    key: 'contacts',
    label: 'Contacts',
  },
  {
    key: 'accounts',
    label: 'Accounts',
  },
  {
    key: 'leads',
    label: 'Leads',
  },
];
export const HUBSPOT_CRM_TABS = [
  {
    key: 'contacts',
    label: 'Contacts',
  },
  {
    key: 'accounts',
    label: 'Accounts',
  },
];


export const CONTACTS_SEP = [{
  keyName: 'export_only_crm_existing_records_to_sep',
  type: 'boolean',
  title: 'Contacts to export',
  tooltip: 'Constrain exports to only CRM records or any records.',
  options: [{ label: 'Both CRM and non-CRM', value: false },
  { label: 'CRM only', value: true }],
  defaultValue: false,
},
{
  keyName: 'enrich_when_exporting_to_sep',
  title: 'Enrich CRM records while exporting',
  tooltip: `CRM records will be enriched before export if enabled and
  credits might be deducted.
  Non CRM records will always be enriched before export and
  credits will be deducted.`,
  options: [{ label: 'Always', value: 'always_enrich' },
  { label: 'Never', value: 'never_enrich' },
  { label: 'Ask users during export', value: 'optional_enrich' }],
  defaultValue: 'always_enrich',
},
];

export const SALES_INTEGRATION_URL_PATH = 'sales-integration';
export const SALES_INTEGRATION_URL = `/settings/${SALES_INTEGRATION_URL_PATH}`;
export const INTEGRATION_URL = '/settings/integration/manage';
export const NEW_MAPPING_URL = '/settings/manage?activeContext=mapping-profiles';

export const INTEGRATION_TYPE = {
  crm: { value: 'crm', label: 'CRM' },
  sep: { value: 'sep', label: 'SEP' },
};


export const INTEGRATION_CRM_TYPE = {
  salesforce: { value: 'Salesforce', label: 'Salesforce' },
  dynamics: { value: 'Dynamics', label: 'Dynamics' },
  hubspot: { value: 'HubspotCrm', label: 'Hubspot' },
  hvs: { value: 'hvs', label: 'HVS' },
};

// integration config keys
export const CONTACT_ACCOUNT_SYNC_ENABLED = 'contact_account_sync_enabled';
export const CONTACT_PUSH_ON_ACCOUNT_FAILURE_ENABLED = 'contact_push_on_account_failure_enabled';
export const CONTACT_EXPORT_ENABLED = 'contact_export_enabled';
export const LEAD_EXPORT_ENABLED = 'lead_export_enabled';
export const ACCOUNT_EXPORT_ENABLED = 'account_export_enabled';
export const ENRICH_ACCOUNT_ON_CONTACT_PUSH = 'enrich_account_on_contact_push';
export const CRM_ACCOUNT_SELECTION_ENABLED = 'crm_account_selection_enabled';

