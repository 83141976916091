import cubejs from "@cubejs-client/core";

const cubeClient = (orgId) =>
  cubejs({
    apiUrl: `${window.location.origin}/cube`,
    headers: {
      "X-CSRFToken": window.__CSRF_TOKEN__,
      "X-org-id": orgId,
    },
  });

export default cubeClient;
