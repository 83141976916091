import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'new_contacts',
    name: 'List of New Contacts',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const sagas = require('./sagas');
          const reducer = require('./modules');
          injectReducer('newContacts', reducer.default);
          injectSagas('newContacts', sagas.default);
          cb(null, containers.NewContactsContainer);
        },
        'new_contacts',
      );
    },
  };
}
