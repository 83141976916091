import {
  get, difference, mapValues,
  filter, toSafeInteger, remove,
} from 'lodash';
import { campaignListColumnsStorage } from './localstorage';
import { DATE_FILTER_OPTIONS_VALUES } from '../../components/DateFilter/constants';
import moment from 'moment';
import { getAllChoicesForFilterOption } from './components/FilterByPanel/utils';
import { isFeatureFlagEnabledForOrg } from 'routes/Advertising/featureGating/utils';
import { campaignClassificationDucks } from 'routes/Advertising/ducks';
import { treeNodeKeysUtils } from 'routes/Advertising/utils';
import { ADVERTISING_CATEGORIES } from 'routes/Advertising/constants';

const {
  constants: {
    CLASSIFICATION_TYPES,
    CAMPAIGN_CLASSIFICATION_ACTIONS,
  },
} = campaignClassificationDucks;

export const filtersStateToQueryParamsObject = (filtersState, filterOptions) => {
  if (Boolean(filtersState) === false) return {};
  const allAvailableChoices = getAllChoicesForFilterOption(filterOptions);

  const defaultFilterBuilder = (filterKey, filterValue) => {
    const value = Object.keys(filterValue).filter(
      (choice) =>
        filterValue[choice] &&
        get(allAvailableChoices, `${filterKey}`, []).includes(choice)
    );

    if (value.length !== 0) {
      return { [filterKey]: value };
    }
    return {};
  };

  const filterParams = Object.keys(filtersState).reduce(
    (acc, filterkey) => ({
      ...acc,
      ...defaultFilterBuilder(filterkey, filtersState[filterkey]),
    }),
    {}
  );

  return filterParams;
};

export function flattenCampaignSelections(campaignListSelections) {
  return Object.keys(campaignListSelections)
    .reduce(
      (acc, pageNumber) => {
        const pageSelections = get(campaignListSelections, pageNumber, {});
        const selectedIds = Object.keys(pageSelections)
          .filter((id) => pageSelections[id])
          .map((id) => parseInt(id));

        return [...acc, ...selectedIds];
      },
      [],
    );
}

export const getAllSelectedCampaigns = (selectedCampaigns) =>
  Object.keys(selectedCampaigns).reduce((accumulator, pageNumber) => {
    const selectedCampaignsOnPage = get(selectedCampaigns, pageNumber, {});
    const campaignIds = Object.keys(selectedCampaignsOnPage);
    const selectedCampaignData = filter(
      campaignIds.map((id) => ({ id: toSafeInteger(id), ...selectedCampaignsOnPage[id] }))
    );

    return [...accumulator, ...selectedCampaignData];
  }, []);

export const mapColumnKeys = (columns) => columns && columns.map(({ key }) => key);

export const updateAndGetLatestColumnConfig = (allColumns, defaultColumns, orgFeatureFlags) => {
  let usersColumnConfig = campaignListColumnsStorage.get();

  if (usersColumnConfig && Array.isArray(usersColumnConfig)) {
    const defaultColumnsKeysList = mapColumnKeys(defaultColumns);
    const userSelectedColumnKeyList = difference(usersColumnConfig, defaultColumnsKeysList);
    usersColumnConfig = userSelectedColumnKeyList.reduce((acc, columnKey) => {
      acc[columnKey] = true;
      return acc;
    }, {});

    campaignListColumnsStorage.set(usersColumnConfig);
  }

  if (usersColumnConfig === null) {
    return defaultColumns;
  }


  // This part deselects columns which were manually selected by user but,
  // are no more available to users because respective feature flag is turned off.
  // e.g. if `has_emc` flag is turned off by csm's then all external campaign related
  // columns will be deselected.
  usersColumnConfig = mapValues(usersColumnConfig, (value, key) => {
    const isFeatureEnabled =
      isFeatureFlagEnabledForOrg({ orgFeatureFlags, featureIdentifier: key });
    if (isFeatureEnabled === false) {
      return false;
    }
    return value;
  });
  campaignListColumnsStorage.set(usersColumnConfig);
  // Deselect column logic ends here


  const userSelectedAndDefaultColumnMerged = allColumns
      .filter(({ key, customizable, defaultSelected }) => {
        if (customizable === false) {
          return true;
        }
        if (Object.keys(usersColumnConfig).includes(key)) {
          return usersColumnConfig[key];
        }
        return defaultSelected;
      });
  return userSelectedAndDefaultColumnMerged;
};

export const updateColumnConfig = (selections, defaultColumns, nonCustomizableColumns) => {
  const selectionColumnKeysList = mapColumnKeys(selections);
  const defaultColumnkeysList = mapColumnKeys(defaultColumns);
  const columnsSelectedByUser = difference(selectionColumnKeysList, nonCustomizableColumns);
  const columnsDeselectedByUser = difference(defaultColumnkeysList, selectionColumnKeysList);
  const currentUserColumnConfig = campaignListColumnsStorage.get();

  const resetedUsersColumnConfig =
    mapValues(currentUserColumnConfig, () => false);

  const updatedUsersColumnConfig = (() => {
    let columnConfig = columnsSelectedByUser.reduce((acc, columnKey) => {
      acc[columnKey] = true;
      return acc;
    }, resetedUsersColumnConfig);

    columnConfig = columnsDeselectedByUser.reduce((acc, columnKey) => {
      acc[columnKey] = false;
      return acc;
    }, columnConfig);

    return columnConfig;
  })();

  campaignListColumnsStorage.set(updatedUsersColumnConfig);
};

export const statsDateRangeParams = (value, from, to, lastPipelineRun) => {
  const stats_start_date = from;
  const stats_end_date = to;
  if (value === DATE_FILTER_OPTIONS_VALUES.dateRange) {
    return ({ stats_start_date, stats_end_date });
  }
  if (value === DATE_FILTER_OPTIONS_VALUES.lifetime) {
    return {};
  }

  return ({
    stats_start_date: moment.utc(lastPipelineRun).subtract(value, 'day').format('YYYY-MM-DD'),
    stats_end_date: moment.utc(lastPipelineRun).format('YYYY-MM-DD'),
  });
};

export const getActionForSelectedCampaigns = (selectedCampaignClassifications) => {
  const { move, restore } = CAMPAIGN_CLASSIFICATION_ACTIONS;
  const campaignClassifications = [...selectedCampaignClassifications];
  // Note: remove alters campaignClassifications by removing the match
  const archivedClassification = remove(
    campaignClassifications,
    (classificationType) => classificationType === CLASSIFICATION_TYPES.CAMPAIGNS_ARCHIVED
  );
  const allAreArchived = archivedClassification.length > 0 && campaignClassifications.length === 0;
  const someAreArchived = archivedClassification.length > 0 && campaignClassifications.length > 0;
  if (allAreArchived) return restore;
  if (someAreArchived) return `${restore}/ ${move}`;
  return CAMPAIGN_CLASSIFICATION_ACTIONS.move;
};

export const isCampaignCategoryFolder = (treeNodeKey) => {
  const folderCategory = treeNodeKeysUtils.extractCategoryFromPrefix(treeNodeKey);
  return folderCategory === ADVERTISING_CATEGORIES.CAMPAIGNS;
};
