import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  appSwitcherEnabledAppsSelector,
  commandbarMetadataSelector,
} from 'modules/user/selectors';
import { AppProvider } from '@sixsense/core/shared/context/app-context';
import FlagContext from 'contexts/FlagContext';
import { useFeatureFlagsCheck } from '@sixsense/core/featureFlags';
import { FEATURE_FLAGS } from 'constants/featureFlags';
import { init } from 'commandbar';

const AppProviderWrapperComponent = (props) => {
  const { commandbarMetadata, enabledApps } = props;

  // ****Commandbar changes****
  const shouldCommandbarDisplay = useFeatureFlagsCheck(FEATURE_FLAGS.shouldCommandbarDisplay);

  useEffect(() => {
    if (shouldCommandbarDisplay) {
      try {
        init('0a783941', { nonce: window.__CSP_NONCE__ }); // eslint-disable-line no-undef
      } catch (e) {
        // Handle error silently
      }
      try {
        window.CommandBar.boot(commandbarMetadata.visitorID.toString());
        window.CommandBar.addMetadataBatch(commandbarMetadata);
      } catch (e) {
        // Handle error silently
      }
    }
  }, [shouldCommandbarDisplay, commandbarMetadata]);

  return (
    <AppProvider currentApp="ABM" enabledApps={enabledApps}>
      {props.children}
    </AppProvider>
  );
};

AppProviderWrapperComponent.propTypes = {
  enabledApps: PropTypes.arrayOf(PropTypes.string),
  commandbarMetadata: PropTypes.any,
  children: PropTypes.any,
};

const mapStateToProps = (state) => ({
  commandbarMetadata: commandbarMetadataSelector(state),
  enabledApps: appSwitcherEnabledAppsSelector(state),
});

AppProviderWrapperComponent.defaultProps = {
};

export default compose(
  FlagContext.FlagConsumer,
  connect(mapStateToProps, {}),
)(AppProviderWrapperComponent);
