const loaderPresets = {
  loaded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorStatus: undefined,
};

const SET_INTEGRATION_STATE_TO_VALIDATE = 'INTEGRATIONS/SET_INTEGRATION_STATE_TO_VALIDATE';

function setIntegrationStateToValidate(stateKey) {
  return { type: SET_INTEGRATION_STATE_TO_VALIDATE, stateKey };
}

const VALIDATE_INTEGRATION_CREDS = 'INTEGRATIONS/VALIDATE_INTEGRATION_CREDS';
const VALIDATE_INTEGRATION_CREDS_SUCCESS = 'INTEGRATIONS/VALIDATE_INTEGRATION_CREDS_SUCCESS';
const VALIDATE_INTEGRATION_CREDS_FAILURE = 'INTEGRATIONS/VALIDATE_INTEGRATION_CREDS_FAILURE';

function validateIntegrationCreds(integrationType) {
  return { type: VALIDATE_INTEGRATION_CREDS, integrationType };
}
function validateIntegrationCredsSuccess(integrationType, areCredsValid) {
  return { type: VALIDATE_INTEGRATION_CREDS_SUCCESS, integrationType, areCredsValid };
}
function validateIntegrationCredsFailure(integrationType, errorMessage, errorStatus) {
  return { type: VALIDATE_INTEGRATION_CREDS_FAILURE, integrationType, errorMessage, errorStatus };
}

export default function integrationsReducer(state={}, action) {
  switch (action.type) {
    case SET_INTEGRATION_STATE_TO_VALIDATE: {
      return {
        ...state,
        [action.stateKey]: {
          areCredsValid: undefined,
          ...loaderPresets,
        },
      };
    }
    case VALIDATE_INTEGRATION_CREDS: {
      return {
        ...state,
        [action.integrationType]: {
          ...state[action.integrationType],
          loading: true,
          loaded: false,
          error: false,
          errorMessage: undefined,
          errorStatus: undefined,
        },
      };
    }
    case VALIDATE_INTEGRATION_CREDS_SUCCESS: {
      return {
        ...state,
        [action.integrationType]: {
          ...state[action.integrationType],
          areCredsValid: action.areCredsValid,
          loaded: true,
          loading: false,
          error: false,
          errorMessage: undefined,
          errorStatus: undefined,
        },
      };
    }
    case VALIDATE_INTEGRATION_CREDS_FAILURE: {
      return {
        ...state,
        [action.integrationType]: {
          ...state[action.integrationType],
          areCredsValid: false,
          loaded: false,
          loading: false,
          error: true,
          errorMessage: action.errorMessage,
          errorStatus: action.errorStatus,
        },
      };
    }

    default:
      return state;
  }
}

export const actions = {
  validateIntegrationCreds,
  validateIntegrationCredsSuccess,
  validateIntegrationCredsFailure,
  setIntegrationStateToValidate,
};

export const actionTypes = { VALIDATE_INTEGRATION_CREDS };
