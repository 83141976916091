import { stateGenerators } from "./stateGenerators";
import { combineReducers } from "redux";
import { createReducerUtil } from "utils/core";
import { SET_AD_LIBRARY_CLASSIFICATION_DRAWER_VISIBILITY } from "./types";

const {
  adLibraryClassifications,
  createAdsClassification,
  changeClassification,
  deleteAdsClassification,
  renameClassification,
} = stateGenerators;

const adLibraryClassificationDrawerInitialState = {
  visible: false,
  placementIds: [],
  uiAction: undefined,
};

const adLibraryClassificationDrawer = createReducerUtil(
  adLibraryClassificationDrawerInitialState,
  {
    [SET_AD_LIBRARY_CLASSIFICATION_DRAWER_VISIBILITY]: (
      state,
      { visible, placementIds, uiAction }
    ) => {
      if (visible === false) {
        return adLibraryClassificationDrawerInitialState;
      }

      return {
        ...state,
        visible,
        placementIds,
        uiAction,
      };
    },
  }
);

export const reducers = combineReducers({
  [adLibraryClassifications.stateKey]: adLibraryClassifications.reducer,
  [createAdsClassification.stateKey]: createAdsClassification.reducer,
  adLibraryclassificationsDrawer: adLibraryClassificationDrawer,
  [changeClassification.stateKey]: changeClassification.reducer,
  [deleteAdsClassification.stateKey]: deleteAdsClassification.reducer,
  [renameClassification.stateKey]: renameClassification.reducer,
});
