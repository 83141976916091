export const LIST_CAMPAIGN_LOAD = 'CAMPAIGNS/LIST/LOAD';
export const load = () => ({ type: LIST_CAMPAIGN_LOAD });

export const PAGE_REFRESH = 'CAMPAIGNS/LIST/REFRESH';
export const refresh = () => ({ type: PAGE_REFRESH });

export const SET_PAGE_NUMBER = 'CAMPAIGNS/LIST/SET_PAGE_NUMBER';
export const setPageNumber = (payload) => ({
  type: SET_PAGE_NUMBER,
  payload,
});

export const INIT_PAGE_NUMBER = 'CAMPAIGNS/LIST/INIT_PAGE_NUMBER';
export const initPageNumber = (payload) => ({
  type: INIT_PAGE_NUMBER,
  payload,
});

export const SORT_LIST = 'CAMPAIGNS/LIST/SORT_LIST';
export const sortList = (payload) => ({
  type: SORT_LIST,
  payload,
});

export const MODIFY_FILTERS = 'CAMPAIGNS/LIST/MODIFY_FILTERS';
export const modifyFilters = (payload, selectedTreeNodeKey) => ({
  type: MODIFY_FILTERS,
  payload,
  selectedTreeNodeKey,
});

export const APPLY_FILTERS_GLOBALLY = 'CAMPAIGNS/LIST/APPLY_FILTERS_GLOBALLY';
export const applyFiltersGlobally = (payload) => ({
  type: APPLY_FILTERS_GLOBALLY,
  payload,
});

export const INIT_FOLDER_FILTERS = 'CAMPAIGNS/LIST/INIT_FOLDER_FILTERS';
export const initFolderFilters = (folderIds) => ({
  type: INIT_FOLDER_FILTERS,
  folderIds,
});

export const INIT_NEW_FOLDER_FILTERS = 'CAMPAIGNS/LIST/INIT_NEW_FOLDER_FILTERS';
export const initNewFolderFilters = (folderId) => ({
  type: INIT_NEW_FOLDER_FILTERS,
  folderId,
});

export const RESET_FILTERS = 'CAMPAIGNS/LIST/RESET_FILTERS';
export const resetFilters = (selectedTreeNodeKey) => ({
  type: RESET_FILTERS,
  selectedTreeNodeKey,
});

export const CAMPAIGN_LIST_ROW_SELECTION = 'CAMPAIGNS/LIST/CAMPAIGN_LIST_ROW_SELECTION';
export const campaignListRowSelection = (payload) => ({
  type: CAMPAIGN_LIST_ROW_SELECTION,
  payload,
});

export const CAMPAIGN_LIST_PAGE_SELECTION = 'CAMPAIGNS/LIST/CAMPAIGN_LIST_PAGE_SELECTION';
export const campaignListPageSelection = (payload) => ({
  type: CAMPAIGN_LIST_PAGE_SELECTION,
  payload,
});

export const RESET_CAMPAIGN_LIST_SELECTION = 'CAMPAIGNS/LIST/RESET_CAMPAIGN_LIST_SELECTION';
export const resetCampaignListSelections = (payload) => ({
  type: RESET_CAMPAIGN_LIST_SELECTION,
  payload,
});

export const CAMPAIGN_LIST_SEARCH = 'CAMPAIGNS/LIST/CAMPAIGN_LIST_SEARCH';
export const campaignListSearch = (payload) => ({
  type: CAMPAIGN_LIST_SEARCH,
  payload,
});

export const REFRESH_GLOBAL_STATE = 'CAMPAIGNS/LIST/REFRESH_GLOBAL_STATE';
export const refreshGlobalState = () => ({
  type: REFRESH_GLOBAL_STATE,
});

