import React from 'react';
import Text from 'v2-components/Text';
import { COLUMN_CATEGORIES } from '../constants';
import { CAMPAIGN_SOURCE } from '../../../constants';
import { NA } from './common/NA';
import { ROW_DATA_TYPES } from 'aa-components/CampaignCSVExportModal/constants';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';

export const LINKEDIN_CAMPAIGN_ID = {
  category: COLUMN_CATEGORIES.linkedIn,
  key: 'linkedin-campaign-id',
  csvColumnKey: 'linkedin_campaign_id',
  title: <Label name={CAMPAIGN_LABELS.linkedinCampaignId} />,
  dataIndex: 'linkedin_campaign_id',
  width: 200,
  render: (linkedInCampaignId, campaign) => {
    if (campaign.campaign_source !== CAMPAIGN_SOURCE.LINKEDIN) {
      return (<NA />);
    }

    return (<Text
      type={Text.TYPE.SUBBODY}
    >
      {linkedInCampaignId}
    </Text>);
  },
  csvOverrides: {
    render: (linkedInCampaignId, campaign, dataType) => {
      if (dataType === ROW_DATA_TYPES.aggregated) return '';

      if (campaign.campaign_source !== CAMPAIGN_SOURCE.LINKEDIN) {
        return '-';
      }

      return linkedInCampaignId;
    },
  },
};
