import { toaster } from '@sixsense/core';
import { get, map, omitBy, reduce } from 'lodash';
import {
  mapTypeSelector,
  orgSelector,
  userObjectSelector,
} from 'modules/user/selectors';
import moment from 'moment';
import { takeEvery } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from 'store/sagas';
import { CRM, MAP, PRODUCT, WEB } from '../../constants';
import { isReviewAccessUserSelector } from '../../selectors';
import {
  DEDUPE_FIELDS,
  DEDUPE_FIELDS_MAP,
  PRODUCT_DEDUPE_FIELDS,
  STATUS_EXCLUDED,
  STATUS_MAPPED,
} from './constants';
import { actions, actionTypes } from './modules';
import {
  formattedFiltersSelector,
  isPreviewModeSelector,
  mappingDataSelector,
  paginationInfoSelector,
  previewDataSelector,
  sortInfoSelector,
  sourceAndTypeSelector,
} from './selectors';
import {
  downloadJobsStateGenerator,
  loadOverrideUserStateGenerator,
  loadRuleNamesStateGenerator,
} from './stateGenerators';
import { coalesceEmpty } from './utils';

const {
  LOAD_MAPPING_DATA_REQUEST,
  UPDATE_MANUAL_MAPPINGS,
  UPDATE_REVIEW_STATUS,
} = actionTypes;

const { loadMappingDataFailure, loadMappingDataSuccess, setMappingData } =
  actions;

const productFields = (record) => ({
  ...record,
  productMapping: coalesceEmpty(record, [
    'mapped_products',
    'rule_products',
    'products',
  ]),
});

const webFields = (record) => ({
  ...record,
  eventMapping: coalesceEmpty(record, [
    'mapped_activity',
    'rule_event',
    'event',
  ]),
});

const activityFields = (record) => ({
  ...record,
  actionMapping: coalesceEmpty(record, [
    'mapped_action',
    'rule_predicted_action',
    'predicted_action',
  ]),
  channelMapping: coalesceEmpty(record, [
    'mapped_channel',
    'rule_predicted_channel',
    'predicted_channel',
  ]),
});

const crmFields = (record) => ({
  ...activityFields(record),
  typeMapping: coalesceEmpty(record, ['mapped_type', 'rule_mapped_type']),
});

const formatMappings = (result, source, classificationType) => {
  if (classificationType === PRODUCT) {
    return map(result, productFields);
  }

  if (source === WEB) {
    return map(result, webFields);
  }

  if (source === CRM) {
    return map(result, crmFields);
  }

  return map(result, activityFields);
};

function* loadMappingsSaga(request) {
  const { dataSource, classificationType } = yield select(
    sourceAndTypeSelector
  );
  const org = yield select(orgSelector);
  const { pageNumber, pageSize } = yield select(paginationInfoSelector);
  const { sortAttr, sortOrder } = yield select(sortInfoSelector);
  const isPreview = yield select(isPreviewModeSelector);

  // Determine offset (since pageNumber starts at 1 we subtract pageSize)
  const offset = pageSize ? pageSize * pageNumber - pageSize : 0;

  let payload = {};
  let mappingsEndpoint;

  if (!isPreview) {
    payload = {
      filters: yield select(formattedFiltersSelector),
    };
    // eslint-disable-next-line max-len
    mappingsEndpoint = `turbotax/taxonomy/org/${org}/turbotax_aggregated_mappings/${classificationType}/${dataSource}/?limit=${pageSize}&offset=${offset}`;
  } else {
    const { ast, mapping, removeManualOverride, mapping_ids } =
      yield select(previewDataSelector);

    payload = {
      ast,
      mapping,
      remove_manual_override: removeManualOverride,
      mapping_ids,
    };
    // eslint-disable-next-line max-len
    mappingsEndpoint = `turbotax/taxonomy/org/${org}/turbotax_review_impacted_records/${classificationType}/${dataSource}/?limit=${pageSize}&offset=${offset}`;
  }

  try {
    const body = {
      ...payload,
      sort: {
        order: sortOrder,
        attr: sortAttr,
      },
    };
    const mappings = yield call(request, mappingsEndpoint, 'POST', {
      body: JSON.stringify(body),
    });

    yield put(
      loadMappingDataSuccess(
        formatMappings(mappings.results, dataSource, classificationType),
        mappings.count
      )
    );
  } catch (e) {
    yield put(loadMappingDataFailure());
    toaster.showError('Failed to load data. Please try again later');
  }
}

const getMappingsForData = (
  dataSource,
  classificationType,
  mapping,
  isInternalUser,
  userName
) => {
  const otherFields = {
    mode_of_mapping: 'manual',
    updated_dt: moment().format(moment.HTML5_FMT.DATE),
    updated_by: userName,
    ...(!isInternalUser ? { is_reviewed: true } : {}),
    checked: false,
    updating: false,
  };

  if (classificationType === PRODUCT) {
    return {
      ...otherFields,
      status:
        get(mapping, 'product.0') === '__do_not_map__'
          ? STATUS_EXCLUDED
          : STATUS_MAPPED,
      productMapping: get(mapping, 'product'),
    };
  }

  if (dataSource === WEB) {
    return {
      ...otherFields,
      status:
        get(mapping, 'event') === '__do_not_map__'
          ? STATUS_EXCLUDED
          : STATUS_MAPPED,
      eventMapping: get(mapping, 'event'),
    };
  }

  return {
    ...otherFields,
    status:
      get(mapping, 'predicted_action') === '__do_not_map__'
        ? STATUS_EXCLUDED
        : STATUS_MAPPED,
    actionMapping: get(mapping, 'predicted_action'),
    channelMapping: get(mapping, 'predicted_channel'),
    typeMapping: get(mapping, 'mapped_type'),
  };
};

const createOverrideBody = (
  recordIndices,
  mappingData,
  dedupeFields,
  mapping,
  userName,
  isInternalUser
) => {
  const source = map(recordIndices, (index) => {
    const record = get(mappingData, index);
    return {
      fields: reduce(
        dedupeFields,
        (result, field) => {
          // eslint-disable-next-line no-param-reassign
          result[field] = get(record, field);
          return result;
        },
        {}
      ),
      mapping_id: get(record, 'mapping_id'),
      composite_key: get(record, 'composite_key'),
    };
  });

  const target = omitBy(mapping, (v) => !v);
  const updated_by = userName;

  return {
    source,
    target,
    updated_by,
    is_external_user: !isInternalUser,
  };
};

function* updateManualMappingsSaga(request, action) {
  const { mapping, recordIndices } = action;
  const { dataSource, classificationType } = yield select(
    sourceAndTypeSelector
  );

  const mappingData = yield select(mappingDataSelector);
  const isInternalUser = yield select(isReviewAccessUserSelector);
  const user = yield select(userObjectSelector);
  const mapType = yield select(mapTypeSelector);
  const org = yield select(orgSelector);

  // eslint-disable-next-line max-len
  const mappingsEndpoint = `turbotax/taxonomy/org/${org}/turbotax_update_mappings/${classificationType}/${dataSource}/`;
  let dedupeFields;

  if (classificationType === PRODUCT) {
    dedupeFields = PRODUCT_DEDUPE_FIELDS[dataSource];
  } else {
    dedupeFields =
      dataSource === MAP
        ? DEDUPE_FIELDS_MAP[mapType]
        : DEDUPE_FIELDS[dataSource];
  }

  try {
    yield call(request, mappingsEndpoint, 'POST', {
      body: JSON.stringify(
        createOverrideBody(
          recordIndices,
          mappingData,
          dedupeFields,
          mapping,
          user.username,
          isInternalUser
        )
      ),
    });

    yield put(
      setMappingData(
        recordIndices,
        getMappingsForData(
          dataSource,
          classificationType,
          mapping,
          isInternalUser,
          user.username
        )
      )
    );
    toaster.showSuccess(
      recordIndices.length > 1
        ? `${recordIndices.length} records have been successfully updated.`
        : 'Record have been successfully updated.'
    );
  } catch (e) {
    toaster.showError('Failed to update mappings. Please try again later');
    yield put(
      setMappingData(recordIndices, {
        updating: false,
      })
    );
  }
}

function* updateReviewStatusSaga(request, action) {
  // we can add is_flag support in same saga.
  const { isReviewed: is_reviewed, recordIndices } = action;
  const { dataSource, classificationType } = yield select(
    sourceAndTypeSelector
  );

  const mappingData = yield select(mappingDataSelector);
  const user = yield select(userObjectSelector);
  const org = yield select(orgSelector);

  // eslint-disable-next-line max-len
  const mappingsEndpoint = `turbotax/taxonomy/org/${org}/turbotax_update_reviewed_status/${classificationType}/${dataSource}/`;
  const reviewedLabel = is_reviewed ? 'reviewed' : 'unreviewed';

  try {
    yield call(request, mappingsEndpoint, 'POST', {
      body: JSON.stringify({
        reviewed_by: user.username,
        is_reviewed,
        composite_keys: map(recordIndices, (i) =>
          get(mappingData, `${i}.composite_key`)
        ),
      }),
    });

    yield put(
      setMappingData(recordIndices, {
        updating: false,
        is_reviewed,
      })
    );
    toaster.showSuccess(
      recordIndices.length > 1
        ? `${recordIndices.length} records have been successfully marked as ${reviewedLabel}.`
        : `Record have been successfully marked as ${reviewedLabel}.`
    );
  } catch (e) {
    toaster.showError(
      `Failed to update record as ${reviewedLabel}. Please try again later`
    );
    yield put(
      setMappingData(recordIndices, {
        updating: false,
      })
    );
  }
}

function* watchLoadMappings(request) {
  yield takeLatest(LOAD_MAPPING_DATA_REQUEST, loadMappingsSaga, request);
}

function* watchUpdateMappings(request) {
  yield takeEvery(UPDATE_MANUAL_MAPPINGS, updateManualMappingsSaga, request);
}

function* watchUpdateReviewStatus(request) {
  yield takeEvery(UPDATE_REVIEW_STATUS, updateReviewStatusSaga, request);
}

export const reviewMappingSagas = [
  fetchSaga(watchLoadMappings),
  fetchSaga(watchUpdateMappings),
  fetchSaga(watchUpdateReviewStatus),
  loadOverrideUserStateGenerator.saga,
  loadRuleNamesStateGenerator.saga,
  downloadJobsStateGenerator.saga,
];
