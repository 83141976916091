import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer } = getAsyncInjectors(store);
  return {
    path: 'sales-intelligence/mapping(/:id)',
    name: 'sales_intelligence_mapping',
    getComponent(_nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const reducer = require('./modules');
          cb(null, containers.Mapping);

          // sagas are loaded at the settings level
          injectReducer('si_mapping', reducer.default);
        },
        'settings_sales_intelligence_mapping'
      );
    },
  };
}
