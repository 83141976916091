import { IntegrationState } from './constants';

const initialState = {
  loading: false,
  error: false,
  integration: undefined,
};


const REQUEST_TOKEN_STATE = 'HUBSPOT/REQUEST_TOKEN_STATE';
const REQUEST_TOKEN_STATE_SUCCESS = 'HUBSPOT/REQUEST_TOKEN_STATE_SUCCESS';
const REQUEST_TOKEN_STATE_ERROR = 'HUBSPOT/REQUEST_TOKEN_STATE_ERROR';
const requestTokenState = () => ({ type: REQUEST_TOKEN_STATE });
const requestTokenStateSuccess = ({ state }) => ({ type: REQUEST_TOKEN_STATE_SUCCESS, state });
const requestTokenStateError = () => ({ type: REQUEST_TOKEN_STATE_ERROR });


const REQUEST_INTEGRATION = 'HUBSPOT/REQUEST_INTEGRATION';
const REQUEST_INTEGRATION_SUCCESS = 'HUBSPOT/REQUEST_INTEGRATION_SUCCESS';
const REQUEST_INTEGRATION_ERROR = 'HUBSPOT/REQUEST_INTEGRATION_ERROR';
const requestIntegration = () => ({ type: REQUEST_INTEGRATION });
const requestIntegrationSuccess = () => ({ type: REQUEST_INTEGRATION_SUCCESS });
const requestIntegrationError = () => ({ type: REQUEST_INTEGRATION_ERROR });

const REFRESH_TOKEN = 'HUBSPOT/REFRESH_TOKEN';
const REFRESH_TOKEN_SUCCESS = 'HUBSPOT/REFRESH_TOKEN_SUCCESS';
const REFRESH_TOKEN_ERROR = 'HUBSPOT/REFRESH_TOKEN_ERROR';
const refreshToken = () => ({ type: REFRESH_TOKEN });
const refreshTokenSucess = () => ({ type: REFRESH_TOKEN_SUCCESS });
const refreshTokenError = () => ({ type: REFRESH_TOKEN_ERROR });

const REMOVE_HUBSPOT_TOKEN = 'HUBSPOT/REMOVE_TOKEN';
const REMOVE_TOKEN_SUCCESS = 'HUBSPOT/REMOVE_TOKEN/SUCCESS';
const removeToken = () => ({ type: REMOVE_HUBSPOT_TOKEN });
const removeTokenSuccess = () => ({ type: REMOVE_TOKEN_SUCCESS });

export const actionTypes = {
  REQUEST_INTEGRATION,
  REQUEST_INTEGRATION_STATE: REQUEST_TOKEN_STATE,
  REFRESH_TOKEN,
  REMOVE_HUBSPOT_TOKEN,
};
export const actions = {
  requestTokenState,
  requestTokenStateSuccess,
  requestTokenStateError,
  requestIntegration,
  requestIntegrationSuccess,
  requestIntegrationError,
  refreshToken,
  refreshTokenError,
  refreshTokenSucess,
  removeToken,
  removeTokenSuccess,
};

export default function hubspotReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_TOKEN_STATE:
    case REQUEST_INTEGRATION:
    case REFRESH_TOKEN: {

      return { ...state, loading: true };
    }
    case REQUEST_TOKEN_STATE_SUCCESS: {
      return {
        integration: { state: action.state },
        loading: false,
        error: false,
      };
    }
    case REQUEST_INTEGRATION_SUCCESS: {
      return {
        loading: false,
        error: false,
        integration: { state: IntegrationState.PENDING },
      };
    }
    case REFRESH_TOKEN_SUCCESS : {
      return {
        loading: false,
        error: false,
        integration: { state: IntegrationState.AUTHORIZED },
      };
    }
    case REQUEST_TOKEN_STATE_ERROR:
    case REFRESH_TOKEN_ERROR:
    case REQUEST_INTEGRATION_ERROR: {
      return { loading: false, error: true };
    }
    default:
      return state;
  }
}
