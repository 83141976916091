import { STATE_KEY } from "./constants";
import { createSelector } from "reselect";
import { orgSelector } from "modules/user/selectors";
import { isFeatureFlagsEnabledSelector } from "@sixsense/core/featureFlags";
import { FEATURE_FLAGS } from "constants/featureFlags";

export const baseSelector = (state) => state.advertising[STATE_KEY];

export const linkedinAdsPageEndpointSelector = createSelector(
  orgSelector,
  (state) =>
    isFeatureFlagsEnabledSelector(state, FEATURE_FLAGS.hasLinkedinAdvertising),
  (orgId, hasLinkedinAdvertising) =>
    hasLinkedinAdvertising ? `org/${orgId}/linkedin_ads_page/` : null
);
