import moment from 'moment';
import { App } from '@sixsense/rbac/constants';
import { isNil } from 'lodash';
import { ThemeColor } from '@sixsense/core/style';


export const usersFilter = (users) => {
  const formattedUsers = users.map(
    (user) => ({ ...user, ...{ role_rbac: getRoleRbac(user.roles) } })
  );
  return formattedUsers;
};

const getRoleRbac = (roles = []) => {
  const role = roles.find((el) => el.app_id === App.SALES);
  return role ? role.role_id : null;
};

export const getCreditDateFormat =
 (date : string | number = '') => moment.utc(date).format('DD MMM YY');

export const commaSeparatedNumber = (input) => {
  // Try to convert the input to a number
  const number = Number(input);

  // Check if the conversion is valid and not NaN
  if (!isNaN(number)) {
    return number.toLocaleString('en-US');
  }
  return input; // Return the original string if it's not a number

};


/**
 * Relaces all occurances of keys(mapObj) in input str
 * @param str 'this is org with id orgId'
 * @param mapObj { 'org': '6Sense', 'orgId': 1  }
 * @returns str 'this is 6Sense with id 1'
 */
export const replaceAll = (
  str: string,
  mapObj: Object,
) => {
  const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return str.replace(
    re,
    (matched: string) =>
      `${isNil(mapObj[matched]) ? matched : mapObj[matched]}`,
  );
};


// Function to determine color code based on the current live credit balance
/**
* getLiveColorCodeByCreditBalance
* @returns ThemeColor
*/
export const getColorCodeByCreditBalance = (creditBalance: number) => {
  if (creditBalance < 0) {
    return ThemeColor.Error.DEFAULT; // Return error color if balance is negative
  } else if (creditBalance === 0) {
    return ThemeColor.Warning.DEFAULT; // Return warning color if balance is zero
  }
  return ThemeColor.Success.DEFAULT; // Return success color if balance is positive
};
