import { getAsyncInjectors } from 'store/utils';

/*
 * If this code is still here as of 1/1/2022:
 * remove the v1 crap
 * rename v2 naming convention
 *
 * Are there going to be new notifications?:
 *  consider making the new stuff more reusable/shareable
 *
 * No idea?
 * don't do anything, just remove old crap
 *
 * */


export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: 'inbox(/:id)',
    name: 'Inbox',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('discoverInbox', reducer.default);
        injectSagas('discoverInbox', sagas.default);
        cb(null, containers.InboxV2);
      }, 'discover_inbox');
    },
  };
}
