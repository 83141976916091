import React, { useState } from 'react';
import { Card, Flex, Modal } from '@sixsense/core/components';
import { Button, Text } from 'v2-components';
import { css } from 'styles/emotion';

const styles = {
  card: css({
    width: 475,
  }),
};

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onReject: () => void;
}

const ConfirmModal = ({ isOpen, onConfirm, onReject }: Props) => open ? (
  <Modal isOpen={isOpen}>
    <Card className={styles.card}>
      <Card.Header>
        <Card.Header.Title style={{ textTransform: 'none' }}>
          Delete User?
        </Card.Header.Title>
      </Card.Header>
      <Card.Content>
        <div style={{ height: 65 }}>
          <Text>
            This is permanent and cannot be undone. Are you sure you wish to continue?
          </Text>
        </div>
      </Card.Content>
      <Card.Footer>
        <Flex justifyContent="flex-end">
          <Button onClick={onReject}>Cancel</Button>
          <div style={{ width: 8 }} />
          <Button type="primary" onClick={onConfirm}>Delete</Button>
        </Flex>
      </Card.Footer>
    </Card>
  </Modal>
) : null;

type Executor = {
  resolve: (value: void) => void;
  reject: (reason?: any) => void;
} | undefined

const useConfirm = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [executor, setExecutor] = useState<Executor>(undefined);

  const confirm = () => new Promise((resolve, reject) => {
    setExecutor({ resolve, reject });
    setIsOpen(true);
  });

  const onConfirm = () => {
    if (executor) {
      executor.resolve();
      setIsOpen(false);
      setExecutor(undefined);
    }
  };

  const onReject = () => {
    if (executor) {
      executor.reject();
      setIsOpen(false);
      setExecutor(undefined);
    }
  };

  return {
    confirm,
    modalProps: {
      isOpen,
      onConfirm,
      onReject,
    },
  };
};

export default Object.assign(ConfirmModal, { useConfirm });
