import React from 'react';
import PropTypes from 'prop-types';
import { FormComponent } from 'v2-HOCS';
import { Row, Text, Icon } from 'v2-components';
import styles from './Checkbox2.module.scss';

/*
 * @param {string} id - used as data-heap html attr for heap analytics
 * @param {string} className
 * @param {string} containerClassName
 * @param {bool} checked - whether or not this is checked
 * @param {bool} indeterminate - whether or not this is in an indeterminate state
 * @param {string} SIZE - SIZE constants for checkbox.
 * @param {func} onChange - call when selected
 * @param {string} label - label displayed to the right of the checkbox
 * @param {string} checkBoxLabel - label via form component
 * @param {'left'|'right'} placement - which side to place the label
 * @param {bool} disabled
 * */
const Checkbox = (props) => {
  const {
    id,
    size = Icon.SIZE.MEDIUM,
    containerClassName,
    iconClassName,
    checked,
    onChange,
    label,
    checkBoxLabel,
    placement,
    indeterminate,
    iconClass,
    className,
    disabled,
    ...rest
  } = props;

  let iconType = 'check_box_outline_blank';
  let boxStyle = styles.checkedIcon;
  let cursorStyle = styles.pointer;
  let disabledClass = '';

  if (checked) {
    iconType = 'check_box';
  } else if (indeterminate) {
    iconType = 'indeterminate_check_box';
  } else {
    boxStyle = styles.uncheckedIcon;
  }

  if (disabled) {
    cursorStyle = '';
    disabledClass = styles.disabled;
  }

  const displayText = label || checkBoxLabel;
  const displayLabel = typeof displayText === 'string'
    ? <Text className={disabledClass}>{displayText}</Text>
    : displayText;

  return (
    <Row className={containerClassName} alignItems={Row.ALIGN_ITEMS.CENTER}>
      <label
        htmlFor={id}
        className={cursorStyle}
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          userSelect: 'none',
        }}
      >
        {placement === 'left' ? displayLabel : null}
        <Icon
          type={iconType}
          size={size}
          className={`${styles.baseIconClass} ${boxStyle} ${disabledClass} ${iconClassName}`}
        />
        <input
          type="checkbox"
          data-heap={id}
          checked={checked}
          onChange={onChange}
          className={`${styles.hiddenCheckbox} ${cursorStyle}`}
          disabled={disabled}
          {...rest}
        />
        {placement === 'right' ? displayLabel : null}
      </label>
    </Row>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  iconClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.node,
  checkBoxLabel: PropTypes.node,
  placement: PropTypes.string,
  size: PropTypes.string,
  iconClass: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  placement: 'right',
};

Checkbox.SIZE = Icon.SIZE;

export default Checkbox;

const mapInputToProps = (input) => ({
  onChange: (e) => { input.onChange(e.target.checked); input.onBlur(e.target.checked); },
  checked: !!input.value,
});

Checkbox.form = FormComponent(Checkbox, mapInputToProps);
