import React from 'react';
/* eslint max-len: [2, 4000] */
// Using 1000 for now.... might need to bump it up
export function SyncFail(props) {
  return (
    <svg {...props} width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <polygon id="path-1" points="9 3.60428571 8.39571429 3 6 5.39571429 3.60428571 3 3 3.60428571 5.39571429 6 3 8.39571429 3.60428571 9 6 6.60428571 8.39571429 9 9 8.39571429 6.60428571 6" />
      </defs>
      <g id="Linkedin-Integration-v1.2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3.2-c" transform="translate(-450.000000, -598.000000)">
          <g id="Detail-Modal" transform="translate(376.000000, 200.000000)">
            <g id="Table" transform="translate(30.000000, 97.000000)">
              <g id="Status" transform="translate(42.000000, 56.000000)">
                <g id="ic_check_circle" transform="translate(2.000000, 245.000000)">
                  <circle id="Oval" fill="#E02020" cx="6" cy="6" r="6" />
                  <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1" />
                  </mask>
                  <use id="Mask" fill="#FFFFFF" xlinkHref="#path-1" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>);
}
