import { takeLatest, put, call, select } from 'redux-saga/effects';
import { fetchSaga } from 'store/sagas';
import { actionTypes, actions } from 'modules/segments';
import {
  orgSelector,
  userSelector,
  hasCompanyMasterLookupSelector,
  hasSalesforceIntegrationSelector,
  isPredictiveSelector,
} from 'modules/user/selectors';
import { actions as globalActions } from 'modules/global';
import { actions as astActions } from 'ast-redux/modules';
import { astSelector } from 'ast-redux/selectors';
import {
  And,
  VarExpr,
  Const,
  TYPE_CONSTRUCTOR_MAP,
} from 'ast-redux/constructors';
import { NULLEXPR, EQOP } from 'ast-redux/constants';
import { orderNext, firstDate } from 'aa-ast/utils';
import { actions as segmentsSegmentActions } from 'routes/Segments/routes/Segment/modules';
import { LOOKALIKE_ACCOUNTS } from 'routes/Segments/constants';
import { destroyAllParamsNavigate } from 'utils/navigate';
import {
  actions as manageActions,
  actionTypes as sManageActionTypes,
} from 'routes/Segments/routes/Manage/modules';
import { routePathnameSelector } from 'modules/global/selectors';
import { includes, isNull, cloneDeep } from 'lodash';
import { actions as fetchableActions } from 'modules/fetchable';
import { crmDataTypeSelector } from 'routes/Segments/routes/Manage/selectors';
import {
  processFiltersConfig,
  generateFolderBody,
  generateTagBodyCreate,
  generateTagBodyUpdate,
  formatFiltersetResponse,
  isValidGTMSegment,
} from './utils';
import { generateNodeConfig } from 'aa-components/AA-AST/utils/utils';
import {
  filtersConfigSelector,
  folderToCreateSelector,
  segFoldersSelector,
  tagsToCreateSelector,
  segTagsSelector,
  filtersetSelector,
  unchangedFiltersetSelector,
} from './selectors';
import { segmentSelector } from 'routes/Segments/routes/Segment/selectors';
import { MAX_FOLDERS_LIMIT, UNSORTED_FOLDER } from './constants';
import { loadSegmentTags } from '../../routes/Settings/routes/PublishedSegments/sagas';


const { clearColumn } = fetchableActions;

const {
  changeSaveModalVisibility: changeManageModalVisibility,
  setViewBy,
} = manageActions;
const {
  changeSaveModalVisibility: changeSegmentModalVisibility,
} = segmentsSegmentActions;

const { showNotification } = globalActions;

const {
  LOAD_SEGMENT_DATA,
  CREATE_SEGMENT,
  UPDATE_SEGMENT,
  CREATE_SEGMENT_SUCCESS,
  UPDATE_SEGMENT_SUCCESS,
  ADD_AST_FILTER,
  GET_SEG_FOLDERS,
  GET_SEG_TAGS,
  LOAD_FILTERS_CONFIG,
} = actionTypes;

const { CLONE_SEGMENT_SUCCESS } = sManageActionTypes;

const {
  loadFiltersConfig,
  loadFiltersConfigFail,
  loadFiltersConfigSuccess,
  loadSegmentDataSuccess,
  loadSegmentDataFail,
  createSegmentSuccess,
  createSegmentFail,
  updateSegmentSuccess,
  updateSegmentFail,
  unstageNewSegmentData,
  getSegFoldersSuccess,
  getSegFoldersFail,
  getSegTagsSuccess,
  getSegTagsFail,
  setViewFolder,
  setEmptyFilterset,
  setFolderToCreate,
} = actions;

function* createSegment(request, action) {
  const { segmentData, segmentId } = action;
  try {
    delete segmentData.tags; // Need tags for gtm update but not create
    const org = yield select(orgSelector);
    const userId = yield select(userSelector);
    const filterset = yield select(filtersetSelector);
    const hasSalesforce = yield select(hasSalesforceIntegrationSelector);
    let endpoint = `org/${org}/segment/`;
    if (!isNull(segmentId) && segmentId !== 'new') {
      endpoint = `${endpoint}${segmentId}/clone/`;
    }

    const segmentRequest = {
      ...segmentData,
      use_external_matching: false,
      filterset: formatFiltersetResponse(filterset, org, userId),
    };
    const containsInvalidFilters = filterset.filters.some(
      (filter) => filter.filter.variable==='tech_stack'
    );
    if (containsInvalidFilters) {
      const invalidFilters = ['[Deprecated] Technology Used (Other Sources)'].join(' ');
      throw new Error(`Contains invalid filter: ${invalidFilters}`);
    }

    const hasCMl = yield select(hasCompanyMasterLookupSelector);
    // Lookalike segments created from csvs are LOOKALIKE_ACCOUNTS
    // and should not use crm id matching. (external types are LOOKALIKE_EXTERNAL)
    // External (id) matching only available for salesforce integrations.
    if (hasCMl && segmentData.segment_type !== LOOKALIKE_ACCOUNTS && hasSalesforce) {
      const crmDataType = yield select(crmDataTypeSelector);
      segmentRequest.crm_data_type = crmDataType;
      segmentRequest.use_external_matching = true;
    }
    delete segmentRequest.edited_by_id;

    const segment = yield call(request, endpoint, 'POST', {
      body: JSON.stringify(segmentRequest),
    });

    // clear fetchable segments so this newly saved one appears in the filter select w/o refresh
    yield call(createAndUpdateClassifications, request, { ...action, segmentId: segment.id });
    yield put(clearColumn('in_segment'));
    yield put(clearColumn('not_in_segment'));
    yield put(unstageNewSegmentData());
    yield put(showNotification('success', 'Segment Successfully Saved'));
    yield put(createSegmentSuccess(segment));
  } catch (e) {
    yield put(showNotification(
        'error',
        'Segment could not be created. There is likely an issue in your filters.'
      )
    );
    yield put(createSegmentFail(e));
  }
}

function* createAndUpdateClassifications(request, action) {
  const { type, segmentId } = action;
  const segment = yield select(segmentSelector);
  const segFolder = yield select(folderToCreateSelector);
  const allFolders = yield select(segFoldersSelector);
  const segTags = yield select(tagsToCreateSelector);
  const allTags = yield select(segTagsSelector);
  const orgId = yield select(orgSelector);
  const userId = yield select(userSelector);
  const viewFolder = allFolders.filter((folder) => folder.name === segFolder)[0];
  let folderResponse = [];
  let tagResponse = [];
  if (type === UPDATE_SEGMENT) {
    folderResponse = generateFolderBody(segment, segmentId, userId, segFolder, allFolders, true);
    tagResponse = generateTagBodyUpdate(segment, userId, segTags, allTags);
  } else {
    folderResponse = generateFolderBody(segment, segmentId, userId, segFolder, allFolders, false);
    tagResponse = generateTagBodyCreate(segmentId, userId, segTags, allTags);
  }
  const fullResponse = folderResponse.concat(tagResponse);
  for (const values in fullResponse) {
    if (fullResponse[values].edited_by) {
      delete fullResponse[values].edited_by;
    }
  }
  const endpoint = `org/${orgId}/classification/`;
  try {
    yield call(request, endpoint, 'POST', { body: JSON.stringify(fullResponse) });
    yield put(setViewFolder(viewFolder));
    yield put(setViewBy('all'));
  } catch (e) {
    yield put(
      showNotification(
        'error',
        'Segment could not be saved. There was an issue updating folder/tags.'
      )
    );
    if (type === UPDATE_SEGMENT) {
      yield put(updateSegmentFail(e));
    } else {
      yield put(createSegmentFail(e));
    }
  }
}

function* updateSegment(request, action) {
  const { id, segmentData } = action;
  try {
    const org = yield select(orgSelector);
    const userId = yield select(userSelector);
    const filterset = yield select(filtersetSelector);
    const unchangedFilterset = yield select(unchangedFiltersetSelector);
    const filtersConfig = yield select(filtersConfigSelector);
    const endpoint = `org/${org}/segment/${id}/`;
    const segmentRequest = {
      ...segmentData,
    };
    const containsInvalidFilters = filterset.filters.some(
      (filter) => filter.filter.variable==='tech_stack'
    );
    if (containsInvalidFilters) {
      const invalidFilters = ['[Deprecated] Technology Used (Other Sources)'].join(' ');
      throw new Error(`Contains invalid filter: ${invalidFilters}`);
    }
    if (!unchangedFilterset) {
      segmentRequest.filterset = formatFiltersetResponse(filterset, org, userId);
    }
    const hasCML = yield select(hasCompanyMasterLookupSelector);
    if (hasCML && segmentData.segment_type !== LOOKALIKE_ACCOUNTS) {
      segmentRequest.use_external_matching = true;
    }
    const segment = yield call(request, endpoint, 'PATCH', {
      body: JSON.stringify(segmentRequest),
    });
    // Segment is currently published for GTM
    if (segmentData.tags.some((tag) => tag.segment_tag_id === 4 && !tag.is_deleted)) {
      if (!isValidGTMSegment(filterset, segmentData.segment_type, filtersConfig)) {
        yield call(unPublishGTMSegment, request, action);
      }
    }
    yield call(createAndUpdateClassifications, request, { ...action, segmentId: segment.id });
    yield put(updateSegmentSuccess(segment));
    yield put(showNotification('success', 'Segment Successfully Updated'));
  } catch (e) {
    yield put(
      showNotification(
        'error',
        'Segment could not be saved. There is likely an issue in your filters.'
      )
    );
    yield put(updateSegmentFail(e));
  }
}

function* unPublishGTMSegment(request, action) {
  const orgId = yield select(orgSelector);
  const userId = yield select(userSelector);
  const segmentId = action.id;
  const tags = action.segmentData.tags.filter((tag) => !tag.is_deleted);
  const endpoint = tags.length === 1 ? `org/${orgId}/segment/unpublish_segment/` :
    `org/${orgId}/segment/update_published_tags/`;
  const body = {
    body: JSON.stringify({
      edited_by_id: userId,
      segment_id: segmentId,
      tags: [4],
      checked: false,
    }),
  };
  try {
    yield call(request, endpoint, 'PATCH', body);
  } catch (e) {
    yield put(
      showNotification(
        'error',
        'Segment could not be saved. There was an issue unpublishing GTM status.'
      )
    );
    yield put(updateSegmentFail(e));
  }
}

export function* fetchConfigs(request) {
  try {
    const org = yield select(orgSelector);
    const endpoint = `query/${org}/account/`;
    const options = yield call(request, endpoint, 'OPTIONS');
    const isPredictive = yield select(isPredictiveSelector);
    const {
      actions: { POST },
    } = options;

    // Collect any lazy-loaded fields and load them before we mark the filter config as loaded.
    const lazyFilterFields = Object.values(POST).filter(
      (filterField) => filterField.is_lazy
    ).map((filterField) => filterField.column);
    const partialConfig = yield loadFilterConfig(
      request,
      lazyFilterFields,
      false /* updateConfig */
    );

    const config = processFiltersConfig(POST);

    if (!isPredictive && config.status) {
      config.status.disabled = true;
    }

    const templateEndpoint = `org/${org}/segment_template/`;
    const templateResponse = yield call(request, templateEndpoint);

    const { global_templates, org_templates } = templateResponse;

    const templateData = cloneDeep(global_templates.results);

    if (org_templates && org_templates.results) {
      org_templates.results.forEach(
        (template) => {
          const clonedTemplate = cloneDeep(template);
          templateData[template.id] = clonedTemplate;
        }
      );
    }

    yield put(loadFiltersConfigSuccess({ ...config, ...partialConfig }, templateData));
  } catch (e) {
    yield put(loadFiltersConfigFail(e));
  }
}

function* loadSegFolders(request) {
  const limit = MAX_FOLDERS_LIMIT;
  const orgId = yield select(orgSelector);
  const endpoint =
    `org/${orgId}/classification/?classification_type=segment_folder&limit=${limit}`;
  try {
    const orgFolders = yield call(request, endpoint, 'GET');
    const unSortedFolder = orgFolders.results.filter(
      (folder) => folder.classification_type === UNSORTED_FOLDER
    );
    const folderToCreate = unSortedFolder[0].name;
    yield put(setFolderToCreate(folderToCreate));
    yield put(getSegFoldersSuccess(orgFolders.results));
  } catch (e) {
    yield put(getSegFoldersFail(e.toString()));
  }
}

function* loadSegTags(request) {
  const orgId = yield select(orgSelector);
  const endpoint = `org/${orgId}/classification/?classification_type=tag&limit=500`;
  try {
    const orgTags = yield call(request, endpoint, 'GET');
    const updatedResults = orgTags.results.map((tag) => {
      const origName = tag.name;
      return {
        ...tag,
        newName: origName,
      };
    });
    yield put(getSegTagsSuccess(updatedResults));
  } catch (e) {
    yield put(getSegTagsFail(e.toString()));
  }
}

export function* loadFilterConfig(request, apiNames, updateConfig = true) {
  const fieldsParam = apiNames.map((name) => encodeURIComponent(name)).join(',');
  const org = yield select(orgSelector);
  const endpoint = `query/${org}/account/filters/?field=${fieldsParam}`;
  const loadedConfig = yield call(request, endpoint);
  const partialConfig = processFiltersConfig(loadedConfig);
  if (updateConfig) {
    yield put(actions.updateFiltersConfig(partialConfig));
  }
  return partialConfig;
}

function* addAstFilter(request, action) {
  const { astKey, filterConfig } = action;
  const breadcrumbs = [astKey, 'ast'];
  const ast = yield select(astSelector(astKey));
  const replaceExpr = (nextNode) => {
    if (ast.type !== NULLEXPR) {
      return astActions.replaceExpr(astKey)(And(ast, nextNode), breadcrumbs);
    }
    return astActions.replaceExpr(astKey)(nextNode, breadcrumbs);
  };

  let finalFilterConfig = filterConfig;

  if (filterConfig.is_lazy) {
    const loadingNode = filterConfig.is_date_dependent ? And(
      TYPE_CONSTRUCTOR_MAP[EQOP](
        VarExpr(filterConfig.column, { loading: true }),
        Const(''),
      ),
      firstDate(ast),
      orderNext(ast),
    ) : TYPE_CONSTRUCTOR_MAP[EQOP](
      VarExpr(filterConfig.column, { loading: true }),
      Const(''),
      orderNext(ast),
    );
    yield put(replaceExpr(loadingNode));
    yield loadFilterConfig(request, [filterConfig.column]);
    const filtersConfig = yield select(filtersConfigSelector);
    finalFilterConfig = filtersConfig[filterConfig.column];
  }

  const {
    is_date_dependent,
    default_operator,
    newColumnName,
    newColumnValue,
    newColumnConfig,
  } = generateNodeConfig(finalFilterConfig);

  const newNode = is_date_dependent
    ? And(
      TYPE_CONSTRUCTOR_MAP[default_operator](
        VarExpr(newColumnName, newColumnConfig),
        Const(newColumnValue),
      ),
      firstDate(ast),
      orderNext(ast),
    )
    : TYPE_CONSTRUCTOR_MAP[default_operator](
      VarExpr(newColumnName, newColumnConfig),
      Const(newColumnValue),
      orderNext(ast),
    );

  yield put(replaceExpr(newNode));
}

// this saga is exported so it can be called directly from orchestration's
// segment details saga. createorch/sagas/segmentDetails
//
// we only want to call this saga when required, and yield the saga instead of the
// triggering action as to ensure the config has loaded before formatting.
export function* fetchSegmentData(request) {
  try {
    yield put(loadFiltersConfig());
    yield call(fetchConfigs, request);
    yield call(loadSegFolders, request);
    yield call(loadSegTags, request);
    yield call(loadSegmentTags, request);
    yield put(loadSegmentDataSuccess());
  } catch (error) {
    yield put(loadSegmentDataFail());
  }
}

function* actionSuccess() {
  yield put(setEmptyFilterset());
  const route = yield select(routePathnameSelector);
  if (includes(route, 'segments/manage')) {
    // TODO: this goes away in named accounts v2
    yield put(changeManageModalVisibility(false));
  } else {
    yield put(changeSegmentModalVisibility(false));
  }
  destroyAllParamsNavigate('/segments/manage');
}


function* watchLoadSegmentData(request) {
  yield takeLatest(LOAD_SEGMENT_DATA, fetchSegmentData, request);
}

function* watchCreateSegment(request) {
  yield takeLatest(CREATE_SEGMENT, createSegment, request);
}

function* watchUpdateSegment(request) {
  yield takeLatest(UPDATE_SEGMENT, updateSegment, request);
}

function* watchAddAstFilter(request) {
  yield takeLatest(ADD_AST_FILTER, addAstFilter, request);
}

function* watchGetFolders(request) {
  yield takeLatest(GET_SEG_FOLDERS, loadSegFolders, request);
}

function* watchGetTags(request) {
  yield takeLatest(GET_SEG_TAGS, loadSegTags, request);
}

function* watchLoadFiltersConfig(request) {
  yield takeLatest(LOAD_FILTERS_CONFIG, fetchConfigs, request);
}

function* watchActionSuccess() {
  yield takeLatest(
    [CREATE_SEGMENT_SUCCESS, UPDATE_SEGMENT_SUCCESS, CLONE_SEGMENT_SUCCESS],
    actionSuccess,
  );
}

export default [
  fetchSaga(watchLoadSegmentData),
  fetchSaga(watchCreateSegment),
  fetchSaga(watchUpdateSegment),
  fetchSaga(watchAddAstFilter),
  fetchSaga(watchGetFolders),
  fetchSaga(watchGetTags),
  watchActionSuccess,
  fetchSaga(watchLoadFiltersConfig),
];
