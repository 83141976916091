import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip2, Row } from 'v2-components';
import { debounce } from 'lodash';
import styles from './HeaderSearchBar.module.scss';

/*
  PLEASE READ
  This component is the new search bar as designed for
  part of the platform header redesign project. At the moment,
  this search bar is only to be used as part of page headers due
  to the extra space it takes up. Please use the normal searchbar
  component if your searchbar is not part of a page header.
*/

class HeaderSearchBar extends Component {
  static defaultProps = {
    wait: 200,
  }
  static propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    BeforeIcon: PropTypes.element,
    onClickInput: PropTypes.func,
    disabled: PropTypes.bool,
    wait: PropTypes.number,
    allowClear: PropTypes.bool,
    clear: PropTypes.func,
    onSubmit: PropTypes.func,
    tooltipText: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    clearOnClose: PropTypes.bool,
  }
  static defaultProps = {
    wait: 200,
    onChange: () => {},
    tooltipPlacement: 'bottom',
  }

  constructor(props) {
    super(props);
    this.state = {
      searchBarVisible: false,
      focused: false,
      isHovered: false,
      text: props.value,
    };
    this.textInput = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    const { value: newValue } = nextProps;
    if (newValue !== this.state.text) {
      this.setState({ text: newValue });
    }
  }

  _debouncedOnChange = debounce((text) => this.props.onChange(text), this.props.wait);

  handleOnChange = (e) => {
    const newText = e.target.value;
    this.setState({ text: newText }, () => this._debouncedOnChange(newText));
  };

  defaultHandleClear = () => {
    this.setState({ text: '' });
    if (this.props.onSubmit && this.props.value) {
      this.props.onSubmit('');
    } else {
      this._debouncedOnChange('');
    }
    this.textInput.current.focus();
  }

  handleOnKeydown = (e) => {
    if (this.props.onSubmit && e.key === 'Enter') {
      this.props.onSubmit(e.target.value);
    }
  }

  toggleShowSearch = (searchBarVisible = !this.state.searchBarVisible) => {
    if (this.props.clearOnClose && !searchBarVisible) {
      this.props.onChange('');
    }
    this.setState({
      searchBarVisible,
    });
  };

  toggleFocused = (focused) => (
    this.setState({
      searchBarVisible: focused === undefined ? !this.state.focused : focused,
    }));

  handleHover = (isHovered) => (
    this.setState({
      isHovered,
    })
  )


  render() {
    const {
      placeholder,
      className,
      containerClassName,
      BeforeIcon,
      onClickInput,
      disabled,
      allowClear,
      clear,
      tooltipText,
      tooltipPlacement,
    } = this.props;

    let classText = `${styles.container} ${containerClassName}`;
    if (disabled) {
      classText += ` ${styles.disabledStyle}`;
    }

    const { text } = this.state;
    const canClear = allowClear && text && text.length;

    const iconStyle = this.props.onSubmit ? styles.submitIconContainer : styles.iconContainer;
    const closeText = 'Close Search';

    // debugger;

    return (
      <Row>
        {this.state.searchBarVisible && (
          <div className={classText}>
            <Icon
              className={`${styles.icon} ${canClear ? styles.cursor : undefined}`}
              type={canClear ? 'clear' : 'search'}
              onClick={() => {
                if (canClear) {
                  if (clear) {
                    clear();
                  } else {
                    this.defaultHandleClear();
                  }
                }
              }}
            />
            <input
              type="text"
              placeholder={placeholder}
              value={text}
              onChange={this.handleOnChange}
              onKeyDown={this.handleOnKeydown}
              className={`${styles.inputBox} ${className}`}
              onClick={onClickInput}
              disabled={disabled}
              autoFocus
              ref={this.textInput}
              onBlur={this.handleBlur}
            />
            {BeforeIcon && BeforeIcon}
          </div>
        )}
        <div
          className={
            `${iconStyle} ${this.state.searchBarVisible ? styles.searchActive : null} ${
             disabled ? styles.disabled : ''}`
          }
          onClick={() => !disabled && this.toggleShowSearch()}
        >
          <Tooltip2
            placement={tooltipPlacement}
            overlay={this.state.searchBarVisible ? closeText : tooltipText}
            overlayClassName={styles.overlay}
            mouseLeaveDelay={0}
            visible={disabled ? undefined : this.state.isHovered}
            onMouseEnter={() => this.handleHover(true)}
            onMouseLeave={() => this.handleHover(false)}
          >
            <Icon
              className={`${styles.searchIcon} ${disabled ? '' : styles.cursor}`}
              type={this.state.searchBarVisible ? 'clear' : 'search'}
            />
          </Tooltip2>
        </div>
      </Row>
    );
  }
}

export default HeaderSearchBar;
