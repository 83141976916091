import React from 'react';
import { EmptyState } from '@sixsense/core/components';

type TCreditErrorDisplayProps = {
  message: string;
}

export const CreditErrorDisplay = ({ message }: TCreditErrorDisplayProps) => (
  <EmptyState
    level={{ illustration: 'NoData', position: 'center' }}
    title={''} // Showing error in title renders a big text which is an overkill
    description={message} // this is subtle
  />);
