import React from 'react';
import Flowchart from './Flowchart';
import { get, toLower, isEmpty, toPairs } from 'lodash';
import { EmptyState, Flex, Text, Skeleton } from '@sixsense/core/components';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import {
  EVENT_WEB,
  EVENT_MAP,
  EVENT_CRM,
  EVENT_CRM_EVENT,
  EVENT_CRM_TASK,
  DATA_SOURCE_FLAG_NAMES,
} from '../constants';
import { actions as globalActions } from 'modules/global';
import {
  crmTypeSelector,
  mapTypeSelector,
  orgFlagsSelector,
} from 'modules/user/selectors';
import {
  allMissingObjectsSelector,
  isSingleProductSelector,
  loadPreppedTableSchemaLoadingSelector,
} from '../selectors';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DesignSystemThemeHoc } from './DesignSystemThemeHOC';

const DATASOURCE_NAME_MAPPING = {
  [EVENT_WEB]: 'Website URL',
  [EVENT_MAP]: 'Map',
  [EVENT_CRM]: 'CRM (Campaign)',
  [EVENT_CRM_EVENT]: 'CRM (Event)',
  [EVENT_CRM_TASK]: 'CRM (Task)',
};

const LoadingState =
  // eslint-disable-next-line react/prop-types
  ({ missingObjects = {}, dataSource, loading }) => {
    if (loading) {
      return (
        <Skeleton
          style={{ height: '90vh', width: '90vw' }}
          variant="rectangular"
        />
      );
    }
    if (!isEmpty(missingObjects)) {
      return (
        <Flex justifyContent="center">
          <EmptyState
            v6
            level="page"
            orientation="vertical"
            illustration="failure"
            title="Access is needed"
            description={
              <Flex
                direction="column"
                style={{ width: '100%' }}
                alignItems="center"
              >
                <Text.Body>
                  The system needs access to below objects to populate taxonomy
                  data mappings for {DATASOURCE_NAME_MAPPING[dataSource]}.
                </Text.Body>
                <Text.Body>
                  Please provide the access or contact our PC
                </Text.Body>
                <br />
                {toPairs(missingObjects).map(([key, tables]) => (
                  <Flex direction="column" key={key}>
                    {tables.map(({ table, columns }) => (
                      <Flex key={table} direction="column" alignItems="center">
                        {/*
                         * If table not found show message for that
                         * else check for columns and show specific columsn
                         */}
                        {isEmpty(columns) ? (
                          <Flex>
                            <Text.Body>Table not found:</Text.Body>&nbsp;
                            <Text.Body weight="semibold">{table}</Text.Body>
                          </Flex>
                        ) : (
                          <Flex>
                            <Text.Body>
                              Access not provided in {table} for column(s):{' '}
                            </Text.Body>
                            <Text.Body weight="semibold">
                              {columns.join(', ')}
                            </Text.Body>
                          </Flex>
                        )}
                      </Flex>
                    ))}
                  </Flex>
                ))}
              </Flex>
            }
          />
        </Flex>
      );
    }
    return (
      <Flex justifyContent="center" style={{ width: '80vw' }}>
        <EmptyState
          v6
          level="page"
          orientation="vertical"
          illustration="progress"
          title="Data sync is in progress"
          description={
            <Flex direction="column">
              <Text>Taxonomy mappings will be available soon</Text>
              <Text>
                Please visit the &nbsp;
                <Link
                  href="/settings/integration/manage"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Integrations
                </Link>
                &nbsp;tab to track the progress
              </Text>
            </Flex>
          }
        />
      </Flex>
    );
  };

const DataSourceComponent = ({
  showGlobalNotFound,
  crmType,
  mapType,
  isSingleProduct,
  orgFlags,
  allMissingObjects,
  preppedTableSchemaLoading,
  ...props
}) => {
  const dataSource = toLower(get(props, ['params', 'dataSource']));

  if (
    ![
      EVENT_WEB,
      EVENT_MAP,
      EVENT_CRM,
      EVENT_CRM_EVENT,
      EVENT_CRM_TASK,
    ].includes(dataSource)
  ) {
    showGlobalNotFound(true);
  }

  const dataSyncInProgress = !get(orgFlags, DATA_SOURCE_FLAG_NAMES[dataSource]);

  if (
    (dataSource.startsWith(EVENT_CRM) && !crmType) ||
    (dataSource.startsWith(EVENT_MAP) && !mapType)
  ) {
    return (
      <DesignSystemThemeHoc>
        <Flex justifyContent="center" style={{ width: '80vw' }}>
          <EmptyState
            v6
            level="page"
            orientation="vertical"
            illustration="404"
            title={`${DATASOURCE_NAME_MAPPING[dataSource]} not connected`}
            description={
              <Text>
                Please visit the &nbsp;
                <Link
                  href="/settings/integration/manage"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Integrations
                </Link>
                &nbsp; tab to connect your CRM or MAP
              </Text>
            }
          />
        </Flex>
      </DesignSystemThemeHoc>
    );
  }
  let integrationType = EVENT_WEB;
  if (dataSource.startsWith(EVENT_CRM)) {
    integrationType = toLower(crmType);
  } else if (dataSource.startsWith(EVENT_MAP)) {
    integrationType = toLower(mapType);
  }

  if (dataSyncInProgress) {
    return (
      <DesignSystemThemeHoc>
        <LoadingState
          missingObjects={allMissingObjects[dataSource]}
          dataSource={dataSource}
          loading={preppedTableSchemaLoading}
        />
      </DesignSystemThemeHoc>
    );
  }
  return (
    <DesignSystemThemeHoc>
      <Flowchart
        integrationType={integrationType}
        containerName={DATASOURCE_NAME_MAPPING[dataSource]}
        isMultiProduct={!isSingleProduct}
        dataSource={dataSource}
      />
    </DesignSystemThemeHoc>
  );
};

DataSourceComponent.propTypes = {
  showGlobalNotFound: PropTypes.func,
  crmType: PropTypes.string,
  mapType: PropTypes.string,
  isSingleProduct: PropTypes.bool,
  orgFlags: PropTypes.object,
  allMissingObjects: PropTypes.object,
  preppedTableSchemaLoading: PropTypes.bool,
};

const mapDispatchToProps = {
  showGlobalNotFound: globalActions.showGlobalNotFound,
};

const mapStateToProps = (state) => ({
  crmType: crmTypeSelector(state),
  mapType: mapTypeSelector(state),
  isSingleProduct: isSingleProductSelector(state),
  orgFlags: orgFlagsSelector(state),
  allMissingObjects: allMissingObjectsSelector(state),
  preppedTableSchemaLoading: loadPreppedTableSchemaLoadingSelector(state),
});

export const DataSource = compose(connect(mapStateToProps, mapDispatchToProps))(
  DataSourceComponent
);
