import { pxToRem } from '@sixsense/core/style';
import { capitalize, omit } from 'lodash';
import moment from 'moment';

import {
  EVENT, EVENT_CRM, EVENT_CRM_EVENT,
  EVENT_CRM_TASK, EVENT_MAP, EVENT_WEB,
  LAST_30_DAYS,
  LAST_60_DAYS,
  LAST_7_MONTHS,
  LAST_90_DAYS,
  PRODUCT,
  PRODUCT_CRM, PRODUCT_MAP, PRODUCT_WEB, ROOT_FONT_SIZE,
  TAB_LABEL_CRM, TAB_LABEL_CRM_CAMPAIGN, TAB_LABEL_CRM_EVENT,
  TAB_LABEL_CRM_TASK, TAB_LABEL_MAP, TAB_LABEL_WEB,
} from './constants';

export const loaderPresets = {
  loading: false,
  loaded: false,
  error: false,
  errorStatus: undefined,
  errorMessage: undefined,
};

export const setPayload = (state, action) => {
  const payload = omit(action, 'type');
  return { ...state, ...payload };
};

export const pxToRemUtil = (px) => pxToRem(px, ROOT_FONT_SIZE);

export function coaleseNull(value) {
  if (value === undefined || value === null) {
    return null;
  }
  return value;
}

export function getDateRanges(dateRange) {
  if (dateRange === LAST_7_MONTHS) {
    return undefined;
  }
  const today = moment().format('YYYY-MM-DD');
  let prevDate = '';
  switch (dateRange) {
    case LAST_30_DAYS:
      prevDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
      break;
    case LAST_60_DAYS:
      prevDate = moment().subtract(60, 'days').format('YYYY-MM-DD');
      break;
    case LAST_90_DAYS:
      prevDate = moment().subtract(90, 'days').format('YYYY-MM-DD');
      break;
    case LAST_7_MONTHS:
      prevDate = moment().subtract(7, 'months').format('YYYY-MM-DD');
      break;
    default:
      break;
  }
  return { to: today, from: prevDate };
}

export function getTabLabel(data_source, classification_type) {
  if (classification_type === EVENT) {
    switch (data_source.toLowerCase()) {
      case EVENT_WEB: return TAB_LABEL_WEB;
      case EVENT_MAP: return TAB_LABEL_MAP;
      case EVENT_CRM: return TAB_LABEL_CRM_CAMPAIGN;
      case EVENT_CRM_EVENT: return TAB_LABEL_CRM_EVENT;
      case EVENT_CRM_TASK: return TAB_LABEL_CRM_TASK;
      default: return null;
    }
  } else if (classification_type === PRODUCT) {
    switch (data_source.toLowerCase()) {
      case PRODUCT_WEB: return TAB_LABEL_WEB;
      case PRODUCT_MAP: return TAB_LABEL_MAP;
      case PRODUCT_CRM: return TAB_LABEL_CRM;
      default: return null;
    }
  }
  return null;
}

export function getName(mail) {
  try {
    // first.last@6sense.com => First Last
    return mail.split('@')[0].split('.').map((t) => capitalize(t)).join(' ');
  } catch (e) {
    return mail;
  }
}

export function formatDate(dateString) {

  const date = moment(dateString);

  let formattedDate;
  if (date.isSame(moment(), 'day')) {
    formattedDate = 'Today';
  } else if (date.isSame(moment().subtract(1, 'day'), 'day')) {
    formattedDate = 'Yesterday';
  } else {
    formattedDate = date.format('D MMM, YYYY');
  }
  return formattedDate;
}
