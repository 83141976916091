import React from 'react';
import { Text, Row, SixSenseLogo } from 'v2-components';
import styles from './Maintenance.module.scss';

const Maintenance = () => (
  <Row
    className={styles.container}
    flexDirection={'column'}
    alignItems={'center'}
  >


    <SixSenseLogo className={styles.logo} />
    <Text type={Text.TYPE.TITLE}>
      Scheduled Maintenance
    </Text>


    <i className={`fa fa-cogs fa-5x ${styles.icon}`} />

    <Text>
      We're performing some upgrades to your account on the
    </Text>
    <Text>
      ABM Orchestration Platform, and should be back shortly.
    </Text>
  </Row>
);

export default Maintenance;
