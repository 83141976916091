import NewContacts from './routes/NewContacts';
import ConfigCFN from './routes/Config';
import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer } = getAsyncInjectors(store);
  return {
    path: 'CFN',
    name: 'Contacts For Nurture',
    indexRoute: {
      onEnter: (nextState, replace) => replace('CFN/new_contacts'),
    },

    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const reducer = require('./modules');
          injectReducer('CFN', reducer.default);
          cb(null, containers.CFNContainer);
        },
        'settings_CFN',
      );
    },
    childRoutes: [NewContacts(store), ConfigCFN(store)],
  };
}
