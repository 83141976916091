import React from 'react';
import { Flex, Skeleton } from '@sixsense/core/components';
import { SkeletonVariant } from '@sixsense/core/components/Skeleton/constant';
import { times } from 'lodash';

export const CreditFetcherSkeleton = () => (
  <Flex direction="column" gap={30}>
    {times(3).map(() => <Skeleton
      variant={SkeletonVariant.RECTANGULAR}
      style={{
        height: '150px',
        width: '100%',
      }}
    />)}
  </Flex>
);
