import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';
import { createSelector } from 'reselect';
import { orgSelector } from 'modules/user/selectors';

export const utmMacrosListStateGenerator = stateGenerator({
  actionPrefix: 'UTM_MACROS',
  endpointSelector: createSelector(
    orgSelector,
    (orgId) => `org/${orgId}/utm_macros/`,
  ),
  receivedDataTransform:
    (data) => data.map(({ value, info }) => ({
      label: value,
      value,
      info,
    })),
  stateKey: 'utmMacros',
  initialData: {},
  method: 'GET',
});
