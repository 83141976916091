import { fetchSaga } from 'store/sagas';
import { call, all, put, select, takeLatest } from 'redux-saga/effects';
import { getFormValues, reset } from 'redux-form';
import { VALID_DATA_SOURCES } from '../../../constants';
import { orgSelector, userObjectSelector } from 'modules/user/selectors';
import { toaster } from '@sixsense/core';
import {
  loadCoverageThresholdGenerator,
  loadAssigneeGenerator,
  INITIALIZE_COVERAGE_THRESHOLD,
 } from '../stateGenerators';
import {
  loadRecordsReviewStatusCountGenerator,
 } from '../../../stateGenerators';
import { differenceWith, isEqual, isEmpty } from 'lodash';
import { adminSelector, thresholdCoverageFormDataSelector } from '../selectors';
import { initializeAssigneeForm } from '../utils';

import { actionTypes, actions } from '../modules';

const {
  SAVE_THRESHOLD_COVERAGE_REQUEST,
  SAVE_ADMIN,
  SAVE_ASSIGNEES,
  ON_CANCEL,
} = actionTypes;
const {
  saveThresholdCoverageFailure,
  saveThresholdCoverageSuccess,
  saveAssigneeFailure,
  saveAssigneeSuccess,
  setCoverageThresholdFormValues,
  changeAdminAssignee,
}= actions;

export function* saveCoverageThresholdSaga(request) {
  try {
    const orgId = yield select(orgSelector);
    const { username } = yield select(userObjectSelector);
    const formValues = yield select(thresholdCoverageFormDataSelector);
    const values = yield select(loadCoverageThresholdGenerator.dataSelector);
    const oldValues = [];
    const requestArray = values.reduce((acc, obj) => {
      const ds = formValues.find((d) => d.data_source === obj.data_source);
      if (ds.value !== obj.value) {
        acc.push(
          call(request,
            `turbotax/taxonomy/org/${orgId}/` +
            `turbotax_coverage_threshold/${obj.id}/`,
            'PATCH', {
              body: JSON.stringify({
                value: ds.value,
                updated_by: username,
              }),
            })
        );
      } else {
        oldValues.push(obj);
      }
      return acc;
    }, []);
    if (!isEmpty(requestArray)) {
      const response = yield all(requestArray);
      const coverage = oldValues.concat(response);
      yield put(saveThresholdCoverageSuccess(coverage));
      yield put(loadRecordsReviewStatusCountGenerator.loadAction());
      toaster.showSuccess('Coverage Threshold saved successfully!');
    } else {
      yield put(saveThresholdCoverageSuccess(oldValues));
    }
  } catch (error) {
    toaster.showError('Error occurred in saving coverage thresholds, please try again!');
    yield put(saveThresholdCoverageFailure(error.errorMessage));

  }
}

function* saveAdminSaga(request) {
  const allAssignees = yield select(loadAssigneeGenerator.dataSelector);
  const orgId = yield select(orgSelector);
  const { username } = yield select(userObjectSelector);
  const currentAdmin = allAssignees.find((assignee) => assignee.data_source === 'all');
  const newAdmin = yield select(adminSelector);
  try {
    // create new admin,
    if (!currentAdmin) {
      const requestBody = {
        org_id: orgId,
        data_source: 'all',
        created_by: username,
        name: newAdmin,
      };
      const response = yield call(
        request,
        `turbotax/taxonomy/org/${orgId}/turbotax_assignee/`, 'POST', {
          body: JSON.stringify(requestBody),
        });
      const assignees = [...allAssignees, response];
      toaster.showSuccess('Admin saved successfully!');
      yield put(saveAssigneeSuccess(assignees));
    } else if (newAdmin !== currentAdmin.name) {
      const requestBody = { name: newAdmin, updated_by: username };

      const response = yield call(
        request,
        `turbotax/taxonomy/org/${orgId}/turbotax_assignee/${currentAdmin.id}/`, 'PATCH', {
          body: JSON.stringify(requestBody),
        });
      const assignees = allAssignees.map((assignee) => {
        if (assignee.data_source === 'all') {
          // eslint-disable-next-line no-param-reassign
          assignee = response;
        }
        return assignee;
      });
      toaster.showSuccess('Admin saved successfully!');
      yield put(saveAssigneeSuccess(assignees));
    }
  } catch (error) {
    toaster.showError('Error occurred in saving admin, please try again');
    yield put(saveAssigneeFailure(error.errorMessage));
  }
}

function* saveAssigneesSaga(request) {
  try {
    const formValues = yield select(getFormValues('form_assignee'));
    const orgId = yield select(orgSelector);
    const allAssignees = yield select(loadAssigneeGenerator.dataSelector);
    const currentAdmin = allAssignees.find((assignee) => assignee.data_source === 'all');
    const { username } = yield select(userObjectSelector);
    const values = VALID_DATA_SOURCES.reduce((acc, data_source) => {
      const currentAssignees = allAssignees.filter((assignee) =>
      assignee.data_source === data_source);
      const newAssignees = formValues[data_source] || [];
      const deletedEntries = currentAssignees.filter(
      (assignee) => !newAssignees.includes(assignee.name));
      const unchangedEntries = differenceWith(currentAssignees, deletedEntries, isEqual);
      const currentAssigneeValues = currentAssignees.map((assignee) => assignee.name);
      const newEntries = newAssignees
      .filter((assignee) => !currentAssigneeValues.includes(assignee));
      acc[data_source] = { deletedEntries, newEntries, unchangedEntries };
      return acc;
    }, {});
    const requests = VALID_DATA_SOURCES.reduce((acc, data_source) => {
      const { deletedEntries, newEntries, unchangedEntries } = values[data_source];
      const deletedRequests = deletedEntries.map((entry) => call(
            request,
            `turbotax/taxonomy/org/${orgId}/turbotax_assignee/${entry.id}/`, 'DELETE'));
      acc.deletedRequests.push(...deletedRequests);
      const newRequests = newEntries.map((entry) => {
        const requestBody = {
          org_id: orgId,
          data_source,
          created_by: username,
          name: entry,
        };
        return call(
          request,
          `turbotax/taxonomy/org/${orgId}/turbotax_assignee/`, 'POST', {
            body: JSON.stringify(requestBody),
          });
      });
      acc.newRequests.push(...newRequests);
      acc.unchanged.push(...unchangedEntries);
      return acc;
    }, { deletedRequests: [], newRequests: [], unchanged: [] });

    if (!isEmpty(requests.newRequests) || !isEmpty(requests.deletedRequests)) {
      const newResponse = yield all(requests.newRequests);
      yield all(requests.deletedRequests);
      const finalAssignees = [...newResponse, ...requests.unchanged];
      if (currentAdmin) {
        finalAssignees.push(currentAdmin);
      }
      toaster.showSuccess('Assignees saved successfully!');
      yield put(saveAssigneeSuccess(finalAssignees));
      yield put(initializeAssigneeForm(finalAssignees));
    }
  } catch (error) {
    toaster.showError('Error occurred in saving assignees, please try again');
    yield put(saveAssigneeFailure(error.errorMessage));

  }
}

function* onCancelSaga() {
  yield put(reset('form_assignee'));
  const data = yield select(loadCoverageThresholdGenerator.dataSelector);
  yield put({ type: INITIALIZE_COVERAGE_THRESHOLD, data });
  const assignee = yield select(loadAssigneeGenerator.dataSelector);
  const admin = assignee.find((assignees) => assignees.data_source === 'all');
  yield put(changeAdminAssignee(admin.name || ''));
}

function* initializeCoverageThresholdSaga(args) {
  const processedData = VALID_DATA_SOURCES.reduce((acc, data_source) => {
    const ds = args.data.find((d) => d.data_source === data_source);
    acc.push({ data_source, value: ds.value });
    return acc;
  }, []);
  yield put(setCoverageThresholdFormValues(processedData));
}


function* watchSaveCoverageThreshold(request) {
  yield takeLatest([SAVE_THRESHOLD_COVERAGE_REQUEST], saveCoverageThresholdSaga, request);
}

function* watchSaveAdmin(request) {
  yield takeLatest([SAVE_ADMIN], saveAdminSaga, request);
}

function* watchSaveAssignee(request) {
  yield takeLatest([SAVE_ASSIGNEES], saveAssigneesSaga, request);
}

function* watchOnCancel() {
  yield takeLatest([ON_CANCEL], onCancelSaga);
}

function* watchInitializeCoverageThreshold() {
  yield takeLatest(INITIALIZE_COVERAGE_THRESHOLD, initializeCoverageThresholdSaga);
}

export const preferencesSagas = [
  loadCoverageThresholdGenerator.saga,
  loadAssigneeGenerator.saga,
  fetchSaga(watchSaveCoverageThreshold),
  fetchSaga(watchSaveAdmin),
  fetchSaga(watchSaveAssignee),
  watchOnCancel,
  watchInitializeCoverageThreshold,
];
