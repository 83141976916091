export default function createRoutes() {
  return {
    path: 'product-url-configuration',
    name: 'Product URL Configuration',
    getComponent(nextState, cb) {
      require.ensure(
          [],
          (require) => {
            const container = require('./containers/ProductURLConfigContainer');
            cb(null, container.ProductURLConfigContainer);
          },
          'product-url-configuration',
        );
    },
  };
}
