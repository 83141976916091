import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber as AntInputNumber } from 'antd';
import FormComponent from '../../v2-HOCS/FormComponent';

function InputNumber(props) {
  const { children, className, reference } = props;

  return (
    <AntInputNumber ref={reference} {...props} className={className}>
      {children}
    </AntInputNumber>
  );
}

// Refer to AntD modal props
InputNumber.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  reference: PropTypes.func,
};

const inputToProps = (input) => ({ ...input });
InputNumber.form = FormComponent(InputNumber, inputToProps);

export default InputNumber;
