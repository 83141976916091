export const IntegrationState = {
  NOT_STARTED: 'not_started',
  PENDING: 'pending',
  AUTHORIZED: 'authorized',
  EXPIRED: 'expired',
};
export const IntegrationSystem = {
  OUTREACH: 'outreach',
  HUBSPOT: 'hubspot',
};
