import React from 'react';
import styles from './OrchestrationIconStyles.module.scss';
import SlintelLogo from 'images/slintel_logo.png';

type TSlintelProps = {
  className: string;
  size: string;
}

const Slintel = ({ className, size }: TSlintelProps) =>
  (
    <img
      alt={'Slintel Logo'}
      src={SlintelLogo}
      className={`${styles.default} ${className} ${styles[size]}`}
    />
  );

export default Slintel;
