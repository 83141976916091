import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: 'create/:id',
    name: 'createTPR',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        injectReducer('createTPR', reducer.default);
        injectSagas('createTPR', sagas.default);
        cb(null, containers.CreateTalkingPointRules);
      }, 'createListTalkingPointRules');
    },
  };
}
