import React from 'react';
import { EnabledIcon } from '../assets';
import { actions } from '../modules';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { authTypeSelector } from '../selectors';
import { LoadComponent } from 'HOCS';

const EnabledIconWrapperComponent = (props) => {
  const { authType } = props;
  return (
        authType === 'SAML'?
          <EnabledIcon {...props} />
        : null
  );
};

EnabledIconWrapperComponent.propTypes = {
  authType: PropTypes.string,
};

const mapStateToProps = (state) => ({
  authType: authTypeSelector(state),
});

const { loadAuthType } = actions;

export const EnabledIconWrapper = compose(
  connect(mapStateToProps,
     { load: loadAuthType }),
     LoadComponent,
)(EnabledIconWrapperComponent);

