import React from 'react';
import Text from 'v2-components/Text';
import { NA } from './common/NA';
import { CAMPAIGN_STATUS_LABELS, CAMPAIGN_STATUS_X } from '../../../constants';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';
import styles from './status.module.scss';

export const CAMPAIGN_STATUS_STYLES = {
  [CAMPAIGN_STATUS_X.active]: styles.active,
  [CAMPAIGN_STATUS_X.scheduled]: styles.scheduled,
  [CAMPAIGN_STATUS_X.completed]: styles.completed,
  [CAMPAIGN_STATUS_X.draft]: styles.draft,
  [CAMPAIGN_STATUS_X.deactivated]: styles.deactivated,
};

export const STATUS = {
  customizable: true,
  defaultSelected: true,
  key: 'status',
  csvColumnKey: 'status',
  title: <Label name={CAMPAIGN_LABELS.campaignStatus} />,
  width: 110,
  render: (_, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (!campaignStatus) {
      return <NA />;
    }

    return (<Text type={Text.TYPE.SUBBODY} className={CAMPAIGN_STATUS_STYLES[campaignStatus]}>
      {CAMPAIGN_STATUS_LABELS[campaignStatus]}
    </Text>);
  },
};
