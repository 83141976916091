import React from "react";
import { Text } from "@sixsense/core/components";
import { CAMPAIGN_SOURCE_LABELS } from "../../../constants";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const CAMPAIGN_TYPE = {
  customizable: false,
  key: "campaign-type",
  csvColumnKey: "campaign_source",
  title: <Label name={CAMPAIGN_LABELS.campaignType} />,
  dataIndex: "campaign_source",
  width: pxToRem(150),
  render: (campaignSource) => {
    const campaignTypeLabel = CAMPAIGN_SOURCE_LABELS[campaignSource];

    return (
      <Text.Body
        weight="normal"
        color={`var(${TextColor.DEFAULT})`}
        title={campaignSource}
      >
        {campaignTypeLabel}
      </Text.Body>
    );
  },
};
