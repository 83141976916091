/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { HeaderSearchBar, Icon, Button, Text, Link, Tooltip2 as Tooltip } from 'v2-components';
import { css } from 'styles/emotion';
import { Card, Flex, Loading, ResultBoundary } from '@sixsense/core/components';
import { User } from '../types';
import UserList from './UserList';
import {
  authTypeSelector,
  errorMessageSelector,
  formattedUsersSelector,
  usersLoadedSelector,
  allUsersForDownloadSelector,
  isAppEditUserPermissionMapSelector,
  getTotalAppActiveUserCountSelector,
  totalActiveUserCountSelector,
  isIframeUserSelector,
  getIFrameUserCountSelector,
  appsListSelector,
  getEmailsFilterMissingMapSelector,
  getUsernameFilterSelector,
  formattedOwnerSelector,
  licenseResponseSelector,
} from '../selectors';
import { Pagination } from 'antd';
import usePagination from '@sixsense/core/hooks/usePagination';
import {
  AsyncResult,
  ResultStatus,
} from '@sixsense/core/shared/async-result/types';
import { useDispatch, useSelector } from '@sixsense/core/versioned/react-redux';
import { actions as downloadActions } from 'v2-components/Download/modules';
import { orgObjSelector } from 'modules/user/selectors';
import ErrorDisplay from '../components/ErrorDisplay';
import MultiUserActions from './MultiUserActions';
import { SortState } from './types';
import { sortBy, values, flatten, cloneDeep, forEach } from 'lodash';
import { AdvancedSearch } from '../components/AdvancedSearch';
import { ADVANCED_SEARCH_DEFAULT_FILTER } from '../constants';
import { actions } from '../modules';
import { isTwoArrayEqualWithPrimitiveTypeData } from '../utils';
import { classNames } from 'utils/utils';

type Props = {
  loading: boolean;
  errorMessage: string | undefined;
  isSAML: boolean;
  users: User[];
  orgName: string;
  isIframeUser: boolean;
  iFrameUserCount: number | null;
  setEmailsFilterMissingMap: (emailsFilterMissingMap: any) => void;
};

const styles = {
  input: css({
    width: '80% !important',
  }),
  inputContainer: css({
    minWidth: 200,
  }),
  filterButton: css({
    height: '36px',
    width: '150px',
    marginRight: '10px',
    marginLeft: '10px',
  }),
  filterByText: css({
    marginRight: '5px',
    marginLeft: '5px',
  }),
  active: css({
    color: 'white',
  }),
  countPip: css({
    backgroundColor: '#E12C2C',
    color: 'white',
    borderRadius: '50%',
    width: '18px',
  }),
  linkStyle: css({
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
    color: '#0082D4',
    paddingLeft: '17px',
    paddingRight: '17px',
  }),
  appUserCountText: css({
    marginRight: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '18px',
    color: '#001F32',
  }),
  dividerPipe: css({
    width: '2px',
    height: '18px',
    borderRight: '1px solid #505C62',
    display: 'block',
    marginRight: '17px',
  }),
  linkStyleLearnMore: css({
    paddingLeft: '0px',
  }),
  flexCenter: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  tooltip: css({
    color: '#0082d4',
    fontSize: '14px',
    marginLeft: '10px',
    cursor: 'pointer',
  }),
};

const getDisplayCount = (val) => {
  if (val === -1) {
    return '';
  }
  return ` / ${val}`;
};

const AppUserCountTitle = ({
  allowedLicenses,
  totalActiveUsers,
  appShortName,
  licenseLabel,
}: {
  allowedLicenses: any,
  totalActiveUsers: any,
  appShortName: any,
  licenseLabel: any,
}) => (
  <span className={classNames(styles.appUserCountText, styles.flexCenter)}>
    {totalActiveUsers}
    {getDisplayCount(allowedLicenses)} {appShortName}{' '}
    {licenseLabel ? `${licenseLabel} ` : ''}
    Users
    <Tooltip
      overlay={
          `
            Shows the allocated count of Active and Invited users with
            ${appShortName} ${licenseLabel ? `${licenseLabel} ` : ''} roles relative
            to the purchased ${appShortName} ${licenseLabel ? `${licenseLabel} ` : ''}
            licenses
          `
        }
    >
      <span className={styles.flexCenter}>
        <Icon type="info" className={styles.tooltip} />
      </span>
    </Tooltip>
  </span>
);


const UsersCard = ({
  users,
  loading,
  errorMessage,
  isSAML,
  orgName,
  isIframeUser,
  iFrameUserCount,
  setEmailsFilterMissingMap,
}: Props) => {
  const licenseResponse = useSelector(licenseResponseSelector);

  const usernameFilter = useSelector(getUsernameFilterSelector);
  const ownerObject = useSelector(formattedOwnerSelector) || {};

  const [sortState, setSortState] = useState<SortState>({
    column: 'username',
    order: 'asc',
  });

  const appsList = useSelector(appsListSelector);

  const {
    notInSystem: notInSystemPrev = [],
    filteredOut: filteredOutPrev = [],
    paEmail: paEmailPrev,
  } = useSelector(getEmailsFilterMissingMapSelector);

  // eslint-disable-next-line no-undef
  const [selectedUsers, setSelectedUsers] = useState<Set<number>>(new Set());
  const dispatch = useDispatch();
  const canEditAppMap = useSelector(isAppEditUserPermissionMapSelector);

  const [advSearchVisible, setAdvSearchVisible] = useState(false);
  // const [advSearchPillVisibility, setAdvSearchPillVisibility] = useState(true);
  const [filterApplied, setFilterApplied] = useState(
    cloneDeep(ADVANCED_SEARCH_DEFAULT_FILTER.values)
  );

  useEffect(() => {
    setSelectedUsers(new Set());
  }, [users]);

  const filteredUsers = useMemo(
    () =>
      users.filter((user) => {
        const { rolesFilterAppMap, statusFilter, isEmail, emailsListFilter } =
          filterApplied;
        // should we skip this user
        if (statusFilter !== 'all' && user.status !== statusFilter) {
          return false;
        }
        if (
          usernameFilter &&
          !user.username.toLowerCase().includes(usernameFilter.toLowerCase())
        ) {
          return false;
        }
        if (isEmail && emailsListFilter.length > 0) {
          return emailsListFilter.includes(user.username.toLowerCase());
        }
        if (!isEmail) {
          return appsList.every(
            ({ id: appId }) =>
              !rolesFilterAppMap[appId] ||
              rolesFilterAppMap[appId].length === 0 ||
              rolesFilterAppMap[appId].includes(user.roleIdMap[appId])
          );
        }
        return true;
      }),
    [users, filterApplied, usernameFilter, appsList]
  );

  React.useEffect(() => {
    const { isEmail, emailsListFilter } = filterApplied;
    if (isEmail) {
      const allEmailsFilterMissingList = [];
      forEach(
        emailsListFilter.filter((e) => !!e),
        (email) => {
          const isUserMissing = !filteredUsers.find(
            ({ username }) => username === email
          );
          if (isUserMissing) {
            allEmailsFilterMissingList.push(email);
          }
        }
      );

      const notInSystemNew = [];
      const filteredOutNew = [];
      let paEmailNew = null;
      forEach(allEmailsFilterMissingList, (email) => {
        if (email === ownerObject.username) {
          paEmailNew = email;
          return;
        }
        const isNotInSystem = !users.find(({ username }) => username === email);
        if (isNotInSystem) {
          notInSystemNew.push(email);
        } else {
          filteredOutNew.push(email);
        }
      });

      const isNotInSystemChanged = !isTwoArrayEqualWithPrimitiveTypeData(
        notInSystemNew,
        notInSystemPrev
      );
      const isFilteredOutChanged = !isTwoArrayEqualWithPrimitiveTypeData(
        filteredOutNew,
        filteredOutPrev
      );

      const isPaEmailChanged = paEmailNew !== paEmailPrev;

      if (isNotInSystemChanged || isFilteredOutChanged || isPaEmailChanged) {
        setEmailsFilterMissingMap({
          notInSystem: notInSystemNew,
          filteredOut: filteredOutNew,
          paEmail: paEmailNew,
        });
      }
    } else {
      const isNotInSystemChanged = !isTwoArrayEqualWithPrimitiveTypeData(
        [],
        notInSystemPrev
      );
      const isFilteredOutChanged = !isTwoArrayEqualWithPrimitiveTypeData(
        [],
        filteredOutPrev
      );

      if (isNotInSystemChanged || isFilteredOutChanged) {
        setEmailsFilterMissingMap({
          notInSystem: [],
          filteredOut: [],
          paEmail: null,
        });
      }
    }
  }, [filterApplied, usernameFilter]);

  const sortedUsers = useMemo(() => {
    if (!sortState) {
      return filteredUsers;
    }
    let sorted = sortBy(filteredUsers, [sortState.column]);
    if (sortState.order === 'desc') {
      sorted = sorted.reverse();
    }
    return sorted;
  }, [filteredUsers, sortState]);

  const {
    data: pageData,
    page,
    setPage,
  } = usePagination(sortedUsers, { pageSize: 10 });

  useEffect(() => {
    // When any filters are applied reset the list to the first page.
    setPage(1);
  }, [filterApplied, usernameFilter]);

  let result: AsyncResult<User[]> = {
    status: ResultStatus.LOADED,
    data: pageData,
  };
  if (loading) {
    result = { status: ResultStatus.LOADING };
  }

  if (errorMessage) {
    result = { status: ResultStatus.ERROR, message: errorMessage };
  }

  const CountPills = () => {
    const totalFilterApplied =
      (filterApplied.statusFilter !== 'all' ? 1 : 0) +
      (filterApplied.isEmail
        ? 1
        : flatten(values(filterApplied.rolesFilterAppMap)).length);
    if (totalFilterApplied !== 0) {
      return <div className={styles.countPip}>{totalFilterApplied}</div>;
    }
    return <React.Fragment>{}</React.Fragment>;
  };

  const getIframeCountRender = () => {
    if (
      isIframeUser &&
      iFrameUserCount !== null &&
      iFrameUserCount !== undefined
    ) {
      return (
        <React.Fragment>
          <span
            className={classNames(styles.appUserCountText, styles.flexCenter)}
          >
            {iFrameUserCount} SI CRM/SEP Users
            <Tooltip
              overlay={`
                Shows the number of Unregistered SI CRM/SEP users who have interacted
                with the SI iframe in CRM or SEP at least once in the last 90 days period
                `}
            >
              <span className={styles.flexCenter}>
                <Icon type="help" className={styles.tooltip} />
              </span>
            </Tooltip>
          </span>
        </React.Fragment>
      );
    }
    return null;
  };

  const getAllAppUserCountTile = () => {
    const appsTitleComp = [];
    let isIframeRendered = false;
    forEach(licenseResponse, ({ licenses, app_name: appShortName }) => {
      // eslint-disable-next-line no-shadow
      forEach(licenses, ({ licenseLabel, users: totalActiveUsers, value }) => {
        appsTitleComp.push(
          <AppUserCountTitle
            licenseLabel={licenseLabel}
            allowedLicenses={value}
            totalActiveUsers={totalActiveUsers}
            appShortName={appShortName}
          />
        );
      });
      if (appShortName === 'SI' && getIframeCountRender()) {
        isIframeRendered = true;
        appsTitleComp.push(getIframeCountRender());
      }
      appsTitleComp.push(<div className={styles.dividerPipe} />);
    });
    if (!isIframeRendered && getIframeCountRender()) {
      appsTitleComp.push(getIframeCountRender());
      appsTitleComp.push(<div className={styles.dividerPipe} />);
    }
    appsTitleComp.pop();
    return appsTitleComp;
  };

  const UserTableTitle = () => (
    <React.Fragment>
      {getAllAppUserCountTile()}

      <Link
        isInternal
        newWindow
        id="learn_moare"
        link={'https://support.6sense.com/knowledge-base/review-license-usage/'}
        className={`${styles.linkStyle} ${styles.linkStyleLearnMore}`}
      >
        Learn more
      </Link>
    </React.Fragment>
  );

  return (
    <Card>
      <Card.Header>
        <Flex alignItems="center">
          <span>
            <ResultBoundary
              result={result}
              renderLoading={() => <Loading.Spinner size={24} />}
              renderError={() => null}
            >
              {() =>
                selectedUsers.size &&
                appsList.some(({ id: appId }) => canEditAppMap[appId]) ? (
                  <MultiUserActions
                    selectedUsers={selectedUsers}
                    users={sortedUsers}
                    setSelectedUsers={setSelectedUsers}
                    isSAML={isSAML}
                  />
                ) : (
                  <Card.Header.Title
                    style={{
                      textTransform: 'none',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <UserTableTitle />
                  </Card.Header.Title>
                )
              }
            </ResultBoundary>
          </span>
          <div style={{ minWidth: 16, flex: 1 }} />
          <Flex alignItems="center">
            <div style={{ position: 'relative' }}>
              <Button
                onClick={() => setAdvSearchVisible(!advSearchVisible)}
                type={advSearchVisible ? 'primary' : 'secondary'}
                className={styles.filterButton}
              >
                <Icon
                  type="filter_list"
                  className={`${advSearchVisible ? styles.active : null}`}
                />
                <Text
                  className={`
                  ${styles.filterByText}
                  ${advSearchVisible ? styles.active : null}
                `}
                >
                  Filters
                </Text>
                <CountPills />
              </Button>
              <AdvancedSearch
                visibility={advSearchVisible}
                changeVisible={setAdvSearchVisible}
                setFilterApplied={setFilterApplied}
                selectedUsers={selectedUsers}
                isSAML={isSAML}
                filterApplied={filterApplied}
              />
            </div>
            <HeaderSearchBar
              disabled={!!selectedUsers.size}
              placeholder={'Search Users'}
              tooltipText={'Search Users'}
              onChange={(v) => {
                dispatch(actions.setUsernameFilter(v));
              }}
              clearOnClose
              value={usernameFilter}
              containerClassName={styles.inputContainer}
              className={styles.input}
            />
            <div style={{ width: 8 }} />
            <Icon
              onClick={() =>
                dispatch(
                  downloadActions.download(
                    allUsersForDownloadSelector,
                    `[${orgName}] Users.csv`,
                    'users',
                    orgName
                  )
                )
              }
              type={'get_app'}
              tooltipWrapped
              tooltipText={'Download Users'}
            />
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Content.Async
        result={result}
        renderError={() => <ErrorDisplay />}
        style={{ minHeight: 200 }}
      >
        {({ data }) => (
          <UserList
            users={data || []}
            isSAML={isSAML}
            sortState={sortState}
            setSortState={setSortState}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        )}
      </Card.Content.Async>
      <Card.Footer>
        <Flex justifyContent="center">
          <Pagination
            current={page}
            pageSize={10}
            onChange={(nextPage) => setPage(nextPage)}
            total={sortedUsers.length}
          />
        </Flex>
      </Card.Footer>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  loading: !usersLoadedSelector(state),
  errorMessage: errorMessageSelector(state),
  users: formattedUsersSelector(state),

  isSAML: authTypeSelector(state) === 'SAML',
  orgName: orgObjSelector(state).name,
  getTotalAppActiveUserCount: getTotalAppActiveUserCountSelector(state),
  totalActiveUserCount: totalActiveUserCountSelector(state),
  isIframeUser: isIframeUserSelector(state),
  iFrameUserCount: getIFrameUserCountSelector(state),
});

const { setEmailsFilterMissingMap } = actions;

export default connect(mapStateToProps, { setEmailsFilterMissingMap })(
  UsersCard
);
