import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { LayoutCard } from 'HOCS';
import { Row, Col, Select } from 'v2-components';
import { DATE_RANGE_OPTIONS } from '../constants';
import { pxToRem } from '@sixsense/core/style';
import { css } from 'styles/emotion';
import { SalesPermission, usePermissionCheck } from '@sixsense/rbac';
import { isViewOnlyEnabledFor6senseAccessSelector } from 'modules/user/selectors';
import { useSelector } from '@sixsense/core/versioned/react-redux';


const styles = {
  itemWrap: css({
    flex: 1,
    paddingTop: pxToRem(20),
    paddingRight: pxToRem(12),
    paddingBottom: pxToRem(15),
    paddingLeft: pxToRem(20),
    margin: '0px',
  }),
  sortSelect: css({
    paddingRight: pxToRem(10),
  }),
  select: css({
    paddingTop: pxToRem(18),
    paddingLeft: pxToRem(20),
    paddingBottom: pxToRem(20),
  }),
};

const DateRangeCardComponent = ({
  onChange,
  config,
}) => {
  const hasEditPermission = usePermissionCheck([SalesPermission.SETTINGS_COMPONENTS_METRICS_EDIT]);
  const isViewOnlyEnabled = useSelector(isViewOnlyEnabledFor6senseAccessSelector);
  return (
    <div className={styles.itemWrap}>
      <Row>
        <Col>
          Set a default date range that you want on Sales Intelligence Interface
        </Col>
      </Row>
      <Row>
        <Col maxWidth={'280px'} className={styles.select}>
          <Select
            disabled={!hasEditPermission || isViewOnlyEnabled}
            rowClassName={styles.sortSelect}
            label={'Default Date Range'}
            options={DATE_RANGE_OPTIONS}
            value={config.default_date_range}
            labelKey={'display_name'}
            onChange={(el) => { onChange({ default_date_range: el }); }}
            key={'date_range_selection'}
            id={'date_range_selection'}
          />
        </Col>

      </Row>
    </div>
  );
};


DateRangeCardComponent.propTypes = {
  config: PropTypes.object,
  onChange: PropTypes.func,
};

export const DateRangeCard = compose(
  LayoutCard({
    size: LayoutCard.RESPONSIVE,
    alignment: 'start',
    headerBorder: true,
  }),
)(DateRangeCardComponent);
