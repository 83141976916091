import { takeLatest, put, select, call } from 'redux-saga/effects';
import { fetchSaga } from 'store/sagas';
import { actions } from './index';
import { actionTypes as userActionTypes } from 'modules/user';
import { orgSelector } from 'modules/user/selectors';

const { LOAD_USER_SUCCESS } = userActionTypes;
const { loadLabelsFailure, loadLabelsSuccess } = actions;

export function* loadLabels(request) {
  try {
    const org = yield select(orgSelector);
    const endpoint = `query/${org}/label/`;
    const displayMap = yield call(request, endpoint);
    yield put(loadLabelsSuccess(displayMap));
  } catch (e) {
    yield put(loadLabelsFailure(e));
  }
}

export function* watchLoadLabels(request) {
  yield takeLatest(LOAD_USER_SUCCESS, loadLabels, request);
}


export default [
  fetchSaga(watchLoadLabels),
];
