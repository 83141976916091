/*
* Util method for auth
*/
export const isSubDomain = () => {
  const { host } = window.location;
  const rootParts = window.process.env.AA_UI_ENDPOINT.split('.');
  const parts = host.split('.');

  return parts.length > rootParts.length;
};

export const getOrg = () => {
  const { host } = window.location;
  const parts = host.split('.');
  return parts[0];
};

export const redirectCorrectOrgLink = (org) => {
  const { host } = window.location;
  const rootParts = window.process.env.AA_UI_ENDPOINT.split('.');
  const parts = host.split('.');

  if (rootParts.length === parts.length) {
    parts[0] = org;
  } else {
    parts.unshift(org);
  }

  return parts.join('.');
};

export const getOrgNameAtTimeOfLoginUrl = () => {
  const { host } = window.location;
  const parts = host.split('.');

  const { host: login_host } = new URL(window.process.env.LOGIN_SERVICE_ENDPOINT);
  const login_host_parts = login_host.split('.');

  if (parts.length > login_host_parts.length) {
    return parts[0];
  }
  return '';
};

export const isPlanA = () => {
  if (!getOrgNameAtTimeOfLoginUrl()) {
    return true;
  }
  return false;
};
