/* eslint-disable */
import React from 'react';

export const User = ({ className }) => (
<svg id="id_user" className={className} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.6673 16C14.6673 14.837 14.6673 14.2555 14.5238 13.7824C14.2006 12.717 13.3669 11.8834 12.3016 11.5602C11.8284 11.4167 11.247 11.4167 10.084 11.4167H5.91732C4.75435 11.4167 4.17286 11.4167 3.6997 11.5602C2.63436 11.8834 1.80068 12.717 1.47752 13.7824C1.33398 14.2555 1.33398 14.837 1.33398 16M11.7507 4.75C11.7507 6.82107 10.0717 8.5 8.00065 8.5C5.92958 8.5 4.25065 6.82107 4.25065 4.75C4.25065 2.67893 5.92958 1 8.00065 1C10.0717 1 11.7507 2.67893 11.7507 4.75Z" stroke="url(#paint0_linear_1883_10840)" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
    <linearGradient id="paint0_linear_1883_10840" x1="1.33398" y1="16" x2="16.2305" y2="2.75862" gradientUnits="userSpaceOnUse">
      <stop stop-color="#19CCC6"/>
      <stop offset="1" stop-color="#007BC1"/>
    </linearGradient>
  </defs>
</svg>
);

export default User;
