import React from 'react';
import { Tooltip2 as Tooltip } from 'v2-components';
import {
  CompanyContact,
  InsideView,
  ZoomInfo,
  PeopleDataLabs,
  Slintel,
} from '../assets';

const COMPANYCONTACT = 'COMPANY_CONTACT';
const INSIDEVIEW = 'INSIDEVIEW';
const ZOOMINFO = 'ZOOMINFO';
const PEOPLEDATALABS = 'PEOPLEDATALABS';
const SLINTEL = 'SLINTEL';

const tooltipContainerStyle = { position: 'unset' };

type TVendorsIconProps = {
  type: string;
  className?: string;
  size?: string;
}

const VendorsIcon = (props: TVendorsIconProps) => {
  const { type, className, size } = props;


  switch (type) {
    case COMPANYCONTACT:
      return <CompanyContact className={className} size={size} />;
    case INSIDEVIEW:
      return (
        <Tooltip placement={'top'} overlay={'InsideView'} style={tooltipContainerStyle}>
          <span>
            <InsideView className={className} size={size} />
          </span>
        </Tooltip>
      );
    case ZOOMINFO:
      return (
        <Tooltip placement={'top'} overlay={'ZoomInfo'} style={tooltipContainerStyle}>
          <span>
            <ZoomInfo className={className} size={size} />
          </span>
        </Tooltip>
      );
    case PEOPLEDATALABS:
      return (
        <Tooltip placement={'top'} overlay={'People Data Labs'} style={tooltipContainerStyle}>
          <span>
            <PeopleDataLabs className={className} size={size} />
          </span>
        </Tooltip>
      );
    case SLINTEL:
      return (
        <Tooltip placement={'top'} overlay={'Slintel'} style={tooltipContainerStyle}>
          <span>
            <Slintel className={className} size={size} />
          </span>
        </Tooltip>
      );
    default:
      return null;
  }
};

VendorsIcon.COMPANYCONTACT = COMPANYCONTACT;
VendorsIcon.INSIDEVIEW = INSIDEVIEW;
VendorsIcon.ZOOMINFO = ZOOMINFO;
VendorsIcon.PEOPLEDATALABS = PEOPLEDATALABS;
VendorsIcon.SLINTEL = SLINTEL;

export default VendorsIcon;
