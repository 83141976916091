import React from 'react';

/* eslint max-len: [2, 100000] */
export function Tag(props) {
  return (
    <svg {...props} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <title>tag</title>
      <g fillRule="nonzero" fill="none">
        <path
          d="M331.16 743.455l-26.324 26.326c-3.51 3.51-7.547 5.266-12.11 5.266s-8.6-1.755-12.11-5.265L35.267 524.43C31.755 520.92 30 516.88 30 512.32c0-4.564 1.755-8.6 5.265-12.11l245.352-245.352c3.51-3.51 7.546-5.265 12.11-5.265 4.562 0 8.6 1.755 12.11 5.265l26.324 26.325c3.51 3.51 5.266 7.547 5.266 12.11s-1.755 8.6-5.265 12.11L124.246 512.32 331.16 719.235c3.51 3.51 5.266 7.547 5.266 12.11s-1.755 8.6-5.265 12.11zm311.166-561.78L445.94 861.392c-1.405 4.563-4.125 7.985-8.16 10.266-4.038 2.28-8.162 2.72-12.374 1.316l-32.644-8.95c-4.563-1.405-7.985-4.125-10.266-8.162-2.282-4.036-2.72-8.336-1.317-12.9l196.386-679.718c1.404-4.563 4.124-7.985 8.16-10.267 4.037-2.282 8.162-2.72 12.374-1.317l32.643 8.95c4.563 1.405 7.985 4.125 10.267 8.16 2.28 4.038 2.72 8.338 1.316 12.9zM988.24 524.428L742.89 769.78c-3.51 3.51-7.548 5.266-12.11 5.266-4.564 0-8.6-1.755-12.11-5.265l-26.326-26.325c-3.51-3.51-5.265-7.546-5.265-12.11 0-4.562 1.754-8.6 5.264-12.11L899.26 512.32 692.345 305.402c-3.51-3.51-5.265-7.547-5.265-12.11s1.754-8.6 5.264-12.11l26.325-26.325c3.51-3.51 7.546-5.265 12.11-5.265 4.562 0 8.6 1.755 12.11 5.265L988.24 500.21c3.51 3.51 5.265 7.546 5.265 12.11 0 4.562-1.755 8.6-5.265 12.11z"
          fill="#B0BEC5"
        />
        <path
          d="M609.4 145.142c0-10.837-3.835-20.09-11.502-27.756-7.668-7.667-16.92-11.5-27.756-11.5-10.837 0-20.09 3.833-27.756 11.5-7.667 7.667-11.5 16.92-11.5 27.756 0 10.836 3.833 20.088 11.5 27.756 7.667 7.667 16.92 11.5 27.756 11.5 10.836 0 20.088-3.833 27.756-11.5 7.667-7.668 11.5-16.92 11.5-27.756zm327.24 176.655c0 10.837-3.782 20.038-11.347 27.603L774.706 500.293c-7.974 7.565-17.277 11.348-27.91 11.348-10.835 0-20.036-3.782-27.6-11.347L499.908 280.7c-7.77-7.565-14.364-17.89-19.783-30.976C474.71 236.64 472 224.678 472 213.84V86.258c0-10.632 3.885-19.833 11.654-27.603 7.77-7.77 16.97-11.654 27.603-11.654H638.84c10.838 0 22.8 2.71 35.884 8.127 13.086 5.42 23.514 12.013 31.283 19.782l219.286 218.978c7.565 7.974 11.348 17.277 11.348 27.91z"
          fill="#109E96"
        />
      </g>
    </svg>
  );
}
