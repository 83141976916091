import { ACTION_PREFIX, AD_LIBRARY_CLASSIFICATION_ACTIONS } from './constants';
import { hotSwapActionsFactory } from '../advertisingClassificationCommon/actionTypes';
import { SET_AD_LIBRARY_CLASSIFICATION_MODAL_VISIBILITY } from './types';

const hotSwapActions = hotSwapActionsFactory(ACTION_PREFIX);

const addClassificationHotSwap = (payload) => ({
  type: hotSwapActions.FOLDER_LIST_HOT_SWAP_ADD,
  payload,
});

const replaceClassificationHotSwap = (payload) => ({
  type: hotSwapActions.FOLDER_LIST_HOT_SWAP_REPLACE,
  payload,
});

const deleteClassificationHotSwap = (payload) => ({
  type: hotSwapActions.FOLDER_LIST_HOT_SWAP_DELETED,
  payload,
});

export const setClassificationModalVisibility = (
  visible,
  placementIds,
  uiAction = AD_LIBRARY_CLASSIFICATION_ACTIONS.move
) => ({
  type: SET_AD_LIBRARY_CLASSIFICATION_MODAL_VISIBILITY,
  visible,
  placementIds,
  uiAction,
});

export const actionCreators = {
  addClassificationHotSwap,
  replaceClassificationHotSwap,
  deleteClassificationHotSwap,
  setClassificationModalVisibility,
};
