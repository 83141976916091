import { browserHistory } from 'react-router';
import qs from 'qs';
import { omit, merge } from 'lodash';
import { curry } from 'lodash/fp';

/*
  For dealing with navigation with query string params
*/

const stringifyOptions = { encode: false };
const parseOptions = { ignoreQueryPrefix: true };

const getCurrentParams = () => qs.parse(browserHistory.getCurrentLocation().search, parseOptions);

const getAllQueryParams = (url) => qs.parse(url, parseOptions);

const getCurrentRoute = () => browserHistory.getCurrentLocation().pathname;

const navigate = curry((route, params) => browserHistory.push(`${route}?${params}`));

const destroyAllParamsNavigate = (route, newTab) => {
  if (newTab) {
    openInNewTab(route);
  } else {
    browserHistory.push(route);
  }
};

const openInNewTab = (url) => {
  // https://stackoverflow.com/a/63627688
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) {
    newWindow.opener = null;
  }
};

const toParams = (obj) => qs.stringify(obj, stringifyOptions);

const setParamValue = curry((paramName, newValue) => {
  const currentRoute = getCurrentRoute();
  const currentParams = getCurrentParams();
  currentParams[paramName] = newValue;
  const newParams = toParams(currentParams);
  navigate(currentRoute, newParams);
});

const preserveParamsNavigate = (route) => {
  const currentParams = toParams(getCurrentParams());
  navigate(route, toParams(currentParams));
};

const mergeParamsNavigate = curry((route, newParams) =>
  navigate(route, toParams(merge({}, getCurrentParams(), newParams)))
);

const destroyParamNavigate = (param) => {
  const currentParams = getCurrentParams();
  const currentRoute = getCurrentRoute();
  const newParams = omit(currentParams, param);
  navigate(currentRoute, toParams(newParams));
};

const newParamsNaviate = (route, newParams) => navigate(route, toParams(newParams));

// new hire task: replace all instances of above with proper spelling then remove this
const newParamsNavigate = newParamsNaviate;

// Plain javascript method to get url query params without any dependency
// NOTE: we get values from param as strings in both the cases from redux-router and window.location
// If you want that values in sepecfic format ie bool, int you have to convert it specifically.
// Expects: window.location object

const getCurrentURLParams = (location = window.location) => {
  const queryParams = new URLSearchParams(location.search);
  const data = {};
  queryParams.forEach((value = '', key) => {
    data[key] = value;
  });
  return data;
};

export {
  setParamValue,
  preserveParamsNavigate,
  destroyParamNavigate,
  destroyAllParamsNavigate,
  getCurrentParams,
  mergeParamsNavigate,
  toParams,
  navigate,
  newParamsNaviate,
  getCurrentRoute,
  getAllQueryParams,
  newParamsNavigate,
  getCurrentURLParams,
};
