import React from 'react';
import PropTypes from 'prop-types';
import { ActionDivider } from './ActionDivider';
import { Menu } from 'antd';
import { Icon, TextMap, Text, Tooltip2 as Tooltip } from 'v2-components';
import styles from './ActionItem.module.scss';

const Item = Menu.Item;

function ActionItem(props) {
  const {
    action,
    icon,
    useGlobalMapper,
    children,
    iconClass,
    textClass='',
    id,
    menuKey,
    className = '',
    disabled,
    disabledTooltip = '',
    disabledTextPlacement = 'left',
    ...rest
  } = props;

  const text = useGlobalMapper ? <TextMap>{children}</TextMap> : children;
  const iconComponent = (
    <Icon
      type={icon}
      className={`${styles.leftIcon} ${iconClass}`}
      disabled={disabled}
    />
  );

  return (
    <Tooltip
      placement={disabledTextPlacement}
      overlayStyle={!disabled || disabledTooltip === '' ?
        { display: 'none' } : { minWidth: '250px' }}
      overlay={<div>{disabledTooltip}</div>}
    >
      <a className={styles.menuItemWrapper} onClick={disabled ? undefined : action} id={id}>

        <Item
          {...rest}
          className={`${className}` ? `${className}` : `${styles.menuItem}`}
          key={menuKey}
          disabled={disabled}
        >
          {icon && iconComponent}
          <Text className={textClass || styles.itemText} disabled={disabled}>{text}</Text>
        </Item>
      </a>
    </Tooltip>
  );
}

ActionItem.defaultProps = {
  disabled: false,
};

ActionItem.propTypes = {
  id: PropTypes.string,
  action: PropTypes.func,
  icon: PropTypes.string,
  useGlobalMapper: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconClass: PropTypes.string,
  menuKey: PropTypes.string,
  className: PropTypes.string,
  textClass: PropTypes.string,
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabledTextPlacement: PropTypes.string,
};

ActionItem.ActionDivider = ActionDivider;

export default ActionItem;
