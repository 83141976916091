/**
 * SAGAS belong in here that apply to the global module/level
 */

import { takeEvery, select } from 'redux-saga/effects';
import { actionTypes } from './index';
import { Modal } from 'v2-components';
import { message, notification } from 'antd';
import { LOCATION_CHANGE } from 'react-router-redux';
import { omit } from 'lodash';
import { promptMessageSelecor } from './selectors';

notification.config({
  duration: 5,
});

const {
  SHOW_GLOBAL_ERROR,
  SHOW_NOTIFICATION,
  PROMPT_ON_LEAVE,
} = actionTypes;

function* promptOnLeaveSaga(action) {
  const { toDestination, onConfirmLeave } = action;
  const content = yield select(promptMessageSelecor);

  Modal.prompt({
    type: Modal.PROMPT_TYPES.CONFIRM,
    title: 'Are you sure you want to navigate away?',
    content,
    onOk() {
      onConfirmLeave();
      toDestination();
    },
  });
}

function recordToMixPanel(action) {
  const { payload: { pathname } } = action;
  const { mixpanel } = window;

  if (mixpanel) {
    mixpanel.track('Page Loaded', { url: window.location.hostname + pathname, pathname });
  }
}

export function* showGlobalError() {
  yield takeEvery(SHOW_GLOBAL_ERROR, (action) => {
    message.error(action.message);
  });
}

function* watchShowNotification() {
  yield takeEvery(SHOW_NOTIFICATION, (action) => {
    const {
      notificationType = 'open',
        notificationProps = {},
    } = action;

    notification[notificationType]({
      ...omit(action, ['type', 'notificationType']),
      ...notificationProps,
    });
  });
}

function* watchPromptOnLeave() {
  yield takeEvery(PROMPT_ON_LEAVE, promptOnLeaveSaga);
}

function* watchLocationChange() {
  yield takeEvery(LOCATION_CHANGE, recordToMixPanel);
}

export default [
  watchShowNotification,
  watchPromptOnLeave,
  showGlobalError,
  watchLocationChange,
];
