import React from 'react';
import { PermissionBoundary, Permission } from '@sixsense/rbac';

type TEditPermissionWrapProps = {
  children: React.ReactElement;
};

const EditPermissionWrap = ({ children }: TEditPermissionWrapProps) => (
  <PermissionBoundary
    allow={(permissions) => permissions.has(Permission.ORCHESTRATION_EDIT)}
    allowExternalSixsenseUser
  >
    {() => children}
  </PermissionBoundary>
);

export default EditPermissionWrap;
