import { stateGenerator } from "./stateGenerator";
import { createSelector } from "reselect";
import { orgSelector } from "modules/user/selectors";

export const campaignLabelsStateGenerator = stateGenerator({
  actionPrefix: "CAMPAIGN_LABELS",
  endpointSelector: createSelector(
    orgSelector,
    (orgId) => `org/${orgId}/campaign_labels/`
  ),
  stateKey: "campaignLabels",
  initialData: {},
  receivedDataTransform: (data) =>
    data.results.reduce(
      (acc, value) => ({ ...acc, [value.name]: value.display_name }),
      {}
    ),
  baseStateSelector: (state) => state.campaigns,
  method: "GET",
});
