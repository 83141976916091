import { AUDIENCE_ROUTES } from './constants';
import { AudienceContainer } from './containers';

export default function createRoutes() {
  return {
    path: AUDIENCE_ROUTES.DEFAULT.PATH,
    name: 'workflows',
    component: AudienceContainer,
  };
}
