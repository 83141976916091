import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid/lib';
import { Card as AntCard } from 'antd';

import { Content } from 'v2-components';

import styles from './GenericCard.module.scss';

function GenericCard(props) {
  const {
    id,
    footer,
    footerBorder,
    footerClass,
    header,
    headerBorder,
    headerClass,
    loading,
    children,
    className,
    bodyClass,
    error,
    errorMessage,
    errorStatus,
    disabled,
    disabledMessage,
    onRefresh,
    noData,
    noDataMessage,
    zeroData,
    zeroDataMessage,
    ContentComponent,
    headerRowClass,
  } = props;

  const headerStyle = headerBorder ? `${styles.header} ${styles.headBorder}` : styles.header;
  const footerStyle = footerBorder ? `${styles.footer} ${styles.footBorder}` : styles.footer;

  const headerComponent = (
    <AntCard.Grid className={`${headerStyle} ${headerClass}`}>{header}</AntCard.Grid>
  );
  const footerComponent = (
    <AntCard.Grid className={`${footerStyle} ${footerClass}`}>{footer}</AntCard.Grid>
  );

  const cardBody = {
    width: '100%',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  };

  const classText = `${styles.card} ${className}`;

  return (
    <AntCard
      id={id}
      hoverable={false}
      bordered={false}
      className={classText}
      bodyStyle={cardBody}
      loading={loading}
    >
      <Row style={{ marginLeft: 0, marginRight: 0 }} className={headerRowClass}>
        {headerComponent}
      </Row>
      <Row
        style={{
          marginLeft: 0,
          marginRight: 0,
          flex: 1,
          minHeight: 0, // need this to allow container scroll in firefox
        }}
      >
        <ContentComponent
          error={error}
          errorMessage={errorMessage}
          errorStatus={errorStatus}
          disabled={disabled}
          disabledMessage={disabledMessage}
          onRefresh={onRefresh}
          noData={noData}
          noDataMessage={noDataMessage}
          zeroData={zeroData}
          zeroDataMessage={zeroDataMessage}
          className={bodyClass}
        >
          {children}
        </ContentComponent>
      </Row>

      <div>{footerComponent}</div>
    </AntCard>
  );
}

GenericCard.propTypes = {
  id: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  bodyClass: PropTypes.string,
  headerBorder: PropTypes.bool,
  headerClass: PropTypes.string,
  footerBorder: PropTypes.bool,
  footerClass: PropTypes.string,
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  error: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  onRefresh: PropTypes.func,
  errorStatus: PropTypes.string,
  noData: PropTypes.bool,
  noDataMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  zeroData: PropTypes.bool,
  zeroDataMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ContentComponent: PropTypes.func,
  headerRowClass: PropTypes.string,
};

GenericCard.defaultProps = {
  ContentComponent: Content,
  headerBorder: false,
  footerBorder: false,
  loading: false,
  responsive: false,
};

export default GenericCard;
