import { Badge, Card, CloseButton, Flex, Modal, Text } from '@sixsense/core/components';

import UsersCard from './UsersCard';
import { css } from 'styles/emotion';
import UsersListFetcher from './UsersListFetcher';
import { useSharedState } from '@sixsense/core';
import { allocateSiCreditsModalOpenState, orgPoolSelectedState } from '../../state';
import { CreditType, TCreditConfig } from '../../types';
import React, { useState } from 'react';
import { SixsenseColor } from '@sixsense/core/style';
import { commaSeparatedNumber } from '../../utils';

const styles = {
  cardContent: css({
    width: '80vw',
    height: '80vh',
    overflow: 'auto',
  }),
};

const STRINGS = {
  HEADER: 'Sales Intelligence Credit Allocation (By 6sense User)',
  DESCRIPTION:
    'Allocate credits from the total Sales Intelligence credit balance to individual users',
};

type TAllocateCreditsByUserModal = {
  creditConfig: TCreditConfig[];
  orgId: number;
  userRoles: Array<[]>;
  dl: any;
  planName: string;
};

const AllocateCreditsByUserModal = ({
  creditConfig,
  dl,
  ...rest
}: TAllocateCreditsByUserModal) => {
  const [selectedUsers, setSelectedUsers] = useState<Set<String>>(new Set());
  const [isAllocateDrawerOpen, setIsAllocateDrawerOpen] = useSharedState(
    allocateSiCreditsModalOpenState
  );
  const closeAllocateByUserDrawer = () => setIsAllocateDrawerOpen(false);
  const [orgPoolSelected] = useSharedState<CreditType>(orgPoolSelectedState);

  const siCreditInfo = creditConfig.find(
    (config) => config.appCreditType === 'sales_intelligence'
  );
  const downloadCsv = (content) => {
    // Get the current date in 'YYYY-MM-DD' format
    const currentDate = new Date().toISOString().split('T')[0];
    const fileName = `6sense_credit_report_${currentDate}.csv`;
    return dl(content, fileName, 'report');
  };
  const unAllocatedCredits = commaSeparatedNumber(
    siCreditInfo?.creditData?.total_unallocated_credits);
  return (
    <Modal isOpen={!!isAllocateDrawerOpen}>
      <Card>
        <Card.Header>
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} gap={10}>
              <Card.Header.Title style={{ textTransform: 'none' }}>
                {STRINGS.HEADER}
              </Card.Header.Title>
              <Card.Header.Subtitle>{STRINGS.DESCRIPTION}</Card.Header.Subtitle>
            </Flex>
            <CloseButton onClick={closeAllocateByUserDrawer} />
          </Flex>
        </Card.Header>
        <Card.Content style={{ paddingTop: 0 }}>
          <Flex className="pt2" gap={10}>
            <Text.SectionHeading size={14} color={SixsenseColor.Gray.DARK_1}>
              Unallocated Credits
            </Text.SectionHeading>
            <Badge mode="light" color="primary">
              {unAllocatedCredits}
            </Badge>
          </Flex>
          <div className={styles.cardContent} >
            {isAllocateDrawerOpen && (
              <UsersListFetcher
                orgId={rest.orgId}
                orgPoolId={orgPoolSelected?.id}
              >
                {(users) => (
                  <UsersCard
                    {...rest}
                    creditInfo={siCreditInfo?.creditData}
                    users={users}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    downloadCsv={downloadCsv}
                  />
                )}
              </UsersListFetcher>
            )}
          </div>
        </Card.Content>
      </Card>
    </Modal>
  );
};

export default AllocateCreditsByUserModal;
