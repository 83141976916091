import React, { Fragment, PropsWithChildren, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { initLogRocket } from './logrocket';
import { useUser } from 'contexts/UserContext';

/**
 * LogRocket should be initialized before rollbar in appContextProvider
 * because rollbar has to insert logrocket session url in rollbar logs.
 *
 * This is also used to ensure we have access to the user before initialization
 * @param children
 * @returns {children}
 */
export const LogrocketInitializer = ({ children }: PropsWithChildren) => {
  const user = useUser();
  const username = user?.username || '';

  useEffect(
    () => {
      if (!isEmpty(username) && username !== 'statuspage@6sense.com') {
        initLogRocket();
      }
    },
    [username],
  );

  return <Fragment>{children}</Fragment>;
};
