const NULLEXPR = 'NullExpr';
const CONST = 'Const';
const VAREXPR = 'VarExpr';
const INTCONST = 'IntConst';
const BOOLCONST = 'BoolConst';
const NOTOP = 'NotOp';
const AND = 'ModelAnd';
const OR = 'ModelOr';

// Positives

const LTOP = 'LtOp';
const LEOP = 'LeOp';
const EQOP = 'EqOp';
const GEOP = 'GeOp';
const GTOP = 'GtOp';
const STARTSWITHOP = 'StartsWithOp';
const CONTAINSOP = 'ContainsOp';
const ENDSWITHOP = 'EndsWithOp';
const BETWEENOP = 'BetweenOp';
const INOP = 'InOp';

// Negatives

const NOTEQOP = 'NotEqOp';
const NOTLTOP = 'NotLtOp';
const NOTLEOP = 'NotLeOp';
const NOTGEOP = 'NotGeOp';
const NOTGTOP = 'NotGtOp';
const NOTSTARTSWITHOP = 'NotStartsWithOp';
const NOTCONTAINSOP = 'NotContainsOp';
const NOTENDSWITHOP = 'NotEndsWithOp';
const NOTBETWEENOP = 'NotBetweenOp';
const NOTINOP = 'NotInOp';

const NEGATIVE_BINARY_EXPRESSIONS = Object.freeze(new Set([
  NOTLTOP,
  NOTLEOP,
  NOTEQOP,
  NOTGEOP,
  NOTGTOP,
  NOTSTARTSWITHOP,
  NOTCONTAINSOP,
  NOTENDSWITHOP,
]));

const BINARY_EXPRESSIONS = Object.freeze(new Set([
  LTOP,
  LEOP,
  EQOP,
  GEOP,
  GTOP,
  STARTSWITHOP,
  CONTAINSOP,
  ENDSWITHOP,
]));

const PRIMITIVE_EXPRESSIONS = Object.freeze(new Set([
  NULLEXPR,
  CONST,
  INTCONST,
  BOOLCONST,
  VAREXPR,
]));

const LOGICAL_EXPRESSIONS = Object.freeze(new Set([
  AND,
  OR,
]));

const EQUALITY_OPS = Object.freeze(new Set([
  EQOP,
  NOTEQOP,
]));

const OP_LABELS = Object.freeze({
  [LTOP]: 'Less than',
  [LEOP]: 'Less than or equal',
  [EQOP]: 'Equal',
  [GTOP]: 'Greater than',
  [GEOP]: 'Greater than or equal',
  [STARTSWITHOP]: 'Starts with',
  [CONTAINSOP]: 'Contains',
  [ENDSWITHOP]: 'Ends with',
  [NOTLTOP]: 'Not less than',
  [NOTLEOP]: 'Not less than or equal',
  [NOTEQOP]: 'Not equal',
  [NOTGTOP]: 'Not greater than',
  [NOTGEOP]: 'Not greater than or equal',
  [NOTSTARTSWITHOP]: 'Does not start with',
  [NOTCONTAINSOP]: 'Does not contain',
  [NOTENDSWITHOP]: 'Does not end with',
});

const DT_OP_LABELS = Object.freeze({
  [LTOP]: 'Before',
  [LEOP]: 'On or before',
  [EQOP]: 'On',
  [GTOP]: 'After',
  [GEOP]: 'On or after',
  [NOTEQOP]: 'Not on',
  [NOTLTOP]: 'Not before',
  [NOTGTOP]: 'Not after',
});

const MATCHING_OPS = Object.freeze(new Set([
  LTOP,
  GTOP,
  STARTSWITHOP,
  CONTAINSOP,
  ENDSWITHOP,
  NOTLTOP,
  NOTGTOP,
  NOTSTARTSWITHOP,
  NOTCONTAINSOP,
  NOTENDSWITHOP,
]));

export {
  NULLEXPR,
  CONST,
  VAREXPR,
  INTCONST,
  BOOLCONST,
  NOTOP,
  AND,
  OR,
  LTOP,
  LEOP,
  EQOP,
  GEOP,
  GTOP,
  NOTEQOP,
  NOTLTOP,
  NOTLEOP,
  NOTGEOP,
  NOTGTOP,
  NOTSTARTSWITHOP,
  NOTCONTAINSOP,
  NOTENDSWITHOP,
  NOTBETWEENOP,
  NOTINOP,
  STARTSWITHOP,
  CONTAINSOP,
  ENDSWITHOP,
  BETWEENOP,
  INOP,
  BINARY_EXPRESSIONS,
  PRIMITIVE_EXPRESSIONS,
  LOGICAL_EXPRESSIONS,
  NEGATIVE_BINARY_EXPRESSIONS,
  OP_LABELS,
  EQUALITY_OPS,
  MATCHING_OPS,
  DT_OP_LABELS,
};
