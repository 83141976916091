import { stateGenerator } from "routes/AdvertisingDS/routes/Campaigns/stateGenerator";
import { actions as globalActions } from "modules/global";
import {
  adEndpointSelector,
  baseSelector,
  createAdPayloadSelector,
  creativeEndpointSelector,
} from "./selectors";
import { ACTION_PREFIX } from "./constants";

const { showNotification } = globalActions;

export const createAdStateGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: `${ACTION_PREFIX}/CREATE_AD`,
  endpointSelector: adEndpointSelector,
  stateKey: "createAd",
  method: "POST",
  requestOptions: createAdPayloadSelector,
  onFailureActions: [
    () => showNotification("error", "Failed to create ad. Please try again"),
  ],
});

export const adOptionsStateGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: `${ACTION_PREFIX}/AD_OPTIONS`,
  endpointSelector: adEndpointSelector,
  stateKey: "adOptions",
  method: "OPTIONS",
  onFailureActions: [
    () => showNotification("error", "Something went wrong. Please try again"),
  ],
});

export const creativesOptionsStateGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: `${ACTION_PREFIX}/CREATIVE_OPTIONS`,
  endpointSelector: creativeEndpointSelector,
  stateKey: "creativeOptions",
  method: "OPTIONS",
  onFailureActions: [
    () => showNotification("error", "Something went wrong. Please try again"),
  ],
});
