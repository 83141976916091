import { getAsyncInjectors } from 'store/utils';
import createReport from './routes/Create';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: 'manage',
    name: 'Manage',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const ManageReports = require('./ManageReports');
          const reducer = require('./modules');
          const sagas = require('./sagas');
          injectReducer('reportsManage', reducer.default);
          injectSagas('reportsManage', sagas.default);
          cb(null, ManageReports.default);
        },
        'reportsManage'
      );
    },
    childRoutes: [
      createReport(),
    ],
  };
}
