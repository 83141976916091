import React, { useState } from 'react';
import { Icon, Tooltip2 as Tooltip } from 'v2-components';
import PropTypes from 'prop-types';
import { Flex } from '@sixsense/core/components';


async function copyTextToClipboard(textToCopy) {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
}
const Copyable=(props) => {
  const [overlayText, setOverlayText] = useState(props.description);
  return (
    <Tooltip overlay={overlayText}>
      <Flex
        alignItems="center"
        gap={8}
        onClick={() => {
          copyTextToClipboard(props.copyText);
          setOverlayText('Copied!');
          setTimeout(() => {
            setOverlayText(props.description);
          }, 2000);
        }}
      >
        <span>
          {props.children}
        </span>
        <Icon
          type="content_copy"
          size={Icon.SIZE.MEDIUM}
          color="grey1"
          pointer
        />
      </Flex>
    </Tooltip>
  );
};

Copyable.propTypes = {
  children: PropTypes.element,
  copyText: PropTypes.string,
  description: PropTypes.string,
};

export default Copyable;
