export default function createRoutes() {
  return {
    path: 'crm',
    name: 'CRM Settings',

    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          cb(null, containers.CRMFormContainer);
        }, 'crm'
      );
    },
  };

}

