import ContactDetails from './routes/ContactDetails';
import SamlSSO from './routes/SamlSSO';

export default function createRoutes(store) {
  return {
    path: 'security',
    name: 'security',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/settings/security/samlsso'),
    },

    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        cb(null, containers.SecurityConfig);
      }, 'settings_security');
    },
    childRoutes: [
      SamlSSO(store),
      ContactDetails(store),
    ],
  };
}
