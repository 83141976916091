import React from "react";
import { Text } from "@sixsense/core/components";
import { formatDate } from "utils/utils";
import { DATE_FORMAT } from "utils/constants";
import { ROW_DATA_TYPES } from "aa-components/CampaignCSVExportModal/constants";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const LAST_UPDATED = {
  sortByKey: "client_updated",
  key: "last-updated",
  csvColumnKey: "last_updated",
  title: <Label name={CAMPAIGN_LABELS.lastUpdated} />,
  dataIndex: "client_updated",
  width: pxToRem(150),
  render: (lastUpdated) => (
    <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
      {formatDate(DATE_FORMAT)(lastUpdated)}
    </Text.Body>
  ),
  csvOverrides: {
    dataIndex: "",
    render: (dataObject, _, dataType) => {
      if (dataType === ROW_DATA_TYPES.aggregated) return "";

      if (dataType === ROW_DATA_TYPES.campaign) {
        return `"${formatDate(DATE_FORMAT)(dataObject.client_updated)}"`;
      }

      return `"${formatDate(DATE_FORMAT)(dataObject.updated)}"`;
    },
  },
};
