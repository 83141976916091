import React, { useState } from 'react';
import { connect } from 'react-redux';
import { PageHeader } from 'HOCS';
import { AccessDenied } from 'v2-components';
import { css } from 'styles/emotion';
import { Permission, withPermissionBoundary } from '@sixsense/rbac';
import PrivacyOptOutHeader from '../components/PrivacyOptOutHeader';
import { compose } from 'redux';
import { orgObjSelector } from 'modules/user/selectors';
import HeaderComponent from '../components/HeaderComponent';
import PeopleTable from '../components/PeopleTable';
import { pxToRem } from '@sixsense/core/style';
import { actions } from 'v2-components/Download/modules';
import PropTypes from 'prop-types';
import { useRequestValue } from '@sixsense/core';
import { usersListRequestState } from '../state';
import moment from 'moment';

const styles = {
  container: css({
    padding: `${pxToRem(20)} ${pxToRem(30)}`,
  }),
};

const mapCsvFields = (data) => data.map((user) => ({
  Name: `${user.first_name} ${user.last_name}`,
  Email: user.email,
  'Company Website': user.company_website,
  'Lead Id': user.people_id,
  // eslint-disable-next-line max-len
  'Request Date': `${moment.utc(user.requested_at).format('MMM D, YYYY')} ${moment.utc(user.requested_at).format('HH:mm:ss')} (UTC)`,
}));

const PrivacyOptOutContainer = ({ dl }) => {
  const [page, setPage] = useState(1);
  const users = useRequestValue(usersListRequestState, {
  });

  const downloadCsv = () => {
    const content = mapCsvFields(users?.data);
    return dl(
      content,
      '6sense_privacy_optout_list.csv',
      'report'
    );
  };
  return (
    <div className={styles.container}>
      <HeaderComponent
        downloadCsv={() => downloadCsv()}
        enableDownload={!users?.data?.length}
      />
      <PeopleTable page={page} setPage={setPage} users={users} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  org: orgObjSelector(state),
});

PrivacyOptOutContainer.propTypes = {
  dl: PropTypes.func,
};

export default compose(
  withPermissionBoundary({
    allow: (permissions) =>
      [Permission.SETTINGS_AUTHENTICATION_EDIT].some((permission) =>
        permissions.has(permission)
      ),
    renderDenied: () => <AccessDenied accessDenied />,
  }),
  connect(mapStateToProps, { dl: actions.download }),
  PageHeader([PrivacyOptOutHeader])
)(PrivacyOptOutContainer);
