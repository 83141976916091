import React from 'react';
import { useSelector } from '@sixsense/core/versioned/react-redux';
import {
  getEmailsFilterMissingMapSelector,
  getUsernameFilterSelector,
} from '../selectors';
import { Icon } from 'v2-components';
import { css } from 'styles/emotion';
import { classNames } from 'utils/utils';
import { errorNotificationContainerElementId } from '../constants';
import { FilterErrorMsgObject } from '../components/Messages';

const styles = {
  errorContainer: css({
    // error
    background: '#fff2f0',
    border: '1px solid #ffccc7',
  }),
  warningContainer: css({
    // warring
    background: '#fffbe6',
    border: '1px solid #ffe58f',
  }),
  notificationContainer: css({
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    margin: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingRight: '10px',
  }),
  errorIcon: css({
    // error
    color: '#ff4d4f',
  }),
  warningIcon: css({
    // warring
    color: '#faad14',
  }),
  notificationIcon: css({
    marginLeft: '18px',
    marginRight: '12px',
  }),
  notificationText: css({
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#001F32',
    wordBreak: 'break-all',
  }),
  impText: css({
    fontWeight: 600,
    fontSize: '14px',
  }),
};

const EmailsFilterMissingNotification = () => {
  const {
    notInSystem = [],
    filteredOut = [],
    paEmail,
  } = useSelector(getEmailsFilterMissingMapSelector);
  const usernameFilter = useSelector(getUsernameFilterSelector);
  if (usernameFilter) {
    return null;
  }
  return (
    <div id={errorNotificationContainerElementId}>
      {paEmail ? (
        <div
          className={classNames(
            styles.notificationContainer,
            styles.errorContainer
          )}
        >
          <Icon
            type="error"
            className={classNames(styles.notificationIcon, styles.errorIcon)}
          />
          <span className={styles.notificationText}>
            {FilterErrorMsgObject.PrimaryAdminInFilter(paEmail)}
          </span>
        </div>
      ) : null}
      {notInSystem.length > 0 ? (
        <div
          className={classNames(
            styles.notificationContainer,
            styles.errorContainer
          )}
        >
          <Icon
            type="error"
            className={classNames(styles.notificationIcon, styles.errorIcon)}
          />
          <span className={styles.notificationText}>
            {FilterErrorMsgObject.EmailNotInSystem(notInSystem)}
          </span>
        </div>
      ) : null}
      {filteredOut.length > 0 ? (
        <div
          className={classNames(
            styles.notificationContainer,
            styles.warningContainer
          )}
        >
          <Icon
            type="warning"
            className={classNames(styles.notificationIcon, styles.warningIcon)}
          />
          <span className={styles.notificationText}>
            {FilterErrorMsgObject.EmailFilterOut(filteredOut)}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default EmailsFilterMissingNotification;
