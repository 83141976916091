import { toaster } from '@sixsense/core';
import { orgSelector } from 'modules/user/selectors';
import { createSelector } from 'reselect';
import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';
import { PRESET_STRING } from './constants';
import { actions } from './modules';
import {
  classificationTypeQueryParamSelector,
  dataSourceQueryParamSelector,
  ruleListSelector, ruleManagerSelector, ruleObjEditCopySelector,
} from './selectors';

const loadRulesEndpointSelector = createSelector(
    orgSelector,
    (_, loadAction) => loadAction,
    (orgId, loadAction) => `turbotax/taxonomy/org/${orgId}/turbotax_rules/` +
      `?data_source=${loadAction.payload.dataSource}` +
      `&classification_type=${loadAction.payload.classificationType}`
);

export const loadRulesStateGenerator = stateGenerator({
  baseStateSelector: ruleManagerSelector,
  actionPrefix: `${PRESET_STRING}/LIST_RULES`,
  endpointSelector: loadRulesEndpointSelector,
  stateKey: 'loadRules',
  onSuccessActions: [
    (response) => actions.setRules(response),
  ],
  onFailureActions: [],
  method: 'GET',
});

const createRuleEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `turbotax/taxonomy/org/${orgId}/turbotax_rules/`
);

const createRulePayloadSelector = createSelector(
  ruleObjEditCopySelector,
  (ruleObjEditCopy) => ({
    body: JSON.stringify(ruleObjEditCopy),
  })
);

export const createRuleStateGenerator = stateGenerator({
  baseStateSelector: ruleManagerSelector,
  actionPrefix: `${PRESET_STRING}/CREATE_RULE`,
  endpointSelector: createRuleEndpointSelector,
  stateKey: 'createRule',
  onSuccessActions: [
    () => actions.setCreateRuleModalOpen(false), // hide the create rule modal
    () => actions.setRuleCreatedSuccessModalOpen(true), // show the success modal
    (response) => actions.addToRules(response), // add to the rules list
    (response) => actions.prepRuleObjForEdit(response), // used to refresh state with response
    () => actions.setRecordCounts([]), // empty array will set all to re-calculating
    // load record counts
    (_, action) => loadRecordCountsStateGenerator.loadAction({
      classificationType: action.payload.classificationType,
      dataSource: action.payload.dataSource,
    }),
  ],
  onFailureActions: [
    () => actions.setCreateRuleModalOpen(false), // hide the create rule modal,
    () => { toaster.showError('Rule Create Failure'); return null; },
  ],
  method: 'POST',
  requestOptions: createRulePayloadSelector,
});

const updateRuleEndpointSelector = createSelector(
  orgSelector,
  ruleObjEditCopySelector,
  (orgId, ruleObjEditCopy) => `turbotax/taxonomy/org/${orgId}/turbotax_rules/${ruleObjEditCopy.id}/`
);

const updateRulePayloadSelector = createSelector(
  ruleObjEditCopySelector,
  (ruleObjEditCopy) => ({
    body: JSON.stringify(ruleObjEditCopy),
  })
);

export const updateRuleStateGenerator = stateGenerator({
  baseStateSelector: ruleManagerSelector,
  actionPrefix: `${PRESET_STRING}/UPDATE_RULE`,
  endpointSelector: updateRuleEndpointSelector,
  stateKey: 'updateRule',
  onSuccessActions: [
    () => actions.setCreateRuleModalOpen(false),
    () => actions.setUpdateRuleCfmModalOpen(false),
    () => actions.setDisableRuleCfmModalOpen(false),
    () => actions.setEnableRuleCfmModalOpen(false),
    (response) => actions.replaceRule(response),
    () => { toaster.showSuccess('Rule Update Success'); return null; }, // returning null is a hack
    () => actions.setRecordCounts([]), // empty array will set all to re-calculating
    // load record counts
    (_, action) => loadRecordCountsStateGenerator.loadAction({
      classificationType: action.payload.classificationType,
      dataSource: action.payload.dataSource,
    }),
  ],
  onFailureActions: [
    () => actions.setCreateRuleModalOpen(false),
    () => actions.setUpdateRuleCfmModalOpen(false),
    () => actions.setDisableRuleCfmModalOpen(false),
    () => actions.setEnableRuleCfmModalOpen(false),
    () => { toaster.showError('Rule Update Failure'); return null; },
  ],
  method: 'PUT',
  requestOptions: updateRulePayloadSelector,
});

const deleteRuleEndpointSelector = createSelector(
  orgSelector,
  (_, loadAction) => loadAction,
  (orgId, loadAction) => `turbotax/taxonomy/org/${orgId}/turbotax_rules/${loadAction.payload.id}/`
);

export const deleteRuleStateGenerator = stateGenerator({
  baseStateSelector: ruleManagerSelector,
  actionPrefix: `${PRESET_STRING}/DELETE_RULE`,
  endpointSelector: deleteRuleEndpointSelector,
  stateKey: 'deleteRule',
  onSuccessActions: [
    () => actions.setDeleteRuleCfmModalOpen(false), // hide the rule delete confirm modal
    (_, action) => actions.deleteRule(action.payload.id), // remove the rule from the list
    () => { toaster.showSuccess('Rule Delete Success'); return null; },
    () => actions.setRecordCounts([]), // empty array will set all to re-calculating
    // load record counts
    (_, action) => loadRecordCountsStateGenerator.loadAction({
      classificationType: action.payload.classificationType,
      dataSource: action.payload.dataSource,
    }),
  ],
  onFailureActions: [
    () => actions.setDeleteRuleCfmModalOpen(false), // hide the rule delete confirm modal
    () => { toaster.showError('Rule Delete Failure'); return null; },
  ],
  method: 'DELETE',
});

const updateRuleOrderEndpointSelector = createSelector(
  orgSelector,
  (_, loadAction) => loadAction,
  (orgId, loadAction) => `turbotax/taxonomy/org/${orgId}/turbotax_rules/updateorder/` +
  `?data_source=${loadAction.payload.dataSource}` +
  `&classification_type=${loadAction.payload.classificationType}`
);


const updateRuleOrderPayloadSelector = createSelector(
  ruleListSelector,
  (ruleList) => ({
    body: JSON.stringify({
      rules: ruleList.rules.map((r) => r.id),
    }),
  })
);

export const updateRuleOrderStateGenerator = stateGenerator({
  baseStateSelector: ruleManagerSelector,
  actionPrefix: `${PRESET_STRING}/UPDATE_RULE_ORDER`,
  endpointSelector: updateRuleOrderEndpointSelector,
  stateKey: 'updateRuleOrder',
  onSuccessActions: [
    () => actions.setChangeRuleOrderInProgress(false), // turn off rule order change mode
    () => actions.setUpdateRuleOrderCfmModalOpen(false), // close the confirmation modal
    () => { toaster.showSuccess('Rule Re-order Success'); return null; },
    () => actions.setRecordCounts([]), // empty array will set all to re-calculating
    // load record counts
    (_, action) => loadRecordCountsStateGenerator.loadAction({
      classificationType: action.payload.classificationType,
      dataSource: action.payload.dataSource,
    }),
  ],
  onFailureActions: [
    () => actions.revertRuleOrder(), // revert rule order
    () => actions.setChangeRuleOrderInProgress(false), // turn off rule order change mode
    () => actions.setUpdateRuleOrderCfmModalOpen(false), // close the confirmation modal
    () => { toaster.showError('Rule Re-order Failure'); return null; },
  ],
  method: 'PATCH',
  requestOptions: updateRuleOrderPayloadSelector,
});

const loadImpactedRulesEndpointSelector = createSelector(
  orgSelector,
  classificationTypeQueryParamSelector,
  dataSourceQueryParamSelector,
  (orgId, classificationType, dataSource) =>
    `turbotax/taxonomy/org/${orgId}/count_by_rule_query/` +
    `${classificationType}/${dataSource}/`
);


const loadImpactedRulesPayloadSelector = createSelector(
  (_, loadAction) => loadAction,
  (loadAction) => {
    if (!loadAction.payload) return null;
    return ({
      body: JSON.stringify({
        mapping: JSON.parse(loadAction.payload.mapping),
        ast: loadAction.payload.ast,
        remove_manual_override: loadAction.payload.overrideManualMapping,
      }),
    });
  }
);

// for the 'Show Records / Refresh' on create rule modal to get count for single rule
export const loadImpactedRulesStateGenerator = stateGenerator({
  baseStateSelector: ruleManagerSelector,
  actionPrefix: `${PRESET_STRING}/GET_IMPACTED_RULES`,
  endpointSelector: loadImpactedRulesEndpointSelector,
  stateKey: 'getImpactedRules',
  onSuccessActions: [
    (_, loadAction) => actions.setImpactedRecordsLastCallPayload({
      mapping: loadAction.payload.mapping,
      ast: loadAction.payload.ast,
      overrideManualMapping: loadAction.payload.overrideManualMapping,
    }),
  ],
  onFailureActions: [],
  method: 'POST',
  requestOptions: loadImpactedRulesPayloadSelector,
});

const recordCountsEndpointSelector = createSelector(
  orgSelector,
  (_, loadAction) => loadAction,
  (orgId, loadAction) => {
    let url = `turbotax/taxonomy/org/${orgId}/count_by_rule/` +
    `${loadAction.payload.classificationType}/` +
    `${loadAction.payload.dataSource}/`;

    if (loadAction.payload.ruleId) {
      url += `?rule_id=${loadAction.payload.ruleId}`;
    }
    return url;
  }
);

// to load counts for all the rules in one go in rules list screen
export const loadRecordCountsStateGenerator = stateGenerator({
  baseStateSelector: ruleManagerSelector,
  actionPrefix: `${PRESET_STRING}/GET_RECORD_COUNTS`,
  endpointSelector: recordCountsEndpointSelector,
  stateKey: 'getRecordCounts',
  onSuccessActions: [
    // eslint-disable-next-line no-unused-vars
    (response, _) => actions.updateRecordCounts(response),
  ],
  onFailureActions: [
    () => { toaster.showError('Load Record Counts Failure'); return null; },
  ],
  method: 'GET',
});

const ruleHistoryEndpointSelector = createSelector(
  orgSelector,
  (_, loadAction) => loadAction,
  (orgId, loadAction) =>
    `turbotax/taxonomy/org/${orgId}/rule/${loadAction.payload.ruleId}/audit/`
);


export const loadRuleHistoryStateGenerator = stateGenerator({
  baseStateSelector: ruleManagerSelector,
  actionPrefix: `${PRESET_STRING}/GET_RULE_HISTORY`,
  endpointSelector: ruleHistoryEndpointSelector,
  stateKey: 'getRuleHistory',
  onSuccessActions: [],
  onFailureActions: [
    () => { toaster.showError('Load Rule History Failure'); return null; },
  ],
  method: 'GET',
});
