import React from 'react';
import Text from 'v2-components/Text';
import { CAMPAIGN_SOURCE } from '../../../constants';
import { numberToDollar } from 'utils/utils';
import { NA } from './common/NA';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';

export const DAILY_SPEND = {
  sortByKey: 'daily_spend_amount',
  key: 'daily-spend-amount',
  csvColumnKey: 'daily_spend_amount',
  title: <Label name={CAMPAIGN_LABELS.maxDailySpend} />,
  dataIndex: 'daily_spend_amount',
  width: 190,
  render: (dailySpendAmount, campaign) => {
    const { campaign_source } = campaign;
    if (campaign_source === CAMPAIGN_SOURCE.EXTERNAL) {
      return (<NA />);
    }

    return (<Text
      type={Text.TYPE.SUBBODY}
    >
      {numberToDollar(dailySpendAmount, { insertCommas: true })}
    </Text>);
  },
};
