import React from 'react';
import Text from 'v2-components/Text';
import { NA } from './common/NA';
import { is6SenseCampaign } from '../../../utils';
import Label, { CAMPAIGN_LABELS } from '../../../components/Labels';
import { getAdTypeLabelAndLogo } from 'routes/Advertising/utils';
import styles from './AD_TYPE.module.scss';
import Row from 'v2-components/Row';

export const AD_TYPE = {
  customizable: true,
  key: 'ad-type',
  csvColumnKey: 'campaign_subtype',
  title: <Label name={CAMPAIGN_LABELS.campaignSubtype} />,
  dataIndex: 'campaign_subtype',
  width: 170,
  render: (campaignSubType, campaign) => {
    if (!is6SenseCampaign(campaign)) {
      return (<NA />);
    }

    const adTypeInfo = getAdTypeLabelAndLogo(campaignSubType);

    return (
      <Row>
        <img src={adTypeInfo.icon} alt={campaignSubType} className={styles.icon} />
        <Text type={Text.TYPE.SUBBODY}>{adTypeInfo.label}</Text>
      </Row>
    );
  },
};
