import { defineSharedState } from '@sixsense/core';
import { PATH_PREFIX } from './constants';
import {
  PsychographicsSearchState,
  PsychographicsState,
  UserPsychographicsType,
} from './types';

export const psychographicsSearchState =
  defineSharedState<PsychographicsSearchState>({
    path: `${PATH_PREFIX}.psychographicsSearchState`,
    defaultValue: '',
  });

export const psychographicsState =
  defineSharedState<PsychographicsState>({
    path: `${PATH_PREFIX}.psychographicsState`,
    defaultValue: [],
  });

export const userPsychographicsState = defineSharedState <UserPsychographicsType>({
  path: `${PATH_PREFIX}.userPsychographicsList`,
  defaultValue: [],
});

