import React from 'react';
/* eslint max-len: [2, 4000] */
// Using 1000 for now.... might need to bump it up
export function ExpiredExclamation(props) {
  return (
    <svg width="12px" {...props} height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <desc>Created with Sketch.</desc>
      <g id="Linkedin-Integration" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="A5" transform="translate(-609.000000, -574.000000)" fill="#505C62" filRule="nonzero">
          <g id="campaign.type-copy-12" transform="translate(602.000000, 568.000000)">
            <g id="error_outline-24px" transform="translate(7.000000, 6.000000)">
              <path d="M5.4,7.8 L6.6,7.8 L6.6,9 L5.4,9 L5.4,7.8 Z M5.4,3 L6.6,3 L6.6,6.6 L5.4,6.6 L5.4,3 Z M5.994,0 C2.682,0 0,2.688 0,6 C0,9.312 2.682,12 5.994,12 C9.312,12 12,9.312 12,6 C12,2.688 9.312,0 5.994,0 Z M6,10.8 C3.348,10.8 1.2,8.652 1.2,6 C1.2,3.348 3.348,1.2 6,1.2 C8.652,1.2 10.8,3.348 10.8,6 C10.8,8.652 8.652,10.8 6,10.8 Z" id="Shape" />
            </g>
          </g>
        </g>
      </g>
    </svg>);
}
