export default function createRoutes() {
  return {
    path: 'preferences',
    name: 'taxonomy-preferences',
    getComponent(nextState, cb) {
      require.ensure(
          [],
          (require) => {
            const container = require('./containers/PreferencesContainer');
            cb(null, container.PreferencesContainer);
          },
          'taxv2-preferences',
        );
    },
  };
}
