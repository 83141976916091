import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'v2-components';
import { max, map, keyBy } from 'lodash';
import { PX_REM_SIZE_FACTOR } from 'utils/constants';
import { Dimension } from 'crossfilter-redux';
import styles from './PredictiveBoxChart.module.scss';
import { Box } from './Box';

const BUYING_STAGES = ['Target', 'Awareness', 'Consideration', 'Decision', 'Purchase'];

export const PredictiveBoxChart = (props) => {
  const { maxHeight, addFilter, dataset, removeFilter, appliedFilters, onClick } = props;

  let isCrossFiltered = true;
  if (!addFilter || !removeFilter || !appliedFilters) {
    isCrossFiltered = false;
  }

  const selected = isCrossFiltered ? new Set(map(appliedFilters, (d) => d.filterValue)) : new Set();
  const maxData = max(map(dataset, (d) => d.key === 'Target' ? 0 : d.value).concat([1]));
  const maxCompHeight = maxHeight + (60 / PX_REM_SIZE_FACTOR);
  const modifier = maxHeight / maxData;
  const data = keyBy(dataset, (d) => d.key);

  const buyingStagesDetails = BUYING_STAGES.map((buyingStage) => {
    const value = data[buyingStage] ? data[buyingStage].value : 0;
    const isSelected = selected.has(buyingStage);
    const exclude = buyingStage === 'Target';
    return {
      value,
      isSelected,
      selected,
      exclude,
      label: buyingStage,
      isEngaged: buyingStage === 'Target',
      height: exclude ? (maxCompHeight / 2) : (value * modifier),
      onClick: isCrossFiltered
        ? () => isSelected ? removeFilter(buyingStage) : addFilter(buyingStage)
        : () => onClick(buyingStage),
    };
  });

  const engagedBoxes = [];
  const unEngagedBoxes = [];
  buyingStagesDetails.forEach((boxProps) => {
    const box = <Box key={`${boxProps.label}-${boxProps.value}`} {...boxProps} />;
    if (boxProps.isEngaged) {
      engagedBoxes.push(box);
    } else {
      unEngagedBoxes.push(box);
    }
  });

  return (
    <div className={styles.container}>
      <div className={styles.containerEngaged} style={{ height: `${maxCompHeight}rem` }}>
        <div className={styles.containerEngagedInner}>{engagedBoxes}</div>
        <div className={styles.status}>
          <div className={styles.bar} />
          <Text>
            &nbsp;Not Engaged&nbsp;
          </Text>
          <div className={styles.bar} />
        </div>
      </div>
      <div className={styles.between} />
      <div className={styles.containerUnengaged} style={{ height: `${maxCompHeight}rem` }}>
        <div className={styles.containerUnengagedInner}>{unEngagedBoxes}</div>
        <div className={styles.status}>
          <div className={styles.bar} />
          <Text>
            &nbsp;Engaged&nbsp;
          </Text>
          <div className={styles.bar} />
        </div>
      </div>
    </div>
  );
};

PredictiveBoxChart.propTypes = {
  maxHeight: PropTypes.number,
  buyingStagesDetails: PropTypes.array,
  dataset: PropTypes.array,
  ...Dimension.dimensionPropTypes,
};

PredictiveBoxChart.defaultProps = {
  maxHeight: 250 / PX_REM_SIZE_FACTOR,
};

export default PredictiveBoxChart;
