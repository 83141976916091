import React from 'react';
import PropTypes from 'prop-types';
import GlobalStateContext from './context';
import { connect } from 'react-redux';
import { globalStateSelector } from 'modules/global/selectors';
import { actions } from 'modules/global';

const GlobalStateProvider = ({ children, state, setState }) => (
  <GlobalStateContext.Provider value={{ state, setState }}>
    {children}
  </GlobalStateContext.Provider>
);

GlobalStateProvider.propTypes = {
  children: PropTypes.any,
  state: PropTypes.object,
  setState: PropTypes.func,
};

const mapStateToProps = (state) => ({
  state: globalStateSelector(state),
});

const mapDispatchToProps = { setState: actions.setGlobalState };

export default connect(mapStateToProps, mapDispatchToProps)(GlobalStateProvider);
