import { select, call, put } from 'redux-saga/effects';
import { actionTypes, actions } from './modules';
import { fetchSaga, debounce } from 'store/sagas';
import { endpointSelector } from './selectors';
import { orderBy } from 'lodash';

const { GLOBAL_SEARCH } = actionTypes;
const { loadAccountsFail, loadAccountsSuccess } = actions;

export function* loadAccountList(request) {
  const endpoint = yield select(endpointSelector);

  try {
    const accountsJSON = yield call(request, endpoint);
    yield put(loadAccountsSuccess(
      orderBy(accountsJSON.suggestions, ['score', 'firm_name'], ['desc', 'asc']),
      accountsJSON.total
    ));
  } catch (e) {
    yield put(loadAccountsFail(e.toString()));
  }
}

export function* watchChange(request) {
  yield debounce(400, [GLOBAL_SEARCH], loadAccountList, request);
}

export default [fetchSaga(watchChange)];
