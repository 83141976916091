import { CAMPAIGN_CSV_EXPORT_DRAWER_STATE_KEY } from "./constants";
import { createSelector } from "reselect";

export const stateSelector = (state) =>
  state[CAMPAIGN_CSV_EXPORT_DRAWER_STATE_KEY];

export const modalStateSelector = createSelector(
  stateSelector,
  ({ modalState }) => modalState
);

export const formStateSelector = createSelector(
  stateSelector,
  ({ formState }) => formState
);
