import {
  SALESFORCE_SALES_ENGAGEMENT_ROUTE,
  SALESFORCE_SALES_ENGAGEMENT_REDUX_STATE_KEY,
} from './constants';
import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: SALESFORCE_SALES_ENGAGEMENT_ROUTE,
    getComponent(nextState, cb) {
      // @ts-ignore
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');
          const reducer = require('./modules');
          const sagas = require('./sagas');

          injectReducer(SALESFORCE_SALES_ENGAGEMENT_REDUX_STATE_KEY, reducer.default);
          injectSagas(SALESFORCE_SALES_ENGAGEMENT_REDUX_STATE_KEY, sagas.default);

          const formWizardReducer =
            require('routes/Settings/routes/Integration/components/FormWizard2/modules');
          injectReducer('SettingsFormWizard', formWizardReducer.default);

          const dataSyncsReducer =
            require('routes/Settings/routes/Integration/components/DataSyncs/modules');
          injectReducer('DataSyncs', dataSyncsReducer.default);

          cb(null, containers.SalesforceSepSummaryContainer);
        },
        'snowflake',
      );
    },
  };
}
