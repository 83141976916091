import { getAsyncInjectors } from 'store/utils';
import ListOrchestrationRoutes from './routes/ListOrchestration';
import CreateOrchestrationRoutes from './routes/CreateOrchestration';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: 'orchestrations',
    name: 'orchestrations',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/orchestrations/manage'),
    },
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        const sagas = require('./sagas');
        const reducer = require('./modules');
        const listOrchestrationSagas = require('./routes/ListOrchestration/sagas');
        const listOrchestrationReducer = require('./routes/ListOrchestration/modules');
        const createOrchestrationSagas = require('./routes/CreateOrchestration/sagas');
        const createrOrchestrationReducer = require('./routes/CreateOrchestration/modules');
        injectReducer('orchestrations', reducer.default);
        injectSagas('orchestrations', sagas.default);
        injectReducer('listOrchestration', listOrchestrationReducer.default);
        injectSagas('listOrchestration', listOrchestrationSagas.default);
        injectReducer('createOrchestration', createrOrchestrationReducer.default);
        injectSagas('createOrchestration', createOrchestrationSagas.default);
        cb(null, containers.Orchestration);
      }, 'orchestration');
    },
    childRoutes: [
      ListOrchestrationRoutes(store),
      CreateOrchestrationRoutes(store),
    ],
  };
}
