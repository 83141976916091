import React from "react";
import { Text } from "@sixsense/core/components";
import { localeString } from "utils/utils";
import { NA } from "./common/NA";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { isDraftOrScheduledCampaign } from "../../../utils";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const ACCOUNTS_WITH_INCREASED_ENGAGEMENT = {
  sortByKey: "increased_engagement",
  key: "increased-engagement",
  csvColumnKey: "accounts_with_increased_engagement",
  title: <Label name={CAMPAIGN_LABELS.accountsWithIncreasedEngagement} />,
  dataIndex: "campaign_data.increased_engagement",
  width: pxToRem(230),
  render: (increasedEngagement, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (isDraftOrScheduledCampaign(campaignStatus)) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {localeString(increasedEngagement)}
      </Text.Body>
    );
  },
  align: "right",
};
