import { css } from 'styles/emotion';

export const KEYWORDS = 'keywords';
export const GROUPS = 'keyword_groups';
export const GROUP_KW_LIST = 'keyword_group_kw_list';
export const ADD_TO_GROUP = 'add_to_group';
export const DEACTIVATED = 'deactivated';
export const BRANDED = 'branded';
export const GENERIC = 'generic';
export const PATH_PREFIX = 'settings.keywordsPage';
export const REC_ORIGIN_VALUE = 'recommended';
export const USER_ADDED_ORIGIN_VALUE = 'user_added';
export const NEW_GROUP = 'new_group';
export const NO_MATCH = 'No Keywords match your filters.';
export const NO_KEYWORDS =
  "You have not added any keywords yet. Click 'Add Keywords' to add your Keywords.";

// For now, Keyword Experiment product will be shown as 'Other'
export const KW_EXP = 'Keyword Experiment';
export const OTHER = 'Other';

export const TABS = [
  { label: '6sense Intent Keywords', value: KEYWORDS },
  { label: 'Keyword Groups', value: GROUPS },
  { label: 'Deactivated Keywords', value: DEACTIVATED },
];

export const KEYWORD_CATEGORY_OPTIONS = [
  { label: 'All Keyword Types', value: 'all' },
  { label: 'Branded', value: 'branded' },
  { label: 'Generic', value: 'generic' },
];

export const RESEARCH_OPTIONS = [
  { label: 'Last 7 Days', value: 'last_7_days' },
  { label: 'Last 30 Days', value: 'last_30_days' },
];

export const FILTER_OPTIONS = [
  { label: 'All Keyword Types', value: 'all' },
  { label: 'Branded', value: 'branded' },
  { label: 'Generic', value: 'generic' },
];

export const INTENT_TABLE_HEADERS = [
  {
    columnName: 'Keywords',
    className: css({ width: '20%', cursor: 'pointer' }),
    key: 'normalized_keyword',
  },
  {
    columnName: '',
    className: css({ width: '5%' }),
  },
  {
    columnName: 'Keyword Type',
    className: css({ width: '10%', cursor: 'pointer' }),
    key: 'category',
  },
  {
    columnName: 'Research Accounts',
    className: css({ width: '13%' }),
  },
  {
    columnName: 'Keyword Groups',
    className: css({ width: '20%' }),
  },
  {
    columnName: 'Product Category',
    className: css({ width: '15%', marginRight: '3px', paddingLeft: '15px' }),
  },
  {
    columnName: 'Created On',
    className: css({ width: '10%', cursor: 'pointer' }),
    key: 'client_added',
  },
];

export const GROUP_TABLE_HEADERS = [
  {
    columnName: 'Keyword Groups',
    className: css({ width: '25%', cursor: 'pointer' }),
    key: 'name',
  },
  {
    columnName: '',
    className: css({ width: '10%' }),
  },
  {
    columnName: 'No. Of Keywords',
    className: css({ width: '13%', cursor: 'pointer' }),
    key: 'keyword_count',
  },
  {
    columnName: 'Research Accounts',
    className: css({ width: '17%' }),
  },
  {
    columnName: 'Last Updated On',
    className: css({ width: '15%', marginRight: '3px', cursor: 'pointer' }),
    key: 'updated',
  },
  {
    columnName: 'Created By',
    className: css({ width: '17%', cursor: 'pointer' }),
    key: 'username',
  },
];

export const DEACTIVATED_TABLE_HEADERS = [
  {
    columnName: 'Keywords',
    className: css({ width: '20%', cursor: 'pointer' }),
    key: 'normalized_keyword',
  },
  {
    columnName: 'Keyword Type',
    className: css({ width: '15%', cursor: 'pointer', paddingLeft: '35px' }),
    key: 'category',
  },
  {
    columnName: 'Deactivated On',
    className: css({ width: '15%', cursor: 'pointer' }),
    key: 'updated',
  },
  {
    columnName: 'Deactivated By',
    className: css({ width: '20%', cursor: 'pointer' }),
    key: 'updated_by',
  },
  {
    columnName: 'Product Category',
    className: css({ width: '20%' }),
  },
  {
    columnName: '',
    className: css({ width: '5%' }),
  },
];

// For use when checking if a page 'has filters', ignore filters like is_deleted
export const uniquePageFilters =
  ['is_deleted', 'keyword_item__keyword_set_id', 'sortBy', 'filterBy'];

export const DOWNLOAD_LIMIT = 100000;

// eslint-disable-next-line max-len
export const REC_KEYWORDS_QUERY = 'query KeywordRecommendationsQuery($inputKeywords: [String]!, $limit: Int) { keywordRecommendations(inputKeywords: $inputKeywords, limit: $limit) { value, strength}}';

// eslint-disable-next-line max-len
export const ORG_PRODUCTS_QUERY = 'query { products(platformOnlyProducts: false) {name, displayName, isDeleted, id } }';
