import { takeLatest, select, call } from 'redux-saga/effects';
import { actionTypes } from './modules';
import { actionTypes as segmentActionTypes } from 'routes/Segments/routes/Manage/modules';
import mapfp from 'lodash/fp/map';
import sortByfp from 'lodash/fp/sortBy';
import { flow, isFunction, filter, mapKeys, upperCase, capitalize, forEach } from 'lodash';
import { unparse } from 'papaparse';
import FileSaver from 'file-saver';
import moment from 'moment';
import {
  CREATED_OPPS,
  NO_OPPS,
  ACTIVITY_OPP,
  CAMPAIGN_TO_OPP,
  WEB_MATCH_RATES,
  CAMPAIGN_TO_OPPORTUNITY_LIFETIME,
} from 'routes/Reports/routes/Reports/routes/Value/constants';
import { MID_RULES_KEY } from
  'routes/Settings/routes/Blacklist/containers/CompanyBlacklist/components/Rules/constants';
import {
  STATUS_LABEL_MAP,
} from 'routes/Settings/routes/UserManagementV2/constants';
import {
  KEYWORDS,
  DEACTIVATED,
  GROUP_KW_LIST,
  GROUPS,
} from 'routes/Settings/routes/Keywords/constants';

import {
  appsListSelector,
} from 'routes/Settings/routes/UserManagementV2/selectors';
import { fetchSaga } from 'store/sagas';

const { DOWNLOAD } = actionTypes;
const { DOWNLOAD_ACCOUNTS } = segmentActionTypes;

const sortKeywords = (keywords, products, pageKey) => {
  switch (pageKey) {
    case GROUPS: {
      const header = 'New Keyword'.padEnd(50);
      return flow(
        mapfp((keyword) => ({ [header]: keyword.keyword,
        })))(keywords);
    }
    case KEYWORDS:
    case GROUP_KW_LIST: {
      const groupHeader = 'Keyword Groups'.padEnd(50);
      const productHeader = 'Keyword Products'.padEnd(50);
      return flow(
        mapfp((keyword) => {
          const updatedKeywordProducts = keyword.keyword_products.map((kw_product) =>
            products.find((p) => p.value === kw_product.product)?.label || '');
          // Extra spaces are hack for longer columns
          return {
            Keyword: keyword.keyword,
            Category: capitalize(keyword.category),
            Created: keyword.created,
            [groupHeader]: keyword.keyword_groups.map((v) => v.name).join('\n'),
            [productHeader]: updatedKeywordProducts.join('\n'),
          };
        }),
        sortByfp(['Keyword'])
      )(keywords);
    }
    case DEACTIVATED:
      return flow(
        mapfp((keyword) => ({
          Keyword: keyword.keyword,
          Category: capitalize(keyword.category),
          Created: keyword.created,
          'Deactivated By': keyword.updated_by || '--',
        })),
        sortByfp(['Keyword'])
      )(keywords);
    default:
      return flow(
        mapfp((keyword) => ({
          Keyword: keyword.keyword,
          Category: keyword.category,
          Total_Accounts: keyword.account_count,
          Created: keyword.created,
        })),
        sortByfp(['Keyword'])
      )(keywords);
  }
};

const mapRecommendedKeywords = (recKeywords) => mapfp((k) => ({
  Keyword: k.value,
}))(recKeywords);

const mapPreviewBlacklistAccountsData = (accounts, tableType) => {
  // Excel has this weird bug where an empty array []
  // shows up as some weird non ascii chars so this is the workaround.
  if (accounts.length === 0) {
    return [{
      'CRM Account ID': '',
      'CRM Account Name': '',
      'CRM Account Website': '',
      'CRM Account Country': '',
    }];
  }

  if (tableType === MID_RULES_KEY) {
    return mapfp((account) => ({
      'CRM Account ID': account.external_id,
      'CRM Account Name': account.crm_name,
      'CRM Account Website': account.crm_domain,
      'CRM Account Country': account.crm_country,
      '6sense Account Name': account.name,
      '6sense Account Website': account.domain,
      '6sense Account Country': account.country,
    }))(accounts);
  }
  return mapfp((account) => ({
    'CRM Account ID': account.external_id,
    'CRM Account Name': account.crm_name,
    'CRM Account Website': account.crm_domain,
    'CRM Account Country': account.crm_country,
  }))(accounts);
};

const mapSampleOpData = (accounts) => {
  const month = moment().format('MMM');
  return mapfp((account) => ({
    Product: account.product,
    'Account Name': account.account_name,
    'Account Website': account.account_website,
    'Account Country': account.account_country,
    'CRM Account ID': account.crm_account_id,
    '6sense ID': account['6sense_id'],
    'Opp Name': account.opp_name,
    'Opp ID': account.opportunity_id,
    'Opp Type': account.opportunity_type,
    [`Opp Stage (as of ${month} 1st)`]: account.opp_stage,
    'Opp Open Date': account.opp_open_date,
    [`Opp Amount (as of ${month} 1st)`]: account.opp_amount,
    'Max Buying Stage': account.buying_stage_oppty_max === '1-Target' ? 'Target'
      : account.buying_stage_oppty_max,
    'Days to open after Max Buying Stage Prediction': account.days_before_open,
    'First Worked Date': account.first_worked_dt,
    [`Closed (as of ${month} 1st)`]: account.closed === '0' ? 'No' : 'Yes',
    [`Won (as of ${month} 1st)`]: account.won === '0' ? 'No' : 'Yes',
    'Close Date': account.opp_close_date,
    'Relevant Opportunity': account.relevant_opportunity === '0' ? 'No' : 'Yes',
  }))(accounts);
};

const mapVmDownloadData = (accounts, metricName, selectedMonth) => {
  const month = moment().format('MMM');
  const selMonth = selectedMonth && selectedMonth.split('_')[0];
  switch (metricName) {
    case CREATED_OPPS:
      return mapfp((account) => ({
        Product: account.product,
        'Account Name': account.account_name,
        'Account Website': account.account_website,
        'Account Country': account.account_country,
        'CRM Account ID': account.crm_account_id,
        '6sense ID': account['6sense_id'],
        'Opp Name': account.opp_name,
        'Opp ID': account.opportunity_id,
        'Opp Type': account.opportunity_type,
        [`Opp Stage (as of ${month} 1st)`]: account.opp_stage,
        'Opp Open Date': account.opp_open_date,
        [`Opp Amount (as of ${month} 1st)`]: account.opp_amount,
        'Max Buying Stage': account.buying_stage_oppty_max === '1-Target' ? 'Target'
          : account.buying_stage_oppty_max,
        'Days to open after Max Buying Stage Prediction': account.days_before_open,
        'First Worked Date': account.first_worked_dt,
        [`Closed (as of ${month} 1st)`]: account.closed === '0' ? 'No' : 'Yes',
        [`Won (as of ${month} 1st)`]: account.won === '0' ? 'No' : 'Yes',
        'Close Date': account.opp_close_date,
        'Relevant Opportunity': account.relevant_opportunity === '0' ? 'No' : 'Yes',
      }))(accounts);
    case CAMPAIGN_TO_OPPORTUNITY_LIFETIME:
      return mapfp((account) => ({
        'Existing Opp Status':
          account.opportunity_window === 'Existing' ? 'Existing Open Opp' : 'No Open Opp',
        'Account Name': account.account_name,
        'Account Website': account.account_website,
        'Account Country': account.account_country,
        'CRM Account ID': account.crm_account_id,
        '6sense ID': account['6sense_id'],
        'Opp Name': account.opportunity_name,
        'Opp ID': account.opportunity_id,
        'Opp Type': account.opportunity_type,
        'Opp Stage': account.current_stage,
        Engaged: account.engaged_opportunities,
        'Increased Engagement': account.increased_engagement_opportunities,
        Closed: account.opportunity_closed,
        Won: account.opportunity_won,
        Pipeline: account.pipeline,
        'Close Date': account.opportunity_closed_date,
      }))(accounts);
    case NO_OPPS:
      return mapfp((account) => ({
        Product: account.product,
        'Account Name': account.account_name,
        'Account Website': account.account_website,
        'Account Country': account.account_country,
        'CRM Account ID': account.crm_account_id,
        '6sense ID': account['6sense_id'],
        [`Max Buying Stage (as of ${selMonth} 1st)`]: account.buying_stage_month_max,
        'First Date of Max Buying Stage': account.buying_stage_month_max_dt,
      }))(accounts);
    case ACTIVITY_OPP:
      return mapfp((account) => ({
        Product: account.product,
        'Account Name': account.account_name,
        'Account Website': account.account_website,
        'Account Country': account.account_country,
        'CRM Account ID': account.crm_account_id,
        '6sense ID': account['6sense_id'],
        'Opp Name': account.opp_name,
        'Opp ID': account.opportunity_id,
        'Opp Type': account.opportunity_type,
        'Opp Phase': account.opp_phase === '1-Before' ? 'Pre-Pipeline' : 'Post-Pipeline',
        'Anonymous Research Activities': account.anonymous_research_activity === '0' ? 'No' : 'Yes',
        'Anonymous Web Engagements': account.anonymous_web_engaged === '0' ? 'No' : 'Yes',
        'Known Web Engagements': account.known_web_engaged === '0' ? 'No' : 'Yes',
        'Marketing Reach Activities': account.marketing_reached === '0' ? 'No' : 'Yes',
        'Marketing Engagement Activities': account.marketing_engaged === '0' ? 'No' : 'Yes',
        'Sales Reach Activities': account.sales_reached === '0' ? 'No' : 'Yes',
        'Sales Engagement Activities': account.sales_engaged === '0' ? 'No' : 'Yes',
        'External Display Impressions': account.external_campaign_reached === '0' ? 'No' : 'Yes',
        'External Display Engagements': account.external_campaign_engaged === '0' ? 'No' : 'Yes',
        '6sense Display Impressions': account['6sense_campaign_reached'] === '0' ? 'No' : 'Yes',
        '6sense Display Engagements': account['6sense_campaign_engaged'] === '0' ? 'No' : 'Yes',
      }))(accounts);
    case CAMPAIGN_TO_OPP:
      return mapfp((account) => ({
        [`Existing Opp Status (as of ${selMonth} 1st)`]:
          account.opportunity_window === 'Existing' ? 'Existing Open Opp' : 'No Open Opp',
        'Account Name': account.account_name,
        'Account Website': account.account_website,
        'Account Country': account.account_country,
        'CRM Account ID': account.crm_account_id,
        '6sense ID': account['6sense_id'],
        'Opp Name': account.opportunity_name,
        'Opp ID': account.opportunity_id,
        'Opp Type': account.opportunity_type,
        [`Opp Stage (as of ${month} 1st)`]: account.current_stage,
        Engaged: account.engaged_opportunities,
        'Increased Engagement': account.increased_engagement_opportunities,
        [`Closed (as of ${month} 1st)`]: account.opportunity_closed,
        [`Won (as of ${month} 1st)`]: account.opportunity_won,
        [`Pipeline (as of ${month} 1st)`]: account.pipeline,
        [`Close Date (as of ${month} 1st)`]: account.opportunity_closed_date,
      }))(accounts);
    case WEB_MATCH_RATES:
      return mapfp((account) => ({
        Month: account.month,
        'Total Pageviews': account.total_pageviews,
        'Matched Pageviews': account.matched_pageviews,
        'Pageview Match Rate': account.pageview_match_rate,
        'Distinct Visitors': account.distinct_visitors,
        'Matched Visitors': account.matched_visitors,
        'Visitor Match Rate': account.visitor_match_rate,
        'Accounts Identified': account.accounts_identified,
      }))(accounts);
    default:
      return null;
  }
};

const mapAccounts = (accounts, byCRMID) => {
  const formatAccount = (account) => {
    const {
      mid,
      firm_name: firmName,
      name,
      firm_country: firmCountry,
      country,
      firm_domain: firmDomain,
      website,
      firm_revenue_range: firmRevenueRange,
      firm_employee_range: firmEmployeeRange,
      firm_industry: firmIndustry,
      firm_industry_v2: firmIndustryV2 = [],
      external_id: externalId,
      crm_name: crmName,
      crm_website: crmWebsite,
      crm_country: crmCountry,
    } = account;

    if (byCRMID) {
      if (externalId) {
        return {
          'CRM Account ID': externalId,
          '6sense ID': mid,
          'CRM Account Name': crmName,
          'CRM Account Country': crmCountry,
          'CRM Account Domain': crmWebsite,
        };
      }
      return null;
    }
    const rootIndustries = new Set();
    firmIndustryV2.forEach(([industry]) => rootIndustries.add(industry));
    return {
      '6sense Company Name': firmName || name,
      '6sense Country': firmCountry || country,
      '6sense Domain': firmDomain || website,
      '6sense Revenue Range': firmRevenueRange,
      '6sense Employee Range': firmEmployeeRange,
      Industry: Array.from(rootIndustries).join(', '),
      'Industry (Legacy)': firmIndustry,
    };
  };
  return filter(
    mapfp(formatAccount)(accounts), (acc) => !!acc);
};

const mapAnalytics = (accounts) =>
  mapfp((account) => ({
    'Campaign Id': account.campaign_id,
    'Campaign Name': account.campaign_name,
    'Campaign URL': account.campaign_url,
    'Ad Requests': account.video_service,
    Impressions: account.media_impression,
    Clicks: account.media_click,
    Error: account.video_error,
    Start: account.video_start,
    Skip: account.video_skip,
    '25% Complete': account.video_first_quartile,
    '50% Complete': account.video_half_point,
    '75 % Complete': account.video_third_quartile,
    '100% Complete': account.video_completion,
  }))(accounts);

const upperCaseHeaders = (rows) =>
  mapfp((row) => mapKeys(row, (v, k) => upperCase(k)))(rows);

const mapUsers = (users, orgName, appsList) => flow(
  mapfp((user = {}) => {
    let mapedObj = {
      Email: user.email,
      Username: user.username,
      Status: STATUS_LABEL_MAP[user.status],
      // Role: user.isOwner ? 'Owner' : user.role,
    };
    appsList.forEach(({ id: appId, name }) => {
      mapedObj[`${name} Role`] = user.isOwner ? 'Owner' : user.roleNameMap[appId];
      if (user.roleLicenseLabelMap[appId]) {
        mapedObj[`${name} Role`] += user.isOwner ? '' : ` ${user.roleLicenseLabelMap[appId]}`;
      }
    });
    mapedObj = {
      ...mapedObj,
      'Invite Date': user.invitedDate ? moment(user.invitedDate).format('MMM DD, YYYY') : '',
      'Invited By': user.invitedBy,
      'Last Login': user.lastLogin ? moment(user.lastLogin).format('MMM DD, YYYY') : '',
      Organization: orgName,
    };
    return mapedObj;
  }),
  sortByfp(['Status', 'Username']),
)(users);

const sortAccounts = (accounts) => sortByfp(['Name'])(accounts);

export function formatNumber(num) {
  if (isNaN(+num)) {
    return num;
  }
  return (+num).toLocaleString();
}
function sanitizeValue(value) {
  if (value === undefined || value === null || (`${value}`).trim() === '') {
    return '';
  }
  if (isNaN(+value)) {
    return (`${value}`).trim();
  }
  const valueFix = Number.isInteger(+value) ? value : (+value).toFixed(2);
  return formatNumber(valueFix);
}

const mapAccountsAndContact = ({
  chartsList,
  categories,
  seriesTitle,
  headers,
}) => {
  const allData = [...headers];
  forEach(chartsList, ({ data, option }) => {
    const { plotValue } = data;
    if (plotValue) {
      let label = '';
      if (option) {
        label = option.label.trim();
      }
      allData.push([
        `Baseline Conversion Rate - ${label}`,
        `${sanitizeValue(plotValue)}%`,
      ]);
    }
  });
  allData.push([]);
  allData.push([seriesTitle, ...categories]);
  forEach(chartsList, ({ data, option }) => {
    const { series } = data;
    let label = '';
    if (option) {
      label = option.label.trim();
    }
    const seriesIteration = ({ name, data: seriesData }) => {
      if (!seriesData || seriesData.length <= 0) {
        return;
      }
      const cellArray = [];
      let cellName = '';
      if (name && name.trim() && label && label.trim()) {
        cellName = `${name} - ${label}`;
      } else if (name && name.trim()) {
        cellName = name;
      } else {
        cellName = label;
      }
      cellArray.push(cellName);
      forEach(seriesData, ({ extraValue, extraValueUnit, valueUnit, y }) => {
        const ySanitize = sanitizeValue(y);
        const valueUnitSanitize = sanitizeValue(valueUnit);
        const extraValueSanitize = sanitizeValue(extraValue);
        const extraValueUnitSanitize = sanitizeValue(extraValueUnit);
        if (extraValue) {
          cellArray.push(
            `${ySanitize}${valueUnitSanitize} (${extraValueSanitize}${extraValueUnitSanitize})`
          );
        } else {
          cellArray.push(`${ySanitize}${valueUnitSanitize}`);
        }
      });
      allData.push(cellArray);
    };
    forEach(series, (ser, index) => {
      if (index%2 === 0) {
        seriesIteration(ser);
      }
    });

    forEach(series, (ser, index) => {
      if (index%2 !== 0) {
        seriesIteration(ser);
      }
    });
  });
  return allData;
};

const saveFile = (accounts, fileName, returnFileDetail = false) => {
  const listCSV = unparse(accounts);
  const blob = new Blob([listCSV], { type: 'text/csv;charset=utf-8' });
  if (returnFileDetail) {
    FileSaver.saveAs(blob, fileName);
    return {
      file_size: blob.size,
      file_name: fileName,
    };
  }
  return FileSaver.saveAs(blob, fileName);
};

function* logReportDownloadEvent(request, logReportDownloadEventObject, fileDetail) {
  if (logReportDownloadEventObject?.startTime && fileDetail) {
    const { startTime, objectId, logDownloadEventEndpoint } = logReportDownloadEventObject;
    const endTime = new Date().getTime();
    try {
      yield call(request, logDownloadEventEndpoint, 'POST', {
        body: JSON.stringify({
          event_type: 'download_event',
          duration: endTime - startTime,
          object_id: objectId,
          ...fileDetail,
        }),
      });
    } catch (e) {
      console.error('audit event error', e);
    }
  }
}

export function* downloadComponent(request, action) {
  const { componentReference, fileName, ...rest } = action;

  if (isFunction(componentReference)) {
    const itemsToDownload = yield select(componentReference);
    const appsLists = yield select(appsListSelector);
    if (rest[0] === 'users') {
      const { 1: orgName } = rest; // w1 2019 cleanup task
      return saveFile(mapUsers(itemsToDownload, orgName, appsLists), fileName);
    }

    return saveFile(sortAccounts(itemsToDownload), fileName);
  }

  if (rest[0] === 'downloadAccountsAndContactCSV') {
    return saveFile(mapAccountsAndContact(componentReference), fileName);
  }
  if (rest[0] === 'downloadSignalsCSV') {
    return saveFile(componentReference, fileName);
  }

  if (rest[0] === 'keywords') {
    return saveFile(sortKeywords(componentReference, rest[1], rest[2]), fileName);
  }

  if (rest[0] === 'rec_keywords') {
    return saveFile(mapRecommendedKeywords(componentReference), fileName);
  }

  if (rest[0] === 'analytics') {
    return saveFile(mapAnalytics(componentReference), fileName);
  }

  if (rest[0] === 'vm_report') {
    const metricName = rest[1];
    const selectedMonth = rest[2];
    const logReportDownloadEventObject = rest[3] || {};
    const fileDetail = saveFile(
      mapVmDownloadData(componentReference, metricName, selectedMonth),
      fileName,
      Boolean(logReportDownloadEventObject?.startTime)
    );
    yield call(logReportDownloadEvent, request, logReportDownloadEventObject, fileDetail);
    return fileDetail;
  }

  if (rest[0] === 'preview_blacklist') {
    const tableType = rest[1];
    return saveFile(mapPreviewBlacklistAccountsData(componentReference, tableType), fileName);
  }

  if (rest[0] === 'sample_opps') {
    return saveFile(mapSampleOpData(componentReference), fileName);
  }

  if (rest[0] === 'report') {
    if (rest[1] === 'contacts-purchased-details') {
      return saveFile(upperCaseHeaders(componentReference), fileName);
    }
    const logReportDownloadEventObject = rest[3] || {};
    const fileDetail = saveFile(
      componentReference,
      fileName,
      Boolean(logReportDownloadEventObject?.startTime)
    );
    yield call(logReportDownloadEvent, request, logReportDownloadEventObject, fileDetail);
    return fileDetail;
  }
  if (rest[0] === 'map_errors') {
    return saveFile(componentReference, fileName);
  }

  const mappedAccounts = mapAccounts(componentReference, rest[1]);
  if (rest[0] === 'sorted') {
    return saveFile(mappedAccounts, fileName);
  }

  return saveFile(sortAccounts(mappedAccounts), fileName);
}

export function* watchDownload(request) {
  yield takeLatest([DOWNLOAD, DOWNLOAD_ACCOUNTS], downloadComponent, request);
}

export default [fetchSaga(watchDownload)];
