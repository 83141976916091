import { getAsyncInjectors } from 'store/utils';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'gartner-digital-markets',
    name: 'Gartner',
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const GartnerContainer = require('./containers/GartnerContainer');
          const sagas = require('../../PeerspotGartnerCommonConfig/sagas');
          const reducer = require('../../PeerspotGartnerCommonConfig/modules');
          injectReducer('buyer_discovery', reducer.default);
          injectSagas('buyer_discovery', sagas.default);
          cb(null, GartnerContainer.default);
        },
        'Gartner',
      );
    },
  };
}
