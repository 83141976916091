/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import styles from './UrlInputWithCopyEditPanel.module.scss';
import { ShowHideUnder, Row, Input, Icon, Link, Button, Tooltip2 } from 'v2-components';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'HOCS';
import { actions as globalActions } from 'modules/global';
import { connect } from 'react-redux';
import { classNames } from 'utils/utils';

const { showNotification: showNotificationAction } = globalActions;

const textAreaFormLayout = { rightExtraSize: '0%', inputSize: '100%' };

const UrlCopyEditPanelComponent = ({
    copyToClipboard,
    isCopied,
    showNotification,
    isEditMode,
    setEditMode,
    urlLinkId,
    hideCopyUrl,
    disableEditUrl,
    value,
    ...props
}) => {
  const [stagedUrlValue, setStagedUrlValue] = useState('');
  useEffect(() => () => setEditMode(false), []);

  const editUrl = () => {
    setStagedUrlValue(value);
    setEditMode(true);
  };

  const updateStagedUrl = (e) => setStagedUrlValue(e.target.value);
  const cancelStagedUrlUpdate = () => {
    setStagedUrlValue('');
    setEditMode(false);
  };
  const applyStagedUrlChange = () => {
    if (props.onChange) {
      props.onChange(stagedUrlValue);
    }
    setStagedUrlValue('');
    setEditMode(false);
  };

  return (<Row
    flexDirection={Row.FLEX_DIRECTION.COLUMN}
    className={styles.panelContainer}
  >
    {isEditMode ? (<Row flexDirection={Row.FLEX_DIRECTION.COLUMN}>
      <Input
        className={styles.textArea}
        type="textarea"
        formLayout={textAreaFormLayout}
        value={stagedUrlValue}
        onChange={updateStagedUrl}
      />
      <Row
        className={styles.actionBtns}
        justifyContent={Row.JUSTIFY_CONTENT.FLEX_END}
      >
        <Button onClick={cancelStagedUrlUpdate} className={styles.panelBtn}>
            Cancel
        </Button>
        <Button
          className={styles.applyBtn}
          type="primary"
          onClick={applyStagedUrlChange}
        >
            Apply
        </Button>
      </Row>
    </Row>) : (<Row
      alignItems={Row.ALIGN_ITEMS.CENTER}
    >
      <Icon type={'public'} />
      <Link
        id={urlLinkId}
        newWindow
        title={value}
        link={value}
        className={styles.urlLink}
      >
        {value}
      </Link>
      <Tooltip2 overlay={disableEditUrl ? 'disabled': ''}>
        <Icon
          type={'create'}
          className={classNames(styles.editUrl, disableEditUrl ? styles.disabledEditIcon: '')}
          onClick={disableEditUrl ? null : editUrl}
        />
      </Tooltip2>
      {!hideCopyUrl ? <Icon
        type={'content_copy'}
        className={styles.copyLink}
        onClick={() => {
          copyToClipboard(urlLinkId);
          showNotification('success', 'Url copied successfully');
        }}
      />: null}
    </Row>)}
  </Row>);
};

UrlCopyEditPanelComponent.propTypes = {
  value: PropTypes.string,
  copyToClipboard: PropTypes.func,
  isCopied: PropTypes.bool,
  showNotification: PropTypes.func,
  isEditMode: PropTypes.bool,
  setEditMode: PropTypes.func,
  onChange: PropTypes.func,
  urlLinkId: PropTypes.string,
  hideCopyUrl: PropTypes.bool,
  disableEditUrl: PropTypes.bool,
};

const UrlCopyEditPanel = CopyToClipboard({ timeout: 3000 })(UrlCopyEditPanelComponent);

const WrappedUrlInputField = (props) => {
  const { urlInputField: UrlInputField } = props;

  const onKeyUp = (e) => {
    if (props.onKeyUp) {
      props.onKeyUp(e);
    }
    props.closeFn();
  };

  const showPanel = () => {
    if (props.value) {
      props.showFn();
    }
  };

  return (
    <UrlInputField {...props} onClick={showPanel} onKeyUp={onKeyUp} />
  );
};

WrappedUrlInputField.propTypes = {
  urlInputField: PropTypes.elementType,
  showFn: PropTypes.func,
  closeFn: PropTypes.func,
  onKeyUp: PropTypes.func,
  value: PropTypes.string,
};

const UrlInputWithCopyEditPanel = (props) => {
  const [isEditMode, setEditMode] = useState(false);

  return (
    <span key={props.key}>
      <ShowHideUnder
        Toggle={WrappedUrlInputField}
        toggleProps={{ ...props, disabled: props.disabled || isEditMode }}
        ToShow={UrlCopyEditPanel}
        toShowProps={{ ...props, isEditMode, setEditMode }}
        useDocument
        containerClassName={styles.container}
      />
    </span>
  );
};

UrlInputWithCopyEditPanel.propTypes = {
  disabled: PropTypes.bool,
  key: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  value: PropTypes.string,
  hideCopyUrl: PropTypes.bool,
  disableEditUrl: PropTypes.bool,
};

export default connect(
    null,
    { showNotification: showNotificationAction }
)(UrlInputWithCopyEditPanel);
