import rollbar from 'rollbar/dist/rollbar.umd.min.js';
import LogRocket from 'logrocket';

const rollbarConfig = {
  ignoredMessages: [
    'should_do_lastpass_here',
    'filter cannot be used',
    // 'null', jk :(
  ],
  accessToken: process.env.ROLLBAR_ACCESS_KEY_AA_UI_CLIENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: true,
  payload: {
    environment: window.process.env.SIXSENSE_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.DIST_VERSION,
        // Optionally have Rollbar guess which frames the error was thrown from
        // when the browser does not provide line and column numbers.
        guess_uncaught_frames: true,
      },
    },
  },
  // this will tag the rollbar to the logrocket session
  transform: (obj) => ({
    ...obj,
    sessionURL: LogRocket?.sessionURL || obj?.sessionURL,
  }),
};

const rollbarClient = rollbar.init(rollbarConfig);

if (process.env.NODE_ENV === 'development') {
  rollbarClient.configure({ enabled: false });
}

export default rollbarClient;
