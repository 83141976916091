import React, { useState } from "react";
import {
  Text,
  Badge,
  Flex,
  Button,
  Link,
  Modal,
  Card,
  CloseButton,
  Tooltip,
} from "@sixsense/core/components";
import { TextColor } from "@sixsense/core/style/variables";
import { CAMPAIGN_SOURCE } from "../../../constants";
import { truncateString } from "utils/utils";
import { cx, css } from "styles/emotion";
import { NA } from "./common/NA";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { isSystemSegment } from "../../../utils";
import { pxToRem } from "@sixsense/core/style";
import { isSegmentv2 } from "routes/AdvertisingDS/utils";
import { SegmentRoute } from "@sixsense/core/segments/management/constants";
import PropTypes from "prop-types";

const SegmentNameDisplay = ({ segment = {}, truncateName = false }) =>
  isSystemSegment(segment) ? (
    <Tooltip overlay="Segments generated from Orchestrations and Workflows are not clickable.">
      <Text size={14} className={cx(css({ width: "fit-content" }), "py1")}>
        {truncateName ? truncateString(segment.name, 20) : segment.name}
      </Text>
    </Tooltip>
  ) : (
    <a
      key={segment.id}
      href={
        isSegmentv2(segment.id)
          ? SegmentRoute.EDIT(segment.id, "accounts")
          : `/segments/segment/${segment.id}/accounts/`
      }
      target="_blank"
      rel="noopener noreferrer"
      className="py1"
    >
      <Link size={14} title={segment.name}>
        {truncateName ? truncateString(segment.name, 20) : segment.name}
      </Link>
    </a>
  );

SegmentNameDisplay.propTypes = {
  segment: PropTypes.object,
  truncateName: PropTypes.bool,
};

// eslint-disable-next-line react/prop-types
const LocalModal = ({ children, content, title }) => {
  const [visible, setVisible] = useState(false);

  const footer = (
    <Flex justifyContent="flex-end">
      <Button
        hierarchy="primary"
        size="small"
        onClick={() => setVisible(false)}
      >
        Done
      </Button>
    </Flex>
  );

  return (
    <Flex>
      <Modal isOpen={visible} onRequestClose={() => setVisible(false)}>
        <Card className={css({ width: pxToRem(450) })}>
          <Card.Header
            title={title}
            actions={[
              <CloseButton title="Close" onClick={() => setVisible(false)} />,
            ]}
          />
          <Card.Content>{content}</Card.Content>
          <Card.Footer>{footer}</Card.Footer>
        </Card>
      </Modal>
      <div
        onClick={() => setVisible(true)}
        className={css({ ":hover": { cursor: "pointer" } })}
      >
        {children}
      </div>
    </Flex>
  );
};

export const SEGMENT_NAME = {
  key: "segment-name",
  csvColumnKey: "segment_name",
  title: <Label name={CAMPAIGN_LABELS.segmentName} />,
  dataIndex: "segment",
  defaultSelected: true,
  customizable: true,
  width: pxToRem(200),
  render: (segment, campaign) => {
    const { campaign_source } = campaign;

    if (
      (!segment && !campaign.ad_network_segments?.length) ||
      campaign_source === CAMPAIGN_SOURCE.EXTERNAL
    ) {
      return <NA />;
    }

    let all_segments = [];

    if (campaign.ad_network_segments?.length) {
      all_segments = campaign.ad_network_segments;
    }

    if (segment) all_segments.push(segment);
    const firstSegment = all_segments[0];
    const hasMultipleSegments = Boolean(all_segments.length > 1);

    return (
      <Flex alignItems="center">
        <SegmentNameDisplay
          segment={firstSegment}
          truncateName={hasMultipleSegments}
        />
        &nbsp;
        {all_segments.length > 1 && (
          <LocalModal
            content={
              <Flex direction="column">
                {all_segments.map((seg) => (
                  <SegmentNameDisplay segment={seg} truncateName={false} />
                ))}
              </Flex>
            }
            title={
              <Flex direction="column">
                <Text.Title size="xl" color={TextColor.HEAD}>
                  Segments
                </Text.Title>
                <Text.Body>{campaign.campaign_name}</Text.Body>
              </Flex>
            }
          >
            <Badge color="gray" mode="light">
              +{all_segments.length - 1}
            </Badge>
          </LocalModal>
        )}
      </Flex>
    );
  },
  align: "left",
};
