import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { LayoutCard } from 'HOCS';
import { Checkbox, Col, Row, Text } from 'v2-components';
import { pxToRem } from '@sixsense/core/style';
import { css } from 'styles/emotion';
import ExampleModal from './ExampleModal';
import { AlertTriangle } from '@sixsense/core/icons';
import { Flex } from '@sixsense/core/components';
import { SalesPermission, usePermissionCheck } from '@sixsense/rbac';
import { isViewOnlyEnabledFor6senseAccessSelector } from 'modules/user/selectors';
import { useSelector } from '@sixsense/core/versioned/react-redux';


const styles = {
  itemWrap: css({
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    paddingTop: pxToRem(28),
    paddingLeft: pxToRem(20),
    paddingBottom: pxToRem(27),
    margin: '0px',
  }),
  navItem: css({
    marginRight: pxToRem(20),
    marginLeft: pxToRem(0),
    fontSize: pxToRem(14),
    marginBottom: pxToRem(4),
  }),
  message: css({
    marginTop: pxToRem(10),
  }),
  warning: css({
    fontSize: pxToRem(14),
    lineHeight: pxToRem(21),
    color: '#CA8504',
    paddingTop: pxToRem(2),
  }),
  warningMessage: css({
    maxWidth: pxToRem(276),
  }),
  alertIcon: css({
    paddingTop: pxToRem(4),
  }),
  contentsList: css({
    paddingTop: pxToRem(20),
  }),
  content: css({
    display: 'flex',
  }),
};

const showComponentsCheckBox = (el, onChange, config, warningMessages, disabled) => (
  <span key={el.value}>
    <Checkbox
      disabled={disabled}
      key={el.value}
      onChange={() => onChange({ [el.value]: !config[el.value] })}
      checked={config[el.value]}
      label={el.label}
      className={styles.navItem}
    />
    {el.examples ? <ExampleModal content={el} /> : null}
    {config[el.value] && warningMessages[el.value] && (
    <Flex className={styles.warning} gap={8}>
      <div className={styles.alertIcon}><AlertTriangle size={16} color="Warning" /></div>
      <div className={styles.warningMessage}>{warningMessages[el.value] }</div>
    </Flex>
            )}
  </span>
);

const NavigationCardComponent = ({ contents, onChange, config, warningMessages={} }) => {
  const hasEditPermission = usePermissionCheck([SalesPermission.SETTINGS_COMPONENTS_METRICS_EDIT]);
  const isViewOnlyEnabled = useSelector(isViewOnlyEnabledFor6senseAccessSelector);
  return (<div className={styles.itemWrap} >
    {contents.map((el, i) =>
      <Row className={i ? styles.contentsList : ''} key={el.label}>
        {el.label && <Col flexBasis={'10%'} >
          <Text weight="bold">{el.label}</Text>
          </Col>}
        <Col className={styles.content} flexBasis={'90%'} >
          {el.list.map((item) => (
            item.isVisible &&
             showComponentsCheckBox(item,
              onChange,
              config,
              warningMessages,
              !hasEditPermission || isViewOnlyEnabled)
          ))}
        </Col>
      </Row>
    )}
  </div>
  );
};

NavigationCardComponent.propTypes = {
  config: PropTypes.object,
  onChange: PropTypes.func,
  contents: PropTypes.array,
  warningMessages: PropTypes.object,
};

export const NavigationCard = compose(
  LayoutCard({
    size: LayoutCard.RESPONSIVE,
    alignment: 'start',
    headerBorder: true,
  })
)(NavigationCardComponent);
