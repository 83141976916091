import { getAsyncInjectors } from 'store/utils';
import { sagas as pollingSagas } from 'saga-polling';
import globalSagas from 'modules/global/sagas';
import userSagas from 'modules/user/sagas';
import segmentSagas from 'modules/segments/sagas';
import labelSagas from 'modules/labels/sagas';
import uploadSagas2 from 'v2-components/Upload2/sagas';
import uploadSagas from 'OLD_components/Upload/sagas';
import autoCompleteSagas from 'modules/autoComplete/sagas';
import fetchableSagas from 'modules/fetchable/sagas';
import authSagas from 'modules/auth/sagas';
import slackSagas from 'modules/slack/sagas';
import outreachSagas from 'modules/outreach/sagas';
import hubspotSagas from 'modules/hubspot/sagas';
import integrationsSagas from 'modules/integrations/sagas';
import globalSearchSagas from 'containers/Navbar/sagas';
import downloadSagas from 'v2-components/Download/sagas';
import accessDeniedSagas from 'v2-components/AccessDenied/sagas';
import DynamicListSagas from 'v2-components/DynamicList/sagas';
import CampaignCSVExportModalSagas from 'aa-components/CampaignCSVExportModal/sagas';
import { utmMacrosListStateGenerator } from 'aa-components/UTMBuilder/stateGenerators';
// eslint-disable-next-line max-len
import CampaignCSVExportDrawerSagas from './routes/AdvertisingDS/routes/Campaigns/containers/CampaignCSVExportDrawer/sagas';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */

export default function createSagas(store) {
  const { injectSagas } = getAsyncInjectors(store);
  // inject any other sagas here

  const rootSagas = [
    ...globalSagas,
    ...autoCompleteSagas,
    ...userSagas,
    ...segmentSagas,
    ...labelSagas,
    ...uploadSagas,
    ...uploadSagas2,
    ...authSagas,
    ...slackSagas,
    ...outreachSagas,
    ...fetchableSagas,
    ...downloadSagas,
    ...globalSearchSagas,
    ...pollingSagas,
    ...accessDeniedSagas,
    ...DynamicListSagas,
    ...CampaignCSVExportModalSagas,
    ...CampaignCSVExportDrawerSagas,
    utmMacrosListStateGenerator.saga,
    ...hubspotSagas,
    ...integrationsSagas,
  ];

  injectSagas('root', rootSagas);
}
