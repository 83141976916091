import { defaults as activityDefaults } from './containers/Activities/defaults';
import { defaults as personaDefaults } from './containers/Persona/defaults';

const baseDefaults = {
  insights: 'abm',
  productOptions: [],
  settingCustomDateRange: false,
};

export const defaults = {
  ...baseDefaults,
  ...activityDefaults,
  ...personaDefaults,
};
