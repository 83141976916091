/* eslint no-constant-condition: 0 */
import { select, call, take, put, fork, cancel, actionChannel, all } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { actionTypes, actions } from './modules';
import { taskSelector, channelTaskSelector } from './selectors';
import { SYNC_STATES } from './constants';
import { map } from 'lodash';

const {
  POLL_INIT,
  CANCEL_POLL,
} = actionTypes;

const {
  pollStart,
  heartbeat,
} = actions;

export function* pollSaga(action) {
  // These should never change
  const { channel, guid, toPoll, onSuccess, onFailure, config: { isDone, pollFrequency } } = action;
  try {
    while (true) {
      const result = yield call(toPoll);
      if (isDone(result)) {
        yield put(heartbeat(
          channel,
          guid,
          {
            sync_status: SYNC_STATES.DONE,
            result,
          }
        ));
        yield put(onSuccess(result));
        return result;
      }
      yield put(heartbeat(
        channel,
        guid,
        {
          sync_status: SYNC_STATES.IN_PROGRESS,
          timestamp: new Date().valueOf(),
          result,
        }
      ));
      yield delay(pollFrequency);
    }
  } catch (e) {
    yield put(onFailure(e));
  }
  return null;
}

export function* pollInitiator() {
  const pollChannel = yield actionChannel(POLL_INIT);
  while (true) {
    const action = yield take(pollChannel);
    const { channel, guid } = action;
    // If there is an old task cancel it
    const oldTask = yield select(taskSelector(channel)(guid));
    if (oldTask) {
      yield cancel(oldTask);
    }
    // Create new task
    const task = yield fork(pollSaga, action);
    yield put(pollStart(channel, guid, task));
  }
}

export function* pollCanceler() {
  const pollChannel = yield actionChannel(CANCEL_POLL);
  while (true) {
    const action = yield take(pollChannel);
    const { channel, guid } = action;
    let tasks;
    if (guid) {
      tasks = [yield select(taskSelector(channel)(guid))];
    } else {
      tasks = yield select(channelTaskSelector(channel));
    }
    yield all(map(tasks, (task) => cancel(task)));
  }
}

export default [
  pollInitiator,
  pollCanceler,
];
