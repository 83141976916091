import { useRequestValue, useSharedValue } from '@sixsense/core';
import { useOrg } from 'hooks';
import {
  keywordGroupSelectRequestState,
  keywordGroupSelectQueryState,
  selectGroupCounter,
} from '../state';

const useKeywordGroupSelectRequest = () => {
  const org = useOrg();
  const counter = useSharedValue(selectGroupCounter);
  const searchQuery = useSharedValue(keywordGroupSelectQueryState);

  const groupsResult = useRequestValue(
    keywordGroupSelectRequestState,
    {
      counter,
      orgId: org.id,
      searchQuery,
    }
  );

  return groupsResult;
};

export default useKeywordGroupSelectRequest;
