import { getAsyncInjectors } from 'store/utils';
import { combineReducers } from 'redux';
import { concat } from 'lodash';
import TableListRoute from './routes/TableList';
import TableEditRoute from './routes/TableEdit';
import TableCreateRoute from './routes/TableCreate';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  return {
    path: 'prepete',
    name: 'prepete',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/settings/standardization/prepete/table_list'),
    },
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const containers = require('./containers');

          const tableListSagas = require('./containers/TableList/sagas');
          const tableEditSagas = require('./containers/TableEdit/sagas');
          const tableCreateSagas = require('./containers/TableCreate/sagas');
          const baseSaga = require('./sagas');

          const tableListReducer = require('./containers/TableList/modules');
          const tableEditReducer = require('./containers/TableEdit/modules');
          const tableCreateReducer = require('./containers/TableCreate/modules');
          const baseReducer = require('./modules');

          const combinedReducers = combineReducers({
            tableList: tableListReducer.default,
            tableEdit: tableEditReducer.default,
            tableCreate: tableCreateReducer.default,
            base: baseReducer.default,
          });

          const combinedSagas = concat(
            tableListSagas.default,
            tableEditSagas.default,
            tableCreateSagas.default,
            baseSaga.default,
          );

          injectReducer('prepete', combinedReducers);
          injectSagas('prepete', combinedSagas);

          cb(null, containers.PrepeteContainer);
        },
        'settings_standardization_prepete',
      );
    },
    childRoutes: [TableListRoute(), TableEditRoute(), TableCreateRoute()],
  };
}
