import React from 'react';
import Text from 'v2-components/Text';

export const CAMPAIGN_ID = {
  sortByKey: 'id',
  customizable: false,
  key: 'campaign-id',
  csvColumnKey: 'id',
  title: 'Campaign ID',
  dataIndex: 'id',
  width: 150,
  render: (campaignId) => (<Text
    type={Text.TYPE.SUBBODY}
  >
    {campaignId}
  </Text>),
};
