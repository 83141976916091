import React from 'react';
import PropTypes from 'prop-types';
import { Pill, Text, Row } from 'v2-components';
import { getFileSizeInMb } from 'utils/utils';
import styles from './../AdPreviewWrapper.module.scss';

const AcceptedFileSize = (props) => {
  const {
    acceptedFileSize,
    placementType,
    customPillClass,
  } = props;
  let maxFileSizeLabel = `${acceptedFileSize.maxSize} KB`;
  const minFileSizeLabel = `${acceptedFileSize.minSize} KB`;
  if (placementType === 'video' || placementType === 'html5' || placementType === 'html5_dynamic') {
    maxFileSizeLabel = `${getFileSizeInMb(acceptedFileSize.maxSize)} MB`;
  }
  const acceptedFileMaxSizeView = (
    <Pill
      key={`file-size-${maxFileSizeLabel}`}
      notClickable
      inactive
      className={`${styles.pillClass} ${customPillClass || ''}`}
    >
      {maxFileSizeLabel}
    </Pill>
  );
  const acceptedFileMinSizeView = (
    <Pill
      key={`file-size-${minFileSizeLabel}`}
      notClickable
      inactive
      className={`${styles.pillClass} ${customPillClass || ''}`}
    >
      {minFileSizeLabel}
    </Pill>
  );
  return (
    <Row justifyContent={Row.JUSTIFY_CONTENT.FLEX_START} alignItems={Row.ALIGN_ITEMS.CENTER}>
      <Text>Min:</Text>
      {acceptedFileMinSizeView}
      <Text>Max:</Text>
      {acceptedFileMaxSizeView}
    </Row>
  );
};
AcceptedFileSize.propTypes = {
  acceptedFileSize: PropTypes.object,
  placementType: PropTypes.string,
  customPillClass: PropTypes.string,
};

export default AcceptedFileSize;
