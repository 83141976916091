import { getAsyncInjectors } from 'store/utils';
import AnalyticsRoute from '../Analytics';
import AccountsRoute from './routes/Accounts';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: 'segment/:id',
    name: 'Segment',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');

        // analytics saga/reducer loaded at parent segments level
        const accountListReducer = require('./routes/Accounts/modules');
        const accountListSagas = require('./routes/Accounts/sagas');

        injectReducer('accountList', accountListReducer.default);
        injectSagas('accountList', accountListSagas.default);

        cb(null, containers.SegmentDetailSwitcher);
      }, 'segmentsSegment');
    },
    childRoutes: [
      AnalyticsRoute(store),
      AccountsRoute(store),
    ],
  };
}
