import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';

const ALIGN_SELF = Object.freeze({
  FLEX_START: 'flex-start',
  FLEX_END: 'flex-end',
  CENTER: 'center',
  BASELINE: 'baseline',
  STRETCH: 'stretch',
});

function Col(props) {
  const {
    id,
    className,
    children,
    alignSelf,
    maxWidth = '100%',
    flexGrow = 1,
    flexShrink = 0,
    flexBasis = 'auto',
    style,
    onClick,
    ...rest
  } = props;

  const ColStyle = {
    maxWidth,
    flex: `${flexGrow} ${flexShrink} ${flexBasis}`,
    alignSelf,
    ...style,
  };

  return (
    <div
      id={id}
      style={ColStyle}
      className={className}
      onClick={onClick}
      {...rest}
    >
      {children}
    </div>
  );
}

// Refer to AntD modal props
Col.propTypes = {
  className: PropTypes.string,
  maxWidth: PropTypes.string,
  flexGrow: PropTypes.number,
  flexShrink: PropTypes.number,
  flexBasis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  alignSelf: PropTypes.oneOf(values(ALIGN_SELF)),
  style: PropTypes.any,
  onClick: PropTypes.func,
  id: PropTypes.any,
};

Col.ALIGN_SELF = ALIGN_SELF;

export default Col;
