import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/core.scss';
import { connect } from 'react-redux';
import { actions } from '../Navbar/modules';
import AccountIcon from 'images/nav_account_icon.svg';
import { Dropdown, TopBar } from '@sixsense/core/components';
import { LogOut } from '@sixsense/core/icons';

function AccountComponent(props) {
  const { username } = props;

  return (
    <Dropdown
      menu={(
        <Dropdown.Menu>
          <Dropdown.Menu.Header title={username} />
          <Dropdown.Menu.Divider />
          <Dropdown.Menu.Item
            before={<LogOut size={16} />}
            onClick={() => {
              window.location = '/logout/';
            }}
          >
            Log out
          </Dropdown.Menu.Item>
        </Dropdown.Menu>
      )}
    >
      <TopBar.Avatar src={AccountIcon} />
    </Dropdown>
  );
}

AccountComponent.propTypes = {
  username: PropTypes.string,
};

AccountComponent.defaultProps = {};

const mapStateToProps = (state) => ({
  username: state.user.user.username,
});

export default connect(mapStateToProps, actions)(AccountComponent);
