import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, SearchBar } from 'v2-components';
import TableLayout from './TableLayout';
import AdPreview from './AdPreview';
import styles from './TablePreviewLayout.module.scss';
import Media from './Media';
import { cx } from 'styles/emotion';

function TablePreviewLayout(props) {
  const {
    previewPaneChildren,
    tableBodyChildren,
    searchBarExtra,
    searchBarOnChange,
    searchBarPlaceholder,
    searchBarId,
    searchBarValue,
    searchBarKey,
    searchBarContainerClass,
    searchBarClass,
    searchBarHidden,
    previewPaneClassName,
    tableContainerClassName,
    tableBodyClassName,
  } = props;

  return (
    <Row className={styles.fullHeight} flexDirection={Row.FLEX_DIRECTION.COLUMN}>
      {!searchBarHidden && (
        <Row
          className={`
            ${styles.searchBarHeight} ${styles.addBorderBottom} ${styles.searchBarPadding}`}
          justifyContent={searchBarExtra
            ? Row.JUSTIFY_CONTENT.SPACE_BETWEEN
            : Row.JUSTIFY_CONTENT.FLEX_END
          }
          alignItems={Row.ALIGN_ITEMS.CENTER}
        >
          {searchBarExtra && searchBarExtra}
          <SearchBar
            placeholder={searchBarPlaceholder}
            id={searchBarId}
            onChange={searchBarOnChange}
            key={searchBarKey}
            value={searchBarValue}
            containerClassName={`${styles.searchBarContainer} ${searchBarContainerClass}`}
            className={`${styles.searchBarInput} ${searchBarClass}`}
          />
        </Row>
      )}
      <Row className={`${styles.bodyHeight} ${tableContainerClassName}`}>
        <Col
          flexGrow={0}
          flexBasis={'70%'}
          className={cx(styles.customScrollBar, tableBodyClassName)}
        >
          <div className={styles.tableBodyContainer}>
            {tableBodyChildren}
          </div>
        </Col>
        <Col
          flexGrow={0}
          flexBasis={'30%'}
          className={`
            ${styles.addBorderLeft}
            ${styles.addPadding}
            ${styles.customScrollBar}
            ${previewPaneClassName}
          `}
        >
          {previewPaneChildren}
        </Col>
      </Row>
    </Row>
  );
}

TablePreviewLayout.propTypes = {
  previewPaneChildren: PropTypes.element,
  tableBodyChildren: PropTypes.element,
  searchBarExtra: PropTypes.element,
  searchBarOnChange: PropTypes.func,
  searchBarPlaceholder: PropTypes.string,
  searchBarId: PropTypes.string,
  searchBarValue: PropTypes.string,
  searchBarKey: PropTypes.string,
  searchBarContainerClass: PropTypes.string,
  searchBarClass: PropTypes.string,
  searchBarHidden: PropTypes.bool,
  previewPaneClassName: PropTypes.string,
  tableContainerClassName: PropTypes.string,
  tableBodyClassName: PropTypes.string,
};

// Workaround until we replace all of the search bars with searchbar v2
TablePreviewLayout.defaultProps = {
  searchBarHidden: false,
};

TablePreviewLayout.Table = TableLayout;
TablePreviewLayout.AdPreview = AdPreview;
TablePreviewLayout.Media = Media;

export default TablePreviewLayout;
