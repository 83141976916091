import { put, call, select } from 'redux-saga/effects';
import { browserHistory } from 'react-router';
import {
  ENRICH_SEGMENTS,
  ENRICH,
  PUSH_CONTACTS,
  PURCHASE,
  L2A,
  L2C,
  NET_NEW_ACCOUNTS,
  WEB_ENGAGEMENT,
  CREATE_NEW_OPPORTUNITIES,
  EXPORT_SCORES,
  PUSH_ESI_CONTACTS,
  SYSTEMS,
} from './constants';
import { get, capitalize as _capitalize, omit, isEmpty, toLower, isArray } from 'lodash';
import { actions as globalActions } from 'modules/global';
import { throwIfNot404 } from 'utils/request';
import { OF, SUPPORTED_DESTINATIONS } from 'routes/Orchestrations/constants';

/**
 * Takes in the orchestration action name as defined from the enum in ActionType.java
 * and returns the acionType used throughout the UI. This function should only be used
 * where useOrchestration (the orchestration context) cannot be used.
 *
 * @param {string} value - action name/type
*/
export const getActionType = (value) => {
  let type = '';
  switch (value) {
    case 'ENRICH_SEGMENT_NAMES':
      type = ENRICH_SEGMENTS;
      break;
    case 'ENRICH_RECORDS':
      type = ENRICH;
      break;
    case 'ADD_EXISTING_CONTACTS':
      type = PUSH_CONTACTS;
      break;
    case 'PURCHASE_AND_PUSH_NEW_CONTACTS':
      type = PURCHASE;
      break;
    case 'L2A':
      type = L2A;
      break;
    case 'L2C':
      type = L2C;
      break;
    case 'NET_NEW_ACCOUNTS':
      type = NET_NEW_ACCOUNTS;
      break;
    case 'DRIVE_WEB_ENGAGEMENT':
      type = WEB_ENGAGEMENT;
      break;
    case 'CREATE_NEW_OPPORTUNITIES':
      type = CREATE_NEW_OPPORTUNITIES;
      break;
    case 'EXPORT_SCORES':
      type = EXPORT_SCORES;
      break;
    case 'ADD_NEW_CONTACTS_FROM_EMAIL':
      type = PUSH_ESI_CONTACTS;
      break;
    default:
      break;
  }
  return type;
};

/**
 * Retrieves system and object for an orchestration based on form values + other args
 * @param {object} formValues - formValue object from getCombinedFormValuesSelector
 * @param {string} mapType
 * @param {'lead'|'contact'} string - lead or contact, used for purchase+sf campaign orc
 * @param {object} config - captialize/plural options
*/
export const getTargetSystemAndObj = (
  formValues,
  mapType,
  crmCampaignTarget,
  { capitalize, plural } = { capitalize: false, plural: false },
  crmType,
) => {
  const {
    [OF.ACTION_TYPE]: orcActionType,
    [OF.SOURCE]: orchestration_source,
    [OF.ACTION_TARGET]: orcActionTarget = '',
  } = formValues;

  let system = toLower(crmType);
  // Hack for conversational_email_campaign as we need system as conversational_email
  const [targetSystem, targetSystem2] = orcActionTarget.split('_');
  if (targetSystem) {
    system = targetSystem;
    if ([
      SUPPORTED_DESTINATIONS.CONVERSATIONAL_EMAIL_CAMPAIGN,
      SUPPORTED_DESTINATIONS.CONVERSATIONAL_EMAIL_NO_CAMPAIGN].includes(orcActionTarget)) {
      system = `${targetSystem}_${targetSystem2}`;
    }
  } else {
    system = orchestration_source === 'malead' ? mapType.toLowerCase() : toLower(crmType);
  }

  let object = '';
  if (orchestration_source) {
    object = orchestration_source === 'malead' ? 'lead' : orchestration_source;
  }

  const actionType = getActionType(orcActionType);

  if ([PURCHASE, PUSH_CONTACTS, PUSH_ESI_CONTACTS].includes(actionType)) {
    const targetOrSourceToObjectMap = {
      [SUPPORTED_DESTINATIONS.SALESFORCE_LEAD_NO_CAMPAIGN]: 'lead',
      [SUPPORTED_DESTINATIONS.SALESFORCE_CONTACT_NO_CAMPAIGN]: 'contact',
      [SUPPORTED_DESTINATIONS.HUBSPOTCRM_NO_CAMPAIGN]: 'contact',

      [SUPPORTED_DESTINATIONS.MARKETO_NO_CAMPAIGN]: 'malead',
      [SUPPORTED_DESTINATIONS.ELOQUA_NO_CAMPAIGN]: 'malead',
      [SUPPORTED_DESTINATIONS.HUBSPOT_NO_CAMPAIGN]: 'malead',
      [SUPPORTED_DESTINATIONS.PARDOT_NO_CAMPAIGN]: 'malead',

      [SUPPORTED_DESTINATIONS.MARKETO_STATIC_LIST]: 'malead',
      [SUPPORTED_DESTINATIONS.HUBSPOT_STATIC_LIST]: 'malead',
      [SUPPORTED_DESTINATIONS.HUBSPOTCRM_STATIC_LIST]: 'contact',
      [SUPPORTED_DESTINATIONS.ELOQUA_LIST]: 'malead',
      [SUPPORTED_DESTINATIONS.PARDOT_LIST]: 'malead',
      [SUPPORTED_DESTINATIONS.CSV]: 'csv',
      [SUPPORTED_DESTINATIONS.MSD_LEAD_NO_CAMPAIGN]: 'lead',
      [SUPPORTED_DESTINATIONS.MSD_CONTACT_NO_CAMPAIGN]: 'contact',
    };

    object = targetOrSourceToObjectMap[orcActionTarget] || crmCampaignTarget;

    // for add audience to sf campaign, there isnt a toggle between lead/contact like there is
    // for purchase. So we need to force the object to be contact, as the crmCampaignTarget is
    // irrelevant
    const PUSH_DESTINATIONS = [
      SUPPORTED_DESTINATIONS.SALESFORCE_CAMPAIGN,
      SUPPORTED_DESTINATIONS.OUTREACH_SEQUENCE, SUPPORTED_DESTINATIONS.SALESLOFT_CADENCE,
      SUPPORTED_DESTINATIONS.CONVERSATIONAL_EMAIL_CAMPAIGN];
    if (actionType === PUSH_CONTACTS &&
      (PUSH_DESTINATIONS.includes(orcActionTarget))) {
      object = 'contact';
    }
  }

  if (actionType === NET_NEW_ACCOUNTS) {
    object = 'account';
  }
  if (actionType === L2C) {
    object = 'lead';
  }

  if (capitalize) {
    system = _capitalize(system);
    object = _capitalize(object);
  }
  if (plural && object) {
    object = `${object}s`;
  }

  return { system, object };
};

/**
 * A simple utility for the common pattern of simple state updates:
 * return all of the existing state, everything in the action besides the type key.
 * @param {object} state - current state
 * @param {object} action
 * @param {string|string[]} omitted fields - lodash's omit is awesome, so after the first 2
 * args, you can handle the omitted fields however you want, either usage will work,
 * but i'd use the first:
 * withoutReducer(state, action, 'type', 'value');
 * withoutReducer(state, action, ['type', 'value']);
 * */
export const withoutReducer = (state, action, ...omitted) => ({
  ...state,
  ...omit(action, omitted),
});

const { showNotification } = globalActions;

/*
 * quick saga for generic get requests...
 * Placing here, for now - will eventually move
 * to sixsense lib
 * todo: just rewrite a log method so dont have to check the config every log
 */
/* eslint-disable */
const logStyle = 'color: #a59ad6';
const errorStyle = 'color: red; font-weight: bold';
const sucStyle = 'color: green; font-weight: bold';
const separatorStyle = 'color: orange; margin-bottom: 15px';
export const genericGetSaga = (
  endpointSelector,
  {
    success,
    fail,
    preFlight,
    log = false,
  },
  failMessage,
) => function* getSaga(request) {
  if (log) {
    console.log('%c------------------------------------------------------------',
      'color: orange; margin-top: 15px');
    console.log('%cSAGA LOGGING ENABLED', logStyle);
  }
  const endpoint = yield select(endpointSelector);
  if (endpoint) {
    if (log) console.log(`%cendpoint found: ${endpoint}`, logStyle);
    try {
      if (preFlight) {
        if (log) console.log('%cdispatching preflight action', logStyle);
        yield put(preFlight());
      }
      const results = yield call(request, endpoint, 'GET', {}, throwIfNot404);
      if (log) {
        console.log('%cSUCCESS:', logStyle);
        console.log(results);
      }
      yield put(success(results));
      if (log) {
        console.log('%csaga complete [SUCCESS]!', sucStyle);
      }
      console.log('%c------------------------------------------------------------', separatorStyle);
      return 1;
    } catch (er) {
      if (log) console.log(`%cFAIL: ${er}`, logStyle);
      if (failMessage) {
        yield put(showNotification('error', failMessage));
      }
      if (fail) {
        if (log) console.log('%cdispatching fail action', logStyle);
        yield put(fail());
      }
    }
    if (log) {
      console.log('%csaga complete [FAILED]!', errorStyle);
      console.log('%c------------------------------------------------------------', separatorStyle);
    }
    return 0;
  }
  if (log) {
    console.log('%cno endpoint returned from the provided selector', logStyle);
    console.log('%csaga complete [ABORTED]!', 'color: yellow');
    console.log('%c------------------------------------------------------------', separatorStyle);
  }
  return 0;
};

export const fetchIfEmpty = (sel) => (getSaga) => function* emptyCheck(request) {
  const data = yield select(sel);
  if (isEmpty(data)) {
    yield getSaga(request);
  }
};

export const genericPostSaga = (
  endpointSelector,
  {
    success,
    fail, // eslint-disable-line
    preFlight,
    log = false,
    postBodySelector,
    errHandler = throwIfNot404,
  },
  failMessage,
) => function* getSaga(request) {
  if (log) {
    console.log('%c------------------------------------------------------------',
      'color: orange; margin-top: 15px');
    console.log('%cSAGA LOGGING ENABLED', logStyle);
  }
  const endpoint = yield select(endpointSelector);
  if (endpoint) {
    if (log) console.log(`%cendpoint found: ${endpoint}`, logStyle);
    try {
      if (preFlight) {
        if (log) console.log('%cdispatching preflight action', logStyle);
        yield put(preFlight());
      }
      const postBody = yield select(postBodySelector);
      if (log) {
        console.log('%cpost body found', logStyle);
        console.log(postBody);
      }
      const results = yield call(request, endpoint, 'POST', postBody, errHandler);
      if (log) {
        console.log('%cSUCCESS:', logStyle);
        console.log(results);
      }
      yield put(success(results));
      if (log) {
        console.log('%csaga complete [SUCCESS]!', sucStyle);
      }
      console.log('%c------------------------------------------------------------', separatorStyle);
      return 1;
    } catch (er) {
      if (log) console.log(`%cFAIL: ${er}`, logStyle);
      if (failMessage) {
        yield put(showNotification('error', failMessage));
      }
      if (fail) {
        if (log) console.log('%cdispatching fail action', logStyle);
        yield put(fail());
      }
    }
    if (log) {
      console.log('%csaga complete [FAILED]!', errorStyle);
      console.log('%c------------------------------------------------------------', separatorStyle);
    }
    return 0;
  }
  if (log) {
    console.log('%cno endpoint returned from the provided selector', logStyle);
    console.log('%csaga complete [ABORTED]!', 'color: yellow');
    console.log('%c------------------------------------------------------------', separatorStyle);
  }
  return 0;
};
/* eslint-enable */

// i just copy/pastad the post...need to do some thinking how to make a single interface
// or check if rupinder's lib is good enough

export const getOrchestrationId = (pathname) => {
  const pattern = /orchestrations\/manage\/(\d+)\//;
  const rex = new RegExp(pattern);
  const group = pathname.match(rex);
  return Number(get(group, '[1]', 0));
};

export const redirectToListView = () => browserHistory.push('/orchestrations/manage');

export const getAllSystemsToCheck = (crmType, mapType, sepType) => {
  const systems = [crmType, mapType, 'csv', SYSTEMS.CONVERSATIONAL_EMAIL];
  if (isArray(sepType)) {
    sepType.forEach((sep) => {
      systems.push(sep);
    });
  } else {
    systems.push(sepType);
  }

  return systems;
};
// eslint-disable-next-line
export const isFalsyValuePresent = (urlToCheck) => ['undefined', 'null', 'NaN'].some((val) => urlToCheck.includes(val));

export const containsEncodedComponents = (param) =>
  // ie ?,=,&,/,@ etc
  (decodeURI(param) !== decodeURIComponent(param));

export const removeParamsFromURL = (urlToCheck = '', paramsToRemove = []) => {
  try {
    const url = new URL(urlToCheck, window.location.origin);
    const params = new URLSearchParams(url.search);

    // Remove params we want to delete from URL
    paramsToRemove.forEach((param) => params.delete(param));
    // Assign back to URL object
    url.search = params.toString();
    // Check if we have any encoded values in the params and decode them
    url.search = containsEncodedComponents(url.search) ?
      decodeURIComponent(url.search) : url.search;
    // Remove initial "/"
    const pathname = (url.pathname.charAt(0) === '/') ?
      url.pathname.substring(1) : url.pathname;

    return `${pathname}${url.search}`;
  } catch (e) {
    return urlToCheck;
  }
};

/**
 * Used to extract error messages from spring response object
*/
export const getSpringResponseErrorMessage = (error) => new Promise((resolve, reject) => {
  error.response.json()
  .then((a) => resolve(a.localizedMessage))
  .catch(() => reject(error.errorMessage));
});
