import React from 'react';
import moment from 'moment';
import { Card, Flex, Grid } from '@sixsense/core/components';
import { User } from '../types';
import { STATUS_LABEL_MAP } from '../constants';
import { useSelector } from '@sixsense/core/versioned/react-redux';
import {
  authTypeSelector,
  formattedAllPrimaryAdminSelector,
  ownerSelector,
} from '../selectors';
import { Link } from 'v2-components';
import { useSetSharedValue } from '@sixsense/core/shared-state';
import { selectedUserState } from '../state';
import { css } from 'styles/emotion';
import { pxToRem } from '@sixsense/core/style';
import UserActionDropdown from './UserActionDropdown';
import PropTypes from 'prop-types';

const styles = {
  gridText: css({
    fontSize: pxToRem(13),
    color: '#001F32',
  }),
  label: css({
    fontWeight: 'bold',
  }),
};

const OwnerGridHeaders = ({ isSaml }) => (
  <React.Fragment>
    <Grid.Cell className={styles.label} row="1" column="1">
      Username
    </Grid.Cell>

    {!isSaml ? (
      <React.Fragment>
        <Grid.Cell className={styles.label} row="1" column="2">
          Status
        </Grid.Cell>
      </React.Fragment>
    ) : null}

    <Grid.Cell className={styles.label} row="1" column="3">
      First Name
    </Grid.Cell>

    <Grid.Cell className={styles.label} row="1" column="4">
      Last Name
    </Grid.Cell>

    <Grid.Cell className={styles.label} row="1" column="5">
      Role
    </Grid.Cell>

    {!isSaml ? (
      <React.Fragment>
        <Grid.Cell className={styles.label} row="1" column="6">
          Invited By
        </Grid.Cell>
      </React.Fragment>
    ) : null}

    <Grid.Cell className={styles.label} row="1" column="7">
      Last Login
    </Grid.Cell>
  </React.Fragment>
);

OwnerGridHeaders.propTypes = {
  isSaml: PropTypes.bool,
};

const OwnerGridData = ({
  owner,
  rowIndex,
  isSaml,
  isOwner,
  showOwnerLabel,
  setSelectedUser,
}) => (
  <React.Fragment>
    <Grid.Cell row={rowIndex} column="1">
      <Link onClick={() => setSelectedUser(owner.id)}>{owner.username}</Link>
    </Grid.Cell>

    {!isSaml ? (
      <React.Fragment>
        <Grid.Cell row={rowIndex} column="2">
          {STATUS_LABEL_MAP[owner.status]}
        </Grid.Cell>
      </React.Fragment>
    ) : null}

    <Grid.Cell row={rowIndex} column="3">
      {owner.firstName || '-'}
    </Grid.Cell>

    <Grid.Cell row={rowIndex} column="4">
      {owner.lastName || '-'}
    </Grid.Cell>

    <Grid.Cell row={rowIndex} column="5">
      Primary Administrator {isOwner && showOwnerLabel ? ' (Owner) ' : ''}
    </Grid.Cell>

    {!isSaml ? (
      <React.Fragment>
        <Grid.Cell row={rowIndex} column="6">
          {owner.invitedBy}
        </Grid.Cell>
      </React.Fragment>
    ) : null}

    <Grid.Cell row={rowIndex} column="7">
      {owner.lastLogin
        ? moment(owner.lastLogin).format('MMM DD, YYYY hh:mm a')
        : '-'}
    </Grid.Cell>

    <Grid.Cell row={rowIndex} column="8">
      <Flex alignItems="center" style={{ height: '100%' }}>
        <UserActionDropdown user={owner} isSAML={false} isPrimaryAdmin />
      </Flex>
    </Grid.Cell>
  </React.Fragment>
);


OwnerGridData.propTypes = {
  owner: PropTypes.any,
  rowIndex: PropTypes.number,
  isSaml: PropTypes.bool,
  isOwner: PropTypes.bool,
  showOwnerLabel: PropTypes.bool,
  setSelectedUser: PropTypes.func,
};


const OwnerCard = () => {
  const setSelectedUser = useSetSharedValue(selectedUserState);
  const paList: User[] | undefined = useSelector(
    formattedAllPrimaryAdminSelector
  );
  const isSaml = useSelector(authTypeSelector) === 'SAML';
  const owner = useSelector(ownerSelector);

  return paList.length > 0 ? (
    <Card>
      <Card.Content>
        <Grid
          className={styles.gridText}
          gap={8}
          templateColumns={
            isSaml
              ? '1fr min-content 1fr 1fr 1fr min-content 1fr min-content'
              : 'repeat(7, 1fr) min-content'
          }
        >
          <OwnerGridHeaders isSaml={isSaml} />
          {paList.map((pa, rowIndex) => (
            <OwnerGridData
              setSelectedUser={setSelectedUser}
              showOwnerLabel={paList.length > 1}
              owner={pa}
              isSaml={isSaml}
              rowIndex={rowIndex + 2}
              isOwner={pa.userId === owner.id}
            />
          ))}
        </Grid>
      </Card.Content>
    </Card>
  ) : null;
};

export default OwnerCard;
