import React, { useEffect } from 'react';
import { css } from 'styles/emotion';
import {
  Select,
} from 'v2-components';
import { KW_EXP, OTHER, MODELS_ENABLED_PLAN, PRODUCT_CATEGORIES } from '../constants';
import { ProductCategory } from '../types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orgObjSelector } from 'modules/user/selectors';
import { get } from 'lodash';

const styles = {
  categoryDropdown: css({
    width: '200px',
    height: '36px',
  }),
};
type Props = {
  onChange: (any) => void;
  setSelectedCategory: (any) => void;
  selectedCategory: ProductCategory;
  org : any;
};
const ProductCategorySelect = ({
  onChange,
  setSelectedCategory,
  selectedCategory,
  org,
} : Props) => {
  const packagePlan = get(org, 'packagePlan.SI.plan.name', '');
  const isPredictivePlan = MODELS_ENABLED_PLAN.includes(packagePlan);
  const products = isPredictivePlan
    ? get(org, 'packages.aa.products', []) // same as in keywords
    : get(org, 'packages.SI.products', []);

  let productOptions = products.filter(
    (el) => !el.is_deleted
  );
  if (isPredictivePlan && products.length > 1) {
    productOptions = [...PRODUCT_CATEGORIES, ...productOptions];
  }
  productOptions = productOptions.map((p) => ({
    value: p.id,
    label: p.display_name === KW_EXP ? OTHER : p.display_name,
    name: p.name,
  }));

  let defaultLabel;

  useEffect(() => {
    if (productOptions && productOptions.length && !defaultLabel) {
      setSelectedCategory(productOptions[0]);
      defaultLabel = productOptions[0].label;
    }
  }, []);

  useEffect(() => {
    if (selectedCategory.name) {
      onChange(selectedCategory);
    }
  }, [selectedCategory]);

  const onChangeCatgory = (val) => {
    setSelectedCategory(productOptions?.find((el) => val === el.value) || {});
  };

  return (
    <div className={styles.categoryDropdown}>
      <Select
        onChange={onChangeCatgory}
        defaultValue={defaultLabel}
        placeholder="Select Product"
        value={selectedCategory?.value}
        valueKey="value"
        options={productOptions}
        width="100%"
        tether
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  org: orgObjSelector(state),
});

export default compose(
  connect(mapStateToProps, null),
)(ProductCategorySelect);
