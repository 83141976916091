/* eslint-disable */
import React from 'react';
import styles from './EnabledIcon.module.scss';
import EnabledIconImage from './images/enabled_icon.svg';

const EnabledIcon = (props) => {
const { className, size } = props;
return (
  <img src={EnabledIconImage}
    className={`${styles.default} ${styles[size]} ${styles['enabled']} ${className} `}
  />
);
};
export default EnabledIcon;