import {
  DefaultRole,
  ConversationalEmailDefaultRole,
  SalesDefaultRole,
  WorkflowsDefaultRole,
  App,
} from '@sixsense/rbac';

// Todo: Each App Team Need to Enter Details in this Functions.
export const getPrimaryAdminIdList = () => [
  DefaultRole.PRIMARY_ADMINISTRATOR,
  ConversationalEmailDefaultRole.PRIMARY_ADMINISTRATOR,
  WorkflowsDefaultRole.PRIMARY_ADMINISTRATOR,


  SalesDefaultRole.A_PRIMARY_ADMINISTRATOR,
  SalesDefaultRole.B_PRIMARY_ADMINISTRATOR,
  SalesDefaultRole.C_PRIMARY_ADMINISTRATOR,
  SalesDefaultRole.D_PRIMARY_ADMINISTRATOR,
  SalesDefaultRole.E_FULL_PRIMARY_ADMINISTRATOR,
  SalesDefaultRole.E_LITE_PRIMARY_ADMINISTRATOR,

];

export const getPrimaryAdminListByAppId = () => ({
  [App.ABM]: [DefaultRole.PRIMARY_ADMINISTRATOR],
  [App.CE]: [ConversationalEmailDefaultRole.PRIMARY_ADMINISTRATOR],
  [App.WORKFLOWS]: [WorkflowsDefaultRole.PRIMARY_ADMINISTRATOR],
  [App.SALES]: [
    SalesDefaultRole.A_PRIMARY_ADMINISTRATOR,
    SalesDefaultRole.B_PRIMARY_ADMINISTRATOR,
    SalesDefaultRole.C_PRIMARY_ADMINISTRATOR,
    SalesDefaultRole.D_PRIMARY_ADMINISTRATOR,
    SalesDefaultRole.E_FULL_PRIMARY_ADMINISTRATOR,
    SalesDefaultRole.E_LITE_PRIMARY_ADMINISTRATOR,
  ],
});

export const getAdminIdList = () => [
  DefaultRole.ADMINISTRATOR,
  ConversationalEmailDefaultRole.ADMINISTRATOR,
  WorkflowsDefaultRole.ADMINISTRATOR,


  SalesDefaultRole.C_ADMINISTRATOR,
  SalesDefaultRole.D_ADMINISTRATOR,
  SalesDefaultRole.E_FULL_ADMINISTRATOR,
  SalesDefaultRole.E_LITE_ADMINISTRATOR,
];

export const getAdminIdListByAppId = () => ({
  [App.ABM]: [DefaultRole.ADMINISTRATOR],
  [App.CE]: [ConversationalEmailDefaultRole.ADMINISTRATOR],
  [App.WORKFLOWS]: [WorkflowsDefaultRole.ADMINISTRATOR],
  [App.SALES]: [
    SalesDefaultRole.C_ADMINISTRATOR,
    SalesDefaultRole.D_ADMINISTRATOR,
    SalesDefaultRole.E_FULL_ADMINISTRATOR,
    SalesDefaultRole.E_LITE_ADMINISTRATOR,
  ],
});


export const getShortAndLongNameMap = () => ({
  [App.ABM]: {
    shortName: 'ABM',
    longName: 'ABM',
  },
  [App.CE]: {
    shortName: 'CE',
    longName: 'Conversational Email',
  },
  [App.SALES]: {
    shortName: 'SI',
    longName: 'Sales Intelligence',
  },
  [App.WORKFLOWS]: {
    shortName: 'WF',
    longName: 'Workflows',
  },
});
export const getRoleIdsToHide = () => ([
  ConversationalEmailDefaultRole.SALES_USER,
  DefaultRole.USER__LEGACY,
  WorkflowsDefaultRole.MARKETING_OPS_USER,
]);
