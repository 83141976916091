import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { Flex } from '@sixsense/core/components';
import { Permission, usePermissionCheck } from '@sixsense/rbac';
import { Select, Text, Icon, Button, Loading } from 'v2-components';
import {
  KEYWORD_CATEGORY_OPTIONS,
  RESEARCH_OPTIONS,
  DOWNLOAD_LIMIT,
  KEYWORDS,
  GROUPS,
  DEACTIVATED,
  BRANDED,
  GENERIC,
  KW_EXP,
  OTHER,
} from '../constants';
import { css } from 'styles/emotion';
import SearchBar from './SearchBar';
import { useSharedState, useSharedValue, useSetSharedValue, useRequest } from '@sixsense/core';
import {
  deactivatedConfigState,
  restoreKeywordsVisibleState,
  deleteGroupVisibleState,
  orgProductState,
  addKeywordsConfigState,
} from '../state';
import { capitalize } from 'lodash';
import { getQueryParams, getConfigState } from '../utils';
import { useOrg } from 'hooks';
import { actions as globalActions } from 'modules/global';
import { actions as downloadActions } from 'v2-components/Download/modules';
import { Option } from 'rc-select';
import CategoryDescription from './CategoryDescriptions';
import { AAColor } from '@sixsense/core/style';
import { KeywordActions, FilterBy } from '../components/Keywords';
import { useDebounce } from 'react-use';
import { RecKeywordsModal } from '../components/Modals';

const styles = {
  productSelect: css({
    marginLeft: '10px',
  }),
  searchContainer: css({
    width: '100%',
  }),
  container: css({
    width: '100%',
    flexDirection: 'column',
  }),
  label: css({
    marginRight: '10px',
  }),
  downloadIcon: css({
    marginRight: '5px',
  }),
  downloadButton: css({
    marginLeft: '10px',
    fontSize: '1rem',
    height: '36px',
  }),
  title: css({
    whiteSpace: 'nowrap',
  }),
  loadingIcon: css({
    marginLeft: '10px',
  }),
  option: css({
    whiteSpace: 'normal !important',
    '&:hover': {
      backgroundColor: `${AAColor.BLUE} !important`,
      color: `${AAColor.WHITE} !important`,
    },
  }),
  recKeywords: css({
    cursor: 'pointer',
    marginLeft: '10px',
  }),
  catSelect: css({
    marginLeft: 10,
    marginRight: 5,
  }),
  groupSearch: css({
    marginLeft: '20px !important',
  }),
};

type Props = {
  label: string;
  multiLabel: string;
  disabled: boolean;
  allKeywords?: any;
  keywordsCount?: number;
  pageKey: string;
};

const KeywordFilters = ({
  label,
  multiLabel,
  disabled,
  allKeywords = [],
  keywordsCount,
  pageKey,
}: Props) => {
  const products = useSharedValue(orgProductState);
  const org = useOrg();
  const request = useRequest();
  const dispatch = useDispatch();

  const state = getConfigState(pageKey, false);
  const kwsToRestore = useSharedValue(deactivatedConfigState).selectedValues || [];
  const setRestoreModalVisible = useSetSharedValue(restoreKeywordsVisibleState);
  const setDeleteModalVisible = useSetSharedValue(deleteGroupVisibleState);
  const [config, setConfig] = useSharedState(state);
  const [addKwConfig, setAddKwConfig] = useSharedState(addKeywordsConfigState);
  const [loading, setLoading] = useState(false);
  const [query, setSearchQuery] = useState(undefined);

  const canEdit = usePermissionCheck([Permission.SETTINGS_KEYWORDS_EDIT]);

  const productOptions = useMemo(
    () => {
      const options = products.map((p) => ({
        value: p.id,
        label: p.displayName === KW_EXP ? OTHER : p.displayName,
      }));
      if (options.length > 1) {
        return [{ label: 'All Product Categories', value: 'all' }].concat(options);
      }
      return options;
    }, [products]
  );

  useEffect(() => {
    if (config.filters.product_id === 'all' && productOptions.length === 1 && pageKey !== GROUPS) {
      // If the user isn't changing the page, we should reset the page number to 1
      // for any filter changes.
      setConfig({
        ...config,
        filters: { ...config.filters, product_id: productOptions[0].value },
        pagination: { ...config.pagination, pageNumber: 1 },
        selectedValues: [],
      });
    }
    setSearchQuery(config.filters.query);
    if (config.isRefresh) {
      setConfig({ ...config, isRefresh: false });
    }
  }, [config.filters.product_id, productOptions, config.filters]);

  useDebounce(() => {
    setConfig({
      ...config,
      filters: { ...config.filters, query },
      pagination: { ...config.pagination, pageNumber: 1 },
      selectedValues: [],
    });
  }, 1500, [query]);

  const title = config.selectedValues.length > 0 ? multiLabel : label;

  const downloadKeywords = async () => {
    if (keywordsCount > DOWNLOAD_LIMIT) {
      dispatch(globalActions.showNotification('error',
        'You may only download a maximum of 100,000 keywords. Please refine your filters.'));
    } else {
      setLoading(true);
      const queryParams = getQueryParams(config.filters, false);
      const { results } = await request(`organization/${org.id}/keyword/${queryParams}`, {
        method: 'GET',
      });
      dispatch(downloadActions.download(
        results, `${org.name}_keywords`, 'keywords', productOptions, pageKey));
      setLoading(false);
    }
  };

  const categoryOptions = KEYWORD_CATEGORY_OPTIONS.map((option) => (
    <Option
      key={option.value}
      value={option.value}
      className={styles.option}
    >
      {[BRANDED, GENERIC].includes(option.value) ?
        <CategoryDescription category={option.value} /> : option.label}
    </Option>
  ));

  return (
    <div className={styles.container}>
      {pageKey !== GROUPS && <Flex style={{ marginBottom: '25px' }}>
        <Select
          showSearch={false}
          options={productOptions}
          value={products.length === 1 ? products[0].displayName : config.filters.product_id}
          onChange={(v) => {
            setConfig({
              ...config,
              filters: { ...config.filters, product_id: v },
              pagination: { ...config.pagination, pageNumber: 1 },
              selectedValues: [],
            });
          }}
          width="200px"
          placeholder="Select a Product"
          icon={Select.FILTER_ICON}
          disabled={disabled || loading}
        />
        <Select
          showSearch={false}
          options={categoryOptions}
          useCustomOptions
          value={[BRANDED, GENERIC].includes(config.filters.category) ?
            capitalize(config.filters.category) : config.filters.category}
          width="200px"
          containerClassName={styles.catSelect}
          icon={Select.FILTER_ICON}
          placeholder="Select a Keyword Category"
          onChange={(v) => {
            setConfig({
              ...config,
              filters: { ...config.filters, category: v },
              pagination: { ...config.pagination, pageNumber: 1 },
              selectedValues: [],
            });
          }}
          disabled={disabled || loading}
        />
        <SearchBar
          containerClassName={styles.searchContainer}
          value={query}
          setValue={setSearchQuery}
          disabled={disabled || loading}
          placeholder={'Search keywords'}
        />
      </Flex>}
      <Flex style={{ marginBottom: '25px', justifyContent: 'space-between', width: '100%' }}>
        {pageKey === GROUPS ? (
          <Flex
            alignItems="flex-end"
            style={{ flexGrow: 1, alignItems: 'center', marginRight: 20 }}
          >
            <Text type="title" bold className={styles.title}>
              {disabled ? 'Loading...' : title}
            </Text>
            <SearchBar
              containerClassName={styles.groupSearch}
              value={query}
              setValue={setSearchQuery}
              disabled={disabled || loading}
              placeholder={'Search keyword groups'}
            />
          </Flex>
        ) : (
          <Flex alignItems="baseline">
            <Text className={styles.label} type="title" bold>
              {disabled ? 'Loading...' : title}
            </Text>
            {(pageKey === KEYWORDS && config.selectedValues.length === 0) && canEdit && (
              <Text
                className={styles.recKeywords}
                color={Text.COLOR.BLUE}
                bold
                onClick={() => setAddKwConfig({ ...addKwConfig, recKeywordsVisible: true })}
              >
                Recommended Keywords
              </Text>
            )}
            <RecKeywordsModal
              onCancel={() => setAddKwConfig({ ...addKwConfig, recKeywordsVisible: false })}
              visible={addKwConfig.recKeywordsVisible}
            />
          </Flex>
        )}
        <Flex style={{ alignItems: 'center' }}>
          {pageKey !== DEACTIVATED && <React.Fragment>
            <Text bold type="subBody">Research activity period</Text>
            <Select
              showSearch={false}
              options={RESEARCH_OPTIONS}
              value={config.spanFilter}
              onChange={(v) => setConfig({
                ...config, spanFilter: v, pagination: { ...config.pagination, pageNumber: 1 } })}
              width="125px"
              icon={Select.DATE_ICON}
              containerClassName={styles.productSelect}
              disabled={disabled || loading}
            />
          </React.Fragment>}
          {pageKey === DEACTIVATED && kwsToRestore.length > 1 &&
            <Button
              className={styles.downloadButton}
              onClick={() => setRestoreModalVisible(true)}
            >
              <Icon className={styles.downloadIcon} size={Icon.SIZE.MEDIUM} type="undo" />
              Restore
            </Button>
          }
          {pageKey === GROUPS && config.selectedValues.length > 1 &&
            <Button
              className={styles.downloadButton}
              onClick={() => setDeleteModalVisible(true)}
            >
              Delete Groups
            </Button>
          }
          {pageKey !== GROUPS && pageKey !== DEACTIVATED && config.selectedValues.length > 0 &&
            <KeywordActions
              multiSelect
              selectedKeywords={config.selectedValues.map((i) =>
                allKeywords.find((k) => k.id === i))}
            />
          }
          {pageKey !== GROUPS && <React.Fragment>
            {pageKey === KEYWORDS && (
              <FilterBy config={config} setConfig={setConfig} disabled={disabled || loading} />
            )}
            <Button
              className={styles.downloadButton}
              onClick={() => downloadKeywords()}
            >
              <Icon className={styles.downloadIcon} size={Icon.SIZE.MEDIUM} type="get_app" />
              Download
            </Button>
            {loading && (
            <Loading
              size="small"
              spinnerWidth={15}
              spinnerHeight={15}
              pageLevel={false}
              className={styles.loadingIcon}
            />
          )}
          </React.Fragment>}
        </Flex>
      </Flex>
    </div>
  );
};

export default KeywordFilters;
