import { capitalize, isNil, map } from 'lodash';
import { numberToDollar } from 'utils/utils';


const ignores = ['for', 'is', 'and', 'of', 'the', '&'];

export const formatCaps = (v) => {
  const words = v.split(' ');
  const caps = map(words, (w) => ignores.includes(w) ? w : capitalize(w));
  return caps.join(' ');
};

const oneOffMap = {
  '1b  25b': '1B - 2.5B',
  '5b': '5B+',
  '25b  5b': '2.5B - 5B',
  10000: '10000+',
};

const handleEmptys = (v) => isNil(v) || v === '6si-missing' ? 'N/A' : formatCaps(v);

export const signalFormatters = new Proxy(
  {
    'number of employees': (v) => v.split(' ').join('-'),
    'revenue in $': (v) => numberToDollar(Number(v), { insertCommas: true }),
    'naics code': (v) => v,
    country: (v) => handleEmptys(v),
    industry: (v) => handleEmptys(v),
    'revenue range': (v) => v in oneOffMap // eslint-disable-line
      ? oneOffMap[v]
      : (isNil(v) ? 'N/A' : v.split('  ').join(' - ').toUpperCase()),
    'Revenue range': (v) => v in oneOffMap // eslint-disable-line
      ? oneOffMap[v]
      : (isNil(v) ? 'N/A' : v.split('  ').join(' - ').toUpperCase()),

    'employee range': (v) => v in oneOffMap // eslint-disable-line
      ? oneOffMap[v]
      : (isNil(v) ? 'N/A' : v.split('  ').join(' - ').toUpperCase()),
    'job level derived from title': (v) => isNil(v) ? 'N/A' : formatCaps(v),
    'job function derived from title': (v) => isNil(v) ? 'N/A' : formatCaps(v),
  },
  {
    get(obj, prop) {
      return prop in obj
        ? obj[prop]
        : (v) => (isNil(v) || v === '6si-missing') ? 'N/A' : capitalize(v);
    },
  }
);

export const categoryFormatters = {
  'naics code': 'NAICS Code',
  'sic code': 'SIC Code',
  'Revenue range': 'Revenue Range',
  'Technology Tracked': 'Technology Tracked',
  'first digit of sic code': 'First Digit of SIC Code',
  'job level derived from title': 'Job Level',
  'job function derived from title': 'Job Function',
  'is name missing': 'Missing Name',
  'presence of valid email': 'Presence of email',
  'presence of valid phone': 'Presence of phone',
  'lead source': 'Lead Source',
  decision_making_role: 'Decision Making Role - Provided By Customer',
  'decision_making_role provided by customer': 'Decision Making Role - Provided By Customer',
  'role -- provided by customer': 'Role - Provided By Customer',
};
