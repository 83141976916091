import { is } from 'ramda';
import { Record } from 'immutable';

export class NullExpr extends Record({}) {
  toJSON() {
    return { type: this.constructor.name };
  }
}

function constType(value) {
  if (is(Number, value)) {
    if (Number.isInteger(value)) {
      return 'IntConst';
    }
  }

  if (is(Boolean, value)) {
    return 'BoolConst';
  }
  return 'Const';
}

export class Const extends Record({ value: null }) {
  constructor(value) {
    super({ value });
  }

  toJSON() {
    return {
      type: constType(this.value),
      value: this.value,
    };
  }
}

export class VarExpr extends Record({ name: null }) {
  constructor(name) {
    super({ name });
  }

  toJSON() {
    return { type: this.constructor.name, name: this.name };
  }
}

export class NotOp extends Record({ expr: null }) {
  constructor(expr) {
    super({ expr });
  }
  toJSON() {
    return { type: this.constructor.name, expr: this.expr.toJSON() };
  }
}

export class Cast extends Record({ expr: null, cast_type: null }) {
  constructor(expr, cast_type) {
    super({ expr, cast_type });
  }

  toJSON() {
    return {
      type: this.constructor.name,
      expr: this.expr.toJSON(),
      cast_type: this.cast_type,
    };
  }
}

export class AllColsExpr {
  toJSON() {
    return { type: this.constructor.name };
  }
}

export class AliasExpr extends Record({ expr: null, alias: null }) {
  constructor(expr, alias) {
    super({ expr, alias });
  }

  toJSON() {
    return {
      type: this.constructor.name,
      expr: this.expr,
      alias: this.alias,
    };
  }
}

// Unary Expressions
export class UnaryExpr extends Record({ expr: null }) {
  constructor(expr) {
    super({ expr });
  }

  toJSON() {
    return { type: this.constructor.name, expr: this.expr };
  }
}

export class Asc extends UnaryExpr {}

export class Desc extends UnaryExpr {}

// Binary Expressions

export class BinaryExpr extends Record({ lhs: null, rhs: null }) {
  constructor(lhs, rhs) {
    super({ lhs, rhs });
  }

  toJSON() {
    return { type: this.constructor.name, lhs: this.lhs, rhs: this.rhs };
  }
}

export class LogicalOperator extends BinaryExpr {
 /* Common parent class for AND/OR operator */
}

export class And extends LogicalOperator {
  /* lhs and rhs */
}

export class Or extends LogicalOperator {
  /* lhs or rhs */
}

export class ModelAnd extends And {
  /* ModelAnd is same as And, added just for handling AST expr name */
}

export class ModelOr extends Or {
  /* ModelOr is same as Or, added just for handling AST expr name */
}

export class LtOp extends BinaryExpr {
  /* Less than */
}

export class LeOp extends BinaryExpr {
  /* Less than or equal to */
}

export class EqOp extends BinaryExpr {
  /* Equal to */
}

export class NeOp extends BinaryExpr {
  /* Not equal */
}

export class NotEqOp extends BinaryExpr {
  /* Not equal for filterset */
}

export class GeOp extends BinaryExpr {
  /* Greater than  equal to */
}

export class GtOp extends BinaryExpr {
  /* Greater than */
}

export class RegExpOp extends BinaryExpr {}

export class StartsWithOp extends BinaryExpr {}

export class ContainsOp extends BinaryExpr {}

export class NotContainsOp extends BinaryExpr {
  /* Does not contain */
}

export class EndsWithOp extends BinaryExpr {}

export class LikeOp extends BinaryExpr {}
export class NotLikeOp extends BinaryExpr {}

export class RLikeOp extends BinaryExpr {}
export class NotRLikeOp extends BinaryExpr {}

export class IsNotOp extends BinaryExpr {}
export class IsOp extends BinaryExpr {}

export class RenameOp extends BinaryExpr {}

export class BetweenOp extends Record({ expr: null, ge_op: null, le_op: null }) {
  constructor(expr, ge_op, le_op) {
    super({ expr, ge_op, le_op });
  }

  toJSON() {
    return {
      type: this.constructor.name,
      expr: this.expr.toJSON(),
      ge_op: this.ge_op.toJSON(),
      le_op: this.le_op.toJSON(),
    };
  }
}

export class IfElseExpr {
  constructor(expr1, expr2, expr3) {
    // if exprx1 is true return the results of expr2 otherwise return expr3
    this.expr1 = expr1;
    this.expr2 = expr2;
    this.expr3 = expr3;
  }
}

export class CaseWhen extends Record({ conditions: null, default_: null }) {
  constructor(conditions, default_) {
    super({ conditions, default_ });
  }

  toJSON() {
    return {
      type: this.constructor.name,
      conditions: this.conditions.map((cond) => ({
        when: cond.when.toJSON(),
        then: cond.then.toJSON(),
      })),
      default: this.default_ ? this.default_.toJSON() : null,
    };
  }
}

export const Condition = Record({ when: null, then: null });

export class InOp extends Record({ expr: null, exprs: null }) {
  constructor(expr, exprs) {
    super({ expr, exprs });
  }

  toJSON() {
    return {
      type: this.constructor.name,
      expr: this.expr.toJSON(),
      exprs: this.exprs.map((e) => e.toJSON()),
    };
  }
}

export class NotInOp extends InOp {}

export class Func extends Record({ name: null, exprs: null }) {
  constructor(name, exprs) {
    super({ name, exprs });
  }

  toJSON() {
    return {
      type: this.constructor.name,
      name: this.name,
      exprs: this.exprs.map((e) => e.toJSON()),
    };
  }
}

export class Relation extends Record({}) {
  toJSON() {
    return { type: this.constructor.name };
  }
}

export class ScanOp extends Record({ name: null }) {
  constructor(name) {
    super({ name });
  }

  toJSON() {
    return { type: this.constructor.name, name: this.name };
  }
}

export class ProjectOp extends Record({ relation: null, exprs: null }) {
  constructor(relation, exprs) {
    super({ relation, exprs });
  }

  toJSON() {
    return {
      type: this.constructor.name,
      relation: this.relation.toJSON(),
      exprs: this.exprs.map((e) => e.toJSON()),
    };
  }
}

export class SelectionOp extends Record({ relation: null, bool_op: null }) {
  constructor(relation, bool_op) {
    super({ relation, bool_op });
  }

  toJSON() {
    return {
      type: this.constructor.name,
      relation: this.relation.toJSON(),
      bool_op: this.bool_op.toJSON(),
    };
  }
}

export class JoinOp extends Record({ left: null, right: null, bool_op: null }) {
  constructor(left, right, bool_op) {
    super({ left, right, bool_op });
  }

  toJSON() {
    return {
      type: this.constructor.name,
      left: this.left.toJSON(),
      right: this.right.toJSON(),
      bool_op: this.bool_op.toJSON(),
    };
  }
}

export class LeftJoinOp extends Record({ left: null, right: null, bool_op: null }) {
  constructor(left, right, bool_op) {
    super({ left, right, bool_op });
  }

  toJSON() {
    return {
      type: this.constructor.name,
      left: this.left.toJSON(),
      right: this.right.toJSON(),
      bool_op: this.bool_op.toJSON(),
    };
  }
}

export class UnionAllOp extends Record({ left: null, right: null }) {
  constructor(left, right) {
    super({ left, right });
  }

  toJSON() {
    return {
      type: this.constructor.name,
      left: this.left.toJSON(),
      right: this.right.toJSON(),
    };
  }
}
