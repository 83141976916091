import ConfigRoute from './routes/config';

export default function createRoutes(store) {
  return {
    path: 'asdf',
    name: 'Keywords',
    indexRoute: {
      onEnter: (nextState, replace) => replace('asdf'),
    },

    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const containers = require('./containers');
        cb(null, containers.KeywordsConfig);
      }, 'settings_keywords');
    },
    childRoutes: [
      ConfigRoute(store),
    ],
  };
}
