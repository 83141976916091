import React from 'react';
import PropTypes from 'prop-types';
import { CustomIcon, Image, Video, Loading2 } from 'v2-components';
import { ImageErrorComponent } from 'v2-components/Image';
import { AdBlockContext as AdBlockConsumer } from 'contexts';
import { strings } from 'utils/constants';
import styles from './AdPreview.module.scss';
import { AD_TYPE_IDS } from 'routes/Advertising/constants';
import { get } from 'lodash';


const Loading = Loading2.LoadingComponent;

function Media(props) {
  const {
    name,
    creative_type,
    s3_url,
    className,
    showControls,
    containerClass,
    ErrorComponent,
    context,
    adBlockEnabled,
    html5IconSize,
    localPreviewUrl,
    captionUrl,
    thumbnail_attachment,
    showThumbnailAsOverlay = false,
  } = props;

  const thumbnailUrl = get(thumbnail_attachment, 's3_url', null);

  if (adBlockEnabled) {
    const AdBlockerError = ImageErrorComponent(
      strings.DISABLE_ADBLOCKER,
      styles.errorIcon,
      CustomIcon.ADBLOCKER_ICON
    );
    return (<AdBlockerError
      containerClassName={`${styles.containerClassName} ${containerClass}`}
    />);
  }

  /* <div className={`${styles.containerClassName} ${containerClass}`}>
  <video
    controls={showControls}
    preload="metadata"
    style={{ maxWidth: '100%', maxHeight: '100%' }}
  >
    <source src={s3_url} type={'video/mp4'} />
    Your browser does not support the video tag.
  </video>
</div> */
  let content;
  switch (creative_type) {
    case AD_TYPE_IDS.linkedInVideoAd:
    case 'video': {
      content = (
        <Video
          src={s3_url || localPreviewUrl}
          controls={showControls}
          preload="metadata"
          className={`${styles.mediaImage} ${className}`}
          containerClassName={`${styles.containerClassName} ${containerClass}`}
          ErrorComponent={ErrorComponent}
          LoadingComponent={() => (
            <Loading className={`${styles.imageLoader} ${containerClass}`} size={'small'} />
          )}
          captionUrl={captionUrl}
          thumbnailUrl={thumbnailUrl}
          showThumbnailAsOverlay={showThumbnailAsOverlay}
        />
      );
      break;
    }
    case 'native':
    case 'icon':
    case AD_TYPE_IDS.linkedInSingleImageAd:
    case 'banner': {
      content = (
        <Image
          src={s3_url || localPreviewUrl}
          alt={name}
          context={context}
          LoadingComponent={() => (
            <Loading className={`${styles.imageLoader} ${containerClass}`} size={'small'} />
          )}
          className={`${styles.mediaImage} ${className}`}
          containerClassName={`${styles.containerClassName} ${containerClass}`}
          ErrorComponent={ErrorComponent}
        />
      );
      break;
    }
    case 'html5':
    case 'html5_dynamic': {
      content = <CustomIcon iconType={CustomIcon.HTML5_ICON} size={html5IconSize} />;
      break;
    }
    default: {
      content = null;
      break;
    }
  }

  return content;
}

Media.propTypes = {
  name: PropTypes.string,
  creative_type: PropTypes.string,
  s3_url: PropTypes.string,
  className: PropTypes.string,
  showControls: PropTypes.bool,
  containerClass: PropTypes.string,
  ErrorComponent: PropTypes.any,
  context: PropTypes.oneOf(['Global', 'Local', 'None']),
  adBlockEnabled: PropTypes.bool,
  html5IconSize: PropTypes.string,
  localPreviewUrl: PropTypes.string,
  captionUrl: PropTypes.string,
  thumbnail_attachment: PropTypes.object,
  showThumbnailAsOverlay: PropTypes.bool,
};

Media.defaultProps = {
  context: 'Global',
  html5IconSize: 'large1',
};

export default AdBlockConsumer(Media);
